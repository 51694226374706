import { ecrGateway } from '@/constants'
import { sha256 } from 'js-sha256'
import axios from 'axios'
import delay from '@/libs/delay'
import ecrPayMethods from '@/constants/ecrPayMethods'
import parser from 'fast-xml-parser'
import store from '@/redux/store'
const getState = store.getState

export default class BBMSL {
  constructor () {
    this.cardTerminalIpAddress = getState().app.settings.cardTerminalIpAddress?.bbMSL
  }

  sales = async (AMT, ecrRef, payMethod = ecrPayMethods.CREDIT_CARD.BBMSL) => {
    const data = {
      amount: AMT,
      transactionType: 'SALE',
      payMethod: payMethod,
      invoiceNumber: ecrRef,
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
      ecrRef,
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  adjustment = async (transactionId, TIPS) => {
    const data = {
      transactionId,
      transactionType: 'TIPSADJUST',
      tipAmount: TIPS,
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  void = async (transactionId, paymentMethod) => {
    const data = {
      transactionId,
      transactionType: 'VOID',
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  _sha256Checksum = data => {
    return sha256(JSON.stringify(data))
  }

  _startTransaction = (payload) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `http://${this.cardTerminalIpAddress}/pos`,
        params: payload.data,
      }).then(async (response) => {
        const result = parser.parse(response.data, {
          numParseOptions: {
            leadingZeros: false,
          },
        }).WAPIResult
        console.log('response', result)

        if (result.status === 'SUCCESS') {
          const formattedResponse = this.toJsonResponse(result, payload.ecrRef)
          resolve(formattedResponse)
        } else {
          const error = new Error(`${result.status}: ${result.ErrorMessage}`)
          reject(error)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getPaymentStatus = async (invoiceNumber) => {
    const data = {
      transactionType: 'QUERY_LAST_TRANSACTION',
      invoiceNumber,
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
      ecrRef: invoiceNumber,
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  toJsonResponse = (response, ecrRef) => {
    const getCard = () => {
      if (response.cardData) {
        switch (response?.cardData?.cardType) {
          case 'MASTER':
            return 'master'
          case 'AMEX':
            return 'ae'
          case 'WECHAT':
            return 'wechat'
          case 'ALIPAY':
            return 'alipay'
          default:
            return response?.cardData?.cardType?.toLowerCase()
        }
      }
      if (response.receiptData?.octopusNo) {
        return 'octopus'
      }
    }

    return {
      card: getCard(),
      gateway: ecrGateway.BBMSL,
      ecrRef,
      payload: response,
    }
  }
}
