import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

import { LinearProgress } from 'react-native-elements'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import colors from '@/theme/colors'

const COUNTDOWN = 10 // 預設倒數時間

/**
 *
 * @param {*} props
 * @returns
 */
export default function EPaymentDialog (props) {
  const { type, manualRef, cancelRef } = props
  const [counter, setCounter] = React.useState(null)
  const isShowEPayment = useSelector(state => state.app.dialog.ePayment)
  const { t } = useTranslation()
  const typeI18n = t(`app.component.ePaymentDialog.${type}`)

  React.useEffect(() => {
    setCounter(COUNTDOWN)
  }, [isShowEPayment])

  // Timer
  React.useEffect(() => {
    let timer
    if (isShowEPayment && counter > 0) {
      timer = setInterval(() => setCounter(prevCounter => prevCounter - 1), 1000)
    }
    return () => clearInterval(timer)
  }, [counter, isShowEPayment])

  return (
    <CenterModal
      visible={isShowEPayment}
      noCloseButton
      title={t('app.component.ePaymentDialog.header', { type: typeI18n })}
      enablePressOutsideClose={false}
      contentContainerStyle={styles.container}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.textTitle}>{t('app.component.ePaymentDialog.waitingTitle', { type: typeI18n })}</Text>
          {counter > 0
            ? <Text style={styles.textMessage}>{t('app.component.ePaymentDialog.waitingMessage', { type: typeI18n, counter })}</Text>
            : <Text style={styles.textMessage}>{t('app.component.ePaymentDialog.timeoutMessage', { type: typeI18n })}</Text>}
          <LinearProgress
            style={styles.progress}
            color={colors.lightPrimary}
            variant='indeterminate'
          />
          {counter === 0 &&
            <View style={styles.buttons}>
              <Button
                title={t('app.component.ePaymentDialog.yes', { type: typeI18n })}
                onPress={() => { manualRef.current = true }}
              />
              <Button
                title={t('app.component.ePaymentDialog.no')}
                onPress={() => { cancelRef.current = true }}
                backgroundColor={colors.light}
                textColor={colors.textTertiary}
              />
            </View>}
        </View>
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 560,
    height: 175,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    alignItems: 'center',
    marginTop: 25,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  textTitle: {
    margin: 4,
    fontSize: 30,
    fontWeight: 'bold',
  },
  textMessage: {
    margin: 6,
    fontSize: 16,
  },
  progress: {
    width: '90%',
    margin: 6,
  },
})
