import { Platform } from 'react-native'
import { produce } from 'immer'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import _ from 'lodash'

import { PrintReason } from '@/constants/printing'
import { PrinterType } from '@/constants/printer'
import { actions } from '@/redux'
import { dimorderApi, dimorderLib } from '@/libs/api/dimorder'
import { loadingKey } from '@/constants'
import CustomizedKitchenBatch from '@/libs/printing/PrintDoc/CustomizedKitchenBatch'
import CustomizedOrderReceipt from '@/libs/printing/PrintDoc/CustomizedOrderReceipt'
import KitchenBatch from '@/libs/printing/PrintDoc/KitchenBatch'
import Label from '@/libs/printing/PrintDoc/Label'
import OrderReceipt from '@/libs/printing/PrintDoc/OrderReceipt'
import QRCode from '@/libs/printing/PrintDoc/QRCode'
import Report from '@/libs/printing/PrintDoc/Report'
import firebase from '@/libs/firebase'
import i18n from '@/i18n'
import imageToBitData from '@/libs/imageToBitData'
import logger from '@/libs/logger'
import printerService from '@/libs/printing/PrinterService'

import ActionTypes from './ActionTypes'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppBatchItemSummary, IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IMenuOptionGroup, IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

const deviceId = Constants.installationId

/**
 * 將 PrinterSetting 存到 printer.printerSetting 並設定給 PrinterService、備份到 AsyncStorage
 * @param {IPrinterSetting} updatedPrinterSetting
 * @returns
 */
export function updateAppPrinterSetting (updatedPrinterSetting) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_PRINTER_SETTING,
      payload: { printerSetting: updatedPrinterSetting },
    })

    // UPDATE_PRINTER_SETTING 會將 printerSetting merge
    // `{ ...draft.printerSetting, ...printerSetting }` 所以要用 getState 拿合併過的
    const printerSetting = getState().printer.printerSetting
    printerService.updatePrinterSetting(printerSetting)
    dispatch(storePrinterSetting())
  }
}

function storePrinterSetting () {
  return async (dispatch, getState) => {
    if (Platform.OS === 'web') return
    const printerSetting = getState().printer.printerSetting
    AsyncStorage.setItem('printerSetting', JSON.stringify(printerSetting))
  }
}

/**
 *
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    const disablePrinterInit = getState().app.settings.debugSettings.disablePrinterInit
    if (disablePrinterInit) return

    try {
      await dispatch(getPrinterSetting())
      dispatch(storePrinterSetting())
      dispatch({ type: ActionTypes.INIT })
    } catch (error) {
      logger.log(`printer.init error ${error?.message ?? error.toString()}`, { error })
    }
  }
}

/**
 *
 * @param {IPrinterSetting} printerSetting
 * @returns {ThunkFunction}
 */
export function restorePrinterSetting (printerSetting) {
  return (dispatch, getState) => {
    const disablePrinterInit = getState().app.settings.debugSettings.disablePrinterInit
    if (disablePrinterInit) return

    dispatch({
      type: ActionTypes.RESTORE_PRINTER_SETTING,
      payload: { printerSetting },
    })
    printerService.updatePrinterSetting(printerSetting)
  }
}
/**
 * @param {boolean} isLoading
 */
export function updateLoading (isLoading) {
  return {
    type: ActionTypes.UPDATE_LOADING,
    payload: { isLoading },
  }
}

export function connectPrinter (printer) {
  return async (dispatch, getState) => {
    return printerService.testConnect(printer.id)
  }
}

export function disconnectPrinter (id) {
  return async (dispatch, getState) => {
    return printerService.disconnectPrinter(id)
  }
}
/**
 * @returns {ThunkFunction}
 */
export function getPrinterSetting () {
  return async (dispatch, getState) => {
    const printerSetting = await dimorderApi.merchantPrinter.getPrinterSetting()
    const hasPrinterSetting = !_.isEmpty(printerSetting)
    if (hasPrinterSetting) {
      dispatch(updateAppPrinterSetting(printerSetting))
    }
  }
}

/**
 * 新增/編輯 printer
 * @param {IPrinter} updatedPrinter
 * @returns {ThunkFunction}
 */
export function upsertPrinter (updatedPrinter) {
  return async (dispatch, getState) => {
    const printerSetting = getState().printer.printerSetting
    const oldId = updatedPrinter.index != null ? printerSetting.printers[updatedPrinter.index]?.id : null

    const newPrinterSetting = produce(printerSetting, (draft) => {
      if (!draft.printers) {
        draft.printers = []
      }
      if (updatedPrinter.index != null) {
        // update printer ip or name
        draft.printers[updatedPrinter.index] = {
          id: updatedPrinter.id,
          name: updatedPrinter.name,
          printerType: updatedPrinter.printerType,
        }
      } else {
        // create new printer
        draft.printers.push({
          id: updatedPrinter.id,
          name: updatedPrinter.name,
          printerType: updatedPrinter.printerType,
        })
      }
      draft.kitchenReceiptSettings.map((kitchenReceiptSetting, kitchenReceiptSettingIndex) => {
        const index = kitchenReceiptSetting.printer.findIndex(p => p === oldId)
        if (index >= 0) {
          draft.kitchenReceiptSettings[kitchenReceiptSettingIndex].printer[index] = updatedPrinter.id
        }
      })
      draft.invoiceSettings.map((invoiceSetting, invoiceSettingIndex) => {
        const index = invoiceSetting.printer.findIndex(p => p === oldId)
        if (index >= 0) {
          draft.invoiceSettings[invoiceSettingIndex].printer[index] = updatedPrinter.id
        }
      })
      draft.labelSettings.map((labelSetting, labelSettingIndex) => {
        const index = labelSetting.printer.findIndex(p => p === oldId)
        if (index >= 0) {
          draft.labelSettings[labelSettingIndex].printer[index] = updatedPrinter.id
        }
      })
      // set default / qrcode / report or update ip
      if (updatedPrinter.isDefaultPrinter || draft.defaultPrinter === oldId) {
        draft.defaultPrinter = updatedPrinter.id
      }
      if (updatedPrinter.isQRCodePrinter || draft.qrcodePrinter === oldId) {
        draft.qrcodePrinter = updatedPrinter.id
      }
      if (updatedPrinter.isReportPrinter || draft.reportPrinter === oldId) {
        draft.reportPrinter = updatedPrinter.id
      }
    })

    dispatch(updatePrinterSetting({ printerSetting: newPrinterSetting, showSuccessMessage: true }))
  }
}

/**
 * 刪除 printer
 * @param {number} index
 * @returns {ThunkFunction}
 */
export function deletePrinter (index) {
  return async (dispatch, getState) => {
    const printerSetting = getState().printer.printerSetting
    const printers = printerSetting.printers
    const deletePrinterId = printers[index]?.id
    const newPrinterSetting = produce(printerSetting, draft => {
      // 將 deletePrinterId 從 printers 中移除
      draft.printers.splice(index, 1)

      // 將 deletePrinterId 從每一個 kitchenReceiptSetting 的 printer 中移除
      _.forEach(
        draft.kitchenReceiptSettings,
        kitchenReceiptSetting => _.remove(kitchenReceiptSetting.printer, printerId => printerId === deletePrinterId),
      )

      // 將 deletePrinterId 從每一個 invoiceSetting 的 printer 中移除
      _.forEach(
        draft.invoiceSettings,
        invoiceSetting => _.remove(invoiceSetting.printer, printerId => printerId === deletePrinterId),
      )

      // 將 deletePrinterId 從 prioritizedPrinters[deviceId] 中移除
      if (_.has(draft.prioritizedPrinters, deviceId)) {
        draft.prioritizedPrinters[deviceId] = draft.prioritizedPrinters[deviceId].filter(printerId => printerId !== deletePrinterId)
      }

      // 將 deletePrinterId 從 priority 中移除，並重新排序
      const deletePrinterPriority = draft.priority[deletePrinterId]
      _.forEach(draft.priority, (priority, printerId) => {
        if (printerId === deletePrinterId) {
          delete (draft.priority[printerId])
        } else if (deletePrinterPriority < priority) {
          draft.priority[printerId] = priority - 1
        }
      })

      if (draft.defaultPrinter === deletePrinterId) {
        // 從剩下的 printers 中找出替代的 defaultPrinter，若沒找到則設為 null
        // TDP225 為標籤機不可設為 defaultPrinter
        const newDefaultPrinterId = draft.printers.find(printer => printer.printerType !== PrinterType.TDP225)?.id
        draft.defaultPrinter = newDefaultPrinterId || null
      }

      if (draft.reportPrinter === deletePrinterId) {
        draft.reportPrinter = null
      }

      if (draft.qrcodePrinter === deletePrinterId) {
        draft.qrcodePrinter = null
      }
    })

    dispatch(updatePrinterSetting({ printerSetting: newPrinterSetting, showSuccessMessage: true }))
    firebase.deletePrinter(deletePrinterId)
  }
}

/**
 * 覆寫 printer setting
 * @typedef UpdatePrinterSettingParams
 * @property {string | undefined} path // 用於更新特定的 setting
 * @property {*} value // 用於更新特定的 setting
 * @property {IPrinterSetting | undefined} printerSetting // 用於更新整個 printerSetting
 * @property {boolean | undefined} showSuccessMessage // 更新成功後是否顯示『儲存成功』提示
 *
 * @param {UpdatePrinterSettingParams} params
 * @returns {ThunkFunction}
 */
export function updatePrinterSetting (params) {
  const { printerSetting, path, value, showSuccessMessage = false } = params

  return async (dispatch, getState) => {
    const isMerchantLogin = getState().auth.isMerchantLogin
    const printerSettingState = getState().printer.printerSetting

    // 整個 printerSetting 蓋掉或是只更新特定的 setting
    const newPrinterSetting = printerSetting ||
    produce(printerSettingState, printerSetting => {
      _.set(printerSetting, path, value)
    })

    dispatch(updateLoading(true))
    if (isMerchantLogin) {
      try {
        const data = await dimorderApi.merchantPrinter.updatePrinterSetting(newPrinterSetting)
        dispatch(updateAppPrinterSetting(data))

        if (showSuccessMessage) {
          dispatch(actions.app.showAlert({ message: i18n.t('app.alert.saveSuccess') }))
        }
      } catch (error) {
        console.log('updatePrinterSetting error', error)
      }
    }
    dispatch(updateLoading(false))
  }
}

/**
 * 列印測試
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function printTest (id) {
  return async (dispatch, getState) => {
    return printerService.printTest(id)
      .then(({ success, error }) => {
        console.log('[printTest] success', success)
        // TODO: how to display error?
        if (!success) {
          console.error('[printTest] error', error)
          if (Platform.OS === 'web') {
            dispatch(actions.app.showSimpleAlert(i18n.t('app.common.error'), i18n.t('app.page.setting.printer.errorMsg3')))
          } else {
            dispatch(actions.app.enqueueSnackbar({
              text: i18n.t('app.page.setting.printer.errorMsg3'),
              duration: 5000,
            }))
          }
        }
      })
  }
}

/**
 * 設定圖片
 * @deprecated 不再使用 setLogo
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function setLogo (id) {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.PRINT, 'setLogo'))
      return printerService.setLogo(id)
        .then(({ success, error }) => {
          console.log('[setLogo] success', success)
          if (!success) {
            console.error('[setLogo] error', error)
            if (Platform.OS === 'web') {
              dispatch(actions.app.showSimpleAlert(i18n.t('app.common.error'), i18n.t('app.page.setting.printer.errorMsg3')))
            } else {
              dispatch(actions.app.enqueueSnackbar({
                text: i18n.t('app.page.setting.printer.errorMsg3'),
                duration: 5000,
              }))
            }
          }
        })
    } catch (error) {
      console.log('setLogo error', error)
      dispatch(actions.app.closeLoading(loadingKey.PRINT, 'setLogo'))
    }
  }
}

/**
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function drawerKick () {
  return (dispatch, getState) => {
    return printerService.drawerKick()
      .then(result => {
        // TODO: how to display error?
        result.some(r => {
          if (!r.success) {
            console.error('[drawerKick] error', r.error)
            if (Platform.OS === 'web') {
              dispatch(actions.app.showSimpleAlert(i18n.t('app.common.error'), i18n.t('app.page.setting.printer.errorMsg3')))
            } else {
              dispatch(actions.app.enqueueSnackbar({
                text: i18n.t('app.page.setting.printer.errorMsg3'),
                duration: 5000,
              }))
            }
            return true
          }
        })
      })
  }
}

export function updatePrinterStatus (id, status) {
  return async (dispatch, getState) => {
    if (Platform.OS === 'web') { return }
    status = { id, ...status }
    const printer = getState().printer.printerSetting.printers.find(
      (printer) => printer.id === id,
    )
    const c = printerService.getPrinterClient(id)
    try {
      firebase.updatePrinter(id, {
        ...status,
        ...printer,
        queueLength: c.q.length(),
      })
    } catch (error) {
      console.error('[firebase] updatePrinter error', error)
    }
    dispatch({
      type: ActionTypes.UPDATE_PRINTER_STATUS,
      payload: { id, status },
    })
  }
}

export function updatePrinters (printerStatus) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_PRINTERS,
      payload: { printerStatus },
    })
  }
}

// TODO: STATUS
// TODO: add button to print QRCode

/**
 * 列印 QRCode
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function printQRCode (order, setting, selectedPrinterId = '', printReason) {
  return async (dispatch, getState) => {
    try {
      const merchant = getState().merchant.data
      const printerSetting = getState().printer.printerSetting
      const prioritizedPrinters = _.get(printerSetting.prioritizedPrinters, deviceId, [])
      const printerStatus = getState().printer.printerStatus
      const printers = Object.keys(printerStatus)
      let customerAppUrls = getState().config.clientUrls

      if (!customerAppUrls) {
        await dispatch(actions.config.init())
        customerAppUrls = getState().config.clientUrls
      }

      let params = `?m=${merchant.id}&o=${order.id}`
      if (setting.defaultClientLanguage) {
        params += `&l=${setting.defaultClientLanguage}`
      }
      let categoryTagName

      if (merchant.setting.enableCategoryTag) {
        const tags = getState().menu.categoryTags
        const categoryTag = tags.find(o => o.id === order.categoryTag)
        if (categoryTag) {
          params += `&pkg=${categoryTag.categoryIds.map(o => o.slice(0, 8)).join(',')}`
          params += `&cot=${categoryTag.orderCutOffMins}`
          categoryTagName = categoryTag.name
        }
      }
      params += '&isD2CWeb=1'
      const oldWebUrl = `${customerAppUrls.customerWeb}${params}`
      const newWebUrl = `${customerAppUrls.customerAppWeb}${params}`
      const newAppUrl = `${customerAppUrls.customerApp}${params}`

      console.log('QRCode Url', {
        oldWebUrl,
        newWebUrl,
        newAppUrl,
      })

      // 使用新版 Web
      const url = merchant?.setting?.useCustomerWebV2 ? newAppUrl : oldWebUrl
      const shortUrl = await dimorderApi.shortUrl(url)
      logger.log('[printQRCode] QRCode Short Url', { shortUrl })
      const qrcodePrinterId = printerSetting?.qrcodePrinter || printerSetting?.defaultPrinter
      if ((!qrcodePrinterId || !printers.includes(qrcodePrinterId)) && !prioritizedPrinters?.length) {
        dispatch(actions.app.closeLoading(loadingKey.PRINT))
        return
      }

      let qrcodePrinters = [qrcodePrinterId]
      if (prioritizedPrinters.length) {
        qrcodePrinters = prioritizedPrinters
      }
      if (selectedPrinterId) {
        qrcodePrinters = [selectedPrinterId]
      }

      return Promise.all(qrcodePrinters.map(id => {
        return printerService.print(
          id,
          new QRCode(shortUrl, merchant, order, categoryTagName, printReason),
        )
      }))
    } catch (error) {
      console.log('printQRCode error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.PRINT))
    }
  }
}

/**
 * 根據 order 和 summarySettings 取得 summary items
 * 根據 locale 取得 localizedSummary
 * @param {IAppOrder} order
 * @param {'zh' | 'en'?} locale - 可以透過傳入 locale 印出指定的語言（預設為 app 介面語言）
 * @returns {ThunkFunction}
 */
function getLocalizedReceiptSummaryItems (order, locale) {
  return (dispatch, getState) => {
    const merchant = getState().merchant.data
    const printerSetting = getState().printer.printerSetting
    const appLocale = getState().app.settings.lang.slice(0, 2) // 'zh' | 'en'
    const targetLocale = locale ?? appLocale

    const { sortReceiptItemByCategory } = merchant.setting
    const { receiptGroupBy } = printerSetting

    const summarySettings = { sortReceiptItemByCategory, receiptGroupBy }

    /** @type {IAppBatchItemSummary[]} */
    const summaryItems = dimorderLib.formatSummary(order, summarySettings)

    return dispatch(getLocalizedItems(summaryItems, targetLocale))
  }
}

/**
 * 尋找每個 option groups 中的 options 有沒有指定 optionId 的選項，有的話回傳 optionGroup 和 menuOption，沒有的話會 undeifned
 * @param {IMenuOptionGroup[]} optionGroups
 * @param {string} optionId
 * @return {[IMenuOptionItem, IMenuOptionItem] | undefined}
 */
function findOptionInGroups (optionGroups, optionId) {
  let menuOption // 如果有找到 menuOption 就存起來，之後就不用從 optionGroup 中 find 一次
  const menuOptionGroup = _.find(optionGroups, optionGroup => {
    // 在每個 option group 中 options 尋找指定 optionId 的 menuOption
    const menuOptionInGroup = optionGroup.options.find(option => option.id === optionId)
    if (menuOptionInGroup) {
      menuOption = menuOptionInGroup
      return true
    }
    return false
  })
  if (menuOptionGroup && menuOption) {
    return [menuOptionGroup, menuOption]
  }
}

/**
 * 根據 locale 取得 LocalizedItems
 * @param {IAppBatchItem | IAppBatchItemSummary} batchItem
 * @param {'zh' | 'en'?} locale - 可以透過傳入 locale 印出指定的語言（預設為 app 介面語言）
 * @returns {ThunkFunction}
 */
function getLocalizedItem (batchItem, locale) {
  return (dispatch, getState) => {
    const appLocale = getState().app.settings.lang.slice(0, 2) // 'zh' | 'en'
    const targetLocale = locale ?? appLocale
    const sets = getState().menu.sets
    const menus = getState().menu.menus
    const rewards = getState().menu.rewards
    const menu = sets[batchItem.menuId] ?? menus[batchItem.menuId] // 若 batchItem 為套餐會在 sets 中找到，否則會在 menus 中找到

    const localeName = batchItem.rewardId
      // batchItem 是 reward 的話，要從 reward 中找出對應的 name，找不到就用 batchItem.name
      ? (_
        .chain(rewards)
        .find(r => r.id === batchItem.rewardId)
        .get('locales')
        .find(l => l.locale === targetLocale)
        .get('name', batchItem.name)
        .value())
        ?.trim()
      // batchItem 是 一般 menu 的話，要從 menu 中找出對應的 name
      : _.get(menu?.localeNames, targetLocale, menu?.name || batchItem.name)?.trim()

    // 翻譯選項
    const localeOptions = _.map(batchItem.options, itemOption => {
      // 因為選項可能來自通用的選項 (presets) 或針對餐點設定的選項，所以要從兩個地方找出來
      // 從 menu.options 或 menu.optionGroupPresets 找出 batchItem 選項的 optionGroup
      const [menuOptionGroup, menuOption] = findOptionInGroups(menu?.options, itemOption.optionItemId) ?? findOptionInGroups(menu?.optionGroupPresets, itemOption.optionItemId) ?? []
      const localeOptionGroupName = _.get(menuOptionGroup?.localeNames, targetLocale, itemOption.optionName)?.trim()
      const localeOptionName = _.get(menuOption?.localeNames, targetLocale, itemOption.name)?.trim()
      return { ...itemOption, optionName: localeOptionGroupName, name: localeOptionName }
    })

    // 避免POS抓不到菜單，出現undefined 的name，所以使用下單原有的name
    const localizedItem = { ...batchItem, name: localeName ?? batchItem.name, options: localeOptions }

    if (batchItem.setItems) {
      // 處理套餐中的餐點
      localizedItem.setItems = _.map(batchItem.setItems, setItem => {
        return dispatch(getLocalizedItem(setItem, targetLocale))
      })
    }

    if (batchItem.items) {
      // summary 的 setItems 被改叫 items
      localizedItem.items = _.map(batchItem.items, setItem => {
        return dispatch(getLocalizedItem(setItem, targetLocale))
      })
    }

    return localizedItem
  }
}

/**
 * 根據 locale 取得 LocalizedItems
 * @param {IAppBatchItem[] | IAppBatchItemSummary[]} batchItems
 * @param {'zh' | 'en'?} locale - 可以透過傳入 locale 印出指定的語言（預設為 app 介面語言）
 * @returns {ThunkFunction}
 */
function getLocalizedItems (batchItems, locale) {
  return (dispatch, getState) => {
    const appLocale = getState().app.settings.lang.slice(0, 2) // 'zh' | 'en'
    const targetLocale = locale ?? appLocale

    return _.map(batchItems, (batchItem) => {
      return dispatch(getLocalizedItem(batchItem, targetLocale))
    })
  }
}

/**
 * 列印廚房單 (batch)
 * @param {IAppOrder} order
 * @param {IAppBatchItem[]} items
 * @param {IPrintConfig?} printConfig
 * @param {IAppOrderBatch} batch
 * @param {object} quantity // ? object 的內容是什麼？
 *
 * @typedef IPrintConfig
 * @property {boolean?} reprint
 * @property {boolean?} cancel
 * @property {boolean?} serveUp
 * @property {boolean?} printStaff
 * @property {boolean?} change
 * @property {ITransferTableInfo?} transfer
 *
 * @typedef ITransferTableInfo // ? 應該不只這些，還有什麼東西？
 * @property {{ table: string }} from
 * @property {{ table: string }} to
 * @returns {ThunkFunction}
 */
export function printKitchenBatch (
  order,
  items,
  printConfig = {
    reprint: false,
    cancel: false,
    serveUp: false,
    printStaff: false,
    change: false,
    transfer: null,
  },
  isAutoConfirm,
  batch,
  quantity,
) {
  return async (dispatch, getState) => {
    try {
      const printerSetting = getState().printer.printerSetting
      const { orderSerialDigit = 3 } = printerSetting.defaultSettings
      const labelSettings = printerSetting?.labelSettings ?? []
      let kitchenReceiptSettings = printerSetting?.kitchenReceiptSettings ?? []
      kitchenReceiptSettings = kitchenReceiptSettings.concat(labelSettings)
      const merchant = getState().merchant.data
      const rewards = getState().menu.rewards
      const lang = ['zh', 'en', 'thai', 'korean', 'kitchen']
      const { sorting } = getState().merchant
      const printHoldItem = !(printConfig.reprint || printConfig.cancel || printConfig.serveUp)
      const printTable = printConfig.transfer
        ? [_.get(printConfig, 'transfer.from.table'), _.get(printConfig, 'transfer.to.table')]
        : [order.table]

      if (isAutoConfirm) { logger.log('[printKitchenBatch] isAutoConfirm') }
      const menuPrinter = _.flatMap(kitchenReceiptSettings, 'menu')

      if (order.takeaway) {
        kitchenReceiptSettings = kitchenReceiptSettings.filter(setting => _.get(setting, 'takeaway', true))
      }

      if (printerSetting.defaultPrinter && _.find(printerSetting.printers, printer => printer.id === printerSetting.defaultPrinter)) {
        // 找出預設打印機需要印的category/table
        const setItems = _.uniq(_.flatMap(_.filter(items, item => item.isSet), 'setItems'))
        const itemsCategoryId = _.uniq(_.flatMap(_.concat(items, setItems), 'categoryId'))
        const printersCategoryId = _.uniq(_.flatMap(kitchenReceiptSettings, 'category'))
        const tables = getState().table.tables
        const allTable = _.uniq(_.flatMap(tables, 'key'))
        const printerstable = _.uniq(_.flatMap(kitchenReceiptSettings, 'table'))

        const defaultPrinterCategory = _.difference(itemsCategoryId, printersCategoryId)
        // filter all rewardItem to avoid setting default printer one more time
        const defaultPrinterCategoryWithoutReward = defaultPrinterCategory.filter(category => category !== '')

        const defaultPrinterTable = allTable.filter(x => !printerstable.includes(x))
        const takeaway = _.flatMap(kitchenReceiptSettings, setting => _.get(setting, 'takeaway', true)).every(t => !t)

        if ((!_.isEmpty(defaultPrinterTable) && !order.takeaway) || !_.isEmpty(defaultPrinterCategoryWithoutReward) || (takeaway && order.takeaway)) {
          const tempDefaultPrinterSetting = {
            printer: [printerSetting.defaultPrinter],
            name: 'defaultPrinter',
            category: ((!_.isEmpty(defaultPrinterTable) && (!_.isEmpty(defaultPrinterCategoryWithoutReward))) || !_.isEmpty(defaultPrinterCategoryWithoutReward)
              ? defaultPrinterCategoryWithoutReward
              : itemsCategoryId),
            ...printerSetting.defaultSettings,
            table: ((_.isEmpty(defaultPrinterTable) || !_.isEmpty(defaultPrinterCategoryWithoutReward)) ? allTable : defaultPrinterTable),
            batchLocale: merchant.batchLocale,
            takeaway: takeaway,
          }
          kitchenReceiptSettings = Object.assign([], kitchenReceiptSettings)
          kitchenReceiptSettings.push(tempDefaultPrinterSetting)
        }
      }

      const printers = _.uniq(_.flatMap(kitchenReceiptSettings, 'printer'))
      if (!printers || !printers.length) {
        return
      }

      const isSettingsEmpty = []
      return Promise.all(kitchenReceiptSettings.map(async (kitchenReceiptSetting, i) => {
        if (!kitchenReceiptSetting?.printCancelledItem && printConfig.cancel) {
          return
        }

        if (!kitchenReceiptSetting.printTransferItem && printConfig.transfer) {
          return
        }

        let newBatchItems = items // 如果沒有跑翻譯，fallback 原本的 items
        const batchLocale = kitchenReceiptSetting.batchLocale || merchant.batchLocale
        if (lang.includes(batchLocale)) {
          newBatchItems = dispatch(getLocalizedItems(items, batchLocale))
        }

        // 訂單枱號是否為 setting 指定的 table
        const allowTable = printTable.some(t => kitchenReceiptSetting.table.includes(t))
        if (!allowTable && !order.takeaway) {
          return
        }

        let filteredItems = newBatchItems.filter((item) => {
          if (item.rewardId) {
            const reward = rewards.find(r => r.id === item.rewardId)

            // 如果 reward 沒有設定 kitchenReceiptSettingId 就用default kitchenReceiptSetting 印
            // 如果 reward 設定了 kitchenReceiptSettingId 與 目前的kitchenReceiptSetting.id 不一樣就不印贈品
            return (reward.merchants[merchant.id]?.kitchenReceiptSettingId === null || reward.merchants[merchant.id]?.kitchenReceiptSettingId === kitchenReceiptSetting?.id)
          }

          const avoidPrinting = item.tags.findIndex(o => o.id === 'avoidPrinting') > -1
          if (avoidPrinting) {
            return false
          }

          const isHoldItem = item.tags.findIndex(o => o.id === 'serve-later') > -1
          if (printHoldItem && !_.get(kitchenReceiptSetting, 'printHoldItem', true) && isHoldItem) {
            return false
          }
          if (!_.isEmpty(kitchenReceiptSetting.menu)) {
            // 檢查 item 是否在 menus
            const allowItem = kitchenReceiptSetting.menu.includes(item.menuId)

            // 如果是套餐，檢查底下的餐點是否在 menus
            const returnItem = item.setItems.some(setItem => {
              const avoidPrinting = setItem.tags.findIndex(o => o.id === 'avoidPrinting') > -1
              const isHoldSetItem = setItem.tags.findIndex(o => o.id === 'serve-later') > -1
              return (
                kitchenReceiptSetting.menu.includes(setItem.menuId) &&
                !(printHoldItem && !_.get(kitchenReceiptSetting, 'printHoldItem', true) && isHoldSetItem) &&
                !avoidPrinting
              )
            })
            if (allowItem || returnItem) {
              return true
            }
          }

          if (menuPrinter.includes(item.menuId)) {
            return false
          }

          if (!item.isSet) {
            if (!kitchenReceiptSetting.category.includes(item.categoryId)) {
              // category 不在範圍內
              return false
            }
          } else {
            const returnItem = item.setItems.some(setItem => {
              const avoidPrinting = setItem.tags.findIndex(o => o.id === 'avoidPrinting') > -1
              const isHoldSetItem = setItem.tags.findIndex(o => o.id === 'serve-later') > -1
              return (
                kitchenReceiptSetting.category.includes(setItem.categoryId) &&
                !(printHoldItem && !_.get(kitchenReceiptSetting, 'printHoldItem', true) && isHoldSetItem) &&
                !avoidPrinting
              )
            })
            return returnItem
          }

          return true
        })
        filteredItems = filteredItems.map(item => {
          if (!item.isSet) {
            return item
          } else {
            const newSetItems = item.setItems.filter(s => {
              const isHoldSetItem = s.tags.findIndex(o => o.id === 'serve-later') > -1
              const avoidPrinting = s.tags.findIndex(o => o.id === 'avoidPrinting') > -1
              return (
                ((
                  kitchenReceiptSetting.category.includes(s.categoryId) &&
                  !menuPrinter.includes(s.menuId)
                ) ||
                kitchenReceiptSetting?.menu?.includes(s.menuId)) &&
                !(printHoldItem && !_.get(kitchenReceiptSetting, 'printHoldItem', true) && isHoldSetItem) &&
                !avoidPrinting
              )
            })
            return { ...item, setItems: newSetItems }
          }
        }).filter(item => !item.isSet || item.setItems.length > 0)

        if (_.isEmpty(filteredItems)) {
          return
        }
        isSettingsEmpty.push(false)
        // 每台有連線的打印機都印出 filteredItems

        return Promise.all(kitchenReceiptSetting.printer.map((id) => {
          let printDoc
          if (kitchenReceiptSetting?.isLabel) {
            const style = printerSetting.customizedLabelSettings?.find(s => s.id === kitchenReceiptSetting.customized)
            printDoc = new Label(
              filteredItems,
              merchant,
              order,
              kitchenReceiptSetting,
              { ...printConfig, orderSerialDigit },
              quantity,
              style,
            )
          } else {
            const style = printerSetting.customizedKitchenSettings?.find(s => s.id === kitchenReceiptSetting.customized)
            if (style) {
              printDoc = new CustomizedKitchenBatch(
                filteredItems,
                merchant,
                order,
                kitchenReceiptSetting,
                { ...printConfig, orderSerialDigit },
                sorting,
                batch,
                quantity,
                style,
              )
            } else {
              printDoc = new KitchenBatch(
                filteredItems,
                merchant,
                order,
                kitchenReceiptSetting,
                printConfig,
                sorting,
                batch,
                quantity,
              )
            }
          }
          return printerService.print(
            id,
            printDoc,
            isAutoConfirm,
          ).then(r => {
            if (!r.success) {
              throw r
            }
          }).catch(e => {
            if (isAutoConfirm) { logger.log(`[AutoConfirm] ${order.serial} Error`, e) }
            throw e
          })
        }))
      }))
    } catch (error) {
      console.log('printKitchenBatch error', error)
      throw error
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.PRINT))
    }
  }
}

/**
 * 列印收據 + kick drawer
 * @param {IPrintOrderReceiptParams} params
 * @returns {ThunkFunction}
 *
 * @typedef IPrintOrderReceiptParams
 * @property {IAppOrder} order
 * @property {boolean?} sync
 * @property {TPrinterId?} selectedPrinterId
 * @property {TCancelledType?} cancelledType
 * @property {TOrderReceiptPrintReason?} printReason
 * @property {IPrintConfig?} printConfig
 *
 * @typedef IPrintConfig
 * @property {boolean?} reprint
 */
export function printOrderReceipt (params) {
  const {
    order,
    sync = false,
    selectedPrinterId,
    cancelledType,
    printReason,
    printConfig = { reprint: true, locale: undefined },
    pointsClaimPassword,
  } = params

  return async (dispatch, getState) => {
    try {
      const printerSetting = getState().printer.printerSetting
      const merchant = getState().merchant.data
      let invoiceSettings = printerSetting?.invoiceSettings
      const prioritizedPrinters = _.get(printerSetting.prioritizedPrinters, deviceId, [])
      const { orderSerialDigit = 3 } = printerSetting.defaultSettings
      const clientUrls = getState().config.clientUrls
      const groupId = getState().merchant.group?.id

      /** @type {IAppBatchItemSummary[]} */
      const localizedSummaryItems = dispatch(getLocalizedReceiptSummaryItems(order, printConfig.locale))

      if (_.isEmpty(invoiceSettings)) {
        if (!printerSetting.defaultPrinter) {
          return
        }
        printerService.print(
          printerSetting.defaultPrinter,
          new OrderReceipt(
            localizedSummaryItems,
            merchant,
            order,
            printerSetting.defaultSettings,
            cancelledType,
            { ...printConfig, orderSerialDigit },
            printReason,
          ),
        )
        return
      }
      const tables = getState().table.tables
      const allTable = _.uniq(_.flatMap(tables, 'key'))
      if (printerSetting.defaultPrinter && _.find(printerSetting.printers, printer => printer.id === printerSetting.defaultPrinter)) {
      // 找出預設打印機需要印的table

        const printersTable = _.uniq(_.flatMap(invoiceSettings, 'table'))
        const takeaway = _.flatMap(invoiceSettings, setting => _.get(setting, 'takeaway', true)).every(t => !t)

        const defaultPrinterTable = allTable.filter(x => !printersTable.includes(x))
        if ((!_.isEmpty(defaultPrinterTable) && !order.takeaway) || (takeaway && order.takeaway)) {
          const tempDefaultPrinterSetting = {
            printer: [printerSetting.defaultPrinter],
            name: 'defaultPrinter',
            ...printerSetting.defaultSettings,
            table: defaultPrinterTable,
            printTimes: 0,
            takeaway: takeaway,

          }
          invoiceSettings = Object.assign([], invoiceSettings)
          invoiceSettings.push(tempDefaultPrinterSetting)
        }
      }

      const printers = _.uniq(_.flatMap(invoiceSettings, 'printer'))
      if (!printers || !printers.length) {
        return
      }

      return Promise.all(_.map(invoiceSettings, async invoiceSetting => {
        let printers = invoiceSetting.printer

        if (sync && !(
          (invoiceSetting.syncReceipt && !order.takeaway) ||
            (invoiceSetting.syncTakeawayReceipt && order.takeaway)
        )) {
          return
        }

        if (printReason === PrintReason.ORDER_RECEIPT.PAYMENT_SUBMITTED) {
          const autoPrintDineIn = _.get(invoiceSetting, 'autoPrintDineIn', true)
          const autoPrintTakeaway = _.get(invoiceSetting, 'autoPrintTakeaway', true)
          if (!((order.takeaway && autoPrintTakeaway) || (!order.takeaway && autoPrintDineIn))) {
            return
          }
        }

        if (!_.get(invoiceSetting, 'printCancelReceipt', true) &&
          cancelledType &&
          (printReason === PrintReason.ORDER_RECEIPT.CLICK_VOID_ORDER || printReason === PrintReason.ORDER_RECEIPT.CLICK_CANCEL_ORDER)) {
          return
        }

        if (printReason === PrintReason.ORDER_RECEIPT.ORDER_SUBMITTED && !invoiceSetting.syncReceiptPrioritizedPrinter) {
          // 下單時打印收據，但設定中沒有開下單時打印收據，所以不印
          return
        }

        if (!order.takeaway) {
          // 訂單枱號是否為 setting 指定的 table
          const allowTable = invoiceSetting.table.includes(order.table)
          if (!allowTable) {
            return
          }
        }

        if (_.get(invoiceSetting, 'takeaway') === false && order.takeaway) {
          return
        }

        if (prioritizedPrinters?.length) {
          printers = prioritizedPrinters
        }
        if (_.isEmpty(printers)) {
          printers = [printerSetting.defaultPrinter]
        }
        if (selectedPrinterId) {
          printers = [selectedPrinterId]
        }

        return Promise.all(_.map(printers, async id => {
          const style = printerSetting.customizedStyleSettings?.find(s => s.id === invoiceSetting.customized)
          if (style) {
            // shortUrl for print CRM QRCode
            let shortUrl = ''
            const joinMemberQRCode = style.style.find(s => s.key === 'joinMemberQRCode')

            // print crm QRCode 條件
            // 堂食訂單, 餐廳有開 enableCRM, 沒有登錄會員, 有開「打印註冊 QRCode」toggle 和 結帳後打印
            if (order.deliveryType === 'table' &&
            merchant.setting?.enableCRM &&
            !order?.usePhoneInCRM &&
            joinMemberQRCode?.enable &&
            printReason === PrintReason.ORDER_RECEIPT.PAYMENT_SUBMITTED
            ) {
              const url = `${clientUrls.customerApp}/d2c/${merchant.id}/membership/${groupId}?o=${order.id}&p=${pointsClaimPassword}`
              try {
                shortUrl = await dimorderApi.shortUrl(url)
              } catch (e) {
                shortUrl = url
                logger.log('CRM Join Member QRCode shortUrl error', e)
              }
            }
            logger.log('CRM Join Member QRCode shortUrl', shortUrl)

            return printerService.print(
              id,
              new CustomizedOrderReceipt(
                localizedSummaryItems,
                merchant,
                order,
                invoiceSetting,
                cancelledType,
                { ...printConfig, orderSerialDigit },
                style,
                printReason,
                shortUrl,
              ),
            )
          } else {
            return printerService.print(
              id,
              new OrderReceipt(
                localizedSummaryItems,
                merchant,
                order,
                invoiceSetting,
                cancelledType,
                printConfig,
                printReason,
              ),
            )
          }
        }))
      }))
    } catch (error) {
      console.log('printOrderReceipt error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.PRINT))
    }
  }
}

/**
 * 列印報表
 * @returns {ThunkFunction}
 */
export function printReport (selectedPrinterId = '') {
  return (dispatch, getState) => {
    const data = getState().statistics.data
    const printerSetting = getState().printer.printerSetting
    const merchant = getState().merchant.data
    const reportPrinterId = printerSetting?.reportPrinter || printerSetting?.defaultPrinter
    const selectedStatType = getState().statistics.selectedStatType
    const selectedSubType = getState().statistics.selectedSubType
    if (_.isEmpty(data[selectedStatType]) && selectedStatType !== 'dimorder') return

    const printerStatus = getState().printer.printerStatus
    const printers = Object.keys(printerStatus)
    const prioritizedPrinters = _.get(printerSetting.prioritizedPrinters, deviceId, [])
    const printDoc = new Report(data, selectedStatType, selectedSubType, merchant)

    let reportPrinters = []
    if (printers.includes(reportPrinterId)) {
      reportPrinters = [reportPrinterId]
    }
    if (prioritizedPrinters?.length) {
      reportPrinters = prioritizedPrinters
    }
    if (selectedPrinterId) {
      reportPrinters = [selectedPrinterId]
    }
    if (!reportPrinters.length) {
      dispatch(actions.app.showSimpleAlert('錯誤', '沒有設定報表打印機'))
      return
    }
    return Promise.all(reportPrinters.map(id => {
      return printerService.print(
        id,
        printDoc,
      )
    }))
  }
}

export function setPrioritizedPrinter (prioritizedPrinters) {
  return (dispatch, getState) => {
    const printerSetting = getState().printer.printerSetting
    const value = { ..._.get(printerSetting, 'prioritizedPrinters', {}), [deviceId]: prioritizedPrinters }
    dispatch(actions.printer.updatePrinterSetting({ path: 'prioritizedPrinters', value }))
  }
}

/**
 *
 * @param {number} [threshold]
 * @returns {ThunkFunction}
 */
export function getMerchantLogo (threshold) {
  return async (dispatch, getState) => {
    const logo = getState().app.settings?.logo
    const merchant = getState().merchant.data
    const url = _.get(merchant, 'images.cover', '')
    const id = _.get(merchant, 'id', '') + url
    const imageThreshold = threshold ?? getState().printer.printerSetting?.logo?.threshold
    if (url && (_.get(logo, 'id') !== id || threshold)) {
      try {
        const data = await imageToBitData(url, 128, imageThreshold, 'ESC *')
        const quadrupleData = await imageToBitData(url, 256, imageThreshold, 'ESC *')
        dispatch(actions.app.updateSetting(['logo'], { id: id, data, quadrupleData }))
      } catch (error) {
        logger.error('getMerchantLogo error', error)
      }
    }
  }
}
