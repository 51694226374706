import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import SummaryRow from './SummaryRow'

export default function OptionInfo (props) {
  const optionData = useSelector(state => state.statistics.data.option?.data) ?? []
  const { t } = useTranslation()

  const sortedData = React.useMemo(() => {
    return _.orderBy(optionData, ['totalSales', 'title'], ['desc', 'asc'])
  }, [optionData])

  const renderItem = ({ item }) => {
    return (
      <SummaryRow
        title={item.title}
        count={item.count}
        amount={item.totalSales}
      />
    )
  }

  if (_.isEmpty(optionData)) {
    return null
  }

  return (
    <View style={styles.container}>
      <FlatList
        data={sortedData}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <View style={{ height: 25, marginTop: 30 }}>
            <Text style={styles.title}>{t('app.page.stats.option.item')}</Text>
            <View style={styles.divider} />
          </View>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})
