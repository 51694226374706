import paymentConfigs from '@/configs/paymentConfigs'
import store from '@/redux/store'

export function getPaymentMethod (key) {
  /** @type {() => IRootState} */
  const getState = store.getState

  const merchantPayments = getState().merchant.data.payments

  const ecrProvider = paymentConfigs.ecrProviderMaps[key]
  if (ecrProvider) return ecrProvider

  const paymentMethod = paymentConfigs.paymentMethodMaps[key]
  if (paymentMethod) return paymentMethod

  const merchantPayment = merchantPayments.find(merchantPayment => merchantPayment?.key === key)

  return {
    tips: merchantPayment?.tips,
    key: merchantPayment?.key ?? key,
    name: merchantPayment?.name ?? key,
    custom: true,
    iconSource: paymentConfigs.otherMethod.iconSource,
    useSmallIcon: true,
  }
}
