import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import TableRow from '@/components/OrderTable/TableRow'
import colors from '@/theme/colors'
import getFormatBatchItemStatus from '@/libs/getFormatBatchItemStatus'
import i18n from '@/i18n'

import SetItems from './SetItems'
import tableConfig from './tableConfig'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IOrderSummary } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

const pauseIcon = require('@icons/orders/pause.png')

/**
 * @typedef OrderItemProps
 * @property {IAppOrderBatch} batch
 * @property {number} serial
 * @property {boolean} isSelected
 * @property {boolean} isSetItemsExpaned
 * @property {IAppBatchItem} item
 * @property {() => void} onPress
 * @property {IOrderSummary[]} summary
 */

/**
 * @param {OrderItemProps} props
 */
export default function OrderItem (props) {
  const {
    batch,
    serial,
    isSelected,
    isSetItemsExpaned,
    item,
    onPress,
    summary,
  } = props

  const { t } = useTranslation()
  const expandSetItems = item.isSet && isSetItemsExpaned
  const itemInSummary = summary?.find(s => s?.itemId === item.menuId)
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)
  const allSets = useSelector(state => state.menu.sets)

  const localeName = React.useMemo(() => {
    const menu = item.isSet
      ? allSets[item.menuId]
      : allMenus[item.menuId]
    return _.get(menu?.localeNames, lang, item.name)
  }, [item.isSet, item.menuId, item.name, allSets, allMenus, lang])

  const displayItem = React.useMemo(() => {
    const displayItem = { ...item }

    // locale name
    displayItem.name = localeName

    // summary price 不知道在幹麻?
    if (itemInSummary) {
      displayItem.total = itemInSummary?.price
    }

    // 顯示時價
    if (item.priceUndetermined) {
      displayItem.price = t('app.constant.order.priceUndetermined')
      displayItem.total = t('app.constant.order.priceUndetermined')
    }

    // format status
    displayItem.status = getFormatBatchItemStatus(item)

    // 標示不收服務費
    if (item.excludedOrderSurcharge) {
      const excludedSurchargeText = t('app.constant.order.excludedSurcharge')
      displayItem.status = item.status ? `${item.status} (${excludedSurchargeText})` : `(${excludedSurchargeText})`
    }

    return displayItem
  }, [item, itemInSummary, batch.id, localeName])

  return (
    <>
      <TouchableOpacity
        onPress={() => onPress({
          ...item,
          batchId: batch.id, // 當 item 被選擇後有可能會用來列印廚房單（追單等功能），那時候需要知道 item 在哪個 batch，因此放入 batchId
        })}
        activeOpacity={1}
      >
        <TableRow
          item={displayItem}
          fields={tableConfig.fields}
          widths={tableConfig.widths}
          justifyContents={tableConfig.justifyContents}
          style={[styles.row, isSelected && styles.selectedRow]}
          textStyle={[styles.text, isSelected && styles.selectedText]}
          renderLeftAction={() => {
            const isServeLater = item.tags.find(tag => tag.id === 'serve-later')
            const isServeUp = item.tags.find(tag => tag.id === 'serve-up')
            return (
              <View style={styles.serialColumn}>
                {isServeLater && !isServeUp && (
                  <Image
                    style={styles.pauseIcon}
                    source={pauseIcon}
                  />
                )}
                <Text style={styles.text}>{serial}</Text>
              </View>
            )
          }}
        />
      </TouchableOpacity>
      {expandSetItems &&
        <SetItems
          serial={serial}
          item={{
            ...item,
            batchId: batch.id, // 當 item 被選擇後有可能會用來列印廚房單（追單等功能），那時候需要知道 item 在哪個 batch，因此放入 batchId
          }}
        />}

    </>
  )
}

const styles = StyleSheet.create({
  row: {
    backgroundColor: colors.lightBlue1,
    minHeight: 30,
  },
  pauseIcon: {
    width: 20,
    height: 20,
  },
  serialColumn: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: 6,
    flex: tableConfig.widths[0],
  },
  text: {
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
  selectedRow: {
    backgroundColor: colors.tertiary,
  },
  selectedText: {
    color: colors.textPrimary,
  },
})
