import { StyleSheet } from 'react-native'
import OrderResultItem from './OrderResultItem'
import React from 'react'
import Row from '@/components/Row'

export default function OrderResultList ({ orders, onPress }) {
  return (
    <Row style={styles.container}>
      {orders.length > 0 &&
        orders.map(order =>
          <OrderResultItem
            key={order.id}
            order={order}
            onPress={() => onPress(order)}
          />)}
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
  },
})
