
import { Image, StyleSheet, Text } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import CenterModal from '@/components/CenterModal'
import React from 'react'
import colors from '@/theme/colors'

const iconSources = {
  success: require('@icons/checkout/success.png'),
  fail: require('@icons/checkout/fail.png'),
}
export default function PopUpStatus ({ success }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(state => success
    ? state.app.dialog.checkout.paymentSuccess : state.app.dialog.checkout.paymentFail)

  const onClose = () => {
    dispatch(actions.app.closeDialog(['checkout', success ? 'paymentSuccess' : 'paymentFail']))
  }

  return (
    <CenterModal
      enablePressOutsideClose
      noTitle
      visible={visible}
      contentContainerStyle={{ height: 200, width: 258, backgroundColor: colors.light }}
      onClose={onClose}
    >
      <Image
        style={styles.icon}
        source={success ? iconSources.success : iconSources.fail}
      />
      <Text style={styles.text}>{`${success ? t('app.page.checkout.paymentResult.success') : t('app.page.checkout.paymentResult.fail')}`}</Text>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  icon: {
    height: 60,
    width: 60,
  },
  text: {
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: 33,
    color: colors.primary,
  },
})
