import { Image, StyleSheet, Text } from 'react-native'
import Column from '@/components/Column'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef ColumnLabel
 * @property {string} label
 * @property {JSX.Element} children
 * @property {boolean?} required
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {ColumnLabelProps} props
 * @returns
 */
export default function ColumnLabel (props) {
  const { style, label, required = false, children } = props
  return (
    <Column style={style}>
      {required &&
        <Image style={styles.asteriskIcon} source={require('@icons/table/asterisk.png')} />}

      <Text style={styles.labelText}>
        {label}
      </Text>

      {children}
    </Column>
  )
}

const styles = StyleSheet.create({
  asteriskIcon: {
    height: 20,
    width: 20,
    position: 'absolute',
    top: 0,
    left: -20,
  },
  labelText: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
    color: colors.textSecondary,
  },
})
