export default {
  center: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  left: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  right: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  bold: {
    fontWeight: 'bold',
  },
  small: {
    fontSize: 15,
    marginBottom: 3,
  },
  medium: {
    fontSize: 30,
    marginBottom: 20,
  },
  large: {
    fontSize: 38,
  },
}
