/* global __DEV__ */
import { AppState, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment'

import { AsyncStorageKey } from '@/constants/AsyncStorageKey'
import { actions } from '@/redux'
import codePush from '@/libs/codePush'
import codePushConfig from '@/configs/codePush'
import firebase from '@/libs/firebase'
import i18n from '@/i18n'
import logger, {
  logId,
  setCodePushDeployment,
  setCodePushLabel,
  setCodePushOverwriteDeployment,
} from '@/libs/logger'

import ActionTypes from './ActionTypes'

let checkUpdateTime // 下次檢查更新時間
let appStateChangeListener
let memoryWarningListener
let updateTimeout

/**
 * @returns {ThunkFunction}
 */
export function initUpdateTrigger () {
  return async (dispatch, getState) => {
    if (Platform.OS === 'web') return

    // 先清除之前的 listener 和 updateTimeout
    await dispatch(removeUpdateTrigger())

    // 檢查時間為下次 4AM
    const today4AM = moment().set('hour', 4).startOf('hours')
    if (moment().isAfter(today4AM)) {
      // 今天的 4AM 已經過了，加一天
      checkUpdateTime = today4AM.add(1, 'day')
    } else {
      checkUpdateTime = today4AM
    }

    const checkUpdateTimeDuration = moment.duration(checkUpdateTime - moment())
    updateTimeout = setTimeout(async () => {
      logger.log('[CodePush] 每日檢查更新')
      // 時間到，檢查更新
      const overwriteDeployment = getState().codePush.overwriteDeployment
      const merchantDeployment = getState().merchant.data?.setting?.deployment
      const codePushMeta = getState().codePush
      logger.log('[CodePush] trigger checkUpdate by initUpdateTrigger by daily update timer', {
        overwriteDeployment,
        merchantDeployment,
        codePushMeta,
      })
      await dispatch(checkUpdate(true))
      // 重設 timeout
      dispatch(initUpdateTrigger())
    }, checkUpdateTimeDuration.asMilliseconds())

    // 在 background 時， timeout 不會被執行，resume 時再判斷是否過了檢查時間
    appStateChangeListener = async (state) => {
      if (state === 'active') {
        // 先停止原本的 timeout
        clearTimeout(updateTimeout)
        const checkUpdateAt = getState().codePush.checkUpdateAt

        // 現在時間已超過檢查更新時間，且檢查更新時間已超過上次檢查更新
        if (moment().isAfter(checkUpdateTime) && moment(checkUpdateTime).isAfter(checkUpdateAt)) {
          logger.log('[CodePush] APP Resume 今天還沒檢查，檢查更新')
          const overwriteDeployment = getState().codePush.overwriteDeployment
          const merchantDeployment = getState().merchant.data?.setting?.deployment
          const codePushMeta = getState().codePush
          logger.log('[CodePush] trigger checkUpdate by initUpdateTrigger by app state change', {
            overwriteDeployment,
            merchantDeployment,
            codePushMeta,
          })
          await dispatch(checkUpdate(true))
        }
        // 重設 timeout
        dispatch(initUpdateTrigger())
      }
    }
    AppState.addEventListener('change', appStateChangeListener)

    // 記憶體警告的 callback
    memoryWarningListener = async (event) => {
      logger.warn('[Memory] receive memory warning')
    }
    AppState.addEventListener('memoryWarning', memoryWarningListener)
  }
}

/**
 * @returns {ThunkFunction}
 */
export function removeUpdateTrigger () {
  return () => {
    if (Platform.OS === 'web') return

    if (appStateChangeListener) {
      AppState.removeEventListener('change', appStateChangeListener)
    }
    if (memoryWarningListener) {
      AppState.removeEventListener('memoryWarning', memoryWarningListener)
    }
    clearTimeout(updateTimeout)
  }
}

/**
 * 檢查 firebase device 的 deployment channel 還原到本地的 overwrite deployment。注意：此 action 不會觸發更新
 * @returns {ThunkFunction}
 */
export function restoreDeploymentFromFirebase () {
  return async (dispatch, getState) => {
    let firebaseCheckupdate, firebaseDeploymentChannel

    try {
      firebaseCheckupdate = await firebase.getCheckUpdate()
    } catch (err) {
      console.error('firebaseCheckupdate error', err)
    }
    try {
      firebaseDeploymentChannel = await firebase.getDeploymentChannel()
    } catch (err) {
      console.error('firebaseDeploymentChannel error', err)
    }
    const deployment = firebaseCheckupdate || firebaseDeploymentChannel

    if (!codePushConfig.deploymentKeys[deployment]) return
    // 若 checkUpdate 有記錄 deployment，表示需要更新到指定的 deployment
    await overwriteDeploymentFromFirebase(deployment)
  }
}

/**
 * 處理從 firebase device 觸發的更新 (update from dashboard)。注意：此 action 不會觸發更新
 * @returns {ThunkFunction<Promise<boolean>>} return boolean 表示新的 deployment 是否有效
 */
export function overwriteDeploymentFromFirebase (deployment) {
  return async (dispatch, getState) => {
    if (!deployment) return false
    try {
      if (!codePushConfig.deploymentKeys[deployment]) {
      // 若 deploymentKey 不存在，寫入 firebase checkUpdateError
        await firebase.setCheckUpdateError('deployment channel not found')
        return false
      }

      // 收到有效的 deployment，清除 error
      await firebase.setCheckUpdateError('')

      // log
      const overwriteDeployment = getState().codePush.overwriteDeployment
      const merchantDeployment = getState().merchant.data?.setting?.deployment
      const codePushMeta = getState().codePush
      logger.log(`[CodePush] trigger checkUpdate by firebase deploymentChannel change: ${deployment}`, {
        overwriteDeployment,
        merchantDeployment,
        codePushMeta,
      })

      // 前端已收到 checkUpdate 的 deployment，清除 firebase 欄位
      await firebase.resetCheckUpdate()

      // 更改 overwrite deployment
      await dispatch(actions.codePush.overwriteDeployment(deployment, false))
      return true
    } catch (error) {
      console.error('overwriteDeploymentFromFirebase error', error)
    }
  }
}

/**
 * 更改 deployment 並檢查更新
 * @param {TOption | TOption[]} newDeployment
 * @returns {ThunkFunction}
 */
export function overwriteDeployment (newDeployment, forceCheck = false) {
  return async (dispatch, getState) => {
    const currentDeployment = getState().codePush.deployment

    if (newDeployment) {
      await AsyncStorage.setItem(AsyncStorageKey.OVERWRITE_DEPLOYMENT, newDeployment)
    } else {
      await AsyncStorage.removeItem(AsyncStorageKey.OVERWRITE_DEPLOYMENT)
    }
    dispatch(updateOverwirteDeployment(newDeployment))

    if (
      !newDeployment || // deployment 被移除，改用預設
      currentDeployment !== newDeployment || // deployment 被改變
      forceCheck // dispatch 時要求立即檢查更新
    ) {
      const overwriteDeployment = getState().codePush.overwriteDeployment // 新的 overwriteDeployment
      const merchantDeployment = getState().merchant.data?.setting?.deployment // 餐廳的預設 deployment
      const codePushMeta = getState().codePush // 目前的 codepush 狀態
      logger.log('[CodePush] trigger checkUpdate by dispatch overwriteDeployment', {
        overwriteDeployment,
        merchantDeployment,
        codePushMeta,
      })
      // 檢查 codepush 更新（顯示 dialog）
      dispatch(checkUpdate(true))
    }
    // 設定 logger 的 overwriteDeployment label
    setCodePushOverwriteDeployment(newDeployment)
    try {
      // 設定 firebase 的 deployment channel (for dashboard 顯示)
      firebase.setDeploymentChannel(newDeployment || getState().codePush.deployment || (__DEV__ ? '__DEV__' : 'Default'))
    } catch (err) {
      logger.error('firebase setDeploymentChannel error', err)
    }
  }
}

export function updateOverwirteDeployment (deployment) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_OVERWROTE_DEPLOYMENT,
      payload: { deployment },
    })
  }
}

export function updateDialogOpen (open) {
  return {
    type: ActionTypes.UPDATE_DIALOG_OPEN,
    payload: { open },
  }
}

/**
 * @param {boolean?} showDialog 前景更新
 * @returns {ThunkFunction}
 */
export function checkUpdate (showDialog = false) {
  return async (dispatch, getState) => {
    const merchant = getState().merchant.data
    if (Platform.OS === 'web') {
      // web 不做 codePush 更新
      return
    }
    if (__DEV__) {
      // 本地開發不更新
      return
    }
    logger.log('[CodePush] checkUpdate')

    try {
      const overwriteDeployment = await AsyncStorage.getItem(AsyncStorageKey.OVERWRITE_DEPLOYMENT)
      if (overwriteDeployment) {
        // 還原 overwriteDeployment
        dispatch(updateOverwirteDeployment(overwriteDeployment))
      }

      // 取得目前執行的版本
      const codePushPackageMeta = await codePush.getUpdateMetadata()
      logger.log('[CodePush] codePushPackageMeta', { codePushPackageMeta })
      if (codePushPackageMeta) {
        const deployment = codePushConfig.deploymentKeyMap[codePushPackageMeta.deploymentKey]
        setCodePushDeployment(deployment)
        setCodePushLabel(codePushPackageMeta.label)

        dispatch({
          type: ActionTypes.UPDATE_PACKAGE_META,
          payload: {
            codePushPackageMeta: {
              ...codePushPackageMeta,
              deployment: deployment,
            },
          },
        })
      }

      // 根據環境選擇預設的 deployment
      let deployment = codePushConfig.Deployments['Prod-Stable|3'] // 預設 Prod-Stable|3
      let merchantDeploymentLog
      let overwriteDeploymentLog

      // 如果 merchant 有登入，且有設定 merchant.setting.deployment，改用 merchant 的設定
      if (
        merchant?.setting?.deployment &&
      codePushConfig.merchantSettingDeploymentMap[merchant.setting.deployment]
      ) {
        deployment = codePushConfig.merchantSettingDeploymentMap[merchant.setting.deployment]
        merchantDeploymentLog = codePushConfig.merchantSettingDeploymentMap[merchant.setting.deployment]
      }

      // 如果有設定 overwriteDeployment，改用 overwriteDeployment
      if (overwriteDeployment) {
        deployment = overwriteDeployment
        overwriteDeploymentLog = overwriteDeployment
      }

      // 找出 deployment key
      const deploymentKey = codePushConfig.deploymentKeys[deployment]

      logger.log('[CodePush] final deployment ' + deployment, {
        finalDeployment: deployment,
        merchantDeployment: merchantDeploymentLog,
        overwriteDeployment: overwriteDeploymentLog,
        deploymentKey,
      })

      // 將最後選擇的 deployment 存起來
      dispatch({
        type: ActionTypes.UPDATE_DEPLOYMENT,
        payload: { deployment },
      })

      // dev mode 時預設不更新，但如果有去改 overwriteDeployment 就會更新
      if (__DEV__ && !overwriteDeployment) {
        console.log('[CodePush] skip in dev mode')
        return
      }

      if (showDialog) {
        dispatch({
          type: ActionTypes.UPDATE_DIALOG_OPEN,
          payload: { open: true },
        })
      }

      logger.log('[CodePush] sync start')
      await codePush.sync({
        deploymentKey,
        installMode: codePush.InstallMode.IMMEDIATE,
        // updateDialog: {
        //   title: '有可用更新',
        //   mandatoryUpdateMessage: '即將開始更新',
        //   mandatoryContinueButtonLabel: '繼續',
        //   optionalUpdateMessage: '有可用更新，要立即安裝嗎？',
        //   optionalInstallButtonLabel: '安裝',
        //   optionalIgnoreButtonLabel: '先不要',
        // },
        rollbackRetryOptions: {
          delayInHours: 0.5, // 更新失敗後多久 retry，預設 24
          maxRetryAttempts: 100, // 可以 retry 幾次，預設 1
        },
      }, (status) => {
        dispatch({
          type: ActionTypes.UPDATE_STATUS,
          payload: { status },
        })

        const statusI18nKey = codePushConfig.statusDisplayTextI18nKeys[status]
        logger.log(`[CodePush] status change: [${status}] ${i18n.t(statusI18nKey)}`, { status, message: i18n.t(statusI18nKey) })
      }, (progress) => {
        dispatch({
          type: ActionTypes.UPDATE_PROGRESS,
          payload: { progress },
        })
        console.log('[CodePush] download progress: ' + progress)
      }, (remotePackage) => {
        // Native 版本需更新
        // TODO: 這邊可以幫他開啟 app store 讓他去下載新版
      })
      dispatch({
        type: ActionTypes.UPDATE_CHECK_UPDATE_AT,
        payload: {
          checkUpdateAt: new Date(),
        },
      })
      logger.log('[CodePush] sync done')
    } catch (error) {
      logger.error(`[CodePush] error(${logId})` + error?.message || error, { error })
      dispatch({
        type: ActionTypes.UPDATE_ERROR,
        payload: { error: `更新時發生錯誤 (${logId})` },
      })
    }
  }
}
