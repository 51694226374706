
import _ from 'lodash'
import hash from 'object-hash'
import moment from 'moment'
import shortid from 'shortid'

import { PrintMethod } from '@/constants/printer'
import { PrintStyle } from '@/constants/printing'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import PrintDoc from '@/libs/printing/PrintDoc'
import i18n from '@/i18n'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppBatchItemOption, IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IMerchant } from 'dimorder-orderapp-lib/dist/types/Merchant'
import { ITag } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

/**
 * 1.
 * admin 有 11 個設定，但是只有 4 個在 CustomizedKitchenBatch 會生效
 * (mutedPrinter, printWithNoOptions, kitchenReceiptOptionSeparator, code) 應該要搬到 kitchen batch setting 裡面
 * 才不會在 admin 改了其他 8 個設定然後 CustomizedKitchenBatch 沒有反應以為是 bug
 *
 * 2.
 * 看起來 options, tags, remarks 漏了大闊開啟 imageMode 的判斷
 * 請香港測試字體為 4 （大闊）時 options, tags, remarks 印出來的字體大小
 * 與 batchHeader, batchFooter, batchItems 印出來的字體大小是否一致
 */

export default class CustomizedKitchenBatch extends PrintDoc {
  TYPE = PrintDoc.Types.CUSTOMIZED_KITCHEN_BATCH

  /**
   *
   * @param {IAppBatchItem[]} items
   * @param {IMerchant} merchant
   * @param {IAppOrder} order
   * @param {IKitchenReceiptSetting} printerSetting
   * @param {IPrintConfig} printConfig
   * @param {object} sorting // ? object 的內容是什麼？
   * @param {IAppOrderBatch} batch
   * @param {object} quantity // ? object 的內容是什麼？
   * @param {CustomizedKitchenBatchSetting} customizedStyle
   *
   * @typedef IPrintConfig
   * @property {boolean?} reprint
   * @property {boolean?} cancel
   * @property {boolean?} serveUp
   * @property {boolean?} printStaff
   * @property {boolean?} change
   * @property {number?} orderSerialDigit
   * @property {ITransferTableInfo?} transfer
   *
   * @typedef ITransferTableInfo // ? 應該不只這些，還有什麼東西？
   * @property {{ table: string }} from
   * @property {{ table: string }} to
   * @returns {ThunkFunction}
   */
  constructor (
    items,
    merchant,
    order,
    printerSetting,
    printConfig,
    sorting,
    batch,
    quantity,
    customizedStyle,
  ) {
    super()
    this.items = items
    this.merchant = merchant
    this.order = order
    this.printerSetting = printerSetting
    this.printConfig = printConfig
    this.sorting = sorting
    this.batch = batch
    this.quantity = quantity
    this.customizedStyle = customizedStyle

    this.printId = shortid.generate()
    this.batchLocale = printerSetting.batchLocale || merchant.batchLocale
    this.printHash = this._generatePrintHash()
    this.TEXT = this._generateBatchLocaleText()

    this.color = 'black'
    this.lineSpacing = Number(customizedStyle.lineSpacing) || 1
    this.additionalLineSpacing = {
      0: 0, // 字型大小：小
      1: 1, // 字型大小：中
      2: 2, // 字型大小：大
      5: 2, // 字型大小：特大
      3: 1, // 字型大小：中闊
      4: 2, // 字型大小：大闊
    }
  }

  print () {
    let items = this.items
    const { printSetmenuBundled, cutPaperMode, splitItem, printTimes } = this.printerSetting
    const printCategories = this.sorting?.printCategories

    items = _.flatMap(items, item => {
      const quantity = Number(_.get(this.quantity, item.id, item.quantity))
      const total = item.total / item.quantity * quantity
      return { ...item, quantity, total }
    })

    const { settings: { showSetName } } = this.customizedStyle.style.find(s => s.key === 'content')
    items = this._groupSetItems(items, showSetName)
    if (splitItem) {
      items = this._splitItemsByQuantity(items)
    }

    const printItems = _.flatMap(_.clone(printCategories), category => {
      return [_.filter(_.clone(items), item => category.includes(item.categoryId))]
    }).filter(printItem => printItem.length)
    const remainItems = _.difference(items, _.flatMap(_.clone(printItems), items => items))
    printItems[0] = _.concat(printItems[0] ?? [], remainItems)

    if (cutPaperMode === 'category') {
      this._addKitchenBatchInit()
      _.each(printItems, (printItem) => {
        this._printWithStyle(cutPaperMode, printItem)
        this._addKitchenBatchCutPaper()
      })
    } else if (cutPaperMode === 'none') {
      this._addKitchenBatchInit()
      this._printWithStyle(cutPaperMode, printItems)
      this._addKitchenBatchCutPaper()
    } else {
      // cutPaperMode === item
      if (printSetmenuBundled) {
        _.each(items, (item) => {
          this._addKitchenBatchInit()
          this._printWithStyle(cutPaperMode, [item])
          this._addKitchenBatchCutPaper()
        })
      } else {
        let allItems = _.flatMap(items, item => {
          if (!item.isSet) {
            return item
          }
          if (item.oldWeb) {
            return [item, ...item.setItems]
          }
          if (item.isSet) {
            return item.setItems
          }
          return item
        })
        const showItemPrice = _.get(_.find(this.customizedStyle.style, s => s.key === 'content'), 'settings.showItemPrice', false)
        if (!splitItem) {
          allItems = _.flatMap(_.groupBy(allItems, item => {
            const options = _.map(item.options, option => option.name + option.quantity)
            const tags = _.map(item.tags, 'name')
            const remarks = item.remarks
            return [item.menuId, options, tags, remarks, showItemPrice && item.price]
          }), groupedItems => {
            let groupedItem
            _.each(groupedItems, item => {
              if (_.isEmpty(groupedItem)) {
                groupedItem = item
              } else {
                groupedItem = {
                  ...groupedItem,
                  quantity: item.quantity + groupedItem.quantity,
                  total: item.total + groupedItem.total,
                  ids: groupedItem.ids.concat(item.ids),
                  itemIds: groupedItem.ids.concat(item.itemIds),
                }
              }
            })
            return groupedItem
          })
        }
        _.each(allItems, (item) => {
          this._addKitchenBatchInit()
          this._printWithStyle(cutPaperMode, [item])
          this._addKitchenBatchCutPaper()
        })
      }
    }
    if (printTimes > 0) {
      this._addCopy(printTimes)
    }

    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  /**
   * 根據 customizedStyle 列印指定區塊
   * @param {TCutPapaerMode} cutPaperMode
   * @param {*[]} items  // printItems
   */
  _printWithStyle (cutPaperMode, items) {
    const styles = this.customizedStyle.style
    const printer = this.printer

    _.each(styles, style => {
      switch (style.key) {
        // * 訂單資料
        case 'info':
          this._addKitchenBatchHeader(style)
          break

        // * 訂單內容
        case 'content':
          if (cutPaperMode === 'none') {
            _.each(items, (printItem, index) => {
              this._addKitchenBatchItem(printItem, style)
              if (items.length !== index + 1) {
                printer.setImageMode(false)
                printer.addSeparator()
              }
            })
          } else {
            this._addKitchenBatchItem(items, style)
          }
          break

        // * 頁尾
        case 'footer':
          this._addKitchenBatchFooter(style)
          break

        // * 自訂
        case 'custom': {
          this._addKitchenBatchCustom(style)
          break
        }

        default:
          break
      }
    })
  }

  /**
   * 重複列印
   * @param {number} printTimes
   */
  _addCopy (printTimes) {
    const printer = this.printer
    const data = Array.from(this.printer.getCommandBuffer())
    for (let i = 0; i < printTimes; i++) {
      printer.addCommand(data)
    }
  }

  /**
   * 訂單資訊
   * @param {CKBInfoBlockSetting<'info'>} style
   */
  _addKitchenBatchHeader (style) {
    const printer = this.printer
    const { table, serial, customers, pickupAt, deliveryType, shipping } = this.order
    const { createdAt } = this.batch

    let isNewLine = true
    const sortedStyleContents = _.sortBy(_.values(style.content), 'index')

    _.each(sortedStyleContents, styleContent => {
      const isImageMode = this._checkImageMode([styleContent.fontSize, styleContent.last3Digit?.fontSize ?? ''])
      printer.setImageMode(isImageMode)
      switch (styleContent.key) {
        // * 預訂單提醒
        case 'preOrderAlert': {
          const isPreOrder = (
            !table &&
            moment()
              .add(styleContent.specifiedDayTime.day, 'days')
              .add(styleContent.specifiedDayTime.hour, 'hours')
              .isBefore(moment(pickupAt))
          )

          if (styleContent.enable && isPreOrder) {
            this._addTextSetting(styleContent, isNewLine)

            printer.addText(`### ${i18n.t('app.printing.preOrder')} ###`, styleContent.fontSize)
            isNewLine = this._addItemSeparator(styleContent)
          }
          break
        }

        // * 狀態

        case 'status': {
          const shouldPrintStatusText = this.printConfig.cancel || this.printConfig.serveUp || this.printConfig.reprint || this.printConfig.change
          if (styleContent.enable && shouldPrintStatusText) {
            this._addTextSetting(styleContent, isNewLine)
            this._addStatus(styleContent)
            isNewLine = this._addItemSeparator(styleContent)
          }
          break
        }

        // * 枱號
        case 'tableNo': {
          if (styleContent.enable) {
            this._addTextSetting(styleContent, isNewLine)
            this._addTableNo(styleContent)
            isNewLine = this._addItemSeparator(styleContent)
          }
          break
        }

        // * 人數
        case 'customerNo': {
          if (styleContent.enable && table) {
            this._addTextSetting(styleContent, isNewLine)
            printer.addText(`${customers + i18n.t('app.printing.customer')}`, styleContent.fontSize)
            isNewLine = this._addItemSeparator(styleContent)
          }
          break
        }

        // * 單號
        case 'serialNo': {
          if (styleContent.enable) {
            this._addTextSetting(styleContent, isNewLine)

            if (styleContent.last3Digit) {
              const orderSerialDigit = this.printConfig.orderSerialDigit ?? 3
              const orderSerial = styleContent.last3Digit.enable ? '' : _.dropRight(serial, orderSerialDigit).join('')
              const last3Digit = _.takeRight(serial, orderSerialDigit).join('')
              printer.addText(`#${orderSerial}`, styleContent.fontSize)
              printer.addBoldMode(styleContent.last3Digit.bold)
              printer.addText(last3Digit, styleContent.last3Digit.fontSize)
            } else {
              printer.addText(`#${serial}`, styleContent.fontSize)
            }

            isNewLine = this._addItemSeparator(styleContent)
          }
          break
        }

        // * 客人取餐時間
        case 'pickupAt': {
          if (styleContent.enable && !table) {
            this._addTextSetting(styleContent, isNewLine)

            const time = deliveryType === 'storeDelivery' ? _.get(shipping, 'lalamove.scheduleAt') : pickupAt
            const addMin = deliveryType === 'storeDelivery' ? 10 : 0
            printer.addText(`${i18n.t('app.printing.pickupAt')} ${moment(time).add(addMin, 'm').format(styleContent.format)}`, styleContent.fontSize)
            isNewLine = this._addItemSeparator(styleContent)
          }
          break
        }

        // * 點餐次數
        case 'batchIndex': {
          if (styleContent.enable) {
            this._addTextSetting(styleContent, isNewLine)

            printer.addText(`${i18n.t('app.printing.orderTime')}: ${this.order.batches.length}`, styleContent.fontSize)
            isNewLine = this._addItemSeparator(styleContent)
          }
          break
        }

        // * 落單時間
        case 'createAt': {
          if (styleContent.enable) {
            this._addTextSetting(styleContent, isNewLine)

            printer.addText(moment(createdAt).format(styleContent.format), styleContent.fontSize)
            isNewLine = this._addItemSeparator(styleContent)
          }
          break
        }

        default:
          break
      }
    })

    if (!isNewLine) {
      // 如果最後不是新行時換行
      printer.addRowFeed(this.lineSpacing)
    }
    printer.addBoldMode(false)
    printer.addUnderlineMode(false)
  }

  /**
   *
   * @param {IAppBatchItem[]} items
   * @param {CKBContentBlockSetting<'content'>} style
   */
  _addKitchenBatchItem (items, style) {
    const printer = this.printer
    const { code, printWithNoOptions } = this.merchant.setting
    const { printSetmenuBundled, cutPaperMode } = this.printerSetting
    const {
      fontSize,
      format = ['quantity', 'item', 'price'],
      settings: {
        showItemPrice,
        hideSetText,
        hideSetItemText = false,
        leading,
        setItemLeading = leading,
        itemSeparator,
        setItemSeparator,
        lineSpacing = 1,
        setItemlineSpacing = lineSpacing || 1,
        itemQuantityFont = fontSize,
      },
    } = style

    const printSetMenu = !(cutPaperMode === 'item' && !printSetmenuBundled)
    const fontSizes = this._getRowWithFormat([itemQuantityFont, fontSize, fontSize], format)
    const align = [
      PrintStyle.ALIGN.LEFT,
      format[0] === 'item' ? PrintStyle.ALIGN.RIGTH : PrintStyle.ALIGN.LEFT,
      format[2] === 'item' ? PrintStyle.ALIGN.LEFT : PrintStyle.ALIGN.RIGTH,
    ]
    const quantityFormatIndex = format.findIndex(f => f === 'quantity')
    const itemFormatIndex = format.findIndex(f => f === 'item')

    printer.addAlign('left')

    if (this.batchLocale === 'thai') {
      printer.addPrintThai()
    }

    printer.addRowFeed(this.lineSpacing)

    _.each(items, (item, itemIndex) => {
      const isImageMode = this._checkImageMode([fontSize, itemQuantityFont])
      const itemWidths = this._getItemWidths(fontSizes, format, showItemPrice, isImageMode)
      this._addTextColor('black')

      if (itemSeparator && itemIndex) {
        printer.setImageMode(false)
        printer.addSeparator()
      }
      let name = item.name
      if (item.oldWeb && !hideSetText && printSetMenu) {
        name += ` ${this.TEXT.SETTEXT}`
      }
      const printName = [
        code && item.code ? item.code : '',
        name,
      ]
        .join(' ')
        .trim()
      const itemRowText = isImageMode
        ? [
          `${quantityFormatIndex < itemFormatIndex ? `${item.quantity}x` : `x${item.quantity}`}`,
          printName + (showItemPrice ? ` (${currencyWithCommas(item.total)})` : ''),
          '',
        ]
        : [
          `${quantityFormatIndex < itemFormatIndex ? `${item.quantity}x` : `x${item.quantity}`}`,
          printName,
          showItemPrice ? currencyWithCommas(item.total) : '',
        ]
      printer.setImageMode(isImageMode)
      printer.addFixedRow(this._getRowWithFormat(itemRowText, format), itemWidths, fontSize, this.lineSpacing, align, fontSizes)

      if (!_.isEmpty(item.options) || printWithNoOptions) {
        this._addOptions(item.options, style)
      }

      const tags = _.clone(item.tags).filter(tag => tag.id !== 'serve-up' && tag.id !== 'serve-later' && tag.id !== 'printKitchen')
      if (!_.isEmpty(tags)) {
        this._addTags(tags, style)
      }

      if (!_.isEmpty(item.setItems) && printSetMenu) {
        _.each(item.setItems, setItem => {
          this._addTextColor('black')

          if (setItemLeading) {
            printer.addRowFeed(setItemlineSpacing)
          }
          if (setItemSeparator) {
            printer.setImageMode(false)
            printer.addSeparator()
          }
          const quantityText = setItem.quantity > 1 ? `${quantityFormatIndex < itemFormatIndex ? `${setItem.quantity}x` : `x${setItem.quantity}`}` : ''
          const setName = setItem.name
          const followText = hideSetItemText ? '' : this.TEXT.FOLLOWTEXT
          const setRowText = isImageMode
            ? [
              followText + quantityText,
              setName + (showItemPrice ? ` (${currencyWithCommas(setItem.total)})` : ''),
              '',
            ]
            : [
              followText + quantityText,
              setName,
              showItemPrice ? currencyWithCommas(setItem.total) : '',
            ]
          printer.setImageMode(isImageMode)
          printer.addFixedRow(this._getRowWithFormat(setRowText, format), itemWidths, fontSize, this.lineSpacing, align, fontSizes)
          if (!_.isEmpty(setItem.options) || printWithNoOptions) {
            this._addOptions(setItem.options, style)
          }
          const setItemTags = _.clone(setItem.tags).filter(tag => tag.id !== 'serve-up' && tag.id !== 'serve-later')
          if (!_.isEmpty(setItemTags)) {
            this._addTags(setItemTags, style)
          }
          this._addRemarks(setItem.remarks, style)
          const isServeLater = setItem.tags.findIndex(o => o.id === 'serve-later') > -1 && !this.printConfig.reprint
          if (isServeLater) {
            this._addServeLater(style.settings.tag.fontSize)
          }
        })
      }

      this._addRemarks(item.remarks, style)

      const isServeLater = item.tags.findIndex(o => o.id === 'serve-later') > -1 && !this.printConfig.reprint
      if (isServeLater) {
        this._addServeLater(style.settings.tag.fontSize)
      }

      if (leading) {
        printer.addRowFeed(lineSpacing)
      }
    })
  }

  /**
   * 頁尾
   * @param {CKBFooterBlockSetting<'footer'>} style
   */
  _addKitchenBatchFooter (style) {
    const printer = this.printer

    let isNewLine = true
    const sort = _.sortBy(_.values(style.content), 'index')

    printer.addRowFeed(2)

    _.each(sort, s => {
      const isImageMode = this._checkImageMode(s.fontSize)
      printer.setImageMode(isImageMode)
      switch (s.key) {
        // * 狀態
        case 'status': {
          const shouldPrintStatusText = this.printConfig.cancel || this.printConfig.serveUp || this.printConfig.reprint || this.printConfig.change
          if (s.enable && shouldPrintStatusText) {
            this._addTextSetting(s, isNewLine)
            this._addStatus(s)
            isNewLine = this._addItemSeparator(s)
          }
          break
        }

        // * 枱號
        case 'tableNo': {
          if (s.enable) {
            this._addTextSetting(s, isNewLine)
            this._addTableNo(s)
            isNewLine = this._addItemSeparator(s)
          }
          break
        }
      }
    })

    if (!isNewLine) {
      // 如果最後不是新行時換行
      printer.addRowFeed(this.lineSpacing)
    }
    printer.addBoldMode(false)
    printer.addUnderlineMode(false)
  }

  /**
   * 自訂模組
   * @param {CKBCustomBlockSetting<'custom'>} style
   */
  _addKitchenBatchCustom (style) {
    const printer = this.printer
    console.log('_addKitchenBatchCustom', style)
    const isImageMode = this._checkImageMode(style.fontSize)
    printer.setImageMode(isImageMode)
    this._addTextColor('black')
    switch (style.type) {
      //*  自訂：分隔線
      case 'separator':
        printer.addSeparator()
        break

        // * 自訂：訂單備註
      case 'remark': {
        const remark = _.get(this.order, 'remark', '').trim()
        if (!remark) return
        printer.addAlign(style.align)
        printer.addBoldMode(style.bold)
        printer.addText(`${i18n.t('app.constant.order.orderRemark')}: ` + remark, style.fontSize)
        printer.addRowFeed(this.lineSpacing + this.additionalLineSpacing[style.fontSize])
        printer.addBoldMode(false)
        break
      }

      // * 自訂：文字
      case 'text':
        printer.addAlign(style.align)
        printer.addBoldMode(style.bold)
        printer.addText(style.text, style.fontSize)
        printer.addRowFeed(this.lineSpacing + this.additionalLineSpacing[style.fontSize])
        printer.addBoldMode(false)
        break

      default:
        break
    }
  }

  /**
   * 加入廚房單辨識碼與切紙
   */
  _addKitchenBatchCutPaper () {
    this._addBatchIdentifier()
    this.printer.addRowFeed(4)
    this.printer.addCutPaper()
  }

  /**
   * 加入廚房單辨識碼
   */
  _addBatchIdentifier () {
    const batch = this.batch
    const printStaff = this.printConfig.printStaff
    const nameLength = this.printer.MAX_WIDTH - 10

    let name = ''
    if (printStaff) {
      if (batch.from === 'MERCHANT') {
        name = batch.identifier
      } else if (batch.from === 'CUSTOMER_WEB') {
        if (batch.identifier === 'waiter') {
          name = batch.identifier
        } else {
          name = this.TEXT.CUSTOMERORDER
        }
      }
    }

    const itemRowText = [this.printId, '', name]
    const widths = [10, 0, nameLength]
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH]

    this._addTextColor('black')
    this.printer.setImageMode(false) // BatchIdentifier 永遠是小字體
    this.printer.addAlign('left')
    this.printer.addFixedRow(itemRowText, widths, 0, 1, align)
  }

  /**
   * 切換字體顏色
   * ? 為什麼要用 this.color 不能直接改嗎？其他都是直接改的啊？
   * @param {TTextColor} color
   */
  _addTextColor (color) {
    if (this.color !== color) {
      this.printer.addColor(color)
      this.color = color
    }
  }

  /**
   * 切換字形（對齊, 顏色, 粗體, 底線）
   * @param {TextSetting} setting
   * @param {boolean} isNewLine // 不能一行內同時有兩種顏色
   */
  _addTextSetting (setting, isNewLine) {
    const {
      align = 'left',
      color = 'black',
      bold = false,
      underline = false,
    } = setting

    this.printer.addAlign(align)
    this.printer.addBoldMode(bold)
    this.printer.addUnderlineMode(underline)

    if (color !== this.color && isNewLine) {
      // 不能一行內同時有兩種顏色
      this.printer.addColor(color)
      this.color = color
    }
  }

  /**
   * 加入項目分隔符
   * @param {CKBStyleSetting} styleSetting
   * @returns isNewLine
   */
  _addItemSeparator (setting) {
    const { separateFormat, fontSize } = setting

    if (separateFormat === '\n') {
      this.printer.addRowFeed(this.lineSpacing + this.additionalLineSpacing[fontSize])
      return true // isNewLine
    } else {
      this.printer.addText(separateFormat, fontSize)
      return false // isNewLine
    }
  }

  /**
   * 加入狀態
   * @param {StyleSetting<'status'>} setting
   */
  _addStatus (setting) {
    let text
    if (this.printConfig.cancel) {
      text = `(${this.TEXT.CANCELED})`
    } else if (this.printConfig.serveUp) {
      text = `(${this.TEXT.SERVEUP})`
    } else if (this.printConfig.reprint) {
      text = this.TEXT.REPRINT
    } else if (this.printConfig.change) {
      text = this.TEXT.CHANGE
    }
    this.printer.addText(text, setting.fontSize)
  }

  /**
   * 加入枱號
   * @param {CKBTableNoStyleSetting} setting
   */
  _addTableNo (setting) {
    const { table, subTable, deliveryType } = this.order
    const printer = this.printer
    const { printTable = true, fontSize } = setting

    const tableText = printTable ? this.TEXT.TABLE_NUMBER : '' // 是否打印 "號檯"
    let text = deliveryType === 'storeDelivery' ? this.TEXT.DELIVERY : this.TEXT.TAKEWAY // "外送" or "外賣"

    if (table) {
      // 轉枱
      if (this.printConfig.transfer) {
        const fromOrder = this.printConfig.transfer.from
        const toOrder = this.printConfig.transfer.to
        const fromTableNoText = `${fromOrder.table}${fromOrder.subTable > 0 ? `(${String.fromCharCode(fromOrder.subTable + 64)})` : ''}`
        const toTableNoText = `${toOrder.table}${toOrder.subTable > 0 ? `(${String.fromCharCode(toOrder.subTable + 64)})` : ''}`
        const fromText = i18n.language === 'zh-HK' ? fromTableNoText + tableText : tableText + fromTableNoText
        const toText = i18n.language === 'zh-HK' ? toTableNoText + tableText : tableText + toTableNoText
        text = `${fromText} ${i18n.t('app.printing.transfer')} ${toText}`
      } else {
        // 桌號
        const subTableText = subTable > 0 ? `(${String.fromCharCode(subTable + 64)})` : ''
        const tableNoText = table + subTableText
        text = i18n.language === 'zh-HK' ? tableNoText + tableText : tableText + tableNoText
      }
    }

    printer.addText(text, fontSize)
  }

  /**
   * 加入選項
   * @param {IAppBatchItemOption[]} options
   * @param {CKBContentBlockSetting<'content'>} style
   */
  _addOptions (options, style) {
    const { printWithNoOptions, kitchenReceiptOptionSeparator } = this.merchant.setting
    const { showOptionPrice, option } = style.settings

    const isImageMode = this._checkImageMode(option.fontSize)
    const itemQuantityWidth = this.printer.getQuantityMaxWidth(option.fontSize)
    const priceMaxWidth = this.printer.getPriceMaxWidth(option.fontSize)
    const itemMaxWidths = this.printer.getItemMaxWidth(option.fontSize, this.batchLocale)
    const itemWidths = [itemQuantityWidth, itemMaxWidths + priceMaxWidth, 0]
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT]
    const separator = (kitchenReceiptOptionSeparator || ', ').replace(/\\n/g, '\n')

    this.printer.setImageMode(isImageMode)
    this._addTextColor(option.color)

    if (_.isEmpty(options) && printWithNoOptions) {
      // 沒有選項時，若有開啟 列印正常選項 設定則印一個 正常
      this.printer.addText(` ** ${this.TEXT.NORMALOPTION}`, option.fontSize)
      this.printer.addRowFeed(this.lineSpacing)
    } else {
      _
        .chain(options)
        .map(option => {
          if (option.quantity > 1) {
            return option.name + `(x${option.quantity})`
          }
          if (showOptionPrice && option.price !== 0) {
            return option.name + `(${currencyWithCommas(option.price * option.quantity)})`
          }
          return option.name
        })
        .join(separator)
        .split('\n')
        .filter(t => t.trim())
        .each(text => {
          const itemRowText = ['**', text, '']
          this.printer.addFixedRow(itemRowText, itemWidths, option.fontSize, this.lineSpacing, align)
        })
        .value()
    }
    // TODO if (isImageMode) this.printer.setImageMode(false)?
  }

  /**
   * 加入標籤
   * @param {ITag[]} tags
   * @param {CKBContentBlockSetting<'content'>} style
   */
  _addTags (tags, style) {
    if (_.isEmpty(tags)) return

    const { kitchenReceiptOptionSeparator } = this.merchant.setting // ???? 之後應該把分隔符的設定搬進 app 內?
    const { tag } = style.settings

    const isImageMode = this._checkImageMode(tag.fontSize)
    const itemQuantityWidth = this.printer.getQuantityMaxWidth(tag.fontSize)
    const priceMaxWidth = this.printer.getPriceMaxWidth(tag.fontSize)
    const itemMaxWidths = this.printer.getItemMaxWidth(tag.fontSize, this.batchLocale)
    const itemWidths = [itemQuantityWidth, itemMaxWidths + priceMaxWidth, 0]
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT]
    const separator = (kitchenReceiptOptionSeparator || ', ').replace(/\\n/g, '\n')

    this.printer.setImageMode(isImageMode)
    this._addTextColor(tag.color)
    _
      .chain(tags)
      .flatMap('name')
      .join(separator)
      .split('\n')
      .filter(t => t.trim())
      .each(text => {
        const itemRowText = ['**', text, '']
        this.printer.addFixedRow(itemRowText, itemWidths, tag.fontSize, this.lineSpacing, align)
      })
      .value()
    // TODO if (isImageMode) this.printer.setImageMode(false)?
  }

  /**
   * 加入備註
   * @param {string[]} remarks
   * @param {CKBContentBlockSetting<'content'>} style
   */
  _addRemarks (remarks, style) {
    if (_.isEmpty(remarks)) return

    const { remark } = style.settings
    const isImageMode = this._checkImageMode(remark.fontSize)
    const itemQuantityWidth = this.printer.getQuantityMaxWidth(remark.fontSize)
    const priceMaxWidth = this.printer.getPriceMaxWidth(remark.fontSize)
    const itemMaxWidths = this.printer.getItemMaxWidth(remark.fontSize, this.batchLocale)
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT]
    const itemWidths = [itemQuantityWidth, itemMaxWidths + priceMaxWidth, 0]

    this.printer.setImageMode(isImageMode)
    this._addTextColor(remark.color)

    _.each(remarks, r => {
      const itemRowText = ['', `${this.TEXT.REMARKSTEXT}: ${r}`, '']
      this.printer.addFixedRow(itemRowText, itemWidths, remark.fontSize, this.lineSpacing, align)
    })
    // TODO if (isImageMode) this.printer.setImageMode(false)?
  }

  /**
   * 加入叫起
   * @param {number} fontSize
   */
  _addServeLater (fontSize) {
    const isImageMode = this._checkImageMode(fontSize)
    this.printer.setImageMode(isImageMode)
    this._addTextColor('red')
    this.printer.addText(` (${this.TEXT.SERVELATER})`, fontSize)
    this.printer.addRowFeed(this.lineSpacing)
  }

  /**
   * 印表機初始
   */
  _addKitchenBatchInit () {
    const { mutedPrinter } = this.merchant.setting
    const { deepenDotColor } = this.printerSetting
    const printer = this.printer

    printer.addInit()
    printer.addCodePage()

    if (!mutedPrinter) {
      printer.addBeep()
    }

    if (deepenDotColor && printer.PRINT_METHOD === PrintMethod.DOT) {
      // 針機開啟顏色加深模式
      printer.addDoubleStrikeMode()
    }
  }

  /**
   * 根據字體大小判斷是否應使用影像模式
   * @param {number | number[]} fontSizes
   */
  _checkImageMode (fontSizes) {
    const isDotMatrixPrinter = this.printer.PRINT_METHOD === PrintMethod.DOT // 針機
    const LARGE_FONT_SIZES = [
      2, // 大
      4, // 大闊
      5, // 特大
    ]
    const sizes = _.isNumber(fontSizes)
      ? [fontSizes]
      : _.isArray(fontSizes)
        ? fontSizes
        : []
    return isDotMatrixPrinter && sizes.some(fontSize => LARGE_FONT_SIZES.includes(fontSize))
  }

  /**
   *
   * @param {*[]} items
   * @returns
   */
  _splitItemsByQuantity (items) {
    // for each item and each qty, add
    const toItems = []

    _.each(items, (item) => {
      // from
      const quantity = item.quantity

      for (let i = 0; i < quantity; i++) {
        // to
        const cloneItem = _.cloneDeep(item)
        cloneItem.quantity = 1

        if (item.ids) {
          cloneItem.id = item.ids[i]
        }

        if (cloneItem.prices) {
          cloneItem.total = cloneItem.prices[i]
        } else {
          cloneItem.total = cloneItem.price
        }

        cloneItem.setItems = this._splitItemsByQuantity(item.setItems)
        const setItemIndex = toItems.findIndex(toItem => toItem.setMenuIndex === item.setMenuIndex && item.oldWeb)

        if (setItemIndex > -1) {
          toItems[setItemIndex].setItems.unshift(cloneItem)
        } else {
          toItems.push(cloneItem)
        }
      }
    })

    return toItems
  }

  /**
   *
   * @param {*[]} items
   * @param {boolean} showSetName
   * @returns
   */
  _groupSetItems (items, showSetName) {
    const groupedItems = _.flatMap(_.groupBy(items, 'setMenuIndex'), item => {
      if (!_.get(item[0], 'isSet') && _.get(item[0], 'setMenuIndex') !== null) {
        const setItems = _.clone(item)
        setItems.shift()
        return { ...item[0], isSet: true, setItems, oldWeb: true }
      }

      if (!showSetName && _.get(item[0], 'isSet')) {
        const setItems = _.clone(item[0].setItems)
        const setItem = setItems.shift()
        return { ...setItem, isSet: true, setItems, oldWeb: true }
      }

      return item
    })
    return groupedItems
  }

  /**
   * 判斷 batch item中，項目數量、項目名稱、項目價錢的 alignment
   * @param {Array<0 | 1 | 2 | 3 | 4 | 5>} fontSizes
   * @param {Array<'item' | 'price' | 'quantity'} format
   * @param {boolean} showItemPrice
   * @returns
   */
  _getItemWidths (fontSizes, format, showItemPrice, isImageMode) {
    const printer = this.printer
    const itemNameFontSize = fontSizes[format.findIndex((value) => value === 'item')]
    const itemQuantityFontSize = fontSizes[format.findIndex((value) => value === 'quantity')]
    let itemQuantityWidth = printer.getQuantityMaxWidth(itemQuantityFontSize)
    let itemPriceWidth = printer.getPriceMaxWidth(itemNameFontSize)
    let itemNameWidth = printer.getItemMaxWidth(itemNameFontSize, this.batchLocale)

    const sizeWidths = [[0], [1, 3], [2, 4], [5]] // [[小], [中, 中闊], [大, 大闊], 特大]
    const itemNameFontSizeIndex = _.findIndex(sizeWidths, sizeWidth => sizeWidth.includes(itemNameFontSize))
    const itemQuantityFontSizeIndex = _.findIndex(sizeWidths, sizeWidth => sizeWidth.includes(itemQuantityFontSize))

    // imageMode 無法印小字型，所以把項目設定為中字型的寬度
    if (isImageMode && itemQuantityFontSize === 0) {
      itemQuantityWidth = printer.getQuantityMaxWidth(1)
    }

    // imageMode 無法印小字型，所以把項目設定為中字型的寬度
    if (isImageMode && itemNameFontSize === 0) {
      itemNameWidth = printer.getItemMaxWidth(1)
      itemPriceWidth = printer.getPriceMaxWidth(1)
    }

    // 當不顯示項目價格或處於影像模式時，將項目價格寬度移給項目名稱寬度
    if (!showItemPrice || isImageMode) {
      itemNameWidth += itemPriceWidth
      itemPriceWidth = 0
    }

    // 當 isImageMode，而項目名稱的字型不等於項目數量的字型時，更改項目數量的寬度
    if (isImageMode && itemNameFontSizeIndex !== itemQuantityFontSizeIndex) {
      // 找出項目數量於項目名稱字型時的寬度
      itemQuantityWidth = printer.getQuantityMaxWidth(itemNameFontSize)
    }

    // 當 !isImageMode，而項目名稱的字型不等於項目數量的字型時，更改項目名稱的寬度
    if (!isImageMode && itemNameFontSizeIndex !== itemQuantityFontSizeIndex) {
      // 找出項目數量於項目名稱字型時的寬度
      const expectedItemQuantityWidth = printer.getQuantityMaxWidth(itemNameFontSize)
      // 把項目數量寬度和於項目名稱字型時寬度轉回小字型的寬度，再計算差異
      const difference = (expectedItemQuantityWidth * (itemNameFontSizeIndex + 1)) - (itemQuantityWidth * (itemQuantityFontSizeIndex + 1))
      // 把差異轉回項目名稱字型的寬度，再補到項目名稱寬度
      itemNameWidth = itemNameWidth + Math.floor(difference / (itemNameFontSizeIndex + 1))
    }

    return this._getRowWithFormat([itemQuantityWidth, itemNameWidth, itemPriceWidth], format)
  }

  /**
   *
   * @param {Array<0 | 1 | 2 | 3 | 4 | 5>} row
   * @param {Array<'item' | 'price' | 'quantity'} format
   * @returns
   */
  _getRowWithFormat (row, format) {
    const defaultFormat = ['quantity', 'item', 'price']
    // format 與預設格式相同則不轉換 row
    if (_.isEqual(defaultFormat, format)) return row
    // 根據 format 轉換 row
    const indices = _.map(format, f => defaultFormat.findIndex(d => d === f))
    return _.map(indices, index => row[index])
  }

  /**
   * @returns {string}
   */
  _generatePrintHash () {
    const shouldReprint = this.printConfig.reprint || this.printConfig.serveUp || this.printConfig.cancel || this.printConfig.change
    if (shouldReprint) return this.printId
    // batch item 的 property printed 和 printError 有機會是會變的
    // 為了避免重複印單所以改為使用不會變動的 batchId 確保只會列印一次
    return hash({ bacthId: this.batch.id, printerSetting: this.printerSetting })
  }

  /**
   * @returns
   */
  _generateBatchLocaleText () {
    // 廚房單語言
    // 沒有設定/中文(香港)/廚房語言：中文
    // 英文/泰文：英文
    const batchLocalei18n = this.batchLocale === 'en' || this.batchLocale === 'thai'
      ? i18n.getFixedT('en-US')
      : i18n.getFixedT('zh-HK')

    return {
      TABLE_NUMBER: i18n.t('app.printing.tableNum'),
      CANCELED: batchLocalei18n('app.printing.cancelled'),
      SETTEXT: batchLocalei18n('app.printing.setText'),
      FOLLOWTEXT: batchLocalei18n('app.printing.follow'),
      REMARKSTEXT: batchLocalei18n('app.printing.remark'),
      NORMALOPTION: batchLocalei18n('app.printing.normal'),
      SERVELATER: batchLocalei18n('app.printing.serveLater'),
      SERVEUP: batchLocalei18n('app.printing.serveUp'),
      REPRINT: batchLocalei18n('app.printing.reprint'),
      TAKEWAY: i18n.t('app.printing.takeaway'),
      DELIVERY: i18n.t('app.printing.delivery'),
      CUSTOMERORDER: i18n.t('app.printing.customerOrder'),
      CHANGE: batchLocalei18n('app.printing.printChange'),
    }
  }
}
