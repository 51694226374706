import { ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'

import { useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import ChipLabel from '@/components/ChipLabel'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'

import CategoryTagInfo from './CategoryTagInfo'

export default function CategoryTagPanel (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedCategoryTag, setSelectedCategoryTag] = React.useState(null)
  const categoryTags = useSelector(state => state.menu.categoryTags) || []

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.categoryTagPanel.header')} />
        </Header>
        <View style={styles.content}>
          <SettingList>
            <ScrollView showsVerticalScrollIndicator={false}>
              {categoryTags.length === 0 && (
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.categoryTagPanel.tag-null')}</Text>
                </View>
              )}
              {categoryTags.map((categoryTag, index) => {
                return (
                  <SettingListItem
                    key={categoryTag.id}
                    onPress={() => {
                      setSelectedCategoryTag(categoryTag)
                    }}
                    divider={index !== categoryTags.length - 1}
                  >
                    <SettingListItemText text={categoryTag.name} />
                  </SettingListItem>
                )
              })}
            </ScrollView>
            <SettingListFooterAddButton
              text={t('app.page.setting.categoryTagPanel.add')}
              onPress={() => {
                setSelectedCategoryTag({
                  name: '',
                  categoryIds: [],
                  orderCutOffMins: 0,
                })
              }}
            />
          </SettingList>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <CategoryTagInfo
          key={selectedCategoryTag?.id + selectedCategoryTag?.name}
          categoryTag={selectedCategoryTag}
          onClose={() => setSelectedCategoryTag(null)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
