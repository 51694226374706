import { ScrollView, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import EmployeeOption from './EmployeeOption'

/**
 * @typedef EmployeeBoardProps
 * @property {() => void} onSelect
 */

/**
 *
 * @param {EmployeeBoardProps} props
 * @returns
 */
export default function EmployeeBoard (props) {
  const { onSelect } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const scopeCredentials = useSelector(state => state.auth.merchantLogin?.scopeCredentials)
  const [employees, setEmployees] = React.useState([])
  const positions = [
    t('app.page.login.position.supervisor'),
    t('app.page.login.position.manager'),
    t('app.page.login.position.normal'),
    t('app.page.login.position.staff'),
    t('app.page.login.position.staff'),
    t('app.page.login.position.staff'),
    t('app.page.login.position.staff'),
    t('app.page.login.position.staff'),
    t('app.page.login.position.staff'),
    t('app.page.login.position.staff'),
    t('app.page.login.position.staff'),
    t('app.page.login.position.staff'),
  ]

  React.useEffect(() => {
    try {
      const e = scopeCredentials.map((user, index) => {
        const identifier = user.identifier.toLowerCase()
        const name = (identifier.charAt(0).toUpperCase() + identifier.slice(1)).slice(0, 2)
        /** @type {IEmployee} */
        const employee = {
          account: user.identifier,
          avatar: user.avatar,
          name: name,
          position: positions[index],
        }
        return employee
      })
      setEmployees(e)
    } catch (err) {
      console.log('Load scopeCredential Error', err)
      dispatch(actions.auth.restoreMerchant())
    }
  }, [scopeCredentials])

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.content}>
        {employees?.map((employee, index) =>
          <EmployeeOption
            key={`employee-option-${employee.name}-${index}`}
            employee={employee}
            onPress={() => onSelect(employee)}
            style={styles.option}
            blur
          />)}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 370,
    width: 515,
  },
  content: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  option: {
    height: 70,
    width: 150,
    marginRight: 20,
    marginBottom: 20,
  },
})
