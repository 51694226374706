import { StyleSheet } from 'react-native'
import React from 'react'

import { routes } from '@/libs/setting'
import { useSelector } from '@/redux'
import RenderRoutes from '@/components/RenderRoutes'
import RightNavBar from '@/components/RightNavBar'
import Row from '@/components/Row'
import colors from '@/theme/colors'

import NavBar from './NavBar'

export default function Setting (props) {
  const isDataInit = useSelector(state => state.app.isDataInit)

  if (!isDataInit) {
    return null
  }

  return (
    <Row style={styles.container}>
      <NavBar />
      <RenderRoutes routes={routes} />
      <RightNavBar />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.light,
  },
})
