import { Pressable, StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { calculateStepIsMax, calculateStepQuantityTotal, getStepQuantityMax, isBetweenDayTime } from '@/libs/order'
import { useSelectedBatchItem, useSelectedOrder, useSelectedSetMenuIndex } from '@/hooks/order'
import { useSelectedParentCategory } from '@/hooks/category'
import { useTranslation } from 'react-i18next'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import MenuItem from './MenuItem'
import MenuItemBackButton from './MenuItemBackButton'
import MenuItemFolder from './MenuItemFolder'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'

// eslint-disable-next-line no-unused-vars
import { IAppCategory, IAppMenuItem } from 'dimorder-orderapp-lib/dist/types/AppMenu'

export default function PictureMenu (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = React.useState(0)
  const parentCategory = useSelectedParentCategory()
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const selectedSet = useSelector(state => state.menu.selectedSet)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  const selectedItem = useSelectedBatchItem()[0]
  const selectedSetMenuIndex = useSelectedSetMenuIndex()
  const isMax = calculateStepIsMax(selectedItem, selectedSetStep, selectedSetMenuIndex)
  const stepItemQuantity = calculateStepQuantityTotal(selectedItem, selectedSetStep, selectedSetMenuIndex)
  const stepMax = getStepQuantityMax(selectedItem, selectedSetStep)
  const stepCount = stepMax - stepItemQuantity
  const setItems = selectedItem?.setItems
  const selectedOrder = useSelectedOrder()
  const index = selectedSet?.steps.findIndex(o => o === selectedSetStep)
  const action = _.throttle(() => dispatch(actions.menu.selectSetStep(selectedSet.steps[index + 1])), 1000, { trailing: false })
  const isfilterByTime = useSelector(state => state.menu.isfilterByTime)
  const setNextStepButton = useSelector(state => state.app.settings.setNextStepButton)

  if (!selectedCategory) return null

  /** @type {{action: 'BACK', onPress: () => void} | IAppCategory | IAppMenuItem} */
  let menuItems = []

  if (parentCategory && parentCategory.id !== 'ROOT' && parentCategory.id !== 'SET_ROOT') {
    // Menu 中顯示子分類及所屬餐點，若 parent 不是 root 會在第一個加入 MenuItemBackButton
    menuItems.push({
      action: 'BACK',
      onPress: () => {
        dispatch(actions.menu.selectCategory(parentCategory))
      },
    })
  }
  if (selectedSet) {
    // 選擇套餐中
    const menus = selectedSet.menus.filter(menuItem => menuItem.step === selectedSetStep?.id)
    menuItems = menuItems.concat(menus)
  } else {
    // 非套餐 menu 顯示分類和餐點內容
    menuItems = menuItems.concat(selectedCategory.categories)
    if (selectedCategory.isInSetCategories) {
      menuItems = menuItems.concat(selectedCategory.sets)
    } else {
      menuItems = menuItems.concat(selectedCategory.menus)
    }
  }
  menuItems = menuItems.filter(m => (!m.deleted && !(selectedOrder.deliveryType === 'takeaway' && m.takeawayDisabled)) && !m?.disabled)
  const isSetMenuItems = menuItems.some(item => item.step)
  if (isSetMenuItems && setNextStepButton) {
    const allSteps = _.map(selectedSet?.steps, (s, i) => {
      return { ...s, stepIndex: i }
    })
    const selectedSetItems = selectedItem.setItems.map(s => s.setMenuId)
    const steps = _.drop(allSteps, index + 1)
    const nextStep = steps.find(step => {
      return (
        !step.dependsOnSetMenuItem ||
        selectedSetItems.includes(step.dependsOnSetMenuItem)
      ) &&
      (
        !step.dependsOnSetMenuItems?.length ||
        selectedSetItems.some(s => step.dependsOnSetMenuItems.includes(s))
      )
    })
    const nextStepIndex = _.get(nextStep, 'stepIndex', false)
    if (nextStepIndex) {
      menuItems.unshift({
        id: 'nextSetStep',
        isNextSetStepButton: true,
        nextStepIndex,
      })
    }
  }
  if (isfilterByTime) {
    menuItems = menuItems.filter(item => isBetweenDayTime(item))
  }
  // 分成 10 個一組
  const chunkedData = _.chunk(menuItems, 10)

  const renderItem = ({ item: pageItems }) => {
    const itemsRow = _.chunk(pageItems, 5)
    return itemsRow.map((rowItems, index) => (
      <Pressable key={index} style={{ flex: 1 }}>
        <View style={styles.contentContainer}>
          {rowItems.map((item) => {
            if (item.isNextSetStepButton) {
              return (
                <TouchableHighlight
                  style={styles.button}
                  onPress={action}
                  key={item.id}
                >
                  <View style={styles.nameView}>
                    <Text style={[styles.text, { flex: 1 }]} numberOfLines={3} ellipsizeMode='tail' wrap>{t('app.page.order.orderMenu.nextStep')}</Text>
                  </View>
                </TouchableHighlight>
              )
            }
            if (item.action === 'BACK') {
            // Back button
              return <MenuItemBackButton key='BACK' handleBack={item.onPress} />
            }
            if (!item.categoryId && !item.step) {
            // 沒有 categoryId 和 stepId, 表示他本身是 category
              return <MenuItemFolder key={item.id} category={item} />
            }

            // 有 categoryId 或 stepId, 表示他本身是 category/set 下的 menu item
            return <MenuItem key={item.id} item={item} isMax={isMax} stepCount={stepCount} setItems={setItems} />
          })}
        </View>
      </Pressable>
    ))
  }

  return (
    <View style={styles.container}>
      <Carousel
        data={chunkedData}
        renderItem={renderItem}
        itemWidth={600}
        sliderWidth={600}
        onScrollIndexChanged={(index) => setActiveIndex(index)}
      />
      <Pagination
        vertical
        containerStyle={styles.paginationContainer}
        dotsLength={chunkedData.length}
        activeDotIndex={activeIndex}
        dotStyle={styles.paginationDot}
        inactiveDotStyle={styles.paginationInActiveDot}
        inactiveDotScale={1}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minWidth: 600,
    height: 226,
    alignSelf: 'center',
    alignItems: 'flex-start',
  },
  contentContainer: {
    minWidth: 600,
    height: 113,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  paginationContainer: {
    width: 32,
    position: 'absolute',
    right: -28,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingHorizontal: 14,
    paddingVertical: 4,
  },
  paginationDot: {
    backgroundColor: colors.primary,
    width: 6,
    height: 6,
    margin: 2,
  },
  paginationInActiveDot: {
    backgroundColor: colors.dotGray,
    width: 6,
    height: 6,
    margin: 2,
  },
  button: {
    width: 100,
    height: 105,
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 4,
    marginHorizontal: 10,
    backgroundColor: colors.secondary,
  },
  nameView: {
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
  },
  text: {
    color: colors.white,
    fontSize: 25,
    fontWeight: '500',
    lineHeight: 25,
    textAlign: 'center',
    margin: 2,
  },
})
