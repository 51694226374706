import _ from 'lodash'

export default function getNameWithNumber (names, text) {
  const sameNames = _.filter(names, name => name.replace(/\(\d+\)/g, '') === text)
  if (sameNames.length === 0) {
    return text
  }
  const indices = _.map(sameNames, name => name.replace(text, '').replace(/\(|\)/g, '')).filter(i => i)
  let number = 1
  while (indices.includes(String(number))) {
    number += 1
  }
  return `${text}(${number})`
}
