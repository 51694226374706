import { PrinterType } from '@/constants/printer'
import GPrinter from '@/libs/printing/printer/GPrinter'

/**
 * GP_D801 熱敏打印機
 * encoding - 中文: gbk
 * command: ESC/POS
 * 用途：廚房單／收據
 * Printer Programming Manual: https://drive.google.com/drive/folders/1rQKQpbzN181W7zv6GAQfjhV1-ZRicKE9
 * 除了 addQRCode 的預設 size 是 4 以外，其他行為都和 GPrinter 相同
 */
export default class GP_D801 extends GPrinter {
  TYPE = PrinterType.GP_D801

  async addQRCode (url, position = 'center', size = 4) {
    super.addQRCode(url, position, size)
    return this
  }
}
