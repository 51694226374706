import { FlatList, Platform, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { PrintReason } from '@/constants/printing'
import { actions, useDispatch, useSelector } from '@/redux'
import { ecrGateway, loadingKey } from '@/constants'
import { getStatusConfig } from '@/libs/orderStatus'
import { useSelectedOrder } from '@/hooks/orderHistory'
import ActionButtonDialog from '@/components/dialogs/ActionButtonDialog'
import EPaymentDialog from '@/components/dialogs/EPaymentDialog'
import ItemNumberInputDialog from '@/components/dialogs/ItemNumberInputDialog'
import ModifierDialog from '@/components/dialogs/ModifierDialog'
import NewCustomerCountDialog from '@/components/dialogs/NewCustomerCountDialog'
import NumPadDialog from '@/components/dialogs/NumPadDialog'
import OrderCancelDialog from '@/components/dialogs/OrderCancelDialog'
import OrderInfoDialog from '@/pages/Order/OrderInfoDialog'
import PaymentHistoryDialog from '@/components/dialogs/PaymentHistoryDialog'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import Row from '@/components/Row'
import TableAreaDialog from '@/components/dialogs/TableAreaDialog'
import TextInputDialog from '@/components/dialogs/TextInputDialog'
import colors from '@/theme/colors'

import ActionButton from './ActionButton'

/**
 *
 * @param {*} props
 * @returns
 */
export default function ActionPanel (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // data
  const order = useSelectedOrder()
  const selectedOrderItems = useSelector(state => state.orderHistory.selectedOrderItems)
  const selectedOrderBatches = useSelector(state => state.orderHistory.selectedOrderBatches)
  const historyOrders = useSelector(state => state.orderHistory.orders)

  // settings
  const { autoUpdateOrders, addRushToTag, selectPrinterBeforePrint, isMaster } = useSelector(state => state.app.settings)
  const merchantSetting = useSelector(state => state.merchant.data?.setting) ?? {}
  const { enablePrintStaff, isAutoConfirm, enableTnDmode, enableDineInPickupNotification, payFirst } = merchantSetting
  const isSkipVerif = useSelector(state => state.merchant.data?.setting?.skipCreditCardVerification, _.isEqual)

  // dialog opening states
  const [cancelReasonDialogOpen, setCancelReasonDialogOpen] = React.useState(false)
  const [customerCountDialogOpen, setCustomerCountDialogOpen] = React.useState(false)
  const [modifierDialogOpen, setModifierDialogOpen] = React.useState(false)
  const [itemActionDialogOpen, setItemActionDialogOpen] = React.useState(false)
  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)
  const [numPadDialogOpen, setNumPadDialogOpen] = React.useState(false)
  const [numPadDialogForCodeOpen, setNumPadDialogForCodeOpen] = React.useState(false)
  const [tableAreaDialogOpen, setTableAreaDialogOpen] = React.useState(false)
  const [paymentHistoryDialogOpen, setPaymentHistoryOpen] = React.useState(false)
  const [takeawayDialogOpen, setTakeawayDialogOpen] = React.useState(false)
  const [multiSelectDialogOpen, setMultiSelectDialogOpen] = React.useState(false)
  const [orderInfoDialogOpen, setOrderInfoDialogOpen] = React.useState(false)

  // other states
  const [onSelect, setOnSelect] = React.useState(() => () => {})
  const [onCancel, setOnCancel] = React.useState(() => () => {})
  const [modifierType, setModifierType] = React.useState(null)
  const [multiSelectQuantity, setMultiSelectQuantity] = React.useState({})
  const [warningText, setWarningText] = React.useState('')

  // 付款明細加上 batch items
  const payments = order?.payments.map((payment) => {
    return { ...payment, orderId: order.id, items: _.flatMap(order.batches, batch => batch.items) }
  })

  if (!order) {
    return <View style={styles.container} />
  }

  // 有付款紀錄的 order，不可更改
  const hasPayment = order?.payments?.some(p => p.status !== 'cancel')

  const orderStatus = getStatusConfig(order)
  const actionButtons =
    selectedOrderItems.length > 0
      ? orderStatus.itemActions.filter((action) => {
        if (action.action === 'setPrice') {
          if (hasPayment) {
            return false
          }
          // 只有選擇一個 item 且 item priceUndetermined 才顯示 setPrice
          return (
            selectedOrderItems.length === 1 &&
            selectedOrderItems?.[0].priceUndetermined
          )
        }
        if (action.action === 'discount') {
          if (hasPayment) {
            return false
          }
          // 非堂食和已付款的情況下不顯示
          if (order.status === 'paid' && order.deliveryType !== 'table') {
            return false
          }
          // 已選擇的餐點中有已取消的情況下不顯示
          return selectedOrderItems.length === 1 && selectedOrderItems.find(selectedOrderItem =>
            !selectedOrderItem.cancelled,
          )
        }
        if (action.action === 'surcharge') {
          if (hasPayment) {
            return false
          }
          // 非堂食和已付款的情況下不顯示
          if (order.status === 'paid' && order.deliveryType !== 'table') {
            return false
          }
          // 項目已取消的情況下不顯示
          return selectedOrderItems.length === 1 && selectedOrderItems.find(selectedOrderItem =>
            !selectedOrderItem.cancelled,
          )
        }
        if (action.action === 'itemChangePrice') {
          if (hasPayment) {
            return false
          }
          // 非堂食和已付款的情況下不顯示
          if (order.status === 'paid' && order.deliveryType !== 'table') {
            return false
          }
          // 項目已取消的情況下不顯示
          return selectedOrderItems.length === 1 && selectedOrderItems.find(selectedOrderItem =>
            !selectedOrderItem.cancelled,
          )
        }
        if (action.action === 'itemModifier') {
          if (hasPayment) {
            return false
          }
          // 非堂食和已付款的情況下不顯示

          if (order.status === 'paid' && order.deliveryType !== 'table') {
            return false
          }

          const batchCancelled = _.map(selectedOrderItems, selectedOrderItem => order.batches.find(batch => batch.batchId === selectedOrderItem.batchId)).some(batch => batch?.status === 'cancelled')
          // 項目已取消和時價未設定的情況下不顯示
          return !batchCancelled && !selectedOrderItems?.[0].setName && selectedOrderItems.every(selectedOrderItem =>
            (!selectedOrderItem.cancelled && !selectedOrderItem.priceUndetermined),
          )
        }
        if (action.action === 'transfer') {
          if (hasPayment) {
            return false
          }
          const batchCancelled = _.map(selectedOrderItems, selectedOrderItem => order.batches.find(batch => batch.batchId === selectedOrderItem.batchId)).some(batch => batch?.status === 'cancelled')
          return !(selectedOrderItems.some(s => Boolean(s.setName) || s.cancelled) ||
          (selectedOrderItems.length > 1 && selectedOrderItems.some(s => s.isSet)) ||
          batchCancelled)
        }
        if (action.action === 'confirmBatch') {
          return selectedOrderBatches.length >= 1 && selectedOrderBatches.find(selectedOrderBatch =>
            selectedOrderBatch.status !== 'confirmed',
          )
        }
        // 刪菜
        if (action.action === 'cancelItem') {
          if (hasPayment) {
            return false
          }
          // 非堂食和已付款的情況下不顯示
          if (order.status === 'paid' && order.deliveryType !== 'table') {
            return false
          }
          const batchCancelled = _.map(selectedOrderItems, selectedOrderItem => {
            if (Boolean(selectedOrderItem.setName) && !selectedOrderItem.isSet) {
              return order.batches.find(batch => batch.items.find(i => {
                return i.setMenuIndex === selectedOrderItem.setMenuIndex && selectedOrderItem.setName === i.name
              }))
            }
            return order.batches.find(batch => batch.batchId === selectedOrderItem.batchId)
          }).some(batch => batch?.status === 'cancelled')
          return !(selectedOrderItems.some(s => s.cancelled) ||
          (selectedOrderItems.length > 1 && selectedOrderItems.some(s => s.isSet)) ||
          batchCancelled)
        }
        // 還原
        if (action.action === 'revealItem') {
          if (hasPayment) {
            return false
          }
          // 非堂食和已付款的情況下不顯示
          if (order.status === 'paid' && order.deliveryType !== 'table') {
            return false
          }
          // 已選擇的餐點中沒有已取消的情況下不顯示
          return selectedOrderItems.length === 1 && selectedOrderItems.find(selectedOrderItem =>
            selectedOrderItem.cancelled,
          )
        }
        // 追單 || 起菜
        if (action.action === 'printKitchen' || action.action === 'serveUp' || action.action === 'printChangeKitchen') {
          // 已選擇的餐點中有已取消的情況下不顯示
          const batchCancelled = _.map(selectedOrderItems, selectedOrderItem => {
            if (Boolean(selectedOrderItem.setName) && !selectedOrderItem.isSet) {
              return order.batches.find(batch => batch.items.find(i => {
                return i.setMenuIndex === selectedOrderItem.setMenuIndex && selectedOrderItem.setName === i.name
              }))
            }
            return order.batches.find(batch => batch.batchId === selectedOrderItem.batchId)
          }).some(batch => batch?.status === 'cancelled')
          return selectedOrderItems.findIndex(selectedOrderItem =>
            selectedOrderItem.cancelled,
          ) < 0 && !batchCancelled
        }
        return true
      })
      : orderStatus.orderActions.filter((action) => {
        if (action.action === 'qrcode') {
          return merchantSetting.qrcode && merchantSetting.enableDynamicQRCode
        }
        if (action.action === 'cancel') {
          return ((order.gateway === '' || order.gateway === 'bypass') ||
          (order.takeawayStatus === 'pending')) ||
          order.from === 'MERCHANT'
        }
        if (action.action === 'ready') {
          return !order?.from.includes('MERCHANT')
        }
        if (action.action === 'complete') {
          return order?.takeawayStatus === 'ready' && order?.status === 'paid'
        }
        if (action.action === 'confirmAllBatch') {
          return (
            order?.batches.find(batch => batch?.status !== 'confirmed') && autoUpdateOrders && !isAutoConfirm && isMaster
          )
        }
        if (action.action === 'checkout' && order?.deliveryType === 'takeaway') {
          return order?.status !== 'paid'
        }
        if (action.action === 'checkout') {
          // 客人使用DimPay付款中，點餐按鈕會被隱藏
          const isPayingInDimpay = order?.status === 'pending' && order?.payments.some(p => p.customerId && p.status !== 'cancel')

          // 所有的 batch 都必須是 confirmed 或 cancelled 才能結帳
          // 當 some 找到任一個 batch 非 confirmed 或 cancelled 就不留下 checkout action
          const isBatchNotConfirmed = order?.batches.some(batch => batch?.status !== 'confirmed' && batch?.status !== 'cancelled')

          return !isBatchNotConfirmed && !isPayingInDimpay
        }
        if (action.action === 'paymentHistory') {
          // 有任何不是客人付款的記錄都顯示 paymentHistory
          return payments.length > 0
        }
        if (action.action === 'order') {
          // 客人使用DimPay付款中，點餐按鈕會被隱藏
          const isPayingInDimpay =
            order?.status === 'pending' &&
            order?.payments.some(p => p.customerId && p.status !== 'cancel')
          return !enableTnDmode && !isPayingInDimpay
        }
        if (action.action === 'takeawayInfo') {
          return order.from === 'MERCHANT'
        }
        if (action.action === 'pickUpNotify') {
          return payFirst && // 餐廳先付款
            enableDineInPickupNotification && // 開啟堂食取餐通知
            !order.pickupNotifyAt && // 還沒有 pickupNotifyAt 的記錄
            !order?.batches.some(batch => batch?.status !== 'confirmed' && batch?.status !== 'cancelled') // 所有的 batch 都必須是 confirmed 或 cancelled
        }
        return true
      })

  return (
    <Row style={styles.container}>
      <FlatList
        horizontal
        data={actionButtons}
        contentContainerStyle={{
          padding: 5,
          alignItems: 'center',
          paddingHorizontal: 12,
        }}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item: actoinButtonConfig, index }) => {
          return (
            <ActionButton
              {...actoinButtonConfig}
              buttonStyle={{ width: actionButtons.length >= 8 ? 70 : 75 }}
              onPress={async () => {
                switch (actoinButtonConfig.action) {
                  // * order actions
                  case 'qrcode': {
                    // 列印 QRCode
                    if (selectPrinterBeforePrint) {
                      setOnSelect(() => async (selectedPrinterId) => {
                        await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-selectedPrinter-QRcode'))
                        dispatch(actions.printer.printQRCode(order, merchantSetting, selectedPrinterId, PrintReason.QR_CODE.CLICK_PRINT))
                      })
                      setPrinterDialogOpen(true)
                    } else {
                      await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-QRcode'))
                      dispatch(actions.printer.printQRCode(order, merchantSetting, '', PrintReason.QR_CODE.CLICK_PRINT))
                    }
                    break
                  }
                  case 'order': {
                    const permission = order.deliveryType === 'takeaway' ? 'order-takeaway' : 'merchant-staff-order'
                    // 點餐、加點
                    dispatch(actions.auth.permissionCheck(permission, () => {
                      dispatch(actions.order.startOrder(order.id))
                    }))
                    break
                  }
                  case 'checkout': {
                    // 結帳
                    dispatch(actions.auth.permissionCheck('checkout', () => {
                      dispatch(actions.app.setScopeLogin(true))
                      dispatch(actions.orderCheckout.checkoutOrder(order.id))
                    }))
                    break
                  }
                  case 'customerCount': {
                    // 更改人數
                    setCustomerCountDialogOpen(true)
                    // dispatch(actions.orderCheckout.checkoutOrder(order.id))
                    break
                  }
                  case 'cancel': {
                    // 取消、退桌、作廢、不接單
                    const typeText = order.deliveryType === 'table'
                      ? `${t('app.printing.table')}: ${order.table}${order.subTable > 0 ? `(${String.fromCharCode(order.subTable + 64)})` : ''}`
                      : order.deliveryType === 'takeaway'
                        ? t('app.page.stats.newSummary.takeaway')
                        : t('app.page.stats.newSummary.delivery')
                    setWarningText(`${t('app.printing.cancel')}: [${typeText}] ${order.serial}`)
                    if (order.status !== 'paid') {
                      dispatch(actions.auth.permissionCheck('order-cancel', () => {
                        setCancelReasonDialogOpen(true)
                        setOnCancel(() => async (text) => {
                          dispatch(actions.orderHistory.cancelOrder(order, text))
                          if (selectPrinterBeforePrint) {
                            setOnSelect(() => async (selectedPrinterId) => {
                              await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-cancel-receipt'))
                              dispatch(actions.printer.printOrderReceipt({
                                order,
                                selectedPrinterId,
                                cancelledType: 'cancel',
                                printReason: PrintReason.ORDER_RECEIPT.CLICK_CANCEL_ORDER,
                              }))
                            })
                            setPrinterDialogOpen(true)
                          } else {
                            await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-cancel-receipt'))
                            dispatch(actions.printer.printOrderReceipt({
                              order,
                              cancelledType: 'cancel',
                              printReason: PrintReason.ORDER_RECEIPT.CLICK_CANCEL_ORDER,
                            }))
                          }
                        })
                      }))
                    } else {
                      dispatch(actions.auth.permissionCheck('order-void', () => {
                        const filterPayment = order.payments.filter(payment => payment.status === 'paid')
                        if (!_.every(filterPayment, ['paymentMethod', 'cash'])) {
                          if (_.some(filterPayment, payment => Object.values(ecrGateway).includes(payment.gateway))) {
                            dispatch(actions.app.showAlert({
                              message: t('app.page.orderHistory.actionPanel.alert.haveCreditCardPayments.message'),
                              buttons: [
                                {
                                  children: t('app.page.orderHistory.actionPanel.alert.haveCreditCardPayments.continue'),
                                  onPress: () => {
                                    setPaymentHistoryOpen(true)
                                  },
                                },
                                {
                                  backgroundColor: colors.light,
                                  textColor: colors.textTertiary,
                                  children: t('app.page.order.stash.alert.leave.button.cancel'),
                                  onPress: () => { },
                                },
                              ],
                            }))
                          } else {
                            dispatch(actions.app.showAlert({
                              message: t('app.page.orderHistory.actionPanel.alert.confirmCancelPayment.message'),
                              buttons: [
                                {
                                  children: t('app.page.orderHistory.actionPanel.alert.confirmCancelPayment.continue'),
                                  onPress: () => {
                                    setCancelReasonDialogOpen(true)
                                  },
                                },
                                {
                                  backgroundColor: colors.light,
                                  textColor: colors.textTertiary,
                                  children: t('app.page.order.stash.alert.leave.button.cancel'),
                                  onPress: () => { },
                                },
                              ],
                            }))
                          }
                        } else {
                          setCancelReasonDialogOpen(true)
                        }
                        setOnCancel(() => async (text) => {
                          dispatch(actions.orderHistory.cancelOrder(order, text))
                          if (selectPrinterBeforePrint) {
                            setOnSelect(() => async (selectedPrinterId) => {
                              await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-void-receipt'))
                              dispatch(actions.printer.printOrderReceipt({
                                order,
                                selectedPrinterId,
                                cancelledType: 'void',
                                printReason: PrintReason.ORDER_RECEIPT.CLICK_VOID_ORDER,
                              }))
                            })
                            setPrinterDialogOpen(true)
                          } else {
                            await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-void-receipt'))
                            dispatch(actions.printer.printOrderReceipt({
                              order,
                              cancelledType: 'void',
                              printReason: PrintReason.ORDER_RECEIPT.CLICK_VOID_ORDER,
                            }))
                          }
                        })
                      }))
                    }
                    break
                  }
                  case 'copy': {
                    // 複製訂單
                    dispatch(actions.orderHistory.copyOrder(order.id))
                    break
                  }
                  case 'printReceipt': {
                    // 列印收據
                    const cancelledType = order.displayStatusKey === 'cancel_table'
                      ? 'cancel'
                      : order.displayStatusKey === 'delete_order'
                        ? 'void'
                        : null

                    if (selectPrinterBeforePrint) {
                      setOnSelect(() => async (selectedPrinterId) => {
                        await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-selectedPrinter-receipt'))
                        dispatch(actions.printer.printOrderReceipt({
                          order,
                          selectedPrinterId,
                          cancelledType,
                          printReason: PrintReason.ORDER_RECEIPT.CLICK_PRINT_RECEIPT,
                        }))
                      })
                      setPrinterDialogOpen(true)
                    } else {
                      await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-print-receipt'))
                      dispatch(actions.printer.printOrderReceipt({
                        order,
                        cancelledType,
                        printReason: PrintReason.ORDER_RECEIPT.CLICK_PRINT_RECEIPT,
                      }))
                    }

                    break
                  }
                  case 'confirm': {
                    // 接單
                    await dispatch(actions.orderHistory.confirmOrder(order))
                    break
                  }
                  case 'ready': {
                    // 出餐
                    await dispatch(actions.orderHistory.readyOrder(order))
                    break
                  }
                  case 'complete': {
                    // 取餐
                    if (!order.from.includes('MERCHANT') && !(isSkipVerif)) {
                      setNumPadDialogForCodeOpen(true)
                    } else {
                      await dispatch(actions.orderHistory.completeOrder(order, order.code))
                    }
                    break
                  }
                  case 'paymentHistory': {
                    // 付款紀錄
                    setPaymentHistoryOpen(true)
                    break
                  }
                  // * item actions
                  case 'setPrice': {
                    // 開啟時價設定
                    setNumPadDialogOpen(true)
                    break
                  }
                  case 'discount': {
                    // 折扣
                    dispatch(actions.auth.permissionCheck('order-discount', () => {
                      setModifierDialogOpen(true)
                      setModifierType('DISCOUNT')
                    }))
                    break
                  }
                  case 'surcharge': {
                    // 服務費
                    dispatch(actions.auth.permissionCheck('order-edit', () => {
                      setModifierDialogOpen(true)
                      setModifierType('SURCHARGE')
                    }))
                    break
                  }
                  case 'itemChangePrice': {
                    // 項目改價
                    dispatch(actions.auth.permissionCheck('order-edit', () => {
                      setModifierDialogOpen(true)
                      setModifierType('MERCHANT')
                    }))
                    break
                  }
                  case 'itemModifier': {
                    setItemActionDialogOpen(true)
                    break
                  }
                  case 'cancelItem': {
                    // 刪菜
                    dispatch(actions.auth.permissionCheck('order-edit', async () => {
                      if (selectedOrderItems.every(i => i.quantity === 1)) {
                        if (Platform.OS !== 'web') {
                          await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-cancelItem'))
                        }
                        setWarningText(`${t('app.page.stats.cancel.cancelledItem')}`)
                        setCancelReasonDialogOpen(true)
                        setOnCancel(() => async (text) => {
                          const multiQuantity = {}
                          _.forEach(selectedOrderItems, selectedOrderItem => {
                            multiQuantity[selectedOrderItem.id] = 1
                          })
                          dispatch(actions.orderHistory.cancelSelectedItem(text, multiQuantity))
                          dispatch(actions.orderHistory.resetSelectedOrderItems())
                        })
                      } else {
                        setMultiSelectDialogOpen(true)
                        setOnSelect(() => async (value) => {
                          if (Platform.OS !== 'web') {
                            await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-cancelItem'))
                          }
                          setMultiSelectQuantity(value)
                          const itemText = _.filter(selectedOrderItems, i => value[i.id]).map(i => `${i.name}x${value[i.id]}`).join()
                          setWarningText(`${t('app.page.stats.cancel.cancelledItem')}: ${itemText}`)
                          setCancelReasonDialogOpen(true)
                          setOnCancel(() => async (text) => {
                            dispatch(actions.orderHistory.cancelSelectedItem(text, value))
                            dispatch(actions.orderHistory.resetSelectedOrderItems())
                            setMultiSelectQuantity({})
                          })
                        })
                      }
                    }))
                    break
                  }
                  case 'revealItem': {
                    // 還原
                    dispatch(actions.orderHistory.revealSelectedItem())
                    break
                  }
                  case 'transfer': {
                    // 轉移
                    dispatch(actions.auth.permissionCheck('item-transfer', () => {
                      if (selectedOrderItems.every(i => i.quantity === 1)) {
                        dispatch(actions.table.updateSelectingTable(true))
                        setTableAreaDialogOpen(true)
                      } else {
                        setOnSelect(() => async (value) => {
                          setMultiSelectQuantity(value)
                          dispatch(actions.table.updateSelectingTable(true))
                          setTableAreaDialogOpen(true)
                        })
                        setMultiSelectDialogOpen(true)
                      }
                    }))
                    break
                  }
                  case 'serveUp': {
                    // 起菜
                    if (selectedOrderItems.every(i => i.quantity === 1)) {
                      if (Platform.OS !== 'web') {
                        await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-printKitchen'))
                      }
                      const value = {}
                      _.each(selectedOrderItems, item => {
                        value[item.id] = 1
                      })
                      dispatch(actions.orderHistory.serveUpSelectedItems(order, value))
                    } else {
                      setOnSelect(() => async (value) => {
                        if (Platform.OS !== 'web') {
                          await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-serveUp'))
                        }
                        dispatch(actions.orderHistory.serveUpSelectedItems(order, value))
                      })
                      setMultiSelectDialogOpen(true)
                    }
                    break
                  }
                  case 'printKitchen': {
                    // 追單
                    const printConfig = {
                      reprint: true,
                      printStaff: enablePrintStaff,
                    }

                    /**
                     * 列印追單
                     * @param {{[itemId: string]: number}} itemsQuantity
                     */
                    const printKitchen = async (itemsQuantity) => {
                      if (Platform.OS !== 'web') {
                        await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-printKitchen'))
                      }
                      const printItems = selectedOrderItems.filter(item => _.get(itemsQuantity, item.id))
                      const groupedBatchItems = _.groupBy(printItems, 'batchId')

                      for (const batchId in groupedBatchItems) {
                        const batch = order.batches.find(batche => batche.id === batchId)
                        await dispatch(actions.printer.printKitchenBatch(order, groupedBatchItems[batchId], printConfig, false, batch, itemsQuantity))
                      }
                      if (addRushToTag) {
                        dispatch(actions.orderHistory.selectedItemAddTag(order, itemsQuantity, { id: 'printKitchen', name: t('app.constant.orderAction.printKitchen') }))
                      }
                    }

                    if (selectedOrderItems.every(item => item.quantity === 1)) {
                      // 當選擇要追單的餐點，數量都只有 1 時直接列印即可
                      const itemsQuantity = {}
                      _.each(selectedOrderItems, item => {
                        itemsQuantity[item.id] = 1
                      })
                      await printKitchen(itemsQuantity)
                    } else {
                      // 當有餐點數量不是 1，需要先詢問要追單的數量
                      setOnSelect(() => async (itemsQuantity) => await printKitchen(itemsQuantity))
                      setMultiSelectDialogOpen(true)
                    }
                    break
                  }
                  case 'printChangeKitchen': {
                    // 改單
                    const printConfig = {
                      change: true,
                      printStaff: enablePrintStaff,
                    }
                    if (Platform.OS !== 'web') {
                      await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-printKitchen'))
                    }
                    const groupedBatchItems = _.groupBy(selectedOrderItems, 'batchId')
                    for (const [key, groupedBatchItem] of Object.entries(groupedBatchItems)) {
                      const batch = order.batches.find(batche => batche.id === key)
                      await dispatch(actions.printer.printKitchenBatch(order, groupedBatchItem, printConfig, false, batch))
                    }
                    break
                  }
                  case 'resetSelect': {
                    // 取消操作
                    dispatch(actions.orderHistory.resetSelectedOrderItems())
                    break
                  }
                  case 'confirmAllBatch': {
                    // 確認全部訂單
                    dispatch(actions.orderHistory.confirmAllBatch(order))
                    break
                  }
                  case 'confirmBatch': {
                    // 確認選取了的訂單
                    selectedOrderBatches.forEach((selectedOrderBatch) => {
                      dispatch(actions.orderHistory.confirmBatch(order, selectedOrderBatch))
                    })
                    break
                  }
                  case 'selectAllItems' : {
                    dispatch(actions.orderHistory.selectAllItems())
                    break
                  }
                  case 'takeawayInfo': {
                    setOrderInfoDialogOpen(true)
                    break
                  }
                  case 'pickUpNotify': {
                    dispatch(actions.app.openLoading(loadingKey.PICKUP_NOTIFY, 'ActionPanel-PickupNotify'))
                    dispatch(actions.orderHistory.sendPickupNotify(order.id))
                    break
                  }
                }
              }}
            />
          )
        }}
        keyExtractor={(item) => item.action}
      />

      <OrderCancelDialog
        open={cancelReasonDialogOpen}
        onClose={() => {
          dispatch(actions.app.closeLoading(loadingKey.PRINT, 'ActionPanel-cancelItem'))
          setCancelReasonDialogOpen(false)
          setWarningText('')
        }}
        title={t('app.component.cancelReasonDialog.title')}
        placeholder={t('app.component.cancelReasonDialog.placeholder')}
        onSubmit={(text) => {
          onCancel(text)
        }}
        orderType={order.deliveryType}
        isItem={selectedOrderItems?.length}
        warningText={warningText}
      />
      <TextInputDialog
        keyboardType='number-pad'
        open={takeawayDialogOpen}
        onClose={() => setTakeawayDialogOpen(false)}
        title={t('app.component.takeawayDialog.title')}
        placeholder={t('app.component.takeawayDialog.placeholder')}
        onSubmit={(text) => {
          dispatch(
            actions.orderHistory.getTakeawayOrderByCode(text),
          )
        }}
      />
      <ActionButtonDialog
        open={itemActionDialogOpen}
        onClose={() => setItemActionDialogOpen(false)}
        onPress={(type, value) => {
          setItemActionDialogOpen(false)
          setTimeout(() => {
            dispatch(actions.auth.permissionCheck('order-discount', () => {
              const onPress = (quantity) => {
                if (type === 'SURCHARGE') {
                  dispatch(actions.orderHistory.updateSurcharge(order.id, value, quantity))
                } else {
                  setModifierDialogOpen(true)
                  setModifierType(type)
                  setOnSelect(() => (modifier) => {
                    dispatch(actions.orderHistory.updateSelectedItemModifier(modifier, quantity))
                  })
                }
              }
              if (selectedOrderItems.length === 1 && selectedOrderItems[0].quantity === 1) {
                onPress({ [selectedOrderItems[0].id]: 1 })
              } else {
                setMultiSelectDialogOpen(true)
                setOnSelect(() => (quantity) => {
                  onPress(quantity)
                })
              }
            }))
          }, 200)
        }}
      />
      <ModifierDialog
        key={ModifierDialog + String(modifierDialogOpen)}
        amount={selectedOrderItems?.[0]?.prices[0]}
        open={modifierDialogOpen}
        modifierType={modifierType}
        onSubmit={onSelect}
        onClose={() => setModifierDialogOpen(false)}
        showDefault={modifierType === 'DISCOUNT'}
        isSet={selectedOrderItems?.[0]?.isSet}
      />
      <NumPadDialog
        key={NumPadDialog + numPadDialogOpen}
        open={numPadDialogOpen}
        onClose={() => setNumPadDialogOpen(false)}
        title={t('app.component.priceInputDialog.title')}
        enablePressOutsideClose
        defaultValue={selectedOrderItems?.[0]?.price ?? 0}
        onSubmit={(price) => {
          dispatch(actions.orderHistory.updateSelectedItemPrice(Number(price)))
          setNumPadDialogOpen(false)
          dispatch(actions.orderHistory.resetSelectedOrderItems())
        }}
      />
      <NumPadDialog
        open={numPadDialogForCodeOpen}
        onClose={() => setNumPadDialogForCodeOpen(false)}
        title={t('app.component.takeawayDialog.title')}
        defaultValue=''
        clearValue=''
        keepZero
        useCode
        maxLength={4}
        disableDecimalPoint
        onSubmit={async (value) => {
          setNumPadDialogForCodeOpen(false)
          await dispatch(actions.app.openLoading(loadingKey.TAKEAWAY, 'ActionPanel-getTakeawayOrderByCode'))
          await dispatch(actions.orderHistory.getTakeawayOrderByCode(value, order))
          dispatch(actions.app.closeLoading(loadingKey.TAKEAWAY))
        }}
      />
      <TableAreaDialog
        open={tableAreaDialogOpen}
        onClose={() => {
          dispatch(actions.table.updateSelectingTable(false))
          setTableAreaDialogOpen(false)
        }}
        onSelectTable={(table) => {
          const hasTablePendingOrder = _.some(historyOrders, order => {
            return _.includes(table.orderIds, order.id) && order.status === 'pending'
          })
          if (!hasTablePendingOrder) {
            setTableAreaDialogOpen(false)
            dispatch(actions.orderHistory.transferSelectedItems(undefined, multiSelectQuantity, table.key))
            setMultiSelectQuantity({})
          }
        }}
        onSelectOrder={(order) => {
          dispatch(actions.orderHistory.transferSelectedItems(order.id, multiSelectQuantity))
          setMultiSelectQuantity({})
        }}
      />
      <ItemNumberInputDialog
        open={multiSelectDialogOpen}
        onClose={() => setMultiSelectDialogOpen(false)}
        items={selectedOrderItems}
        onSubmit={onSelect}
      />
      <PaymentHistoryDialog
        orderStatus={order.status}
        payments={payments}
        open={paymentHistoryDialogOpen}
        onClose={() => setPaymentHistoryOpen(false)}
        showDetailButton
      />
      <NewCustomerCountDialog
        open={customerCountDialogOpen}
        order={order}
        onClose={() => setCustomerCountDialogOpen(false)}
        setOpenCustomerCountDialog={setCustomerCountDialogOpen}
      />
      <PrinterDialog
        open={printerDialogOpen}
        onClose={() => setPrinterDialogOpen(false)}
        onSelect={(selectedPrinterId) => {
          onSelect(selectedPrinterId)
        }}
      />
      <OrderInfoDialog
        open={orderInfoDialogOpen}
        onClose={() => setOrderInfoDialogOpen(false)}
        onSubmit={() => {
          setOrderInfoDialogOpen(false)
        }}
        isOrderHistory
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 103,
    alignItems: 'center',
    backgroundColor: colors.lightGray2,
  },
})
