import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SettingListFooterAddButtonProps
 * @property {string} text
 * @property {() => void} onPress
 * @property {boolean?} disabled
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {SettingListFooterAddButtonProps} props
 * @returns
 */
export default function SettingListFooterAddButton (props) {
  const { onPress, disabled, text, style } = props
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled || !onPress}>
      <View style={[styles.container, style, disabled && styles.disabled]}>
        <Text style={styles.text}>＋ {text}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    flexDirection: 'row',
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomStartRadius: 5,
    borderBottomEndRadius: 5,
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.white,
  },
  disabled: {
    opacity: 0.4,
  },
})
