import { PrinterFontSize } from '@/constants/printer'

import GPrinter from '../printer/GPrinter'

export default class PrintDoc {
  //  All supported print doc types
  static Types = {
    QRCODE: 'QRCode',
    DRAWER_KICK: 'DrawerKick',
    KITCHEN_BATCH: 'KitchenBatch',
    REPORT: 'Report',
    ORDER_RECEIPT: 'OrderReceipt',
    TEST_PRINT: 'TestPrint',
    TEST_CONNECT: 'TestConnect',
    LABEL: 'Label',
    CUSTOMIZED_ORDER_RECEIPT: 'CustomizedOrderReceipt',
    CUSTOMIZED_KITCHEN_BATCH: 'CustomizedKitchenBatch',
    SET_LOGO: 'SetLogo',
  }

  TYPE = ''
  printer = new GPrinter() // 這個好像沒必要，而且不是所有printer都是GPrinter，log會出現錯誤的msg

  log = () => {
    return {
      printId: this.printId,
      printHash: this.printHash,
      printReason: this.printReason,
      printerSettingName: this.printerSetting?.name,
      printerSettingId: this.printerSetting?.id,
      orderSerial: this.order?.serial,
      orderId: this.order?.id,
      batchId: this.batch?.id,
      type: this.TYPE,
    }
  }

  addBackupText = () => {
    const printer = this.printer
    printer.addInit()
    printer.addCodePage()
    printer.addRowFeed()
    printer.addAlign('center')
    printer.addBoldMode()
    printer.addText('***後備打印***', PrinterFontSize.LARGE)
    printer.addRowFeed(3)
  }
}
