import { FlatList, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'

import { PrintStyle } from '@/constants/printing'
import { v4 as uuid } from 'uuid'

export default function CustomizedKitchenPanel (props) {
  const { onSelect } = props
  const { t } = useTranslation()

  const settings = useSelector(state => state.printer.printerSetting.customizedKitchenSettings) || []

  const renderItem = ({ item, index }) => {
    return (
      <SettingListItem
        key={index}
        onPress={() => {
          onSelect(item)
        }}
        divider={index !== item.length - 1}
      >
        <SettingListItemText text={item.name} />
      </SettingListItem>
    )
  }

  return (
    <View style={styles.contentContainer}>
      <Header>
        <HeaderText text={t('app.routes.setting.printing.customized')} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={settings}
            ListEmptyComponent={(
              <View style={styles.emptyView}>
                <Text>{t('app.page.setting.printer.noSetting')}</Text>
              </View>
            )}
            keyExtractor={(item, index) => String(index + item?.id)}
            renderItem={renderItem}
          />
          <SettingListFooterAddButton
            text={t('app.page.setting.printer.add') + t('app.page.setting.printer.setting')}
            onPress={() => {
              onSelect({
                name: '',
                style: defaultKitchenStyle,
                lineSpacing: 1,
              })
            }}
          />
        </SettingList>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labels: {
    padding: 0,
  },
})

const defaultKitchenStyle = [
  {
    key: 'info',
    enable: true,
    id: uuid(),
    content: {
      preOrderAlert: {
        key: 'preOrderAlert',
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 2,
        bold: true,
        underline: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        index: 0,
        color: PrintStyle.COLOR.BLACK,
        specifiedDayTime: {
          day: 0,
          hour: 3,
        },
      },
      status: {
        key: 'status',
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        color: PrintStyle.COLOR.RED,
        index: 1,
      },
      tableNo: {
        key: 'tableNo', // 枱號
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        color: PrintStyle.COLOR.BLACK,
        index: 2,
        printTable: true,
      },
      customerNo: {
        key: 'customerNo', // 人數
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        color: PrintStyle.COLOR.BLACK,
        index: 3,
      },
      serialNo: {
        key: 'serialNo', // 單號
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        color: PrintStyle.COLOR.BLACK,
        last3Digit: {
          fontSize: 1,
          bold: false,
          enable: false,
        },
        index: 4,
      },
      pickupAt: {
        key: 'pickupAt',
        enable: true,
        type: PrintStyle.TYPE.TIME,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: true,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        format: PrintStyle.TIME.LONG24,
        color: PrintStyle.COLOR.BLACK,
        index: 5,
      },
      batchIndex: {
        key: 'batchIndex', // 單號
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: true,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        color: PrintStyle.COLOR.BLACK,
        index: 6,
      },
      createAt: {
        key: 'createAt',
        enable: true,
        type: PrintStyle.TYPE.TIME,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: true,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        format: PrintStyle.TIME.SHORT24,
        color: PrintStyle.COLOR.BLACK,
        index: 7,
      },
    },
    drag: true,
  },
  {
    key: 'content',
    enable: true,
    fontSize: 1,
    format: ['quantity', 'item', 'price'],
    settings: {
      imageMode: false, // ! deprecated
      showItemPrice: false,
      showOptionPrice: false,
      showSetName: false,
      hideSetText: false,
      hideSetItemText: false,
      leading: false,
      setItemLeading: false,
      itemSeparator: false,
      setItemSeparator: false,
      lineSpacing: 1,
      setItemlineSpacing: 1,
      itemQuantityFont: 1,
      option: {
        fontSize: 1,
        color: PrintStyle.COLOR.RED,
      },
      remark: {
        fontSize: 1,
        color: PrintStyle.COLOR.RED,
      },
      tag: {
        fontSize: 1,
        color: PrintStyle.COLOR.RED,
      },
    },
    id: uuid(),
    drag: true,
  },
  {
    key: 'footer', // 頁尾
    enable: true,
    id: uuid(),
    drag: true,
    content: {
      tableNo: {
        key: 'tableNo', // 枱號
        enable: false,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        color: PrintStyle.COLOR.BLACK,
        index: 0,
        printTable: true,
      },
      status: {
        key: 'status',
        enable: false,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        underline: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        color: PrintStyle.COLOR.BLACK,
        index: 1,
      },
    },
  },
]
