import { FlatList, Pressable, StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { calculateStepIsMax, calculateStepQuantityTotal, getStepQuantityMax, isBetweenDayTime } from '@/libs/order'
import { useSearchedMenu } from '@/hooks/search'
import { useSelectedBatchItem, useSelectedOrder, useSelectedSetMenuIndex } from '@/hooks/order'

import { useTranslation } from 'react-i18next'
import CodeSearchNumPad from './CodeSearchNumPad'
import MenuItem from './MenuItem'
import MenuItemFolder from './MenuItemFolder'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
export default function TextMenu (props) {
  const { t } = useTranslation()
  const isNumPadOrder = useSelector(state => state.app.settings?.numPadOrder)
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const selectedSet = useSelector(state => state.menu.selectedSet)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  const isfilterByTime = useSelector(state => state.menu.isfilterByTime)
  const selectedItem = useSelectedBatchItem()[0]
  const selectedSetMenuIndex = useSelectedSetMenuIndex()
  const selectedOrder = useSelectedOrder()
  const isMax = calculateStepIsMax(selectedItem, selectedSetStep, selectedSetMenuIndex)
  const setItems = selectedItem?.setItems
  const stepItemQuantity = calculateStepQuantityTotal(selectedItem, selectedSetStep, selectedSetMenuIndex)
  const stepMax = getStepQuantityMax(selectedItem, selectedSetStep)
  const stepCount = stepMax - stepItemQuantity
  const dispatch = useDispatch()
  const index = selectedSet?.steps.findIndex(o => o === selectedSetStep)
  const action = _.throttle(() => dispatch(actions.menu.selectSetStep(selectedSet.steps[index + 1])), 1000, { trailing: false })
  const setNextStepButton = useSelector(state => state.app.settings.setNextStepButton)
  const scrollRef = React.useRef()

  const menuItems = useSearchedMenu()?.filter(item => {
    const isBetween = isBetweenDayTime(item)

    return isBetween &&
    !((!item.categoryId &&
      !item.step &&
      item.categories?.length === 0 &&
      item.menus?.length === 0 &&
      item.sets?.length === 0) ||
      item.deleted ||
      item.disabled ||
      (selectedCategory?.type === 'SINGLE' && item.isSet) ||
      (selectedOrder.deliveryType === 'takeaway' && item.takeawayDisabled) ||
      (selectedOrder.deliveryType === 'table' && item.takeawayOnly))
    // 不顯示空的分類
  })

  React.useEffect(() => {
    if (selectedCategory) {
      scrollRef.current.scrollToOffset({
        animated: false,
        offset: 0,
      })
    }
  }, [selectedCategory])

  if (!selectedCategory) return null

  const showNumberPad = isNumPadOrder && !selectedSet
  const isSetMenuItems = menuItems.some(item => item.step)
  if (isSetMenuItems && setNextStepButton) {
    const allSteps = _.map(selectedSet?.steps, (s, i) => {
      return { ...s, stepIndex: i }
    })
    const selectedSetItems = selectedItem.setItems.map(s => s.setMenuId)
    const steps = _.drop(allSteps, index + 1)
    const nextStep = steps.find(step => {
      return (
        !step.dependsOnSetMenuItem ||
        selectedSetItems.includes(step.dependsOnSetMenuItem)
      ) &&
      (
        !step.dependsOnSetMenuItems?.length ||
        selectedSetItems.some(s => step.dependsOnSetMenuItems.includes(s))
      )
    })
    const nextStepIndex = _.get(nextStep, 'stepIndex', false)
    if (nextStepIndex) {
      menuItems.unshift({
        id: 'nextSetStep',
        isNextSetStepButton: true,
        nextStepIndex,
      })
    }
  }

  const renderItem = ({ item }) => {
    if (item.isNextSetStepButton) {
      return (
        <TouchableHighlight
          style={styles.button}
          underlayColor={colors.darkSecondary}
          onPress={action}
          key={item.id}
        >
          <View style={styles.nameView}>
            <Text style={[styles.text, { flex: 1 }]} numberOfLines={3} ellipsizeMode='tail' wrap>{t('app.page.order.orderMenu.nextStep')}</Text>
          </View>
        </TouchableHighlight>
      )
    }

    if (!item.categoryId && !item.step) {
      // 沒有 categoryId 和 stepId, 表示他本身是 category
      return <Pressable key={item.id}><MenuItemFolder category={item} /></Pressable>
    }

    // 有 categoryId 或 stepId, 表示他本身是 category/set 下的 menu item
    return <Pressable key={item.id}><MenuItem item={item} isMax={isMax} stepCount={stepCount} setItems={setItems} /></Pressable>
  }

  return (
    <View style={styles.container}>
      <FlatList
        ref={scrollRef}
        style={[styles.flatList, showNumberPad && styles.numPadFlatList]}
        contentContainerStyle={styles.flatListContent}
        showsVerticalScrollIndicator={false}
        key={String(showNumberPad)}
        numColumns={showNumberPad ? 2 : 3}
        data={menuItems}
        renderItem={renderItem}
        keyExtractor={item => item.id}
      />
      {showNumberPad && (
        <CodeSearchNumPad />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: 600,
    height: 240,
    alignSelf: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  flatList: {
    width: '100%',
    height: '100%',
  },
  flatListContent: {
    // alignSelf: 'flex-end',
    // alignItems: 'flex-start',
  },
  numPadFlatList: {
    width: 426,
  },
  button: {
    width: 180,
    height: 70,
    borderRadius: 8,
    backgroundColor: colors.secondary,
    overflow: 'hidden',
    marginBottom: 4,
    marginHorizontal: 10,
  },
  nameView: {
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
  },
  text: {
    color: colors.white,
    fontSize: 25,
    fontWeight: '500',
    lineHeight: 25,
    textAlign: 'center',
    margin: 2,
  },
})
