import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import { dimorderApi } from '@/libs/api/dimorder'
import ActionTypes from './ActionTypes'
import actions from '../actions'

/**
 * 根據 merchant.data 取得 openings
 * 抓 customReason
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch(getOpeningSetting())
    await dispatch(getCustomReason())
    dispatch({
      type: ActionTypes.INIT_DATA,
    })
  }
}

/**
 * 從 merchant 拿營業時間設定
 * @returns {ThunkFunction}
 */
export function getOpeningSetting () {
  return (dispatch, getState) => {
    const openingWithSurcharge = getState().merchant.data.openingsWithSurcharge

    const openingsArray = []
    for (let i = 0; i < 7; i++) {
      if (openingWithSurcharge === undefined || openingWithSurcharge[i] === undefined) {
        openingsArray.push([])
      } else {
        openingsArray.push(openingWithSurcharge[i].map((opening, index) => {
          return {
            day: i, // 星期幾
            id: uuid(), // local識別id
            index,
            ...opening,
          }
        }))
      }
    }

    dispatch({
      type: ActionTypes.UPDATE_OPENING_SETTING,
      payload: {
        openings: openingsArray,
      },
    })
  }
}

/**
 * 打開選項頁面
 * @param {Array} path
 */
export function showSettingOptions (config) {
  return {
    type: ActionTypes.SHOW_SETTING_OPTIONS,
    payload: { config },
  }
}

/**
 * 關閉選項頁面
 */
export function closeSettingOptions () {
  return {
    type: ActionTypes.CLOSE_SETTING_OPTIONS,
  }
}

/**
 * 重置子畫面
 */
export function resetAllSubView () {
  return {
    type: ActionTypes.RESET_ALL_SUB_VIEW,
  }
}

/**
 * @param {string} searchText
 * @returns {ThunkFunction}
 */
export function updateSearchText (searchText) {
  return (dispatch, getState) => {
    const prevSearchText = getState().setting.searchText

    if (searchText === prevSearchText) return // searchText 沒有改變時不用處理

    dispatch({
      type: ActionTypes.UPDATE_SEARCH_TEXT,
      payload: { searchText },
    })
    dispatch(actions.menu.filterCategoryWithSearchText())
  }
}

/**
 * 新增營業時間
 * @param {object} localOpening
 * @returns {ThunkFunction}
 */
export function createOpening (localOpening) {
  return async (dispatch, getState) => {
    const openingWithSurcharge = getState().merchant.data.openingsWithSurcharge

    // 在selectedDay，加上一個新的營業時間
    let selectedDay
    if (openingWithSurcharge[localOpening.day] === undefined) {
      selectedDay = [
        {
          ...localOpening,
          start: Number(localOpening.start) || 0,
          startMin: Number(localOpening.startMin) || 0,
          end: Number(localOpening.end) || 0,
          endMin: Number(localOpening.endMin) || 0,
          orderBeforeMin: Number(localOpening.orderBeforeMin) || 0,
          surcharge: {
            percent: Number(localOpening.surcharge) || 0,
            amount: 0,
          },
          discount: Number(localOpening.discount) || 0,
        }]
    } else {
      selectedDay = [
        ...openingWithSurcharge[localOpening.day],
        {
          ...localOpening,
          start: Number(localOpening.start) || 0,
          startMin: Number(localOpening.startMin) || 0,
          end: Number(localOpening.end) || 0,
          endMin: Number(localOpening.endMin) || 0,
          orderBeforeMin: Number(localOpening.orderBeforeMin) || 0,
          surcharge: {
            percent: Number(localOpening.surcharge) || 0,
            amount: 0,
          },
          discount: Number(localOpening.discount) || 0,
        }]
    }

    // 取代原本day設定
    const updatedOpenings = _.mapValues({ ...openingWithSurcharge, [localOpening.day]: selectedDay }, day => {
      const updatedDay = _.map([...day], time => {
        const updatedTime = { ...time, discount: Number(_.isObject(time.discount) ? time.discount.percent : time.discount) || 0 }
        return updatedTime
      })
      return updatedDay
    })

    dispatch(actions.merchant.updateOpening(updatedOpenings))
  }
}

/**
 * 更新營業時間
 * @param {object} opening
 * @returns {ThunkFunction}
 */
export function updateOpening (opening) {
  return async (dispatch, getState) => {
    const openingWithSurcharge = getState().merchant.data.openingsWithSurcharge
    // 拿掉local識別的欄位(id, day, index)，並轉換資料格式
    const { id, day, index, ...others } = opening
    const updatedOpening = {
      ...others,
      start: Number(opening.start) || 0,
      startMin: Number(opening.startMin) || 0,
      end: Number(opening.end) || 0,
      endMin: Number(opening.endMin) || 0,
      orderBeforeMin: Number(opening.orderBeforeMin) || 0,
      surcharge: {
        ...others.surcharge,
        percent: Number(opening.surcharge) || 0,
      },
      discount: Number(opening.discount) || 0,
    }

    // 在selectedDay中，找到營業時間設定並取代
    const selectedDay = openingWithSurcharge[day]
      .map((o, i) => i === index ? updatedOpening : o,
      )
    // 取代原本day設定
    const updatedOpenings = _.mapValues({ ...openingWithSurcharge, [day]: selectedDay }, day => {
      const updatedDay = _.map([...day], time => {
        const updatedTime = { ...time, discount: Number(_.isObject(time.discount) ? time.discount.percent : time.discount) || 0 }
        return updatedTime
      })
      return updatedDay
    })

    dispatch(actions.merchant.updateOpening(updatedOpenings))
  }
}

/**
 * 刪除營業時間
 * @param {object} opening
 * @returns {ThunkFunction}
 */
export function deleteOpening (opening) {
  return async (dispatch, getState) => {
    const openingWithSurcharge = getState().merchant.data.openingsWithSurcharge
    const { day, index } = opening
    // 在selectedDay中，找到營業時間設定並移除
    const selectedDay = openingWithSurcharge[day]
      .filter((o, i) => i !== index)
    // 取代原本day設定
    const updatedOpenings = _.mapValues({ ...openingWithSurcharge, [day]: selectedDay }, day => {
      const updatedDay = _.map([...day], time => {
        const updatedTime = { ...time, discount: Number(_.isObject(time.discount) ? time.discount.percent : time.discount) || 0 }
        return updatedTime
      })
      return updatedDay
    })

    dispatch(actions.merchant.updateOpening(updatedOpenings))
  }
}

/**
 * 更改服務費
 * @returns {ThunkFunction}
 */
export function updateSurcharge (percent) {
  return async (dispatch, getState) => {
    const surcharge = {
      percent: Number(percent),
      amount: 0,
      overrideItem: false,
    }
    await dispatch(actions.merchant.updateSurcharge(surcharge))
  }
}

/**
 * 更改小數處理
 * @returns {ThunkFunction}
 */
export function updateRounding (type) {
  return async (dispatch, getState) => {
    const digits = getState().merchant.data.rounding.digits
    const rounding = {
      type,
      digits: 0,
    }
    if (type === '') {
      rounding.digits = 1
    }

    await dispatch(actions.merchant.updateRounding(rounding))
  }
}

/**
 * 更改小數位數
 * @returns {ThunkFunction}
 */
export function updateRoundingDigits (digits) {
  return async (dispatch, getState) => {
    const type = getState().merchant.data.rounding.type
    const rounding = {
      type,
      digits,
    }
    await dispatch(actions.merchant.updateRounding(rounding))
  }
}

/**
 * 建立單點分類
 * @returns {ThunkFunction}
 */
export function createMenuCategory () {
  return async (dispatch, getState) => {
    const selectedCategory = getState().setting.selectedCategory
    const rootCategory = getState().menu.categories.ROOT
    const categories = getState().menu.categories
    let parentCategory = selectedCategory?.name ? selectedCategory : rootCategory
    if (parentCategory.path === 'PROMOTED' || parentCategory.path === 'SOLDOUT') {
      parentCategory = rootCategory
    }
    if (parentCategory?.parentId !== 'ROOT' && parentCategory.path !== 'ROOT') {
      const id = parentCategory?.parentId
      parentCategory = categories[id]
    }

    const nextIndex = parentCategory.categories?.length || 0

    const category = {
      name: '',
      shortName: '',
      desc: '',
      code: '',
      type: 'SINGLE',
      parentId: parentCategory.id,
      path: parentCategory.path + `.categories[${nextIndex}]`,
      timerange: [],
      weekdays: [],
      waiterOnly: false,
      takeawayOnly: false,
      localeNames: {
        zh: '',
        en: '',
      },
      localeDesc: {
        zh: '',
        en: '',
      },
    }

    dispatch(selectCategory(category))
  }
}

/**
 * 建立套餐分類
 * @returns {ThunkFunction}
 */
export function createSetCategory () {
  return async (dispatch, getState) => {
    const selectedCategory = getState().setting.selectedCategory
    const rootCategory = getState().menu.categories.SET_ROOT
    const categories = getState().menu.categories
    let parentCategory = selectedCategory?.name ? selectedCategory : rootCategory
    if (parentCategory?.parentId !== 'SET_ROOT' && parentCategory.path !== 'SET_ROOT') {
      const id = parentCategory?.parentId
      parentCategory = categories[id]
    }

    const nextIndex = parentCategory.categories?.length || 0

    const category = {
      name: '',
      shortName: '',
      desc: '',
      code: '',
      type: 'SET',
      parentId: parentCategory.id,
      path: parentCategory.path + `.categories[${nextIndex}]`,
      timerange: [],
      weekdays: [],
      waiterOnly: false,
      takeawayOnly: false,
    }

    dispatch(selectCategory(category))
  }
}

/**
 * 選擇分類
 * @returns {ThunkFunction}
 */
export function selectCategory (category) {
  return async (dispatch, getState) => {
    const selectedCategory = getState().setting.selectedCategory
    if (!_.isEqual(category, selectedCategory)) {
      dispatch({
        type: ActionTypes.SELECT_CATEGORY,
        payload: { category },
      })
    }
  }
}

/**
 * 自定取消原因
 * @returns {ThunkFunction}
 */
export function getCustomReason () {
  return async (dispatch, getState) => {
    try {
      const customReason = await dimorderApi.customReason.getCustomReason()
      dispatch({
        type: ActionTypes.UPDATE_CUSTOM_REASON,
        payload: { customReason },
      })
    } catch (error) {
      console.log('getCustomReason error', error)
    }
  }
}

export function upsertCustomReason (customReason) {
  return async (dispatch, getState) => {
    try {
      const customReasons = getState().setting.customReason || []
      const updateResult = [...customReasons]
      if (!customReason.id) {
        const result = await dimorderApi.customReason.createCustomReason(customReason)
        updateResult.push(result)
      } else {
        const result = await dimorderApi.customReason.updateCustomReason(customReason.id, customReason)
        const index = updateResult.findIndex(r => r.id === result.id)
        updateResult[index] = result
      }
      dispatch({
        type: ActionTypes.UPDATE_CUSTOM_REASON,
        payload: { customReason: updateResult },
      })
    } catch (error) {
      console.log('upsertCustomReason error', error)
    }
  }
}

export function deleteCustomReason (id) {
  return async (dispatch, getState) => {
    try {
      const customReasons = getState().setting.customReason || []
      const updateResult = [...customReasons]
      await dimorderApi.customReason.deleteCustomReason(id)
      const index = updateResult.findIndex(r => r.id === id)
      _.remove(updateResult, (d, i) => index === i)
      dispatch({
        type: ActionTypes.UPDATE_CUSTOM_REASON,
        payload: { customReason: updateResult },
      })
    } catch (error) {
      console.log('deleteCustomReason error', error)
    }
  }
}
