import React from 'react'

import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemEnter from '@/components/Setting/SettingListItemEnter'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 * @typedef EnterRowProps
 * @property {string} label
 * @property {string} value
 * @property {boolean?} divider
 * @property {boolean?} selected
 * @property {() => void} onPress
 */

/**
 *
 * @param {EnterRowProps} props
 * @returns
 */
export default function EnterRow (props) {
  const { divider, selected, label, value, onPress } = props
  return (
    <SettingListItem
      divider={divider}
      selected={selected}
      onPress={onPress}
    >
      <SettingListItemText text={label} />
      <SettingListItemEnter text={value} />
    </SettingListItem>
  )
}
