const productionConfig = {
  wssUrl: 'wss://api.dimorder.com/',
  apiUrls: {
    go: 'https://api.dimorder.com',
    node: 'https://node.dimorder.com',
    urlShortener: 'https://s.dimorder.com',
  },
  clientUrls: {
    customerWeb: 'https://dimorder.com',
    merchantFrontend: 'https://merchant.dimorder.com',
    customerAppWeb: 'https://c.dimorder.com',
    customerApp: 'https://app.dimorder.com',
  },
}

export default productionConfig
