import { useSelector } from '@/redux'
import NumberInputAction from './NumberInputAction'
import NumberQuickAction from './NumberQuickAction'
import React from 'react'

export default function NumberInput (props) {
  const isInputMode = useSelector(state => state.app.settings.numberBarInputMode)
  return (
    isInputMode ? <NumberInputAction /> : <NumberQuickAction />
  )
}
