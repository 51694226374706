import { StyleSheet, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useHistory } from '@/libs/reactRouter'
import { useTranslation } from 'react-i18next'
import CategoryInfo from '../CategoryInfo'
import CategoryList from '../CategoryList'
import React from 'react'
import SetInfo from './SetInfo'
import SetList from './SetList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import _ from 'lodash'

export default function SetMenuPanel (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const rootSetCategory = useSelector(state => state.menu.rootSetCategory)
  const promote = useSelector(state => state.menu.promote)

  const [sets, setSets] = React.useState([])
  const selectedCategory = useSelector(state => state.setting.selectedCategory)
  const [selectedSetItem, setSelectedSetItem] = React.useState(null)
  const categories = rootSetCategory.searchCategories
  const searchText = useSelector(state => state.setting.searchText)

  React.useEffect(() => {
    if (selectedCategory?.sets) {
      setSets(selectedCategory?.sets)
    } else {
      setSets([])
    }
  }, [selectedCategory])

  React.useEffect(() => {
    let selected = null
    _.each(categories, category => {
      const id = selectedCategory?.id
      if (category.id === id) {
        selected = category
      }
      const subCategories = category.categories
      const ids = _.flatMap(subCategories, 'id')
      if (ids.includes(id)) {
        selected = subCategories?.find(sub => sub.id === id)
      }
    })
    dispatch(actions.setting.selectCategory(selected ?? null))
  }, [categories])

  const toggleSelectCategory = (category) => {
    setSelectedSetItem(null)
    if (category.id === selectedCategory?.id) {
      dispatch(actions.setting.selectCategory(null))
      return
    }
    dispatch(actions.setting.selectCategory(category))
  }

  const addCategory = () => {
    setSelectedSetItem(null)
    dispatch(actions.setting.createSetCategory())
  }

  const addSet = () => {
    if (!selectedCategory || selectedCategory.id === undefined) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.set.error.category')))
      return
    }
    history.push(`/setting/menu/create-set/${selectedCategory.id}`)
  }

  return (
    <View style={styles.container}>
      {/* left container */}
      <View style={styles.leftContainer}>
        {/* 套餐分類 */}
        <CategoryList
          draggable
          style={{ flex: 1 }}
          title={t('app.page.setting.menuPanel.set.setCategory')}
          parentCategory={rootSetCategory}
          categories={categories ?? []}
          selectedCategory={selectedCategory}
          onPress={toggleSelectCategory}
          onPressBottomButton={addCategory}
        >
          {
            !searchText.trim() && (
              <SettingListItem
                divider
                selected={selectedCategory?.id === 'promote'}
                draggable
                dragDisabled
                onPress={() => toggleSelectCategory(promote)}
              >
                <SettingListItemText
                  text={t('app.constant.menu.promoted')}
                  wrap
                />
              </SettingListItem>
            )
          }
        </CategoryList>
        {/* 套餐項目 */}
        <SetList
          draggable
          style={{ flex: 2 }}
          categoryId={selectedCategory?.id}
          sets={sets ?? []}
          selectedSetItem={selectedSetItem}
          setSelectedSetItem={setSelectedSetItem}
          addSet={addSet}
          disableBottomButton={_.size(selectedCategory?.categories) > 0}
        />
      </View>

      {/* right container */}
      <View style={styles.rightContainer}>
        {/* 套餐分類info */}
        {selectedCategory &&
            !selectedSetItem &&
              <CategoryInfo
                category={selectedCategory}
                onClose={() => dispatch(actions.setting.selectCategory(null))}
              />}
        {/* 套餐項目 info */}
        {selectedSetItem &&
          <SetInfo set={selectedSetItem} />}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  leftContainer: {
    flex: 3,
    flexDirection: 'row',
    marginHorizontal: 10,
  },
  rightContainer: {
    flex: 2,
    marginHorizontal: 10,
  },
})
