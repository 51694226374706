import _ from 'lodash'
import moment from 'moment'
import orderappLibs from 'dimorder-orderapp-lib/dist/libs'

import i18n from '@/i18n'
import store from '@/redux/store'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppOrderBatch, IFormatedBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IAppMenuItem, IAppSet } from 'dimorder-orderapp-lib/dist/types/AppMenu'
import { ISetStep } from 'dimorder-orderapp-lib/dist/types/Menu'
import { ITableArea } from '@/redux/table/Table.d'
/* eslint-enable no-unused-vars */

/** @type {() => IRootState} */
const getState = store.getState

/**
 * @returns {IAppOrderBatch}
 */
export function getSelectedBatch () {
  return getState().orderBatch.selectedBatch
}

/**
 * @returns {[IAppBatchItem, IAppBatchItem]}
 */
export function getSelectedBatchItem () {
  const selectedItemKey = getState().orderBatch.selectedItemKey
  const selectedSetItemKey = getState().orderBatch.selectedSetItemKey

  const selectedBatch = getState().orderBatch.selectedBatch
  const selectedItem = selectedBatch?.items.find(item => item.key === selectedItemKey)
  const selectedSetItem = selectedItem?.setItems?.find(setItem => setItem.key === selectedSetItemKey)

  return [selectedItem, selectedSetItem]
}
/**
 * @returns {IAppOrderBatch}
 */
export function getAllbatch () {
  const selectedOrder = getState().order.selectedOrder
  const selectedBatch = getState().orderBatch.selectedBatch
  return [selectedOrder.batches, selectedBatch]
}
/**
 * @param {IAppBatchItem} item
 * @returns {IFormatedBatchItem}
 */
export function getDisplayBatchItem (item) {
  const selectedOrder = getState().order.selectedOrder
  return orderappLibs.calculateLocalBatchItem(orderappLibs.formatBatchItemStatus(item), selectedOrder)
}

/**
 * 計算套餐內的所選步驟的餐點總數量
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @param {(number | null)} setMenuIndex
 * @returns {number}}
 */
export function calculateStepQuantityTotal (item, setStep, setMenuIndex = null) {
  const setItems = item?.setItems
  const stepQuantityTotal = _.reduce(setItems, (sum, setItem) => {
    if (setItem?.step === setStep?.id) {
      // setMenuIndex == null 就全部都算
      // 否則只算 setMenuIndex 相符的 item
      if (setMenuIndex == null || setMenuIndex === setItem.setMenuIndex) {
        return sum + setItem?.quantity
      }
    }
    return sum
  }, 0)
  return stepQuantityTotal
}

/**
 * 計算套餐內的所選步驟的餐點總數量上限
 * @param {ISetStep} setStep
 * @param {(number | null)} setMenuIndex
 * @returns {number}}
 */
export function getStepQuantityMax (item, setStep, setMenuIndex = null) {
  const stepQuantityLimit = setStep && (setStep?.max || setStep?.min || 0)
  // setMenuIndex == null 就要看 item.quantity 否則只算一項的 limit
  const itemQuantity = setMenuIndex == null ? item?.quantity : 0
  return stepQuantityLimit * itemQuantity || 0
}

/**
 * 計算套餐內的所選步驟的餐點總數量下限
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @param {(number | null)} setMenuIndex
 * @returns {number}}
 */
export function getStepQuantityMin (item, setStep, setMenuIndex = null) {
  if (setStep.optional) return 0
  const stepQuantityLimit = setStep && (setStep?.min || 0)
  // setMenuIndex == null 就要看 item.quantity 否則只算一項的 limit
  const itemQuantity = setMenuIndex == null ? item?.quantity : 0
  return stepQuantityLimit * itemQuantity || 0
}

/**
 * 判斷套餐內的所選步驟的餐點總數量是否已達步驟上限
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @param {(number | null)} setMenuIndex
 * @returns {boolean}
 */
export function calculateStepIsMax (item, setStep, setMenuIndex = null) {
  if (item?.isSet) {
    const stepQuantityTotalAll = calculateStepQuantityTotal(item, setStep)
    const stepQuantityMaxAll = getStepQuantityMax(item, setStep)
    const stepQuantityTotal = calculateStepQuantityTotal(item, setStep, setMenuIndex)
    const stepQuantityMax = getStepQuantityMax(item, setStep, setMenuIndex)

    if (!stepQuantityMax) return false // 沒有限制 max

    // 全部項目的數量是否達到 max
    const allIsMax = stepQuantityTotalAll >= stepQuantityMaxAll

    // 特定 setMenuItem 自己的項目數量是否達到 max
    const indexIsMax = stepQuantityTotal >= stepQuantityMax

    // 任何一種有達到 max 就到上限了
    const isMax = allIsMax || indexIsMax
    return isMax
  }
}

/**
 * 判斷套餐內的所選步驟的餐點總數量是否已完成
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @param {(number | null)} setMenuIndex
 * @returns {boolean}
 */
export function calculateStepIsComplete (item, setStep, setMenuIndex = null) {
  if (item?.isSet) {
    const stepQuantityTotalAll = calculateStepQuantityTotal(item, setStep)
    const stepQuantityMinAll = getStepQuantityMin(item, setStep)
    const stepQuantityTotalIndex = calculateStepQuantityTotal(item, setStep, setMenuIndex)
    const stepQuantityMinIndex = getStepQuantityMin(item, setStep, setMenuIndex)
    const allSetItemIds = _.map(item?.setItems, s => s.setMenuId)
    let allComplete = false

    if (setStep?.dependsOnSetMenuItems?.length) {
      const isStepHide = !allSetItemIds.some(id => setStep.dependsOnSetMenuItems.includes(id))
      if (isStepHide) {
        return true
      }
    } else if (setStep?.dependsOnSetMenuItem && !allSetItemIds.includes(setStep.dependsOnSetMenuItem)) {
      return true
    }

    // 全部項目的數量是否 >= 數量 * min
    if (stepQuantityMinAll === 0) {
      allComplete = true
    } else {
      allComplete = stepQuantityTotalAll >= stepQuantityMinAll
    }

    // 特定 setMenuItem 自己的項目數量是否 >= min
    const indexComplete = stepQuantityTotalIndex >= stepQuantityMinIndex

    // 任何一種有完成就算是完成了，因為可能自己套餐沒點東西，點在外面
    const isComplete = allComplete || indexComplete
    return isComplete
  }
}

/**
 * 判斷套餐內的所選步驟的餐點餘下數量
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @param {(number | null)} setMenuIndex
 * @returns {boolean}
 */
export function calculateStepItems (item, setStep) {
  if (item?.isSet) {
    const stepQuantityTotalAll = calculateStepQuantityTotal(item, setStep)
    const stepQuantityMinAll = getStepQuantityMin(item, setStep)
    const allSetItemIds = _.map(item?.setItems, s => s.setMenuId)

    if (setStep?.dependsOnSetMenuItems?.length) {
      const isStepHide = !allSetItemIds.some(id => setStep.dependsOnSetMenuItems.includes(id))
      if (isStepHide) {
        return 0
      }
    } else if (setStep?.dependsOnSetMenuItem && !allSetItemIds.includes(setStep.dependsOnSetMenuItem)) {
      return 0
    }

    return stepQuantityMinAll - stepQuantityTotalAll
  }
}

/**
 * 判斷選項是否完成
 * @param {IAppBatchItem} batchItem
 * @param {IAppMenuItem} menu
 * @returns {boolean}
 */
export function isOptionsComplete (batchItem, menu) {
  let isComplete = true
  const options = _.sortBy(_.concat(_.get(menu, 'options', []) ?? [], _.get(menu, 'optionGroupPresets', []) ?? []), ['weight'])
  options.forEach(menuOptionGroup => {
    if (menuOptionGroup.min > 0) {
      const optionGroupOptions = batchItem.options.filter(optionGroup => optionGroup.optionGroupId === menuOptionGroup.id)
      const optionGroupQuantity = _.sumBy(optionGroupOptions, 'quantity')
      if (optionGroupQuantity < menuOptionGroup.min) {
        isComplete = false
      }
    }
  })

  return isComplete
}

/**
 * 判斷套餐是否已完成
 * @param {IAppBatchItem} batchItem
 * @param {IAppSet} set
  * @param {(number | null)} setMenuIndex
 * @returns {boolean}
 */
export function isSetItemComplete (batchItem, set, setMenuIndex = null) {
  if (!batchItem.isSet) {
    // 非套餐，直接算完成
    return true
  }

  const notCompleteStep = _.get(set, 'steps', []).find(step => {
    return !calculateStepIsComplete(batchItem, step, setMenuIndex)
  })

  let isSetComplete = true
  _.get(set, 'menus', []).forEach(menu => {
    if (menu.min > 0 || menu.max > 0) {
      let count = 0
      _.each(batchItem?.setItems, item => {
        if (item.menuId === menu.menuId && item.step === menu.step) {
          count += item.quantity
        }
      })
      if (count < menu.min || count > menu.max) {
        isSetComplete = false
      }
    }
  })

  return !notCompleteStep && batchItem?.setItems?.length > 0 && isSetComplete
}
/**
 *
 * @param {ITableArea} selectedTableArea
 * @returns
 */
export function getSurcharge (selectedTableArea) {
  const openingsWithSurcharge = getState().merchant.data.openingsWithSurcharge
  const tableGroupWithSurcharge = getState().merchant.data?.tableGroupWithSurcharge
  const { surcharge: defaultSurcharge } = getState().merchant.data
  if (tableGroupWithSurcharge && tableGroupWithSurcharge[selectedTableArea.text]?.useSurcharge) {
    return tableGroupWithSurcharge[selectedTableArea.text]?.surcharge
  }
  const now = moment()

  const weekday = now.format('d')
  const todayOpeningsWithSurcharge = openingsWithSurcharge[weekday]
  if (todayOpeningsWithSurcharge) {
    const timeslot = todayOpeningsWithSurcharge.find(
      opening => {
        const startTime = moment().hour(opening.start).minute(opening.startMin).second(0)
        const endTime = moment().hour(opening.end).minute(opening.endMin).second(0)
        const wholeDay = [opening.start, opening.startMin, opening.end, opening.endMin].every(t => Number(t) === 0)
        return now.isBetween(startTime, endTime) || wholeDay
      },
    )
    if (!timeslot?.surcharge) {
      return defaultSurcharge ?? {
        percent: 0,
        amount: 0,
        overrideItem: false,
        useDefault: false,
      }
    }
    return timeslot.surcharge
  } else {
    return defaultSurcharge ?? {
      percent: 0,
      amount: 0,
      overrideItem: false,
      useDefault: false,
    }
  }
}

export function getDiscount (selectedTableGroup) {
  const openingsWithSurcharge = getState().merchant.data.openingsWithSurcharge
  const tableGroupWithSurcharge = getState().merchant.data?.tableGroupWithSurcharge
  const { discount: defaultDiscount } = getState().merchant.data
  if (tableGroupWithSurcharge && tableGroupWithSurcharge[selectedTableGroup.text]?.useDiscount) {
    return tableGroupWithSurcharge[selectedTableGroup.text]?.discount
  }
  const now = moment()

  const weekday = now.format('d')
  const todayOpeningsWithSurcharge = openingsWithSurcharge[weekday]
  if (todayOpeningsWithSurcharge) {
    const timeslot = todayOpeningsWithSurcharge.find(
      opening => {
        const startTime = moment().hour(opening.start).minute(opening.startMin).second(0)
        const endTime = moment().hour(opening.end).minute(opening.endMin).second(0)
        const wholeDay = [opening.start, opening.startMin, opening.end, opening.endMin].every(t => Number(t) === 0)
        return now.isBetween(startTime, endTime) || wholeDay
      },
    )
    if (!timeslot?.discount) {
      return defaultDiscount ?? {
        percent: 0,
        amount: 0,
        overrideItem: false,
        useDefault: false,
      }
    }
    return timeslot.discount
  } else {
    return defaultDiscount ?? {
      percent: 0,
      amount: 0,
      overrideItem: false,
      useDefault: false,
    }
  }
}
/**
 * @param {Object} item
 * @returns {boolean}
 */
export function isBetweenDayTime (item) {
  const isfilterByTime = getState().menu.isfilterByTime

  if (isfilterByTime) {
    const now = moment()

    let startTime = moment().hour(0).minute(0).second(0)
    let endTime = moment().hour(23).minute(59).second(59)
    if (!_.isEmpty(item.timerange)) {
      startTime = startTime.hour(item.timerange[0].hour).minute(item?.timerange[0].minute).second(item.timerange[0].second)
      endTime = endTime.hour(item.timerange[1].hour).minute(item?.timerange[1].minute).second(item.timerange[1].second)
    }
    return (now.isBetween(startTime, endTime) && (item?.weekdays?.includes(Number(now.format('e'))) || _.isEmpty(item.weekdays)))
  }
  return true
}

const getNameTitle = (title) => {
  switch (title) {
    case 'mr': {
      return i18n.t('app.component.infoDialog.mr')
    }
    case 'ms': {
      return i18n.t('app.component.infoDialog.ms')
    }
    default: {
      return title || ''
    }
  }
}

/**
 * 拿到format好的名字
 * @param {*} name
 * @param {*} title
 * @param {*} overrideLang
 * @returns {string}
 */
export function getDisplayCustomerName (name, title, overrideLang) {
  name = name || ''
  const lang = overrideLang || String(i18n.language ?? 'zh').split('-')[0]
  switch (lang) {
    case 'zh':
      return `${name}${getNameTitle(title)}`
    default: {
      return `${getNameTitle(title)} ${name}`.trim()
    }
  }
}

/**
 * 用作檢查該訂單是否可在Local Database移除
 * @param {IAppOrder} order
 * @returns {boolean}
 */
export function isOrderAllowLocalDatabaseClear (order) {
  // 已被取消的訂單不會再有改動 = 已完成訂單
  if (order?.status === 'cancelled' || order?.takeawayStatus === 'cancelled') return true

  switch (order.deliveryType) {
    // 堂食 且已付款的訂單 = 已完成訂單
    case 'table':
      if (order?.status === 'paid') { return true }
      break

    // 自取 且已取餐的訂單 = 已完成訂單
    case 'takeaway':
      if (order?.takeawayStatus === 'completed') { return true }
      break

    // 外送 且已送達的訂單 = 已完成訂單
    case 'storeDelivery':
      if (order?.takeawayStatus === 'delivered') { return true }
      break
  }

  return false
}
