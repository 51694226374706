import { StyleSheet, View } from 'react-native'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'
import i18n from '@/i18n'

import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListHeaderButton from '@/components/Setting/SettingListHeaderButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import shadows from '@/theme/shadows'

export default function MenuList (props) {
  const {
    style,
    draggable,
    title,
    stepKey,
    categoryId,
    menus,
    selectedMenu,
    headerButtonText,
    onPress,
    onPressBottomButton,
    onPressHeaderButton,
    onPressAllButton,
    onChangeMenuOrder,
    isSetMenu = false,
    disableBottomButton = false,
  } = props

  const readOnly = categoryId === 'promote' || categoryId === 'soldOut'

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useSelector(state => state.menu.isMenuLoading)
  const search = useSelector(state => state.setting.searchText)
  const listDraggable = draggable
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)

  const renderMenuItem = ({ item, index, drag, isActive }) => {
    const isSelected = item.id === selectedMenu?.id
    // const priceText = item.prices?.map(p => `${p.type}: $${p.price}`).join(', ') || '-'
    const priceText = item.priceUndetermined ? t('app.constant.order.priceUndetermined') : currencyWithCommas(item.price)

    return (
      <SettingListItem
        key={item.id}
        divider
        selected={isSelected}
        draggable={listDraggable}
        dragging={isActive}
        dragDisabled={search.trim() && !stepKey}
        disabled={!onPress}
        onDrag={drag}
        onPress={() => { onPress(item) }}
        style={isActive && shadows.slight}
        itemStyle={isSetMenu && { height: 50 }}
        contentStyle={isSetMenu && { height: '100%' }}
      >
        <View style={{ flex: 1, display: 'flex', flexDirection: 'row', minWidth: 110 }}>
          <SettingListItemText
            text={isLoading ? '' : item.code}
            style={{ marginRight: 3, flexGrow: item.code ? 0.2 : 0 }}
            wrap={!isSetMenu}
            numberOfLines={isSetMenu && 2}
          />
          <SettingListItemText
            text={isLoading ? '...' : _.get((allMenus[item.menuId] ?? item)?.localeNames, lang) ?? item.name}
            style={styles.itemText}
            wrap={!isSetMenu}
            numberOfLines={isSetMenu && 2}
          />
        </View>
        <SettingListItemText
          text={isLoading ? '...' : priceText}
          style={styles.priceText}
          wrap={!isSetMenu}
          numberOfLines={isSetMenu && 2}
        />
      </SettingListItem>
    )
  }

  return (
    <SettingList
      shadow
      style={style}
      title={title}
    >
      {onPressHeaderButton && (
        <SettingListHeaderButton
          text={headerButtonText}
          style={styles.button}
          onPress={onPressHeaderButton}
          icon='square-edit-outline'
        />)}
      <DraggableFlatList
        containerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        data={menus}
        extraData={selectedMenu}
        keyExtractor={item => item.id}
        renderItem={renderMenuItem}
        onDragEnd={({ data }) => {
          if (!listDraggable) return
          const originalIds = menus.map(m => m.id)
          const menuIds = data.map(m => {
            return m.id
          })
          if (_.isEqual(originalIds, menuIds)) return
          onChangeMenuOrder(data, categoryId || stepKey, menuIds)
        }}
      />
      {(onPressBottomButton && !readOnly) &&
        <SettingListFooterAddButton
          text={t('app.common.add') + title}
          style={styles.button}
          onPress={onPressBottomButton}
          disabled={disableBottomButton}
        />}
      {(onPressAllButton && menus.length > 0) &&
        <SettingListFooterAddButton
          text={t('app.common.selectAll')}
          style={styles.button}
          onPress={onPressAllButton}
        />}
    </SettingList>
  )
}

const styles = StyleSheet.create({
  itemText: {
    flex: 1,
  },
  priceText: {
    width: '40%',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  button: {
    width: 'auto',
    marginLeft: 0,
  },
})
