import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'

import { countPlaces } from '@/libs/countPlaces'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import CenterModal from '@/components/CenterModal'
import NumberPad from '@/components/NumberPad'

const DECIMAL_BUTTONS = [
  { text: '1', fontSize: 25 },
  { text: '2', fontSize: 25 },
  { text: '3', fontSize: 25 },
  { text: '4', fontSize: 25 },
  { text: '5', fontSize: 25 },
  { text: '6', fontSize: 25 },
  { text: '7', fontSize: 25 },
  { text: '8', fontSize: 25 },
  { text: '9', fontSize: 25 },
  { text: 'C', fontSize: 24, variant: 'action' },
  { text: '0', fontSize: 25 },
  { text: '.', fontSize: 25 },
]

const OK_BUTTONS = [
  { text: '1', fontSize: 25 },
  { text: '2', fontSize: 25 },
  { text: '3', fontSize: 25 },
  { text: '4', fontSize: 25 },
  { text: '5', fontSize: 25 },
  { text: '6', fontSize: 25 },
  { text: '7', fontSize: 25 },
  { text: '8', fontSize: 25 },
  { text: '9', fontSize: 25 },
  { text: 'C', fontSize: 24 },
  { text: '0', fontSize: 25 },
  { text: 'OK', fontSize: 25, variant: 'action' },
]

/**
 * @typedef NumPadDialogProps
 * @property {boolean} open
 * @property {() => void?} onClose
 * @property {() => void?} onPress
 * @property {() => void?} onSubmit
 * @property {'dark' | 'light'} variant
 * @property {string} title
 * @property {string?} submitButtonText
 * @property {string?} defaultValue
 * @property {string?} clearValue
 * @property {number?} buttonSize
 * @property {number?} maxLength
 * @property {number?} minAmount
 * @property {number?} maxAmount
 * @property {boolean?} enablePressOutsideClose
 * @property {boolean?} noCloseButton
 * @property {boolean?} disableDecimalPoint
 * @property {boolean?} keepZero
 * @property {boolean?} useCode
 */

/**
 *
 * @param {NumPadDialogProps} props
 * @returns
 */
export default function NumPadDialog (props) {
  const {
    open,
    onClose = () => { },
    onPress = () => { },
    onSubmit = () => 0,
    variant = 'light',
    title,
    submitButtonText = 'OK',
    defaultValue = '0',
    clearValue = '0',
    buttonSize = 70,
    maxLength = 10,
    minAmount = 0,
    maxAmount = 999999999,
    enablePressOutsideClose = true,
    noCloseButton = false,
    disableDecimalPoint = false,
    keepZero = false,
    useCode = false,
  } = props

  const [valueString, setValueString] = React.useState(String(defaultValue))
  const disable = valueString < minAmount || valueString > maxAmount
  const disableNumber = {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    0: true,
    '.': true,
  }
  const disableOK = { OK: disable }
  return (
    <CenterModal
      enablePressOutsideClose={enablePressOutsideClose}
      title={title}
      visible={open}
      onClose={() => {
        onClose()
        setValueString(defaultValue)
      }}
      contentContainerStyle={styles.modalContainer}
      noCloseButton={noCloseButton}
    >
      <View style={styles.valueRow}>
        <Text style={styles.valueText} numberOfLines={1}>
          {useCode ? valueString : Number(valueString)}
        </Text>
        {
          !disableDecimalPoint && (
            <TouchableOpacity
              onPress={() => {
                onSubmit(valueString)
                setValueString(defaultValue)
              }}
              disabled={disable}
            >
              <View style={[styles.submitButton, disable
                ? { backgroundColor: colors.lightGray5 }
                : { backgroundColor: colors.primary }]}
              >
                <Text style={styles.submitButtonText}>{submitButtonText}</Text>
              </View>
            </TouchableOpacity>
          )
        }
      </View>
      <NumberPad
        variant={variant}
        buttonSize={buttonSize}
        buttons={disableDecimalPoint ? OK_BUTTONS : DECIMAL_BUTTONS}
        disabledTexts={countPlaces(valueString) >= 1 ? Object.assign({}, disableOK, disableNumber) : disableOK}
        onButtonPress={(inputChar) => {
          if (inputChar === 'C') {
            setValueString(clearValue)
            onPress(clearValue)
            return
          }
          if (inputChar === 'OK') {
            if (disable) {
              return
            }
            onSubmit(valueString)
            setValueString(defaultValue)
            onPress(defaultValue)
            return
          }
          if (inputChar === '.' && String(valueString).includes('.')) {
            // 已有小數點，不再處理小數點
            return
          }
          if (valueString === '0' && inputChar !== '.' && !keepZero) {
            // 原本是 0，且不是小數點，輸入的內容直接蓋過去
            setValueString(inputChar)
            onPress(inputChar)
            return
          }
          if (valueString.length === maxLength) {
            // 超過長度，不再輸入
            return
          }
          setValueString(valueString + inputChar)
          onPress(valueString + inputChar)
        }}
      />
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    padding: 24,
  },
  valueRow: {
    alignSelf: 'stretch',
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.numPadGray,
    borderRadius: 8,
    margin: 2,
    marginBottom: 16,
  },
  valueText: {
    flex: 1,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'right',
    color: colors.textTertiary,
    marginHorizontal: 16,
  },
  submitButton: {
    height: 40,
    width: 70,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    ...shadows.default,
  },
  submitButtonText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 'bold',
  },
})
