import { Animated, TouchableWithoutFeedback } from 'react-native'
import React, { useEffect, useRef } from 'react'
import colors from '@/theme/colors'

const RATIO = 2 // 長(1)寬(2)比
const PADDING = 2

/**
 * @typedef ToggleSwitchProps
 * @property {boolean} value
 * @property {() => void} onChangeValue
 * @property {number?} size
 */

/**
 *
 * @param {ToggleSwitchProps} props
 * @returns
 */
export default function ToggleSwitch (props) {
  const { value, onChangeValue, size = 17 } = props
  // 當 render 時，若 value 為 true，ToggleSwitch 仍會有從 false 跳成 true 的動畫，這是決議保留的 feature
  // 若未來不想要這個效果的話， 可將 Animated.Value 設為 value ? 1 : 0
  const animation = useRef(new Animated.Value(0)).current

  const trackStyles = {
    height: size,
    width: size * RATIO,
    borderRadius: size / 2,
    justifyContent: 'center',
    padding: PADDING,
    backgroundColor: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [colors.lightGray3, colors.primary], // 動畫顏色改變
    }),
  }

  const thumbStyles = {
    height: size - PADDING * 2,
    width: size - PADDING * 2,
    backgroundColor: colors.white,
    borderRadius: (size - PADDING * 2) / 2,
    transform: [
      {
        translateX: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [0, size * (RATIO - 1)], // 動畫水平滑動
        }),
      },
    ],
  }

  useEffect(() => {
    Animated.timing(
      animation,
      {
        toValue: value ? 1 : 0,
        duration: 250,
        useNativeDriver: false,
      },
    ).start()
  }, [value])

  return (
    <TouchableWithoutFeedback
      onPress={onChangeValue}
    >
      <Animated.View style={trackStyles}>
        <Animated.View style={thumbStyles} />
      </Animated.View>
    </TouchableWithoutFeedback>
  )
}
