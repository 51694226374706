import EscPosEncoder from 'esc-pos-encoder'
import _ from 'lodash'

import {
  PrintMethod,
  PrinterBrand,
  PrinterCommand,
  PrinterEncoding,
  PrinterFontSize,
  PrinterType,
  PrinterUsage,
} from '@/constants/printer'
import PrintUtiliy from '@/libs/printing/PrintUtiliy'
import Printer from '@/libs/printing/printer/Printer'
import store from '@/redux/store'

import ImageMode from '../BmpImage/ImageMode'
import getImageCommand from '../BmpImage/getImageCommand'
import straightLine from '../BmpImage/straightLine'

/** @type {() => IRootState} */
const getState = store.getState

/**
 * EPSON 針機
 * encoding - 中文: big5
 * command: ESC/POS
 * 用途：主要是廚房單，一般不會用於收據
 * Printer Programming Manual: https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=72
 */
export default class TM_U220B extends Printer {
  TYPE = PrinterType.TM_U220B
  PRINTER_BRAND = PrinterBrand.EPSON
  PRINTER_COMMAND = PrinterCommand.ESC_POS
  PRINT_METHOD = PrintMethod.DOT
  USAGES = [PrinterUsage.KITCHEN_BATCH]
  ENCODINGS = { BIG5: PrinterEncoding.BIG5, TIS620: PrinterEncoding.TIS620 }
  DEFAULT_ENCODING = this.ENCODINGS.BIG5
  MAX_WIDTH = 33
  DOT_WIDTH = 200

  encoding = this.DEFAULT_ENCODING
  encoder = new EscPosEncoder()
  imageMode = new ImageMode(this.DOT_WIDTH, this.TYPE)
  isImageMode = false
  chineseTextWidth = 1.5

  setImageMode (on = true) {
    this.isImageMode = on
    return this
  }

  addInit () {
    const command = this.encoder.initialize().encode()
    this.writeNumbers(command)
    return this
  }

  addCodePage () {
    this.encoding = this.DEFAULT_ENCODING
    this.writeNumbers([28, 38])
    return this
  }

  addPrintThai () {
    this.encoding = this.ENCODINGS.TIS620
    // 退出中文字符模式
    this.writeNumbers([28, 46])
    // Thai code page
    this.writeNumbers([27, 116, 20])
    return this
  }

  /**
   *
   * @param {string} text
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @param {boolean?} isImageMode
   * @returns
   */
  addText (text, fontSize, isImageMode = this.isImageMode) {
    console.log(`text(style=${fontSize}): `, text)
    if (isImageMode) {
      const image = this.imageMode
      image.addText(text, fontSize)
      this.writeString(text, this.encoding, true)
      return this
    }
    // check current text style to prevent duplicate call
    if (typeof fontSize !== 'undefined') {
      switch (fontSize) {
        case PrinterFontSize.EXTRALARGE:
        case 5:
        case PrinterFontSize.LARGEWIDTH:
        case 4:
        case PrinterFontSize.MEDIUWIDTH:
        case 3:
        case PrinterFontSize.LARGE:
        case 2:
        case PrinterFontSize.MEDIUM:
        case 1:
          this.writeNumbers([27, 33, 48]) // ESC ! [Name] Select print mode(s) (16: Double-height mode: ON, 32: Double-width mode: ON)
          this.writeNumbers([28, 33, 12]) // FS ! [Name] Select print mode(s) for Kanji characters (4: Double-width selected, 8: Double-height selected)
          break
        default:
          this.writeNumbers([27, 33, 0])
          this.writeNumbers([28, 33, 0])
      }
    }
    // console.log('this.encoding', this.encoding)

    if (typeof text !== 'undefined') {
      this.writeString(text, this.encoding)
    }

    return this
  }

  addLogo () {
    const image = _.get(getState(), 'app.settings.logo.data', [])
    const command = getImageCommand(image, 'ESC *')
    this.addAlign('center')
    this.writeNumbers(command)
    return this
  }

  async addQRCode (url, position = 'center', size = 8) {
    const command = this.encoder
      .align(position)
      .qrcode(url, 1, size, 'h')
      .encode()
    this.writeNumbers(command)
    return this
  }

  addBoldMode (on = true) {
    const command = this.encoder.bold(on).encode()
    this.writeNumbers(command)
    return this
  }

  addDoubleStrikeMode (on = true) {
    const n = on ? 1 : 0
    this.writeNumbers([27, 71, n])
    return this
  }

  addItalicMode (on = true) {
    const command = this.encoder.italic(on).encode()
    this.writeNumbers(command)
    return this
  }

  addUnderlineMode (on = true) {
    const image = this.imageMode
    image.addUnderlineMode(on)
    const command = this.encoder.underline(on).encode()
    this.writeNumbers(command)
    return this
  }

  /**
   *
   * @param {'left' | 'center' | 'right'} align
   * @returns
   */
  addAlign (align) {
    const image = this.imageMode
    image.addAlign(align)
    const command = this.encoder.align(align).encode()
    this.writeNumbers(command)
    return this
  }

  /**
   *
   * @param {string} color
   * @returns
   */
  addColor (color) {
    let n = 48
    switch (color) {
      case 'red':
        n = 49
        break
      case 'black':
      default:
        n = 48
        break
    }
    this.writeNumbers([27, 114, n])
    return this
  }

  addRowFeed (rows = 1) {
    if (this.isImageMode) {
      const image = this.imageMode
      const command = image.printText()
      this.writeNumbers(command)
    }
    this.writeNumbers(Array((Math.floor(rows / 2) > 1 ? Math.floor(rows / 2) : 1)).fill([10]))
    return this
  }

  addFixedRow (texts, widths, styleText, rowFeed = 1, align = [], fonts = []) {
    const row = _.map(texts, (text, index) => PrintUtiliy.getItemNameByMaxWidth(text ?? '', widths[index], this.chineseTextWidth))
    if (this.isImageMode) {
      console.log(`text(style=${styleText}): `, texts)
      const image = this.imageMode
      const command = image.addFixedRow(texts, widths, styleText, align, fonts)
      this.writeNumbers(command)
      const feed = rowFeed - 1
      if (feed) {
        this.addRowFeed(feed)
      }
      this.writeString(_.flatten(row).join(''), this.encoding, true)
      return this
    }
    const maxLength = _.max(_.map(row, r => r?.length ?? 0))
    for (let i = 0; i < maxLength; i++) {
      const itemRowText = _.map(row, (r, index) => this._alignText(r[i] ?? '', align[index], widths[index]))
      this._printRow(itemRowText, widths, styleText, rowFeed, fonts)
    }
  }

  _alignText (text, align, length) {
    if (!text) {
      return text
    }
    switch (align) {
      case ImageMode.Align.RIGTH:
        return PrintUtiliy.leftPad(text, length)
      default:
        return text
    }
  }

  _printRow (texts, widths, styleText, rowFeed = 1, fonts) {
    if (!texts) {
      texts = []
    }
    const PRINT_MAX_WIDTH = this.MAX_WIDTH
    if (_.sum(widths) > PRINT_MAX_WIDTH) {
      console.error('widths too long:', widths)
    }
    _.each(texts, (text, i) => {
      const width = widths[i]
      let buffer = ''
      _.each(text, (char) => {
        const length = PrintUtiliy.strLen(buffer + char, 1.5)
        if (length > width) {
        } else {
          buffer += char
        }
      })
      if (PrintUtiliy.strLen(buffer, 1.5) < width) {
        buffer += [...new Array(width - PrintUtiliy.strLen(buffer, 1.5))]
          .map((a) => ' ')
          .join('')
      }
      this.addText(buffer, fonts[i] ?? styleText)
    })
    this.addRowFeed(rowFeed)
  }

  /**
   * 用於自訂收據單（CustomizedOrderReceipt）的分隔線選項
   * @returns
   */
  addStraightLine () {
    this.addAlign('left')
    const command = getImageCommand(straightLine(this.DOT_WIDTH), 'ESC *')
    this.writeNumbers(command)
    return this
  }

  addCutPaper () {
    this.addRowFeed(12)
    const command = this.encoder.cut('partial').encode()
    this.writeNumbers(command)
    return this
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @param {'thai'?} batchLocale
   * @returns
   */
  getItemMaxWidth (fontSize, batchLocale) {
    const thaiWidths = [70, 30, 16, 30, 16, 16]
    const widths = [24, 8, 5, 9, 5, 3]
    if (batchLocale === 'thai') {
      return thaiWidths[fontSize] ?? thaiWidths[0]
    }
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @returns
   */
  getPriceMaxWidth (fontSize) {
    const widths = [5, 4, 4, 4, 4, 3]
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @returns
   */
  getQuantityMaxWidth (fontSize) {
    const widths = [4, 4, 2, 3, 2, 2]
    return widths[fontSize] ?? widths[0]
  }

  /**
   * @deprecated 沒有使用
   * @returns
   */
  statusTransmission () {
    this.writeNumbers([16, 4, 1])
    return this
  }
}
