import { StyleSheet, Text, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { v4 as uuid } from 'uuid'
import ActionButton from '@/pages/OrderHistory/OrderDetail/ActionButton'
import BackNavigator from '@/components/Setting/BackNavigator'
import Header from '@/components/Setting/Header'
import ItemList from '@/components/ItemList'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import colors from '@/theme/colors'
import getNameWithNumber from '@/libs/getNameWithNumber'

/**
 * @param {{
 * onClose: () => void,
 * }} props
 */
export default function ComboGroup (props) {
  const { onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const groups = useSelector(state => state.merchant.data.comboRule.groups) || []
  const rules = useSelector(state => state.merchant.data.comboRule.rules) || []
  const menus = useSelector(state => state.menu.menus)
  const categories = useSelector(state => state.menu.categories)
  const [selectedGroup, setSelectedGroup] = useImmer(groups[0])
  const [editingGroups, setEditingGroups] = useImmer(groups)

  const renderItem = ({ item, index, drag, isActive }) => {
    const selected = selectedGroup?.id === item.id
    return (
      <SettingListItem
        draggable
        dragging={isActive}
        onDrag={drag}
        onPress={() => setSelectedGroup({ ...item, index })}
        selected={selected}
        divider
      >
        <SettingListItemText text={item.name} />
      </SettingListItem>
    )
  }

  const onAdd = () => {
    let name = selectedGroup.name
    if (!name.trim()) {
      const idsCatgory = _.uniq(_.map(selectedGroup.itemIds, id => menus[id].categoryId))
      const names = _.map(editingGroups, g => g.name)
      const text = t('app.routes.setting.order.comboRulesSetting.group')
      name = getNameWithNumber(names, idsCatgory.length === 1 ? _.get(categories, `${idsCatgory[0]}.name`, text) : text)
    }
    setEditingGroups(draft => {
      draft.push({
        ...selectedGroup,
        name,
        id: uuid(),
      })
    })
    setSelectedGroup(null)
  }

  const onDelete = () => {
    setEditingGroups(draft => {
      _.remove(draft, group => group.id === selectedGroup.id)
    })
    setSelectedGroup(null)
  }

  const onUpdate = (path, value) => {
    setSelectedGroup(draft => {
      draft[path] = value
    })
    const index = _.findIndex(editingGroups, g => g.id === selectedGroup.id)
    if (index > -1) {
      setEditingGroups(draft => {
        draft[index] = {
          ...selectedGroup,
          [path]: value,
        }
      })
    }
  }

  const updateChange = () => {
    dispatch(actions.app.showAlert({
      title: t('app.routes.setting.order.comboRulesSetting.title'),
      message: t('app.routes.setting.order.comboRulesSetting.msg'),
      buttons: [
        {
          children: t('app.page.order.stash.alert.leave.button.continue'),
          onPress: async () => {
            const updatedComboRules = _.map(rules, rule => {
              const updatedRules = _.map(rule.mandatoryItems, r => {
                const group = _.find(editingGroups, g => g.id === r.groupId)
                if (!group) {
                  return false
                }
                return { ...r, itemIds: group.itemIds }
              }).filter(r => r)
              const updatedModifies = _.map(rule.discountedItems, m => {
                const group = _.find(editingGroups, g => g.id === m.groupId)
                if (!group) {
                  return false
                }
                return { ...m, itemIds: group.itemIds }
              }).filter(m => m)

              return { ...rule, mandatoryItems: updatedRules, discountedItems: updatedModifies }
            })
            await dispatch(actions.merchant.updateComboRule({ groups: editingGroups, rules: updatedComboRules }))
            onClose()
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.page.order.stash.alert.leave.button.cancel'),
          onPress: () => {
            onClose()
          },
        },
      ],
    }))
  }

  return (
    <View style={styles.container}>
      <View style={styles.contentLeftContainer}>
        <BackNavigator
          text={t('app.routes.setting.order.comboRulesSetting.group')}
          onPress={() => {
            if (!_.isEqual(editingGroups, groups)) {
              updateChange()
            } else {
              onClose()
            }
          }}
        />
        <View style={styles.content}>
          <SettingList style={{ flex: 1 }}>
            <DraggableFlatList
              data={editingGroups}
              renderItem={renderItem}
              keyExtractor={(item, index) => String(item.id) + index}
              showsVerticalScrollIndicator={false}
              onDragEnd={({ data }) => setEditingGroups(data)}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.printer.add') + t('app.page.setting.printer.setting')}
              onPress={() => {
                setSelectedGroup({
                  name: '',
                  itemIds: [],
                })
              }}
            />
          </SettingList>
        </View>
      </View>
      <View style={styles.contentContainer}>
        {
          selectedGroup && (
            <>
              <Header />
              <SettingList>
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.name')} />
                  <SettingListInput
                    value={selectedGroup.name}
                    onChangeText={(text) => {
                      onUpdate('name', text.trim())
                    }}
                  />
                  <SettingListItemRightAction>
                    <ActionButton
                      text={selectedGroup.id ? t('app.common.delete') : t('app.common.add')}
                      buttonStyle={styles.button}
                      backgroundColor={selectedGroup.id && colors.secondary}
                      onPress={() => selectedGroup.id ? onDelete() : onAdd()}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              </SettingList>
              <ItemList
                onChange={(value) => {
                  const itemIds = _.uniq(_.flatMap(Object.values(value)))
                  onUpdate('itemIds', itemIds)
                }}
                menu={selectedGroup.itemIds || []}
                resetId={selectedGroup.id}
                noBackButton
              />
            </>
          )
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
  },
  contentLeftContainer: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
    marginRight: 20,
    maxWidth: 230,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    height: 36,
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 8,
    paddingHorizontal: 4,
    marginTop: 5,
    marginRight: 12,
  },
})
