import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

export default function Avatar ({ name, style }) {
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.text}>{name}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 25,
  },
  text: {
    fontSize: 30,
    color: colors.darkSecondary,
  },
})
