import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import colors from '@/theme/colors'

import ChipLabel from '@/components/ChipLabel'
import Header from '@/components/Setting/Header'
import HeaderRightAction from '@/components/Setting/HeaderRightAction'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

import ContentSetting from './Settings/Content'
import FooterSetting from './Settings/Footer'
import InfoSetting from './Settings/Info'

/**
 * @typedef SettingInfoProps
 * @property {*} setting
 * @property {() => void} onChange
 * @property {() => void} onAdd
 * @property {() => void} onDelete
 */

/**
 *
 * @param {SettingInfoProps} props
 * @returns
 */
export default function SettingInfo (props) {
  const { setting, onChange, onAdd, onDelete } = props
  const { t } = useTranslation()
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const [sortInfo, setSortInfo] = React.useState(false)

  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 5, display: t('app.page.setting.printer.size5') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]
  const textAlignOptions = [
    { value: 'left', display: t('app.page.setting.printer.left') },
    { value: 'center', display: t('app.page.setting.printer.center') },
    { value: 'right', display: t('app.page.setting.printer.right') },
  ]
  const customTypeOptions = [
    { value: 'text', display: t('app.page.setting.printer.customizedSetting.text') },
    { value: 'separator', display: t('app.page.setting.printer.customizedSetting.separator') },
    { value: 'remark', display: t('app.page.setting.printer.customizedSetting.remark') },
  ]

  React.useEffect(() => {
    setOptionsPanelConfig(null)
  }, [setting?.key])

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  const renderItem = ({ item, index, drag, isActive }) => {
    return (
      <SettingListItem
        key={item.key}
        divider
        draggable
        dragging={isActive}
        onDrag={drag}
      >
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.' + item.key)} />
      </SettingListItem>
    )
  }
  if (sortInfo) {
    const data = _.sortBy(_.values(setting?.content), 'index')
    return (
      <View style={styles.content}>
        <Header onBack={() => setSortInfo(false)}>
          <HeaderText text={t('app.page.setting.printer.customizedSetting.sort')} />
        </Header>
        <View style={styles.content}>
          <SettingList style={styles.content}>
            <DraggableFlatList
              style={{ backgroundColor: colors.white }}
              showsVerticalScrollIndicator={false}
              data={data}
              keyExtractor={(item, index) => String(item.key + index)}
              renderItem={renderItem}
              onDragEnd={({ data }) => {
                const content = { ...setting?.content }
                _.each(data, (d, index) => {
                  content[d.key] = {
                    ...content[d.key],
                    index,
                  }
                })
                onChange('content', content)
              }}
            />
          </SettingList>
        </View>
      </View>
    )
  }

  return (
    <>
      <Header>
        <HeaderText text={t('app.page.setting.printer.customizedSetting.' + setting.key)} />
        {
          (setting.key === 'info' || setting.key === 'footer') && (
            <HeaderRightAction>
              <TouchableOpacity
                onPress={() => setSortInfo(true)}
              >
                <ChipLabel
                  active
                  label={t('app.page.setting.printer.customizedSetting.sort')}
                />
              </TouchableOpacity>
            </HeaderRightAction>
          )
        }
        {
          ((setting.key === 'custom' && setting.type) || setting.key === 'separator') && (
            <HeaderRightAction>
              <TouchableOpacity
                onPress={() => {
                  if (setting.added || setting.key === 'separator') {
                    onDelete()
                  } else {
                    onAdd({ ...setting, added: true })
                  }
                }}
              >
                <ChipLabel
                  active
                  label={(setting.added || setting.key === 'separator') ? t('app.common.delete') : t('app.common.add')}
                />
              </TouchableOpacity>
            </HeaderRightAction>
          )
        }
      </Header>
      <ScrollView style={styles.content} showsVerticalScrollIndicator={false}>
        <SettingList>
          {
            setting.key === 'info' && (
              <InfoSetting
                setting={setting}
                onChange={onChange}
              />
            )
          }
          {
            setting.key === 'content' && (
              <ContentSetting
                setting={setting}
                onChange={onChange}
              />
            )
          }
          {
            setting.key === 'footer' && (
              <FooterSetting
                setting={setting}
                onChange={onChange}
              />
            )
          }
          {
            setting.key === 'custom' && (
              <>
                <SettingListEnterRow
                  divider={setting.type}
                  label={t('app.page.setting.printer.customizedSetting.type')}
                  value={customTypeOptions.find(t => t.value === setting.type)?.display ||
                      setting.type}
                  onPress={() => {
                    setOptionsPanelConfig({
                      title: t('app.page.setting.printer.customizedSetting.type'),
                      defaultValue: setting.type || 0,
                      onChangeValue: (value) => onChange('type', value),
                      options: customTypeOptions,
                      closeOnSelect: true,
                    })
                  }}
                />
                {
                  (setting.type && setting.type !== 'separator') && (
                    <>
                      {
                        setting.type === 'text' && (
                          <SettingListItem>
                            <SettingListItemText text={'-' + t('app.page.setting.menuPanel.menuInfo.content')} />
                            <SettingListItemRightAction>
                              <SettingListInput
                                value={setting.text}
                                onChangeText={(text) => onChange('text', text)}
                              />
                            </SettingListItemRightAction>
                          </SettingListItem>
                        )
                      }
                      <SettingListItem>
                        <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
                        <SettingListItemRightAction>
                          <ToggleSwitch
                            value={setting.bold}
                            onChangeValue={() => onChange('bold', !setting.bold)}
                            size={23}
                          />
                        </SettingListItemRightAction>
                      </SettingListItem>
                      <SettingListEnterRow
                        label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
                        value={textStyleOptions.find(t => t.value === setting.fontSize)?.display ||
                        setting.fontSize}
                        onPress={() => {
                          setOptionsPanelConfig({
                            title: t('app.page.setting.printer.customizedSetting.fontSize'),
                            defaultValue: setting.fontSize || 0,
                            onChangeValue: (value) => onChange('fontSize', value),
                            options: textStyleOptions,
                            closeOnSelect: true,
                          })
                        }}
                      />
                      <SettingListEnterRow
                        label={'-' + t('app.page.setting.printer.customizedSetting.align')}
                        value={textAlignOptions.find(t => t.value === setting.align)?.display ||
                        setting.align}
                        onPress={() => {
                          setOptionsPanelConfig({
                            title: t('app.page.setting.printer.customizedSetting.align'),
                            defaultValue: setting.align || 0,
                            onChangeValue: (value) => onChange('align', value),
                            options: textAlignOptions,
                            closeOnSelect: true,
                          })
                        }}
                      />
                    </>
                  )
                }
              </>
            )
          }
        </SettingList>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  content: {
    flex: 1,
  },
})
