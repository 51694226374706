import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useSelectedBooking } from '@/hooks/table'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import ExpandableDivider from '@/components/ExpandableDivider'
import Row from '@/components/Row'
import TagList from '@/components/InfoDialog/TagList'

import DetailInput from '../DetailInput'
import Header from '../Header'
import SubHeader from '../SubHeader'

/**
 *
 * @param {*} props
 * @returns
 */
export default function BookingDialog (props) {
  const dispatch = useDispatch()
  const visible = useSelector(state => state.table.dialog.booking)
  const booking = useSelectedBooking()
  const [state, setState] = useState(null)
  const [error, setError] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    setState(booking)
  }, [booking, visible])

  if (state === null) return null

  const onChangeState = (key, val) => {
    setIsChanged(true)
    setState(prevState =>
      _.set(_.assign({}, prevState), key, val),
    )
  }

  const onSelectTag = (selectTag) => {
    setIsChanged(true)
    setState((prevState) => {
      return {
        ...prevState,
        tags: prevState.tags.includes(selectTag)
          ? prevState.tags.filter(tag => tag !== selectTag)
          : [...prevState.tags, selectTag],
      }
    })
  }

  const resetState = () => {
    setState(null)
    setIsExpanded(false)
    setIsChanged(false)
    setError('')
  }

  const onClose = () => {
    resetState()
    dispatch(actions.table.closeDialog(['booking']))
  }

  const onSubmit = () => {
    // Checksum
    if (state.name.trim().length === 0) {
      setError('請輸入 * 姓名資料')
      return
    }

    if (
      state.phone.trim().length === 0) {
      setError('請輸入 * 電話資料')
      return
    }

    if (state.adults + state.children <= 0) {
      setError('至少要有一位客人')
      return
    }

    dispatch(actions.table.updateBooking(state))
    onClose()
  }

  const onCancelBooking = () => {
    // 確認視窗
    const alertConfig = {
      title: '取消預約單',
      message: '是否取消預約單？',
      buttons: [
        {
          title: '確定',
          type: 'TouchableOpacity',
          textStyle: { fontWeight: 'bold' },
          onPress: () => {
            dispatch(actions.table.cancelBooking(state.id))
            resetState()
          },
        },
        {
          title: '取消',
          type: 'TouchableOpacity',
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          textStyle: { fontWeight: 'bold' },
        },
      ],
    }
    dispatch(actions.app.showAlert(alertConfig))
  }

  const onProceedBooking = () => {
    dispatch(actions.table.proceedBookingToOrder(state))
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title='預約單'
      visible={visible}
      onClose={onClose}
    >
      <View style={styles.container}>
        <Header
          state={state}
          showDatePicker={() => dispatch(actions.app.showDateTimePicker('date', state.bookingAt, (value) => onChangeState('bookingAt', value)))}
          withCustomerInfo
        />
        <SubHeader
          state={state}
          onChangeState={onChangeState}
          showTimePicker={() => dispatch(actions.app.showDateTimePicker('time', state.bookingAt, (value) => onChangeState('bookingAt', value)))}
        />
        <ExpandableDivider
          expanded={isExpanded}
          onPress={() => setIsExpanded(!isExpanded)}
        />

        {isExpanded &&
          <DetailInput
            state={state}
            onChangeState={onChangeState}
            requireName
            requirePhone
            requireadults
          />}
        <TagList
          selectedTags={state.tags}
          onSelectTag={onSelectTag}
        />

        {/* actions */}
        <Row style={styles.bottomRow}>
          <Text style={styles.errorText}>{error || ' '}</Text>
          <Button
            title={isChanged ? '取消' : '取消預約'}
            backgroundColor={colors.light}
            textColor={colors.textTertiary}
            textBold
            type='TouchableOpacity'
            onPress={isChanged ? onClose : onCancelBooking}
          />
          <Button
            title={isChanged ? '確定' : '入座'}
            textBold
            type='TouchableOpacity'
            onPress={isChanged ? onSubmit : onProceedBooking}
          />
        </Row>
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 366,
    alignItems: 'center',
    marginHorizontal: 43,
  },
  bottomRow: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    flex: 1,
    fontWeight: '500',
    color: colors.secondary,
  },
})
