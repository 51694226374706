import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'

import SettingList from '@/components/Setting/SettingList'
import SettingListHeaderButton from '@/components/Setting/SettingListHeaderButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import colors from '@/theme/colors'

export default function StepList (props) {
  const { draggable, steps, addSetStep, selectedStep, setSelectedStep, onEditStep, onChangeStepOrder } = props
  const { t } = useTranslation()

  const renderStepItem = ({ item: step, index, drag, isActive }) => {
    const isSelected = step.id === selectedStep?.id
    const text = `${step.name} ${step.min || '-'}/${step.max || '-'}`
    return (
      <SettingListItem
        divider
        draggable={draggable}
        dragging={isActive}
        onDrag={drag}
        onPress={() => setSelectedStep(isSelected ? null : step)}
        selected={isSelected}
        itemStyle={{ height: 50, paddingRight: 0 }}
        contentStyle={{ height: '100%' }}
      >
        <SettingListItemText text={text} style={{ flex: 1 }} />
        {isSelected &&
          <TouchableOpacity
            style={styles.button}
            onPress={() => onEditStep(step)}
          >
            <Text style={styles.text}>{t('app.common.edit')}</Text>
          </TouchableOpacity>}
      </SettingListItem>
    )
  }

  return (
    <SettingList
      shadow
      style={styles.container}
    >
      <SettingListHeaderButton
        text={t('app.page.setting.menuPanel.set.addStep')}
        style={styles.addButton}
        onPress={addSetStep}
        icon='plus'
      />
      <DraggableFlatList
        style={{ flex: 1 }}
        containerStyle={{ flex: 1 }}
        data={steps}
        extraData={selectedStep}
        keyExtractor={item => item.id}
        renderItem={renderStepItem}
        showsVerticalScrollIndicator={false}
        onDragEnd={({ data }) => {
          if (!draggable) return
          onChangeStepOrder(data)
        }}
      />

    </SettingList>

  )
}

const styles = StyleSheet.create({
  container: {
    width: 200,
  },
  addButton: {
    width: 'auto',
    marginLeft: 0,
  },
  button: {
    height: '100%',
    width: 58,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.lightPrimary,
  },
  text: {
    color: colors.white,
    fontSize: 14,
    fontWeight: '500',
  },
})
