import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import SummaryRow from './SummaryRow'

/**
 * @typedef CategoryInfoProps
 * @property {IStatCategory} category
 */

/**
 *
 * @param {CategoryInfoProps} props
 * @returns
 */
export default function CategoryInfo ({ data = {} }) {
  const { t } = useTranslation()
  const { key, text, filters } = useSelector(state => state.statistics.filter)

  const categoryData = _.concat(data.categorySection, data.setSection).map(d => {
    const items = _.filter(d.items, item => item.name.includes(text) && (filters.setItem ? item.isSetItem : true))
    const sets = _.filter(d.sets, set => set.setName.includes(text) && !filters.setItem)
    const count = _.sumBy(_.concat(items, sets), c => c.quantity)
    return { ...d, count, items, sets, sales: _.sumBy(items, item => item.isSetItem ? 0 : item.sales) + _.sumBy(sets, 'sales') }
  }).filter(d => {
    return (_.isEmpty(key) || key.includes(d.key)) && !(_.isEmpty(d.items) && _.isEmpty(d.sets))
  })

  const renderItem = ({ item }) => {
    return (
      <>
        <SummaryRow title={item.categoryName} count={String(item.count)} amount={item.sales} />
        {
          !filters.hideItem && (
            <FlatList
              data={!_.isEmpty(item.items) ? item.items : item.sets}
              renderItem={renderMenuItem}
              keyExtractor={(item, index) => String(item.menuId + index)}
              showsVerticalScrollIndicator={false}
              style={{ marginBottom: 25 }}
            />
          )
        }
      </>
    )
  }

  const renderMenuItem = ({ item }) => {
    const name = item.name ? item.name : `${item.setName} (${t('app.page.stats.category.set')})`
    const displayName = item.isSetItem ? `[${t('app.page.stats.category.setItem')}] ${name}` : name
    return (
      <SummaryRow
        title={`    ${displayName}`}
        count={item.quantity}
        amount={item.isSetItem ? '--' : item.sales}
        isString={!!item.isSetItem}
        textStyle={{ color: colors.gray }}
      />
    )
  }

  if (_.isEmpty(data)) {
    return null
  }

  return (
    <View style={styles.container}>
      <View>
        <FlatList
          data={categoryData || []}
          renderItem={renderItem}
          keyExtractor={(item, index) => String(item.categoryName + index)}
          showsVerticalScrollIndicator={false}
          style={{ maxHeight: 500 }}
          ListHeaderComponent={
            <View style={{ height: 25, marginTop: 30 }}>
              <Text style={styles.title}>{t('app.page.stats.category.item')}</Text>
              <View style={styles.divider} />
            </View>
          }
        />
      </View>
      <View>
        <Text style={styles.desc}>*{t('app.page.stats.category.priceDesc')}</Text>
        <Text style={styles.desc}>*{t('app.page.stats.category.setDesc')}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  desc: {
    fontSize: 14,
    marginTop: 15,
    color: colors.dotGray,
  },
})
