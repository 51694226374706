import { StyleSheet, Text, View } from 'react-native'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Row from '@/components/Row'

export default function CancelledItemSummaryRow ({ title, count, serial, staff, textStyle }) {
  const { t } = useTranslation()
  return (
    <Row style={styles.tableRow}>
      <View style={styles.item}>
        <Text style={textStyle}>{t(title)}</Text>
      </View>
      <View style={styles.count}>
        <Text style={textStyle}>{count}</Text>
      </View>
      <View style={styles.serial}>
        <Text style={textStyle}>{serial}</Text>
      </View>
      <View style={styles.staff}>
        <Text style={textStyle}>{staff}</Text>
      </View>
    </Row>
  )
}

const styles = StyleSheet.create({
  tableRow: {
    marginTop: 15,
  },
  item: {
    width: 230,
  },
  count: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
  },
  serial: {
    width: 160,
    display: 'flex',
    alignItems: 'flex-end',
  },
  staff: {
    width: 160,
    display: 'flex',
    alignItems: 'flex-end',
  },
})
