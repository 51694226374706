import { StyleSheet } from 'react-native'
import { useSelector } from '@/redux'
import CategoryFilterPanel from './CategoryFilterPanel'
import Column from '@/components/Column'
import Header from './Header'
import NewSummary from './NewSummary'
import React from 'react'
import RightNavBar from '@/components/RightNavBar'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function Stats (props) {
  const isDataInit = useSelector(state => state.app.isDataInit)
  const [openFilter, setOpenFilter] = React.useState(false)

  if (!isDataInit) {
    return null
  }

  return (
    <Row style={styles.container}>
      <Column style={[styles.content, { zIndex: 99, elevation: 7 }]}>
        <Header setOpenFilter={() => setOpenFilter(!openFilter)} />
        <NewSummary />
      </Column>
      <RightNavBar />
      {openFilter && <CategoryFilterPanel onClose={() => setOpenFilter(false)} />}
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.light,
  },
  content: {
    flex: 1,
  },
})
