import { Icon } from 'react-native-elements'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { getDisplayCustomerName } from '../libs/order'
import Button from './buttons/Button'
import Column from '@/components/Column'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

/**
 * @typedef CustomerInfoCardProps
 * @property {*} customer
 * @property {ViewProps['style']?} style
 * @property {boolean?} register
 */

/**
 *
 * @param {CustomerInfoCardProps} props
 * @returns
 */
export default function CustomerInfoCard (props) {
  const dispatch = useDispatch()
  const { customer: { id, address, name, customerNameSuffix, memberId, phone, usePhoneInCRM }, style, register = false } = props
  const enableCRM = useSelector(state => state.merchant.data.setting.enableCRM)
  const { t } = useTranslation()

  if (!memberId && !name && !phone && enableCRM) {
    // 防止centerModal 相撞出現bug，register = false 不能加入會員
    return register ? (
      <View style={[styles.container, style]}>
        <Button
          title={t('app.component.customerInfoCard.memberLogin')}
          onPress={() => {
            dispatch(actions.app.showDialog(['memberRegistrationDialog']))
          }}
          style={{ alignSelf: 'center' }}
        />
      </View>
    ) : null
  }

  // 顯示會員電話 (去除+852)，加上+852 UI會奇怪
  const formattedPhone = phone.includes('+852') ? phone.replace('+852', '') : phone

  return (
    <Column style={[styles.container, style, { paddingLeft: 10 }]}>
      <Row style={[styles.phone]}>
        <Row style={{ marginBottom: 5 }}>
          <Image
            style={styles.phoneIcon}
            source={require('@icons/phone.png')}
          />
          <Text style={styles.text}>
            {formattedPhone || '-'}
          </Text>
        </Row>
        {/* 加入button 或 顯示會員 */}
        {(usePhoneInCRM && phone)
          ? (
            <View style={[styles.register, { backgroundColor: 'transparent' }]}>
              <Text style={[styles.registerText, { color: colors.primary }]}>{t('app.component.customerInfoCard.member')}</Text>
            </View>
          )
          : (
            register &&
              <TouchableOpacity
                style={styles.register}
                onPress={() => {
                  // 未成為會員客人可加入會員
                  if (!phone) {
                    dispatch(actions.app.showDialog(['memberRegistrationDialog', 'memberRegistrationOpen']))
                  } else {
                    dispatch(actions.orderHistory.updateOrderOwner(id, { phone: phone, name: name }))
                  }
                }}
              >
                <Text style={styles.registerText}>{t('app.component.customerInfoCard.join')}</Text>
              </TouchableOpacity>
          )}
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Icon
          name='account-circle-outline'
          type='material-community'
          size={16}
          style={{ color: colors.primary, marginRight: 3 }}
        />
        <Text style={styles.titleText}>
          {name ? getDisplayCustomerName(name, customerNameSuffix) : '-'}
        </Text>
      </Row>
      {(address && !register) ? (
        <Row>
          <Text style={styles.text}>{address}</Text>
        </Row>) : null}
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 85,
    width: 172,
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderRadius: 5,
    ...shadows.default,
    alignContent: 'center',
  },
  titleText: {
    fontSize: 13,
    fontWeight: '500',
    color: colors.primary,
  },
  text: {
    fontSize: 13,
    lineHeight: 15,
    fontWeight: '500',
    color: colors.gray,
  },
  phone: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  phoneIcon: {
    height: 16,
    width: 16,
    marginRight: 3,
  },
  register: {
    height: 25,
    width: 45,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    position: 'absolute',
    top: -5,
    right: 10,
    backgroundColor: colors.lightPrimary,
  },
  registerText: {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '500',
    color: colors.white,
  },
})
