import { FlatList, StyleSheet, View } from 'react-native'
import { actions, useDispatch } from '@/redux'
import React from 'react'
import SubItem from './SubItem'
import colors from '@/theme/colors'

/* eslint-disable no-unused-vars */
import { IAppBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { SetItemsProps } from '@/pages/Order/OrderBatch/BatchList/index.d'
/* eslint-enable no-unused-vars */

/**
 * @param {SetItemsProps} props
 */
export default function QuantityItems (props) {
  const {
    serial,
    item,
  } = props
  const dispatch = useDispatch()

  const singleItem = {
    ...item,
    quantity: 1,
    totalDiscount: item.totalDiscount / item.quantity,
    surcharge: item.surcharge / item.quantity,
    total: item.total / item.quantity,
  }

  // 根據 quantity 產生 array，內容都一樣是 item
  const data = Array(item.quantity).fill(singleItem)

  /**
   *
   * @param {{item: IAppBatchItem, index: number}} info
   * @returns {React.ReactElement}
   */
  const renderItem = ({ item, index }) => {
    return (
      <SubItem
        isQuantity
        serial={serial + index}
        item={item}
        onPress={() => {
          // 選擇 serial，用來知道被選擇的是哪一個 subitem
          dispatch(actions.orderBatch.selectQuantity({ serial: serial + index }))
        }}
        onDelete={() => {
          // 刪除其實是把 selectedItem 數量 -1
          dispatch(actions.orderBatch.selectQuantity(null))
          dispatch(actions.orderBatch.addQuantity(-1))
        }}
      />
    )
  }

  return (
    <View style={styles.container}>
      <FlatList
        scrollEnabled={false}
        data={data}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(1000 * item.quantity + index)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // width:580,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderColor: colors.lightGray5,
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'solid',
  },
})
