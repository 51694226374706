import _ from 'lodash'
import store from '@/redux/store'

// eslint-disable-next-line no-unused-vars
import { ITable, ITableArea } from '@/redux/table/Table.d'

/** @type {() => IRootState} */
const getState = store.getState

/**
 * @returns {ITable | undefined}
 */
export function getSelectedTable () {
  const selectedTableId = getState().table.selectedTableId
  const tables = getState().table.tables
  return _.find(tables, table => table.id === selectedTableId)
}

/**
 * @returns {ITableArea | {}}
 */
export function getSelectedTableArea () {
  const selectedTable = getSelectedTable()
  const tableAreas = getState().table.tableAreas
  if (!selectedTable) return {}
  return _.find(tableAreas, area => area.id === selectedTable.areaId)
}
