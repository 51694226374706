import { KeyboardAvoidingView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { produce } from 'immer'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderRightAction from '@/components/Setting/HeaderRightAction'
import HeaderText from '@/components/Setting/HeaderText'
import OptionItem from '@root/src/pages/Setting/MenuSetting/OptionPanel/OptionItem'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'

import PresetOptionItem from './PresetOptionItem'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionGroup } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 * @typedef OptionGroupEditorProps
 * @property {IMenuOptionGroup} optionGroup
 * @property {() => void} onClose
 * @property {(submittedOptionGroup :IMenuOptionGroup) => void} onSubmit
 */

/**
 *
 * @param {OptionGroupEditorProps} props
 * @returns
 */
export default function OptionGroupEditor (props) {
  const { optionGroup, menuId, locale, onClose, onSubmit, onDelete } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const OptionListRef = React.useRef()
  const [expand, setExpand] = React.useState(false)
  const [keyboardAvoidingViewEnabled, setKeyboardAvoidingViewEnabled] = React.useState(false)
  const [offset, setOffset] = React.useState(0)

  const [editingOptionGroup, updateEditingOptionGroup] = useImmer(optionGroup)

  if (!optionGroup) return null

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.menuPanel.options.alert.delete.title'),
      message: `${t('app.page.setting.menuPanel.options.alert.delete.msg')}${optionGroup.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            onDelete()
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const disableSave = _.isEmpty(editingOptionGroup.name) || // 如果 editingOptionGroup 沒有設名字
    _.size(editingOptionGroup.options) === 0 || // 如果 editingOptionGroup 底下沒有設定任何 option
    Boolean(_.find(editingOptionGroup.options, opt => !opt.name)) || // 如果 editingOptionGroup 底下的 option 其中有一個沒有設名字
    _.isEqual(optionGroup, editingOptionGroup) // 如果 editingOptionGroup 沒有任何改動

  const handleSave = () => {
    // 儲存時將文字輸入欄位轉成數值
    const editedOptionGroup = produce(editingOptionGroup, draft => {
      draft.max = Number(draft.max)
      draft.min = Number(draft.min)
      draft.options = draft.options.map(option => {
        option.price = Number(option.price)
        option.max = Number(option.max)
        return option
      })
    })
    onSubmit(editedOptionGroup)
    if (menuId && editedOptionGroup?.id) {
      _.each(_.keys(editingOptionGroup.localeNames), l => {
        if (!_.isEqual(_.get(optionGroup, `localeNames.${l}`), _.get(editingOptionGroup, `localeNames.${l}`))) {
          dispatch(actions.menu.updateMenuOptionGroupTranslation(menuId,
            editedOptionGroup?.id,
            {
              ...editingOptionGroup,
              name: _.get(editingOptionGroup, `localeNames.${l}`, editingOptionGroup.name),
              locale: l,
            }))
        }
      })
      _.each(optionGroup.options, option => {
        const editedOption = _.find(editingOptionGroup.options, o => o.id === option.id)
        if (editedOption) {
          _.each(_.keys(editedOption.localeNames), l => {
            if (!_.isEqual(_.get(option, `localeNames.${l}`), _.get(editedOption, `localeNames.${l}`))) {
              dispatch(actions.menu.updateMenuOptionTranslation(menuId,
                editedOptionGroup?.id,
                editedOption?.id,
                {
                  ...editedOption,
                  name: _.get(editedOption, `localeNames.${l}`, editedOption.name),
                  locale: l,
                }))
            }
          })
        }
      })
    }
    onClose()
  }

  const handleDragEnd = (options) => {
    // save options order
    updateEditingOptionGroup(draft => {
      draft.options = options
    })
  }

  const renderItem = ({ item: option, index, drag, isActive }) => {
    const localeOption = { ...option, name: _.get(option, `localeNames.${locale}`, option.name) }
    if (optionGroup.isPreset) {
      return (
        <PresetOptionItem
          option={localeOption}
          onChangeHidden={(hidden) => {
            updateEditingOptionGroup(draft => {
              draft.options[index].hidden = hidden
            })
          }}
        />
      )
    }
    return (
      <OptionItem
        option={localeOption}
        isExpand={expand}
        dragging={isActive}
        onChangeOption={(field, value) => {
          updateEditingOptionGroup(draft => {
            draft.options[index][field] = value
            if (field === 'name') {
              draft.options[index].localeNames[locale] = value
            }
          })
          if (field === 'hidden') {
            const isAllOptionHidden = editingOptionGroup.options.map((o, i) => {
              if (i === index) {
                return { ...o, hidden: value }
              }
              return o
            }).every(o => o.hidden)
            if (isAllOptionHidden && editingOptionGroup.options.length) {
              updateEditingOptionGroup(draft => {
                draft.min = 0
              })
            }
          }
        }}
        onFocusOption={(field) => {
          let offset = -180
          const height = 280
          const maxOffset = Math.min(index, 1)
          switch (field) {
            case 'name': {
              offset += 0 + (maxOffset * height)
              break
            }
            case 'price': {
              offset += 35 + (maxOffset * height)
              break
            }
            case 'max': {
              offset += 70 + (maxOffset * height)
              break
            }
            default: {
              offset = -180
              break
            }
          }
          setOffset(offset)
          setKeyboardAvoidingViewEnabled(true)
        }}
        onDrag={drag}
        onDelete={() => {
          updateEditingOptionGroup(draft => {
            draft.options.splice(index, 1)
          })
        }}
      />
    )
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      contentContainerStyle={styles.container}
      behavior='position'
      enabled={keyboardAvoidingViewEnabled}
      keyboardVerticalOffset={offset}
    >
      <Header>
        <HeaderText text={optionGroup.id ? t('app.page.setting.menuPanel.options.optionGroup.edit') : t('app.page.setting.menuPanel.options.optionGroup.add')} />
      </Header>

      <SettingList>
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.name')} />
          <SettingListItemRightAction>
            <SettingListInput
              autoFocus={!optionGroup.id && editingOptionGroup.name === ''}
              value={_.get(editingOptionGroup, `localeNames.${locale}`, editingOptionGroup.name)}
              onChangeText={(text) => {
                updateEditingOptionGroup(draft => {
                  // iOS 字串最後一個空格不會顯示，將空格取代為 \u00a0 讓他顯示出空格
                  draft.name = replaceSpace(text)
                  draft.localeNames[locale] = replaceSpace(text)
                })
              }}
              onBlur={() => {
                updateEditingOptionGroup(draft => {
                  // 結束編輯時，將 \u00a0 換回普通的 \u0020 空格
                  draft.name = replaceSaveSpace(editingOptionGroup?.name, true)
                  draft.localeNames[locale] = replaceSaveSpace(editingOptionGroup?.name, true)
                })
              }}
              onFocus={() => {
                setKeyboardAvoidingViewEnabled(false)
              }}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListSwitchRow
          divider
          label={t('app.page.setting.menuPanel.options.optionGroup.must')}
          value={editingOptionGroup.min > 0}
          onChangeValue={(required) => {
            if (required) {
              updateEditingOptionGroup(draft => {
                draft.min = _.max([editingOptionGroup.min, 1])
              })
              if (editingOptionGroup.options.length) {
                updateEditingOptionGroup(draft => {
                  draft.options[0].hidden = false
                })
              }
            } else {
              updateEditingOptionGroup(draft => {
                draft.min = 0
              })
            }
          }}
        />
        {editingOptionGroup.min > 0 && (
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.mustMin')} />
            <SettingListItemRightAction>
              <SettingListInput
                keyboardType='number-pad'
                value={String(editingOptionGroup.min) || ''}
                onChangeText={(text) => {
                  updateEditingOptionGroup(draft => {
                    draft.min = text.replace(/\D/g, '')
                  })
                }}
                onFocus={() => {
                  setKeyboardAvoidingViewEnabled(false)
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        )}
        <SettingListSwitchRow
          divider={editingOptionGroup.multiple}
          label={t('app.page.setting.menuPanel.options.optionGroup.multiple')}
          value={editingOptionGroup.multiple}
          onChangeValue={(multiple) => {
            updateEditingOptionGroup(draft => {
              draft.multiple = multiple
            })
          }}
        />
        {editingOptionGroup.multiple && (
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.multipleLimit')} />
            <SettingListItemRightAction>
              <SettingListInput
                keyboardType='number-pad'
                value={String(editingOptionGroup.max) || ''}
                onChangeText={(text) => {
                  if (/^\d+$/.test(text) || text === '') {
                    updateEditingOptionGroup(draft => {
                      draft.max = text
                    })
                  }
                }}
                onFocus={() => {
                  setKeyboardAvoidingViewEnabled(false)
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        )}

      </SettingList>

      <Header>
        <HeaderText text={t('app.page.setting.menuPanel.options.option.item')} />
        <HeaderRightAction>
          <TouchableOpacity
            style={{ marginRight: 30 }}
            onPress={() => setExpand(!expand)}
          >
            {
              expand
                ? <HeaderText style={{ color: colors.darkSecondary }} text={t('app.page.setting.menuPanel.options.option.collapseAll')} />
                : <HeaderText style={{ color: colors.darkSecondary }} text={t('app.page.setting.menuPanel.options.option.expandAll')} />
            }
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              updateEditingOptionGroup(draft => {
                draft.options.push({
                  locale,
                  name: '',
                  price: 0,
                  max: 1,
                  hidden: false,
                  localeNames: {
                    [locale]: '',
                  },
                })
              })
            }}
          >
            <HeaderText style={{ color: colors.darkSecondary }} text={t('app.page.setting.menuPanel.options.option.add')} />
          </TouchableOpacity>
        </HeaderRightAction>
      </Header>

      <DraggableFlatList
        ref={OptionListRef}
        style={styles.flatList}
        containerStyle={{ flex: 1 }}
        data={editingOptionGroup.options}
        keyExtractor={(item, index) => index.toString()}
        renderItem={renderItem}
        onDragEnd={({ data }) => handleDragEnd(data)}
      />

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          disabled={!optionGroup.id}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
          disabled={disableSave}
        />
      </View>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    // margin: 8,
  },
  addIcon: {
    width: 25,
    height: 25,
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
  },
  flatList: {
    flex: 1,
    height: '100%',
    marginBottom: 10,
  },
})
