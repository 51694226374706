import { Platform } from 'react-native'
import { useEffect } from 'react'
import NetInfo from '@react-native-community/netinfo'
import Ping from 'react-native-ping'

import { actions, useDispatch, useSelector } from '@/redux'
import configs from '@/configs'

const isApp = Platform.OS !== 'web'

/**
 * 檢查裝置網路連線狀態
 * @param {number} [delay]
 */
export default function useCheckNetworkConnection (delay = 60 * 1000) {
  const dispatch = useDispatch()
  const isUserLogin = useSelector(state => state.auth.isUserLogin)
  const isDataInit = useSelector(state => state.app.isDataInit)
  const { apiUrls } = configs[process.env.NODE_ENV] || configs.default

  useEffect(() => {
    NetInfo.configure({
      reachabilityUrl: `${apiUrls.go}/s/health`,
      reachabilityTest: async (response) => response.status === 200,
      reachabilityLongTimeout: 15 * 1000, // 15s
      reachabilityShortTimeout: 5 * 1000, // 5s
      reachabilityRequestTimeout: 10 * 1000, // 10s
    })

    const unsubscribe = NetInfo.addEventListener(state => {
      dispatch(actions.app.updateNetInfo(state))
    })

    async function getTrafficStats () {
      NetInfo.fetch().then(state => {
        dispatch(actions.app.updateNetInfo(state))
      })

      try {
        const {
          receivedNetworkSpeed,
          sendNetworkSpeed,
          receivedNetworkTotal,
          sendNetworkTotal,
        } = await Ping.getTrafficStats()
        const networkSpeed = {
          receivedNetworkSpeed,
          sendNetworkSpeed,
          receivedNetworkTotal,
          sendNetworkTotal,
          errorCode: null,
          errorMessage: null,
        }
        await dispatch(actions.app.updateNetInfo(networkSpeed))
      } catch (error) {
        await dispatch(actions.app.updateNetInfo({
          errorCode: error.code,
          errorMessage: error.message,
        }))
      }
    }

    let intervalId = ''
    if (isApp && isDataInit && isUserLogin) {
      getTrafficStats()
      intervalId = setInterval(() => {
        getTrafficStats()
      }, delay)
    }

    return () => {
      unsubscribe()
      clearInterval(intervalId)
    }
  }, [isDataInit, isUserLogin])
}
