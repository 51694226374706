import _set from 'lodash/set'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IStatState} */
export const initialState = {
  isInit: false,
  data: {
    summary: {},
    category: {},
    cancel: {},
    department: {},
    tableGroupSales: {}, // 枱號群統計
    option: {}, // 選項統計
  },
  selectedStatType: 'summary',
  selectedSubType: 'items',
  datetime: {
    from: null,
    to: null,
  },
  filter: {
    key: [],
    text: '',
    filters: {
      setItem: false,
    },
  },
}

export default produce(
  /**
   * @param {IStatState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_STAT_DATA: {
        const { path, data } = action.payload
        _set(draft.data, path, data)
        break
      }
      case ActionTypes.SELECT_STAT_TYPE: {
        const { type } = action.payload
        draft.selectedStatType = type
        break
      }
      case ActionTypes.SELECT_SUB_TYPE: {
        const { type } = action.payload
        draft.selectedSubType = type
        break
      }
      case ActionTypes.UPDATE_STAT_DATETIME: {
        const { datetime } = action.payload
        draft.datetime = datetime
        break
      }
      case ActionTypes.SELECT_FILTER: {
        const { filter } = action.payload
        draft.filter = filter
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
