const sampleOrder = {
  isSample: true,
  id: '34ba3ac6-595e-4c87-8951-db39c4a27148',
  apiVersion: '2.0.0',
  serial: '174-3327',
  orderSerial: '174-3327',
  merchantId: '6ffc77e8-4622-4b8b-a318-41ac2795624f',
  merchantName: '岳川串揚 Yakimori',
  merchantAddress: '銅鑼灣摩理臣山道8號地舖',
  table: '2',
  customers: 2,
  adults: 2,
  children: 0,
  items: [
    {
      id: '23f8f771-9959-479d-ac42-0c5a53e3a756',
      categoryId: '1c502b50-0f97-43f0-a185-e8227e8e879d',
      menuId: 'bf615163-25d2-45db-9435-76e9cb7e5a3f',
      setId: '',
      originMenuId: 'bf615163-25d2-45db-9435-76e9cb7e5a3f',
      setMenuIndex: null,
      setName: null,
      setNameCode: null,
      name: 'Suntory 生啤（第2支半價）',
      quantity: 2,
      price: 102,
      prices: [
        102,
        102,
      ],
      discounts: [
        0,
        0,
      ],
      totalDiscounts: [
        0,
        0,
      ],
      originalTotal: 204,
      totalDiscount: 0,
      modifiers: [
        [

        ],
        [

        ],
      ],
      tags: [
        [

        ],
        [

        ],
      ],
      total: 204,
      totals: [
        102,
        102,
      ],
      priceUndetermined: false,
      excludeOrderDiscount: false,
      excludedOrderSurcharge: false,
      options: [
        [
          {
            optionId: 'f862dd04-134e-4653-923b-f95545e8d135',
            assoOptionId: '',
            categoryId: '',
            name: 'Suntory（第二枝半價） ',
            price: 34,
            quantity: 1,
          },
        ],
        [
          {
            optionId: 'f862dd04-134e-4653-923b-f95545e8d135',
            assoOptionId: '',
            categoryId: '',
            name: 'Suntory（第二枝半價） ',
            price: 34,
            quantity: 1,
          },
        ],
      ],
      unconfirmed: false,
      cancelled: false,
      CancelIdentifier: null,
      cancelReason: null,
      cancelApprover: null,
      remarks: [
        [

        ],
        [

        ],
      ],
      code: '',
      divisor: 0,
      discount: 0,
      priceDeductDiscount: 68,
      kitchenCompletedAt: null,
      kitchenReceivedAt: null,
      printed: null,
      printError: null,
      identifier: '',
      createdAt: null,
      setStepId: '',
      ids: [
        '23f8f771-9959-479d-ac42-0c5a53e3a756',
        '74d268eb-0b0a-4421-8726-b072fd8a181e',
      ],
    },
    {
      id: '8d829d79-0d8e-4af9-ab16-9d6f3bcabb27',
      categoryId: 'd99c114b-2444-45d5-9831-442e44733fd6',
      menuId: '58557e6a-3bc8-4d46-8a9f-63d8c17c5df4',
      setId: '',
      originMenuId: '58557e6a-3bc8-4d46-8a9f-63d8c17c5df4',
      setMenuIndex: null,
      setName: null,
      setNameCode: null,
      name: '燒日本蠔(4隻)',
      quantity: 1,
      price: 195,
      prices: [
        195,
      ],
      discounts: [
        0,
      ],
      totalDiscounts: [
        0,
      ],
      originalTotal: 195,
      totalDiscount: 0,
      modifiers: [
        [

        ],
      ],
      tags: [
        [

        ],
      ],
      total: 195,
      totals: [
        195,
      ],
      priceUndetermined: false,
      excludeOrderDiscount: false,
      excludedOrderSurcharge: false,
      options: [
        [

        ],
      ],
      unconfirmed: false,
      cancelled: false,
      CancelIdentifier: null,
      cancelReason: null,
      cancelApprover: null,
      remarks: [
        [

        ],
      ],
      code: '',
      divisor: 0,
      discount: 0,
      priceDeductDiscount: 195,
      kitchenCompletedAt: null,
      kitchenReceivedAt: null,
      printed: null,
      printError: null,
      identifier: '',
      createdAt: null,
      setStepId: '',
      ids: [
        '8d829d79-0d8e-4af9-ab16-9d6f3bcabb27',
      ],
    },
    {
      id: '041dcdbd-8e87-4984-82a9-313a1464e9e8',
      categoryId: '38983f3c-2edc-4341-82f3-b6763b1cea8d',
      menuId: '6cc5a57e-ae32-4a87-a719-39e556b154a3',
      setId: '',
      originMenuId: '6cc5a57e-ae32-4a87-a719-39e556b154a3',
      setMenuIndex: null,
      setName: null,
      setNameCode: null,
      name: '雞皮餃子 (4pcs) （揚）',
      quantity: 1,
      price: 48,
      prices: [
        48,
      ],
      discounts: [
        0,
      ],
      totalDiscounts: [
        0,
      ],
      originalTotal: 48,
      totalDiscount: 0,
      modifiers: [
        [

        ],
      ],
      tags: [
        [

        ],
      ],
      total: 48,
      totals: [
        48,
      ],
      priceUndetermined: false,
      excludeOrderDiscount: false,
      excludedOrderSurcharge: false,
      options: [
        [

        ],
      ],
      unconfirmed: false,
      cancelled: false,
      CancelIdentifier: null,
      cancelReason: null,
      cancelApprover: null,
      remarks: [
        [

        ],
      ],
      code: '',
      divisor: 0,
      discount: 0,
      priceDeductDiscount: 48,
      kitchenCompletedAt: null,
      kitchenReceivedAt: null,
      printed: true,
      printError: false,
      identifier: 'user1',
      createdAt: '2022-10-25T14:44:18.448Z',
      setStepId: '',
      ids: [
        '041dcdbd-8e87-4984-82a9-313a1464e9e8',
      ],
    },
    {
      id: '9e4a0454-43dd-4cab-9b2a-4c82c54f9f10',
      categoryId: 'ce120da5-994e-499f-9459-15a2d89b2f62',
      menuId: 'd1e119b7-6364-480b-bbd3-5a37ee7f999d',
      setId: '',
      originMenuId: 'd1e119b7-6364-480b-bbd3-5a37ee7f999d',
      setMenuIndex: null,
      setName: null,
      setNameCode: null,
      name: '豚肉湯烏冬（熱）',
      quantity: 1,
      price: 78,
      prices: [
        78,
      ],
      discounts: [
        0,
      ],
      totalDiscounts: [
        0,
      ],
      originalTotal: 78,
      totalDiscount: 0,
      modifiers: [
        [

        ],
      ],
      tags: [
        [

        ],
      ],
      total: 78,
      totals: [
        78,
      ],
      priceUndetermined: false,
      excludeOrderDiscount: false,
      excludedOrderSurcharge: false,
      options: [
        [

        ],
      ],
      unconfirmed: false,
      cancelled: false,
      CancelIdentifier: null,
      cancelReason: null,
      cancelApprover: null,
      remarks: [
        [

        ],
      ],
      code: '',
      divisor: 0,
      discount: 0,
      priceDeductDiscount: 78,
      kitchenCompletedAt: null,
      kitchenReceivedAt: null,
      printed: null,
      printError: null,
      identifier: '',
      createdAt: null,
      setStepId: '',
      ids: [
        '9e4a0454-43dd-4cab-9b2a-4c82c54f9f10',
      ],
    },
  ],
  remarks: [

  ],
  customItems: [

  ],
  discount: {
    percent: 0,
    amount: 0,
    overrideItem: false,
    code: '',
    offeredBy: null,
  },
  surcharge: {
    percent: 10,
    amount: 0,
    overrideItem: false,
    useDefault: false,
  },
  originalTotal: 525,
  discountTotal: 0,
  orderDiscount: 0,
  surchargeTotal: 52.5,
  total: 577.5,
  originalShippingFee: 0,
  discountShippingFee: 0,
  payment: null,
  paymentTips: null,
  paidAmount: 578,
  gateway: '',
  extraPayments: null,
  payments: [
    {
      id: '1dfe3d53-e6b2-4d4c-89d7-4f4c9c313df4',
      paymentMethod: 'visa',
      paymentCharge: {
        percent: 0,
        amount: 0,
      },
      paymentTips: 0,
      amount: 578,
      paidAmount: 578,
      change: 0,
      remark: '',
      createdAt: '2022-10-26T02:30:05.534Z',
      status: 'paid',
      reason: '',
      gateway: '',
      payload: '',
      ref: '',
      cancellable: true,
      paidAt: null,
      customerId: '',
    },
  ],
  paying: false,
  payingCustomerId: null,
  status: 'paid',
  paidAt: '2022-10-26T02:30:05.534Z',
  cancelReason: null,
  cancelIdentifier: null,
  cancelApprover: null,
  name: '',
  phone: '',
  createdAt: '2022-10-25T14:38:48.665Z',
  updatedAt: '2022-10-26T02:30:08.386Z',
  cancelledAt: null,
  version: 21,
  takeaway: false,
  delivery: false,
  deliveryStatus: '',
  pickuppOrderNumber: null,
  deliveryType: 'table',
  shipping: {
    key: '',
    name: '',
    cost: 0,
    minAmount: 0,
    type: '',
    minDeliveryMinute: 0,
    maxDeliveryMinute: 0,
    enabled: false,
    discounts: null,
    freightCollect: false,
    areas: null,
    lalamove: null,
    isSystem: false,
    customerAmount: 0,
    baseShippingFee: 0,
    tunnelFee: 0,
    smallOrderFee: 0,
    longDistanceFee: 0,
    stops: null,
  },
  shippingFee: 0,
  shippingProvider: '',
  memberId: '',
  needTableware: false,
  creditSales: false,
  remark: '',
  modifiers: [
    {
      id: 'SURCHARGE',
      name: null,
      type: 'SURCHARGE',
      payload: '',
      code: '',
      percent: 10,
      amount: 0,
      overrideItem: false,
      applyTo: 'PRODUCT',
      calculatedAmount: 52.5,
      offeredBy: null,
      custom: false,
      customName: '',
      customId: '',
      deletedAt: null,
    },
    {
      id: 'DISCOUNT',
      name: null,
      type: 'DISCOUNT',
      payload: '',
      code: '',
      percent: 0,
      amount: 0,
      overrideItem: false,
      applyTo: 'PRODUCT',
      calculatedAmount: 0,
      offeredBy: null,
      custom: false,
      customName: '',
      customId: '',
      deletedAt: null,
    },
  ],
  promocode: '',
  tags: null,
  orderCutOffMins: 720,
  paidByCustomer: false,
  withholding: null,
  subTable: 0,
  orderReferId: '',
  valid: true,
  from: 'CUSTOMER_WEB',
  roundedTotal: 578,
  subtotal: 525,
  originalSubtotal: 525,
  refunded: false,
  calculatedDiscountTotal: 0,
  ricecoin: 0,
  roundedRevenue: 578,
  summary: [
    {
      id: 'bf615163-25d2-45db-9435-76e9cb7e5a3f',
      quantity: 2,
      name: 'Suntory 生啤（第2支半價）',
      price: 204,
      unitPrice: 102,
      items: [

      ],
      remarks: [

      ],
      options: [
        {
          optionId: 'f862dd04-134e-4653-923b-f95545e8d135',
          assoOptionId: '',
          categoryId: '',
          name: 'Suntory（第二枝半價） ',
          price: 34,
          quantity: 1,
        },
      ],
    },
    {
      id: '58557e6a-3bc8-4d46-8a9f-63d8c17c5df4',
      quantity: 1,
      name: '燒日本蠔(4隻)',
      price: 195,
      unitPrice: 195,
      items: [

      ],
      remarks: [

      ],
      options: [

      ],
    },
    {
      id: '6cc5a57e-ae32-4a87-a719-39e556b154a3',
      quantity: 1,
      name: '雞皮餃子 (4pcs) （揚）',
      price: 48,
      unitPrice: 48,
      items: [

      ],
      remarks: [

      ],
      options: [

      ],
    },
    {
      id: 'd1e119b7-6364-480b-bbd3-5a37ee7f999d',
      quantity: 1,
      name: '豚肉湯烏冬（熱）',
      price: 78,
      unitPrice: 78,
      items: [

      ],
      remarks: [

      ],
      options: [

      ],
    },
  ],
  roundedAmount: 0.5,
  merchantOrderDiscount: 0,
  serviced: false,
  servicedCustomerId: null,
  merchantPortalRoundedTotal: 525,
  unpaid: false,
  printedCheckoutReceipt: false,
  categoryTag: '',
  customerNameSuffix: '先生',
  creatorId: '',
  rounding: {
    type: 'off',
    digits: 0,
  },
  batches: [
    {
      orderId: '34ba3ac6-595e-4c87-8951-db39c4a27148',
      orderSerial: '174-3327',
      batchId: 'dd597bbf-6f0f-489f-ba4b-a0e072fc5aaa',
      table: '3C',
      takeout: false,
      index: 0,
      items: [
        {
          id: '23f8f771-9959-479d-ac42-0c5a53e3a756',
          ids: [
            '23f8f771-9959-479d-ac42-0c5a53e3a756',
            '74d268eb-0b0a-4421-8726-b072fd8a181e',
          ],
          categoryId: '1c502b50-0f97-43f0-a185-e8227e8e879d',
          menuId: 'bf615163-25d2-45db-9435-76e9cb7e5a3f',
          setId: '',
          originMenuId: 'bf615163-25d2-45db-9435-76e9cb7e5a3f',
          setMenuIndex: null,
          setName: null,
          setNameCode: null,
          name: 'Suntory 生啤（第2支半價）',
          quantity: 2,
          price: 102,
          prices: [
            102,
            102,
          ],
          discounts: [
            0,
            0,
          ],
          totalDiscounts: [
            0,
            0,
          ],
          originalTotal: 204,
          totalDiscount: 0,
          modifiers: [
            [

            ],
            [

            ],
          ],
          tags: [
            [

            ],
            [

            ],
          ],
          total: 204,
          totals: [
            102,
            102,
          ],
          priceUndetermined: false,
          excludeOrderDiscount: false,
          excludedOrderSurcharge: false,
          options: [
            [
              {
                optionId: 'f862dd04-134e-4653-923b-f95545e8d135',
                assoOptionId: '',
                categoryId: '',
                name: 'Suntory（第二枝半價） ',
                price: 34,
                quantity: 1,
              },
            ],
            [
              {
                optionId: 'f862dd04-134e-4653-923b-f95545e8d135',
                assoOptionId: '',
                categoryId: '',
                name: 'Suntory（第二枝半價） ',
                price: 34,
                quantity: 1,
              },
            ],
          ],
          unconfirmed: false,
          cancelled: false,
          CancelIdentifier: null,
          cancelReason: null,
          cancelApprover: null,
          remarks: [
            [

            ],
            [

            ],
          ],
          code: '',
          divisor: 0,
          discount: 0,
          priceDeductDiscount: 68,
          kitchenCompletedAt: null,
          kitchenReceivedAt: null,
          printed: null,
          printError: null,
          identifier: '',
          createdAt: null,
          setStepId: '',
        },
        {
          id: '8d829d79-0d8e-4af9-ab16-9d6f3bcabb27',
          ids: [
            '8d829d79-0d8e-4af9-ab16-9d6f3bcabb27',
          ],
          categoryId: 'd99c114b-2444-45d5-9831-442e44733fd6',
          menuId: '58557e6a-3bc8-4d46-8a9f-63d8c17c5df4',
          setId: '',
          originMenuId: '58557e6a-3bc8-4d46-8a9f-63d8c17c5df4',
          setMenuIndex: null,
          setName: null,
          setNameCode: null,
          name: '燒日本蠔(4隻)',
          quantity: 1,
          price: 195,
          prices: [
            195,
          ],
          discounts: [
            0,
          ],
          totalDiscounts: [
            0,
          ],
          originalTotal: 195,
          totalDiscount: 0,
          modifiers: [
            [

            ],
          ],
          tags: [
            [

            ],
          ],
          total: 195,
          totals: [
            195,
          ],
          priceUndetermined: false,
          excludeOrderDiscount: false,
          excludedOrderSurcharge: false,
          options: [
            [

            ],
          ],
          unconfirmed: false,
          cancelled: false,
          CancelIdentifier: null,
          cancelReason: null,
          cancelApprover: null,
          remarks: [
            [

            ],
          ],
          code: '',
          divisor: 0,
          discount: 0,
          priceDeductDiscount: 195,
          kitchenCompletedAt: null,
          kitchenReceivedAt: null,
          printed: null,
          printError: null,
          identifier: '',
          createdAt: null,
          setStepId: '',
        },
      ],
      remark: '',
      createdAt: '2022-10-25T14:38:48.665Z',
      updatedAt: '2022-10-25T14:38:48.665Z',
      receivedAt: null,
      status: 'confirmed',
      takeawayStatus: null,
      delivery: false,
      storeDelivery: false,
      shop: false,
      takeaway: false,
      deliveryStatus: '',
      deliveryType: 'table',
      pickuppOrderNumber: null,
      from: 'CUSTOMER_WEB',
      identifier: '972b1c85-3b88-47a3-9b4d-472b87a32aaa',
      creatorId: '',
      submittedAt: '2022-10-25T14:38:48.665Z',
      confirmByDeviceId: '26E395AB-8C6E-4202-B0E7-2B863FAC56EC',
      isWaiter: false,
    },
    {
      orderId: '34ba3ac6-595e-4c87-8951-db39c4a27148',
      orderSerial: '174-3327',
      batchId: 'bf8ea533-f534-47df-831f-120acc1e6cb9',
      table: '3C',
      takeout: false,
      index: 1,
      items: [
        {
          id: '041dcdbd-8e87-4984-82a9-313a1464e9e8',
          ids: [
            '041dcdbd-8e87-4984-82a9-313a1464e9e8',
          ],
          categoryId: '38983f3c-2edc-4341-82f3-b6763b1cea8d',
          menuId: '6cc5a57e-ae32-4a87-a719-39e556b154a3',
          setId: '',
          originMenuId: '6cc5a57e-ae32-4a87-a719-39e556b154a3',
          setMenuIndex: null,
          setName: null,
          setNameCode: null,
          name: '雞皮餃子 (4pcs) （揚）',
          quantity: 1,
          price: 48,
          prices: [
            48,
          ],
          discounts: [
            0,
          ],
          totalDiscounts: [
            0,
          ],
          originalTotal: 48,
          totalDiscount: 0,
          modifiers: [
            [

            ],
          ],
          tags: [
            [

            ],
          ],
          total: 48,
          totals: [
            48,
          ],
          priceUndetermined: false,
          excludeOrderDiscount: false,
          excludedOrderSurcharge: false,
          options: [
            [

            ],
          ],
          unconfirmed: false,
          cancelled: false,
          CancelIdentifier: null,
          cancelReason: null,
          cancelApprover: null,
          remarks: [
            [

            ],
          ],
          code: '',
          divisor: 0,
          discount: 0,
          priceDeductDiscount: 48,
          kitchenCompletedAt: null,
          kitchenReceivedAt: null,
          printed: true,
          printError: false,
          identifier: 'user1',
          createdAt: '2022-10-25T14:44:18.448Z',
          setStepId: '',
        },
      ],
      remark: '',
      createdAt: '2022-10-25T14:44:18.448Z',
      updatedAt: '2022-10-25T14:44:18.448Z',
      receivedAt: null,
      status: 'confirmed',
      takeawayStatus: null,
      delivery: false,
      storeDelivery: false,
      shop: false,
      takeaway: false,
      deliveryStatus: '',
      deliveryType: 'table',
      pickuppOrderNumber: null,
      from: 'MERCHANT',
      identifier: 'user1',
      creatorId: '',
      submittedAt: '2022-10-25T14:44:18.448Z',
      confirmByDeviceId: '26E395AB-8C6E-4202-B0E7-2B863FAC56EC',
      isWaiter: false,
    },
    {
      orderId: '34ba3ac6-595e-4c87-8951-db39c4a27148',
      orderSerial: '174-3327',
      batchId: '4acdfcf0-bbc9-48b0-92ca-30e6c46706af',
      table: '3C',
      takeout: false,
      index: 2,
      items: [
        {
          id: '9e4a0454-43dd-4cab-9b2a-4c82c54f9f10',
          ids: [
            '9e4a0454-43dd-4cab-9b2a-4c82c54f9f10',
          ],
          categoryId: 'ce120da5-994e-499f-9459-15a2d89b2f62',
          menuId: 'd1e119b7-6364-480b-bbd3-5a37ee7f999d',
          setId: '',
          originMenuId: 'd1e119b7-6364-480b-bbd3-5a37ee7f999d',
          setMenuIndex: null,
          setName: null,
          setNameCode: null,
          name: '豚肉湯烏冬（熱）',
          quantity: 1,
          price: 78,
          prices: [
            78,
          ],
          discounts: [
            0,
          ],
          totalDiscounts: [
            0,
          ],
          originalTotal: 78,
          totalDiscount: 0,
          modifiers: [
            [

            ],
          ],
          tags: [
            [

            ],
          ],
          total: 78,
          totals: [
            78,
          ],
          priceUndetermined: false,
          excludeOrderDiscount: false,
          excludedOrderSurcharge: false,
          options: [
            [

            ],
          ],
          unconfirmed: false,
          cancelled: false,
          CancelIdentifier: null,
          cancelReason: null,
          cancelApprover: null,
          remarks: [
            [

            ],
          ],
          code: '',
          divisor: 0,
          discount: 0,
          priceDeductDiscount: 78,
          kitchenCompletedAt: null,
          kitchenReceivedAt: null,
          printed: null,
          printError: null,
          identifier: '',
          createdAt: null,
          setStepId: '',
        },
      ],
      remark: '',
      createdAt: '2022-10-25T14:49:40.197Z',
      updatedAt: '2022-10-25T14:49:40.197Z',
      receivedAt: null,
      status: 'confirmed',
      takeawayStatus: null,
      delivery: false,
      storeDelivery: false,
      shop: false,
      takeaway: false,
      deliveryStatus: '',
      deliveryType: 'table',
      pickuppOrderNumber: null,
      from: 'CUSTOMER_WEB',
      identifier: '55d0f8d9-275a-49a7-a3f7-15eb4577b480',
      creatorId: '',
      submittedAt: '2022-10-25T14:49:40.197Z',
      confirmByDeviceId: '26E395AB-8C6E-4202-B0E7-2B863FAC56EC',
      isWaiter: false,
    },
  ],
}

export default sampleOrder
