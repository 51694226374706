import { StyleSheet, Text } from 'react-native'
import { numberWithCommas } from '@root/src/libs/numberWithCommas'
import { useCheckoutPayment } from '@/hooks/orderCheckout'
import { usePaymentMethods } from '@/hooks/merchant'
import { useSelectedOrder } from '@/hooks/orderHistory'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Row from '@/components/Row'
import _ from 'lodash'
import colors from '@/theme/colors'

export default function Header () {
  const { t } = useTranslation()
  const selectedOrder = useSelectedOrder()
  const checkoutPayment = useCheckoutPayment()
  const total = selectedOrder.roundedTotal

  const isEcrPayment = Boolean(checkoutPayment.gateway)
  const paid = isEcrPayment ? Number(checkoutPayment.paidAmount) : Number(checkoutPayment.paidAmount) + Number(checkoutPayment.paymentTips)
  // mutipayment
  // const paidAmount = selectedOrder.payments.filter((p, index) => !(index === selectedOrder.payments.length - 1) && p?.status !== 'cancel')
  //   .map(p => Number(p.paidAmount))
  //   .reduce((a, b) => a + b, 0)
  return (
    <Row style={styles.container}>
      {/* <Text style={styles.text}>{t('app.page.checkout.orderSummary.payTotal')}：＄{numberWithCommas(Number((total).toFixed(1)))}</Text>
      <Text style={styles.text}>{t('app.page.checkout.payment.paidTotal')}：＄{numberWithCommas(Number(paid))}</Text> */}
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 63,
    width: '105%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.lightPrimary,
    borderBottomRightRadius: 20,
    marginLeft: '-5%', // cover the backgorund under OrderSummary
    paddingLeft: '5%', // cover the backgorund under OrderSummary
  },
  text: {
    flex: 1,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.white,
  },
})
