import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { getItemTags } from '@/libs/tags'
import { useSelectedBatchItem, useSelectedOrder } from '@/hooks/order'
import { useTranslation } from 'react-i18next'
import AllTagsDialog from './AllTagsDialog'
import OptionButton from './OptionButton'
import OptionTitle from './OptionTitle'
import React from 'react'
import RemarkInputDialog from './RemarkInputDialog'
import _ from 'lodash'
import colors from '@/theme/colors'

export default function Options (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const menus = useSelector(state => state.menu.menus)
  const sets = useSelector(state => state.menu.sets)
  const [selectedItem, selectedSetItem] = useSelectedBatchItem()
  const batchItem = selectedSetItem || selectedItem
  const customTag = useSelector(state => state.merchant.data.customTag)
  const [allTagsDialogOpen, setAllTagsDialogOpen] = React.useState(false)
  const [remarkInputDialogOpen, setRemarkInputDialogOpen] = React.useState(false)
  const tags = getItemTags()
  const selectedOrder = useSelectedOrder()

  if (!batchItem) return null

  let menuItem = null
  if (batchItem.isSet) {
    menuItem = sets[batchItem.menuId]
  } else {
    menuItem = menus[batchItem.menuId]
  }
  if (!menuItem) return null

  const remark = _.get(batchItem, ['remarks', 0], '')
  tags.unshift({ id: 'title', name: 'tag' })

  if (!_.isEmpty(customTag)) {
    if (customTag?.prefix?.length > 0 || customTag?.suffix?.length > 0) {
      tags.push({ id: 'other', name: t('app.common.other') })
    }
  }

  const renderItem = ({ item: tag }) => {
    if (tag.id === 'title') {
      return (
        <OptionTitle title='Tag' color={colors.primary} />
      )
    }

    const selected = Boolean(batchItem.tags.find(selectedTag => selectedTag.name === tag.name))
    return (
      <OptionButton
        variant='tag'
        text={t(tag.name)}
        onPress={() => tag.id === 'other'
          ? setAllTagsDialogOpen(true)
          : dispatch(actions.orderBatch.toggleTag(tag))}
        selected={selected}
      />
    )
  }

  return (
    <View style={styles.container}>
      <FlatList
        columnWrapperStyle={styles.tagContainer}
        numColumns={2}
        data={tags}
        renderItem={renderItem}
        keyExtractor={(tag, index) => index}
      />
      <OptionTitle title={t('app.constant.order.remark')} color={colors.primary} />
      <TouchableOpacity onPress={() => setRemarkInputDialogOpen(true)}>
        <View style={styles.remarkInputButton}>
          <Text>{remark}</Text>
        </View>
      </TouchableOpacity>
      <AllTagsDialog
        open={allTagsDialogOpen}
        onClose={() => setAllTagsDialogOpen(false)}
      />
      <RemarkInputDialog
        key={batchItem.key + remark}
        remark={remark}
        onSubmit={remark => dispatch(actions.orderBatch.updateRemark(remark))}
        open={remarkInputDialogOpen}
        onClose={() => setRemarkInputDialogOpen(false)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    backgroundColor: colors.white,
    paddingHorizontal: 20,
    paddingVertical: 8,
    borderBottomRightRadius: 20,
  },
  tagContainer: {
    justifyContent: 'space-between',
  },
  remarkInputButton: {
    width: 160,
    height: 25,
    margin: 8,
    paddingHorizontal: 4,
    color: colors.primary,
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: 0,
    borderWidth: 1.3,
    borderColor: colors.primary,
  },
})
