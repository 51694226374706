import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { ecrGateway, paymentMethods } from '@/constants'
import { getPaymentMethod } from '@/libs/merchant'
import { usePaymentMethods } from '@/hooks/merchant'
import { useSelectedOrder } from '@/hooks/orderHistory'
import Button from '@/components/buttons/Button'
import ButtonDialog from '@/components/dialogs/ButtonDialog'
import CancelViaGatewayDialog from '@/components/dialogs/CancelViaGatewayDialog'
import CenterModal from '@/components/CenterModal'
import EPaymentDialog from '@/components/dialogs/EPaymentDialog'
import NumPadDialog from '@/components/dialogs/NumPadDialog'
import PaymentDetailDialog from '@/components/dialogs/PaymentDetailDialog'
import Row from '@/components/Row'
import TableHeader from '@/components/OrderTable/TableHeader'
import TableRow from '@/components/OrderTable/TableRow'
import TextInputDialog from '@/components/dialogs/TextInputDialog'
import colors from '@/theme/colors'
import moment from 'moment'

// eslint-disable-next-line no-unused-vars
import { IAppPayment } from 'dimorder-orderapp-lib/dist/types/AppOrder'

// payment 使用的欄位名稱
const fields = ['time', 'method', 'amount', 'status', null]
// 欄寬
const widths = [30, 20, 25, 20, 110]
// 對齊
const justifyContents = [
  'center',
  'center',
  'center',
  'center',
  'center',
]

/**
 * @typedef PaymentHistoryDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {IAppPayment[]} payments
 * @property {boolean?} showTipsButton
 */

/**
 *
 * @param {PaymentHistoryDialogProps} props
 * @returns
 */
export default function PaymentHistoryDialog (props) {
  const { open, onClose, payments, showTipsButton = true, orderStatus } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [paymentDetailDialogOpen, setPaymentDetailOpen] = React.useState(false)
  const [deleteReasonDialogOpen, setDeleteReasonDialogOpen] = React.useState(null)
  const [cancelViaGatewayDialogOpen, setCancelViaGatewayDialogOpen] = React.useState(null)
  const [selectedPayment, setSelectedPayment] = React.useState(null)
  const [updateTipPayment, setUpdateTipPayment] = React.useState(null)
  const [updatePaymentMethod, setUpdatePaymentMethod] = React.useState(null)
  const [deleteReason, setDeleteReason] = React.useState(null)
  const selectedOrder = useSelectedOrder()
  const [allPaymentMethods, cardProviders, qrProviders] = usePaymentMethods()
  const [visible, setVisible] = React.useState(open)
  const manualRefundRef = React.useRef(false) // EPaymentDialog中‘已成功退款’button
  const cancelRefundRef = React.useRef(false) // EPaymentDialog中‘返回重試’button
  const buttons = _.concat(allPaymentMethods, cardProviders, qrProviders).map(p => {
    return {
      title: t(p.name),
      action: () => {
        const updatedPayment = {
          ...updatePaymentMethod,
          paymentMethod: p.key,
        }
        dispatch(actions.orderHistory.updateOrderPayment(selectedOrder.id, updatePaymentMethod.id, updatedPayment))
      },
      disabled: Boolean(p.gateway),
    }
  })

  React.useEffect(() => {
    setVisible(open)
  }, [open])

  // 表頭
  const headers = [
    t('app.component.paymentHistoryDialog.time'),
    t('app.component.paymentHistoryDialog.method'),
    t('app.component.paymentDetailDialog.amount'),
    t('app.component.paymentHistoryDialog.status'),
    '',
  ]

  const data = useMemo(() => {
    return _.chain(payments)
      .filter(payment => {
        // order.status pending 時顯示全部 payment，paid 或 cancelled 把 pending payment 隱藏
        return orderStatus === 'pending' || payment.status !== 'pending'
      })
      .map(payment => {
        const paymentMethod = getPaymentMethod(payment.paymentMethod)
        const amount = ['paid', 'cancel'].includes(payment.status) ? Number(payment.amount) : 0
        const tips = Number(payment.paymentTips ?? 0)
        const time = payment.status === 'paid' ? payment.paidAt
          : payment.status === 'cancel' ? payment.cancelledAt : payment.createdAt

        let status
        if (payment.status === 'paid') {
          // 已付款
          status = t('app.component.paymentHistoryDialog.success')
        } else if (payment.status === 'pending') {
          // CA 未完成付款
          status = t('app.component.paymentHistoryDialog.pending')
        } else if (payment.status === 'cancel' && Boolean(payment.customerId)) {
          // CA 取消付款
          status = payment.paidAt === null
            ? t('app.component.paymentHistoryDialog.cancel')
            : t('app.component.paymentHistoryDialog.refund')
        } else if (payment.status === 'cancel' && !payment.customerId) {
          // MR 取消付款
          status = t('app.component.paymentHistoryDialog.void')
        }

        return {
          time: moment(time).format('YYYY/MM/DD') + '\n' + moment(time).format('HH:mm:ss'),
          utcTime: time,
          method: `${t(paymentMethod.name)}${payment.customerId ? '\n(DimPay)' : ''}`,
          amount: `${currencyWithCommas(amount)} (${currencyWithCommas(tips)})`,
          status: status,
          paymentStatus: payment.status,
          payment: payment,
          total: amount,
        }
      })
      .orderBy((payment) => payment.utcTime, ['asc'])
      .value()
  }, [payments, orderStatus])

  const paid = _.sumBy(data, (e) => e.paymentStatus === 'paid' ? e.total : 0)

  const renderItem = ({ item, index }) => {
    const newItem = { ...item, method: t(item.method) }
    return (
      <TouchableOpacity
        onPress={() => {
          setSelectedPayment(item.payment)
          setPaymentDetailOpen(true)
        }}
      >
        <TableRow
          item={newItem}
          fields={fields}
          widths={widths}
          justifyContents={justifyContents}
          style={styles.paymentRow}
          textStyle={{ fontSize: 16, textAlign: 'center' }}
          // action button at the end of the row
          renderRightAction={() => {
            const isEcrPayment = Boolean(item.payment.gateway && item.payment.gateway !== 'none')
            const isPaid = item.payment.status === 'paid'
            const isCustomerApp = Boolean(item.payment.customerId)
            const disabled = !isPaid || isCustomerApp

            return (
              <Row style={styles.buttonRow}>
                {((selectedOrder.gateway === '' && selectedOrder.from !== 'MERCHANT') ||
                selectedOrder.from === 'MERCHANT') && !(isEcrPayment && item.payment.paymentMethod === paymentMethods.OCTOPUS) && (
                  <Button
                    title={t('app.component.paymentHistoryDialog.void')}
                    disabled={disabled}
                    onPress={() => {
                      setVisible(false)
                      dispatch(actions.auth.permissionCheck('change-after-paid', () => {
                        setTimeout(() => {
                          setVisible(true)
                        }, 500)
                        setSelectedPayment(item.payment)
                        setDeleteReasonDialogOpen(true)
                      }, () => setTimeout(() => {
                        setVisible(true)
                      }, 500)))
                    }}
                    backgroundColor={colors.light}
                    textColor={colors.secondary}
                  />
                )}
                {
                  showTipsButton && (
                    <Button
                      title={t('app.component.paymentHistoryDialog.update-tips')}
                      onPress={() => {
                        setVisible(false)
                        dispatch(actions.auth.permissionCheck('change-after-paid', () => {
                          setTimeout(() => {
                            setVisible(true)
                          }, 500)
                          setUpdateTipPayment(item.payment)
                        }, () => setTimeout(() => {
                          setVisible(true)
                        }, 500)))
                      }}
                      backgroundColor={colors.light}
                      textColor={colors.secondary}
                      disabled={disabled || !((!isEcrPayment && selectedOrder.from === 'MERCHANT') || (selectedOrder.gateway === '' && selectedOrder.from !== 'MERCHANT'))}
                    />
                  )
                }
                {
                  showTipsButton && (
                    <Button
                      title={t('app.component.paymentHistoryDialog.updatePaymentMethod')}
                      onPress={() => {
                        setVisible(false)
                        dispatch(actions.auth.permissionCheck('repay', () => {
                          setTimeout(() => {
                            setVisible(true)
                          }, 500)
                          setUpdatePaymentMethod(item.payment)
                        }, () => setTimeout(() => {
                          setVisible(true)
                        }, 500)))
                      }}
                      backgroundColor={colors.light}
                      textColor={colors.secondary}
                      disabled={disabled || !(!isEcrPayment && ((selectedOrder.gateway === '' && selectedOrder.from !== 'MERCHANT') ||
                      selectedOrder.from === 'MERCHANT'))}
                    />
                  )
                }
              </Row>
            )
          }}
        />
      </TouchableOpacity>
    )
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title={t('app.component.paymentHistoryDialog.record')}
      visible={visible}
      contentContainerStyle={styles.contentContainer}
      onClose={onClose}
    >
      <TableHeader
        headers={headers}
        widths={widths}
        justifyContents={justifyContents}
        style={{ backgroundColor: colors.lightPrimary, justifyContent: 'flex-start' }}
        textStyle={{ fontSize: 14 }}
      />
      <FlatList
        style={styles.paymentList}
        data={data}
        renderItem={renderItem}
        keyExtractor={(payment, index) => String(index)}
        showsVerticalScrollIndicator={false}
      />
      <View style={styles.summary}>
        <Text style={styles.summaryText}>{`${t('app.component.paymentHistoryDialog.total')} ： ${currencyWithCommas(paid)}`}</Text>
      </View>
      <PaymentDetailDialog
        payment={selectedPayment}
        open={paymentDetailDialogOpen}
        onClose={() => {
          setPaymentDetailOpen(false)
          setSelectedPayment(null)
        }}
      />
      <TextInputDialog
        open={deleteReasonDialogOpen}
        onClose={() => setDeleteReasonDialogOpen(false)}
        title={t('app.component.cancelReasonDialog.title')}
        placeholder={t('app.component.cancelReasonDialog.placeholder')}
        onSubmit={(reason) => {
          if (Object.values(ecrGateway).includes(selectedPayment.gateway) && selectedPayment.paymentMethod !== paymentMethods.OCTOPUS) {
            setDeleteReason(reason)
            setCancelViaGatewayDialogOpen(true)
          } else {
            dispatch(actions.orderHistory.voidOrderPayment(selectedOrder.id, selectedPayment, reason))
          }
        }}
      />
      <NumPadDialog
        open={Boolean(updateTipPayment)}
        onClose={() => setUpdateTipPayment(null)}
        title={t('app.page.checkout.paymentResult.update-tips')}
        defaultValue={0}
        onSubmit={(value) => {
          const isEcrPayment = Boolean(updateTipPayment?.gateway)

          if (isEcrPayment && Object.values(ecrGateway).includes(updateTipPayment.gateway)) {
            dispatch(actions.orderCheckout.adjustCardPayment(updateTipPayment, value))
            setUpdateTipPayment(null)
          } else {
            setUpdateTipPayment(null)
            setPaymentDetailOpen(false)
            dispatch(actions.orderHistory.updatePaymentTips(updateTipPayment.id, value))
          }
        }}
      />
      <ButtonDialog
        open={Boolean(updatePaymentMethod)}
        onClose={() => setUpdatePaymentMethod(null)}
        title={t('app.component.paymentHistoryDialog.updatePaymentMethod')}
        buttons={buttons}
      />
      <CancelViaGatewayDialog
        open={cancelViaGatewayDialogOpen}
        onClose={() => setCancelViaGatewayDialogOpen(false)}
        onSubmit={async (cancelViaGateway) => {
          // 餐廳選擇作廢經不經卡機
          if (cancelViaGateway) {
            dispatch(actions.orderHistory.voidCardPayment(selectedOrder.id, selectedPayment, deleteReason, manualRefundRef, cancelRefundRef, onClose))
          } else {
            dispatch(actions.orderHistory.voidOrderPayment(selectedOrder.id, selectedPayment, deleteReason))
          }
          // 退款流程完成後把manualRefundRef, cancelRefundRef改回false
          setCancelViaGatewayDialogOpen(false)
          manualRefundRef.current = false
          cancelRefundRef.current = false
        }}
      />
      <EPaymentDialog type='refund' manualRef={manualRefundRef} cancelRef={cancelRefundRef} />
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 750,
    padding: 0,
    backgroundColor: colors.light,
  },
  summary: {
    height: 70,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
  paymentList: {
    width: '100%',
  },
  paymentRow: {
    height: 45,
  },
  buttonRow: {
    height: '100%',
    width: 100,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  dimpayText: {

  },
})
