import React from 'react'
import Table from './Table'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { ITable } from '@/redux/table/Table.d'
/* eslint-enable no-unused-vars */

/**
 * @typedef TableFactoryProps
 * @property {ITable} table
 * @property {number} ratio
 * @property {(table: ITable) => void} onSelectTable
 * @property {(order: IAppOrder) => void} onSelectOrder
 * @property {boolean} [disableOrders=false]
 * @property {boolean} [requestWaiter=false]
 * @property {boolean} [forceSrcoll=false]
 * @property {Animated.AnimatedProps<TextProps['style']> | null} animatedStyle
 */

/**
 *
 * @param {TableFactoryProps} props
 * @returns
 */
export default function TableFactory (props) {
  const { table, ratio, ...other } = props
  return (
    <Table
      table={table}
      style={{
        position: 'absolute',
        width: table.size.width * ratio,
        height: table.size.height * ratio,
        borderRadius: table.shape === 'circle' || table.shape === 'oval' ? table.size.width * ratio / 2 : 5,
        left: table.coordinates.x * ratio,
        top: table.coordinates.y * ratio,
      }}
      {...other}
    />
  )
}
