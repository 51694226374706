import _ from 'lodash'
import moment from 'moment'

import { paymentGateways, paymentMethods } from '@/constants'
import i18n from '@/i18n'
import paymentConfigs from '@/configs/paymentConfigs'
import store from '@/redux/store'

/* eslint-disable no-unused-vars */
import { IApiOrder } from 'dimorder-orderapp-lib/dist/types/ApiOrder'
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IFilter } from '@/redux/orderHistory/OrderHistoryState.d'
/* eslint-enable no-unused-vars */

const getState = store.getState

export function filterSections () {
  const tableAreas = getState().table.tableAreas
  const groupTables = getState().table.groupTables// paymentConfigs.flatPaymentMethods
  const merchantPayments = getState().merchant.data.payments
  const quickMode = getState().merchant.data?.setting?.quickMode
  const methods = _.map(merchantPayments, payment => {
    const defaultPayment = _.find(paymentConfigs.flatPaymentMethods, method => method.key === payment.key)
    return defaultPayment || payment
  })
  // filters
  const deliveryTypeFilters = [
    { value: 'table', name: 'app.constant.deliveryType.table', quickMode: [false] },
    { value: 'takeaway', name: 'app.constant.deliveryType.takeaway', quickMode: [true, false] },
    { value: 'storeDelivery', name: 'app.constant.deliveryType.storeDelivery', quickMode: [true, false] },
  ]

  const displayStatusKeyfilters = [
    { value: 'waiting_order', name: 'app.constant.forFilter.waiting_order', quickMode: [false] },
    { value: 'waiting_accept', name: 'app.constant.forFilter.waiting_accept', quickMode: [true, false] },
    { value: 'preparing_meal', name: 'app.constant.forFilter.preparing_meal', quickMode: [true, false] },
    { value: 'waiting_pick_up', name: 'app.constant.forFilter.waiting_pick_up', quickMode: [true, false] },
    { value: 'picked_up', name: 'app.constant.forFilter.picked_up', quickMode: [true, false] },
    { value: 'waiting_pay', name: 'app.constant.forFilter.waiting_pay', quickMode: [true, false] },
    { value: 'paid', name: 'app.constant.forFilter.paid', quickMode: [true, false] },
    { value: 'cancel_table', name: 'app.constant.forFilter.cancel_table', quickMode: [false] },
    { value: 'delete_order', name: 'app.constant.forFilter.delete_order', quickMode: [true, false] },
  ]

  const tablefilters = _.map(tableAreas, area => {
    const tables = _.filter(groupTables, table => table.areaId === area.id).map(table => table.key)
    return {
      value: tables,
      name: area.text,
      quickMode: [false],
    }
  })

  const paymentMethodFilters = _.map(methods, method => {
    return {
      value: method.key,
      name: method.name,
      quickMode: [true, false],
    }
  })
  if (quickMode) {
    return [
      { field: 'deliveryType', name: 'app.component.filter.deliveryType', fieldValues: deliveryTypeFilters, columns: 3 },
      { field: 'displayStatusKey', name: 'app.component.filter.displayStatusKey', fieldValues: displayStatusKeyfilters, columns: 3 },
      { field: 'paymentMethods', name: 'app.page.setting.checkout.payment', fieldValues: paymentMethodFilters, columns: 3 },
    // { field: 'status', name: '訂單狀態', fieldValues: statusFilters, columns: 3 },
    // { field: 'takeawayStatus', name: '外送狀態', fieldValues: takeawayStatusFilters, columns: 3 },
    // { field: 'shippingProvider', name: '外送分類', fieldValues: shippingProviderFilter, columns: 2 },
    ]
  }
  return [
    { field: 'deliveryType', name: 'app.component.filter.deliveryType', fieldValues: deliveryTypeFilters, columns: 3 },
    { field: 'displayStatusKey', name: 'app.component.filter.displayStatusKey', fieldValues: displayStatusKeyfilters, columns: 3 },
    { field: 'table', name: 'app.page.setting.table.tableGroup', fieldValues: tablefilters, columns: 3 },
    { field: 'paymentMethods', name: 'app.page.setting.checkout.payment', fieldValues: paymentMethodFilters, columns: 3 },
    // { field: 'status', name: '訂單狀態', fieldValues: statusFilters, columns: 3 },
    // { field: 'takeawayStatus', name: '外送狀態', fieldValues: takeawayStatusFilters, columns: 3 },
    // { field: 'shippingProvider', name: '外送分類', fieldValues: shippingProviderFilter, columns: 2 },
  ]
}

/**
 * @param {('deliveryType' | 'orderStatus' | 'deliveryStatus' | 'deliveryProvider')} field
 * @returns {{field: ('deliveryType' | 'orderStatus' | 'deliveryStatus' | 'deliveryProvider'), name: string, columns: number, fieldValues: {value: string, name: string} }}
 */
export function getFilterSecion (field) {
  return filterSections().find(section => section.field === field)
}

/**
 * 將特定的 query 轉換成文字顯示
 * @param {string} query
 * @returns {string}
 */
export function getQueryName (query) {
  if (query === 'priceUndetermined') {
    return i18n.t('app.component.filter.priceUndetermined')
  }
  if (query === 'paying') {
    return i18n.t('app.component.filter.paying')
  }
  if (query === 'table_waiting_confirm') {
    return i18n.t('app.component.filter.table_waiting_confirm')
  }
  return query
}

/**
 * 取得使用中的 filter 名稱
 * @param {IFilter} filter
 * @returns {string[]}
 */
export function getActiveFilterNames (filter) {
  const activeFilterNames = []
  _.forEach(filter, (filterValues, field) => {
    const filterSection = filterSections().find(filterSection => filterSection.field === field)
    if (filterValues.length > 0) {
      filterValues.forEach(filterValue => {
        const filterConfig = filterSection.fieldValues.find(filterConfig => _.isEqual(filterValue, filterConfig.value))
        if (filterConfig) {
          activeFilterNames.push(i18n.t(filterConfig.name))
        }
      })
    }
  })

  return activeFilterNames.join(activeFilterNames.length > 1 ? ', ' : '')
}

const queryFields = [
  // { field: 'id', searchFunction: (fieldValue, query) => fieldValue?.includes(query) },
  { field: 'serial', searchFunction: (fieldValue, query) => fieldValue?.includes(query) },
  { field: 'name', searchFunction: (fieldValue, query) => fieldValue?.includes(query) },
  { field: 'phone', searchFunction: (fieldValue, query) => fieldValue?.startsWith(query) },
  { field: 'table', searchFunction: (fieldValue, query) => fieldValue?.includes(query) },
  { field: 'shippingProvider', searchFunction: (fieldValue, query) => fieldValue === query },
  { field: 'paying', searchFunction: (fieldValue, query) => query === 'paying' && fieldValue },
  {
    /**
     * 在 items 中尋找
     * @param {IAppOrder} order
     * @param {string} query
     */
    searchFunction: (order, query) => {
      const items = _.flatMap(order.batches, batch => batch.items)
      return _.some(items, item => {
        if (query === 'priceUndetermined' && item.priceUndetermined && !item.cancelled && order.status !== 'cancelled') {
          // 搜尋時價未設定
          return true
        }
        if (item.tags.includes(query)) {
          // 搜尋 tag
          return true
        }
      })
    },
  },
]

/**
 * format orders for quick link
 * 因為quick link filter orders 只需要知道filter後的orders length，而其他的flag不需要用到，所以只抽起要用到的flag來計算
 * 利用format過後的orders 再filter 會大大加快計算速度2倍或以上
 * @param {IApiOrder} orders[]
 */
export function formatOrdersForQuickLinkCount (orders) {
  const formatBatch = function (batch) {
    const formattedBatch = {
      status: batch.status,
      items: batch.items,
    }
    return formattedBatch
  }

  const formatOrder = function (order) {
    const formattedOrder = {
      serial: order.serial,
      deliveryType: order.deliveryType,
      createdAt: order.createdAt,
      batches: order.batches.map((batch) =>
        formatBatch(batch),
      ),
      isMerged: order.isMerged,
      orderReferId: order.orderReferId,
      valid: order?.valid,
      payments: order.payments,
      status: order.status,
      from: order.from,
      cancelReason: order.cancelReason,
      displayStatusKey: order.displayStatusKey,
      shippingProvider: order.shippingProvider,
      table: order.table,
      takeawayStatus: order.takeawayStatus,
    }
    return formattedOrder
  }
  return orders.map(order => formatOrder(order))
}

/**
 * @param {IAppOrder[]} orders
 * @param {string} query
 * @param {IFilter} filter
 */
export function searchAndFilterOrders (orders, query = '', filter) {
  const { ALI_PAY, WECHAT_PAY, PAY_ME, FPS } = paymentMethods
  const { MY2C2P } = paymentGateways
  const excludedPendingOrCancelledPayments = [ALI_PAY, WECHAT_PAY, PAY_ME, FPS]
  const excludedPendingOrCancelledGateways = [MY2C2P]
  const oneDayAgo = moment().subtract(1, 'days')
  const payFirst = getState().merchant.data?.setting?.payFirst
  return orders.filter(order => {
    const filteringOrder = _.clone(order)
    if (filteringOrder.deliveryType === 'table' && query === '') {
      const createdAt = moment(filteringOrder.createdAt)
      if (!createdAt.isAfter(oneDayAgo)) return false
    }
    filteringOrder.paymentMethods = _.map(order.payments, payment => payment.paymentMethod)
    let passFilter = true

    // 外賣跟batches沒有東西不顯示
    if (filteringOrder.batches.length <= 0 && filteringOrder.deliveryType === 'takeaway' && filteringOrder.status === 'pending') return false
    // 合併單不顯示
    if (filteringOrder.isMerged || filteringOrder.orderReferId || filteringOrder?.valid === false) return false

    // wechatpay,payme,alipay 未付款/已取消的單不顥示
    // payment gateway : 2C2P 未付款/已取消的單不顥示
    if (excludedPendingOrCancelledGateways.includes(_.last(filteringOrder.payments)?.gateway) &&
    (filteringOrder.status === 'pending' || (filteringOrder.status === 'cancelled' && filteringOrder.cancelReason === '2C2P overtime')) && filteringOrder.deliveryType !== 'table' &&
    filteringOrder.from !== 'MERCHANT') return false

    // payment method : wechatpay,payme,alipay 未付款/已取消的單不顥示
    if (excludedPendingOrCancelledPayments.includes(filteringOrder.payment) &&
    (filteringOrder.status === 'pending' || (filteringOrder.status === 'cancelled' && filteringOrder.cancelReason === 'Qfpay overtime')) && filteringOrder.deliveryType !== 'table' &&
    filteringOrder.from !== 'MERCHANT') return false

    // 外送已取消的單不顥示
    if (filteringOrder.deliveryType === 'storeDelivery' && filteringOrder.status === 'cancelled') return false

    // 設定 "先付款", 未付款和來自CA時不顯示
    if (payFirst && filteringOrder.status === 'pending' && filteringOrder.from?.startsWith('CUSTOMER_')) return false

    // 根據 filter 過濾
    _.forEach(filter, (fieldFilters, field) => {
      if (fieldFilters.length > 0) {
        passFilter = passFilter && _.intersection(_.flattenDeep(fieldFilters), _.flattenDeep([filteringOrder[field]])).length > 0
      }
    })

    // 根據 query 過濾
    if (query !== '') {
      passFilter = passFilter && _.some(queryFields, queryField => {
        if (!queryField.field) {
        // 沒指定欄位，給整個 order 來搜尋
          return queryField.searchFunction(filteringOrder, query)
        }
        return queryField.searchFunction(filteringOrder[queryField.field], query)
      })
      if (query === 'paying' && (filteringOrder.status === 'cancelled' || filteringOrder.status === 'paid')) {
        passFilter = false
      }
    }

    if (query === 'table_waiting_confirm') {
      return _.find(filteringOrder.batches, batch =>
        batch.status === 'submitted' && filteringOrder.status !== 'cancelled' && filteringOrder.deliveryType === 'table')
    }

    return passFilter
  })
}

export default {
  filterSections,
  getFilterSecion,
  getActiveFilterNames,
  searchAndFilterOrders,
}
