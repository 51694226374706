import { Text, View } from 'react-native'
import React from 'react'
import Row from '@/components/Row'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import styles from '../styles'

const rowWidth = [270, 100]

export default function Payment (props) {
  const { items } = props
  const { t } = useTranslation()

  return (
    <View>
      <Row style={{ paddingBottom: 10 }}>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>{t('app.page.setting.printer.customizedSetting.payMethod')}</Text>
      </Row>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>Master</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.small]}>$100</Text>
      </Row>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>{t('app.page.setting.printer.customizedSetting.tips')}</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.small]}>$40</Text>
      </Row>
    </View>
  )
}
