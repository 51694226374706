import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import IconButton from '@/components/buttons/IconButton'

/**
 * @typedef LoginBoxProps
 * @property {boolean?} backButton
 * @property {() => void?} onBack
 * @property {JSX.Element?} children
 */

/**
 *
 * @param {LoginBoxProps} props
 * @returns
 */
export default function LoginBox (props) {
  const { backButton, onBack, children } = props
  const isMerchantLogin = useSelector(state => state.auth.isMerchantLogin)
  const { t } = useTranslation()
  let title = t('app.page.login.restaurantLogin')
  if (isMerchantLogin) {
    title = t('app.page.login.employeeLogin')
  }
  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <View style={styles.titleLeft}>
          {backButton &&
            <IconButton iconSource={require('@icons/back.png')} onPress={onBack} />}
        </View>
        <View style={styles.titleRight}>
          <Text style={styles.titleText}>{title}</Text>
        </View>
      </View>

      <View style={styles.content}>
        {children}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 341,
  },
  title: {
    height: 208,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.primary,
    borderTopLeftRadius: 100,
    borderBottomRightRadius: 100,
    zIndex: 1,
    ...shadows.default,
  },
  titleLeft: {
    width: 48,
    marginLeft: 45,
    paddingTop: 45,
  },
  titleRight: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  titleText: {
    fontSize: 40,
    fontWeight: 'bold',
    color: colors.white,
    marginLeft: -24,
    marginBottom: 30,
  },
  content: {
    height: 372,
    alignItems: 'center',
    backgroundColor: colors.light,
    borderBottomLeftRadius: 100,
    marginTop: -100,
    paddingTop: 100,

    ...shadows.default,
  },
})
