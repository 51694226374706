import { abs, all, create } from 'mathjs'
import _ from 'lodash'

import { PrinterType } from '@/constants/printer'

import { getHeightBySize, getTextBmp, separateTextWithLength } from './getTextBmp'
import { textToUnicode } from './convertBinary'
import getImageCommand from '../BmpImage/getImageCommand'

const config = {
  epsilon: 1e-12,
  matrix: 'Matrix',
  number: 'number',
  precision: 64,
  predictable: false,
  randomSeed: null,
}

const math = create(all, config)
// size = [height, width]

export default class ImageMode {
  static Align = {
    LEFT: 'left',
    CENTER: 'center',
    RIGTH: 'right',
  }

  /**
   *
   * @param {number} dotWidth
   * @param {EPrinterType} printerType
   */
  constructor (dotWidth, printerType) {
    this.dotWidth = dotWidth
    this.spaceBetweenText = 2
    this.printerType = printerType
    this.bold = false
    this.command = []
    this.blackMode = false
    this.align = ImageMode.Align.LEFT
    this.underline = false
    this.text = [[]]
  }

  addFixedRow = (text, widths, styleText, align = [], fonts) => {
    const dots = _.map(widths, w => Math.round((w / _.sum(widths) * this.dotWidth)))
    const remain = this.dotWidth - _.sum(dots)
    const row = _.map(text, (t, i) => separateTextWithLength(t, dots[i], fonts[i] ?? styleText))
    const rowLength = _.max(_.map(_.clone(row), r => r.length))
    const height = getHeightBySize(_.isEmpty(fonts) ? styleText : this._getMaxFontIndex(fonts))
    dots[_.findLastIndex(dots)] += remain
    let image = []
    _.each(row, (rowText, index) => {
      const width = dots[index]
      let rowImage = []
      _.each(rowText, t => {
        let textImage = new Array(height).fill([])
        _.each(t, unicode => {
          let textBmp = getTextBmp(unicode, fonts[index] ?? styleText, this.underline, this.bold)
          const size = math.matrix(textBmp).size()
          const diff = height - size[0]
          if (diff) {
            const space = math.zeros(diff, size[1])
            textBmp = math.concat(space, textBmp, 0)
          }
          textImage = math.concat(textImage, textBmp)
        })
        textImage = this.alignImage(textImage, align[index], height, width)
        rowImage = _.isEmpty(rowImage) ? textImage : math.concat(rowImage, textImage, 0)
      })
      const resizedImage = math.resize(rowImage, [height * rowLength, width], 0)
      image = _.isEmpty(image) ? resizedImage : math.concat(image, resizedImage)
    })
    let bmp = math.matrix(image)

    // 黑白反轉列印功能已沒有使用
    if (this.blackMode) {
      const blank = math.ones(4, this.dotWidth)
      bmp = math.concat(math.concat(blank, math.map(image, i => abs(Number(i) - 1)), 0), blank, 0)
    }

    return this._getCommand(bmp._data)
  }

  addBoldMode = (on = true) => {
    this.bold = on
  }

  /**
   * 黑白反轉列印功能
   * @deprecated 沒有使用
   * @param {boolean?} on
   */
  addBWInvertMode = (on = true) => {
    this.blackMode = on
  }

  addUnderlineMode = (on = true) => {
    this.underline = on
  }

  addAlign = (align = ImageMode.Align.LEFT) => {
    this.align = align
  }

  addImage = (image, align) => {
    const inputImage = math.matrix(math.clone(image))
    const height = inputImage.size()[0]
    const outputImage = this.alignImage(inputImage, align, height, this.dotWidth)
    return this._getCommand(outputImage._data)
  }

  addText = (text, style) => {
    const unicode = textToUnicode(text)
    _.each(unicode, u => {
      const lastIndex = _.findLastIndex(this.text)
      const textBmp = getTextBmp(u, style, this.underline, this.bold)
      if (_.isEmpty(this.text[lastIndex])) {
        this.text[lastIndex].push(textBmp)
      } else {
        const totalWidth = math.sum(_.map(this.text[lastIndex], t => {
          const matrix = math.matrix(t)
          return matrix.size()[1]
        }))
        const size = math.size(textBmp)
        if (totalWidth + size[1] > this.dotWidth) {
          this.text.push([textBmp])
        } else {
          this.text[lastIndex].push(textBmp)
        }
      }
    })
  }

  printText = () => {
    if (_.isEmpty(this.text[0])) {
      return []
    }
    let command = []
    _.each(this.text, text => {
      const maxHeight = math.max(_.map(text, t => {
        const matrix = math.matrix(t)
        return matrix.size()[0]
      }))
      let image = []
      _.each(text, t => {
        let matrix = math.matrix(t)
        const size = matrix.size()
        const diff = maxHeight - size[0]
        if (diff) {
          const space = math.zeros(diff, size[1])
          matrix = math.concat(space, matrix, 0)
        }
        image = _.isEmpty(image) ? matrix : math.concat(image, matrix)
      })
      const output = this.alignImage(image, this.align, maxHeight, this.dotWidth)
      command = command.concat(this._getCommand(output._data))
    })
    this.text = [[]]

    return command
  }

  alignImage = (image, align, height, width) => {
    let output = math.matrix(image)
    const length = (width - output.size()[1])
    switch (align) {
      case ImageMode.Align.CENTER: {
        const zeroLength = Math.round(length / 2)
        const left = math.zeros(height, zeroLength)
        const right = math.zeros(height, length - zeroLength)
        output = math.concat(left, output)
        output = math.concat(output, right)
        break
      }
      case ImageMode.Align.RIGTH: {
        const left = math.zeros(height, length)
        output = math.concat(left, output)
        break
      }
      case ImageMode.Align.LEFT:
      default: {
        output = math.resize(output, [height, width], 0)
        break
      }
    }
    return output
  }

  // 找出最大font的index
  _getMaxFontIndex = (fonts) => {
    if (fonts.includes(2) && !fonts.includes(5)) return 2
    return Math.max(...fonts)
  }

  _getCommand = (data) => {
    switch (this.printerType) {
      case PrinterType.GPRINTER:
      case PrinterType.GP_D801:
      case PrinterType.TP808:
      case PrinterType.TSP043:
      case PrinterType.TM_T88VI: {
        return getImageCommand(data, 'GS v 0', false)
      }
      case PrinterType.SP700: {
        return getImageCommand(data, 'ESC K')
      }
      case PrinterType.TM_U220B: {
        return getImageCommand(data, 'ESC *')
      }
      case PrinterType.TSP100III: {
        return getImageCommand(data, 'b')
      }
      case PrinterType.TSP100IV: {
        return getImageCommand(data, 'ESC GS S')
      }
      default:
        return []
    }
  }
}
