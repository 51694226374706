import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Column from '@/components/Column'
import CustomerInfoCard from '@/components/CustomerInfoCard'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import moment from 'moment'

export default function Header (
  {
    state,
    showDatePicker,
    withCustomerInfo = false,
  }) {
  const { id, bookingAt, createdAt } = state
  return (
    <Row style={styles.container}>
      {/* booking id */}
      {!withCustomerInfo &&
        id &&
        (
          <Column style={styles.id}>
            <Text style={styles.idText}>
              預約單號
            </Text>
            <Text style={styles.idNumberText}>
              {id.slice(-3)}
            </Text>
          </Column>
        )}

      <Column>
        {/* Date input */}
        <TouchableOpacity
          onPress={showDatePicker}
        >
          <Text style={styles.titleText}>
            {moment(bookingAt).format('YYYY/MM/DD dddd')}
          </Text>
        </TouchableOpacity>

        {/* Info display */}
        <Text style={styles.text}>
          成立預約單時間：{moment(createdAt).format('HH:mm')}
        </Text>
        <Text style={styles.text}>
          預約單編號：{id}
        </Text>
      </Column>

      {/* ? Days after */}
      {!withCustomerInfo &&
        (
          <View style={styles.daysAfter}>
            <Text style={styles.daysAfterText}>
              {moment(bookingAt).calendar()}
            </Text>
          </View>
        )}

      {/* Customer Info card */}
      {withCustomerInfo &&
        <CustomerInfoCard
          customer={state}
          style={styles.card}
        />}
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 12,
  },
  id: {
    width: 82,
    height: 82,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.lightPrimary,
    borderRadius: 5,
    marginRight: 16,
  },
  idText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.white,
  },
  idNumberText: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.white,
  },
  titleText: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 30,
    color: colors.textSecondary,
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 22,
    color: colors.primary,
    opacity: 0.3,
  },
  daysAfter: {
    height: 26,
    minWidth: 51,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tertiary,
    borderRadius: 18,
    paddingHorizontal: 4,
    marginLeft: 12,
  },
  daysAfterText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.white,
  },
  card: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})
