export default {
  INIT: 'MENU/INIT',
  UPDATE_CATEGORY_LOADING: 'MENU/UPDATE_CATEGORY_LOADING',
  UPDATE_MENU_LOADING: 'MENU/UPDATE_MENU_LOADING',
  UPDATE_PICTURE_MODE: 'MENU/UPDATE_PICTURE_MODE',
  UPDATE_BATCH_LIST_EXPAND: 'MENU/UPDATE_BATCH_LIST_EXPAND',
  UPDATE_SEARCH_EXPAND: 'MENU/UPDATE_SEARCH_EXPAND',
  UPDATE_CATEGORIES: 'MENU/UPDATE_CATEGORIES',
  UPDATE_PROMOTE: 'MENU/UPDATE_PROMOTE',
  UPDATE_SOLDOUT: 'MENU/UPDATE_SOLDOUT',
  UPDATE_FILTERED_CATEGORY: 'MENU/UPDATE_FILTERED_CATEGORY',
  SELECT_CATEGORY: 'MENU/SELECT_CATEGORY',
  SELECT_SET: 'MENU/SELECT_SET',
  SELECT_SET_STEP: 'MENU/SELECT_SET_STEP',
  UPDATE_SEARCH_TEXT: 'MENU/UPDATE_SEARCH_TEXT',
  UPDATE_OPTION_GROUP_PRESETS: 'MENU/UPDATE_OPTION_GROUP_PRESETS',
  CREATE_OPTION_GROUP_PRESET: 'MENU/CREATE_OPTION_GROUP_PRESET',
  UPDATE_OPTION_GROUP_PRESET: 'MENU/UPDATE_OPTION_GROUP_PRESET',
  DELETE_OPTION_GROUP_PRESET: 'MENU/DELETE_OPTION_GROUP_PRESET',
  CREATE_CATEGORY: 'MENU/CREATE_CATEGORY',
  DELETE_CATEGORY: 'MENU/DELETE_CATEGORY',
  UPDATE_CATEGORY: 'MENU/UPDATE_CATEGORY',
  CREATE_MENU: 'MENU/CREATE_MENU',
  DELETE_MENU: 'MENU/DELETE_MENU',
  UPDATE_MENU: 'MENU/UPDATE_MENU',
  CREATE_SET: 'MENU/CREATE_SET',
  DELETE_SET: 'MENU/DELETE_SET',
  UPDATE_SET: 'MENU/UPDATE_SET',
  CREATE_CATEGORY_TAG: 'MENU/CREATE_CATEGORY_TAG',
  UPDATE_CATEGORY_TAG: 'MENU/UPDATE_CATEGORY_TAG',
  DELETE_CATEGORY_TAG: 'MENU/DELETE_CATEGORY_TAG',
  RESET_SELECTIONS: 'MENU/RESET_SELECTIONS',
  UPDATE_CUSTOM_ITEMS: 'MENU/UPDATE_CUSTOM_ITEMS',
  UPDATE_KITCHEN_DEPARTMENTS: 'MENU/UPDATE_KITCHEN_DEPARTMENTS',
  UPDATE_REWARD_ITEMS: 'MENU/UPDATE_REWARD_ITEMS',
  RESTORE: 'MENU/RESTORE',
  UPDATE_CATEGORIES_ORDER: 'MENU/UPDATE_CATEGORIES_ORDER',
  UPDATE_MENUS_ORDER: 'MENU/UPDATE_MENUS_ORDER',
  UPDATE_SETS_ORDER: 'MENU/UPDATE_SETS_ORDER',
  UPDATE_FILTER_BY_TIME: 'MENU/UPDATE_FILTER_BY_TIME',
}
