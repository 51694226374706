import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import EmployeeOption from './EmployeeOption'
import React from 'react'

// const employees = [
//   { position: '店長', name: 'M', account: '1' },
//   { position: '副店長', name: 'D', account: '2' },
//   { position: '經理', name: 'A', account: '3' },
//   { position: '組長', name: 'L', account: '4' },
//   { position: '店員', name: 'J', account: '5' },
//   { position: '店員', name: 'P', account: '6' },
//   { position: '店員', name: 'Vi', account: '7' },
//   { position: '店員', name: 'Vk', account: '8' },
//   { position: '店員', name: 'Mi', account: '9' },
//   { position: '店員', name: 'Z', account: '10' },
//   { position: '店員', name: 'Q', account: '11' },
//   { position: '店員', name: 'F', account: '12' },
// ]

export default function EmployeeBoard ({ onSelect, scope, type }) {
  const { t } = useTranslation()
  const scopeCredentials = useSelector(state => state.auth.merchantLogin?.scopeCredentials)
  const positions = [t('app.component.loginDialog.supervisor'), t('app.component.loginDialog.manager'),
    t('app.component.loginDialog.normal'), t('app.component.loginDialog.supervisor'),
    t('app.component.loginDialog.staff'), t('app.component.loginDialog.staff'), t('app.component.loginDialog.staff'),
    t('app.component.loginDialog.staff'), t('app.component.loginDialog.staff'), t('app.component.loginDialog.staff'),
    t('app.component.loginDialog.staff'), t('app.component.loginDialog.staff')]
  let employees = []
  if (type === 'permission') {
    employees = scopeCredentials?.filter((scopeCredential) =>
      (scopeCredential.scopes || [])?.find(_scope => _scope === scope))
      .map((user, index) => {
        const identifier = user.identifier.toLowerCase()
        const name = (identifier.charAt(0).toUpperCase() + identifier.slice(1)).slice(0, 2)
        return {
          account: user.identifier,
          avatar: user.avatar,
          name: name,
          position: positions[index],
        }
      })
  } else {
    employees = scopeCredentials?.map((user, index) => {
      const identifier = user.identifier.toLowerCase()
      const name = (identifier.charAt(0).toUpperCase() + identifier.slice(1)).slice(0, 2)
      return {
        account: user.identifier,
        avatar: user.avatar,
        name: name,
        position: positions[index],
      }
    })
  }
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.content}>
        {employees?.map((employee, index) =>
          <EmployeeOption
            key={`employee-option-${employee.name}-${index}`}
            employee={employee}
            onPress={() => onSelect(employee)}
            style={styles.option}
            blur
          />)}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 370,
    width: 515,
  },
  content: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  option: {
    height: 70,
    width: 150,
    marginRight: 20,
    marginBottom: 20,
  },
})
