import { FlatList, KeyboardAvoidingView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { countPlaces } from '@/libs/countPlaces'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import NumberPad from '@/pages/Checkout/Payment/NumberPad'

/**
 *
 * @param {*} props
 * @returns
 */
export default function PayInOutPanel (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [amount, setAmount] = React.useState('0')
  const [disable, setDisable] = React.useState(false)
  const [type, setType] = React.useState('in')
  const [reason, setReason] = React.useState('')
  const [text, setText] = React.useState('')
  const reasonOptions = [
    { text: t('app.page.setting.payInOut.food'), value: 'food' },
    { text: t('app.page.setting.payInOut.kitchenware'), value: 'kitchenware' },
    { text: t('app.page.setting.payInOut.tableware'), value: 'tableware' },
    { text: t('app.page.setting.payInOut.cleaningSupplies'), value: 'cleaningSupplies' },
    { text: t('app.page.setting.payInOut.stationery'), value: 'stationery' },
    { text: t('app.common.other'), value: 'other' },
  ]

  const handleKeyboardInput = (value) => {
    if (value === 'C') {
      setAmount('0')
      setDisable(false)
      return
    }
    if (disable) return
    if (value === '.' && amount.includes('.')) {
      // 已有小數點，不再處理小數點
      return
    }
    // 根據原本是不是 0 來判斷要蓋掉還是接上去
    if (amount === '0' && value !== '.') {
      setAmount(String(value))
    } else {
      setAmount(amount + String(value))
    }
  }

  const reset = () => {
    setDisable(false)
    setAmount('0')
    setType('in')
    setReason('')
    setText('')
  }

  const submit = () => {
    if (Number(amount) === 0) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.checkout.amountError')))
      return
    }
    const inputReason = reason === 'other' ? text : reason
    dispatch(actions.merchant.addCashRequest({
      amount: Number(amount),
      reason: inputReason,
      type: type,
    }))
    reset()
  }

  const renderItem = ({ item, index }) => {
    const selected = reason === item.value
    return (
      <>
        <TouchableOpacity
          style={[styles.button, { marginVertical: 4 }, selected && { backgroundColor: colors.primary }]}
          onPress={() => {
            if (selected) {
              setReason('')
              setText('')
            } else {
              if (item.value !== 'other') {
                setText('')
              }
              setReason(item.value)
            }
          }}
        >
          <Text style={[styles.buttonText, selected && { color: colors.white }]}>{item.text}</Text>
        </TouchableOpacity>
        {
          item.value === 'other' && (
            <TextInput
              style={styles.input}
              placeholder={t('app.page.setting.checkout.reason')}
              placeholderTextColor='#B0B0B0'
              value={text}
              onChangeText={(text) => {
                if (reason !== 'other') {
                  setReason('other')
                }
                setText(text)
              }}
              multiline
            />
          )
        }
      </>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={[styles.button, type === 'in' && { backgroundColor: colors.primary }]}
            onPress={() => setType('in')}
          >
            <Text style={[styles.buttonText, type === 'in' && { color: colors.white }]}>{t('app.page.setting.checkout.payIn')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.button, type === 'out' && { backgroundColor: colors.primary }]}
            onPress={() => setType('out')}
          >
            <Text style={[styles.buttonText, type === 'out' && { color: colors.white }]}>{t('app.page.setting.checkout.payOut')}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.buttonContainer}>
          <Text style={styles.text}>{t('app.page.setting.checkout.reason')}</Text>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.amountContiner}>
          <View style={styles.textContiner}>
            <Text style={styles.text}>{t('app.page.setting.checkout.amount')}</Text>
            <Text style={[styles.text, { fontSize: 45 }]}>${amount}</Text>
          </View>
          <NumberPad
            handleKeyboardInput={handleKeyboardInput}
            handleAmountInput={(value) => {
              setAmount(String(Number(amount) + value))
              setDisable(true)
            }}
            color={colors.white}
            disabled={countPlaces(amount) >= 1 || disable}
          />
        </View>
        <KeyboardAvoidingView behavior='position' keyboardVerticalOffset={-100} style={styles.amountContiner}>
          <FlatList
            data={reasonOptions}
            renderItem={renderItem}
            keyExtractor={(item, index) => String(index)}
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
          />
        </KeyboardAvoidingView>
      </View>
      <View style={styles.headerContainer}>
        <View style={styles.buttonContainer} />
        <View style={styles.buttonContainer}>
          <Button
            title={t('app.common.confirm')}
            style={styles.confirmButton}
            textStyle={{ fontSize: 20, fontWeight: '500' }}
            onPress={() => submit()}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 25,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  confirmButton: {
    height: 50,
    width: 200,
  },
  amountContiner: {
    flex: 1,
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContiner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 20,
  },
  contentContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    fontSize: 36,
    fontWeight: '500',
    color: colors.primary,
  },
  button: {
    height: 50,
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: colors.primary,
    borderRadius: 8,
    marginHorizontal: 5,
    marginVertical: 8,
  },
  buttonText: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    color: colors.primary,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'center',
  },
  textContainer: {
    backgroundColor: colors.white,
    width: 500,
    borderRadius: 12,
    padding: 15,
    height: 200,
    margin: 50,
  },
  input: {
    fontSize: 20,
    width: 300,
    height: 145,
    borderWidth: 2,
    borderColor: colors.primary,
    borderRadius: 8,
    marginHorizontal: 5,
    marginVertical: 4,
    padding: 10,
  },
})
