import { FlatList, KeyboardAvoidingView, ScrollView, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Constants from 'expo-constants'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'

import { PrinterType } from '@/constants/printer'
import CheckBox from '@/components/CheckBox'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'

import LogoSetting from './LogoSetting'

const deviceId = Constants.installationId

export default function DefaultPrintingPanel () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const printerSetting = useSelector(state => state.printer.printerSetting)
  const isMaster = useSelector(state => state.app.settings.isMaster)
  const { printers, prioritizedPrinters } = printerSetting
  const [priorities, setPriorities] = React.useState(_.get(prioritizedPrinters, deviceId, []))
  const merchantSetting = useSelector(state => state.merchant.data?.setting) ?? {}
  const { quickMode, showRoundingInReceipt, autoPrintReceipt, footerCustomText } = merchantSetting
  const filteredPrinters = printers?.filter(p => p.printerType !== PrinterType.TDP225)
  const [text, setText] = React.useState(footerCustomText)
  const [showLogoSetting, setShowLogoSetting] = React.useState(false)
  const receiptGroupByOptions = [
    { key: 'all', text: t('app.page.setting.printer.receiptGroupByAll') },
    { key: 'batch', text: t('app.page.setting.printer.receiptGroupByBatch') },
    { key: 'item', text: t('app.page.setting.printer.receiptGroupByItem') },
  ]
  const textSizeOptions = [
    { key: 0, text: t('app.page.setting.printer.size0') },
    { key: 1, text: t('app.page.setting.printer.size1') },
    { key: 2, text: t('app.page.setting.printer.size2') },
    { key: 5, text: t('app.page.setting.printer.size5') },
    { key: 3, text: t('app.page.setting.printer.size3') },
    { key: 4, text: t('app.page.setting.printer.size4') },
  ]
  const reportTextSizeOptions = [
    { key: 0, text: t('app.page.setting.printer.size0') },
    { key: 1, text: t('app.page.setting.printer.size1') },
  ]
  const orderSerialDigitOptions = [
    { key: 3, text: t('app.page.setting.printer.digit3') },
    { key: 4, text: t('app.page.setting.printer.digit4') },
  ]
  const cutPaperOptions = [{ key: 'item', text: t('app.page.setting.printer.item') }, { key: 'category', text: t('app.page.setting.printer.category') }, { key: 'none', text: t('app.page.setting.printer.none') }]
  const {
    syncReceipt,
    syncTakeawayReceipt,
    syncReceiptPrioritizedPrinter,
    printCancelledItem,
    cutPaperMode,
    batchMenuStyleText,
    printSetmenuBundled,
    splitItem,
    printWithPrice,
    customerReceiptStyleText,
    customFooterStyleText = 1,
    summaryReportTextSize,
    categoryReportTextSize,
    cancelOrdersReportTextSize,
    printCancelReceipt = true,
    printHoldItem = true,
    orderSerialDigit = 3,
    receiptGroupBy = 'all',
  } = useSelector(state => state.printer.printerSetting.defaultSettings)

  const { autoConfirm } = useSelector(state => state.merchant.data?.setting) ?? {}
  const { selectPrinterBeforePrint } = useSelector(state => state.app.settings)

  if (showLogoSetting) {
    return (
      <LogoSetting
        logo={printerSetting?.logo}
        onClose={() => setShowLogoSetting(false)}
        onSave={async (logo) => {
          await dispatch(actions.printer.updatePrinterSetting({ path: 'logo', value: logo }))
          dispatch(actions.printer.getMerchantLogo(logo.threshold))
        }}
      />
    )
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.container}>
        {/** Left */}
        <KeyboardAvoidingView style={styles.contentContainer} behavior='position' keyboardVerticalOffset={-200}>
          <Header>
            <HeaderText text={t('app.page.setting.printer.receipt')} />
          </Header>
          <SettingList>
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.syncReceiptPrioritizedPrinter')}
              value={syncReceiptPrioritizedPrinter}
              onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.syncReceiptPrioritizedPrinter', value: !syncReceiptPrioritizedPrinter }))}
            />
            {
              !quickMode && (
                <SettingListSwitchRow
                  divider
                  label={t('app.page.setting.printer.syncReceipt')}
                  value={syncReceipt}
                  onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.syncReceipt', value: !syncReceipt }))}
                />
              )
            }
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.syncTakeawayReceipt')}
              value={syncTakeawayReceipt}
              onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.syncTakeawayReceipt', value: !syncTakeawayReceipt }))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.localPanel.setting.other.auto-receipt')}
              value={autoPrintReceipt}
              onChangeValue={() => dispatch(actions.merchant.updateAutoPrintReceipt(!autoPrintReceipt))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.localPanel.setting.other.printCancelReceipt')}
              value={printCancelReceipt}
              onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.printCancelReceipt', value: !printCancelReceipt }))}
            />
            <SettingListEnterRow
              label={t('app.page.setting.printer.customerReceiptStyleText')}
              value={textSizeOptions.find(o => o.key === customerReceiptStyleText)?.text || customerReceiptStyleText}
              onPress={() =>
                dispatch(actions.setting.showSettingOptions({
                  text: t('app.page.setting.printer.customerReceiptStyleText'),
                  valuePath: ['printer', 'printerSetting', 'defaultSettings', 'customerReceiptStyleText'],
                  onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.customerReceiptStyleText', value }),
                  closeOnChange: true,
                  options: textSizeOptions.filter(option => option.key !== 5),
                }))}
            />
          </SettingList>

          <Header>
            <HeaderText text={t('app.page.setting.printer.kitchenBatch')} />
          </Header>
          <SettingList>
            <SettingListEnterRow
              divider
              label={t('app.page.setting.printer.cutPaperMode')}
              value={cutPaperOptions.find(o => o.key === cutPaperMode)?.text || cutPaperMode}
              onPress={() =>
                dispatch(actions.setting.showSettingOptions({
                  text: t('app.page.setting.printer.cutPaperMode'),
                  valuePath: ['printer', 'printerSetting', 'defaultSettings', 'cutPaperMode'],
                  onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.cutPaperMode', value }),
                  closeOnChange: true,
                  options: cutPaperOptions,
                }))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.printSetmenuBundled')}
              value={printSetmenuBundled}
              onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.printSetmenuBundled', value: !printSetmenuBundled }))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.splitItem')}
              value={splitItem}
              onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.splitItem', value: !splitItem }))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.printHoldItem')}
              value={printHoldItem}
              onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.printHoldItem', value: !printHoldItem }))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.printCancelledItem')}
              value={printCancelledItem}
              onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.printCancelledItem', value: !printCancelledItem }))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.printWithPrice')}
              value={printWithPrice}
              onChangeValue={() => dispatch(actions.printer.updatePrinterSetting({ path: 'defaultSettings.printWithPrice', value: !printWithPrice }))}
            />
            <SettingListEnterRow
              label={t('app.page.setting.printer.font')}
              value={textSizeOptions.find(o => o.key === batchMenuStyleText)?.text || batchMenuStyleText}
              onPress={() =>
                dispatch(actions.setting.showSettingOptions({
                  text: t('app.page.setting.printer.font'),
                  valuePath: ['printer', 'printerSetting', 'defaultSettings', 'batchMenuStyleText'],
                  onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.batchMenuStyleText', value }),
                  closeOnChange: true,
                  options: textSizeOptions,
                }))}
            />
          </SettingList>
        </KeyboardAvoidingView>

        {/** Right */}
        <View style={styles.contentContainer}>
          <Header>
            <HeaderText text={t('app.page.setting.printer.general')} />
          </Header>
          <SettingList>
            {
              isMaster && (
                <SettingListSwitchRow
                  divider
                  label={t('app.page.setting.printer.autoConfirm')}
                  value={autoConfirm}
                  onChangeValue={() => dispatch(actions.merchant.updatePrinterAutoConfirm(!autoConfirm))}
                />
              )
            }
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.selectPrinterBeforePrint')}
              value={selectPrinterBeforePrint}
              onChangeValue={() => dispatch(actions.app.updateSetting(['selectPrinterBeforePrint'], !selectPrinterBeforePrint))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.printStaff')}
              value={merchantSetting.enablePrintStaff}
              onChangeValue={() => dispatch(actions.merchant.updateEnablePrintStaff(!merchantSetting.enablePrintStaff))}
            />
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.showRoundingInReceipt')}
              value={showRoundingInReceipt}
              onChangeValue={() => dispatch(actions.merchant.updateShowRoundingInReceipt(!showRoundingInReceipt))}
            />
            <SettingListEnterRow
              divider
              label={t('app.page.setting.printer.customFooterStyleText')}
              value={textSizeOptions.find(o => o.key === customFooterStyleText)?.text || customFooterStyleText}
              onPress={() =>
                dispatch(actions.setting.showSettingOptions({
                  text: t('app.page.setting.printer.customFooterStyleText'),
                  valuePath: ['printer', 'printerSetting', 'defaultSettings', 'customFooterStyleText'],
                  onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.customFooterStyleText', value }),
                  closeOnChange: true,
                  options: textSizeOptions,
                }))}
            />
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.localPanel.setting.other.customFooterText')} style={{ width: '50%' }} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={replaceSpace(text)}
                  onChangeText={(text) => setText(text)}
                  onBlur={() => dispatch(actions.merchant.updateFooterCustomText(replaceSaveSpace(text)))}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            {/* <SettingListEnterRow
            label='Logo'
            value=''
            onPress={() => setShowLogoSetting(true)}
          /> */}
            <SettingListEnterRow
              divider
              label={t('app.page.setting.printer.orderSerialDigit')}
              value={orderSerialDigitOptions.find(o => o.key === orderSerialDigit)?.text || orderSerialDigit}
              onPress={() =>
                dispatch(actions.setting.showSettingOptions({
                  text: t('app.page.setting.printer.orderSerialDigit'),
                  valuePath: ['printer', 'printerSetting', 'defaultSettings', 'orderSerialDigit'],
                  onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.orderSerialDigit', value }),
                  closeOnChange: true,
                  options: orderSerialDigitOptions,
                }))}
            />
            <SettingListEnterRow
              label='Logo'
              value=''
              onPress={() => setShowLogoSetting(true)}
            />
          </SettingList>

          <Header>
            <HeaderText text={t('app.page.setting.printer.report')} />
          </Header>
          <SettingList>
            <SettingListEnterRow
              divider
              label={t('app.page.setting.printer.summaryReportTextSize')}
              value={reportTextSizeOptions.find(o => o.key === summaryReportTextSize)?.text || summaryReportTextSize}
              onPress={() =>
                dispatch(actions.setting.showSettingOptions({
                  text: t('app.page.setting.printer.summaryReportTextSize'),
                  valuePath: ['printer', 'printerSetting', 'defaultSettings', 'summaryReportTextSize'],
                  onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.summaryReportTextSize', value }),
                  closeOnChange: true,
                  options: reportTextSizeOptions,
                }))}
            />
            <SettingListEnterRow
              divider
              label={t('app.page.setting.printer.categoryReportTextSize')}
              value={reportTextSizeOptions.find(o => o.key === categoryReportTextSize)?.text || categoryReportTextSize}
              onPress={() =>
                dispatch(actions.setting.showSettingOptions({
                  text: t('app.page.setting.printer.categoryReportTextSize'),
                  valuePath: ['printer', 'printerSetting', 'defaultSettings', 'categoryReportTextSize'],
                  onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.categoryReportTextSize', value }),
                  closeOnChange: true,
                  options: reportTextSizeOptions,
                }))}
            />
            <SettingListEnterRow
              label={t('app.page.setting.printer.cancelOrdersReportTextSize')}
              value={reportTextSizeOptions.find(o => o.key === cancelOrdersReportTextSize)?.text || cancelOrdersReportTextSize}
              onPress={() =>
                dispatch(actions.setting.showSettingOptions({
                  text: t('app.page.setting.printer.cancelOrdersReportTextSize'),
                  valuePath: ['printer', 'printerSetting', 'defaultSettings', 'cancelOrdersReportTextSize'],
                  onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.cancelOrdersReportTextSize', value }),
                  closeOnChange: true,
                  options: reportTextSizeOptions,
                }))}
            />
          </SettingList>

          <Header>
            <HeaderText text={t('app.page.setting.printer.prioritizedPrinters')} />
          </Header>
          <SettingList>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={filteredPrinters}
              ListEmptyComponent={(
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.printer.noDevice')}</Text>
                </View>
              )}
              keyExtractor={(item) => item.id}
              renderItem={({ item: printer, index }) => {
                const priotiryIndex = priorities.findIndex(ip => ip === printer.id)

                return (
                  <SettingListItem
                    key={printer.id}
                    onPress={() => {

                    }}
                    divider={index !== filteredPrinters.length - 1}
                  >
                    <SettingListItemText text={printer.name || printer.id} />
                    <SettingListItemRightAction style={styles.labels}>
                      <CheckBox
                        value={priotiryIndex > -1}
                        onChangeValue={() => {
                          if (priotiryIndex > -1) {
                            setPriorities([])
                            dispatch(actions.printer.setPrioritizedPrinter([]))
                          } else {
                            setPriorities([printer.id])
                            dispatch(actions.printer.setPrioritizedPrinter([printer.id]))
                          }
                        }}
                      />
                    </SettingListItemRightAction>
                  </SettingListItem>
                )
              }}
            />
          </SettingList>
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  emptyView: {
    minHeight: 40,
    padding: 12,
    paddingLeft: 25,
  },
})
