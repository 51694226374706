import { Dimensions, StyleSheet } from 'react-native'
import React from 'react'

import { useSelectedOrder } from '@/hooks/orderHistory'
import { useSelector } from '@/redux'

import ActionButton from '@/pages/OrderHistory/OrderDetail/ActionButton'

import CenterModal from '@/components/CenterModal'
import Column from '@/components/Column'

const WINDOW_HEIGHT = Dimensions.get('window').height

/**
 *
 * @param {{
 * open:boolean
 * onClose: () => void
 * onPress: () => void
 * }} props
 * @returns
 */
export default function ActionButtonDialog (props) {
  const { open, onClose, onPress } = props
  const selectedOrder = useSelectedOrder()
  const selectedOrderItems = useSelector(state => state.orderHistory.selectedOrderItems)

  return (
    <CenterModal
      visible={open}
      onClose={onClose}
      noTitle
      noBackdrop
      noCloseButton
      enablePressOutsideClose
      contentContainerStyle={{ padding: 0 }}
      containerStyle={styles.centerModalStyle}
    >
      <Column style={styles.container}>
        <ActionButton
          text='app.constant.orderAction.itemChangePrice'
          buttonStyle={styles.button}
          onPress={() => onPress('MERCHANT')}
        />
        {
          selectedOrderItems.every(item => item.excludedOrderSurcharge)
            ? (
              <ActionButton
                text='app.constant.orderAction.surcharge'
                buttonStyle={styles.button}
                onPress={() => onPress('SURCHARGE', false)}
                disabled={selectedOrder.deliveryType !== 'table'}
              />
            )
            : (
              <ActionButton
                text='app.constant.orderAction.excludedSurcharge'
                buttonStyle={styles.button}
                onPress={() => onPress('SURCHARGE', true)}
                disabled={selectedOrder.deliveryType !== 'table'}
              />
            )
        }
        <ActionButton
          text='app.constant.orderAction.discount'
          buttonStyle={{ margin: 0 }}
          onPress={() => onPress('DISCOUNT')}
        />
      </Column>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 75,
    height: 185,
  },
  centerModalStyle: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: (WINDOW_HEIGHT >= 800) ? 29 : 0,
    bottom: 80,
  },
  button: {
    marginBottom: 10,
    marginTop: 0,
    marginLeft: 0,
  },
})
