import React from 'react'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import PrintingSettingList from '../PrintingSettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * }} props
 */
export default function FooterSetting (props) {
  const { setting, onChange } = props
  const { t } = useTranslation()
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const [selecting, setSelecting] = React.useState(false)

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  return (
    <>
      <PrintingSettingList
        divider
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.tableNo')}
        path='content.tableNo'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      >
        <SettingListItem>
          <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.printTable')} />
          <SettingListItemRightAction>
            <ToggleSwitch
              value={_.get(setting, 'content.tableNo.printTable', true)}
              onChangeValue={() => onChange('content.tableNo.printTable', !_.get(setting, 'content.tableNo.printTable', true))}
              size={23}
            />
          </SettingListItemRightAction>
        </SettingListItem>
      </PrintingSettingList>
      <PrintingSettingList
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.status')}
        path='content.status'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      />
    </>
  )
}
