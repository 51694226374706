import _ from 'lodash'

import { actions } from '@/redux'
import AboutPanel from '@/pages/Setting/GeneralSetting/AboutPanel'
import AuthorizationPanel from '@/pages/Setting/GeneralSetting/AuthorizationPanel'
import CancelReasonPanel from '@/pages/Setting/MenuSetting/CancelReasonPanel'
import CategoryTagPanel from '@/pages/Setting/OrderSetting/CategoryTagPanel'
import CheckoutSetting from '@/pages/Setting/CheckoutSetting'
import ComboRulePanel from '@/pages/Setting/OrderSetting/ComboRulePanel'
import CreditCardPanel from '@/pages/Setting/CheckoutSetting/CreditCardPanel'
import DefaultPrintingPanel from '@/pages/Setting/PrintingSetting/DefaultPrintingPanel'
import DepartmentPanel from '@/pages/Setting/MenuSetting/DepartmentPanel'
import DiscountPanel from '@/pages/Setting/CheckoutSetting/DiscountPanel'
import EditAbout from '@/pages/Setting/GeneralSetting/AboutPanel/EditAbout'
import EditMenu from '@root/src/pages/Setting/MenuSetting/MenuPanel/EditMenu'
import EditOpening from '@/pages/Setting/GeneralSetting/AboutPanel/EditOpening'
import EditSet from '@root/src/pages/Setting/MenuSetting/SetMenuPanel/EditSet'
import EditSetMenu from '@root/src/pages/Setting/MenuSetting/SetMenuPanel/EditSet/EditSetMenu'
import ExtraPaymentPanel from '@/pages/Setting/CheckoutSetting/ExtraPaymentPanel'
import GeneralSetting from '@/pages/Setting/GeneralSetting'
import LocalPanel from '@/pages/Setting/GeneralSetting/LocalPanel'
import MenuPanel from '@root/src/pages/Setting/MenuSetting/MenuPanel'
import MenuSetting from '@/pages/Setting/MenuSetting'
import OptionPanel from '@root/src/pages/Setting/MenuSetting/OptionPanel'
import OrderSetting from '@/pages/Setting/OrderSetting'
import PayInOutPanel from '@/pages/Setting/CheckoutSetting/PayInOutPanel'
import PaymentPanel from '@/pages/Setting/CheckoutSetting/PaymentPanel'
import PrintSettingPanel from '@/pages/Setting/PrintingSetting/PrintSettingPanel'
import PrinterPanel from '@/pages/Setting/PrintingSetting/PrinterPanel'
import PrinterPriorityPanel from '@/pages/Setting/PrintingSetting/PrinterPriorityPanel'
import PrintingSetting from '@/pages/Setting/PrintingSetting'
import SetMenuPanel from '@root/src/pages/Setting/MenuSetting/SetMenuPanel'
import ShippingPanel from '@/pages/Setting/CheckoutSetting/ShippingPanel'
import ShortcutPanel from '@/pages/Setting/OrderSetting/ShortcutPanel'
import SpecialOptionPanel from '@root/src/pages/Setting/MenuSetting/SpecialOptionPanel'
import TableGroupPanel from '@/pages/Setting/CheckoutSetting/TableGroupPanel'
import TableSetting from '@/pages/Setting/TableSetting'

/**
 * setting下的sub routes
 */
export const routes = [
  {
    key: 'general',
    path: '/setting/general',
    exact: false,
    text: '基本設定',
    iconSource: require('@icons/setting/nav/general-setting.png'),
    component: GeneralSetting,
    routes: [{
      key: 'local',
      path: '/setting/general/local',
      exact: true,
      text: '本機設定',
      component: LocalPanel,
    },
    // {
    //   key: 'local-version',
    //   path: '/setting/general/local/version',
    //   exact: true,
    //   text: '版本資訊',
    //   component: VersionDetail,
    // },
    {
      key: 'about',
      path: '/setting/general/about',
      text: '餐廳資訊',
      exact: true,
      component: AboutPanel,
    },
    {
      key: 'about-detail',
      path: '/setting/general/about/detail',
      exact: true,
      component: EditAbout,
    },
    {
      key: 'about-opening',
      path: '/setting/general/about/opening',
      exact: true,
      component: EditOpening,
    },
    {
      key: 'authorization',
      path: '/setting/general/authorization',
      text: '權限設定',
      exact: true,
      component: AuthorizationPanel,
    },
    // {
    //   key: 'update',
    //   path: '/setting/general/update',
    //   text: '同步狀態',
    //   exact: true,
    //   component: UpdatePanel,
    // }, // TODO: 暫時隱藏
    // {
    //   key: 'notification',
    //   path: '/setting/general/notification',
    //   text: '通知設定',
    //   exact: true,
    //   component: NotificationPanel,
    // }, // TODO: 暫時隱藏
    ],
  },
  {
    key: 'menu',
    path: '/setting/menu',
    text: '菜單設定',
    iconSource: require('@icons/setting/nav/restr-setting.png'),
    component: MenuSetting,
    routes: [
      {
        key: 'order-option',
        path: '/setting/menu/order-option',
        text: '選項設定',
        exact: true,
        component: OptionPanel,
      },
      {
        key: 'menu',
        path: '/setting/menu/menu',
        exact: true,
        text: '單點設定',
        component: MenuPanel,
      },
      {
        key: 'create-menu',
        path: '/setting/menu/create-menu/:categoryId',
        text: '',
        component: EditMenu,
      },
      {
        key: 'menu-detail',
        path: '/setting/menu/menu-detail/:menuId',
        text: '',
        component: EditMenu,
      },
      {
        key: 'set',
        path: '/setting/menu/set',
        exact: true,
        text: '套餐設定',
        component: SetMenuPanel,
      },
      {
        key: 'create-set',
        path: '/setting/menu/create-set/:categoryId',
        text: '',
        component: EditSet,
      },
      {
        key: 'set-detail',
        path: '/setting/menu/set-detail/:setId',
        text: '',
        component: EditSet,
      },
      {
        key: 'set-menu',
        path: '/setting/menu/set-menu/:setId/:stepId',
        text: '',
        component: EditSetMenu,
      },
      {
        key: 'department',
        path: '/setting/menu/department',
        text: '部門設定',
        exact: true,
        component: DepartmentPanel,
      }],
  },
  {
    key: 'order',
    path: '/setting/order',
    text: '落單設定',
    iconSource: require('@icons/setting/nav/order-setting.png'),
    component: OrderSetting,
    routes: [
      // {
      //   key: 'order-general',
      //   path: '/setting/order/order-general',
      //   text: '點單設定',
      //   exact: true,
      //   component: OrderGeneralPanel,
      // }, // TODO: 暫時隱藏
      {
        key: 'orderPage',
        path: '/setting/order/shortcut',
        exact: true,
        text: '落單版面設定',
        component: ShortcutPanel,
      },
      {
        key: 'category-tag',
        path: '/setting/order/categoryTag',
        text: '分類標籤',
        component: CategoryTagPanel,
      },
      {
        key: 'order-special-option',
        path: '/setting/order/special-order-option',
        text: '特別選項設定',
        exact: true,
        component: SpecialOptionPanel,
      },
      {
        key: 'order-cancel-option',
        path: '/setting/order/order-cancel-option',
        text: '自定取消原因',
        exact: true,
        component: CancelReasonPanel,
      },
      {
        key: 'combo-rule',
        path: '/setting/order/combo-rule',
        text: '撞餐規則',
        exact: true,
        component: ComboRulePanel,
      },
    ],
  },
  {
    key: 'printing',
    path: '/setting/printing',
    text: '列印設定',
    iconSource: require('@icons/setting/nav/printing-setting.png'),
    component: PrintingSetting,
    routes: [{
      key: 'printing-general',
      path: '/setting/printing/printing-general',
      text: '預設',
      exact: true,
      component: DefaultPrintingPanel,
    },
    {
      key: 'printer',
      path: '/setting/printing/printer',
      exact: true,
      text: '裝置列表',
      component: PrinterPanel,
    },
    {
      key: 'priority',
      path: '/setting/printing/priority',
      exact: true,
      text: '後備打印機',
      component: PrinterPriorityPanel,
    },
    // {
    //   key: 'customer',
    //   path: '/setting/printing/customer',
    //   text: '確認單',
    //   exact: true,
    //   component: PrintSettingPanel,
    // },
    {
      key: 'kitchen',
      path: '/setting/printing/kitchen',
      text: '廚房單',
      exact: true,
      component: PrintSettingPanel,
    },
    {
      key: 'receipt',
      path: '/setting/printing/receipt',
      text: '收據',
      exact: true,
      component: PrintSettingPanel,
    },
    {
      key: 'label',
      path: '/setting/printing/label',
      text: '標籤',
      exact: true,
      component: PrintSettingPanel,
    },
    ],
  },
  // {
  //   key: 'restr',
  //   path: '/setting/restr',
  //   text: '餐廳設定',
  //   iconSource: require('@icons/setting/nav/restr-setting.png'),
  //   component: RestrSetting,
  //   routes: [{
  //     key: 'table',
  //     path: '/setting/restr/table',
  //     exact: true,
  //     text: '堂食設定',
  //     component: TablePanel,
  //   }, {
  //     key: 'takeaway',
  //     path: '/setting/restr/takeaway',
  //     text: '外賣設定',
  //     exact: true,
  //     component: TakeawayPanel,
  //   },
  //   {
  //     key: 'delivery',
  //     path: '/setting/restr/delivery',
  //     text: '外送設定',
  //     exact: true,
  //     component: DeliveryPanel,
  //   },
  //   ],
  // },// TODO: 暫時隱藏
  {
    key: 'table',
    path: '/setting/table',
    text: '座位設定',
    iconSource: require('@icons/setting/nav/table-setting.png'),
    component: TableSetting,
  },
  {
    key: 'checkout',
    path: '/setting/checkout',
    text: '結帳設定',
    iconSource: require('@icons/setting/nav/checkout-setting.png'),
    component: CheckoutSetting,
    routes: [{
      key: 'payment',
      path: '/setting/checkout/payment',
      exact: true,
      text: '付款設定',
      component: PaymentPanel,
    },
    {
      key: 'creditCardSetting',
      path: '/setting/checkout/creditCardSetting',
      exact: true,
      text: '終端機設定',
      component: CreditCardPanel,
    },
    // {
    //   key: 'extraPayment',
    //   path: '/setting/checkout/extraPayment',
    //   exact: true,
    //   text: '代用金額',
    //   component: ExtraPaymentPanel,
    // },
    {
      key: 'shipping',
      path: '/setting/checkout/shipping',
      exact: true,
      text: '運送設定',
      component: ShippingPanel,
    },
    {
      key: 'modifier',
      path: '/setting/checkout/modifier',
      exact: true,
      text: '自定折扣',
      component: DiscountPanel,
    },
    {
      key: 'tableGroup',
      path: '/setting/checkout/tableGroup',
      exact: true,
      text: '檯號群',
      component: TableGroupPanel,
    },
    {
      key: 'payInOut',
      path: '/setting/checkout/payInOut',
      exact: true,
      text: '收入支出',
      component: PayInOutPanel,
    },
    // {
    //   key: 'discount',
    //   path: '/setting/checkout/discount',
    //   text: '折扣設定',
    //   exact: true,
    //   component: DiscountPanel,
    // }, // TODO: 暫時隱藏
    ],
  },
  // {
  //   key: 'member',
  //   path: '/setting/member',
  //   text: '會員設定',
  //   iconSource: require('@icons/setting/nav/member-setting.png'),
  //   component: () => <Text style={{ flex: 1 }}>會員設定</Text>,
  // }, // TODO: 暫時隱藏
]

/**
 * 回傳route
 * @param {string} key
 * @returns {object}
 */
export const getRouteByKey = (key) => {
  const flatten = _.flatMap(routes, (r) => r.routes ? [r, ...r.routes] : r)
  return flatten.find(route => route.key === key)
}

/**
 * 星期顯示文字//
 */
export const days = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  0: '日',
}

/**
 * 設定顯示的variant enum
 */
export const variants = {
  DISPLAY: 'display',
  SELECT: 'select',
  SLIDER: 'slider',
  SWITCH: 'switch',
  OPTION: 'option',
  TEXTINPUT: 'textInput',
  BUTTON: 'button',
  CUSTOM: 'custom',
  UNDEFINED: 'undefined',
}

/**
 * 聲音options
 */
const sounds = [
  { key: 'default', displayText: '鈴聲(預設)' },
  { key: 'sound1', displayText: '上升' },
  { key: 'sound2', displayText: '山坡' },
  { key: 'sound3', displayText: '公告' },
  { key: 'sound4', displayText: '水晶' },
  { key: 'sound5', displayText: '波浪' },
  { key: 'sound6', displayText: '和弦' }]

/**
 * 文字大小options
 */
const textSizes = [{ key: 1, displayText: '中' }, { key: 2, displayText: '大' }]

/**
 * 設定顯示configs
 */
const settingConfigs = {
  // 基本設定-本機設定
  訂閱方案: {
    displayText: '訂閱方案',
    variant: variants.DISPLAY,
  },
  系統啟用日: {
    displayText: '系統啟用日',
    variant: variants.DISPLAY,
  },
  系統合約到期日: {
    displayText: '系統合約到期日',
    variant: variants.DISPLAY,
  },
  使用總期間: {
    displayText: '使用總期間',
    variant: variants.DISPLAY,
  },
  目前版本: {
    displayText: '目前版本',
    variant: variants.DISPLAY,
  },
  版本更新: {
    displayText: '版本更新',
    variant: variants.DISPLAY,
  },
  整合服務申請: {
    displayText: '整合服務申請',
    variant: variants.DISPLAY,
  },
  網路加值服務: {
    displayText: '網路加值服務',
    variant: variants.DISPLAY,
  },
  經銷服務: {
    displayText: '經銷服務',
    variant: variants.DISPLAY,
  },
  採購服務: {
    displayText: '採購服務',
    variant: variants.DISPLAY,
  },
  授權日期: {
    displayText: '授權日期',
    variant: variants.DISPLAY,
  },
  法律資訊與規範: {
    displayText: '法律資訊與規範',
    variant: variants.DISPLAY,
  },
  外掛模組: {
    displayText: '外掛模組',
    variant: variants.DISPLAY,
  },
  提示音設定: {
    displayText: '提示音設定',
    variant: variants.SELECT,
    options: sounds,
  },
  結帳音設定: {
    displayText: '結帳音設定',
    variant: variants.SELECT,
    options: sounds,
  },
  出單提示音設定: {
    displayText: '出單提示音設定',
    variant: variants.SELECT,
    options: sounds,
  },
  音量: {
    displayText: '音量',
    variant: variants.SLIDER,
    sliderIcons: {
      min: require('@icons/setting/volume-min.png'),
      max: require('@icons/setting/volume-max.png'),
    },
    minimumValue: 0,
    maximumValue: 100,
    step: 5,
  },
  停留待機時間: {
    displayText: '停留待機時間',
    variant: variants.SELECT,
    options: [{ key: 5, displayText: '5 分鐘' }, { key: 10, displayText: '10 分鐘' }, { key: 15, displayText: '15 分鐘' }, { key: 30, displayText: '30 分鐘' }],
  },
  結帳待機: {
    displayText: '結帳待機',
    variant: variants.SWITCH,
  },
  充電模式: {
    displayText: '充電模式',
    variant: variants.SWITCH,
  },
  低電量模式: {
    displayText: '低電量模式',
    variant: variants.SWITCH,
  },
  勿擾模式: {
    displayText: '勿擾模式',
    variant: variants.SWITCH,
  },
  clientLocales:
  {
    displayText: '用戶介面語言',
    variant: variants.SELECT,
    multipleSelection: true,
    options: [{ key: 'zh', displayText: '繁體中文' },
      { key: 'en', displayText: 'English' }],
    onChangeAction: actions.setting.updateClientLocales,
  },
  defaultClientLanguage:
  {
    displayText: '用戶介面預設語言',
    variant: variants.SELECT,
    options: [{ key: 'zh', displayText: '繁體中文' },
      { key: 'en', displayText: 'English' }],
    onChangeAction: actions.setting.updateClientDefaultLanguage,
  },
  batchLocale:
  {
    displayText: '廚房單語言',
    variant: variants.SELECT,
    options: [
      { key: 'zh', displayText: '繁體中文' },
      { key: 'en', displayText: 'English' },
      { key: 'thai', displayText: '泰文' },
      { key: 'korean', displayText: '韓文' },
      { key: 'kitchen', displayText: '廚房語言' },
    ],
    onChangeAction: actions.setting.updateBatchPrintLanguage,
  },
  roundingType:
  {
    displayText: '金額進位規則',
    variant: variants.SELECT,
    options: [
      { key: '', displayText: '保留' },
      { key: 'off', displayText: '四捨五入法' },
      { key: 'up', displayText: '上捨入法' },
      { key: 'down', displayText: '下捨入法' },
    ],
    onChangeAction: actions.setting.updateRounding,
  },
  roundingDigists:
  {
    displayText: '金額小數位',
    variant: variants.TEXTINPUT,
    suffix: '位',
    onChangeAction: (value) => actions.setting.updateRoundingDigits(Number(value)),
  },
  surcharge:
  {
    displayText: '預設服務費',
    variant: variants.SELECT,
    options: [
      { key: 0, displayText: '無' },
      { key: 5, displayText: '5%' },
      { key: 10, displayText: '10%' },
      { key: 20, displayText: '20%' },
      { key: 30, displayText: '30%' },
    ],
    onChangeAction: actions.setting.updateSurcharge,
  },

  qrcode:
  {
    displayText: '動態QR',
    variant: variants.SWITCH,
    onChangeAction: actions.merchant.updateDynamicQRCodeEnable,
  },
  qrDisableCustomerCount:
  {
    displayText: '動態QR不需輸入人數',
    variant: variants.SWITCH,
    onChangeAction: actions.merchant.updateDynamicQRCodeDisableCustomerCount,
  },
  qrCustomText:
  {
    displayText: '動態QR自訂文字',
    variant: variants.TEXTINPUT,
    onChangeAction: actions.merchant.updateDynamicQRCodeCustomText,
  },

  prepaid: {
    displayText: '先結帳',
    variant: variants.SWITCH,
  },
  autoPrintReceipt:
  {
    displayText: '結帳後自動打印收據',
    variant: variants.SWITCH,
    onChangeAction: actions.merchant.autoPrintReceipt,
  },
  litePrinting:
  {
    displayText: '精簡模式',
    variant: variants.SWITCH,
    onChangeAction: actions.merchant.updateLitePrinting,
  },
  waiter:
  {
    displayText: '叫侍應',
    variant: variants.SWITCH,
    onChangeAction: actions.merchant.updateWaiterEnable,
  },
  code:
  {
    displayText: '啟用餐單編號',
    variant: variants.SWITCH,
    onChangeAction: actions.merchant.updateEnableCode,
  },
  takeawayPickupAfterMins:
  {
    displayText: '外賣最快取餐時間',
    variant: variants.TEXTINPUT,
    suffix: '分鐘',
    onChangeAction: (value) => actions.merchant.updateTakeawayPickupAfterMinutes(Number(value)),
  },
  recommendCustomText:
  {
    displayText: '自訂推薦文字',
    variant: variants.TEXTINPUT,
    onChangeAction: actions.merchant.updateRecommendCustomText,
  },
  footerCustomText:
  {
    displayText: '頁腳自訂文字',
    variant: variants.TEXTINPUT,
    onChangeAction: actions.merchant.updateFooterCustomText,
  },

  // 基本設定-餐廳資訊
  name: {
    displayText: '餐廳名稱',
    variant: variants.TEXTINPUT,
  },
  座位數目: {
    displayText: 'app.page.setting.aboutPanel.seatNo',
    variant: variants.TEXTINPUT,
  },
  包廂數目: {
    displayText: 'app.page.setting.aboutPanel.roomNo',
    variant: variants.TEXTINPUT,
  },
  菜色數目: {
    displayText: 'app.page.setting.aboutPanel.itemNo',
    variant: variants.TEXTINPUT,
  },
  payFirst: {
    displayText: '先結帳',
    variant: variants.SWITCH,
  },
  預約: {
    displayText: '預約',
    variant: variants.SWITCH,
  },
  價位: {
    displayText: '價位',
    variant: variants.TEXTINPUT,
  },
  contact: {
    displayText: '電話',
    variant: variants.TEXTINPUT,
  },
  address: {
    displayText: '地址',
    variant: variants.TEXTINPUT,
    multiline: true,
  },
  desc: {
    displayText: '餐廳簡介',
    variant: variants.TEXTINPUT,
    multiline: true,
  },
  開放時間: {
    displayText: '開放時間',
    variant: variants.DISPLAY,
  },
  // 餐廳資訊-營業時間
  opening: {
    day: {
      displayText: '星期',
      variant: variants.DISPLAY,
    },
    start: {
      displayText: '開始時間',
      variant: variants.TEXTINPUT,
      keyboardType: 'number-pad',
      placeholder: '請輸入0~23數字',
      maxLength: 2,
      suffix: '點',
    },
    startMin: {
      displayText: '開始時間',
      variant: variants.TEXTINPUT,
      keyboardType: 'number-pad',
      placeholder: '請輸入0~59數字',
      maxLength: 2,
      suffix: '分',
    },
    end: {
      displayText: '結束時間',
      variant: variants.TEXTINPUT,
      keyboardType: 'number-pad',
      placeholder: '請輸入0~23數字',
      maxLength: 2,
      suffix: '點',
    },
    endMin: {
      displayText: '結束時間',
      variant: variants.TEXTINPUT,
      keyboardType: 'number-pad',
      placeholder: '請輸入0~59數字',
      maxLength: 2,
      suffix: '分',
    },
    orderBeforeMin: {
      displayText: '截單時間',
      variant: variants.TEXTINPUT,
      keyboardType: 'number-pad',
      placeholder: '請輸入數字',
      maxLength: 3,
      suffix: '分鐘',
    },
    takeaway: {
      displayText: '外賣',
      variant: variants.SWITCH,
    },
    storeDelivery: {
      displayText: '餐廳外送',
      variant: variants.SWITCH,
    },
    surcharge: {
      displayText: '服務費',
      variant: variants.TEXTINPUT,
      keyboardType: 'number-pad',
      maxLength: 3,
      suffix: '％',
    },
  },
  // 基本設定-同步狀態
  設定檔同步: {
    displayText: '設定檔同步',
    variant: variants.DISPLAY,
  },
  餐單同步: {
    displayText: '餐單同步',
    variant: variants.DISPLAY,
  },
  座位同步: {
    displayText: '座位同步',
    variant: variants.DISPLAY,
  },
  列印機設定同步IOT: {
    displayText: '列印機設定同步IOT',
    variant: variants.DISPLAY,
  },
  基本資料同步: {
    displayText: '基本資料同步',
    variant: variants.DISPLAY,
  },
  優惠規則同步: {
    displayText: '優惠規則同步',
    variant: variants.DISPLAY,
  },
  // 基本設定-通知設定
  送單通知: {
    displayText: '送單通知',
    variant: variants.SWITCH,
  },
  進單通知: {
    displayText: '進單通知',
    variant: variants.SWITCH,
  },
  結帳通知: {
    displayText: '結帳通知',
    variant: variants.SWITCH,
  },
  廚師關閉通知: {
    displayText: '廚師關閉通知',
    variant: variants.SWITCH,
  },
  區域通知: {
    displayText: '區域通知',
    variant: variants.SWITCH,
  },
  員工上下班通知: {
    displayText: '員工上/下班通知',
    variant: variants.SWITCH,
  },
  金流通知: {
    displayText: '金流通知',
    variant: variants.SWITCH,
  },
  服務員通知: {
    displayText: '服務員通知',
    variant: variants.SWITCH,
  },
  // 基本設定-權限設定
  group: {
    id: {
      displayText: '名稱',
      variant: variants.DISPLAY,
    },
    name: {
      displayText: '名稱',
      variant: variants.TEXTINPUT,
    },
    主POS: {
      displayText: '主POS',
      variant: variants.SWITCH,
    },
    外掛POS: {
      displayText: '外掛POS',
      variant: variants.SWITCH,
    },
    行動點餐: {
      displayText: '行動點餐',
      variant: variants.SWITCH,
    },
    主餐單維護: {
      displayText: '主餐單維護',
      variant: variants.SWITCH,
    },
    臨時加菜: {
      displayText: '臨時加菜',
      variant: variants.SWITCH,
    },
    座位表維護: {
      displayText: '座位表維護',
      variant: variants.SWITCH,
    },
    臨時加桌: {
      displayText: '臨時加桌',
      variant: variants.SWITCH,
    },
    列表機維護: {
      displayText: '列表機維護',
      variant: variants.SWITCH,
    },
    付款方式維護: {
      displayText: '付款方式維護',
      variant: variants.SWITCH,
    },
    '裝置維護/配對': {
      displayText: '裝置維護/配對',
      variant: variants.SWITCH,
    },
    折扣維護: {
      displayText: '折扣維護',
      variant: variants.SWITCH,
    },
    單品折扣: {
      displayText: '單品折扣',
      variant: variants.SWITCH,
    },
    全單折扣: {
      displayText: '全單折扣',
      variant: variants.SWITCH,
    },
    自定義折扣: {
      displayText: '自定義折扣',
      variant: variants.SWITCH,
    },
    免服務費: {
      displayText: '免服務費',
      variant: variants.SWITCH,
    },
    招待: {
      displayText: '招待',
      variant: variants.SWITCH,
    },
    點餐: {
      displayText: '點餐',
      variant: variants.SWITCH,
    },
    '點餐-特殊菜品': {
      displayText: '點餐-特殊菜品',
      variant: variants.SWITCH,
    },
    訂單作廢: {
      displayText: '訂單作廢',
      variant: variants.SWITCH,
    },
    折扣作廢: {
      displayText: '折扣作廢',
      variant: variants.SWITCH,
    },
    修改小費: {
      displayText: '修改小費',
      variant: variants.SWITCH,
    },
    結帳: {
      displayText: '結帳',
      variant: variants.SWITCH,
    },
    信用卡支付: {
      displayText: '信用卡支付',
      variant: variants.SWITCH,
    },
    繼續營業: {
      displayText: '繼續營業',
      variant: variants.SWITCH,
    },
    '關帳/小結': {
      displayText: '關帳/小結',
      variant: variants.SWITCH,
    },
    營業日報表: {
      displayText: '營業日報表',
      variant: variants.SWITCH,
    },
    user: {
      displayText: '權限設定',
      variant: variants.SWITCH,
    },
    'waiter-login': {
      displayText: '侍應登入',
      variant: variants.SWITCH,
    },
    'slave-login': {
      displayText: '副機登入',
      variant: variants.SWITCH,
    },
    'item-transfer': {
      displayText: '項目轉枱',
      variant: variants.SWITCH,
    },
    'order-edit': {
      displayText: '柯打修改',
      variant: variants.SWITCH,
    },
    'order-discount': {
      displayText: '柯打折扣',
      variant: variants.SWITCH,
    },
    'order-cancel': {
      displayText: '取消柯打',
      variant: variants.SWITCH,
    },
    'order-void': {
      displayText: '柯打 Void',
      variant: variants.SWITCH,
    },
    'change-after-paid': {
      displayText: '埋單後修改',
      variant: variants.SWITCH,
    },
    repay: {
      displayText: '再付款',
      variant: variants.SWITCH,
    },
    stats: {
      displayText: '統計',
      variant: variants.SWITCH,
    },
    menu: {
      displayText: '餐單',
      variant: variants.SWITCH,
    },
    'printer-setting': {
      displayText: '打印機設定',
      variant: variants.SWITCH,
    },
    'takeaway-disable': {
      displayText: '關閉外賣',
      variant: variants.SWITCH,
    },
    'drawer-kick': {
      displayText: '打開錢箱',
      variant: variants.SWITCH,
    },
    'merchant-staff-order': {
      displayText: '點餐',
      variant: variants.SWITCH,
    },
    'draw-cash': {
      displayText: '支出功能',
      variant: variants.SWITCH,
    },
  },
  // 基本設定-帳號設定
  account: {
    id: {
      displayText: '編號',
      variant: variants.DISPLAY,
    },
    group: {
      displayText: '權限群組',
      variant: variants.DISPLAY,
    },
    name: {
      displayText: '姓名',
      variant: variants.TEXTINPUT,
    },
    phone: {
      displayText: '電話號碼',
      variant: variants.TEXTINPUT,
    },
    active: {
      displayText: '在職狀況',
      variant: variants.SWITCH,
    },
    password: {
      displayText: '六位數密碼',
      variant: variants.TEXTINPUT,
      placeholder: '000000',
      secureTextEntry: true,
      maxLength: 6,
      keyboardType: 'number-pad',
    },
    confirmPassword: {
      displayText: '再次確認密碼',
      variant: variants.TEXTINPUT,
      placeholder: '000000',
      secureTextEntry: true,
      maxLength: 6,
      keyboardType: 'number-pad',
    },
  },
  // 餐廳設定-內用
  用餐時間: {
    displayText: '用餐時間',
    variant: variants.SELECT,
    options: [{ key: 0, displayText: '不限制' }, { key: 60, displayText: '60分鐘' }, { key: 100, displayText: '100分鐘' }, { key: 120, displayText: '120分鐘' }],
  },
  最後加點時間: {
    displayText: '最後加點時間',
    variant: variants.SELECT,
    options: [{ key: 21, displayText: '21:00' }, { key: 22, displayText: '22:00' }, { key: 23, displayText: '23:00' }],
  },
  內用預設服務費: {
    displayText: '預設服務費',
    variant: variants.SELECT,
    options: [{ key: 'product', displayText: '依商品而定' }, { key: 'flat', displayText: '統一服務費' }, { key: 'none', displayText: '無' }],
  },
  內用預設服務費率: {
    displayText: '統一服務費率',
    variant: variants.TEXTINPUT,
    suffix: '%',
    keyboardType: 'numeric',
    maxLength: 3,
    textAlign: 'center',
  },
  預設點單模式: {
    displayText: '預設點單模式',
    variant: variants.SELECT,
    options: [{ key: 'table', displayText: '座位' }, { key: 'call', displayText: '電話' }],
  },
  允許搭桌: {
    displayText: '允許搭桌',
    variant: variants.SWITCH,
  },
  內用預掛單必須付款: {
    displayText: '預掛單必須付款',
    variant: variants.SWITCH,
  },
  內用下單時必須付款: {
    displayText: '下單時必須付款',
    variant: variants.SWITCH,
  },
  // 餐廳設定-外賣自取
  外賣自取廚房單預掛列印: {
    displayText: '廚房單預掛列印',
    variant: variants.SWITCH,
  },
  外賣自取預掛列印時間: {
    displayText: '預掛列印時間',
    variant: variants.SELECT,
    options: [{ key: 0, displayText: '馬上' }, { key: 1, displayText: '1 分鐘後' }, { key: 2, displayText: '2 分鐘後' }, { key: 3, displayText: '3 分鐘後' }],
  },
  外賣預設服務費率: {
    displayText: '預設服務費',
    variant: variants.TEXTINPUT,
    suffix: '%',
    keyboardType: 'numeric',
    maxLength: 3,
    textAlign: 'center',
  },
  外賣預掛單必須付款: {
    displayText: '預掛單必須付款',
    variant: variants.SWITCH,
  },
  外賣下單時必須付款: {
    displayText: '下單時必須付款',
    variant: variants.SWITCH,
  },
  // 餐廳設定-外送
  外送廚房單預掛列印: {
    displayText: '廚房單預掛列印',
    variant: variants.SWITCH,
  },
  外送預掛列印時間: {
    displayText: '預掛列印時間',
    variant: variants.SELECT,
    options: [{ key: 0, displayText: '馬上' }, { key: 1, displayText: '1 分鐘後' }, { key: 2, displayText: '2 分鐘後' }, { key: 3, displayText: '3 分鐘後' }],
  },
  運費: {
    displayText: '運費',
    variant: variants.TEXTINPUT,
  },
  外送預設服務費率: {
    displayText: '預設服務費',
    variant: variants.TEXTINPUT,
    suffix: '%',
    keyboardType: 'numeric',
    maxLength: 3,
    textAlign: 'center',
  },
  外送預掛單必須付款: {
    displayText: '預掛單必須付款',
    variant: variants.SWITCH,
  },
  外送下單時必須付款: {
    displayText: '下單時必須付款',
    variant: variants.SWITCH,
  },
  // 結帳設定-付款方式
  payment: {
    name: {
      displayText: '付款名稱',
      variant: variants.TEXTINPUT,
      placeholder: '請輸入',
    },
    active: {
      displayText: '啟用',
      variant: variants.SWITCH,
    },
    tips: {
      displayText: '小費',
      variant: variants.SWITCH,
    },
    percent: {
      displayText: '服務費比例',
      variant: variants.TEXTINPUT,
    },
    amount: {
      displayText: '服務費金額',
      variant: variants.TEXTINPUT,
    },
    結帳備註: {
      displayText: '結帳備註',
      variant: variants.SWITCH,
    },
    開啟錢箱: {
      displayText: '開啟錢箱',
      variant: variants.SWITCH,
    },
  },

  // 座位設定-枱號群
  tableGroup: {
    'GROUND FLOOR': { displayText: '一樓' },
    'FIRST FLOOR': { displayText: '二樓' },
    BAR: { displayText: '吧台' },
    123: { displayText: '其他' },
  },

  // 列印設定
  autoConfirm: {
    displayText: '堂食自動確認',
    variant: variants.SWITCH,
    onChangeAction: actions.setting.updatePrinterAutoConfirm,
  },
  selectPrinterBeforePrint: {
    displayText: '每次打印前詢問打印機',
    variant: variants.SWITCH,
    onChangeAction: actions.setting.updatePrinterSelectPrinterBeforePrint,
  },
  printStaff: {
    displayText: '廚房單列印負責員工',
    variant: variants.SWITCH,
    onChangeAction: actions.setting.updatePrintStaff,
  },
  takeawayNotifyPlayCount: {
    displayText: '外賣提示音次數',
    variant: variants.SELECT,
    options: [{ key: -1, displayText: '∞' }, { key: 1, displayText: '一次' }, { key: 2, displayText: '二次' }, { key: 3, displayText: '三次' }],
    onChangeAction: actions.setting.updateTakeawayNotifyAudioPlayCount,
  },
  storeDeliveryNotifyPlayCount: {
    displayText: '外送提示音次數',
    variant: variants.SELECT,
    options: [{ key: -1, displayText: '∞' }, { key: 1, displayText: '一次' }, { key: 2, displayText: '二次' }, { key: 3, displayText: '三次' }],
    onChangeAction: actions.setting.updateStoreDeliveryNotifyAudioPlayCount,
  },
  printerSetting: {
    defaultSettings: {
      syncReceipt: {
        displayText: '確認時打印顧客收據(堂食)',
        variant: variants.SWITCH,
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.syncReceipt', value }),
      },
      syncTakeawayReceipt: {
        displayText: '確認時打印顧客收據(外賣)',
        variant: variants.SWITCH,
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.syncTakeawayReceipt', value }),
      },
      printCancelledItem: {
        displayText: '打印已取消項目',
        variant: variants.SWITCH,
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.printCancelledItem', value }),
      },
      cutPaperMode: {
        displayText: '切紙模式',
        variant: variants.SELECT,
        options: [{ key: 'item', displayText: '項目' }, { key: 'category', displayText: '分類' }],
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.cutPaperMode', value }),
      },
      printSetmenuBundled: {
        displayText: '套餐組合打印',
        variant: variants.SWITCH,
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.printSetmenuBundled', value }),
      },
      splitItem: {
        displayText: '逐項打印',
        variant: variants.SWITCH,
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.splitItem', value }),
      },
      batchMenuStyleText: {
        displayText: '字型',
        variant: variants.SELECT,
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.batchMenuStyleText', value }),
      },
      printWithPrice: {
        displayText: '顯示價錢',
        variant: variants.SWITCH,
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.printWithPrice', value }),
      },
      customerReceiptStyleText: {
        displayText: '客人收據字型',
        variant: variants.SELECT,
        options: textSizes,
        onChangeAction: (value) => actions.printer.updatePrinterSetting({ path: 'defaultSettings.customerReceiptStyleText', value }),
      },
    },
    printer: {
      name: {
        displayText: '裝置名稱',
        variant: variants.TEXTINPUT,
      },
      id: {
        displayText: 'IP位置',
        variant: variants.TEXTINPUT,
        placeholder: '000.000.000.000:0000',
      },

    },
  },

  // 落單設定-點單
  主題樣板: {
    displayText: '主題樣板',
    variant: variants.SELECT,
    options: [{ key: 1, displayText: '華貴喔' }, { key: 2, displayText: '華貴捏' }, { key: 3, displayText: '華貴嗎' }],
  },
  點單模式: {
    displayText: '點單模式',
    variant: variants.SELECT,
    options: [{ key: 1, displayText: '圖片點餐' }, { key: 2, displayText: '文字點餐' }],
  },
  位置方向: {
    displayText: '位置方向',
    variant: variants.SELECT,
    options: [{ key: 1, displayText: '左右' }, { key: 2, displayText: '左左右右' }],
  },
  文字大小: {
    displayText: '文字大小',
    variant: variants.SELECT,
    options: textSizes,
  },
  顯示頁面大小: {
    displayText: '顯示頁面大小',
    variant: variants.SELECT,
    options: [{ key: 1, displayText: '標準' }, { key: 2, displayText: '不標準' }],
  },
  顯示資訊: {
    displayText: '顯示資訊',
    variant: variants.SELECT,
    options: [{ key: 1, displayText: '預設' }, { key: 2, displayText: '不預設' }],
  },
  按鈕選擇大小: {
    displayText: '按鈕選擇大小',
    variant: variants.SELECT,
    options: [{ key: 1, displayText: '標準' }, { key: 2, displayText: '不標準' }],
  },
  搜尋預設模式: {
    displayText: '預設模式',
    variant: variants.SELECT,
    options: [{ key: 1, displayText: '鍵盤搜尋' }, { key: 2, displayText: '地毯式搜尋' }],
  },
  外賣自取模式: {
    displayText: '外賣自取模式',
    variant: variants.SWITCH,
  },
  預設座位圖: {
    displayText: '預設座位圖',
    variant: variants.SWITCH,
  },
  堂食模式: {
    displayText: '堂食模式',
    variant: variants.SWITCH,
  },
  外送模式: {
    displayText: '外送模式',
    variant: variants.SWITCH,
  },
  // 落單設定-快捷列
  數量開關: {
    displayText: '數量開關',
    variant: variants.SWITCH,
  },
  折扣開關: {
    displayText: '折扣開關',
    variant: variants.SWITCH,
  },
  狀態開關: {
    displayText: '狀態開關',
    variant: variants.SWITCH,
  },
  快捷列預設模式: {
    displayText: '預設模式',
    variant: variants.SELECT,
    options: [{ key: 1, displayText: '右' }, { key: 2, displayText: '左' }],
  },
  numPadOrder: {
    displayText: '數字鍵點餐',
    variant: variants.SWITCH,
  },
  numberBarInputMode: {
    displayText: '數字輸入模式',
    variant: variants.SWITCH,
  },
}

/**
 * 返回指定路徑的config，若路徑不存在回傳未知
 * @param {array} path
 * @returns {object}
 */
export const getSettingConfig = (path) => {
  return _.get(settingConfigs, path, { displayText: '未知', variant: variants.UNDEFINED })
}

/**
 * 將一個設定包上config及路徑
 * @param {object} setting
 * @returns {object}
 */
export const mapSettingWithConfigs = (setting, configPath = [], updatePath = []) => {
  const key = _.keys(setting)[0]
  const value = setting[key]
  const configs = getSettingConfig([...configPath, key])

  return {
    key,
    value,
    path: [...updatePath, key],
    ...configs,
  }
}

/**
 * 將所有設定包上config內容及路徑，回傳成array
 * @param {object} settings
 * @param {array} configPath config路徑
 * @param {array} updatePath redux更新路徑
 * @returns {array}
 */
export const mapSettingsToArray = (settings, configPath, updatePath) => {
  return _.values(_.mapValues(settings, (value, key) =>
    mapSettingWithConfigs({ [key]: value }, configPath, updatePath),
  ))
}
