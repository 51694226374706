import { Image, StyleSheet, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import PaymentInfo from './PaymentInfo'
import React from 'react'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'
import _ from 'lodash'
import paymentConfigs from '@/configs/paymentConfigs'

const arrowRightIcon = require('@icons/arrow-right.png')
export default function PaymentPanel () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedPayment, setSelectedPayment] = React.useState(null)
  let paymentsOrder = useSelector(state => state.app.settings.paymentsOrder) || paymentConfigs.defaultPaymentsOrder
  if (_.isEmpty(paymentsOrder)) {
    paymentsOrder = paymentConfigs.defaultPaymentsOrder
  }
  const merchantMethods = useSelector(state => state.merchant.data.payments)
  const paymentMethods = paymentConfigs.flatPaymentMethods.map(buildInMethod => {
    return {
      ...buildInMethod,
      active: false,
      custom: false,
      extra: false,
    }
  })
  merchantMethods.forEach(merchantMethod => {
    const paymentMethod = paymentMethods.find(paymentMethod => paymentMethod.key === merchantMethod.key)
    if (paymentMethod) {
      paymentMethod.active = true
      paymentMethod.serviceCharge = merchantMethod.serviceCharge
      paymentMethod.gateway = merchantMethod.gateway ?? ''
      paymentMethod.merchantId = merchantMethod.merchantId ?? ''
      paymentMethod.terminalId = merchantMethod.terminalId ?? ''
    } else {
      paymentMethods.push({
        ...paymentConfigs.otherMethod,
        key: merchantMethod.key,
        name: merchantMethod.name,
        gateway: merchantMethod.gateway ?? '',
        active: true,
        custom: true,
        tips: merchantMethod.tips ?? false,
        serviceCharge: merchantMethod.serviceCharge,
      })
    }
  })
  const updatePayments = _.map(paymentMethods, p => {
    const payment = paymentsOrder.find(o => o.key === p.key)
    return { ...p, index: _.get(payment, 'index') }
  })
  const payments = _.map(_.sortBy(updatePayments, 'index'), (s, index) => {
    return { ...s, index }
  })

  const renderPayments = ({ item: paymentMethod, index, drag, isActive }) => {
    const isEcrPayment = paymentConfigs.enabledEcrPayments.includes(paymentMethod?.key)
    return (
      <SettingListItem
        draggable
        dragging={isActive}
        onDrag={drag}
        divider
        disabled={!paymentMethod.custom && !isEcrPayment}
        onPress={() => {
          if (paymentMethod.custom || isEcrPayment) {
            setSelectedPayment(_.assign(paymentMethod, { isEcrPayment }))
          }
        }}
      >
        <SettingListItemText text={t(paymentMethod.name)} />
        <SettingListItemRightAction>
          {
            paymentMethod.custom || isEcrPayment ? (
              <Image
                source={arrowRightIcon}
                style={styles.icon}
              />
            ) : (
              <ToggleSwitch
                value={paymentMethod.active}
                onChangeValue={() => {
                  setSelectedPayment(null)
                  dispatch(actions.merchant.activePaymentMethod(paymentMethod, !paymentMethod.active))
                }}
                size={23}
              />
            )
          }
        </SettingListItemRightAction>
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>
      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.checkout.payment')} />
        </Header>
        <View style={styles.content}>
          <SettingList style={{ flex: 1 }}>
            <DraggableFlatList
              showsVerticalScrollIndicator={false}
              data={payments}
              keyExtractor={item => item.key}
              renderItem={renderPayments}
              onDragEnd={({ data }) => {
                const updatedData = _.map(data, (d, index) => {
                  return { ...d, index }
                })
                dispatch(actions.app.updateSetting(['paymentsOrder'], updatedData))
              }}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.checkout.add') + t('app.page.setting.checkout.custom') + t('app.page.setting.checkout.payment')}
              onPress={() => {
                setSelectedPayment({ name: '', extra: false, isCardPayment: false, tips: false, active: true })
              }}
            />
          </SettingList>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <PaymentInfo
          key={selectedPayment?.key + selectedPayment?.name}
          paymentMethod={selectedPayment}
          onClose={() => setSelectedPayment(null)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  icon: {
    width: 16,
    height: 16,
  },

})
