export default function straightLine (width = 288, double = false) {
  if (double) {
    return [
      new Array(width).fill(1),
      new Array(width).fill(1),
      new Array(width).fill(0),
      new Array(width).fill(0),
      new Array(width).fill(0),
      new Array(width).fill(0),
    ]
  } else {
    return [
      new Array(width).fill(1),
      new Array(width).fill(0),
      new Array(width).fill(0),
    ]
  }
}
