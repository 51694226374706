import { StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import orderAppLib from 'dimorder-orderapp-lib'

import { numberWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import Row from '@/components/Row'
import colors from '@/theme/colors'

import IconLabel from '../TableLegend/IconLabel'

/**
 *
 * @param {*} props
 * @returns
 */
export default function InfoBar (props) {
  const { t } = useTranslation()
  const userPermissions = useSelector(state => state.auth.userLogin?.permissions)
  const rounding = useSelector(state => state.merchant.data.rounding)
  const tables = useSelector(state => state.table.tables)
  const historyOrders = useSelector(state => state.orderHistory.orders)

  const tableCapacity = tables.length

  const activeTableCount = React.useMemo(() => {
    return _.filter(tables, table => table.orderIds.length > 0).length
  }, [tables])

  const orderIds = React.useMemo(() => {
    return _.chain(tables).map(table => table.orderIds).flatten().uniq().value()
  }, [tables])

  const orders = React.useMemo(() => {
    return _.filter(historyOrders, order => _.includes(orderIds, order.id))
  }, [historyOrders, orderIds])

  const unpaidOrdersSummary = React.useMemo(() => {
    const unpaidOrders = [] // 總未結帳單
    let unpaidTotalAmount = 0 // 總未結帳金額
    let customerCount = 0 // 總用餐人數

    _.forEach(orders, order => {
      if (order.status === 'pending') {
        unpaidOrders.push(order)
        unpaidTotalAmount += order.roundedTotal
        customerCount += (order.adults + order.children)
      }
    })

    return {
      unpaidOrders,
      unpaidOrdersCount: unpaidOrders.length,
      unpaidTotalAmount: numberWithCommas(orderAppLib.libs.getDisplayRoundNumber(unpaidTotalAmount, rounding)),
      customerCount,
    }
  }, [orders])

  const { unpaidOrdersCount, unpaidTotalAmount, customerCount } = unpaidOrdersSummary

  const hasDisplayUpaidTotalPermission = React.useMemo(() => {
    return _.some(userPermissions, permission => _.includes(['stats', 'admin'], permission))
  }, [userPermissions])

  return (
    <Row style={styles.container}>
      <Row>
        <Text style={styles.text}>
          {t('app.page.table.tableArea.infoBar.unpaid')}：{unpaidOrdersCount}
        </Text>
        {hasDisplayUpaidTotalPermission && (
          <Text style={styles.text}>
            {t('app.page.table.tableArea.infoBar.unpaidTotal')}：＄{unpaidTotalAmount}
          </Text>
        )}
      </Row>
      <Row>
        <IconLabel
          text={`${activeTableCount}/${tableCapacity}`}
          textStyle={{ color: colors.textPrimary }}
          style={{ marginRight: 8 }}
          iconSource={require('@icons/table/table-blue.png')}
        />
        <IconLabel
          text={`${customerCount}`}
          textStyle={{ color: colors.textPrimary }}
          iconSource={require('@icons/table/adult.png')}
        />
      </Row>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.gray,
    marginRight: 20,
  },
})
