import { setHours, setMinutes } from 'date-fns'
import DatePicker from 'react-datepicker'
import React, { useState } from 'react'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

export default function DateTimePicker (props) {
  const { value, mode, onChange, minimumDate, maximumDate, minuteInterval } = props
  const [now, setStartDate] = useState(new Date())
  const minTime = moment().startOf('day').diff(value, 'days') === 0 ? value : setHours(setMinutes(now, 0), 0)

  // 第一次render的時候，若是time模式，選擇下一個的interval
  React.useEffect(() => {
    if (mode === 'time') {
      const remainder = minuteInterval - (moment(value).minute() % minuteInterval)

      const dateTime = moment(value).add(remainder, 'minutes').toDate()

      onChange(null, dateTime)
    }
  }, [])

  const onDateChange = (date) => {
    onChange(null, date)
  }

  return (
    <DatePicker
      selected={value}
      onChange={onDateChange}
      minDate={minimumDate}
      maxDate={maximumDate}
      inline
      minTime={mode === 'datetime' ? setHours(setMinutes(now, 0), 0) : minTime}
      maxTime={moment(value).startOf('day').diff(maximumDate, 'days') === 0 ? maximumDate : setHours(setMinutes(now, 45), 23)}
      showTimeSelect={mode === 'time' || mode === 'datetime'}
      showTimeSelectOnly={mode === 'time'}
      timeIntervals={minuteInterval}
      timeFormat='HH:mm aa'
    />
  )
};
