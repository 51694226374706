import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import orderappLibs from 'dimorder-orderapp-lib/dist/libs'

import { actions, useDispatch, useSelector } from '@/redux'
import { numberWithCommas } from '@/libs/numberWithCommas'
import { useSelectedBatch, useSelectedOrder } from '@/hooks/order'
import colors from '@/theme/colors'
import useMountedRef from '@/hooks/useMountedRef'

import BigIconButton from '@/components/buttons/BigIconButton'
import Column from '@root/src/components/Column'
import ItemNumberInputDialog from '@/components/dialogs/ItemNumberInputDialog'
import ModifierDialog from '@/components/dialogs/ModifierDialog'
import Row from '@/components/Row'

import ActionButton from './ActionButton'
import OrderInfoDialog from '../OrderInfoDialog'

const PRINTER_ICON = require('@icons/print.png')
const CHECKOUT_ICON = require('@icons/checkout.png')

/**
 *
 * @param {*} props
 * @returns
 */
export default function BottomActions (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { rounding, setting = {} } = useSelector(state => state.merchant.data)
  const { payFirst, takeawayOpeningPrompt: takeawayOpeningPromptSetting, quickMode } = setting
  const { selectedItemKey, selectedSetItemKey, batchStashes } = useSelector(state => state.orderBatch)
  const { skipTakeawayModal, discountShortcut } = useSelector(state => state.app.settings)
  const quickCheckoutWithCash = useSelector(state => state.app.settings.quickCheckoutWithCash)
  const menus = useSelector(state => state.menu.menus)
  const discountModifiers = useSelector(state => state.merchant.modifier)

  const selectedOrder = useSelectedOrder()
  const selectedBatch = useSelectedBatch()

  const [pressed, setPressed] = React.useState(false)
  const [openingPromptVisible, setOpeningPromptVisible] = React.useState(false)
  const [openModifierDialog, setOpenModifierDialog] = React.useState(false)
  const [orderInfoDialog, setOrderInfoDialog] = React.useState(false)
  const [onSelect, setOnSelect] = React.useState(() => () => {})
  const [onSubmit, setOnSubmit] = React.useState(() => () => {})
  const merchant = useSelector(state => state.merchant.data)
  const mounted = useMountedRef()

  const filteredMenus = _.filter(_.values(menus) || [], menu => menu?.takeawayOpeningPrompt)
  const batchItemIndex = selectedBatch.items.findIndex(o => o.key === selectedItemKey || o.key === selectedSetItemKey)
  const selectedItem = selectedBatch?.items[batchItemIndex]
  const takeawayOpeningPrompt = takeawayOpeningPromptSetting && selectedOrder.deliveryType === 'takeaway'
  const discountModifier = _.find(discountModifiers, d => d.id === discountShortcut)

  // 計算 order 的數量
  let totalQuantity = _.reduce(selectedOrder?.batches, (sum, batch) => {
    return sum + _.reduce(batch.items, (sum, item) => {
      return sum + item.quantity
    }, 0)
  }, 0)
  // 計算 order 的金額
  let totalAmount = selectedOrder.subtotal || selectedOrder.roundedTotal || selectedOrder.total

  // 加上目前 batch 的數量和金額
  selectedBatch.items.forEach(item => {
    totalQuantity += item.quantity
    totalAmount += orderappLibs.calculateLocalBatchItem(item, selectedOrder).total
  })

  // 根據設定四捨五入
  totalAmount = orderappLibs.getDisplayRoundNumber(totalAmount, rounding)

  const handleDeleteItem = () => {
    const target = selectedSetItemKey || selectedItemKey

    if (target) {
      dispatch(actions.orderBatch.deleteItem(target))
    } else {
      const alertConfig = {
        modalProps: { enablePressOutsideClose: true },
        title: t('app.page.order.bottomAction.alert.deleteAll.title'),
        message: t('app.page.order.bottomAction.alert.deleteAll.msg'),
        buttons: [
          {
            title: t('app.common.deleteAll'),
            type: 'TouchableOpacity',
            textStyle: { fontWeight: 'bold' },
            onPress: () => dispatch(actions.orderBatch.resetItems()),
          },
          {
            title: t('app.common.back'),
            type: 'TouchableOpacity',
            backgroundColor: colors.light,
            textColor: colors.textTertiary,
            textStyle: { fontWeight: 'bold' },
          },
        ],
      }
      dispatch(actions.app.showAlert(alertConfig))
    }
  }

  const StashButton = discountModifier ? ActionButton : BigIconButton
  const PendOrderButton = quickCheckoutWithCash ? ActionButton : BigIconButton

  const isQuickCheckoutWithCashAndTakeaway = quickCheckoutWithCash && selectedOrder.deliveryType === 'takeaway'

  return (
    <>
      <View style={styles.container}>
        <Row style={styles.leftActions}>
          <Column>
            {/* Delete Btn */}
            <ActionButton
              iconSource={require('@icons/order-action/delete.png')}
              backgroundColor={colors.secondary}
              disabled={_.isEmpty(selectedBatch.items) || (batchItemIndex < 0 && (Boolean(selectedSetItemKey) || Boolean(selectedItemKey)))}
              onPress={handleDeleteItem}
            />
            {/* 暫存歷史 Btn */}
            <ActionButton
              onlyText={t('app.page.order.stash.history')}
              disabled={_.isEmpty(batchStashes)}
              onPress={() => {
                dispatch(actions.app.showDialog(['order', 'batchStashes']))
              }}
            />
          </Column>
          <Column>
            {/* 暫存 Btn，沒有自訂折扣Btn 就用ActionButton */}
            <StashButton
              style={discountModifier ? { marginHorizontal: 5 } : {
                marginHorizontal: 8,
                marginVertical: 5,
                width: 96,
              }}
              color={colors.white}
              backgroundColor={colors.primary}
              onlyText={t('app.page.order.stash.title')}
              disabled={_.isEmpty(selectedBatch.items)}
              onPress={() => {
                dispatch(actions.orderBatch.stashBatch())
              }}
            />

            {/* 自訂折扣 Btn */}
            {
              (discountModifier) &&

                <ActionButton
                  onlyText={discountModifier.name}
                  disabled={(!selectedItemKey && !selectedSetItemKey) || batchItemIndex < 0}
                  onPress={() =>
                    dispatch(actions.auth.permissionCheck('order-discount',
                      () => {
                        const modifier = {
                          percent: discountModifier.percent,
                          amount: discountModifier.amount,
                          id: 'DISCOUNT',
                          type: 'DISCOUNT',
                          applyTo: 'PRODUCT',
                          custom: true,
                          customName: discountModifier.name,
                          customId: discountModifier.id,
                        }
                        dispatch(actions.orderBatch.updateModifier(modifier))
                      },
                    ))}
                />
            }

          </Column>

        </Row>

        {/* 分隔線 */}
        <View style={{ height: '100%', borderColor: colors.gray, borderWidth: 0.5 }} />
        <Column style={{ flex: 1, marginHorizontal: 10 }}>
          <Text style={styles.detailText}>{`${t('app.page.order.bottomAction.totalQuantity')}：${totalQuantity}`}</Text>
          <Text style={styles.detailText}>{`${t('app.page.order.bottomAction.totalAmount')}：${numberWithCommas(totalAmount)}`}</Text>
          {/* 折扣 Btn */}
          <ActionButton
            onlyText={t('app.constant.order.discount')}
            disabled={!selectedItemKey || batchItemIndex < 0 || Boolean(selectedSetItemKey)}
            onPress={() =>
              dispatch(actions.auth.permissionCheck('order-discount',
                () => {
                  setOpenModifierDialog(true)
                },
              ))}
          />
        </Column>

        <Row style={styles.actions}>

          <Row>
            <Column>
              {/* 掛單 Btn，有開現金快速結帳改用ActionButton */}
              {selectedOrder.deliveryType === 'takeaway' &&
                <PendOrderButton
                  style={quickCheckoutWithCash ? null : {
                    marginHorizontal: 5,
                    marginVertical: 5,
                    width: 96,
                  }}
                  color={colors.white}
                  backgroundColor={colors.primary}
                  onlyText={t('app.page.order.bottomAction.pendOrder')}
                  disabled={_.isEmpty(selectedBatch.items) || pressed}
                  onPress={async () => {
                    setOnSubmit(() => async () => {
                      setOrderInfoDialog(false)
                      await dispatch(actions.orderBatch.deselectItems())
                      await dispatch(actions.order.submitOrderBatch({ ignoreUnavailable: false, unpaid: true }))
                    })
                    const press = async () => {
                      if (pressed) {
                        return
                      }
                      setPressed(true)
                      if (selectedOrder.deliveryType === 'takeaway') {
                        if (skipTakeawayModal) {
                          await dispatch(actions.orderBatch.deselectItems())
                          await dispatch(actions.order.selectOrder(selectedOrder))
                          await dispatch(actions.order.submitOrderBatch({ ignoreUnavailable: false, unpaid: true }))
                        } else {
                          setOrderInfoDialog(true)
                        }
                      } else {
                        await dispatch(actions.orderBatch.deselectItems())
                        await dispatch(actions.order.submitOrderBatch())
                      }
                      if (mounted.current) setPressed(false)
                    }
                    if (takeawayOpeningPrompt && _.get(filteredMenus, 'length')) {
                      setOnSelect(() => async () => await press())
                      setOpeningPromptVisible(true)
                    } else {
                      await press()
                    }
                  }}
                />}
              {/* 結帳 ActionButton */}
              {isQuickCheckoutWithCashAndTakeaway &&
                <ActionButton
                  iconSource={payFirst ? CHECKOUT_ICON : PRINTER_ICON}
                  backgroundColor={colors.secondary}
                  disabled={_.isEmpty(selectedBatch.items) || pressed}
                  onPress={async () => {
                    setOnSubmit(() => async () => {
                      setOrderInfoDialog(false)
                      await dispatch(actions.orderBatch.deselectItems())
                      await dispatch(actions.order.submitOrderBatch())
                    })
                    const press = async () => {
                      if (pressed) {
                        return
                      }
                      setPressed(true)
                      if (selectedOrder.deliveryType === 'takeaway') {
                        await dispatch(actions.orderBatch.deselectItems())
                        const pickupAt = moment().add(_.get(merchant, 'setting.takeawayPickupAfterMins') || 0, 'm').toDate()
                        await dispatch(actions.order.updateSelectedOrderFields({ pickupAt }))
                        if (skipTakeawayModal) {
                          await dispatch(actions.orderBatch.deselectItems())
                          await dispatch(actions.order.selectOrder(selectedOrder))
                          await dispatch(actions.order.submitOrderBatch())
                        } else {
                          setOrderInfoDialog(true)
                        }
                      } else {
                        await dispatch(actions.orderBatch.deselectItems())
                        await dispatch(actions.order.submitOrderBatch())
                      }
                      // 防止submitOrderBatch / 其他dispatch關閉UI後setPressed有衝突(memory leak)
                      if (mounted.current) setPressed(false)
                    }

                    if (takeawayOpeningPrompt && _.get(filteredMenus, 'length')) {
                      setOnSelect(() => async () => await press())
                      setOpeningPromptVisible(true)
                    } else {
                      await press()
                    }
                  }}
                />}
            </Column>
            {/* 正常模式: 右下紅色儲存並列印 大Btn */}
            {!isQuickCheckoutWithCashAndTakeaway &&
              <BigIconButton
                style={{
                  marginHorizontal: 5,
                  marginVertical: 5,
                  width: quickCheckoutWithCash || selectedOrder.deliveryType === 'takeaway' ? 96 : 200,
                }}
                iconSource={(payFirst || quickMode) ? CHECKOUT_ICON : PRINTER_ICON}
                backgroundColor={colors.secondary}
                disabled={_.isEmpty(selectedBatch.items) || pressed}
                onPress={async () => {
                  setOnSubmit(() => async () => {
                    setOrderInfoDialog(false)
                    await dispatch(actions.orderBatch.deselectItems())
                    await dispatch(actions.order.submitOrderBatch())
                  })
                  const press = async () => {
                    if (pressed) {
                      return
                    }
                    setPressed(true)
                    if (selectedOrder.deliveryType === 'takeaway') {
                      await dispatch(actions.orderBatch.deselectItems())
                      const pickupAt = moment().add(_.get(merchant, 'setting.takeawayPickupAfterMins') || 0, 'm').toDate()
                      await dispatch(actions.order.updateSelectedOrderFields({ pickupAt }))
                      if (skipTakeawayModal) {
                        await dispatch(actions.orderBatch.deselectItems())

                        quickMode ? await dispatch(actions.order.updateSelectedOrderFields(selectedOrder))
                          : await dispatch(actions.order.selectOrder(selectedOrder))

                        await dispatch(actions.order.submitOrderBatch())
                      } else {
                        setOrderInfoDialog(true)
                      }
                    } else {
                      await dispatch(actions.orderBatch.deselectItems())
                      await dispatch(actions.order.submitOrderBatch())
                    }
                    // 防止submitOrderBatch / 其他dispatch關閉UI後setPressed有衝突(memory leak)
                    if (mounted.current) setPressed(false)
                  }

                  if (takeawayOpeningPrompt && _.get(filteredMenus, 'length')) {
                    setOnSelect(() => async () => await press())
                    setOpeningPromptVisible(true)
                  } else {
                    await press()
                  }
                }}
              />}

            {/* 現金快速結帳模式 */}
            {quickCheckoutWithCash &&
              <BigIconButton
                style={{
                  marginHorizontal: 5,
                  marginVertical: 5,
                  width: 96,
                }}
                backgroundColor={colors.secondary}
                onlyText={t('app.routes.setting.paymentMethod.cash')}
                disabled={_.isEmpty(selectedBatch.items) || pressed}
                onPress={async () => {
                  setOnSubmit(() => async () => {
                    setOrderInfoDialog(false)
                    await dispatch(actions.orderBatch.deselectItems())
                    await dispatch(actions.order.submitOrderBatch({ cashQuickCheckout: true }))
                  })
                  const press = async () => {
                    if (pressed) {
                      return
                    }
                    setPressed(true)
                    if (selectedOrder.deliveryType === 'takeaway') {
                      await dispatch(actions.orderBatch.deselectItems())
                      const pickupAt = moment().add(_.get(merchant, 'setting.takeawayPickupAfterMins') || 0, 'm').toDate()
                      await dispatch(actions.order.updateSelectedOrderFields({ pickupAt }))
                      if (skipTakeawayModal) {
                        await dispatch(actions.orderBatch.deselectItems())
                        await dispatch(actions.order.updateSelectedOrderFields(selectedOrder))
                        await dispatch(actions.order.submitOrderBatch({ cashQuickCheckout: true }))
                      } else {
                        setOrderInfoDialog(true)
                      }
                    } else {
                      await dispatch(actions.orderBatch.deselectItems())
                      await dispatch(actions.order.submitOrderBatch({ cashQuickCheckout: true }))
                    }
                    if (mounted.current) setPressed(false)
                  }
                  if (takeawayOpeningPrompt && _.get(filteredMenus, 'length')) {
                    setOnSelect(() => async () => await press())
                    setOpeningPromptVisible(true)
                  } else {
                    await press()
                  }
                  // dispatch(actions.menu.selectCategory(rootSetCategory))
                }}
              />}

          </Row>
        </Row>
      </View>
      <ModifierDialog
        key={String(openModifierDialog)}
        amount={selectedItem?.price}
        isSet={selectedItem?.isSet}
        open={openModifierDialog}
        onSubmit={(modifier) => dispatch(actions.orderBatch.updateModifier(modifier))}
        onClose={() => setOpenModifierDialog(false)}
        showDefault
      />
      <ItemNumberInputDialog
        open={openingPromptVisible}
        onClose={async () => {
          setOpeningPromptVisible(false)
          await onSelect()
        }}
        items={filteredMenus}
        selectAll={false}
        onSubmit={async (quantity) => {
          await dispatch(actions.orderBatch.deselectItems())
          const selectedItems = filteredMenus.filter(o => quantity[o?.id])
          if (selectedItems.length) {
            for (let i = 0; i < selectedItems.length; i++) {
              await dispatch(actions.orderBatch.addItem(selectedItems[i], quantity[selectedItems[i]?.id]))
            }
          }
        }}
      />
      <OrderInfoDialog
        open={orderInfoDialog}
        onClose={() => setOrderInfoDialog(false)}
        onSubmit={onSubmit}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightGray4,
    display: 'flex',
    flexDirection: 'row',
    marginLeft: -100,
    paddingLeft: 100,
    paddingVertical: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    flex: 1,
    marginRight: 15,
    justifyContent: 'flex-end',

  },
  leftActions: {
    flex: 1,
    marginLeft: 15,
    justifyContent: 'flex-start',
  },
  detailText: {
    color: colors.textPrimary,
    fontSize: 16,
    lineHeight: 25,
    marginHorizontal: 8,
    // marginVertical: 5,
  },
})
