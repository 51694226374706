import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { getCategoryName } from '@/libs/menu'
import { loadingKey } from '@/constants'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

const defaultState = {
  name: '',
  desc: '',
  shortName: '',
  weekdays: [],
  timerange: [],
  waiterOnly: false,
  takeawayOnly: false,
  dineinOnly: false,
  code: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function CategoryInfo (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { category, onClose } = props
  const [categoryState, setCategoryState] = React.useState(defaultState)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const { printCategories } = useSelector(state => state.merchant.sorting)
  const categories = Object.keys(useSelector(state => state.menu.categories)).filter(c => c !== 'ROOT' && c !== 'SET_ROOT')
  const [printOrder, setPrintOrder] = React.useState(_.get(printCategories, 'length') ? printCategories : [categories])
  const printOptions = (new Array((_.get(printOrder, 'length') || 1) + 2)).fill(0).map((o, index) => {
    const displayText = index === 0 ? '（最先）' : index === (printOrder.length + 1) ? '（最後）' : ''
    return { value: index, display: index + displayText }
  })
  const departments = useSelector(state => state.menu.departments)
  const kitchenDepartment = departments.find(department => department.id === categoryState.kitchenDepartmentId)

  const departmentOptions = _.concat({ value: '', display: t('app.common.unselected') }, departments.map(department => {
    return { value: department.id, display: department.name }
  }))
  const language = String(i18n.language ?? 'zh').split('-')[0]
  const [lang, setLang] = React.useState(language)
  const langOptions = [
    { value: 'zh', display: t('app.constant.language.zh') },
    { value: 'en', display: t('app.constant.language.en') },
  ]

  const weekdayOptions = [
    { value: 0, display: t('app.constant.weekday.sun') },
    { value: 1, display: t('app.constant.weekday.mon') },
    { value: 2, display: t('app.constant.weekday.tue') },
    { value: 3, display: t('app.constant.weekday.wed') },
    { value: 4, display: t('app.constant.weekday.thur') },
    { value: 5, display: t('app.constant.weekday.fri') },
    { value: 6, display: t('app.constant.weekday.sat') },
  ]
  const isSetCategory = categoryState?.path?.includes('SET_ROOT')
  const parentCategories = useSelector(state => state.menu.categories[isSetCategory ? 'SET_ROOT' : 'ROOT'].categories)
  const parentOptions = _.map(_.filter(parentCategories, p => {
    return _.isEmpty(p.menus) && _.isEmpty(p.sets) && p.id !== category.id && (p.parentId === 'SET_ROOT' || p.parentId === 'ROOT') && _.isEmpty(category.categories)
  }), c => {
    return { value: c.id, display: getCategoryName(c.id) }
  })
  parentOptions.unshift({ value: isSetCategory ? 'SET_ROOT' : 'ROOT', display: t('app.constant.menu.root') })
  const [parentId, setParentId] = React.useState(null)

  React.useEffect(() => {
    if (category) {
      setCategoryState(category)
      setOptionsPanelConfig(null)
      setLang(language)
      setParentId(category?.parentId)
    }
  }, [category])

  const parentCategoryName = getCategoryName(category?.parentId)

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.menuPanel.category.alert.delete.title'),
      message: `${t('app.page.setting.menuPanel.category.alert.delete.msg')}${category.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: async () => {
            onClose()
            await dispatch(actions.app.openLoading(loadingKey.CATEGORY))
            await dispatch(actions.menu.deleteCategory(category, printOrder))
            dispatch(actions.app.closeLoading(loadingKey.CATEGORY))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    if (categoryState.name.trim().length === 0) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.category.error.name')))
      return
    }

    if (categoryState.timerange.length !== 0) {
      if (!_.inRange(Number(categoryState.timerange[0].hour), 24) || !_.inRange(Number(categoryState.timerange[1].hour), 24)) {
        dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.category.error.hour')))
        return
      }
      if (!_.inRange(Number(categoryState.timerange[0].minute), 60) || !_.inRange(Number(categoryState.timerange[1].minute), 60)) {
        dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.category.error.min')))
        return
      }
    }
    onClose()
    if (categoryState?.id) {
      dispatch(actions.menu.updateCategory({ ...categoryState, name: replaceSaveSpace(categoryState.name) }, printOrder, parentId, lang))
    } else {
      dispatch(actions.menu.createCategory({ ...categoryState, name: replaceSaveSpace(categoryState.name) }, printOrder))
    }
  }

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  const onResetTime = () => {
    setCategoryState({
      ...categoryState,
      timerange: [],
    })
  }

  return (
    <>
      <Header dense>
        <HeaderText text={category?.id ? t('app.page.setting.menuPanel.category.info') : t('app.page.setting.menuPanel.category.add')} />
      </Header>
      <ScrollView style={styles.content} showsVerticalScrollIndicator={false}>
        <SettingList>
          {
            (categoryState.name !== t('app.page.setting.menuPanel.menuInfo.promoted') && categoryState.name !== t('app.page.setting.menuPanel.menuInfo.sold') && categoryState?.id)
              ? (
                <>
                  <SettingListEnterRow
                    label={t('app.page.setting.menuPanel.category.parent')}
                    value={getCategoryName(parentId)}
                    onPress={() => {
                      setOptionsPanelConfig({
                        title: t('app.page.setting.menuPanel.category.parent'),
                        defaultValue: parentId,
                        onChangeValue: (value) => setParentId(value),
                        options: parentOptions,
                        closeOnSelect: true,
                      })
                    }}
                  />
                  <SettingListEnterRow
                    label={t('app.page.setting.menuPanel.menuInfo.lang')}
                    value={langOptions.find(o => o.value === lang)?.display}
                    onPress={() => {
                      setOptionsPanelConfig({
                        title: t('app.page.setting.menuPanel.menuInfo.lang'),
                        defaultValue: lang,
                        onChangeValue: (value) => setLang(value),
                        options: langOptions,
                        closeOnSelect: true,
                      })
                    }}
                  />
                </>
              )
              : (
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.menuPanel.category.parent')} />
                  <SettingListItemRightAction>
                    <SettingListItemText text={parentCategoryName} />
                  </SettingListItemRightAction>
                </SettingListItem>
              )
          }
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.name')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={replaceSpace(_.get(categoryState.localeNames, lang, categoryState.name)) || ''}
                disabled={categoryState.name === t('app.page.setting.menuPanel.menuInfo.promoted') || categoryState.name === t('app.page.setting.menuPanel.menuInfo.sold')}
                onChangeText={(text) => {
                  setCategoryState({
                    ...categoryState,
                    name: text,
                    localeNames: {
                      ...categoryState.localeNames,
                      [lang]: text,
                    },
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.desc')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={(_.get(categoryState.localeDesc, lang) ?? categoryState.desc) || ''}
                disabled={categoryState.name === t('app.page.setting.menuPanel.menuInfo.promoted') || categoryState.name === t('app.page.setting.menuPanel.menuInfo.sold')}
                onChangeText={(text) => setCategoryState({
                  ...categoryState,
                  desc: text,
                  localeDesc: {
                    ...categoryState.localeDesc,
                    [lang]: text,
                  },
                })}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.menuPanel.category.code')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={categoryState.code || ''}
                disabled={categoryState.name === t('app.page.setting.menuPanel.menuInfo.promoted') || categoryState.name === t('app.page.setting.menuPanel.menuInfo.sold')}
                onChangeText={(text) => setCategoryState({
                  ...categoryState,
                  code: text,
                })}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          {
            (categoryState.name !== t('app.page.setting.menuPanel.menuInfo.promoted') && categoryState.name !== t('app.page.setting.menuPanel.menuInfo.sold')) && (
              <>
                {
                  !categoryState?.path?.includes('SET_ROOT') && (
                    <SettingListEnterRow
                      label={t('app.page.setting.menuPanel.menuInfo.department')}
                      value={kitchenDepartment?.name ?? t('app.common.unselected')}
                      onPress={() => {
                        setOptionsPanelConfig({
                          title: t('app.page.setting.menuPanel.menuInfo.department'),
                          defaultValue: kitchenDepartment?.id ?? '',
                          onChangeValue: (value) => {
                            setCategoryState({ ...categoryState, kitchenDepartmentId: value })
                          },
                          options: departmentOptions,
                          closeOnSelect: true,
                        })
                      }}
                    />
                  )
                }
                <SettingListEnterRow
                  label={t('app.page.setting.menuPanel.menuInfo.date')}
                  value={categoryState.weekdays.length > 0
                    ? categoryState.weekdays.map(d =>
                      weekdayOptions.find(o =>
                        o.value === d)?.display).join() : t('app.common.null')}
                  onPress={() => {
                    if (categoryState.name !== t('app.page.setting.menuPanel.menuInfo.promoted') && categoryState.name !== t('app.page.setting.menuPanel.menuInfo.sold')) {
                      setOptionsPanelConfig({
                        title: t('app.constant.weekday.week'),
                        multiple: true,
                        defaultValue: categoryState.weekdays,
                        onChangeValue: (value) => {
                          setCategoryState({ ...categoryState, weekdays: value })
                        },
                        options: weekdayOptions,
                      })
                    }
                  }}
                />
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.start')} />
                  <SettingListItemRightAction>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                      <TouchableOpacity
                        onPress={() => dispatch(actions.app.showDateTimePicker('time',
                          moment({
                            hour: categoryState?.timerange[0]?.hour,
                            minute: categoryState?.timerange[0]?.minute,
                          }).toDate(),
                          (value) => {
                            const selectedStart = moment(value)
                            const timerange = [{ ...categoryState.timerange[0] }, { ...categoryState.timerange[1] }]
                            timerange[0].minute = Number(selectedStart.format('mm'))
                            timerange[0].hour = Number(selectedStart.format('HH'))
                            setCategoryState({
                              ...categoryState,
                              timerange: timerange,
                            })
                          },
                          moment({
                            hour: 0,
                            minute: 0,
                          }).toDate(),
                        ))}
                      >
                        <Text>
                          {
                            categoryState?.timerange[0]
                              ? moment({ hour: categoryState?.timerange[0]?.hour, minute: categoryState?.timerange[0]?.minute }).format('HH : mm')
                              : '- - : - -'
                          }
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.end')} />
                  <SettingListItemRightAction>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                      <TouchableOpacity
                        onPress={() => dispatch(actions.app.showDateTimePicker('time',
                          moment({
                            hour: categoryState?.timerange[1]?.hour,
                            minute: categoryState?.timerange[1]?.minute,
                          }).toDate(),
                          (value) => {
                            const selectedStart = moment(value)
                            const timerange = [{ ...categoryState.timerange[0] }, { ...categoryState.timerange[1] }]
                            timerange[1].minute = Number(selectedStart.format('mm'))
                            timerange[1].hour = Number(selectedStart.format('HH'))
                            setCategoryState({
                              ...categoryState,
                              timerange: timerange,
                            })
                          },
                          moment({
                            hour: categoryState?.timerange[0]?.hour,
                            minute: categoryState?.timerange[0]?.minute,
                          }).toDate(),
                        ))}
                      >
                        <Text>
                          {
                            categoryState?.timerange[1]
                              ? moment({ hour: categoryState?.timerange[1]?.hour, minute: categoryState?.timerange[1]?.minute }).format('HH : mm')
                              : '- - : - -'
                          }
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem>
                  <SettingListItemRightAction>
                    <Button
                      title={t('app.page.setting.menuPanel.menuInfo.clearTime')}
                      style={styles.button}
                      backgroundColor={colors.primary}
                      textBold
                      onPress={onResetTime}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.menuPanel.category.staffOnly')} />
                  <SettingListItemRightAction>
                    <ToggleSwitch
                      value={categoryState.waiterOnly}
                      disabled={categoryState.name === t('app.page.setting.menuPanel.menuInfo.promoted') || categoryState.name === t('app.page.setting.menuPanel.menuInfo.sold')}
                      onChangeValue={() => setCategoryState({
                        ...categoryState,
                        waiterOnly: !categoryState.waiterOnly,
                      })}
                      size={23}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.menuPanel.category.takeawayOnly')} />
                  <SettingListItemRightAction>
                    <ToggleSwitch
                      value={categoryState.takeawayOnly}
                      disabled={categoryState.name === t('app.page.setting.menuPanel.menuInfo.promoted') || categoryState.name === t('app.page.setting.menuPanel.menuInfo.sold')}
                      onChangeValue={() => setCategoryState({
                        ...categoryState,
                        takeawayOnly: !categoryState.takeawayOnly,
                      })}
                      size={23}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.menuPanel.category.dineinOnly')} />
                  <SettingListItemRightAction>
                    <ToggleSwitch
                      value={categoryState.dineinOnly}
                      disabled={categoryState.name === t('app.page.setting.menuPanel.menuInfo.promoted') || categoryState.name === t('app.page.setting.menuPanel.menuInfo.sold')}
                      onChangeValue={() => setCategoryState({
                        ...categoryState,
                        dineinOnly: !categoryState.dineinOnly,
                      })}
                      size={23}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
                {
                  _.get(categoryState, 'id') && (
                    <>
                      <SettingListItem>
                        <SettingListItemText text='Disable' />
                        <SettingListItemRightAction>
                          <ToggleSwitch
                            value={categoryState.disabled}
                            disabled={!_.get(categoryState, 'id')}
                            onChangeValue={() => {
                              dispatch(actions.menu.disableCategory(categoryState.id, categoryState.disabled))
                              setCategoryState({
                                ...categoryState,
                                disabled: !categoryState.disabled,
                              })
                            }}
                            size={23}
                          />
                        </SettingListItemRightAction>
                      </SettingListItem>
                      <SettingListItem>
                        <SettingListItemText text={t('app.page.setting.menuPanel.category.hide')} />
                        <SettingListItemRightAction>
                          <ToggleSwitch
                            value={categoryState?.hidden}
                            disabled={!_.get(categoryState, 'id')}
                            onChangeValue={() => {
                              dispatch(actions.menu.hideCategory(categoryState.id, !categoryState?.hidden))
                              setCategoryState({
                                ...categoryState,
                                hidden: !categoryState?.hidden,
                              })
                            }}
                            size={23}
                          />
                        </SettingListItemRightAction>
                      </SettingListItem>
                      <SettingListEnterRow
                        label={t('app.page.setting.menuPanel.category.printOrder')}
                        value={printOrder.findIndex(m => m.includes(category.id)) + 1 || 1}
                        onPress={() => {
                          setOptionsPanelConfig({
                            title: t('app.page.setting.menuPanel.category.printOrder'),
                            defaultValue: printOrder.findIndex(m => m.includes(category.id)) + 1 || 1,
                            onChangeValue: (value) => {
                              const index = printOrder.findIndex(m => m.includes(category.id))
                              if (value === index + 1) return // no change

                              const newPrintOrder = Array.from(printOrder)
                              if (index > -1) {
                                const newArray = [...newPrintOrder[index]]
                                const itemIndex = printOrder[index].findIndex(m => m === category.id)
                                newArray.splice(itemIndex, 1)
                                newPrintOrder[index] = newArray
                              }

                              if (newPrintOrder[newPrintOrder.length - 1].length === 0) newPrintOrder.splice(-1, 1)

                              if (value === 0) {
                                newPrintOrder.unshift([category.id])
                              } else if (value === printOrder.length + 1) {
                                newPrintOrder.push([category.id])
                              } else {
                                const newArray = [...newPrintOrder[value - 1]]
                                newArray.push(category.id)
                                newPrintOrder[value - 1] = newArray
                              }
                              setPrintOrder(newPrintOrder)
                            },
                            options: printOptions,
                            closeOnSelect: true,
                          })
                        }}
                      />
                    </>
                  )
                }
              </>
            )
          }
        </SettingList>
      </ScrollView>

      {
        (categoryState.name !== t('app.page.setting.menuPanel.menuInfo.promoted') && categoryState.name !== t('app.page.setting.menuPanel.menuInfo.sold')) && (
          <View style={styles.buttons}>
            <Button
              textBold
              title={t('app.common.delete')}
              backgroundColor={colors.primary}
              disabled={!category.id}
              onPress={handleDelete}
            />
            <Button
              textBold
              title={t('app.common.save')}
              backgroundColor={colors.darkSecondary}
              onPress={handleSave}
            />
          </View>
        )
      }
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
