import { StyleSheet, Text, TextInput, View } from 'react-native'
import { actions, useDispatch } from '@/redux'
import { getStatusConfig } from '@/libs/orderStatus'
import { useSelectedOrder } from '@/hooks/orderHistory'
import { useTranslation } from 'react-i18next'
import Column from '@/components/Column'
import CustomerInfoCard from '@/components/CustomerInfoCard'
import React, { useState } from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import moment from 'moment'

export default function Header (props) {
  const { crmSelectedOrder, fromCRM = false } = props
  const { t } = useTranslation()
  const _selectedOrder = useSelectedOrder()
  const selectedOrder = fromCRM ? crmSelectedOrder : _selectedOrder
  const dispatch = useDispatch()
  const [inputRemark, setInputRemark] = useState(selectedOrder.remark)
  if (!selectedOrder) { return <View style={styles.container} /> }
  const { id, serial, table, remark, customers, createdAt, pickupAt, subTable } = selectedOrder

  const orderStatus = getStatusConfig(selectedOrder)?.text

  React.useEffect(() => {
    setInputRemark(remark)
  }, [remark])

  return (
    <Row style={styles.container}>
      <Column style={styles.content}>
        {selectedOrder.deliveryType === 'table' && (
          <Row>
            <Text style={styles.titleText}>{t('app.page.orderHistory.orderDetail.status')}：{orderStatus}</Text>
            <Text style={styles.titleText}>{t('app.constant.order.tableLong')}：{table}{subTable > 0 ? `(${String.fromCharCode(subTable + 64)})` : ''}</Text>
          </Row>
        )}
        {selectedOrder.deliveryType === 'takeaway' && (
          <Row>
            <Text style={styles.titleText}>{t('app.page.orderHistory.orderDetail.status')}：{orderStatus}</Text>
            <Text style={styles.titleText}>{t('app.constant.order.pickupAt')}：{moment(pickupAt).format('HH:mm')}</Text>
          </Row>
        )}
        {selectedOrder.deliveryType === 'storeDelivery' && (
          <Row>
            <Text style={styles.titleText}>{t('app.page.orderHistory.orderDetail.status')}：{orderStatus}</Text>
          </Row>
        )}
        {selectedOrder.deliveryType === 'table' && (
          <>
            <Row>
              <Text style={styles.text}>{t('app.constant.order.serial')}：{serial}</Text>
              <Text style={styles.text}>{t('app.constant.order.dine-in-time')}：{moment(createdAt).format('HH:mm')}</Text>
            </Row>
            <Row>
              <Text style={styles.text}>{t('app.page.table.dialog.date')}：{moment(createdAt).format('DD/MM/YYYY')}</Text>
              <Text style={styles.text}>{t('app.constant.order.customers')}：{customers}</Text>

            </Row>
          </>
        )}
        {selectedOrder.deliveryType === 'takeaway' && (
          <>
            <Row>
              <Text style={styles.text}>{t('app.constant.order.serial')}：{serial}</Text>
              <Text style={styles.text}>{t('app.constant.order.pickupDate')}：{moment(pickupAt).format('DD/MM/YYYY')}</Text>
            </Row>
            <Row>
              <Text style={styles.text}>{t('app.page.table.dialog.date')}：{moment(createdAt).format('DD/MM/YYYY')}</Text>
            </Row>
          </>
        )}
        {selectedOrder.deliveryType === 'storeDelivery' && (
          <>
            <Row>
              <Text style={styles.text}>{t('app.constant.order.serial')}：{serial}</Text>
              <Text style={styles.text}>{t('app.constant.order.pickupDate')}：{moment(pickupAt).format('DD/MM/YYYY')}</Text>
            </Row>
            <Row>
              <Text style={styles.text}>{t('app.page.table.dialog.date')}：{moment(createdAt).format('DD/MM/YYYY')}</Text>
              <Text style={styles.text}>{t('app.constant.order.completeAt')}：{moment(pickupAt).format('HH:mm')}</Text>
            </Row>
            {/* <Row>
              <Text style={styles.text}>外送員：{shipping.lalamove.driverName}</Text >
            </Row> */}
          </>
        )}
        {
          !fromCRM &&
            <TextInput
              value={inputRemark}
              style={styles.textInput}
              placeholder={t('app.constant.order.orderRemark')}
              onChangeText={(text) => {
                setInputRemark(text)
              }}
              onBlur={() => {
                dispatch(actions.orderHistory.updateOrderRemark(id, inputRemark))
              }}
            />
        }
      </Column>

      <Column>
        <CustomerInfoCard customer={selectedOrder} />
        <Text style={[styles.text, { alignSelf: 'flex-end' }]}>
          {selectedOrder.tags?.map(tag => `＃${tag.name}`).join(' ')}
        </Text>
      </Column>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 122,
    backgroundColor: colors.lightPrimary,
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  content: {
    flex: 1,
  },
  titleText: {
    flex: 1,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 'bold',
    color: colors.white,
  },
  text: {
    flex: 1,
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '500',
    color: colors.white,
  },
  textInput: {
    height: 24,
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    marginRight: 50,
    marginVertical: 5,
    color: colors.primary,
    fontSize: 12,
    fontWeight: '500',
  },

})
