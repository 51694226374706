import React from 'react'

import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @typedef SwitchRowProps
 * @property {string} label
 * @property {boolean} value
 * @property {boolean?} divider
 * @property {() => void} onChangeValue
 */

/**
 *
 * @param {SwitchRowProps} props
 * @returns
 */
export default function SwitchRow (props) {
  const { divider, label, value, onChangeValue } = props
  return (
    <SettingListItem divider={divider}>
      <SettingListItemText text={label} />
      <SettingListItemRightAction>
        <ToggleSwitch
          value={value}
          onChangeValue={() => onChangeValue(!value)}
          size={23}
        />
      </SettingListItemRightAction>
    </SettingListItem>
  )
}
