import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import Snackbar from './Snackbar'

export default function Container (props) {
  const dispatch = useDispatch()
  const snackbars = useSelector(state => state.app.snackbars)

  return _.map(snackbars, snackbar => (
    <Snackbar
      key={snackbar.id}
      config={snackbar}
      handleCloase={() => {
        console.log('close snackbar', snackbar.message)
        dispatch(actions.app.closeSnackbar(snackbar.id))
      }}
    />
  ))
}
