import { ScrollView, StyleSheet } from 'react-native'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { getCategoryName } from '@/libs/menu'
import { useHistory } from '@/libs/reactRouter'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import ImageHeader from '../ImageHeader'
import React from 'react'
import SettingList from '@/components/Setting/SettingList'
import SettingListHeaderButton from '@/components/Setting/SettingListHeaderButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingStatus from '@/components/Setting/SettingStatus'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'

export default function MenuInfo (props) {
  const history = useHistory()
  const { t } = useTranslation()

  const weekdayOptions = [
    { value: 0, display: t('app.constant.weekday.sun') },
    { value: 1, display: t('app.constant.weekday.mon') },
    { value: 2, display: t('app.constant.weekday.tue') },
    { value: 3, display: t('app.constant.weekday.wed') },
    { value: 4, display: t('app.constant.weekday.thur') },
    { value: 5, display: t('app.constant.weekday.fri') },
    { value: 6, display: t('app.constant.weekday.sat') },
  ]

  const { menu: selectedMenu } = props
  const allMenus = useSelector(state => state.menu.menus)
  const menu = _.get(allMenus, selectedMenu?.id, selectedMenu)
  const 產品分類 = getCategoryName(menu?.categoryId)
  const inventory = useSelector(state => state.merchant.data?.setting?.inventory)
  const takeawayIndex = (menu?.prices ?? []).findIndex(p => p.type === 'takeaway')
  const deliveryIndex = (menu?.prices ?? []).findIndex(p => p.type === 'storeDelivery')
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  // const 適用範圍 = menu.prices?.map(p => p.type).join(',')
  // const 適用期間 = menu.weekdays?.join(',')
  // const 税別 = menu.??? ? "含稅":"税前"
  // const 限時 = menu.timerange?.map(t => `${t.hour}:${t.minute}`).join(',')

  return (
    <>
      <SettingListHeaderButton
        text={t('app.page.setting.menuPanel.menuInfo.edit')}
        style={styles.addButton}
        onPress={() => history.push(`/setting/menu/menu-detail/${menu.id}`)}
        icon='square-edit-outline'
      />
      <SettingList
        shadow
        style={styles.container}
      >
        <ImageHeader menu={menu} disableButton />
        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.category')} style={styles.leftText} />
            <SettingListItemText text={產品分類} style={styles.rightText} />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.name')} style={styles.leftText} />
            <SettingListItemText text={(_.get(menu?.localeNames, lang) ?? menu.name) || '-'} style={styles.rightText} wrap />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.code')} style={styles.leftText} />
            <SettingListItemText text={menu.code || '-'} style={styles.rightText} wrap />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.desc')} style={styles.leftText} />
            <SettingListItemText text={(_.get(menu?.localeDesc, lang) ?? menu.desc) || '-'} style={styles.rightText} wrap />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.price')} style={styles.leftText} />
            <SettingListItemText text={menu.priceUndetermined ? '時價' : `${currencyWithCommas(menu.price - menu.discount)}`} style={styles.rightText} />
          </SettingListItem>
          {
            takeawayIndex > -1 && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.takeawayPrice')} style={styles.leftText} />
                <SettingListItemText text={menu.priceUndetermined ? '時價' : `${currencyWithCommas(menu.prices[takeawayIndex].price - menu.prices[takeawayIndex].discount)}`} style={styles.rightText} />
              </SettingListItem>
            )
          }
          {
            deliveryIndex > -1 && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.deliveryPrice')} style={styles.leftText} />
                <SettingListItemText text={menu.priceUndetermined ? '時價' : `${currencyWithCommas(menu.prices[deliveryIndex].price - menu.prices[deliveryIndex].discount)}`} style={styles.rightText} />
              </SettingListItem>
            )
          }
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.date')} style={styles.leftText} />
            <SettingListItemText
              text={
                _.get(menu, 'weekdays.length')
                  ? weekdayOptions.filter(o => {
                    const weekdays = menu.weekdays || []
                    return weekdays.includes(o.value)
                  }).map(o => o.display).join()
                  : '-'
              } style={styles.rightText}
            />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.time')} style={styles.leftText} />
            <SettingListItemText
              text={
                _.get(menu, 'timerange.length')
                  ? `${_.padStart(menu.timerange[0].hour, 2, '0')}:${_.padStart(menu.timerange[0].minute, 2, '0')} - ${_.padStart(menu.timerange[1].hour, 2, '0')}:${_.padStart(menu.timerange[1].minute, 2, '0')}`
                  : '-'
              } style={styles.rightText}
            />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.promoted')} style={styles.leftText} />
            <SettingStatus enabled={menu.promoted} />
          </SettingListItem>
          {
            menu.promoted && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.promoDate')} style={styles.leftText} />
                <SettingListItemText
                  text={
                    _.get(menu, 'promotedDays.length')
                      ? weekdayOptions.filter(o => {
                        const weekdays = menu.promotedDays || []
                        return weekdays.includes(o.value)
                      }).map(o => o.display).join()
                      : '-'
                  } style={styles.rightText}
                />
              </SettingListItem>
            )
          }
          {
            menu.promoted && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.promoTime')} style={styles.leftText} />
                <SettingListItemText
                  text={
                    _.get(menu, 'promotedTimerange.length')
                      ? `${_.padStart(menu.promotedTimerange[0].hour, 2, '0')}:${_.padStart(menu.promotedTimerange[0].minute, 2, '0')} - ${_.padStart(menu.promotedTimerange[1].hour, 2, '0')}:${_.padStart(menu.promotedTimerange[1].minute, 2, '0')}`
                      : '-'
                  } style={styles.rightText}
                />
              </SettingListItem>
            )
          }
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.noDiscount')} style={styles.leftText} />
            <SettingStatus enabled={menu.excludedDiscount} />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.noSurcharge')} style={styles.leftText} />
            <SettingStatus enabled={menu.excludedSurcharge} />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.constant.deliveryType.table')} style={styles.leftText} />
            <SettingStatus enabled={!menu.nonTakeawayDisabled} />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.takeaway')} style={styles.leftText} />
            <SettingStatus enabled={!menu.takeawayDisabled} />
          </SettingListItem>
          {
            inventory && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.inventory')} style={styles.leftText} />
                <SettingListItemText text={menu.inventory} style={styles.rightText} />
              </SettingListItem>
            )
          }
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.option')} />
            <SettingListItemText text={menu.options?.length === 0 ? '-' : ''} style={styles.rightText} />
          </SettingListItem>

          {_.unionBy(menu.options, menu.optionGroupPresets, 'id').map(optionGroup => {
            const text = optionGroup.options.map(o => o.name).join(', ')
            return (
              <SettingListItem key={optionGroup.id}>
                <SettingListItemText text={optionGroup.name} style={[styles.leftText, { paddingLeft: 8 }]} />
                <SettingListItemText text={text} style={styles.rightText} />
              </SettingListItem>
            )
          },
          )}

        </ScrollView>
      </SettingList>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  scrollView: {
    flex: 1,
  },
  leftText: {
    width: '30%',
  },
  rightText: {
    flex: 1,
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: 16,
  },
  imageContainer: {
    height: 90,
    width: 90,
    backgroundColor: colors.light,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  textContainer: {
    flexWrap: 'wrap',
  },
  text: {
    color: colors.gray,
    fontSize: 12,
    fontWeight: '500',
  },
  addButton: {
    width: 'auto',
    marginLeft: 0,
  },
})
