import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import i18n from '@/i18n'
import moment from 'moment'

import Column from '@/components/Column'
import Row from '@/components/Row'
import TableHeader from '@/components/OrderTable/TableHeader'
import TableRow from '@/components/OrderTable/TableRow'
import colors from '@/theme/colors'
import getFormatBatchItemStatus from '@/libs/getFormatBatchItemStatus'
import shadows from '@/theme/shadows'

// item 使用的欄位名稱
// const fields = ['serial', 'name', 'price', 'quantity', 'discount', 'surcharge', 'total']
const fields = ['serial', 'name', 'price', 'quantity', 'total']

// 欄寬
// const widths = [45, 152, 45, 45, 45, 50, 50] // width: 432
const widths = [45, 152, 45 + 45 + 45, 50, 50] // width: 432

// 對齊
const justifyContents = [
  'center',
  'flex-start',
  'flex-start',
  'center',
  'flex-start',
  'flex-start',
  'flex-start',
]

export default function BatchList ({ batches }) {
  const { t } = useTranslation()
  const serialText = t('app.page.table.dialog.serial')
  const nameText = t('app.page.table.dialog.name')
  const priceText = t('app.page.table.dialog.price')
  const quantityText = t('app.page.table.dialog.quantity')
  const totalText = t('app.page.table.dialog.total')
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)
  const allSets = useSelector(state => state.menu.sets)

  // 表頭
  // const headers = ['序號', '產品名稱', '價錢', '數量', '折扣', '服務費', '小計']
  const headers = [serialText, nameText, priceText, quantityText, totalText]
  const items = _.flatMap(batches, batch => batch.items)
  if (items.length === 0) return null

  let usedSerial = 0
  const itemSerials = items.map((item, index) => {
    const itemSerial = usedSerial + 1
    usedSerial++
    return { id: item.id, itemSerial }
  })

  const renderItem = ({ item }) => {
    const price = item.priceUndetermined ? t('app.constant.order.priceUndetermined') : item.price
    const total = item.priceUndetermined ? t('app.constant.order.priceUndetermined') : item.total
    const newItem = {
      ...item,
      price,
      total,
      name: _.get((
        item.isSet
          ? allSets[item.menuId]
          : allMenus[item.menuId]
      )?.localeNames, lang) ?? item.name,
      status: getFormatBatchItemStatus(item),
    }
    return (
      <TableRow
        serial={itemSerials.find(itemSerial => itemSerial.id === item.id).itemSerial}
        item={newItem}
        fields={fields}
        widths={widths}
        justifyContents={justifyContents}
        commentText={item.status}
        displaySetItems
      />
    )
  }

  const renderBatch = ({ item: batch }) => (
    <Column>
      <Row style={styles.batchHeader}>
        <Text style={styles.batchText}>
          ＃{batch.index + 1}  {moment(batch.createdAt).format('HH:mm')}
        </Text>
      </Row>
      <TableHeader
        headers={headers}
        widths={widths}
        justifyContents={justifyContents}
        style={styles.header}
        textStyle={styles.headerText}
      />
      <FlatList
        data={batch.items}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        showsVerticalScrollIndicator={false}
      />
    </Column>
  )

  return (
    <View style={styles.container}>
      <FlatList
        data={batches}
        renderItem={renderBatch}
        keyExtractor={item => item.id}
        showsVerticalScrollIndicator={false}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopRightRadius: 5,
    overflow: 'hidden',
    marginBottom: 10,
    ...shadows.slight,
  },
  batchHeader: {
    height: 25,
    alignItems: 'center',
    backgroundColor: colors.lightPrimary,
    borderTopLeftRadius: 15,
    paddingLeft: 10,
  },
  batchText: {
    fontSize: 13,
    fontWeight: '500',
    color: colors.white,
  },
  header: {
    backgroundColor: colors.lightGray3,
    height: 20,
  },
  headerText: {
    fontSize: 10,
    fontWeight: '500',
    color: colors.textTertiary,
  },
})
