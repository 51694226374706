import { FlatList, StyleSheet } from 'react-native'
import React from 'react'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import colors from '@/theme/colors'

/**
 * @typedef IButton
 * @property {string} title
 * @property {() => void} action
 * @property {boolean?} disabled
 */

/**
 * @typedef ButtonDialogProps
 * @property {string} title
 * @property {*} open
 * @property {() => void} onClose
 * @property {IButton[]} buttons
 */

/**
 *
 * @param {ButtonDialogProps} props
 * @returns
 */
export default function ButtonDialog (props) {
  const { open, buttons = [], title, onClose } = props

  const renderItem = ({ item, index }) => {
    const { title, action = () => {}, disabled } = item
    return (
      <Button
        title={title}
        onPress={() => {
          action()
          onClose()
        }}
        disabled={disabled}
        backgroundColor={colors.primary}
        style={styles.button}
      />
    )
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title={title}
      visible={open}
      contentContainerStyle={styles.contentContainer}
      onClose={onClose}
    >
      <FlatList
        data={buttons}
        renderItem={renderItem}
        keyExtractor={(button, index) => String(index)}
        showsHorizontalScrollIndicator={false}
        numColumns={4}
      />
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 500,
    padding: 0,
    backgroundColor: colors.light,
  },
  button: {
    height: 60,
  },
})
