import { StyleSheet, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { getRouteByKey } from '@/libs/setting'
import { useLocation } from '@/libs/reactRouter'
import { useSelector } from '@/redux'

import TabBar from '@/components/Setting/TabBar'

import OptionPanel from './OptionPanel'

/**
 * @typedef LayoutProps
 * @property {JSX.Element?} children
 */

/**
 *
 * @param {LayoutProps} props
 * @returns
 */
export default function Layout (props) {
  const { children } = props
  const { visible } = useSelector(state => state.setting.optionConfig)
  const merchant = useSelector(state => state.merchant.data)
  const currentUserPermissions = useSelector(state => state.auth.userLogin?.permissions)
  const isAdmin = currentUserPermissions?.includes('admin')
  // example: /setting/general/local
  const location = useLocation().pathname.split('/')
  const routes = _.filter(getRouteByKey(location[2]).routes, route => {
    if (route.key === 'category-tag') {
      return merchant?.setting?.enableCategoryTag
    }
    if (route.key === 'payInOut') {
      return isAdmin || currentUserPermissions?.find(currentUserPermission => currentUserPermission === 'draw-cash')
    }
    return true
  })

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <TabBar items={routes} location={location} />
        {visible
          ? <OptionPanel />
          : children}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1024,
    paddingHorizontal: 24,
    paddingBottom: 48,
  },
})
