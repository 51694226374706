import { Platform } from 'react-native'
import { v4 as uuid } from 'uuid'
import AppCenter from 'appcenter'
import AsyncStorage from '@react-native-async-storage/async-storage'
import _ from 'lodash'
import orderAppLib from 'dimorder-orderapp-lib'

import { dimorderApi } from '@/libs/api/dimorder'
import { loadingKey } from '@/constants'
import getNameWithNumber from '@/libs/getNameWithNumber'
import i18n from '@/i18n'
import logger, { setMerchantId } from '@/libs/logger'
import sentry from '@/libs/sentry'

import ActionTypes from './ActionTypes'
import actions from '../actions'

/* eslint-disable no-unused-vars */
import { IMerchant, IMerchantOpeningSetting, IMerchantSetPaymentRequest, IMerchantSetShippingRequest, IMerchantUpdateInfoRequest, IMerchantUpdateSurchargeRequest } from 'dimorder-orderapp-lib/dist/types/Merchant'
import { IModifier } from 'dimorder-orderapp-lib/dist/types/Modifier'
import { IOrderRounding } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

export function init () {
  return async (dispatch, getState) => {
    try {
      await Promise.all([
        dispatch(fetchMerchant()),
        dispatch(fetchSorting()),
        dispatch(fetchModifier()),
      ])
      dispatch(fetchGroup())
      dispatch({ type: ActionTypes.INIT })

      if (Platform.OS === 'web') return
      const merchant = getState().merchant
      await AsyncStorage.setItem('merchant', JSON.stringify(merchant))
    } catch (error) {
      logger.log(`merchant.init error ${error?.message ?? error.toString()}`, { error })
    }
  }
}

export function restore (merchant) {
  return (dispatch, getState) => {
    setMerchantId(merchant.data.id)
    dispatch({
      type: ActionTypes.RESTORE,
      payload: { merchant },
    })
  }
}

export function updateMerchant (data) {
  return async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.UPDATE_MERCHANT,
      payload: { data },
    })
    const merchant = getState().merchant
    await AsyncStorage.setItem('merchant', JSON.stringify(merchant))
  }
}
/**
 * @param {boolean} isLoading
 * @returns {ThunkFunction}
 */
export function updateLoading (isLoading) {
  return {
    type: ActionTypes.UPDATE_LOADING,
    payload: { isLoading },
  }
}

/**
 * @returns {ThunkFunction}
 */
export function fetchMerchant () {
  return async (dispatch, getState) => {
    const isMerchantLogin = getState().auth.isMerchantLogin
    if (isMerchantLogin) {
      const data = await dimorderApi.merchant.getMerchant()
      setMerchantId(data.id) // 設定 logger 的 info.merchantId
      orderAppLib.libs.libConfig.rounding = data.rounding

      sentry.setUser({
        id: data.id,
        username: data.name,
        merchantId: data.id,
        merchantName: data.name,
        merchantShortCode: data.shortCode,
      })
      sentry.setTags({
        merchantId: data.id,
        merchantName: data.name,
        merchantShortCode: data.shortCode,
      })
      if (Platform.OS !== 'web') {
        AppCenter.setUserId(data.id)
      }

      dispatch(updateMerchant(data))
    }
  }
}

/**
 * 覆寫 print Order
 * @param {Array} printOrder
 * @returns {ThunkFunction}
 */
export function fetchSorting () {
  return async (dispatch, getState) => {
    const sorting = await dimorderApi.merchant.getSorting()
    dispatch({
      type: ActionTypes.UPDATE_SORTING,
      payload: { sorting },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function fetchModifier () {
  return async (dispatch, getState) => {
    const modifier = await dimorderApi.modifier.getModifier()
    dispatch({
      type: ActionTypes.UPDATE_MODIFIER,
      payload: { modifier },
    })
  }
}

/**
 * 取得CRM group
 * @returns {ThunkFunction}
 */
export function fetchGroup () {
  return async (dispatch, getState) => {
    const enableCRM = getState().merchant.data.setting.enableCRM

    if (enableCRM) {
      const group = await dimorderApi.merchant.getGroup()
      dispatch({
        type: ActionTypes.UPDATE_GROUP,
        payload: { group },
      })
    }
  }
}

/**
 * 更改堂食自動確認打印開關
 * @param {boolean} autoConfirm
 * @returns {ThunkFunction}
 */
export function updatePrinterAutoConfirm (autoConfirm) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchantPrinter.updatePrinterAutoConfirm(autoConfirm)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updatePrinterAutoConfirm error', error)
    }
  }
}

/**
 * 更改收據打印進位差異
 * @param {boolean} showRoundingInReceipt
 * @returns {ThunkFunction}
 */
export function updateShowRoundingInReceipt (showRoundingInReceipt) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateShowRoundingInReceipt(showRoundingInReceipt)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateShowRoundingInReceipt error', error)
    }
  }
}

/**
 * 更改外賣提示音次數
 * @param {number} count -1 表示無限次
 * @returns {ThunkFunction}
 */
export function updateTakeawayNotifyAudioPlayCount (count) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchantPrinter.updateTakeawayNotifyAudioPlayCount(count)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateTakeawayNotifyAudioPlayCount error', error)
    }
  }
}

/**
 * 更改外送提示音次數
 * @param {number} count -1 表示無限次
 * @returns {ThunkFunction}
 */
export function updateStoreDeliveryNotifyAudioPlayCount (count) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchantPrinter.updateStoreDeliveryNotifyAudioPlayCount(count)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateStoreDeliveryNotifyAudioPlayCount error', error)
    }
  }
}

/**
 * 建立 table group
 * @param {string} group
 * @returns {ThunkFunction}
 */
export function createTableGroup (group) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.createTablegroup(group)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('createTableGroup error', error)
    }
  }
}

/**
 * 建立 table group
 * @param {string} group
 * @returns {ThunkFunction}
 */
export function deleteTableGroup (group) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.deleteTablegroup(group)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('deleteTableGroup error', error)
    }
  }
}

/**
 * 刪除在平面圖中，所有被刪除 table group 中的檯號
 * @param {string[]} tables
 * @returns {ThunkFunction}
 */
export function deleteTableGeometry (tables) {
  return async (dispatch, getState) => {
    try {
      const tableGeometry = getState().merchant.data.tableGeometry
      const updatedTableGeometry = tableGeometry.map(area => ({
        ...area,
        tables: area.tables.filter(table => !tables.includes(table.name)),
      }))
      const data = await dimorderApi.merchant.updateTableGeometry(updatedTableGeometry)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('deleteTableGeometry error', error)
    }
  }
}

/**
 * 建立 table 或更改 group
 * @param {string[]} tables
 * @param {string} group
 * @returns {ThunkFunction}
 */
export function updateTables (tables, group) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateTables(tables, group)
      const printerSetting = getState().printer.printerSetting
      const invoiceSettings = printerSetting.invoiceSettings.map(s => {
        const updatedTables = _.union(s.table, tables)
        return { ...s, table: updatedTables }
      })
      const kitchenReceiptSettings = printerSetting.kitchenReceiptSettings.map(s => {
        const updatedTables = _.union(s.table, tables)
        return { ...s, table: updatedTables }
      })
      await dispatch(actions.printer.updatePrinterSetting({ printerSetting: { ...printerSetting, invoiceSettings, kitchenReceiptSettings } }))
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateTable error', error)
    }
  }
}

/**
 * 刪除 table
 * @param {string[]} tables
 * @returns {ThunkFunction}
 */
export function deleteTables (tables) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.deleteTables(tables)
      const printerSetting = getState().printer.printerSetting
      const invoiceSettings = printerSetting.invoiceSettings.map(s => {
        const updatedTables = _.difference(s.table, tables)
        return { ...s, table: updatedTables }
      })
      const kitchenReceiptSettings = printerSetting.kitchenReceiptSettings.map(s => {
        const updatedTables = _.difference(s.table, tables)
        return { ...s, table: updatedTables }
      })
      await dispatch(actions.printer.updatePrinterSetting({ printerSetting: { ...printerSetting, invoiceSettings, kitchenReceiptSettings } }))
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('deleteTables error', error)
    }
  }
}

// * 餐廳設定

/**
 * 更改餐廳的 name, desc, contact, address
 * @param {IMerchantUpdateInfoRequest} info
 * @returns {Promise<IMerchant>}
 */
export function updateInfo (info) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateInfo(info)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateInfo error', error)
    }
  }
}

/**
 * 更改服務費
 * @param {IMerchantUpdateSurchargeRequest} surcharge
 * @returns {Promise<IMerchant>}
 */
export function updateSurcharge (surcharge) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateSurcharge(surcharge)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateSurcharge error', error)
    }
  }
}

/**
 * 更改小數處理
 * @param {IOrderRounding} rounding
 * @returns {Promise<IMerchant>}
 */
export function updateRounding (rounding) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateRounding(rounding)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateRounding error', error)
    }
  }
}

/**
 * 更改用戶介面語言，例如 locales: ['zh', 'en']
 * @param {string[]} locales
 * @returns {Promise<IMerchant>}
 */
export function updateClientLocales (locales) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateClientLocales(locales)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateClientLocales error', error)
    }
  }
}

/**
 * 更改用戶介面預設語言，例如 language: 'zh'
 * @param {string} language
 * @returns {Promise<IMerchant>}
 */
export function updateClientDefaultLanguage (language) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateClientDefaultLanguage(language)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateClientDefaultLanguage error', error)
    }
  }
}

/**
 * 更改廚房單預設語言，例如 language: 'zh'
 * @param {string} language
 * @returns {Promise<IMerchant>}
 */
export function updateBatchPrintLanguage (language) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateBatchPrintLanguage(language)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateBatchPrintLanguage error', error)
    }
  }
}

/**
 * 開關叫侍應功能
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateWaiterEnable (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateWaiterEnable(enable)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateWaiterEnable error', error)
    }
  }
}

/**
 * 開關動態 QRCode 功能
 * @param {boolean} dynamic
 * @returns {Promise<IMerchant>}
 */
export function updateDynamicQRCodeEnable (dynamic) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateDynamicQRCodeEnable(dynamic)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateDynamicQRCodeEnable error', error)
    }
  }
}

/**
 * 開關手動列印 QRCode 功能
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateManualPrintQR (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.enableManualPrintQR(enable)
      dispatch({
        type: ActionTypes.UPDATE_MERCHANT,
        payload: { data },
      })
    } catch (error) {
      console.log('updateManualPrintQR error', error)
    }
  }
}

/**
 * 開關動態 QRCode 列印餐廳Logo
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateQRCodeLogoEnable (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateQRCodeLogoEnable(enable)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateQRCodeLogoEnable error', error)
    }
  }
}

/**
 * 更改動態 QRCode 自訂顯示文字
 * @param {string} value
 * @returns {Promise<IMerchant>}
 */
export function updateDynamicQRCodeCustomText (value) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateDynamicQRCodeCustomText(value)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateDynamicQRCodeCustomText error', error)
    }
  }
}

/**
 * 更改動態 QRCode 不須輸入人數
 * @param {string} disableCustomerCount
 * @returns {Promise<IMerchant>}
 */
export function updateDynamicQRCodeDisableCustomerCount (disableCustomerCount) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateDynamicQRCodeDisableCustomerCount(disableCustomerCount)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateDynamicQRCodeDisableCustomerCount error', error)
    }
  }
}

/**
 * 更改外賣最快取餐時間（分鐘）
 * @param {number} minutes
 * @returns {Promise<IMerchant>}
 */
export function updateTakeawayPickupAfterMinutes (minutes) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateTakeawayPickupAfterMinutes(minutes)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateTakeawayPickupAfterMinutes error', error)
    }
  }
}

/**
 * 更改先後結帳
 * @param {boolean} payFirst
 * @returns {Promise<IMerchant>}
 */
export function updatePayFirst (payFirst) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updatePayFirst(payFirst)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updatePayFirst error', error)
    }
  }
}

/**
 * 更改堂食取餐通知開關
 * @param {boolean} dineInPickUp
 * @returns {Promise<IMerchant>}
 */
export function updateEnableDineInPickupNotification (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateEnableDineInPickupNotification(enable)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateEnableDineInPickupNotification error', error)
    }
  }
}

/**
 * 更改結帳後自動打印收據開關
 * @param {number} autoPrintReceipt
 * @returns {Promise<IMerchant>}
 */
export function updateAutoPrintReceipt (autoPrintReceipt) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateAutoPrintReceipt(autoPrintReceipt)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateAutoPrintReceipt error', error)
    }
  }
}

/**
 * 更改自訂推薦文字
 * @param {string} value
 * @returns {Promise<IMerchant>}
 */
export function updateRecommendCustomText (value) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateRecommendCustomText(value)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateRecommendCustomText error', error)
    }
  }
}

/**
 * 更改精簡模式(列印?)
 * @param {boolean} litePrinting
 * @returns {Promise<IMerchant>}
 */
export function updateLitePrinting (litePrinting) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateLitePrinting(litePrinting)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateLitePrinting error', error)
    }
  }
}

/**
 * 更改圖片點餐模式
 * @param {boolean} orderPictureMode
 * @returns {Promise<IMerchant>}
 */
export function updateOrderPictureMode (orderPictureMode) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateOrderPictureMode(orderPictureMode)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateOrderPictureMode error', error)
    }
  }
}

/**
 * 更改啟用餐單編號
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateEnableCode (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateEnableCode(enable)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateEnableCode error', error)
    }
  }
}

/**
 * 外賣時詢問數量
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateTakeawayOpeningPrompt (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateTakeawayOpeningPrompt(enable)
      dispatch({
        type: ActionTypes.UPDATE_MERCHANT,
        payload: { data },
      })
    } catch (error) {
      console.log('updateTakeawayOpeningPrompt error', error)
    }
  }
}

/**
 * 更改顯示更多座位資訊
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateEnableLargerTable (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateEnableLargerTable(enable)
      dispatch({
        type: ActionTypes.UPDATE_MERCHANT,
        payload: { data },
      })
    } catch (error) {
      console.log('updateEnableLargerTable error', error)
    }
  }
}

/**
 * 更改座位切換捲軸或下一頁的按鈕
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateEnableTablePaginationButton (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateEnableTablePaginationButton(enable)
      dispatch({
        type: ActionTypes.UPDATE_MERCHANT,
        payload: { data },
      })
    } catch (error) {
      console.log('updateEnableTablePaginationButton error', error)
    }
  }
}

/**
 * 更改廚房單列印員工
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateEnablePrintStaff (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.enablePrintStaff(enable)
      dispatch({
        type: ActionTypes.UPDATE_MERCHANT,
        payload: { data },
      })
    } catch (error) {
      console.log('updateEnablePrintStaff error', error)
    }
  }
}

/**
 * 更改展開所有座位資訊
 * @param {boolean} enable
 * @returns {Promise<IMerchant>}
 */
export function updateUnstackSubTables (enable) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.unstackSubTables(enable)
      dispatch({
        type: ActionTypes.UPDATE_MERCHANT,
        payload: { data },
      })
    } catch (error) {
      console.log('updateUnstackSubTables error', error)
    }
  }
}

/**
 * 更改頁腳自訂文字
 * @param {string} value
 * @returns {Promise<IMerchant>}
 */
export function updateFooterCustomText (value) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateFooterCustomText(value)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateFooterCustomText error', error)
    }
  }
}

/**
 * 更改營業時間設定
 * @param {IMerchantOpeningSetting[][]} openingWithSurcharge
 */
export function updateOpening (openingWithSurcharge) {
  return async (dispatch) => {
    try {
      const data = await dimorderApi.merchant.updateOpening(openingWithSurcharge)
      await dispatch(updateMerchant(data))

      dispatch(actions.setting.getOpeningSetting())
    } catch (error) {
      console.log('deleteTable error', error)
    }
  }
}

/**
 * 啟動/關閉付款方式
 * @param {IMerchantSetPaymentRequest} paymentMethod
 * @param {boolean} active
 */
export function activePaymentMethod (paymentMethod, active) {
  return async (dispatch) => {
    try {
      const data = await dimorderApi.merchant.updatePayment({
        key: paymentMethod.key,
        name: paymentMethod.name,
        tips: paymentMethod.tips,
        remove: !active,
      })
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updatePayment error', error)
    }
  }
}

/**
 * 新增/更改付款方式
 * @param {IMerchantSetPaymentRequest} paymentMethod
 */
export function upsertPaymentMethod (paymentMethod) {
  return async (dispatch) => {
    try {
      if (paymentMethod.amount) {
        if (typeof paymentMethod.amount !== 'number' || isNaN(paymentMethod.amount)) {
          dispatch(actions.app.showAlert({ message: i18n.t('app.alert.extraPaymentError') }))
          return false
        }
      }
      paymentMethod.remove = !paymentMethod.active
      const data = await dimorderApi.merchant.updatePayment(paymentMethod)
      dispatch(updateMerchant(data))

      dispatch(actions.app.showAlert({ message: i18n.t('app.alert.saveSuccess') }))
    } catch (error) {
      console.log('updatePayment error', error)
    }
  }
}

/**
 * 新增/更改自定折扣
 * @param {IModifier} modifier
 */
export function upsertModifier (modifier) {
  return async (dispatch, getState) => {
    try {
      const discountModifier = getState().merchant.modifier
      const updatedModifier = Array.from(discountModifier)
      if (modifier.id) {
        const data = await dimorderApi.modifier.updateModifier(modifier.id, modifier)
        const index = discountModifier.findIndex(m => m.id === modifier.id)
        updatedModifier[index] = data
      } else {
        const data = await dimorderApi.modifier.createModifier(modifier)
        updatedModifier.push(data)
      }

      dispatch({
        type: ActionTypes.UPDATE_MODIFIER,
        payload: { modifier: updatedModifier },
      })
      dispatch(actions.app.showAlert({ message: i18n.t('app.alert.saveSuccess') }))
    } catch (error) {
      console.log('upsertModifier error', error)
    }
  }
}

/**
 * 刪除自定折扣
 * @param {string} id
 */
export function deleteModifier (id) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.modifier.deleteModifier(id)
      const discountModifier = getState().merchant.modifier
      const updatedModifier = Array.from(discountModifier)
      const index = updatedModifier.findIndex(m => m.id === id)
      updatedModifier.splice(index, 1)

      dispatch({
        type: ActionTypes.UPDATE_MODIFIER,
        payload: { modifier: updatedModifier },
      })
      dispatch(actions.app.showAlert({ message: i18n.t('app.alert.cancelSuccess') }))
    } catch (error) {
      console.log('deleteModifier error', error)
    }
  }
}

/**
 * 刪除付款方式
 * @param {IMerchantSetPaymentRequest} paymentMethod
 */
export function deletePaymentMethod (paymentMethod) {
  return async (dispatch) => {
    try {
      const data = await dimorderApi.merchant.updatePayment({
        ...paymentMethod,
        remove: true,
      })
      dispatch(updateMerchant(data))

      dispatch(actions.app.showAlert({ message: i18n.t('app.alert.cancelSuccess') }))
    } catch (error) {
      console.log('updatePayment error', error)
    }
  }
}
/**
 * 新增/更改運送方式
 * @param {IMerchantSetShippingRequest} shippingMethod
 */
export function upsertShippingMethod (shippingMethod) {
  return async (dispatch) => {
    try {
      const data = await dimorderApi.merchant.updateShipping(shippingMethod)
      dispatch(updateMerchant(data))

      dispatch(actions.app.showAlert({ message: i18n.t('app.alert.saveSuccess') }))
    } catch (error) {
      console.log('updateShipping error', error)
    }
  }
}
/**
 * 刪除運送方式
 * @param {IMerchantSetShippingRequest} shippingMethod
 */
export function deleteShippingMethod (shippingMethod) {
  return async (dispatch) => {
    try {
      const data = await dimorderApi.merchant.updateShipping({
        ...shippingMethod,
        remove: true,
      })
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('shippingMethod error', error)
    }
  }
}

/**
 * 上傳圖片
 * @param {string} type
 * @param {Blob} image
 */
export function uploadImage (type, image) {
  return async (dispatch) => {
    try {
      const data = await dimorderApi.merchant.updateMerchantImage(type, image)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('uploadImage error', error)
    }
  }
}

/**
 * 刪除圖片
 * @param {string} type
 * @param {Blob} image
 */
export function deleteImage (type) {
  return async (dispatch) => {
    try {
      const data = await dimorderApi.merchant.removeMerchantImage(type)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('deleteImage error', error)
    }
  }
}

export function updateIsTakeawayDisabled (disabled) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.toggleTakeawayDisabled(disabled)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateIsTakeawayDisabled', error)
    }
  }
}

export function updateBusyMode (busyMode) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.enableBusyMode(busyMode)
      dispatch(updateMerchant(data))

      setTimeout(() => { dispatch(fetchMerchant()) }, 1800000)
    } catch (error) {
      console.log('updateBusyMode', error)
    }
  }
}

export function updateCustomTags (tags) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.updateCustomTag(tags)
      dispatch(updateMerchant(data))
    } catch (error) {
      console.log('updateCustomTags', error)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateTableGroup (group, setting) {
  return async (dispatch, getState) => {
    const data = await dimorderApi.merchant.updateTablegroup(group, setting)
    dispatch({
      type: ActionTypes.UPDATE_MERCHANT,
      payload: { data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateComboRule (comboRule) {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.COMBO))
      const data = await dimorderApi.merchant.updateComboRule(comboRule)
      dispatch({
        type: ActionTypes.UPDATE_MERCHANT,
        payload: { data },
      })
    } catch (error) {
      console.log('updateComboRule error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.COMBO))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function convertSetToComboGroup () {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.COMBO_GROUP))
      const sets = _.values(getState().menu.sets)
      const categories = getState().menu.categories
      const groups = getState().merchant.data.comboRule.groups || []
      const rules = getState().merchant.data.comboRule.rules || []
      const updatedGroups = [...groups]
      const updatedRules = [...rules]
      _.each(sets, set => {
        _.each(set.steps, step => {
          const itemIds = _.filter(set.menus, menu => menu.step === step.id).map(m => m.menuId)
          const name = `${set.name}-${step.name}`
          const groupIndex = _.findIndex(updatedGroups, g => g.name === name)
          if (groupIndex === -1) {
            const names = _.map(updatedGroups, g => g.name)
            updatedGroups.push({
              id: uuid(),
              name: getNameWithNumber(names, name),
              itemIds,
            })
          } else if (!_.isEqual(updatedGroups[groupIndex].itemIds, itemIds)) {
            updatedGroups[groupIndex] = {
              ...updatedGroups[groupIndex],
              itemIds,
            }
          }
        })
        const isExisted = _.findIndex(updatedRules, r => r.name === set.name) > -1
        if (!isExisted) {
          const category = categories[set.categoryId]
          const defaultTimeRange = {
            hour: 0,
            minute: 0,
          }
          updatedRules.push({
            id: uuid(),
            name: set.name,
            mandatoryItems: [],
            discountedItems: [],
            weekdays: _.get(category, 'weekdays', []),
            startTime: _.get(category, 'timerange.0', defaultTimeRange),
            endTime: _.get(category, 'timerange.1', defaultTimeRange),
            enabled: true,
            applyTo: ['merchant'],
          })
        }
      })
      await dispatch(updateComboRule({ rules: updatedRules, groups: updatedGroups }))
    } catch (error) {
      console.log('convertSetToComboGroup error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.COMBO_GROUP))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function addCashRequest (cash) {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.SETTING))
      await dimorderApi.merchant.addCashRequest(cash)
      dispatch(actions.app.showAlert({ message: i18n.t('app.alert.saveSuccess') }))
    } catch (error) {
      console.log('addCashRequest error', error)
      dispatch(actions.app.showSimpleAlert(i18n.t('app.alert.saveFail'), error))
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.SETTING))
    }
  }
}
