import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import React from 'react'

import colors from '@/theme/colors'

import IconButton from '@/components/buttons/IconButton'
import Row from '@/components/Row'

/**
 * @typedef BackNavigatorProps
 * @property {string} text
 * @property {() => void} onPress
 */

/**
 *
 * @param {BackNavigatorProps} props
 * @returns
 */
export default function BackNavigator (props) {
  const { text, onPress } = props
  return (
    <Row style={styles.container}>
      <TouchableOpacity
        style={styles.iconContainer}
        onPress={onPress}
      >
        <IconButton
          iconSource={require('@icons/setting/back.png')}
          iconStyle={styles.backIcon}
          onPress={onPress}
        />
      </TouchableOpacity>
      <Text style={styles.text}>{text}</Text>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    alignItems: 'center',
  },
  iconContainer: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  backIcon: {
    width: 15,
    height: 15,
    marginHorizontal: 15,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
  },
})
