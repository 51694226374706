
import _ from 'lodash'
import hash from 'object-hash'
import moment from 'moment'
import shortid from 'shortid'

import { PrintStyle } from '@/constants/printing'
import { PrinterFontSize } from '@/constants/printer'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import PrintDoc from '@/libs/printing/PrintDoc'
import i18n from '@/i18n'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppBatchItemOption, IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IMerchant } from 'dimorder-orderapp-lib/dist/types/Merchant'
import { ITag } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

/**
 * 目前有使用到的 merchant settings ( > 代表 Customized 有用到):
 * > mutedPrinter
 * printCustomerCountOnKitchenReceipt
 * printSerialOnReceipt: printSerialOnTableReceipt // 在堂食的廚房單上顯示訂單編號
 * printSerialOnReceiptTextStyle: printSerialOnTableReceiptTextStyle // 在堂食的廚房單上顯示訂單編號時的字體大小
 * showPickupTimeInKitchenReceipt
 * showBatchIndexInKitchenReceipt
 *
 * largerLineSpacingInKitchenReceipt
 *
 * > printWithNoOptions
 * kitchenReceiptGroupOptionSet
 * > kitchenReceiptOptionSeparator
 *
 * > code
 * kitchenReceiptSetMenuHideLabel
 */

export default class KitchenBatch extends PrintDoc {
  TYPE = PrintDoc.Types.KITCHEN_BATCH

  /**
   *
   * @param {IAppBatchItem[]} items
   * @param {IMerchant} merchant
   * @param {IAppOrder} order
   * @param {IKitchenReceiptSetting} printerSetting
   * @param {IPrintConfig} printConfig
   * @param {object} sorting // ? object 的內容是什麼？
   * @param {IAppOrderBatch} batch
   * @param {object} quantity // ? object 的內容是什麼？
   *
   * @typedef IPrintConfig
   * @property {boolean?} reprint
   * @property {boolean?} cancel
   * @property {boolean?} serveUp
   * @property {boolean?} printStaff
   * @property {boolean?} change
   * @property {ITransferTableInfo?} transfer
   *
   * @typedef ITransferTableInfo // ? 應該不只這些，還有什麼東西？
   * @property {{ table: string }} from
   * @property {{ table: string }} to
   * @returns {ThunkFunction}
   */
  constructor (
    items,
    merchant,
    order,
    printerSetting,
    printConfig,
    sorting,
    batch,
    quantity,
  ) {
    super()
    this.items = items
    this.merchant = merchant
    this.order = order
    this.printerSetting = printerSetting
    this.printConfig = printConfig
    this.sorting = sorting
    this.batch = batch
    this.quantity = quantity

    this.batchLocale = printerSetting.batchLocale || merchant.batchLocale
    this.printId = shortid.generate()
    this.printHash = this._generatePrintHash()
    this.TEXT = this._generateBatchLocaleText()

    this.align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH]
  }

  print () {
    const { printSetmenuBundled, cutPaperMode, splitItem, printTimes } = this.printerSetting
    const printCategories = this.sorting?.printCategories
    const items = splitItem ? this._splitItemsByQuantity(this.items) : this.items

    // TODO nonemptyPrintItems 和 itemsForCutPaperMode 這段可以優化，因為每次都會執行但並不是每次都會需要
    // * cutPaperMode === 'category'
    // * cutPaperMode === 'none'
    const printItems = []
    const remainItems = [...items]
    _.each(printCategories, (printCategory, index) => {
      printItems.push([])
      _.each(items, item => {
        if (printCategory.includes(item.categoryId)) {
          const itemIndex = remainItems.findIndex(r => r === item)
          printItems[index].push(item)
          remainItems.splice(itemIndex, 1)
        }
      })
    })
    printItems.unshift(remainItems)
    const nonemptyPrintItems = printItems.filter((printItem) => printItem.length)

    // * cutPaperMode === 'item'
    const itemsForCutPaperMode = []
    let setItems = []
    let setMenuIndex = -1
    _.each(items, item => {
      if (!item.isSet && item.setMenuIndex !== null) {
        if (setMenuIndex !== item.setMenuIndex && setItems.length) {
          itemsForCutPaperMode.push(setItems)
          setItems = []
        }
        setItems.push(item)
        setMenuIndex = item.setMenuIndex
      } else {
        itemsForCutPaperMode.push([item])
      }
    })
    if (setItems.length) {
      itemsForCutPaperMode.push(setItems)
    }

    if (cutPaperMode === 'category') {
      _.each(nonemptyPrintItems, (printItem) => {
        this._addKitchenBatchHeader(this.order)
        this._addKitchenBatchItem(printItem)
        this._addKitchenBatchFooter()
      })
    } else if (cutPaperMode === 'none') {
      this._addKitchenBatchHeader(this.order)
      _.each(nonemptyPrintItems, (printItem, index) => {
        this._addKitchenBatchItem(printItem)
        if (nonemptyPrintItems.length !== index + 1) {
          this.printer.addSeparator()
        }
      })
      this._addKitchenBatchFooter()
    } else {
      // cutPaperMode === 'item'
      _.each(itemsForCutPaperMode, (item) => {
        if (!printSetmenuBundled && item[0]?.isSet) {
          let setItems = item[0].setItems
          if (splitItem) {
            setItems = this._splitItemsByQuantity(setItems)
          }
          _.each(setItems, (item) => {
            this._addKitchenBatchHeader(this.order)
            this._addKitchenBatchItem([item])
            this._addKitchenBatchFooter()
          })
        } else if (!printSetmenuBundled && /^\d+$/.test(item[0]?.setMenuIndex)) {
          _.each(item, (i) => {
            this._addKitchenBatchHeader(this.order)
            this._addKitchenBatchItem([i])
            this._addKitchenBatchFooter()
          })
        } else {
          this._addKitchenBatchHeader(this.order)
          this._addKitchenBatchItem(item)
          this._addKitchenBatchFooter()
        }
      })
    }

    if (printTimes > 0) {
      this._addCopy(printTimes)
    }

    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  /**
   *
   * @param {number} printTimes
   */
  _addCopy (printTimes) {
    const printer = this.printer
    const data = Array.from(this.printer.getCommandBuffer())
    for (let i = 0; i < printTimes; i++) {
      printer.addCommand(data)
    }
  }

  _addKitchenBatchHeader () {
    const printer = this.printer
    const { createdAt } = this.batch
    const { table, serial, customers, subTable, deliveryType } = this.order
    const { deepenDotColor } = this.printerSetting
    const {
      setting: {
        mutedPrinter,
        printCustomerCountOnKitchenReceipt,
        printSerialOnReceipt: printSerialOnTableReceipt, // 在堂食的廚房單上顯示訂單編號
        printSerialOnReceiptTextStyle: printSerialOnTableReceiptTextStyle, // 在堂食的廚房單上顯示訂單編號時的字體大小
        showPickupTimeInKitchenReceipt,
        showBatchIndexInKitchenReceipt,
      },
    } = this.merchant

    printer.addInit()
    printer.addCodePage()
    printer.addAlign('center')
    printer.addColor('black')

    if (deepenDotColor) {
      printer.addDoubleStrikeMode()
    }

    if (!mutedPrinter) {
      printer.addBeep()
    }

    // ???? 看不懂在做什麼
    if (!table) {
      // * STORE_DELIVERY
      // * TAKEAWAY
      let text = deliveryType === 'storeDelivery' ? this.TEXT.DELIVERY : this.TEXT.TAKEWAY
      if (this.printConfig.reprint) {
        // FIXME BUG 追單這兩個字應該是紅色的
        printer.addColor('red')
        text += this.TEXT.REPRINT
        printer.addColor('black')
      }

      printer
        .addText(text, PrinterFontSize.MEDIUM)
        .addRowFeed(2)
        .addText(`#${serial}`, PrinterFontSize.MEDIUM)
    } else {
      // * TABLE
      if (this.printConfig.reprint) {
        printer.addColor('red')
        const text = this.TEXT.REPRINT
        printer.addText(text, PrinterFontSize.MEDIUM)
        printer.addRowFeed(2)
        printer.addColor('black')
      }

      // * 桌號
      // header 的桌號的字體固定為 MEDIUM，footer 的桌號的字體大小可由 batchMenuStyleText 設定改變
      // ???? 為什麼沒有判斷 deliveryType === 'table' 才印桌號？
      this._addTableNumber(table, subTable)

      // * 用餐人數
      if (printCustomerCountOnKitchenReceipt && customers) {
        printer.addRowFeed()
        const text = ` ${customers + i18n.t('app.printing.customer')}`
        printer.addText(text, PrinterFontSize.MEDIUM)
      }

      // * 訂單編號
      if (printSerialOnTableReceipt) {
        const textStyle = printSerialOnTableReceiptTextStyle || PrinterFontSize.SMALL
        printer.addRowFeed(2)
        printer.addText(`#${serial}`, textStyle)
      }
    }

    printer.addRowFeed(2)
    printer.addUnderlineMode(true)

    // * 取餐時間
    if (showPickupTimeInKitchenReceipt && !table) {
      const pickupAt = deliveryType === 'storeDelivery'
        ? _.get(this.order, 'shipping.lalamove.scheduleAt') // 車手預計取餐時間
        : this.order.pickupAt // 客人取餐時間
      const addMin = deliveryType === 'storeDelivery'
        ? 10 // FIXME 讓車手在現場等 10 分鐘？
        : 0
      const pickupAtText = i18n.t('app.printing.pickupAt')
      const pickupTime = moment(pickupAt).add(addMin, 'm').format('YYYY/MM/DD HH:mm:ss')
      const printText = `${pickupAtText} ${pickupTime}`
      printer.addText(printText, PrinterFontSize.MEDIUM)
      printer.addRowFeed(2)
    }

    // * 點餐次數
    if (showBatchIndexInKitchenReceipt) {
      const orderTimesText = i18n.t('app.printing.orderTime')
      const orderTimes = this.order.batches.length
      printer.addText(`${orderTimesText}: ${orderTimes}`, PrinterFontSize.MEDIUM)
      printer.addRowFeed(2)
    }

    // * 點餐時間
    const isSameDay = moment(createdAt).isSame(moment(), 'day')
    if (!isSameDay) {
      printer.addText(moment(createdAt).format('YYYY/MM/DD'), PrinterFontSize.MEDIUM)
      printer.addRowFeed(2)
    }
    printer.addText(moment(createdAt).format('HH:mm:ss'), PrinterFontSize.MEDIUM)
    printer.addRowFeed(2)

    printer.addUnderlineMode(false)

    // * 起菜
    if (this.printConfig.serveUp) {
      printer.addColor('red')
      printer.addText(`(${this.TEXT.SERVEUP})`, PrinterFontSize.MEDIUM)
      printer.addRowFeed(2)
      printer.addColor('black')
    }
  }

  _addKitchenBatchItem (items) {
    const { batchMenuStyleText, printWithPrice, printCancelledItem } = this.printerSetting
    const { setting: { largerLineSpacingInKitchenReceipt } } = this.merchant
    const printer = this.printer
    // 預設格式沒有 imageMode，fontSize 最大是中字體
    const fontSize = batchMenuStyleText > 1 ? 1 : batchMenuStyleText

    const itemQuantityWidth = printer.getQuantityMaxWidth(fontSize)
    const itemMaxWidths = printer.getItemMaxWidth(fontSize, this.batchLocale)
    const priceMaxWidth = printer.getPriceMaxWidth(fontSize)
    const twoItemWidths = [itemQuantityWidth, itemMaxWidths + priceMaxWidth - 1, 0]
    let threeItemWidths = []
    if (printWithPrice) {
      threeItemWidths = [itemQuantityWidth, itemMaxWidths - 1, priceMaxWidth]
    } else {
      threeItemWidths = twoItemWidths
    }

    if (this.batchLocale === 'thai') {
      printer.addPrintThai()
    }

    if (printCancelledItem && this.printConfig.cancel) {
      const cancelText = `(${this.TEXT.CANCELED})`
      printer.addColor('red')
      printer.addText(cancelText, fontSize)
      printer.addRowFeed(2)
      printer.addColor('black')
    }

    const printItems = [items]
    printItems.forEach((p, index) => {
      if (index > 0) {
        printer.addSeparator()
      }

      _.each(p, (item, itemIndex) => {
        this._addItem(items, item, itemIndex, threeItemWidths)
        this._addOptions(item.options)
        this._addTags(item.tags)
        this._addSetItems(item.setItems, threeItemWidths)
        this._addRemarks(item.remarks)
        this._addServeLaterTag(item.tags, twoItemWidths)

        printer.addRowFeed(largerLineSpacingInKitchenReceipt ? 2 : 1)
        printer.addAlign('center')
      })
    })
  }

  _addKitchenBatchFooter () {
    const { table, deliveryType, subTable } = this.order
    const {
      batchMenuStyleText,
      noFooter, // 不要在 footer 顯示桌號
    } = this.printerSetting
    const printStaff = this.printConfig.printStaff
    const printer = this.printer
    const batch = this.batch
    // 預設格式沒有 imageMode，fontSize 最大是中字體
    const fontSize = batchMenuStyleText > 1 ? 1 : batchMenuStyleText

    printer.addColor('black') // ???? 有必要嗎？
    printer.addCodePage()

    if (!noFooter) {
      printer.addRowFeed(2)
      if (deliveryType === 'table') {
        // * TABLE
        // 有的餐廳喜歡在 footer 再印一次桌號
        this._addTableNumber(table, subTable, fontSize)
      } else {
        // * STORE_DELIVERY
        // * TAKEAWAY
        printer.addText(i18n.t('app.printing.takeaway'), fontSize)
      }
      printer.addRowFeed(3)
    }
    printer.addRowFeed(2)

    // TODO adding _addIdentifier 不應該在 footer 裡面
    let name = ''
    const nameMaxWidth = printer.MAX_WIDTH - 10

    if (printStaff) {
      if (batch.from === 'MERCHANT') {
        name = batch.identifier
      } else if (batch.from === 'CUSTOMER_WEB') {
        if (batch.identifier === 'waiter') {
          name = batch.identifier
        } else {
          name = this.TEXT.CUSTOMERORDER
        }
      }
    }
    const itemWidths = [10, 0, nameMaxWidth]

    const itemRowText = [this.printId, '', name]

    printer.addAlign('left')
    printer.addFixedRow(itemRowText, itemWidths, 0, 1, this.align)
    printer.addRowFeed(4)

    printer.addCutPaper()
  }

  /**
   *
   * @param {ITag[]} tags
   */
  _addTags (tags) {
    if (_.isEmpty(tags)) return

    const {
      setting: {
        kitchenReceiptOptionSeparator, // ???? 兼用 option 的設定?
        kitchenReceiptGroupOptionSet, // ???? 兼用 option 的設定?
      },
    } = this.merchant
    const { batchMenuStyleText } = this.printerSetting
    const printer = this.printer
    // 預設格式沒有 imageMode，fontSize 最大是中字體
    const fontSize = batchMenuStyleText > 1 ? 1 : batchMenuStyleText

    printer.addColor('red')
    printer.addAlign('left')

    let tagTexts = []
    _.each(tags, (tag) => {
      const excludedTagIds = ['serve-up', 'serve-later', 'printKitchen'] // 起菜 叫起 追單
      const isExcludedTag = excludedTagIds.some(id => id === tag.id)
      // 這三種 tag 不要在 _addTag 的時候印出來
      // 'serve-later' tag 有另外的處理方式
      // 'serve-up' 透過 printConfig.serveUp 加註在 header
      // ???? 'printKitchen' 的 tag 是怎麼處理的？
      if (!_.isEmpty(tag) && !isExcludedTag) {
        tagTexts.push(tag.name)
      }
    })

    if (kitchenReceiptGroupOptionSet) {
      tagTexts = _
        .chain(tagTexts)
        .groupBy(tagName => tagName)
        .map((value, key) => value.length > 1 ? `${key} (x ${value.length})` : key)
        .value()
    }

    if (tagTexts.length > 0) {
      const separator = (kitchenReceiptOptionSeparator || ', ').replace(/\\n/g, '\n')
      tagTexts
        .join(separator)
        .split('\n')
        .forEach(text => {
          printer.addText(` ** ${text}`, fontSize)
          printer.addRowFeed(2)
        })
    }

    printer.addRowFeed()
    printer.addColor('black')
  }

  /**
   *
   * @param {IAppBatchItemOption[]} options
   */
  _addOptions (options) {
    if (_.isEmpty(options)) return

    const {
      setting: {
        printWithNoOptions,
        kitchenReceiptOptionSeparator,
        kitchenReceiptGroupOptionSet,
      },
    } = this.merchant
    const { batchMenuStyleText } = this.printerSetting
    const printer = this.printer
    // 預設格式沒有 imageMode，fontSize 最大是中字體
    const fontSize = batchMenuStyleText > 1 ? 1 : batchMenuStyleText

    printer.addColor('red')
    printer.addAlign('left')

    let optionTexts = []
    _.each(options, (option) => {
      if (!_.isEmpty(option)) {
        const quantity = option.quantity > 1 ? `(${option.quantity})` : '' // item 的選項數量只有一個時不顯示數量
        optionTexts.push(option.name + quantity)
      } else {
        if (printWithNoOptions) {
          optionTexts.push(this.TEXT.NORMALOPTION) // item 沒有選項時印一個 "正常"
        }
      }
    })

    if (kitchenReceiptGroupOptionSet) {
      optionTexts = _
        .chain(optionTexts)
        .groupBy(optionText => optionText)
        .map((value, key) => value.length > 1 ? `${key} (x ${value.length})` : key)
        .value()
    }

    const separator = (kitchenReceiptOptionSeparator || ', ').replace(/\\n/g, '\n')
    optionTexts
      .join(separator)
      .split('\n')
      .forEach((text, index, array) => {
        printer.addText(` ** ${text}`, fontSize)
        const isLast = index === array.length - 1
        if (!isLast) {
          printer.addRowFeed()
        }
      })

    printer.addRowFeed()
    printer.addColor('black')
  }

  /**
   *
   * @param {string[]} remarks
   */
  _addRemarks (remarks) {
    if (_.isEmpty(remarks)) return

    const { batchMenuStyleText } = this.printerSetting
    const printer = this.printer
    // 預設格式沒有 imageMode，fontSize 最大是中字體
    const fontSize = batchMenuStyleText > 1 ? 1 : batchMenuStyleText

    printer.addColor('red')
    _.each(remarks, (remark) => {
      if (!_.isEmpty(remark)) {
        printer
          .addText(`${this.TEXT.REMARKSTEXT}: ${remark}`, fontSize)
          .addRowFeed()
      }
    })
    printer.addColor('black')
  }

  /**
   *
   * @param {*[]} items // FIXME 這是什麼？
   * @param {IAppBatchItem} item
   * @param {number} index
   * @param {number[]} itemWidths
   */
  _addItem (items, item, index, itemWidths) {
    const { batchMenuStyleText, printWithPrice, cutPaperMode, splitItem } = this.printerSetting
    const { setting: { code, kitchenReceiptSetMenuHideLabel } } = this.merchant
    const printer = this.printer
    // 預設格式沒有 imageMode，fontSize 最大是中字體
    const fontSize = batchMenuStyleText > 1 ? 1 : batchMenuStyleText

    printer.addAlign('left')

    // quantity, printName, priceText, itemCode 都是用來產生 itemRowText 用的
    let quantity = item.quantity
    if (this.printConfig.serveUp || this.printConfig.reprint) {
      // serveUp, reprint, cancel 為使用情境不會同時發生
      quantity = Number(_.get(this.quantity, item.id, item.quantity))
    }
    if (this.printConfig.cancel || splitItem) {
      // FIXME 這行導致取消時印出來的數量永遠為 1
      quantity = 1
    }

    const isSetFromWeb = items.length > 1 && cutPaperMode === 'item' // ????  cutPaperMode === 'item'?
    let printName = item.name
    if (!kitchenReceiptSetMenuHideLabel && item.setId) {
      printName = `${printName} ${this.TEXT.SETTEXT}`
    }
    if (isSetFromWeb && index > 0 && item.quantity > 1) {
      printName = `${item.quantity}x ` + printName
    }
    if (isSetFromWeb && index === 0) {
      printName = `${printName} ${this.TEXT.SETTEXT}`
    }

    let priceText = ''
    if (printWithPrice) {
      const itemPrice = item.total / item.quantity * quantity
      priceText = currencyWithCommas(itemPrice)
    }

    const itemCode = code && item.code ? item.code : ''

    const itemRowText = [
      `${isSetFromWeb && index > 0 ? this.TEXT.FOLLOWTEXT : `${quantity}x`}`,
      [itemCode, printName].join(' ').trim(),
      priceText,
    ]

    printer.addFixedRow(itemRowText, itemWidths, fontSize, 1, this.align)
  }

  /**
   *
   * @param {IAppBatchItem[]} setItems
   * @param {number[]} itemWidths
   */
  _addSetItems (setItems, itemWidths) {
    if (_.isEmpty(setItems)) return

    const { batchMenuStyleText, printWithPrice } = this.printerSetting
    const printer = this.printer
    // 預設格式沒有 imageMode，fontSize 最大是中字體
    const fontSize = batchMenuStyleText > 1 ? 1 : batchMenuStyleText

    _.each(setItems, (setItem) => {
      const quantity = setItem.quantity > 1 ? `${setItem.quantity}x ` : '' // setItem 的數量只有一個時不顯示數量
      const name = setItem.name
      const price = setItem.total > 0 ? currencyWithCommas(setItem.total) : ''
      const itemRowText = [
        `${this.TEXT.FOLLOWTEXT} `,
        quantity + name,
        printWithPrice ? price : '',
      ]
      printer.addFixedRow(itemRowText, itemWidths, fontSize, 1, this.align)

      this._addOptions(setItem.options)
      this._addTags(setItem.tags)
      this._addRemarks(setItem.remarks)
    })
  }

  /**
   *
   * @param {ITag[]} tags
   * @param {number[]} itemWidths
   */
  _addServeLaterTag (tags, itemWidths) {
    const isServeLater = _.some(tags, tag => tag.id === 'serve-later')
    const shouldPrintServeLaterTag = isServeLater && !this.printConfig.reprint
    if (!shouldPrintServeLaterTag) return

    const { batchMenuStyleText } = this.printerSetting
    const printer = this.printer
    // 預設格式沒有 imageMode，fontSize 最大是中字體
    const fontSize = batchMenuStyleText > 1 ? 1 : batchMenuStyleText

    const serveLaterText = ` (${this.TEXT.SERVELATER})`
    const itemRowText = ['', serveLaterText, '']
    printer.addColor('red')
    printer.addFixedRow(itemRowText, itemWidths, fontSize, 1, this.align)
    printer.addColor('black')
  }

  /**
   *
   * @param {string} table
   * @param {string} subTable
   * @param {ETextStyle?} fontSize
   */
  _addTableNumber (table, subTable, fontSize = PrinterFontSize.MEDIUM) {
    const printer = this.printer
    const tableNumberText = this.TEXT.TABLE_NUMBER

    if (this.printConfig.transfer) {
      const fromOrder = this.printConfig.transfer.from
      const toOrder = this.printConfig.transfer.to
      const fromTableNoText = `${fromOrder.table}${fromOrder.subTable > 0 ? `(${String.fromCharCode(fromOrder.subTable + 64)})` : ''}`
      const toTableNoText = `${toOrder.table}${toOrder.subTable > 0 ? `(${String.fromCharCode(toOrder.subTable + 64)})` : ''}`
      const fromText = i18n.language === 'zh-HK' ? fromTableNoText + tableNumberText : tableNumberText + fromTableNoText
      const toText = i18n.language === 'zh-HK' ? toTableNoText + tableNumberText : tableNumberText + toTableNoText
      const text = `${fromText} ${i18n.t('app.printing.transfer')} ${toText}`
      printer.addText(text, fontSize)
    } else {
      const tableNoText = `${table}${subTable > 0 ? `(${String.fromCharCode(subTable + 64)})` : ''}`
      const text = i18n.language === 'zh-HK' ? tableNoText + tableNumberText : tableNumberText + tableNoText
      printer.addText(text, fontSize)
    }
  }

  _splitItemsByQuantity (items) {
    // for each item and each qty, add
    const toItems = []
    _.each(items, (item) => {
      // from
      const quantity = item.quantity
      for (let i = 0; i < quantity; i++) {
        // to
        const cloneItem = _.cloneDeep(item)
        cloneItem.quantity = 1
        if (item.ids) {
          cloneItem.id = item.ids[i]
        }
        if (cloneItem.prices) {
          cloneItem.total = cloneItem.prices[i]
        } else {
          cloneItem.total = cloneItem.price
        }
        cloneItem.setItems = this._splitItemsByQuantity(item.setItems)
        toItems.push(cloneItem)
      }
    })
    return toItems
  }

  /**
   *
   * @returns {string}
   */
  _generatePrintHash () {
    const shouldReprint = this.printConfig.reprint || this.printConfig.serveUp || this.printConfig.cancel
    if (shouldReprint) return this.printId
    // batch item 的 property printed 和 printError 有機會是會變的
    // 為了避免重複印單所以改為使用不會變動的 batchId 確保只會列印一次
    return hash({ bacthId: this.batch.id, printerSetting: this.printerSetting })
  }

  _generateBatchLocaleText () {
    const batchLocalei18n = this.batchLocale !== 'zh' && this.batchLocale !== 'kitchen'
      ? i18n.getFixedT('en-US')
      : i18n.getFixedT('zh-HK')

    return {
      TABLE_NUMBER: i18n.t('app.printing.tableNum'),
      CANCELED: batchLocalei18n('app.printing.cancelled'),
      SETTEXT: batchLocalei18n('app.printing.setText'),
      FOLLOWTEXT: batchLocalei18n('app.printing.follow'),
      REMARKSTEXT: batchLocalei18n('app.printing.remark'),
      NORMALOPTION: batchLocalei18n('app.printing.normal'),
      SERVELATER: batchLocalei18n('app.printing.serveLater'),
      SERVEUP: batchLocalei18n('app.printing.serveUp'),
      REPRINT: batchLocalei18n('app.printing.reprint'),
      TAKEWAY: i18n.t('app.printing.takeaway'),
      DELIVERY: i18n.t('app.printing.delivery'),
      CUSTOMERORDER: i18n.t('app.printing.customerOrder'),
    }
  }
}
