import { Platform, StyleSheet, Text, View } from 'react-native'
import React from 'react'

import { LinearProgress } from 'react-native-elements'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import CenterModal from '@/components/CenterModal'
import colors from '@/theme/colors'

const isApp = Platform.OS !== 'web'

/**
 * 用作顯示 Migration || 清理本地數據庫 || 開機大量寫入本地數據庫 的Dialog
 * @param {*} props
 * @returns
 */
export default function StorageDialog () {
  const { t } = useTranslation()
  const [visible, setVisible] = React.useState(false)
  const isShowStorageMigration = useSelector(state => state.app.dialog.storageMigration)
  const isShowClearLocalDatabase = useSelector(state => state.app.dialog.clearLocalDatabase)
  const isShowStoreLocalDatabase = useSelector(state => state.app.dialog.storeLocalDatabase)
  const progressMessage = useSelector(state => state.app.localDBInitQueuingMessage)
  const title = isShowStorageMigration ? t('app.component.storageInitDialog.storageMigration.title')
    : isShowClearLocalDatabase ? t('app.component.storageInitDialog.clearLocalDatabase.title')
      : isShowStoreLocalDatabase ? !progressMessage ? t('app.component.storageInitDialog.storeLocalDatabase.initializing.title')
        : t('app.component.storageInitDialog.storeLocalDatabase.title') : ''

  const message = isShowStorageMigration ? t('app.component.storageInitDialog.storageMigration.message')
    : isShowClearLocalDatabase ? t('app.component.storageInitDialog.clearLocalDatabase.message')
      : isShowStoreLocalDatabase ? !progressMessage ? t('app.component.storageInitDialog.storeLocalDatabase.initializing.message')
        : t('app.component.storageInitDialog.storeLocalDatabase.message') : ''
  React.useEffect(() => {
    let setVisibleTimer = ''
    // setTimeout 防止 "No native splash screen registered for given view controller. Call 'SplashScreen.show' for given view controller first."
    if (isApp && (isShowStorageMigration || isShowClearLocalDatabase || isShowStoreLocalDatabase)) {
      setVisibleTimer = setTimeout(() => setVisible(true), 500)
    } else {
      setVisible(false)
    }
    return () => clearTimeout(setVisibleTimer)
  }
  , [isShowStorageMigration, isShowClearLocalDatabase, isShowStoreLocalDatabase])

  return (
    <CenterModal
      visible={visible}
      noCloseButton
      title={title}
      enablePressOutsideClose={false}
      contentContainerStyle={styles.container}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.message}>{message}</Text>
        </View>
        <LinearProgress
          style={styles.progress}
          color={colors.lightPrimary}
          variant='indeterminate'
        />
        {isShowStoreLocalDatabase && (
          <Text style={styles.text}>{progressMessage}</Text>
        )}
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 480,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 8,
    paddingBottom: 20,
  },
  content: {
    flex: 1,
    minHeight: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 8,
    alignSelf: 'stretch',
  },
  message: {
    margin: 4,
    fontSize: 30,
    fontWeight: 'bold',
  },
  text: {
    margin: 2,
    fontSize: 16,
  },
  progress: {
    width: '90%',
    margin: 4,
  },
  error: {
    color: colors.error,
    margin: 4,
  },
})
