import { Platform } from 'react-native'
import { v4 as uuid } from 'uuid'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import LRU from 'lru-cache'
import _ from 'lodash'

import { PrinterType, PrinterUsage } from '@/constants/printer'
import { actions } from '@/redux'
import { loadingKey } from '@/constants'
import PrinterClient from '@/libs/printing/PrinterClient'
import i18n from '@/i18n'
import logger from '@/libs/logger'
import store from '@/redux/store'

import DrawerKick from './PrintDoc/DrawerKick'
import PrintDoc from './PrintDoc'
import SetLogo from './PrintDoc/SetLogo'
import TestConnect from './PrintDoc/TestConnect'
import TestPrint from './PrintDoc/TestPrint'

import GP_D801 from './printer/GP_D801'
import GPrinter from './printer/GPrinter'
import SP700 from './printer/SP700'
import TDP225 from './printer/TDP225'
import TM_T88VI from './printer/TM_T88VI'
import TM_U220B from './printer/TM_U220B'
import TP808 from './printer/TP808'
import TSP043 from './printer/TSP043'
import TSP100III from './printer/TSP100III'
import TSP100IV from './printer/TSP100IV'

const deviceId = Constants.installationId

const options = {
  max: 500,
  length: function (n, key) { return 1 },
  // dispose: function (key, n) { },
  maxAge: 1000 * 60 * 60,
}

const { dispatch } = store
class PrinterService {
  // wait 5 second for the whole print function
  MAX_PRINT_WAITING_TIME=5000
  // wait 0.5 second for the ASB status
  MAX_ASB_WAITING_TIME=500
  // FIXME: wait 1 second after error?
  MAX_CONNECTION_WAITING_TIME=1000

  /** @type {{[ip: string]: PrinterClient}} */
  _printerClients={}

  /** @type {IPrinterSetting} */
  _printerSetting={
    printers: [],
  }

  _prioritizedPrinters=[]

  // TODO: consider recovering cache from local?
  _printCache=new LRU(options)
  _printingCache=new LRU(options)
  constructor () {
    this.id = uuid()
  }

  /**
   *
   * @param {PrintDoc} printDoc
   * @param {string} printerId
   */
  createPrinterInstance (printerId) {
    const printerType = this.getPrinterType(printerId)

    switch (printerType) {
      case PrinterType.GPRINTER:
        return new GPrinter()

      case PrinterType.GP_D801:
        return new GP_D801()

      case PrinterType.TP808:
        return new TP808()

      case PrinterType.EPSON:
      case PrinterType.TM_U220B:
        return new TM_U220B()

      case PrinterType.TDP225:
        return new TDP225()

      case PrinterType.TM_T88VI:
        return new TM_T88VI()

      case PrinterType.TSP100III:
        return new TSP100III()

      case PrinterType.TSP043:
        return new TSP043()

      case PrinterType.TSP100IV:
        return new TSP100IV()

      case PrinterType.SP700:
        return new SP700()

      default:
        break
    }
  }

  /**
   * @param {string} id
   * @returns {EPrinterType}
   */
  getPrinterType (id) {
    const printerInfo = this._printerSetting.printers.find(printer => printer.id === id)
    const printerType = _.get(printerInfo, 'printerType') || PrinterType.GPRINTER
    return printerType.toUpperCase()
  }

  /**
   *
   * @param {string} type
   * @returns
   */
  getPrintType (type) {
    switch (type) {
      case PrintDoc.Types.KITCHEN_BATCH:
      case PrintDoc.Types.CUSTOMIZED_KITCHEN_BATCH:
        return PrinterUsage.KITCHEN_BATCH
      case PrintDoc.Types.REPORT:
      case PrintDoc.Types.ORDER_RECEIPT:
      case PrintDoc.Types.QRCODE:
      case PrintDoc.Types.CUSTOMIZED_ORDER_RECEIPT:
        return PrinterUsage.ORDER_RECEIPT
      default:
        break
    }
  }

  /**
   *
   * @param {string} id
   * @returns
   */
  getPrinterClient (id) {
    const client = this._printerClients[id]
    if (!client) {
      throw new Error(`Printer (${id}) not found`)
    }
    return client
  }

  /**
   *
   * @param {IPrinter} printer
  */
  setPrinterClient (printer) {
    if (printer.id in this._printerClients) {
      throw new Error('client already exist')
    }
    const newClients = { ...this._printerClients }
    newClients[printer.id] = new PrinterClient(printer)
    this._printerClients = newClients
    return this.testConnect(printer.id)
  }

  deletePrinterClient (id) {
    const c = this.getPrinterClient(id)
    const newClients = { ...this._printerClients }
    if (c) {
      c.disconnect()
      delete newClients[id]
      this._printerClients = newClients
      dispatch(actions.printer.updatePrinters(newClients))
    }
  }

  getPrinterSetting () {
    return this._printerSetting || {}
  }

  getPrioritizedPrinters () {
    return this._prioritizedPrinters || []
  }

  /**
   * update printer setting and cleanup and rebuild clients
   * @param {IPrinterSetting} printerSetting
   */
  updatePrinterSetting (printerSetting) {
    this._printerSetting = printerSetting
    this._prioritizedPrinters = _.get(printerSetting.prioritizedPrinters, deviceId, [])
    const settingPrinters = _.get(this._printerSetting, 'printers', [])
    const printersId = settingPrinters.map(p => p.id)
    // setting有, 沒有在printerClients
    const printersToBeCreated = _.differenceBy(printersId, Object.keys(this._printerClients))
    // 在printerClients, 沒有在setting
    const printersToBeRemoved = _.differenceBy(Object.keys(this._printerClients), printersId)
    // 幫新的 printer 建立 client
    _.map(printersToBeCreated, o => { this.setPrinterClient(settingPrinters.find(p => p.id === o)) })
    // 把不存在的 client 刪除
    _.map(printersToBeRemoved, o => { this.deletePrinterClient(o) })
  }

  getBackupPrinters (id, printType) {
    const settings = this.getPrinterSetting()
    if (!settings.priority) {
      return [id, id]
    }
    const type = this.getPrintType(printType)
    const printers = _(settings.priority)
      .pickBy((value, key) => {
        const printerInstance = this.createPrinterInstance(key)
        return _.isNumber(value) && printerInstance.USAGES.includes(type)
      }) // filter out non-number key and unsupported printer
      .keys()
      .sortBy([o => settings.priority[o]]) // sort by priority
      .value()
    const index = printers.indexOf(id)
    if (index === -1) {
      return [id, id].concat([...printers, ...printers])
    }
    const result = _.clone(printers)
    for (let i = 0; i < index; i++) {
      result.push(result.shift())
    }
    return result.concat([id, id, ...printers, ...printers])
  }

  /**
   *
   * @param {string} id
   * @param {PrintDoc} printDoc
  */
  async print (id, printDoc, isAutoConfirm) {
    let printerClient = this.getPrinterClient(id)
    // ==============
    // Prevent duplicate kitchenBatch hash logic
    const printHash = `${id}|${printDoc.printHash}`
    const printLogData = {
      printDocType: printDoc.TYPE,
      orderId: printDoc.order?.id ?? null,
      batchId: printDoc.batch?.batchId ?? null,
      printHash,
    }
    if ([PrintDoc.Types.ORDER_RECEIPT, PrintDoc.Types.CUSTOMIZED_ORDER_RECEIPT, PrintDoc.Types.QRCODE].includes(printDoc.TYPE)) {
      // 收據和QRCode會有 printReason，也加進 log data 中
      printLogData.printReason = printDoc.printReason
    }
    logger.log(`[PrinterService] print() called: ${printDoc.TYPE}`, printLogData)
    if (
      printDoc.TYPE === PrintDoc.Types.KITCHEN_BATCH ||
      printDoc.TYPE === PrintDoc.Types.LABEL ||
      printDoc.TYPE === PrintDoc.Types.CUSTOMIZED_KITCHEN_BATCH ||
      printDoc.TYPE === PrintDoc.Types.ORDER_RECEIPT ||
      printDoc.TYPE === PrintDoc.Types.CUSTOMIZED_ORDER_RECEIPT
    ) {
      // * 防止 Repeat Print Action
      const printing = this._printingCache.get(printHash)
      const printed = this._printCache.get(printHash)

      // * 放到 if (printing) 前, 確保可觸發
      if (printed) {
        logger.log(`[PrinterService] already printed ${printDoc.TYPE}, skipping hash: ${printHash}`, printLogData)
        // FIXME: close loading after success print
        if (printDoc.TYPE !== PrintDoc.Types.TEST_CONNECT) dispatch(actions.app.closeLoading(loadingKey.PRINT))
        return { success: true, skip: true }
      }

      if (printing) {
        logger.log(`[PrinterService] already printing ${printDoc.TYPE}, skipping hash: ${printHash}`, printLogData)
        if (printDoc.TYPE !== PrintDoc.Types.TEST_CONNECT) dispatch(actions.app.closeLoading(loadingKey.PRINT))
        return { success: true, skip: true }
      }
      this._printingCache.set(printHash, true)
      const PRINTINGHASH = _.map(this._printingCache.keys(), (key) => {
        return { key }
      })
      await AsyncStorage.setItem('PRINTINGHASH', JSON.stringify(PRINTINGHASH))
      // TODO: use uuid as hash?
    }

    // * Print Action
    // ==============
    const printers = [id]
    // TODO: backup logic
    const backupTypes = [
      PrintDoc.Types.KITCHEN_BATCH,
      PrintDoc.Types.REPORT,
      PrintDoc.Types.ORDER_RECEIPT,
      PrintDoc.Types.QRCODE,
      PrintDoc.Types.CUSTOMIZED_ORDER_RECEIPT,
      PrintDoc.Types.CUSTOMIZED_KITCHEN_BATCH,
    ]
    if (backupTypes.includes(printDoc.TYPE)) {
      printers.push(...this.getBackupPrinters(id, printDoc.TYPE))
      logger.log(`[PrinterService] ready to print ${printDoc.TYPE} in ${id}`, {
        ipAddress: id,
        ...printDoc.log(),
      })
    }
    console.log('Backup printers: ', printers)

    const start = new Date()
    let printerSuccess = false
    do {
      for (let i = 0; i < printers.length; i++) {
        try {
          printDoc.printer = this.createPrinterInstance(printers[i])
          if (i !== 0) {
            printDoc.addBackupText()
          }
          if (i === 1) {
          // FIXME: close only printer loading
            dispatch(actions.app.closeLoading(loadingKey.PRINT))
          }
          logger.log(`[PrinterService] ${printDoc.TYPE} addToQueue`, {
            trial: i,
            time: new Date() - start,
            printersLength: printers.length,
            ...printDoc.log(),
          })
          printerClient = this.getPrinterClient(printers[i])

          console.log('[PrinterService]', 'before addToQueue')
          await printerClient.addToQueue(printDoc)
          console.log('[PrinterService]', 'after addToQueue')
          if (printDoc.TYPE === PrintDoc.Types.KITCHEN_BATCH || printDoc.TYPE === PrintDoc.Types.CUSTOMIZED_KITCHEN_BATCH) {
            const items = printDoc.items
            const order = printDoc.order
            const batch = printDoc.batch
            _.each(items, async (item) => {
              dispatch(actions.orderHistory.updatePrinted(order.id, batch.id, item.id, false, true))
            })
          }
          if (printDoc.TYPE !== PrintDoc.Types.TEST_PRINT) {
            if (i !== 0) {
              logger.log(`[printer log] ${printDoc.TYPE} printed in backup printer ${printers[i]}`, printDoc.log())
            } else {
              logger.log(`[printer log] ${printDoc.TYPE} printed in printer ${printers[i]}`, printDoc.log())
            }
          }
          printerSuccess = true
          break
        } catch (error) {
          logger.error(`[PrinterService] ${printDoc.TYPE} write error: `, {
            error,
            ipAddress: printers[i],
            trial: i,
            printersLength: printers.length,
            ...printDoc.log(),
          })
          // TODO: comment the purpose here
          if ((printDoc.TYPE === PrintDoc.Types.KITCHEN_BATCH ||
            printDoc.TYPE === PrintDoc.Types.CUSTOMIZED_KITCHEN_BATCH) &&
            isAutoConfirm) {
            logger.error(`[AutoConfirm] ${printDoc?.order.serial} Error: `, {
              reason: 'Printer issue',
              error,
              orderId: printDoc.order.id,
              batchId: printDoc.batch.batchId,
              batchIndex: printDoc.batch.index,
            })
          }
          // TODO: comment the purpose here
          if (printDoc.TYPE === PrintDoc.Types.KITCHEN_BATCH || printDoc.TYPE === PrintDoc.Types.CUSTOMIZED_KITCHEN_BATCH) {
            const items = printDoc.items
            const order = printDoc.order
            const batch = printDoc.batch
            _.each(items, async (item) => {
              dispatch(actions.orderHistory.updatePrinted(order.id, batch.id, item.id, true, false))
            })
          }
          // try backup printer, and quit if final trial
          if (i === printers.length - 1) {
            console.error(`[service.print] failed to print on printer:${printers[i]}, no more trial`)
            if (!isAutoConfirm) {
              if (Platform.OS === 'web') {
                dispatch(actions.app.showSimpleAlert(i18n.t('app.common.error')))
              } else {
                if (error?.message === 'NOT_SUPPORTED') {
                  // not supported snackbar
                  dispatch(actions.app.enqueueSnackbar({
                    text: i18n.t('app.page.setting.printer.notSupported', {
                      printerModel: printDoc.printer.TYPE,
                      printDocType: i18n.t(`app.page.setting.printer.printDoc.${printDoc.TYPE}`),
                    }),
                    duration: 5000,
                  }))
                } else {
                  dispatch(actions.app.enqueueSnackbar({
                    text: i18n.t('app.page.setting.printer.errorMsg3'),
                    duration: 5000,
                  }))
                }
              }
            } else {
              continue
            }
            if (printDoc.TYPE !== PrintDoc.Types.TEST_CONNECT) dispatch(actions.app.closeLoading(loadingKey.PRINT))
            return { success: false, error }
          } else {
            // try next backup printer
            console.error(`[service.print] failed to print on printer:${printers[i]}, next trial on printer:${printers[i + 1]}`)
          }
        }
      }
    // eslint-disable-next-line no-unmodified-loop-condition
    } while (!printerSuccess && isAutoConfirm)
    // FIXME: close loading after success print
    if (printDoc.TYPE !== PrintDoc.Types.TEST_CONNECT) dispatch(actions.app.closeLoading(loadingKey.PRINT))
    if (printDoc.TYPE === PrintDoc.Types.KITCHEN_BATCH ||
      printDoc.TYPE === PrintDoc.Types.LABEL ||
      printDoc.TYPE === PrintDoc.Types.CUSTOMIZED_KITCHEN_BATCH ||
      printDoc.TYPE === PrintDoc.Types.ORDER_RECEIPT ||
      printDoc.TYPE === PrintDoc.Types.CUSTOMIZED_ORDER_RECEIPT
    ) {
      this._printCache.set(printHash, true)
      const PRINTHASH = _.map(this._printCache.keys(), (key) => {
        return { key }
      })
      await AsyncStorage.setItem('PRINTHASH', JSON.stringify(PRINTHASH))
    }
    return { success: true }
  }

  disconnectPrinter (id) {
    const printerClient = this.getPrinterClient(id)
    printerClient.disconnect()
  }

  async testConnect (id) {
    return this.print(id, new TestConnect())
  }

  async printTest (id) {
    return this.print(id, new TestPrint())
  }

  /**
   * @deprecated 不再使用 SetLogo
   * @param {string} id
   * @returns
   */
  async setLogo (id) {
    return this.print(id, new SetLogo())
  }

  // FIXME: kick by device
  async drawerKick () {
    const { invoiceSettings } = this.getPrinterSetting()
    const prioritizedPrinters = this.getPrioritizedPrinters()
    const printers = prioritizedPrinters?.length
      ? prioritizedPrinters
      : _(invoiceSettings)
        .flatMapDeep('printer')
        .uniq()
        .value()
    return Promise.all(printers.map(id => {
      return this.print(id, new DrawerKick())
    }))
  }

  setPrintCache (caches) {
    _.forEach(caches, (cache) => {
      if (cache.key) { this._printCache.set(cache.key, true) }
    })
  }

  setPrintingCache (printingCaches) {
    _.forEach(printingCaches, (printingCache) => {
      if (printingCache.key) { this._printingCache.set(printingCache.key, true) }
    })
  }
}

const service = new PrinterService()

export default service
