import { Icon } from 'react-native-elements'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import ActionButton from '@/pages/OrderHistory/OrderDetail/ActionButton'
import Header from '@/components/Setting/Header'
import HeaderRightAction from '@/components/Setting/HeaderRightAction'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import ToggleSwitch from '@/components/ToggleSwitch'

import ComboGroup from './ComboGroup'
import ComboRuleRow from './ComboRuleRow'
import EditPage from './EditPage'

const widths = [140, 190, 190, 170]

/**
 *
 * @param {*} props
 * @returns
 */
export default function ComboRulePanel (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedRule, setSelectedRule] = React.useState(null)
  const [editGroup, setEditGroup] = React.useState(false)
  const [multiDelete, setMultiDelete] = React.useState(false)
  const rules = useSelector(state => state.merchant.data.comboRule.rules) || []
  const groups = useSelector(state => state.merchant.data.comboRule.groups) || []
  const weekdayText = [
    t('app.constant.weekday.sun'),
    t('app.constant.weekday.mon'),
    t('app.constant.weekday.tue'),
    t('app.constant.weekday.wed'),
    t('app.constant.weekday.thur'),
    t('app.constant.weekday.fri'),
    t('app.constant.weekday.sat'),
  ]

  if (editGroup) {
    return (
      <ComboGroup
        onClose={() => setEditGroup(false)}
      />
    )
  }

  const onDelete = (id) => {
    const updatedRules = _.remove([...rules], r => r.id !== id)
    dispatch(actions.merchant.updateComboRule({ groups, rules: updatedRules }))
    setSelectedRule(null)
  }

  if (selectedRule) {
    return (
      <EditPage
        rule={selectedRule}
        onClose={() => setSelectedRule(null)}
        onDelete={() => onDelete(selectedRule.id)}
      />
    )
  }

  const renderItem = ({ item, index, drag, isActive }) => {
    const mandatoryItems = _.map(item.mandatoryItems, r => {
      const group = _.find(groups, g => g.id === r.groupId)
      return `${r.match > 1 ? `${r.match}x` : ''}${group?.name}`
    }).join('+')
    const discountedItems = _.map(item.discountedItems, m => {
      const group = _.find(groups, g => g.id === m.groupId)
      const modifier = m.modifier
      let discountText = ''
      if (modifier.type === 'DISCOUNT') {
        discountText = modifier.percent > 0
          ? `(-${modifier.percent}%)`
          : `(-$${modifier.amount})`
      } else if (modifier.amount > 0) {
        discountText = `(+$${modifier.amount})`
      }
      return `${m.match > 1 ? `${m.match}x` : ''}${group?.name}${discountText}`
    }).join()
    const startTime = `${_.padStart(item.startTime.hour, 2, '0')}:${_.padStart(item.startTime.minute, 2, '0')}`
    const endTime = `${_.padStart(item.endTime.hour, 2, '0')}:${_.padStart(item.endTime.mintue, 2, '0')}`
    const weekdays = _.map(item.weekdays, w => weekdayText[Number(w)]).join(', ')

    return (
      <SettingListItem
        draggable
        dragging={isActive}
        onDrag={drag}
        style={{ paddingRight: 10 }}
        itemStyle={{ paddingRight: 0 }}
        divider
      >
        <ComboRuleRow
          row={[
            item.name,
            mandatoryItems,
            discountedItems,
            `${weekdays}\n${startTime}-${endTime}`,
          ]}
          widths={widths}
        />
        <ToggleSwitch
          value={item.enabled}
          onChangeValue={() => {
            const updatedRules = [...rules]
            updatedRules[index] = {
              ...updatedRules[index],
              enabled: !updatedRules[index].enabled,
            }
            dispatch(actions.merchant.updateComboRule({ groups, rules: updatedRules }))
          }}
          size={23}
        />
        {
          multiDelete
            ? (
              <TouchableOpacity
                onPress={() => onDelete(item.id)}
              >
                <Icon
                  size={35}
                  name='delete'
                  color={colors.red}
                />
              </TouchableOpacity>
            )
            : (
              <TouchableOpacity
                onPress={() => setSelectedRule(item)}
              >
                <Icon
                  size={35}
                  name='edit'
                  color={colors.green}
                />
              </TouchableOpacity>
            )
        }
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>
      <Header>
        <HeaderText text={t('app.routes.setting.order.comboRule')} />
        <HeaderRightAction>
          <ActionButton
            text={t('app.routes.setting.order.comboRulesSetting.autoGroup')}
            buttonStyle={styles.button}
            backgroundColor={colors.status8}
            onPress={() => {
              dispatch(actions.app.showAlert({
                title: t('app.routes.setting.order.comboRulesSetting.convertTitle'),
                message: t('app.routes.setting.order.comboRulesSetting.convertMsg'),
                buttons: [
                  {
                    children: t('app.page.order.stash.alert.leave.button.continue'),
                    onPress: () => dispatch(actions.merchant.convertSetToComboGroup()),
                  },
                  {
                    backgroundColor: colors.light,
                    textColor: colors.textTertiary,
                    children: t('app.page.order.stash.alert.leave.button.cancel'),
                    onPress: () => {},
                  },
                ],
              }))
            }}
          />
          {
            multiDelete
              ? (
                <ActionButton
                  text={t('app.common.cancel')}
                  buttonStyle={styles.button}
                  backgroundColor={colors.lightPrimary}
                  onPress={() => setMultiDelete(false)}
                />
              )
              : (
                <ActionButton
                  text={t('app.common.delete')}
                  buttonStyle={styles.button}
                  backgroundColor={colors.primary}
                  onPress={() => setMultiDelete(true)}
                />
              )
          }
          <ActionButton
            text={t('app.routes.setting.order.comboRulesSetting.group')}
            buttonStyle={styles.button}
            backgroundColor={colors.tertiary}
            onPress={() => setEditGroup(true)}
          />
          <ActionButton
            text={t('app.routes.setting.order.comboRulesSetting.add')}
            buttonStyle={styles.button}
            backgroundColor={colors.secondary}
            onPress={() => setSelectedRule(defaultSetting)}
          />
        </HeaderRightAction>
      </Header>
      <SettingList style={{ flex: 1 }}>
        <SettingListItem
          style={{ backgroundColor: colors.primary }}
          draggable
          dragDisabled
          divider
        >
          <ComboRuleRow
            widths={widths}
            row={[
              t('app.page.setting.menuPanel.menuInfo.name'),
              t('app.routes.setting.order.comboRulesSetting.mandatoryItems'),
              t('app.routes.setting.order.comboRulesSetting.discountedItems'),
              t('app.page.setting.menuPanel.menuInfo.time'),
            ]}
            isTitle
          />
        </SettingListItem>
        <DraggableFlatList
          data={rules}
          renderItem={renderItem}
          keyExtractor={(item, index) => String(item.id + index)}
          showsVerticalScrollIndicator={false}
          onDragEnd={({ data }) => dispatch(actions.merchant.updateComboRule({ groups, rules: data }))}
        />
      </SettingList>
    </View>

  )
}

const defaultSetting = {
  name: '',
  mandatoryItems: [],
  discountedItems: [],
  weekdays: [],
  startTime: {
    hour: 0,
    minute: 0,
  },
  endTime: {
    hour: 0,
    minute: 0,
  },
  enabled: true,
  applyTo: ['merchant'],
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    height: 36,
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 8,
    paddingHorizontal: 4,
    marginTop: 5,
    marginRight: 12,
  },
  cell: {
    padding: 5,
    borderLeftWidth: 1,
    borderLeftColor: colors.lightGray2,
  },
})
