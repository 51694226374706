import { StyleSheet, View } from 'react-native'
import React, { useState } from 'react'

import { actions, useDispatch } from '@/redux'
import { ecrGateway } from '@/constants'
import { useSelectedOrder } from '@/hooks/orderHistory'
import CancelPaymentsAndUnlockOrderBanner from '@/components/CancelPaymentsAndUnlockOrderBanner'
import CancelPaymentsAndUnlockOrderDialog from '@root/src/components/dialogs/CancelPaymentsAndUnlockOrderDialog'
import Column from '@/components/Column'
import PriceSummary from '@/components/PriceSummary'
import colors from '@/theme/colors'

import ActionPanel from './ActionPanel'
import BatchList from './BatchList'
import Header from './Header'

export default function OrderDetail () {
  const dispatch = useDispatch()
  const selectedOrder = useSelectedOrder()
  const [isCancelPaymentDialogOpen, setIsCancelPaymentDialogOpen] = useState(false)

  // 客人使用DimPay付款時，會顯示解鎖和取消付款提示
  const showCancelPaymentsAndUnlockOrderBanner =
    selectedOrder?.status === 'pending' &&
    selectedOrder?.payments.some(p => p.customerId && p.status !== 'cancel')

  return (
    <Column style={styles.container}>
      {/** 確保選擇訂單時，Header 會被 Reset，讓裡面的 state 會拿到新的 remark */}
      {selectedOrder ? <Header key={selectedOrder.id} /> : <View style={styles.header} />}
      {/* <OrderItemList /> */}
      {showCancelPaymentsAndUnlockOrderBanner && (
        <CancelPaymentsAndUnlockOrderBanner setIsCancelPaymentDialogOpen={setIsCancelPaymentDialogOpen} />
      )}
      <BatchList />
      <PriceSummary showTotal order={selectedOrder} />
      <ActionPanel />
      <CancelPaymentsAndUnlockOrderDialog
        open={isCancelPaymentDialogOpen}
        onClose={() => setIsCancelPaymentDialogOpen(false)}
        onSubmit={() => {
          selectedOrder.payments.forEach((payment) => {
            if (payment.status !== 'cancel') {
              if (Object.values(ecrGateway).includes(payment.gateway)) {
                dispatch(actions.orderHistory.voidCardPayment(selectedOrder.id, payment, 'unlock order', false, false))
              } else {
                dispatch(actions.orderHistory.voidOrderPayment(selectedOrder.id, payment, 'unlock order'))
              }
            }
          })
        }}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.light,
  },
  header: {
    height: 122,
    backgroundColor: colors.lightPrimary,
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  whiteText: {
    color: colors.white,
  },
  customerIsPayingBanner: {
    padding: 8,
    backgroundColor: colors.secondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
