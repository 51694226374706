import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { currencyWithCommas, numberWithCommas } from '@root/src/libs/numberWithCommas'
import { useSelector } from '@/redux'
import Column from '@/components/Column'
import React from 'react'
import colors from '@/theme/colors'
import orderAppLib from 'dimorder-orderapp-lib'

export default function AmountButton ({
  title,
  amount,
  checked = false,
  disabled = false,
  onPress,
}) {
  const buttonStyle = {
    backgroundColor:
      amount < 0
        ? colors.secondary
        : checked
          ? colors.lightPrimary : colors.white,
    opacity: disabled ? 0.4 : 1,
  }

  const textStyle = {
    color:
      amount < 0 || checked ? colors.white
        : colors.textPrimary,
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <Column style={[styles.container, buttonStyle]}>
        <Text style={[styles.text, textStyle]}>
          {title}
        </Text>
        <Text style={[styles.priceText, textStyle]}>
          {currencyWithCommas(Number(amount))}
        </Text>
      </Column>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 84,
    width: 158,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  text: {
    fontSize: 18,
    lineHeight: 27,
    fontWeight: 'bold',
  },
  priceText: {
    fontSize: 24,
    lineHeight: 36,
    fontWeight: 'bold',
  },
})
