import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IUnsyncOrderState} */
export const initialState = {
  pendingOrderIds: [],
  syncingOrderId: null,
  isDialogOpen: false,
  isSyncingData: false,
}

export default produce(
  /**
   * @param {IUnsyncOrderState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.RESTORE_UNSYNC_ORDER_IDS: {
        const { orderIds } = action.payload
        draft.pendingOrderIds = orderIds
        break
      }

      case ActionTypes.ADD_UNSYNC_ORDER_IDS: {
        const { orderIds } = action.payload
        const unsyncOrderIds = draft.pendingOrderIds
        const updatedOrderIds = _.chain(unsyncOrderIds).concat(orderIds).uniq().value()
        draft.pendingOrderIds = updatedOrderIds
        break
      }

      case ActionTypes.REMOVE_UNSYNC_ORDER_IDS: {
        const { orderIds } = action.payload
        const unsyncOrderIds = draft.pendingOrderIds
        const updatedOrderIds = _.chain(unsyncOrderIds).xor(orderIds).value()
        draft.pendingOrderIds = updatedOrderIds
        break
      }

      case ActionTypes.UPDATE_SYNCING_ORDER_ID: {
        const { syncingOrderId } = action.payload
        draft.syncingOrderId = syncingOrderId
        break
      }

      case ActionTypes.UPDATE_DIALOG_OPEN: {
        const { open } = action.payload
        draft.isDialogOpen = open
        break
      }

      case ActionTypes.UPDATE_IS_SYNCING_DATA: {
        const { isSyncingData } = action.payload
        draft.isSyncingData = isSyncingData
        break
      }

      default:
        return draft
    }
  },
  initialState,
)
