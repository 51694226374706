import { FlatList, StyleSheet, Text, View } from 'react-native'
import React, { useState } from 'react'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import DepartmentInfo from './DepartmentInfo'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import _ from 'lodash'

export default function DepartmentPanel () {
  const { t } = useTranslation()
  const departments = useSelector(state => state.menu.departments)
  const [selectedDepartment, setSelectedDepartment] = useState(null)

  const renderItem = ({ item: department, index }) => {
    return (
      <SettingListItem
        key={department.id}
        onPress={() => {
          setSelectedDepartment(department)
        }}
        divider={index !== departments.length - 1}
      >
        <SettingListItemText text={department.name || department.id} />
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.department.title')} />
        </Header>
        <View style={styles.content}>
          <SettingList>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={departments}
              ListEmptyComponent={(
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.department.noSetting')}</Text>
                </View>
              )}
              keyExtractor={(item) => item.id}
              renderItem={renderItem}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.department.add')}
              onPress={() => {
                setSelectedDepartment({
                  name: '',
                })
              }}
            />
          </SettingList>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <DepartmentInfo
          key={selectedDepartment?.id + selectedDepartment?.name}
          department={selectedDepartment}
          onClose={() => setSelectedDepartment(null)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labels: {
    padding: 0,
  },
})
