import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IConfigState} */
export const initialState = {
  env: null,
  deployment: null,
  wssUrl: null,
  apiUrls: {
    go: null,
    node: null,
    urlShortener: null,
  },
  clientUrls: {
    merchantFrontend: null,
    customerApp: null,
    customerWeb: null,
  },
}

export default produce(
  /**
   * @param {IConfigState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.UPDATE_CONFIGS: {
        const { env, configs } = action.payload
        draft.env = env
        draft.wssUrl = configs.wssUrl
        draft.apiUrls = configs.apiUrls
        draft.clientUrls = configs.clientUrls
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
