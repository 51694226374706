import _ from 'lodash'
import hash from 'object-hash'
import moment from 'moment'
import shortid from 'shortid'

import { PrintStyle } from '@/constants/printing'
import { paymentMethods as constPaymentMethods, ecrGateway } from '@/constants'
import i18n from '@/i18n'
import store from '@/redux/store'

import { PrinterFontSize } from '@/constants/printer'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { getDisplayCustomerName } from '../../order'
import { getPaymentMethod } from '@/libs/merchant'
import PrintDoc from '@/libs/printing/PrintDoc'

/* eslint-disable no-unused-vars */
import { IAppBatchItemSummary, IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IMerchant } from 'dimorder-orderapp-lib/dist/types/Merchant'
/* eslint-enable no-unused-vars */

/** @type {() => IRootState} */
const getState = store.getState
export default class OrderReceipt extends PrintDoc {
  TYPE = PrintDoc.Types.ORDER_RECEIPT

  static TEXT = {
    TYPETEXT: {
      TAKEAWAY: i18n.t('app.printing.takeawayTitle'),
      storedelivery: i18n.t('app.printing.sdeliveryTitle'),
    },
    CANCELED: i18n.t('app.printing.cancelled'),
    FOLLOWTEXT: i18n.t('app.printing.follow'),
    REMARKSTEXT: i18n.t('app.printing.remark'),
    NORMALOPTION: i18n.t('app.printing.normal'),
  }

  /**
   *
   * @param {IMerchant} merchant
   * @param {IAppBatchItemSummary[]} summaryItems 經 getLocalizedReceiptSummary 取得特定語言的 item.name, setItem.name, option.name 後的 summary
   * @param {IAppOrder} order
   * @param {IPrinterDefaultSetting | IInvoiceSetting} printerSetting
   * @param {TCancelledType?} cancelledType
   * @param {IPrinterConfig?} printConfig
   * @param {TOrderReceiptPrintReason?} printReason
   *
   * @typedef IPrinterConfig
   * @property {boolean?} reprint
   * @property {number?} orderSerialDigit
   */
  constructor (
    summaryItems,
    merchant,
    order,
    printerSetting,
    cancelledType,
    printConfig,
    printReason,
  ) {
    super()
    this.summaryItems = summaryItems
    this.merchant = merchant
    this.order = order
    this.printerSetting = printerSetting
    this.cancelledType = cancelledType
    this.reprint = printConfig?.reprint ?? false
    this.printReason = printReason
    this.orderSerialDigit = printConfig?.orderSerialDigit ?? 3
    this.printId = shortid.generate()
    this.discountItem = []
    this.subtotal = 0
    this.locale = printConfig?.locale // 'zh' | 'en' | undefined
    this.printHash = this._generatePrintHash()
  }

  /**
   * 翻譯文字
   * 如果 printConfig.locale 有指定語言時更新 i18n 並使用指定語言進行翻譯
   * @param {string} key
   * @returns
   */
  t (key) {
    if (this.locale === 'zh' || this.locale === 'en') {
      const t = this.locale === 'zh'
        ? i18n.getFixedT('zh-HK')
        : i18n.getFixedT('en-US')
      return t(key)
    }
    return i18n.t(key)
  }

  print = () => {
    const { status } = this.order
    const { printTimes } = this.printerSetting
    const payments = this.order.status === 'paid' ? this.order.payments : []

    if (status === 'paid') {
      this.addOrderReceiptHeader()
      this.addOrderReceiptItem()
      this.addOrderReceiptFooter(payments)
    } else {
      this.addOrderReceiptHeader()
      this.addOrderReceiptItem()
      this.addOrderReceiptFooter()
    }

    if (printTimes > 0) {
      this._addCopy(printTimes)
    }

    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  _addCopy = (printTimes) => {
    const data = Array.from(this.printer.getCommandBuffer())
    const printer = this.printer

    for (let i = 0; i < printTimes; i++) {
      printer.addCommand(data)
    }
  }

  /*
                (Merchant Name)
              (Merchant Address)

  --------------------------------------------
  (Table Number)
  (Receipt Number)
  (Order Time)
  (printing Time)
  --------------------------------------------
  */
  addOrderReceiptHeader = () => {
    const {
      table,
      serial,
      createdAt,
      deliveryType,
      shipping,
      pickupAt,
      phone,
      name,
      address,
      dropoffAt,
      takeaway,
      customers,
      subTable,
      remark,
      customerNameSuffix,
    } = this.order

    const {
      name: merchantName,
      address: merchantAddress,
      contact: merchantPhone,
      setting: {
        largerSerialInReceipt,
        printCustomerCountOnReceipt,
        hideReceiptAddress,
        receiptHideCustomerPhone,
        quickMode,
      },
    } = this.merchant
    const { customerReceiptStyleText } = this.printerSetting

    const printer = this.printer

    printer.addInit()
    printer.addCodePage()
    printer.addAlign('center')
    // await printer.addImage()
    if (this.cancelledType) {
      const text = this.t('app.printing.cancelReceipt.' + this.cancelledType)
      printer.addBoldMode()
      printer.addText(text, PrinterFontSize.LARGE).addRowFeed(2)
      printer.addBoldMode(false)
    }

    if (!table) {
      printer.addAlign('center')
      let typeText = this.t('app.printing.takeawayTitle')
      if (deliveryType === 'storeDelivery') {
        typeText = this.t('app.printing.sdeliveryTitle')
      }

      if (!quickMode || this.order.from !== 'MERCHANT') {
        printer.addText(typeText, PrinterFontSize.LARGE).addRowFeed(2)
        printer.addAlign('left')
      }

      if (quickMode && this.order.from === 'MERCHANT') {
        printer.addAlign('center')
        printer.addText(`${serial.slice(-this.orderSerialDigit)}`, PrinterFontSize.LARGE).addRowFeed(2)
      }

      if (shipping && _.get(shipping, 'lalamove.scheduleAt')) {
        printer.addAlign('center')
        printer.addText(
          this.t('app.printing.driver') +
          ': ',
          PrinterFontSize.MEDIUM,
        )
        printer.addRowFeed(1)
        printer.addText(
          moment(shipping.lalamove.scheduleAt).add(10, 'm').format('DD/MM HH:mm'),
          PrinterFontSize.MEDIUM,
        )
        printer.addRowFeed(2)
      }

      if (!quickMode || this.order.from !== 'MERCHANT') {
        printer.addAlign('center')
        printer.addText(
          this.t('app.printing.customerPickup') + ': ',
          PrinterFontSize.MEDIUM,
        )

        printer.addRowFeed(1)
        printer.addText(
          moment(pickupAt).format('DD/MM HH:mm'),
          PrinterFontSize.MEDIUM,
        )

        printer.addRowFeed(2)
      }
    }
    // 商店名稱
    if (quickMode && this.order.from === 'MERCHANT') {
      printer.addRowFeed(1)
    } else {
      printer.addRowFeed(2)
    }
    printer.addAlign('center')
    printer.addBoldMode(true)
    printer.addText(merchantName, PrinterFontSize.MEDIUM)
    printer.addRowFeed(2)

    printer.addBoldMode(false)
    printer.addText(merchantAddress, customerReceiptStyleText)
    printer.addRowFeed()
    printer.addText(`${this.t('app.printing.phone')}: ${merchantPhone}`, customerReceiptStyleText)
    printer.addRowFeed()
    printer.addSeparator()
    printer.addAlign('left')

    // 檯號
    printer.addBoldMode(true)
    if (takeaway) {
      if (!quickMode) {
        printer.addText(this.t('app.printing.takeaway'), PrinterFontSize.MEDIUM)
      }
    } else {
      printer.addText(`${this.t('app.printing.table')}: ${table}${subTable > 0 ? `(${String.fromCharCode(subTable + 64)})` : ''}`, PrinterFontSize.MEDIUM)
    }

    if (printCustomerCountOnReceipt && !_.isNil(table) && !_.isNil(customers) && !quickMode) {
      printer.addText(`  ${this.t('app.printing.customers')}: ${customers}`, PrinterFontSize.MEDIUM)
    }

    printer.addBoldMode(false)
    if (!quickMode) {
      printer.addRowFeed(2)
    }
    // 收據
    let serialTextStyle = customerReceiptStyleText
    if (largerSerialInReceipt) {
      serialTextStyle = PrinterFontSize.LARGE
    }

    printer.addText(`${this.t('app.printing.receipt')}: ${serialTextStyle === PrinterFontSize.LARGE ? '\n' : ''}${serial}`, serialTextStyle)
    printer.addRowFeed(2)
    // 落單時間
    printer.addText(
      `${this.t('app.constant.order.createAt')}: ${moment(createdAt).format('YYYY/MM/DD HH:mm:ss')}`,
      customerReceiptStyleText,
    )
    printer.addRowFeed()
    // 打印時間
    printer.addText(
      `${this.t('app.printing.qrcode.time')}: ${moment().format('YYYY/MM/DD HH:mm:ss')}`,
      customerReceiptStyleText,
    )

    // 客人電話
    if (!_.isEmpty(phone) && !receiptHideCustomerPhone) {
      printer.addRowFeed()

      printer.addText(`${this.t('app.printing.customerPhone')}: ${phone}`, customerReceiptStyleText)
    }

    // 收件人姓名
    if (!_.isEmpty(name)) {
      printer.addRowFeed()
      printer.addText(`${this.t('app.printing.name')}: ${getDisplayCustomerName(name, customerNameSuffix)}`, customerReceiptStyleText)
    }

    // 送餐地址
    if (!_.isEmpty(address) && !hideReceiptAddress && deliveryType === 'storeDelivery') {
      printer.addRowFeed()

      printer.addText(`${this.t('app.printing.address')}: ${address}`, customerReceiptStyleText)
    }

    // 取貨時間 / 取餐時間
    if (!_.isEmpty(pickupAt)) {
      printer.addRowFeed()
      printer.addText(
        deliveryType === 'shop'
          ? `${this.t('app.printing.shopPickup')}: `
          : `${this.t('app.printing.pickupAt')}: ` + moment(pickupAt).format('YYYY/MM/DD HH:mm'),
        customerReceiptStyleText,
      )
    }

    // 收件時間
    if (!_.isEmpty(dropoffAt)) {
      printer.addRowFeed()
      printer.addText(
        `${this.t('app.printing.dropoffAt')}: ` + moment(dropoffAt).format('YYYY/MM/DD HH:mm'),
        customerReceiptStyleText,
      )
    }

    if (remark.trim()) {
      printer.addRowFeed()
      printer.addText(`${this.t('app.constant.order.orderRemark')}: ` + remark, customerReceiptStyleText)
    }

    printer.addSeparator()
  }

  /*
  ITEM NAME                 QUANTITY    PRICE
  --------------------------------------------
  (item name)              (quantity)  (price)
  --------------------------------------------
  */
  addOrderReceiptItem = (payment = {}) => {
    const { customerReceiptStyleText, lineSpacing } = this.printerSetting
    const printer = this.printer
    const { itemWidths } = printer.getReceiptTextWidth(customerReceiptStyleText)
    const { receiptHideFreeItem } = this.merchant.setting

    const HEADER = [
      this.t('app.printing.item'),
      this.t('app.printing.amount'),
      this.t('app.printing.price'),
    ]
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH]
    printer.addSetTabPosition([itemWidths[0], itemWidths[0] + itemWidths[1]])
    printer.addFixedRow(HEADER, itemWidths, customerReceiptStyleText, 1, align)
    printer.addUnderline()

    const filteredItems = this.summaryItems.filter(o => !(
      receiptHideFreeItem &&
        o.price === 0 &&
        o.items.length === 0
    ))

    _.each(filteredItems, (item, index) => {
      const itemRowText = [
        item.name,
        String(item.quantity),
        currencyWithCommas(item.price),
      ]
      printer.addFixedRow(itemRowText, itemWidths, customerReceiptStyleText, 1, align)
      if (!_.isEmpty(item.options)) {
        this._addOptionText(item.options)
      }
      if (!_.isEmpty(item.remarks)) {
        this._addRemarksText(item.remarks)
      }
      if (item.items?.length > 0) {
        const followText = '+'
        const setItemWidths = [3, itemWidths[0] - 3, itemWidths[1], itemWidths[2]]
        // ? 整理 summary 的時候把 setItems 改名叫 items 然後用的時候又改回 setItems 幹麻？
        const setItems = item.items.filter(i => !i.cancelled)
        _.each(setItems, (setItem) => {
          printer.addRowFeed()
          const setItemsRowText = [
            followText,
            setItem.name,
            String(setItem.quantity),
            '',
          ]
          printer.addFixedRow(setItemsRowText, setItemWidths, customerReceiptStyleText, 1, [PrintStyle.ALIGN.LEFT, ...align])

          if (!_.isEmpty(setItem.options)) {
            this._addOptionText(setItem.options)
          }

          if (!_.isEmpty(setItem.remarks)) {
            this._addRemarksText(setItem.remarks)
          }
        })
      }
      if (lineSpacing && index !== filteredItems?.length - 1) {
        printer.addRowFeed()
      }
    })
  }

  /*
                          SUBTOTAL   (subtotal)
                          DISCOUNT   (discount)
                        SERVICEFEE  (service fee)

                          TOTAL         (total)
  */
  addOrderReceiptFooter = (payments = {}) => {
    const { customerReceiptStyleText } = this.printerSetting
    const printer = this.printer
    const {
      deliveryType,
      takeaway,
      needTableware,
      status,
      modifiers,
      subtotal,
      shipping,
      roundedTotal,
      calculatedDiscountTotal: discountTotal,
      batches,
      roundedAmount,
    } = this.order

    const {
      setting: {
        quickMode,
        showRoundingInReceipt,
      },
    } = this.merchant
    const discountIndex = _.findIndex(modifiers, (modifier) => modifier.type === 'DISCOUNT' && modifier.calculatedAmount !== 0 && modifier.deletedAt == null)
    const surchargeIndex = _.findIndex(modifiers, (modifier) => modifier.type === 'SURCHARGE' && modifier.calculatedAmount !== 0 && modifier.percent !== 0 && modifier.deletedAt == null)
    const promocodeIndex = _.findIndex(modifiers, (modifier) => modifier.type === 'PROMOCODE' && modifier.calculatedAmount !== 0 && modifier.deletedAt == null)
    const ricecoinIndex = _.findIndex(modifiers, (modifier) => modifier.type === 'RICECOIN' && modifier.calculatedAmount !== 0 && modifier.deletedAt == null)
    const allItems = batches.map(b => b.items).reduce((acc, items) => acc.concat(items), [])
    const defaultSettings = getState().printer?.printerSetting?.defaultSettings
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH]
    const minOrderAmount = _.get(shipping, 'discounts.0.minOrderAmount', 0)
    const shippingDiscount = Math.min(subtotal >= minOrderAmount ? _.get(shipping, 'discounts.0.amount', 0) : 0, _.get(shipping, 'customerAmount', 0))
    const calculatedDiscountTotal = -(Math.abs(discountTotal || 0) - Math.abs(shippingDiscount))
    // const roundText = {
    //   off: this.translate('app.printing.roundOff'),
    //   up: this.translate('app.printing.roundUp'),
    //   down: this.translate('app.printing.roundDown'),
    // }
    const {
      setting: { footerCustomText },
    } = this.merchant
    const { subtotalWidths } = printer.getReceiptTextWidth(customerReceiptStyleText)
    printer.addSetTabPosition([
      subtotalWidths[0],
      subtotalWidths[0] + subtotalWidths[1],
    ])
    printer.addSeparator()
    // 小計
    const subtotalRowText = [
      this.t('app.printing.subtotal'),
      null,
      currencyWithCommas(subtotal),
    ]
    printer.addFixedRow(
      subtotalRowText,
      subtotalWidths,
      customerReceiptStyleText,
      1,
      align,
    )

    if (
      Boolean(calculatedDiscountTotal) ||
      promocodeIndex > -1 ||
      ricecoinIndex > -1 ||
      discountIndex > -1
    ) {
      const discountTitleText = [
        `${this.t('app.printing.promo')}`,
        null,
        null,
      ]
      printer.addFixedRow(
        discountTitleText,
        subtotalWidths,
        customerReceiptStyleText,
        1,
        align,
      )
    }

    const discountWidths = [2, subtotalWidths[0], subtotalWidths[1] + subtotalWidths[2] - 2]
    allItems.some(item => {
      if (item.cancelled) {
        return false
      }
      const discountModifier = item.modifiers.find(m => m.type === 'DISCOUNT' && m.deletedAt == null)
      if (discountModifier && discountModifier.calculatedAmount !== 0) {
        let discountText = this.t('app.printing.itemDiscount')
        if (discountModifier.custom) {
          discountText = discountModifier.customName
        }
        if (discountModifier.id === 'FREE') {
          discountText = this.t('app.printing.free')
        }
        const itemRowText = [
          null,
          `${discountText}: ${item.name}`,
          currencyWithCommas(discountModifier.calculatedAmount),
        ]
        for (let i = 0; i < _.get(item, 'quantity', 1); i++) {
          printer.addFixedRow(itemRowText, discountWidths, customerReceiptStyleText, 1, align)
        }
      }
      if (item.isSet) {
        item.setItems.some(setItem => {
          if (setItem.cancelled) {
            return false
          }
          const setDiscountModifier = setItem.modifiers.find(m => m.type === 'DISCOUNT' && m.deletedAt == null)
          if (setDiscountModifier && setDiscountModifier.calculatedAmount !== 0) {
            let discountText = this.t('app.printing.itemDiscount')
            if (setDiscountModifier.custom) {
              discountText = setDiscountModifier.customName
            }
            if (setDiscountModifier.id === 'FREE') {
              discountText = this.t('app.printing.free')
            }
            const itemRowText = [
              null,
              `${discountText}: ${setItem.name}`,
              currencyWithCommas(setDiscountModifier.calculatedAmount),
            ]
            for (let i = 0; i < _.get(setItem, 'quantity', 1); i++) {
              printer.addFixedRow(itemRowText, discountWidths, customerReceiptStyleText, 1, align)
            }
          }
        })
      }
    })

    if (promocodeIndex >= 0) {
      const code = modifiers[promocodeIndex]?.code
      const promoName = modifiers[promocodeIndex]?.name
      const promocodeRowText = [
        null,
        promoName || code,
        currencyWithCommas(modifiers[promocodeIndex]?.calculatedAmount),
      ]
      printer.addFixedRow(
        promocodeRowText,
        discountWidths,
        customerReceiptStyleText,
        1,
        align,
      )
    }
    if (ricecoinIndex >= 0) {
      const ricecoinRowText = [
        null,
        'RICECOIN',
        currencyWithCommas(modifiers[ricecoinIndex]?.calculatedAmount),
      ]
      printer.addFixedRow(
        ricecoinRowText,
        discountWidths,
        customerReceiptStyleText,
        1,
        align,
      )
    }
    if (discountIndex >= 0) {
      // 全單折扣
      const discountName = modifiers[discountIndex]?.custom ? modifiers[discountIndex]?.customName : this.t('app.printing.orderDiscount')
      const discountRowText = [
        null,
        `${discountName}${modifiers[discountIndex]?.percent > 0 ? `(${modifiers[discountIndex]?.percent}%)` : ''}`,
        currencyWithCommas(modifiers[discountIndex]?.calculatedAmount),
      ]
      printer.addFixedRow(
        discountRowText,
        discountWidths,
        customerReceiptStyleText,
        1,
        align,
      )
    }

    if (calculatedDiscountTotal !== 0) {
      const discountRowText = [
        this.t('app.printing.discount'),
        null,
        currencyWithCommas(calculatedDiscountTotal),
      ]
      printer.addFixedRow(
        discountRowText,
        subtotalWidths,
        customerReceiptStyleText,
        1,
        align,
      )
    }

    if (surchargeIndex >= 0) {
      const surchargePercent = `(${modifiers[surchargeIndex]?.percent}%)`
      const serviceChargeRowText = [
          `${this.t('app.printing.surcharge')}${surchargePercent}`,
          null,
          currencyWithCommas(modifiers[surchargeIndex]?.calculatedAmount),
      ]
      printer.addFixedRow(
        serviceChargeRowText,
        subtotalWidths,
        customerReceiptStyleText,
        1,
        align,
      )
    }

    if (showRoundingInReceipt) {
      if (Math.abs(roundedAmount) >= 0.01) {
        const roundingRowText = [
          `${this.t('app.printing.roundingDifference')}`,
          null,
          currencyWithCommas(roundedAmount),
        ]
        printer.addFixedRow(
          roundingRowText,
          subtotalWidths,
          customerReceiptStyleText,
          1,
          align,
        )
      }
    }
    // shipping fee
    if (deliveryType === 'shop' || deliveryType === 'storeDelivery') {
      const shippingDiscountText = Math.abs(shippingDiscount) > 0 ? `($${shipping.customerAmount} - $${shippingDiscount})` : ''
      const shippingFeeRowText = [
        this.t('app.printing.shippingFee') + shippingDiscountText,
        null,
        `$${shipping.customerAmount - shippingDiscount}`,
      ]
      printer.addFixedRow(
        shippingFeeRowText,
        subtotalWidths,
        customerReceiptStyleText,
        1,
        align,
      )
    }
    // price total 總計
    const { itemWidths: totalpaidWidths } = printer.getReceiptTextWidth(1)
    totalpaidWidths[2] = totalpaidWidths[2] + totalpaidWidths[1]
    totalpaidWidths[1] = 0

    if (_.isEmpty(payments) || status !== 'paid') {
      printer.addRowFeed()
      const totalRowText = [
        this.t('app.printing.total'),
        null,
        currencyWithCommas(roundedTotal),
      ]
      printer.addFixedRow(totalRowText, totalpaidWidths, PrinterFontSize.MEDIUM, 1, align)
    }

    if (!_.isEmpty(payments) && status === 'paid') {
      // extraPayments
      // todo..

      // 實收金額
      printer.addRowFeed()
      const paidRowText = [
        this.t('app.printing.paidAmount'),
        null,
        currencyWithCommas(roundedTotal),
      ]
      printer.addFixedRow(paidRowText, totalpaidWidths, PrinterFontSize.MEDIUM, 1, align)
      printer.addRowFeed()
      // payment method 付款方法
      printer.addFixedRow(
        [this.t('app.printing.method'), null, null],
        subtotalWidths,
        customerReceiptStyleText,
        1,
        align,
      )
      printer.addRowFeed()
      const filteredPayments = payments.filter(p => p?.status === 'paid')
      _.each(filteredPayments, (payment, index) => {
        if (payment.status === 'cancel') {
          return
        }
        const _paymentMethod = getPaymentMethod(payment.paymentMethod)
        const paymentMethodRowText = [
          this.order.deliveryType === 'table' && payment.customerId ? `DimPay (${this.t(_paymentMethod.name)})` : this.t(_paymentMethod.name), // 由客人自助付款時額外顯示 DimPay 字樣
          null,
          currencyWithCommas(payment.paidAmount),
        ]
        printer.addFixedRow(
          paymentMethodRowText,
          subtotalWidths,
          customerReceiptStyleText,
          1,
          align,
        )
        // eft八達通: 詳情請參閱八達通收據
        if (payment.gateway === ecrGateway.EFT_PAY && payment.paymentMethod === constPaymentMethods.OCTOPUS) {
          printer.addFixedRow(
            [this.t('app.printing.eftOctopusText'), null, null],
            subtotalWidths,
            customerReceiptStyleText,
            1,
            align,
          )
        }
        // 找續
        if (payment.change > 0) {
          const changeRowText = [
            this.t('app.printing.change'),
            null,
            currencyWithCommas(payment.change),
          ]
          printer.addFixedRow(
            changeRowText,
            subtotalWidths,
            customerReceiptStyleText,
            1,
            align,
          )
        }
        // 小費
        if (payment.paymentTips) {
          const tipRowText = [
            this.t('app.printing.tips'),
            null,
            currencyWithCommas(payment.paymentTips),
          ]
          printer.addFixedRow(
            tipRowText,
            subtotalWidths,
            customerReceiptStyleText,
            1,
            align,
          )
        }
        printer.addRowFeed(1)
      })
    }
    if (takeaway && (!quickMode || this.order.from !== 'MERCHANT')) {
      printer.addRowFeed().addAlign('center')
      printer.addText(
        needTableware ? this.t('app.printing.tableware') : `${this.t('app.printing.no') + this.t('app.printing.tableware')}`,
        PrinterFontSize.LARGE,
      )
      printer.addRowFeed()
      if (status !== 'paid') {
        printer.addText(this.t('app.printing.unpaid'), PrinterFontSize.LARGE)
      } else {
        printer.addText(this.t('app.printing.paid'), PrinterFontSize.LARGE)
      }
    }

    if (footerCustomText) {
      printer.addRowFeed(2)
      printer.addAlign('center')
      printer.addText(footerCustomText, defaultSettings?.customFooterStyleText ?? PrinterFontSize.MEDIUM).addRowFeed()
      printer.addAlign('left')
    }

    if (this.cancelledType) {
      const text = this.t('app.printing.cancelReceipt.' + this.cancelledType)
      printer.addAlign('center')
      printer.addRowFeed()
      printer.addBoldMode()
      printer.addText(text, PrinterFontSize.LARGE).addRowFeed()
      printer.addBoldMode(false)
    }

    // Powered by DimPOS
    // printer.addRowFeed(2)
    // printer.addAlign('center')
    // printer.addText(brandingText, PrinterFontSize.MEDIUM).addRowFeed()
    // printer.addAlign('left')

    printer.addRowFeed(2)
    printer.addAlign('left').addText(this.printId, 0)
    printer.addRowFeed(5)
    printer.addCutPaper()
  }

  _addOptionText (options) {
    const printer = this.printer
    let optionFirst = true
    const { customerReceiptStyleText } = this.printerSetting
    printer.addAlign('left')
    const optionText = options
      .map((opt) => {
        let text = opt.name
        if (opt.quantity > 1) {
          text += `(${opt.quantity})`
        }
        return text
      })
      .join(', ')
    printer
      .addText(
        (optionFirst ? '  **' : '  ') + ` ${optionText}`,
        customerReceiptStyleText,
      )
      .addRowFeed()
    optionFirst = false
  }

  _addRemarksText (remarks) {
    const printer = this.printer
    const remarksText = this.t('app.printing.remark')
    printer.addAlign('left')
    const { customerReceiptStyleText } = this.printerSetting
    _.each(remarks, (remark) => {
      if (!_.isEmpty(remark)) {
        printer
          .addText(`  ${remarksText}: ${remark}`, customerReceiptStyleText)
          .addRowFeed()
      }
    })
  }

  /**
   * 根據列印時的情況產生 hash，可避免重複列印
   * @returns {string}
   */
  _generatePrintHash () {
    // 收據上confirmed的batches中沒有被取消的items的數量
    const confirmedBatches = _.filter(this.order?.batches, batch => batch.status === 'confirmed')
    const items = _.flatMap(confirmedBatches, batch => batch.items)
    const successItems = _.filter(items, item => !item.cancelled)
    const itemsCount = _.sumBy(successItems, 'quantity')
    // 原本使用整個 order 做 hash，但 batch item 的 property printed 和 printError 有機會是會變的
    // 為了避免重複印單，將原本的 order hash 改為使用不變的 order.id
    return hash({
      orderId: this.order.id,
      itemsCount: itemsCount,
      total: this.order.roundedTotal,
      status: this.order.status,
      printerSetting: this.printerSetting, // 設定不同時重印
      printReason: this.printReason, // 加入 printReason 避免印完 submit 後的訂單馬上結帳，因 orderId 及 printerSetting 都一樣而被跳過列印
      reprintId: this.reprint ? shortid.generate() : undefined, // 手動重印的話使用產生的 id，避免 id 重複而跳過烈印
    })
  }
}
