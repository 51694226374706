export const firebaseConfig = {
  test: {
    apiKey: 'AIzaSyCC-reTueeTZke4P2RgTQ2L0eIUsHzjK1Q',
    authDomain: 'dimorder-dev.firebaseapp.com',
    databaseURL: 'https://dimorder-dev.firebaseio.com',
    projectId: 'dimorder-dev',
    storageBucket: 'dimorder-dev.appspot.com',
    messagingSenderId: '244888588047',
    appId: '1:244888588047:web:bcab955db302269407787a',
  },
  beta: {
    apiKey: 'AIzaSyCtRflLUe8x6HLoowNgVumvf42oBDidslM',
    authDomain: 'dimorder-beta-10f3e.firebaseapp.com',
    databaseURL: 'https://dimorder-beta-10f3e.firebaseio.com',
    projectId: 'dimorder-beta-10f3e',
    storageBucket: 'dimorder-beta-10f3e.appspot.com',
    messagingSenderId: '1049923969734',
    appId: '1:1049923969734:web:7b4620abc5a2e4968cedc7',
  },
  production: {
    apiKey: 'AIzaSyAYq9sViFBGnHD4BT1pRWftsTDacoAQ1j0',
    authDomain: 'dimorder1.firebaseapp.com',
    databaseURL: 'https://dimorder1.firebaseio.com',
    projectId: 'dimorder1',
    storageBucket: 'dimorder1.appspot.com',
    messagingSenderId: '756396734164',
    appId: '1:756396734164:web:9300f6bcad2df73a936d41',
    measurementId: 'G-S96QJD7K3D',
  },
}
export default firebaseConfig
