import { StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import CustomerCount from '@/pages/Table/Dialogs/CutomerCount'
import NumberInputDialog from '@/components/dialogs/NumberInputDialog'
import Row from '@/components/Row'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef NewCustomerCountDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {IAppOrder} order
 * @property {() => void} setOpenCustomerCountDialog
 */

/**
 *
 * @param {NewCustomerCountDialogProps} props
 * @returns
 */
export default function NewCustomerCountDialog (props) {
  const { open, onClose, order, setOpenCustomerCountDialog } = props
  const dispatch = useDispatch()
  const [adultsCount, setAdultsCount] = useState(0)
  const [childrenCount, setChildrenCount] = useState(0)
  const [error, setError] = useState('')
  const [adultActive, setAdultActive] = useState(true)
  const [childrenActive, setChildrenActive] = useState(false)
  const [clearValue, setClearValue] = useState(true)

  const { t } = useTranslation()
  const submitError = t('app.page.table.dialog.submitError')
  const title = t('app.constant.orderAction.customerCount')
  const confirm = t('app.common.confirm')
  const saveSuccessMessage = t('app.page.orderHistory.updateCustomerCount.alert.successMessage')

  useEffect(() => {
    setAdultsCount(order?.adults)
    setChildrenCount(order?.children)
  }, [order])

  const resetState = () => {
    setError('')
    setAdultActive(true)
    setChildrenActive(false)
    setClearValue(false)
  }

  const onSubmit = async () => {
    if (adultsCount + childrenCount <= 0) {
      setError(submitError)
      return
    }
    await dispatch(actions.orderHistory.updateCustomerCount(order.id, adultsCount, childrenCount))
    setOpenCustomerCountDialog(false)
    setError('')
    dispatch(actions.app.showAlert({ message: saveSuccessMessage }))
  }

  return (
    <NumberInputDialog
      title={title}
      open={open}
      maxLength={2}
      onClose={() => {
        resetState()
        onClose()
      }}
      disableDecimalPoint
      defaultValue={clearValue ? 0 : adultActive ? adultsCount : childrenCount}
      onPress={(value) => {
        if (adultActive) {
          setClearValue(false)
          setAdultsCount(Number(value))
        }
        if (childrenActive) {
          setClearValue(false)
          setChildrenCount(Number(value))
        }
      }}
    >
      <Column style={styles.container}>
        <CustomerCount
          state={{
            adults: adultsCount,
            children: childrenCount,
          }}
          setAdultActive={setAdultActive}
          setChildrenActive={setChildrenActive}
          adultActive={adultActive}
          childrenActive={childrenActive}
          setClearValue={setClearValue}
        />
        <Row style={styles.errorRow}>
          <Text style={styles.errorText}>
            {error || ' '}
          </Text>
        </Row>

        <Row style={styles.bottomRow}>
          <Button
            title={confirm}
            textBold
            type='TouchableOpacity'
            onPress={onSubmit}
          />
        </Row>
      </Column>
    </NumberInputDialog>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 230,
    height: 300,
    marginHorizontal: 43,
    justifyContent: 'space-around',
  },
  bottomRow: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorRow: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  errorText: {
    fontWeight: '500',
    color: colors.secondary,
    alignItems: 'center',
  },
  customers: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
})
