import { Animated, Easing } from 'react-native'
import { Icon } from 'react-native-elements'
import React from 'react'

import colors from '@/theme/colors'

/**
 *
 * @param {*} props
 * @returns
 */
export default function LoadingIcon (props) {
  const fadeAnimation = new Animated.Value(0)

  React.useEffect(() => {
    Animated.loop(
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 3000,
        easing: Easing.linear,
        useNativeDriver: true,
      }),
    ).start()
  })

  return (
    <Animated.View
      style={{
        transform: [{
          rotate: fadeAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', '360deg'],
          }),
        }],
      }}
    >
      <Icon
        size={30}
        name='autorenew'
        type='material'
        color={colors.white}
      />
    </Animated.View>
  )
}
