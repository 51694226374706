/* eslint-disable camelcase */
import { isOrderAllowLocalDatabaseClear } from '@/libs/order'
import { v4 as uuid } from 'uuid'
import CRC32 from 'crc-32'
import Realm from 'realm'
import _ from 'lodash'
import debounce from 'lodash.debounce'
import logger from '@/libs/logger'
import moment from 'moment'

const dbFileName = 'database/realm/orderHistory.realm'
const schemaVersion = 1

const orderHistorySchema = {
  name: 'OrderHistory',
  properties: {
    _id: 'string',
    isClosed: 'bool', // 完全 completed / cancelled = true
    createdAtTs: 'int', // timestamp
    json: 'string',
  },
  primaryKey: '_id',
}

/**
 *
 * @returns
 */
const realmOpen = async () => {
  const logTitle = '[localDatabase/orderHistory] [realmOpen] '
  try {
    return await Realm.open({
      path: dbFileName,
      schema: [orderHistorySchema],
      schemaVersion,
    })
  } catch (e) {
    logger.error(logTitle + `${e?.message}`, e)
  }
}

// 短期內拼發call就會延長close
const realmCloseDebounced = debounce((realm) => {
  if (realm) realm.close()
}, 10 * 1000)

/**
 * 關閉realmClose (已debounced)
 * @param {*} realm
 * @returns
 */
const realmClose = (realm) => realmCloseDebounced(realm)

/**
 * 創造或更新訂單
 * @param {*} order
 */
export const upsertOrder = async (order) => {
  try {
    const realm = await realmOpen()
    // 檢查是否完成的訂單
    const isClosed = isOrderAllowLocalDatabaseClear(order)
    const createdAtTs = moment(order.createdAt).valueOf()

    realm.write(() => {
      realm.create(orderHistorySchema.name, {
        _id: order.id, isClosed, createdAtTs, json: JSON.stringify(order),
      }, 'modified')
    })
    realmClose(realm)
    // console.warn(`~~~~~LocalDB upsertOrder id:${order.serial} ${ms_now(t0)} ms, crc32:${hashStr(JSON.stringify(order))}`)
  } catch (e) {
    const logTitle = '[localDatabase/orderHistory] [upsertOrder] '
    logger.error(logTitle + `orderId: ${order.id}` + ` ${e?.message}`, e)
  }
}

/**
 * 開App拿出需要的回復到redux的訂單
 * @param {string} unsyncOrderIds[]
 * @returns
 */
export const getOrdersForRestore = async (unsyncOrderIds = []) => {
  const realm = await realmOpen()

  const conditionUnsyncOrderIds = `_id IN {${unsyncOrderIds.map(id => `'${id}'`).join()}}`
  // Query訂單  ( 乎合以下任何條件即可 )
  //  1. 在unsyncOrderIds  or
  //  2. 未完成的訂單     or
  //  3. 12小時內的訂單
  const query = `
   ${conditionUnsyncOrderIds}
   || isClosed != true
   || createdAtTs >= ${moment().valueOf() - 12 * 60 * 60 * 1000 /* 12小時內 */}
   `
  const orders = realm.objects(orderHistorySchema.name)
    .filtered(query)
    .map(o => JSON.parse(o.json))
  realmClose(realm)
  // console.warn(`~~~~~LocalDB getOrdersForRestore: ${ms_now(t0)} ms`)
  return orders
}

/**
 * 拿出整個 database 的訂單
 * ! 小心地用,拿出全個database會比較久, 沒必要就不要用
 * @returns
 */
export const getAllOrders = async () => {
  const realm = await realmOpen()
  // query訂單
  const orders = realm.objects(orderHistorySchema.name).map(o => JSON.parse(o.json))
  realmClose(realm)
  // console.warn(`~~~~~LocalDB getAllOrders: ${ms_now(t0)} ms, length: ${orders.length}`)
  return orders
}

/**
 * 清除 database 太過久之前的訂單
 * @param {string} unsyncOrderIds[]
 */
const AllowClearExpiredHours = 36 // 36小時
export const clearOrdersNoLongerUse = async (unsyncOrderIds = []) => {
  const realm = await realmOpen()
  // 清除 localDatabase 乎合以下所有條件的訂單
  // 1. created_at  36小時外
  // 2. 不在 unsyncOrderIds
  // 3. 已完成 / 已取消的訂單
  const conditionNotInUnsyncOrderIds = `NOT _id IN {${unsyncOrderIds.map(id => `'${id}'`).join()}}`
  const query = `
   ${conditionNotInUnsyncOrderIds}
   && isClosed == true
   && createdAtTs < ${moment().valueOf() - AllowClearExpiredHours * 60 * 60 * 1000 /* 早於xx小時外 */}
   `
  realm.write(() => {
    realm.delete(realm.objects(orderHistorySchema.name).filtered(query))
  })
  realmClose(realm)
}

/**
 * 清除 database 所有訂單
 * ! 小心使用
 */
export const clearOrders = async () => {
  const realm = await realmOpen()
  realm.write(() => {
    realm.deleteAll()
  })
  realmClose(realm)
}

// ************************************************************************************
// *****************  [ Tools Function ] **********
/**
 * @param {*} str
 * @returns
 */
export const str2Byte = (str) => new Blob([str]).size

/**
 * 獲取字串hash (crc32)
 * @param {*} str
 * @returns
 */
const hashStr = (str) => {
  return CRC32.str(str)
}

// Tools Function
export const ms = (t1, t0) => (t1 - t0).toFixed(1)
export const ms_now = (t_start) => ms(performance.now(), t_start)
export const str2Byte_mb = (str) => (str2Byte(str) / 1024 / 1024).toFixed(4)
export const str2Byte_mb_lng = (str) => (str2Byte(str) / 1024 / 1024)
export const split_wthLen = (str, n) => str.split(new RegExp('(.{' + n.toString() + '})')).filter(O => O) // .filter(O => O) = remove empty string

// ***  Testing Tool  ***
/**
 * Copy Orders
 * @param {*} ords
 * @param {*} times
 * @returns
 */
export const copyOrders = (ords, times) => {
  return new Array(times).fill(0)
    .map((v, i) => _.cloneDeep(ords[i % ords.length]))
}

/**
 * Copy Orders - Random id (UUID) avoid repeat
 * @param {*} ords
 * @param {*} times
 * @returns
 */
export const copyOrders_randId = (ords, times) => {
  return new Array(times).fill(0)
    .map((v, i) => {
      const ord = _.cloneDeep(ords[i % ords.length])
      ord.id = uuid()
      return ord
    })
}
