import { StyleSheet, Text, View } from 'react-native'
import { useSelectedOrder } from '@/hooks/order'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Column from '@/components/Column'
import React from 'react'
import colors from '@/theme/colors'
import moment from 'moment'

export default function Header (props) {
  const { t } = useTranslation()
  const selectedOrder = useSelectedOrder()
  const userAccount = useSelector(state => state.auth.userLogin.account)
  const data = useSelector(state => state.merchant.data)

  return (
    <View style={styles.container}>
      <View style={styles.details}>
        <Column style={styles.detailColumn}>
          <Text style={styles.detailText}>{`${t('app.constant.order.orderat')}：${moment(selectedOrder.createdAt).format('HH:mm')}`}</Text>
          <Text style={styles.detailText}>{`${t('app.page.order.header.createDate')}：${moment(selectedOrder.createdAt).format('DD/MM/YYYY')}`}</Text>
        </Column>
        <Column style={styles.detailColumn}>
          <Text style={styles.detailText}>{`${t('app.page.order.header.userAccount')}：${userAccount}`}</Text>
          {selectedOrder.deliveryType === 'table' &&
            <Text style={styles.detailText}>{`${t('app.constant.order.serial')}：${selectedOrder.serial}`}</Text>}
          {selectedOrder.deliveryType === 'takeaway' &&
            <Text style={styles.detailText}>{`${t('app.constant.order.pickupAt')}：${selectedOrder.pickupAt ? moment(selectedOrder.pickupAt).format('HH:mm') : moment().add(data.setting?.takeawayPickupAfterMins, 'm').format('HH:mm')}`}</Text>}
          {selectedOrder.deliveryType === 'storeDelivery' &&
            <Text style={styles.detailText}>{`${t('app.constant.order.serial')}：${selectedOrder.serial}`}</Text>}
        </Column>
        <Column style={styles.detailColumn}>
          {selectedOrder.deliveryType === 'takeaway' &&
            <Text style={styles.detailText}>{`${selectedOrder.serial && `${t('app.constant.order.serial')}` + selectedOrder.serial}`}</Text>}
          {selectedOrder.deliveryType === 'storeDelivery' &&
            <Text style={styles.detailText}>{`${t('app.constant.order.pickupAt')}：${moment(selectedOrder.pickupAt).format('HH:mm')}`}</Text>}
        </Column>
      </View>
      <View style={styles.tabs}>
        {selectedOrder.deliveryType === 'table' &&
          <Text style={styles.tagText}>
            {`${t('app.constant.order.table')}：${selectedOrder.table || t('app.page.order.header.notSelected')}${selectedOrder.subTable > 0 ? `(${String.fromCharCode(selectedOrder.subTable + 64)})` : ''}`}
          </Text>}
        {selectedOrder.deliveryType === 'table' &&
          <Text style={styles.tagText}>
            {`${t('app.constant.order.customers')}：${selectedOrder.customers || 1}${t('app.page.order.header.people')}`}
          </Text>}
        {selectedOrder.deliveryType === 'takeaway' &&
          <Text style={styles.tagText}>{t('app.constant.deliveryType.takeaway')}</Text>}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.lightGray2,
    marginLeft: -100,
    paddingLeft: 100,
  },
  details: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
  },
  detailColumn: {
    marginHorizontal: 8,
  },
  detailText: {
    color: colors.textSecondary,
    fontSize: 13,
    margin: 4,
  },
  tagText: {
    color: colors.primary,
    fontSize: 22,
    margin: 15,
    marginTop: 15,
    fontWeight: 'bold',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.light,
    borderTopLeftRadius: 32,
    zIndex: 10,
    paddingHorizontal: 25,
  },
  switch: {
    width: 112,
    justifyContent: 'center',
    backgroundColor: colors.gray,
    borderTopLeftRadius: 32,
    marginRight: -32,
    paddingLeft: 16,
  },
  switchText: {
    fontSize: 13,
    fontWeight: '500',
    color: colors.white,
    margin: 4,
  },

})
