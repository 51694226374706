import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * }} props
 */
export default function HeaderSetting (props) {
  const { setting, onChange } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)

  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]
  const textAlignOptions = [
    { value: 'left', display: t('app.page.setting.printer.left') },
    { value: 'center', display: t('app.page.setting.printer.center') },
    { value: 'right', display: t('app.page.setting.printer.right') },
  ]
  const timeFormatOptions = [
    { value: 'YYYY/MM/DD HH:mm:ss', display: 'YYYY/MM/DD HH:mm:ss' },
    { value: 'MM/DD HH:mm', display: 'MM/DD HH:mm' },
  ]

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  return (
    <>
      <SettingListItem divider={!setting.content?.orderType?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.orderType')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.orderType?.enable}
            onChangeValue={() => onChange('content.orderType.enable', !setting.content?.orderType?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.orderType?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.orderType?.bold}
                  onChangeValue={() => onChange('content.orderType.bold', !setting.content?.orderType?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.orderType?.fontSize)?.display ||
                      setting.content?.orderType?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.orderType?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.orderType.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.orderType?.align)?.display ||
                      setting.content?.orderType?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.orderType?.align || 0,
                  onChangeValue: (value) => onChange('content.orderType.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.serialNo?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.takeawaySerialNo')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.serialNo?.enable}
            onChangeValue={() => onChange('content.serialNo.enable', !setting.content?.serialNo?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.serialNo?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.serialNo?.bold}
                  onChangeValue={() => onChange('content.serialNo.bold', !setting.content?.serialNo?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.serialNo?.fontSize)?.display ||
                      setting.content?.serialNo?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.serialNo?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.serialNo.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.serialNo?.align)?.display ||
                      setting.content?.serialNo?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.serialNo?.align || 0,
                  onChangeValue: (value) => onChange('content.serialNo.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.scheduleAt?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.scheduleAt')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.scheduleAt?.enable}
            onChangeValue={() => onChange('content.scheduleAt.enable', !setting.content?.scheduleAt?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.scheduleAt?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.scheduleAt?.bold}
                  onChangeValue={() => onChange('content.scheduleAt.bold', !setting.content?.scheduleAt?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.scheduleAt?.fontSize)?.display ||
                      setting.content?.scheduleAt?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.scheduleAt?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.scheduleAt.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.scheduleAt?.align)?.display ||
                      setting.content?.scheduleAt?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.scheduleAt?.align || 0,
                  onChangeValue: (value) => onChange('content.scheduleAt.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.format')}
              value={timeFormatOptions.find(t => t.value === setting.content?.scheduleAt?.format)?.display ||
              setting.content?.scheduleAt?.format}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.format'),
                  defaultValue: setting.content?.scheduleAt?.format || 0,
                  onChangeValue: (value) => onChange('content.scheduleAt.format', value),
                  options: timeFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.pickupAt?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.pickupAt')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.pickupAt?.enable}
            onChangeValue={() => onChange('content.pickupAt.enable', !setting.content?.pickupAt?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.pickupAt?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.pickupAt?.bold}
                  onChangeValue={() => onChange('content.pickupAt.bold', !setting.content?.pickupAt?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.pickupAt?.fontSize)?.display ||
                      setting.content?.pickupAt?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.pickupAt?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.pickupAt.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.pickupAt?.align)?.display ||
                      setting.content?.pickupAt?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.pickupAt?.align || 0,
                  onChangeValue: (value) => onChange('content.pickupAt.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.format')}
              value={timeFormatOptions.find(t => t.value === setting.content?.pickupAt?.format)?.display ||
              setting.content?.pickupAt?.format}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.format'),
                  defaultValue: setting.content?.pickupAt?.format || 0,
                  onChangeValue: (value) => onChange('content.pickupAt.format', value),
                  options: timeFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.merchantLogo')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.merchantLogo?.enable}
            onChangeValue={() => onChange('content.merchantLogo.enable', !setting.content?.merchantLogo?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider={!setting.content?.merchantName?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.merchantName')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.merchantName?.enable}
            onChangeValue={() => onChange('content.merchantName.enable', !setting.content?.merchantName?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.merchantName?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.merchantName?.bold}
                  onChangeValue={() => onChange('content.merchantName.bold', !setting.content?.merchantName?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.merchantName?.fontSize)?.display ||
                        setting.content?.merchantName?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.merchantName?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.merchantName.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.merchantName?.align)?.display ||
                        setting.content?.merchantName?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.merchantName?.align || 0,
                  onChangeValue: (value) => onChange('content.merchantName.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.merchantAddress?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.merchantAddress')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.merchantAddress?.enable}
            onChangeValue={() => onChange('content.merchantAddress.enable', !setting.content?.merchantAddress?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.merchantAddress?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.merchantAddress?.bold}
                  onChangeValue={() => onChange('content.merchantAddress.bold', !setting.content?.merchantAddress?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.merchantAddress?.fontSize)?.display ||
                        setting.content?.merchantAddress?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.merchantAddress?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.merchantAddress.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.merchantAddress?.align)?.display ||
                        setting.content?.merchantAddress?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.merchantAddress?.align || 0,
                  onChangeValue: (value) => onChange('content.merchantAddress.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.merchantContact')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.merchantContact?.enable}
            onChangeValue={() => onChange('content.merchantContact.enable', !setting.content?.merchantContact?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.merchantContact?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.merchantContact?.bold}
                  onChangeValue={() => onChange('content.merchantContact.bold', !setting.content?.merchantContact?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.merchantContact?.fontSize)?.display ||
                        setting.content?.merchantContact?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.merchantContact?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.merchantContact.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.merchantContact?.align)?.display ||
                        setting.content?.merchantContact?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.merchantContact?.align || 0,
                  onChangeValue: (value) => onChange('content.merchantContact.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
    </>
  )
}
