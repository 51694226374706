import { StyleSheet, View } from 'react-native'

import BatchList from '../../OrderHistory/OrderDetail/BatchList'
import Column from '@root/src/components/Column'
import Header from '../../OrderHistory/OrderDetail/Header'
import PriceSummary from '@root/src/components/PriceSummary'
import React from 'react'
import colors from '@root/src/theme/colors'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef MemberOrderDetailsProps
 * @property {IAppOrder} selectedOrder
 */

/**
 *
 * @param {MemberOrderDetailsProps} props
 * @returns
 */
export default function MemberOrderDetails (props) {
  const { selectedOrder } = props

  return (
    <Column style={styles.container}>
      {selectedOrder ? <Header crmSelectedOrder={selectedOrder} fromCRM /> : <View style={styles.header} />}
      <BatchList
        crmSelectedOrder={selectedOrder}
        fromCRM
      />
      <PriceSummary showTotal order={selectedOrder} />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 2,
    backgroundColor: colors.light,
  },
  content: {
    flex: 1,
  },
  header: {
    height: 122,
    backgroundColor: colors.lightPrimary,
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
})
