import React from 'react'

import { Route, Switch, useLocation } from '@/libs/reactRouter'
import { useSelector } from '@/redux'

import Checkout from '@/pages/Checkout'
import Login from '@/pages/Login'
import Order from '@/pages/Order'
import OrderHistory from '@/pages/OrderHistory'
import ScanQRCode from '@/pages/ScanQRCode'
import Setting from '@/pages/Setting'
import Stats from '@/pages/Stats'
import Table from '@/pages/Table'

import InitRedirect from './InitRedirect'
import Member from '@/pages/Member'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AppRouter (props) {
  const location = useLocation()
  const apiUrls = useSelector(state => state.config.apiUrls)

  console.log('location', location.pathname)

  if (!apiUrls) return null

  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/order' component={Order} />
      <Route exact path='/orderHistory' component={OrderHistory} />
      <Route path='/orderHistory/checkout' component={Checkout} />
      <Route exact path='/table' component={Table} />
      <Route path='/setting' component={Setting} />
      <Route exact path='/' component={InitRedirect} />
      <Route exact path='/stats' component={Stats} />
      <Route exact path='/scanQRCode' component={ScanQRCode} />
      <Route exact path='/member' component={Member} />
    </Switch>
  )
}
