import { StyleSheet, Text, View } from 'react-native'
import { numberWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Column from '@/components/Column'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function StatSummary ({ amount, count, cancel, customer }) {
  const isDataInit = useSelector(state => state.app.isDataInit)
  const { t } = useTranslation()

  if (!isDataInit) {
    return null
  }

  return (
    <Row style={styles.container}>
      <Column>
        <View style={styles.card}>
          <Text style={styles.title}>{t('app.page.stats.title.total')}</Text>
          <Text style={styles.amount}>{numberWithCommas(amount)}</Text>
        </View>
        <View style={styles.card}>
          <Text style={styles.title}>{t('app.page.stats.title.cancel')}</Text>
          <Text style={styles.amount}>{cancel}</Text>
        </View>
      </Column>
      <Column>
        <View style={styles.card}>
          <Text style={styles.title}>{t('app.page.stats.title.amount')}</Text>
          <Text style={styles.amount}>{count}</Text>
        </View>
        <View style={styles.card}>
          <Text style={styles.title}>{t('app.page.stats.title.customer')}</Text>
          <Text style={styles.amount}>{customer}</Text>
        </View>
      </Column>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 120,
  },
  title: {
    fontSize: 16,
    color: colors.primary,
  },
  amount: {
    fontSize: 42,
    fontWeight: 'bold',
  },
})
