import React from 'react'
import _filter from 'lodash/filter'

import { actions, useDispatch, useSelector } from '@/redux'
import Alert from '@/components/Alert'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AlertQueue (props) {
  const dispatch = useDispatch()
  const alerts = useSelector(state => state.app.alerts)
  const showingAlert = _filter(alerts, (alert) => !alert.dismiss)

  return showingAlert.map(({ id, alertConfig }) => {
    return (
      <Alert
        key={id}
        handleClose={() => dispatch(actions.app.closeAlert(id))}
        alertConfig={alertConfig}
      />
    )
  })
}
