import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IPrinterState} */
export const initialState = {
  isInit: false,
  isLoading: false,
  printerSetting: {
    // Printers
    printers: [],
    defaultPrinter: null,
    qrcodePrinter: null,
    reportPrinter: null,
    priority: {},
    prioritizedPrinters: {},

    // Print Settings
    defaultSettings: {
      batchMenuStyleText: 1,
      customerReceiptStyleText: 0,
      customFooterStyleText: 1,
      cutPaperMode: 'none',
      receiptGroupBy: 'all',
      printCancelledItem: false,
      printSetmenuBundled: false,
      printWithPrice: false,
      splitItem: false,
      syncReceipt: false,
      syncTakeawayReceipt: false,
      syncReceiptPrioritizedPrinter: false,
    },
    confirmReceiptSettings: [],
    kitchenReceiptSettings: [],
    invoiceSettings: [],
    labelSettings: [],

    // Style Settings
    customizedStyleSettings: [],
    customizedKitchenSettings: [],
    customizedLabelSettings: [],

    // Logo Settings
    logo: {
      receipt: false,
      qrcode: false,
      threshold: 127,
    },
  },
  printerStatus: {},
}

export default produce(
  /**
   * @param {IPrinterState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_LOADING: {
        const { isLoading } = action.payload
        draft.loading = isLoading
        break
      }
      case ActionTypes.UPDATE_PRINTER_SETTING: {
        const { printerSetting } = action.payload
        draft.printerSetting = { ...draft.printerSetting, ...printerSetting }
        break
      }
      case ActionTypes.UPDATE_PRINTER_STATUS: {
        const { id, status } = action.payload
        draft.printerStatus[id] = status
        break
      }
      case ActionTypes.UPDATE_PRINTERS: {
        const { printerStatus } = action.payload
        draft.printerStatus = printerStatus
        break
      }
      case ActionTypes.RESTORE_PRINTER_SETTING: {
        const { printerSetting } = action.payload
        draft.printerSetting = printerSetting
        draft.isInit = true
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
