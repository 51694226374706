import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

export default function ActionButton ({ title, icon, onPress, disabled }) {
  return (
    <TouchableOpacity style={shadows.default} onPress={onPress} disabled={disabled}>
      <View style={[styles.container, disabled && styles.disabled]}>
        <Image style={styles.icon} source={icon} />
        <Text style={styles.text}>{title}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 60,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.darkSecondary,
    ...shadows.default,
  },
  disabled: {
    opacity: 0.6,
  },
  icon: {
    width: 36,
    height: 36,
  },
  text: {
    color: colors.white,
  },
})
