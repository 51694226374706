import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import ColumnLabel from '@/components/InfoDialog/ColumnLabel'
import NumberInput from '@/components/InfoDialog/NumberInput'
import React from 'react'
import Row from '@/components/Row'

export default function DetailInput (
  {
    state: {
      name,
      customerNameSuffix,
      phone,
      remark,
      adults,
      children,
    },
    requireName,
    requirePhone,
    requireadults,
    onChangeState,
  },
) {
  const { t } = useTranslation()
  const adult = t('app.page.table.dialog.adult')
  const child = t('app.page.table.dialog.child')
  return (
    <Row style={styles.container}>
      {/* <Column style={styles.leftContainer}>
        {/* Cutomer name input
        <RowTextInput
          placeholder='姓名'
          required={requireName}
          value={name}
          onChangeText={(value) => onChangeState('name', value)}
          suffixValue={customerNameSuffix}
          setSuffix={(value) => onChangeState('customerNameSuffix', value)}
        />

        {/* Cutomer phone input
        <RowTextInput
          placeholder='電話'
          keyboardType='number-pad'
          required={requirePhone}
          value={phone}
          onChangeText={(value) => {
            if (/^\d+$/.test(value) && value.length <= 8) {
              onChangeState('phone', value)
            } else if (value === '') {
              onChangeState('phone', value)
            }
          }}
        />

        {/* Remark input
        <RowTextInput
          placeholder='備註'
          value={remark ?? ''}
          onChangeText={(value) => onChangeState('remark', value)}
        />
      </Column> */}

      <Row style={styles.rightContainer}>
        {/* Adult count input */}
        <ColumnLabel
          label={adult}
          style={styles.columnLabel}
          required={requireadults}
        >
          <NumberInput
            min={0}
            max={20}
            value={adults}
            setValue={(value) => onChangeState('adults', value)}
          />
        </ColumnLabel>

        {/* Child count input */}
        <ColumnLabel
          label={child}
          style={styles.columnLabel}
        >
          <NumberInput
            min={0}
            max={20}
            value={children}
            setValue={(value) => onChangeState('children', value)}
          />
        </ColumnLabel>
      </Row>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 30,
  },
  leftContainer: {
    flex: 2,
    paddingRight: '10%',
  },
  rightContainer: {
    flex: 1,
    paddingTop: 30,
    height: 200,
    justifyContent: 'space-between',
  },
  columnLabel: {
    alignItems: 'center',
    width: 70,
  },
})
