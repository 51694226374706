import { BlurView } from 'expo-blur'
import { Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { actions, useDispatch } from '@/redux'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '@/theme/colors'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

MenuItemFolder.propTypes = {
  category: PropTypes.object,
}

/**
 * @param {{category: IAppCategory}} props
 */
export default function MenuItemFolder (props) {
  const dispatch = useDispatch()
  const { category } = props

  const imageSource = category?.image ? { uri: category.image } : null

  return (
    <ImageBackground source={imageSource} style={styles.container}>
      <View style={styles.blurBackground} />
      <BlurView tint='dark' intensity={20} style={styles.blurView}>
        <TouchableOpacity
          style={styles.contentContainer}
          onPress={() => dispatch(actions.menu.selectCategory(category))}
        >
          <View style={styles.contentContainer}>
            <Image style={styles.buttonIcon} source={require('@icons/folder.png')} />
            <Text style={styles.buttonText} numberOfLines={1} ellipsizeMode='tail'>{category.name}</Text>
          </View>
        </TouchableOpacity>
      </BlurView>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: 105,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: 6,
    overflow: 'hidden',
    marginVertical: 4,
    marginHorizontal: 10,
  },
  blurBackground: {
    backgroundColor: colors.textPrimary + 'AB',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  blurView: {
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 4,
  },
  text: {
    color: colors.white,
    fontSize: 15,
    fontWeight: '500',
    lineHeight: 17,
    textAlign: 'center',
  },
  buttonIcon: {
    width: 44,
    height: 44,
    margin: 8,
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    fontWeight: '500',
    lineHeight: 17,
    textAlign: 'center',
    margin: 4,
  },
})
