import { v4 as uuid } from 'uuid'
import produce from 'immer'

import ActionTypes from './ActionTypes'
import _ from 'lodash'

// eslint-disable-next-line no-unused-vars
import { IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/** @type {IAppOrderBatch} */
export const initBatch = {
  orderId: null,
  orderSerial: null, // 不再使用，與 serial 重複
  batchId: null,
  index: 0,
  table: null,
  items: [],
  remark: '',
  takeout: false, // 外賣自取
  delivery: false, // 是否, pickupp 外送
  storeDelivery: false, // 是否外送訂單
  shop: false, // 是否網店訂單
  takeaway: false, // 是否外賣自取訂單
  status: 'submitted',
  createdAt: '',
  updatedAt: '',
  takeawayStatus: 'pending',
  from: 'MERCHANT',
  identifier: '',
  creatorId: '',
}

/** @type {IAppOrder} */
export const initOrder = {
  id: null,
  sync: false,
  apiVersion: '2.0.0',
  serial: '',
  orderSerial: '', // 不再使用，與 serial 重複
  table: null,
  customers: 1,
  adults: 1,
  children: 0,
  items: [],
  remarks: [],
  customItems: [],
  discount: { percent: 0, amount: 0, overrideItem: false, code: '', offeredBy: null },
  surcharge: { percent: 0, amount: 0 },
  originalTotal: 0,
  discountTotal: 0,
  orderDiscount: 0,
  surchargeTotal: 0,
  total: 0,
  originalShippingFee: 0,
  discountShippingFee: 0,
  paymentTips: null,
  paidAmount: 0,
  extraPayments: [],
  payments: [],
  paying: false,
  status: 'pending',
  paidAt: null,
  cancelReason: null,
  cancelIdentifier: null,
  createdAt: '',
  updatedAt: null,
  pickupAt: '',
  cancelledAt: null,
  version: 0,
  takeaway: false,
  delivery: false,
  deliveryStatus: '',
  pickuppOrderNumber: '',
  batches: [],
  deliveryType: 'takeaway',
  shipping: {
    key: '',
    name: '',
    cost: 0,
    minAmount: 0,
    type: '',
    minDeliveryMinute: 0,
    maxDeliveryMinute: 0,
    enabled: false,
    discounts: null,
    freightCollect: false,
    areas: null,
    lalamove: null,
    isSystem: false,
    customerAmount: 0,
    baseShippingFee: 0,
    tunnelFee: 0,
    smallOrderFee: 0,
    longDistanceFee: 0,
    stops: null,
  },
  shippingFee: 0,
  shippingProvider: '',
  needTableware: false,
  creditSales: false,
  remark: '',
  modifiers: [],
  promocode: '',
  tags: [],
  paidByCustomer: false,
  subTable: 0,
  from: 'MERCHANT',
  roundedTotal: 0,
  subtotal: 0,
  originalSubtotal: 0,
  refunded: false,
  calculatedDiscountTotal: 0,
  ricecoin: 0,
  roundedRevenue: 0,
  summary: [],
  roundedAmount: 0,
  merchantOrderDiscount: 0,
  serviced: false,
  displayStatusKey: 'waiting_order',
  valid: true,
}

/** @type {IOrderState} */
export const initialState = {
  selectedOrder: null,
}

export default produce(
  /**
   * @param {IOrderState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.SELECT_ORDER: {
        const { order } = action.payload
        draft.selectedOrder = order
        break
      }
      case ActionTypes.UPDATE_COMBO_MODIFIER: {
        const { submittedBatchItems } = action.payload
        // 先處理length是1的item
        const orderedBatchItems = _.sortBy(submittedBatchItems, submittedBatchItem => submittedBatchItem.length, ['desc'])
        const pushLater = []
        const batchIndex = orderedBatchItems[0][0].batchIndex

        const selectedBatch = draft.selectedOrder.batches[batchIndex]
        _.forEach(orderedBatchItems, comboLists => {
          const itemIndex = comboLists[0].itemIndex
          // 將一樣的modifier組合一起 一同處理
          const groupComboListsByModifier = _.groupBy(comboLists, comboList => {
            return [
          `${comboList.modifier?.type},${comboList.modifier?.amount},${comboList.modifier?.percent}`,
            ]
          })

          const selectedItem = selectedBatch.items[itemIndex]

          let index = 0
          _.forEach(groupComboListsByModifier, (groupComboList) => {
            const modifier = { ...groupComboList[0].modifier }
            modifier.id = uuid()
            const comboRuleIndex = selectedItem.modifiers.findIndex(modifier => modifier.type === 'COMBORULE')
            // index 0 是不需要分拆的item
            if (index === 0) {
              modifier.amount = modifier.amount - selectedItem.price
              if (!_.isEmpty(groupComboList[0].modifier)) {
                if (comboRuleIndex >= 0) {
                  selectedItem.modifiers.splice(comboRuleIndex, 1)
                }
                selectedItem.modifiers.push(modifier)
              } else if (_.isEmpty(groupComboList[0].modifier) && comboRuleIndex >= 0) {
                selectedItem.modifiers.splice(comboRuleIndex, 1)
              }
              selectedItem.quantity = groupComboList.length
            } else {
              // 需要分拆出來的items
              const spliceItemIdsByQuantity = selectedItem.ids.splice(groupComboList.length - 1, groupComboList.length)
              selectedItem.itemIds.splice(groupComboList.length - 1, groupComboList.length)
              selectedItem.prices.splice(groupComboList.length - 1, groupComboList.length)
              selectedItem.totals.splice(groupComboList.length - 1, groupComboList.length)
              const itemBatchModifiers = [...selectedItem.modifiers]
              modifier.amount = modifier.amount - selectedItem.price
              if (!_.isEmpty(groupComboList[0].modifier)) {
                if (comboRuleIndex >= 0) {
                  itemBatchModifiers.splice(comboRuleIndex, 1)
                }
                itemBatchModifiers.push(modifier)
              } else if (_.isEmpty(groupComboList[0].modifier && comboRuleIndex >= 0)) {
                itemBatchModifiers.splice(comboRuleIndex, 1)
              }
              // 放到pushLater最後再處理 以免打亂item的index
              pushLater.push({
                ...selectedItem,
                id: spliceItemIdsByQuantity[0],
                itemIds: spliceItemIdsByQuantity,
                ids: spliceItemIdsByQuantity,
                prices: _.fill(Array(groupComboList.length), selectedItem.prices[0]),
                totals: _.fill(Array(groupComboList.length), selectedItem.totals[0]),
                modifiers: itemBatchModifiers,
                quantity: groupComboList.length,
                key: spliceItemIdsByQuantity[0],
              })
            }
            index++
          })
          selectedItem.id = selectedItem.ids[0]
        })
        selectedBatch.items = selectedBatch.items.concat(pushLater)

        break
      }
      case ActionTypes.UPDATE_BATCH_ITEM: {
        const { updatedBatch } = action.payload
        draft.selectedOrder.batches = updatedBatch
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
