import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk from 'redux-thunk'
import rootReducer from './reducers'

const middleware = [ReduxThunk]
const composeEnhancers = composeWithDevTools({
  // debug 用
  // 將你不想看到的 action type 加入這個 array
  // 以避免 redux devtools 一直被洗 action 洗掉
  actionsBlacklist: [
    // 'APP/UPDATE_NET_INFO',
  ],
})

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middleware)),
)

export default store
