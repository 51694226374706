import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function Divider () {
  const { t } = useTranslation()

  return (
    <Row style={styles.container}>
      <Text style={styles.text}>{t('app.page.checkout.payment.method')}</Text>
      <View style={styles.line} />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 8,
  },
  text: {
    fontSize: 16,
    lineHeight: 27,
    fontWeight: 'bold',
    color: colors.gray,
    marginRight: 12,
  },
  line: {
    height: 1,
    flex: 1,
    backgroundColor: colors.gray,
  },
})
