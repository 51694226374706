import { Provider } from 'react-redux'
import { View } from 'react-native'
import React from 'react'

import '@/libs/sentry'

import { ReactRouter } from '@/libs/reactRouter'
import Main from '@/pages/Main'
import store from '@/redux/store'

import '@/theme/style.css'

export default function App () {
  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <Provider store={store}>
        <ReactRouter>
          <Main />
        </ReactRouter>
      </Provider>
    </View>
  )
}
