import { StyleSheet, View } from 'react-native'
import React, { useEffect, useState } from 'react'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import IconButton from '@/components/buttons/IconButton'
import NumberButton from '@/components/buttons/NumberButton'

import EmployeeOption from '../EmployeeBoard/EmployeeOption'
import InputControl from './InputControl'

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'C', 0, 'D']

/**
 * @typedef PasswordBoardProps
 * @property {IEmployee} employee
 * @property {() => void} onBack
 * @property {() => void} onSubmit
 */
/**
 *
 * @param {PasswordBoardProps} props
 * @returns
 */
export default function PasswordBoard (props) {
  const { employee, onBack, onSubmit } = props
  const [password, setPassword] = useState([])
  const error = useSelector(state => state.auth.error)
  const neededPermission = useSelector(state => state.app.neededPermission)

  useEffect(() => {
    if (password.length === 6) {
      onSubmit(employee.account, password.join(''), neededPermission)
    }
  }, [password])

  useEffect(() => {
    if (error) {
      setTimeout(() => { setPassword([]) }, 400)
    }
  }, [error])

  const handlePasswordInput = (input) => {
    const length = password.length

    // Clear input
    if (input === 'C') {
      setPassword([])
      return
    }

    // Delete last input
    if (input === 'D') {
      if (length > 0) {
        setPassword(prevState => prevState.slice(0, -1))
      }
      return
    }

    // Prevent from excess
    if (length === 6) { return }

    setPassword(prevState => [...prevState, input])
  }

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <View style={styles.titleLeft}>
          <IconButton iconSource={require('@icons/back.png')} onPress={onBack} />
        </View>

        <View style={styles.titleRight}>
          <EmployeeOption style={styles.employee} employee={employee} />
          <InputControl inputLength={password.length} error={error} />
        </View>
      </View>

      <View style={styles.numbers}>
        {
          options.map((option) =>
            <NumberButton
              key={`password-${option}`}
              text={option}
              style={styles.numberButton}
              highlight={option === 'C' || option === 'D'}
              deleteIcon={option === 'D'}
              onPress={() => handlePasswordInput(option)}
            />)
        }

      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    height: 160,
    width: 440,
    flexDirection: 'row',
    backgroundColor: colors.primary,
    borderTopLeftRadius: 100,
    borderBottomRightRadius: 100,
    marginBottom: 25,
    ...shadows.default,
  },
  titleLeft: {
    marginLeft: 42,
    paddingTop: 45,
  },
  titleRight: {
    flex: 1,
    paddingTop: 42.5,
    paddingLeft: 10,

  },
  employee: {
    marginBottom: 17.5,
  },
  numbers: {
    height: 370,
    width: 276,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  numberButton: {
    height: 80,
    width: 80,
    borderTopLeftRadius: 40, // To overwrite the radius setting in NumberButton
    borderTopRightRadius: 40,
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
  },
})
