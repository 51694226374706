/* global __DEV__ */
import { Alert, DevSettings, Dimensions, KeyboardAvoidingView, Platform, ScrollView, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Clipboard from '@react-native-clipboard/clipboard'
import Constants from 'expo-constants'
import React from 'react'
import _ from 'lodash'
import * as Device from 'expo-device'

import { actions, useDispatch, useSelector } from '@/redux'
import { deploymentDialogOptions } from '@/configs/codePush'
import { dimorderApi } from '@/libs/api/dimorder'
import { getCodePushVersion, getCommit } from '@/libs/versionInfo'
import Button from '@/components/buttons/Button'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import Row from '@/components/Row'
import SettingCard from '@/pages/Setting/components/SettingCard'
import TextInputDialog from '@/components/dialogs/TextInputDialog'
import codePush from '@/libs/codePush'
import colors from '@/theme/colors'
import logger, { logId } from '@/libs/logger'

import QuickModeSetting from './QuickModeSetting'
import Setting from './Setting'
const PACKAGE_JSON = require('@root/package.json')

/**
 *
 * @param {*} props
 * @returns
 */
export default function LocalPanel (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const env = useSelector(state => state.config.env)
  const { isMaster } = useSelector(state => state.app.settings)
  const { enableDebugMode } = useSelector(state => state.app.settings)

  const overwriteDeployment = useSelector(state => state.codePush.overwriteDeployment)
  const merchantDeployment = useSelector(state => state.merchant.data?.setting?.deployment)
  const codePushMeta = useSelector(state => state.codePush)
  const deployment = useSelector(state => state.codePush.deployment)
  const codePushPackageMeta = useSelector(state => state.codePush.packageMeta)
  const { id, setting } = useSelector(state => state.merchant.data)
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const { quickMode, enableTnDmode } = setting

  const windowHeight = Dimensions.get('window').height

  const [deploymentSelectDialogOpen, setDeploymentSelectDialogOpen] = React.useState(false)
  const [adminDialogOpen, setAdminDialogOpen] = React.useState(false)
  const [updatingDebugMode, setUpdatingDebugMode] = React.useState(false)

  const waitingOrdersLength = _.filter(historyOrders, order => {
    return order.deliveryType === 'takeaway' && order.from === 'MERCHANT' && order.displayStatusKey === 'waiting_pick_up'
  }).length

  const commit = getCommit(codePushPackageMeta)
  const displayPackage = getCodePushVersion(codePushPackageMeta)

  const debugInfo = {
    merchantId: id,
    logId: logId,
    deployment,
    env: env,
    platform: Platform.OS,
    nativeVersion: Constants ? `${Constants.nativeAppVersion}\nBuild ${Constants.nativeBuildVersion}` : 'not in native',
    packageVersion: PACKAGE_JSON.version,
    commit: commit,
    codePushVersion: codePushPackageMeta && `${codePushPackageMeta.deployment} ${codePushPackageMeta.label}`,
    updatedRelease: codePushPackageMeta?.label,
  }
  const debugInfoString = '```\n' + JSON.stringify(debugInfo, null, 2) + '\n```'

  const handleLogout = async () => {
    dispatch(actions.auth.getUserInfo())
    dispatch(actions.orderHistory.stopWebSocketOrdersUpdater())
    dispatch(actions.auth.userLogout())
    window.applicatiionHistory.replace('/login')
  }
  return (
    <>
      <View style={styles.container}>
        <KeyboardAvoidingView behavior='position'>
          {Platform.OS === 'web' ? (
            <Row style={styles.row}>
              <SettingCard
                style={styles.item}
                touchableProps={{ activeOpacity: 1 }}
                setting={{
                  displayText: t('app.page.setting.localPanel.settingCard.app'),
                  value: `${env} web`,
                  onLongPress: () => {
                    Clipboard.setString(debugInfoString)
                    window.alert('版本資訊已複製')
                  },
                }}
              />
              <SettingCard
                style={styles.item}
                touchableProps={{ activeOpacity: 1 }}
                setting={{
                  displayText: t('app.page.setting.localPanel.settingCard.package'),
                  value: displayPackage,
                }}
              />
              <SettingCard
                style={styles.item}
                touchableProps={{ activeOpacity: 1, delayLongPress: 2000 }}
                setting={{
                  displayText: '更新頻道',
                  value: '不可用',
                  onLongPress: () => setDeploymentSelectDialogOpen(true),
                }}
              />
              <SettingCard
                style={styles.item}
                setting={{
                  displayText: t('app.page.setting.localPanel.settingCard.system'),
                  value: `${Device.osName} ${Device.osVersion}`,
                }}
              />
            </Row>
          ) : (
            <Row style={styles.row}>
              <SettingCard
                style={styles.item}
                setting={{
                  displayText: t('app.page.setting.localPanel.settingCard.app'),
                  value: `${Constants.nativeAppVersion}\nBuild ${Constants.nativeBuildVersion}`,
                  onLongPress: () => {
                    Clipboard.setString(debugInfoString)
                    Alert.alert('版本資訊已複製')
                  },
                }}
              />
              <SettingCard
                style={styles.item}
                touchableProps={{ activeOpacity: 1 }}
                setting={{
                  displayText: t('app.page.setting.localPanel.settingCard.package'),
                  value: displayPackage,
                  onLongPress: () => {
                    logger.log('[CodePush] trigger checkUpdate by long press version button', {
                      overwriteDeployment,
                      merchantDeployment,
                      codePushMeta,
                    })
                    dispatch(actions.codePush.checkUpdate(true))
                  },
                }}
              />
              <SettingCard
                style={styles.item}
                touchableProps={{ activeOpacity: 1, delayLongPress: 2000 }}
                setting={{
                  displayText: t('app.page.setting.localPanel.settingCard.channel'),
                  value: `${overwriteDeployment || merchantDeployment || deployment || '預設'}`,
                  onLongPress: () => setAdminDialogOpen(true),
                }}
              />
              <SettingCard
                style={styles.item}
                touchableProps={{ activeOpacity: 1, delayLongPress: 2000 }}
                setting={{
                  displayText: t('app.page.setting.localPanel.settingCard.system'),
                  value: (!enableDebugMode ? `${Device.manufacturer} ${Device.modelName}\n${Device.osName} ${Device.osVersion}` : `${Device.manufacturer} ${Device.modelName}\n${Device.osName} ${Device.osVersion} (Debug)`),
                  onLongPress: () => {
                    setUpdatingDebugMode(true)
                    setAdminDialogOpen(true)
                  },
                }}
              />
            </Row>
          )}
          <ScrollView showsVerticalScrollIndicator={false} style={windowHeight >= 800 ? { height: 550 } : { height: 470 }}>
            {
              quickMode || enableTnDmode
                ? <QuickModeSetting />
                : <Setting />
            }
          </ScrollView>
        </KeyboardAvoidingView>
        <View style={styles.buttons}>
          <Button
            textBold
            title={t('app.page.setting.localPanel.completeAllTakeaway')}
            style={styles.button}
            backgroundColor={colors.primary}
            disabled={waitingOrdersLength === 0}
            onPress={() => {
              dispatch(actions.app.showAlert({
                title: t('app.page.setting.localPanel.completeAllTakeaway'),
                message: `${t('app.page.setting.localPanel.completeAllTakeawayMsg', { number: waitingOrdersLength })}`,
                buttons: [
                  {
                    children: t('app.common.confirm'),
                    onPress: () => dispatch(actions.orderHistory.completeAllTakeaway()),
                  },
                  {
                    backgroundColor: colors.light,
                    textColor: colors.textTertiary,
                    children: t('app.common.cancel'),
                    onPress: () => {},
                  },
                ],
              }))
            }}
          />
          <Button
            textBold
            title={t('app.page.setting.localPanel.restart')}
            style={styles.button}
            backgroundColor={colors.tertiary}
            onPress={() => {
              if (Platform.OS === 'web') {
                window.location.href = '/'
              } else {
                if (__DEV__) {
                  DevSettings.reload('REFRESH_BUTTON')
                } else {
                  codePush.restartApp()
                }
              }
            }}
          />
          <Button
            textBold
            title={t('app.page.setting.localPanel.logout')}
            style={styles.button}
            backgroundColor={colors.darkSecondary}
            onPress={() => {
              if (isMaster) {
                dispatch(actions.app.showAlert({
                  title: t('app.page.setting.alert.logoutMasterMessage.title'),
                  message: t('app.page.setting.alert.logoutMasterMessage.message'),
                  buttons: [
                    {
                      backgroundColor: colors.light,
                      textColor: colors.textTertiary,
                      children: t('app.page.order.stash.alert.leave.button.cancel'),
                      onPress: () => { },
                    },
                    {
                      children: t('app.page.setting.localPanel.logout'),
                      onPress: () => {
                        handleLogout()
                      },
                    },
                  ],
                }))
              } else {
                handleLogout()
              }
            }}
          />
        </View>

        <OptionsSelectDialog
          key={deploymentSelectDialogOpen}
          title={t('app.page.setting.localPanel.settingCard.chooseChannel')}
          modalProps={{ enablePressOutsideClose: false }}
          open={deploymentSelectDialogOpen}
          onClose={() => setDeploymentSelectDialogOpen(false)}
          closeOnSelect
          options={deploymentDialogOptions}
          defaultValue={overwriteDeployment}
          onSubmit={deployment => dispatch(actions.codePush.overwriteDeployment(deployment))}
        />
        <TextInputDialog
          enablePressOutsideClose={false}
          open={adminDialogOpen}
          onClose={() => setAdminDialogOpen(false)}
          secureTextEntry
          title={t('app.page.setting.menuPanel.menuInfo.dialog.admin.title')}
          placeholder={t('app.page.setting.menuPanel.menuInfo.dialog.admin.placeholder')}
          onSubmit={async (text) => {
            try {
              await dimorderApi.auth.adminLogin(text)
              // 有兩個情況會共用這個 admin dialog
              // 1. 開啟或關閉 debug mode
              // 2. 選擇 deployment

              if (updatingDebugMode) {
                // 開啟或關閉 debug mode
                dispatch(actions.app.updateDebugMode(!enableDebugMode))
                if (!enableDebugMode) {
                  window.alert('已進入 Debug 模式')
                }
              } else {
                // 選擇 deployment
                setDeploymentSelectDialogOpen(true)
              }
            } catch (error) {
              dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.login')))
            }
          }}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: {
    margin: 10,
  },
  row: {
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
