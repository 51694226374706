import { StyleSheet, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'

import OptionInfo from './OptionInfo'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SpecialOptionPanel (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isSavedSetting = useSelector(state => state.app.isSavedSetting)
  const draggable = true
  const [selectedSpecialOption, setSelectedSpecialOption] = React.useState(null)
  const [tagType, setTagType] = React.useState('prefix')
  const [isEditing, setIsEditing] = React.useState(true)
  const customTag = useSelector(state => state.merchant.data.customTag)
  const [customTagsSetting, setCustomTagsSetting] = useImmer(customTag)
  const onDelete = (id) => {
    setCustomTagsSetting(draft => {
      const deleteIndex = customTagsSetting[tagType].findIndex(tag => tag.id === id)
      if (deleteIndex >= 0) {
        draft[tagType].splice(deleteIndex, 1)
      }
    })
  }
  const handleSubmit = () => {
    const emptyName = customTagsSetting.prefix.some(prefix => !prefix.name?.trim()) ||
    customTagsSetting.suffix.some(suffix => !suffix.name?.trim())
    if (emptyName) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.set.error.name')))
      return
    }
    dispatch(actions.app.updateIsSavedSetting(true))
    dispatch(actions.merchant.updateCustomTags(customTagsSetting))
    dispatch(actions.app.showAlert({ message: t('app.alert.saveSuccess') }))
  }
  const renderItem = ({ item: tag, drag, index }) => {
    return (
      <SettingListItem
        draggable={draggable}
        key={tag.id}
        onPress={() => {
          setIsEditing(true)
          setTagType(tag.type)
          setSelectedSpecialOption(tag)
        }}
        onDrag={drag}
        divider={index !== tag.length - 1}
      >
        <SettingListItemText text={tag?.name} />
      </SettingListItem>
    )
  }
  const onSave = (tag) => {
    setCustomTagsSetting(draft => {
      draft[tagType].unshift(tag)
    })
  }
  const handleUpdate = (id, field, value) => {
    setCustomTagsSetting(draft => {
      const index = customTagsSetting[tagType].findIndex(tag => tag.id === id)
      draft[tagType][index][field] = value
    })
  }
  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        {
          _.map(customTagsSetting, (value, key) => {
            return (
              <View
                style={{ flex: 1 }}
                key={key}
              >
                <Header>
                  <HeaderText text={t(`app.page.setting.menuPanel.specialOptions.${key}.setting`)} />
                </Header>
                <View style={styles.content}>
                  <SettingList style={{ flex: 1 }}>
                    <DraggableFlatList
                      containerStyle={{ flex: 1 }}
                      showsVerticalScrollIndicator={false}
                      data={value}
                      extraData={customTagsSetting[key]}
                      keyExtractor={item => item.id}
                      renderItem={renderItem}
                      tagType={key}
                      onDragEnd={({ data }) => {
                        setCustomTagsSetting(draft => {
                          draft[key] = data
                        })
                        dispatch(actions.app.updateIsSavedSetting(false))
                      }}
                    />
                    <SettingListFooterAddButton
                      text={t(`app.page.setting.menuPanel.specialOptions.${key}.add`)}
                      onPress={() => {
                        setIsEditing(false)
                        setTagType(key)
                        setSelectedSpecialOption({
                          id: uuid(),
                          name: '',
                          enabled: true,
                          type: key,
                        })
                      }}
                    />
                  </SettingList>
                </View>
              </View>
            )
          })
        }
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <View style={styles.content}>
          <OptionInfo
            key={selectedSpecialOption?.id}
            optionGroup={selectedSpecialOption}
            onClose={() => setSelectedSpecialOption(null)}
            onDelete={onDelete}
            onSave={onSave}
            handleUpdate={handleUpdate}
            isEditing={isEditing}
            type={tagType}
          />
        </View>
        {!isSavedSetting &&
          <View style={styles.buttonContainer}>
            <Button
              textBold
              title={t('app.common.save')}
              style={styles.button}
              onPress={handleSubmit}
              backgroundColor={colors.darkSecondary}
            />
          </View>}
      </View>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
