import { Dimensions, StyleSheet } from 'react-native'
import React from 'react'

import { useSelector } from '@/redux'
import AreaBar from '@/pages/Table/AreaBar'
import TableArea from '@/pages/Table/TableArea'

import CenterModal from '@/components/CenterModal'
import FloorPlan from '@root/src/pages/Table/FloorPlan'
import Row from '@/components/Row'

const IPAD_PRO = Dimensions.get('window').width >= 1366

/**
 *
 * @param {*} props
 * @returns
 */
export default function TableAreaDialog (props) {
  const { open, onClose, onSelectTable, onSelectOrder, requestWaiter = false } = props
  const enableFloorPlan = useSelector(state => state.merchant.data.setting?.enableFloorPlan)

  const handleSelectTable = (table) => {
    onSelectTable(table)
    if (!onSelectOrder) {
      onClose()
    }
  }

  const handleSelectOrder = (order) => {
    onSelectOrder(order)
    onClose()
  }

  // 判斷顯示 FloorPlan/檯號群
  const TableContainer = enableFloorPlan ? FloorPlan : TableArea

  return (
    <CenterModal
      visible={open}
      onClose={onClose}
      noTitle
      enablePressOutsideClose
      contentContainerStyle={{ padding: 0 }}
    >
      <Row style={IPAD_PRO ? styles.largeContainer : styles.container}>
        <AreaBar requestWaiter={requestWaiter} />
        <TableContainer
          disableOrders={!onSelectOrder}
          onSelectOrder={onSelectOrder && handleSelectOrder}
          onSelectTable={onSelectTable && handleSelectTable}
          requestWaiter={requestWaiter}
          forceSrcoll
        />
      </Row>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 600,
    width: 900,
  },
  largeContainer: {
    height: 700,
    width: 1200,
  },
})
