import { useSelector } from '@/redux'
import PictureMenu from './PictureMenu'
import React from 'react'
import TextMenu from './TextMenu'

export default function index (props) {
  const isPictureMode = useSelector(state => state.menu.isPictureMode)

  if (isPictureMode) return <PictureMenu />

  return <TextMenu />
}
