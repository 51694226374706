import { Image, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import PropTypes from 'prop-types'
import React from 'react'

const iconSources = {
  left: require('@icons/scroll-left.png'),
  right: require('@icons/scroll-right.png'),
}

CategoryBarScrollButton.propTypes = {
  onPress: PropTypes.func,
  direction: PropTypes.string,
}

export default function CategoryBarScrollButton (props) {
  const { direction, onPress } = props
  return (
    <TouchableWithoutFeedback style={styles.container} onPress={onPress}>
      <Image style={styles.icon} source={iconSources[direction]} />
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {

  },
  icon: {
    width: 24,
    height: 24,
  },
})
