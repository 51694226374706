import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'

/**
 * @typedef AlertProps
 * @property {boolean?} open
 * @property {() => void} handleClose
 * @property {IAlertConfig} props
 */

/**
 *
 * @param {AlertProps} props
 * @returns
 */
export default function Alert (props) {
  const { open = true, handleClose } = props
  const {
    modalProps,
    title,
    messageAlign = 'center',
    message,
    messages,
    renderMessage,
    button,
    buttons,
    enablePressOutsideClose = true,
  } = props.alertConfig

  const { t } = useTranslation()

  const renderButtons = (buttons, handleClose) => {
    if (!buttons) {
      return (
        <Button
          title={t('app.common.confirm')}
          onPress={handleClose}
        />
      )
    }

    if (typeof buttons === 'string') {
      // buttons is string, put in default button children
      return (
        <Button
          title={buttons}
          onPress={handleClose}
        />
      )
    }

    if (buttons.length > 0) {
      return buttons.map((button, index) => {
        // button is string, put in default button children
        if (typeof button === 'string') {
          return (
            <Button
              key={index}
              title={button}
              onPress={handleClose}
            />
          )
        }

        // button is MUI button props
        const { onPress, ...rest } = button
        return (
          <Button
            key={index}
            onPress={() => {
              onPress && onPress()
              handleClose()
            }}
            {...rest}
          />
        )
      })
    }

    if (typeof buttons === 'object') {
      // not array object, button is MUI button props
      const { onPress, ...rest } = buttons
      return (
        <Button
          onPress={() => {
            onPress && onPress()
            handleClose()
          }}
          {...rest}
        />
      )
    }

    // unset buttons, use default button
    return (
      <Button onPress={handleClose} title='知道了' />
    )
  }

  return (
    <CenterModal
      visible={open}
      onClose={handleClose}
      title={title}
      enablePressOutsideClose={enablePressOutsideClose}
      contentContainerStyle={styles.container}
      {...modalProps}
    >
      {renderMessage ? renderMessage() : (
        <View style={styles.message}>
          <Text
            style={{ textAlign: messageAlign }}
            numberOfLines={messages?.length}
          >
            {
              messages && messages.length > 0
                ? messages.join('\n')
                : message
            }
          </Text>
        </View>
      )}
      <View style={styles.buttons}>
        {renderButtons(buttons || button, handleClose)}
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    minWidth: 450,
    minHeight: 80,
  },
  message: {
    margin: 8,
    alignSelf: 'stretch',
  },
  buttons: {
    alignSelf: 'center',
    flexDirection: 'row-reverse',
    padding: 8,
  },
})
