import { Icon } from 'react-native-elements'
import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SyncOrderProcess (props) {
  const pendingSyncOrderIds = useSelector(state => state.unsyncOrder.pendingOrderIds)
  const syncingOrderId = useSelector(state => state.unsyncOrder.syncingOrderId)
  const unsyncOrderCounts = _.uniq(pendingSyncOrderIds.concat([syncingOrderId].filter(orderId => orderId !== null))).length

  if (!unsyncOrderCounts) {
    return null
  }

  return (
    <View style={styles.container}>
      <Icon
        name='cloud-off'
        type='material'
        color={colors.red}
        size={20}
      />
      <Text style={styles.count}>
        {unsyncOrderCounts}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 20,
    right: 0,
    textAlign: 'center',
  },
  icon: {
    position: 'absolute',
    top: 27,
    right: 50,

  },
  count: {
    textAlign: 'center',
    width: 80,
    color: colors.red,
  },
})
