import moment from 'moment'
import produce from 'immer'

import { dimorderLib } from '@/libs/api/dimorder'
import { initBatch } from '@/redux/order/reducer'
import { v4 as uuid } from 'uuid'
import ActionTypes from './ActionTypes'
import Constants from 'expo-constants'
import _ from 'lodash'

export const initialFilter = {
  deliveryType: [],
  status: [],
  takeawayStatus: [],
  shippingProvider: [],
  displayStatusKey: [],
  table: [],
  paymentMethods: [],
}

/** @type {IOrderHistoryState} */
export const initialState = {
  orders: [],
  selectedOrderId: null,
  selectedOrderItems: [],
  selectedOrderBatches: [],
  query: '',
  filter: initialFilter,
  searchOrder: {},
  initialScrollIndex: 0,
  unconfirmedOrders: [],
}

export default produce(
  /**
   * @param {IOrderHistoryState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.UPSERT_ORDERS: {
        const { orders } = action.payload
        _.forEachRight(orders, order => {
          const draftOrderIndex = draft.orders.findIndex(draftOrder => draftOrder.id === order.id)
          if (draftOrderIndex > -1) {
            draft.orders[draftOrderIndex] = order
          } else {
            draft.orders.unshift(order)
          }
        })
        break
      }
      // ! 整個 orders 將會被蓋掉
      case ActionTypes.UPDATE_ORDERS: {
        const { orders } = action.payload
        if (!orders.find(order => order.id === draft.selectedOrderId)) {
          draft.selectedOrderId = null
        }
        draft.orders = orders
        break
      }
      case ActionTypes.UPDATE_ORDER: {
        const { order } = action.payload
        const index = draft.orders.findIndex(draftOrder => draftOrder.id === order.id)
        if (index >= 0) {
          draft.orders[index] = order
        } else {
          draft.orders.unshift(order)
        }
        break
      }
      case ActionTypes.SELECT_ORDER: {
        const { orderId } = action.payload
        draft.selectedOrderId = orderId
        break
      }
      case ActionTypes.UPDATE_INITIAL_SCROLL_INDEX: {
        const { initialScrollIndex } = action.payload
        draft.initialScrollIndex = initialScrollIndex
        break
      }
      case ActionTypes.SELECT_ORDER_ITEM: {
        const { item } = action.payload
        draft.selectedOrderItems.push(item)
        break
      }
      case ActionTypes.SELECT_ORDER_ITEMS: {
        const { items } = action.payload
        draft.selectedOrderItems = items
        break
      }
      case ActionTypes.SELECT_ORDER_ITEM_MODIFIERS: {
        const { modifiers } = action.payload
        draft.selectedOrderItems[0].modifiers = modifiers
        break
      }
      case ActionTypes.DESELECT_ORDER_ITEM: {
        const { item } = action.payload
        const index = draft.selectedOrderItems.findIndex(selectedOrderItem => selectedOrderItem.id === item.id)
        if (index >= 0) {
          draft.selectedOrderItems.splice(index, 1)
        }
        break
      }
      case ActionTypes.RESET_SELECTED_ORDER_ITEMS: {
        draft.selectedOrderItems = []
        draft.selectedOrderBatches = []
        break
      }
      case ActionTypes.SELECT_ORDER_BATCH: {
        const { batch } = action.payload
        draft.selectedOrderBatches.push(batch)
        break
      }
      case ActionTypes.DESELECT_ORDER_BATCH: {
        const { index } = action.payload
        draft.selectedOrderBatches.splice(index, 1)
        break
      }

      case ActionTypes.UPDATE_FILTER: {
        const { filter } = action.payload
        console.log('UPDATE_FILTER', filter)
        draft.filter = filter
        break
      }
      case ActionTypes.UPDATE_QUERY: {
        const { query } = action.payload
        draft.query = query
        break
      }
      case ActionTypes.UPDATE_SEARCH_ORDER: {
        const { order } = action.payload
        draft.searchOrder = order
        break
      }
      case ActionTypes.ADD_UNCONFIRMED_ORDER: {
        const { serial } = action.payload
        draft.unconfirmedOrders.push(serial)
        break
      }
      case ActionTypes.REMOVE_UNCONFIRMED_ORDER: {
        const { serial } = action.payload
        const index = draft.unconfirmedOrders.findIndex(unconfirmedSerial => unconfirmedSerial === serial)
        if (index > -1) {
          draft.unconfirmedOrders.splice(index, 1)
        }
        break
      }
      case ActionTypes.UPDATE_ORDER_PAYING: {
        const { orderId, isPaying } = action.payload
        const orderIndex = draft.orders.findIndex(order => order.id === orderId)
        if (orderIndex !== -1) {
          draft.orders[orderIndex].paying = isPaying
        }
        break
      }
      case ActionTypes.UPDATE_ORDER_SERVICED: {
        const { orderId, isRequesting } = action.payload
        const orderIndex = draft.orders.findIndex(order => order.id === orderId)
        if (orderIndex !== -1) {
          if (isRequesting) {
            draft.orders[orderIndex].serviced = true
          } else {
            draft.orders[orderIndex].serviced = false
            draft.orders[orderIndex].servicedCustomerId = null
          }
        }
        break
      }
      case ActionTypes.RESTORE_ORDERS: {
        const { orders } = action.payload
        draft.orders = orders
        break
      }
      case ActionTypes.CANCEL_ORDER: {
        const { order, reason, account, approver } = action.payload
        const now = moment()
        const index = draft.orders.findIndex(draftOrder => draftOrder.id === order.id)
        if (index >= 0) {
          draft.orders[index].cancelReason = reason
          draft.orders[index].cancelIdentifier = account
          draft.orders[index].cancelledAt = now.utc().toISOString()
          draft.orders[index].cancelApprover = approver
          draft.orders[index].status = 'cancelled'
        }
        break
      }
      case ActionTypes.CANCEL_SELECTED_ITEMS: {
        const { selectedOrderId, selectedItem, reason, account, quantity, approver } = action.payload
        const now = moment()
        const index = draft.orders.findIndex(draftOrder => draftOrder.id === selectedOrderId)
        const order = draft.orders[index]
        const length = selectedItem.quantity - quantity
        const batchIndex = draft.orders[index].batches.findIndex((batch) => {
          const batchItemId = _.map(batch.items, 'id')
          if (_.includes(batchItemId, selectedItem.id)) {
            return true
          }
        })

        if (index >= 0) {
          const updateValue = {
            cancelledAt: now.utc().toISOString(),
            cancelReason: reason,
            CancelIdentifier: account,
            cancelled: true,
            cancelApprover: approver,
            quantity,
          }

          const itemId = selectedItem.id
          if (selectedItem.isSet) {
            const batchIndex = order.batches.findIndex((batch) => {
              const batchItemId = _.flattenDeep(_.map(batch.items, 'itemIds'))
              if (_.includes(batchItemId, itemId)) {
                return true
              }
            })
            const selectBatch = order.batches[batchIndex]
            const itemIndex = selectBatch.items.findIndex(item => item.id === itemId)
            const selectedItem = selectBatch.items[itemIndex]
            selectBatch.items[itemIndex] = {
              ...selectBatch.items[itemIndex],
              ...updateValue,
            }
            selectedItem.setItems.forEach((setItem, setItemIndex) => {
              selectedItem.setItems[setItemIndex] = {
                ...selectedItem.setItems[setItemIndex],
                ...updateValue,
              }
            })
          } else if (selectedItem.setMenuIndex !== null) {
            draft.orders[index].batches.forEach((batch, setBatchIndex) => {
              batch.items.forEach((item, setIndex) => {
                if (!item.isSet) return
                item.setItems.forEach((setItem, setItemIndex) => {
                  if (setItem.id === selectedItem.id) {
                    if (quantity !== setItem.quantity) {
                      const currentItem = draft.orders[index].batches[setBatchIndex].items[setIndex].setItems[setItemIndex]
                      const selectedSetItem = draft.orders[index].batches[setBatchIndex].items[setIndex].setItems
                      const spliceItemIdsByQuantity = currentItem.itemIds.splice(length)
                      currentItem.ids.splice(length)
                      currentItem.prices.splice(length)
                      currentItem.totals.splice(length)
                      currentItem.id = currentItem.ids[0]
                      currentItem.quantity = setItem.quantity - quantity
                      selectedSetItem.push({
                        ...currentItem,
                        ...updateValue,
                        id: spliceItemIdsByQuantity[0],
                        itemIds: spliceItemIdsByQuantity,
                        ids: spliceItemIdsByQuantity,
                        prices: _.fill(Array(quantity - 1 ?? quantity), currentItem.prices[0]),
                        totals: _.fill(Array(quantity - 1 ?? quantity), currentItem.totals[0]),
                      })
                    } else {
                      draft.orders[index].batches[setBatchIndex].items[setIndex].setItems[setItemIndex] = {
                        ...draft.orders[index].batches[setBatchIndex].items[setIndex].setItems[setItemIndex],
                        ...updateValue,
                      }
                    }
                  }
                })
              })
            })
            break
          } else {
            const itemIndex = draft.orders[index].batches[batchIndex].items.findIndex(item => item.id === selectedItem.id)
            const currrentItem = draft.orders[index].batches[batchIndex].items[itemIndex]
            if (quantity !== currrentItem.quantity) {
              const selectedItem = draft.orders[index].batches[batchIndex].items
              const spliceItemIdsByQuantity = currrentItem.itemIds.splice(length)
              currrentItem.ids.splice(length)
              currrentItem.prices.splice(length)
              currrentItem.totals.splice(length)
              currrentItem.quantity = currrentItem.quantity - quantity
              currrentItem.id = currrentItem.ids[0]
              selectedItem.push({
                ...currrentItem,
                ...updateValue,
                id: spliceItemIdsByQuantity[0],
                itemIds: spliceItemIdsByQuantity,
                ids: spliceItemIdsByQuantity,
                prices: _.fill(Array(quantity - 1 ?? quantity), currrentItem.prices[0]),
                totals: _.fill(Array(quantity - 1 ?? quantity), currrentItem.totals[0]),
              })
            } else {
              draft.orders[index].batches[batchIndex].items[itemIndex] = {
                ...draft.orders[index].batches[batchIndex].items[itemIndex],
                ...updateValue,
              }
            }
          }
        }
        break
      }
      case ActionTypes.UPDATE_ORDER_MODIFIER: {
        const { modifier, selectedOrderId } = action.payload
        const order = draft.orders.find(order => order.id === selectedOrderId)
        const index = order.modifiers.findIndex(m => m.type === modifier.type && m.deletedAt == null)
        if (index >= 0) {
          order.modifiers[index] = modifier
        } else {
          order.modifiers.push(modifier)
        }
        // sort the modifiers by modifier.type in descending order
        order.modifiers.sort((a, b) => {
          if (a.type > b.type) return -1
          if (a.type < b.type) return 1
          return 0
        })
        break
      }
      case ActionTypes.UPDATE_SELECTED_ITEM_MODIFIER: {
        const now = moment()
        const { selectedOrderId, itemId, modifiers, quantity, setKey } = action.payload
        const index = draft.orders.findIndex(draftOrder => draftOrder.id === selectedOrderId)
        const batchIndex = draft.orders[index].batches.findIndex((batch) => {
          const batchItemId = _.map(batch.items, 'id')
          if (_.includes(batchItemId, setKey || itemId)) {
            return true
          }
        })
        let path = 'items'
        if (setKey) {
          const setIndex = draft.orders[index].batches[batchIndex].items.findIndex(item => item.id === setKey)
          path = `items.${setIndex}.setItems`
        }
        const items = _.get(draft.orders[index].batches[batchIndex], path)
        const itemIndex = items.findIndex(item => item.id === itemId)
        const selectedItem = items[itemIndex]
        if (quantity !== selectedItem.quantity) {
          const length = selectedItem.quantity - quantity
          const spliceItemIdsByQuantity = selectedItem.itemIds.splice(length)
          selectedItem.ids.splice(length)
          selectedItem.prices.splice(length)
          selectedItem.totals.splice(length)
          selectedItem.id = selectedItem.ids[0]
          selectedItem.quantity -= quantity
          const updateItem = {
            ...selectedItem,
            id: spliceItemIdsByQuantity[0],
            quantity: quantity,
            itemIds: spliceItemIdsByQuantity,
            ids: spliceItemIdsByQuantity,
            modifiers: modifiers,
            updatedAt: now.utc().toISOString(),
            prices: _.fill(Array(quantity - 1 ?? quantity), selectedItem.prices[0]),
            totals: _.fill(Array(quantity - 1 ?? quantity), selectedItem.totals[0]),
          }
          items.push(updateItem)
          _.set(draft.orders[index].batches[batchIndex], path, items)
        } else {
          selectedItem.modifiers = modifiers
        }
        break
      }
      case ActionTypes.UPDATE_ITEM_SURCHARGE: {
        const { selectedOrderId, itemId, value, quantity } = action.payload
        const now = moment()
        const index = draft.orders.findIndex(draftOrder => draftOrder.id === selectedOrderId)
        const batchIndex = draft.orders[index].batches.findIndex((batch) => {
          const batchItemId = _.map(batch.items, 'id')
          if (_.includes(batchItemId, itemId)) {
            return true
          }
        })

        const itemIndex = draft.orders[index].batches[batchIndex].items.findIndex(item => item.id === itemId)
        const selectedItem = draft.orders[index].batches[batchIndex].items[itemIndex]
        if (quantity !== selectedItem.quantity) {
          const length = selectedItem.quantity - quantity
          const spliceItemIdsByQuantity = selectedItem.itemIds.splice(length)
          selectedItem.ids.splice(length)
          selectedItem.prices.splice(length)
          selectedItem.totals.splice(length)
          selectedItem.id = selectedItem.ids[0]
          selectedItem.quantity -= quantity
          const updateItem = {
            ...selectedItem,
            id: spliceItemIdsByQuantity[0],
            quantity: quantity,
            itemIds: spliceItemIdsByQuantity,
            ids: spliceItemIdsByQuantity,
            excludedOrderSurcharge: value,
            updatedAt: now.utc().toISOString(),
            prices: _.fill(Array(quantity - 1 ?? quantity), selectedItem.prices[0]),
            totals: _.fill(Array(quantity - 1 ?? quantity), selectedItem.totals[0]),
          }
          draft.orders[index].batches[batchIndex].items.push(updateItem)
        } else {
          selectedItem.excludedOrderSurcharge = value
        }
        break
      }
      case ActionTypes.UPDATE_SET_ITEM_SURCHARGE : {
        const { selectedOrderId, setId, value } = action.payload
        const now = moment()
        const index = draft.orders.findIndex(draftOrder => draftOrder.id === selectedOrderId)
        const batchIndex = draft.orders[index].batches.findIndex((batch) => {
          const batchItemId = _.map(batch.items, 'id')

          if (_.includes(batchItemId, setId)) {
            return true
          }
        })

        const setIndex = draft.orders[index].batches[batchIndex].items.findIndex(item => item.id === setId)
        const selectedSet = draft.orders[index].batches[batchIndex].items[setIndex]
        selectedSet.setItems.forEach((setItem, setItemIndex) => {
          const selectedSetItem = draft.orders[index].batches[batchIndex].items[setIndex].setItems[setItemIndex]
          selectedSetItem.excludedOrderSurcharge = value
          selectedSetItem.updatedAt = now.utc().utc().toISOString()
        })
        break
      }
      case ActionTypes.SELECTED_ITEMS_ADD_TAG: {
        const { selectedOrderId, items, selectedItems, newTag } = action.payload
        const order = draft.orders.find(draftOrder => draftOrder.id === selectedOrderId)

        _.forEach(selectedItems, selectedItem => {
          const quantity = items[selectedItem.id]
          if (quantity <= 0) return
          const itemId = selectedItem.id
          const length = selectedItem.quantity - quantity
          if (selectedItem.isSet) {
            const batchIndex = order.batches.findIndex((batch) => {
              const batchItemId = _.flattenDeep(_.map(batch.items, 'itemIds'))
              if (_.includes(batchItemId, itemId)) {
                return true
              }
            })
            const selectBatch = order.batches[batchIndex]
            const itemIndex = selectBatch.items.findIndex(item => item.id === itemId)
            const selectedItem = selectBatch.items[itemIndex]
            selectedItem.setItems.forEach((setItem, setItemIndex) => {
              if (!selectedItem.setItems[setItemIndex].tags.find((tag) => tag.id === newTag.id)) {
                selectedItem.setItems[setItemIndex].tags = [...setItem.tags, newTag]
              }
            })
          } else if (selectedItem.setMenuIndex !== null) {
            order.batches.forEach((batch, setBatchIndex) => {
              batch.items.forEach((item, setIndex) => {
                item.setItems.forEach((setItem, setItemIndex) => {
                  if (setItem.id === selectedItem.id) {
                    if (quantity !== setItem.quantity) {
                      const selectedSet = order.batches[setBatchIndex].items[setIndex]
                      const selectedSetItem = order.batches[setBatchIndex].items[setIndex].setItems[setItemIndex]
                      const spliceItemIdsByQuantity = selectedSetItem.itemIds.splice(length)
                      selectedSetItem.ids.splice(length)
                      selectedSetItem.prices.splice(length)
                      selectedSetItem.totals.splice(length)
                      selectedSetItem.id = selectedSetItem.ids[0]
                      selectedSetItem.quantity -= quantity
                      const itemWithServeTag = {
                        ...selectedSetItem,
                        id: spliceItemIdsByQuantity[0],
                        quantity: quantity,
                        itemIds: spliceItemIdsByQuantity,
                        ids: spliceItemIdsByQuantity,
                        tags: [...selectedSetItem.tags, newTag],
                        prices: _.fill(Array(quantity - 1 ?? quantity), selectedSetItem.prices[0]),
                        totals: _.fill(Array(quantity - 1 ?? quantity), selectedSetItem.totals[0]),
                      }
                      if (!itemWithServeTag.tags.find((tag) => tag.id === newTag.id)) {
                        itemWithServeTag.tags = [...itemWithServeTag.tags, newTag]
                      }
                      selectedSet.setItems.push(itemWithServeTag)
                    } else {
                      const selectedSetItem = order.batches[setBatchIndex].items[setIndex].setItems[setItemIndex]
                      if (!selectedSetItem.tags.find((tag) => tag.id === newTag.id)) {
                        selectedSetItem.tags = [...selectedSetItem.tags, newTag]
                      }
                    }
                  }
                })
              })
            })
          } else {
            const batchIndex = order.batches.findIndex((batch) => {
              const batchItemId = _.flattenDeep(_.map(batch.items, 'itemIds'))
              if (_.includes(batchItemId, itemId)) {
                return true
              }
            })
            const selectBatch = order.batches[batchIndex]
            const itemIndex = order.batches[batchIndex].items.findIndex(item => item.id === itemId)
            const selectedItem = order.batches[batchIndex].items[itemIndex]
            if (selectedItem.quantity === quantity) {
              if (!selectedItem.tags.find((tag) => tag.id === newTag.id)) {
                selectedItem.tags = [...selectedItem.tags, newTag]
              }
            } else {
              const spliceItemIdsByQuantity = selectedItem.itemIds.splice(length)
              selectedItem.ids.splice(length)
              selectedItem.prices.splice(length)
              selectedItem.totals.splice(length)
              selectedItem.quantity -= quantity
              selectedItem.id = selectedItem.ids[0]
              const itemWithServeTag = {
                ...selectedItem,
                id: spliceItemIdsByQuantity[0],
                quantity: quantity,
                itemIds: spliceItemIdsByQuantity,
                ids: spliceItemIdsByQuantity,
                prices: _.fill(Array(quantity - 1 ?? quantity), selectedItem.prices[0]),
                totals: _.fill(Array(quantity - 1 ?? quantity), selectedItem.totals[0]),
              }
              if (!itemWithServeTag.tags.find((tag) => tag.id === newTag.id)) {
                itemWithServeTag.tags = [...itemWithServeTag.tags, newTag]
              }
              selectBatch.items.push(itemWithServeTag)
            }
          }
        })
        break
      }
      case ActionTypes.TRANSFER_ITEM: {
        const { fromOrderId, selectedItems, quantity, toOrderId, submittedAt, account } = action.payload
        const now = moment()
        const newBatchId = uuid()
        const targetOrderIndex = draft.orders.findIndex(draftOrder => draftOrder.id === toOrderId)
        const targetOrder = draft.orders[targetOrderIndex]
        const transferItems = []
        selectedItems.forEach(selectedItem => {
          const batchId = selectedItem.batchId

          const sourceOrderIndex = draft.orders.findIndex(draftOrder => draftOrder.id === fromOrderId)

          const sourceOrder = draft.orders[sourceOrderIndex]
          const sourceOrderBatchIndex = sourceOrder.batches.findIndex(draftBatch => draftBatch.batchId === batchId)

          const sourceBatch = sourceOrder.batches[sourceOrderBatchIndex]
          const sourceOrderItemIndex = sourceBatch.items.findIndex(item => _.includes(item.itemIds, selectedItem.id))
          const sourceOrderItem = sourceBatch.items[sourceOrderItemIndex]
          const currentQuantity = quantity[selectedItem.id] ?? 1
          const length = sourceOrderItem.quantity - currentQuantity
          if (sourceOrderItem.quantity !== currentQuantity) {
            const spliceItemIdsByQuantity = sourceOrderItem.itemIds.splice(length)
            sourceOrderItem.ids.splice(length)
            sourceOrderItem.prices.splice(length)
            sourceOrderItem.totals.splice(length)
            sourceOrderItem.quantity -= currentQuantity
            sourceOrderItem.id = sourceOrderItem.ids[0]
            transferItems.push({
              ...sourceOrderItem,
              quantity: currentQuantity,
              submittedAt,
              itemIds: spliceItemIdsByQuantity,
              ids: spliceItemIdsByQuantity,
              id: spliceItemIdsByQuantity[0],
              prices: _.fill(Array(currentQuantity), sourceOrderItem.prices[0]),
              totals: _.fill(Array(currentQuantity), sourceOrderItem.totals[0]),
            })
          } else {
            sourceBatch.items.splice(sourceOrderItemIndex, 1)

            transferItems.push(sourceOrderItem)
          }

          if (_.isEmpty(sourceBatch.items)) {
            sourceBatch.status = 'cancelled'
            // sourceOrder.batches.splice(sourceOrderBatchIndex, 1)
          }
        })

        const merchantId = targetOrder.merchantId
        const newBatch = {
          ...initBatch,
          id: newBatchId,
          orderId: targetOrder.id,
          orderSerial: targetOrder.serial,
          batchId: newBatchId,
          index: targetOrder.batches.length,
          table: targetOrder.table,
          status: 'confirmed',
          items: dimorderLib.groupBatchItem(transferItems),
          createdAt: now.utc().toISOString(),
          updatedAt: now.utc().toISOString(),
          identifier: account,
          creatorId: merchantId,
        }
        targetOrder.batches.push(newBatch)
        break
      }
      case ActionTypes.UPDATE_CUSTOMER_COUNT: {
        const { selectedOrderId, adults, children } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === selectedOrderId)
        const order = draft.orders[orderIndex]
        order.adults = Number(adults)
        order.children = Number(children)
        order.customers = Number(adults) + Number(children)
        break
      }
      case ActionTypes.CONFIRM_BATCH: {
        const now = moment()

        const { orderId, batch } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const order = draft.orders[orderIndex]
        const deviceId = Constants.installationId
        let batchIndex = order.batches.findIndex(b => b.id === batch.id)

        if (batchIndex === -1) {
          // 當從本地訂單找不到 batch 時，將這個 batch 新增至本地訂單並更新 batchIndex
          // push 會 return length
          batchIndex = order.batches.push(batch) - 1
        }

        const selectedBatch = order.batches[batchIndex]

        if (batch.status === 'submitted') {
          selectedBatch.status = 'confirmed'
          selectedBatch.updatedAt = now.utc().toISOString()
          selectedBatch.confirmedat = now.utc().toISOString()
          selectedBatch.confirmByDeviceId = deviceId
        }
        break
      }
      case ActionTypes.VOID_PAYMENT: {
        const now = moment()
        const { orderId, paymentId, reason } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const order = draft.orders[orderIndex]
        const paymentIndex = order.payments.findIndex(payment => payment.id === paymentId)
        const payment = order.payments[paymentIndex]
        payment.status = 'cancel'
        payment.reason = reason
        payment.cancelledAt = now.utc().toISOString()
        order.status = 'pending'
        break
      }
      case ActionTypes.UPDATE_PAYMENT: {
        const { orderId, paymentId, data } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const order = draft.orders[orderIndex]
        const paymentIndex = order.payments.findIndex(payment => payment.id === paymentId)
        const payment = order.payments[paymentIndex]
        _.assign(payment, data)
        break
      }
      case ActionTypes.ADD_PAYMENT: {
        const { orderId, payment } = action.payload
        const order = draft.orders.find(o => o.id === orderId)
        // 將 payment 加入 payments
        order.payments.push(payment)

        // 檢查是否變成已付款
        const paidPayments = order.payments.filter(payment => payment.status === 'paid')
        const totalPaidAmount10x = _.sumBy(paidPayments, (paidPayment) => paidPayment.paidAmount * 10)
        const roundedTotal10x = order.roundedTotal * 10
        const isPaid = Math.round(totalPaidAmount10x * 100) / 100 >= roundedTotal10x
        if (isPaid) {
          // 訂單已付款，更改 order level 的付款相關狀態
          order.paying = false // 關閉 request paying
          order.status = 'paid' // 改成已付款狀態
          order.paidAt = payment.paidAt // 將 paidAt 設為最後一筆 payment 的 paidAt
        }
        break
      }
      case ActionTypes.COMPLETE_ORDER: {
        const now = moment()
        const { orderId } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const order = draft.orders[orderIndex]
        order.takeawayStatus = 'completed'
        order.updatedAt = now.utc().toISOString()
        break
      }
      case ActionTypes.READY_ORDER: {
        const now = moment()
        const { orderId } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const order = draft.orders[orderIndex]
        order.takeawayStatus = 'ready'
        order.updatedAt = now.utc().toISOString()
        break
      }
      case ActionTypes.MERGE_ORDER: {
        const now = moment()
        const { mainOrderId, mergeOrderId } = action.payload

        const mergeOrder = draft.orders.find(o => o.id === mergeOrderId)
        const mainOrder = draft.orders.find(o => o.id === mainOrderId)

        mainOrder.batches = mainOrder.batches.concat(mergeOrder.batches)
        mainOrder.updatedAt = now.utc().toISOString()

        mergeOrder.referorderid = mainOrder.id
        mergeOrder.isMerged = true
        mergeOrder.status = 'cancelled'
        mergeOrder.updatedAt = now.utc().toISOString()
        mergeOrder.batches = []
        mergeOrder.valid = false

        break
      }
      case ActionTypes.UPDATE_PRINTED: {
        const { orderId, batchId, itemId, printed, printError } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const batchIndex = draft.orders[orderIndex].batches.findIndex(b => b.id === batchId)
        const itemIndex = draft.orders[orderIndex].batches[batchIndex].items.findIndex(i => i.id === itemId)
        const item = draft.orders[orderIndex].batches[batchIndex].items[itemIndex]
        if (!item.printed) {
          item.printed = printed
        }
        item.printError = printError
        break
      }
      case ActionTypes.CANCEL_BATCH: {
        const { orderId, batchId } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const order = draft.orders[orderIndex]
        const batchIndex = order.batches.findIndex(b => b.id === batchId)
        order.batches[batchIndex].status = 'cancelled'
        break
      }
      case ActionTypes.UPDATE_ORDER_REMARK: {
        const { orderId, remark } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const order = draft.orders[orderIndex]
        order.remark = remark
        break
      }
      case ActionTypes.UPDATE_ORDERS_SYNC: {
        const { orderIds } = action.payload
        orderIds.forEach(orderId => {
          const orderIndex = draft.orders.findIndex(o => o.id === orderId)
          const order = draft.orders[orderIndex]
          order.version = order.version + 1
        })
        break
      }
      case ActionTypes.UPDATE_ORDER_OWNER: {
        const { orderId, phone, name } = action.payload
        const orderIndex = draft.orders.findIndex(o => o.id === orderId)
        const order = draft.orders[orderIndex]

        order.phone = phone
        order.name = name
        order.usePhoneInCRM = true

        break
      }

      default: {
        return draft
      }
    }
  },
  initialState,
)
