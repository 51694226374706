import { FlatList, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React, { memo } from 'react'
import _ from 'lodash'

import { dimorderApi } from '@/libs/api/dimorder'
import { getActiveFilterNames, getQueryName, searchAndFilterOrders } from '@/libs/orderFilters'
import Column from '@/components/Column'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import OrderItem from './OrderItem'

const Item = ({ order, index }) => {
  const dispatch = useDispatch()
  return (
    <OrderItem
      order={order}
      index={index}
      onSelect={(order) => {
        dispatch(actions.orderHistory.selectOrder(order.id))
      }}
    />
  )
}

const renderItem = ({ item: order, index }) => {
  return (
    <MemoizedItem
      order={order}
      index={index}
    />
  )
}

const MemoizedItem = memo(Item, _.isEqual)

export default function OrderList () {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const historyOrders = useSelector(state => state.orderHistory.orders)
  const filter = useSelector(state => state.orderHistory.filter)
  const query = useSelector(state => state.orderHistory.query)
  const activeFilters = [getQueryName(query), getActiveFilterNames(filter)].filter(f => f).join(', ')
  const filteredOrders = React.useMemo(() => _.orderBy(searchAndFilterOrders(historyOrders, query, filter), ['createdAt'], ['desc']), [historyOrders, query, filter])
  const [orderList, setOrderList] = React.useState(filteredOrders)
  const initialScrollIndex = useSelector(state => state.orderHistory.initialScrollIndex)
  const isEmpty = historyOrders.length === 0 || orderList.length === 0

  React.useEffect(() => {
    const format = /^[0-9]{3}-[0-9]{5}|[0-9]{3}-[0-9]{4}$/
    if (format.test(query)) {
      filtered()
    } else {
      setOrderList(filteredOrders)
      dispatch(actions.orderHistory.setSearchOrder([]))
    }
  }, [query, filter])

  React.useEffect(() => {
    setOrderList(filteredOrders)
  }, [historyOrders])

  async function filtered () {
    try {
      const result = await dimorderApi.order.getOrderByInfo({ serial: query })
      dispatch(actions.orderHistory.updateOrder(result, { selectOrder: true, syncOrder: false }))
      setOrderList([result])
      dispatch(actions.orderHistory.setSearchOrder(result))
    } catch (err) {
      setOrderList([])
      dispatch(actions.orderHistory.selectOrder(null))
    }
  }

  return (
    <Column style={styles.container}>
      {activeFilters !== '' && (
        <Row style={styles.filter}>
          <Text style={styles.filterText}>{t('app.page.orderHistory.filterPanel.filter')}：{activeFilters}</Text>
          <TouchableOpacity onPress={() => {
            dispatch(actions.orderHistory.resetFilter())
            dispatch(actions.orderHistory.updateQuery(''))
          }}
          >
            <Text style={styles.clearText}>{t('app.common.clearAll')}</Text>
          </TouchableOpacity>
        </Row>)}
      <FlatList
        maxToRenderPerBatch={7}
        initialNumToRender={7}
        data={orderList}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        showsVerticalScrollIndicator={false}
        extraData={orderList}
        initialScrollIndex={_.min([initialScrollIndex, _.size(orderList) - 1])}
        contentContainerStyle={(isEmpty) && {
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        ListEmptyComponent={<Text style={styles.text}>{t('app.page.orderHistory.orderList.empty')}</Text>}
        getItemLayout={(data, index) => ({ length: 110, offset: index * 110, index })}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 400,
    height: '100%',
    backgroundColor: colors.light,
    zIndex: 1,
    ...shadows.default,
  },
  filter: {
    height: 30,
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 18,
    backgroundColor: colors.lightGray3,
  },
  filterText: {
    flex: 1,
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.primary,
  },
  clearText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.textTertiary,
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.textTertiary,
  },
})
