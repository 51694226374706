import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Column from '@/components/Column'
import IconLabel from './IconLabel'
import React from 'react'
import Row from '@/components/Row'
import TopRightTag from './TopRightTag'
import colors from '@/theme/colors'
import moment from 'moment'
import shadows from '@/theme/shadows'

export default function BookingItem ({
  booking: {
    id,
    bookingAt,
    table,
    name,
    phone,
    adults,
    children,
  },
  onPress,
}) {
  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      {/* Booking time */}
      <View style={styles.timeContainer}>
        <Text style={styles.timeText}>{moment(bookingAt).format('HH:mm')}</Text>
      </View>

      {/* Booking info */}
      <Column style={styles.contentContainer}>
        <IconLabel text={table} iconSource={require('@icons/table/table-origin.png')} />
        <Row>
          <IconLabel
            text={adults}
            iconSource={require('@icons/table/adult.png')}
            style={styles.adultIcon}
          />
          {children > 0 &&
            <IconLabel
              text={children}
              iconSource={require('@icons/table/child.png')}
            />}
        </Row>
        <Text style={styles.nameText}>{name}</Text>
      </Column>

      {/* Phone or Booking id */}
      <TopRightTag
        text={!phone || phone === '' ? id : phone}
        style={styles.tag}
        showPhoneIcon={phone && phone !== ''}
      />

    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 87,
    width: 80,
    marginRight: 10,
  },
  timeContainer: {
    height: 30,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: colors.gray,
    borderRadius: 5,
    ...shadows.top,
    zIndex: 1,
    elevation: 1,
  },
  contentContainer: {
    height: 67,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    borderRadius: 5,
    padding: 6,
    ...shadows.top,
    zIndex: 2,
    elevation: 2,
  },
  adultIcon: {
    marginRight: 6,
  },
  timeText: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.white,
    marginVertical: 2.5,
    marginHorizontal: 6,
  },
  nameText: {
    fontSize: 15,
    fontWeight: '500',
    color: colors.textPrimary,
  },
  tag: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 3,
    elevation: 3,
  },
})
