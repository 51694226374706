import _ from 'lodash'

import {
  PrintMethod,
  PrinterBrand,
  PrinterCommand,
  PrinterFontSize,
  PrinterType,
  PrinterUsage,
  StyleFontSizeToPrinterFontSizeMap,
} from '@/constants/printer'
import Printer from '@/libs/printing/printer/Printer'
import store from '@/redux/store'

import ImageMode from '../BmpImage/ImageMode'
import getImageCommand from '../BmpImage/getImageCommand'
import straightLine from '../BmpImage/straightLine'

/** @type {() => IRootState} */
const getState = store.getState

/**
 * ! 不使用
 * STAR熱敏打印機
 * encoding - 不適用
 * command: STAR Graphic
 * 用途：廚房單／收據
 * Printer Programming Manual: https://drive.google.com/drive/folders/1rQKQpbzN181W7zv6GAQfjhV1-ZRicKE9
 */
export default class TSP100III extends Printer {
  TYPE = PrinterType.TSP100III
  PRINTER_BRAND = PrinterBrand.STAR
  PRINTER_COMMAND = PrinterCommand.STAR_GRAPHIC
  PRINT_METHOD = PrintMethod.THERMAL
  USAGES = [PrinterUsage.KITCHEN_BATCH, PrinterUsage.ORDER_RECEIPT]
  MAX_WIDTH = 72
  DOT_WIDTH = 576

  imageMode = new ImageMode(this.DOT_WIDTH, this.TYPE)

  addInit (cut = true) {
    this._rasterMode()
    this._pageLength()
    if (!cut) {
      this.cutPaperAtEnd()
    }
  }

  _rasterMode () {
    // ESC * r A (Enter raster mode)
    this.writeNumbers([27, 42, 114, 65])
    return this
  }

  _pageLength (n = 0) {
    // ESC * r P n NUL (Set raster page length)
    // 200 ≦ n ≦ 64000 (0: Continuous printing mode)
    this.writeNumbers([27, 42, 114, 80])
    this._addASCII(n)
    this.writeNumbers([0])
    return this
  }

  /**
   *
   * @param {number} number
   * @returns
   */
  _addASCII (number) {
    const array = []
    const cmd = String(number)
    const length = cmd.length
    for (let i = 0; i < length; i++) {
      array.push(cmd.charCodeAt(i))
    }
    this.writeNumbers(array)
    return this
  }

  /**
   * ! NOT-support
   * @returns
   */
  addCodePage () {
    return this
  }

  /**
   *
   * @param {string} text
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @param {boolean?} larger
   * @returns
   */
  addText (text, fontSize = 0, larger = true) {
    console.log(`text(style=${fontSize}): `, text)
    const image = this.imageMode
    const _fontSize = larger ? this._getLargerSize(fontSize) : fontSize
    image.addText(text, _fontSize)
    return this
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @returns
   */
  _getLargerSize (fontSize) {
    let size = 0
    switch (fontSize) {
      case PrinterFontSize.SMALL:
      case 0:
        size = 1
        break
      case PrinterFontSize.MEDIUM:
      case PrinterFontSize.MEDIUWIDTH:
      case 3:
      case 1:
        size = 2
        break
      case PrinterFontSize.LARGE:
      case PrinterFontSize.LARGEWIDTH:
      case 4:
      case 2:
        size = 2
        break
      default:
        size = 1
    }
    return size
  }

  /**
   * @deprecated
   * @param {string} text
   * @returns
   */
  _getTextAlign (text) {
    if (!text) {
      return ImageMode.Align.LEFT
    }
    const lastIndex = _.findLastIndex(text)
    const leftSpace = /\s/g.test(text[0])
    const rightSpace = /\s/g.test(text[lastIndex])
    if (leftSpace && rightSpace) {
      return ImageMode.Align.CENTER
    }
    if (leftSpace) {
      return ImageMode.Align.RIGTH
    }
    return ImageMode.Align.LEFT
  }

  addImage () {
    const image = _.get(getState(), 'app.settings.logo.data', [])
    const command = getImageCommand(image, 'b')
    this.writeNumbers(command)
    return this
  }

  addLogo () {
    // 其他 printer 的 command 可以把圖片變成 4 倍列印但是 TSP100III 不行
    // 為了要印出一樣大小的 logo 需使用 logo.quadrupleData
    const logo = _.get(getState(), 'app.settings.logo.quadrupleData', [])
    const command = this.imageMode.addImage(logo, ImageMode.Align.CENTER)
    this.writeNumbers(command)
    return this
  }

  addBoldMode (on = true) {
    const image = this.imageMode
    image.addBoldMode(on)
    return this
  }

  addUnderlineMode (on = true) {
    const image = this.imageMode
    image.addUnderlineMode(on)
    return this
  }

  /**
   *
   * @param {'left' | 'center' | 'right'} align
   * @returns
   */
  addAlign (align) {
    const image = this.imageMode
    image.addAlign(align)
    return this
  }

  addRowFeed (rows = 1) {
    const image = this.imageMode
    const command = image.printText()
    this.writeNumbers(command)
    // ESC * r Y n NUL (Moving position in the vertical direction)
    this.writeNumbers([27, 42, 114, 89])
    this._addASCII(rows * 5)
    this.writeNumbers([0])
    return this
  }

  addFixedRow (texts, widths, styleText = 0, rowFeed = 1, align = [], larger = true) {
    console.log(`text(style=${styleText}): `, texts)
    const image = this.imageMode
    const fontSize = larger ? this._getLargerSize(styleText) : styleText
    const command = image.addFixedRow(texts, widths, fontSize, align)
    this.writeNumbers(command)
    this.addRowFeed(rowFeed)
    return this
  }

  addTextConnect () {
    this.addInit(false)
    this.addRowFeed()
    this._endPrinting()
  }

  _endPrinting () {
    // ESC * r B (Quit raster mode)
    this.writeNumbers([27, 42, 114, 66])
    return this
  }

  addUnderline (character = '-') {
    this
      .addFixedRow(
        [character.repeat(this.MAX_WIDTH)],
        [1],
        PrinterFontSize.SMALL,
        1,
        [],
        false,
      )
    return this
  }

  /**
   * 用於自訂收據單（CustomizedOrderReceipt）的分隔線選項
   * @returns
   */
  addStraightLine () {
    const command = getImageCommand(straightLine(this.DOT_WIDTH, true), 'b')
    this.writeNumbers(command)
    return this
  }

  /**
   * ! NOT-support
   * @returns
   */
  addTab () {
    return this
  }

  /**
   * ! NOT-support
   * @returns
   */
  addSetTabPosition () {
    return this
  }

  addCutPaper () {
    // ESC FF NUL (Execute FF mode)
    this.writeNumbers([27, 12, 0])
    return this
  }

  cutPaperAtEnd () {
    // ESC * r E n NUL (Set raster EOT mode)
    this.writeNumbers([27, 42, 114, 69, 49, 0])
    return this
  }

  addPrintTest () {
    this.addInit(true)
    this.addText('Print Test')
    this.addRowFeed(3)
    this._endPrinting()
  }

  /**
   * ! NOT-support
   * @returns
   */
  drawerKick () {
    return this
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @param {'thai'?} batchLocale
   * @returns
   */
  getItemMaxWidth (fontSize, batchLocale) {
    const thaiWidths = [70, 30, 16, 30, 16, 16]
    const widths = [32, 12, 5, 12, 5, 5]
    if (batchLocale === 'thai') {
      return thaiWidths[fontSize] ?? thaiWidths[0]
    }
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @returns
   */
  getPriceMaxWidth (fontSize) {
    const widths = [7, 6, 5, 6, 5, 3]
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @returns
   */
  getQuantityMaxWidth (fontSize) {
    const widths = [5, 4, 4, 4, 4, 3]
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @returns
   */
  getReceiptTextWidth (fontSize) {
    const DEFAULT_SIZE = PrinterFontSize.SMALL
    const itemWidths = {
      [PrinterFontSize.SMALL]: [32, 6, 10],
      [PrinterFontSize.MEDIUM]: [14, 4, 6],
      [PrinterFontSize.LARGE]: [6, 4, 6],
      [PrinterFontSize.MEDIUWIDTH]: [14, 4, 6],
      [PrinterFontSize.LARGEWIDTH]: [6, 4, 6],
    }
    // const subtotalWidths = {
    //   [PrinterFontSize.SMALL]: [26, 12, 10],
    //   [PrinterFontSize.MEDIUM]: [4, 12, 8],
    //   [PrinterFontSize.LARGE]: [1, 9, 6],
    //   [PrinterFontSize.MEDIUWIDTH]: [4, 12, 8],
    //   [PrinterFontSize.LARGEWIDTH]: [1, 9, 6],
    // }

    let key = DEFAULT_SIZE
    if (typeof fontSize === 'number') {
      key = StyleFontSizeToPrinterFontSizeMap[fontSize]
    } else if (typeof fontSize === 'string') {
      key = fontSize
    }

    return {
      itemWidths: itemWidths[key] ?? itemWidths[DEFAULT_SIZE],
      subtotalWidths: itemWidths[key] ?? itemWidths[DEFAULT_SIZE],
    }
  }

  /**
   *
   * @param {0 | 1 | 2 | 'small' | 'medium' | 'large'} fontSize
   * @returns
   */
  getReportTextWidth (fontSize) {
    const DEFAULT_SIZE = PrinterFontSize.SMALL
    const itemWidths = {
      [PrinterFontSize.SMALL]: [20, 10, 18],
      [PrinterFontSize.MEDIUM]: [10, 5, 9],
      [PrinterFontSize.LARGE]: [7, 3, 6],
    }
    const cancelWidths = {
      [PrinterFontSize.SMALL]: [20, 4, 12, 12],
      [PrinterFontSize.MEDIUM]: [10, 2, 6, 6],
      [PrinterFontSize.LARGE]: [6, 2, 4, 4],
    }
    const contentPrintWidths = {
      [PrinterFontSize.SMALL]: [16, 16, 16],
      [PrinterFontSize.MEDIUM]: [8, 8, 8],
      [PrinterFontSize.LARGE]: [6, 5, 5],
    }
    const twoItemsWidths = {
      [PrinterFontSize.SMALL]: [22, 4, 22],
      [PrinterFontSize.MEDIUM]: [11, 2, 11],
      [PrinterFontSize.LARGE]: [7, 2, 7],
    }

    let key = DEFAULT_SIZE
    if (typeof fontSize === 'number') {
      key = StyleFontSizeToPrinterFontSizeMap[fontSize]
    } else if (typeof fontSize === 'string') {
      key = fontSize
    }

    return {
      itemWidths: itemWidths[key] ?? itemWidths[DEFAULT_SIZE],
      cancelWidths: cancelWidths[key] ?? cancelWidths[DEFAULT_SIZE],
      contentPrintWidths: contentPrintWidths[key] ?? contentPrintWidths[DEFAULT_SIZE],
      twoItemsWidths: twoItemsWidths[key] ?? twoItemsWidths[DEFAULT_SIZE],
    }
  }

  /**
   * 黑白反轉列印功能
   * @deprecated 沒有使用
   * @param {boolean?} on
   * @returns
   */
  addBWInvertMode (on = true) {
    const image = this.imageMode
    image.addBWInvertMode(on)
    return this
  }

  /**
   * ! NOT-support
   * @deprecated
   * @returns
   */
  enableASB () {
    return this
  }
}
