import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/* eslint-disable no-unused-vars */
import { IAppBatchItemOption } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IBatchHistory } from '@/redux/orderBatch/OrderBatchState.d'
import { ITag } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

export const initBatchHistory = {
  preBatchHistories: [],
  pastBatchHistories: [],
}

/** @type {IOrderBatchState} */
export const initialState = {
  batchStashes: [],
  batchHistory: initBatchHistory,
  selectedBatch: null,
  menuItemQuantities: {},
  selectedItemKey: null,
  selectedSetItemKey: null,
}

/**
 * @param {IOrderBatchState} draft
 * @returns {IBatchHistory}
 */
function createBatchHistory (draft, itemKey, setItemKey) {
  return {
    selectedItemKey: itemKey === undefined ? draft.selectedItemKey : itemKey,
    selectedSetItemKey: setItemKey === undefined ? draft.selectedSetItemKey : setItemKey,
    batch: _.cloneDeep(draft.selectedBatch),
    menuItemQuantities: _.cloneDeep(draft.menuItemQuantities),
    createdAt: new Date(),
  }
}

/**
 * @param {IOrderBatchState} draft
 * @param {boolean?} noHistory
 */
function storeHistory (draft, noHistory = false) {
  // 不紀錄歷史
  if (noHistory) return

  // 記錄歷史
  const history = createBatchHistory(draft)
  draft.batchHistory.preBatchHistories.push(history)
  // 清除 REDO
  draft.batchHistory.pastBatchHistories = []
}

export default produce(
  /**
   * @param {IOrderBatchState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.SHOW_NOT_COMPLETE_SETS: {
        const { showNotCompleteSets } = action.payload
        draft.selectedBatch.showNotCompleteSets = showNotCompleteSets
        break
      }
      case ActionTypes.UNDO_BATCH: {
        // 將現在狀態放入 pastBatchHistory 的第一個
        const history = createBatchHistory(draft)
        draft.batchHistory.pastBatchHistories.unshift(history)

        // 拿出最後一個 preBatchHistories，並用覆寫目前的 batch
        const preBatchHistory = draft.batchHistory.preBatchHistories.pop()
        draft.selectedBatch = _.cloneDeep(preBatchHistory.batch)
        draft.menuItemQuantities = _.cloneDeep(preBatchHistory.menuItemQuantities)
        break
      }
      case ActionTypes.REDO_BATCH: {
        // 將現在狀態放入 preBatchHistories 的最後
        const history = createBatchHistory(draft)
        draft.batchHistory.preBatchHistories.push(history)

        // 拿出第一個 pastBatchHistories batch
        const pastBatchHistory = draft.batchHistory.pastBatchHistories.shift()
        draft.selectedBatch = _.cloneDeep(pastBatchHistory.batch)
        draft.menuItemQuantities = _.cloneDeep(pastBatchHistory.menuItemQuantities)
        break
      }
      case ActionTypes.SELECT_BATCH: {
        const { batch } = action.payload

        // reset batch history
        draft.batchHistory = initBatchHistory
        draft.selectedBatch = batch

        // 重新統計 menuItemQuantities
        const menuItemQuantities = {}
        batch.items.forEach(item => {
          menuItemQuantities[item.menuId] = (menuItemQuantities[item.menuId] || 0) + item.quantity
          item.setItems.forEach(setItem => {
            menuItemQuantities[setItem.menuId] = (menuItemQuantities[setItem.menuId] || 0) + setItem.quantity
          })
        })
        draft.menuItemQuantities = menuItemQuantities
        break
      }
      case ActionTypes.ADD_ITEM: {
        const { item, noHistory } = action.payload

        storeHistory(draft, noHistory)

        // 新增項目
        draft.selectedBatch.items.push(item)
        // 記錄餐點數量
        draft.menuItemQuantities[item.menuId] = (draft.menuItemQuantities[item.menuId] || 0) + item.quantity
        break
      }
      case ActionTypes.ADD_SET_ITEM: {
        const { item, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        storeHistory(draft, noHistory)

        // 新增項目
        selectedItem.setItems.push(item)
        // 記錄餐點數量
        draft.menuItemQuantities[item.menuId] = (draft.menuItemQuantities[item.menuId] || 0) + item.quantity
        break
      }
      case ActionTypes.SELECT_ITEM: {
        const { itemKey } = action.payload
        draft.selectedItemKey = itemKey
        break
      }
      case ActionTypes.SELECT_SET_ITEM: {
        const { setItemKey } = action.payload
        draft.selectedSetItemKey = setItemKey
        break
      }
      case ActionTypes.UPDATE_ITEM: {
        const { path, value, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        if (!selectedItem) return

        storeHistory(draft, noHistory)

        // 如果是更改數量，記錄餐點數量
        if (path === 'quantity' || path[0] === 'quantity') {
          const originQuantity = _.get(selectedItem, path)
          draft.menuItemQuantities[selectedItem.menuId] -= originQuantity
          draft.menuItemQuantities[selectedItem.menuId] += value
        }

        // 更改項目
        _.set(selectedItem, path, value)
        break
      }
      case ActionTypes.UPDATE_SET_ITEM: {
        const { path, value, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)

        storeHistory(draft, noHistory)

        // 如果是更改數量，記錄餐點數量
        if (path === 'quantity' || path[0] === 'quantity') {
          const originQuantity = _.get(selectedSetItem, path)
          draft.menuItemQuantities[selectedSetItem.menuId] -= originQuantity
          draft.menuItemQuantities[selectedSetItem.menuId] += value
        }

        // 更改項目
        _.set(selectedSetItem, path, value)
        break
      }
      case ActionTypes.RESET_ITEMS: {
        storeHistory(draft)

        // 清除所有 items
        draft.selectedBatch.items = []
        // 清除餐點數量
        draft.menuItemQuantities = {}
        break
      }
      case ActionTypes.DELETE_ITEM: {
        const { index, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items[index]

        storeHistory(draft, noHistory)

        // 扣除餐點數量
        selectedItem.setItems.forEach(setItem => {
          draft.menuItemQuantities[setItem.menuId] -= setItem.quantity
        })
        draft.menuItemQuantities[selectedItem.menuId] -= selectedItem.quantity

        // 刪除
        draft.selectedBatch.items.splice(index, 1)
        break
      }
      case ActionTypes.DELETE_ITEM_BY_MENU_ID: {
        const { menuId } = action.payload
        draft.selectedBatch.items.filter(item => item.menuId !== menuId)
        break
      }
      case ActionTypes.DELETE_SET_ITEM: {
        const { index, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems[index]

        storeHistory(draft, noHistory)

        // 扣除餐點數量
        draft.menuItemQuantities[selectedSetItem.menuId] -= selectedSetItem.quantity

        // 刪除
        selectedItem.setItems.splice(index, 1)
        break
      }
      case ActionTypes.ADD_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        storeHistory(draft, noHistory)

        // 新增 option
        selectedItem.options.push(option)
        break
      }
      case ActionTypes.ADD_SET_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)

        storeHistory(draft, noHistory)

        // 新增 option
        selectedSetItem.options.push(option)
        break
      }
      case ActionTypes.UPDATE_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedItemOptionIndex = selectedItem.options.findIndex(selectedItemOption => selectedItemOption.optionGroupId === option.optionGroupId)

        storeHistory(draft, noHistory)

        // 修改 option
        if (selectedItemOptionIndex >= 0) {
          selectedItem.options[selectedItemOptionIndex] = option
        } else {
          selectedItem.options.push(option)
        }
        break
      }
      case ActionTypes.TOGGLE_ITEM_TAG: {
        const { noHistory } = action.payload
        /** @type {ITag} */
        const tag = action.payload.tag
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        storeHistory(draft, noHistory)

        // 修改 tags
        const itemTagIndex = selectedItem.tags.findIndex(itemTag => itemTag.name === tag.name)
        if (itemTagIndex > -1) {
          selectedItem.tags.splice(itemTagIndex, 1)
        } else {
          selectedItem.tags.push(tag)
        }
        break
      }
      case ActionTypes.ADD_ITEM_TAGS: {
        const { noHistory } = action.payload

        const tags = action.payload.tags
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        storeHistory(draft, noHistory)

        if (selectedItem.tags.length > 0) {
          selectedItem.tags = [selectedItem.tags, ...tags]
        } else {
          selectedItem.tags = tags
        }
        break
      }

      case ActionTypes.UPDATE_BATCH_ITEMS_CUSTOM_TAGS: {
        const { noHistory } = action.payload
        const tags = action.payload.tags
        const selectedBatchItems = draft.selectedBatch.items

        storeHistory(draft, noHistory)
        selectedBatchItems.map(item => {
          item.tags = tags
          if (item.setItems.length !== 0) {
            const setItems = item.setItems
            setItems.map(setItem => {
              setItem.tags = tags
            })
          }
        })
        break
      }

      case ActionTypes.UPDATE_CUSTOM_TAGS: {
        const { noHistory } = action.payload
        const tags = action.payload.tags
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        storeHistory(draft, noHistory)
        selectedItem.tags = tags
        break
      }
      case ActionTypes.UPDATE_SET_CUSTOM_TAGS: {
        const { noHistory } = action.payload
        /** @type {ITag} */
        const tags = action.payload.tags
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)

        storeHistory(draft, noHistory)

        // 修改 tags
        selectedSetItem.tags = tags
        break
      }
      case ActionTypes.UPDATE_SET_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)
        const selectedSetItemOptionIndex = selectedSetItem.options.findIndex(selectedSetItemOption => selectedSetItemOption.optionGroupId === option.optionGroupId)

        storeHistory(draft, noHistory)

        // 修改 option
        if (selectedSetItemOptionIndex >= 0) {
          selectedSetItem.options[selectedSetItemOptionIndex] = option
        } else {
          selectedSetItem.options.push(option)
        }
        break
      }
      case ActionTypes.DELETE_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedItemOptionIndex = selectedItem.options.findIndex(selectedItemOption => selectedItemOption.optionItemId === option.optionItemId)

        if (selectedItemOptionIndex >= 0) {
          storeHistory(draft, noHistory)

          // 刪除
          selectedItem.options.splice(selectedItemOptionIndex, 1)
        }
        break
      }
      case ActionTypes.DELETE_SET_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)
        const selectedSetItemOptionIndex = selectedSetItem.options.findIndex(selectedSetItemOption => selectedSetItemOption.optionItemId === option.optionItemId)

        if (selectedSetItemOptionIndex >= 0) {
          storeHistory(draft, noHistory)

          // 刪除
          selectedSetItem.options.splice(selectedSetItemOptionIndex, 1)
        }
        break
      }
      case ActionTypes.TOGGLE_SET_ITEM_TAG: {
        const { noHistory } = action.payload
        /** @type {ITag} */
        const tag = action.payload.tag
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)

        storeHistory(draft, noHistory)

        // 修改 tags
        const setItemTagIndex = selectedSetItem.tags.findIndex(itemTag => itemTag.name === tag.name)
        if (setItemTagIndex > -1) {
          selectedSetItem.tags.splice(setItemTagIndex, 1)
        } else {
          selectedSetItem.tags.push(tag)
        }
        break
      }
      case ActionTypes.UPDATE_MODIFIER: {
        const { modifier, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = _.find(selectedItem?.setItems, setItem => setItem.key === draft.selectedSetItemKey)
        const target = selectedSetItem || selectedItem

        storeHistory(draft, noHistory)

        target.modifiers[0] = modifier
        break
      }
      case ActionTypes.UPDATE_COMBO_MODIFIER: {
        const { submittingBatchItems } = action.payload
        // 先處理length是1的item
        const orderedBatchItems = _.sortBy(submittingBatchItems, submittingBatchItem => submittingBatchItem.length, ['desc'])
        const pushLater = []

        _.forEach(orderedBatchItems, comboLists => {
          // 將一樣的modifier組合一起 一同處理
          const groupComboListsByModifier = _.groupBy(comboLists, comboList => {
            return [
          `${comboList.modifier?.type},${comboList.modifier?.amount},${comboList.modifier?.percent}`,
            ]
          })
          let index = 0
          const itemIndex = comboLists[0].itemIndex
          const currentItem = draft.selectedBatch.items[itemIndex]
          const comboRuleIndex = currentItem.modifiers.findIndex(modifier => modifier.type === 'COMBORULE')

          _.forEach(groupComboListsByModifier, (groupComboList) => {
            const modifier = { ...groupComboList[0].modifier }
            modifier.id = uuid()

            // index 0 是不需要分拆的item
            if (index === 0) {
              modifier.amount = modifier.amount - currentItem.price
              if (!_.isEmpty(groupComboList[0].modifier)) {
                if (comboRuleIndex >= 0) {
                  currentItem.modifiers.splice(comboRuleIndex, 1)
                }
                currentItem.modifiers.push(modifier)
              } else if (_.isEmpty(groupComboList[0].modifier && comboRuleIndex >= 0)) {
                currentItem.modifiers.splice(comboRuleIndex, 1)
              }
              currentItem.quantity = groupComboList.length
            } else {
              // 需要分拆出來的items
              const itemBatchModifiers = [...currentItem.modifiers]
              modifier.amount = modifier.amount - currentItem.price

              if (!_.isEmpty(groupComboList[0].modifier)) {
                if (comboRuleIndex >= 0) {
                  itemBatchModifiers.splice(comboRuleIndex, 1)
                }
                itemBatchModifiers.push(modifier)
              } else if (_.isEmpty(groupComboList[0].modifier && comboRuleIndex >= 0)) {
                itemBatchModifiers.splice(comboRuleIndex, 1)
              }
              // 放到pushLater最後再處理 以免打亂item的index
              const id = uuid()
              pushLater.push({
                ...currentItem,
                modifiers: itemBatchModifiers,
                quantity: groupComboList.length,
                key: id,
                id: id,
              })
            }
            index++
          })
          console.log('pushLater', pushLater)
        })
        draft.selectedBatch.items = draft.selectedBatch.items.concat(pushLater)
        break
      }
      case ActionTypes.REMOVE_MODIFIER: {
        const { noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = _.find(selectedItem?.setItems, setItem => setItem.key === draft.selectedSetItemKey)
        const target = selectedSetItem || selectedItem

        storeHistory(draft, noHistory)

        target.modifiers = []
        break
      }
      case ActionTypes.RESET_BATCH: {
        // batchStashes 以外都 reset
        draft.batchHistory = initBatchHistory
        draft.selectedBatch = null
        draft.menuItemQuantities = {}
        draft.selectedItemKey = null
        draft.selectedSetItemKey = null
        break
      }
      case ActionTypes.STASH_BATCH: {
        // reset batch history
        draft.batchHistory = initBatchHistory
        draft.menuItemQuantities = {}

        // 儲存現在的 batchHistory 到 stashes
        const history = createBatchHistory(draft)
        draft.batchStashes.push(history)
        break
      }
      case ActionTypes.DROP_STASH: {
        const { createdAt } = action.payload.batchStash
        _.remove(draft.batchStashes, batchStash => batchStash.createdAt === createdAt)
        break
      }
      case ActionTypes.APPLY_STASH: {
        const { batchStash } = action.payload
        // reset batch history
        draft.batchHistory = initBatchHistory
        draft.selectedBatch = batchStash.batch
        draft.menuItemQuantities = batchStash.menuItemQuantities
        break
      }
      case ActionTypes.POP_STASH: {
        const { batch, menuItemQuantities, createdAt } = action.payload.batchStash
        // reset batch history
        draft.batchHistory = initBatchHistory

        _.remove(draft.batchStashes, batchStash => batchStash.createdAt === createdAt)
        draft.selectedBatch = batch
        draft.menuItemQuantities = menuItemQuantities
        break
      }
      case ActionTypes.DROP_ALL_STASH: {
        draft.batchStashes = []
        break
      }
      case ActionTypes.RESTORE_STASH: {
        const { batchStashes } = action.payload
        draft.batchStashes = batchStashes
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
