import { Animated, ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from '@/redux'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import Column from '@/components/Column'
import Row from '@/components/Row'

import InfoBar from '../InfoBar'
import OrderLegends from '../TableLegend/OrderLegends'
import TableFactory from './Table'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { ITable } from '@/redux/table/Table.d'
/* eslint-enable no-unused-vars */

/**
 * @typedef TableAreaProps
 * @property {(table: ITable) => void} onSelectTable
 * @property {(order: IAppOrder) => void} onSelectOrder
 * @property {boolean} [disableOrders=false]
 * @property {boolean} [requestWaiter=false]
 * @property {boolean} [forceSrcoll=false] TableAreaDialog 會強制使用Srcolling view
 */

/**
 *
 * @param {TableAreaProps} props
 * @returns
 */
export default function FloorPlan (props) {
  const { onSelectTable, onSelectOrder, disableOrders = false, requestWaiter = false, forceSrcoll = false } = props

  const { tables, floorPlanAreas, selectedTableAreaId } = useSelector(state => state.table)
  const tableGeometry = useSelector(state => state.merchant.data.tableGeometry)
  const { isSelecting } = useSelector(state => state.table)
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const animatedValuesRef = useRef([])
  const [animatedStyles, setAnimatedStyles] = useState([])

  const [layout, setLayout] = useState({ width: 0, height: 0 })

  // 找平面圖需要縮放的比例
  const floorPlanArea = React.useMemo(() => {
    return _.find(floorPlanAreas, area => area.id === selectedTableAreaId)
  }, [floorPlanAreas, selectedTableAreaId])
  const area = _.find(tableGeometry, ({ name }) => name === floorPlanArea?.key)
  const widthRatio = area ? layout.width / area.size.width : 1
  const heightRatio = area ? layout.height / area.size.height : 1
  const ratio = forceSrcoll ? Math.max(widthRatio, heightRatio) : Math.min(widthRatio, heightRatio)

  const areaTables = React.useMemo(() => {
    // 篩選出所有所選區域的桌位
    return _.filter(tables, table => table.floorPlanAreaId === selectedTableAreaId)
  }, [tables, selectedTableAreaId])

  const areaOrders = React.useMemo(() => {
    // 找出每張所選區域桌位最早的訂單
    const areaOrderIds = areaTables.map(table => requestWaiter ? table.requestWaiterOrderIds : table.orderIds)
    return areaOrderIds.map(orderIds => {
      const earliestOrder = orderIds.reduce((earliest, orderId) => {
        const order = historyOrders.find(order => order.id === orderId)
        if (!earliest || order.createdAt < earliest.createdAt) {
          return order
        }
        return earliest
      }, null)
      return earliestOrder
    })
  }, [areaTables, requestWaiter, historyOrders])

  const areaOverdue = React.useMemo(() => {
    // 找出每張所選區域桌位最早的訂單是否已超時
    return areaOrders.map(order => {
      if (!order) return false
      if (order.status === 'paid') return false
      if (order.orderCutOffMins && order.status === 'pending' && order.deliveryType === 'table') {
        const overdueTime = moment(order.createdAt).add(order.orderCutOffMins, 'minute')
        const isAlmostOverdue = moment().isAfter(moment(overdueTime).subtract(5, 'minute'))
        const isOverdue = moment().isAfter(overdueTime)
        if (isOverdue) return 300
        if (isAlmostOverdue) return 600
      }
      return false
    })
  }, [areaOrders, historyOrders])

  const onLayout = (event) => {
    // 找出可顯示平面圖的 screen size
    const { width, height } = event.nativeEvent.layout
    setLayout({ width, height })
  }

  const startAnimation = () => {
    // 超時的時候用餐時間會閃爍
    animatedValuesRef.current = areaTables.map(() => new Animated.Value(0))
    Animated.loop(
      Animated.sequence([
        Animated.parallel([
          ...areaTables.map((value, index) => {
            if (areaOverdue[index] === 300) {
              return Animated.sequence([
                Animated.parallel([
                  // 由白色到紅色（已經超時）
                  Animated.timing(animatedValuesRef.current[index], {
                    toValue: 1,
                    duration: areaOverdue[index],
                    useNativeDriver: false,
                  }),
                ]),
                Animated.parallel([
                  // 由紅色到白色（已經超時）
                  Animated.timing(animatedValuesRef.current[index], {
                    toValue: 0,
                    duration: areaOverdue[index],
                    useNativeDriver: false,
                  }),
                ]),
              ])
            }
            // 由白色到紅色（接近超時）
            return Animated.timing(animatedValuesRef.current[index], {
              toValue: 1,
              duration: areaOverdue[index],
              useNativeDriver: false,
            })
          }),
        ]),
        Animated.parallel([
          ...areaTables.map((value, index) => {
            if (areaOverdue[index] === 300) {
              return Animated.sequence([
                Animated.parallel([
                  // 由白色到紅色（已經超時）
                  Animated.timing(animatedValuesRef.current[index], {
                    toValue: 1,
                    duration: areaOverdue[index],
                    useNativeDriver: false,
                  }),
                ]),
                Animated.parallel([
                  // 由紅色到白色（已經超時）
                  Animated.timing(animatedValuesRef.current[index], {
                    toValue: 0,
                    duration: areaOverdue[index],
                    useNativeDriver: false,
                  }),
                ]),
              ])
            }
            // 由紅色到白色（接近超時）
            return Animated.timing(animatedValuesRef.current[index], {
              toValue: 0,
              duration: areaOverdue[index],
              useNativeDriver: false,
            })
          }),
        ]),
      ]),
    ).start()
    setAnimatedStyles(() => {
      return (
        animatedValuesRef.current.map((animatedValue) => ({
          color: animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: ['white', 'red'],
          }),
        }))
      )
    },
    )
  }

  // 在通知dialog/選取訂單時容許scroll
  const TablesContainer = forceSrcoll || isSelecting
    ? ScrollView
    : View

  // 用餐時間改變時檢查是否需要閃爍
  useEffect(() => {
    if (areaTables.length > 0) {
      startAnimation()
    }
  }, [areaTables, areaOverdue, historyOrders])

  return (
    <Column style={styles.container}>
      {/* info */}
      <InfoBar />

      {/* tables */}
      <TablesContainer
        style={styles.tables}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: isSelecting || forceSrcoll ? 400 : 0,
        }}
        onLayout={onLayout}
      >
        <View style={{ width: layout.width, height: layout.height }}>
          {areaTables.map((table, index) => (
            <TableFactory
              key={table.id}
              table={table}
              ratio={ratio}
              onSelectTable={onSelectTable}
              onSelectOrder={onSelectOrder}
              disableOrders={disableOrders}
              requestWaiter={requestWaiter}
              forceSrcoll={forceSrcoll}
              animatedStyle={animatedStyles ? animatedStyles[index] : null}
            />
          ))}
        </View>
      </TablesContainer>

      {/* legend */}
      <Row style={styles.legends}>
        {!disableOrders && <OrderLegends />}
      </Row>
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 15,
    paddingHorizontal: 26,
  },
  tables: {
    flex: 1,
    marginTop: 15,
    zIndex: 1,
    elevation: 1,
    paddingBottom: 60,
  },
  legends: {
    marginVertical: 15,
    justifyContent: 'flex-end',
  },
})
