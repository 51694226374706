import { StyleSheet, View } from 'react-native'
import Options from './Options'
import React from 'react'
import Tags from './Tags'
import shadows from '@/theme/shadows'

export default function OptionsPanel (props) {
  return (
    <View style={styles.container}>
      <View style={styles.boundary}>
        <Options />
        <Tags />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 210,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    ...shadows.default,
    zIndex: 100,
  },
  boundary: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    overflow: 'hidden',
  },
})
