import { actions, useDispatch, useSelector } from '@/redux'
import { useEffect } from 'react'

/**
 * 更新裝置資料和最後連線時間
 * @param {number} [delay]
 */
export default function useCheckDeviceConnection (delay = 60 * 1000) {
  const dispatch = useDispatch()
  const isDataInit = useSelector(state => state.app.isDataInit)

  useEffect(() => {
    let intervalId = ''
    if (isDataInit) {
      dispatch(actions.app.updateDevice())
      dispatch(actions.app.updateTTL())
      intervalId = setInterval(() => {
        dispatch(actions.app.updateTTL())
      }, delay)
    }
    return () => { clearInterval(intervalId) }
  }, [isDataInit])
}
