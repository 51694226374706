import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

export default function OrderStatus ({ text, backgroundColor }) {
  return (
    <View
      style={[styles.container, { backgroundColor }]}
    >
      <Text style={styles.text}>{text}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 28,
    width: 67,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.white,
  },
})
