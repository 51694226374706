import { StyleSheet, View } from 'react-native'
import { SwipeRow } from 'react-native-swipe-list-view'
import { getDisplayBatchItem, isSetItemComplete } from '@/libs/order'
import { useSelectedSetMenuIndex } from '@/hooks/order'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Column from '../Column'
import DeleteActionRow from './DeleteActionRow'
import QuantityItems from './QuantityItems'
import React from 'react'
import SetItems from './SetItems'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'
import useBatchFlatList from '@/libs/BatchFlatList/useBatchFlatList'

// eslint-disable-next-line no-unused-vars
import { ItemProps } from '@/pages/Order/OrderBatch/BatchList/index.d'

/**
 * @param {ItemProps} props
 */
export default function BatchItem (props) {
  const {
    serial,
    isSetItemsExpaned = true,
    fillRadius,
    item,
    onPress,
    onDelete,
    disabled,
  } = props

  const { t } = useTranslation()
  const formatedItem = getDisplayBatchItem(item)
  const surchargeIndex = _.findIndex(formatedItem?.modifiers, modifier => modifier.type === 'SURCHARGE' && modifier.deletedAt == null)
  const surchargeModifier =
    surchargeIndex >= 0
      ? formatedItem?.modifiers[surchargeIndex]
      : { percent: 0, amount: 0 }
  if (surchargeIndex >= 0) {
    formatedItem.surcharge =
      (formatedItem.originalTotal * surchargeModifier.percent) / 100 +
      surchargeModifier.amount
  }
  formatedItem.surcharge = Math.floor(formatedItem.surcharge * 10) / 10
  const discountIndex = _.findIndex(formatedItem?.modifiers, modifier => modifier.type === 'DISCOUNT' && modifier.deletedAt == null)
  const discountModifier =
    discountIndex >= 0
      ? formatedItem?.modifiers[discountIndex]
      : { percent: 0, amount: 0 }
  if (discountIndex >= 0) {
    formatedItem.totalDiscount =
      (formatedItem.originalTotal * discountModifier.percent) / 100 +
      discountModifier.amount
  }
  formatedItem.totalDiscount = Math.floor(formatedItem.totalDiscount * 10) / 10
  formatedItem.individualDiscount =
    Math.floor(-1 * (formatedItem.totalDiscount / formatedItem.quantity) * 10) /
    10
  const swipeRowRef = React.useRef()
  const [rowOpened, setRowOpened] = React.useState(false)
  const [rowError, setRowError] = React.useState(null)
  const [autoHideRowError, setAutoHideRowError] = React.useState(false)

  const showNotCompleteSets = useSelector(
    (state) => state.orderBatch.selectedBatch.showNotCompleteSets,
  )

  const menu = useSelector((state) => state.menu.menus[item.menuId])
  const set = useSelector((state) => state.menu.sets[item.menuId])
  const menuItem = set || menu

  // 餐點售罄
  const menuSold = menuItem?.sold
  // 餐點庫存
  const menuInventory = menuItem?.inventory
  // 已點數量
  const orderedQuantity = useSelector((state) =>
    _.get(state.orderBatch, ['menuItemQuantities', item.menuId], 0),
  )
  let isInventoryEnabled = useSelector(
    (state) => state.merchant.data?.setting?.inventory,
  )
  if (menuInventory === undefined) {
    // 餐點沒設定數量時，不管庫存
    isInventoryEnabled = false
  }
  // 可點數量 = 庫存 - 別項點的數量 （別項點的數量 = 已點數量 - 這項點的數量）
  const inventory = menuInventory - (orderedQuantity - item.quantity)

  const selectedItemKey = useSelector(
    (state) => state.orderBatch.selectedItemKey,
  )
  const selectedSetItemKey = useSelector(
    (state) => state.orderBatch.selectedSetItemKey,
  )

  const isSelected = selectedItemKey === item.key
  const isSelectedStyle = isSelected && !selectedSetItemKey
  const flatListRef = useBatchFlatList()
  const expandSetItems = item.isSet && isSetItemsExpaned
  const expandQuantites = !item.isSet && item.quantity > 1 && isSelected
  const selectedSetMenuIndex = useSelectedSetMenuIndex()
  const isComplete = isSetItemComplete(item, menuItem, selectedSetMenuIndex)
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)
  const allSets = useSelector(state => state.menu.sets)
  formatedItem.name = _.get((
    formatedItem.isSet
      ? allSets[formatedItem.menuId]
      : allMenus[formatedItem.menuId]
  )?.localeNames, lang) ?? formatedItem.name

  React.useEffect(() => {
    // row 打開後 3 秒自動關閉
    if (!rowOpened) return
    const timer = setTimeout(() => {
      swipeRowRef?.current?.closeRow()
    }, 3000)

    return () => clearTimeout(timer)
  }, [rowOpened])

  React.useEffect(() => {
    // 若 item.quantity 為 0 則移除此項 item
    if (!item.quantity) {
      onDelete()
    }
  }, [item.quantity])

  React.useEffect(() => {
    // 當 autoHideRowError 啟動時，3秒後會移除 error
    if (!autoHideRowError) return
    const timer = setTimeout(() => {
      if (!autoHideRowError) return
      setRowError(null)
      setAutoHideRowError(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [autoHideRowError])

  React.useEffect(() => {
    if (menuSold) {
      setRowError(t('app.page.order.orderBatch.error.sold'))
      setAutoHideRowError(false)
      return
    }

    if (!isInventoryEnabled) return // 不管庫存

    // 檢查餐點數量
    if (inventory < item.quantity) {
      setRowError(
        `${t('app.page.order.orderBatch.error.soldWithQuantity')}${
          orderedQuantity - menuInventory
        }`,
      )
      setAutoHideRowError(false)
    } else {
      setRowError(null)
    }
  }, [menuSold, isInventoryEnabled, inventory, item.quantity])

  React.useEffect(() => {
    // 檢查套餐完成
    if (!showNotCompleteSets) return // 不顯示套餐未完成

    if (isComplete) {
      setRowError(null)
    } else {
      setRowError(t('app.page.order.orderBatch.error.set-incomplete'))
    }
  }, [showNotCompleteSets, isComplete])

  return (
    <View style={[styles.container, fillRadius && styles.fillRadius]}>
      <SwipeRow
        ref={swipeRowRef}
        disableLeftSwipe={disabled}
        disableRightSwipe
        rightOpenValue={-50}
        // rightActivationValue={-500}
        onRowDidOpen={() => {
          setRowOpened(true)
        }}
        onRowDidClose={() => {
          setRowOpened(false)
        }}
        onSwipeValueChange={({ value }) => {
          // 避免 Swipe 中垂直捲動 FlatList 造成 Swipe 中斷
          flatListRef?.setNativeProps({ scrollEnabled: value > -30 })
          if (value < -600) {
            onDelete()
          }
        }}
        // onRightActionStatusChange={({ isActivated, value }) => {
        //   if (isActivated) {
        //     flatListRef?.setNativeProps({ scrollEnabled: true })
        //     onDelete()
        //   }
        // }}
        onRowPress={!disabled && onPress}
      >
        <DeleteActionRow onPress={onDelete} disabled={disabled} />
        <View style={[styles.itemRow, isSelectedStyle && styles.selected]}>
          <Column
            index={0}
            value={serial}
            expanded={item.isSet ? expandSetItems : undefined}
          />
          <Column index={1} value={formatedItem.name} />
          <Column index={2} value={rowError || t(formatedItem.status)} />
          <Column
            index={3}
            value={
              formatedItem.priceUndetermined
                ? t('app.constant.order.priceUndetermined')
                : formatedItem.priceDeductDiscount ?? (formatedItem.price - formatedItem.discount)
            }
          />
          <Column
            index={4}
            value={formatedItem.quantity}
            input={isSelectedStyle && !item.isSet}
          />
          {!rowError && (
            <>
              {/* <Column index={5} value={formatedItem.totalDiscount} />
                <Column index={6} value={formatedItem.surcharge} /> */}
              <Column index={7} value={Number(formatedItem.total).toFixed(1)} />
            </>
          )}
        </View>
      </SwipeRow>
      {expandSetItems && <SetItems serial={serial} item={formatedItem} />}
      {expandQuantites && <QuantityItems serial={serial} item={formatedItem} />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 30,
    minWidth: 580,
    marginBottom: 1,
  },
  fillRadius: {
    backgroundColor: colors.primary,
  },
  itemRow: {
    // width:580,
    width: '100%',
    minHeight: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: 16,
    backgroundColor: colors.lightBlue1,
  },
  backContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.secondary,
  },
  deleteIcon: {
    width: 28,
    height: 28,
  },
  selected: {
    backgroundColor: colors.tertiary,
  },
})
