import { FlatList, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import _ from 'lodash'
import produce from 'immer'

import { actions, useDispatch, useSelector } from '@/redux'
import { filterSections, getFilterSecion } from '@/libs/orderFilters'
import { initialFilter } from '@/redux/orderHistory/reducer'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import Row from '@/components/Row'

import FilterSection from './FilterSection'

/**
 * @typedef FilterPanelProps
 * @param {() => void} onClose
 */

/**
 *
 * @param {FilterPanelProps} props
 * @returns
 */
export default function FilterPanel (props) {
  const { onClose } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const defaultFilter = useSelector(state => state.orderHistory.filter)
  const sections = filterSections()
  const [filter, setFilter] = useState(defaultFilter)

  const onSelect = (field, value) => {
    const existsIndex = filter[field].findIndex(filterValue => {
      return _.isEqual(filterValue, value)
    })
    if (existsIndex >= 0) {
      setFilter(produce(draft => {
        draft[field].splice(existsIndex, 1)
      }))
    } else {
      setFilter(produce(draft => {
        draft[field].push(value)
      }))
    }
  }

  const renderSecion = ({ item: section, index }) => {
    const filterSection = getFilterSecion(section.field)
    return (
      <FilterSection
        field={section.field}
        filter={filter[section.field]}
        onSelect={onSelect}
        divider={index + 1 !== sections.length}
        columns={filterSection.columns}
      />
    )
  }

  return (
    <View style={[StyleSheet.absoluteFill, { zIndex: 101, elevation: 9 }]}>
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={[StyleSheet.absoluteFill, styles.overlay]} />
      </TouchableWithoutFeedback>

      <Column style={styles.content}>
        {/* header */}
        <View style={styles.header}>
          <Text style={styles.headerText}>{t('app.page.orderHistory.filterPanel.header')}</Text>
        </View>

        {/* selections */}
        <FlatList
          data={sections}
          renderItem={renderSecion}
          keyExtractor={item => item.field}
          extraData={filter}
        />

        {/* actions */}
        <Row style={styles.actions}>
          <Button
            title={t('app.page.orderHistory.filterPanel.reset')}
            textBold
            backgroundColor={colors.lightPrimary}
            type='TouchableOpacity'
            onPress={() => {
              setFilter(initialFilter)
            }}
          />
          <Button
            title={t('app.page.orderHistory.filterPanel.apply')}
            textBold
            backgroundColor={colors.lightPrimary}
            type='TouchableOpacity'
            onPress={() => {
              dispatch(actions.orderHistory.applyFilter(filter))
              onClose()
            }}
          />
        </Row>
      </Column>
    </View>
  )
}

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: colors.black,
    opacity: 0.4,
  },
  content: {
    height: '100%',
    width: 380,
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
  },
  header: {
    height: 100,
    justifyContent: 'flex-end',
    backgroundColor: colors.primary,
    paddingLeft: 38,
    paddingBottom: 15,
    ...shadows.default,
  },
  headerText: {
    fontSize: 20,
    lineHeight: 29,
    fontWeight: 'bold',
    color: colors.white,
  },
  selections: {
    flex: 1,
    paddingHorizontal: 30,
  },
  actions: {
    height: 100,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: colors.light,
    paddingHorizontal: 30,
  },
  row: {
    flexWrap: 'wrap',
  },
})
