import { Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SettingListItemEnterProps
 * @property {string} text
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {SettingListItemEnterProps} props
 * @returns
 */
export default function SettingListItemEnter (props) {
  const { style, text } = props
  return (
    <View style={[styles.container, style]}>
      {!!text && <Text style={styles.text}>{text}</Text>}
      <Image
        style={styles.icon}
        source={require('@icons/setting/more.png')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
  },
  icon: {
    height: 12,
    width: 12,
    marginLeft: 10,
  },
})
