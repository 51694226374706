import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { produce } from 'immer'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import ChipLabel from '@/components/ChipLabel'
import EditButton from '@/components/buttons/EditButton'
import Header from '@/components/Setting/Header'
import HeaderRightAction from '@/components/Setting/HeaderRightAction'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

// eslint-disable-next-line no-unused-vars
import { IMenu } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 * @typedef MenuOptionsProps
 * @property {IMenu} editingMenu
 * @property {(path: string, value: any) => void} onUpdate
 * @property {(locale: string) => void} onLocaleUpdate
 */

/**
 *
 * @param {MenuOptionsProps} props
 * @returns
 */
export default function MenuOptions (props) {
  const { editingMenu, onEditOptions, onDragEnd } = props
  const { t } = useTranslation()

  const optionGroups = React.useMemo(() => {
    const optionGroups = []
    editingMenu.options?.forEach(option => {
      optionGroups.push(produce(option, draft => {
        draft.isPreset = false
      }))
    })
    editingMenu.optionGroupPresets?.forEach(option => {
      optionGroups.push(produce(option, draft => {
        draft.isPreset = true
      }))
    })
    return _.orderBy(optionGroups, 'weight')
  }, [editingMenu.options, editingMenu.optionGroupPresets])

  const renderOption = ({ item, index, drag, isActive }) => {
    return (
      <SettingListItem
        key={item.id}
        divider={index !== optionGroups.length - 1}
        draggable
        dragging={isActive}
        onDrag={drag}
      >
        <SettingListItemText text={_.get(item.localeNames, editingMenu.locale) ?? item.name} />
        <SettingListItemRightAction>
          {item.isPreset && <ChipLabel label={t('app.page.setting.menuPanel.options.option.default')} active />}
          <ChipLabel label={t('app.page.setting.menuPanel.options.optionGroup.must')} active={item.min > 0} />
          <ChipLabel label={t('app.page.setting.menuPanel.options.optionGroup.multiple')} active={item.multiple} />
        </SettingListItemRightAction>
      </SettingListItem>
    )
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
    >
      <Header>
        <HeaderText text={t('app.page.setting.menuPanel.options.option.item')} />
        <HeaderRightAction>
          <EditButton label={t('app.common.edit')} onPress={onEditOptions} />
        </HeaderRightAction>
      </Header>

      <SettingList>
        {optionGroups.length === 0 && (
          <View style={styles.emptyView}>
            <Text>{t('app.page.setting.menuPanel.options.option.empty')}</Text>
          </View>
        )}
        <DraggableFlatList
          showsVerticalScrollIndicator={false}
          data={optionGroups}
          keyExtractor={(item, index) => String(index)}
          renderItem={renderOption}
          onDragEnd={onDragEnd}
        />
      </SettingList>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  addIcon: {
    width: 25,
    height: 25,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
