import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { URLSearchParams } from 'react-native-url-polyfill'
import { useHistory } from 'react-router-dom'
import { useLocation } from '@/libs/reactRouter'
import React from 'react'
import colors from '@/theme/colors'

export default function GroupNavButton ({ item, resetLocalTable }) {
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const groupId = query.get('groupId')
  const isActive = groupId === item.key

  return (
    <TouchableOpacity
      onPress={() => {
        if (item.path) {
          history.push(item.path)
          resetLocalTable()
        }
      }}
      style={styles.container}
    >
      <Text style={[styles.text, isActive && styles.activeText]}>
        {item.text}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  activeContainer: {
    backgroundColor: colors.primary,
  },
  text: {
    fontSize: 20,
    fontWeight: '500',
    color: colors.white,
    opacity: 0.4,
  },
  activeText: {
    color: colors.white,
    opacity: 1,
  },
})
