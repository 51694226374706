import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import colors from '@/theme/colors'

import QRCodeDialog from '@/components/dialogs/QRcodeDialog'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem } from 'dimorder-orderapp-lib/dist/types/AppMenu'

/**
 * @typedef MenuImageProps
 * @property {IAppMenuItem} editingMenu
 * @property {JSX.Element} children
 */

/**
 *
 * @param {MenuImageProps} props
 * @returns
 */
export default function MenuImage (props) {
  const { editingMenu, children } = props
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const clientUrls = useSelector(state => state.config.clientUrls)
  const token = useSelector(state => state.auth.userLogin.token)
  const { menus } = useSelector(state => state.menu)
  const currentMenu = _.find(menus, menu => menu.id === editingMenu?.id)

  const [uploadUrl, setUploadUrl] = React.useState(null)
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false)

  // 準備 upload url
  React.useEffect(() => {
    if (!editingMenu.id) return

    async function prepareShortUrl (longUrl) {
      const shortUrl = await dimorderApi.shortUrl(longUrl)
      setUploadUrl(shortUrl)
    }

    const longUrl = `${clientUrls.merchantFrontend}/image?menu=true&id=${editingMenu.id}&token=${token}`
    prepareShortUrl(longUrl)
  }, [editingMenu.id])

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity
          style={[styles.imageContainer]}
          onPress={() => {
            if (!editingMenu.id) {
              dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.saveFirst')))
              return
            }
            setUploadDialogOpen(true)
          }}
        >
          {currentMenu?.image
            ? (
              <Image
                source={
                  Platform.OS !== 'web'
                    ? { url: currentMenu?.image }
                    : currentMenu?.image
                }
                style={StyleSheet.absoluteFill}
              />
            )
            : (
              <>
                <Image
                  style={styles.uploadIcon}
                  source={require('@icons/setting/upload.png')}
                />
                <Text style={styles.imgText}>{t('app.page.setting.menuPanel.menuInfo.uploadImage')}</Text>
              </>
            )}
        </TouchableOpacity>
        <View style={styles.rightContent}>
          {children}
        </View>
      </View>
      <QRCodeDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        url={uploadUrl}
        title={t('app.page.setting.menuPanel.menuInfo.image')}
        id={editingMenu.id}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 15,
    paddingLeft: 25,
  },
  imageContainer: {
    height: 90,
    width: 90,
    backgroundColor: colors.lightGray3,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  rightContent: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  uploadIcon: {
    height: 30,
    width: 30,
    marginBottom: 4,
  },
  imgText: {
    color: colors.gray,
    fontSize: 14,
    fontWeight: '500',
  },
})
