import { actions, useDispatch, useSelector } from '@/redux'
import { useEffect } from 'react'
import PrinterClient from '@/libs/printing/PrinterClient'
import _ from 'lodash'

/**
 * 檢查印表機連線狀態
 * @param {number} [delay]
 */
export default function useCheckPrinterConnection (delay = 60 * 1000) {
  const dispatch = useDispatch()
  const isDataInit = useSelector(state => state.app.isDataInit)
  const { printerStatus } = useSelector(state => state.printer)

  useEffect(() => {
    let intervalId = ''
    if (isDataInit) {
      intervalId = setInterval(() => {
        _.each(printerStatus, (printer) => {
          if (printer.status !== PrinterClient.STATUS.CONNECTED) {
            dispatch(actions.printer.connectPrinter(printer))
          }
        })
      }, delay)
    }
    return () => { clearInterval(intervalId) }
  }, [isDataInit, printerStatus])
}
