import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useIsOnline } from '@/hooks/app'
import colors from '@/theme/colors'

import BigIconButton from '@/components/buttons/BigIconButton'

/**
 * @typedef ActionButtonProps
 * @property {string} iconSource
 * @property {string} text
 * @property {string} action
 * @property {() => void} onPress
 * @property {ViewProps['style']?} buttonStyle
 * @property {string?} backgroundColor
 * @property {boolean?} disabled
 * @property {boolean?} loading
 */

/**
 *
 * @param {ActionButtonProps} props
 * @returns
 */
export default function ActionButton (props) {
  const {
    iconSource,
    text,
    action: actionName,
    onPress,
    buttonStyle,
    backgroundColor = colors.primary,
    disabled = false,
    loading = false,
  } = props
  const { t } = useTranslation()
  const { isOnline } = useIsOnline()
  const action = _.throttle(onPress, 3000, { trailing: false })
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <BigIconButton
      disabled={(!isOnline && actionName === 'qrcode') || disabled || isLoading}
      iconSource={iconSource}
      backgroundColor={backgroundColor}
      style={[styles.button, buttonStyle]}
      iconStyle={styles.icon}
      text={t(text)}
      onPress={() => {
        action()
        if (loading && !isLoading) {
          setIsLoading(true)
          setTimeout(() => {
            setIsLoading(false)
          }, 1500)
        }
      }}
      loading={loading}
    />)
}

const styles = StyleSheet.create({
  button: {
    width: 75,
    height: 55,
    margin: 3.5,
  },
  icon: {
    width: 30,
    height: 30,
  },
})
