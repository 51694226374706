import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import AccountInfo from './AccountInfo'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import React, { useState } from 'react'
import Row from '@/components/Row'
import TableRow from '@/components/OrderTable/TableRow'
import actions from '@/redux/actions'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'
// 表頭
const headers = [
  // '編號',
  '姓名',
  // '電話', '在職狀況'
]
// item 使用的欄位名稱
const fields = [
  // 'id',
  // 'name',
  'identifier',
  // 'phone', 'active'
]
// 欄寬
const widths = [
  // 60,
  100,
  // 100, 80
]
// 對齊
const justifyContents = [
  // 'center',
  'flex-start',
  // 'flex-start','center',
]
// 自訂顯示文字
// const displayConfigs = { active: { true: '在職', false: '-' } }

export default function AuthorizationPanel () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const scopeGroupUsers = { ...useSelector(state => state.auth.scopeGroupUsers) }
  const [isCreate, setIsCreate] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const scopeCredentials = useSelector(state => state.auth.merchantLogin.scopeCredentials)

  React.useEffect(() => {
    try {
      scopeCredentials.map(o => {
        if (!scopeGroupUsers[o.identifier]) {
          scopeGroupUsers[o.identifier] = [o.identifier]
        }
      })
    } catch (err) {
      console.log('Load scopeCredential Error', err)
      dispatch(actions.auth.restoreMerchant())
    }
  }, [scopeCredentials])

  const onCreateUser = () => {
    setIsCreate(true)
    setSelectedAccount({ identifier: '' })
  }

  const renderEmployee = ({ item: employee, index }) => {
    return (
      <TouchableOpacity onPress={() => {
        setIsCreate(false)
        setSelectedAccount(employee)
      }}
      >
        <TableRow
          item={employee}
          fields={fields}
          widths={widths}
          justifyContents={justifyContents}
          style={[styles.row,
            selectedAccount?.identifier === employee.identifier && { backgroundColor: colors.tertiary },
          ]}
          textStyle={styles.text}
        />
      </TouchableOpacity>)
  }

  return (
    <Row style={styles.container}>
      <View style={styles.leftContainer}>
        <Header><HeaderText text={t('app.page.setting.AuthorizationPanel.accountInfo.list')} /></Header>
        <FlatList
          data={scopeCredentials}
          renderItem={renderEmployee}
          keyExtractor={(item) => item.identifier}
          extraData={{ selectedAccount }}
          showsVerticalScrollIndicator={false}
        />
        <TouchableOpacity
          style={[styles.groupContainer, { justifyContent: 'center', alignItems: 'center' }]}
          onPress={onCreateUser}
        >
          <Text style={styles.groupText}>＋ {t('app.page.setting.AuthorizationPanel.accountInfo.add')}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.rightContainer}>
        {selectedAccount && (
          <AccountInfo
            selectedAccount={selectedAccount}
            isCreate={isCreate}
            setSelectedAccount={setSelectedAccount}
          />)}
      </View>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  leftContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 5,
    overflow: 'hidden',
    marginHorizontal: 10,
    ...shadows.default,
  },
  rightContainer: {
    flex: 1,
    marginHorizontal: 20,
    overflow: 'hidden',
  },
  groupContainer: {
    height: 40,
    flexDirection: 'row',
    backgroundColor: colors.primary,
  },
  group: {
    flex: 1,
    paddingLeft: 20,
    justifyContent: 'center',
  },
  groupText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.white,
  },
  addButton: {
    width: 90,
    backgroundColor: colors.secondary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    height: 30,
    paddingHorizontal: 12,
    backgroundColor: colors.light,
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.gray,
  },
  row: {
    height: 45,
    paddingHorizontal: 12,
    backgroundColor: colors.transparent,
  },
})
