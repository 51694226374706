import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import colors from '@/theme/colors'

/**
 * @typedef HeaderButtonProps
 * @property {string} title
 * @property {() => void} onPress
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {HeaderButtonProps} props
 * @returns
 */
export default function HeaderButton (props) {
  const { onPress, title, style } = props
  const action = _.throttle(onPress, 1500, { trailing: false })

  return (
    <TouchableOpacity
      style={style}
      onPress={action}
    >
      <View style={styles.tag}>
        <Text style={styles.text}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  tag: {
    height: 36,
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 8,
    paddingHorizontal: 4,
    marginTop: 5,
    marginRight: 12,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.white,
  },
})
