import { Image, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import { routes } from '@/libs/setting'
import colors from '@/theme/colors'
import envOptions from '@/configs/envOptions'
import usePressedTimes from '@/hooks/usePressedTimes'

import Column from '@/components/Column'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import TextInputDialog from '@/components/dialogs/TextInputDialog'

import NavButton from './NavButton'

/**
 *
 * @param {*} props
 * @returns
 */
export default function NavBar (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const configEnv = useSelector(state => state.config.env)
  const [adminDialogOpen, setAdminDialogOpen] = React.useState(false)
  const [envSelectDialogOpen, setEnvSelectDialogOpen] = React.useState(false)
  const { handlePress } = usePressedTimes(() => setAdminDialogOpen(true)) // 於 5 秒內連點 10 次打開 Dialog
  const merchant = useSelector(state => state.merchant.data)
  const { quickMode, enableTnDmode } = merchant?.setting
  let newRoutes = routes

  if (quickMode || enableTnDmode) {
    newRoutes = newRoutes.filter(o => o.key !== 'table')
    if (enableTnDmode) {
      newRoutes = newRoutes.filter(o => o.key !== 'order')
    }
  }

  const navBtnText = {
    general: t('app.routes.setting.general.nav'),
    order: t('app.routes.setting.order.nav'),
    printing: t('app.routes.setting.printing.nav'),
    table: t('app.routes.setting.table.nav'),
    checkout: t('app.routes.setting.checkout.nav'),
    menu: t('app.routes.setting.menu.nav'),
  }

  routes.map((route) => {
    route.text = navBtnText[route.key] ?? route.text
  })

  return (
    <Column style={styles.container}>
      <TouchableWithoutFeedback onPress={handlePress}>
        <Image
          style={styles.logo}
          source={require('@images/login/cropped-logo_bottomSpace.png')}
        />
      </TouchableWithoutFeedback>
      {newRoutes.map((route, index) => <NavButton key={index} item={route} />)}

      <OptionsSelectDialog
        key={envSelectDialogOpen}
        title='選擇環境'
        modalProps={{ enablePressOutsideClose: false }}
        open={envSelectDialogOpen}
        onClose={() => setEnvSelectDialogOpen(false)}
        closeOnSelect
        options={envOptions}
        defaultValue={configEnv}
        onSubmit={(env) => dispatch(actions.config.overwriteEnv(env))}
      />
      <TextInputDialog
        enablePressOutsideClose={false}
        open={adminDialogOpen}
        onClose={() => setAdminDialogOpen(false)}
        secureTextEntry
        title={t('app.page.setting.menuPanel.menuInfo.dialog.admin.title')}
        placeholder={t('app.page.setting.menuPanel.menuInfo.dialog.admin.placeholder')}
        onSubmit={async (text) => {
          try {
            await dimorderApi.auth.adminLogin(text)
            setEnvSelectDialogOpen(true)
          } catch (error) {
            dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.login')))
          }
        }}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 124,
    alignItems: 'center',
    backgroundColor: colors.lightGray3,
  },
  logo: {
    height: 78,
    width: 50,
    resizeMode: 'contain',
    marginTop: 28,
  },
})
