import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'
import paymentConfigs from '@/configs/paymentConfigs'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemEnter from '@/components/Setting/SettingListItemEnter'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

// eslint-disable-next-line no-unused-vars
import { IAppPayment } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef PaymentInfoProps
 * @property {IAppPayment} paymentMethod
 * @property {() => void} onClose
 */

/**
 *
 * @param {PaymentInfoProps} props
 * @returns
 */
export default function PaymentInfo (props) {
  const { paymentMethod, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [paymentMethodState, setPaymentMethodState] = React.useState(paymentMethod)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const gateways = _.get(paymentConfigs, `ecrGateways[${paymentMethod?.key}]`, [])
  const paymentGatewayOptions = _.filter([
    { key: 'none', value: '', display: 'N/A' },
    { key: 'globalPayment', value: 'globalPayment', display: 'Global Payment' },
    { key: 'bbMSL', value: 'bbMSL', display: 'bbMSL' },
    { key: 'eftPay', value: 'eftPay', display: 'eftPay' },
    { key: 'octopusMiniPC', value: 'octopusMiniPC', display: 'Octopus MiniPC' },
  ], option => option.key === 'none' || gateways.includes(option.key))

  useEffect(() => {
    setPaymentMethodState(paymentMethod)
  }, [paymentMethod])
  if (!paymentMethod) return null
  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: `${t('app.common.delete') + t('app.page.setting.checkout.payment')}`,
      message: `${t('app.common.is') + t('app.common.delete') + paymentMethod.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            dispatch(actions.merchant.deletePaymentMethod(paymentMethod))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }
  const handleSave = () => {
    onClose()
    dispatch(actions.merchant.upsertPaymentMethod(paymentMethodState))
  }
  const paymentText = paymentMethod.custom ? (t('app.page.setting.checkout.custom') + t('app.page.setting.checkout.payment')) : t('app.page.setting.checkout.payment')
  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.key}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }
  return (
    <>
      <Header>
        <HeaderText text={paymentMethod.key ? paymentText : `${t('app.page.setting.checkout.add') + paymentText}`} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.checkout.paymentName')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={t(paymentMethodState.name)}
                disabled={paymentMethodState.isEcrPayment}
                onChangeText={(text) => {
                  setPaymentMethodState({
                    ...paymentMethodState,
                    name: text,
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider={paymentMethodState.active}>
            <SettingListItemText text={t('app.page.setting.checkout.active')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={paymentMethodState.active}
                onChangeValue={() => {
                  setPaymentMethodState({
                    ...paymentMethodState,
                    active: !paymentMethodState.active,
                  })
                }}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          {
            (paymentMethod.isEcrPayment && paymentMethodState.active) &&
              (
                <>
                  <SettingListItem
                    divider={paymentMethodState.key === 'payme' && paymentMethodState.gateway === 'globalPayment'}
                    onPress={() =>
                      setOptionsPanelConfig({
                        title: t('app.page.setting.checkout.paymentGateway'),
                        defaultValue: paymentGatewayOptions.find(paymentGatewayOption => paymentGatewayOption?.key?.toLowerCase() === paymentMethodState?.gateway?.toLowerCase())?.value ?? paymentGatewayOptions[0].value,
                        closeOnSelect: true,
                        onChangeValue: (value) => setPaymentMethodState({
                          ...paymentMethodState,
                          gateway: value,
                        }),
                        options: paymentGatewayOptions,
                      })}
                  >
                    <SettingListItemText text={t('app.page.setting.checkout.paymentGateway')} />
                    <SettingListItemEnter
                      text={paymentGatewayOptions.find(paymentGatewayOption => paymentGatewayOption.key === paymentMethodState.gateway)?.display ?? paymentGatewayOptions[0].display}
                    />
                  </SettingListItem>
                  {
                    paymentMethodState.key === 'payme' && paymentMethodState.gateway === 'globalPayment' && (
                      <>
                        <SettingListItem divider>
                          <SettingListItemText text='Merchant ID' />
                          <SettingListInput
                            keyboardType='number-pad'
                            defaultValue={paymentMethodState?.merchantId ? paymentMethodState?.merchantId : ''}
                            onChangeText={(text) => {
                              setPaymentMethodState({
                                ...paymentMethodState,
                                merchantId: text,
                              })
                            }}
                          />
                        </SettingListItem>
                        <SettingListItem>
                          <SettingListItemText text='Terminal ID' />
                          <SettingListInput
                            keyboardType='number-pad'
                            defaultValue={paymentMethodState?.terminalId ? paymentMethodState?.terminalId : ''}
                            onChangeText={(text) => {
                              setPaymentMethodState({
                                ...paymentMethodState,
                                terminalId: text,
                              })
                            }}
                          />
                        </SettingListItem>
                      </>
                    )
                  }
                </>
              )
          }
          {
            (
              !paymentMethod.isEcrPayment &&
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.checkout.tips')} />
                  <SettingListItemRightAction>
                    <ToggleSwitch
                      value={paymentMethodState.tips}
                      onChangeValue={() => {
                        setPaymentMethodState({
                          ...paymentMethodState,
                          tips: !paymentMethodState.tips,
                        })
                      }}
                      size={23}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
            )
          }
        </SettingList>
      </View>

      <View style={styles.buttons}>
        {
          paymentMethod.custom &&
            <Button
              textBold
              title={t('app.common.delete')}
              style={styles.button}
              backgroundColor={colors.primary}
              disabled={!paymentMethod.key}
              onPress={handleDelete}
            />
        }
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
