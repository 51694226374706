import { FlatList, Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import styles from '../styles'

export default function ReceiptHeader (props) {
  const { items } = props
  const { t } = useTranslation()
  const merchant = useSelector(state => state.merchant.data)
  const { merchantLogo, merchantName, merchantAddress, merchantContact } = items

  return (
    <>
      {
        merchantLogo.enable && (
          <View style={styles.center}>
            <Image style={{ height: 150, width: 150 }} source={{ url: merchant.images.cover }} />
          </View>
        )
      }
      {
        merchantName.enable && (
          <View style={styles[merchantName.align]}>
            <Text style={[merchantName.bold && styles.bold, styles.medium]}>{merchant.name}</Text>
          </View>
        )
      }
      {
        merchantAddress.enable && (
          <View style={styles[merchantAddress.align]}>
            <Text style={[merchantAddress.bold && styles.bold, styles.small]}>{merchant.address}</Text>
          </View>
        )
      }
      {
        merchantContact.enable && (
          <View style={styles[merchantContact.align]}>
            <Text style={[merchantContact.bold && styles.bold, styles.small]}>{t('app.page.setting.printer.customizedSetting.phone')}：{merchant.contact}</Text>
          </View>
        )
      }
    </>
  )
}
