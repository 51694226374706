import PrintDoc from '@/libs/printing/PrintDoc'
import shortid from 'shortid'

/**
 * 打開收銀機
 */
export default class DrawerKick extends PrintDoc {
  TYPE = PrintDoc.Types.DRAWER_KICK

  constructor () {
    super()
    this.printId = shortid.generate()
  }

  print = () => {
    this.addData()
    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  addData = () => {
    const printer = this.printer
    printer.drawerKick()
  }
}
