import { StyleSheet, Text } from 'react-native'
import { numberWithCommas } from '@/libs/numberWithCommas'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function TableCell ({
  value,
  style,
  textStyle,
  isHeader = false,
  width,
  printError,
  justifyContent = 'center',
  displayConfig,
  field,
}) {
  const { t } = useTranslation()

  const cellStyle = {
    flex: width,
    justifyContent: justifyContent,
  }

  const valueStyle = {
    fontSize: isHeader ? 12 : 13,
    color: isHeader ? colors.white : colors.textSecondary,
  }

  if (printError && field === 'status') {
    value += '\n' + t('app.common.printError')
  }
  return (
    <Row style={[styles.container, cellStyle, style]}>
      <Text style={[valueStyle, textStyle]}>
        {displayConfig
          ? displayConfig[value] || value
          : value || value >= 0
            ? Array.isArray(value)
              ? value.map(option => option.name || option).join('/') // Item中tags, options, remarks有可能是Array
              : typeof value === 'number' && (field !== 'quantity' && field !== 'serial')
                ? numberWithCommas(value.toFixed(1))
                : String(value)
            : ' '}
      </Text>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    alignItems: 'center',
    paddingHorizontal: 6,
  },
})
