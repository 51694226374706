import { FlatList, SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { calculateStepIsComplete, calculateStepQuantityTotal, getStepQuantityMax, isBetweenDayTime } from '@/libs/order'
import { useSelectedBatchItem, useSelectedOrder, useSelectedSetMenuIndex } from '@/hooks/order'

import CategoryBarScrollButton from './ScrollButton'
import CategoryButton from './CategoryButton'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'

// eslint-disable-next-line no-unused-vars
import { IAppCategory, IAppSetStep } from 'dimorder-orderapp-lib/dist/types/AppMenu'

/**
 * @param {{ isSet: boolean, setName: string, categoryItems: (IAppCategory[] | IAppSetStep[]), selectedId: string, }} props
 */
export default React.memo(function CategoryBar (props) {
  const { isSet, setName, categoryItems, selectedId } = props
  const dispatch = useDispatch()
  const scrollViewRef = React.useRef()
  const scrollWidth = React.useRef(0)
  const scrollLeft = React.useRef(0)
  const selectedSetMenuIndex = useSelectedSetMenuIndex()
  const [selectedItem] = useSelectedBatchItem()
  const selectedOrder = useSelectedOrder()
  const [categoryWidth, setCategoryWidth] = React.useState([])
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  let filteredCategory = [...categoryItems]
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const isfilterByTime = useSelector(state => state.menu.isfilterByTime)

  if (isSet) {
    const selectedSetItems = _.map(selectedItem?.setItems, s => s.setMenuId)
    filteredCategory = filteredCategory.filter((c, index) => {
      if (!index) { // first cate
        return c
      }
      if (!(c?.dependsOnSetMenuItem || c?.dependsOnSetMenuItems?.length)) {
        return c
      }
      return selectedSetItems.includes(c.dependsOnSetMenuItem) || selectedSetItems.some(s => c?.dependsOnSetMenuItems?.includes(s))
    })
  }

  React.useEffect(() => {
    if (isSet && selectedSetStep) {
      const index = _.min(categoryWidth.findIndex(w => w?.id === selectedSetStep?.id), _.size(filteredCategory))
      try {
        scrollViewRef.current.scrollToIndex({ animated: false, index: _.max([index, 0]), viewPosition: 0 })
      } catch (error) {
        console.log(`scrollToIndex error: Index ${index}`, error)
      }
    }
  }, [selectedSetStep])

  return (
    <View style={styles.container}>
      {setName && (
        <Text style={styles.setName}>{setName}</Text>
      )}

      <CategoryBarScrollButton
        direction='left'
        onPress={() => {
          if (scrollViewRef.current) {
            scrollViewRef.current.scrollToOffset({
              animated: true,
              offset: 0,
            })
          }
        }}
      />
      <SafeAreaView style={{ flex: 1 }}>
        <FlatList
          ref={scrollViewRef}
          showsHorizontalScrollIndicator={false}
          onLayout={event => {
          // store scroll weight
            scrollWidth.current = event.nativeEvent.layout.width
          }}
          onScroll={event => {
          // store scroll position
            scrollLeft.current = event.nativeEvent.contentOffset.x
          }}
          scrollEventThrottle={16}
          horizontal
          data={filteredCategory}
          initialNumToRender={5}
          keyExtractor={item => item.id}
          getItemLayout={(data, index) => {
            const length = categoryWidth[index]?.width ?? 65
            const offset = [...categoryWidth].map(w => w?.width ?? 65).reduce((a, b, i) => index > i ? a + b : a, 0)
            return ({ length, offset, index })
          }}
          renderItem={({ item, index }) => {
            if (isfilterByTime) {
              if (!isBetweenDayTime(item)) return null
            }

            if (
              ((!categoryItems.categoryId &&
            !categoryItems.step &&
            item.categories?.length === 0 && // 沒有sub categories
            item.menus?.length === 0 && // 沒有menu
            item.sets?.length === 0) || // 沒有set
            (selectedOrder.deliveryType === 'table' && item.takeawayOnly) ||
            (item.disabled) ||
            (!item.menus && item.categoryId)
              )) {
              // 沒有 categoryId 和 stepId, 表示他本身是 category
              // 不顯示空的分類
              return null
            }
            let displayText = _.get(item?.localeNames, lang) ?? item.name
            const isComplete = isSet && calculateStepIsComplete(selectedItem, item, selectedSetMenuIndex)
            if (isSet && selectedItem) {
              const stepItemQuantity = calculateStepQuantityTotal(selectedItem, item, selectedSetMenuIndex)
              const stepMax = getStepQuantityMax(selectedItem, item, selectedSetMenuIndex)
              displayText += ` ${stepItemQuantity}/${stepMax}`
            }
            return (
              <CategoryButton
                key={item.id}
                text={displayText}
                selected={item.id === selectedId}
                isComplete={isComplete}
                onLayout={(event) => {
                  const { width } = event.nativeEvent.layout
                  const buttonWidth = [...categoryWidth]
                  buttonWidth[index] = { id: item.id, width }
                  setCategoryWidth(buttonWidth)
                }}
                onPress={() => {
                  if (isSet) {
                    scrollViewRef.current.scrollToIndex({ animated: true, index, viewPosition: 0 })
                    dispatch(actions.menu.selectSetStep(item))
                  } else {
                    dispatch(actions.menu.selectCategory(item))
                  }
                }}
              />
            )
          }}
        />
      </SafeAreaView>
      <CategoryBarScrollButton
        direction='right'
        onPress={() => {
          if (scrollViewRef.current) {
            scrollViewRef.current.scrollToOffset({
              animated: true,
              offset: scrollWidth.current,
            })
          }
        }}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.darkSecondary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 35,
    borderRadius: 17.5,
    marginHorizontal: 8,
  },
  setName: {
    color: colors.white,
    fontSize: 15,
    fontWeight: 'bold',
    paddingLeft: 16,
  },
})
