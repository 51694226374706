import { useSelector } from '@/redux'
import NumPadOrderHeader from './NumPadOrderHeader'
import PictureModeHeader from './PictureModeHeader'
import React from 'react'
import TextModeHeader from './TextModeHeader'

export default function index (props) {
  const isPictureMode = useSelector(state => state.menu.isPictureMode)
  const isNumPadOrder = useSelector(state => state.app.settings?.numPadOrder)

  if (isPictureMode) return <PictureModeHeader />
  if (isNumPadOrder) return <NumPadOrderHeader />
  return (
    <TextModeHeader />
  )
}
