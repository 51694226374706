import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

export const initPayment = {
  paymentMethod: null,
  paymentCharge: { percent: 0, amount: 0 }, // 手續費
  gateway: '',
  payload: '',
  ref: '',
  amount: 0, // 應付金額 (會在 convertPaymentFieldToNumber 時調整)
  paidAmount: '0', // 實收金額
  paymentTips: '0', // 小費
  change: 0,
  remark: '',
  createdAt: null,
  autoInput: false,
}

/** @type {IOrderCheckoutState} */
export const initialState = {
  orderId: null, // 結帳中的 order id
  payment: null, // 結帳中的 draft payment
  submitting: false,
  connectGateway: true,
  failedBBMSL: null,
}

export default produce(
  /**
   * @param {IOrderCheckoutState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.CLEAN_CHECKOUT_ORDER: {
        return initialState
      }
      case ActionTypes.CHECKOUT_ORDER: {
        const { orderId } = action.payload
        draft.orderId = orderId
        break
      }
      case ActionTypes.UPDATE_SUBMITTING: {
        const { submitting } = action.payload
        draft.submitting = submitting
        break
      }
      case ActionTypes.UPDATE_PAYMENT: {
        const { payment } = action.payload
        draft.payment = payment
        break
      }
      case ActionTypes.UPDATE_PAYMENT_FIELD: {
        const { field, value } = action.payload
        draft.payment[field] = value
        break
      }
      case ActionTypes.ALL_CHANGE_TO_TIPS: {
        const lastPayment = _.last(draft.checkoutOrder.payments)
        lastPayment.paymentTips = lastPayment.change
        lastPayment.change = 0
        break
      }
      case ActionTypes.EDIT_FAILED_BBMSL: {
        const { data } = action.payload
        draft.failedBBMSL = data
        break
      }
      case ActionTypes.UPDATE_CONNECT_GATEWAY: {
        const { connect } = action.payload
        draft.connectGateway = connect
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
