import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useDispatch, useSelector } from '@/redux'
import { useHistory } from '@/libs/reactRouter'
import BackNavigator from '@/components/Setting/BackNavigator'
import Column from '@/components/Column'
import Row from '@/components/Row'
import TableHeader from '@/components/OrderTable/TableHeader'
import TableRow from '@/components/OrderTable/TableRow'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import OpeningInfo from './OpeningInfo'

// item 使用的欄位名稱
const fields = ['startText', 'endText', 'orderBeforeMinText']
// 欄寬
const widths = [70, 70, 70]
// 對齊
const justifyContents = [
  'center',
  'center',
  'center',
]

export default function opening (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { openings } = useSelector(state => state.setting)
  const [selectedOpening, setSelectedOpening] = React.useState(null)

  const days = {
    1: t('app.constant.weekday.mon'),
    2: t('app.constant.weekday.tue'),
    3: t('app.constant.weekday.wed'),
    4: t('app.constant.weekday.thur'),
    5: t('app.constant.weekday.fri'),
    6: t('app.constant.weekday.sat'),
    0: t('app.constant.weekday.sun'),
  }

  // 表頭
  const headers = [t('app.page.setting.aboutPanel.opening.start'), t('app.page.setting.aboutPanel.opening.end'),
    t('app.page.setting.aboutPanel.opening.cutoff')]

  const renderOpening = ({ item: opening, index }) => {
    const startText = `${_.padStart(opening.start, 2, '0')}:${_.padStart(opening.startMin, 2, '0')}`
    const endText = `${_.padStart(opening.end, 2, '0')}:${_.padStart(opening.endMin, 2, '0')}`
    const orderBeforeMinText = `${opening.orderBeforeMin}${t('app.page.setting.aboutPanel.opening.min')}`
    return (
      <TouchableOpacity onPress={() => { setSelectedOpening(opening) }}>
        <TableRow
          item={{ startText, endText, orderBeforeMinText }}
          fields={fields}
          widths={widths}
          justifyContents={justifyContents}
          style={[styles.row,
            selectedOpening?.id === opening.id && { backgroundColor: colors.tertiary },
          ]}
          textStyle={styles.text}
        />
      </TouchableOpacity>)
  }

  const renderDay = ({ item, index }) =>
    <FlatList
      data={item}
      renderItem={renderOpening}
      keyExtractor={(item) => item.id}
      ListHeaderComponent={() => (
        <>
          <View style={styles.groupContainer}>
            <View style={styles.group}>
              <Text style={styles.groupText}>{t('app.constant.weekday.week')}{days[index]}</Text>
            </View>

            <TouchableOpacity
              style={styles.addButton}
              onPress={() => {
                setSelectedOpening({
                  day: index,
                  start: '0',
                  startMin: '0',
                  end: '23',
                  endMin: '59',
                  orderBeforeMin: '',
                  takeaway: false,
                  storeDelivery: false,
                  surcharge: '',
                })
              }}
            >
              <Text style={styles.groupText}>＋ {t('app.common.add')}</Text>
            </TouchableOpacity>

          </View>
          <TableHeader
            headers={headers}
            widths={widths}
            justifyContents={justifyContents}
            style={styles.header}
            textStyle={styles.text}
          />
        </>)}
    />

  return (
    <Column style={{ flex: 1 }}>
      <BackNavigator
        text={t('app.page.setting.aboutPanel.opening.openingHour')}
        onPress={() => history.goBack()}
      />
      <Row style={styles.container}>
        <View style={styles.leftContainer}>
          <FlatList
            data={openings}
            renderItem={renderDay}
            keyExtractor={(item, index) => days[index]}
            showsVerticalScrollIndicator={false}
          />
        </View>

        <View style={styles.rightContainer}>
          <OpeningInfo
            selectedOpening={selectedOpening} setSelectedOpening={setSelectedOpening}
          />
        </View>
      </Row>
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  leftContainer: {
    height: '100%',
    width: 340,
    backgroundColor: colors.white,
    borderRadius: 5,
    overflow: 'hidden',
    ...shadows.default,
  },
  rightContainer: {
    height: '100%',
    flex: 1,
    paddingLeft: 20,
  },
  groupContainer: {
    height: 40,
    flexDirection: 'row',
    backgroundColor: colors.primary,
  },
  group: {
    flex: 1,
    paddingLeft: 20,
    justifyContent: 'center',
  },
  groupText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.white,
  },
  addButton: {
    width: 90,
    backgroundColor: colors.secondary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    height: 30,
    backgroundColor: colors.light,
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.gray,
  },
  row: {
    height: 45,
    backgroundColor: colors.transparent,
  },
})
