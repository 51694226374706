import EscPosEncoder from 'esc-pos-encoder' // eslint-disable-line no-unused-vars
import md5 from 'md5'
import * as FileSystem from 'expo-file-system'
import * as iconv from 'iconv-lite'

import { PrinterFontSize } from '@/constants/printer'
import { dimorderApi } from '@/libs/api/dimorder'
import store from '@/redux/store'

import ImageMode from '../BmpImage/ImageMode' // eslint-disable-line no-unused-vars

const Buffer = require('buffer/').Buffer // note: the trailing slash is important!

/** @type {() => IRootState} */
const getState = store.getState

/**
 * Printer Programming Manual Search: https://www.manualslib.com/
 *
 */
export default class Printer {
  TYPE = '' // initialized by each printer
  PRINTER_BRAND = '' // initialized by each printer
  PRINTER_COMMAND = '' // initialized by each printer
  PRINT_METHOD = '' // initialized by each printer
  USAGES = [] // initialized by each printer
  ENCODINGS = {} // initialized by each printer
  DEFAULT_ENCODING = '' // initialized by each printer
  MAX_WIDTH = 0 // initialized by each printer
  DOT_WIDTH = 0 // initialized by each printer

  _commandBuffer = Buffer.alloc(0)
  _stringBuffer = Buffer.alloc(0)

  encoding = this.DEFAULT_ENCODING // initialized by each printer
  /** @type {EscPosEncoder?} */
  encoder // initialized by Gprinter, SP700, TM_U200B, TM_T88VI, TSP043, TP808, TSP100IV
  /** @type {ImageMode?} */
  imageMode // initialized by SP700, TM_U200B, TM_T88VI, TSP100III, TSP100IV
  isImageMode = false // initialized by SP700, TM_U200B
  chineseTextWidth = 2 // 中文字符相對於英文字符的寬度比，用來算幾個中文字符要換行（測量出的結果） TM_U220B = 1.5

  /**
   * 串接 Buffer 到 _commandBuffer 後面
   * @param {Buffer} buffer
   * @returns {Buffer}
   */
  concat (buffer) {
    this._commandBuffer = Buffer.concat([this._commandBuffer, buffer])
    return this._commandBuffer
  }

  /**
   * 將印表機指令轉為 Buffer 後串接到到 _commandBuffer
   * @param {number[]} decimalArray decimal bytes array
   */
  writeNumbers (decimalArray) {
    this._commandBuffer = Buffer.concat([this._commandBuffer, Buffer.from(decimalArray)])
  }

  /**
   *
   * @param {string} text
   * @param {string} encoding
   * @param {boolean} [buffOnly=false]
   */
  writeString (text, encoding, buffOnly = false) {
    const normalizeText = text?.normalize('NFKC')
    if (encoding) {
      if (!buffOnly) {
        this._commandBuffer = Buffer.concat([this._commandBuffer, iconv.encode(normalizeText, encoding)])
      }
      this._stringBuffer = Buffer.concat([this._stringBuffer, iconv.encode(normalizeText + '\n', encoding)])
    } else {
      if (!buffOnly) {
        this._commandBuffer = Buffer.concat([this._commandBuffer, Buffer.from(normalizeText)])
      }
      this._stringBuffer = Buffer.concat([this._stringBuffer, Buffer.from(normalizeText + '\n')])
    }
  }

  /**
   *
   * @returns {Buffer}
   */
  getCommandBuffer () {
    return this._commandBuffer
  }

  /**
   *
   * @returns {string[]}
   */
  getBufferStrings () {
    return iconv.decode(this._stringBuffer, this.encoding).split('\n')
  }

  /**
   * 廚房單（Kitchen Batch）價格欄位的寬度
   * ! NOT-support: TDP225
   * @returns
   */
  getPriceMaxWidth () {
    throw new Error('NOT_SUPPORTED')
  }

  /**
   * 廚房單（Kitchen Batch）名稱欄位的寬度
   * ! NOT-support: TDP225
   * @returns
   */
  getItemMaxWidth () {
    throw new Error('NOT_SUPPORTED')
  }

  /**
   * 廚房單（Kitchen Batch）數量欄位的寬度
   * ! NOT-support: TDP225
   * @returns
   */
  getQuantityMaxWidth () {
    throw new Error('NOT_SUPPORTED')
  }

  /**
   * 收據單（Order Receipt）專用的排版（數字代表英文字寬度）
   * 預設格式為：[item, quantity, price]
   * Customized Order Receipt addContent 有自訂順序的方式 (style.format)
   * ! NOT-support: TDP225, SP700, TM_U220B
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @returns
   */
  getReceiptTextWidth (fontSize) {
    throw new Error('NOT_SUPPORTED')
  }

  /**
   * 報表（Report）專用的排版
   * 僅有熱敏打印機支援報表列印功能
   * ! ONLY-support: GPrinter, TM_T88VI, TP808, TSP043, TSP100III, TSP100IV
   * @param {0 | 1 | 2 | 'small' | 'medium' | 'large'} fontSize
   * @returns
   */
  getReportTextWidth (fontSize) {
    throw new Error('NOT_SUPPORTED')
  }

  /**
   * ImageMode 是把文字轉成圖片
   * TSP100III只能印圖片
   * SP700, TM_U220B 因為針機字型選擇比較少，需要用圖片來打印更大的字型
   * GPrinter, TM_T88VI, TP808, TSP043, TSP100IV 這幾部本身就有較多字型選擇所以不需要用到
   * @param {boolean?} on
   * @returns
   */
  setImageMode (on = true) {
    return this
  }

  /**
   * ! NOT-support: TDP225
   * @returns
   */
  addInit () {
    return this
  }

  /**
   * FS &
   * [Name] Select Kanji character mode
   * [Description] Selects Kanji character mode.
   * ! NOT-support: TSP100III, SP700
   * orverride: TDP225
   * @returns
   */
  addCodePage () {
    return this
  }

  /**
   * 進入泰文字符模式
   * * ONLY-support: TSP043, TP808, TM_T88VI, TM_U220B
   * @returns
   */
  addPrintThai () {
    return this
  }

  /**
   * 對印表機輸入指令
   * ! NOT-support: TDP225
   * @param {number[]} command
   * @returns
   */
  addCommand (command) {
    this.writeNumbers(command)
    return this
  }

  /**
   * 加入文字
   * * override: every printer
   * @param {string} text
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @returns
   */
  addText (text, fontSize) {
    return this
  }

  /**
   * 加入餐廳圖片
   * * override: TDP225, TSP100III
   * @returns
   */
  async addImage () {
    const logoUrl = getState().merchant.data.images.cover
    const logoDir = `${FileSystem.documentDirectory}${md5(logoUrl)}/`
    const iconTxtUri = `${logoDir}logo.txt`
    const fileInfo = await FileSystem.getInfoAsync(iconTxtUri)

    if (!fileInfo.exists) {
      const { data } = await dimorderApi.merchantPrinter.getPrintImage(encodeURIComponent(logoUrl))
      await FileSystem.makeDirectoryAsync(logoDir, { intermediates: true })
      await FileSystem.writeAsStringAsync(iconTxtUri, data.imageString)
    }

    const command = await FileSystem.readAsStringAsync(iconTxtUri)
    this.writeNumbers(command.split(','))
    return this
  }

  /**
   * 加入餐廳 Logo
   * @returns
   */
  addLogo () {
    return this
  }

  /**
   * 加入 QRCode
   * ! NOT-support: TSP100III
   * @param {string} url
   * @returns
   */
  addQRCode (url) {
    return this
  }

  /**
   * 加入 Barcode
   * ! ONLY-support: Gprinter, TM_T88VI, TSP100IV
   * @param {object} style
   * @returns
   */
  addBarcode (style) {
    return this
  }

  /**
   * 粗體字體
   * ! NOT-support: TDP225, SP700
   * @param {boolean?} on
   * @returns
   */
  addBoldMode (on = true) {
    return this
  }

  /**
   * 針機打印兩下加深顏色
   * ! ONLY-support: SP700, TM_U220B
   * @param {boolean?} on
   * @returns
   */
  addDoubleStrikeMode (on = true) {
    return this
  }

  /**
   * 斜體字體
   * ! NOT-support: TDP225, TSP100III
   * @param {boolean?} on
   * @returns
   */
  addItalicMode (on = true) {
    return this
  }

  /**
   * 底線字體
   * ! NOT-support: TDP225
   * @param {boolean?} on
   * @returns
   */
  addUnderlineMode (on = true) {
    return this
  }

  /**
   * 文字對齊
   * @param {'left' | 'center' | 'right'} align
   * @returns
   */
  addAlign (align) {
    return this
  }

  /**
   * 針機選擇打印顏色 只會用在廚房單，熱感不會有顏色
   * ! ONLY-support: SP700, TM_U220B
   * @param {string} color
   * @returns
   */
  addColor (color) {
    return this
  }

  /**
   * 加入空行
   * @param {number?} rows 加入幾行空行
   */
  addRowFeed (rows = 1) {
    return this
  }

  /**
   * ? 重複性有點高的無註解複雜程式，待整理
   * ! NOT-support: TDP225
   * @returns
   */
  addFixedRow () {
    return this
  }

  /**
   * ? 幹嘛用的？ 看起來就是 addRowFeed 的變形？
   * * override: TSP100III, TDP225
   */
  addTextConnect () {
    this.addRowFeed()
    // 每次重新開MR的時候確保ASB enable
    this.enableASB()
  }

  /**
   * 加入底線
   * ! NOT-support: TDP225
   * * override: TSP100III
   * @param {'-' | '='?} character
   * @returns
   */
  addUnderline (character = '-') {
    const width = this.MAX_WIDTH
    const fontSize = PrinterFontSize.SMALL
    this
      .addText(character.repeat(width), fontSize)
      .addRowFeed()
    return this
  }

  /**
   * 加入分隔線
   * ! NOT-support: TDP225
   * @param {boolean?} linebreak 於分隔線上方加入一行空行
   * @param {'-' | '='} character
   * @returns
   */
  addSeparator (linebreak = true, character = '-') {
    if (linebreak) this.addRowFeed()
    this.addColor('black')
    this.addUnderline(character)
    return this
  }

  /**
   * TODO comments
   * @returns
   */
  addStraightLine () {
    return this
  }

  /**
   * HT
   * [Name] Horizontal tab
   * [Description] Moves the print position to the next horizontal tab position.
   * ! NOT-support: TSP100III, TDP225
   * @param {number?} size
   * @returns
   */
  addTab (size = 1) {
    this.writeNumbers(Array(size).fill(9))
    return this
  }

  /**
   * ESC D n1...nk NUL
   * [Name] Set horizontal tab positions
   * [Description] Sets horizontal tab positions.
   * ! NOT-support: TSP100III, TDP225
   * @param {number[]?} widths
   * @returns
   */
  addSetTabPosition (widths = []) {
    this.writeNumbers([27, 68, ...widths, 0]) // 0 is IMPORTANT (but why?)
    return this
  }

  /**
   * ESC B n t
   * [Name] Printer Beeper Notification
   * [Description] Beeper functions when receipt comes.
   * ! NOT-support: TSP100III
   * * override: SP700, TSP100IV
   * @param {1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9} n
   * @param {1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9} t
   * @returns
   */
  addBeep (n = 2, t = 1) {
    this.writeNumbers([27, 66, n, t])
    return this
  }

  /**
   * 切紙
   * @returns
   */
  addCutPaper () {
    return this
  }

  /**
   * 列印測試
   * * override: TSP100III, TDP225
   * @returns
   */
  addPrintTest () {
    this.addText('Print Test')
    this.addRowFeed()
    this.addText(new Date().toISOString())
    this.addRowFeed(5)
    this.addCutPaper()
    return this
  }

  /**
   * ESC p m t1 t2
   * [Name] Generate pulse
   * 打開收銀機
   * ! NOT-support: TSP100III, TDP225
   * * override: TSP100IV
   * @returns
   */
  drawerKick () {
    this.writeNumbers([27, 112, 0, 48, 1, 1])
    return this
  }

  /**
   * Enable to receive real-time printer status
   * Only support: TSP100IV
   * @returns
   */
  enableASB () {
    return this
  }

  /**
   * 用來試 printer 會不會有 print status return 給我們
   * 但 gprinter 的 return 的 status 好像沒甚麼有價值用途
   * ! NOT-support: TSP100III, TDP225
   * @deprecated 沒有使用
   * @returns
   */
  statusTransmission () {
    return this
  }
}
