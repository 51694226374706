import { Image, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { useSelectedOrder } from '@/hooks/orderHistory'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'

import PaymentInfo from './PaymentInfo'

const ICON = require('@icons/checkout/done.png')

/**
 * @typedef CompleteAllContentProps
 * @property {() => void} onUpdateTipPress
 */

/**
 *
 * @param {CompleteAllContentProps} props
 * @returns
 */
export default function CompleteAllContent (props) {
  const { onUpdateTipPress } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedOrder = useSelectedOrder()
  const allPayments = selectedOrder.payments
  const filteredPayments = _.filter(allPayments, payment => payment.createdAt && payment.status === 'paid')

  return (
    <>
      <View style={styles.borderView}>
        <Image style={styles.doneIcon} source={ICON} />
        <Text style={styles.completeText}>{t('app.page.checkout.paymentResult.orderComplete')}</Text>
        <Text style={styles.orderSerialText}>{`${t('app.constant.order.serial')}：${selectedOrder.serial}`}</Text>
      </View>

      {/* payment records */}
      {
        filteredPayments.map((payment, index) => (
          <PaymentInfo
            key={index}
            payment={payment}
            bottomLine={index + 1 !== filteredPayments.length}
            onUpdateTipPress={() => onUpdateTipPress(payment)}
          />
        ))
      }
      {/* confirm button */}
      <Button
        title={t('app.page.checkout.paymentResult.paymentComplete')}
        textBold
        style={styles.confirmButton}
        textStyle={{ fontSize: 16, fontWeight: 'bold' }}
        backgroundColor={colors.darkSecondary}
        onPress={() => {
          dispatch(actions.orderCheckout.completeCheckout())
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  borderView: {
    width: 270,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.primary,
    borderWidth: 2,
    borderRadius: 5,
    marginBottom: 16,
    padding: 8,
  },
  doneIcon: {
    width: 61,
    height: 61,
    margin: 8,
  },
  completeText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: colors.primary,
  },
  orderSerialText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primary,
    margin: 8,
  },
  confirmButton: {
    alignSelf: 'flex-end',
    height: 48,
    width: 125,
    margin: 0,
    marginTop: 24,
  },
})
