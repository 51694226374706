import { StyleSheet } from 'react-native'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { initBatch } from '@/redux/order/reducer'

import Column from '@/components/Column'
import ItemNumberInputDialog from '@/components/dialogs/ItemNumberInputDialog'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import RightNavBar from '@/components/RightNavBar'
import Row from '@/components/Row'

import AreaBar from './AreaBar'
import BookingDialog from './Dialogs/BookingDialog'
import FloorPlan from './FloorPlan'
import Header from './Header'
import NewBookingDialog from './Dialogs/NewBookingDialog'
import NewOrderDialog from './Dialogs/NewOrderDialog'
import OrderActions from './OrderActions'
import SearchResult from './SearchResult'
import TableArea from './TableArea'
import TableDialog from './Dialogs/TableDialog'

// eslint-disable-next-line no-unused-vars
import { ITable } from '@/redux/table/Table.d'

const DEFAULT_BOOKING_INFO = {
  name: '',
  customerNameSuffix: '',
  memberId: '',
  phone: '',
  remark: '',
  adults: 1,
  children: 0,
  tags: [],
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function Table (props) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { isSearching } = useSelector(state => state.table)
  const { menus, categoryTags } = useSelector(state => state.menu)
  const { rapidOrder, selectPrinterBeforePrint } = useSelector(state => state.app.settings)
  const openingPrompt = useSelector(state => state.table.dialog.openingPrompt)
  const selectedOrder = useSelector(state => state.order.selectedOrder)
  const merchant = useSelector(state => state.merchant.data)
  const historyOrders = useSelector(state => state.orderHistory.orders)

  const {
    qrDisableCustomerCount,
    enableCategoryTag,
    qrcode: enableQrcode,
    enableDynamicQRCode,
    enableTnDmode,
    quickMode: enableQuickMode,
    enableFloorPlan,
  } = merchant?.setting ?? {}

  const filteredMenus = React.useMemo(() => {
    return _.filter(_.values(menus), menu => menu.openingPrompt)
  }, [menus])

  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)
  const [tagSelectDialogOpen, setTagSelectDialogOpen] = React.useState(false)
  const [openingPromptVisible, setOpeningPromptVisible] = React.useState(false)
  const [selectedTableKey, setSelectedTableKey] = React.useState('')

  const categoryTagOptions = React.useMemo(() => {
    return _.map(categoryTags, tag => ({ value: tag.id, display: tag.name }))
  }, [categoryTags])

  React.useEffect(() => {
    if (enableTnDmode) {
      history.push('/orderHistory')
    }

    if (enableQuickMode) {
      dispatch(actions.auth.permissionCheck(
        'order-takeaway',
        () => { history.push('/order') },
        () => { history.push('/orderHistory') },
      ))
    }
  }, [enableTnDmode, enableQuickMode])

  React.useEffect(() => {
    return () => {
      dispatch(actions.table.resetAllActions())
    }
  }, [])

  React.useEffect(() => {
    if (openingPrompt) {
      if (!_.isEmpty(filteredMenus)) {
        setOpeningPromptVisible(true)
      } else {
        dispatch(actions.table.closeDialog(['openingPrompt']))
        if (!rapidOrder) {
          dispatch(actions.table.showDialog(['table']))
        } else if (rapidOrder === 'place') {
          dispatch(actions.order.startOrder())
        }
      }
    }
  }, [openingPrompt, filteredMenus, rapidOrder])

  const generateBooking = (tableKey) => {
    return ({
      ...DEFAULT_BOOKING_INFO,
      table: tableKey,
      createdAt: moment().utc().toISOString(),
    })
  }

  /**
   *
   * @param {ITable} table
   * @returns
   */
  const onSelectTable = (table) => {
    dispatch(actions.table.selectTable(table.id))

    const hasTablePendingOrder = _.some(historyOrders, order => {
      return _.includes(table.orderIds, order.id) && order.status === 'pending'
    })
    if (hasTablePendingOrder) {
      dispatch(actions.table.showDialog(['table']))
      return
    }

    if (rapidOrder) {
      dispatch(actions.auth.permissionCheck(
        'merchant-staff-order',
        () => {
          if (qrDisableCustomerCount) {
            if (enableCategoryTag && categoryTagOptions.length !== 0 && enableQrcode && enableDynamicQRCode) {
              setTagSelectDialogOpen(true)
              setSelectedTableKey(table.key)
            } else if (selectPrinterBeforePrint && enableQrcode && enableDynamicQRCode) {
              setPrinterDialogOpen(true)
              setSelectedTableKey(table.key)
            } else {
              const booking = generateBooking(table.key)
              dispatch(actions.table.createOrder(booking))
            }
          } else {
            dispatch(actions.table.showDialog(['newOrder']))
          }
        },
      ))
      return
    }

    dispatch(actions.table.showDialog(['table']))
  }

  // 判斷顯示 FloorPlan/檯號群
  const TableContainer = enableFloorPlan ? FloorPlan : TableArea

  return (
    <Row style={styles.container}>
      {/* main */}
      <Column style={styles.contentContainer}>
        <Header />
        {/* <BookingList /> */}
        {isSearching && <SearchResult />}

        <Row style={styles.row}>
          <AreaBar />
          <OrderActions />
          <TableContainer onSelectTable={onSelectTable} />
        </Row>
      </Column>
      <RightNavBar />

      {/* dialogs */}
      <NewBookingDialog />
      <BookingDialog />
      <TableDialog />
      <NewOrderDialog />
      <ItemNumberInputDialog
        open={openingPromptVisible}
        onClose={() => {
          setOpeningPromptVisible(false)
          dispatch(actions.table.closeDialog(['openingPrompt']))
        }}
        items={filteredMenus}
        selectAll={false}
        useDefault={false}
        onSubmit={async (quantity) => {
          const selectedItems = filteredMenus.filter(o => quantity[o?.id])

          if (rapidOrder === 'place') {
            await dispatch(actions.order.startOrder(selectedOrder?.id))
          }

          if (selectedItems.length) {
            const now = moment()
            const newBatchId = uuid()

            await dispatch(
              actions.orderBatch.selectBatch({
                ...initBatch,
                id: newBatchId,
                orderId: selectedOrder.id,
                orderSerial: selectedOrder.serial,
                batchId: newBatchId,
                index: selectedOrder.batches.length,
                table: selectedOrder.table,
                status: 'confirmed',
                createdAt: new Date(),
                submittedat: now.utc().toISOString(),
                confirmedat: now.utc().toISOString(),
                creatorId: merchant.id,
              }),
            )
            for (let i = 0; i < selectedItems.length; i++) {
              await dispatch(actions.orderBatch.addItem(selectedItems[i], quantity[selectedItems[i]?.id]))
              if (i === selectedItems.length - 1) {
                if (rapidOrder !== 'place') {
                  dispatch(actions.order.submitOrderBatch())
                }
              }
            }
          }
        }}
      />
      <PrinterDialog
        open={printerDialogOpen}
        onClose={() => setPrinterDialogOpen(false)}
        onSelect={(selectedPrinterId) => {
          const booking = generateBooking(selectedTableKey)
          dispatch(actions.table.createOrder(booking, null, selectedPrinterId))
        }}
      />
      <OptionsSelectDialog
        open={tagSelectDialogOpen}
        onClose={() => setTagSelectDialogOpen(false)}
        onSubmit={async (categoryTagId) => {
          if (selectPrinterBeforePrint && enableQrcode && enableDynamicQRCode) {
            setPrinterDialogOpen(true)
          } else {
            const booking = generateBooking(selectedTableKey)
            await dispatch(actions.table.createOrder(booking, categoryTagId, ''))
            setTagSelectDialogOpen(false)
          }
        }}
        options={categoryTagOptions}
        title={t('app.page.table.dialog.categoryTag')}
        enableButtonDisable
        disableHeader
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
  },
  row: {
    flex: 1,
  },
})
