import { StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

import { getStatusConfig } from '@/libs/orderStatus'
import Column from '@/components/Column'
import Row from '@/components/Row'
import colors from '@/theme/colors'

import { numberWithCommas } from '@/libs/numberWithCommas'
import OrderStatus from './OrderStatus'

export default function OrderInfo ({ order }) {
  const { t } = useTranslation()
  const { serial, table, customers, createdAt, pickupAt, subTable, shipping, roundedTotal } = order

  const orderStatus = getStatusConfig(order)

  const Details = {
    table: () => (
      <Column style={styles.tableDetails}>
        <Text style={styles.noText}>{table}{subTable > 0 ? `(${String.fromCharCode(subTable + 64)})` : ''}</Text>
        <Text style={styles.text}>{t('app.constant.order.serial')}：{serial}</Text>
      </Column>
    ),
    takeaway: () => (
      <Column style={styles.details}>
        <Text style={styles.text}>{t('app.constant.order.pickupAt')}：{moment(pickupAt).format('HH:mm')}</Text>
        <Text style={styles.text}>{t('app.constant.order.pickupDate')}：{moment(pickupAt).format('DD/MM/YYYY')}</Text>
        <Text style={styles.text}>{t('app.constant.order.serial')}：{serial}</Text>
      </Column>
    ),
    storeDelivery: () => (
      <Column style={styles.details}>
        <Text style={styles.text}>{t('app.constant.order.pickupDate')}：{moment(pickupAt).format('DD/MM/YYYY')}</Text>
        <Text style={styles.text}>{t('app.constant.order.serial')}：{serial}</Text>
        <Row style={styles.more}>
          {/* <Text style={styles.text}>外送員：{shipping.lalamove.driverName}</Text> */}
          {
            shipping.lalamove && (
              <Text style={styles.text}>{t('app.constant.order.pickupAt')}：{moment(shipping.lalamove.scheduleAt).add(10, 'm').format('HH:mm')}</Text>
            )
          }
          <Text style={styles.text}>{t('app.constant.order.completeAt')}：{moment(pickupAt).format('HH:mm')}</Text>
        </Row>
      </Column>
    ),
  }

  const Detail = Details[order.deliveryType]

  return (
    <Row style={styles.container}>
      <Column style={styles.id}>
        {
          order.deliveryType === 'table'
            ? <Text style={styles.tableText}>{t('app.constant.order.table')}</Text>
            : <Text style={styles.idText}>{serial.slice(-3)}</Text>
        }
        <OrderStatus
          text={orderStatus.text}
          backgroundColor={orderStatus.color}
        />
      </Column>

      <Detail />

      <Text style={styles.price}>
        ${numberWithCommas(roundedTotal)}
      </Text>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  id: {
    alignItems: 'center',
    marginHorizontal: 8,
  },
  noText: {
    fontSize: 45,
    fontWeight: 'bold',
    color: colors.primary,
  },
  tableText: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.primary,
    paddingBottom: 5,
  },
  idText: {
    fontSize: 35,
    fontWeight: 'bold',
    color: colors.primary,
  },
  details: {
    flex: 1,
    borderColor: colors.primary,
    borderBottomWidth: 1,
  },
  tableDetails: {
    flex: 1,
    borderColor: colors.primary,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 50,
  },
  text: {
    fontSize: 12,
    lineHeight: 22,
    fontWeight: 'bold',
    color: colors.primary,
  },
  more: {
    justifyContent: 'space-between',
    borderColor: colors.primary,
    borderTopWidth: 1,
  },
  price: {
    fontSize: 23,
    fontWeight: 'bold',
    color: colors.primary,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})
