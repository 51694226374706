import { Icon } from 'react-native-elements'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import React from 'react'
import colors from '@/theme/colors'

export default function FilterMenuItemByTimeButton ({ style }) {
  const dispatch = useDispatch()
  const isfilterByTime = useSelector(state => state.menu.isfilterByTime)

  return (
    <TouchableOpacity onPress={() => {
      dispatch(actions.menu.updatefilterByTime(!isfilterByTime))
    }}
    >
      <Icon
        size={24}
        name={isfilterByTime ? 'calendar-check' : 'calendar'}
        type='font-awesome-5'
        color={colors.primary}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  image: {
    width: 38,
    height: 38,
    margin: 8,
  },
})
