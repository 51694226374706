import { Image, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import HeaderRow from './HeaderRow'
import React from 'react'
import colors from '@/theme/colors'

const expandIcons = {
  true: require('@icons/collapse-white.png'),
  false: require('@icons/expand-white.png'),
}

export default function BatchHeader (props) {
  const dispatch = useDispatch()
  const isBatchListExpand = useSelector(state => state.menu.isBatchListExpand)

  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback onPress={() => dispatch(actions.menu.updateBatchListExpand(!isBatchListExpand))}>
        <View style={styles.expandButton}>
          <Image style={{ width: 12, height: 12 }} source={expandIcons[isBatchListExpand]} />
        </View>
      </TouchableWithoutFeedback>
      <View style={styles.header}>
        <HeaderRow />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // width: 580,
    width: '100%',
    minWidth: 580,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  expandButton: {
    width: 37,
    height: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  header: {
    width: '100%',
    backgroundColor: colors.primary,
    borderTopRightRadius: 16,
    overflow: 'hidden',
  },
})
