import EscPosEncoder from 'esc-pos-encoder'
import _ from 'lodash'

import {
  PrintMethod,
  PrinterBrand,
  PrinterCommand,
  PrinterEncoding,
  PrinterFontSize,
  PrinterType,
  PrinterUsage,
  StyleFontSizeToPrinterFontSizeMap,
} from '@/constants/printer'
import PrintUtiliy from '@/libs/printing/PrintUtiliy'
import Printer from '@/libs/printing/printer/Printer'
import store from '@/redux/store'

import ImageMode from '../BmpImage/ImageMode'
import getImageCommand from '../BmpImage/getImageCommand'
import straightLine from '../BmpImage/straightLine'

/** @type {() => IRootState} */
const getState = store.getState

/**
 * EPSON 熱敏打印機
 * encoding - 中文: big5
 * command: ESC/POS
 * 用途：廚房單／收據
 * Printer Programming Manual: https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=72
 */
export default class TM_T88VI extends Printer {
  TYPE = PrinterType.TM_T88VI
  PRINTER_BRAND = PrinterBrand.EPSON
  PRINTER_COMMAND = PrinterCommand.ESC_POS
  PRINT_METHOD = PrintMethod.THERMAL
  USAGES = [PrinterUsage.KITCHEN_BATCH, PrinterUsage.ORDER_RECEIPT]
  ENCODINGS = { BIG5: PrinterEncoding.BIG5, TIS620: PrinterEncoding.TIS620 }
  DEFAULT_ENCODING = this.ENCODINGS.BIG5
  MAX_WIDTH = 42
  DOT_WIDTH = 512

  encoding = this.DEFAULT_ENCODING
  encoder = new EscPosEncoder()
  imageMode = new ImageMode(this.DOT_WIDTH, this.TYPE)

  addInit () {
    const command = this.encoder.initialize().encode()
    this.writeNumbers(command)
    return this
  }

  addCodePage () {
    this.encoding = this.DEFAULT_ENCODING
    this.writeNumbers([28, 38])
    return this
  }

  addPrintThai () {
    this.encoding = this.ENCODINGS.TIS620
    // 退出中文字符模式
    this.writeNumbers([28, 46])
    // Thai code page
    this.writeNumbers([27, 116, 21])
    return this
  }

  /**
   *
   * @param {string} text
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @returns
   */
  addText (text, fontSize) {
    console.log(`text(style=${fontSize}): `, text)
    // check current text style to prevent duplicate call
    if (typeof fontSize !== 'undefined') {
      switch (fontSize) {
        case PrinterFontSize.EXTRALARGE:
        case 5:
          this.writeNumbers([29, 33, 51])
          break
        case PrinterFontSize.LARGEWIDTH:
        case 4:
          this.writeNumbers([29, 33, 33])
          break
        case PrinterFontSize.MEDIUWIDTH:
        case 3:
          this.writeNumbers([29, 33, 16])
          break
        case PrinterFontSize.LARGE:
        case 2:
          this.writeNumbers([29, 33, 34])
          break
        case PrinterFontSize.MEDIUM:
        case 1:
          this.writeNumbers([29, 33, 17])
          break
        default:
          this.writeNumbers([29, 33, 0])
      }
    }
    console.log('this.encoding', this.encoding)

    if (typeof text !== 'undefined') {
      this.writeString(text, this.encoding)
    }

    return this
  }

  addLogo () {
    const image = _.get(getState(), 'app.settings.logo.data', [])
    const command = getImageCommand(image, 'GS v 0')
    this.addAlign('center')
    this.writeNumbers(command)
    return this
  }

  async addQRCode (text, position = 'center', size = 8) {
    const command = this.encoder
      .align(position)
      .qrcode(text, 2, size, 'h')
      .encode()
    this.writeNumbers(command)
    return this
  }

  addBarcode (style) {
    const length = style.text.length
    this.writeNumbers([29, 104, 80]) // Set barcode height
    this.writeNumbers([29, 119, 2]) // Set barcode width
    this.writeNumbers([29, 72, 2]) // Select print position of HRI characters
    // Select the barcode system and print the barcode
    this.writeNumbers([29, 107, 69, length])
    this.writeString(style.text, this.encoding)
    return this
  }

  addBoldMode (on = true) {
    const command = this.encoder.bold(on).encode()
    this.writeNumbers(command)
    return this
  }

  addItalicMode (on = true) {
    const command = this.encoder.italic(on).encode()
    this.writeNumbers(command)
    return this
  }

  addUnderlineMode (on = true) {
    const command = this.encoder.underline(on).encode()
    this.writeNumbers(command)
    return this
  }

  /**
   *
   * @param {'left' | 'center' | 'right'} align
   * @returns
   */
  addAlign (align) {
    const command = this.encoder.align(align).encode()
    this.writeNumbers(command)
    return this
  }

  addRowFeed (rows = 1) {
    this.writeNumbers(Array(rows).fill([10]))
    return this
  }

  addFixedRow (texts, widths, styleText, rowFeed = 1, align = [], fonts = []) {
    const row = _.map(texts, (text, index) => PrintUtiliy.getItemNameByMaxWidth(text ?? '', widths[index], this.chineseTextWidth))
    const maxLength = _.max(_.map(row, r => r?.length ?? 0))
    for (let i = 0; i < maxLength; i++) {
      const itemRowText = _.map(row, (r, index) => this._alignText(r[i] ?? '', align[index], widths[index]))
      this._printRow(itemRowText, widths, styleText, rowFeed, fonts)
    }
  }

  _alignText (text, align, length) {
    if (!text) {
      return text
    }
    switch (align) {
      case ImageMode.Align.RIGTH:
        return PrintUtiliy.leftPad(text, length)
      default:
        return text
    }
  }

  _printRow (texts, widths, styleText, rowFeed = 1, fonts) {
    if (!texts) {
      texts = []
    }
    const PRINT_MAX_WIDTH = this.MAX_WIDTH
    if (_.sum(widths) > PRINT_MAX_WIDTH) {
      console.error('widths too long:', widths)
    }
    _.each(texts, (text, i) => {
      const width = widths[i]
      let buffer = ''
      _.each(text, (char) => {
        const length = PrintUtiliy.strLen(buffer) + PrintUtiliy.strLen(char)
        if (length > width) {
        } else {
          buffer += char
        }
      })
      if (PrintUtiliy.strLen(buffer) < width) {
        buffer += [...new Array(width - PrintUtiliy.strLen(buffer))]
          .map((a) => ' ')
          .join('')
      }
      this.addText(buffer, fonts[i] ?? styleText)
    })
    this.addRowFeed(rowFeed)
  }

  /**
   * 用於自訂收據單（CustomizedOrderReceipt）的分隔線選項
   * @returns
   */
  addStraightLine () {
    this.addAlign('left')
    const command = getImageCommand(straightLine(this.DOT_WIDTH / 2), 'GS v 0')
    this.writeNumbers(command)
    return this
  }

  addCutPaper () {
    const command = this.encoder.cut('partial').encode()
    this.writeNumbers(command)
    return this
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @param {'thai'?} batchLocale
   * @returns
   */
  getItemMaxWidth (fontSize, batchLocale) {
    const thaiWidths = [70, 30, 16, 30, 16, 16]
    const widths = [28, 11, 6, 11, 6, 4]
    if (batchLocale === 'thai') {
      return thaiWidths[fontSize] ?? thaiWidths[0]
    }
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @returns
   */
  getPriceMaxWidth (fontSize) {
    const widths = [7, 5, 4, 5, 4, 3]
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @returns
   */
  getQuantityMaxWidth (fontSize) {
    const widths = [5, 4, 3, 4, 3, 3]
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @returns
   */
  getReceiptTextWidth (fontSize) {
    const DEFAULT_SIZE = PrinterFontSize.SMALL
    const itemWidths = {
      [PrinterFontSize.SMALL]: [28, 6, 8],
      [PrinterFontSize.MEDIUM]: [12, 4, 5],
      [PrinterFontSize.LARGE]: [5, 4, 5],
      [PrinterFontSize.MEDIUWIDTH]: [12, 4, 5],
      [PrinterFontSize.LARGEWIDTH]: [5, 4, 5],
    }
    // const subtotalWidths = {
    //   [PrinterFontSize.SMALL]: [26, 12, 10],
    //   [PrinterFontSize.MEDIUM]: [4, 12, 8],
    //   [PrinterFontSize.LARGE]: [1, 9, 6],
    //   [PrinterFontSize.MEDIUWIDTH]: [4, 12, 8],
    //   [PrinterFontSize.LARGEWIDTH]: [1, 9, 6],
    // }

    let key = DEFAULT_SIZE
    if (typeof fontSize === 'number') {
      key = StyleFontSizeToPrinterFontSizeMap[fontSize]
    } else if (typeof fontSize === 'string') {
      key = fontSize
    }

    return {
      itemWidths: itemWidths[key] ?? itemWidths[DEFAULT_SIZE],
      subtotalWidths: itemWidths[key] ?? itemWidths[DEFAULT_SIZE],
    }
  }

  /**
   *
   * @param {0 | 1 | 2 | 'small' | 'medium' | 'large'} fontSize
   * @returns
   */
  getReportTextWidth (fontSize) {
    const DEFAULT_SIZE = PrinterFontSize.SMALL
    const itemWidths = {
      [PrinterFontSize.SMALL]: [18, 8, 16],
      [PrinterFontSize.MEDIUM]: [9, 4, 8],
      [PrinterFontSize.LARGE]: [6, 2, 6],
    }
    const cancelWidths = {
      [PrinterFontSize.SMALL]: [18, 4, 11, 9],
      [PrinterFontSize.MEDIUM]: [9, 2, 6, 4],
      [PrinterFontSize.LARGE]: [6, 2, 3, 3],
    }
    const contentPrintWidths = {
      [PrinterFontSize.SMALL]: [14, 14, 14],
      [PrinterFontSize.MEDIUM]: [7, 7, 7],
      [PrinterFontSize.LARGE]: [5, 4, 5],
    }
    const twoItemsWidths = {
      [PrinterFontSize.SMALL]: [22, 4, 22],
      [PrinterFontSize.MEDIUM]: [11, 2, 1],
      [PrinterFontSize.LARGE]: [6, 2, 6],
    }

    let key = DEFAULT_SIZE
    if (typeof fontSize === 'number') {
      key = StyleFontSizeToPrinterFontSizeMap[fontSize]
    } else if (typeof fontSize === 'string') {
      key = fontSize
    }

    return {
      itemWidths: itemWidths[key] ?? itemWidths[DEFAULT_SIZE],
      cancelWidths: cancelWidths[key] ?? cancelWidths[DEFAULT_SIZE],
      contentPrintWidths: contentPrintWidths[key] ?? contentPrintWidths[DEFAULT_SIZE],
      twoItemsWidths: twoItemsWidths[key] ?? twoItemsWidths[DEFAULT_SIZE],
    }
  }

  /**
   *
   * @deprecated 現在不會使用 setLogo，轉為直接用 addLogo
   * @returns
   */
  sendLogo () {
    const image = _.get(getState(), 'app.settings.logo.data', [])
    const command = getImageCommand(image, 'GS *')
    this.writeNumbers(command)
    return this
  }

  /**
   *
   * @deprecated sendLogo 是和 addSavedLogo 一起使用
   * @returns
   */
  addSavedLogo () {
    this.writeNumbers([29, 47, 0])
    return this
  }

  /**
   * 黑白反轉列印功能
   * @deprecated 沒有使用
   * @param {boolean?} on
   * @returns
   */
  addBWInvertMode (on = true) {
    const num = on ? 1 : 0
    this.writeNumbers([29, 66, num])
    return this
  }

  /**
   * @deprecated 沒有使用
   * @returns
   */
  statusTransmission () {
    this.writeNumbers([16, 4, 2])
    return this
  }
}
