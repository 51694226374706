import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useHistory, useLocation } from '@/libs/reactRouter'
import React from 'react'
import colors from '@/theme/colors'
import i18n from '@/i18n'

export default function TabButton ({ item }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const isActive = location.pathname.startsWith(item.path)
  const isSavedSetting = useSelector(state => state.app.isSavedSetting)

  const handleSwitchNav = (item) => {
    if (item.key === 'authorization') {
      dispatch(actions.auth.permissionCheck('user', () => {
        history.push(item.path)
      }))
    } else {
      history.push(item.path)
    }
  }
  return (
    <TouchableOpacity
      onPress={() => {
        dispatch(actions.setting.resetAllSubView())
        if (item.path) {
          if (!isSavedSetting) {
            dispatch(actions.app.showAlert({
              title: i18n.t('app.page.order.stash.alert.leave.title'),
              message: i18n.t('app.page.order.stash.alert.leave.msg2'),
              buttons: [
                {
                  children: i18n.t('app.page.order.stash.alert.leave.button.continue'),
                  onPress: () => {
                    dispatch(actions.app.updateIsSavedSetting(true))
                    handleSwitchNav(item)
                  },
                },
                {
                  backgroundColor: colors.light,
                  textColor: colors.textTertiary,
                  children: i18n.t('app.page.order.stash.alert.leave.button.cancel'),
                  onPress: () => { },
                },
              ],
            }))
          } else {
            handleSwitchNav(item)
          }
        }
      }}
      style={[styles.container, isActive && styles.activeContainer]}
    >
      <Text style={[styles.text, isActive && styles.activeText]}>
        {item.text}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 35,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    marginRight: 8,
  },
  activeContainer: {
    backgroundColor: colors.primary,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
    opacity: 0.4,
  },
  activeText: {
    color: colors.white,
    opacity: 1,
  },
})
