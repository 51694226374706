import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import Row from '@/components/Row'
import RowListEnter from '@/components/InfoDialog/RowListEnter'
import RowTextInput from '@/components/InfoDialog/RowTextInput'
import RowToggleInput from '@/components/InfoDialog/RowToggleInput'

const DEFAULT_STATE = {
  name: '',
  min: '',
  max: '',
  optional: false,
  dependsOnSetMenuItems: [],
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function StepDialog (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { step, setSelectedStep, menus, steps, locale } = props
  const visible = useSelector(state => state.app.dialog.setting.step)
  const [hasChanged, setHasChanged] = React.useState(false)
  const [stepState, setStepState] = React.useState(DEFAULT_STATE)
  const [error, setError] = React.useState(null)
  const [isVisible, setVisible] = React.useState(false)
  const [openMenuSelectDialog, setOpenMenuSelectDialog] = React.useState(false)
  const [previousStepsMenus, setPreviousStepsMenus] = React.useState([])
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)

  React.useEffect(() => {
    setVisible(visible)
    if (step) {
      let previousSteps = []
      setStepState(step)
      if (!step.id) {
        setHasChanged(true)
        previousSteps = steps.map(s => s.id)
      } else {
        steps.some(s => {
          if (s.id === step.id) {
            return true
          }
          previousSteps.push(s.id)
        })
      }
      const stepMenus = []
      if (previousSteps.length > 0) {
        menus.forEach(m => {
          if (previousSteps.includes(m.step)) {
            stepMenus.push({
              value: m.id,
              display: _.get(allMenus[m.menuId]?.localeNames, lang) ?? m.name,
            })
          }
        })
        setPreviousStepsMenus(stepMenus)
      }
    }
  }, [step, visible])

  if (!stepState) { return null }

  const onChangeState = (key, val) => {
    setHasChanged(true)
    setStepState(prevState =>
      _.set(_.assign({}, prevState), key, val),
    )
  }

  const resetState = () => {
    setStepState(DEFAULT_STATE)
    setError(null)
    setHasChanged(false)
  }

  const onClose = () => {
    resetState()
    dispatch(actions.app.closeDialog(['setting', 'step']))
  }

  const onSubmit = () => {
    // guards
    const submitStep = stepState
    if (stepState.name.trim().length === 0) {
      setError(t('app.page.setting.menuPanel.set.error.detail'))
      return
    }

    if (isNaN(stepState.min) || isNaN(stepState.max)) {
      setError(t('app.page.setting.menuPanel.set.error.quantity'))
      return
    }

    if (String(stepState.min).trim().length === 0) {
      submitStep.min = 0
    }
    if (String(stepState.max).trim().length === 0) {
      submitStep.max = 0
    }

    if (stepState.min > stepState.max) {
      setError(t('app.page.setting.menuPanel.set.error.min'))
      return
    }

    // dispatch(actions.table.updateBooking(stepState))
    onClose()
    if (step?.key) {
      dispatch(actions.menu.updateSetStep(submitStep, menus, locale))
    } else {
      dispatch(actions.menu.createSetStep(submitStep))
    }
  }

  const onDelete = () => {
    setVisible(false)
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.menuPanel.set.alert.deleteStep.title'),
      message: `${t('app.page.setting.menuPanel.set.alert.deleteStep.msg')} ${step.name}？`,
      buttons: [
        {
          title: t('app.common.confirm'),
          type: 'TouchableOpacity',
          textStyle: { fontWeight: 'bold' },
          onPress: () => {
            onClose()
            setSelectedStep(null)
            dispatch(actions.menu.deleteSetStep(step))
          },
        },
        {
          title: t('app.common.cancel'),
          type: 'TouchableOpacity',
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          textStyle: { fontWeight: 'bold' },
          onPress: () => {
            setVisible(true)
          },
        },
      ],
    }))
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title={step?.key ? t('app.page.setting.menuPanel.set.editStep') : t('app.page.setting.menuPanel.set.addStep')}
      visible={isVisible}
      onClose={onClose}
    >
      <View style={styles.container}>
        <RowTextInput
          label={t('app.page.setting.menuPanel.set.stepName')}
          placeholder={t('app.page.setting.menuPanel.set.stepName')}
          required
          value={stepState.name}
          onChangeText={(value) => onChangeState('name', value)}
        />
        {
          !stepState?.optional && (
            <RowTextInput
              label={t('app.page.setting.menuPanel.set.min')}
              placeholder='0'
              required
              keyboardType='number-pad'
              value={stepState.min.toString()}
              onChangeText={(text) => {
                if (/^\d+$/.test(text)) {
                  onChangeState('min', Number(text))
                } else if (text === '') {
                  onChangeState('min', '')
                }
              }}
            />
          )
        }
        <RowTextInput
          label={t('app.page.setting.menuPanel.set.max')}
          placeholder='0'
          required
          keyboardType='number-pad'
          value={stepState.max.toString()}
          onChangeText={(text) => {
            if (/^\d+$/.test(text)) {
              onChangeState('max', Number(text))
            } else if (text === '') {
              onChangeState('max', '')
            }
          }}
        />
        <RowToggleInput
          label={t('app.page.setting.menuPanel.menuInfo.required')}
          value={!stepState.optional}
          onChange={(value) => {
            onChangeState('optional', !value)
            if (!value) {
              onChangeState('min', 0)
            }
          }}
        />
        <RowListEnter
          label={t('app.page.setting.menuPanel.set.dependsOnSetMenuItems')}
          onPress={() => setOpenMenuSelectDialog(true)}
        />

        <Text style={styles.errorText}>{error || ' '}</Text>

        {/* actions */}
        <Row style={styles.actions}>
          <Button
            title={t('app.common.cancel')}
            backgroundColor={colors.light}
            textColor={colors.textTertiary}
            textBold
            type='TouchableOpacity'
            onPress={onClose}
          />
          <Button
            title={hasChanged ? t('app.common.confirm') : t('app.common.delete')}
            textBold
            type='TouchableOpacity'
            onPress={hasChanged ? onSubmit : onDelete}
          />
        </Row>
      </View>
      <OptionsSelectDialog
        open={openMenuSelectDialog}
        multiple
        onClose={() => setOpenMenuSelectDialog(false)}
        onSubmit={(menuIds) => onChangeState('dependsOnSetMenuItems', menuIds)}
        options={previousStepsMenus}
        defaultValue={step?.dependsOnSetMenuItems}
        title={t('app.page.setting.menuPanel.set.dependsOnSetMenuItems')}
      />
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    maxWidth: 250,
    marginHorizontal: 20,
    height: 320,
  },
  actions: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    flex: 1,
    fontWeight: '500',
    color: colors.secondary,
    marginTop: 8,
  },
})
