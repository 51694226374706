import { EventEmitter } from 'eventemitter3'
import { sha256 } from 'js-sha256'
import TcpSocket from 'react-native-tcp-socket'
import moment from 'moment'

import { ecrGateway } from '@/constants'
import delay from '@/libs/delay'
import logger from '@/libs/logger'
import store from '@/redux/store'

const getState = store.getState

export default class GlobalPayment {
  emitter = new EventEmitter()
  constructor () {
    this.cardTerminalIpAddress = getState().app.settings.cardTerminalIpAddress?.globalPayment
    this.paymeData = getState().merchant.data.payments.find(o => o.key === 'payme')
  }

  checkLineStatus = (emitType) => {
    const client = TcpSocket.createConnection({
      host: this.cardTerminalIpAddress.split(':')[0],
      port: this.cardTerminalIpAddress.split(':')[1],
      timeout: 30000,
    }, async () => {
      console.log('cardTerminalIpAddress', this.cardTerminalIpAddress)
      const json = { dataType: 'active' }
      client.write(JSON.stringify(json) + '\n')
    })
    client.on('data', async (data) => {
      console.log('message was received', data.toString())

      const response = JSON.parse(data.toString())
      if (response.dataType === 'ack') {
        await delay(500)
        this.emitter.emit(emitType, true)
        client.destroy()
      } else if (response.dataType === 'nak') {
        this.emitter.emit(emitType, false)
      }
    })
    client.on('error', async () => {
      this.emitter.emit(emitType, false)
      client.destroy()
    })

    client.on('close', (e) => {
      this.client = null
      console.log('Connection closed!', e)
    })
    client.on('timeout', (e) => {
      client.destroy()
      this.emitter.emit(emitType, false)
    })
  }

  replyMessage = () => {
    const json = { dataType: 'ACK' }
    return JSON.stringify(json)
  }

  sales = async (amt, ecrRef = '', txnConsequentialNo, qr, emitterName) => {
    const data = {
      amt,
      ecrRef,
      txnConsequentialNo,
      detail: 'Y',
      merchantName: 'DIM ORDER',
      print: 'Y',
      qr: qr ? 'Y' : undefined,
      tipAmt: '0000',
    }
    const payload = {
      dataType: 'sale',
      data,
      checksum: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload, emitterName)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  octopusSales = async (amt, ecrRef = '', emitterName) => {
    const data = {
      amt, ecrRef, detail: 'Y',
    }
    const payload = {
      dataType: 'OCTOPUS_Sale',
      data,
      checksum: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload, emitterName)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  paymeSales = async (transAmt, msgId, refNum = '', emitterName) => {
    const data = {
      msgId,
      refNum,
      paymentBrand: 'PME',
      transCurrency: 'HKD',
      transAmt,
      busicd: 'PAUT',
      transMode: 'Integrated',
      storeId: this.paymeData?.merchantId,
      termId: this.paymeData?.terminalId,
      merTransTime: moment().format('YYYYMMDDhhmmssZ').replace(':', ''),
    }
    const payload = {
      dataType: 'PME_Sale',
      data,
      checksum: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload, emitterName)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  adjustment = async (adjAmt, traceNo, emitterName) => {
    const data = {
      adjAmt, traceNo,
    }
    const payload = {
      dataType: 'adjust',
      data,
      checksum: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload, emitterName)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  void = async (traceNo, emitterName) => {
    const data = {
      traceNo, detail: 'Y', merchantName: 'DIM ORDER',
    }
    const payload = {
      dataType: 'void',
      data,
      checksum: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload, emitterName)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  paymeRefund = async (orderNum, msgId, amt, emitterName) => {
    const data = {
      msgId: msgId,
      oriOrderNum: orderNum,
      storeId: this.paymeData?.merchantId,
      termId: this.paymeData?.terminalId,
      refundAmt: amt,
      busicd: 'REFD',
      transMode: 'Integrated',
      merTransTime: moment().format('YYYYMMDDhhmmssZ').replace(':', ''),
      paymentBrand: 'PME',
    }
    const payload = {
      dataType: 'PME_Refund',
      data,
      checksum: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload, emitterName)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  _sha256Checksum = data => {
    return sha256(JSON.stringify(data))
  }

  _startTransaction = (payload, emitter) => {
    let retryTime = 0
    let client

    console.log('[Global Payment] Request: ', JSON.stringify(payload))

    return new Promise((resolve, reject) => {
      client = TcpSocket.createConnection({
        host: this.cardTerminalIpAddress.split(':')[0],
        port: this.cardTerminalIpAddress.split(':')[1],
      }, async () => {
        client.write(JSON.stringify(payload) + '\n')
        //
      },
      )

      client.on('data', (data) => {
        console.log('[Global Payment] Response: ', data.toString())
        logger.log(`[Global Payment] ${emitter} connected to ECR`)
        const response = JSON.parse(data.toString())
        if (response.dataType === payload.dataType) {
          if (response.data.errorDetail && response.data.errorDetail !== 'SUCCESS') {
            const error = new Error(response.data.errorDetail)
            client.destroy()
            reject(error)
          }
          if (response.data.errorDetail === 'SUCCESS' && response.data.paymentBrand === 'PME') {
            const formattedResponse = this.toPaymeJsonResponse(response)
            logger.log(`[Global Payment] ${emitter} success`, { response: response.data })
            client.write(this.replyMessage() + '\n')
            client.destroy()
            resolve(formattedResponse)
          } else if (response.data.status === 'A') {
            const formattedResponse = response.data.transType === 'OCTOPUS_Sale'
              ? this.toOctopusJsonResponse(response)
              : this.toJsonResponse(response)
            logger.log(`[Global Payment] ${emitter} success`, { response: response.data })
            client.write(this.replyMessage() + '\n')
            client.destroy()
            resolve(formattedResponse)
          } else if (response.data.status === 'R') {
            const error = new Error(response.data?.rspText)
            client.destroy()
            reject(error)
          } else if (response.data.status === 'P') {
            retryTime++
            if (retryTime >= 2) {
              const error = new Error('cardTerminalProcessing')
              client.destroy()
              reject(error)
            }
          }
        } else if (response.dataType === 'nak') {
          const error = new Error('failToConnectionCardCenter')
          client.destroy()
          reject(error)
        }
      })
      client.on('error', () => {
        const error = new Error('fail')
        client.destroy()
        reject(error)
      })

      client.on('close', (e) => {
        console.log('Connection closed!', e)
        client = null
        this.emitter.removeAllListeners(emitter)
      })
    })
  }

  toJsonResponse = (response) => {
    return {
      card: response.data.cardType.toLowerCase(),
      gateway: ecrGateway.GLOBAL_PAYMENT,
      ecrRef: response.data.referenceNo,
      payload: response.data,
    }
  }

  toOctopusJsonResponse = (response) => {
    return {
      card: 'octopus',
      gateway: ecrGateway.GLOBAL_PAYMENT,
      ecrRef: response.data.referenceNo,
      payload: response.data,
    }
  }

  toPaymeJsonResponse = (response) => {
    return {
      card: 'payme',
      gateway: ecrGateway.GLOBAL_PAYMENT,
      ecrRef: response.data.refNum,
      payload: response.data,
    }
  }
}
