import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { usePaymentMethod } from '@/hooks/merchant'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import Row from '@/components/Row'

// eslint-disable-next-line no-unused-vars
import { IAppPayment } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef PaymentInfoProps
 * @property {IAppPayment} payment
 * @property {boolean?} bottomLine
 */

/**
 *
 * @param {PaymentInfoProps} props
 * @returns
 */
export default function PaymentInfo (props) {
  const { t } = useTranslation()
  const { payment, bottomLine = false } = props
  const paymentMethod = usePaymentMethod(payment.paymentMethod)
  const dispatch = useDispatch()

  const tips = Number(payment.paymentTips)
  // const change = Number(payment.change)
  const amount = Number(payment.amount)
  const paid = amount
  // const rounding = useSelector(state => state.merchant.data.rounding)

  return (
    <Column style={styles.container}>
      {/* <Row>
        <Text style={styles.text}>
          付款編號：
        </Text>
        <Text style={[styles.text, styles.value]}>
          {payment.id.split('-').pop()}
        </Text>
      </Row> */}
      <Row>
        <Text style={styles.text}>
          {t('app.page.checkout.paymentResult.createAt')}：
        </Text>
        <Text style={[styles.text, styles.value]}>
          {moment(payment.createdAt).format('YYYY-MM-DD HH:mm')}
        </Text>
      </Row>
      <Row>
        <Text style={styles.text}>
          {t('app.page.checkout.payment.method')}：
        </Text>
        <Text style={[styles.text, styles.value]}>{t(paymentMethod.name)}</Text>
      </Row>

      <Row>
        <Text style={styles.text}>
          {t('app.page.checkout.payment.paidTotal')}：
        </Text>
        <Text style={[styles.text, styles.value]}>
          {`${currencyWithCommas(Number(paid))}`}
        </Text>
      </Row>
      <Row>
        <Text style={styles.text}>
          {t('app.page.checkout.paymentResult.tips-withBlank')}：
        </Text>
        <Text style={[styles.text, styles.value]}>
          {`${currencyWithCommas(Number(tips))}`}
        </Text>
      </Row>

      {payment.change > 0 && (
        <Row>
          <Text style={styles.text}>
            {t('app.page.checkout.paymentResult.change-withBlank')}：
          </Text>
          <Row style={{ flexWrap: 'wrap', flex: 1 }}>
            <View style={{ flexShrink: 0 }}>
              <Text style={[styles.text, styles.value, { fontSize: 22 }]}>
                {`${currencyWithCommas(Number(payment.change).toFixed(1))}`}
              </Text>
            </View>
            <Button
              title={t('app.page.checkout.paymentResult.tips-button')}
              style={styles.tipButton}
              textStyle={styles.tipButtonText}
              backgroundColor={colors.lightPrimary}
              onPress={() => {
                dispatch(actions.orderHistory.allChangeToTips(payment.id))
              }}
            />
          </Row>
        </Row>
      )}

      {bottomLine && <View style={styles.divider} />}
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 260,
  },
  text: {
    lineHeight: 24,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.textSecondary,
    letterSpacing: 2.3,
  },
  value: {
    letterSpacing: 0,
    flex: 1,
  },
  tipButton: {
    height: 24,
    width: 100,
    borderRadius: 15,
    margin: 0,
  },
  tipButtonText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.white,
  },
  divider: {
    width: 294,
    alignSelf: 'center',
    borderBottomColor: colors.textSecondary,
    borderBottomWidth: 2,
    marginVertical: 12,
  },
})
