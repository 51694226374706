import { LinearProgress } from 'react-native-elements'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { statusDisplayTextI18nKeys } from '@/configs/codePush'
import codePush from '@/libs/codePush'
import colors from '@/theme/colors'
import formatBytes from '@/libs/formatBytes'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CodePushUpdateDialog (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isDialogOpen = useSelector(state => state.codePush.isDialogOpen)
  const status = useSelector(state => state.codePush.status)
  const progress = useSelector(state => state.codePush.progress)
  const error = useSelector(state => state.codePush.error)
  const statusDisplayTextI18nKey = statusDisplayTextI18nKeys[status]
  const disableClose = [
    codePush?.SyncStatus?.SYNC_IN_PROGRESS,
    codePush?.SyncStatus?.CHECKING_FOR_UPDATE,
    codePush?.SyncStatus?.AWAITING_USER_ACTION,
    codePush?.SyncStatus?.DOWNLOADING_PACKAGE,
    codePush?.SyncStatus?.INSTALLING_UPDATE,
  ].includes(status)

  if (Platform.OS === 'web') {
    // web 不做 codePush 更新
    return null
  }

  const showProgress = progress?.receivedBytes > 0
  const progressPercent = showProgress && (100 * progress.receivedBytes / (progress.totalBytes || 1)).toFixed(0)
  const receivedSizes = formatBytes(progress?.receivedBytes || 0)
  const totalSizes = formatBytes(progress?.totalBytes || 0)
  const displayProgress = `${progressPercent}% (${receivedSizes} / ${totalSizes})`

  return (
    <CenterModal
      visible={isDialogOpen}
      title={t('app.page.setting.localPanel.settingCard.checkUpdate')}
      noCloseButton
      enablePressOutsideClose={false}
      contentContainerStyle={styles.container}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.text}>{t(statusDisplayTextI18nKey)}</Text>
          {error && <Text style={styles.error}>{error}</Text>}
          {disableClose && (
            <LinearProgress
              style={styles.progress}
              color={colors.lightPrimary}
              variant='indeterminate'
            />
          )}
          {showProgress && <Text style={styles.text}>{displayProgress}</Text>}
        </View>
        <Button
          title={t('app.page.setting.localPanel.settingCard.continue')}
          onPress={() => dispatch(actions.codePush.updateDialogOpen(false))}
          disabled={disableClose}
        />
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 450,
    height: 140,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 8,
  },
  content: {
    flex: 1,
    minHeight: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 8,
    alignSelf: 'stretch',
  },
  text: {
    margin: 4,
  },
  progress: {
    width: '90%',
    margin: 4,
  },
  error: {
    color: colors.error,
    margin: 4,
  },
})
