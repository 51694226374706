import { FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { URLSearchParams } from 'react-native-url-polyfill'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { loadingKey } from '@/constants'
import { useHistory, useLocation } from '@/libs/reactRouter'
import colors from '@/theme/colors'
import delay from '@/libs/delay'
import shadows from '@/theme/shadows'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import Row from '@/components/Row'
import TableInputDialog from '@/components/dialogs/TableInputDialog'

import GroupNavBar from './GroupNavBar'

const DEFAULT_TABLE = { name: '', maxCustomerCount: '' }

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function TableSetting (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const query = useQuery()
  const groupId = query.get('groupId')
  const history = useHistory()
  const groups = useSelector(state => state.merchant.data.tableGroups)
  const tables = useSelector(state => state.merchant.data.tables)
  const tableGroups = { ...groups }
  const groupTables = _.flatMap(tableGroups)
  const otherTables = _.difference(tables, groupTables)
  if (otherTables?.length) {
    tableGroups.otherTablesWithoutGroup = otherTables
  }
  const otherText = 'otherTablesWithoutGroup'
  const keys = _.keys(tableGroups)
  const index = keys.findIndex(group => group === groupId)
  const [localTable, setLocalTable] = useState(DEFAULT_TABLE)
  const [selectedTable, setSelectedTable] = useState(null)
  const [openNumSeqDialog, setNumSeqDialog] = React.useState(false)
  const [openNumCharSeqDialog, setNumCharSeqDialog] = React.useState(false)
  const tableAreaDisplayTexts = {
    'GROUND FLOOR': '一樓',
    'FIRST FLOOR': '二樓',
    BAR: '吧台',
    otherTablesWithoutGroup: t('app.page.setting.table.other'),
  }

  useEffect(() => {
    // 若params:groupId不存在，推到第一個group
    if (index < 0) {
      history.replace(`/setting/table?groupId=${encodeURIComponent(keys[0])}`)
    }
  }, [])

  const routes = keys.map(key => {
    return {
      key,
      path: `/setting/table?groupId=${encodeURIComponent(key)}`,
      text: tableAreaDisplayTexts[key] || key,
    }
  })

  const addTableInNumSeq = async (table) => {
    if (tableGroups[groupId] === undefined) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.table.groupError')))
      return
    }
    const tables = []
    if (/^\d+$/.test(table.from) && /^\d+$/.test(table.to)) {
      await dispatch(actions.app.openLoading(loadingKey.TABLE, 'addTableInNumSeq'))
      for (let i = Number(table.from); i <= Number(table.to); i++) {
        const tableName = String(i)
        const isExisted = tableGroups[groupId].findIndex(t => t === tableName || t.name === tableName) >= 0
        if (!isExisted) {
          tables.push(i.toString())
        }
      }
      await dispatch(actions.merchant.updateTables(tables, groupId))
      dispatch(actions.app.closeLoading(loadingKey.TABLE))
    } else {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.table.numError')))
      return
    }
    reset()
  }

  const addTableInNumCharSeq = async (table) => {
    const from = table.from.match(/[^\d]+|\d+/g)
    const to = table.to.match(/[^\d]+|\d+/g)
    if (tableGroups[decodeURIComponent(groupId)] === undefined) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.table.groupError')))
      return
    }
    const tables = []
    if ((/^\d+[a-zA-Z]{1}$/.test(table.from) && /^\d+[a-zA-Z]{1}$/.test(table.to)) || (/^[a-zA-Z]{1}\d+$/.test(table.from) && /^[a-zA-Z]{1}\d+$/.test(table.to))) {
      await dispatch(actions.app.openLoading(loadingKey.TABLE, 'addTableInNumCharSeq-English'))
      if (/^\d+$/.test(from[0])) {
        const fromChar = from[1].toUpperCase().charCodeAt(0)
        const toChar = to[1].toUpperCase().charCodeAt(0)
        for (let j = Number(from[0]); j <= Number(to[0]); j++) {
          for (let i = fromChar; i <= toChar; i++) {
            const tableName = j + String.fromCharCode(i)
            const isExisted = tableGroups[groupId].findIndex(t => t === tableName || t.name === tableName) >= 0
            if (!isExisted) {
              tables.push(tableName)
            }
          }
        }
      } else {
        const fromChar = from[0].toUpperCase().charCodeAt(0)
        const toChar = to[0].toUpperCase().charCodeAt(0)
        for (let j = fromChar; j <= toChar; j++) {
          for (let i = Number(from[1]); i <= Number(to[1]); i++) {
            const tableName = String.fromCharCode(j) + i
            const isExisted = tableGroups[groupId].findIndex(t => t === tableName || t.name === tableName) >= 0
            if (!isExisted) {
              tables.push(tableName)
            }
          }
        }
      }
    } else if (from.length === 2 && to.length === 2 && /^\d+$/.test(from[1]) && /^\d+$/.test(to[1]) && from[0] === to[0]) {
      await dispatch(actions.app.openLoading(loadingKey.TABLE, 'addTableInNumCharSeq-Chinese'))
      for (let i = Number(from[1]); i <= Number(to[1]); i++) {
        const tableName = from[0] + String(i)
        const isExisted = tableGroups[groupId].findIndex(t => t === tableName || t.name === tableName) >= 0
        if (!isExisted) {
          tables.push(tableName)
        }
      }
    } else {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.table.formatError')))
      return
    }
    await dispatch(actions.merchant.updateTables(tables, groupId))

    reset()
    dispatch(actions.app.closeLoading(loadingKey.TABLE))
  }

  const onSubmit = () => {
    if (tableGroups[groupId] === undefined) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.table.groupError')))
      return
    }

    if (_.isEmpty(localTable.name?.trim())) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.table.tableError2')))
      return
    }

    const isExisted = tableGroups[groupId].findIndex(t => t === localTable.name || t.name === localTable.name) >= 0

    if (isExisted) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), `${t('app.page.setting.table.table') + localTable.name + t('app.page.setting.table.tableError1')}`))
      return
    }
    dispatch(actions.merchant.updateTables([localTable.name], groupId))
    reset()
  }

  const onDelete = (table) => {
    dispatch(actions.app.showAlert({
      title: `${t('app.common.delete') + t('app.page.setting.table.sit')}`,
      message: `${t('app.common.is') + t('app.common.delete')}${table.name || table}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            dispatch(actions.merchant.deleteTableGeometry([table]))
            dispatch(actions.merchant.deleteTables([table]))
            reset()
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const reset = () => {
    setLocalTable(DEFAULT_TABLE)
    setSelectedTable(null)
  }

  const renderItem = ({ item: table, index }) => {
    const isSelected = selectedTable === table
    return (
      <TouchableOpacity
        onPress={() => setSelectedTable(table)}
        style={[styles.table,
          index % 6 !== 5 && { marginRight: 20 },
          isSelected && styles.selectedTable]}
      >
        <Text style={[styles.tableText, { flex: 1 }]}>
          {table.name ?? table}
        </Text>
        {/* <Text style={[styles.tableText, { fontSize: 14 }]}>
          {`0/${table.maxCustomerCount ?? 2}`}
        </Text> */}
        {isSelected && (
          <TouchableOpacity
            style={styles.deleteIcon}
            onPress={() => onDelete(table)}
          >
            <Image
              style={styles.icon}
              source={require('@icons/setting/close.png')}
            />
          </TouchableOpacity>)}
      </TouchableOpacity>)
  }

  const onDeleteTableGroup = (id) => {
    dispatch(actions.app.showAlert({
      title: `${t('app.common.delete') + t('app.page.setting.table.tableGroup')}`,
      message: `${t('app.common.is') + t('app.common.delete') + id}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: async () => {
            await dispatch(actions.app.openLoading(loadingKey.TABLE))
            await dispatch(actions.merchant.deleteTableGeometry(tableGroups[id]))
            await dispatch(actions.merchant.deleteTableGroup(id))
            await delay(2000)
            await dispatch(actions.merchant.deleteTables(tableGroups[id]))
            reset()
            dispatch(actions.app.closeLoading(loadingKey.TABLE))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const onSort = (id) => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.table.sort'),
      message: t('app.page.setting.table.isSort'),
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: async () => {
            const tables = [...tableGroups[groupId]]
            tables.sort().sort((a, b) => {
              const num1 = a.match(/[^\d]+|\d+/g)
              const num2 = b.match(/[^\d]+|\d+/g)
              if (/^\d+$/.test(num1[0]) && /^\d+$/.test(num2[0])) {
                return num1[0] - num2[0]
              }
              return num1[0].localeCompare(num2[0])
            })
            await dispatch(actions.app.openLoading(loadingKey.TABLE))
            await dispatch(actions.merchant.deleteTables(tables))
            await delay(2000)
            if (groupId === otherText) {
              await dispatch(actions.merchant.updateTables(tables))
            } else {
              await dispatch(actions.merchant.updateTables(tables, groupId))
            }
            dispatch(actions.app.closeLoading(loadingKey.TABLE))
            reset()
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  return (
    <Column style={styles.container}>
      <GroupNavBar items={routes} resetLocalTable={reset} />
      <Row style={styles.actions}>
        <Column style={styles.inputs}>
          <Row>
            {/* <Text style={styles.text}>枱號：</Text>
            <View style={styles.textInputContainer}>
              <TextInput
                value={localTable.name}
                onChangeText={(text) => setLocalTable(prev => { return { ...prev, name: text } })}
                style={styles.text}
                textAlign='center'
              />
            </View> */}
          </Row>
          <Row>
            {/* <Text style={styles.text}>人數：</Text>
            <View style={styles.textInputContainer}>
              <TextInput
                value={localTable.maxCustomerCount}
                onChangeText={(text) => setLocalTable(prev => { return { ...prev, maxCustomerCount: text } })}
                style={styles.text}
                textAlign='center'
              />
            </View> */}
            <Text style={styles.text}>{t('app.page.setting.table.table')}：</Text>
            <View style={styles.textInputContainer}>
              <TextInput
                value={localTable.name}
                onChangeText={(text) => setLocalTable(prev => { return { ...prev, name: text } })}
                style={styles.text}
                textAlign='center'
              />
            </View>
          </Row>
        </Column>
        {
          groupId !== otherText && (
            <>
              <Button
                title={t('app.page.setting.table.add')}
                style={styles.addButton}
                backgroundColor={colors.primary}
                textBold
                onPress={onSubmit}
              />
              <Button
                title={t('app.page.setting.table.num')}
                style={styles.addButton}
                backgroundColor={colors.primary}
                textBold
                onPress={() => setNumSeqDialog(true)}
              />
              <Button
                title={t('app.page.setting.table.numChar')}
                style={styles.addButton}
                backgroundColor={colors.primary}
                textBold
                onPress={() => setNumCharSeqDialog(true)}
              />
            </>
          )
        }
        <Button
          title={t('app.page.setting.table.sort')}
          style={styles.addButton}
          backgroundColor={colors.secondary}
          textBold
          onPress={onSort}
        />
      </Row>
      <View style={styles.tables}>
        <FlatList
          data={tableGroups && tableGroups[groupId]}
          renderItem={renderItem}
          extraData={selectedTable}
          numColumns={6}
          contentContainerStyle={{ paddingTop: 15 }}
          columnWrapperStyle={{ marginBottom: 20 }}
          keyExtractor={(item, index) => index.toString()}
        />
        <View style={styles.buttons}>
          <Button
            textBold
            title={t('app.common.delete') + t('app.page.setting.table.tableGroup')}
            style={styles.button}
            backgroundColor={colors.darkSecondary}
            onPress={() => onDeleteTableGroup(groupId)}
          />
        </View>
      </View>
      <TableInputDialog
        keyboardType='number-pad'
        open={openNumSeqDialog}
        type='NumSeq'
        onClose={() => setNumSeqDialog(false)}
        title={t('app.page.setting.table.num')}
        onSubmit={(text) => {
          addTableInNumSeq(text)
        }}
      />
      <TableInputDialog
        keyboardType='number-pad'
        open={openNumCharSeqDialog}
        type='NumCharSeq'
        onClose={() => setNumCharSeqDialog(false)}
        title={t('app.page.setting.table.numChar')}
        onSubmit={(text) => {
          addTableInNumCharSeq(text)
        }}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  actions: {
    height: 107 + 30, // Cover the space under nav bar
    alignItems: 'flex-end',
    backgroundColor: colors.white,
    paddingLeft: 50,
    paddingTop: 28 + 30, // Cover the space under nav bar
    paddingBottom: 28,
    marginTop: -30, // Cover the space under nav bar
    borderBottomRightRadius: 30,
    zIndex: 0,
    ...shadows.default,
  },
  inputs: {
    height: '100%',
    width: '15%',
    justifyContent: 'space-between',
    marginRight: 24,
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primary,
  },
  textInputContainer: {
    flex: 1,
    marginLeft: 4,
    paddingHorizontal: 4,
    borderColor: colors.primary,
    borderBottomWidth: 2,
  },
  textInput: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primary,
  },
  tables: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 50,
  },
  table: {
    height: 100,
    width: 100,
    backgroundColor: colors.gray,
    borderWidth: 2,
    borderColor: colors.transparent,
    borderRadius: 5,
    padding: 10,
  },
  selectedTable: {
    backgroundColor: colors.lightGray3,
    borderColor: colors.tertiary,
  },
  tableText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: colors.white,
  },
  deleteIcon: {
    position: 'absolute',
    top: -10,
    right: -10,
  },
  icon: {
    height: 30,
    width: 30,
  },
  addButton: {
    height: 35,
    borderRadius: 18,
    marginVertical: 0,
    width: 120,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
