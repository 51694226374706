import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SettingListItemTextProps
 * @property {string} text
 * @property {ViewProps['style']?} style
 * @property {TextProps['style']?} textStyle
 * @property {boolean?} wrap
 * @property {number?} numberOfLines
 */

/**
 *
 * @param {SettingListItemTextProps} props
 * @returns
 */
export default function SettingListItemText (props) {
  const { style, text, textStyle, wrap = false, numberOfLines = 1 } = props
  return (
    <View style={[styles.container, wrap && { flexWrap: 'wrap' }, style]}>
      {wrap
        ? <Text style={[styles.text, textStyle]}>{text}</Text>
        : <Text numberOfLines={numberOfLines} style={[styles.text, textStyle]}>{text}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
  },
})
