import { StyleSheet, Text, TextInput, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'

/**
 * @typedef LoginFormProps
 * @property {string?} accountPlaceHolder
 * @property {string?} passwordPlaceHolder
 * @property {() => void} onSubmit
 */

/**
 *
 * @param {LoginFormProps} props
 * @returns
 */
export default function LoginForm (props) {
  const { t } = useTranslation()
  const {
    accountPlaceHolder = t('app.page.login.id'),
    passwordPlaceHolder = t('app.page.login.password'),
    onSubmit,
  } = props
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [account, setAccount] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmitForm = () => {
    if (account.trim().length === 0) {
      const error = `${t('app.page.login.input')}${accountPlaceHolder}`
      return dispatch(actions.auth.updateError(error))
    }

    const quickLoginCredentials = account.replace(/(\s)/i, ' ').split(' ')
    if (password.trim().length === 0 && quickLoginCredentials.length !== 2) {
      const error = `${t('app.page.login.input')}${passwordPlaceHolder}`
      return dispatch(actions.auth.updateError(error))
    }

    quickLoginCredentials.length === 2 ? onSubmit(quickLoginCredentials[0], quickLoginCredentials[1]) : onSubmit(account, password)
  }

  return (
    <View style={styles.container}>
      <TextInput
        autoCapitalize='none'
        autoCompleteType='off'
        autoCorrect={false}
        style={styles.input}
        placeholder={accountPlaceHolder}
        placeholderTextColor={colors.textPlaceholder}
        onChangeText={text => setAccount(text)}
      />
      <TextInput
        secureTextEntry
        autoCapitalize='none'
        autoCompleteType='off'
        autoCorrect={false}
        style={styles.input}
        placeholder={passwordPlaceHolder}
        placeholderTextColor={colors.textPlaceholder}
        onChangeText={text => setPassword(text)}
      />
      <Button
        style={styles.button}
        title={t('app.page.login.submit')}
        onPress={handleSubmitForm}
      />
      {/* <Text>密碼 123456</Text> */}
      {auth.error && <Text style={styles.errorText}>{auth.error}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 30,
  },
  input: {
    height: 35,
    width: 245,
    borderWidth: 2,
    borderColor: colors.primary,
    borderRadius: 18,
    paddingHorizontal: 15,
    color: colors.primary,
    marginBottom: 20,
  },
  button: {
    marginBottom: 20,
  },
  errorText: {
    color: colors.secondary,
  },
})
