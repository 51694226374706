import { ecrGateway } from '@/constants'
import { sha256 } from 'js-sha256'
import axios from 'axios'
import store from '@/redux/store'
const getState = store.getState

export default class EftPay {
  constructor () {
    this.cardTerminalIpAddress = getState().app.settings.cardTerminalIpAddress?.eftPay
  }

  sales = async (AMT, ECRREF = '', type = 'EDC') => {
    const data = {
      AMT, ECRREF, CMD: 'SALE', TYPE: type,
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  adjustment = async (TRACE, TIPS, TOTALAMT) => {
    const data = {
      TRACE, TIPS, TOTALAMT, CMD: 'TIPS', TYPE: 'EDC',
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  void = async (INVOICE) => {
    const data = {
      INVOICE, CMD: 'VOID', TYPE: 'EDC',
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  refund = async (refPayload) => {
    const data = {
      CMD: 'REFUND',
      TYPE: 'EWALLET',
      CARD: 'PAYME',
      REFNUM: refPayload.REFNUM,
      ECRREF: '',
      AMT: refPayload.AMT,
      REASONCODE: '00',
      REASONMESSAGE: 'CANCELLED',
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  getPaymentStatus = async (ecrRef, type = 'EDC') => {
    const data = {
      CMD: 'RETRIEVAL',
      TYPE: type,
      ECRREF: ecrRef,
    }
    const payload = {
      data: data,
      md5: this._sha256Checksum(data),
    }
    try {
      const result = await this._startTransaction(payload)
      return { success: true, data: result }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  _sha256Checksum = data => {
    return sha256(JSON.stringify(data))
  }

  _startTransaction = (payload) => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { 'X-MD5': payload.md5 },
      }
      axios({
        method: 'post',
        url: `http://${this.cardTerminalIpAddress}`,
        data: payload.data,
        options: options,
      }).then((response) => {
        console.log('response', response)

        if (response.data.STATUS === 'Approved') {
          const formattedResponse = this.toJsonResponse(response)
          resolve(formattedResponse)
        } else if (response.data.STATUS === 'Cancelled') {
          const error = new Error('Transaction Cancelled')
          reject(error)
        } else {
          const error = new Error('sales rejected')
          reject(error)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  toJsonResponse = (response) => {
    const getCard = () => {
      switch (response.data.CARD) {
        case 'MASTERCARD':
          return 'master'
        case 'AMEX':
          return 'ae'
        case 'Alipay':
          return 'alipay'
        case 'WeChat CN':
          return 'wechat'
        case 'WeChat HK':
          return 'wechat'
        case 'UNION PAY QR':
          return 'unionpayQR'
        default:
          return response.data.CARD.toLowerCase()
      }
    }

    return {
      card: getCard(),
      gateway: ecrGateway.EFT_PAY,
      ecrRef: response.data.REFNUM,
      payload: response.data,
    }
  }
}
