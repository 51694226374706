import { FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity } from 'react-native'
import { v4 as uuid } from 'uuid'
import React, { useEffect, useRef, useState } from 'react'

import { getOrderTags } from '@/libs/tags'
import colors from '@/theme/colors'

import Row from '@/components/Row'
import TagItem from './TagItem'

/**
 * @typedef TagListProps
 * @property {string[]} selectedTags
 * @property {(tag: string) => void} onSelectTag
 */

/**
 *
 * @param {TagListProps} props
 * @returns
 */
export default function TagList (props) {
  const { selectedTags = [], onSelectTag } = props
  const [isEditing, setIsEditing] = useState(false)
  const [newTag, setNewTag] = useState(null)
  const inputRef = useRef()
  const tagOptions = getOrderTags()
  // 先顯示已選擇的標籤，再用tagOptions補滿3個
  const tags = [...selectedTags, ...(tagOptions.filter(option => selectedTags.findIndex(tag => tag.id === option.id) < 0))].slice(0, 3)

  const onFinishEditing = (e) => {
    setIsEditing(false)
    const newTagText = e.nativeEvent.text

    if (newTagText.match(/^ *$/) === null) {
      const newTag = { id: uuid(), name: newTagText }
      onSelectTag(newTag)
    }
    setNewTag(null)
  }

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus()
    }
  }, [isEditing])

  const renderItem = ({ item: tag }) => (
    <TagItem
      text={tag.name}
      selected={selectedTags.findIndex(s => s.id === tag.id) >= 0}
      onSelect={() => { onSelectTag(tag) }}
    />

  )
  return (
    <Row style={styles.container}>
      <FlatList
        data={tags}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        extraData={selectedTags}
        horizontal
        contentContainerStyle={{ flex: 1, justifyContent: 'space-between' }}
        // 最後一個顯示新增標籤
        ListFooterComponent={(
          <TouchableOpacity onPress={() => setIsEditing(true)}>
            {isEditing
              ? (
                <Row style={styles.newTag}>
                  <TextInput
                    ref={inputRef}
                    style={[styles.newTagText, styles.newTagInput]}
                    maxLength={5}
                    onBlur={onFinishEditing}
                  />
                </Row>
              )
              : newTag
                ? (
                  <TagItem
                    text={newTag.name}
                    selected={selectedTags.findIndex(s => s.id === newTag.id) >= 0}
                    onSelect={() => { onSelectTag(newTag) }}
                  />
                )
                : (
                  <Row style={styles.newTag}>
                    <Image
                      style={styles.newTagIcon}
                      source={require('@icons/table/plus.png')}
                    />
                    <Text style={styles.newTagText}>標籤</Text>
                  </Row>
                )}
          </TouchableOpacity>
        )}
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  newTag: {
    height: 35,
    width: 84,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
    borderColor: colors.primary,
    borderWidth: 2,
  },
  newTagInput: {
    width: '60%',
  },
  newTagIcon: {
    height: 15,
    width: 15,
  },
  newTagText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.primary,
  },
})
