// eslint-disable-next-line no-unused-vars
import { Image, StyleSheet, Text, TouchableOpacity, TouchableOpacityProps, TouchableWithoutFeedback, View } from 'react-native'
import React from 'react'

import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

const dragIcon = require('@icons/drag.png')

/**
 * @typedef SettingListItemProps
 * @property {JSX.Element} children
 * @property {string?} comment
 * @property {boolean?} divider
 * @property {boolean?} hidden
 * @property {boolean?} disabled
 * @property {boolean?} selected
 * @property {boolean?} dragging
 * @property {boolean?} draggable
 * @property {boolean?} dragDisabled
 * @property {() => void?} onPress
 * @property {() => void?} onLongPress
 * @property {() => void?} onDrag
 * @property {ViewProps['style']?} style
 * @property {ViewProps['style']?} itemStyle
 * @property {ViewProps['style']?} contentStyle
 * @property {ViewProps['style']?} backgroundColor
 * @property {TouchableOpacityProps?} touchableOpacityProps
 */

/**
 *
 * @param {SettingListItemProps} props
 * @returns
 */
export default function SettingListItem (props) {
  const {
    children,
    comment,
    divider,
    hidden,
    disabled,
    selected,
    dragging,
    draggable,
    dragDisabled,
    onPress,
    onLongPress,
    onDrag,
    style,
    itemStyle,
    contentStyle,
    backgroundColor = colors.white,
    touchableOpacityProps = {},
  } = props

  if (hidden) return null

  return (
    <View style={[
      styles.container,
      { backgroundColor: backgroundColor },
      selected && styles.selected,
      dragging && shadows.slight,
      style,
    ]}
    >
      <TouchableOpacity
        {...touchableOpacityProps}
        onPress={onPress}
        onLongPress={onLongPress}
        disabled={disabled || !onPress}
      >
        <View style={[
          styles.item,
          draggable && { marginLeft: 0 },
          itemStyle,
        ]}
        >
          <View style={[styles.contentContainer, contentStyle]}>
            {draggable && (dragDisabled
              ? (
                <View style={styles.dragPlaceholder} />
              )
              : (
                <TouchableWithoutFeedback onLongPress={onDrag}>
                  <View style={styles.dragIconContainer}>
                    <Image
                      source={dragIcon}
                      style={styles.dragIcon}
                    />
                  </View>
                </TouchableWithoutFeedback>
              ))}
            {children}
          </View>
          {comment && <Text>{comment}</Text>}
        </View>
      </TouchableOpacity>
      {divider && <View style={[styles.divider, draggable && { left: 0 }]} />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    minHeight: 40,
    alignItems: 'stretch',
    backgroundColor: colors.white,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: 24,
    marginLeft: 25,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 40,
  },
  selected: {
    backgroundColor: colors.tertiary,
  },
  dragPlaceholder: {
    width: 48,
    height: 16,
  },
  dragIconContainer: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  dragIcon: {
    width: 16,
    height: 16,
  },
  divider: {
    position: 'absolute',
    left: 25,
    right: 0,
    bottom: 0,
    height: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.lightGray3,
  },
})
