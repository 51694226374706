
import { Image, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'

/**
 * @typedef IconButtonProps
 * @property {string} iconSource
 * @property {() => void} onPressed
 * @property {boolean?} disabled
 * @property {ViewProps['style']?} style
 * @property {ImageProps['style']?} iconStyle
 */

/**
 *
 * @param {IconButtonProps} props
 * @returns
 */
export default function IconButton (props) {
  const { iconSource, onPress, disabled, style, iconStyle } = props
  return (
    <TouchableOpacity style={style} disabled={disabled} onPress={onPress}>
      <Image style={[styles.icon, iconStyle]} source={iconSource} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  icon: {
    width: 48,
    height: 48,
  },
})
