import { FlatList } from 'react-native-gesture-handler'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Button from '@root/src/components/buttons/Button'
import Column from '@/components/Column'
import React, { useEffect, useState } from 'react'

import { dimorderApi } from '@root/src/libs/api/dimorder'
import Row from '@root/src/components/Row'
import _ from 'lodash'
import colors from '@root/src/theme/colors'
import moment from 'moment'

/**
 * @typedef MemberListProps
 * @property {() => void} setMember
 * @property {string} filter
 */

/**
 *
 * @param {MemberListProps} props
 * @returns
 */
function MemberList (props) {
  const { setMember, filter } = props
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [records, setRecords] = useState([])
  const [nextPhoneIds, setNextPhoneIds] = useState([])
  const [nextNameIds, setNextNameIds] = useState([])

  async function getGroupUserList ({ queryPhoneNext = '', queryNameNext = '' }) {
    const responseQueryPhone = await dimorderApi.merchant.getGroupUserList({
      limit: 8,
      phone: filter && `+852${filter}`,
      after: queryPhoneNext,
    })

    setNextPhoneIds(prev => [...prev, responseQueryPhone?.next])

    if (filter === '') {
      return responseQueryPhone.data
    }

    const responseQueryName = await dimorderApi.merchant.getGroupUserList({
      limit: 8,
      name: filter,
      after: queryNameNext,
    })

    setNextNameIds(prev => [...prev, responseQueryName?.next])

    // 把重複的資料刪除
    const mergedData = _.concat(responseQueryPhone.data, responseQueryName.data)
    const uniqueData = _.uniqBy(mergedData, 'id')

    return uniqueData
  }

  useEffect(() => {
    async function getGroupUserTotal () {
      const total = await dimorderApi.merchant.getGroupUserCount()
      setTotal(total)
    }

    getGroupUserTotal()
  }, [])

  useEffect(() => {
    async function setData () {
      const data = await getGroupUserList({ queryPhoneNext: '', queryNameNext: '' })

      setRecords(data)
    }

    // clear all next id when filter is refreshed
    setNextPhoneIds([])
    setNextNameIds([])
    setPage(0)

    setData()
  }, [filter])

  /**
   * @param {{item:IMember}} props
   */
  const renderItem = ({ item }) => {
    const securephone = item?.phone?.slice(0, -5) + '***' + item?.phone?.slice(-2)
    const formatJoinedAt = moment(item.joinedAt).format('YYYY-MM-DD')
    const formatLatestOrder = item.orders[0]?.createdAt && moment(item.orders[0]?.createdAt).format('YYYY-MM-DD HH:mm')

    return (
      <TouchableOpacity
        onPress={() => {
          setMember(item)
        }}
        style={styles.row}
      >
        <Row style={{ marginHorizontal: 10 }}>
          <Text style={styles.cell}>{item.name}</Text>
          <Text style={[styles.cell]}>{securephone}</Text>
          <Text style={styles.cell}>{item.total}/{item.bought}</Text>
          <Text style={styles.cell}>{formatJoinedAt}</Text>
          <Text style={styles.cell}>{formatLatestOrder}</Text>
          <Image
            style={styles.icon}
            source={require('@icons/setting/more.png')}
          />
        </Row>
      </TouchableOpacity>
    )
  }

  const PaginationButtons = React.useCallback(() => {
    return (
      <View style={styles.paginationButtons}>
        <Button
          title={t('app.common.prev_page')}
          disabled={page === 0}
          onPress={async () => {
            setPage((prev) => prev - 1)
          }}
        />
        <Button
          title={t('app.common.next_page')}
          onPress={async () => {
            setPage((prev) => prev + 1)

            // 目前page不是最後一頁就不用再去 call api
            if (page < nextPhoneIds.length - 1 || page < nextNameIds.length - 1) return

            // 取得下一頁的資料
            const data = await getGroupUserList({
              queryPhoneNext: nextPhoneIds[page],
              queryNameNext: nextNameIds[page],
            })
            if (data.length === 0) return
            setRecords((prev) => {
              return [...prev, data].flat()
            })
          }}
          // 最後一頁時，nextPhoneIds[page] 和 nextNameIds[page] 都沒有next id
          disabled={!nextPhoneIds[page] && !nextNameIds[page]}
        />
      </View>
    )
  }, [page, records])

  return (
    <Column style={styles.container}>
      <View>
        <Text style={styles.textMessage}>{t('app.page.Member.totalMember')}:{total}</Text>
      </View>
      <FlatList
        style={{ padding: 10 }}
        data={records.slice(page * 8, (page + 1) * 8)}
        renderItem={renderItem}
        keyExtractor={item => item?.id}
        scrollEnabled={false}
        ListHeaderComponent={() => {
          return (
            <View style={styles.header}>
              <Text style={[styles.headerCell]}>{t('app.page.Member.name')}</Text>
              <Text style={[styles.headerCell]}>{t('app.page.Member.phone')}</Text>
              <Text style={[styles.headerCell]}>{t('app.page.Member.MemberList.spendingTotalTimes')}</Text>
              <Text style={[styles.headerCell]}>{t('app.page.Member.joinData')}</Text>
              <Text style={[styles.headerCell]}>{t('app.page.Member.MemberList.latestOrder')}</Text>
            </View>
          )
        }}
        ListEmptyComponent={
          <Column style={[styles.container, { justifyContent: 'center', alignItems: 'center', margin: 20 }]}>
            <Text style={{
              fontSize: 22,
              fontWeight: 'bold',
              color: colors.primary,
            }}
            >{t('app.page.Member.MemberList.noMember')}
            </Text>
          </Column>
        }
      />
      <PaginationButtons />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginHorizontal: '5%',
    marginVertical: '3%',
  },
  content: {
    alignItems: 'center',
  },
  textMessage: {
    marginHorizontal: 25,
    fontWeight: 'bold',
    fontSize: 17,
  },
  progress: {
    width: '90%',
    margin: 6,
  },
  header: {
    flexDirection: 'row',
    backgroundColor: colors.lightGray2,
    paddingVertical: 10,
    marginHorizontal: 15,
  },
  headerCell: {
    flex: 1,
    fontWeight: 'bold',
    color: colors.gray,
    fontSize: 15,
  },
  row: {
    flexDirection: 'row',
    backgroundColor: colors.light,
    borderColor: colors.lightGray4,
    paddingVertical: 23,
    paddingHorizontal: 5,
    borderRadius: 10,
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 3.84,
  },
  cell: {
    flex: 1,
  },
  paginationButtons: {
    marginTop: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',

  },
  icon: {
    height: 12,
    width: 12,
    marginTop: 3,
    marginRight: 10,
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

export default MemberList
