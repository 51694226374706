import { useSelector } from '@/redux'
import _ from 'lodash'

export function useSelectedParentCategory () {
  const categories = useSelector(state => state.menu.categories)
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  if (selectedCategory) {
    return categories[selectedCategory.parentId]
  }
}

export function useCategoriesPaths () {
  const categories = useSelector(state => state.menu.categories)
  const selectedCategory = useSelector(state => state.menu.selectedCategory)

  let category = selectedCategory
  const paths = []
  while (category) {
    paths.push(category)
    if (category.id === 'SET_ROOT') {
      paths.push(categories.ROOT)
    }
    category = categories[category.parentId]
  }

  return _.reverse(paths)
}
