import { StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

import { useSelectedOrder } from '@/hooks/orderHistory'
import Column from '@/components/Column'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function InfoPanel () {
  const { t } = useTranslation()
  const selectedOrder = useSelectedOrder()
  const { deliveryType } = selectedOrder

  return (
    <Column style={styles.container}>
      {deliveryType === 'table' &&
        <Text style={styles.titleText}>
          {`${t('app.constant.order.tableLong')} : `}
          <Text style={styles.hightlightText}>
            {selectedOrder.table}{selectedOrder.subTable > 0 ? `(${String.fromCharCode(selectedOrder.subTable + 64)})` : ''}
          </Text>
        </Text>}
      {(deliveryType === 'takeaway' || deliveryType === 'storeDelivery') &&
        <Text style={styles.titleText}>
          {`${t('app.constant.order.pickupAt')} : `}
          <Text style={styles.hightlightText}>
            {moment(selectedOrder.pickupAt).format('HH:mm')}
          </Text>
        </Text>}
      <Text style={styles.titleText}>
        {deliveryType === 'table' ? `${t('app.page.checkout.orderSummary.customers')} : ` : ' '}
        {deliveryType === 'table' &&
          <Text style={styles.hightlightText}>
            {`${selectedOrder.customers}`}
          </Text>}
      </Text>
      <Text style={styles.text}>
        {`${t('app.constant.order.serial')} : ${selectedOrder.serial}`}
      </Text>
      <Row>
        <Text style={styles.text}>
          {`${deliveryType === 'table' ? `${t('app.constant.order.dine-in-time')}` : `${t('app.constant.order.createAt')}`} : ${moment(selectedOrder.createdAt).format('HH:mm')}`}
        </Text>
      </Row>
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: 9,
  },
  titleText: {
    fontSize: 18,
    lineHeight: 27,
    color: colors.white,
  },
  text: {
    width: 160,
    fontSize: 12,
    lineHeight: 18,
    color: colors.white,
  },
  hightlightText: {
    color: colors.tertiary,
    borderBottomColor: colors.tertiary,
    borderBottomWidth: 2,
  },
})
