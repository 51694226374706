import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IAuthState} */
export const initialState = {
  isInit: false,
  isLoading: false,
  isMerchantLogin: false,
  isUserLogin: false,
  merchantLogin: {},
  userLogin: {},
  error: null,
  scopeGroups: {},
  scopeGroupUsers: {},
  users: [],
  nextActions: [],
  approver: '',
}

export default produce(
  /**
   * @param {IAuthState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'SET_NEXT_ACTIONS': {
        draft.nextActions = action.payload.nextActions
        break
      }
      case 'SET_CLOSE_ACTIONS': {
        draft.closeActions = action.payload.closeActions
        break
      }
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.MERCHANT_LOGIN: {
        const { login } = action.payload
        draft.merchantLogin = login
        draft.isMerchantLogin = true
        break
      }
      case ActionTypes.USER_LOGIN: {
        const { login } = action.payload
        draft.userLogin = login
        draft.isUserLogin = true
        break
      }
      case ActionTypes.USER_LOGOUT: {
        // draft.userLogin = {}
        draft.isUserLogin = false
        draft.error = null
        break
      }
      case ActionTypes.UPDATE_LOADING: {
        const { isLoading } = action.payload
        draft.loading = isLoading
        break
      }
      case ActionTypes.UPDATE_ERROR: {
        const { error } = action.payload
        draft.error = error
        break
      }
      case ActionTypes.UPDATE_USERS: {
        const { users } = action.payload
        draft.users = users
        draft.merchantLogin.scopeCredentials = users
        break
      }
      case ActionTypes.UPDATE_CURRENT_USER_PERMISSION: {
        const { permissions } = action.payload
        draft.userLogin.permissions = permissions
        break
      }
      case ActionTypes.UPDATE_SCOPE_GROUPS: {
        const { scopeGroups } = action.payload
        draft.scopeGroups = scopeGroups
        break
      }
      case ActionTypes.ADD_SCOPE_GROUP: {
        const { groupName } = action.payload
        draft.scopeGroups[groupName] = []
        draft.scopeGroupUsers[groupName] = []
        break
      }
      case ActionTypes.UPDATE_SCOPE_GROUP: {
        const { groupName, scopes } = action.payload
        draft.scopeGroups[groupName] = scopes
        break
      }
      case ActionTypes.DELETE_SCOPE_GROUP: {
        const { groupName } = action.payload
        delete draft.scopeGroups[groupName]
        delete draft.scopeGroupUsers[groupName]
        break
      }
      case ActionTypes.UPDATE_SCOPE_GROUP_USERS: {
        const { scopeGroupUsers } = action.payload
        draft.scopeGroupUsers = scopeGroupUsers
        break
      }
      case ActionTypes.ADD_SCOPE_GROUP_USER: {
        const { groupName, username } = action.payload
        draft.scopeGroupUsers[groupName]?.push(username)
        break
      }
      case ActionTypes.DELETE_SCOPE_GROUP_USER: {
        const { groupName, username } = action.payload
        const userIndex = draft.scopeGroupUsers[groupName].findIndex(scopeUserName => scopeUserName === username)
        if (userIndex > -1) {
          draft.scopeGroupUsers[groupName].splice(userIndex, 1)
        }
        break
      }
      case ActionTypes.SET_APPROVER: {
        const { approver } = action.payload
        draft.approver = approver
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
