import { Platform, StyleSheet, View } from 'react-native'
import { SwipeRow } from 'react-native-swipe-list-view'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Column from '../Column'
import DeleteActionRow from './DeleteActionRow'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'
import useBatchFlatList from '@/libs/BatchFlatList/useBatchFlatList'

// eslint-disable-next-line no-unused-vars
import { ItemProps } from '@/pages/Order/OrderBatch/BatchList/index.d'

/**
 * @param {ItemProps} props
 */
export default function SubItem (props) {
  const {
    isQuantity = false,
    required = false,
    serial = '',
    item,
    onPress,
    onDelete,
  } = props

  const { t } = useTranslation()
  const swipeRowRef = React.useRef()

  const [rowOpened, setRowOpened] = React.useState(false)
  const [rowError, setRowError] = React.useState(null)
  const [autoHideRowError, setAutoHideRowError] = React.useState(false)

  const flatListRef = useBatchFlatList()
  const selectedSetItemKey = useSelector(state => state.orderBatch.selectedSetItemKey)
  const isSelected = selectedSetItemKey && (isQuantity
    ? selectedSetItemKey.serial === serial
    : selectedSetItemKey === item.key)

  const menuItem = useSelector(state => state.menu.menus[item.menuId])
  const lang = String(i18n.language ?? 'zh').split('-')[0]

  // 餐點售罄
  const menuSold = menuItem?.sold
  // 餐點庫存
  const menuInventory = menuItem?.inventory
  // 已點數量
  const orderedQuantity = useSelector(state => _.get(
    state.orderBatch,
    ['menuItemQuantities', item.menuId],
    0,
  ))
  let isInventoryEnabled = useSelector(state => state.merchant.data?.setting?.inventory)
  if (menuInventory === undefined) {
    // 餐點沒設定數量時，不管庫存
    isInventoryEnabled = false
  }
  // 可點數量 = 庫存 - 別項點的數量 （別項點的數量 = 已點數量 - 這項點的數量）
  const inventory = menuInventory - (orderedQuantity - item.quantity)

  React.useEffect(() => {
    // 開啟後 3 秒關閉
    if (!rowOpened) return
    const timer = setTimeout(() => {
      swipeRowRef?.current?.closeRow()
    }, 3000)

    return () => clearTimeout(timer)
  }, [rowOpened])

  // 當 autoHideRowError 啟動時，3秒後會移除 error
  React.useEffect(() => {
    // 顯示錯誤後 3 秒隱藏 rowError
    if (!autoHideRowError) return
    const timer = setTimeout(() => {
      if (!autoHideRowError) return
      setRowError(null)
      setAutoHideRowError(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [autoHideRowError])

  // 若 item.quantity 為 0 則移除此項 setItem
  React.useEffect(() => {
    if (!item.quantity) {
      handleDelete()
    }
  }, [item.quantity])

  // 檢查餐點數量
  React.useEffect(() => {
    if (menuSold) {
      setRowError(t('app.page.order.orderBatch.error.sold'))
      setAutoHideRowError(false)
      return
    }

    if (!isInventoryEnabled) return // 不管庫存

    // 檢查餐點數量
    if (inventory < item.quantity) {
      setRowError(`${t('app.page.order.orderBatch.error.soldWithQuantity')}：${orderedQuantity - menuInventory}`)
      setAutoHideRowError(false)
    } else {
      setRowError(null)
    }
  }, [menuSold, isInventoryEnabled, inventory, item.quantity])

  const handleDelete = () => {
    if (item.isRequired || required) {
      // 關閉 SwipeRow
      swipeRowRef?.current?.closeRow()
      // 顯示刪除錯誤
      setRowError(t('app.page.order.orderBatch.error.delete-fail'))
      setAutoHideRowError(true)
    } else {
      // 關閉 SwipeRow
      swipeRowRef?.current?.closeRow()
      // 刪除
      onDelete()
    }
  }

  return (
    <SwipeRow
      ref={swipeRowRef}
      disableRightSwipe
      rightOpenValue={-50}
      // rightActivationValue={-500}
      onRowDidOpen={() => {
        setRowOpened(true)
      }}
      onRowDidClose={() => {
        setRowOpened(false)
      }}
      onSwipeValueChange={({ value }) => {
        // 避免 Swipe 中垂直捲動 FlatList 造成 Swipe 中斷
        flatListRef?.setNativeProps({ scrollEnabled: value > -30 })
      }}
      // onRightActionStatusChange={({ isActivated, value }) => {
      //   if (isActivated) {
      //     flatListRef?.setNativeProps({ scrollEnabled: true })
      //     handleDelete()
      //   }
      // }}
      onRowPress={onPress}
    >
      <DeleteActionRow onPress={handleDelete} />
      <View style={[styles.container, isSelected && styles.selected]}>
        <Column setItem index={0} value='' />
        <Column setItem index={1} value={` - ${_.get(menuItem?.localeNames, lang) ?? item.name + (item.isRequired ? '*' : '')}`} />
        <Column setItem index={2} value={item.status} />
        <Column setItem index={3} value={item.priceUndetermined ? t('app.constant.order.priceUndetermined') : item.price} />
        {/* <Column setItem index={4} value={isQuantity ? '1' : item.quantity} input={isSelected && !isQuantity && !item.isRequired} /> */}
        {rowError
          ? (
            <Column
              setItem
              index={2}
              width={155}
              textStyle={{ color: colors.textPrimary }}
              value={rowError}
            />
          )
          : (
            <>
              {/* <Column setItem index={5} value={Math.floor(item.totalDiscount * 10) / 10} />
              <Column setItem index={6} value={Math.floor(item.surcharge * 10) / 10} />
              <Column setItem index={7} value={Math.floor(item.total * 10) / 10} /> */}
            </>
          )}
      </View>
    </SwipeRow>
  )
}

const styles = StyleSheet.create({
  container: {
    // width: Platform.OS === 'web' ? 578 : 579,
    width: '100%',
    minWidth: Platform.OS === 'web' ? 578 : 579,
    minHeight: 28,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.light,
  },
  backContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.secondary,
  },
  deleteIcon: {
    width: 28,
    height: 28,
  },
  selected: {
    backgroundColor: colors.tertiary,
  },
})
