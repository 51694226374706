import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'

/**
 * @typedef CancelPaymentsAndUnlockOrderDialog
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {() => void} onSubmit
 */

/**
 *
 * @param {CancelPaymentsAndUnlockOrderDialogProps} props
 * @returns
 */
export default function CancelPaymentsAndUnlockOrderDialog (props) {
  const { t } = useTranslation()
  const {
    open,
    onClose,
    onSubmit,
  } = props

  return (
    <CenterModal
      enablePressOutsideClose
      title=''
      visible={open}
      onClose={onClose}
      contentContainerStyle={styles.contentContainer}
    >
      <Text style={styles.message}>
        {t('app.component.cancelD2cPaymentDialog.message')}
      </Text>
      <View style={styles.buttons}>
        <Button
          title={t('app.common.confirm')}
          onPress={() => {
            onSubmit()
            onClose()
          }}
        />
        <Button
          title={t('app.common.back')}
          onPress={() => {
            onClose()
          }}
        />
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  message: {
    fontSize: 18,
    lineHeight: 24,
    textAlign: 'center',
  },
  contentContainer: {
    width: 550,
    paddingHorizontal: 30,
    minHeight: 100,
  },
  buttons: {
    paddingTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})
