import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

/**
 * @typedef SettingListProps
 * @property {JSX.Element} children
 * @property {string?} title
 * @property {boolean?} shadow
 * @property {ViewProps['style']?} style
 * @property {ViewProps['style']?} contentContainerStyle
 */

/**
 *
 * @param {SettingListProps} props
 * @returns
 */
export default function SettingList (props) {
  const { shadow, style, contentContainerStyle, title, children } = props

  return (
    <View
      style={[
        styles.wrapper,
        shadow && shadows.default,
        title && { marginTop: 30 },
        style,
      ]}
    >
      {title && (
        <View style={styles.tab}>
          <Text style={styles.tabText}>{title}</Text>
        </View>
      )}
      <View
        style={[
          styles.container,
          title && { borderTopLeftRadius: 0 },
          contentContainerStyle,
        ]}
      >
        {children}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 5,
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    maxHeight: '100%',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: 5,
    overflow: 'hidden',
  },
  tab: {
    height: 30,
    width: 'auto',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    paddingHorizontal: 20,
    position: 'absolute',
    top: -30,
  },
  tabText: {
    color: colors.white,
  },
})
