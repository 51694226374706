import { Animated, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import AnimatedBackground from '@/components/AnimatedBackground'
import CustomDialog from '@/components/CustomDialog'
import colors from '@/theme/colors'

import EmployeeLogin from './EmployeeLogin'

/**
 * @typedef LoginDialogProps
 */

/**
 *
 * @param {LoginDialogProps} props
 * @returns
 */
export default function LoginDialog (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [islogoHidden, setIsLogoHidden] = useState(false)
  const animationValue = useRef(new Animated.Value(0)).current
  const screenLockVisible = useSelector(state => state.app.dialog.screenLock)
  const permissionDialogVisible = useSelector(state => state.app.dialog.permission)
  const isOpen = permissionDialogVisible || screenLockVisible
  const type = permissionDialogVisible ? 'permission' : 'screenLock'

  useEffect(() => {
    dispatch(actions.app.isDialogOpened(isOpen))
  }, [isOpen])

  if (!isOpen) return null
  return (
    <CustomDialog
      title={t('app.component.loginDialog.login')}
      width='80%'
      height='90%'
      enablePressOutsideClose={permissionDialogVisible}
      closeButton={permissionDialogVisible}
      onClose={() => {
        dispatch(actions.app.closeDialog(['permission']))
      }}
    >
      <AnimatedBackground animationValue={animationValue} style={styles.roundBottomCorner} />
      <View style={[styles.overlay, styles.roundBottomCorner]} />
      <EmployeeLogin setIsLogoHidden={setIsLogoHidden} type={type} />
    </CustomDialog>
  )
}
const styles = StyleSheet.create({
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: colors.lightPrimary,
    opacity: 0.5,
  },
  roundBottomCorner: {
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
})
