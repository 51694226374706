import { FlatList, StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import colors from '@/theme/colors'

import Column from '@/components/Column'
import IconButton from '@/components/buttons/IconButton'

const ITEM_HEIGHT = 60

/**
 * @typedef NumberInputProps
 * @property {number} value
 * @property {() => void} setValue
 * @property {number?} min
 * @property {number?} max
 * @property {number?} interval
 * @property {ViewProps['style']?} style
 * @property {boolean?} paddingDigit 是否將 value 前面補 0
 */

/**
 *
 * @param {NumberInputProps} props
 * @returns
 */
export default function NumberInput (props) {
  const {
    min = 0,
    max = 3,
    interval = 1,
    value = min,
    setValue,
    style,
    paddingDigit = false,
  } = props

  const scrollRef = useRef()
  const [currentIndex, setCurrentIndex] = useState(Math.floor(min / interval))
  const options = _.range(min, max + interval, interval)

  useEffect(() => {
    // 避免value超出min & max
    const privateValue = _.clamp(value, min, max)
    const index = Math.floor(privateValue / interval) - Math.floor(min / interval)

    setCurrentIndex(prev => {
    // 滑到index，數字太大的話不加動畫
      scrollRef.current.scrollToIndex({ animated: Math.abs(prev - index) < 3, index })
      return index
    })
  }, [value])

  const onIncrease = () => {
    if (currentIndex + 1 >= options.length) {
      return
    }
    setValue((currentIndex + 1) * interval + min)
  }

  const onDecrease = () => {
    if (currentIndex - 1 < 0) {
      return
    }
    setValue((currentIndex - 1) * interval + min)
  }

  const renderItem = ({ item }) =>
    <View style={styles.item}>
      <Text style={styles.itemText}>{paddingDigit
        ? _.padStart(String(item), String(max).length, 0)
        : String(item)}
      </Text>
    </View>

  return (
    <Column style={[styles.container, style]}>
      <IconButton
        iconSource={require('@icons/table/tip-up.png')}
        iconStyle={styles.icon}
        onPress={onIncrease}
      />
      <View style={styles.boundary}>
        <FlatList
          ref={scrollRef}
          data={options}
          renderItem={renderItem}
          keyExtractor={(item) => item.toString()}
          // Scroll
          pagingEnabled
          scrollEnabled={false}
          initialScrollIndex={currentIndex}
          getItemLayout={(data, index) => (
            { length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index }
          )}
          // Style
          contentContainerStyle={{ alignItems: 'center' }}
          showsVerticalScrollIndicator={false}
        />
      </View>
      <IconButton
        iconSource={require('@icons/table/tip-down.png')}
        iconStyle={styles.icon}
        onPress={onDecrease}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  icon: {
    height: 45,
    width: 40,
    padding: 15,
  },
  boundary: {
    height: ITEM_HEIGHT,
    paddingVertical: 5,
  },
  item: {
    height: ITEM_HEIGHT,
  },
  itemText: {
    fontSize: 40,
    fontWeight: '500',
    color: colors.primary,
  },
})
