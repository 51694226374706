import { Image, StyleSheet, TouchableOpacity } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import React from 'react'

const iconSources = {
  pictureMode: require('@icons/order-header/picture-mode.png'),
  textMode: require('@icons/order-header/text-mode.png'),
}

export default function PictureModeSwitchButton ({ style }) {
  const dispatch = useDispatch()
  const isPictureMode = useSelector(state => state.menu.isPictureMode)
  const targetIconSource = isPictureMode ? iconSources.textMode : iconSources.pictureMode

  return (
    <TouchableOpacity onPress={() => {
      dispatch(actions.menu.updatePictureMenuMode(!isPictureMode))
      dispatch(actions.menu.resetSearchInput())
    }}
    >
      <Image style={[styles.image, style]} source={targetIconSource} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  image: {
    width: 38,
    height: 38,
    margin: 8,
  },
})
