import { BlurView } from 'expo-blur'
import { ImageBackground, PixelRatio, StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { numberWithCommas } from '@/libs/numberWithCommas'
import { useSelectedBatch, useSelectedBatchItem } from '@/hooks/order'
import { useTranslation } from 'react-i18next'
import DisabledLayer from '../DisabledLayer'
import InventoryTag from '../InventoryTag'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import cloudinary from '@/libs/cloudinary'
import colors from '@/theme/colors'
import i18n from '@/i18n'
import orderAppLib from 'dimorder-orderapp-lib'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from 'dimorder-orderapp-lib/dist/types/AppMenu'

MenuItem.propTypes = {
  item: PropTypes.object,
  isMax: PropTypes.bool,
}

const IMAGE_TRANSFORMATION = 'w_' + 100 * PixelRatio.get()
console.log('IMAGE_TRANSFORMATION', IMAGE_TRANSFORMATION)

/**
 * @param {{item: IAppMenuItem | IAppSet}} props
 */
export default function MenuItem (props) {
  const { t } = useTranslation()
  const { item: originalItem, isMax: stepIsMax, stepCount, setItems = [] } = props
  const dispatch = useDispatch()
  const selectedBatch = useSelectedBatch()
  const [selectedItem] = useSelectedBatchItem()
  const selectedSet = useSelector(state => state.menu.selectedSet)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  // fixme: add order.from to orderapplib?
  // wing suggestion!!!
  const item = orderAppLib.libs.setDeliveryTypeMenuPrice(originalItem, 'table')
  const hasImage = Boolean(item.image)
  const imageSource = hasImage ? { uri: cloudinary.transformation(item.image, IMAGE_TRANSFORMATION) } : require('@icons/placeholder-1.png')
  let isInventoryEnabled = useSelector(state => state.merchant?.data?.setting?.inventory)
  const orderedQuantity = useSelector(state => state.orderBatch.menuItemQuantities[item.menuId] || 0)
  if (item.inventory === undefined) {
    // 沒設定數量時，不管庫存
    isInventoryEnabled = false
  }
  const stepItemQuantity = setItems.filter(o => o.menuId === originalItem.menuId).length
  // 可點數量 = 庫存 - 已點數量
  const inventory = item.inventory - orderedQuantity
  const isSoldout = inventory <= 0
  const isSuspended = item.sold
  const isMax = stepIsMax || (originalItem.max > 0 && stepItemQuantity >= originalItem.max)
  const isDisabled = (isInventoryEnabled && isSoldout) || isSuspended || isMax
  const disabledText = isInventoryEnabled && isSoldout
    ? t('app.page.order.orderMenu.sold')
    : isSuspended
      ? t('app.page.order.orderMenu.suspend')
      : t('app.page.order.orderMenu.stepMax')
  const price = item.priceUndetermined ? t('app.constant.order.priceUndetermined') : item.price ? (item.price - (item.discount ?? 0)) : 0
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const disabledFontSize = isMax ? 14 : (lang === 'zh') ? 30 : 16
  const allMenus = useSelector(state => state.menu.menus)
  const displayName = selectedSet ? _.get(allMenus[item.menuId]?.localeNames, lang) : _.get(item?.localeNames, lang)

  return (
    <TouchableHighlight
      style={styles.container}
      disabled={isDisabled}
      onPress={async () => {
        if (item.step) {
          // is set step item
          dispatch(actions.orderBatch.addSetItem(item, selectedSet))
          const index = selectedSet?.steps.findIndex(o => o === selectedSetStep)
          const length = selectedSet?.steps.length
          const selectedSetItems = _.map(selectedItem?.setItems, s => s.setMenuId)
          selectedSetItems.push(item.id)
          if (
            stepCount === 1 &&
            (length - index > 1)
          ) {
            const steps = [...selectedSet.steps].slice(index + 1)
            steps.some(step => {
              if ((
                !step?.dependsOnSetMenuItem ||
                  selectedSetItems.includes(step.dependsOnSetMenuItem)
              ) &&
                (
                  !step?.dependsOnSetMenuItems?.length ||
                  selectedSetItems.some(s => step.dependsOnSetMenuItems.includes(s))
                )) {
                dispatch(actions.menu.selectSetStep(step))
                return true
              }
            })
          }
        } else {
          await dispatch(actions.orderBatch.addItem(item))
        }
        if (item.priceUndetermined) {
          const existedItem = selectedBatch.items.find(o => o.menuId === item.menuId)
          if (existedItem) {
            if (!existedItem.priceUndetermined) {
              dispatch(actions.orderBatch.updatePrice(Number(existedItem.price)))
            }
          } else {
            dispatch(actions.app.showDialog(['order', 'inputPrice']))
          }
        }
      }}
    >
      <ImageBackground source={imageSource} style={styles.imageBackground} imageStyle={styles.image}>
        <View style={[styles.blurBackground, { backgroundColor: hasImage ? colors.textPrimary + 'AB' : colors.primary }]} />
        <BlurView tint='dark' intensity={20} style={styles.blurView}>
          <View style={styles.textContainer}>
            <Text style={styles.text} numberOfLines={1} ellipsizeMode='tail'>{displayName ?? item.name}</Text>
            <Text style={styles.text}>{/^\d+$/.test(price) ? `＄${numberWithCommas(price)}` : price}</Text>
          </View>
        </BlurView>
        {isDisabled &&
          <DisabledLayer fontSize={disabledFontSize} text={disabledText} enabled={isDisabled} />}

        {isDisabled && isInventoryEnabled && isSoldout &&
          <InventoryTag anchorOrigin={{ vertical: 'top', horizontal: 'right' }} inventory={inventory} />}

      </ImageBackground>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: 105,
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 4,
    marginHorizontal: 10,
  },
  imageBackground: {
    width: 100,
    height: 105,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  image: {
    borderRadius: 8,
  },
  blurBackground: {
    position: 'absolute',
    bottom: 0,
    width: 100,
    height: 44,
  },
  blurView: {
    width: 100,
    height: 44,
  },
  textContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 4,
  },
  text: {
    color: colors.white,
    fontSize: 15,
    fontWeight: '500',
    lineHeight: 17,
    textAlign: 'center',
  },
})
