import { Image, StyleSheet, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SearchBarProps
 * @property {string} value
 * @property {() => void} onChangeText
 * @property {() => void} onCancel
 * @property {() => void?} onBlur
 * @property {() => void?} onOpen
 * @property {boolean?} hidden
 * @property {string?} placeholder
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {SearchBarProps} props
 * @returns
 */
export default function SearchBar (props) {
  const { t } = useTranslation()
  const {
    value = '',
    onChangeText,
    onCancel,
    onBlur = () => {},
    onOpen = () => {},
    hidden = false,
    style,
    placeholder = t('app.page.order.search'),
  } = props

  return (
    <>
      {
        hidden
          ? (
            <TouchableOpacity
              style={styles.container}
              disabled={!hidden}
              onPress={onOpen}
            >
              <Image
                style={styles.icon}
                source={require('@icons/order-header/search.png')}
              />
            </TouchableOpacity>
          )
          : (
            <View style={[styles.searchBar, style]}>
              <Image style={styles.searchIcon} source={require('@icons/order-header/search.png')} />
              <TouchableWithoutFeedback>
                <TextInput
                  autoFocus
                  style={styles.input}
                  returnKeyType='search'
                  placeholder={placeholder}
                  placeholderTextColor={colors.textPlaceholder}
                  value={value}
                  onChangeText={onChangeText}
                  onBlur={onBlur}
                />
              </TouchableWithoutFeedback>
              <TouchableOpacity
                onPress={onCancel}
              >
                <Image
                  style={styles.closeIcon}
                  source={require('@icons/order-header/close.png')}
                />
              </TouchableOpacity>
            </View>
          )
      }
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 35,
    // width: 35,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 35 / 2,
  },
  searchBar: {
    width: 350,
    height: '100%',
    maxHeight: 35,
    borderRadius: 17.5,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  searchIcon: {
    width: 35,
    height: 35,
  },
  input: {
    flex: 1,
    fontSize: 17,
    marginHorizontal: 8,
  },
  closeIcon: {
    width: 35,
    height: 35,
  },
})
