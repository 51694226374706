import { FlatList, Text } from 'react-native'
import React from 'react'
import Row from '@/components/Row'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import styles from '../styles'

const rowWidth = {
  item: 240,
  quantity: 50,
  price: 80,
}

const separatorStyle = {
  dotted: '---------------------------------------------------------',
  doubleDotted: '==========================================',
  straight: '_________________________________________________',
}

export default function ReceiptContent (props) {
  const { item } = props
  const { t } = useTranslation()
  const title = item.title
  const settings = item.settings
  const rowTitle = {
    item: t('app.page.setting.printer.customizedSetting.item'),
    quantity: t('app.page.setting.printer.customizedSetting.quantity'),
    price: t('app.page.setting.printer.customizedSetting.price'),
  }
  const rowItems = [
    {
      item: t('app.page.setting.printer.customizedSetting.menuItem'),
      quantity: '2',
      price: '$10',
      isSetItem: false,
    },
    {
      item: t('app.page.setting.printer.customizedSetting.set'),
      quantity: '1',
      price: '$50',
      isSetItem: false,
    },
    {
      item: t('app.page.setting.printer.customizedSetting.setItem1'),
      quantity: '3',
      price: '$20',
      isSetItem: true,
    },
    {
      item: t('app.page.setting.printer.customizedSetting.setItem2'),
      quantity: '4',
      price: '$30',
      isSetItem: true,
    },
  ]

  const renderItem = ({ item: menuItem, index }) => {
    return (
      <>
        <Row>
          {
            _.flatMap(item.format, (type, index) => {
              const alignRight = index && type !== 'item'
              if (menuItem.isSetItem && !settings.showSetItemsPrice && type === 'price') {
                return <Text key={type + index} style={[{ width: rowWidth[type] }, alignRight && { textAlign: 'right' }, styles.small]} />
              }
              return <Text key={type + index} style={[{ width: rowWidth[type] }, alignRight && { textAlign: 'right' }, styles.small]}>{menuItem[type]}</Text>
            })
          }
        </Row>
        {
          (settings.leading && index !== 3) && (
            <Text key={'space' + index} />
          )
        }
      </>
    )
  }

  return (
    <>
      {
        title.enable && (
          <>
            {
              title.separator && (
                <Text>{separatorStyle[title.separatorStyle]}</Text>
              )
            }
            <Row>
              {
                _.flatMap(item.format, (type, index) => {
                  const alignRight = index && type !== 'item'
                  return <Text key={type} style={[{ width: rowWidth[type] }, alignRight && { textAlign: 'right' }, styles.small]}>{rowTitle[type]}</Text>
                })
              }
            </Row>
            {
              title.separator && (
                <Text>{separatorStyle[title.separatorStyle]}</Text>
              )
            }
          </>
        )
      }
      <FlatList
        data={rowItems}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(item.item + index)}
        showsVerticalScrollIndicator={false}
      />
    </>
  )
}
