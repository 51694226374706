import { Image, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef ExpandableDividerProps
 * @property {boolean?} expanded
 * @property {boolean?} disabled
 * @property {() => void?} onPress
 * @property {ViewProps['style']?} lineStyle
 */

/**
 *
 * @param {ExpandableDividerProps} props
 * @returns
 */
export default function ExpandableDivider (props) {
  const { expanded, disabled, onPress, lineStyle } = props

  const iconSources = {
    up: require('@icons/discount-dialog/keyboard-arrow-up.png'),
    down: require('@icons/discount-dialog/keyboard-arrow-down.png'),
  }
  const direction = expanded ? 'up' : 'down'

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      {
        disabled
          ? (
            <View style={[styles.container, styles.containerDisabled]}>
              <View style={[styles.line, styles.lineDisabled, lineStyle]} />
            </View>
          )
          : (
            <View style={styles.container}>
              <View style={[styles.line, lineStyle]} />
              <Image style={styles.icon} source={iconSources[direction]} />
              <View style={[styles.line, lineStyle]} />
            </View>
          )
      }
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  line: {
    flex: 1,
    height: 1.5,
    borderRadius: 8,
    backgroundColor: colors.lightBlue1,
    margin: 10,
  },
  containerDisabled: {
    height: 36,
  },
  lineDisabled: {
    width: '100%',
  },
  icon: {
    height: 36,
    width: 36,
  },
})
