import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import i18n from '@/i18n'

export default function MethodCard ({
  style,
  item: { name, iconSource, useSmallIcon },
  active,
  onPress,
}) {
  const containerStyle = {
    borderColor: active ? colors.primary : colors.transparent,
  }

  const iconStyle = {
    width: useSmallIcon ? 40 : 80,
  }

  return (
    <TouchableOpacity onPress={onPress}>
      <Row style={[styles.container, containerStyle, style]}>
        <Image style={[styles.icon, iconStyle]} source={iconSource} />
        {useSmallIcon && <Text style={styles.text}>{i18n.t(name)}</Text>}

        {active && <Image style={styles.activeIcon} source={require('@icons/checkout/payment/active-payment.png')} />}
      </Row>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 60,
    width: 110,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 8,
    borderWidth: 4,
  },
  icon: {
    height: 40,
    resizeMode: 'contain',
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
  activeIcon: {
    height: 25,
    width: 25,
    position: 'absolute',
    top: -5,
    right: -5,
  },
})
