import { Image, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import _ from 'lodash'
import orderAppLib from 'dimorder-orderapp-lib'

import { actions, useDispatch, useSelector } from '@/redux'
import { numberWithCommas } from '@/libs/numberWithCommas'
import colors from '@/theme/colors'
import delay from '@/libs/delay'
import shadows from '@/theme/shadows'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import Modal from '@/components/EnhancedModal'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import Row from '@/components/Row'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { ITable } from '@/redux/table/Table.d'
/* eslint-enable no-unused-vars */

/**
 * @typedef TableModalProps
 * @property {ITable} table
 * @property {IAppOrder[]} orders 這張桌的訂單
 * @property {() => void} onClose
 * @property {JSX.Element} children
 */

/**
 *
 * @param {TableModalProps} props
 * @returns
 */
export default function TableModal (props) {
  const {
    table: { key, maxCustomer, name },
    orders,
    onClose = () => { },
    children,
  } = props

  const defaultState = {
    name: '',
    customerNameSuffix: '',
    memberId: '',
    phone: '',
    remark: '',
    adults: 1,
    children: 0,
    tags: [],
    createdAt: new Date(),
  }

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const visible = useSelector(state => state.table.dialog.table)
  const { qrDisableCustomerCount, enableCategoryTag, qrcode, enableDynamicQRCode } = useSelector(state => state.merchant.data?.setting) ?? {}
  const rounding = useSelector(state => state.merchant.data.rounding)
  const { selectPrinterBeforePrint } = useSelector(state => state.app.settings)

  const unpaidOrdersSummary = React.useMemo(() => {
    const unpaidOrders = [] // 總未結帳單
    let unpaidTotalAmount = 0 // 總未結帳金額
    let customerCount = 0 // 總用餐人數

    _.forEach(orders, order => {
      if (order.status === 'pending') {
        unpaidOrders.push(order)
        unpaidTotalAmount += order.roundedTotal
        customerCount += (order.adults + order.children)
      }
    })

    return {
      unpaidOrders,
      unpaidOrdersCount: unpaidOrders.length,
      unpaidTotalAmount: numberWithCommas(orderAppLib.libs.getDisplayRoundNumber(unpaidTotalAmount, rounding)),
      customerCount,
    }
  }, [orders])

  const { unpaidOrdersCount, unpaidTotalAmount, customerCount } = unpaidOrdersSummary
  const isAvailable = customerCount < maxCustomer

  const [state, setState] = useState(defaultState)
  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)
  const [openTagSelectDialog, setOpenTagSelectDialog] = React.useState(false)
  const [isVisible, setVisible] = React.useState(visible)

  const categoryTagOptions = [] // ?????

  const startOrder = t('app.page.table.dialog.startOrder')
  const customer = t('app.page.table.dialog.customer')
  const orderNo = t('app.page.table.dialog.orderNo')
  const categoryTagText = t('app.page.table.dialog.categoryTag')

  // Styles
  const headerStyle = {
    backgroundColor: isAvailable ? colors.green : colors.secondary,
  }

  React.useEffect(() => {
    setVisible(visible)
  }, [visible])

  async function createOrder (selectedPrinterId = '') {
    onClose()
    await dispatch(actions.table.createOrder({ ...state, table: key }, null, selectedPrinterId))
    setState(defaultState)
  }

  return (
    <Modal
      // animationType='fade'
      transparent
      style={styles.centeredView}
      visible={isVisible}
      onRequestClose={onClose}
      supportedOrientations={['landscape']}
    >
      <TouchableWithoutFeedback
        onPress={onClose}
      >
        <View style={[styles.container, styles.backdrop]}>
          <TouchableWithoutFeedback>
            <View style={styles.modalView}>
              <View style={[styles.header, headerStyle]}>
                <TouchableWithoutFeedback onPress={onClose}>
                  <Image style={styles.closeIcon} source={require('@icons/close.png')} />
                </TouchableWithoutFeedback>

                <Column style={styles.headerMain}>
                  <Row>
                    <View style={styles.titleText}>
                      <Text style={styles.title}>{name}</Text>
                    </View>

                    {isAvailable &&
                      <Button
                        title={startOrder}
                        style={styles.newTableButton}
                        textBold
                        type='TouchableOpacity'
                        backgroundColor={colors.transparent}
                        onPress={() => {
                          setVisible(false)
                          dispatch(actions.auth.permissionCheck('merchant-staff-order', async () => {
                            if (qrDisableCustomerCount && visible) {
                              if (enableCategoryTag && categoryTagOptions.length !== 0 && qrcode && enableDynamicQRCode) {
                                setVisible(true)
                                setOpenTagSelectDialog(true)
                              } else if (selectPrinterBeforePrint && qrcode && enableDynamicQRCode) {
                                setVisible(true)
                                setPrinterDialogOpen(true)
                              } else {
                                createOrder()
                              }
                            } else {
                              setVisible(true)
                              onClose()
                              await delay(500)
                              dispatch(actions.table.showDialog(['newOrder']))
                            }
                          }, () => {
                            setVisible(true)
                          }))
                        }}
                      />}
                  </Row>
                  <Row>
                    <Text style={styles.headerText}>{customer}：{customerCount}</Text>
                    <Text style={styles.headerText}>{orderNo}：{unpaidOrdersCount}</Text>
                  </Row>
                </Column>

                <Text style={styles.title}>＄{unpaidTotalAmount}</Text>
              </View>
              <Column style={styles.content}>
                {children}
              </Column>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
      <PrinterDialog
        open={printerDialogOpen}
        onClose={() => setPrinterDialogOpen(false)}
        onSelect={(selectedPrinterId) => {
          createOrder(selectedPrinterId)
        }}
      />
      <OptionsSelectDialog
        open={openTagSelectDialog}
        onClose={() => setOpenTagSelectDialog(false)}
        onSubmit={async (categoryTagId) => {
          if (selectPrinterBeforePrint && qrcode && enableDynamicQRCode) {
            setPrinterDialogOpen(true)
          } else {
            onClose()
            await dispatch(actions.table.createOrder({ ...state, table: key }, categoryTagId, ''))
            setState(defaultState)
          }
        }}
        options={categoryTagOptions}
        title={categoryTagText}
        enableButtonDisable
        disableHeader
      />
    </Modal>

  )
}

const styles = StyleSheet.create({
  centeredView: {
    ...StyleSheet.absoluteFill,
    margin: 0,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    backgroundColor: colors.black + '80',
  },
  modalView: {
    height: 688,
    width: 480,
    borderRadius: 8,
    backgroundColor: colors.white,
    paddingBottom: 6,
    ...shadows.default,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: 20,
  },
  headerMain: {
    flex: 1,
  },
  closeIcon: {
    height: 32,
    width: 32,
    margin: 8,
    marginRight: 20,
  },
  title: {
    fontSize: 38,
    fontWeight: 'bold',
    color: colors.white,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.white,
    marginRight: 16,
  },
  newTableButton: {
    width: 95,
    borderColor: colors.white,
    borderWidth: 2,
    marginLeft: 16,
  },
  content: {
    flex: 1,
    padding: 10,
  },
  titleText: {
    maxWidth: 200,
  },
})
