import EscPosEncoder from 'esc-pos-encoder'
import _ from 'lodash'

import {
  PrintMethod,
  PrinterBrand,
  PrinterCommand,
  PrinterEncoding,
  PrinterFontSize,
  PrinterType,
  PrinterUsage,
} from '@/constants/printer'
import PrintUtiliy from '@/libs/printing/PrintUtiliy'
import Printer from '@/libs/printing/printer/Printer'
import store from '@/redux/store'

import ImageMode from '../BmpImage/ImageMode'
import getImageCommand from '../BmpImage/getImageCommand'
import straightLine from '../BmpImage/straightLine'

/** @type {() => IRootState} */
const getState = store.getState

/**
 * STAR 針機
 * encoding - 中文: big5
 * command: ESC/POS
 * 用途：主要是廚房單，一般不會用於收據
 * Printer Programming Manual: https://drive.google.com/drive/folders/1rQKQpbzN181W7zv6GAQfjhV1-ZRicKE9
 */
export default class SP700 extends Printer {
  TYPE = PrinterType.SP700
  PRINTER_BRAND = PrinterBrand.STAR
  PRINTER_COMMAND = PrinterCommand.ESC_POS
  PRINT_METHOD = PrintMethod.DOT
  USAGES = [PrinterUsage.KITCHEN_BATCH]
  ENCODINGS = { BIG5: PrinterEncoding.BIG5 }
  DEFAULT_ENCODING = this.ENCODINGS.BIG5
  MAX_WIDTH = 42
  DOT_WIDTH = 208

  encoding = this.DEFAULT_ENCODING
  encoder = new EscPosEncoder()
  imageMode = new ImageMode(this.DOT_WIDTH, this.TYPE)
  isImageMode = false

  errorStatus = {
    2: {
      32: 'cover_open',
    },
    3: {
      4: 'mechanical_error',
      8: 'auto-cutter_error',
      32: 'non-recoverable_Error',
      64: 'stopped_by_high_head_temperature',
    },
    4: {
      8: 'black_mark_detection_error',
      64: 'buffer_overflow',
    },
    5: {
      8: 'paper_end',
    },
  }

  setImageMode (on = true) {
    this.isImageMode = on
    return this
  }

  addInit () {
    this.writeNumbers([27, 64])
    return this
  }

  /**
   * ! NOT-support
   * SP700 沒有這個 command
   * @returns
   */
  addCodePage () {
    // this.encoding = this.DEFAULT_ENCODING
    // this.writeNumbers([28, 38])
    return this
  }

  /**
   *
   * @param {string} text
   * @param {0 | 1 | 2 | 3 | 4 | 'small' | 'medium' | 'large' | 'medium_width' | 'large_width'} fontSize
   * @param {boolean?} isImageMode
   * @returns
   */
  addText (text, fontSize, isImageMode = this.isImageMode) {
    console.log(`text(style=${fontSize}): `, text)
    if (isImageMode) {
      const image = this.imageMode
      image.addText(text, fontSize)
      this.writeString(text, this.encoding, true)
      return this
    }
    // check current text style to prevent duplicate call
    if (typeof fontSize !== 'undefined') {
      switch (fontSize) {
        case PrinterFontSize.EXTRALARGE:
        case 5:
        case PrinterFontSize.LARGEWIDTH:
        case 4:
        case PrinterFontSize.MEDIUWIDTH:
        case 3:
        case PrinterFontSize.LARGE:
        case 2:
        case PrinterFontSize.MEDIUM:
        case 1:
          this.writeNumbers([27, 120, 0]) // ESC x 0 [Name] Specify expanded Kanji characters (Double high/double high & wide)
          this.writeNumbers([27, 87, 1]) // ESC W n [Name] Specify/cancel expanded double-wide printing (0: Cancel double wide printing, 1: Specify double-wide expanded printing)
          this.writeNumbers([27, 104, 1]) // ESC h n [Name] Specify/cancel expanded double-tall printing (0: Cancel printing double high expanded character height, 1: Specify double-tall expanded printing)
          break
        default:
          this.writeNumbers([27, 120, 1]) // ESC x 1 [Name] Specify two byte Kanji characters (cancel expanded Kanji characters)
          this.writeNumbers([27, 87, 0])
          this.writeNumbers([27, 104, 0])
      }
    }
    // console.log('this.encoding', this.encoding)

    if (typeof text !== 'undefined') {
      this.writeString(text, this.encoding)
    }

    return this
  }

  addLogo () {
    const image = _.get(getState(), 'app.settings.logo.data', [])
    const command = getImageCommand(image, 'ESC K')
    this.addAlign('center')
    this.writeNumbers(command)
    return this
  }

  async addQRCode (url, position = 'center', size = 8) {
    const command = this.encoder
      .align(position)
      .qrcode(url, 1, size, 'h')
      .encode()
    this.writeNumbers(command)
    return this
  }

  addDoubleStrikeMode (on = true) {
    const n = on ? 69 : 70
    this.writeNumbers([27, n])
    return this
  }

  addItalicMode (on = true) {
    const command = this.encoder.italic(on).encode()
    this.writeNumbers(command)
    return this
  }

  addUnderlineMode (on = true) {
    const image = this.imageMode
    image.addUnderlineMode(on)
    const command = this.encoder.underline(on).encode()
    this.writeNumbers(command)
    return this
  }

  /**
   *
   * @param {'left' | 'center' | 'right'} align
   * @returns
   */
  addAlign (align) {
    const image = this.imageMode
    image.addAlign(align)
    let command = ''
    if (align === 'left') command = [27, 29, 97, 0]
    if (align === 'center') command = [27, 29, 97, 1]
    if (align === 'right') command = [27, 29, 97, 2]
    this.writeNumbers(command)
    return this
  }

  /**
   *
   * @param {string} color
   * @returns
   */
  addColor (color) {
    let n = 52
    switch (color) {
      case 'red':
        n = 52
        break
      case 'black':
      default:
        n = 53
        break
    }
    this.writeNumbers([27, n])
    return this
  }

  addRowFeed (rows = 1) {
    if (this.isImageMode) {
      const image = this.imageMode
      const command = image.printText()
      this.writeNumbers(command)
    }
    this.writeNumbers(Array((Math.floor(rows / 2) > 1 ? Math.floor(rows / 2) : 1)).fill([10]))
    return this
  }

  addFixedRow (texts, widths, styleText, rowFeed = 1, align = [], fonts = []) {
    const row = _.map(texts, (text, index) => PrintUtiliy.getItemNameByMaxWidth(text ?? '', widths[index], this.chineseTextWidth))
    if (this.isImageMode) {
      console.log(`text(style=${styleText}): `, texts)
      const image = this.imageMode
      const command = image.addFixedRow(texts, widths, styleText, align, fonts)
      this.writeNumbers(command)
      const feed = rowFeed - 1
      if (feed) {
        this.addRowFeed(feed)
      }
      this.writeString(_.flatten(row).join(''), this.encoding, true)
      return this
    }
    const maxLength = _.max(_.map(row, r => r?.length ?? 0))
    for (let i = 0; i < maxLength; i++) {
      const itemRowText = _.map(row, (r, index) => this._alignText(r[i] ?? '', align[index], widths[index]))
      this._printRow(itemRowText, widths, styleText, rowFeed, fonts)
    }
  }

  _alignText (text, align, length) {
    if (!text) {
      return text
    }
    switch (align) {
      case ImageMode.Align.RIGTH:
        return PrintUtiliy.leftPad(text, length)
      default:
        return text
    }
  }

  _printRow (texts, widths, styleText, rowFeed = 1, fonts) {
    if (!texts) {
      texts = []
    }
    const PRINT_MAX_WIDTH = this.MAX_WIDTH
    if (_.sum(widths) > PRINT_MAX_WIDTH) {
      console.error('widths too long:', widths)
    }
    _.each(texts, (text, i) => {
      const width = widths[i]
      let buffer = ''
      _.each(text, (char) => {
        const length = PrintUtiliy.strLen(buffer + char, 2)
        if (length > width) {
        } else {
          buffer += char
        }
      })
      if (PrintUtiliy.strLen(buffer, 2) < width) {
        buffer += [...new Array(width - PrintUtiliy.strLen(buffer, 2))]
          .map((a) => ' ')
          .join('')
      }
      this.addText(buffer, fonts[i] ?? styleText)
    })
    this.addRowFeed(rowFeed)
  }

  /**
   * 用於自訂收據單（CustomizedOrderReceipt）的分隔線選項
   * @returns
   */
  addStraightLine () {
    this.addAlign('left')
    const command = getImageCommand(straightLine(this.DOT_WIDTH), 'ESC K')
    this.writeNumbers(command)
    return this
  }

  addBeep () {
    this.writeNumbers([30])
    return this
  }

  // Enable to receive real-time printer status
  enableASB () {
    this.writeNumbers([27, 30, 97, 51])
    return this
  }

  addCutPaper () {
    this.addRowFeed(6)
    this.writeNumbers([27, 100, 0])
    return this
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @param {'thai'?} batchLocale
   * @returns
   */
  getItemMaxWidth (fontSize, batchLocale) {
    const thaiWidths = [70, 30, 16, 30, 16, 16]
    const widths = [30, 11, 6, 11, 6, 5]
    if (batchLocale === 'thai') {
      return thaiWidths[fontSize] ?? thaiWidths[0]
    }
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @returns
   */
  getPriceMaxWidth (fontSize) {
    const widths = [7, 6, 5, 6, 5, 3]
    return widths[fontSize] ?? widths[0]
  }

  /**
   *
   * @param {0 | 1 | 2 | 3 | 4 | 5} fontSize
   * @returns
   */
  getQuantityMaxWidth (fontSize) {
    const widths = [5, 4, 3, 4, 3, 2]
    return widths[fontSize] ?? widths[0]
  }

  /**
   * @deprecated 沒有使用
   * @returns
   */
  statusTransmission () {
    this.writeNumbers([16, 4, 1])
    return this
  }
}
