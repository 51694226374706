export default {
  // 更新資料
  UPDATE_TABLE: 'TABLE/UPDATE_TABLE',
  UPDATE_TABLES: 'TABLE/UPDATE_TABLES',
  UPDATE_TABLE_AREAS: 'TABLE/UPDATE_TABLE_AREAS',
  UPDATE_FLOORPLAN_AREAS: 'TABLE/UPDATE_FLOORPLAN_AREAS',
  UPDATE_GROUP_TABLES: 'TABLE/UPDATE_GROUP_TABLES',
  UPDATE_BOOKING: 'TABLE/UPDATE_BOOKING',
  DELETE_BOOKING: 'TABLE/DELETE_BOOKING',
  SET_CANCEL_ID: 'TABLE/SET_CANCEL_ID',

  // 選擇控制
  SELECT_TABLE_AREA: 'TABLE/SELECT_TABLE_AREA',
  SELECT_TABLE: 'TABLE/SELECT_TABLE',
  SELECT_BOOKING: 'TABLE/SELECT_BOOKING',
  SELECT_ORDER: 'TABLE/SELECT_ORDER',
  DESELECT_ORDER: 'TABLE/DESELECT_ORDER',

  // 切換mode
  UPDATE_SELECTING: 'TABLE/UPDATE_SELECTING',
  UPDATE_MOVING: 'TABLE/UPDATE_MOVING',
  UPDATE_SEARCHING: 'TABLE/UPDATE_SEARCHING',
  UPDATE_SEARCH_TEXT: 'TABLE/UPDATE_SEARCH_TEXT',
  UPDATE_OVERVIEW_EXPAND: 'TABLE/UPDATE_OVERVIEW_EXPAND',
  SHOW_DIALOG: 'TABLE/SHOW_DIALOG',
  CLOSE_DIALOG: 'TABLE/CLOSE_DIALOG',
  UPDATE_SELECTING_TABLE: 'TABLE/UPDATE_SELECTING_TABLE',

  // 重置
  RESET_ALL_ACTIONS: 'TABLE/RESET_ALL_ACTIONS',
}
