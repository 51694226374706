import { StyleSheet, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @typedef TableGroupInfoProps
 * @property {*} tableGroup
 * @property {() => void} onClose
 */

/**
 *
 * @param {TableGroupInfoProps} props
 * @returns
 */
export default function TableGroupInfo (props) {
  const { tableGroup, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  if (!tableGroup) return null

  const { tableGroupWithSurcharge } = useSelector(state => state.merchant.data)
  const tableGroupSetting = _.get(tableGroupWithSurcharge, tableGroup.key, {})
  const defaultSetting = {
    useSurcharge: tableGroupSetting.useSurcharge ?? false,
    surcharge: _.get(tableGroupSetting, 'surcharge.percent', 0),
    useDiscount: tableGroupSetting.useDiscount ?? false,
    discount: _.get(tableGroupSetting, 'discount.percent', 0),
  }
  const [setting, setSetting] = useImmer(defaultSetting)

  const handleSave = () => {
    onClose()
    dispatch(actions.merchant.updateTableGroup(tableGroup.key, setting))
  }

  return (
    <>
      <Header>
        <HeaderText text={tableGroup.text} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          <SettingListItem>
            <SettingListItemText text={t('app.constant.order.surcharge')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={setting.useSurcharge}
                onChangeValue={() => setSetting(draft => { draft.useSurcharge = !setting.useSurcharge })}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.constant.order.percentage')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={String(setting.surcharge)}
                disabled={!setting.useSurcharge}
                keyboardType='number-pad'
                onChangeText={(text) => {
                  if (/^\d+$/.test(text) || text === '') {
                    setSetting(draft => {
                      draft.surcharge = Number(text)
                    })
                  }
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.constant.order.discount')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={setting.useDiscount}
                onChangeValue={() => setSetting(draft => { draft.useDiscount = !setting.useDiscount })}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.constant.order.percentage')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={String(setting.discount)}
                disabled={!setting.useDiscount}
                keyboardType='number-pad'
                onChangeText={(text) => {
                  if (/^\d+$/.test(text) || text === '') {
                    setSetting(draft => {
                      draft.discount = Number(text)
                    })
                  }
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
      </View>

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
