import { Icon } from 'react-native-elements'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import React, { memo } from 'react'

/**
 * RightNavBar 右下的Database Icon
 * @returns
 */
export function LocalDatabaseIcon () {
  return (
    <View>
      <Icon
        size={35}
        name='storage'
        type='material'
        color={colors.green}
      />
      <LazyQueueNumber />
    </View>
  )
}

/**
 * 等候寫進Database 的訂單
 * @returns
 */
export function LazyQueueNumber () {
  const number = useSelector(state => state.app.localDBLazyQueuingNumber)

  if (number) {
    return (<Text style={styles.label}>L:{number}</Text>)
  } else return null
}

export default memo(LocalDatabaseIcon)

const styles = StyleSheet.create({
  label: {
    fontSize: 10,
    textAlign: 'center',
    color: colors.dotGray,
    elevation: 4,
  },
})
