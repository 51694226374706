import { FlatList } from 'react-native'
import NumberPadButton from './NumberPadButton'
import React from 'react'

/**
  * @param {NumberPadProps} props
 */
export default function NumberPad (props) {
  const {
    variant,
    style,
    contentContainerStyle,
    numColumns = 3,
    radius = 20,
    buttonSize,
    gap = 4,
    buttons,
    disabledTexts = {},
    onButtonPress,
  } = props

  const renderItem = ({ item: button }) => {
    return (
      <NumberPadButton
        key={button.text}
        variant={variant}
        button={button}
        size={buttonSize}
        gap={gap}
        onButtonPress={onButtonPress}
        disabled={disabledTexts[button.text]}
      />
    )
  }

  return (
    <FlatList
      keyExtractor={(item, index) => index.toString()}
      style={[
        {
          flexShrink: 0,
          flexGrow: 0,
        },
        style,
      ]}
      contentContainerStyle={[
        {
          borderRadius: radius,
          overflow: 'hidden',
        },
        contentContainerStyle,
      ]}
      numColumns={numColumns}
      data={buttons}
      renderItem={renderItem}
    />
  )
}
