import { ScrollView, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import Row from '@/components/Row'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @typedef AccountInfoProps
 * @property {*} selectedAccount
 * @property {() => void} setSelectedAccount
 * @property {boolean?} isCreate
 */

/**
 *
 * @param {AccountInfoProps} props
 * @returns
 */
export default function AccountInfo (props) {
  const {
    selectedAccount,
    setSelectedAccount,
    isCreate,
  } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const defaultScopes = [
    {
      scope: 'user',
      name: t('app.page.setting.AuthorizationPanel.scope.user'),
      status: false,
    },
    {
      scope: 'waiter-login',
      name: t('app.page.setting.AuthorizationPanel.scope.waiter-login'),
      status: false,
    },
    {
      scope: 'slave-login',
      name: t('app.page.setting.AuthorizationPanel.scope.slave-login'),
      status: false,
    },
    {
      scope: 'item-transfer',
      name: t('app.page.setting.AuthorizationPanel.scope.item-transfer'),
      status: false,
    },
    {
      scope: 'order-edit',
      name: t('app.page.setting.AuthorizationPanel.scope.order-edit'),
      status: false,
    },
    {
      scope: 'order-discount',
      name: t('app.page.setting.AuthorizationPanel.scope.order-discount'),
      status: false,
    },
    {
      scope: 'order-cancel',
      name: t('app.page.setting.AuthorizationPanel.scope.order-cancel'),
      status: false,
    },
    {
      scope: 'order-void',
      name: t('app.page.setting.AuthorizationPanel.scope.order-void'),
      status: false,
    },
    {
      scope: 'change-after-paid',
      name: t('app.page.setting.AuthorizationPanel.scope.change-after-paid'),
      status: false,
    },
    {
      scope: 'repay',
      name: t('app.page.setting.AuthorizationPanel.scope.updatePaymentMethod'),
      status: false,
    },
    {
      scope: 'stats',
      name: t('app.page.setting.AuthorizationPanel.scope.stats'),
      status: false,
    },
    {
      scope: 'menu',
      name: t('app.page.setting.AuthorizationPanel.scope.menu'),
      status: false,
    },
    {
      scope: 'set-menu-soldOut',
      name: t('app.page.setting.AuthorizationPanel.scope.setMenuSoldOut'),
      status: false,
    },
    {
      scope: 'printer-setting',
      name: t('app.page.setting.AuthorizationPanel.scope.printer-setting'),
      status: false,
    },
    {
      scope: 'takeaway-disable',
      name: t('app.page.setting.AuthorizationPanel.scope.takeaway-disable'),
      status: false,
    },
    {
      scope: 'drawer-kick',
      name: t('app.page.setting.AuthorizationPanel.scope.drawer-kick'),
      status: false,
    },
    {
      scope: 'merchant-staff-order',
      name: t('app.page.setting.AuthorizationPanel.scope.merchant-staff-order'),
      status: false,
    },
    {
      scope: 'order-takeaway',
      name: t('app.page.setting.AuthorizationPanel.scope.order-takeaway'),
      status: false,
    },
    {
      scope: 'draw-cash',
      name: t('app.page.setting.AuthorizationPanel.scope.draw-cash'),
      status: false,
    },
    {
      scope: 'checkout',
      name: t('app.page.setting.AuthorizationPanel.scope.checkout'),
      status: false,
    },
    {
      scope: 'opening',
      name: t('app.page.setting.AuthorizationPanel.scope.opening'),
      status: false,
    },
    {
      scope: 'merchant-portal',
      name: t('app.page.setting.AuthorizationPanel.scope.merchant-portal'),
      status: false,
    },
    {
      scope: 'merchant-portal-crm',
      name: t('app.page.setting.AuthorizationPanel.scope.merchant-portal-crm'),
      status: false,
    },
  ]
  const defaultState = { identifier: '', scopes: defaultScopes }
  const defaultPasswordState = { password: '', confirmPassword: '' }

  const users = useSelector(
    (state) => state.auth.merchantLogin.scopeCredentials,
  )
  const [userState, setUserState] = useState(defaultState)
  const [passwordState, setPasswordState] = useState(defaultPasswordState)
  if (!selectedAccount) return null

  React.useEffect(() => {
    if (selectedAccount) {
      const cloneScopes = _.cloneDeep(defaultScopes)
      _.forEach(cloneScopes, ({ scope }, key) => {
        cloneScopes[key].status = Boolean(
          _.includes(selectedAccount.scopes, scope),
        )
      })
      setUserState({
        identifier: selectedAccount.identifier,
        scopes: cloneScopes,
      })
    }
  }, [selectedAccount])

  const onChangeState = (key, val) => {
    setUserState((prevState) => {
      return _.set(_.assign({}, prevState), key, val)
    })
  }

  const onChangePassword = (key, val) => {
    setPasswordState((prevState) => _.set(_.assign({}, prevState), key, val))
  }

  const onSubmit = async () => {
    if (isCreate) {
      if (userState.identifier === '') {
        dispatch(
          actions.app.showSimpleAlert(
            t('app.common.error'),
            t('app.page.setting.AuthorizationPanel.accountInfo.error.name'),
          ),
        )
        return
      }
      const identifiers = _.map(users, 'identifier')
      if (_.indexOf(identifiers, userState.identifier) >= 0) {
        dispatch(
          actions.app.showSimpleAlert(
            t('app.common.error'),
            t('app.page.setting.AuthorizationPanel.accountInfo.error.exist'),
          ),
        )
        return
      }
    }
    if (
      isCreate ||
      passwordState.password !== '' ||
      passwordState.confirmPassword !== ''
    ) {
      if (passwordState.password.length !== 6) {
        dispatch(
          actions.app.showSimpleAlert(
            t('app.common.error'),
            t('app.page.setting.AuthorizationPanel.accountInfo.error.number'),
          ),
        )
        return
      }

      if (passwordState.password !== passwordState.confirmPassword) {
        dispatch(
          actions.app.showSimpleAlert(
            t('app.common.error'),
            t('app.page.setting.AuthorizationPanel.accountInfo.error.confirm'),
          ),
        )
        return
      }
    }

    // 若user未存在，建立新user
    if (isCreate) {
      dispatch(
        actions.auth.createUser(userState.identifier, passwordState.password),
      )
    }
    dispatch(
      actions.auth.updateUserScopeByUser(
        userState.identifier,
        userState.scopes,
        passwordState.password,
      ),
    )
    setPasswordState(defaultPasswordState)
    setSelectedAccount(null)
  }

  const onDeleteAccount = () => {
    dispatch(
      actions.app.showAlert({
        title: t(
          'app.page.setting.AuthorizationPanel.accountInfo.alert.deleteAccount.title',
        ),
        message: `${t(
          'app.page.setting.AuthorizationPanel.accountInfo.alert.deleteAccount.msg',
        )}${userState.identifier}?`,
        buttons: [
          {
            children: t('app.common.confirm'),
            onPress: () => {
              dispatch(actions.auth.deleteUser(userState.identifier))
              setSelectedAccount(null)
            },
          },
          {
            backgroundColor: colors.light,
            textColor: colors.textTertiary,
            children: t('app.common.cancel'),
            onPress: () => {},
          },
        ],
      }),
    )
  }

  const renderPermission = ({ item, index }) => {
    return (
      <SettingListItem divider>
        <SettingListItemText text={item.name} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={item.status}
            onChangeValue={() =>
              onChangeState(`scopes.${index}.status`, !item.status)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
    )
  }
  return (
    <Column style={styles.container}>
      <Column style={styles.content}>
        <Header>
          <HeaderText
            text={t('app.page.setting.AuthorizationPanel.accountInfo.info')}
          />
        </Header>
        <SettingList>
          <SettingListItem>
            <SettingListItemText
              text={t('app.page.setting.AuthorizationPanel.accountInfo.name')}
            />
            <SettingListItemRightAction>
              {/* 若user未存在，用name欄位讓使用者輸入，已存在的話只display */}
              {!isCreate ? (
                <SettingListItemText text={userState.identifier} />
              ) : (
                <SettingListInput
                  value={userState.identifier}
                  onChangeText={(text) => onChangeState('identifier', text)}
                />
              )}
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
        <Header>
          <HeaderText
            text={t('app.page.setting.AuthorizationPanel.accountInfo.password')}
          />
        </Header>
        <SettingList>
          <SettingListItem divider>
            <SettingListItemText
              text={t(
                'app.page.setting.AuthorizationPanel.accountInfo.6ditgits',
              )}
            />
            <SettingListItemRightAction>
              <SettingListInput
                value={passwordState.password}
                onChangeText={(text) => onChangePassword('password', text)}
                placeholder='000000'
                secureTextEntry
                maxLength={6}
                keyboardType='number-pad'
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText
              text={t(
                'app.page.setting.AuthorizationPanel.accountInfo.confirmPassword',
              )}
            />
            <SettingListItemRightAction>
              <SettingListInput
                value={passwordState.confirmPassword}
                onChangeText={(text) =>
                  onChangePassword('confirmPassword', text)}
                placeholder='000000'
                secureTextEntry
                maxLength={6}
                keyboardType='number-pad'
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
        <Header>
          <HeaderText
            text={t(
              'app.page.setting.AuthorizationPanel.accountInfo.login-authority',
            )}
          />
        </Header>
        <View style={styles.content}>
          <SettingList style={styles.content}>
            <ScrollView showsHorizontalScrollIndicator={false}>
              {userState.scopes?.map((scope, index) => (
                <SettingListItem divider key={index}>
                  <SettingListItemText text={scope.name} />
                  <SettingListItemRightAction>
                    <ToggleSwitch
                      value={scope.status}
                      onChangeValue={() =>
                        onChangeState(`scopes.${index}.status`, !scope.status)}
                      size={23}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              ))}
            </ScrollView>
          </SettingList>
        </View>
      </Column>
      <Row style={styles.buttons}>
        <Button
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          textBold
          onPress={onDeleteAccount}
          disabled={isCreate}
        />
        <Button
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          textBold
          onPress={onSubmit}
        />
      </Row>
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  buttons: {
    justifyContent: 'flex-end',
  },
  button: {
    marginBottom: 0,
  },
})
