import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

// eslint-disable-next-line no-unused-vars
import { IAppCategoryTag } from '@/redux/menu/MenuState.d'

/**
 * @typedef CategoryTagInfoProps
 * @property {IAppCategoryTag} categoryTag
 * @property {() => void} onClose
 */

/**
 *
 * @param {CategoryTagInfoProps} props
 * @returns
 */
export default function CategoryTagInfo (props) {
  const { categoryTag, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const categories = useSelector(state => state.menu.categories)
  const categoryOptions = _.map(categories, category => {
    if (category.id === 'ROOT' || category.id === 'SET_ROOT') {
      return null
    }
    return {
      value: category.id,
      display: category.name,
    }
  }).filter(o => o)

  const [categoryTagState, setCategoryTagState] = React.useState(categoryTag)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const selectedCategories = categoryTagState?.categoryIds.length > 0
    ? categoryTagState?.categoryIds.map(categoryId => {
      const category = categories[categoryId]
      return category?.name
    }).join(', ')
    : t('app.common.unselected')

  if (!categoryTag) return null

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.categoryTagPanel.alert.delete.title'),
      message: `${t('app.page.setting.categoryTagPanel.alert.delete.msg')}${categoryTag.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            dispatch(actions.menu.deleteCategoryTag(categoryTag))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    if (categoryTagState.id) {
      dispatch(actions.menu.updateCategoryTag({ ...categoryTagState, name: replaceSaveSpace(categoryTagState.name) }))
    } else {
      if (categoryTagState.name !== '') {
        dispatch(actions.menu.createCategoryTag({ ...categoryTagState, name: replaceSaveSpace(categoryTagState.name) }))
      }
    }
  }

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  return (
    <>
      <View style={styles.content}>
        <Header>
          <HeaderText text={categoryTag.id ? t('app.page.setting.categoryTagPanel.setting') : t('app.page.setting.categoryTagPanel.add')} />
        </Header>
        <SettingList>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.categoryTagPanel.name')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={replaceSpace(categoryTagState.name)}
                onChangeText={(text) => {
                  setCategoryTagState({
                    ...categoryTagState,
                    name: text,
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.categoryTagPanel.cutoff')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={String(categoryTagState.orderCutOffMins)}
                onChangeText={(text) => {
                  setCategoryTagState({
                    ...categoryTagState,
                    orderCutOffMins: Number(text) || 0,
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListEnterRow
            label={t('app.page.setting.categoryTagPanel.category')}
            value={_.get(categoryTagState, 'categoryIds.length') ?? ''}
            onPress={() => {
              setOptionsPanelConfig({
                title: t('app.page.setting.categoryTagPanel.category'),
                multiple: true,
                defaultValue: _.get(categoryTagState, 'categoryIds') ?? '',
                onChangeValue: (value) => {
                  setCategoryTagState({ ...categoryTagState, categoryIds: value })
                },
                options: categoryOptions,
              })
            }}
          />
          <SettingListItem>
            <SettingListItemRightAction>
              <SettingListItemText
                style={styles.rightText}
                text={selectedCategories}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
      </View>

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          disabled={!categoryTag.id}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  checkboxLabel: {
    fontSize: 14,
    color: colors.textSecondary,
  },
  addIcon: {
    width: 25,
    height: 25,
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
  },
  rightText: {
    justifyContent: 'flex-end',
  },
})
