import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import CancelledItemSummaryRow from './CancelledItemSummaryRow'
import SummaryRow from './SummaryRow'

export default function CashRequest ({ data }) {
  const { t } = useTranslation()
  const summaryData = useSelector(state => state.statistics.data.summary)
  const cashSummary = _.find(summaryData?.sections, section => section.type === 'cash')
  const reasonText = t('app.page.setting.checkout.reason')
  const totalText = t('app.page.stats.department.total')

  const renderCash = (item) => {
    return (
      <SummaryRow
        title={'app.page.stats.newSummary.' + item.type}
        count=''
        amount={item.amount}
      />
    )
  }

  const renderItem = ({ item }) => {
    let typeText = ''
    if (item.type === 'in') {
      typeText = t('app.page.setting.checkout.payIn')
    } else if (item.type === 'out') {
      typeText = t('app.page.setting.checkout.payOut')
    }
    return (
      <CancelledItemSummaryRow
        title={mapReasonKey(item.reason)}
        serial={moment(item.createdAt).format('DD/MM HH:mm')}
        count={typeText}
        staff={currencyWithCommas(item.amount)}
      />
    )
  }

  const mapReasonKey = (key) => {
    switch (key) {
      case 'food': {
        return t('app.page.setting.payInOut.food')
      }
      case 'kitchenware': {
        return t('app.page.setting.payInOut.kitchenware')
      }
      case 'tableware': {
        return t('app.page.setting.payInOut.tableware')
      }
      case 'cleaningSupplies': {
        return t('app.page.setting.payInOut.cleaningSupplies')
      }
      case 'stationery': {
        return t('app.page.setting.payInOut.stationery')
      }
      default: {
        return key
      }
    }
  }

  if (_.isEmpty(data)) {
    return null
  }

  return (
    <View style={styles.container}>
      <View style={{ height: 25, marginTop: 30 }}>
        <Text style={styles.title}>{t('app.page.stats.newSummary.total')}</Text>
        <View style={styles.divider} />
      </View>
      {
        _.map(cashSummary?.data?.cash, cash => renderCash(cash))
      }
      <SummaryRow
        title={t('app.page.stats.newSummary.total')}
        count=''
        amount={_.get(cashSummary, 'data.total', 0)}
        textStyle={{ color: '#EE7B06' }}
      />
      <View style={{ height: 25, marginTop: 30 }}>
        <Text style={styles.title}>{t('app.page.stats.list.cashRequest')}</Text>
        <View style={styles.divider} />
      </View>
      <CancelledItemSummaryRow
        title={reasonText}
        serial={t('app.component.dateTimeDialog.date')}
        count={t('app.page.setting.printer.customizedSetting.type')}
        staff={totalText}
      />
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(item + index)}
        showsVerticalScrollIndicator={false}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    flex: 1,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})
