import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { useSelectedBatch, useSelectedBatchItem, useSelectedOrder } from '@/hooks/order'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import NumPadDialog from '@/components/dialogs/NumPadDialog'
import RightNavBar from '@/components/RightNavBar'

import BatchStashesDialog from './BatchStashesDialog'
import BottomActions from './BottomActions'
import Header from './Header'
import NumberInputBar from './NumberInputBar'
import OptionsPanel from './OptionsPanel'
import OrderBatch from './OrderBatch'
import OrderMenu from './OrderMenu'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Order (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedOrder = useSelectedOrder()
  const selectedBatch = useSelectedBatch()
  const visible = useSelector(state => state.app.dialog.order.inputPrice)
  const [selectedItem, selectedSetItem] = useSelectedBatchItem()
  const targetItem = selectedSetItem || selectedItem
  const merchant = useSelector(state => state.merchant.data)
  const isPictureMode = merchant?.setting?.orderPictureMode

  React.useEffect(() => {
    if (selectedOrder == null) {
      dispatch(actions.order.createLocalOrder())
    } else if (selectedBatch == null) {
      dispatch(actions.order.startOrder())
    } else if (selectedBatch?.items?.length === 0) {
      dispatch(actions.menu.updatePictureMenuMode(isPictureMode))
    }
  }, [selectedOrder?.id, selectedBatch?.id])

  return (
    <View style={styles.container}>
      <OptionsPanel />
      <View style={styles.content}>
        {
          selectedOrder && selectedBatch
            ? (
              <>
                <Header />
                <OrderMenu />
                <OrderBatch />
                <BottomActions />
              </>
            )
            : null
        }
      </View>
      <NumberInputBar />
      <RightNavBar />
      <BatchStashesDialog />
      {
        targetItem && (
          <NumPadDialog
            open={visible}
            onClose={() => {
              dispatch(actions.app.closeDialog(['order', 'inputPrice']))
              dispatch(actions.orderBatch.deleteItem(targetItem.key))
            }}
            title={t('app.component.priceInputDialog.title')}
            enablePressOutsideClose
            defaultValue={0}
            minAmount={0.1}
            onSubmit={(price) => {
              dispatch(actions.orderBatch.updatePrice(Number(price)))
              dispatch(actions.app.closeDialog(['order', 'inputPrice']))
            }}
          />
        )
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    backgroundColor: colors.light,
    ...shadows.default,
  },
})
