import { StyleSheet, View } from 'react-native'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'
import i18n from '@/i18n'

import { actions, useDispatch, useSelector } from '@/redux'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useTranslation } from 'react-i18next'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'

export default function SetList (props) {
  const {
    draggable,
    style,
    categoryId,
    sets,
    selectedSetItem,
    setSelectedSetItem,
    addSet,
    disableBottomButton = false,
  } = props

  const readOnly = categoryId === 'promote' || categoryId === 'soldOut'
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.menu.isMenuLoading)
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const [categorySets, setCategorySets] = React.useState(sets)

  React.useEffect(() => {
    setCategorySets(sets)
  }, [sets])

  const renderSetItem = ({ item, index, drag, isActive }) => {
    const isSelected = item.id === selectedSetItem?.id
    const priceText = item.priceUndetermined ? t('app.constant.order.priceUndetermined') : `${currencyWithCommas(item.price)}`

    return (
      <SettingListItem
        key={item.id}
        divider
        selected={isSelected}
        dragging={isActive}
        draggable={draggable}
        onDrag={drag}
        onPress={() => { setSelectedSetItem(item) }}
      >
        <View>
          <SettingListItemText
            text={isLoading ? '' : item.code}
            style={{ marginRight: 0 }}
          />
          <SettingListItemText
            text={isLoading ? '...' : _.get(item?.localeNames, lang) ?? item.name}
            style={styles.itemText}
            // wrap
          />
        </View>
        <SettingListItemText
          text={isLoading ? '...' : priceText}
          style={styles.priceText}
        />
      </SettingListItem>
    )
  }

  return (
    <SettingList
      shadow
      style={style}
      title={t('app.page.setting.menuPanel.set.setItem')}
    >
      <DraggableFlatList
        containerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        data={categorySets}
        extraData={selectedSetItem}
        keyExtractor={item => item.id}
        renderItem={renderSetItem}
        onDragEnd={({ data }) => {
          if (!draggable) return
          const originalIds = sets.map(s => s.id)
          const setIds = data.map(s => {
            return s.id
          })
          if (_.isEqual(originalIds, setIds)) return
          dispatch(actions.menu.updateSetOrder(categoryId, setIds, data))
          setCategorySets(data)
        }}
      />
      {!readOnly && (
        <SettingListFooterAddButton
          text={t('app.page.setting.menuPanel.set.addSet')}
          style={styles.addButton}
          onPress={addSet}
          disabled={disableBottomButton}
        />
      )}
    </SettingList>
  )
}

const styles = StyleSheet.create({
  itemText: {
    flex: 1,
  },
  priceText: {
    width: '25%',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  addButton: {
    width: 'auto',
    marginLeft: 0,
  },
})
