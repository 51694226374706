import { Image, StyleSheet, View } from 'react-native'
import React from 'react'

export default function OrderImage ({ iconSource, backgroundColor, style }) {
  return (
    <View style={[styles.container, { backgroundColor }]}>
      <Image
        source={iconSource}
        style={style}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 65,
    width: 65,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
  },
})
