import { Image, StyleSheet, Text, View } from 'react-native'
import { numberWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
import orderAppLib from 'dimorder-orderapp-lib'

// eslint-disable-next-line no-unused-vars
import { ColumnProps } from '@/pages/Order/OrderBatch/BatchList/index.d'

const expandIcons = {
  true: require('@icons/collapse-triangle.png'),
  false: require('@icons/expand-triangle.png'),
}
const widths = [40, 125, 170 + 45, 45 + 45, 60, 50]
const totalWidths = _.sumBy(widths)
const widthPercents = widths.map(width => (100 * width / totalWidths) + '%')
const justifyContents = [
  'center',
  'flex-start',
  'flex-start',
  'flex-end',
  'center',
  'flex-end',
  'flex-end',
  'flex-end',
]

/**
 * @param {ColumnProps} props
 */
export default function Column (props) {
  const {
    index,
    header,
    setItem,
    expanded,
    selected,
    input,
    width,
    value,
    round,
    style,
    textStyle,
  } = props

  const columnStyle = {
    width: width ? (100 * width / totalWidths) + '%' : widthPercents[index],
    justifyContent: justifyContents[index],
    paddingRight: index === 7 ? 14 : 6,
  }

  const valueStyle = {
    fontSize: header ? 12 : 13,
    color: header ? colors.white : (!selected && setItem) ? colors.textSecondary : colors.textPrimary,
    fontWeight: header ? 'normal' : (setItem || index === 2 ? 'normal' : 'bold'),
  }
  const rounding = useSelector(state => state.merchant.data.rounding)

  let displayValue = value
  if (typeof value === 'number') {
    if (round) {
      displayValue = orderAppLib.libs.getDisplayRoundNumber(value, rounding)
    }
    displayValue = numberWithCommas(displayValue)
  }

  return (
    <View style={[styles.column, columnStyle, style]}>
      <Text style={[valueStyle, input && styles.input, textStyle]}>{displayValue || ''}</Text>
      {/* <Text style={[valueStyle, input && styles.input, textStyle]}>
        {displayValue ? String(displayValue) : ''}
  </Text> */}
      {expanded !== undefined && (
        <Image style={styles.expandIcon} source={expandIcons[expanded]} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  column: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 6,
    minHeight: 28,
  },
  input: {
    flex: 1,
    color: colors.textPrimary,
    backgroundColor: colors.white,
    textAlign: 'center',
  },
  expandIcon: {
    width: 8,
    height: 7,
    position: 'absolute',
    right: 0,
  },
})
