/* global __DEV__ */
import { DevSettings, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { AsyncStorageKey } from '@/constants/AsyncStorageKey'
import { actions } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import codePush from '@/libs/codePush'
import colors from '@/theme/colors'
import configs from '@/configs'
import logger, { setEnv } from '@/libs/logger'
import sentry from '@/libs/sentry'

import ActionTypes from './ActionTypes'

import * as OrderLocalDatabase from '@/libs/orderLocalDatabase'

const envMap = {
  test: 'test',
  development: 'test',
  beta: 'beta',
  prod: 'production',
  production: 'production',
}

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    const OVERWRITE_ENV = await AsyncStorage.getItem(AsyncStorageKey.OVERWRITE_ENV)
    const env = OVERWRITE_ENV || process.env.REACT_APP_ENV || process.env.NODE_ENV
    const appEnv = envMap[env] || 'test'

    console.log(`NODE_ENV=${process.env.NODE_ENV}`)
    console.log(`OVERWRITE_ENV=${OVERWRITE_ENV}`)
    console.log('use env', appEnv)

    sentry.setContext('state', {
      NODE_ENV: process.env.NODE_ENV,
      OVERWRITE_ENV: process.env.OVERWRITE_ENV,
      ENV: appEnv,
    })

    const envConfigs = configs[appEnv] || configs.default

    if (envConfigs && envConfigs.apiUrls) {
      // 設定 api url
      dimorderApi.setUrl(envConfigs.apiUrls.go, envConfigs.apiUrls.node)
    }

    // 設定 logger environment
    setEnv(appEnv)

    dispatch({
      type: ActionTypes.UPDATE_CONFIGS,
      payload: {
        env: appEnv,
        configs: envConfigs,
      },
    })
  }
}

/**
 * @param {TEnv} env
 * @returns {ThunkFunction}
 */
export function overwriteEnv (env) {
  return async (dispatch, getState) => {
    const appEnv = getState().config.env
    const overwriteDeployment = getState().codePush.overwriteDeployment
    if (appEnv === env) {
      // env 未改變
      return
    }

    dispatch(actions.app.showAlert({
      title: '切換環境',
      message: '若繼續將登出並重新啟動 APP',
      buttons: [
        {
          children: '繼續',
          onPress: async () => {
            logger.warn(`[OverwriteEnv] overwrite env: ${appEnv} -> ${env}`)
            // 清除 storage
            await AsyncStorage.clear()
            // 清除 Local Database 內的訂單
            await OrderLocalDatabase.clearOrders()
            // 設定回必要的 AsyncStorage
            await AsyncStorage.setItem(AsyncStorageKey.OVERWRITE_ENV, env)
            if (overwriteDeployment) { await AsyncStorage.setItem(AsyncStorageKey.OVERWRITE_DEPLOYMENT, overwriteDeployment) }

            // 重新啟動
            if (Platform.OS === 'web') {
              window.location.href = '/'
            } else {
              if (__DEV__) {
                DevSettings.reload('OVERWRITE_ENV')
              } else {
                codePush.restartApp()
              }
            }
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: '取消',
          onPress: () => {},
        },
      ],
    }))
  }
}
