import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'

import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 * @typedef DepartmentInfoProps
 * @property {IStatDepartmentData} department
 * @property {() => void} onClose
 */

/**
 *
 * @param {DepartmentInfoProps} props
 * @returns
 */
export default function DepartmentInfo (props) {
  const { department, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedDepartment, setSelectedDepartment] = React.useState(department)

  useEffect(() => {
    setSelectedDepartment(department)
  }, [department])

  if (!department) return null
  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.department.delete'),
      message: `${t('app.common.is') + t('app.common.delete') + department.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            dispatch(actions.menu.deleteKitchenDepartments(department.id))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    onClose()
    dispatch(actions.menu.upsertKitchenDepartments(selectedDepartment))
  }

  return (
    <>
      <Header>
        <HeaderText text={department.id ? t('app.page.setting.department.edit') : t('app.page.setting.department.add')} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.department.name')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={selectedDepartment.name}
                onChangeText={(text) => {
                  setSelectedDepartment({
                    ...selectedDepartment,
                    name: text,
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
      </View>

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          disabled={!department.id}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
