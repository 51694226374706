import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { color } from 'react-native-elements/dist/helpers'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '@/theme/colors'

SelectedOptionButton.propTypes = {
  variant: PropTypes.string,
  selected: PropTypes.bool,
  text: PropTypes.string,
  onPress: PropTypes.func,
}

export default function SelectedOptionButton (props) {
  const { variant = 'option', selected, text, onPress, removeable } = props
  const styleName = variant + (selected ? 'Selected' : '')
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.container, styles[styleName]]}>
        {removeable && (
          <View style={styles.removeContainer}>
            <Text style={styles.remove}>X</Text>
          </View>
        )}
        {text && (
          <Text
            numberOfLines={2}
            ellipsizeMode='tail'
            style={[styles.text]}
          >
            {text}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    minWidth: 80,
    height: 38,
    // flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 6,
    borderWidth: 1.3,
    paddingLeft: 15,
    paddingRight: 30,
    borderRadius: 20,
    borderColor: colors.white,
    backgroundColor: colors.white,
  },
  removeContainer: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: 8,
    right: 5,
    alignItems: 'center',
  },
  remove: {
    color: colors.primary,
  },
  option: {
    borderColor: colors.white,
    backgroundColor: colors.primary,
  },
  optionText: {
    color: colors.white,
  },
  tag: {
    borderColor: colors.primary,
  },
  tagText: {
    color: colors.primary,
  },
  text: {
    flexShrink: 1,
    flexWrap: 'wrap',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
    color: colors.primary,
  },
})
