import { StyleSheet, Text, View } from 'react-native'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { getPaymentMethod } from '@/libs/merchant'

import { useTranslation } from 'react-i18next'
import CenterModal from '@/components/CenterModal'
import React from 'react'
import colors from '@/theme/colors'

// eslint-disable-next-line no-unused-vars
import { IPayment } from 'dimorder-orderapp-lib/dist/types/Merchant'

/**
 * @typedef PaymentDetailDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {IPayment} payment
 */

/**
 *
 * @param {PaymentDetailDialogProps} props
 * @returns
 */
export default function PaymentDetailDialog (props) {
  const { open, onClose, payment } = props
  const { t } = useTranslation()
  const amount = Number(payment?.amount ?? 0)
  const tips = Number(payment?.paymentTips ?? 0)
  const change = Number(payment?.change ?? 0)
  const paymentMethod = payment?.paymentMethod ? getPaymentMethod(payment.paymentMethod).name : ''

  return (
    <CenterModal
      enablePressOutsideClose
      title={t('app.component.paymentDetailDialog.title')}
      visible={open}
      contentContainerStyle={styles.contentContainer}
      onClose={onClose}
    >
      <View style={styles.summary}>
        <View style={styles.summaryLeft}>
          <Text style={styles.summaryText}>
            {t('app.component.paymentDetailDialog.paymentMethod')}
          </Text>
          <Text style={styles.summaryText}>
            {t('app.component.paymentDetailDialog.paidAmount')}
          </Text>
          <Text style={styles.summaryText}>
            {t('app.component.paymentDetailDialog.change')}
          </Text>
          <Text style={styles.summaryText}>
            {t('app.component.paymentDetailDialog.tips')}
          </Text>
        </View>
        <View style={styles.summaryRight}>
          <Text style={styles.summaryText}>
            {t(paymentMethod)}
          </Text>
          <Text style={styles.summaryText}>
            {currencyWithCommas(amount)}
          </Text>
          <Text style={styles.summaryText}>
            {currencyWithCommas(change)}
          </Text>
          <Text style={styles.summaryText}>
            {currencyWithCommas(tips)}
          </Text>
        </View>
      </View>

    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 400,
    padding: 0,
    backgroundColor: colors.light,
  },
  summary: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  summaryLeft: {
    flex: 1,
  },
  summaryRight: {
    alignItems: 'flex-end',
  },
  summaryText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: colors.textPrimary,
    lineHeight: 30,
  },
  paymentList: {
    maxHeight: 500,
    width: '100%',
  },
  paymentRow: {
    minHeight: 40,
  },
  button: {
    height: '100%',
    width: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.secondary,
  },
})
