import * as Sentry from '@sentry/react'

/* global __DEV__ */
if (!__DEV__) {
  Sentry.init({
    // dsn: 'https://562bc0734616474e8c2bfb0c544ea8b0@sentry.dimorder.com/5',
    dsn: 'https://acc0e12076bc41778a548020290f44e7@o951143.ingest.sentry.io/5900514',
  })
}

export default Sentry
