import { FlatList, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useHistory } from '@/libs/reactRouter'
import colors from '@/theme/colors'

import Column from '@/components/Column'
import EditButton from '@/components/buttons/EditButton'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import Row from '@/components/Row'
import SettingCard from '@/pages/Setting/components/SettingCard'
import SettingImage from '@/components/Setting/SettingImage'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemEnter from '@/components/Setting/SettingListItemEnter'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AboutPanel (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  // const { tags, 預約 } = useSelector(state => state.setting)
  const { name, desc, contact, address, images, setting, tables } = useSelector(state => state.merchant.data)
  const menus = useSelector(state => state.menu.menus)
  // const 座位數目 = tables?.length
  // const 包廂數目 = 0
  const 菜色數目 = _.size(menus)
  let min
  let max
  let 價位
  if (菜色數目 > 0) {
    min = _.reduce(menus, (min, current) => !current.priceUndetermined && current.price < min ? current.price : min, Object.values(menus)[0].price)
    max = _.reduce(menus, (max, current) => !current.priceUndetermined && current.price > max ? current.price : max, Object.values(menus)[0].price)
    價位 = `${min}~${max}`
  } else {
    價位 = t('app.common.null')
  }
  const mode = setting?.quickMode ? t('app.page.setting.aboutPanel.quickMode') : t('app.page.setting.aboutPanel.normalMode')
  const method = setting?.payFirst ? t('app.page.setting.aboutPanel.payFirst') : t('app.page.setting.aboutPanel.payAfter')

  const settings = [
    { value: _.size(tables), displayText: t('app.page.setting.aboutPanel.tables') },
    { value: mode, displayText: t('app.page.setting.aboutPanel.mode') },
    { value: _.size(menus), displayText: t('app.page.setting.aboutPanel.menus') },
    { value: method, displayText: t('app.page.setting.aboutPanel.method') },
  ]

  const renderSettingCard = ({ item: setting, index }) => {
    return (
      <SettingCard
        style={[styles.item, styles.card]}
        setting={setting}
        valueTextStyle={styles.valueText}
      />)
  }

  return (
    <Column style={styles.container}>
      <Header style={{ justifyContent: 'space-between' }}>
        <HeaderText size='large' text={name} />
        {/* {tags.map((tag, index) => <Tag key={index} text={tag} />)} */}
        <EditButton onPress={() => history.push('/setting/general/about/detail')} />
      </Header>

      <Row>
        <SettingImage
          source={images.info}
          notFoundText={t('app.page.setting.aboutPanel.introImage-null')}
          style={styles.item}
        />
        <FlatList
          data={settings}
          renderItem={renderSettingCard}
          numColumns={2}
          contentContainerStyle={styles.cards}
          keyExtractor={(item, index) => String(index)}
        />
      </Row>

      <SettingList style={[styles.item, styles.desc]}>
        <SettingListItem>
          <SettingListItemText text={t('app.page.setting.aboutPanel.editAbout.intro')} textStyle={styles.text} />
        </SettingListItem>
        <SettingListItem>
          <SettingListItemText text={desc} wrap />
        </SettingListItem>
      </SettingList>

      <Row>
        <SettingList style={[styles.item, styles.info]}>
          <SettingListItem
            divider onPress={() =>
              dispatch(actions.auth.permissionCheck('opening',
                () => {
                  history.push('/setting/general/about/opening')
                },
              ))}
          >
            <SettingListItemText text={t('app.page.setting.aboutPanel.opening.openingHour')} textStyle={styles.text} />
            <SettingListItemEnter />
          </SettingListItem>
          {/* <SettingListItem divider>
            <SettingListItemText text='預約' textStyle={styles.text}/>
            <SettingListItemRightAction>
              <ToggleSwitch
                value={預約}
                onChangeValue={() => {}}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem> */}
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.aboutPanel.priceRange')} textStyle={styles.text} />
            <SettingListItemRightAction>
              <SettingListItemText text={價位} />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.aboutPanel.editAbout.phone')} textStyle={styles.text} />
            <SettingListItemRightAction>
              <SettingListItemText text={contact} />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.aboutPanel.editAbout.address')} textStyle={styles.text} />
            <SettingListItemRightAction>
              <SettingListItemText text={address} />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
        <SettingImage
          source={images.cover}
          notFoundText={t('app.page.setting.aboutPanel.frontPageImage-null')}
          style={styles.item}
        />
      </Row>

    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  item: {
    margin: 10,
  },
  cards: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    height: 100,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 5,
    paddingTop: 15,
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
  valueText: {
    fontSize: 30,
    fontWeight: '500',
    color: colors.gray,
  },
  desc: {
    flex: 1,
    minHeight: 105,
  },
  info: {
    flex: 1,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
  },
})
