import _ from 'lodash'

export function hexToBinary (hex) {
  return parseInt(hex, 16).toString(2).padStart(4, '0')
}

export function binaryToBits (binary) {
  return binary.join('').match(/.{1}/g)
}

export function bitsToDec (bits) {
  const data = []
  let binary = ''
  _.each(bits, bit => {
    binary += String(bit)
    if (binary.length === 8) {
      data.push(parseInt(binary, 2))
      binary = ''
    }
  })
  return data
}

export function textToUnicode (text = '') {
  const unicode = []
  const string = text.normalize('NFKC')
  _.each(string, (char, index) => {
    unicode.push(string.charCodeAt(index).toString(16).padStart(4, '0').toUpperCase())
  })
  return unicode
}

export default {
  hexToBinary,
  binaryToBits,
  bitsToDec,
  textToUnicode,
}
