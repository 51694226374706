import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import React from 'react'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'
import _ from 'lodash'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

const separatorStyleOptions = [
  { value: 'dotted', display: '-----' },
  { value: 'doubleDotted', display: '=====' },
  { value: 'straight', display: '_____' },
]

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * }} props
 */
export default function ContentSetting (props) {
  const { setting, onChange } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]
  const renderItem = ({ item, index, drag, isActive }) => {
    return (
      <TouchableWithoutFeedback
        onLongPress={drag}
        onPress={() => {
        }}
      >
        <View style={[
          styles.button,
          isActive && shadows.slight,
        ]}
        >
          <Text>{t('app.page.setting.printer.customizedSetting.' + item)}</Text>
          <View style={styles.divider} />
        </View>
      </TouchableWithoutFeedback>
    )
  }
  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  return (
    <>
      <DraggableFlatList
        showsVerticalScrollIndicator={false}
        data={setting.format}
        horizontal
        keyExtractor={(item, index) => String(item + index)}
        renderItem={renderItem}
        onDragEnd={({ data }) => onChange('format', data)}
      />
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.enableTitle')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.title?.enable}
            onChangeValue={() => onChange('title.enable', !setting.title?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.titleSeparator')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.title?.separator}
            onChangeValue={() => onChange('title.separator', !setting.title?.separator)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListEnterRow
        divider
        label={t('app.page.setting.printer.customizedSetting.separator')}
        value={separatorStyleOptions.find(t => t.value === setting.title?.separatorStyle)?.display ||
                  setting.title?.separatorStyle}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.separator'),
            defaultValue: setting.title?.separatorStyle || 0,
            onChangeValue: (value) => onChange('title.separatorStyle', value),
            options: separatorStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.showSetName')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings?.showSetName}
            onChangeValue={() => onChange('settings.showSetName', !setting.settings?.showSetName)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.setTotalIncludeItems')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={_.get(setting.settings, 'setTotalIncludeItems', true)}
            onChangeValue={() => {
              onChange('settings.setTotalIncludeItems', !_.get(setting.settings, 'setTotalIncludeItems', true))
              if (_.get(setting.settings, 'setTotalIncludeItems', true)) {
                onChange('settings.showSetItemsPrice', true)
              }
            }}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.showFreeSetItem')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings?.showFreeSetItem}
            onChangeValue={() => onChange('settings.showFreeSetItem', !setting.settings?.showFreeSetItem)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.showSetItemsPrice')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings?.showSetItemsPrice}
            onChangeValue={() => {
              onChange('settings.showSetItemsPrice', !setting.settings?.showSetItemsPrice)
              if (setting.settings?.showSetItemsPrice) {
                onChange('settings.setTotalIncludeItems', true)
              }
            }}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.showOptionPrice')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings?.showOptionPrice}
            onChangeValue={() => onChange('settings.showOptionPrice', !setting.settings?.showOptionPrice)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.hideFreeItem')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings?.hideFreeItem}
            onChangeValue={() => onChange('settings.hideFreeItem', !setting.settings?.hideFreeItem)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.leading')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings?.leading}
            onChangeValue={() => onChange('settings.leading', !setting.settings?.leading)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListEnterRow
        divider
        label={t('app.page.setting.printer.customizedSetting.itemFontSize')}
        value={textStyleOptions.find(t => t.value === setting.fontSize)?.display ||
                  setting.fontSize}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.itemFontSize'),
            defaultValue: setting.fontSize || 0,
            onChangeValue: (value) => onChange('fontSize', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        divider
        label={t('app.page.setting.printer.customizedSetting.optionFontSize')}
        value={textStyleOptions.find(t => t.value === setting.settings?.optionFontSize)?.display ||
                  setting.settings?.optionFontSize}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.optionFontSize'),
            defaultValue: setting.settings?.optionFontSize || 0,
            onChangeValue: (value) => onChange('settings.optionFontSize', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        label={t('app.page.setting.printer.customizedSetting.remarkFontSize')}
        value={textStyleOptions.find(t => t.value === setting.settings?.remarkFontSize)?.display ||
                  setting.settings?.remarkFontSize}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.remarkFontSize'),
            defaultValue: setting.settings?.remarkFontSize || 0,
            onChangeValue: (value) => onChange('settings.remarkFontSize', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
    </>
  )
}
const styles = StyleSheet.create({
  button: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
    padding: 10,
    width: 135,
    alignItems: 'center',
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.lightGray3,
  },
})
