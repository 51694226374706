import _ from 'lodash'
import moment from 'moment'
import shortid from 'shortid'

import { PrintStyle } from '@/constants/printing'
import { PrinterFontSize } from '@/constants/printer'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { getPaymentMethod } from '@/libs/merchant'
import PrintDoc from '@/libs/printing/PrintDoc'
// import PrintUtiliy from '@/libs/printing/PrintUtiliy'
import i18n from '@/i18n'
import store from '@/redux/store'

/** @type {() => IRootState} */
const getState = store.getState
export default class Report extends PrintDoc {
  TYPE = PrintDoc.Types.REPORT

  constructor (data, type, subType, merchant) {
    super()
    this.data = data
    this.TYPE = type
    this.subType = subType
    this.merchant = merchant
    this.printId = shortid.generate()
    this.printerSetting = getState().printer.printerSetting.defaultSettings
  }

  print = () => {
    switch (this.TYPE) {
      case 'dimorder':
      case 'summary': {
        const title = i18n.t('app.printing.summary')
        this.addReportHeader(title)
        this.printSummary(this.data.summary.sections, this.data.tableGroupSales.data)
        break
      }
      case 'category': {
        const title = i18n.t('app.printing.category')
        this.addReportHeader(title)
        this.printProductCategoryReport(this.data?.category)
        break
      }
      case 'option': {
        const title = i18n.t('app.printing.productOptionReport')
        this.addReportHeader(title)
        this.printProductOptionReport()
        break
      }
      case 'cancel': {
        const data = this.data?.cancel
        if (this.subType === 'orders') {
          const paidOrder = _.filter(data.order, ['paymentStatus', 'paid'])
          const unpaidOrder = _.filter(data.order, ['paymentStatus', 'unpaid'])
          this.addReportHeader(`${i18n.t('app.printing.cancel')} (${i18n.t('app.component.paymentStatus.unpaid')})`)
          this.printCancelled(unpaidOrder)
          this.addReportHeader(`${i18n.t('app.printing.cancel')} (${i18n.t('app.component.paymentStatus.paid')})`)
          this.printCancelled(paidOrder)
        }
        if (this.subType === 'items') {
          this.addReportHeader(i18n.t('app.page.stats.cancel.cancelledItem'))
          this.printCategory(true, data?.category)
        }
        break
      }
      case 'department': {
        const title = i18n.t('app.printing.department')
        this.addReportHeader(title)
        this.printDepartment()
        break
      }
      case 'cash': {
        const title = i18n.t('app.printing.payInOut')
        this.addReportHeader(title)
        this.printCashRequest()
        break
      }
    }

    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  sortOrders = (serials) => {
    serials = serials.filter(i => i)
    serials = _.orderBy(serials, [
      (orderNumber) => {
        return Number(orderNumber.replace('-', ''))
      },
    ], ['asc'])
    return { start: serials[0], end: serials[serials.length - 1] }
  }

  addReportHeader = (title) => {
    const { setting: { hideTimeInReport } } = this.merchant
    const printer = this.printer
    const datetime = getState().statistics.datetime

    printer.addInit()
    printer.addCodePage()
    printer.addAlign('center')
    printer.addText(this.merchant.name, PrinterFontSize.MEDIUM).addRowFeed(2)
    printer.addText(this.merchant.address, PrinterFontSize.SMALL).addRowFeed(2)
    printer.addText(title, PrinterFontSize.SMALL).addRowFeed()

    printer.addAlign('left')
    printer.addUnderline()
    if (!hideTimeInReport) {
    }
    const timeFormat = hideTimeInReport ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
    printer.addText(`${i18n.t('app.printing.start')}: ${moment(datetime.from).format(timeFormat)}`, PrinterFontSize.SMALL).addRowFeed()
    printer.addText(`${i18n.t('app.printing.end')}: ${moment(datetime.to).format(timeFormat)}`, PrinterFontSize.SMALL).addRowFeed()
    printer.addText(`${i18n.t('app.printing.qrcode.time')}: ${moment().format('YYYY-MM-DD HH:mm:ss')}`, PrinterFontSize.SMALL).addRowFeed()
    printer.addUnderline()
  }

  printSummary = (summarySectionsData, tableGroupSalesData) => {
    const printer = this.printer
    const { quickMode: enableQuickMode } = this.merchant.setting ?? {}

    const summaryTitles = [
      'sales',
      'customPriceModifier',
      'customItemPriceModifier',
      'paymentMethod',
      'dimOrderDineIn',
      'paymentMethodTips',
      'audit',
      'cash',
      'table',
      'tableGroupSales',
      'merchantTakeaway',
    ]

    const dimorderTitles = [
      'dimOrderTakeaway',
      'dimOrderStoreDelivery',
    ]

    const titles = this.TYPE === 'dimorder'
      ? dimorderTitles
      : summaryTitles

    const data = titles
      .map(title => {
        if (title === 'tableGroupSales') {
          // 將資料整理成跟其他的格式一致
          const formatedTableGroupSalesData = _.reduce(tableGroupSalesData, (acc, data) => {
            acc[data.tableName] = { count: data.count, amount: data.totalSales }
            return acc
          }, {})
          return {
            type: 'tableGroupSales',
            data: formatedTableGroupSalesData,
          }
        }
        return summarySectionsData.find(data => data.type === title)
      })
      .filter(data => {
        if (data.type === 'table' && enableQuickMode) return false
        if (data.type === 'cash') return _.get(data, 'data.cash')
        if (['customPriceModifier', 'customItemPriceModifier'].includes(data.type)) {
          return _.get(data, 'data.total', 0)
        }
        return data
      })

    const { summaryReportTextSize = 0 } = this.printerSetting
    const { itemWidths } = printer.getReportTextWidth(summaryReportTextSize)
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH]
    const spaceSize = summaryReportTextSize === 0 ? 4 : 0
    const widths = [spaceSize, itemWidths[0] - spaceSize, itemWidths[1], itemWidths[2]]

    data.forEach((data, index) => {
      const title = i18n.exists('app.page.stats.newSummary.' + data.type)
        ? 'app.page.stats.newSummary.' + data.type
        : data.type

      printer.addText(i18n.t(title), summaryReportTextSize).addRowFeed()

      for (const [key, value] of Object.entries(data.data)) {
        const listTitle = i18n.exists('app.page.stats.newSummary.' + key)
          ? 'app.page.stats.newSummary.' + key
          : key
        const isTextPin = key === 'totalSales' || key === 'revenue' || key === 'total'
        if (key === 'merchantPromocode' && index === 0 && this.TYPE === 'summary') {
          continue
        }
        if (typeof value === 'object') {
          if (key === 'payments') {
            (value || []).forEach(payment => {
              const row = [
                '',
                `-${i18n.t(getPaymentMethod(payment.type)?.name)}`,
                String(payment.count),
                ` ${currencyWithCommas(payment.amount, 2)}`,
              ]
              printer.addFixedRow(row, widths, summaryReportTextSize, 1, align)
            })
          } else if (key === 'modifiers') {
            (value || []).forEach(modifier => {
              const row = [
                '',
                `-${modifier.name}`,
                String(modifier.count),
                ` ${currencyWithCommas(modifier.amount, 2)}`,
              ]
              printer.addFixedRow(row, widths, summaryReportTextSize, 1, align)
            })
          } else if (key === 'cash') {
            (value || []).forEach(modifier => {
              const row = [
                '',
                `-${i18n.t('app.page.stats.newSummary.' + modifier.type)}`,
                '',
                ` ${currencyWithCommas(modifier.amount)}`,
              ]
              printer.addFixedRow(row, widths, summaryReportTextSize, 1, align)
            })
          } else {
            const row = [
              '',
              `${isTextPin ? '' : (summaryReportTextSize === 0 ? '  -' : '-')}${i18n.t(listTitle)}`,
              String(value.count),
              ` ${currencyWithCommas(value.amount, 2)}`,
            ]
            printer.addFixedRow(row, [2, widths[1] + spaceSize - 2, widths[2], widths[3]], summaryReportTextSize, 1, align)
          }
        } else {
          const rowValue = key === 'guest' ? String(value) : ` ${currencyWithCommas(value, 2)}`
          const row = [
            '',
            `${isTextPin ? '' : (summaryReportTextSize === 0 ? '  -' : '-')}${i18n.t(listTitle)}`,
            '',
            rowValue,
          ]
          printer.addFixedRow(row, [2, widths[1] + spaceSize - 2, widths[2], widths[3]], summaryReportTextSize, 1, align)
        }
      }
      printer.addRowFeed()
    })

    printer.addRowFeed(5)
    printer.addCutPaper()
  }

  printCategory = (isCancelled, printData) => {
    const printer = this.printer
    const newData = Object.values(printData.categoryTotals).map(total => {
      const menuTotals = Object.values(total.menuTotals)
      return { ...total, menuTotals }
    })
    const { cancelOrdersReportTextSize = 0 } = this.printerSetting
    const { itemWidths, cancelWidths } = printer.getReportTextWidth(cancelOrdersReportTextSize)
    const titleText = isCancelled ? i18n.t('app.page.stats.cancel.cancelledItem') : i18n.t('app.page.stats.newSummary.item')
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH]
    const align5 = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH]
    const widths = [cancelWidths[0], cancelWidths[1], cancelWidths[2], 1, cancelWidths[3] - 1]

    printer.addAlign('left')
    printer.addFixedRow(
      [
        titleText,
        i18n.t('app.page.stats.cancel.count'),
        i18n.t('app.page.stats.cancel.serial'),
        '',
        i18n.t('app.page.stats.cancel.identifier'),
      ],
      widths,
      cancelOrdersReportTextSize,
      1,
      align5,
    )

    newData.forEach(category => {
      printer.addBoldMode()
      const row = [
        category.name,
        !isCancelled ? String(category.count) : '',
        !isCancelled ? currencyWithCommas(category.total, 2) : '',
      ]
      printer.addFixedRow(row, itemWidths, cancelOrdersReportTextSize, 1, align)
      printer.addBoldMode(false)
      category.menuTotals.forEach(item => {
        const orders = item.cancelIdentifierOrders.map(order => {
          return { ...order, name: item?.name }
        })
        _.each(orders, (order, key) => {
          const row = [
            item.name,
            String(order.quantity),
            order.serial,
            '',
            order.staff,
          ]
          printer.addFixedRow(row, widths, cancelOrdersReportTextSize, 1, align5)
        })
      })
    })

    printer.addRowFeed(5)
    printer.addCutPaper()
  }

  printProductCategoryReport = (printData) => {
    const printer = this.printer
    const { categoryReportTextSize = 0 } = this.printerSetting
    const contentStyleText = { text: categoryReportTextSize, val: categoryReportTextSize }
    const { itemWidths } = printer.getReportTextWidth(categoryReportTextSize)
    const titleText = i18n.t('app.page.stats.newSummary.item')
    const setItemText = i18n.t('app.page.stats.category.setItem')
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH]
    const widths = [2, itemWidths[0] - 2, itemWidths[1], itemWidths[2]]
    const itemAlign = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH]
    const { key, text, filters } = getState().statistics.filter
    const { setItem } = filters

    const categoryData = _.concat(printData.categorySection, printData.setSection).map(d => {
      const items = _.filter(d.items, item => item.name.includes(text) && (setItem ? item.isSetItem : true))
      const sets = _.filter(d.sets, set => set.setName.includes(text) && !setItem)
      const count = _.sumBy(_.concat(items, sets), c => c.quantity)
      return { ...d, count, items, sets, sales: _.sumBy(items, item => item.isSetItem ? 0 : item.sales) + _.sumBy(sets, 'sales') }
    }).filter(d => {
      return (_.isEmpty(key) || key.includes(d.key)) && !(_.isEmpty(d.items) && _.isEmpty(d.sets))
    })

    printer.addAlign('left')
    printer.addFixedRow(
      [
        titleText,
        i18n.t('app.page.stats.cancel.count'),
        i18n.t('app.page.stats.cancel.amount'),
      ],
      itemWidths,
      contentStyleText,
      1,
      align,
    )

    categoryData.forEach(category => {
      printer.addBoldMode(!filters.hideItem)
      const row = [
        category.categoryName,
        String(category.count),
        ` ${currencyWithCommas(category.sales, 2)}`,
      ]
      printer.addFixedRow(row, itemWidths, categoryReportTextSize, 1, align)
      printer.addBoldMode(false)
      if (!filters.hideItem) {
        if (!_.isEmpty(category.items)) {
          category.items?.forEach(item => {
            const name = item.name ? item.name : `${item.setName} (${i18n.t('app.page.stats.category.set')})`
            const displayName = item.isSetItem ? `[${setItemText}] ${name}` : name
            printer.addFixedRow(
              [
                '-',
                displayName,
                String(item.quantity),
                item.isSetItem ? '--' : ` ${currencyWithCommas(item.sales, 2)}`,
              ],
              widths,
              categoryReportTextSize,
              1,
              itemAlign,
            )
          })
        } else if (category.sets) {
          category.sets?.forEach(item => {
            const name = item.name ? item.name : `${item.setName} (${i18n.t('app.page.stats.category.set')})`
            const displayName = item.isSetItem ? `[${setItemText}] ${name}` : name
            printer.addFixedRow(
              [
                '-',
                displayName,
                String(item.quantity),
                currencyWithCommas(item.sales, 2),
              ],
              widths,
              categoryReportTextSize,
              1,
              itemAlign,
            )
          })
        }
      }
    })

    printer.addRowFeed(5)
    printer.addCutPaper()
  }

  printCancelled = (data) => {
    const printer = this.printer
    const { cancelOrdersReportTextSize = 0 } = this.printerSetting
    const { contentPrintWidths } = printer.getReportTextWidth(cancelOrdersReportTextSize)
    const contentStyleText = cancelOrdersReportTextSize
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH]

    printer.addAlign('left')
    printer.addFixedRow(
      [
        i18n.t('app.page.stats.cancel.serial'),
        i18n.t('app.page.stats.cancel.reason'),
        i18n.t('app.page.stats.cancel.identifier'),
      ],
      contentPrintWidths,
      contentStyleText,
      1,
      align,
    )

    for (const item of data) {
      printer.addFixedRow(
        [
          item.orderNumber,
          `${this.mapCancelKey(item.cancelReason) ?? i18n.t('app.page.stats.cancel.none')}`,
          item.cancelledBy,
        ],
        contentPrintWidths,
        contentStyleText,
        1,
        align,
      )
    }

    printer.addRowFeed(5)
    printer.addCutPaper()
  }

  printDepartment = () => {
    const printer = this.printer
    const contentStyleText = { text: PrinterFontSize.SMALL, val: PrinterFontSize.SMALL }
    const { twoItemsWidths } = printer.getReportTextWidth(0)
    const data = this.data?.department?.data
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH]

    printer.addAlign('left')
    printer.addFixedRow(
      [
        i18n.t('app.page.stats.department.title'),
        '',
        i18n.t('app.page.stats.department.total'),
      ],
      twoItemsWidths,
      contentStyleText,
      1,
      align,
    )

    for (const item of data) {
      printer.addFixedRow(
        [
          item.name,
          '',
          item.total,
        ],
        twoItemsWidths,
        contentStyleText,
        1,
        align,
      )
    }

    printer.addRowFeed(5)
    printer.addCutPaper()
  }

  printCashRequest = () => {
    const printer = this.printer
    const cashRequestFont = 0
    const { cancelWidths: widths } = printer.getReportTextWidth(cashRequestFont)
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH]
    const data = this.data?.cash

    const summaryData = this.data.summary.sections
    const cashSummary = _.find(summaryData, section => section.type === 'cash')
    _.each(cashSummary?.data?.cash, cash => {
      const row = [
        i18n.t('app.page.stats.newSummary.' + cash.type),
        '',
        '',
        ` ${currencyWithCommas(cash.amount)}`,
      ]
      printer.addFixedRow(row, widths, cashRequestFont, 1, align)
    })
    const row = [
      i18n.t('app.page.stats.newSummary.total'),
      '',
      '',
      ` ${currencyWithCommas(_.get(cashSummary, 'data.total', 0))}`,
    ]
    printer.addFixedRow(row, widths, cashRequestFont, 1, align)
    printer.addUnderline()

    printer.addAlign('left')
    printer.addFixedRow(
      [
        i18n.t('app.page.setting.checkout.reason'),
        i18n.t('app.page.setting.printer.customizedSetting.type'),
        i18n.t('app.component.dateTimeDialog.date'),
        i18n.t('app.page.stats.department.total'),
      ],
      widths,
      cashRequestFont,
      1,
      align,
    )

    for (const item of data) {
      let typeText = ''
      if (item.type === 'in') {
        typeText = i18n.t('app.page.setting.checkout.payIn')
      } else if (item.type === 'out') {
        typeText = i18n.t('app.page.setting.checkout.payOut')
      }
      printer.addFixedRow(
        [
          this.mapReasonKey(item.reason) || i18n.t('app.page.stats.cancel.none'),
          typeText,
          moment(item.createdAt).format('DD/MM HH:mm'),
          currencyWithCommas(item.amount),
        ],
        widths,
        cashRequestFont,
        1,
        align,
      )
    }
    printer.addRowFeed(5)
    printer.addCutPaper()
  }

  printProductOptionReport = () => {
    /** @type {IStatOptionData[]} */
    const optionData = this.data.option?.data ?? []
    const sortedData = _.orderBy(optionData, ['totalSales', 'title'], ['desc', 'asc'])
    const { optionReportTextSize = 0 } = this.printerSetting // optionReportTextSize 尚不存在於 printerSetting.defaultSettings 內，預設字體為 0 即 'small'
    const { itemWidths: baseWidths } = this.printer.getReportTextWidth(optionReportTextSize)
    const itemWidths = [baseWidths[0] + 6, baseWidths[1] - 2, baseWidths[2] - 4] //  [PrinterFontSize.SMALL]: [20, 10, 18]，稍微調整一下欄位的寬度成 [26, 8, 14] 比較省紙
    const aligns = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH, PrintStyle.ALIGN.RIGTH]
    const rowFeed = 1

    this.printer.addAlign('left')
    this.printer.addFixedRow(
      // |商品選項銷售           |      數量|              價錢|
      [
        i18n.t('app.page.stats.option.item'),
        i18n.t('app.page.stats.cancel.count'),
        i18n.t('app.page.stats.cancel.amount'),
      ],
      itemWidths,
      optionReportTextSize,
      rowFeed,
      aligns,
    )

    _.forEach(sortedData, data => {
      this.printer.addFixedRow(
        //  |沛綠雅 - 加檸檬片      |         1|            $5.00|
        [
          data.title,
          String(data.count),
          currencyWithCommas(data.totalSales, 2),
        ],
        itemWidths,
        optionReportTextSize,
        rowFeed,
        aligns,
      )
    })

    this.printer.addRowFeed(5)
    this.printer.addCutPaper()
  }

  mapCancelKey = (key) => {
    switch (key) {
      case 'invalid': {
        return i18n.t('app.component.cancelReason.invalid')
      }
      case 'trial': {
        return i18n.t('app.component.cancelReason.trial')
      }
      case 'rejected': {
        return i18n.t('app.component.cancelReason.rejected')
      }
      case 'unavailable': {
        return i18n.t('app.component.cancelReason.unavailable')
      }
      case 'busy': {
        return i18n.t('app.component.cancelReason.busy')
      }
      case 'closed': {
        return i18n.t('app.component.cancelReason.closed')
      }
      default: {
        return key
      }
    }
  }

  mapReasonKey = (key) => {
    switch (key) {
      case 'food': {
        return i18n.t('app.page.setting.payInOut.food')
      }
      case 'kitchenware': {
        return i18n.t('app.page.setting.payInOut.kitchenware')
      }
      case 'tableware': {
        return i18n.t('app.page.setting.payInOut.tableware')
      }
      case 'cleaningSupplies': {
        return i18n.t('app.page.setting.payInOut.cleaningSupplies')
      }
      case 'stationery': {
        return i18n.t('app.page.setting.payInOut.stationery')
      }
      default: {
        return key
      }
    }
  }
}
