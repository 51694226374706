import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Blink from '@root/src/components/Blink'
import React from 'react'
import colors from '@/theme/colors'

export default function AreaButton ({ active, text, onPress, showOverdueIcon = false, duration = 500 }) {
  const textStyle = {
    opacity: active ? 1 : 0.4,
  }

  return (
    <TouchableOpacity onPress={onPress} style={{ width: 100 }}>
      <View style={styles.container}>
        <Text style={[styles.text, textStyle]}>{text}</Text>
        {
          showOverdueIcon && (
            <Blink duration={duration} style={styles.iconContainer}>
              <Image style={styles.iconStyle} source={require('@icons/table/overdue.png')} />
            </Blink>
          )
        }
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: 30,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    fontWeight: '500',
    color: colors.white,
  },
  iconContainer: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  iconStyle: {
    width: 20,
    height: 20,
    opacity: 0.5,
  },
})
