import { Redirect } from 'react-router-dom'
import { Route, Switch } from '@/libs/reactRouter'
import { StyleSheet } from 'react-native'
import { useCheckoutPayment } from '@/hooks/orderCheckout'
import { useSelectedOrder } from '@/hooks/orderHistory'
import OrderSummary from './OrderSummary'
import Payment from './Payment'
import PaymentResult from './PaymentResult'
import React from 'react'
import RightNavBar from '@/components/RightNavBar'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function Checkout () {
  const selectedOrder = useSelectedOrder()
  const checkoutPayment = useCheckoutPayment()

  if (!selectedOrder || !checkoutPayment) {
    return <Redirect to='/orderHistory' push={false} />
  }

  return (
    <Row style={styles.container}>
      <OrderSummary />
      <Switch>
        <Route
          exact
          path='/orderHistory/checkout/result'
          render={() => (
            <>
              <PaymentResult />
              <RightNavBar />
            </>
          )}
        />
        <Route exact path='/orderHistory/checkout' component={Payment} />
      </Switch>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray3,
  },
})
