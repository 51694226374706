import { StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useSelectedTable } from '@/hooks/table'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import NumberInputDialog from '@/components/dialogs/NumberInputDialog'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import Row from '@/components/Row'

import CategoryTagList from '../CategoryTagList'
import CustomerCount from '../CutomerCount'

const DEFAULT_STATE = {
  name: '',
  customerNameSuffix: '',
  memberId: '',
  phone: '',
  remark: '',
  adults: 1,
  children: 0,
  tags: [],
  createdAt: new Date(),
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function NewOrderDialog (props) {
  const dispatch = useDispatch()
  const visible = useSelector(state => state.table.dialog.newOrder)
  const tableKey = useSelectedTable()?.key
  const [state, setState] = useState(DEFAULT_STATE)
  const [error, setError] = useState('')
  const [categoryTagId, setCategoryTagId] = useState(null)
  const setting = useSelector(state => state.merchant.data?.setting) ?? {}
  const enableCategoryTag = setting?.enableCategoryTag
  const tags = useSelector(state => state.menu.categoryTags)
  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)
  const { selectPrinterBeforePrint } = useSelector(state => state.app.settings)
  const qrcode = setting?.qrcode
  const enableDynamicQRCode = setting?.enableDynamicQRCode
  const [adultActive, setAdultActive] = useState(true)
  const [childrenActive, setChildrenActive] = useState(false)
  const [clearValue, setClearValue] = useState(true)

  const categoryTagOptions = []
  tags.map(o => {
    categoryTagOptions.push({
      value: o.id,
      display: o.name,
    })
  })
  const { t } = useTranslation()
  const submitError = t('app.page.table.dialog.submitError')
  const tagError = t('app.page.table.dialog.tagError')
  const startOrderText = t('app.page.table.dialog.startOrder')
  const confirm = t('app.common.confirm')

  if (!visible) return null

  const onChangeState = (key, val) => {
    setState(prevState =>
      _.set(_.assign({}, prevState), key, val),
    )
  }

  const resetState = () => {
    setState(DEFAULT_STATE)
    setError('')
    setAdultActive(true)
    setChildrenActive(false)
    setClearValue(true)
    setCategoryTagId(null)
  }

  const onClose = () => {
    resetState()
    dispatch(actions.table.closeDialog(['newOrder']))
  }
  const createOrder = (selectedPrinterId = '') => {
    dispatch(actions.table.createOrder({ ...state, table: tableKey, subTable: 'A' }, categoryTagId, selectedPrinterId))
  }

  const onSubmit = () => {
    // Checksum
    if (state.adults + state.children <= 0) {
      setError(submitError)
      return
    }
    if (enableCategoryTag && !categoryTagId && !_.isEmpty(categoryTagOptions) && enableDynamicQRCode && qrcode) {
      setError(tagError)
      return
    }
    if (selectPrinterBeforePrint && qrcode && enableDynamicQRCode) {
      setPrinterDialogOpen(true)
    } else {
      createOrder()
      resetState()
      dispatch(actions.table.closeDialog(['newOrder']))
    }
  }
  return (
    <NumberInputDialog
      title={startOrderText}
      open={visible}
      maxLength={2}
      onClose={onClose}
      disableDecimalPoint
      defaultValue={clearValue ? 0 : adultActive ? state.adults : state.children}
      onPress={(value) => {
        if (adultActive) {
          setClearValue(false)
          onChangeState('adults', Number(value))
        }
        if (childrenActive) {
          setClearValue(false)
          onChangeState('children', Number(value))
        }
      }}
      footer={enableCategoryTag && !_.isEmpty(categoryTagOptions) && enableDynamicQRCode && qrcode
        ? <CategoryTagList categoryTags={categoryTagOptions} onSelectCategoryTag={(id) => setCategoryTagId(id)} selectedCategoryTag={categoryTagId} />
        : <></>}
    >
      <Column style={styles.container}>
        <CustomerCount
          state={state}
          setAdultActive={setAdultActive}
          setChildrenActive={setChildrenActive}
          adultActive={adultActive}
          childrenActive={childrenActive}
          setClearValue={setClearValue}
        />
        <Row style={styles.errorRow}>
          <Text style={styles.errorText}>
            {error || ' '}
          </Text>
        </Row>

        <Row style={styles.bottomRow}>
          <Button
            title={confirm}
            textBold
            type='TouchableOpacity'
            onPress={onSubmit}
          />
        </Row>
      </Column>
      <PrinterDialog
        open={printerDialogOpen}
        onClose={() => setPrinterDialogOpen(false)}
        onSelect={(selectedPrinterId) => {
          resetState()
          createOrder(selectedPrinterId)
          dispatch(actions.table.closeDialog(['newOrder']))
        }}
      />
    </NumberInputDialog>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 230,
    height: 300,
    marginHorizontal: 43,
    justifyContent: 'space-around',
  },
  bottomRow: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorRow: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  errorText: {
    fontWeight: '500',
    color: colors.secondary,
    alignItems: 'center',
  },
  customers: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
})
