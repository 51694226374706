import { SectionList, StyleSheet, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import React from 'react'
import SetItemHeader from './SetItemHeader'
import SubItem from './SubItem'
import _ from 'lodash'
import colors from '@/theme/colors'

/* eslint-disable no-unused-vars */
import { IAppBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { SetItemsProps } from '@/pages/Order/OrderBatch/BatchList/index.d'
/* eslint-enable no-unused-vars */

/**
 * @param {SetItemsProps} props
 */
export default function SetItems (props) {
  const {
    serial,
    item,
  } = props
  const dispatch = useDispatch()
  const menus = useSelector(state => state.menu.menus)
  const set = useSelector(state => state.menu.sets[item.menuId])
  const selectedSetItemKey = useSelector(state => state.orderBatch.selectedSetItemKey)

  if (!item.isSet) return null

  const itemGroup = _.groupBy(item?.setItems, 'setMenuIndex')
  const sections = [
    {
      setMenuIndex: null,
      title: null,
      data: itemGroup.null || [],
    },
  ]

  _.times(item.quantity, (setMenuIndex) => {
    sections.push(
      {
        setMenuIndex: setMenuIndex,
        title: item.quantity > 1 ? `${item.name} - ${setMenuIndex + 1}` : null,
        serial: serial + setMenuIndex,
        data: itemGroup[setMenuIndex] || [],
      },
    )
  })

  /**
   * @param {{section: {setMenuIndex: number, title: string, data: IAppBatchItem[]}}} info
   * @returns {React.ReactElement}
   */
  const renderSectionHeader = ({ section }) => {
    if (section.title == null) {
      return null
    }
    return (
      <SetItemHeader
        serial={section.serial}
        name={section.title}
        onPress={() => dispatch(actions.orderBatch.selectItem(item.key, section))}
        selected={selectedSetItemKey?.setMenuIndex === section.setMenuIndex}
      />
    )
  }

  /**
   *
   * @param {{item: IAppBatchItem, index: number}} info
   * @returns {React.ReactElement}
   */
  const renderItem = ({ item: setItem, index }) => {
    const menu = menus[setItem.menuId]
    if (!set) {
      return null
    }
    const required = set.menus.length === 1 && menu.required
    return (
      <SubItem
        item={setItem}
        required={required}
        onPress={() => dispatch(actions.orderBatch.selectItem(item.key, setItem.key))}
        onDelete={() => {
          dispatch(actions.orderBatch.selectItem(item.key))
          dispatch(actions.orderBatch.deleteItem(setItem.key))
        }}
      />
    )
  }

  return (
    <View style={styles.container}>
      <SectionList
        scrollEnabled={false}
        sections={sections}
        renderSectionHeader={renderSectionHeader}
        renderItem={renderItem}
        extraData={1000 * item.quantity + item?.setItems.length}
        keyExtractor={item => item.key}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // width:580,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderColor: colors.lightGray5,
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'solid',
  },
})
