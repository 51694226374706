import { Dimensions } from 'react-native'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import { actions } from '@/redux'
import { filterRequestWaiterOrders, getHistoryOrder } from '@/libs/orderHistory'
import { loadingKey } from '@/constants'
import { searchAndFilterOrders } from '@/libs/orderFilters'
import delay from '@/libs/delay'
import logger from '@/libs/logger'
import orderEvent from '@/libs/orderEvent'

import ActionTypes from './ActionTypes'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

// iPad Pro 每排額外多顯示兩個桌位
const iPadProExtendedTables = Dimensions.get('window').width >= 1366 ? 2 : 0

/**
 * 將 merchant 桌位設定整理到 table store
 * 根據 merchant.data 產生 groupTables 和 tables
 * @returns {ThunkFunction}
 */
export function init () {
  return (dispatch, getState) => {
    dispatch(getTableGroups())
    dispatch(getTables())
  }
}

/**
 * 從 merchant 拿座位，並轉化成 areas 及 tables 陣列
 * @returns {ThunkFunction}
 */
export function getTables () {
  return (dispatch, getState) => {
    const enableLargerTable = getState().merchant.data?.setting?.enableLargerTable
    const tableGeometry = getState().merchant.data.tableGeometry ?? []
    const tableAreas = getState().table.tableAreas
    const floorPlanAreas = getState().table.floorPlanAreas
    // table加入識別id, areaId及table config(形狀/人數/排數)
    // 加入 floorPlanAreaId，shape，size 及 coordinates，用作 FloorPlan 顯示
    const tables = _.flatMap(tableAreas, (area) => {
      const tablePerRow = (enableLargerTable ? 4 : 6) + iPadProExtendedTables // 每排 6 個 / 4 個 (largerTable)

      return _.map(area.tables, (table, index) => {
        const selectedTable = tableGeometry.flatMap(area => area.tables).find(t => t.name === table)
        return {
          key: table,
          id: uuid(),
          areaId: area.id,
          floorPlanAreaId: floorPlanAreas.find(area => area.tables.includes(table))?.id,
          shape: selectedTable?.shape,
          size: {
            width: selectedTable?.width,
            height: selectedTable?.height,
          },
          coordinates: {
            x: selectedTable?.x,
            y: selectedTable?.y,
          },
          maxCustomer: 9999,
          row: _.floor(index / tablePerRow),
          orderIds: [],
          requestWaiterOrderIds: [],
        }
      })
    })
    dispatch({
      type: ActionTypes.UPDATE_TABLES,
      payload: { tables },
    })
    dispatch({
      type: ActionTypes.UPDATE_GROUP_TABLES,
      payload: { tables },
    })
  }
}

export function getTableGroups () {
  return (dispatch, getState) => {
    const tableGroups = getState().merchant.data.tableGroups
    const allTables = getState().merchant.data.tables
    const tableGeometry = getState().merchant.data.tableGeometry ?? []
    const enableFloorPlan = getState().merchant.data.setting?.enableFloorPlan

    // FloorPlan 每個區域加入識別id
    const floorPlanAreas = tableGeometry.map(area => {
      return {
        key: area.name,
        text: area.name,
        id: uuid(),
        tables: area.tables.filter(table => allTables.includes(table.name)).map(table => table.name),
      }
    })

    // area加入識別id
    const tableAreas = _
      .chain(tableGroups)
      .keys()
      .filter(key => !_.isEmpty(tableGroups[key]))
      .map(key => {
        return {
          key,
          text: key,
          id: uuid(),
          tables: tableGroups[key],
        }
      })
      .value()

    // 其他枱號
    const otherTables = _.difference(allTables, _.flatMap(tableGroups))
    if (_.size(otherTables) > 0) {
      tableAreas.push({
        key: 'otherTablesWithoutGroup',
        text: 'app.page.setting.table.other',
        id: uuid(),
        tables: otherTables,
      })
    }

    dispatch({
      type: ActionTypes.UPDATE_TABLE_AREAS,
      payload: { tableAreas },
    })

    dispatch({
      type: ActionTypes.UPDATE_FLOORPLAN_AREAS,
      payload: { floorPlanAreas },
    })

    const area = enableFloorPlan ? floorPlanAreas : tableAreas
    if (area[0]) {
      // 預設選擇第一個Area
      dispatch(selectTableArea(area[0].id))
    }
  }
}

/**
 * 從 orderHistory 拿內用訂單，並加入 tables
 * @returns {ThunkFunction}
 */
export function mapOrdersIntoTables () {
  return (dispatch, getState) => {
    try {
      const { enableLargerTable, unstackSubTables, enableFloorPlan } = getState().merchant.data?.setting ?? {}
      const groupTables = getState().table.groupTables
      if (!groupTables) return // 還沒有 init 沒有 groupTables
      const historyOrders = getState().orderHistory.orders
      const pendingTableOrders = searchAndFilterOrders(historyOrders, '', { deliveryType: ['table'], status: ['pending'] })
      const requestWaiterOrders = filterRequestWaiterOrders(historyOrders)
      const tablePerRow = (enableLargerTable ? 4 : 6) + iPadProExtendedTables // 每排 6 個 / 4個 (largerTable)

      // 經過 filterOrders 不該出現已付款的訂單，這是為了 debug 用，當發現有 paid 訂單時 log error
      const paidOrders = _.filter(pendingTableOrders, order => order.status === 'paid')
      if (paidOrders.length > 0) {
        logger.error('[mapOrdersIntoTables] 出現 status: paid 訂單', { paidOrder: paidOrders })
      }

      const separatedTables = _.flatMap(groupTables, table => {
        const tableOrders = _
          .chain(pendingTableOrders)
          .filter(order => order.table === table.key)
          .sortBy(['subTable', 'createdAt'])
          .value()

        if (!unstackSubTables || enableFloorPlan) {
          return {
            ...table,
            subTable: 0,
            name: table.key,
            orderIds: _.map(tableOrders, order => order.id),
            requestWaiterOrderIds: _
              .chain(requestWaiterOrders)
              .filter(order => order.table === table.key)
              .map(order => order.id)
              .value(),
          }
        }

        const subTableOrders = _.groupBy(tableOrders, 'subTable')
        const subTables = _.flatMap(subTableOrders, orders => {
          const subTableNo = _.get(orders[0], 'subTable', 0)
          return {
            ...table,
            id: subTableNo ? table.id + String(subTableNo) : table.id,
            subTable: subTableNo,
            name: subTableNo ? `${table.key}(${String.fromCharCode(subTableNo + 64)})` : table.key,
            orderIds: _.map(orders, order => order.id),
            requestWaiterOrderIds: _
              .chain(requestWaiterOrders)
              .filter(order => order.table === table.key && order.subTable === subTableNo)
              .map(order => order.id)
              .value(),
          }
        })

        if (subTables.findIndex(t => Number(t.subTable) === 0) === -1) {
          subTables.unshift({
            ...table,
            subTable: 0,
            name: table.key,
            orderIds: [],
            requestWaiterOrderIds: [],
          })
        }

        return subTables
      })

      const updatedTables = _.flatMap(
        _.groupBy(separatedTables, 'areaId'),
        areaTables => _.flatMap(
          areaTables,
          (table, index) => {
            table.row = _.floor(index / tablePerRow)
            return table
          },
        ),
      )

      dispatch({
        type: ActionTypes.UPDATE_TABLES,
        payload: { tables: updatedTables },
      })
    } catch (error) {
      logger.error(`[mapOrdersIntoTables] catch error: ${error.message || error.toString()}`, { error })
    }
  }
}

/**
 * 選擇枱號群(TableArea)
 * @param {string} tableAreaId
 */
export function selectTableArea (tableAreaId) {
  return {
    type: ActionTypes.SELECT_TABLE_AREA,
    payload: { tableAreaId },
  }
}

/**
 * 選擇座位(Table)
 * @param {string} tableId
 */
export function selectTable (tableId) {
  return {
    type: ActionTypes.SELECT_TABLE,
    payload: { tableId },
  }
}

/**
 *  選擇預約單(booking)
 * @param {string} bookingId
 */
export function selectBooking (bookingId) {
  return {
    type: ActionTypes.SELECT_BOOKING,
    payload: { bookingId },
  }
}

/**
 *  選擇訂單(order)
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function selectOrder (order) {
  return (dispatch, getState) => {
    const selectedOrders = getState().table.selectedOrders
    const index = _.findIndex(selectedOrders, o => o.id === order.id)

    if (index >= 0) {
      dispatch({
        type: ActionTypes.DESELECT_ORDER,
        payload: { order },
      })
    } else {
      dispatch({
        type: ActionTypes.SELECT_ORDER,
        payload: { order },
      })
    }
  }
}

/**
 * 將已選擇之訂單轉枱
 * @param {string} tableKey
 * @returns {ThunkFunction}
 */
export function moveOrder (tableKey) {
  return async (dispatch, getState) => {
    const selectedOrders = getState().table.selectedOrders
    const historyOrders = getState().orderHistory.orders
    const tables = getState().table.tables
    const table = _.find(tables, table => table.key === tableKey)
    const tableOrders = _.filter(historyOrders, order => _.includes(table.orderIds, order.id))

    const subTables = _.uniq(_.flatMap(tableOrders.filter(t => t.status === 'pending'), o => o.subTable ?? 0)).sort()

    const subTable = []
    new Array((tableOrders.length ?? 1) + (selectedOrders.length ?? 1)).fill(0).some((s, index) => {
      if (!subTables.includes(index)) {
        subTable.push(index)
      }
    })

    _.each(selectedOrders, (order, index) => {
      const responseOrder = {
        ...order,
        table: tableKey,
        subTable: subTable[index],
      }
      // 更新轉枱後的訂單
      dispatch(actions.orderHistory.updateOrder(responseOrder, { selectOrder: true, syncOrder: true }))
      orderEvent.emitter.emit(orderEvent.eventType.ORDER_MOVE, responseOrder, order.table, tableKey)
    })

    // 重置狀態
    dispatch(resetSelectingOrder())
  }
}

/**
 * 將單一訂單退桌
 * @param {string} orderId
 * @param {string} reason
 * @returns {ThunkFunction}
 */
export function cancelOrder (order, reason) {
  return async (dispatch, getState) => {
    dispatch(actions.orderHistory.cancelOrder(order, reason))
  }
}

/**
 * 將已選擇之訂單合併
 * @returns {ThunkFunction}
 */
export function mergeOrder () {
  return async (dispatch, getState) => {
    const selectedOrders = getState().table.selectedOrders
    const order = _.last(selectedOrders) // 最後一張為主單
    const fromOrders = _.take(selectedOrders, selectedOrders.length - 1)
    const updateOrders = await Promise.all(_.map(fromOrders, async (fromOrder) => {
      await dispatch(actions.orderHistory.mergeOrder(order.id, fromOrder.id))
      const mergeOrder = getHistoryOrder(fromOrder.id)
      orderEvent.emitter.emit(orderEvent.eventType.ORDER_MERGE, mergeOrder, order)
      return mergeOrder
    }))
    const mainOrder = getHistoryOrder(order.id)
    updateOrders.push(mainOrder)

    dispatch(actions.orderHistory.updateOrders(updateOrders, { syncOrder: true, overwrite: false }))
    // 重置狀態
    dispatch(resetSelectingOrder())
  }
}

/**
 * 將已選擇之訂單合併結帳
 * @returns {ThunkFunction}
 */
export function mergeCheckout () {
  return async (dispatch, getState) => {
    const selectedOrders = getState().table.selectedOrders
    const order = _.last(selectedOrders) // 最後一張為主單

    const readyToMergeCheckout = await dispatch(preCheckoutOrder(selectedOrders))

    if (readyToMergeCheckout) {
      await dispatch(mergeOrder())
      await delay(500)
      // 結帳
      dispatch(actions.orderCheckout.checkoutOrder(order.id))
    }
  }
}

/**
 * 檢查訂單們是否可以結帳
 * @param {IAppOrder} orders
 * @returns {ThunkFunction}
 */
export function preCheckoutOrder (orders) {
  return async (dispatch, getState) => {
    const errorMessages = []

    await Promise.all(orders.map(async (order) => {
      const selectedOrder = getHistoryOrder(order.id)
      const items = _.flatMap(selectedOrder.batches, batch => batch.items)

      items.forEach(item => {
        // 檢查時價是否都設定完成
        if (item.priceUndetermined && !item.cancelled) {
          errorMessages.push(item.name + '時價未設定')
        }
        item.setItems.forEach(setItem => {
          if (setItem.priceUndetermined && !setItem.cancelled) {
            errorMessages.push(setItem.name + '時價未設定')
          }
        })
      })
    }))

    if (errorMessages.length > 0) {
      dispatch(actions.app.showAlert({
        modalProps: { enablePressOutsideClose: true },
        title: '結帳失敗',
        messages: errorMessages,
      }))
      return false
    }

    return true
  }
}

/**
 * 入座
 * @param {object} localBooking
 * @returns {ThunkFunction}
 */
export function createOrder (localBooking, categoryTagId = null, selectedPrinterId = '', isTransferItem = false, syncOrder = true) {
  return async (dispatch, getState) => {
    // 將預約單資訊轉成訂單資訊
    const localOrder = _.pick(localBooking, ['table', 'name', 'customerNameSuffix', 'memberId', 'phone', 'remark', 'adults', 'children', 'tags'])
    const historyOrders = getState().orderHistory.orders
    const tables = getState().table.tables
    const orderIds = _
      .chain(tables)
      .filter(table => table.key === localBooking.table)
      .map(table => table.orderIds)
      .flatten()
      .uniq()
      .value()
    const orders = _.filter(historyOrders, order => _.includes(orderIds, order.id))
    const subTables = _.uniq(_.flatMap(orders.filter(t => t.status === 'pending'), o => o.subTable ?? 0)).sort()
    let subTable = 0
    new Array((orders.length ?? 1) + 1).fill(0).some((s, index) => {
      if (subTables[index] !== index) {
        subTable = index
        return true
      }
    })
    localOrder.subTable = subTable
    // 建立新內用訂單
    await dispatch(actions.order.createLocalOrder('table'))
    await dispatch(actions.order.updateSelectedOrderFields(localOrder))
    const selectedOrder = getState().order.selectedOrder
    // await dispatch(actions.order.startOrder())
    await dispatch(actions.order.createOrder(selectedOrder, categoryTagId, selectedPrinterId, isTransferItem, syncOrder))
  }
}

/**
 * 建立預約單(booking)
 * @param {object} booking
 * @returns {ThunkFunction}
 */
export function createBooking (booking) {
  return async (dispatch, getState) => {
  // TODO: 建立預約單
    dispatch({
      type: ActionTypes.UPDATE_BOOKING,
      payload: { booking },
    })
  }
}

/**
 * 更新預約單(booking)
 * @param {object} booking
 * @returns {ThunkFunction}
 */
export function updateBooking (booking) {
  return {
    type: ActionTypes.UPDATE_BOOKING,
    payload: { booking },
  }
}

/**
 * 取消預約單(booking)
 * @param {object} booking
 * @returns {ThunkFunction}
 */
export function cancelBooking (bookingId) {
  return async (dispatch, getState) => {
    dispatch(selectBooking(null))
    dispatch({
      type: ActionTypes.DELETE_BOOKING,
      payload: { bookingId },
    })
  }
}

/**
 * 預約單入座
 * @param {string} localBooking
 * @returns {ThunkFunction}
 */
export function proceedBookingToOrder (localBooking) {
  return async (dispatch, getState) => {
    // TODO: 若預約單沒有座位資訊，要在table page選擇座位？還是在order page?
    dispatch(createOrder(localBooking))
  }
}

/**
 * 切換 選擇訂單mode
 * @param {boolean} isSelecting
 */
export function updateSelecting (isSelecting) {
  return {
    type: ActionTypes.UPDATE_SELECTING,
    payload: { isSelecting },
  }
}

/**
 * @param {boolean} isSelectingTable
 */
export function updateSelectingTable (isSelectingTable) {
  return {
    type: ActionTypes.UPDATE_SELECTING_TABLE,
    payload: { isSelectingTable },
  }
}

/**
 * 切換 訂單轉枱mode
 * @param {boolean} isMoving
 */
export function updateMoving (isMoving) {
  return {
    type: ActionTypes.UPDATE_MOVING,
    payload: { isMoving },
  }
}

/**
 * 切換 搜尋mode
 * @param {boolean} isSearching
 */
export function updateSearching (isSearching) {
  return {
    type: ActionTypes.UPDATE_SEARCHING,
    payload: { isSearching },
  }
}

/**
 * 顯示彈窗
 * @param {Array} path
 */
export const showDialog = (path) => {
  return async (dispatch, getState) => {
    const dialog = getState().table.dialog
    const isLoading = (Object.values(dialog) || []).some(d => d === true)
    await delay(100)
    if (!isLoading) {
      await dispatch({
        type: ActionTypes.SHOW_DIALOG,
        payload: { path },
      })
    }
  }
}

/**
 * 關閉彈窗
 * @param {Array} path
 */
export const closeDialog = (path) => {
  return {
    type: ActionTypes.CLOSE_DIALOG,
    payload: { path },
  }
}

/**
 * 設定搜尋字串
 * @param {string} searchText
 */
export function updateSearchText (searchText) {
  return {
    type: ActionTypes.UPDATE_SEARCH_TEXT,
    payload: { searchText },
  }
}

/**
 * 切換 預約單總覽mode
 * @param {boolean} isOverviewExpanded
 */
export function updateOverviewExpand (isOverviewExpanded) {
  return {
    type: ActionTypes.UPDATE_OVERVIEW_EXPAND,
    payload: { isOverviewExpanded },
  }
}

/**
 * 重置所有資料以外的狀態
 */
export function resetAllActions () {
  return {
    type: ActionTypes.RESET_ALL_ACTIONS,
  }
}

/**
 * 刪除單號
 * @param {string} id
 */
export function setCancelId (id) {
  return {
    type: ActionTypes.SET_CANCEL_ID,
    payload: { id },
  }
}

/**
 * 重置選擇相關的狀態
 */
export function resetSelectingOrder () {
  return async (dispatch, getState) => {
    dispatch(updateSelecting(false))
    dispatch(updateMoving(false))
  }
}

/**
 * 清除所有空枱
 * @returns {ThunkFunction}
 */
export function cancelAllEmptyOrder () {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.ORDER))
      const historyOrders = getState().orderHistory.orders
      const emptyOrders = _.filter(historyOrders, order => {
        return order.deliveryType === 'table' && _.isEmpty(order.batches) && order.status === 'pending'
      })
      await dispatch(actions.orderHistory.cancelOrders(emptyOrders, '清場Clear All'))
    } catch (error) {
      console.log('cancelAllEmptyOrder error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.ORDER))
    }
  }
}
