import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import Row from '@/components/Row'
import colors from '@/theme/colors'

/**
 * @typedef RowTextInputProps
 * @property {string?} label
 * @property {boolean?} required
 * @property {boolean?} disabled
 * @property {string} suffixValue
 * @property {() => void} setSuffix
 */

/**
 *
 * @param {TextInputProps & RowTextInputProps} props
 * @returns
 */
export default function RowTextInput (props) {
  const { label, required, disabled, suffixValue, setSuffix, ...others } = props
  const [selected, setSelected] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    if (suffixValue) {
      setSelected(suffixValue)
    }
  }, [suffixValue])

  const onSelectSuffix = (value) => {
    setSelected(value)
    setSuffix(value)
  }

  return (
    <Row style={[styles.container, disabled && { borderBottomColor: colors.transparent }, others?.multiline && { height: 60 }]}>

      {/* require asterisk sign */}
      {required && (
        <Image
          style={styles.asteriskIcon}
          source={require('@icons/table/asterisk.png')}
        />
      )}

      {/* text input */}

      <View style={styles.input}>
        {label && <Text style={[styles.inputText, { flex: 1 }]}>{label}</Text>}
        <View style={{ flex: 1 }}>
          <TextInput
            style={[styles.inputText, label && { textAlign: 'right' }]}
            placeholderTextColor={colors.textPlaceholder}
            editable={!disabled}
            {...others}
          />
        </View>
      </View>

      {/* suffix input */}
      {setSuffix &&
        <Row>
          <TouchableOpacity
            onPress={() => onSelectSuffix('mr')}
          >
            <Text
              style={[styles.suffixText, selected === 'mr' && styles.selected]}
            >
              {t('app.component.infoDialog.mr')}
            </Text>
          </TouchableOpacity>

          <Text style={styles.suffixText}>/</Text>

          <TouchableOpacity
            onPress={() => onSelectSuffix('ms')}
          >
            <Text
              style={[styles.suffixText, selected === 'ms' && styles.selected]}
            >
              {t('app.component.infoDialog.ms')}
            </Text>
          </TouchableOpacity>
        </Row>}
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 43,
    width: '100%',
    alignItems: 'flex-end',
    borderBottomColor: colors.primary,
    borderBottomWidth: 2,
    paddingHorizontal: 10,
    paddingBottom: 5,
  },
  asteriskIcon: {
    height: 20,
    width: 20,
    position: 'absolute',
    bottom: 5,
    left: -6 - 10,
  },
  input: {
    flex: 1,
    flexDirection: 'row',
  },
  inputText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.primary,
  },
  suffixText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.textPlaceholder,
  },
  selected: {
    color: colors.primary,
  },
})
