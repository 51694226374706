import { ScrollView, StyleSheet } from 'react-native'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { getCategoryName } from '@/libs/menu'
import { useHistory } from '@/libs/reactRouter'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import ImageHeader from '../ImageHeader'
import React from 'react'
import SettingList from '@/components/Setting/SettingList'
import SettingListHeaderButton from '@/components/Setting/SettingListHeaderButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingStatus from '@/components/Setting/SettingStatus'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'

export default function SetInfo (props) {
  const history = useHistory()
  const { t } = useTranslation()
  const { set } = props
  const 產品分類 = getCategoryName(set?.categoryId)
  const inventory = useSelector(state => state.merchant.data?.setting?.inventory)
  const takeawayIndex = (set?.prices ?? []).findIndex(p => p.type === 'takeaway')
  const deliveryIndex = (set?.prices ?? []).findIndex(p => p.type === 'storeDelivery')
  const lang = String(i18n.language ?? 'zh').split('-')[0]

  const weekdayOptions = [
    { value: 0, display: t('app.constant.weekday.sun') },
    { value: 1, display: t('app.constant.weekday.mon') },
    { value: 2, display: t('app.constant.weekday.tue') },
    { value: 3, display: t('app.constant.weekday.wed') },
    { value: 4, display: t('app.constant.weekday.thur') },
    { value: 5, display: t('app.constant.weekday.fri') },
    { value: 6, display: t('app.constant.weekday.sat') },
  ]
  // const 適用範圍 = menu.prices?.map(p => p.type).join(',')
  // const 適用期間 = menu.weekdays?.join(',')
  // const 税別 = menu.??? ? "含稅":"税前"
  // const 限時 = menu.timerange?.map(t => `${t.hour}:${t.minute}`).join(',')

  return (
    <>
      <SettingListHeaderButton
        text={t('app.page.setting.menuPanel.set.editSet')}
        style={styles.addButton}
        onPress={() => history.push(`/setting/menu/set-detail/${set.id}`)}
        icon='square-edit-outline'
      />
      <SettingList
        shadow
        style={styles.container}
      >
        <ImageHeader menu={set} disableButton />
        <ScrollView showsVerticalScrollIndicator={false}>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.category')} style={styles.leftText} />
            <SettingListItemText text={產品分類} style={styles.rightText} />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.name')} style={styles.leftText} />
            <SettingListItemText text={(_.get(set?.localeNames, lang) ?? set.name) || '-'} style={styles.rightText} wrap />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.desc')} style={styles.leftText} />
            <SettingListItemText text={set.desc || '-'} style={styles.rightText} wrap />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.defaultPrice')} style={styles.leftText} />
            <SettingListItemText
              text={
                set.priceUndetermined ? t('app.constant.order.priceUndetermined') : `${currencyWithCommas(set.price)}`
              } style={styles.rightText}
            />
          </SettingListItem>
          {
            takeawayIndex > -1 &&
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.takeawayPrice')} style={styles.leftText} />
                <SettingListItemText
                  text={set.priceUndetermined ? t('app.constant.order.priceUndetermined') : `${currencyWithCommas(set.prices[takeawayIndex].price - set.prices[takeawayIndex].discount)}`}
                  style={styles.rightText}
                />
              </SettingListItem>
          }
          {
            deliveryIndex > -1 &&
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.deliveryPrice')} style={styles.leftText} />
                <SettingListItemText
                  text={set.priceUndetermined ? t('app.constant.order.priceUndetermined') : `${currencyWithCommas(set.prices[deliveryIndex].price - set.prices[deliveryIndex].discount)}`}
                  style={styles.rightText}
                />
              </SettingListItem>
          }
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.noDiscount')} style={styles.leftText} />
            <SettingStatus enabled={set.excludedDiscount} />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.noSurcharge')} style={styles.leftText} />
            <SettingStatus enabled={set.excludedSurcharge} />
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.promoted')} style={styles.leftText} />
            <SettingStatus enabled={set.promoted} />
          </SettingListItem>
          {
            set.promoted && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.promoDate')} style={styles.leftText} />
                <SettingListItemText
                  text={
                    _.get(set, 'promotedDays.length')
                      ? weekdayOptions.filter(o => {
                        const weekdays = set.promotedDays || []
                        return weekdays.includes(o.value)
                      }).map(o => o.display).join()
                      : '-'
                  } style={styles.rightText}
                />
              </SettingListItem>
            )
          }
          {
            set.promoted && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.promoTime')} style={styles.leftText} />
                <SettingListItemText
                  text={
                    _.get(set, 'promotedTimeRange.length')
                      ? `${_.padStart(set.promotedTimeRange[0].hour, 2, '0')}:${_.padStart(set.promotedTimeRange[0].minute, 2, '0')} - ${_.padStart(set.promotedTimeRange[1].hour, 2, '0')}:${_.padStart(set.promotedTimeRange[1].minute, 2, '0')}`
                      : '-'
                  } style={styles.rightText}
                />
              </SettingListItem>
            )
          }
          {
            inventory && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.inventory')} style={styles.leftText} />
                <SettingListItemText text={set.inventory} style={styles.rightText} />
              </SettingListItem>
            )
          }
          {/* <SettingListItem divider>
          <SettingListItemText text='適用範圍' style={styles.leftText} />
          <SettingListItemText text={適用範圍 || '-'} style={styles.rightText} />
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemText text='適用期間' style={styles.leftText} />
          <SettingListItemText text={適用期間 || '-'} style={styles.rightText} />
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemText text='稅別' style={styles.leftText} />
          <SettingListItemText text={'稅別' || '-'} style={styles.rightText} />
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemText text='限時' style={styles.leftText} />
          <SettingListItemText text={限時 || '-'} style={styles.rightText} />
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemText text='限量' style={styles.leftText} />
          <SettingListItemText text={menu.max || '-'} style={styles.rightText} />
        </SettingListItem> */}
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.menuPanel.set.step')} />
            <SettingListItemText text={set.steps?.length === 0 ? '-' : ''} style={styles.rightText} />
          </SettingListItem>

          {set.steps?.map((step, index) => (
            <SettingListItem key={step.id ?? index}>
              <SettingListItemText text={step.name} style={{ paddingLeft: 8 }} />
              <SettingListItemText text={`${step.min || '-'}/${step.max || '-'}`} style={styles.rightText} />
            </SettingListItem>
          ),
          )}

        </ScrollView>
      </SettingList>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  leftText: {
    width: '30%',
  },
  rightText: {
    flex: 1,
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: 16,
  },
  imageContainer: {
    height: 90,
    width: 90,
    backgroundColor: colors.light,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  textContainer: {
    flexWrap: 'wrap',
  },
  text: {
    color: colors.gray,
    fontSize: 12,
    fontWeight: '500',
  },
  addButton: {
    width: 'auto',
    marginLeft: 0,
  },
})
