import { useSelector } from '@/redux'
import _ from 'lodash'
import paymentConfigs from '@/configs/paymentConfigs'

export function usePaymentMethods () {
  const merchantPayments = useSelector(state => state.merchant.data.payments)

  const paymentMethods = []
  const cardProviders = []
  const qrProviders = []
  merchantPayments.forEach(merchantPayment => {
    const cardProvider = paymentConfigs.cardProviderMaps[merchantPayment.key]
    if (cardProvider) {
      cardProviders.push(_.assign(cardProvider, { gateway: merchantPayment?.gateway }))
      return
    }
    const qrProvider = paymentConfigs.qrProviderMaps[merchantPayment.key]
    if (qrProvider) {
      qrProviders.push(_.assign(qrProvider, { gateway: merchantPayment?.gateway }))
      return
    }
    const paymentMethod = paymentConfigs.paymentMethodMaps[merchantPayment.key]
    if (paymentMethod) {
      paymentMethods.push(_.assign(paymentMethod, { gateway: merchantPayment?.gateway }))
      return
    }

    // custom payment method
    paymentMethods.push({
      tips: merchantPayment?.tips,
      key: merchantPayment.key,
      name: merchantPayment.name,
      custom: true,
      iconSource: paymentConfigs.otherMethod.iconSource,
      useSmallIcon: true,
    })
  })

  return [paymentMethods, cardProviders, qrProviders]
}

export function usePaymentMethod (key) {
  const merchantPayments = useSelector(state => state.merchant.data.payments)

  const ecrProvider = paymentConfigs.ecrProviderMaps[key]
  if (ecrProvider) return ecrProvider

  const cardProvider = paymentConfigs.cardProviderMaps[key]
  if (cardProvider) return cardProvider

  const qrProvider = paymentConfigs.qrProviderMaps[key]
  if (qrProvider) return qrProvider

  const paymentMethod = paymentConfigs.paymentMethodMaps[key]
  if (paymentMethod) return paymentMethod

  const merchantPayment = merchantPayments.find(merchantPayment => merchantPayment?.key === key)

  return {
    tips: merchantPayment?.tips,
    key: merchantPayment?.key ?? key,
    name: merchantPayment?.name ?? key,
    custom: true,
    iconSource: paymentConfigs.otherMethod.iconSource,
    useSmallIcon: true,
  }
}
