import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import colors from '@/theme/colors'

/**
 * @typedef EditButtonProps
 * @property {string?} label
 * @property {() => void} onPress
 */

/**
 *
 * @param {EditButtonProps} props
 * @returns
 */
export default function EditButton (props) {
  const { t } = useTranslation()
  const { label = t('app.component.editButton.title'), onPress } = props

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onPress}
    >
      <Text style={styles.text}>{label}</Text>
      <Image style={styles.icon} source={require('@icons/setting/edit.png')} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.textPrimary,
    borderColor: colors.textPrimary,
    borderBottomWidth: 1.5,
  },
  icon: {
    height: 22,
    width: 22,
  },
})
