import { KeyboardAvoidingView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { produce } from 'immer'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import { getCategoryName } from '@/libs/menu'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import { useHistory } from '@/libs/reactRouter'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import BatchLocaleSelectButton from '@/components/buttons/BatchLocaleSelectButton'
import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import QRCodeDialog from '@/components/dialogs/QRcodeDialog'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

import ImageHeader from '../../ImageHeader'

// eslint-disable-next-line no-unused-vars
import { IAppSet } from 'dimorder-orderapp-lib/dist/types/AppMenu'

const DEFAULT_KEYBOARD = { enabled: true, offset: 0 }

/**
 * @typedef EditSetDetailProps
 * @property {IAppSet} set
 * @property {() => void} onChangeState
 * @property {() => void} setSetState
 * @property {boolean?} disableButton
 */

/**
 *
 * @param {EditSetDetailProps} props
 * @returns
 */
export default function EditSetDetail (props) {
  const { set, onChangeState, setSetState, disableButton } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const clientUrls = useSelector(state => state.config.clientUrls)
  const token = useSelector(state => state.auth.userLogin.token)

  const [keyboardAvoidingView, setKeyboardAvoidingView] = React.useState(DEFAULT_KEYBOARD)
  const [qrCodeDialogOpen, setQRCodeDialogOpen] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const isInventoryEnabled = useSelector(state => state.merchant.data?.setting?.inventory)
  const [isLoading, setIsLoading] = React.useState()

  const takeawayIndex = (set?.prices ?? []).findIndex(p => p.type === 'takeaway')
  const deliveryIndex = (set?.prices ?? []).findIndex(p => p.type === 'storeDelivery')
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const [localeMenus, updateLocaleMenus] = useImmer({}) // {[locale]: IAppMenu}
  const [locale, setLocale] = React.useState(lang)

  const [promotedDaysSelectDialogOpen, setPromotedDaysSelectDialogOpen] = React.useState(false)
  const [categoriesSelectDialogOpen, setCategoriesSelectDialogOpen] = React.useState(false)
  const categories = useSelector(state => state.menu.categories)
  const categoryOptions = _.flatMap(categories)
    .filter(c => c.id !== 'ROOT' && c.id !== 'SET_ROOT' && c.path?.includes('SET_ROOT') && _.isEmpty(c.categories)).map(c => {
      return { value: c.id, display: c.name }
    })

  const weekdayOptions = [
    { value: 0, display: t('app.constant.weekday.sun') },
    { value: 1, display: t('app.constant.weekday.mon') },
    { value: 2, display: t('app.constant.weekday.tue') },
    { value: 3, display: t('app.constant.weekday.wed') },
    { value: 4, display: t('app.constant.weekday.thur') },
    { value: 5, display: t('app.constant.weekday.fri') },
    { value: 6, display: t('app.constant.weekday.sat') },
  ]

  React.useEffect(() => {
    if (set.priceUndetermined) {
      onChangeState('price', 0)
      onChangeState('discount', 0)
    }
  }, [set.priceUndetermined])

  React.useEffect(() => {
    if (set?.id && set.locale !== locale) {
      getLocaleMenu(locale)
    }
  }, [set?.id, set?.locale])

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.menuPanel.set.alert.deleteSet.title'),
      message: `${t('app.page.setting.menuPanel.set.alert.deleteSet.msg')}${set.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            history.replace('/setting/menu/set')
            dispatch(actions.menu.deleteSet(set))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    if (set.name.trim().length === 0) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.set.error.name')))
      return
    }
    if (isNaN(set.price)) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.set.error.price')))
    }

    history.replace('/setting/menu/set')

    if (set.id) {
      const finalLocaleMenus = produce(localeMenus, draft => {
        draft[set.locale] = set
      })
      console.log('finalLocaleMenus', finalLocaleMenus)
      // 將編輯過的每一種語言 update
      const localeNames = { ...set?.localeNames && set?.localeNames }
      _.each(finalLocaleMenus, menu => {
        _.setWith(localeNames, menu.locale, menu.name, Object)
      })
      _.forEach(finalLocaleMenus, (localeMenu) => {
        dispatch(actions.menu.updateSet({ ...localeMenu, localeNames }))
      })
    } else {
      dispatch(actions.menu.createSet(set))
    }
  }

  const onUpload = async () => {
    if (set.id) {
      const url = `${clientUrls.merchantFrontend}/image?menu=true&set=true&id=${set.id}&token=${token}`
      setUrl(await dimorderApi.shortUrl(url))
      setQRCodeDialogOpen(true)
    } else {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.set.error.save')))
    }
  }

  const getLocaleMenu = async (locale = lang) => {
    if (set) {
      // 語言相同，不用處理
      if (locale === set.locale) return

      updateLocaleMenus(draft => {
        // 將上一個語言的餐單存起來
        draft[set.locale ?? 'zh'] = set
      })
    }

    try {
      // 取得新語言的餐單
      setIsLoading(true)
      let localeMenu = {}

      if (set.id) {
        const steps = _.map(set.steps, step => {
          return {
            ...step,
            name: _.get(step, `localeNames.${locale}`, step.name),
            desc: _.get(step, `localeDesc.${locale}`, step.desc),
          }
        })
        localeMenu = {
          ...set,
          name: _.get(set?.localeNames, locale, set?.name),
          desc: _.get(set?.localeDesc, locale, set?.desc),
          steps,
        }
      }

      if (set) {
        // 覆蓋有多國語言的部分
        onChangeState('locale', locale)
        onChangeState('name', localeMenu.name)
        onChangeState('desc', localeMenu.desc)
        onChangeState('steps', localeMenu.steps)
      } else if (localeMenu) {
        // 還沒有 editingMenu，有抓到 localeMenu
        setSetState(localeMenu)
      }
      setIsLoading(false)
    } catch (error) {
      console.log('getMenu error', error)
    }
  }

  return (
    <View style={styles.container}>
      <ImageHeader
        style={{ paddingVertical: 12 }}
        menu={set}
        onPress={onUpload}
      />
      <Header style={styles.header}>
        <HeaderText text={t('app.page.setting.menuPanel.menuInfo.info')} />
        <BatchLocaleSelectButton
          selectedLocale={locale}
          onSelect={locale => {
            getLocaleMenu(locale)
            setLocale(locale)
          }}
          disabled={!set.id || isLoading}
        />
      </Header>
      <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        <KeyboardAvoidingView behavior='position' enabled={keyboardAvoidingView.enabled} keyboardVerticalOffset={keyboardAvoidingView.offset}>
          <SettingList style={{ width: '100%' }}>
            <SettingListEnterRow
              divider
              label={t('app.page.setting.menuPanel.set.setCategory')}
              value={getCategoryName(set?.categoryId)}
              onPress={() => setCategoriesSelectDialogOpen(true)}
            />
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.name')} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={replaceSpace(set.name)}
                  onFocus={() => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: false,
                    })
                  }}
                  onChangeText={(text) => onChangeState('name', text)}
                  onBlur={() => onChangeState('name', replaceSaveSpace(set.name))}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.shortName')} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={replaceSpace(set.shortName)}
                  onFocus={() => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: false,
                    })
                  }}
                  onChangeText={(text) => onChangeState('shortName', text)}
                  onBlur={() => onChangeState('shortName', replaceSaveSpace(set.shortName))}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.set.code')} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={replaceSpace(set.code)}
                  onFocus={() => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: true,
                      offset: 10,
                    })
                  }}
                  onBlur={() => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: false,
                      offset: 50,
                    })
                  }}
                  onChangeText={(text) => onChangeState('code', text)}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.desc')} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={replaceSpace(set.desc)}
                  onFocus={() => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: true,
                      offset: 50,
                    })
                  }}
                  onBlur={() => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: false,
                      offset: 50,
                    })
                    onChangeState('desc', replaceSaveSpace(set.desc))
                  }}
                  onChangeText={(text) => onChangeState('desc', text, locale)}
                  multiline
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider={!!set.promoted}>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.promoted')} style={styles.leftText} />
              <ToggleSwitch
                value={set.promoted}
                onChangeValue={() => onChangeState('promoted', !set.promoted)}
                size={23}
              />
            </SettingListItem>
            {
              set.promoted && (
                <SettingListEnterRow
                  divider
                  label={t('app.page.setting.menuPanel.menuInfo.promoDate')}
                  value={set.promotedDays?.length > 0
                    ? set.promotedDays.map(d =>
                      weekdayOptions.find(o =>
                        o.value === d)?.display).join() : t('app.common.null')}
                  onPress={() => setPromotedDaysSelectDialogOpen(true)}
                />
              )
            }
            {
              set.promoted && (
                <SettingListItem divider>
                  <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.startPromoTime')} />
                  <SettingListItemRightAction>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                      <TouchableOpacity
                        onPress={() => dispatch(actions.app.showDateTimePicker('time',
                          moment({
                            hour: _.get(set, 'promotedTimeRange[0].hour'),
                            minute: _.get(set, 'promotedTimeRange[0].minute'),
                          }).toDate(),
                          (value) => {
                            const selectedStart = moment(value)
                            const promotedTimeRange = set.promotedTimeRange
                              ? [{ ...set.promotedTimeRange[0] }, { ...set.promotedTimeRange[1] }]
                              : [{ hour: 0, minute: 0, second: 0 }, { hour: 0, minute: 0, second: 0 }]
                            promotedTimeRange[0].minute = Number(selectedStart.format('mm'))
                            promotedTimeRange[0].hour = Number(selectedStart.format('HH'))
                            onChangeState('promotedTimeRange', promotedTimeRange)
                          },
                          moment({
                            hour: 0,
                            minute: 0,
                          }).toDate(),
                        ))}
                      >
                        <Text>
                          {
                            _.get(set, 'promotedTimeRange[0]')
                              ? moment({ hour: _.get(set, 'promotedTimeRange[0].hour'), minute: _.get(set, 'promotedTimeRange[0].minute') }).format('HH : mm')
                              : '- - : - -'
                          }
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </SettingListItemRightAction>
                </SettingListItem>
              )
            }
            {
              set.promoted && (
                <SettingListItem divider>
                  <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.endPromoTime')} />
                  <SettingListItemRightAction>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                      <TouchableOpacity
                        onPress={() => dispatch(actions.app.showDateTimePicker('time',
                          moment({
                            hour: _.get(set, 'promotedTimeRange[1].hour'),
                            minute: _.get(set, 'promotedTimeRange[1].minute'),
                          }).toDate(),
                          (value) => {
                            const selectedStart = moment(value)
                            const promotedTimeRange = set.promotedTimeRange
                              ? [{ ...set.promotedTimeRange[0] }, { ...set.promotedTimeRange[1] }]
                              : [{ hour: 0, minute: 0, second: 0 }, { hour: 0, minute: 0, second: 0 }]
                            promotedTimeRange[1].minute = Number(selectedStart.format('mm'))
                            promotedTimeRange[1].hour = Number(selectedStart.format('HH'))
                            onChangeState('promotedTimeRange', promotedTimeRange)
                          },
                          moment({
                            hour: _.get(set, 'promotedTimeRange[0].hour'),
                            minute: _.get(set, 'promotedTimeRange[0].minute'),
                          }).toDate(),
                        ))}
                      >
                        <Text>
                          {
                            _.get(set, 'promotedTimeRange[1]')
                              ? moment({ hour: _.get(set, 'promotedTimeRange[1].hour'), minute: _.get(set, 'promotedTimeRange[1].minute') }).format('HH : mm')
                              : '- - : - -'
                          }
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </SettingListItemRightAction>
                </SettingListItem>
              )
            }
            {
              set.promoted && (
                <SettingListItem>
                  <SettingListItemRightAction>
                    <Button
                      title={t('app.page.setting.menuPanel.menuInfo.clearTime')}
                      style={styles.button}
                      backgroundColor={colors.primary}
                      textBold
                      onPress={() => onChangeState('promotedTimeRange', [])}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              )
            }
            {
              isInventoryEnabled && (
                <SettingListItem divider>
                  <SettingListItemText text='庫存' />
                  <SettingListItemRightAction>
                    <SettingListInput
                      value={(set.inventory || 0).toString()}
                      onChangeText={(text) => {
                        const amount = parseInt(text) || 0
                        onChangeState('inventory', amount)
                      }}
                      keyboardType='decimal-pad'
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              )
            }
            {isInventoryEnabled && (
              <SettingListItem divider>
                <SettingListItemText text='低庫存預警' />
                <SettingListItemRightAction>
                  <SettingListInput
                    value={(set.stockAlertLevel || 0).toString()}
                    onChangeText={(text) => {
                      const amount = parseInt(text) || 0
                      onChangeState('stockAlertLevel', amount)
                    }}
                    keyboardType='decimal-pad'
                  />
                </SettingListItemRightAction>
              </SettingListItem>
            )}
          </SettingList>

          <Header>
            <HeaderText text={t('app.page.setting.menuPanel.menuInfo.price')} />
          </Header>
          <SettingList style={{ width: '100%' }}>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.price')} />
              <SettingListItemRightAction>
                {/* 當priceUndetermine=true時，關閉編輯 */}
                <SettingListInput
                  keyboardType='decimal-pad'
                  value={set.price.toString() ?? ''}
                  onFocus={() => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: true,
                      offset: 100,
                    })
                  }}
                  onBlur={(event) => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: false,
                      offset: 50,
                    })
                    onChangeState('price', Number(event.nativeEvent.text))
                  }}
                  onChangeText={(text) => {
                    if (!Number.isNaN(Number(text))) {
                      onChangeState('price', text)
                    }
                  }}
                  disabled={set.priceUndetermined}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.discount')} />
              <SettingListItemRightAction>
                {/* 當priceUndetermine=true時，關閉編輯 */}
                <SettingListInput
                  keyboardType='decimal-pad'
                  value={set.discount.toString() ?? ''}
                  onFocus={() => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: true,
                      offset: 150,
                    })
                  }}
                  onBlur={(event) => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: false,
                      offset: 50,
                    })
                    onChangeState('discount', Number(event.nativeEvent.text))
                  }}
                  onChangeText={(text) => {
                    if (!Number.isNaN(Number(text))) {
                      onChangeState('discount', text)
                    }
                  }}
                  disabled={set.priceUndetermined}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.takeawayPrice')} />
              <SettingListItemRightAction>
                {/* 當priceUndetermine=true時，關閉編輯 */}
                <SettingListInput
                  keyboardType='decimal-pad'
                  value={String(_.get(set, `prices[${takeawayIndex}].price`, ''))}
                  placeholder={String(set.price)}
                  onFocus={(event) => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: true,
                      offset: 200,
                    })
                  }}
                  onChangeText={(text) => {
                    if (takeawayIndex === -1) {
                      const prices = set.prices ? [...set.prices] : []
                      prices.push({
                        type: 'takeaway',
                        price: text,
                        discount: 0,
                      })
                      onChangeState('prices', prices)
                    } else {
                      onChangeState(`prices[${takeawayIndex}].price`, text)
                    }
                  }}
                  disabled={set.priceUndetermined}
                  onBlur={(event) => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: false,
                      offset: 50,
                    })
                    if (takeawayIndex > -1) {
                      onChangeState(`prices[${takeawayIndex}].price`, Number(event.nativeEvent.text.trim() === '' ? set.price : event.nativeEvent.text), true)
                    }
                  }}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.deliveryPrice')} />
              <SettingListItemRightAction>
                {/* 當priceUndetermine=true時，關閉編輯 */}
                <SettingListInput
                  keyboardType='decimal-pad'
                  value={String(_.get(set, `prices[${deliveryIndex}].price`, ''))}
                  placeholder={String(_.get(set, `prices[${takeawayIndex}].price`, set.price))}
                  onFocus={(event) => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: true,
                      offset: 200,
                    })
                  }}
                  onChangeText={(text) => {
                    if (deliveryIndex === -1) {
                      const prices = set.prices ? [...set.prices] : []
                      prices.push({
                        type: 'storeDelivery',
                        price: text,
                        discount: 0,
                      })
                      onChangeState('prices', prices)
                    } else {
                      onChangeState(`prices[${deliveryIndex}].price`, text)
                    }
                  }}
                  disabled={set.priceUndetermined}
                  onBlur={(event) => {
                    setKeyboardAvoidingView({
                      ...keyboardAvoidingView,
                      enabled: false,
                      offset: 50,
                    })
                    if (deliveryIndex > -1) {
                      onChangeState(`prices[${deliveryIndex}].price`, Number(event.nativeEvent.text.trim() === '' ? _.get(set, `prices[${takeawayIndex}].price`, set.price) : event.nativeEvent.text), true)
                    }
                  }}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.constant.order.priceUndetermined')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={set.priceUndetermined}
                  onChangeValue={() => onChangeState('priceUndetermined', !set.priceUndetermined)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.noDiscount')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={set.excludedDiscount}
                  onChangeValue={() => onChangeState('excludedDiscount', !set.excludedDiscount)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.noSurcharge')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={set.excludedSurcharge}
                  onChangeValue={() => onChangeState('excludedSurcharge', !set.excludedSurcharge)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          </SettingList>
        </KeyboardAvoidingView>
      </ScrollView>
      <View style={styles.buttonContainer}>
        <Button
          textBold
          title={t('app.common.delete')}
          backgroundColor={colors.primary}
          disabled={!set.id || disableButton}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
          disabled={disableButton}
        />
      </View>
      <QRCodeDialog
        open={qrCodeDialogOpen}
        onClose={() => setQRCodeDialogOpen(false)}
        url={url}
        title={t('app.page.setting.menuPanel.menuInfo.image')}
        set
        id={set.id}
      />

      <OptionsSelectDialog
        title={t('app.constant.weekday.week')}
        multiple
        open={promotedDaysSelectDialogOpen}
        onClose={() => setPromotedDaysSelectDialogOpen(false)}
        options={weekdayOptions}
        defaultValue={set.promotedDays}
        onSubmit={(promotedDays) => {
          onChangeState('promotedDays', _.orderBy(promotedDays))
        }}
        modalProps={{ noTitle: true }}
      />

      <OptionsSelectDialog
        title={t('app.page.setting.menuPanel.set.setCategory')}
        open={categoriesSelectDialogOpen}
        onClose={() => setCategoriesSelectDialogOpen(false)}
        options={categoryOptions}
        defaultValue={set.categoryId}
        onSubmit={(id) => {
          onChangeState('categoryId', id)
        }}
        modalProps={{ noTitle: true }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})
