import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'

import colors from '@/theme/colors'

import LoadingIcon from './LoadingIcon'

/**
 * @typedef BigIconButton
 * @property {string?} iconSource
 * @property {string?} text
 * @property {string?} onlyText
 * @property {() => void} onPress
 * @property {boolean?} disabled
 * @property {boolean?} loading
 * @property {*} backgroundColor
 * @property {ViewProps['style']?} style
 * @property {ImageProps['style']?} iconStyle
 */

/**
 *
 * @param {BigIconButtonProps} props
 * @returns
 */
export default function BigIconButton (props) {
  const {
    iconSource,
    text,
    onlyText,
    onPress,
    disabled,
    loading,
    backgroundColor,
    style,
    iconStyle,
  } = props

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress}>
      <View style={[styles.container, disabled && styles.disabled, style, { backgroundColor }]}>
        {iconSource && (
          (loading && disabled)
            ? <LoadingIcon />
            : <Image style={[styles.icon, iconStyle]} source={iconSource} />
        )}
        {text &&
          <Text style={styles.text}>{text}</Text>}
        {onlyText &&
          <Text style={styles.onlyText}>{onlyText}</Text>}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 106,
    height: 86,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  disabled: {
    opacity: 0.4,
  },
  icon: {
    width: 80,
    height: 80,
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.white,
    textAlign: 'center',
  },
  onlyText: {
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.white,
  },
})
