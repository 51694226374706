import _ from 'lodash'

import { useSelector } from '@/redux'

function toUpperCase (string) {
  if (typeof string === 'string') {
    return string.toUpperCase()
  }
  return string
}

export function matchItemCode (item, searchText) {
  if (!item) return false
  return _.includes(toUpperCase(item.shortCode), toUpperCase(searchText))
}

export function matchPath (path, categoryPath, options = { searchAllInRoot: false }) {
  return (
    options.searchAllInRoot &&
    categoryPath === 'ROOT'
  ) || path.startsWith(categoryPath)
}

export function matchSearchText (item, searchText, options = { caseSensitive: false }) {
  if (!item) return false
  const targets = [item.code, item.shortCode, item.name, item.shortName]
  // search text case insensitive
  return targets.find(target => {
    if (options.caseSensitive) {
      return _.includes(target, searchText)
    } else {
      return _.includes(toUpperCase(target), toUpperCase(searchText))
    }
  })
}

export function useSearchedMenu () {
  const searchText = useSelector(state => state.menu.searchText)
  const isNumPadOrder = useSelector(state => state.app.settings?.numPadOrder)
  const categories = useSelector(state => state.menu.categories)
  const menus = useSelector(state => state.menu.menus)
  const sets = useSelector(state => state.menu.sets)
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const selectedSet = useSelector(state => state.menu.selectedSet)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)

  if (!selectedCategory) return []

  // 正在套餐中，直接返回套餐步驟餐點
  if (selectedSet) {
    return selectedSet.menus.filter(menuItem => menuItem.step === selectedSetStep?.id)
  }

  // 沒搜尋，直接回傳選擇分類下的內容
  // * 如果要讓數字鍵盤模式預設顯示全部東西，把 "&& !isNumPadOrder" 拿掉
  if (!searchText && !isNumPadOrder) {
    const menu = _.concat(selectedCategory.categories, selectedCategory.sets, selectedCategory.menus)

    // 在根目錄時顯示套餐目錄
    if (selectedCategory.id === 'ROOT') {
      menu.unshift(categories.SET_ROOT)
    }
    return menu.filter(m => m !== undefined)
  }

  // 數字鍵盤模式只找選擇的分類下
  if (isNumPadOrder) {
    // 沒搜尋，回傳空 []
    if (!searchText) {
      return []
    }
    if (selectedCategory.path.startsWith('ROOT')) {
      // 找出相關餐點
      const filteredMenus = _.filter(menus, menuItem => {
        // 從非套餐餐點找
        return !menuItem.step && matchSearchText(menuItem, searchText)
      })

      // 去除重複
      return _.uniqBy(filteredMenus, 'id')
    }
    if (selectedCategory.path.startsWith('SET_ROOT')) {
      // 從套餐找
      const filteredSets = _.filter(sets, set => matchSearchText(set, searchText))

      // 去除重複
      return _.uniqBy(filteredSets, 'id')
    }
  } else {
    // 找出相關分類
    const filteredCategories = _.filter(categories, category => {
      return selectedCategory !== category &&
          matchPath(category.path, selectedCategory.path, { searchAllInRoot: true }) &&
          matchSearchText(category, searchText)
    })

    // 找出相關套餐（套餐本身或套餐中餐點中包含 search text 的套餐）
    const filteredSets = _.filter(sets, set => {
      return matchPath(set.path, selectedCategory.path, { searchAllInRoot: true }) &&
    (
      matchSearchText(set, searchText) ||
    _.find(set.menus, (menu) => matchSearchText(menu, searchText))
    )
    })

    // 找出相關餐點(單點)
    const filteredMenus = _.filter(menus, menuItem => {
      return !menuItem.step &&
        matchPath(menuItem.path, selectedCategory.path, { searchAllInRoot: true }) && matchSearchText(menuItem, searchText)
    })

    const results = _.concat(filteredCategories, filteredSets, filteredMenus)
    // 去除重複
    return _.uniqBy(results, 'id')
  }

  // final fallback
  return []
}
