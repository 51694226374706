import { View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import CodePushUpdateDialog from '@/components/dialogs/CodePushUpdateDialog'
import DateTimeDialog from '@/components/dialogs/DateTimeDialog'
import LoginDialog from '@/components/dialogs/LoginDialog'
import OfflineDialog from '@/components/dialogs/OfflineDialog'
import SnackbarContainer from '@/components/Snackbar/Container'
import StorageDialog from '@/components/dialogs/StorageDialog'

import useCheckConnections from '@/hooks/useCheckConnections'
import useInitializeApp from '@/hooks/useInitializeApp'
import useScreenLock from '@/hooks/useScreenLock'
import useSoundNotifications from '@/hooks/useSoundNotifications'
import useSynchronizeOrders from '@/hooks/useSynchronizeOrders'

import AlertQueue from './AlertQueue'
import AppRouter from './AppRouter'
import Loading from './Loading'
import SyncOrderProcess from './SyncOrderProcess'
import VersionLabel from './VersionLabel'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Main (props) {
  const isLocalDbCleanCompleted = useSelector(state => state.app.isLocalDbCleanCompleted, _.isEqual)

  // *** (1) 初始環境
  useInitializeApp()
  // *** (2) 檢查連線狀態
  useCheckConnections()
  // *** (3) 同步訂單
  useSynchronizeOrders()
  // *** (4) 提示音效
  useSoundNotifications()
  // *** (5) 螢幕鎖定
  const panResponder = useScreenLock()

  const DateTimeDialogMemo = React.useMemo(() => <DateTimeDialog />, [])

  // 未處理好 Storage Migration 及 LocalDb Clean 前, 只顯示 Loading, StorageDialog, 及避免子元件的 useEffect 先行
  if (!isLocalDbCleanCompleted) {
    return (
      <View style={{ flex: 1 }} {...panResponder.panHandlers}>
        <Loading />
        <StorageDialog />
      </View>
    )
  }

  return (
    <>
      <View style={{ flex: 1 }} {...panResponder.panHandlers}>
        <Loading />
        <AlertQueue />
        <CodePushUpdateDialog />
        <AppRouter />
        {DateTimeDialogMemo}
        <LoginDialog />
        <VersionLabel />
        <SyncOrderProcess />
        <OfflineDialog />
        <SnackbarContainer />
        <StorageDialog />
      </View>
    </>
  )
}
