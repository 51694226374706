import { useEffect, useState } from 'react'

const DEFALT_CONFIG = {
  duration: 5 * 1000,
  limit: 10,
}

/**
 * @typedef UsePressedTimesConfig
 * @property {number} [duration] 預設 5 秒
 * @property {number} [limit] 預設 10 次
 */

/**
 * 於 duration 內達到 limit 次數後執行 callback
 * @param {() => void} callback
 * @param {UsePressedTimesConfig} config
 * @returns
 */
export default function usePressedTimes (callback = () => {}, config) {
  const { duration, limit } = { ...DEFALT_CONFIG, config }
  const [pressedTimes, setPressedTimes] = useState(0)

  useEffect(() => {
    // 若期間內沒有更新 pressedTimes 則重設
    const timeoutId = setTimeout(() => {
      if (pressedTimes > 0) {
        setPressedTimes(0)
      }
    }, duration)

    return () => clearTimeout(timeoutId)
  }, [pressedTimes, duration])

  const handlePress = () => {
    if (pressedTimes === limit - 1) {
      // 若達上限則重設 pressedTimes 並執行 callback
      setPressedTimes(0)
      callback()
    } else {
      // 若未達上限則增加 pressTimes 次數
      setPressedTimes((prev) => prev + 1)
    }
  }

  return { pressedTimes, setPressedTimes, handlePress }
}
