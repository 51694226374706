const betaConfig = {
  wssUrl: 'wss://beta-api.dimorder.com/',
  apiUrls: {
    go: 'https://beta-api.dimorder.com',
    node: 'https://beta-node.dimorder.com',
    urlShortener: 'https://s.dimorder.com',
  },
  clientUrls: {
    customerWeb: 'https://beta.dimorder.com',
    merchantFrontend: 'https://beta-merchant.dimorder.com',
    customerAppWeb: 'https://beta-c.dimorder.com',
    customerApp: 'https://beta-app.dimorder.com',
  },
}

export default betaConfig
