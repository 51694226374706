import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from '@/libs/reactRouter'
import React from 'react'
import colors from '@/theme/colors'
import i18n from '@/i18n'

export default function NavButton ({ item }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const path = item.path?.split(':')[0] || item.path // 排除dynamic param
  const isActive = location.pathname.startsWith(path)
  const isSavedSetting = useSelector(state => state.app.isSavedSetting)
  const handleSwitchNav = (item) => {
    switch (item.key) {
      case 'printing':
        dispatch(actions.auth.permissionCheck('printer-setting', () => {
          history.push(item.path)
        }))
        break
      case 'order':
        dispatch(actions.auth.permissionCheck('menu', () => {
          history.push(item.path)
        }))
        break
      case 'menu':
        dispatch(actions.auth.permissionCheck('menu', () => {
          history.push(item.path)
        }))
        break
      default:
        history.push(item.path)
    }
  }
  return (
    <TouchableOpacity
      onPress={() => {
        dispatch(actions.setting.resetAllSubView())
        if (item.path) {
          if (!isSavedSetting) {
            dispatch(actions.app.showAlert({
              title: i18n.t('app.page.order.stash.alert.leave.title'),
              message: i18n.t('app.page.order.stash.alert.leave.msg2'),
              buttons: [
                {
                  children: i18n.t('app.page.order.stash.alert.leave.button.continue'),
                  onPress: () => {
                    dispatch(actions.app.updateIsSavedSetting(true))
                    handleSwitchNav(item)
                  },
                },
                {
                  backgroundColor: colors.light,
                  textColor: colors.textTertiary,
                  children: i18n.t('app.page.order.stash.alert.leave.button.cancel'),
                  onPress: () => { },
                },
              ],
            }))
          } else {
            handleSwitchNav(item)
          }
        }
      }}
      style={[styles.container, isActive && { backgroundColor: colors.light }]}
    >
      <Image
        style={styles.icon}
        source={item.iconSource}
      />
      <Text style={styles.text}>
        {item.text}
      </Text>

      {/* 邊緣圓角 */}
      {isActive &&
        <View style={styles.activeEdge}>
          <View style={[styles.mask, { borderBottomRightRadius: 24, top: 0 }]} />
          <View style={[styles.mask, { borderTopRightRadius: 24, bottom: 0 }]} />
        </View>}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 80,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 24,
  },
  icon: {
    width: 44,
    height: 44,
  },
  text: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.primary,
  },
  activeEdge: {
    height: 80 + 24 * 2,
    width: 24,
    backgroundColor: colors.light,
    position: 'absolute',
    top: -24,
    right: 0,
  },
  mask: {
    height: 24,
    width: '100%',
    backgroundColor: colors.lightGray3,
    position: 'absolute',
  },
})
