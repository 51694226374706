import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import Column from '@/components/Column'
import OrderItem from '../../OrderList/OrderItem'
import React from 'react'
import colors from '@/theme/colors'

export default function OrderResultItem ({ order, onPress }) {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onPress}
    >
      <OrderItem
        order={order}
        relativePosition
      />
      <Column style={styles.info}>
        <Text style={styles.text}>
          {order.area} {order.table}
        </Text>
        <Text
          style={styles.text}
          numberOfLines={1}
        >
          {`${order.customer.name}/${order.customer.phone}`}
        </Text>
      </Column>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 200,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 24,
    marginBottom: 15,
  },
  info: {
    flex: 1,
    marginLeft: 15,
  },
  text: {
    fontSize: 15,
    fontWeight: '500',
    color: colors.textPrimary,
  },
})
