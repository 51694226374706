import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'

export default function FilterButton ({ width, marginRight, text, selected, onPress }) {
  const containerStyle = {
    width: width,
    backgroundColor: selected ? colors.primary : colors.transparent,
  }
  const textStyle = {
    color: selected ? colors.white : colors.primary,
  }
  const { t } = useTranslation()
  return (
    <TouchableOpacity
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <Text style={[styles.text, textStyle]}>{t(text)}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 45,
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: colors.primary,
    borderRadius: 8,
    marginHorizontal: 5,
    marginVertical: 8,
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
})
