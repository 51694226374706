import { Image, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef } from 'react'
import colors from '@/theme/colors'

export default function SearchBar ({ active, onOpen, onClose, searchText, setSearchText, style }) {
  const inputRef = useRef()
  const { t } = useTranslation()

  const activeStyle = {
    flexGrow: active ? 1 : 0,
    flexBasis: 35,
  }

  useEffect(() => {
    if (active) {
      inputRef.current.focus()
    }
  }, [active])

  return (
    <TouchableOpacity
      style={[styles.container, activeStyle, style]}
      disabled={active}
      onPress={onOpen}
    >
      <Image
        style={styles.icon}
        source={require('@icons/order-header/search.png')}
      />

      {active &&
        <View style={styles.input}>
          <TextInput
            ref={inputRef}
            value={searchText}
            style={styles.textInput}
            placeholder={t('app.page.orderHistory.searchHeader')}
            placeholderTextColor={colors.textPlaceholder}
            onChangeText={setSearchText}
          />
        </View>}

      {active &&
        <TouchableOpacity onPress={onClose}>
          <Image
            style={styles.closeIcon}
            source={require('@icons/order-header/close.png')}
          />
        </TouchableOpacity>}

    </TouchableOpacity>

  )
}

const styles = StyleSheet.create({
  container: {
    height: 35,
    // width: 35,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 35 / 2,
  },
  icon: {
    width: 35,
    height: 35,
  },
  input: {
    flex: 1,
  },
  textInput: {
    fontSize: 17,
  },
  closeIcon: {
    width: 28,
    height: 28,
    margin: 7,
  },
})
