import { StyleSheet, Text, TextInput, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import OptionsPanel from '@/components/Setting/OptionsPanel'

/**
 * @typedef OrderCancelDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {() => void} onSubmit
 * @property {string} title
 * @property {string} placeholder
 * @property {string?} submitButtonText
 * @property {string?} defaultText
 * @property {string?} warningText
 * @property {TextInputProps['keyboardType']?} keyboardType
 * @property {string?} orderType
 * @property {boolean?} secureTextEntry
 * @property {boolean?} isItem
 */

/**
 *
 * @param {OrderCancelDialogProps} props
 * @returns
 */
export default function OrderCancelDialog (props) {
  const { t } = useTranslation()
  const {
    open,
    onClose,
    onSubmit,
    title,
    placeholder,
    submitButtonText = t('app.common.send'),
    defaultText = '',
    warningText = '',
    keyboardType = 'default',
    orderType = 'table',
    secureTextEntry = false,
    isItem = false,
  } = props
  const [reason, setReason] = React.useState(defaultText)
  const [otherReason, setOtherReason] = React.useState('')
  const cancelReason = [
    { value: 'invalid', display: t('app.component.cancelReason.invalid') },
    { value: 'trial', display: t('app.component.cancelReason.trial') },
    { value: 'rejected', display: t('app.component.cancelReason.rejected') },
  ]
  const takeawayCancelReason = [
    { value: 'unavailable', display: t('app.component.cancelReason.unavailable') },
    { value: 'busy', display: t('app.component.cancelReason.busy') },
    { value: 'closed', display: t('app.component.cancelReason.closed') },
  ]
  const customReason = useSelector(state => state.setting.customReason)
  const type = isItem ? 'item' : orderType === 'table' ? 'dineIn' : 'takeaway'
  const defaultReason = orderType === 'table' ? cancelReason : takeawayCancelReason
  const filteredReason = _.concat(defaultReason, [...customReason].filter(r => (r.type === '' || r.type === type) && r.enable).map(r => {
    return { value: r.name, display: r.name }
  }))
  filteredReason.push({ value: 'others', display: t('app.component.cancelReason.others') })

  return (
    <CenterModal
      enablePressOutsideClose
      title={title}
      visible={open}
      onClose={onClose}
      contentContainerStyle={styles.contentContainer}
    >
      <Text style={styles.text}>{warningText}</Text>
      <OptionsPanel
        style={{ width: '100%', minWidth: '25%' }}
        options={filteredReason}
        title={t('app.component.cancelReason.title')}
        defaultValue={false}
        onChangeValue={(value) => {
          setReason(value)
        }}
      />
      {
        reason === 'others' && (
          <View style={styles.inputContainer}>
            <TextInput
              keyboardType={keyboardType}
              secureTextEntry={secureTextEntry}
              style={styles.input}
              placeholder={placeholder}
              placeholderTextColor='#B0B0B0'
              value={otherReason}
              onChangeText={(text) => setOtherReason(text)}
            />
          </View>
        )
      }
      <View style={styles.buttons}>
        <Button
          title={t('app.common.clear')}
          onPress={() => setOtherReason('')}
        />
        <Button
          title={submitButtonText}
          disabled={!reason || (reason === 'others' && !otherReason)}
          onPress={() => {
            onSubmit(reason === 'others' ? otherReason : reason)
            setReason('')
            onClose()
          }}
        />
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 500,
    padding: 16,
    minHeight: 325,
  },
  inputContainer: {
    width: '90%',
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.primary,
    maxHeight: 35,
  },
  input: {
    fontSize: 16,
    width: '100%',
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  text: {
    fontSize: 28,
    fontWeight: '500',
    color: colors.primary,
  },
})
