import { StyleSheet, View } from 'react-native'
import React from 'react'
import TableCell from './TableCell'
import colors from '@/theme/colors'

export default function TableHeader ({ headers, widths, justifyContents, style, textStyle }) {
  return (
    <View style={[styles.container, style]}>
      {headers.map((header, index) =>
        <TableCell
          key={`table-header-${header}-${index}`}
          value={header}
          isHeader
          width={widths[index]}
          justifyContent={justifyContents[index]}
          textStyle={textStyle}
        />)}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 27,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.primary,
    alignItems: 'center',
    marginTop: 0.5,
  },
})
