import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { useSelectedBatch } from '@/hooks/order'
import colors from '@/theme/colors'

import Alert from '@/components/Alert'
import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'

/**
 * @typedef StashDialogProps
 * @property {*} selectedStash
 * @property {() => void} onClose
 */

/**
 *
 * @param {StashDialogProps} props
 * @returns
 */
export default function StashDialog (props) {
  const { selectedStash, onClose } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedBatch = useSelectedBatch()

  const [showStashNotEmptyAlert, setShowStashNotEmptyAlert] = React.useState(false)
  const [showDeleteStashAlert, setShowDeleteStashAlert] = React.useState(false)

  // 套用選擇的 stash 並關閉 BatchStashesDialog
  const handlePopStash = (stash) => {
    // 套用選擇的 stash
    dispatch(actions.orderBatch.popStash(stash))

    // close BatchStashesDialog
    dispatch(actions.app.closeDialog(['order', 'batchStashes']))
  }

  const renderBatchItem = ({ item: batchItem, index }) => {
    return (
      <Pressable>
        <View key={index} style={styles.stashItemRow}>
          <Text numberOfLines={1} style={styles.stashItemName}>{batchItem.name}</Text>
          <Text>{`x ${batchItem.quantity}`}</Text>
        </View>
      </Pressable>
    )
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title={t('app.page.order.stash.list.title')}
      visible={Boolean(selectedStash)}
      onClose={onClose}
      contentContainerStyle={styles.modal}
    >
      <FlatList
        data={selectedStash?.batch?.items}
        renderItem={renderBatchItem}
        keyExtractor={(item, index) => String(index)}
        showsVerticalScrollIndicator={false}
      />
      <View style={styles.stashItemListButtons}>
        <Button
          title={t('app.common.delete')}
          type='TouchableOpacity'
          backgroundColor={colors.light}
          textColor={colors.textTertiary}
          textStyle={{ fontWeight: 'bold' }}
          onPress={() => {
            // 確認刪除暫存
            setShowDeleteStashAlert(true)
          }}
        />
        <Button
          title={t('app.common.use')}
          type='TouchableOpacity'
          textStyle={{ fontWeight: 'bold' }}
          onPress={() => {
            if (_.isEmpty(selectedBatch.items)) {
              // 套用選擇的 stash 並關閉 BatchStashesDialog
              handlePopStash(selectedStash)
              onClose()
            } else {
              // 問要不要先存到暫存區
              setShowStashNotEmptyAlert(true)
            }
          }}
        />
      </View>

      <Alert
        open={showStashNotEmptyAlert}
        handleClose={() => setShowStashNotEmptyAlert(false)}
        alertConfig={{
          modalProps: { enablePressOutsideClose: true },
          title: t('app.page.order.stash.alert.notEmpty.title'),
          message: t('app.page.order.stash.alert.notEmpty.msg'),
          buttons: [
            {
              title: t('app.page.order.stash.alert.notEmpty.button.save'),
              type: 'TouchableOpacity',
              textStyle: { fontWeight: 'bold' },
              onPress: () => {
                // 儲存 selectedBatch
                dispatch(actions.orderBatch.stashBatch())
                // 套用選擇的 stash 並關閉 BatchStashesDialog
                handlePopStash(selectedStash)
                setShowStashNotEmptyAlert(false)
                onClose()
              },
            },
            {
              title: t('app.common.deleteAll'),
              type: 'TouchableOpacity',
              backgroundColor: colors.light,
              textColor: colors.textTertiary,
              textStyle: { fontWeight: 'bold' },
              onPress: () => {
                // 套用選擇的 stash 並關閉 BatchStashesDialog
                handlePopStash(selectedStash)
                setShowStashNotEmptyAlert(false)
                onClose()
              },
            },
          ],
        }}
      />
      <Alert
        open={showDeleteStashAlert}
        handleClose={() => setShowDeleteStashAlert(false)}
        alertConfig={{
          modalProps: { enablePressOutsideClose: true },
          title: t('app.page.order.stash.alert.delete.title'),
          message: t('app.page.order.stash.alert.delete.msg'),
          buttons: [
            {
              title: t('app.common.yes'),
              type: 'TouchableOpacity',
              textStyle: { fontWeight: 'bold' },
              onPress: () => {
                console.log('dropStash', selectedStash)
                dispatch(actions.orderBatch.dropStash(selectedStash))
                setShowDeleteStashAlert(false)
                onClose()
              },
            },
            {
              title: t('app.common.no'),
              type: 'TouchableOpacity',
              backgroundColor: colors.light,
              textColor: colors.textTertiary,
              textStyle: { fontWeight: 'bold' },
              onPress: () => {
                setShowDeleteStashAlert(false)
              },
            },
          ],
        }}
      />
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  modal: {
    width: 480,
    minHeight: 150,
    maxHeight: 300,
    alignItems: 'center',
    overflow: 'hidden',
  },
  stashItemListButtons: {
    flexShrink: 0,
    flexDirection: 'row',
    alignSelf: 'center',
    padding: 8,
  },
  stashItemRow: {
    width: 240,
    minHeight: 28,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: colors.textPrimary,
    fontWeight: 'normal',
  },
  stashItemName: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 16,
    overflow: 'hidden',
    textAlign: 'left',
  },
})
