import i18n from '../i18n'

const orderTags = [
  { id: 'super-vip', name: '重要客人' },
  { id: 'many-children', name: '小孩多' },
  { id: 'bar-table', name: '吧台' },
  { id: 'vegetarian', name: '素食' },
]

function itemTags () {
  const tag = [
    { id: 'avoidPrinting', name: i18n.t('app.constant.orderAction.ignore') },
    { id: 'serve-later', name: i18n.t('app.constant.orderAction.serveLater') },
    // { id: 'other', name: i18n.t('app.common.other') },
  ]
  return tag
}

export const getOrderTags = () => orderTags
export const getItemTags = () => itemTags()
