import { FlatList, Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import styles from '../styles'

export default function ReceiptInfo (props) {
  const { items } = props
  const { t } = useTranslation()
  const merchant = useSelector(state => state.merchant.data)
  const { tableNo, customerNo, serialNo, createAt, printAt } = items

  return (
    <>
      {
        tableNo.enable && (
          <View style={styles[tableNo.align]}>
            <Text style={[tableNo.bold && styles.bold, styles.small]}>{t('app.page.setting.printer.customizedSetting.table')}：A1</Text>
          </View>
        )
      }
      {
        customerNo.enable && (
          <View style={styles[customerNo.align]}>
            <Text style={[customerNo.bold && styles.bold, styles.small]}>{t('app.page.setting.printer.customizedSetting.people')}：1</Text>
          </View>
        )
      }
      {
        serialNo.enable && (
          <View style={styles[serialNo.align]}>
            <Text style={[serialNo.bold && styles.bold, styles.small]}>{t('app.page.setting.printer.customizedSetting.invoice')}：001-00011</Text>
          </View>
        )
      }
      {
        createAt && (
          <View style={styles[createAt.align]}>
            <Text style={[createAt.bold && styles.bold, styles.small]}>{t('app.page.setting.printer.customizedSetting.orderAt')}：{moment().startOf('day').format(createAt.format)}</Text>
          </View>
        )
      }
      {
        printAt && (
          <View style={styles[printAt.align]}>
            <Text style={[printAt.bold && styles.bold, styles.small]}>{t('app.page.setting.printer.customizedSetting.printAt')}：{moment().startOf('day').format(printAt.format)}</Text>
          </View>
        )
      }
    </>
  )
}
