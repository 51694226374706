import { StyleSheet, Text, View } from 'react-native'
import { useImmer } from 'use-immer'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useHistory } from '@/libs/reactRouter'
import { useTranslation } from 'react-i18next'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import Row from '@/components/Row'
import RowTextInput from '@/components/InfoDialog/RowTextInput'
import RowToggleInput from '@/components/InfoDialog/RowToggleInput'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem } from 'dimorder-orderapp-lib/dist/types/AppMenu'

const defaultState = {
  name: '',
  min: null,
  max: null,
  price: null,
}

/**
 *
 * @typedef SetMenuDialog
 * @property {IAppMenuItem} setMenu
 * @property {string} setId
 * @property {string} stepId
 */

/**
 *
 * @param {SetMenuDialogProps} props
 * @returns
 */
export default function SetMenuDialog (props) {
  const { setMenu, setId, stepId } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const visible = useSelector(state => state.app.dialog.setting.setMenu)
  const history = useHistory()
  const [setMenuState, setSetMenuState] = useImmer(defaultState)
  const [error, setError] = React.useState(null)
  const [hasChanged, setHasChanged] = React.useState(false)
  const [isVisible, setVisible] = React.useState(visible)
  const takeawayIndex = (setMenuState?.prices ?? []).findIndex(p => p.type === 'takeaway')
  const deliveryIndex = (setMenuState?.prices ?? []).findIndex(p => p.type === 'storeDelivery')

  React.useEffect(() => {
    if (setMenu) {
      setSetMenuState(setMenu)
      if (!setMenu.id) {
        setHasChanged(true)
      }
    }
    setVisible(visible)
  }, [setMenu, visible])

  if (!setMenuState) { return null }

  const onChangeState = (path, value) => {
    setHasChanged(true)
    setSetMenuState(draft => {
      _.set(draft, path, value)
    })
  }

  const resetState = () => {
    setSetMenuState(defaultState)
    setError(null)
    setHasChanged(false)
  }

  const onClose = () => {
    resetState()
    dispatch(actions.app.closeDialog(['setting', 'setMenu']))
  }

  const onSubmit = () => {
    // guards
  //   if (String(setMenuState.min).trim().length === 0 ||
  //  String(setMenuState.max).trim().length === 0) {
  //     setError('請輸入必填資料')
  //     return
  //   }

    if (isNaN(setMenuState.min) || isNaN(setMenuState.max) || isNaN(setMenuState.price)) {
      setError(t('app.page.setting.menuPanel.set.error.number'))
      return
    }

    onClose()

    if (setMenu?.id) {
      dispatch(actions.menu.updateSetMenu(setId, setMenuState))
    } else {
      dispatch(actions.menu.addSetMenu(setId, stepId, setMenuState))
    }
  }

  const onDelete = () => {
    setVisible(false)
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.menuPanel.set.alert.deleteItem.title'),
      message: `${t('app.page.setting.menuPanel.set.alert.deleteItem.msg')} ${setMenu.name}？`,
      buttons: [
        {
          title: t('app.common.confirm'),
          type: 'TouchableOpacity',
          textStyle: { fontWeight: 'bold' },
          onPress: () => {
            onClose()
            dispatch(actions.menu.removeSetMenu(setId, setMenu.id))
          },
        },
        {
          title: t('app.common.cancel'),
          type: 'TouchableOpacity',
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          textStyle: { fontWeight: 'bold' },
          onPress: () => {
            setVisible(true)
          },
        },
      ],
    }))
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title={setMenuState?.id ? t('app.page.setting.menuPanel.set.setMenu') : t('app.page.setting.menuPanel.set.addSetMenu')}
      visible={isVisible}
      onClose={onClose}
    >
      <View style={styles.container}>
        <RowTextInput
          label={t('app.page.setting.menuPanel.set.setMenu')}
          placeholder=''
          value={setMenu?.name || ''}
          multiline
          disabled
        />
        {/* <RowTextInput
          label={t('app.page.setting.menuPanel.set.min')}
          placeholder='0'
          keyboardType='number-pad'
          value={setMenuState.min === null ? '' : setMenuState.min.toString()}
          onChangeText={(text) => {
            if (/^\d+$/.test(text)) {
              onChangeState('min', Number(text))
            } else if (text === '') {
              onChangeState('min', 0)
            }
          }}
        /> */}
        <RowTextInput
          label={t('app.page.setting.menuPanel.set.max')}
          placeholder='0'
          keyboardType='number-pad'
          value={setMenuState.max === null ? '' : setMenuState.max.toString()}
          onChangeText={(text) => {
            if (/^\d+$/.test(text)) {
              onChangeState('max', Number(text))
            } else if (text === '') {
              onChangeState('max', 0)
            }
          }}
        />
        <RowTextInput
          label={t('app.page.setting.menuPanel.set.price')}
          placeholder='0'
          keyboardType='number-pad'
          value={setMenuState.price === null ? '' : setMenuState.price.toString()}
          onChangeText={(text) => {
            if (!Number.isNaN(Number(text))) {
              onChangeState('price', text)
            }
          }}
          onBlur={(event) => {
            onChangeState('price', Number(event.nativeEvent.text))
          }}
        />
        <RowTextInput
          label={t('app.page.setting.menuPanel.set.takeawayPrice')}
          placeholder={String(_.get(setMenuState, 'price', '0'))}
          keyboardType='number-pad'
          value={String(_.get(setMenuState, `prices[${takeawayIndex}].price`, ''))}
          onChangeText={(text) => {
            if (!Number.isNaN(Number(text))) {
              if (takeawayIndex === -1) {
                const prices = setMenuState.prices ? [...setMenuState.prices] : []
                prices.push({
                  type: 'takeaway',
                  price: text,
                  discount: 0,
                })
                onChangeState('prices', prices)
              } else {
                onChangeState(`prices[${takeawayIndex}].price`, text)
              }
            }
          }}
          onBlur={(event) => {
            if (takeawayIndex > -1) {
              onChangeState(`prices[${takeawayIndex}].price`, Number(event.nativeEvent.text.trim() === '' ? _.get(setMenuState, 'price', '0') : event.nativeEvent.text))
            }
          }}
        />
        <RowTextInput
          label={t('app.page.setting.menuPanel.set.deliveryPrice')}
          placeholder={String(_.get(setMenuState, `prices[${takeawayIndex}].price`, String(_.get(setMenuState, 'price', '0'))))}
          keyboardType='number-pad'
          value={String(_.get(setMenuState, `prices[${deliveryIndex}].price`, ''))}
          onChangeText={(text) => {
            if (!Number.isNaN(Number(text))) {
              if (deliveryIndex === -1) {
                const prices = setMenuState.prices ? [...setMenuState.prices] : []
                prices.push({
                  type: 'storeDelivery',
                  price: text,
                  discount: 0,
                })
                onChangeState('prices', prices)
              } else {
                onChangeState(`prices[${deliveryIndex}].price`, text)
              }
            }
          }}
          onBlur={(event) => {
            if (deliveryIndex > -1) {
              onChangeState(`prices[${deliveryIndex}].price`, Number(event.nativeEvent.text.trim() === '' ? _.get(setMenuState, `prices[${takeawayIndex}].price`, _.get(setMenuState, 'price', '0')) : event.nativeEvent.text))
            }
          }}
        />
        {
          setMenuState?.id && (
            <>
              <RowToggleInput
                label={t('app.page.setting.menuPanel.menuInfo.promoted')}
                value={setMenuState?.promoted}
                onChange={(value) => {
                  onChangeState('promoted', value)
                }}
              />
              <RowToggleInput
                label={t('app.page.setting.menuPanel.menuInfo.noDiscount')}
                value={setMenuState?.excludedDiscount}
                onChange={(value) => {
                  onChangeState('excludedDiscount', value)
                }}
              />
              <RowToggleInput
                label={t('app.page.setting.menuPanel.menuInfo.noSurcharge')}
                value={setMenuState?.excludedSurcharge}
                onChange={(value) => {
                  onChangeState('excludedSurcharge', value)
                }}
              />
              <RowToggleInput
                label={t('app.page.setting.menuPanel.menuInfo.sold')}
                value={setMenuState?.sold}
                onChange={(value) => {
                  onChangeState('sold', value)
                  dispatch(actions.menu.soldMenu(setMenu, value, true))
                }}
              />
              <RowToggleInput
                label={t('app.page.setting.menuPanel.menuInfo.hide')}
                value={setMenuState?.disabled}
                onChange={(value) => {
                  onChangeState('disabled', value)
                  dispatch(actions.menu.enableSetMenu(setMenu, !value))
                }}
              />
              <RowToggleInput
                label={t('app.page.setting.menuPanel.menuInfo.required')}
                value={setMenuState?.required}
                onChange={(value) => {
                  onChangeState('required', value)
                }}
              />
            </>
          )
        }
        <Text style={styles.errorText}>{error || ' '}</Text>

        {/* actions */}
        <Row style={styles.actions}>
          <Button
            title={t('app.common.cancel')}
            backgroundColor={colors.light}
            textColor={colors.textTertiary}
            textBold
            type='TouchableOpacity'
            onPress={onClose}
          />
          <Button
            title={hasChanged ? t('app.common.confirm') : t('app.common.delete')}
            textBold
            type='TouchableOpacity'
            onPress={hasChanged ? onSubmit : onDelete}
          />
          {setMenuState?.id && (
            <Button
              title={t('app.page.setting.menuPanel.set.origin')}
              textBold
              backgroundColor={colors.lightPrimary}
              type='TouchableOpacity'
              onPress={() => {
                history.push(`/setting/menu/menu-detail/${setMenuState.menuId}`)
                onClose()
              }}
            />
          )}
        </Row>
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    maxWidth: 350,
    marginHorizontal: 20,
    maxHeight: 550,
  },
  actions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    flex: 1,
    fontWeight: '500',
    color: colors.secondary,
    marginTop: 8,
  },
})
