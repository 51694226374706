import { StyleSheet, View } from 'react-native'
import { useSelectedParentCategory } from '@/hooks/category'
import { useSelector } from '@/redux'
import BackButton from '../CategoryBar/BackButton'
import CategoryBar from '../CategoryBar'
import FilterMenuItemByTimeButton from '../FilterMenuItemByTimeButton'
import PictureModeSwitchButton from '../PictureModeSwitchButton'
import React from 'react'
import SetSwitchButton from '../SetSwitchButton'
import _ from 'lodash'
import i18n from '@/i18n'

export default function PictureModeHeader (props) {
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const selectedSet = useSelector(state => state.menu.selectedSet)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  const selectedParentCategory = useSelectedParentCategory()
  const isSet = Boolean(selectedSet)
  const lang = String(i18n.language ?? 'zh').split('-')[0]

  if (!selectedCategory) return null

  return (
    <View style={styles.container}>
      {isSet ? <BackButton /> : <SetSwitchButton />}

      <CategoryBar
        isSet={isSet}
        setName={isSet && _.get(selectedSet?.localeNames, lang, selectedSet.name)}
        categoryItems={isSet ? selectedSet.steps : selectedParentCategory.categories}
        selectedId={isSet ? selectedSetStep?.id : selectedCategory?.id}
      />

      {!isSet && <PictureModeSwitchButton />}
      {!isSet && <FilterMenuItemByTimeButton />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 32,
    marginVertical: 8,
  },
})
