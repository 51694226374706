import { FlatList, Pressable } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useSelectedTable } from '@/hooks/table'

import TableModal from './TableModal'
import TableOrder from './TableOrder'

export default function TableDialog () {
  const dispatch = useDispatch()
  const selectedTable = useSelectedTable()
  const historyOrders = useSelector(state => state.orderHistory.orders)

  React.useEffect(() => {
    dispatch(actions.orderHistory.updateInitialScrollIndex(0))
  }, [])

  const onClose = () => {
    dispatch(actions.table.closeDialog(['table']))
  }

  const selectedTableOrders = React.useMemo(() => {
    const orderIds = selectedTable?.orderIds
    return _.filter(historyOrders, order => _.includes(orderIds, order.id))
  }, [selectedTable, historyOrders])

  const renderItem = ({ item }) => {
    return (
      <Pressable>
        <TableOrder
          title={`${selectedTable?.key}${item.subTable > 0 ? `(${String.fromCharCode(item.subTable + 64)})` : ''}`}
          currentOrder={item}
        />
      </Pressable>
    )
  }

  if (!selectedTable) {
    return null
  }

  return (
    <TableModal
      table={selectedTable}
      orders={selectedTableOrders}
      onClose={onClose}
    >
      <FlatList
        data={selectedTableOrders}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
      />
    </TableModal>

  )
}
