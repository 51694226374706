import _ from 'lodash'
import orderAppLib from 'dimorder-orderapp-lib'

import { currencyWithCommas } from '@/libs/numberWithCommas'
import i18n from '@/i18n'
import store from '@/redux/store'

const getState = store.getState

// 會把 setItems 也 format status
export default function getFormatBatchItemStatus (item) {
  if (item?.rewardId) {
    return item.cancelled ? i18n.t('app.constant.displayStatusKey.cancel_order') : i18n.t('app.constant.reward.pointsRedeem')
  }

  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const menus = getState().menu.menus
  const localeMenu = menus[item.menuId]
  const localeOptions = (localeMenu?.options || []).concat(localeMenu?.optionGroupPresets || [])
    ?.map(o => o.options ?? []).reduce((acc, cur) => acc.concat(cur), [])
  // 後台設定的餐點折扣不加入status
  // const discount = []
  // if (item.discount != null && item.discount !== 0) {
  //   // 後台設定的餐點折扣
  //   discount.push(`-${getDisplayRoundNumber(item.discount)}$`)
  // }

  // 組合每一個變價字串
  const modifierDiscounts = []
  const hasMerchantModifier = item.modifiers.find(modifier => modifier.type === 'MERCHANT' && modifier.deletedAt == null)

  if (item.modifiers != null) {
    item.modifiers.forEach(modifier => {
      if (!modifier.deletedAt) {
        let discountText = ''
        if (modifier.type !== 'COMBORULE') {
          if (modifier.name != null) {
            // 有名字時顯示名字
            discountText += modifier.name
          }
          if (modifier.amount !== 0) {
            // 顯示更動金額
            discountText += currencyWithCommas(modifier.amount)
          }
          if (modifier.percent !== 0) {
            // 顯示更動百分比
            discountText += String(modifier.percent) + '%'
          }
        } else if (modifier.type === 'COMBORULE' && _.isEmpty(hasMerchantModifier)) {
          discountText += orderAppLib.libs.getDisplayRoundNumber(modifier.amount) + '$'
        }
        modifierDiscounts.push(discountText)
      }
    })
  }

  const options = _.map(item.options, option => {
    const localeOption = localeOptions.find(o => o.id === option.optionId)
    const optionName = _.get(localeOption?.localeNames, lang, option.name)
    return `${optionName}${option.quantity > 1 ? `x${option.quantity}` : ''}`
  })
  const tags = _.map(item.tags, 'name')
  const remarks = Array.isArray(item.remarks)
    ? _.filter(item.remarks, remark => !_.isEmpty(remark))
    : item.remarks

  const status = _.concat(
    // discount,
    options,
    tags,
    remarks,
    modifierDiscounts,
  )

  return item.cancelled ? i18n.t('app.constant.displayStatusKey.cancel_order') : status.join('/')
}
