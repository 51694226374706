import { StyleSheet, TextInput } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SettingListInputProps
 * @property {boolean?} disabled
 */

/**
 * @param {TextInputProps & SettingListInputProps} props
 */
export default function SettingListInput (props) {
  const { style, disabled } = props
  const [textInputHeight, setTextInputHeight] = React.useState(20)

  return (
    <TextInput
      {...props}
      selectTextOnFocus
      editable={!disabled}
      style={[styles.textInput, disabled && styles.disabled, { height: textInputHeight }, style]}
      onContentSizeChange={(e) => {
        if (e.nativeEvent.contentSize.height > 0 && e.nativeEvent.contentSize.height < 100) {
          setTextInputHeight(e.nativeEvent.contentSize.height)
        }
      }}
    />
  )
}

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    width: '100%',
    height: '10%',
    textAlign: 'right',
    color: colors.gray,
    backgroundColor: colors.light,
    borderRadius: 4,
    fontSize: 14,
    lineHeight: 16,
    fontWeight: '500',
    paddingHorizontal: 8,
    overflow: 'scroll',
  },
  disabled: {
    backgroundColor: colors.white,
  },
})
