import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef ChipLabelProps
 * @property {string} label
 * @property {boolean?} active
 */

/**
 *
 * @param {ChipLabelProps} props
 * @returns
 */
export default function ChipLabel (props) {
  const { label, active } = props
  return (
    <View style={[styles.container, active && styles.active]}>
      <Text style={[styles.text, active && styles.textActive]}>{label}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 36,
    height: 19,
    borderRadius: 10,
    backgroundColor: colors.lightGray3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  active: {
    backgroundColor: colors.lightPrimary,
  },
  text: {
    color: colors.primary,
    fontSize: 10,
    opacity: 0.4,
  },
  textActive: {
    opacity: 1,
    color: colors.white,
  },
})
