import { Button } from 'react-native-elements'
import { FlatList, Image, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { loadingKey } from '@/constants'
import { useSelectedOrder } from '@/hooks/orderHistory'
import Column from '@/components/Column'
import Row from '@/components/Row'
import TableHeader from '@/components/OrderTable/TableHeader'
import colors from '@/theme/colors'

import OrderItemList from './OrderItemList'
import tableConfig from './OrderItemList/tableConfig'

export default function BatchList (props) {
  const { crmSelectedOrder, fromCRM = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const _selectedOrder = useSelectedOrder()
  const selectedOrder = fromCRM ? crmSelectedOrder : _selectedOrder
  const selectedOrderBatches = useSelector(state => state.orderHistory.selectedOrderBatches)
  const { isMaster } = useSelector(state => state.app.settings) // master
  // 打開的 set
  const [expandedSetItems, setExpandedSetItems] = React.useState({})
  const items = _.flatMap(selectedOrder?.batches, batch => batch.items)
  let usedSerial = 0
  const itemSerials = items.map((item, index) => {
    const itemSerial = usedSerial + 1
    usedSerial++
    return { id: item.id, itemSerial }
  })

  // 表頭
  //  headers: ['序號', '產品名稱', '狀態', '價錢', '數量', '折扣', '服務費', '小計'],
  const headers = [t('app.common.list.serial'), t('app.constant.order.name'), t('app.constant.order.status'),
    t('app.constant.order.price'), t('app.constant.order.quantity'), t('app.constant.order.subtotal')]

  const renderItem = ({ item: batch, index }) => {
    if (batch.items.length <= 0) return
    let confirmStatusHeader = null
    if (batch.status === 'submitted' && selectedOrder.deliveryType === 'table') {
      if (isMaster && selectedOrder.displayStatusKey !== 'paying') {
        confirmStatusHeader =
          <Row style={[styles.confirmRow]}>
            <Button
              buttonStyle={[styles.confirmBtn]}
              onPress={async () => {
                await dispatch(actions.app.openLoading(loadingKey.PRINT, 'batchList-confirm-batch'))
                await dispatch(actions.orderHistory.confirmBatch(selectedOrder, batch))
              }}
              icon={
                <Image
                  style={{ width: 13, height: 13 }}
                  source={require('@icons/orders/order-action/accept.png')}
                />
              }
              title={t('app.common.orderConfirm')}
              titleStyle={[styles.BtnTextStyle]}
              accessibilityLabel={t('app.common.orderConfirm')}
            />
            <Button
              buttonStyle={[styles.unconfirmBtn]}
              onPress={() => {
                dispatch(actions.orderHistory.cancelBatch(selectedOrder, batch))
              }}
              icon={
                <Image
                  style={{ width: 13, height: 13 }}
                  source={require('@icons/orders/order-action/reject.png')}
                />
              }
              title={t('app.common.orderReject')}
              titleStyle={[styles.BtnTextStyle]}
              accessibilityLabel={t('app.common.orderReject')}
            />
          </Row>
      }
    } else if (batch.status === 'cancelled') {
      confirmStatusHeader = <Row style={[styles.confirmRow]}><Text style={[styles.unConfirmedText]}>({t('app.common.cancelled')})</Text></Row>
    }
    let name = ''
    if (batch.from === 'MERCHANT') {
      name = batch.identifier
    } else if (batch.from === 'CUSTOMER_WEB') {
      if (batch.identifier === 'waiter') {
        name = batch.identifier
      } else {
        name = t('app.printing.customerOrder')
      }
    }
    return (
      <>
        <TouchableOpacity>
          <Row
            style={[styles.batchHeader, styles.setItemRow]}
          >
            <Text style={[styles.batchText, { width: 40 }]}>
              ＃{batch.index + 1}
            </Text>
            <Text style={styles.batchText}>
              {moment(batch.createdAt).format('HH:mm')}
            </Text>
            <Text style={styles.batchIdentifierText}>
              {name}
            </Text>
            {confirmStatusHeader && confirmStatusHeader}
          </Row>
        </TouchableOpacity>
        <OrderItemList
          batch={batch}
          itemSerials={itemSerials}
          expandedSetItems={expandedSetItems}
          setExpandedSetItems={setExpandedSetItems}
          summary={selectedOrder.summary}
        />
      </>
    )
  }

  return (
    <Column style={styles.container}>
      <TableHeader
        headers={headers}
        widths={tableConfig.widths}
        justifyContents={tableConfig.justifyContents}
      />
      <FlatList
        data={selectedOrder?.batches}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(item.id + index)}
        showsVerticalScrollIndicator={false}
        extraData={[expandedSetItems, selectedOrderBatches]}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  batchHeader: {
    height: 30,
    alignItems: 'center',
    backgroundColor: colors.lightGray3,
    paddingLeft: 15,
  },
  batchText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
  batchIdentifierText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.textPrimary,
    marginLeft: 40,
  },
  setItemRow: {
    minHeight: 30,
  },
  unConfirmedText: {
    color: colors.red,
    fontWeight: 'bold',
  },
  selectedRow: {
    backgroundColor: colors.tertiary,
  },
  selectedText: {
    color: colors.textPrimary,
  },
  confirmRow: {
    marginLeft: 'auto',
    flexDirection: 'row-reverse',
    fontSize: 13,
  },
  unconfirmBtn: {
    backgroundColor: colors.darkSecondary,
  },
  confirmBtn: {
    backgroundColor: colors.lightPrimary,
  },
  BtnTextStyle: {
    fontSize: 13,
  },
})
