import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import _ from 'lodash'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import Row from '@/components/Row'

import InfoBar from '../InfoBar'
import OrderLegends from '../TableLegend/OrderLegends'
import TableFactory from './Table'
import TableLegends from '../TableLegend/TableLegends'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { ITable } from '@/redux/table/Table.d'
/* eslint-enable no-unused-vars */

/**
 * @typedef TableAreaProps
 * @property {(table: ITable) => void} onSelectTable
 * @property {(order: IAppOrder) => void} onSelectOrder
 * @property {boolean} [disableOrders=false]
 * @property {boolean} [requestWaiter=false]
 * @property {boolean} [forceSrcoll=false] TableAreaDialog 會強制使用Srcolling view
 */

/**
 *
 * @param {TableAreaProps} props
 * @returns
 */
export default function TableArea (props) {
  const { onSelectTable, onSelectOrder, disableOrders = false, requestWaiter = false, forceSrcoll = false } = props
  const { t } = useTranslation()
  const { tables, selectedTableAreaId } = useSelector(state => state.table)
  const { isSelecting } = useSelector(state => state.table)
  const enableLargerTable = useSelector(state => state.merchant.data?.setting?.enableLargerTable)
  const enableTablePaginationButton = useSelector(state => state.merchant.data?.setting?.enableTablePaginationButton)
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])

  const maxRow = enableLargerTable
    ? 3 // 桌位增大顯示時，應顯示較少的行數避免超出畫面
    : 5

  const isPaginationView = forceSrcoll ? false : enableTablePaginationButton // popup TableAreaDialog always use ScrollView

  const TablesContainer = isPaginationView
    ? View // 分頁顯示桌位時，畫面不可捲動
    : ScrollView // 非分頁顯示桌位時，畫面可捲動

  const areaTables = React.useMemo(() => {
    // 篩選出所有所選桌位群組的桌位
    return _.filter(tables, table => table.areaId === selectedTableAreaId)
  }, [tables, selectedTableAreaId])

  const tableRows = React.useMemo(() => {
    return _.reduce(areaTables, (acc, table) => {
      if (!acc[table.row]) acc.push([])
      acc[table.row].push(table)
      return acc
    }, [])
  }, [areaTables])

  React.useEffect(() => {
    if (isPaginationView) {
      // 分頁顯示桌位時僅顯示當前頁面的桌位
      setRows(tableRows.slice(maxRow * page, maxRow * (page + 1)))
    } else {
      // 非分頁顯示時顯示全部的桌位
      setRows(tableRows)
    }
  }, [tableRows, maxRow, page, isPaginationView])

  React.useEffect(() => {
    // 當切換所選的桌位群組時，應重設桌位頁面回第一頁
    setPage(0)
  }, [selectedTableAreaId])

  const PaginationButtons = React.useCallback(() => {
    if (!isPaginationView) return null // 非分頁顯示時不顯示
    if (tableRows.length <= maxRow) return null // 分頁顯示時，但僅有一頁時不顯示
    return (
      <View style={styles.paginationButtons}>
        <Button
          title={t('app.common.prev_page')}
          style={{ marginRight: 10 }}
          disabled={page === 0}
          onPress={() => setPage(page - 1)}
        />
        <Button
          title={t('app.common.next_page')}
          onPress={() => setPage(page + 1)}
          disabled={page === Math.ceil(tableRows.length / maxRow) - 1}
        />
      </View>
    )
  }, [isPaginationView, tableRows, maxRow, page])

  return (
    <Column style={styles.container}>
      {/* info */}
      <InfoBar />

      {/* tables */}
      <TablesContainer
        style={styles.tables}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: isSelecting ? 500 : 0,
        }}
      >
        {rows.length > 0 &&
          rows.map((row, index) => (
            <Row key={`table-row-${index}`} style={{ zIndex: rows.length - index }}>
              {row.length > 0 &&
                  row.map((table) => (
                    <TableFactory
                      key={table.id}
                      table={table}
                      onSelectTable={onSelectTable}
                      onSelectOrder={onSelectOrder}
                      disableOrders={disableOrders}
                      requestWaiter={requestWaiter}
                    />
                  ))}
            </Row>
          ))}
      </TablesContainer>

      <PaginationButtons />

      {/* legend */}
      <Row style={styles.legends}>
        <TableLegends />
        {!disableOrders && <OrderLegends />}
      </Row>
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 15,
    paddingHorizontal: 26,
  },
  tables: {
    flex: 1,
    marginTop: 15,
    zIndex: 1,
    elevation: 1,
    paddingBottom: 60,
  },
  legends: {
    marginVertical: 15,
    justifyContent: 'space-between',
  },
  paginationButtons: {
    marginTop: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
