import { Buffer } from 'buffer'
import { PNG } from 'pngjs/browser'
import { SaveFormat, manipulateAsync } from 'expo-image-manipulator'
import _ from 'lodash'

export default async function imageToBitData (url, imageWidth = 128, threshold = 127, type = 'data') {
  if (imageWidth % 8 !== 0) {
    throw new Error('Incorrect Image Width (8x)')
  }
  if (threshold < 1 || threshold > 255) {
    throw new Error('Incorrect Threshold (0 < x < 256)')
  }
  const response = await manipulateAsync(
    url,
    [
      {
        resize: {
          width: imageWidth,
        },
      },
    ],
    { base64: true, format: SaveFormat.PNG })
  const originalImage = PNG.sync.read(Buffer.from(response.base64, 'base64'))
  const grayscaleImage = PNG.sync.write(originalImage, { colorType: 0, depth: 1 })
  const rawData = PNG.sync.read(grayscaleImage)
  const width = rawData.width
  const height = rawData.height
  const imageData = (rawData.data || []).filter((d, index) => index % 4 === 0)
  const autoThreshold = Math.round(imageData.reduce((a, b) => a + b, 0) / width * height)
  const imageThreshold = threshold ?? autoThreshold
  const data = _.chunk(imageData.map(d => d < imageThreshold ? 1 : 0), width)

  return data
}
