import PrintDoc from '@/libs/printing/PrintDoc'
import shortid from 'shortid'

export default class TestConnect extends PrintDoc {
  TYPE = PrintDoc.Types.TEST_CONNECT

  constructor () {
    super()
    this.printId = shortid.generate()
  }

  print = () => {
    this.addData()
    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  addData = () => {
    const printer = this.printer
    printer.addTextConnect()
  }
}
