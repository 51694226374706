import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { PrintStyle } from '@/constants/printing'
import { useDispatch, useSelector } from '@/redux'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * }} props
 */
export default function InfoSetting (props) {
  const { setting, onChange } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { orderSerialDigit = 3 } = useSelector(state => state.printer.printerSetting.defaultSettings)

  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)

  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]
  const textAlignOptions = [
    { value: 'left', display: t('app.page.setting.printer.left') },
    { value: 'center', display: t('app.page.setting.printer.center') },
    { value: 'right', display: t('app.page.setting.printer.right') },
  ]
  const timeFormatOptions = [
    { value: 'YYYY/MM/DD HH:mm:ss', display: 'YYYY/MM/DD HH:mm:ss' },
    { value: 'MM/DD HH:mm', display: 'MM/DD HH:mm' },
  ]
  const separateFormatOptions = [
    { value: '  ', display: t('app.page.setting.printer.customizedSetting.space') },
    { value: ', ', display: t('app.page.setting.printer.customizedSetting.comma') },
    { value: '\n', display: t('app.page.setting.printer.customizedSetting.newLine') },
  ]

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  return (
    <>
      <SettingListItem divider={!setting.content?.tableNo?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.tableNo')} />
        {/* <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.tableNo?.enable}
            onChangeValue={() => onChange('content.tableNo.enable', !setting.content?.tableNo?.enable)}
            size={23}
          />
        </SettingListItemRightAction> */}
      </SettingListItem>
      {
        setting.content?.tableNo?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.tableNo?.bold}
                  onChangeValue={() => onChange('content.tableNo.bold', !setting.content?.tableNo?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.tableNo?.fontSize)?.display ||
              setting.content?.tableNo?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.tableNo?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.tableNo.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.tableNo?.align)?.display ||
              setting.content?.tableNo?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.tableNo?.align || 0,
                  onChangeValue: (value) => onChange('content.tableNo.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.tableNo?.separateFormat)?.display ||
              setting.content?.tableNo?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.tableNo?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.tableNo.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.customerNo?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.customerNo')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.customerNo?.enable}
            onChangeValue={() => onChange('content.customerNo.enable', !setting.content?.customerNo?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.customerNo?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.customerNo?.bold}
                  onChangeValue={() => onChange('content.customerNo.bold', !setting.content?.customerNo?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.customerNo?.fontSize)?.display ||
              setting.content?.customerNo?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.customerNo?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.customerNo.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.customerNo?.align)?.display ||
              setting.content?.customerNo?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.customerNo?.align || 0,
                  onChangeValue: (value) => onChange('content.customerNo.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.customerNo?.separateFormat)?.display ||
              setting.content?.customerNo?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.customerNo?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.customerNo.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.serialNo?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.serialNo')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.serialNo?.enable}
            onChangeValue={() => onChange('content.serialNo.enable', !setting.content?.serialNo?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.serialNo?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.serialNo?.bold}
                  onChangeValue={() => onChange('content.serialNo.bold', !setting.content?.serialNo?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.serialNo?.fontSize)?.display ||
              setting.content?.serialNo?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.serialNo?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.serialNo.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.serialNo?.align)?.display ||
              setting.content?.serialNo?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.serialNo?.align || 0,
                  onChangeValue: (value) => onChange('content.serialNo.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.serialNo?.separateFormat)?.display ||
              setting.content?.serialNo?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.serialNo?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.serialNo.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListItem>
              <SettingListItemText text={t('app.page.setting.printer.customizedSetting.last3Digit', { digit: orderSerialDigit })} />
            </SettingListItem>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={_.get(setting, 'content.serialNo.last3Digit.bold', false)}
                  onChangeValue={() => onChange('content.serialNo.last3Digit.bold', !_.get(setting, 'content.serialNo.last3Digit.bold', false))}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === _.get(setting, 'content.serialNo.last3Digit.fontSize', 0))?.display || ''}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.serialNo?.last3Digit?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.serialNo.last3Digit.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListItem divider>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.onlyLast3Digit', { digit: orderSerialDigit })} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={_.get(setting, 'content.serialNo.last3Digit.enable', false)}
                  onChangeValue={() => onChange('content.serialNo.last3Digit.enable', !_.get(setting, 'content.serialNo.last3Digit.enable', false))}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          </>
        )
      }
      <SettingListItem divider={!setting.content?.createAt?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.createAt')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.createAt?.enable}
            onChangeValue={() => onChange('content.createAt.enable', !setting.content?.createAt?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.createAt?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.createAt?.bold}
                  onChangeValue={() => onChange('content.createAt.bold', !setting.content?.createAt?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.createAt?.fontSize)?.display ||
              setting.content?.createAt?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.createAt?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.createAt.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.createAt?.align)?.display ||
              setting.content?.createAt?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.createAt?.align || 0,
                  onChangeValue: (value) => onChange('content.createAt.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.format')}
              value={timeFormatOptions.find(t => t.value === setting.content?.createAt?.format)?.display ||
              setting.content?.createAt?.format}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.format'),
                  defaultValue: setting.content?.createAt?.format || 0,
                  onChangeValue: (value) => onChange('content.createAt.format', value),
                  options: timeFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.createAt?.separateFormat)?.display ||
              setting.content?.createAt?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.createAt?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.createAt.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.printAt?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.printAt')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.printAt?.enable}
            onChangeValue={() => onChange('content.printAt.enable', !setting.content?.printAt?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.printAt?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.printAt?.bold}
                  onChangeValue={() => onChange('content.printAt.bold', !setting.content?.printAt?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.printAt?.fontSize)?.display ||
              setting.content?.printAt?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.printAt?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.printAt.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.printAt?.align)?.display ||
              setting.content?.printAt?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.printAt?.align || 0,
                  onChangeValue: (value) => onChange('content.printAt.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.format')}
              value={timeFormatOptions.find(t => t.value === setting.content?.printAt?.format)?.display ||
              setting.content?.printAt?.format}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.format'),
                  defaultValue: setting.content?.printAt?.format || 0,
                  onChangeValue: (value) => onChange('content.printAt.format', value),
                  options: timeFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.printAt?.separateFormat)?.display ||
              setting.content?.printAt?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.printAt?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.printAt.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.paidAt?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.paidAt')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.paidAt?.enable}
            onChangeValue={() => {
              if (_.get(setting, 'content.paidAt') === undefined) {
                onChange('content.paidAt', {
                  key: 'paidAt',
                  enable: true,
                  type: 'time',
                  align: PrintStyle.ALIGN.LEFT,
                  fontSize: 0,
                  bold: false,
                  separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
                  format: PrintStyle.TIME.LONG24,
                  index: 10,
                })
              }
              onChange('content.paidAt.enable', !setting.content?.paidAt?.enable)
            }}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.paidAt?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.paidAt?.bold}
                  onChangeValue={() => onChange('content.paidAt.bold', !setting.content?.paidAt?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.paidAt?.fontSize)?.display ||
              setting.content?.paidAt?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.paidAt?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.paidAt.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.paidAt?.align)?.display ||
              setting.content?.paidAt?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.paidAt?.align || 0,
                  onChangeValue: (value) => onChange('content.paidAt.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.format')}
              value={timeFormatOptions.find(t => t.value === setting.content?.paidAt?.format)?.display ||
              setting.content?.paidAt?.format}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.format'),
                  defaultValue: setting.content?.paidAt?.format || 0,
                  onChangeValue: (value) => onChange('content.paidAt.format', value),
                  options: timeFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.paidAt?.separateFormat)?.display ||
              setting.content?.paidAt?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.paidAt?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.paidAt.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.customerContact?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.customerContact')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.customerContact?.enable}
            onChangeValue={() => onChange('content.customerContact.enable', !setting.content?.customerContact?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.customerContact?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.customerContact?.bold}
                  onChangeValue={() => onChange('content.customerContact.bold', !setting.content?.customerContact?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.customerContact?.fontSize)?.display ||
              setting.content?.customerContact?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.customerContact?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.customerContact.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.customerContact?.align)?.display ||
              setting.content?.customerContact?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.customerContact?.align || 0,
                  onChangeValue: (value) => onChange('content.customerContact.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.customerContact?.separateFormat)?.display ||
              setting.content?.customerContact?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.customerContact?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.customerContact.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.customerName?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.customerName')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.customerName?.enable}
            onChangeValue={() => onChange('content.customerName.enable', !setting.content?.customerName?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.customerName?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.customerName?.bold}
                  onChangeValue={() => onChange('content.customerName.bold', !setting.content?.customerName?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.customerName?.fontSize)?.display ||
              setting.content?.customerName?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.customerName?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.customerName.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.customerName?.align)?.display ||
              setting.content?.customerName?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.customerName?.align || 0,
                  onChangeValue: (value) => onChange('content.customerName.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.customerName?.separateFormat)?.display ||
              setting.content?.customerName?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.customerName?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.customerName.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.customerAddress?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.customerAddress')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.customerAddress?.enable}
            onChangeValue={() => onChange('content.customerAddress.enable', !setting.content?.customerAddress?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.customerAddress?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.customerAddress?.bold}
                  onChangeValue={() => onChange('content.customerAddress.bold', !setting.content?.customerAddress?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.customerAddress?.fontSize)?.display ||
              setting.content?.customerAddress?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.customerAddress?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.customerAddress.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.customerAddress?.align)?.display ||
              setting.content?.customerAddress?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.customerAddress?.align || 0,
                  onChangeValue: (value) => onChange('content.customerAddress.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              divider
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.customerAddress?.separateFormat)?.display ||
              setting.content?.customerAddress?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.customerAddress?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.customerAddress.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem divider={!setting.content?.pickupAt?.enable}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.pickupAt')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.pickupAt?.enable}
            onChangeValue={() => onChange('content.pickupAt.enable', !setting.content?.pickupAt?.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.pickupAt?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.pickupAt?.bold}
                  onChangeValue={() => onChange('content.pickupAt.bold', !setting.content?.pickupAt?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.pickupAt?.fontSize)?.display ||
              setting.content?.pickupAt?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.pickupAt?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.pickupAt.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.pickupAt?.align)?.display ||
              setting.content?.pickupAt?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.pickupAt?.align || 0,
                  onChangeValue: (value) => onChange('content.pickupAt.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.format')}
              value={timeFormatOptions.find(t => t.value === setting.content?.pickupAt?.format)?.display ||
              setting.content?.pickupAt?.format}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.format'),
                  defaultValue: setting.content?.pickupAt?.format || 0,
                  onChangeValue: (value) => onChange('content.pickupAt.format', value),
                  options: timeFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.pickupAt?.separateFormat)?.display ||
              setting.content?.pickupAt?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.pickupAt?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.pickupAt.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
      <SettingListItem>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.remark')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.content?.remark?.enable}
            onChangeValue={() => {
              if (_.get(setting, 'content.remark') === undefined) {
                onChange('content.remark', {
                  key: 'remark',
                  enable: false,
                  type: PrintStyle.TYPE.TEXT,
                  align: PrintStyle.ALIGN.LEFT,
                  fontSize: 0,
                  bold: false,
                  separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
                  index: 9,
                })
              }
              onChange('content.remark.enable', !setting.content?.remark?.enable)
            }}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.content?.remark?.enable && (
          <>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={setting.content?.remark?.bold}
                  onChangeValue={() => onChange('content.remark.bold', !setting.content?.remark?.bold)}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === setting.content?.remark?.fontSize)?.display ||
              setting.content?.remark?.fontSize}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: setting.content?.remark?.fontSize || 0,
                  onChangeValue: (value) => onChange('content.remark.fontSize', value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === setting.content?.remark?.align)?.display ||
              setting.content?.remark?.align}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: setting.content?.remark?.align || 0,
                  onChangeValue: (value) => onChange('content.remark.align', value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === setting.content?.remark?.separateFormat)?.display ||
              setting.content?.remark?.separateFormat}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: setting.content?.remark?.separateFormat || 0,
                  onChangeValue: (value) => onChange('content.remark.separateFormat', value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
              }}
            />
          </>
        )
      }
    </>
  )
}
