/**
 *
 * @param {string} url
 * @param  {...string} transformations
 */
export function transformation (url, ...transformations) {
  url = url || ''
  if (!url.includes('res.cloudinary.com')) {
    return url
  }

  const splitUrl = url.split('/upload')
  const head = splitUrl.shift()
  splitUrl[0] = '/' + transformations.join(',') + splitUrl[0]
  splitUrl.unshift(head)
  url = splitUrl.join('/upload')
  url = url.replace('.png', '.jpg')
  return url
}

export default {
  transformation,
}
