import { StyleSheet, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useHistory } from '@/libs/reactRouter'
import { useTranslation } from 'react-i18next'
import CategoryInfo from '../CategoryInfo'
import CategoryList from '../CategoryList'
import MenuInfo from './MenuInfo'
import MenuList from '../MenuList'
import React from 'react'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import _ from 'lodash'

export default function MenuPanel (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const rootCategory = useSelector(state => state.menu.rootCategory)
  const promote = useSelector(state => state.menu.promote)
  const soldOut = useSelector(state => state.menu.soldOut)

  const [menus, setMenus] = React.useState([])
  const selectedCategory = useSelector(state => state.setting.selectedCategory)
  const [selectedMenu, setSelectedMenu] = React.useState(null)
  const categories = rootCategory.searchCategories
  const searchText = useSelector(state => state.setting.searchText)

  React.useEffect(() => {
    if (selectedCategory?.menus) {
      setMenus(selectedCategory?.menus)
    } else {
      setMenus([])
    }
  }, [selectedCategory])

  React.useEffect(() => {
    let selected = null
    _.each(categories, category => {
      const id = selectedCategory?.id
      if (category.id === id) {
        selected = category
      }
      const subCategories = category.categories
      const ids = _.flatMap(subCategories, 'id')
      if (ids.includes(id)) {
        selected = subCategories?.find(sub => sub.id === id)
      }
    })
    dispatch(actions.setting.selectCategory(selected ?? null))
  }, [categories])

  const toggleSelectCategory = (category) => {
    setSelectedMenu(null)
    if (category.id === selectedCategory?.id) {
      dispatch(actions.setting.selectCategory(null))
      return
    }
    dispatch(actions.setting.selectCategory(category))
  }

  const addCategory = () => {
    setSelectedMenu(null)
    dispatch(actions.setting.createMenuCategory())
  }

  const addMenu = () => {
    if (!selectedCategory || selectedCategory.id === undefined) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.category')))
      return
    }
    history.push(`/setting/menu/create-menu/${selectedCategory.id}`)
  }

  const onChangeMenuOrder = (menus, categoryId, menuIds) => {
    setMenus(menus)
    dispatch(actions.menu.updateMenuOrder(categoryId, menuIds, menus))
  }

  return (
    <View style={styles.container}>
      {/* left container */}
      <View style={styles.leftContainer}>

        {/* 餐點分類 */}
        <CategoryList
          draggable
          style={{ flex: 1 }}
          title={t('app.page.setting.menuPanel.menuInfo.category')}
          parentCategory={rootCategory}
          categories={categories ?? []}
          selectedCategory={selectedCategory}
          onPress={toggleSelectCategory}
          onPressBottomButton={addCategory}
        >
          {
            !searchText.trim() && (
              <>
                <SettingListItem
                  divider
                  selected={selectedCategory?.id === 'promote'}
                  draggable
                  dragDisabled
                  onPress={() => toggleSelectCategory(promote)}
                >
                  <SettingListItemText
                    text={t('app.constant.menu.promoted')}
                    wrap
                  />
                </SettingListItem>
                <SettingListItem
                  divider
                  selected={selectedCategory?.id === 'soldOut'}
                  draggable
                  dragDisabled
                  onPress={() => toggleSelectCategory(soldOut)}
                >
                  <SettingListItemText
                    text={t('app.constant.menu.sold')}
                    wrap
                  />
                </SettingListItem>
              </>
            )
          }
        </CategoryList>
        {/* 餐點項目 */}
        <MenuList
          draggable
          style={{ flex: 1.75 }}
          title={t('app.page.setting.menuPanel.menuInfo.item')}
          categoryId={selectedCategory?.id}
          menus={menus ?? []}
          selectedMenu={selectedMenu}
          onPress={(menu) => setSelectedMenu(menu)}
          onPressBottomButton={addMenu}
          onChangeMenuOrder={onChangeMenuOrder}
          disableBottomButton={_.size(selectedCategory?.categories) > 0}
        />
      </View>
      {/* right container */}
      <View style={styles.rightContainer}>
        {/* 餐點分類info */}
        {selectedCategory &&
          !selectedMenu &&
            <CategoryInfo
              category={selectedCategory}
              onClose={() =>
                dispatch(actions.setting.selectCategory(null))}
            />}
        {/* 餐點項目 info */}
        {selectedMenu &&
          <MenuInfo menu={selectedMenu} />}
      </View>
    </View>)
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  leftContainer: {
    flex: 3,
    flexDirection: 'row',
    marginHorizontal: 10,
  },
  rightContainer: {
    flex: 2,
    marginHorizontal: 10,
  },
})
