import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useSelectedParentCategory } from '@/hooks/category'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'

export default function SetSwitchButton (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const categories = useSelector(state => state.menu.rootSetCategory.categories)

  if (categories.length === 0) return null

  return (
    <TouchableOpacity
      onPress={() => {
        if (selectedCategory.isInSetCategories) {
          dispatch(actions.menu.swithToSingleCategories())
        } else {
          dispatch(actions.menu.swithToSetCategories())
        }
      }}
    >
      <Text style={styles.text}>
        {selectedCategory.isInSetCategories ? t('app.page.order.orderMenu.single') : t('app.page.order.orderMenu.set')}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.darkSecondary,
    margin: 8,
  },
})
