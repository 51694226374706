import { Buffer } from 'buffer'
import { Image, StyleSheet, View } from 'react-native'
import { PNG } from 'pngjs/browser'
import { SaveFormat, manipulateAsync } from 'expo-image-manipulator'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Slider from '@react-native-community/slider'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'

/**
 *
 * @param {*} props
 * @returns
 */
export default function LogoSetting (props) {
  const { logo, onClose, onSave } = props
  const { t } = useTranslation()
  const [receipt, setReceipt] = React.useState(logo?.receipt)
  const [qrcode, setQrcode] = React.useState(logo?.qrcode)
  const url = useSelector(state => state.merchant.data.images?.cover)
  const [base64, setBase64] = React.useState(null)
  const [height, setHeight] = React.useState(300)
  const base64header = 'data:image/png;base64,'
  const [threshold, setThreshold] = React.useState(logo?.threshold)
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    if (url) {
      loadImage()
    }
  }, [url])

  React.useEffect(() => {
    if (threshold) {
      updateImage()
    }
  }, [threshold])

  React.useEffect(() => {
    if (data) {
      updateImage()
    }
  }, [data])

  const loadImage = async () => {
    const response = await manipulateAsync(
      url,
      [
        {
          resize: {
            width: 300,
          },
        },
      ],
      { base64: true, format: SaveFormat.PNG })
    const originalImage = PNG.sync.read(Buffer.from(response.base64, 'base64'))
    const grayscaleImage = PNG.sync.write(originalImage, { colorType: 0, depth: 1 })
    const rawData = PNG.sync.read(grayscaleImage)
    setHeight(response.height)
    setData(rawData)
  }

  const updateImage = () => {
    if (data) {
      const updatedData = data.data.map((d, index) => {
        if (index % 4 === 3) {
          return d
        }
        return d < threshold ? 0 : 255
      })
      const grayscaleImage = PNG.sync.write({ ...data, data: updatedData }, { colorType: 0, depth: 1 })
      const grayScaleBase64 = grayscaleImage.toString('base64')
      setBase64(base64header + grayScaleBase64)
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <Header onBack={onClose}>
          <HeaderText text={t('app.page.setting.printer.logoSetting')} />
        </Header>
        {/* <SettingList>
          <SettingListSwitchRow
            divider
            label={t('app.page.setting.printer.receipt')}
            value={receipt}
            onChangeValue={() => setReceipt(!receipt)}
          />
          <SettingListSwitchRow
            label={t('app.page.setting.printer.qrcode')}
            value={qrcode}
            onChangeValue={() => setQrcode(!qrcode)}
          />
        </SettingList> */}
        <View style={styles.imageContainer}>
          {
            base64 && (
              <>
                <Image style={{ width: 300, height }} source={{ uri: base64 }} />
                <Slider
                  style={{ width: 200, height: 40 }}
                  value={threshold}
                  minimumValue={1}
                  maximumValue={255}
                  minimumTrackTintColor='#000000'
                  maximumTrackTintColor='#FFFFFF'
                  onSlidingComplete={(value) => setThreshold(Math.round(value))}
                />
              </>
            )
          }
        </View>
        <View style={styles.buttonContainer}>
          <Button
            textBold
            title={t('app.common.reset')}
            backgroundColor={colors.tertiary}
            onPress={() => setThreshold(127)}
          />
          <Button
            textBold
            title={t('app.common.save')}
            onPress={() => {
              onSave({ receipt, qrcode, threshold })
              onClose()
            }}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
