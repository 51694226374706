import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import IconLabel from './IconLabel'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function OrderLegends () {
  const { t } = useTranslation()
  const pendingText = t('app.page.table.tableArea.orderLegends.pending')
  const preparingText = t('app.page.table.tableArea.orderLegends.preparing')

  const legends = [
    { text: pendingText, color: colors.status1 },
    { text: preparingText, color: colors.status2 },
  ]
  return (
    <Row>
      {legends.map(status =>
        <IconLabel
          key={status.text}
          text={status.text}
          style={styles.label}
          colorIcon={status.color}
        />,
      )}
    </Row>
  )
}

const styles = StyleSheet.create({
  label: {
    marginRight: 18,
  },
})
