import { Image, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { loadingKey } from '@/constants'
import { useHistory, useParams } from '@/libs/reactRouter'
import colors from '@/theme/colors'

import BackNavigator from '@/components/Setting/BackNavigator'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'

import CategoryList from '../../../CategoryList'
import MenuList from '../../../MenuList'
import SetMenuDialog from './SetMenuDialog'
import SetMenuList from './SetMenuList'

/**
 *
 * @param {*} props
 * @returns
 */
export default function EditSetMenu (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { setId, stepId } = useParams()
  const history = useHistory()
  const set = _.find(useSelector(state => state.menu.sets), s => s.id === setId)
  const categories = useSelector(state => state.menu.rootCategory.categories)
  const [step, setStep] = React.useState(null)
  const [setMenus, setSetMenus] = React.useState([])
  const [selectedSetMenu, setSelectedSetMenu] = React.useState(null)
  const [selectedCategory, setSelectedCategory] = React.useState(null)
  const [categoryMenus, setCategoryMenus] = React.useState([])
  const [stepItemPriceDialogOpen, setStepItemPriceDialogOpen] = React.useState(false)

  const stepItemPriceOptions = [
    { value: 'original', display: t('app.page.setting.menuPanel.set.stepItemPriceDialog.options.originalPrice') },
    { value: 'zero', display: t('app.page.setting.menuPanel.set.stepItemPriceDialog.options.zero') },
  ]

  // 在sets裡找不到setId
  if (setId && stepId && !set) {
    setTimeout(() => history.goBack(), 2000)
    return <Text style={styles.errorText}>{t('app.page.setting.menuPanel.set.empty')}</Text>
  }

  React.useEffect(() => {
    if (set) {
      const step = set.steps.filter(s => s.id === stepId)
      const setMenus = set.menus.filter(m => m.step === stepId)
      setStep(step)
      setSetMenus(setMenus)
    }
  }, [set])

  React.useEffect(() => {
    if (selectedCategory?.menus) {
      setCategoryMenus(selectedCategory.menus)
    } else {
      setCategoryMenus([])
    }
  }, [selectedCategory])

  const addMenuToStep = async (menu) => {
    const stepMenu = setMenus.find(m => m.menuId === menu.id)
    if (stepMenu) {
      setSelectedSetMenu(stepMenu)
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), `${menu.name}${t('app.page.setting.menuPanel.set.error.exist')}`))
      return
    }
    dispatch(actions.app.openLoading(loadingKey.SET_MENU))
    const setMenu = {
      menuId: menu.id,
      name: _.get(menu?.localeNames, 'zh') ?? menu.name,
      min: null,
      max: null,
      price: null,
    }

    if (!stepMenu) {
      await dispatch(actions.menu.addSetMenu(setId, stepId, setMenu))
    }
    dispatch(actions.app.closeLoading(loadingKey.SET_MENU))
  }

  const onSelectStepMenu = (setMenu) => {
    setSelectedSetMenu(setMenu)
    dispatch(actions.app.showDialog(['setting', 'setMenu']))
  }

  const onAddAllMenu = async (priceOption) => {
    for (const menu of categoryMenus) {
      dispatch(actions.app.openLoading(loadingKey.SET_MENU))
      console.log(menu)
      const stepMenu = setMenus.find(m => m.menuId === menu.id)
      const setMenu = {
        menuId: menu.id,
        name: _.get(menu?.localeNames, 'zh') ?? menu.name,
        min: null,
        max: null,
        price: priceOption === 'original'
          ? menu.price
          : null,
        prices: priceOption === 'original'
          ? menu.prices
          : null,
      }

      if (!stepMenu) {
        await dispatch(actions.menu.addSetMenu(setId, stepId, setMenu))
      }
      dispatch(actions.app.closeLoading(loadingKey.SET_MENU))
    }
  }

  return (
    <View style={styles.container}>
      <BackNavigator
        text={t('app.page.setting.menuPanel.set.edit')}
        onPress={() => { history.goBack() }}
      />
      <View style={styles.contentContainer}>
        <View style={styles.leftContent}>
          {/* 步驟 */}
          <SetMenuList
            title={step?.name}
            setMenus={setMenus}
            selectedSetMenu={selectedSetMenu}
            onPress={onSelectStepMenu}
          />
        </View>

        {/** 中間的箭頭 */}
        <View style={styles.arrow}>
          <Image
            style={[styles.arrowIcon, styles.toLeftArrow]}
            source={require('@icons/split-order/to-left.png')}
          />
          <Image
            style={[styles.arrowIcon, styles.toRightArrow]}
            source={require('@icons/split-order/to-right.png')}
          />
        </View>
        <View style={styles.rightContent}>
          {/* 餐點分類 */}
          <CategoryList
            title={t('app.page.setting.menuPanel.set.category')}
            style={{ width: 200 }}
            categories={categories}
            selectedCategory={selectedCategory}
            onPress={setSelectedCategory}
          />
          {/* 餐點項目 */}
          <MenuList
            title={t('app.page.setting.menuPanel.set.item')}
            style={{ width: 260 }}
            categoryId={selectedCategory?.id}
            menus={categoryMenus}
            onPress={(item) => addMenuToStep(item)}
            onPressAllButton={() => setStepItemPriceDialogOpen(true)}
          />
        </View>
      </View>

      <SetMenuDialog
        setMenu={selectedSetMenu} setId={setId} stepId={stepId}
      />

      <OptionsSelectDialog
        title={t('app.page.setting.menuPanel.set.stepItemPriceDialog.title')}
        open={stepItemPriceDialogOpen}
        onClose={() => setStepItemPriceDialogOpen(false)}
        closeOnSelect
        disableHeader
        disableSaveValue
        options={stepItemPriceOptions}
        onSubmit={(option) => {
          onAddAllMenu(option)
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  leftContent: {
    flexDirection: 'row',
    zIndex: 1,
  },
  rightContent: {
    flexDirection: 'row',
    zIndex: 1,
    alignContent: 'space-between',
  },
  arrow: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 0,
  },
  arrowIcon: {
    height: 59,
    width: 79,
    shadowColor: colors.black,
    shadowOpacity: 0.16,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowRadius: 6,
  },
  toLeftArrow: {
    right: -30,
    marginBottom: 5,
  },
  toRightArrow: {
    left: -30,
  },
})
