import { StyleSheet, Text, View } from 'react-native'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'

import EmployeeBoard from './EmployeeBoard'
import LoginBox from '../LoginBox'
import LoginForm from '../LoginForm'
import PasswordBoard from './PasswordBoard'

/**
 * @typedef EmployeeLoginProps
 * @param {() => void} onBack
 * @param {() => void} setIsLogoHidden
 * @param {boolean} showLoginBox
 */

/**
 *
 * @param {EmployeeLoginProps} props
 * @returns
 */
export default function EmployeeLogin (props) {
  const { onBack, setIsLogoHidden, showLoginBox } = props
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isUserLogin = useSelector(state => state.auth.isUserLogin)
  const [employeeSelected, setEmployeeSelected] = useState(null)
  const users = useSelector(state => state.auth.users)
  const title = t('app.page.login.employeeLogin')

  React.useEffect(() => {
    if (isUserLogin) {
      history.push('/table')
    }
  }, [isUserLogin])

  const handleEmployeeLogin = (account, password) => {
    dispatch(actions.auth.userLogin(account, password))
  }

  const handleSelectEmployee = (employee) => {
    setIsLogoHidden(true)
    setEmployeeSelected(employee)
  }

  const goToEmployeeLogin = () => {
    dispatch(actions.auth.updateError(null))
    setIsLogoHidden(false)
    setEmployeeSelected(null)
  }

  return (
    <View style={styles.container}>
      {/* Show employees if not selected */}
      {!employeeSelected
        ? (
          <View style={styles.container}>
            <View style={styles.main}>
              <Text style={styles.title}>{title}</Text>
              <EmployeeBoard onSelect={handleSelectEmployee} />
            </View>
            {(users?.length <= 0 || showLoginBox) &&
              <LoginBox backButton onBack={onBack}>
                <LoginForm
                  accountPlaceHolder={t('app.page.login.employee.id')}
                  onSubmit={handleEmployeeLogin}
                />
              </LoginBox>}
          </View>
        )
        : (
          <PasswordBoard
            employee={employeeSelected}
            onBack={goToEmployeeLogin}
            onSubmit={handleEmployeeLogin}
          />
        )}

    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  blurView: {
    padding: 15,
  },
  title: {
    fontSize: 60,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 25,

  },
  main: {
    flex: 1,
    alignItems: 'center',
    paddingRight: 52.5,
  },

})
