import { Platform, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-native-qrcode-svg'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { resizeImage } from '@/libs/Image/ImageUtil'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import Row from '@/components/Row'

/**
 * @typedef QRCodeDialogProps
 * @property {string} title
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {string} url
 * @property {string?} id
 * @property {boolean?} set
 */

/**
 *
 * @param {QRCodeDialogProps} props
 * @returns
 */
export default function QRCodeDialog (props) {
  const { title, open, onClose, url, id = '', set = false } = props
  const { t } = useTranslation()
  const inputFile = React.useRef()
  const dispatch = useDispatch()
  const [visible, setVisible] = React.useState(open)
  const info = t('app.page.setting.aboutPanel.editAbout.introBackground')
  const cover = t('app.page.setting.aboutPanel.editAbout.frontPageImage')
  const banner = t('app.page.setting.aboutPanel.editAbout.menuBanner')
  let type

  React.useEffect(() => {
    setVisible(open)
  }, [open])

  if (!url) return null
  switch (title) {
    case info: {
      type = 'info'
      break
    }
    case cover: {
      type = 'cover'
      break
    }
    case banner: {
      type = 'banner'
      break
    }
  }

  const upload = async () => {
    try {
      const file = inputFile.current.files[0]
      const resizedBlob = await resizeImage({ file })
      if (id) {
        if (set) {
          await dispatch(actions.menu.uploadSetImage(id, resizedBlob))
        } else {
          await dispatch(actions.menu.uploadImage(id, resizedBlob))
        }
      } else {
        await dispatch(actions.merchant.uploadImage(type, resizedBlob))
      }
      onClose()
    } catch (error) {
      dispatch(actions.app.showAlert({
        title: t('app.page.setting.aboutPanel.editAbout.error.title'),
        message: error.message,
      }))
    }
  }

  const deleteImage = async () => {
    if (id) {
      if (set) {
        await dispatch(actions.menu.deleteSetImage(id))
      } else {
        await dispatch(actions.menu.deleteImage(id))
      }
      onClose()
    } else {
      setVisible(false)
      setTimeout(async () => {
        await dispatch(actions.app.showAlert({
          title: t('app.component.qrCodeDialog.deleteImage'),
          message: `${t('app.component.qrCodeDialog.deleteConfirm')}${title}?`,
          enablePressOutsideClose: false,
          buttons: [
            {
              children: t('app.common.confirm'),
              onPress: () => {
                setTimeout(() => {
                  // setVisible(true)
                  onClose()
                  dispatch(actions.merchant.deleteImage(type))
                }, 500)
              },
            },
            {
              backgroundColor: colors.light,
              textColor: colors.textTertiary,
              children: t('app.common.cancel'),
              onPress: () => {
                setTimeout(() => {
                  setVisible(true)
                }, 500)
              },
            },
          ],
        }))
      }, 500)
    }
  }

  const fetchImage = async () => {
    if (id) {
      await dispatch(actions.menu.fetchCategories())
      onClose()
    } else {
      try {
        await dispatch(actions.merchant.fetchMerchant())
      } catch (error) {
        console.log('fetchMerchant error', error)
      }
      onClose()
    }
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title={title}
      visible={visible}
      onClose={onClose}
      contentContainerStyle={styles.contentContainer}
    >
      {
        Platform.OS !== 'web' && (
          <QRCode
            value={url}
            size={200}
          />
        )
      }
      <Row style={styles.actions}>
        <Button
          textBold
          title={t('app.component.qrCodeDialog.deleteImage')}
          backgroundColor={colors.secondary}
          onPress={deleteImage}
        />
        {
          Platform.OS === 'web'
            ? (
              <View>
                <Button
                  textBold
                  title={t('app.component.qrCodeDialog.select')}
                  backgroundColor={colors.tertiary}
                  disabled={Platform.OS !== 'web'}
                  onPress={() => { inputFile.current.click() }}
                />
                <input type='file' hidden id='fileInput' ref={inputFile} accept='image/*' onChange={upload} />
              </View>
            )
            : (
              <View>
                <Button
                  textBold
                  title={t('app.component.qrCodeDialog.uploaded')}
                  backgroundColor={colors.tertiary}
                  disabled={Platform.OS === 'web'}
                  onPress={fetchImage}
                />
              </View>
            )
        }
      </Row>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 300,
    padding: 16,
  },
  inputContainer: {
    width: '100%',
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.primary,
    margin: 16,
  },
  input: {
    fontSize: 16,
    width: '100%',
    margin: 8,
  },
  actions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})
