import _ from 'lodash'
import orderAppLib from 'dimorder-orderapp-lib'

import i18n from '@/i18n'
import store from '@/redux/store'

const getState = store.getState

export default function getFormatBatchItemStatusWithSet (item) {
  const status = []
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = getState().menu.menus
  item.setItems.forEach(setItem => {
    let setStatus = _.get(allMenus[setItem.menuId]?.localeNames, lang, setItem.name)
    const setItemStatus = orderAppLib.libs.getFormatBatchItemStatus(setItem)
    if (setItemStatus !== '') {
      setStatus += `(${setItemStatus})`
    }
    status.push(setStatus)
  })

  const itemStatus = orderAppLib.libs.getFormatBatchItemStatus(item)
  if (itemStatus !== '') {
    status.concat(itemStatus)
  }

  return item.cancelled ? i18n.t('app.constant.displayStatusKey.cancel_order') : status.join('/')
}
