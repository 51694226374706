import * as app from './app/actions'
import * as auth from './auth/actions'
import * as codePush from './codePush/actions'
import * as config from './config/actions'
import * as menu from './menu/actions'
import * as merchant from './merchant/actions'
import * as order from './order/actions'
import * as orderBatch from './orderBatch/actions'
import * as orderCheckout from './orderCheckout/actions'
import * as orderHistory from './orderHistory/actions'
import * as printer from './printer/actions'
import * as setting from './setting/actions'
import * as statistics from './statistics/actions'
import * as table from './table/actions'
import * as unsyncOrder from './unsyncOrder/actions'

export default {
  app,
  codePush,
  auth,
  merchant,
  menu,
  order,
  orderBatch,
  orderCheckout,
  orderHistory,
  table,
  printer,
  setting,
  config,
  unsyncOrder,
  statistics,
}
