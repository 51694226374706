import { StyleSheet, View } from 'react-native'
import React from 'react'

import IconButton from '@/components/buttons/IconButton'

/**
 * @typedef HeaderProps
 * @property {ViewProps['style']?} style
 * @property {boolean?} dense
 * @property {JSX.Element?} children
 * @property {() => void?} onBack
 */

/**
 *
 * @param {HeaderProps} props
 * @returns
 */
export default function Header (props) {
  const { style, dense = false, children, onBack } = props
  return (
    <View
      style={[
        { minHeight: dense ? 30 : 38 },
        styles.container,
        onBack && styles.backContainer,
        style,
      ]}
    >
      {onBack && (
        <IconButton
          style={styles.iconButton}
          iconStyle={styles.backIcon}
          iconSource={require('@icons/setting/back.png')}
          onPress={onBack}
        />
      )}
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 25,
  },
  backContainer: {
    paddingLeft: 15,
  },
  iconButton: {
    marginLeft: -10,
    padding: 10,
  },
  backIcon: {
    width: 15,
    height: 15,
  },
})
