import { StyleSheet, Text } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef HeaderTextProps
 * @param {string} text
 * @param {'small' | 'large' | null} size
 * @param {TextProps['style']?} style
 */

/**
 *
 * @param {HeaderTextProps} props
 * @returns
 */
export default function HeaderText (props) {
  const { style, size, text } = props
  return (
    <Text
      style={[
        styles.text,
        { fontSize: size === 'large' ? 24 : 16 },
        style,
      ]}
    >
      {text}
    </Text>
  )
}

const styles = StyleSheet.create({
  text: {
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
})
