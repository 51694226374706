import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'

import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

// eslint-disable-next-line no-unused-vars
import { IMerchantExtraPayment } from 'dimorder-orderapp-lib/dist/types/Merchant'

/**
 * @typedef ExtraPaymentInfoProps
 * @param {IMerchantExtraPayment} paymentMethod
 * @param {() => void} onClose
 */

/**
 *
 * @param {ExtraPaymentInfoProps} props
 * @returns
 */
export default function ExtraPaymentInfo (props) {
  const { paymentMethod, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [paymentMethodState, setPaymentMethodState] = React.useState(paymentMethod)

  useEffect(() => {
    setPaymentMethodState(paymentMethod)
  }, [paymentMethod])

  if (!paymentMethod) return null
  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: `${t('app.common.delete') + t('app.page.setting.checkout.payment')}`,
      message: `${t('app.common.is') + t('app.common.delete') + paymentMethod.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            dispatch(actions.merchant.deletePaymentMethod(paymentMethod))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    onClose()
    dispatch(actions.merchant.upsertPaymentMethod(paymentMethodState))
  }

  const extraPaymentText = t('app.page.setting.checkout.extra') + t('app.page.setting.checkout.payment')

  return (
    <>
      <Header>
        <HeaderText text={paymentMethod.key ? extraPaymentText : `${t('app.page.setting.checkout.add') + extraPaymentText}`} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.couponValue')} />
            <SettingListItemRightAction>
              <SettingListInput
                keyboardType='number-pad'
                value={String(paymentMethodState.amount)}
                onChangeText={(amount) => {
                  if (!Number.isNaN(Number(amount))) {
                    setPaymentMethodState({
                      ...paymentMethodState,
                      amount,
                    })
                  }
                }}
                onBlur={(event) => {
                  setPaymentMethodState({
                    ...paymentMethodState,
                    amount: Number(event.nativeEvent.text),
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.paymentName')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={paymentMethodState.name}
                onChangeText={(text) => {
                  setPaymentMethodState({
                    ...paymentMethodState,
                    name: text,
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
      </View>

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          disabled={!paymentMethod.key}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
