import { FlatList, StyleSheet } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useCheckoutPayment } from '@/hooks/orderCheckout'
import { usePaymentMethods } from '@/hooks/merchant'
import { useSelectedOrder } from '@/hooks/orderHistory'
import Column from '@/components/Column'
import Divider from './Divider'
import MethodCard from './MethodCard'
import React from 'react'
import _ from 'lodash'
import paymentConfigs from '@/configs/paymentConfigs'

export default function MethodPanel () {
  const dispatch = useDispatch()
  const selectedOrder = useSelectedOrder()
  const checkoutPayment = useCheckoutPayment()

  const [paymentMethods, cardProviders, qrProviders] = usePaymentMethods()
  const selectedPaymentMethod = checkoutPayment.paymentMethod
  let paymentsOrder = useSelector(state => state.app.settings.paymentsOrder) || paymentConfigs.defaultPaymentsOrder
  if (_.isEmpty(paymentsOrder)) {
    paymentsOrder = paymentConfigs.defaultPaymentsOrder
  }
  const defaultMethods = paymentConfigs.flatPaymentMethods
  const card = paymentConfigs.paymentMathods.find(i => i.key === 'card')
  const qr = paymentConfigs.paymentMathods.find(i => i.key === 'qrpayment')
  const merchantMethods = useSelector(state => state.merchant.data.payments)
  const allMethods = _.unionBy(_.filter(defaultMethods, d => {
    const active = merchantMethods.findIndex(m => m.key === d.key) > -1
    return active
  }), merchantMethods, 'key')
  const allPaymentMethods = _.sortBy(allMethods.map(merchantMethod => {
    const cardIndex = cardProviders.findIndex(c => c.key === merchantMethod.key)
    const qrIndex = qrProviders.findIndex(qr => qr.key === merchantMethod.key)
    const order = paymentsOrder.find(o => o.key === merchantMethod.key)
    let method = paymentMethods.find(p => p.key === merchantMethod.key)
    if (cardIndex > -1) {
      method = cardProviders[cardIndex]
    } else if (qrIndex > -1) {
      method = qrProviders[qrIndex]
    }
    return {
      ...merchantMethod,
      ...method,
      index: _.get(order, 'index'),
      isCard: cardIndex > -1,
      isQR: qrIndex > -1,
    }
  }), 'index').map((s, index) => {
    return { ...s, index }
  })

  const cardPayments = allPaymentMethods.filter(p => p.isCard)
  const qrPayments = allPaymentMethods.filter(p => p.isQR)
  const defaultCardMethodKey = _.head(cardPayments)
  const defaultQrMethodKey = _.head(qrPayments)
  const payments = allPaymentMethods.filter(p => !(p.isCard || p.isQR))

  if (!_.isEmpty(cardPayments)) {
    const qr = _.take(allPaymentMethods, cardPayments[0].index).filter(p => p.isQR)
    payments.splice(cardPayments[0].index - qr.length, 0, card)
  }

  if (!_.isEmpty(qrPayments)) {
    const card = _.take(allPaymentMethods, qrPayments[0].index).filter(p => p.isCard)
    payments.splice(qrPayments[0].index - card.length, 0, qr)
  }

  const paidAmount = selectedOrder.payments.filter(p => p?.status === 'paid')
    .map(p => Number(p.amount))
    .reduce((a, b) => a + b, 0)
  const total = selectedOrder.roundedTotal

  const renderItem = ({ item, index }) => (
    <MethodCard
      style={styles.card}
      item={item}
      active={selectedPaymentMethod === item.key || item.subMethods?.includes(selectedPaymentMethod)}
      onPress={() => {
        const paymentUpdates = {
          paidAmount: Number(total) - Number(paidAmount),
          change: 0,
          paymentTips: 0,
          autoInput: true,
        }
        if (item.key === 'card') {
          // 如果選 card，使用 defaultCardMethodKey
          paymentUpdates.paymentMethod = defaultCardMethodKey?.key
          paymentUpdates.gateway = defaultCardMethodKey?.gateway
        } else if (item.key === 'qrpayment') {
          paymentUpdates.paymentMethod = defaultQrMethodKey?.key
          paymentUpdates.gateway = defaultQrMethodKey?.gateway
        } else {
          paymentUpdates.paymentMethod = item.key
          paymentUpdates.gateway = item.gateway
        }
        dispatch(actions.orderCheckout.updatePayment(paymentUpdates))
      }}
    />
  )

  return (
    <Column style={styles.container}>
      <Divider />
      <FlatList
        horizontal
        data={payments}
        renderItem={renderItem}
        keyExtractor={item => item.key}
        extraDate={selectedPaymentMethod} // To re-render when selected changes
        showsHorizontalScrollIndicator={false}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  card: {
    marginHorizontal: 8,
  },
})
