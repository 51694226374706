import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SettingListItemTextProps
 * @property {string} text
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {SettingListItemTextProps} props
 * @returns
 */
export default function SettingListItemText (props) {
  const { style, text } = props
  return (
    <View style={[styles.container, style]}>
      <Text>{text}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: 6,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
  },
})
