import { StyleSheet, View } from 'react-native'
import { useSelector } from '@/redux'
import BackButton from '../CategoryBar/BackButton'
import CategoryBar from '../CategoryBar'
import FilterMenuItemByTimeButton from '../FilterMenuItemByTimeButton'
import PictureModeSwitchButton from '../PictureModeSwitchButton'
import React from 'react'
import SearchInput from '../SearchInput'
import SetSwitchButton from '../SetSwitchButton'

export default function NumPadOrderHeader (props) {
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const selectedSet = useSelector(state => state.menu.selectedSet)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  const isSet = Boolean(selectedSet)

  if (!selectedCategory) return null

  return (
    <View style={styles.container}>
      {isSet ? <BackButton /> : <SetSwitchButton />}

      {selectedSetStep ? (
        <CategoryBar
          isSet
          setName={selectedSet.name}
          categoryItems={selectedSet.steps}
          selectedId={selectedSetStep?.id}
        />
      ) : (
        <SearchInput />
      )}

      {!isSet && <PictureModeSwitchButton />}
      {!isSet && <FilterMenuItemByTimeButton />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 32,
    marginVertical: 8,
  },
  leftView: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuModeIcon: {
    width: 38,
    height: 38,
    marginLeft: 16,
  },
})
