import { StyleSheet, View } from 'react-native'
import React from 'react'

import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import CenterModal from '@/components/CenterModal'
import NumberPad from '@/components/NumberPad'

const BUTTONS = [
  { text: '1', fontSize: 25 },
  { text: '2', fontSize: 25 },
  { text: '3', fontSize: 25 },
  { text: '4', fontSize: 25 },
  { text: '5', fontSize: 25 },
  { text: '6', fontSize: 25 },
  { text: '7', fontSize: 25 },
  { text: '8', fontSize: 25 },
  { text: '9', fontSize: 25 },
  { text: 'C', fontSize: 24, variant: 'action' },
  { text: '0', fontSize: 25 },
  { text: '.', fontSize: 25 },
]

/**
 * @typedef NumberInputDialogProps
 * @property {boolean} open
 * @property {() => void?} onClose
 * @property {() => void?} onPress
 * @property {string} title
 * @property {'dark' | 'light'} variant
 * @property {string?} defaultValue
 * @property {string?} clearValue
 * @property {boolean?} noCloseButton
 * @property {boolean?} disableDecimalPoint
 * @property {boolean?} enablePressOutsideClose
 * @property {boolean?} replace
 * @property {number?} buttonSize
 * @property {number?} maxLength
 * @property {number?} maxAmount
 * @property {JSX.Element?} footer
 * @property {JSX.Element?} children
 *
 */

/**
 *
 * @param {NumberInputDialogProps} props
 * @returns
 */
export default function NumberInputDialog (props) {
  const {
    open,
    onClose = () => { },
    onPress = () => { },
    title,
    variant = 'light',
    defaultValue = '0',
    clearValue = '0',
    noCloseButton = false,
    disableDecimalPoint = false,
    enablePressOutsideClose = true,
    replace = false,
    buttonSize = 70,
    maxLength = 3,
    maxAmount = 999999,
    footer,
    children,
  } = props

  const [valueString, setValueString] = React.useState(String(defaultValue))
  const disableDecimal = { '.': disableDecimalPoint }

  React.useEffect(() => {
    setValueString(String(defaultValue))
  }, [defaultValue])

  return (
    <CenterModal
      enablePressOutsideClose={enablePressOutsideClose}
      title={title}
      visible={open}
      onClose={() => {
        onClose()
        setValueString(String(defaultValue))
      }}
      contentContainerStyle={styles.modalContainer}
      noCloseButton={noCloseButton}
    >
      <View style={styles.container}>
        {children}
        <NumberPad
          variant={variant}
          buttonSize={buttonSize}
          buttons={BUTTONS}
          disabledTexts={disableDecimal}
          onButtonPress={(inputChar) => {
            if (inputChar === 'C') {
              setValueString(clearValue)
              onPress(clearValue)
              return
            }
            if (inputChar === '.' && String(valueString).includes('.')) {
            // 已有小數點，不再處理小數點
              return
            }
            if (Number(inputChar) <= maxAmount && replace) {
              setValueString(inputChar)
              onPress(inputChar)
              return
            }
            if (Number(valueString + inputChar) > maxAmount) {
              return
            }
            if (String(valueString) === '0' && inputChar !== '.') {
            // 原本是 0，且不是小數點，輸入的內容直接蓋過去
              setValueString(inputChar)
              onPress(inputChar)
              return
            }
            if (valueString.length === maxLength) {
            // 超過長度，不再輸入
              return
            }

            setValueString(valueString + inputChar)
            onPress(valueString + inputChar)
          }}
        />
      </View>
      {footer}
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    padding: 24,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  valueRow: {
    alignSelf: 'stretch',
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.numPadGray,
    borderRadius: 8,
    margin: 2,
    marginBottom: 16,
  },
  valueText: {
    flex: 1,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'right',
    color: colors.textTertiary,
    marginHorizontal: 16,
  },
  submitButton: {
    height: 40,
    width: 70,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    ...shadows.default,
  },
  submitButtonText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 'bold',
  },
})
