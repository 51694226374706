// polyfill crypto.getRandomValues() for uuid
import '@/i18n'
import 'react-native-get-random-values'

import { registerRootComponent } from 'expo'

import App from '@/App'
import polyfillNetworkInspect from '@/libs/polyfillNetworkInspect'

polyfillNetworkInspect()

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App)
