import { FlatList, StyleSheet, Text, View } from 'react-native'
import React, { useState } from 'react'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import ChipLabel from '@/components/ChipLabel'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import PrinterClient from '@/libs/printing/PrinterClient'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import StatusDot from '@/components/StatusDot'
import _ from 'lodash'
import colors from '@/theme/colors'

import PrinterInfo from './PrinterInfo'

const statusColors = {
  [PrinterClient.STATUS.CONNECTING]: colors.tertiary,
  [PrinterClient.STATUS.CONNECTED]: colors.success,
  [PrinterClient.STATUS.ERROR]: colors.error,
  [PrinterClient.STATUS.CLOSED]: colors.gray,
  undefined: colors.gray,
}

export default function PrinterPanel () {
  const { t } = useTranslation()
  const [selectedPrinter, setSelectedPrinter] = useState(null)

  const printerStatus = useSelector(state => state.printer.printerStatus)
  const printers = useSelector(state => state.printer.printerSetting.printers)
  const defaultPrinter = useSelector(state => state.printer.printerSetting.defaultPrinter)
  const qrcodePrinter = useSelector(state => state.printer.printerSetting.qrcodePrinter)
  const reportPrinter = useSelector(state => state.printer.printerSetting.reportPrinter)

  const renderItem = ({ item: printer, index }) => {
    const printerState = printerStatus[printer.id]
    const statusColor = statusColors[_.get(printerState, 'status') ?? 'ERROR']
    const isDefaultPrinter = printer.id === defaultPrinter
    const isQRCodePrinter = printer.id === qrcodePrinter
    const isReportPrinter = printer.id === reportPrinter
    return (
      <SettingListItem
        key={printer.id}
        onPress={() => {
          setSelectedPrinter({
            index,
            ...printer,
            isDefaultPrinter,
            isQRCodePrinter,
            isReportPrinter,
          })
        }}
        divider={index !== printers.length - 1}
      >
        <StatusDot color={statusColor} />
        <SettingListItemText text={printer.name || printer.id} />
        <SettingListItemRightAction style={styles.labels}>
          {isDefaultPrinter && <ChipLabel label={t('app.page.setting.printer.default')} active />}
          {isQRCodePrinter && <ChipLabel label={t('app.page.setting.printer.qrcode')} active />}
          {isReportPrinter && <ChipLabel label={t('app.page.setting.printer.report')} active />}
        </SettingListItemRightAction>
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.printer.deviceList')} />
        </Header>
        <View style={styles.content}>
          <SettingList>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={printers}
              ListEmptyComponent={(
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.printer.noDevice')}</Text>
                </View>
              )}
              keyExtractor={(item) => item.id}
              renderItem={renderItem}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.printer.newDevice')}
              onPress={() => {
                setSelectedPrinter({
                  id: '',
                  name: '',
                  isDefaultPrinter: false,
                  isQrcodePrinter: false,
                  isReportPrinter: false,
                })
              }}
            />
          </SettingList>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <PrinterInfo
          key={selectedPrinter?.id + selectedPrinter?.name}
          printer={selectedPrinter}
          onClose={() => setSelectedPrinter(null)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labels: {
    padding: 0,
  },
})
