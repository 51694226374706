import { all, create } from 'mathjs'
import { bitsToDec } from './convertBinary'
import _ from 'lodash'

const config = {
  matrix: 'Matrix',
}
const math = create(all, config)
export default function getImageCommand (image, command, quadruple = true) {
  try {
    const imageData = _.clone(image)
    const matrix = math.matrix(imageData)
    const size = matrix.size()
    const width = size[1]
    const height = size[0]

    switch (command) {
      case 'b': {
        const data = []
        const chunkedData = _.chunk(math.flatten(imageData), width)
        _.each(chunkedData, d => {
          data.push(98, width / 8, 0, ...bitsToDec(d))
        })
        return data
      }
      case 'ESC K': {
        const chunkedData = _.chunk(imageData, 8)
        const data = [27, 51, 24]
        _.each(chunkedData, d => {
          const printImage = math.resize(d, [8, width], 0)
          const transposeData = math.flatten(math.transpose(printImage))
          data.push(27, 75, width, 0, ...bitsToDec(new Uint8Array(transposeData)), 10)
        })
        return data.concat(27, 50)
      }
      case 'ESC GS S': {
        const data = math.flatten(imageData)
        return [27, 29, 83, 1, width / 8, 0, height, 0, 0, ...bitsToDec(data)]
      }
      case 'GS *': {
        const transposeData = math.flatten(math.transpose(imageData))
        return [29, 42, width / 8, height / 8, ...bitsToDec(new Uint8Array(transposeData))]
      }
      case 'ESC *': {
        const chunkedData = _.chunk(imageData, 8)
        const data = [27, 51, 16]
        _.each(chunkedData, d => {
          const printImage = math.resize(d, [8, width], 0)
          const transposeData = math.flatten(math.transpose(printImage))
          data.push(27, 42, 0, width, 0, ...bitsToDec(new Uint8Array(transposeData)), 10)
        })
        return data.concat(27, 50)
      }
      case 'GS v 0': {
        const data = math.flatten(imageData)
        return [29, 118, 48, quadruple ? 3 : 0, width / 8, 0, height, 0, ...bitsToDec(data)]
      }
      default: {
        return []
      }
    }
  } catch (error) {
    console.log('getImageCommand error:', error)
  }
}
