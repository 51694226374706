import { Animated, Easing, StyleSheet, Text, TouchableOpacity } from 'react-native'
import React from 'react'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

/**
 *
 * @param {Object} props
 * @param {ISnackbarConfig} props.config snackbar 設定
 * @param {() => void} props.handleCloase
 * @returns {JSX.Element}
 */
function Snackbar ({ config = {}, handleCloase }) {
  const {
    show,
    onClose,
    text,
    textColor = colors.white,
    backgroundColor = '#323232',
    duration,
    action,
  } = config

  const hideSnackbars = useSelector(state => state.app.settings?.debugSettings.hideSnackbars)
  const animationValue = React.useRef(new Animated.Value(0)).current

  // 控制自動關閉
  React.useEffect(() => {
    let timeout
    if (duration > 0) {
      timeout = setTimeout(() => {
        onClose?.() // 顯示 snackbar 時可以在 config 中加入 callback 沒有就算了
        handleCloase() // 由 Container dispatch action 關閉 snackbar
      }, duration)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [duration])

  // 控制動畫
  React.useEffect(() => {
    if (show) {
      slideUp()
    } else {
      slideDown()
    }
  }, [show])

  const slideUp = () => {
    Animated.timing(animationValue, {
      toValue: 1,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start()
  }

  const slideDown = () => {
    Animated.timing(animationValue, {
      toValue: 0,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start()
  }

  const translateY = {
    translateY: animationValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0, -48],
    }),
  }

  const renderAction = () => {
    if (!action) return null

    const { text, textColor = colors.secondary, onPress = () => {} } = action

    return (
      <TouchableOpacity style={styles.button} onPress={onPress}>
        <Text style={[styles.buttonText, { color: textColor }]}>{text}</Text>
      </TouchableOpacity>
    )
  }

  if (hideSnackbars) return null

  return (
    <Animated.View style={[styles.container, { backgroundColor, transform: [translateY] }]}>
      <Text style={[styles.text, { color: textColor }]}>{text}</Text>
      {renderAction()}
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: -48,
    left: 0,
    right: 0,
    height: 48,
    paddingHorizontal: 32,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: 'bold',
  },
  button: {
    paddingVertical: 16,
    paddingHorizontal: 32,
  },
  buttonText: {
    fontWeight: 'bold',
  },
})

export default Snackbar
