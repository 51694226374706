const cardKeys = ['visa', 'master', 'ae', 'jcb', 'unionpay']
const qrKeys = ['payme', 'wechat', 'alipay', 'unionpayQR']

// * CardProviders
const visa = {
  key: 'visa',
  name: 'Visa',
  iconSource: require('@icons/checkout/credit-card/visa-card.png'),
}
const master = {
  key: 'master',
  name: 'Master',
  iconSource: require('@icons/checkout/credit-card/master-card.png'),
}
const ae = {
  key: 'ae',
  name: 'American Express',
  iconSource: require('@icons/checkout/credit-card/ae-card.png'),
}
const jcb = {
  key: 'jcb',
  name: 'JCB',
  iconSource: require('@icons/checkout/credit-card/jcb-card.png'),
}
const unionpay = {
  key: 'unionpay',
  name: 'Union Pay',
  iconSource: require('@icons/checkout/credit-card/unionPay-card.png'),
}

// * qrProviders
const payme = {
  key: 'payme',
  name: 'PayMe',
  iconSource: require('@icons/checkout/payment/payme.png'),
  useSmallIcon: false,
}
const wechat = {
  key: 'wechat',
  name: 'app.page.setting.paymentMethod.wechatPay',
  iconSource: require('@icons/checkout/payment/wechat-pay.png'),
  useSmallIcon: false,
  ecrKey: {
    bbmsl: 'wechat',
    globalPayment: 'wechat pay',
    eftPay: 'wechat',
  },
}
const alipay = {
  key: 'alipay',
  name: 'app.page.setting.paymentMethod.alipay',
  iconSource: require('@icons/checkout/payment/alipay.png'),
  useSmallIcon: false,
  ecrKey: {
    bbmsl: 'alipay',
    globalPayment: 'alipay',
    eftPay: 'alipay',
  },
}
const unionpayQR = {
  key: 'unionpayQR',
  name: '雲閃付 Quick Pass',
  iconSource: require('@icons/checkout/payment/union-pay-qr.png'),
  useSmallIcon: false,
  ecrKey: {
    eftPay: 'union pay qr',
  },
}

// * PaymentMethods
const otherMethod = {
  key: null,
  name: null,
  iconSource: require('@icons/checkout/payment/cash.png'),
  useSmallIcon: true,
}
const cash = {
  key: 'cash',
  name: 'app.page.setting.paymentMethod.cash',
  tips: true,
  iconSource: require('@icons/checkout/payment/cash.png'),
  useSmallIcon: true,
}
const card = {
  key: 'card',
  subMethods: cardKeys,
  name: 'app.page.setting.paymentMethod.card',
  iconSource: require('@icons/checkout/payment/credit-card.png'),
  useSmallIcon: true,
}
const qrpayment = {
  key: 'qrpayment',
  subMethods: qrKeys,
  name: 'app.page.setting.paymentMethod.ePayment',
  iconSource: require('@icons/checkout/payment/credit-card.png'),
  useSmallIcon: true,
}
const octopus = {
  key: 'octopus',
  name: 'app.page.setting.paymentMethod.octopus',
  iconSource: require('@icons/checkout/payment/octopus.png'),
  useSmallIcon: true,
  ecrKey: {
    bbmsl: 'octopus',
  },
}
const creditsales = {
  key: 'creditsales',
  name: 'creditsales',
  iconSource: require('@icons/checkout/payment/cash.png'),
  useSmallIcon: true,
}

// * 未使用
const giftCard = {
  key: 'gift-card',
  name: 'app.page.setting.paymentMethod.giftCard',
  iconSource: require('@icons/checkout/payment/gift-card.png'),
  useSmallIcon: true,
}
const tng = {
  key: 'tng',
  name: 'TNG',
  iconSource: require('@icons/checkout/payment/tng.png'),
  useSmallIcon: false,
}

const ecrProviders = [
  visa,
  master,
  ae,
  jcb,
  unionpay,
  payme,
  wechat,
  alipay,
  unionpayQR,
  octopus,
]

const ecrGateways = {
  visa: ['globalPayment', 'bbMSL', 'eftPay'],
  master: ['globalPayment', 'bbMSL', 'eftPay'],
  ae: ['globalPayment', 'bbMSL', 'eftPay'],
  jcb: [],
  unionpay: [],
  payme: ['globalPayment', 'eftPay'],
  wechat: ['bbMSL', 'globalPayment', 'eftPay'],
  alipay: ['bbMSL', 'globalPayment', 'eftPay'],
  unionpayQR: ['eftPay'],
  octopus: ['bbMSL', 'globalPayment', 'eftPay', 'octopusMiniPC'],
}

const CardProviders = [
  visa,
  master,
  ae,
  jcb,
  unionpay,
]

const qrProviders = [
  payme,
  wechat,
  alipay,
  unionpayQR,
]

const paymentMathods = [
  cash,
  card,
  qrpayment,
  giftCard,
  octopus,
  tng,
  // creditsales,
]

// 沒有 card 階層
const flatPaymentMethods = [
  cash,
  visa,
  master,
  ae,
  jcb,
  unionpay,
  giftCard,
  alipay,
  wechat,
  octopus,
  tng,
  payme,
  unionpayQR,
  // creditsales,
]

const ecrProviderMaps = {
  visa: visa,
  master: master,
  ae: ae,
  jcb: jcb,
  unionpay: unionpay,
  unionpayQR: unionpayQR,
  payme: payme,
  octopus: octopus,
  wechat: wechat,
  alipay: alipay,
}

const cardProviderMaps = {
  visa: visa,
  master: master,
  ae: ae,
  jcb: jcb,
  unionpay: unionpay,
}

const qrProviderMaps = {
  unionpayQR: unionpayQR,
  payme: payme,
  wechat: wechat,
  alipay: alipay,
}

const paymentMethodMaps = {
  cash,
  alipay: qrpayment,
  wechat: qrpayment,
  visa: card,
  master: card,
  ae: card,
  jcb: card,
  unionpay: card,
  octopus,
  creditsales,
  giftCard,
  tng,
  payme: qrpayment,
  unionpayQR: qrpayment,
}

const enabledEcrPayments = [
  'alipay',
  'wechat',
  'visa',
  'master',
  'ae',
  'jcb',
  'unionpay',
  'octopus',
  'payme',
  'unionpayQR',
]

const defaultPaymentsOrder = [
  { key: 'cash', index: 0 },
  { key: 'visa', index: 1 },
  { key: 'master', index: 2 },
  { key: 'ae', index: 3 },
  { key: 'jcb', index: 4 },
  { key: 'unionpay', index: 5 },
]

export default {
  otherMethod,
  cardKeys,
  ecrProviders,
  CardProviders,
  qrProviders,
  paymentMathods,
  flatPaymentMethods,
  ecrProviderMaps,
  cardProviderMaps,
  qrProviderMaps,
  paymentMethodMaps,
  enabledEcrPayments,
  defaultPaymentsOrder,
  ecrGateways,
}
