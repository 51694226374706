export default {
  UPSERT_ORDERS: 'ORDER_HISTORY/UPSERT_ORDERS',
  UPDATE_ORDERS: 'ORDER_HISTORY/UPDATE_ORDERS', // ! 整個 orders 將會被蓋掉
  UPDATE_ORDER: 'ORDER_HISTORY/UPDATE_ORDER',
  SELECT_ORDER: 'ORDER_HISTORY/SELECT_ORDER',
  SELECT_ORDER_ITEM: 'ORDER_HISTORY/SELECT_ORDER_ITEM',
  SELECT_ORDER_ITEMS: 'ORDER_HISTORY/SELECT_ORDER_ITEMS',
  SELECT_ORDER_ITEM_MODIFIERS: 'ORDER_HISTORY/SELECT_ORDER_ITEM_MODIFIERS',
  DESELECT_ORDER_ITEM: 'ORDER_HISTORY/DESELECT_ORDER_ITEM',
  SELECT_ORDER_BATCH: 'ORDER_HISTORY/SELECT_ORDER_BATCH',
  DESELECT_ORDER_BATCH: 'ORDER_HISTORY/DESELECT_ORDER_BATCH',
  RESET_SELECTED_ORDER_ITEMS: 'ORDER_HISTORY/RESET_SELECTED_ORDER_ITEMS',
  UPDATE_FILTER: 'ORDER_HISTORY/UPDATE_FILTER',
  UPDATE_QUERY: 'ORDER_HISTORY/UPDATE_QUERY',
  UPDATE_INITIAL_SCROLL_INDEX: 'ORDER_HISTORY/UPDATE_INITIAL_SCROLL_INDEX',
  UPDATE_SEARCH_ORDER: 'ORDER_HISTORY/UPDATE_SEARCH_ORDER',
  ADD_UNCONFIRMED_ORDER: 'ORDER_HISTORY/ADD_UNCONFIRMED_ORDER',
  REMOVE_UNCONFIRMED_ORDER: 'ORDER_HISTORY/REMOVE_UNCONFIRMED_ORDER',
  UPDATE_ORDER_PAYING: 'ORDER_HISTORY/UPDATE_ORDER_PAYING',
  UPDATE_ORDER_SERVICED: 'ORDER_HISTORY/UPDATE_ORDER_SERVICED',
  RESTORE_ORDERS: 'ORDER_HISTORY/RESTORE_ORDERS',
  /**
   * localupdate order
   */
  CANCEL_ORDER: 'ORDER_HISTORY/CANCEL_ORDER',
  UPDATE_ORDER_REMARK: 'ORDER_HISTORY/UPDATE_ORDER_REMARK',
  CONFIRM_ORDER: 'ORDER_HISTORY/CONFIRM_ORDER',
  READY_ORDER: 'ORDER_HISTORY/READY_ORDER',
  COMPLETE_ORDER: 'ORDER_HISTORY/COMPLETE_ORDER',
  UPDATE_ORDER_MODIFIER: 'ORDER_HISTORY/UPDATE_ORDER_MODIFIER',
  UPDATE_SELECTED_ITEM_PRICE: 'ORDER_HISTORY/UPDATE_SELECTED_ItEM_PRICE',
  UPDATE_SELECTED_ITEM_MODIFIER: 'ORDER_HISTORY/UPDATE_SELECTED_ITEM_MODIFIER',
  ADD_ITEM_TAG: 'ORDER_HISTORY/ADD_ITEM_TAG',
  TRANSFER_ITEM: 'ORDER_HISTORY/TRANSFER_ITEM',
  SELECTED_ITEMS_ADD_TAG: 'ORDER_HISTORY/SELECTED_ITEMS_ADD_TAG',
  CANCEL_SELECTED_ITEMS: 'ORDER_HISTORY/CANCEL_SELECTED_ITEMS',
  REVEAL_SELECTED_ITEM: 'ORDER_HISTORY/REVEAL_SELECTED_ITEM',
  CONFIRM_BATCH: 'ORDER_HISTORY/CONFIRM_BATCH',
  CANCEL_BATCH: 'ORDER_HISTORY/CANCEL_BATCH',
  UPDATE_ITEM_SURCHARGE: 'ORDER_HISTORY/UPDATE_ITEM_SURCHARGE',
  UPDATE_SET_ITEM_SURCHARGE: 'ORDER_HISTORY/UPDATE_SET_ITEM_SURCHARGE',
  UPDATE_CUSTOMER_COUNT: 'ORDER_HISTORY/UPDATE_CUSTOMER_COUNT',
  VOID_PAYMENT: 'ORDER_HISTORY/VOID_PAYMENT',
  UPDATE_PAYMENT: 'ORDER_HISTORY/UPDATE_PAYMENT',
  ADD_PAYMENT: 'ORDER_HISTORY/ADD_PAYMENT',
  MERGE_ORDER: 'ORDER_HISTORY/MERGE_ORDER',
  UPDATE_PRINTED: 'ORDER_HISTORY/UPDATE_PRINTED',
  UPDATE_ORDERS_SYNC: 'ORDER_HISTORY/UPDATE_ORDERS_SYNC',
  UPDATE_ORDER_OWNER: 'ORDER_HISTORY/UPDATE_ORDER_OWNER',
}
