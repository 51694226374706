import { ScrollView, StyleSheet, Text, TextInput, TouchableWithoutFeedback, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import Row from '@/components/Row'

import ShortCutButton from './ShortCutButton'

/**
 * @typedef CategoryFilterPanelProps
 * @property {() => void} onClose
 */

/**
 *
 * @param {CategoryFilterPanelProps} props
 * @returns
 */
export default function CategoryFilterPanel (props) {
  const { onClose } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = _.get(useSelector(state => state.statistics.data), 'category')
  const { key, text, filters } = useSelector(state => state.statistics.filter)
  const [selectedCategories, setSelectedCategories] = React.useState(key)
  const [searchText, setSearchText] = React.useState(text)
  const [shortCutFilters, setFilters] = useImmer(filters)

  const renderItem = ({ item, index }) => {
    const selected = selectedCategories.includes(item.key)
    return (
      <ShortCutButton
        key={item.key}
        value={selected}
        text={item.categoryName}
        onPress={() => {
          const updatedList = [...selectedCategories]
          if (selected) {
            _.remove(updatedList, cat => cat === item.key)
          } else {
            updatedList.push(item.key)
          }
          setSelectedCategories(updatedList)
        }}
      />
    )
  }

  return (
    <View style={[StyleSheet.absoluteFill, { zIndex: 101, elevation: 9 }]}>
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={[StyleSheet.absoluteFill, styles.overlay]} />
      </TouchableWithoutFeedback>
      <Column style={styles.content}>
        {/* header */}
        <View style={styles.header}>
          <Text style={styles.headerText}>{t('app.page.orderHistory.filterPanel.header')}</Text>
          <TextInput
            value={searchText}
            style={styles.textInput}
            placeholder={t('app.page.order.search')}
            placeholderTextColor={colors.textPlaceholder}
            onChangeText={(value) => setSearchText(value)}
          />
        </View>

        <ScrollView style={{ flex: 1 }}>
          <Column style={styles.buttonContainer}>
            <Row style={styles.row}>
              <ShortCutButton
                value={Boolean(shortCutFilters.setItem)}
                text={t('app.page.stats.category.setItems')}
                onPress={() => setFilters(draft => { draft.setItem = !draft.setItem })}
              />
              <ShortCutButton
                value={Boolean(shortCutFilters.hideItem)}
                text={t('app.page.stats.category.hideItem')}
                onPress={() => setFilters(draft => { draft.hideItem = !draft.hideItem })}
              />
            </Row>
            <View style={styles.divider} />
          </Column>
          {
            !_.isEmpty(data.categorySection) && (
              <Column style={styles.buttonContainer}>
                <Text style={styles.text}>{t('app.page.orderHistory.filterPanel.itemCategory')}</Text>
                <Row style={styles.row}>
                  {
                    _.map(data.categorySection, (item, index) => {
                      return renderItem({ item, index })
                    })
                  }
                </Row>
                <View style={styles.divider} />
              </Column>
            )
          }
          {
            !_.isEmpty(data.setSection) && (
              <Column style={styles.buttonContainer}>
                <Text style={styles.text}>{t('app.page.orderHistory.filterPanel.setCategory')}</Text>
                <Row style={styles.row}>
                  {
                    _.map(data.setSection, (item, index) => {
                      return renderItem({ item, index })
                    })
                  }
                </Row>
              </Column>
            )
          }
        </ScrollView>

        <Row style={styles.actions}>
          <Button
            title={t('app.page.orderHistory.filterPanel.reset')}
            textBold
            backgroundColor={colors.lightPrimary}
            type='TouchableOpacity'
            onPress={() => {
              setSelectedCategories([])
              setSearchText('')
              dispatch(actions.statistics.selectFilter([], '', false))
              setFilters({})
            }}
          />
          <Button
            title={t('app.page.orderHistory.filterPanel.apply')}
            textBold
            backgroundColor={colors.lightPrimary}
            type='TouchableOpacity'
            onPress={() => {
              dispatch(actions.statistics.selectFilter(selectedCategories, searchText, shortCutFilters))
              onClose()
            }}
          />
        </Row>
      </Column>
    </View>
  )
}

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: colors.black,
    opacity: 0.4,
  },
  content: {
    height: '100%',
    width: 380,
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
  },
  header: {
    height: 140,
    justifyContent: 'flex-end',
    backgroundColor: colors.primary,
    paddingLeft: 38,
    paddingRight: 38,
    paddingBottom: 15,
    ...shadows.default,
  },
  headerText: {
    fontSize: 20,
    lineHeight: 29,
    fontWeight: 'bold',
    color: colors.white,
  },
  selections: {
    flex: 1,
    paddingHorizontal: 30,
  },
  actions: {
    height: 100,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: colors.light,
    paddingHorizontal: 30,
  },
  buttonContainer: {
    width: '100%',
    paddingHorizontal: 25,
    paddingTop: 20,
  },
  row: {
    flexWrap: 'wrap',
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 'bold',
    color: colors.primary,
    marginHorizontal: 8,
    marginVertical: 5,
  },
  divider: {
    height: 2,
    backgroundColor: colors.lightGray3,
    marginTop: 12,
  },
  textInput: {
    marginTop: 5,
    padding: 10,
    width: '100%',
    height: 35,
    color: colors.gray,
    backgroundColor: colors.light,
    borderRadius: 4,
    fontSize: 14,
    lineHeight: 16,
    fontWeight: '500',
    overflow: 'scroll',
  },
})
