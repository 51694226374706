import _ from 'lodash'
import store from '@/redux/store'

/** @type {() => IRootState} */
const getState = store.getState

export default function searchedCategory (search) {
  const searchText = search.toLowerCase()
  let rootCategory = getState().menu.rootCategory?.categories || []
  let rootSetCategory = getState().menu.rootSetCategory?.categories || []

  if (search === '') return { rootCategory, rootSetCategory }

  if (searchText.trim()) {
    rootCategory = rootCategory.filter(category => {
      if (_.get(category, 'name', '').toLowerCase().includes(searchText)) {
        return true
      }
      // 餐點子分類
      const subCategories = category?.categories
      if (subCategories?.length && subCategories?.some(sub => sub?.menus?.some(menu => {
        const name = _.get(menu, 'name', '').toLowerCase()
        return name.includes(searchText)
      }))) {
        return true
      }
      // 餐點項目
      const isMenu = category?.menus?.some(menu => {
        const name = _.get(menu, 'name', '').toLowerCase()
        return name.includes(searchText)
      })
      return isMenu
    })?.map(category => {
      if (_.get(category, 'name', '').toLowerCase().includes(searchText)) {
        return category
      }
      const subCategories = category?.categories
      const newSubCategories = subCategories.filter(sub => {
        return sub?.menus?.some(menu => {
          const name = _.get(menu, 'name', '').toLowerCase()
          return name.includes(searchText)
        })
      }).map(sub => {
        const subMenus = sub?.menus
        const newSubMenus = subMenus.filter(set => {
          const name = _.get(set, 'name', '').toLowerCase()
          return name.includes(searchText)
        })
        return { ...sub, menus: newSubMenus }
      })
      const menus = category?.menus
      const newMenus = menus.filter(menu => {
        const name = _.get(menu, 'name', '').toLowerCase()
        return name.includes(searchText)
      })
      return { ...category, categories: newSubCategories, menus: newMenus }
    })
    rootSetCategory = rootSetCategory.filter(category => {
      if (_.get(category, 'name', '').toLowerCase().includes(searchText)) {
        return true
      }
      // 套餐子分類
      const subCategories = category?.categories
      if (subCategories?.length && subCategories?.some(sub => sub?.sets?.some(set => {
        const name = _.get(set, 'name', '').toLowerCase()
        return name.includes(searchText)
      }))) {
        return true
      }
      // 套餐項目
      const isSet = category?.sets?.some(set => {
        const name = _.get(set, 'name', '').toLowerCase()
        return name.includes(searchText)
      })
      return isSet
    }).map(category => {
      if (_.get(category, 'name', '').toLowerCase().includes(searchText)) {
        return category
      }
      const subCategories = category?.categories
      const newSubCategories = subCategories.filter(sub => {
        return sub?.sets?.some(set => {
          const name = _.get(set, 'name', '').toLowerCase()
          return name.includes(searchText)
        })
      }).map(sub => {
        const subSets = sub?.sets
        const newSubSets = subSets.filter(set => {
          const name = _.get(set, 'name', '').toLowerCase()
          return name.includes(searchText)
        })
        return { ...sub, sets: newSubSets }
      })
      const sets = category?.sets
      const newSets = sets.filter(set => {
        const name = _.get(set, 'name', '').toLowerCase()
        return name.includes(searchText)
      })
      return { ...category, categories: newSubCategories, sets: newSets }
    })
  }

  return { rootCategory, rootSetCategory }
}
