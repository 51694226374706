import { StyleSheet, View } from 'react-native'
import { produce } from 'immer'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import React from 'react'
import _ from 'lodash'

import { PrinterType } from '@/constants/printer'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import BackNavigator from '@/components/Setting/BackNavigator'
import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import ItemList from '@/components/ItemList'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInputRow from '@/components/Setting/SettingListInputRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'

const printTimesOptions = [
  { value: 0, display: '0' },
  { value: 1, display: '1' },
  { value: 2, display: '2' },
  { value: 3, display: '3' },
  { value: 4, display: '4' },
  { value: 5, display: '5' },
]

/**
 * @typedef PrintSettingPanelProps
 * @property {'confirmReceiptSettings' | 'kitchenReceiptSettings' | 'invoiceSettings'} type
 * @property {string} title
 * @property {IPrinterSetting} setting
 * @property {() => void} onClose
 */

/**
 *
 * @param {PrintSettingPanelProps} props
 * @returns
 */
export default function PrintSettingPanel (props) {
  const { type, title, setting, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const receiptGroupByOptions = [
    { value: 'all', display: t('app.page.setting.printer.receiptGroupByAll') },
    { value: 'batch', display: t('app.page.setting.printer.receiptGroupByBatch') },
    { value: 'item', display: t('app.page.setting.printer.receiptGroupByItem') },
  ]
  const cutPaperModeOptions = [
    { value: 'item', display: t('app.page.setting.printer.item') },
    { value: 'category', display: t('app.page.setting.printer.category') },
    { value: 'none', display: t('app.page.setting.printer.none') },
  ]
  const batchLocaleOptions = [
    { value: 'zh', display: t('app.page.setting.printer.chinese') },
    { value: 'en', display: t('app.page.setting.printer.english') },
    { value: 'thai', display: t('app.page.setting.printer.thai') },
    { value: 'korean', display: t('app.page.setting.printer.korean') },
    { value: 'kitchen', display: t('app.page.setting.printer.kitchen') },
  ]

  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]

  const isLoading = useSelector(state => state.printer.isLoading)
  const categories = useSelector(state => state.menu.categories)
  const menus = useSelector(state => state.menu.menus)
  const tables = useSelector(state => state.merchant.data.tables)
  const printerSetting = useSelector(state => state.printer.printerSetting)
  const [settingState, setSettingState] = React.useState(setting)
  const { printCancelReceipt = true, autoPrintTakeaway = true, autoPrintDineIn = true } = settingState
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const [listPanelConfig, setListPanelConfig] = React.useState(null)
  const merchantSetting = useSelector(state => state.merchant.data?.setting) ?? {}
  const settingBatchLocaleOptions = batchLocaleOptions.filter(x => merchantSetting.batchLocaleOptions.includes(x.value))
  const { quickMode } = merchantSetting
  const includedCategories = _.flatMap(printerSetting.kitchenReceiptSettings, k => k.category || [])
  const includedItems = _.flatMap(printerSetting.kitchenReceiptSettings, k => k.menu || [])
  if (_.get(settingState, 'takeaway') === undefined) {
    setSettingState({ ...settingState, takeaway: true })
  }
  const customizedStyleSettings = useSelector(state => state.printer.printerSetting.customizedStyleSettings) || []
  const customizedKitchenSettings = useSelector(state => state.printer.printerSetting.customizedKitchenSettings) || []
  const customizedLabelSettings = useSelector(state => state.printer.printerSetting.customizedLabelSettings) || []
  const customizedStyleOption = (
    type === 'kitchenReceiptSettings'
      ? customizedKitchenSettings
      : type === 'invoiceSettings'
        ? customizedStyleSettings
        : customizedLabelSettings
  ).map(s => {
    return { value: s.id, display: s.name }
  })
  customizedStyleOption.unshift({ value: '', display: t('app.page.setting.printer.null') })

  const printerOptions = printerSetting.printers?.filter(p => {
    if (p.printerType === PrinterType.TM_U220B && type !== 'kitchenReceiptSettings') {
      return false
    }
    if (p.printerType !== PrinterType.TDP225 && type === 'labelSettings') {
      return false
    }
    if (p.printerType === PrinterType.TDP225 && type !== 'labelSettings') {
      return false
    }
    return p
  })?.map(printer => {
    return {
      value: printer.id,
      display: printer.name || printer.id,
    }
  })
  const tableOptions = tables.map(table => {
    return {
      value: table,
      display: table,
    }
  })
  const categoryOptions = _.map(categories, category => {
    if (
      category.id === 'ROOT' ||
      category.id === 'SET_ROOT' ||
      category.type === 'SET' ||
      category?.categories?.length > 0 ||
      category.sets?.length > 0 ||
      category.path?.includes('SET_ROOT')
    ) {
      return null
    }
    const subCategory = category.parentId !== 'ROOT'
    const parentName = _.get(categories, `${category.parentId}.name`, '')
    return {
      value: category.id,
      display: `${subCategory ? `${parentName} - ` : ''}${category.name}`,
    }
  }).filter(o => o)

  const selectedPrinters = settingState?.printer?.length > 0
    ? settingState.printer?.map(printerId => {
      const printer = printerSetting.printers.find(printer => printer.id === printerId)
      return printer?.name || printer?.id.split(':')[0] || printerId
    }).join(', ')
    : t('app.page.setting.printer.noSelected')
  const selectedTables = settingState.table?.length > 0
    ? settingState.table.join(', ')
    : t('app.page.setting.printer.noSelected')
  const selectedCategories = settingState.category?.length > 0
    ? settingState.category?.map(categoryId => {
      const category = categories[categoryId]
      return category?.name
    }).join(', ')
    : t('app.page.setting.printer.noSelected')
  const selectedMenus = settingState?.menu?.length > 0
    ? settingState.menu?.map(menuId => {
      const menu = menus[menuId]
      return menu?.name
    }).join(', ')
    : t('app.page.setting.printer.noSelected')

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  if (listPanelConfig) {
    return (
      <ItemList
        onClose={(value) => {
          setListPanelConfig(null)
          setSettingState({ ...settingState, menu: value })
        }}
        menu={settingState?.menu ?? []}
        includedCategories={includedCategories}
        includedItems={includedItems}
        search
      />
    )
  }

  const handleDelete = () => {
    const newPrinterSetting = produce(printerSetting, (draft) => {
      const draftSettings = draft[type]
      // * Delete
      const index = draftSettings.findIndex(draftSetting => {
        return setting.id === draftSetting.id
      })
      draftSettings.splice(index, 1)
    })

    dispatch(actions.printer.updatePrinterSetting({ printerSetting: newPrinterSetting, showSuccessMessage: true }))
    onClose()
  }

  const handleSave = async () => {
    const newPrinterSetting = produce(printerSetting, (draft) => {
      const draftSettings = draft[type]
      if (settingState.id) {
        // * Update
        const index = draftSettings.findIndex(draftSetting => {
          return setting.id === draftSetting.id
        })
        draftSettings[index] = settingState
      } else {
        // * Create
        draftSettings.push({
          id: uuid(),
          ...settingState,
        })
      }
    })

    dispatch(actions.printer.updatePrinterSetting({ printerSetting: newPrinterSetting, showSuccessMessage: true }))
    onClose()
  }

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <BackNavigator
          text={title}
          onPress={onClose}
        />
        <SettingList style={styles.settingList}>
          <SettingListInputRow
            divider
            label={t('app.page.setting.printer.name')}
            value={settingState.name}
            onChangeText={(text) => {
              setSettingState({ ...settingState, name: text })
            }}
          />

          <SettingListEnterRow
            divider
            label={t('app.routes.setting.printing.customized')}
            value={
              customizedStyleOption.find(option => {
                return option.value === settingState.customized
              })?.display ?? t('app.page.setting.printer.null')
            }
            onPress={() => {
              setOptionsPanelConfig({
                title: t('app.routes.setting.printing.customized'),
                closeOnSelect: true,
                defaultValue: settingState.customized,
                onChangeValue: (value) => {
                  setSettingState({ ...settingState, customized: value })
                },
                options: customizedStyleOption,
              })
            }}
          />

          <SettingListEnterRow
            divider
            label={t('app.page.setting.printer.printTimes')}
            value={(settingState.printTimes ?? '').toString()}
            onPress={() => {
              setOptionsPanelConfig({
                title: t('app.page.setting.printer.printTimes'),
                closeOnSelect: true,
                defaultValue: settingState.printTimes,
                onChangeValue: (value) => {
                  setSettingState({ ...settingState, printTimes: value })
                },
                options: printTimesOptions,
              })
            }}
          />

          {type === 'kitchenReceiptSettings' && (
            <>
              <SettingListEnterRow
                divider
                label={t('app.page.setting.printer.printerLang')}
                value={
                  settingBatchLocaleOptions.find(option => {
                    return option.value === settingState.batchLocale
                  })?.display ?? t('app.page.setting.printer.notSet')
                }
                onPress={() => {
                  setOptionsPanelConfig({
                    title: t('app.page.setting.printer.printerLang'),
                    closeOnSelect: true,
                    defaultValue: settingState.batchLocale,
                    onChangeValue: (value) => {
                      setSettingState({ ...settingState, batchLocale: value })
                    },
                    options: settingBatchLocaleOptions,
                  })
                }}
              />
              <SettingListEnterRow
                divider
                label={t('app.page.setting.printer.cutPaperMode')}
                value={
                  cutPaperModeOptions.find(option => {
                    return option.value === settingState.cutPaperMode
                  })?.display ?? t('app.page.setting.printer.null')
                }
                onPress={() => {
                  setOptionsPanelConfig({
                    title: t('app.page.setting.printer.cutPaperMode'),
                    closeOnSelect: true,
                    defaultValue: settingState.cutPaperMode,
                    onChangeValue: (value) => {
                      setSettingState({ ...settingState, cutPaperMode: value })
                    },
                    options: cutPaperModeOptions,
                  })
                }}
              />
              {
                settingState.cutPaperMode === 'item' && (
                  <SettingListSwitchRow
                    divider
                    label={t('app.page.setting.printer.printSetmenuBundled')}
                    value={settingState.printSetmenuBundled}
                    onChangeValue={() => {
                      setSettingState({ ...settingState, printSetmenuBundled: !settingState.printSetmenuBundled })
                    }}
                  />
                )
              }
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.printer.splitItem')}
                value={settingState.splitItem}
                onChangeValue={() => {
                  setSettingState({ ...settingState, splitItem: !settingState.splitItem })
                }}
              />
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.printer.printHoldItem')}
                value={_.get(settingState, 'printHoldItem', true)}
                onChangeValue={() => {
                  setSettingState({ ...settingState, printHoldItem: !settingState.printHoldItem })
                }}
              />
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.printer.printTransferItem')}
                value={settingState.printTransferItem}
                onChangeValue={() => {
                  setSettingState({ ...settingState, printTransferItem: !settingState.printTransferItem })
                }}
              />
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.printer.printCancelledItem')}
                value={settingState.printCancelledItem}
                onChangeValue={() => {
                  setSettingState({ ...settingState, printCancelledItem: !settingState.printCancelledItem })
                }}
              />
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.printer.noFooter')}
                value={settingState.noFooter}
                onChangeValue={() => {
                  setSettingState({ ...settingState, noFooter: !settingState.noFooter })
                }}
              />
            </>
          )}

          {(type === 'kitchenReceiptSettings' || type === 'labelSettings') && (
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.printWithPrice')}
              value={settingState.printWithPrice}
              onChangeValue={() => {
                setSettingState({ ...settingState, printWithPrice: !settingState.printWithPrice })
              }}
            />
          )}

          {type === 'kitchenReceiptSettings' && (
            <SettingListSwitchRow
              divider
              label={t('app.page.setting.printer.deepenDotColor')}
              value={_.get(settingState, 'deepenDotColor')}
              onChangeValue={() => {
                setSettingState({ ...settingState, deepenDotColor: !settingState.deepenDotColor })
              }}
            />
          )}

          {type === 'invoiceSettings' && (
            <>
              <SettingListEnterRow
                divider
                label={t('app.page.setting.printer.receiptGroupBy',
                )}
                value={
                  receiptGroupByOptions.find(option => {
                    return option.value === settingState.receiptGroupBy
                  })?.display ?? t('app.page.setting.printer.receiptGroupByAll')
                }
                onPress={() => {
                  setOptionsPanelConfig({
                    title: t('app.page.setting.printer.receiptGroupBy'),
                    closeOnSelect: true,
                    defaultValue: settingState.receiptGroupBy,
                    onChangeValue: (value) => {
                      setSettingState({ ...settingState, receiptGroupBy: value })
                    },
                    options: receiptGroupByOptions,
                  })
                }}
              />
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.printer.syncReceiptPrioritizedPrinter')}
                value={settingState.syncReceiptPrioritizedPrinter}
                onChangeValue={() => {
                  setSettingState({ ...settingState, syncReceiptPrioritizedPrinter: !settingState.syncReceiptPrioritizedPrinter })
                }}
              />
              {
                !quickMode && (
                  <SettingListSwitchRow
                    divider
                    label={t('app.page.setting.printer.syncReceipt')}
                    value={settingState.syncReceipt}
                    onChangeValue={() => {
                      setSettingState({ ...settingState, syncReceipt: !settingState.syncReceipt })
                    }}
                  />
                )
              }
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.printer.syncTakeawayReceipt')}
                value={settingState.syncTakeawayReceipt}
                onChangeValue={() => {
                  setSettingState({ ...settingState, syncTakeawayReceipt: !settingState.syncTakeawayReceipt })
                }}
              />
              {
                !quickMode && (
                  <SettingListSwitchRow
                    divider
                    label={t('app.page.setting.localPanel.setting.other.autoPrintDineIn')}
                    value={autoPrintDineIn}
                    onChangeValue={() => {
                      setSettingState({ ...settingState, autoPrintDineIn: !autoPrintDineIn })
                    }}
                  />
                )
              }
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.localPanel.setting.other.autoPrintTakeaway')}
                value={autoPrintTakeaway}
                onChangeValue={() => {
                  setSettingState({ ...settingState, autoPrintTakeaway: !autoPrintTakeaway })
                }}
              />
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.localPanel.setting.other.printCancelReceipt')}
                value={printCancelReceipt}
                onChangeValue={() => {
                  setSettingState({ ...settingState, printCancelReceipt: !printCancelReceipt })
                }}
              />
              <SettingListSwitchRow
                divider
                label={t('app.page.setting.printer.lineSpacing')}
                value={settingState.lineSpacing}
                onChangeValue={() => {
                  setSettingState({ ...settingState, lineSpacing: !settingState.lineSpacing })
                }}
              />
            </>
          )}

          {
            type !== 'labelSettings' && (
              <SettingListEnterRow
                divider
                label={t('app.page.setting.printer.font')}
                value={
                  textStyleOptions.find(option => {
                    const field = type === 'invoiceSettings' ? 'customerReceiptStyleText' : 'batchMenuStyleText'
                    return option.value === settingState[field]
                  })?.display ?? t('app.page.setting.printer.notSet')
                }
                onPress={() => {
                  const field = type === 'invoiceSettings' ? 'customerReceiptStyleText' : 'batchMenuStyleText'
                  setOptionsPanelConfig({
                    title: t('app.page.setting.printer.font'),
                    closeOnSelect: true,
                    defaultValue: settingState[field],
                    onChangeValue: (value) => {
                      setSettingState({ ...settingState, [field]: value })
                    },
                    options: textStyleOptions,
                  })
                }}
              />
            )
          }

          {
            type === 'labelSettings' && (
              <>
                <SettingListInputRow
                  divider
                  label={t('app.page.setting.printer.width')}
                  value={String(_.get(settingState, 'width', 1.58))}
                  onChangeText={(text) => {
                    if (!Number.isNaN(Number(text))) {
                      setSettingState({ ...settingState, width: text })
                    }
                  }}
                  inputProps={{
                    onBlur: (event) => {
                      setSettingState({ ...settingState, width: Number(event.nativeEvent.text) })
                    },
                  }}
                />
                <SettingListInputRow
                  divider
                  label={t('app.page.setting.printer.height')}
                  value={String(_.get(settingState, 'height', 1.18))}
                  onChangeText={(text) => {
                    if (!Number.isNaN(Number(text))) {
                      setSettingState({ ...settingState, height: text })
                    }
                  }}
                  inputProps={{
                    onBlur: (event) => {
                      setSettingState({ ...settingState, height: Number(event.nativeEvent.text) })
                    },
                  }}
                />
              </>
            )
          }

          <SettingListSwitchRow
            label={t('app.page.setting.printer.takeaway')}
            value={settingState.takeaway}
            onChangeValue={() => {
              setSettingState({ ...settingState, takeaway: !settingState.takeaway })
            }}
          />

          {/* <SettingListEnterRow
            label={t('app.page.setting.printer.type')}
            value={
              printerTypeOptions.find(option => {
                return _.get(settingState, 'printerType') && option.value === settingState.printerType.toUpperCase()
              })?.display ?? t('app.page.setting.printer.notSet')
            }
            onPress={() => {
              setOptionsPanelConfig({
                title: t('app.page.setting.printer.type'),
                closeOnSelect: true,
                defaultValue: settingState.printerType,
                onChangeValue: (value) => {
                  setSettingState({ ...settingState, printerType: value })
                },
                options: printerTypeOptions,
              })
            }}
          /> */}

        </SettingList>
      </View>

      {/** Right */}
      <View style={styles.contentContainerRight}>
        <Header />
        <View style={styles.content}>
          <SettingList style={styles.settingList}>
            <SettingListEnterRow
              divider
              label={t('app.page.setting.printer.select') + t('app.page.setting.printer.printer')}
              value={settingState?.printer?.length ?? ''}
              onPress={() => {
                setOptionsPanelConfig({
                  title: `${t('app.page.setting.printer.select') + t('app.page.setting.printer.printer')}`,
                  multiple: true,
                  defaultValue: settingState.printer.filter(printerId => printerSetting.printers.find(printer => printer.id === printerId)),
                  onChangeValue: (value) => {
                    setSettingState({ ...settingState, printer: value })
                  },
                  options: printerOptions,
                })
              }}
            />
            <SettingListItem>
              <SettingListItemRightAction>
                <SettingListItemText
                  style={styles.rightText}
                  text={selectedPrinters}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          </SettingList>

          {type !== 'invoiceSettings' && (
            <SettingList style={styles.settingList}>
              <SettingListEnterRow
                divider
                label={t('app.page.setting.printer.category') + t('app.page.setting.printer.setting')}
                value={settingState?.category?.length ?? ''}
                onPress={() => {
                  setOptionsPanelConfig({
                    title: `${t('app.page.setting.printer.category') + t('app.page.setting.printer.setting')}`,
                    multiple: true,
                    defaultValue: settingState.category,
                    onChangeValue: (value) => {
                      setSettingState({ ...settingState, category: value })
                    },
                    options: categoryOptions,
                  })
                }}
              />
              <SettingListItem>
                <SettingListItemRightAction>
                  <SettingListItemText
                    style={styles.rightText}
                    text={selectedCategories}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
            </SettingList>
          )}

          {type !== 'invoiceSettings' && (
            <SettingList style={styles.settingList}>
              <SettingListEnterRow
                divider
                label={t('app.page.setting.printer.item') + t('app.page.setting.printer.setting')}
                value={settingState?.menu?.length ?? ''}
                onPress={() => {
                  setListPanelConfig({
                    title: `${t('app.page.setting.printer.item') + t('app.page.setting.printer.setting')}`,
                    multiple: true,
                    defaultValue: settingState.category,
                    onChangeValue: (value) => {
                      setSettingState({ ...settingState, category: value })
                    },
                    options: categoryOptions,
                  })
                }}
              />
              <SettingListItem>
                <SettingListItemRightAction>
                  <SettingListItemText
                    style={styles.rightText}
                    text={selectedMenus}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
            </SettingList>
          )}

          {
            !quickMode && (
              <SettingList style={styles.settingList}>
                <SettingListEnterRow
                  divider
                  label={t('app.page.setting.printer.table') + t('app.page.setting.printer.setting')}
                  value={settingState?.table?.length ?? ''}
                  onPress={() => {
                    setOptionsPanelConfig({
                      title: `${t('app.page.setting.printer.table') + t('app.page.setting.printer.setting')}`,
                      multiple: true,
                      defaultValue: settingState.table,
                      onChangeValue: (value) => {
                        setSettingState({ ...settingState, table: value })
                      },
                      options: tableOptions,
                    })
                  }}
                />
                <SettingListItem>
                  <SettingListItemRightAction>
                    <SettingListItemText
                      style={styles.rightText}
                      text={selectedTables}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              </SettingList>
            )
          }

        </View>

        <View style={styles.buttons}>
          <Button
            textBold
            title={t('app.common.delete')}
            style={styles.button}
            backgroundColor={colors.primary}
            disabled={isLoading || setting.id == null}
            onPress={handleDelete}
          />
          <Button
            textBold
            title={t('app.common.save')}
            style={styles.button}
            backgroundColor={colors.darkSecondary}
            disabled={isLoading}
            onPress={handleSave}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
  },
  contentContainerRight: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
    marginLeft: 20,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  settingList: {
    marginBottom: 10,
  },
  rightText: {
    justifyContent: 'flex-end',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
