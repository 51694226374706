import { useSelector } from '@/redux'
import OrderItem from './OrderItem'
import React from 'react'
import _ from 'lodash'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef OrderListProps
 * @property {IAppOrder} orders
* @property {(order: IAppOrder) => void} onSelectOrder
 * @property {boolean} [expanded]
 * @property {boolean} [disabled]
 */

/**
 *
 * @param {OrderListProps} props
 * @returns
 */
export default function OrderList (props) {
  const { orders, onSelectOrder, expanded, disabled } = props
  const unpaidOrders = _.filter(orders, order => order.status !== 'paid')
  const { selectedOrders } = useSelector(state => state.table)

  return (
    <>
      {_.map(unpaidOrders, (order, index) => {
        const isSelected = _.some(selectedOrders, selectedOrder => selectedOrder.id === order.id)
        return (
          <OrderItem
            key={`order-${order.id}`}
            index={index}
            order={order}
            onSelectOrder={onSelectOrder}
            expanded={expanded}
            selected={isSelected}
            disabled={disabled}
          />
        )
      })}
    </>
  )
}
