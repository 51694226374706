import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Column from '@/components/Column'
import React from 'react'
import Row from '@/components/Row'
import TableCell from './TableCell'
import _ from 'lodash'
import colors from '@/theme/colors'
import orderAppLib from 'dimorder-orderapp-lib'
import tableRoundFields from '@/configs/tableRoundFields'

export default function TableRow ({
  serial,
  item,
  style,
  textStyle,
  fields,
  widths,
  justifyContents,
  renderLeftAction,
  renderRightAction,
  commentText = null,
  displayConfigs,
  displaySetItems,
  divider = true,
}) {
  const commentTextStyle = {
    marginLeft: widths[0],
    paddingLeft: 16,
    fontSize: 10,
    lineHeight: 15,
    color: colors.textSecondary,
    opacity: 0.8,
  }
  const rounding = useSelector(state => state.merchant.data.rounding)
  const { t } = useTranslation()
  const showDivider = (divider && _.isEmpty(item.setItems)) || !displaySetItems

  const renderItem = ({ item: setItem, index }) => {
    const isLast = _.findLastIndex(item.setItems) === index
    return (
      <TableRow
        item={setItem}
        fields={fields}
        widths={widths}
        justifyContents={justifyContents}
        commentText={setItem.status}
        style={styles.setItemContainer}
        displaySetItems
        isSetItem
        divider={isLast}
      />
    )
  }
  return (
    <>
      <Column style={[styles.container, showDivider && styles.border, style]}>
        <Row style={styles.main}>
          {fields.map((field, index) => {
            const width = widths[index]
            const justifyContent = justifyContents[index]
            const roundField = tableRoundFields.includes(field)
            const displayConfig = displayConfigs && displayConfigs[field]
            let value
            if (field === 'serial' && index === 0) {
              value = (serial || item.serial)
            } else if (roundField) {
              value = orderAppLib.libs.getDisplayRoundNumber(item[field], rounding)
            } else {
              value = item[field]
            }
            if (index === 0 && renderLeftAction) {
              const columnStyle = {
                flex: width,
                justifyContent,
              }
              return (
                <View key='left-action' style={columnStyle}>
                  {renderLeftAction()}
                </View>
              )
            }
            if (index === fields.length - 1 && renderRightAction) {
              const columnStyle = {
                flex: width,
                justifyContent,
              }
              return (
                <View key='right-action' style={columnStyle}>
                  {renderRightAction()}
                </View>
              )
            }
            const priceUndetermined = (field === 'price' || field === 'total') && value === t('app.constant.order.priceUndetermined')
            const printError = item.printError
            return (
              <TableCell
                key={`table-row-${field}-${item[field]}`}
                printError={printError}
                width={width}
                justifyContent={justifyContent}
                value={value}
                field={field}
                textStyle={[
                  textStyle,
                  (priceUndetermined || printError) && { color: colors.red },
                  (field === 'amount' && item.paymentStatus === 'cancel') && { textDecorationLine: 'line-through' },
                ]}
                displayConfig={displayConfig}
              />
            )
          })}
        </Row>
        {Boolean(commentText) &&
          <Text
            style={[commentTextStyle, textStyle]}
            numberOfLines={1}
          >
            {
              Array.isArray(commentText)
                ? commentText.join('/')
                : String(commentText)
            }
          </Text>}
      </Column>
      {
        (displaySetItems && item.isSet) && (
          <FlatList
            data={item.setItems}
            renderItem={renderItem}
            keyExtractor={item => item.id}
            showsVerticalScrollIndicator={false}
          />
        )
      }
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 45,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
  },
  setItemContainer: {
    minHeight: 35,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
  },
  border: {
    borderBottomColor: '#D6D6D6',
    borderBottomWidth: 1,
  },
  main: {
    alignItems: 'center',
  },
})
