import _ from 'lodash'
import hash from 'object-hash'
import moment from 'moment'
import shortid from 'shortid'

import { PrintStyle } from '@/constants/printing'
import { paymentMethods as constPaymentMethods, ecrGateway } from '@/constants'
import i18n from '@/i18n'
import store from '@/redux/store'

import { PrinterFontSize } from '@/constants/printer'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { getDisplayCustomerName } from '../../order'
import { getPaymentMethod } from '@/libs/merchant'
import PrintDoc from '@/libs/printing/PrintDoc'

/* eslint-disable no-unused-vars */
import { IAppBatchItemSummary, IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IMerchant } from 'dimorder-orderapp-lib/dist/types/Merchant'
/* eslint-enable no-unused-vars */

/** @type {() => IRootState} */
const getState = store.getState
export default class CustomizedOrderReceipt extends PrintDoc {
  TYPE = PrintDoc.Types.CUSTOMIZED_ORDER_RECEIPT

  /**
   * @param {IMerchant} merchant
   * @param {IAppBatchItemSummary[]} summaryItems 經 getLocalizedReceiptSummary 取得特定語言的 item.name, setItem.name, option.name 後的 summary
   * @param {IAppOrder} order
   * @param {IPrinterDefaultSetting | IInvoiceSetting} printerSetting
   * @param {TCancelledType?} cancelledType
   * @param {IPrinterConfig?} printConfig
   * @param {object} customizedStyle // ? object 的內容是什麼？
   * @param {TOrderReceiptPrintReason?} printReason
   * @param {string?} shortUrl // Print CRM QRCode
   *
   * @typedef IPrinterConfig
   * @property {boolean?} reprint
   * @property {number?} orderSerialDigit
   */
  constructor (
    summaryItems,
    merchant,
    order,
    printerSetting,
    cancelledType,
    printConfig,
    customizedStyle,
    printReason,
    shortUrl,
  ) {
    super()
    this.summaryItems = summaryItems
    this.merchant = merchant
    this.printerSetting = printerSetting
    this.printId = shortid.generate()
    this.discountItem = []
    this.order = order
    this.subtotal = 0
    this.cancelledType = cancelledType
    this.customizedStyle = customizedStyle
    this.reprint = printConfig?.reprint
    this.printReason = printReason
    this.orderSerialDigit = _.get(printConfig, 'orderSerialDigit', 3)
    this.locale = printConfig?.locale // 'zh' | 'en' | undefined
    this.lineSpacing = Number(customizedStyle.lineSpacing) || 1
    this.printHash = this._generatePrintHash()
    this.shortUrl = shortUrl
  }

  /**
   * 翻譯文字
   * 如果 printConfig.locale 有指定語言時更新 i18n 並使用指定語言進行翻譯
   * @param {string} key
   * @returns
   */
  t (key) {
    if (this.locale === 'zh' || this.locale === 'en') {
      const t = this.locale === 'zh'
        ? i18n.getFixedT('zh-HK')
        : i18n.getFixedT('en-US')
      return t(key)
    }
    return i18n.t(key)
  }

  print = () => {
    const { printTimes } = this.printerSetting
    const printer = this.printer
    const styles = this.customizedStyle.style
    console.log('CustomizedOrderReceipt: ', styles)

    printer.addInit()
    printer.addCodePage()

    if (this.cancelledType) {
      const text = this.t('app.printing.cancelReceipt.' + this.cancelledType)
      printer.addAlign('center')
      printer.addBoldMode()
      printer.addText(text, PrinterFontSize.LARGE).addRowFeed(2)
      printer.addBoldMode(false)
    }

    _.each(styles, style => {
      switch (style.key) {
        case 'header':
          this.addHeader(style)
          break
        case 'info':
          this.addInfo(style)
          break
        case 'content':
          this.addContent(style)
          break
        case 'subtotal':
          this.addSubtotal(style)
          break
        case 'payment': {
          if (this.order.status === 'paid') {
            this.addPayment(style)
          }
          break }
        case 'footer':
          this.addFooter(style)
          break
        case 'separator':
          this._addSeparator(style.style)
          break
        case 'custom': {
          if (style.type === 'separator') {
            this._addSeparator(style.style)
          }
          if (style.type === 'text') {
            this._addAlign(style.align)
            printer.addBoldMode(style.bold)
            printer.addText(style.text, style.fontSize)
            printer.addRowFeed(this.lineSpacing + style.fontSize)
            printer.addBoldMode(false)
          }
          if (style.type === 'barcode') {
            printer.addRowFeed(2)
            printer.addAlign('center')
            printer.addBarcode(style)
            printer.addRowFeed()
          }
          break
        }
        case 'joinMemberQRCode': {
          (style.enable && this.shortUrl !== '') &&
            this.addQRCode(style)
          break
        }
      }
    })

    if (this.cancelledType) {
      const text = this.t('app.printing.cancelReceipt.' + this.cancelledType)
      printer.addAlign('center')
      printer.addRowFeed()
      printer.addBoldMode()
      printer.addText(text, PrinterFontSize.LARGE).addRowFeed()
    }
    printer.addRowFeed()
    printer.addBoldMode(false)
    printer.addAlign('left').addText(`${this.printId}       -Powered by DimPOS-`, PrinterFontSize.SMALL)
    printer.addRowFeed(5)
    printer.addCutPaper()

    if (printTimes > 0) {
      this._addCopy(printTimes)
    }

    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  _addCopy = (printTimes) => {
    const data = Array.from(this.printer.getCommandBuffer())
    const printer = this.printer

    for (let i = 0; i < printTimes; i++) {
      printer.addCommand(data)
    }
  }

  addHeader = (style) => {
    const {
      table,
      serial,
      deliveryType,
      shipping,
      pickupAt,
    } = this.order

    const {
      name,
      address,
      contact,
      setting: { quickMode },
    } = this.merchant
    const printer = this.printer
    const {
      orderType,
      serialNo,
      scheduleAt,
      pickupAt: pickup,
      merchantLogo,
      merchantName,
      merchantAddress,
      merchantContact,
    } = style.content

    if (!table) {
      if (orderType.enable && !quickMode) {
        this._addAlign(orderType.align)
        printer.addBoldMode(orderType.bold)
        if (deliveryType === 'storeDelivery') {
          printer.addText(this.t('app.printing.sdeliveryTitle'), orderType.fontSize)
        } else {
          printer.addText(this.t('app.printing.takeawayTitle'), orderType.fontSize)
        }
        printer.addRowFeed(this.lineSpacing + orderType.fontSize)
      }

      if (serialNo.enable && quickMode && this.order.from === 'MERCHANT') {
        this._addAlign(serialNo.align)
        printer.addBoldMode(serialNo.bold)
        printer.addText(`${serial.slice(-3)}`, serialNo.fontSize)
        printer.addRowFeed(this.lineSpacing + serialNo.fontSize)
      }

      if (scheduleAt.enable && shipping && _.get(shipping, 'lalamove.scheduleAt')) {
        this._addAlign(scheduleAt.align)
        printer.addBoldMode(scheduleAt.bold)
        printer.addText(
          this.t('app.printing.driver') +
          ':',
          scheduleAt.fontSize,
        )
        printer.addRowFeed(this.lineSpacing)
        printer.addText(
          moment(shipping.lalamove.scheduleAt).add(10, 'm').format(scheduleAt.format),
          scheduleAt.fontSize,
        )
        printer.addRowFeed(this.lineSpacing + scheduleAt.fontSize)
      }

      if (pickup.enable && !quickMode) {
        this._addAlign(pickup.align)
        printer.addBoldMode(pickup.bold)
        printer.addText(
          this.t('app.printing.customerPickup') +
          ':',
          pickup.fontSize,
        )
        printer.addRowFeed(this.lineSpacing)
        printer.addText(
          moment(pickupAt).format(pickup.format),
          pickup.fontSize,
        )
        printer.addRowFeed(this.lineSpacing + pickup.fontSize)
      }
    }

    if (merchantLogo.enable) {
      printer.addLogo()
      printer.addRowFeed(this.lineSpacing)
    }

    if (merchantName.enable) {
      this._addAlign(merchantName.align)
      printer.addBoldMode(merchantName.bold)
      printer.addText(name, merchantName.fontSize)
      printer.addRowFeed(this.lineSpacing + merchantName.fontSize)
    }

    if (merchantAddress.enable) {
      this._addAlign(merchantAddress.align)
      printer.addBoldMode(merchantAddress.bold)
      printer.addText(address, merchantAddress.fontSize)
      printer.addRowFeed(this.lineSpacing + merchantAddress.fontSize)
    }

    if (merchantContact.enable) {
      this._addAlign(merchantContact.align)
      printer.addBoldMode(merchantContact.bold)
      printer.addText(`${this.t('app.printing.phone')}: ${contact}`, merchantContact.fontSize)
      printer.addRowFeed(this.lineSpacing + merchantContact.fontSize)
    }
    printer.addBoldMode(false)
  }

  addInfo = (style) => {
    const {
      table,
      serial,
      createdAt,
      deliveryType,
      pickupAt,
      phone,
      name,
      address,
      takeaway,
      customers,
      customerNameSuffix,
      subTable,
      remark: orderRemark,
      paidAt: paidTime,
      status,
    } = this.order

    const {
      setting: { hideReceiptAddress, receiptHideCustomerPhone, quickMode },
    } = this.merchant

    const printer = this.printer
    const {
      tableNo,
      customerNo,
      serialNo,
      createAt: create,
      printAt,
      customerContact,
      customerName,
      customerAddress,
      pickupAt: pickup,
      remark,
      paidAt,
    } = style.content
    const sort = _.sortBy(_.values(style.content), 'index')
    let isNewLine = false
    _.each(sort, s => {
      switch (s.key) {
        case 'tableNo' :
          if (tableNo.enable && !quickMode) {
            this._addAlign(tableNo.align)
            printer.addBoldMode(tableNo.bold)
            if (takeaway) {
              printer.addText(this.t('app.printing.takeaway'), tableNo.fontSize)
            } else {
              printer.addText(`${this.t('app.printing.table')}: ${table}${subTable > 0 ? `(${String.fromCharCode(subTable + 64)})` : ''}`, tableNo.fontSize)
            }
            if (tableNo.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + tableNo.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(tableNo.separateFormat, tableNo.fontSize)
            }
          }
          break
        case 'customerNo':
          if (customerNo.enable && table && !_.isNil(customers) && !quickMode) {
            this._addAlign(customerNo.align)
            printer.addBoldMode(customerNo.bold)
            printer.addText(`${this.t('app.printing.customers')}: ${customers}`, customerNo.fontSize)
            if (customerNo.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + customerNo.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(customerNo.separateFormat, customerNo.fontSize)
            }
          }
          break
        case 'serialNo':
          if (serialNo.enable) {
            this._addAlign(serialNo.align)
            printer.addBoldMode(serialNo.bold)
            if (serialNo.last3Digit) {
              const orderSerial = serialNo.last3Digit.enable ? '' : _.dropRight(serial, this.orderSerialDigit).join('')
              const last3Digit = _.takeRight(serial, this.orderSerialDigit).join('')
              printer.addText(`${this.t('app.printing.receipt')}: ${orderSerial}`, serialNo.fontSize)
              printer.addBoldMode(serialNo.last3Digit.bold)
              printer.addText(last3Digit, serialNo.last3Digit.fontSize)
            } else {
              printer.addText(`${this.t('app.printing.receipt')}: ${serial}`, serialNo.fontSize)
            }
            if (serialNo.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + serialNo.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(serialNo.separateFormat, serialNo.fontSize)
            }
          }
          break
        case 'createAt':
          if (create.enable) {
            this._addAlign(create.align)
            printer.addBoldMode(create.bold)
            printer.addText(
                  `${this.t('app.constant.order.createAt')}: ${moment(createdAt).format(create.format)}`,
                  create.fontSize,
            )
            if (create.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + create.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(create.separateFormat, create.fontSize)
            }
          }
          break
        case 'printAt':
          if (printAt.enable) {
            this._addAlign(printAt.align)
            printer.addBoldMode(printAt.bold)
            printer.addText(
                    `${this.t('app.printing.qrcode.time')}: ${moment().format(printAt.format)}`,
                    printAt.fontSize,
            )
            if (printAt.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + printAt.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(printAt.separateFormat, printAt.fontSize)
            }
          }
          break
        case 'customerContact':
          if (customerContact.enable && !_.isEmpty(phone) && !receiptHideCustomerPhone) {
            this._addAlign(customerContact.align)
            printer.addBoldMode(customerContact.bold)
            printer.addText(`${this.t('app.printing.customerPhone')}: ${phone}`, customerContact.fontSize)
            if (customerContact.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + customerContact.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(customerContact.separateFormat, customerContact.fontSize)
            }
          }
          break
        case 'customerName':
          if (customerName.enable && !_.isEmpty(name)) {
            this._addAlign(customerName.align)
            printer.addBoldMode(customerName.bold)

            printer.addText(`${this.t('app.printing.name')}: ${getDisplayCustomerName(name, customerNameSuffix)}`, customerName.fontSize)
            if (customerName.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + customerName.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(customerName.separateFormat, customerName.fontSize)
            }
          }
          break
        case 'customerAddress':
          if (customerAddress.enable && !_.isEmpty(address) && !hideReceiptAddress && deliveryType === 'storeDelivery') {
            this._addAlign(customerAddress.align)
            printer.addBoldMode(customerAddress.bold)
            printer.addText(`${this.t('app.printing.address')}: ${address}`, customerAddress.fontSize)
            if (customerAddress.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + customerAddress.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(customerAddress.separateFormat, customerAddress.fontSize)
            }
          }
          break
        case 'pickupAt':
          if (pickup.enable && !_.isEmpty(pickupAt)) {
            this._addAlign(pickup.align)
            printer.addBoldMode(pickup.bold)
            printer.addText(`${this.t('app.printing.pickupAt')}: ` + moment(pickupAt).format(pickup.format), pickup.fontSize)
            if (pickup.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + pickup.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(pickup.separateFormat, pickup.fontSize)
            }
          }
          break
        case 'remark' :
          if (remark.enable && orderRemark.trim()) {
            this._addAlign(remark.align)
            printer.addBoldMode(remark.bold)
            printer.addText(`${this.t('app.constant.order.orderRemark')}: ` + orderRemark, remark.fontSize)
            if (remark.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + remark.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(remark.separateFormat, remark.fontSize)
            }
          }
          break
        case 'paidAt' :
          if (paidAt.enable && !_.isEmpty(paidTime) && status === 'paid') {
            this._addAlign(paidAt.align)
            printer.addBoldMode(paidAt.bold)
            printer.addText(`${this.t('app.printing.paidAt')}: ` + moment(paidTime).format(paidAt.format), paidAt.fontSize)
            if (paidAt.separateFormat === '\n') {
              printer.addRowFeed(this.lineSpacing + paidAt.fontSize)
              isNewLine = true
            } else {
              isNewLine = false
              printer.addText(paidAt.separateFormat, paidAt.fontSize)
            }
          }
          break
      }
    })
    if (!isNewLine) {
      printer.addRowFeed()
      printer.addAlign('left')
    }
    printer.addBoldMode(false)
  }

  addContent = (style) => {
    const printer = this.printer
    const { receiptGroupBy } = this.printerSetting
    const { receiptHideFreeItem, sortReceiptItemByCategory } = this.merchant.setting
    const originalFormat = ['item', 'quantity', 'price']
    const { format, fontSize, title, settings } = style
    const { itemWidths: w } = printer.getReceiptTextWidth(fontSize)
    const widths = {}
    _.each(format, f => {
      const index = originalFormat.findIndex(o => f === o)
      widths[f] = w[index]
    })
    const itemWidths = _.values(widths)
    const align = [
      PrintStyle.ALIGN.LEFT,
      format[0] === 'item' ? PrintStyle.ALIGN.RIGTH : PrintStyle.ALIGN.LEFT,
      format[2] === 'item' ? PrintStyle.ALIGN.LEFT : PrintStyle.ALIGN.RIGTH,
    ]

    printer.addSetTabPosition([itemWidths[0], itemWidths[0] + itemWidths[1]])
    if (title.enable) {
      if (title.separator) {
        this._addSeparator(title.separatorStyle)
      }
      const HEADER = _.map(format, f => this.t('app.printing.' + f))
      printer.addFixedRow(HEADER, itemWidths, fontSize, this.lineSpacing, align)
      if (title.separator) {
        this._addSeparator(title.separatorStyle)
      }
    }

    const filteredItems = this.summaryItems.filter(item => !(
      (receiptHideFreeItem || settings.hideFreeItem) &&
        item.price === 0 &&
        item.items.length === 0
    ))

    _.each(filteredItems, (item, index) => {
      const price = _.get(settings, 'setTotalIncludeItems', true) ? item.price : item.totalWithoutSetItems
      const hideSetName = !settings.showSetName && price === 0 && item.items?.length > 0
      const hideSetPrice = price === 0 && item.items?.length > 0 && !_.get(settings, 'setTotalIncludeItems', true)
      const itemRowText = _.map(format, f => {
        switch (f) {
          case 'item':
            return item.name
          case 'quantity':
            return String(item.quantity)
          case 'price':
            return hideSetPrice ? '' : currencyWithCommas(price)
        }
      })
      if (!hideSetName) {
        printer.addFixedRow(itemRowText, itemWidths, fontSize, this.lineSpacing, align)
      }
      if (!_.isEmpty(item.options)) {
        this._addOptionText(item.options, settings.optionFontSize, settings.showOptionPrice, format)
      }
      if (!_.isEmpty(item.remarks)) {
        this._addRemarksText(item.remarks, settings.remarkFontSize, format)
      }
      const setItemWidths = _.flatMap(itemWidths, (itemWidth, i) => {
        if (format[i] === 'item') {
          return [3, itemWidth - 3]
        }
        return itemWidth
      })
      const setItemAlign = _.flatMap(align, (a, i) => {
        if (format[i] === 'item') {
          return [a, a]
        }
        return a
      })
      if (item.items?.length > 0) {
        const followText = '+'
        const setItems = item.items.filter(i => !(!settings.showFreeSetItem && i.price === 0) && !i.cancelled)
        _.each(setItems, (setItem) => {
          if (settings.leading) {
            printer.addRowFeed()
          }
          const setRowText = _.flatMap(format, f => {
            switch (f) {
              case 'item':
                return [followText, setItem.name]
              case 'quantity':
                return String(setItem.quantity)
              case 'price':
                return settings.showSetItemsPrice ? currencyWithCommas(setItem.originalTotal) : ''
            }
          })
          printer.addFixedRow(setRowText, setItemWidths, fontSize, this.lineSpacing, setItemAlign)
          if (!_.isEmpty(setItem.options)) {
            this._addOptionText(setItem.options, settings.optionFontSize, settings.showOptionPrice, format)
          }

          if (!_.isEmpty(setItem.remarks)) {
            this._addRemarksText(setItem.remarks, settings.remarkFontSize, format)
          }
        })
      }
      if (settings.leading && index !== filteredItems?.length - 1) {
        printer.addRowFeed()
      }
    })
  }

  addSubtotal = (style) => {
    const printer = this.printer
    const {
      deliveryType,
      status,
      modifiers,
      subtotal,
      shipping,
      roundedTotal,
      calculatedDiscountTotal: discountTotal,
      batches,
      roundedAmount,
      payments,
    } = this.order

    const {
      setting: {
        showRoundingInReceipt,
      },
    } = this.merchant
    const discountIndex = _.findIndex(modifiers, (modifier) => modifier.type === 'DISCOUNT' && modifier.calculatedAmount !== 0 && modifier.deletedAt == null)
    const surchargeIndex = _.findIndex(modifiers, (modifier) => modifier.type === 'SURCHARGE' && modifier.calculatedAmount !== 0 && modifier.percent !== 0 && modifier.deletedAt == null)
    const promocodeIndex = _.findIndex(modifiers, (modifier) => modifier.type === 'PROMOCODE' && modifier.calculatedAmount !== 0 && modifier.deletedAt == null)
    const ricecoinIndex = _.findIndex(modifiers, (modifier) => modifier.type === 'RICECOIN' && modifier.calculatedAmount !== 0 && modifier.deletedAt == null)
    const allItems = batches.map(b => b.items).reduce((acc, items) => acc.concat(items), [])
    const { detail, paidAmount } = style
    const { subtotalWidths: w } = printer.getReceiptTextWidth(detail.fontSize)
    const minOrderAmount = _.get(shipping, 'discounts.0.minOrderAmount', 0)
    const shippingDiscount = Math.min(subtotal >= minOrderAmount ? _.get(shipping, 'discounts.0.amount', 0) : 0, _.get(shipping, 'customerAmount', 0))
    const calculatedDiscountTotal = -(Math.abs(discountTotal || 0) - Math.abs(shippingDiscount))
    const subtotalWidths = [...w]
    subtotalWidths[2] = subtotalWidths[1] + subtotalWidths[2] - 1
    subtotalWidths[1] = 1
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH]

    printer.addSetTabPosition([
      subtotalWidths[0],
      subtotalWidths[0] + subtotalWidths[1],
    ])
    // 小計
    const subtotalRowText = [
      this.t('app.printing.subtotal'),
      null,
      currencyWithCommas(subtotal),
    ]
    printer.addFixedRow(
      subtotalRowText,
      subtotalWidths,
      detail.fontSize,
      this.lineSpacing,
      align,
    )

    if (detail.showDiscountDetail) {
      if (
        Boolean(calculatedDiscountTotal) ||
        promocodeIndex > -1 ||
        ricecoinIndex > -1 ||
        discountIndex > -1
      ) {
        const discountTitleText = [
          this.t('app.printing.promo'),
          null,
          null,
        ]
        printer.addFixedRow(
          discountTitleText,
          subtotalWidths,
          detail.fontSize,
          this.lineSpacing,
          align,
        )
      }

      const discountWidths = [2, w[0], w[1] + w[2] - 2]
      allItems.some(item => {
        if (item.cancelled) {
          return false
        }
        const discountModifier = item.modifiers.find(m => m.type === 'DISCOUNT' && m.deletedAt == null)
        if (discountModifier && discountModifier.calculatedAmount !== 0) {
          const discountText = discountModifier.custom ? discountModifier.customName : this.t('app.printing.itemDiscount')
          const itemRowText = [
            null,
            `${discountText}: ${item.name}`,
            currencyWithCommas(discountModifier.calculatedAmount),
          ]
          for (let i = 0; i < _.get(item, 'quantity', 1); i++) {
            printer.addFixedRow(itemRowText, discountWidths, detail.fontSize, this.lineSpacing, align)
          }
        }
        if (item.isSet) {
          item.setItems.some(setItem => {
            if (setItem.cancelled) {
              return false
            }
            const setDiscountModifier = setItem.modifiers.find(m => m.type === 'DISCOUNT' && m.deletedAt == null)
            if (setDiscountModifier && setDiscountModifier.calculatedAmount !== 0) {
              const discountText = setDiscountModifier.custom ? setDiscountModifier.customName : this.t('app.printing.itemDiscount')
              const itemRowText = [
                null,
                `${discountText}: ${setItem.name}`,
                currencyWithCommas(discountModifier.calculatedAmount),
              ]
              for (let i = 0; i < _.get(setItem, 'quantity', 1); i++) {
                printer.addFixedRow(itemRowText, discountWidths, detail.fontSize, this.lineSpacing, align)
              }
            }
          })
        }
      })

      if (promocodeIndex >= 0) {
        const code = modifiers[promocodeIndex]?.code
        const promoName = modifiers[promocodeIndex]?.name
        const promocodeRowText = [
          null,
          promoName || code,
          currencyWithCommas(modifiers[promocodeIndex]?.calculatedAmount),
        ]
        printer.addFixedRow(
          promocodeRowText,
          discountWidths,
          detail.fontSize,
          this.lineSpacing,
          align,
        )
      }
      if (ricecoinIndex >= 0) {
        const ricecoinRowText = [
          null,
          'RICECOIN',
          currencyWithCommas(modifiers[ricecoinIndex]?.calculatedAmount),
        ]
        printer.addFixedRow(
          ricecoinRowText,
          discountWidths,
          detail.fontSize,
          this.lineSpacing,
          align,
        )
      }
      if (discountIndex >= 0) {
        // 全單折扣
        const discountName = modifiers[discountIndex]?.custom ? modifiers[discountIndex]?.customName : this.t('app.printing.orderDiscount')
        const discountRowText = [
          null,
          `${discountName}${modifiers[discountIndex]?.percent > 0 ? `(${modifiers[discountIndex]?.percent}%)` : ''}`,
          currencyWithCommas(modifiers[discountIndex]?.calculatedAmount),
        ]
        printer.addFixedRow(
          discountRowText,
          discountWidths,
          detail.fontSize,
          this.lineSpacing,
          align,
        )
      }
    }

    if (calculatedDiscountTotal !== 0) {
      const discountRowText = [
        this.t('app.printing.discount'),
        null,
        currencyWithCommas(calculatedDiscountTotal),
      ]
      printer.addFixedRow(
        discountRowText,
        subtotalWidths,
        detail.fontSize,
        this.lineSpacing,
        align,
      )
    }

    if (surchargeIndex >= 0) {
      const surchargePercent = `(${modifiers[surchargeIndex]?.percent}%)`
      const serviceChargeRowText = [
          `${this.t('app.printing.surcharge')}${surchargePercent}`,
          null,
          currencyWithCommas(modifiers[surchargeIndex]?.calculatedAmount),
      ]
      printer.addFixedRow(
        serviceChargeRowText,
        subtotalWidths,
        detail.fontSize,
        this.lineSpacing,
        align,
      )
    }

    if (showRoundingInReceipt) {
      if (Math.abs(roundedAmount) >= 0.01) {
        const roundingRowText = [
          `${this.t('app.printing.roundingDifference')}`,
          null,
          currencyWithCommas(roundedAmount),
        ]
        printer.addFixedRow(
          roundingRowText,
          subtotalWidths,
          detail.fontSize,
          this.lineSpacing,
          align,
        )
      }
    }
    // shipping fee
    if (deliveryType === 'storeDelivery') {
      const shippingDiscountText = Math.abs(shippingDiscount) > 0 ? `($${shipping.customerAmount} - $${shippingDiscount})` : ''
      const shippingFeeRowText = [
        this.t('app.printing.shippingFee') + shippingDiscountText,
        null,
        currencyWithCommas(shipping.customerAmount - shippingDiscount),
      ]
      printer.addFixedRow(
        shippingFeeRowText,
        subtotalWidths,
        detail.fontSize,
        this.lineSpacing,
        align,
      )
    }
    // price total 總計
    const { itemWidths: totalpaidWidths } = printer.getReceiptTextWidth(paidAmount.fontSize)
    totalpaidWidths[2] = _.sum(totalpaidWidths) - 9
    totalpaidWidths[1] = 0
    totalpaidWidths[0] = 9

    printer.addBoldMode()
    const unpaid = _.isEmpty(payments) || status !== 'paid'
    printer.addRowFeed()
    const totalRowText = [
      unpaid ? this.t('app.printing.total') : this.t('app.printing.paidAmount'),
      null,
      currencyWithCommas(roundedTotal),
    ]
    printer.addFixedRow(totalRowText, totalpaidWidths, paidAmount.fontSize, this.lineSpacing, align)
    printer.addBoldMode(false)
  }

  addPayment = (style) => {
    const printer = this.printer
    const { payments } = this.order
    const { settings: { fontSize, leading } } = style
    const { subtotalWidths: widths } = printer.getReceiptTextWidth(fontSize)
    const subtotalWidths = [widths[0], 1, widths[2] + widths[1] - 1]
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.RIGTH]
    printer.addSetTabPosition([
      subtotalWidths[0],
      subtotalWidths[0] + subtotalWidths[1],
    ])
    // payment method 付款方法
    printer.addRowFeed(this.lineSpacing)
    printer.addText(this.t('app.printing.method'), fontSize)
    printer.addRowFeed(this.lineSpacing)
    printer.addRowFeed(this.lineSpacing)
    const filteredPayments = payments.filter(p => p.status === 'paid')
    _.each(filteredPayments, (payment, index) => {
      const _paymentMethod = getPaymentMethod(payment.paymentMethod)
      const rowText = [
        this.order.deliveryType === 'table' && payment.customerId ? `DimPay (${this.t(_paymentMethod.name)})` : this.t(_paymentMethod.name), // 由客人自助付款時顯示 DimPay 字樣
        null,
        currencyWithCommas(payment.paidAmount),
      ]
      printer.addFixedRow(rowText, subtotalWidths, fontSize, this.lineSpacing, align)
      // eft八達通: 詳情請參閱八達通收據
      if (payment.gateway === ecrGateway.EFT_PAY && payment.paymentMethod === constPaymentMethods.OCTOPUS) {
        printer.addFixedRow(
          [this.t('app.printing.eftOctopusText'), null, null],
          subtotalWidths,
          fontSize,
          1,
          align,
        )
      }
      // 找續
      if (payment.change > 0) {
        const rowText = [
          this.t('app.printing.change'),
          null,
          currencyWithCommas(payment.change),
        ]
        printer.addFixedRow(rowText, subtotalWidths, fontSize, this.lineSpacing, align)
      }
      // 小費
      if (payment.paymentTips) {
        const rowText = [
          this.t('app.printing.tips'),
          null,
          currencyWithCommas(payment.paymentTips),
        ]
        printer.addFixedRow(rowText, subtotalWidths, fontSize, this.lineSpacing, align)
      }

      if (leading) {
        printer.addRowFeed(this.lineSpacing)
      }
    })
  }

  addFooter = (style) => {
    const printer = this.printer
    const {
      takeaway,
      needTableware,
      status,
    } = this.order
    const {
      setting: {
        quickMode,
        footerCustomText,
      },
    } = this.merchant
    const { tableware, paymentStatus } = style
    const defaultSettings = getState().printer?.printerSetting?.defaultSettings

    printer.addRowFeed(this.lineSpacing)
    printer.addAlign('center')
    if (takeaway && (!quickMode || this.order.from !== 'MERCHANT')) {
      if (tableware) {
        printer.addText(
          needTableware ? this.t('app.printing.tableware') : `${this.t('app.printing.no') + this.t('app.printing.tableware')}`,
          PrinterFontSize.LARGE,
        )
        printer.addRowFeed(this.lineSpacing)
      }
      if (paymentStatus) {
        if (status !== 'paid') {
          printer.addText(this.t('app.printing.unpaid'), PrinterFontSize.LARGE)
        } else {
          printer.addText(this.t('app.printing.paid'), PrinterFontSize.LARGE)
        }
        printer.addRowFeed(this.lineSpacing)
      }
      printer.addRowFeed(this.lineSpacing)
    }

    if (footerCustomText) {
      printer.addAlign('center')
      printer.addText(footerCustomText, defaultSettings?.customFooterStyleText ?? PrinterFontSize.MEDIUM)
      printer.addRowFeed(this.lineSpacing)
      printer.addAlign('left')
    }
  }

  _addOptionText (options, font, showPrice, format) {
    const printer = this.printer
    printer.addAlign('left')
    const itemIndex = format.findIndex(f => f === 'item')
    const { itemWidths: w } = printer.getReceiptTextWidth(font)
    const originalFormat = ['item', 'quantity', 'price']
    const widths = {}
    _.each(format, f => {
      const index = originalFormat.findIndex(o => f === o)
      widths[f] = w[index]
    })
    const itemWidths = _.values(widths).map((iw, i) => {
      if (i < itemIndex) {
        return iw
      } else if (i === itemIndex) {
        return _.values(widths).slice(itemIndex).reduce((a, b) => a + b, 0)
      } else {
        return 0
      }
    })
    const optionText = options
      .map((opt) => {
        let text = opt.name
        if (opt.price > 0 && showPrice) {
          text += `(${currencyWithCommas(opt.price)})`
        }
        if (opt.quantity > 1) {
          text += `(${opt.quantity})`
        }
        return text
      })
      .join(', ')
    const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT]
    const rowText = ['', '', '']
    rowText[itemIndex] = `  ** ${optionText}`
    printer.addFixedRow(
      rowText,
      itemWidths,
      font,
      1,
      align,
    )
  }

  _addRemarksText (remarks, font, format) {
    const printer = this.printer
    const remarksText = this.t('app.printing.remark')
    const itemIndex = format.findIndex(f => f === 'item')
    const { itemWidths: w } = printer.getReceiptTextWidth(font)
    const originalFormat = ['item', 'quantity', 'price']
    const widths = {}
    _.each(format, f => {
      const index = originalFormat.findIndex(o => f === o)
      widths[f] = w[index]
    })
    const itemWidths = _.values(widths).map((iw, i) => {
      if (i < itemIndex) {
        return iw
      } else if (i === itemIndex) {
        return _.values(widths).slice(itemIndex).reduce((a, b) => a + b, 0)
      } else {
        return 0
      }
    })

    printer.addAlign('left')
    _.each(remarks, (remark) => {
      if (!_.isEmpty(remark)) {
        const align = [PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT, PrintStyle.ALIGN.LEFT]
        const rowText = ['', '', '']
        rowText[itemIndex] = `${remarksText}: ${remark}`
        printer.addFixedRow(
          rowText,
          itemWidths,
          font,
          1,
          align,
        )
      }
    })
  }

  _addAlign (align) {
    const printer = this.printer
    switch (align) {
      case 'center':
        printer.addAlign('center')
        break
      case 'left':
        printer.addAlign('left')
        break
      case 'right':
        printer.addAlign('right')
        break
    }
  }

  _addSeparator (style) {
    const printer = this.printer
    switch (style) {
      case 'dotted':
        printer.addUnderline('-')
        break
      case 'doubleDotted':
        printer.addUnderline('=')
        break
      case 'straight':
        printer.addStraightLine()
        break
    }
  }

  /**
   * 根據列印時的情況產生 hash，可避免重複列印
   * @returns {string}
   */
  _generatePrintHash () {
    // 收據上confirmed的batches中沒有被取消的items的數量
    const confirmedBatches = _.filter(this.order?.batches, batch => batch.status === 'confirmed')
    const items = _.flatMap(confirmedBatches, batch => batch.items)
    const successItems = _.filter(items, item => !item.cancelled)
    const itemsCount = _.sumBy(successItems, 'quantity')
    // 原本使用整個 order 做 hash，但 batch item 的 property printed 和 printError 有機會是會變的
    // 為了避免重複印單，將原本的 order hash 改為使用不變的 order.id
    return hash({
      orderId: this.order.id,
      itemsCount: itemsCount,
      total: this.order.roundedTotal,
      status: this.order.status,
      printerSetting: this.printerSetting, // 設定不同時重印
      printReason: this.printReason, // 加入 printReason 避免印完 submit 後的訂單馬上結帳，因 orderId 及 printerSetting 都一樣而被跳過列印
      reprintId: this.reprint ? shortid.generate() : undefined, // 手動重印的話使用產生的 id，避免 id 重複而跳過烈印
    })
  }

  addQRCode (style) {
    const printer = this.printer
    const {
      enable,
      text,
      position,
      bold,
      fontSize,
      align,
    } = style.customizedText

    if (!enable) {
      printer.addQRCode(this.shortUrl)
    } else {
      if (bold) {
        printer.addBoldMode()
      }

      if (position === 'top') {
        printer.addAlign(align)
        printer.addText(text, fontSize)
        printer.addRowFeed(this.lineSpacing)
        printer.addQRCode(this.shortUrl)
      } else {
        printer.addQRCode(this.shortUrl)
        printer.addRowFeed(this.lineSpacing)
        printer.addAlign(align)
        printer.addText(text, fontSize)
      }

      printer.addBoldMode(false)
    }
  }
}
