import { AppState, Platform } from 'react-native'
import { v4 as uuid } from 'uuid'
import Constants from 'expo-constants'
import ReconnectingWebSocket from 'react-native-reconnecting-websocket'
import _ from 'lodash'
import colors from '@/theme/colors'
import delay from '@/libs/delay'
import moment from 'moment'
import produce from 'immer'
import shortid from 'shortid'

import { PrintReason } from '@/constants/printing'
import { actions } from '@/redux'
import { dimorderApi, dimorderLib } from '@/libs/api/dimorder'
import { ecrGateway, loadingKey, paymentMethods, webSocketStatusCode } from '@/constants'
import { getHistoryOrder, preserveValidOrderFlags } from '@/libs/orderHistory'
import BBMSL from '@/libs/EcrGateway/BBMSL'
import EftPay from '@/libs/EcrGateway/EftPay'
import GlobalPayment from '@/libs/EcrGateway/GlobalPayment'
import i18n from '@/i18n'
import logger, { convertToShortOrdersLog, convertToSimpleOrder, logId } from '@/libs/logger'
import orderEvent from '@/libs/orderEvent'
import paymentConfigs from '@/configs/paymentConfigs'

import { initialFilter } from './reducer'
import ActionTypes from './ActionTypes'
import debounceAction from '../debounceAction'
import sampleOrder from '@/constants/sampleOrderforGenerate'

import AsyncStorage from '@react-native-async-storage/async-storage'
import queue from 'async/queue'
import store from '@/redux/store'
import * as OrderLocalDatabase from '@/libs/orderLocalDatabase'

// eslint-disable-next-line no-unused-vars
import { IAppBatchItem, IAppOrder, IAppOrderBatch, IAppPayment } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/** @type {() => IRootState} */
const getState = store.getState

const WsEvent = {
  ORDER_CONFIRMED: 'domain.OrderConfirmedEvent',
  ORDER_SUBMITTED: 'domain.OrderSubmittedEvent',
  ORDER_PAID: 'domain.OrderPaidEvent',
  ORDER_CANCELLED: 'domain.OrderCancelledEvent',
  ORDER_CREATED: 'domain.OrderCreatedEvent',
  ORDER_SURCHARGE_UPDATED: 'domain.OrderSurchargeUpdateEvent',
  ORDER_ITEM_TAG_UPDATED: 'domain.OrderItemTagUpdateEvent',
  ORDER_ITEM_EXCLUDED_ORDER_SURCHARGE_UPDATED: 'domain.OrderItemExcludedOrderSurchargeUpdateEvent',
  ORDER_ITEM_MODIFIER_UPDATED: 'domain.OrderItemModifierUpdateEvent',
  ORDER_MODIFIER_UPDATED: 'domain.OrderUpdateModifiersEvent',
  ORDER_BATCH_CANCELLED: 'domain.OrderBatchCancelledEvent',
  ORDER_DELETE_PAYMENT: 'domain.OrderDeletePaymentEvent',
  ORDER_READY: 'domain.OrderReadyEvent',
  ORDER_ITEM_CANCELLED: 'domain.OrderItemCancelledEvent',
  ORDER_PAYMENT_PAID: 'domain.OrderPaymentPaidEvent',
  ORDER_SERVICE_REQUEST: 'domain.OrderServiceRequestedEvent',
  ORDER_UPDATE_PAYING: 'domain.OrderUpdatePayingEvent',
  ORDER_PUT_EVENT: 'domain.OrderPutEvent',
}

/** @type {WebSocket} */
let ws = null
let wsRetryTimeouts = [] // Arr 儲存所有 timeout
const wsEventId = []
/** @type {AppStateStatus} */
let appState = 'active'
let inactiveMoment = null
let appStateListen = null
let checkUpdateTime
let updateTimeout
let updateOrderInterval

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    logger.log('[orderHistory/init]')
    await dispatch(startOrdersUpdater())
    dispatch(initDailyOrderReminder())
  }
}

/**
 * 嘗試從 AsyncStorage 還原 orderHistory.orders
 * ! orderHistory 存的是整個 redux state object，但最後只有拿裡面的 orders 來用
 * ! 因此 action 的命名是 storeState 和 restoreOrders
 * @returns {ThunkFunction}
 */
export function restoreOrders () {
  return async (dispatch, getState) => {
    try {
      const unsyncOrderIdsJson = await AsyncStorage.getItem('unsyncOrderIds')
      let restoreUnsyncOrderIds = []
      if (unsyncOrderIdsJson) { restoreUnsyncOrderIds = JSON.parse(unsyncOrderIdsJson) }

      const localOrders = await OrderLocalDatabase.getOrdersForRestore(restoreUnsyncOrderIds)
      if (!localOrders) {
        return
      }
      const length = localOrders.length
      dispatch({
        type: ActionTypes.RESTORE_ORDERS,
        payload: { orders: localOrders },
      })

      logger.log(`[localOrders/restoreOrders] Done, restore localOrders orders length: ${length}`)
    } catch (error) {
      logger.error(`[localOrders/restoreOrders] error: ${error?.message || error.toString()}`, { error })
    }
  }
}

/**
 * @deprecated 沒有使用
 * 將 orderHistory 存入 AsyncStorage
 * ! orderHistory 存的是整個 redux state object，但最後只有拿裡面的 orders 來用
 * ! 因此 action 的命名是 storeState 和 restoreOrders
 * @returns {ThunkFunction}
 */
export function storeState () {
  return async (dispatch, getState) => {
    const disableOrderHistoryStorage = getState().app.settings.debugSettings.disableOrderHistoryStorage
    if (disableOrderHistoryStorage) return

    try {
      const t0 = performance.now()
      const orderHistory = getState().orderHistory
      const length = orderHistory.orders.length
      // 暫時 Comment 來測試 GCP Logger 用量倍增問題
      // const ordersLog = orderHistory.orders.map((order) => convertToSimpleOrder(order, true))
      const ordersLog = convertToShortOrdersLog(orderHistory.orders)
      const storeAt = Date.now() // 用作讓查看 "Done" 時候，可以認回 "Start" 的 Logger, 減少重覆資料寫入到 Logger
      logger.log(`[orderHistory/storeState] Start, storeAt: ${storeAt}, orderHistory orders length: ${length}`, { ...ordersLog })
      const orderHistoryJson = JSON.stringify(orderHistory)
      const t1 = performance.now()

      const ms = (t1 - t0).toFixed(1)
      logger.log(`[orderHistory/storeState] Done , storeAt: ${storeAt}, Stored json string, orderHistory orders length:${length}, ${ms}ms, jsonStr_length:${orderHistoryJson.length}`, {})
    } catch (error) {
      logger.error(`[orderHistory/storeState] error: ${error?.message ?? error.toString()}`, { error })
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function startOrdersUpdater () {
  return async (dispatch, getState) => {
    dispatch(startPollingOrdersUpdater())

    logger.log('[orderHistory/startOrdersUpdater]')

    // 開機時trigger 的websocket
    await dispatch(startWebSocketOrdersUpdater(true))

    if (appStateListen) {
      // 移除原本的 app state listener
      AppState.removeEventListener('change', appStateListen)
    }

    // 建立 app state listener
    appStateListen = (nextState) => {
      if (appState === 'active' && nextState !== 'active') {
        // 變成 inacitve
        if (!inactiveMoment) {
          // 只記錄最早變成 inacitve 的時間
          inactiveMoment = moment()
        }
      }
      if (appState !== 'active' && nextState === 'active') {
        // 變成 active，計算 inactive 過了多久
        const inactiveDuration = moment.duration(moment().diff(inactiveMoment))
        const inactiveSeconds = inactiveDuration.asSeconds()
        if (inactiveSeconds > 30) {
          // inactive 超過 30 秒，更新訂單
          dispatch(throttledGetOrders(`appState change, appState ${appState} -> ${nextState}`))
        }
        // 通知後端這個裝置還活著
        dispatch(actions.app.updateTTL())
        // 移除 inactiveTimestamp
        inactiveMoment = null
      }
      appState = nextState
    }
    AppState.addEventListener('change', appStateListen)
  }
}

export function initDailyOrderReminder () {
  return (dispatch, getState) => {
    const checkOrderAt = getState().app.settings.checkOrderAt // 上一次判斷自取/外送訂單提示時間
    // 先清除之前的 listener 和 updateTimeout
    dispatch(removeUpdateTrigger())

    // 檢查時間為下次 6AM
    const today6AM = moment().set('hour', 6).startOf('hours')
    if (moment().isAfter(today6AM)) {
      if (today6AM.isAfter(checkOrderAt)) {
        // 今天的 6AM 已經過了，但還沒有判斷自取/外送訂單提示
        logger.log('[OrderHistory] APP Start 今天還沒判斷，判斷自取/外送訂單提示')
        dispatch(remindTodayReservedOrders())
      }
      // 今天的 6AM 已經過了，加一天
      checkUpdateTime = today6AM.add(1, 'day')
    } else {
      // 今天還沒有過 6AM
      checkUpdateTime = today6AM
    }

    const checkUpdateTimeDuration = moment.duration(checkUpdateTime - moment())
    updateTimeout = setTimeout(() => {
      logger.log('[OrderHistory] 每日判斷自取/外送訂單提示')
      dispatch(remindTodayReservedOrders())
      // 重設 timeout
      dispatch(initDailyOrderReminder())
    }, checkUpdateTimeDuration.asMilliseconds())

    // 在 background 時， timeout 不會被執行，resume 時再判斷是否過了檢查時間
    appStateListen = (state) => {
      if (state === 'active') {
        // 先停止原本的 timeout
        clearTimeout(updateTimeout)

        // 現在時間已超過檢查更新時間，且檢查更新時間已超過上次檢查更新
        if (moment().isAfter(checkUpdateTime) && moment(checkUpdateTime).isAfter(checkOrderAt)) {
          logger.log('[OrderHistory] APP Resume 今天還沒判斷，判斷自取/外送訂單提示')
          dispatch(remindTodayReservedOrders())
        }
        // 重設 timeout
        dispatch(initDailyOrderReminder())
      }
    }
    // 已判斷完，記錄現時時間到 async storage
    dispatch(actions.app.updateSetting(['checkOrderAt'], new Date()))
    AppState.addEventListener('change', appStateListen)
  }
}

/**
 * @returns {ThunkFunction}
 */
export function removeUpdateTrigger () {
  return () => {
    if (appStateListen) {
      AppState.removeEventListener('change', appStateListen)
    }
    clearTimeout(updateTimeout)
  }
}

export function remindTodayReservedOrders () {
  return (dispatch, getState) => {
    logger.log('[OrderHistory] remindTodayReservedOrders called')
    const orders = getState().orderHistory.orders
    const todayStart = moment().startOf('day').add(6, 'hours')
    const todayEnd = moment().endOf('day')
    const containReservedOrders = orders.some(order => {
      const isTakeawayOrStoreDelivery = order.deliveryType === 'takeaway' || order.deliveryType === 'storeDelivery' // 自取或外送訂單
      const isPaid = order.status === 'paid' // 已付款
      const isConfirmedOrReady = order?.takeawayStatus === 'confirmed' || order.takeawayStatus === 'ready' // 備餐中或待取餐
      const isPickupToday = moment(order?.pickupAt).isBetween(todayStart, todayEnd) // 今日需要出餐
      const isCreatedBefore = moment(order.createdAt) < moment().startOf('day') // 在今日以前
      return (isTakeawayOrStoreDelivery && isPaid && isConfirmedOrReady && isPickupToday && isCreatedBefore)
    })
    if (containReservedOrders) {
      window.applicatiionHistory.push('/orderHistory') // 前往 /orderHistory
      // 設定 filter
      dispatch(applyFilter({
        ...initialFilter,
        deliveryType: ['takeaway', 'storeDelivery'],
        displayStatusKey: ['preparing_meal', 'waiting_pick_up'],
      }))
      // 顯示 alert
      dispatch(actions.app.showAlert({
        title: i18n.t('app.component.remindOrdersDialog.title'),
        message: i18n.t('app.component.remindOrdersDialog.message'),
      }))
    }
  }
}

/**
 * 複製不同類型的訂單，方便模擬餐廳多訂單的環境用作testing
 * @param {string} quantity
 * @param {object} orderType
 * @param {boolean} isGenfromMerchant
 * @param {boolean} isSaveAtServer
 * @returns
 */
export function generateOrder (quantity, orderType = {}, isGenfromMerchant = true, isSaveAtServer = false) {
  return (dispatch, getState) => {
    const orders = getState().orderHistory.orders
    const merchantId = getState().merchant.data.id
    const table = getState().table.tables
    const dummyOrder = isGenfromMerchant
      ? _.find(orders, order => {
        return !order?.isSample && // 不能generate sample order 到server
          (orderType.status.type !== 'paid' ? order.status !== 'paid' : order.status === 'paid') &&
          order.deliveryType === orderType.deliveryType.type
      })
      : sampleOrder

    if (!dummyOrder) { return dispatch(actions.app.showAlert({ message: `請新增 ${orderType.status.text}的${orderType.deliveryType.text} 作複製使用` })) }

    const dummyOrders = []
    for (let i = 0; i < quantity; i++) {
      const tempOrder = {
        ...dummyOrder,
        id: `test-${moment().format('YYYY-MM-DD-HH-mm-ss_SSS')}-${Math.floor(Math.random() * (999 - 100 + 1) + 100)}-${parseInt('0') + i}`,
        merchantId: merchantId,
        serial: `048-${parseInt('1612') + i}`,
        orderSerial: `048-${parseInt('1612') + i}`,
        table: `${(i % table.length) + 1}`,
        batches: _.set(dummyOrder.batches, '[0].table', ((i % table.length) + 1).toString()),
      }
      dummyOrders.push(tempOrder)
    }

    if (isSaveAtServer) {
      dispatch(updateOrders(dummyOrders, { syncOrder: true, overwrite: false }))
    } else {
      const newOrders = dummyOrders.concat(orders)
      dispatch({
        type: ActionTypes.UPDATE_ORDERS,
        payload: { orders: newOrders },
      })
    }
  }
}
/**
 * @returns {ThunkFunction}
 */
export function stopOrdersUpdater () {
  return async (dispatch, getState) => {
    dispatch(stopPollingOrdersUpdater())
    dispatch(stopWebSocketOrdersUpdater())

    if (appStateListen) {
      // 移除原本的 app state listener
      AppState.removeEventListener('change', appStateListen)
      appStateListen = null
    }
  }
}

export function startPollingOrdersUpdater () {
  return (dispatch, getState) => {
    if (updateOrderInterval) {
      logger.log('[startPollingOrdersUpdater]')
      dispatch(stopPollingOrdersUpdater())
    }
    updateOrderInterval = setInterval(() => {
      dispatch(actions.orderHistory.getOrdersInterval())
    }, 30000)
  }
}

/**
 * @returns {ThunkFunction}
 */
export function stopPollingOrdersUpdater () {
  return (dispatch, getState) => {
    if (updateOrderInterval) {
      logger.log('[stopPollingOrdersUpdater]')
      clearInterval(updateOrderInterval)
      updateOrderInterval = null
    }
  }
}

/**
 * 啟用WebSocket 的更新
 * @param {boolean} isInit 是否開機時trigger
 * @returns
 */
export function startWebSocketOrdersUpdater (isInit = false) {
  return async (dispatch, getState) => {
    logger.log('[WebSocket] startWebSocketOrdersUpdater()')
    const deviceId = Constants.installationId
    const wssUrl = getState().config.wssUrl
    const token = getState().auth.userLogin.token

    const getOrdersMessage = isInit ? 'startWebSocketOrdersInit' : 'startWebSocketOrdersUpdater'
    dispatch(throttledGetOrders(getOrdersMessage))

    if (!token) {
      logger.log('[WebSocket] skip by no userLogin token')
      return
    }
    const webSocketUrl = wssUrl + 'm/subscribe/orderchanged?device_id=' + deviceId + '&token=' + token + '&noping=true'

    if (ws?.readyState === webSocketStatusCode.OPEN) return

    // Ensurer - 保證清除所有wsRetryTimeout，最終只剩下1條socket
    if (wsRetryTimeouts.length) {
      wsRetryTimeouts.forEach(tId => clearTimeout(tId))
      wsRetryTimeouts = []
    }

    const heartCheck = {
      timeout: 10000, // default 10s
      reset: function () {
        clearTimeout(this.timeoutObj)
        return this
      },
      start: function () {
        if (ws && ws.readyState === ws.OPEN) {
          try {
            ws.send(JSON.stringify({ action: 'ping' }))
          } catch (error) {
            logger.warn(`[WebSocket] ping error: ${error?.message || error.toString()}`, { error })
          }
        }
        const self = this
        this.timeoutObj = setTimeout(() => {
          self.start()
        }, this.timeout)
      },
    }
    // 如果已經有 ws，先 close 避免重複連線
    dispatch(stopWebSocketOrdersUpdater('startWebSocketOrdersUpdater'))
    if (Platform.OS === 'web') {
      if (ws && ws.readyState === ws.CONNECTING) {
        // 如 ws 已在 connecting 中 不用再 create new connection
      } else {
        ws = new WebSocket(webSocketUrl)
      }
    } else {
      // 防止重複開一條新的 Socket，因為使用了auto ReconnectingWebSocket
      if (!ws) ws = new ReconnectingWebSocket(webSocketUrl)
    }

    ws.onopen = () => {
      logger.log(`[WebSocket] Connected to server ${webSocketUrl}`)
      dispatch(actions.app.updateWebSocketStatus(webSocketStatusCode.OPEN))

      heartCheck.reset().start()
      dispatch(actions.app.updateWebSocketReady(true))
    }

    ws.onmessage = (event) => {
      if (event.data === 'pong') return

      const msg = JSON.parse(event.data)

      if (ws && ws.readyState === ws.OPEN) {
        // reply msg to server
        try {
          ws.send(JSON.stringify({ id: msg.id }))
        } catch (error) {
          logger.error(`[WebSocket] reply msg id: ${msg.id}, error: ${error?.message || error.toString()}`, { error, msgId: msg.id })
        }
      }

      if (!msg?.orderHistory) return

      // format order
      const order = dimorderLib.apiOrderToAppOrder(msg.orderHistory)

      const pendingSyncOrderIds = getState().unsyncOrder.pendingOrderIds
      if (pendingSyncOrderIds.includes(order.id)) {
        // 這張訂單有未上傳到後端的資料，未避免被覆蓋，這個 event 不做處理
        logger.log(`[WebSocket] order ${order.serial} pending upload, skip this event`, { eventId: msg.id, order: convertToSimpleOrder(order) })
        return
      }

      const syncingOrderId = getState().unsyncOrder.syncingOrderId
      if (syncingOrderId === order.id) {
        // 這張訂單有未上傳到後端的資料，未避免被覆蓋，這個 event 不做處理
        logger.log(`[WebSocket] order ${order.serial} uploading, skip this event`, { eventId: msg.id, order: convertToSimpleOrder(order) })
        return
      }

      // get current settings
      if (wsEventId.includes(msg.id)) {
        // aviod duplicated event id
        logger.log(`[WebSocket] order ${order.serial} duplicated id, skip this event`, { eventId: msg.id })
        return
      }

      logger.log(`[WebSocket] Received event ${msg.event} order ${order.serial}`, { event: msg.event, order })
      if (wsEventId.length >= 100) {
        wsEventId.pop()
      }

      wsEventId.unshift(msg.id)

      // 記錄現時在 checkoutOrder 的 status 和 roundedTotal
      const checkoutOrderId = getState().orderCheckout.orderId
      const { status: prevStatus, roundedTotal: prevRoundedTotal } = getHistoryOrder(checkoutOrderId) || {}

      // 更新到 orderHistory
      dispatch(actions.orderHistory.updateOrder(order, { selectOrder: false, syncOrder: false }))

      switch (msg.event) {
        // 不用處理的 event
        case WsEvent.ORDER_CREATED: {
          // ? 不明流程，先保留
          // 應該不用處理，但這裡有個舊的 code 是把堂食訂單的 valid 都改成 true 在寫到 store 卻沒有再上傳給後端
          // MR 開的堂食單 valid 原本就是 valid: true
          // CA 用 static QRCode 開桌才會是 valid: false
          // 可能是想讓 static QRCode 開桌的訂單也出現，但不知道為什麼沒有更新到後端
          // 這樣 get order 不會拿到這張訂單
          if (order.deliveryType === 'table' && !order.valid) {
            order.valid = true
            dispatch(updateOrder(order, { selectOrder: false, syncOrder: false }))
          }
          break
        }
        // 不用處理的 event
        case WsEvent.ORDER_READY:
          break
        case WsEvent.ORDER_MODIFIER_UPDATED:
        case WsEvent.ORDER_DELETE_PAYMENT:
        case WsEvent.ORDER_ITEM_MODIFIER_UPDATED:
        case WsEvent.ORDER_ITEM_EXCLUDED_ORDER_SURCHARGE_UPDATED:
        case WsEvent.ORDER_ITEM_CANCELLED: {
          dispatch(alertOrderChange(order, prevStatus, prevRoundedTotal))
          break
        }
        // 收到 submit batch event，需要檢查是否需要自動確認，並提示訂單更新
        case WsEvent.ORDER_SUBMITTED: {
          dispatch(autoConfirmOrders([order]))
          dispatch(alertOrderChange(order, prevStatus, prevRoundedTotal))
          break
        }
        // 收到有 batch 被確認，檢查是否需要從未確認提示中移除
        case WsEvent.ORDER_CONFIRMED: {
          dispatch(removeUnconfirmedOrder(order.serial))
          break
        }
        // batch 被取消，檢查是否需要從未確認提示中移除，會更動到價格因此檢查是否需要提示訂單更新
        case WsEvent.ORDER_BATCH_CANCELLED: {
          dispatch(removeUnconfirmedOrder(order.serial))
          dispatch(alertOrderChange(order, prevStatus, prevRoundedTotal))
          break
        }
        // 叫侍應 flag 被更新，更新叫侍應提示
        case WsEvent.ORDER_SERVICE_REQUEST: {
          dispatch(updateRequestWaiterOrder(order))
          break
        }
        // 請求付款 flag 被更新，更新請求付款提示
        case WsEvent.ORDER_UPDATE_PAYING: {
          dispatch(updateRequestPayingOrder(order))
          break
        }
        // `ORDER_PAYMENT_PAID` 是第三方付款通知已結帳：後端收到第三方付款成功付款的 webhook，且支付金額 >= 訂單金額，通知 MR status: paid
        case WsEvent.ORDER_PAYMENT_PAID: {
          dispatch(autoConfirmOrders([order]))
          dispatch(updateRequestPayingOrder(order))
          dispatch(updateRequestWaiterOrder(order))
          dispatch(removeUnconfirmedOrder(order.serial))
          dispatch(alertOrderChange(order, prevStatus, prevRoundedTotal))

          // 堂食後付款 DimPay 結帳後自動打印收據
          // 因為有給 printReason 和 reprint: false，所以重複 dispatch printOrderReceipt 也不會重複印
          const isMaster = getState().app.settings.isMaster
          const isPayFirst = getState().merchant.data.setting?.payFirst
          const isTablePayLater = order.deliveryType === 'table' && !isPayFirst // 堂食後付款
          if (
            isMaster &&
            isTablePayLater &&
            order.status === 'paid' &&
            order.payments.some(payment => payment?.status === 'paid' && payment?.customerId && payment?.paidAmount > 0)
          ) {
            dispatch(actions.printer.printOrderReceipt({
              order: order,
              sync: false,
              printReason: PrintReason.ORDER_RECEIPT.WS_PAYMENT_PAID,
              printConfig: { reprint: false },
            }))
          }
          break
        }
        // `ORDER_PAID` 是開始結帳
        // 若使用同步的付款方式會馬上是 status: paid，例如現金或 stripe，但目前都未使用
        // 若使用第三方付款需要等後端收到成功付款通知的 webhook 之後由 ORDER_PAYMENT_PAID 通知才會變成 status: paid
        case WsEvent.ORDER_PAID:
        case WsEvent.ORDER_PUT_EVENT: // 訂單被更新，可能有各種情況，包含變成已結帳，因此也要把全部可能的處理做一遍
          dispatch(autoConfirmOrders([order]))
          dispatch(updateRequestPayingOrder(order))
          dispatch(updateRequestWaiterOrder(order))
          dispatch(removeUnconfirmedOrder(order.serial))
          dispatch(alertOrderChange(order, prevStatus, prevRoundedTotal))
          break
        case WsEvent.ORDER_CANCELLED: // 訂單被取消，關閉各種通知並提示訂單更新
          dispatch(updateRequestPayingOrder(order))
          dispatch(updateRequestWaiterOrder(order))
          dispatch(removeUnconfirmedOrder(order.serial))
          dispatch(alertOrderChange(order, prevStatus, prevRoundedTotal))
          break
        default:
          break
      }
    }
    ws.onclose = (event) => {
      logger.log(`[WebSocket] Disconnected ${event.reason}`, { event })
      dispatch(actions.app.updateWebSocketStatus(webSocketStatusCode.CLOSED))
      heartCheck.reset()
      dispatch(handleWebSocketError())
    }
    ws.onerror = (error) => {
      logger.error(`[WebSocket] Error ${error?.message || error}`, { error })
      dispatch(actions.app.updateWebSocketStatus(webSocketStatusCode.CLOSED))
      heartCheck.reset()
      dispatch(handleWebSocketError())
    }
  }
}
/**
 * @returns {ThunkFunction}
 */
export function handleWebSocketError () {
  return (dispatch, getState) => {
    const isUserLogin = getState().auth.isUserLogin
    const showWebSockectWarningAt = getState().app.showWebSockectWarningAt
    // 顯示 warning alert
    const diff = moment().diff(showWebSockectWarningAt, 'minutes')
    dispatch(actions.app.updateWebSocketReady(false))
    dispatch(stopWebSocketOrdersUpdater())
    if (!isUserLogin) return
    if (diff > 5 || showWebSockectWarningAt === '') {
      dispatch(actions.app.updateShowWebSockectWarningAt())
      dispatch(actions.app.enqueueSnackbar({
        text: i18n.t('app.component.webSocket.alert.webSocketDisconnect.message'),
        duration: 5000,
      }))
    }

    // [ 部署重新連接 ]  - timeout 後重連
    const timeoutID = setTimeout(() => dispatch(startWebSocketOrdersUpdater()), 5 * 1000)
    wsRetryTimeouts.push(timeoutID) // 記錄timeoutID
  }
}

/**
 * @param {string} reason
 * @returns {ThunkFunction}
 */
export function stopWebSocketOrdersUpdater (reason) {
  return (dispatch, getState) => {
    if (ws && ws.readyState === ws.OPEN) {
      logger.log('[WebSocket] stopWebSocketOrdersUpdater', { reason })
      // send msg給server 叫server主動斷了client的連接
      try {
        ws.send(JSON.stringify({ action: 'close' }))
      } catch (error) {
        logger.warn(`[WebSocket] close error: ${error?.message || error.toString()}`, { error })
      }
      ws.close(4000, reason)
      ws = null
    }
  }
}

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function getOrderById (orderId) {
  return async (dispatch, getState) => {
    const order = await dimorderApi.order.getOrder(orderId)

    dispatch({
      type: ActionTypes.UPDATE_ORDER,
      payload: { order },
    })
  }
}

/**
 * orderHistory.init 起
 * 每 30 秒執行一次
 * 抓最後 10 分鐘的訂單更新到本地（避免 ws 漏資料）
 * @returns {ThunkFunction}
 */
export function getOrdersInterval () {
  return async (dispatch, getState) => {
    const orders = await dimorderApi.order.getOrders({
      // 10 分鐘內更新過的訂單
      from: moment().subtract(10, 'minutes').toDate(),
      timeProp: 'updatedat',
    })

    dispatch(updateOrdersFromServer(orders, 'getOrdersInterval', false))
  }
}

export const throttledGetOrdersThunk = debounceAction(getOrders, 10000, {
  leading: true, // 第一次觸發會直接執行
  trailing: false, // 之後 10 秒內觸發的都被跳過
})
/**
 * 10 秒只能執行一次 getOrders
 * @param {string} [reason='unknown']
 * @returns {ThunkFunction}
 */
export function throttledGetOrders (reason) {
  return async (dispatch) => {
    logger.log(`[orderHistory/throttledGetOrders] reason: ${reason}`, { reason })
    await dispatch(throttledGetOrdersThunk(reason))
  }
}

/**
 * 抓全部有效範圍內的訂單，通常只會在 APP 剛啟動或是斷線恢復後時抓
 * 其他情況會在 ws event 得到更新或是 getOrdersInterval 補齊 ws 漏掉的資料
 * @param {string} [reason='unknown']
 * @returns {ThunkFunction}
 */
export function getOrders (reason = 'unknown') {
  return async (dispatch, getState) => {
    try {
      logger.log(`[orderHistory/getOrders] reason: ${reason}`, { reason })
      const now = moment()
      const oneDayAgo = now.clone().subtract(1, 'days')
      const eightDaysAgo = now.clone().subtract(8, 'days')
      const sixtyDaysAgo = now.clone().subtract(60, 'days')

      const [
        ordersCreatedWithin1Day = [], // 訂單建立時間 (createdat) 1 天以內的堂食訂單
        processingTakeawayOrders = [], // 訂單取餐時間 (pickupat) 8 天以內，且仍在進行中的外帶訂單
        processingStoreDeliveryOrders = [], // 訂單取餐時間 (pickupat) 60 天以內，且仍在進行中的外送訂單
      ] = await Promise.all([
        dimorderApi.order.getOrders({
          from: oneDayAgo.toDate(),
          timeProp: 'createdat',
        }),
        dimorderApi.order.getOrders({
          from: eightDaysAgo.toDate(),
          timeProp: 'pickupAt',
          takeaway: true,
          storeDelivery: false,
          takeawayStatus: 'pending,confirmed,ready', // ? 問題：餐廳不需要知道外帶訂單已取餐？
          // takeawayStatus: 'pending' -> 待接單
          // takeawayStatus: 'confirmed' -> 備餐中
          // takeawayStatus: 'ready' -> 待取餐
        }),
        dimorderApi.order.getOrders({
          from: sixtyDaysAgo.toDate(),
          timeProp: 'pickupAt',
          storeDelivery: true,
          takeawayStatus: 'pending,confirmed,ready,completed', // ? 問題：餐廳不需要知道外送訂單已送達？
          // takeawayStatus: 'pending' -> 待接單
          // takeawayStatus: 'confirmed' -> 備餐中
          // takeawayStatus: 'ready' -> 待取餐
          // takeawayStatus: 'completed' -> 配送中
        }),
      ])

      const orders = _
        .chain([ordersCreatedWithin1Day, processingTakeawayOrders, processingStoreDeliveryOrders])
        .flatten()
        .uniqBy('id') // 去除重複的訂單
        .value()

      // 沒有orders時，防止 存入本地數據庫dialog 卡住User
      if (_.isEmpty(orders) && reason.includes('startWebSocketOrdersInit')) {
        dispatch(actions.app.closeDialog(['storeLocalDatabase']))
      }

      dispatch(updateOrdersFromServer(orders, `getOrders by ${reason}`, true, reason))
    } catch (error) {
      logger.error(`[orderHistory/getOrders Error] ${error}`, { error, reason })
      // 沒有網絡時，防止 存入本地數據庫dialog 卡住User
      if (reason.includes('startWebSocketOrdersInit')) { dispatch(actions.app.closeDialog(['storeLocalDatabase'])) }
    }
  }
}

/**
 * 處理後端來的訂單
 * @param {IAppOrder} orders
 * @param {string?} [reason='unknown'] 由誰觸發
 * @param {boolean?} [overwrite=false] 覆蓋全部 orderHistory.orders (只有 getOrders 會用)
 * @returns {ThunkFunction}
 */
export function updateOrdersFromServer (orders, reason = 'unknown', overwrite = false) {
  return (dispatch, getState) => {
    // 記錄現時在 checkoutOrder 的 status 和 roundedTotal
    const checkoutOrderId = getState().orderCheckout.orderId
    const { status: prevStatus, roundedTotal: prevRoundedTotal } = getHistoryOrder(checkoutOrderId) || {}
    // 取得一個 lodash instant
    const lodash = _.runInContext()
    lodash.mixin({
      logOrders: (orderArray, message) => {
        // 下面 chain 已經加了註解，將註解打開即可顯示各步驟的結果
        console.log(`[updateOrdersFromServer/logOrders] ${message}`, { reason, overwrite, orders: orderArray?.map(convertToSimpleOrder) })
        return orderArray
      },
      formatOrders: (orderArray) => dispatch(formatOrders(orderArray)),
      updateOrders: (orderArray) => dispatch(updateOrders(orderArray, { syncOrder: false, overwrite, reason: reason })),
      autoConfirmOrders: (orderArray) => dispatch(autoConfirmOrders(orderArray)),
      notifyOrders: (orderArray) => {
        return orderArray.map(order => {
          // 檢查是否需要更新叫侍應提示
          dispatch(updateRequestWaiterOrder(order))
          // 檢查是否需要更新請求付款提示
          dispatch(updateRequestPayingOrder(order))
          // 檢查是否需要關閉未確認訂單提示
          dispatch(removeUnconfirmedOrder(order.serial))
          // 檢查是否需要提示訂單更新
          dispatch(alertOrderChange(order, prevStatus, prevRoundedTotal))
          return order
        })
      },
    })

    const pendingSyncOrderIds = getState().unsyncOrder.pendingOrderIds
    const syncingOrderId = getState().unsyncOrder.syncingOrderId
    const historyOrders = getState().orderHistory.orders
    const unsyncOrders = overwrite ? historyOrders.filter(order => pendingSyncOrderIds.concat([syncingOrderId].filter(orderId => orderId !== null)).includes(order.id)) : []

    // 取出最近 5 秒內已同步的訂單，避免同步時被蓋掉
    const time = moment()
    const syncedOrders = historyOrders.filter(order => {
      if (order?.syncedAt) {
        return time.diff(order?.syncedAt, 'seconds') < 5
      }
    })
    const syncedOrdersIds = syncedOrders.map(order => order.id)

    lodash.chain(orders)
      // 排除等代上傳的訂單（等代上傳的訂單還有資料沒上傳到後端，不可被後端的更新蓋掉）
      .filter(order => !pendingSyncOrderIds.includes(order.id))
      // 排除正在上傳的訂單（正在上傳的訂單還有資料沒上傳到後端，不可被後端的更新蓋掉）
      .filter(order => !(syncingOrderId === order.id))
      // 排除剛剛同步過的訂單（剛剛同步過的訂單有機會與後端有時間差，暫時不可被後端的更新蓋掉）
      .filter(order => !syncedOrdersIds.includes(order.id))
      .filter(order => {
        const historyOrder = getHistoryOrder(order.id)

        // 如果沒有歷史訂單，直接更新
        if (!historyOrder) return true

        // 檢查 server pending 的 payment 在本地是否 paid，如果是，則不更新
        const hasPaymentConflict = historyOrder.payments.some((payment) => {
          const serverPayment = order.payments.find((serverPayment) => serverPayment.id === payment.id)
          if (!serverPayment) return true

          return payment.status === 'paid' && serverPayment.status === 'pending'
        })

        // 如果本地訂單狀態是 paid，但是server訂單狀態是 pending，則不更新
        const hasStatusConflict = historyOrder.status === 'paid' && order.status === 'pending'

        return !(hasStatusConflict || hasPaymentConflict)
      })
      // .logOrders('after filter')
      // 經過 formatOrders 整理格式
      .formatOrders()
      .without(undefined) // 去除 formatOrders 中 error 的 order
      // .logOrders('after formatOrders')
      // 如果 orderHistory.orders 會被 overwrite，需要將未上傳的本地訂單加回來
      .concat(unsyncOrders)
      // 把剛剛同步過的訂單加回來
      .concat(syncedOrders)
      // .logOrders('after concat')
      // 更新到 store orderHistory.orders
      .updateOrders()
      // .logOrders('after updateOrders')
      // 檢查 batches 是否需要 auto confirm
      .autoConfirmOrders()
      // .logOrders('after autoConfirmOrders')
      // 處理訂單的各種提示
      .notifyOrders()
      // .logOrders('after notifyOrders')
      .value()
  }
}

/**
 * 將 inputOrders 整理成前端用的格式
 * ! 當 order 處理錯誤時，map 將不會 return order，因此結果的 array 中可能會有 undefined，需要過濾後使用
 * ! 注意: 已知這些 format 有問題，且和 getOrders 的行為不一致
 * https://outline.dimorder.com/doc/merchant-getorders-8fXArzPtbW
 * @param {IAppOrder[]} inputOrders
 * @returns {ThunkFunction}
 */
export function formatOrders (inputOrders) {
  return (dispatch, getState) => {
    const rounding = getState().merchant.data.rounding
    const setting = getState().merchant.data.setting

    return inputOrders.map(inputOrder => {
      try {
        // ? order.createdAt 應該要被改掉嗎？
        // ? 如果就這樣改掉了，之後 /orderimport 就會把 db 中 order.createdAt 蓋掉？
        let createdAt = inputOrder.createdAt
        if (!setting.showOrderCreateAt && inputOrder.batches.length >= 1) {
          createdAt = moment(inputOrder.batches[0].createdAt).utc().toISOString()
        }

        // ? 這裡的 calculateLocalBatchItem, formatBatchItemStatus, groupBatchItem 和 apiOrderToAppOrder 有大量重複的流程
        // ? 但是有差異，也有看到有 format 錯東西
        // ? 且只有這邊這樣用，如果是 getOrders 則會使用 apiOrderToAppOrder 來 format
        // ? 未來可能需要整理過 https://outline.dimorder.com/doc/merchant-getorders-8fXArzPtbW
        const formatedBatches = inputOrder.batches.map((batch) => {
          return {
            ...batch,
            items: dimorderLib.groupBatchItem(batch.items.map((item, index) => {
              return dimorderLib.formatBatchItemStatus(dimorderLib.calculateLocalBatchItem(item))
            })),
          }
        })

        const formatedOrder = {
          ...inputOrder,
          rounding, // ? 為什麼要動 rounding，inpurtOrder 都會有 rounding
          createdAt, // ? 為什麼要動 createdAt?
          batches: formatedBatches,
        }

        return formatedOrder
      } catch (error) {
        logger.error(`[orderHistory/formatOrders] error: ${error?.message ?? error?.toString()}`, { error, inputOrder })
      }
    })
  }
}

/**
 *
 * @param {IAppOrder[]} orders
 * @returns {ThunkFunction}
 */
export function autoConfirmOrders (orders) {
  return (dispatch, getState) => {
    const isMaster = getState().app.settings.isMaster
    const enableTableAutoConfirm = getState().merchant.data.setting?.autoConfirm
    const enableTakeawayAutoConfirm = getState().merchant.data.setting?.takeawayAutoConfirm
    const isPayFirst = getState().merchant.data.setting?.payFirst

    if (!isMaster) return orders // 只有 master 會自動確認

    const deliveryTypeEnableAutoConfirm = {
      table: enableTableAutoConfirm, // 堂食只要看 autoConfirm
      takeaway: enableTakeawayAutoConfirm, // 自取只要看 takeawayAutoConfirm
      storeDelivery: false, // 外送不會自動確認
    }

    _.forEach(orders, order => {
      const isTablePayLater = order.deliveryType === 'table' && !isPayFirst // 堂食後付款
      if (!['pending', 'paid'].includes(order.status)) return // 只有 pending 和 paid 狀態的訂單才會自動確認
      if (!isTablePayLater && order.status === 'pending') return // 非堂食後付款的訂單（也就是需要先付款，如：自取、外送、堂食先付款），這類訂單在 pending 狀態時不會自動確認，只有 paid 才會自動確認

      const submittedBatches = order.batches.filter(batch => batch.status === 'submitted')
      if (submittedBatches.length === 0) return // 沒有待確認的 batch

      // 是否可以自動確認
      const enableAutoConfirm = deliveryTypeEnableAutoConfirm[order.deliveryType] ?? false
      if (enableAutoConfirm) {
        if (order.deliveryType === 'table') {
          // 堂使用 confirmBatch 自動確認全部 submitted batch
          submittedBatches.forEach(batch => {
            dispatch(actions.orderHistory.confirmBatch(order, batch, true, 'autoConfirmOrders'))
          })
        } else if (order.deliveryType === 'takeaway') {
          // 自取使用 confirmOrder call api confirm batch
          // takeawayStatus 也會被改成 confirmed 表示接單
          dispatch(actions.orderHistory.confirmOrder(order))
        }
      } else {
        // 不可自動確認，提示有訂單未確認
        dispatch(actions.orderHistory.addUnconfirmedOrder(order.serial))
      }
    })

    return orders
  }
}

/**
 * 將訂單更新到 orderHistory.orders，包含重新計算訂單及 displayStatusKey
 * @param {IAppOrder} nextOrder 更新的訂單
 * @param {UpdateOrderOptions} options
 *
 * @typedef UpdateOrderOptions
 * @property {boolean} [selectOrder=true] 在 orderHistory 選擇訂單
 * @property {boolean} [syncOrder=true] 是否上傳訂單 (加入 unsyncOrder 中，待 request /orderimport)
 * @returns {ThunkFunction}
 */
export function updateOrder (nextOrder, options = {}) {
  return (dispatch, getState) => {
    if (!nextOrder) return
    const { selectOrder = true, syncOrder = true } = options

    logger.log('[orderHistory/updateOrder] nextOrder', { order: convertToSimpleOrder(nextOrder) })

    // 防止訂單flag被刪除
    const validatedOrder = preserveValidOrderFlags(nextOrder)

    // 重新計算訂單
    const calculatedOrder = dimorderLib.calculateLocalOrder(validatedOrder)
    logger.log('[orderHistory/updateOrder] calculatedOrder', { order: convertToSimpleOrder(calculatedOrder) })

    // 更新到 store
    dispatch({
      type: ActionTypes.UPDATE_ORDER,
      payload: { order: calculatedOrder },
    })

    // 在 orderHistory 選擇訂單、並更新訂單頁面的 initialScrollIndex
    if (selectOrder) {
      dispatch(actions.orderHistory.selectOrder(calculatedOrder.id))
    }

    // 若是目前正在點餐的訂單，也要更新到 order.selectedOrder
    const selectedOrderId = getState().order?.selectedOrder?.id
    if (selectedOrderId === calculatedOrder.id) {
      dispatch(actions.order.selectOrder(calculatedOrder))
    }

    // 若有開 syncOrder，準備丟到後端
    const disableOrderSync = getState().app.settings.debugSettings.disableOrderSync
    if (syncOrder && !disableOrderSync) {
      dispatch(actions.unsyncOrder.addUnsyncOrderIds([calculatedOrder.id]))
    }

    // 把更新的訂單寫進 Local Database
    OrderLocalDatabase.upsertOrder(calculatedOrder)

    // submitOrderBatch 有使用 return order，不可移除 return
    return calculatedOrder
  }
}

/**
 * 將訂單更新到 orderHistory.orders，包含重新計算訂單及 displayStatusKey
 * @param {IAppOrder[]} nextOrders 更新的訂單
 * @param {UpdateOrdersOptions} options
 *
 * @typedef UpdateOrdersOptions
 * @property {boolean?} [syncOrder=false] 是否上傳訂單 (加入 unsyncOrder 中，待 request /orderimport)
 * @property {boolean?} [overwrite=false] 覆蓋全部 orderHistory.orders
 * @property {string?} [reason=unknown] 用作檢查寫進Local Database 的條件
 * @returns {ThunkFunction}
 */
export function updateOrders (nextOrders, options = {}) {
  return (dispatch, getState) => {
    if (!nextOrders || nextOrders.length === 0) return []
    const { syncOrder = false, overwrite = false, reason = '' } = options
    const runAt = Date.now() // 方便在logger認回同一個function call，避免混淆

    console.log(`[orderHistory/updateOrders] ${runAt} nextOrders`, { syncOrder, overwrite, orders: nextOrders.map(convertToSimpleOrder) })

    // 防止訂單flag被刪除
    const validatedOrders = nextOrders.map(order => preserveValidOrderFlags(order))

    // 重新計算訂單、設定 displayStatusKey
    const rounding = getState().merchant.data.rounding
    const calculatedOrders = _.chain(validatedOrders)
      .map((order) => {
        try {
          return dimorderLib.calculateLocalOrder(order)
        } catch (error) {
          logger.error(`[orderHistory/updateOrders] ${runAt} calculateLocalOrder error: ${error?.message || error?.toString()}`, { error, order, rounding })
        }
      })
      .without(undefined) // 去除 error 時的 undefined
      .value()

    console.log(`[orderHistory/updateOrders] ${runAt} calculatedOrders`, { syncOrder, overwrite, orders: calculatedOrders.map(convertToSimpleOrder) })

    // 更新到 store
    if (overwrite) {
      // 在 getOrders 時會需要用覆蓋的，如果有帶 overwrite = true 則使用 UPDATE_ORDERS 覆蓋
      dispatch({
        type: ActionTypes.UPDATE_ORDERS,
        payload: { orders: calculatedOrders },
      })
    } else {
      // 用 UPSERT_ORDERS 將改動的訂單 update 或 insert 進 orderHistory.orders
      dispatch({
        type: ActionTypes.UPSERT_ORDERS,
        payload: { orders: calculatedOrders },
      })
    }
    logger.log(`[orderHistory/updateOrders] ${runAt} update to store ${calculatedOrders.length} orders`, { syncOrder, overwrite })

    // 若是目前正在點餐的訂單，也要更新到 order.selectedOrder
    const selectedOrderId = getState().order?.selectedOrder?.id
    if (selectedOrderId) {
      _.some(calculatedOrders, order => {
        if (order.id === selectedOrderId) {
          dispatch(actions.order.selectOrder(order))
          return true
        }
      })
    }

    // 若有開 syncOrder，準備丟到後端
    const disableOrderSync = getState().app.settings.debugSettings.disableOrderSync
    if (syncOrder && !disableOrderSync) {
      const unsyncOrderIds = _.map(calculatedOrders, order => order.id)
      dispatch(actions.unsyncOrder.addUnsyncOrderIds(unsyncOrderIds))
    }

    // 把更新的訂單加入到Local Database
    dispatch(upsertingOrderIntoLocalDatabase(calculatedOrders, reason))

    // 為了使用 chain 必須 return orders
    return calculatedOrders
  }
}

/**
 * 把更新的訂單加入到 Local Database
 * @param {*} dispatch
 * @param {IAppOrder[]} orders
 * @param {String?} reason
 */
function upsertingOrderIntoLocalDatabase (orders, reason = null) {
  return async (dispatch, getState) => {
    const lazyLoadReason = ['getOrdersInterval', 'updateNetInfo', 'appState change']
    if (reason.includes('startWebSocketOrdersUpdater')) {
      // 不寫東西到 local databse
      return
    }
    if (reason.includes('startWebSocketOrdersInit')) {
      for (let i = 0; i < orders.length; i++) {
        // for Storage Dialog 顯示目前載入的進度
        if (i % 50 === 0) await delay(1)
        await OrderLocalDatabase.upsertOrder(orders[i])
        dispatch(actions.app.throttledInitQueuingMessage(`${(i + 1)} / ${orders.length}`))
      }
      dispatch(actions.app.closeDialog(['storeLocalDatabase']))
      return
    }
    if (lazyLoadReason.find(lazyReason => reason.includes(lazyReason))) {
      // 使用 lazy queue 慢慢地存儲的 flow 如下:
      // 1. 30 秒一次 Polling (getOrdersInterval 會有最近 1 分鐘修改過的訂單)
      // 2. updateNetInfo 斷線後重新連線 (getOrders 會有大量訂單更新)
      // 3. appState change 關螢幕後 30 秒 (getOrders 會有大量訂單更新)
      for (let i = 0; i < orders.length; i++) {
        dispatch(addLocalDBLazyQueue(orders[i].id))
      }
      return
    }

    // 其他 reason 為 POS 內部更改訂單，不會有大量訂單，使用正常 Queue 就可以
    for (let i = 0; i < orders.length; i++) {
      await OrderLocalDatabase.upsertOrder(orders[i])
    }
  }
}

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function selectOrder (orderId, initialScrollIndex) {
  return async (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    if (selectedOrderId !== orderId) {
      dispatch(resetSelectedOrderItems())
    }
    dispatch({
      type: ActionTypes.SELECT_ORDER,
      payload: { orderId },
    })
    if (initialScrollIndex > 0) {
      dispatch(updateInitialScrollIndex(initialScrollIndex))
    }
  }
}

export function updateInitialScrollIndex (initialScrollIndex = 0) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_INITIAL_SCROLL_INDEX,
      payload: { initialScrollIndex },
    })
  }
}

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function copyOrder (orderId) {
  return async (dispatch, getState) => {
    // TODO: api or create new order, submit batch?
  }
}

/**
 * 取消、作廢、退桌、不接單
 * @param {IAppOrder} order
 * @param {string} reason 取消原因
 * @returns {ThunkFunction}
 */
export function cancelOrder (order, reason) {
  return async (dispatch, getState) => {
    try {
      const account = getState().auth.userLogin.account
      const approver = getState().auth.approver
      if (order.from === 'MERCHANT') {
        await dispatch({
          type: ActionTypes.CANCEL_ORDER,
          payload: { order, reason, account, approver },
        })
        const historyOrders = getState().orderHistory.orders
        const localOrder = historyOrders.find(o => o.id === order.id)
        orderEvent.emitter.emit(orderEvent.eventType.ORDER_CANCELLED, localOrder)
        dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
      } else {
        const canceledOrder = await dimorderApi.order.cancelOrder(order.id, reason, account, approver)

        dispatch(updateOrder(canceledOrder, { selectOrder: false, syncOrder: true }))
      }
    } catch (error) {
      logger.log('[orderHistory/cancelOrder] failed', { error })
      dispatch(actions.app.showAlert({
        title: i18n.t('app.common.error'),
        message: i18n.t('app.component.serve.error'),
      }))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function cancelOrders (orders, reason) {
  return async (dispatch, getState) => {
    const account = getState().auth.userLogin.account
    const updatedOrders = []
    await Promise.all(_.map(orders, async order => {
      if (order.deliveryType === 'table') {
        await dispatch({
          type: ActionTypes.CANCEL_ORDER,
          payload: { order, reason, account },
        })
      }
      const historyOrders = getState().orderHistory.orders
      const localOrder = historyOrders.find(o => o.id === order.id)
      updatedOrders.push(localOrder)
    }))
    if (!_.isEmpty(updatedOrders)) {
      orderEvent.emitter.emit(orderEvent.eventType.ORDER_CANCELLED, updatedOrders)
      dispatch(updateOrders(updatedOrders, { syncOrder: true, overwrite: false }))
    }
  }
}

/**
 * 接單
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function confirmOrder (order) {
  return async (dispatch, getState) => {
    try {
      const deviceId = Constants.installationId
      const { enablePrintStaff: printStaff } = getState().merchant.data?.setting ?? {}
      const printConfig = { printStaff }
      const newOrder = await dimorderApi.order.confirmOrder(
        order.id,
        deviceId,
      )
      const lastBatch = _.last(order.batches)
      const invoiceSettings = getState().printer.printerSetting.invoiceSettings
      const syncTakeawayReceipt = invoiceSettings.length > 0
        ? invoiceSettings.findIndex(o => o.syncTakeawayReceipt) > -1
        : getState().printer.printerSetting.defaultSettings.syncTakeawayReceipt
      const { kitchenReceiptSettings, labelSettings } = getState().printer.printerSetting
      const enableTakeawayAutoConfirm = getState().merchant.data.setting?.takeawayAutoConfirm
      // 檢查有任何一個 printer setting 有開啟 takeaway 和有選擇 printer
      const hasPrinterAndTakeaway =
        kitchenReceiptSettings.some(setting => {
          return setting.printer.length && setting.takeaway
        }) ||
        labelSettings.some(setting => {
          return setting.printer.length && setting.takeaway
        })
      if (enableTakeawayAutoConfirm) {
        // 自動確認外賣訂單直接列印廚房單
        await dispatch(actions.printer.printKitchenBatch(order, lastBatch.items, printConfig, false, lastBatch))
      } else if (hasPrinterAndTakeaway) {
        // 詢問是否馬上列印廚房單
        dispatch(actions.app.showAlert({
          title: i18n.t('app.component.printKitchenBatchDialog.title'),
          message: i18n.t('app.component.printKitchenBatchDialog.message'),
          enablePressOutsideClose: false,
          buttons: [
            {
              backgroundColor: colors.light,
              textColor: colors.textTertiary,
              children: i18n.t('app.common.no'),
            },
            {
              children: i18n.t('app.common.yes'),
              onPress: () => {
                dispatch(actions.printer.printKitchenBatch(order, lastBatch.items, printConfig, false, lastBatch))
              },
            },
          ],
        }))
      }
      if (syncTakeawayReceipt && order.takeaway) {
        dispatch(actions.printer.printOrderReceipt({
          order,
          sync: true,
          printReason: PrintReason.ORDER_RECEIPT.CONFIRM_BATCH,
          printConfig: { reprint: false },
        }))
      }

      dispatch(updateOrder(newOrder, { selectOrder: false, syncOrder: false }))
      dispatch(actions.orderHistory.removeUnconfirmedOrder(order.serial))
    } catch (error) {
      logger.log('[orderHistory/confirmOrder] failed', { error })
      dispatch(actions.app.showAlert({
        title: i18n.t('app.common.error'),
        message: i18n.t('app.component.serve.error'),
      }))
    }
  }
}

/**
 * 出餐
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function readyOrder (order) {
  return async (dispatch, getState) => {
    try {
      if (order.from === 'MERCHANT') {
        await dispatch({
          type: ActionTypes.READY_ORDER,
          payload: { orderId: order.id },
        })
        const localOrder = getHistoryOrder()
        dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
      } else {
        const updatedOrder = await dimorderApi.order.readyOrder(order.id)
        dispatch(updateOrder(updatedOrder, { selectOrder: true, syncOrder: false }))
      }
    } catch (error) {
      dispatch(actions.app.showAlert({
        title: i18n.t('app.alert.error'),
        message: `${i18n.t('app.component.orderError.readyOrder')} (${logId})`,
      }))
      logger.error('[orderHistory/readyOrder] error', { logId, error })
    }
  }
}

/**
 * 取餐
 * @param {IAppOrder} order
 * @param {string} code
 * @returns {ThunkFunction}
 */
export function completeOrder (order, code) {
  return async (dispatch, getState) => {
    if (order.from === 'MERCHANT') {
      await dispatch({
        type: ActionTypes.COMPLETE_ORDER,
        payload: { orderId: order.id },
      })
      const historyOrders = getState().orderHistory.orders
      const localOrder = historyOrders.find(o => o.id === order.id)
      dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
      orderEvent.emitter.emit(orderEvent.ORDER_COMPLETE, order)
    } else {
      const updatedOrder = await dimorderApi.order.completeOrder(order.id, code)
      dispatch(updateOrder(updatedOrder, { selectOrder: true, syncOrder: false }))
    }
  }
}

export function getTakeawayOrderByCode (code, selectedOrder = {}) {
  return async (dispatch, getState) => {
    try {
      let order = {}
      if (!_.isEmpty(selectedOrder)) {
        if (selectedOrder.code !== code) {
          throw new Error()
        }
        order = selectedOrder
      } else {
        order = await dimorderApi.order.getOrderByInfo({ code: code })
      }
      if (order.takeawayStatus === 'ready') {
        const completeOrder = await dimorderApi.order.completeOrder(order.id, code)
        await dispatch(actions.app.showSimpleAlert(i18n.t('app.component.takeawayDialog.takeaway'), `${i18n.t('app.component.takeawayDialog.orderNum') + completeOrder.serial + i18n.t('app.component.takeawayDialog.completedStatus')}`))
        dispatch(updateOrder(completeOrder, { selectOrder: true, syncOrder: true }))
        window.applicatiionHistory.push('/orderHistory')
        dispatch(actions.orderHistory.selectOrder(completeOrder.id))
      } else {
        dispatch(actions.app.showSimpleAlert(i18n.t('app.component.takeawayDialog.error'), order.takeawayStatus === 'completed' ? i18n.t('app.component.takeawayDialog.completedMsg') : i18n.t('app.component.takeawayDialog.completedError')))
      }
    } catch (err) {
      dispatch(actions.app.showSimpleAlert(i18n.t('app.component.takeawayDialog.error'), i18n.t('app.component.takeawayDialog.codeNotFound')))
    }
  }
}

/**
 * 送達
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function deliverOrder (orderId) {
  return async (dispatch, getState) => {
    const order = await dimorderApi.order.deliverOrder(orderId)
    dispatch(updateOrder(order, { selectOrder: true, syncOrder: true }))
  }
}

/**
 * 更改備註
 * @param {string} orderId
 * @param {string} remark
 * @returns {ThunkFunction}
 */
export function updateOrderRemark (orderId, remark) {
  return async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.UPDATE_ORDER_REMARK,
      payload: { orderId, remark },
    })
    const localOrder = getHistoryOrder()
    dispatch(updateOrder(localOrder, { selectOrder: true, syncOrder: true }))
  }
}

export function toggleSelectBatch (batch) {
  return (dispatch, getState) => {
    const selectedOrderBatches = getState().orderHistory.selectedOrderBatches
    const existsBatchIndex = selectedOrderBatches.findIndex(
      (selectedOrderBatch) => selectedOrderBatch.batchId === batch.batchId,
    )
    if (existsBatchIndex >= 0) {
      dispatch({
        type: ActionTypes.DESELECT_ORDER_BATCH,
        payload: { index: existsBatchIndex },
      })
      batch?.items.forEach((item, index) => {
        dispatch({
          type: ActionTypes.DESELECT_ORDER_ITEM,
          payload: { item },
        })
      })
    } else {
      batch?.items.forEach((item, index) => {
        const selectedItems = getState().orderHistory.selectedOrderItems
        const existsItemIndex = selectedItems.findIndex(
          (selectedItem) => selectedItem.id === item.id,
        )
        if (item.isSet) {
          // 如果是選擇套餐，反選全部套餐內容
          const deselectItemIndex = []
          selectedItems.forEach((selectedItem, index) => {
            if (
              !selectedItem.isSet &&
              selectedItem.setMenuIndex === item.setMenuIndex
            ) {
              deselectItemIndex.push(selectedItem)
            }
          })
          deselectItemIndex.reverse().forEach((item, index) => {
            dispatch({
              type: ActionTypes.DESELECT_ORDER_ITEM,
              payload: { item },
            })
          })
        } else {
          if (existsItemIndex < 0) {
            dispatch({
              type: ActionTypes.SELECT_ORDER_ITEM,
              payload: { item },
            })
          }
        }
      })
      dispatch({
        type: ActionTypes.SELECT_ORDER_BATCH,
        payload: { batch },
      })
    }
  }
}
/**
 * 選擇/反選 batch item
 * @param {IAppBatchItem} item
 * @returns {ThunkFunction}
 */
export function toggleSelectItem (item) {
  return (dispatch, getState) => {
    const selectedItems = getState().orderHistory.selectedOrderItems
    const existsItemIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id,
    )
    const isSetItem = !item.isSet && item.setMenuIndex != null
    if (existsItemIndex >= 0) {
      dispatch({
        type: ActionTypes.DESELECT_ORDER_ITEM,
        payload: { item },
      })
    } else {
      if (item.isSet) {
        // 如果是選擇套餐，反選全部套餐內容
        const deselectItemIndex = []
        selectedItems.forEach((selectedItem, index) => {
          if (
            !selectedItem.isSet &&
            selectedItem.setMenuIndex === item.setMenuIndex
          ) {
            deselectItemIndex.push(selectedItem)
          }
        })
        deselectItemIndex.reverse().forEach((item, index) => {
          dispatch({
            type: ActionTypes.DESELECT_ORDER_ITEM,
            payload: { item },
          })
        })
      }
      if (isSetItem) {
        // 如果是選擇套餐餐點，且套餐本身有被選到，則反選套餐
        const setIndex = selectedItems.findIndex(
          (selectedItem) =>
            selectedItem.isSet &&
            selectedItem.setMenuIndex === item.setMenuIndex,
        )
        if (setIndex >= 0) {
          dispatch({
            type: ActionTypes.DESELECT_ORDER_ITEM,
            payload: { item },
          })
        }
      }
      dispatch({
        type: ActionTypes.SELECT_ORDER_ITEM,
        payload: { item },
      })
    }
  }
}

/**
 * 全選order item
 * @returns {ThunkFunction}
 */
export function selectAllItems () {
  return (dispatch, getState) => {
    const selectedOrder = getHistoryOrder()
    if (selectedOrder) {
      const items = _.flatMap(selectedOrder.batches, batch => {
        return _.map(batch.items, item => {
          return { ...item, batchId: batch.id }
        })
      }).filter(item => (!item.cancelled && !item.priceUndetermined))
      dispatch({
        type: ActionTypes.SELECT_ORDER_ITEMS,
        payload: { items },
      })
    }
  }
}

/**
 * 取消選擇 batch item
 * @param {IAppBatchItem} item
 * @returns {ThunkFunction}
 */
export function deSelectItem (item) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.DESELECT_ORDER_ITEM,
      payload: { item },
    })
  }
}

// * Item level operator

/**
 * 轉移選擇的餐點到其他訂單
 * @param {string} toOrderId
 * @returns {ThunkFunction}
 */
export function transferSelectedItems (toOrderId, quantity, table) {
  return async (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const selectedItems = getState().orderHistory.selectedOrderItems
    let orderId = toOrderId
    const fromOrderId = selectedOrderId
    const historyOrders = getState().orderHistory.orders
    const order = historyOrders.find(o => o.id === selectedOrderId)
    const batch = order.batches[_.findLastIndex(order.batches)]
    const account = getState().auth.userLogin.account

    dispatch(actions.table.updateSelectingTable(false))

    await dispatch(actions.app.openLoading(loadingKey.ITEM))

    if (!toOrderId) {
      await dispatch(actions.table.createOrder({
        deliveryType: 'table',
        table,
        adults: 1,
        children: 0,
        create: true,
        from: 'MERCHANT',
      }, null, '', true, false))
      orderId = getState().order.selectedOrder.id
    }

    await dispatch({
      type: ActionTypes.TRANSFER_ITEM,
      payload: { fromOrderId, selectedItems, quantity, toOrderId: orderId, submittedAt: moment().format(), account },
    })

    // await Promise.all(promises)
    const fromOrder = getHistoryOrder(fromOrderId)
    const toOrder = getHistoryOrder(orderId)
    const printConfig = {
      transfer: {
        from: fromOrder,
        to: toOrder,
      },
    }
    await dispatch(actions.printer.printKitchenBatch(fromOrder, selectedItems, printConfig, false, batch, quantity))
    dispatch(updateOrders([toOrder, fromOrder], { syncOrder: true, overwrite: false }))
    orderEvent.emitter.emit(orderEvent.eventType.ORDER_ITEM_TRANSFER, order, toOrder, selectedItems)

    dispatch(actions.app.closeLoading(loadingKey.ITEM))
    dispatch(resetSelectedOrderItems())
  }
}

/**
 * 更改價錢 (時價)
 * @param {string} itemId
 * @param {number} price
 * @returns {ThunkFunction}
 */
export function updateSelectedItemPrice (price) {
  return async (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const selectedItems = getState().orderHistory.selectedOrderItems
    const { isMaster } = getState().app.settings
    if (selectedItems[0]) {
      const order = await dimorderApi.order.updateItemPrice(
        selectedOrderId,
        selectedItems[0].id,
        price,
      )
      dispatch(updateOrder(order, { selectOrder: false, syncOrder: false }))
      if (isMaster) {
        dispatch(confirmAllBatch(order))
      }
    }
  }
}

/**
 * 更改全單折扣/服務費
 * @param {IAppBatchItem} modifier
 * @returns {ThunkFunction}
 */
export function updateModifier (modifier) {
  return (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    dispatch({
      type: ActionTypes.UPDATE_ORDER_MODIFIER,
      payload: { modifier, selectedOrderId },
    })
    const order = getHistoryOrder(selectedOrderId)
    dispatch(updateOrder(order, { selectOrder: false, syncOrder: true }))
  }
}

/**
 * 更改折扣
 * @param {string} itemId
 * @param {IAppBatchItem} modifier
 * @returns {ThunkFunction}
 */
export function updateSelectedItemModifier (modifier, quantity) {
  return async (dispatch, getState) => {
    await dispatch(actions.app.openLoading(loadingKey.ITEM))
    try {
      const selectedOrder = getHistoryOrder()
      const selectedOrderId = getState().orderHistory.selectedOrderId
      const selectedItems = getState().orderHistory.selectedOrderItems
      const updatedQuantity = { ...quantity }
      const filteredItems = _.flatMapDeep(_.filter(selectedItems, item => quantity[item.id]), item => {
        const expand = modifier.type === 'MERCHANT' || (modifier.type === 'DISCOUNT' && modifier.amount === 0)
        if (_.isEmpty(item.setItems) || !expand) {
          return item
        }
        const setItems = _.map(item.setItems, setItem => {
          updatedQuantity[setItem.id] = setItem.quantity
          return { ...setItem, setKey: item.id }
        })
        return [item, setItems]
      })

      if (!_.isEmpty(filteredItems)) {
        const promises = _.flatMap(filteredItems, item => {
          const itemModifier = { ...modifier }
          const selectedModifiers = _.cloneDeep(item?.modifiers)
          const index = _.findIndex(selectedModifiers, ['type', itemModifier.type])
          const optionsTotal = _.sumBy(item.options, option => option.price * option.quantity)
          if (itemModifier.type === 'MERCHANT') {
            itemModifier.amount = item.setKey
              ? (item.price + optionsTotal) * -1
              : parseFloat((itemModifier.amount - (item.price + optionsTotal)).toFixed(1))
          } else {
            itemModifier.amount = Math.min(Math.abs(itemModifier.amount), Math.abs(item.price + optionsTotal)) * -1
          }
          if (index >= 0) {
            selectedModifiers[index] = itemModifier
          } else {
            selectedModifiers.push(itemModifier)
          }
          const modifiers = []
          _.sortBy(selectedModifiers, ['type']).reverse().forEach(selectedModifier => {
            if (selectedModifier.amount !== 0 || selectedModifier.percent !== 0) {
              modifiers.push(selectedModifier)
            }
          })
          if (selectedOrder.deliveryType === 'table') {
            return dispatch({
              type: ActionTypes.UPDATE_SELECTED_ITEM_MODIFIER,
              payload: { selectedOrderId, itemId: item.id, modifiers, quantity: updatedQuantity[item.id], setKey: item.setKey },
            })
          } else {
            const itemIds = _.take(item.itemIds, updatedQuantity[item.id])
            return _.map(itemIds, id => dimorderApi.order.updateItemModifiers(selectedOrderId, id, modifiers))
          }
        })
        await Promise.all(promises)
        if (selectedOrder.deliveryType === 'table') {
          const localOrder = getHistoryOrder()
          dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
          orderEvent.emitter.emit(orderEvent.eventType.ORDER_UPDATE_ITEM_MODIFIER, localOrder, selectedItems, updatedQuantity, modifier)
        }
      }
    } catch (error) {
      console.log('updateSelectedItemModifier error', error)
    } finally {
      dispatch(actions.orderHistory.resetSelectedOrderItems())
      dispatch(actions.app.closeLoading(loadingKey.ITEM))
    }
  }
}

/**
 * 起菜選擇的餐點
 * @returns {ThunkFunction}
 */
export function serveUpSelectedItems (order, items) {
  return async (dispatch, getState) => {
    const selectedItems = getState().orderHistory.selectedOrderItems
    const { enablePrintStaff: printStaff } = getState().merchant.data?.setting ?? {}

    await dispatch({
      type: ActionTypes.SELECTED_ITEMS_ADD_TAG,
      payload: {
        selectedOrderId: order.id,
        items,
        selectedItems,
        newTag: { id: 'serve-up', name: i18n.t('app.constant.orderAction.serveUp') },
      },
    })

    const printConfig = {
      serveUp: true,
      printStaff: printStaff,
    }

    const printItems = selectedItems.filter(i => _.get(items, i.id))
    const groupedBatchItems = _.groupBy(printItems, 'batchId')
    _.each(groupedBatchItems, (groupedBatchItem, key) => {
      const batch = order.batches.find(batche => batche.id === key)
      dispatch(actions.printer.printKitchenBatch(order, groupedBatchItem, printConfig, false, batch, items))
    })
    dispatch(resetSelectedOrderItems())
    const localOrder = getHistoryOrder()
    dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
  }
}

export function selectedItemAddTag (order, items, tag) {
  return async (dispatch, getState) => {
    const selectedItems = getState().orderHistory.selectedOrderItems
    await dispatch({
      type: ActionTypes.SELECTED_ITEMS_ADD_TAG,
      payload: {
        selectedOrderId: order.id,
        items,
        selectedItems,
        newTag: tag,
      },
    })
    dispatch(resetSelectedOrderItems())
    const localOrder = getHistoryOrder()
    dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
  }
}

/**
 * 取消選擇的餐點
 * @returns {ThunkFunction}
 */
export function cancelSelectedItem (text, quantity) {
  return async (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const selectedItems = getState().orderHistory.selectedOrderItems
    const account = getState().auth.userLogin.account
    const approver = getState().auth.approver
    const { printStaff } = getState().app.settings
    const order = getHistoryOrder()
    selectedItems.forEach(async (item) => {
      const currentQuantity = quantity[item.id]
      await dispatch({
        type: ActionTypes.CANCEL_SELECTED_ITEMS,
        payload: { selectedOrderId, selectedItem: item, reason: text, account, quantity: currentQuantity, approver },
      })
    })
    const printConfig = {
      reprint: false,
      cancel: true,
      printStaff: printStaff,
    }
    const groupedBatchItems = _.groupBy(selectedItems, 'batchId')
    _.each(groupedBatchItems, (groupedBatchItem, key) => {
      const batch = order.batches.find(batche => batche.id === key)
      dispatch(actions.printer.printKitchenBatch(order, groupedBatchItem, printConfig, false, batch, quantity,
      ))
    })
    const localOrder = getHistoryOrder()
    orderEvent.emitter.emit(orderEvent.eventType.ORDER_ITEM_CANCELLED, localOrder, selectedItems)
    dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
  }
}

/**
 * 還原選擇的已取消餐點
 * @returns {ThunkFunction}
 */
export function revealSelectedItem () {
  return async (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const selectedItems = getState().orderHistory.selectedOrderItems
    const itemIds = selectedItems.map((item) => item.id)

    const promises = itemIds.map((itemId) => dimorderApi.order.revealOrderItem(selectedOrderId, itemId))
    await Promise.all(promises)
    const newOrders = await dimorderApi.order.getOrders({ id: selectedOrderId })
    if (newOrders?.[0]) {
      dispatch(updateOrder(newOrders[0], { selectOrder: true, syncOrder: true }))
    }
  }
}

// * OrderHistory Filter operator
/**
 * 清除選擇的 batch item
 * @returns {ThunkFunction}
 */
export function resetSelectedOrderItems () {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_SELECTED_ORDER_ITEMS,
    })
  }
}

/**
 * 套用篩選
 * @returns {ThunkFunction}
 */
export function applyFilter (filter) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTER,
      payload: { filter },
    })
  }
}

/**
 * 清除清除篩選
 * @returns {ThunkFunction}
 */
export function resetFilter () {
  return (dispatch, getState) => {
    console.log('resetFiler', initialFilter)
    dispatch({
      type: ActionTypes.UPDATE_FILTER,
      payload: { filter: initialFilter },
    })
  }
}

/**
 * 更改搜尋字串
 * @returns {ThunkFunction}
 */
export function updateQuery (query) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_QUERY,
      payload: { query },
    })
  }
}
/**
 * 確認全部訂單
 * @returns {ThunkFunction}
 */
export function confirmAllBatch (order) {
  return (dispatch, getState) => {
    order?.batches.forEach(async (batch) => {
      if (batch.status === 'submitted' || order.deliveryType !== 'table') {
        await dispatch(confirmBatch(order, batch))
      }
    })
  }
}

/**
 * 確認訂單
 * @param {IAppOrder} order
 * @param {IAppOrderBatch} batch
 * @param {boolean} [isAutoConfirm=false]
 * @param {string} [triggeredBy]
 * @returns {ThunkFunction}
 */
export function confirmBatch (order, batch, isAutoConfirm = false, triggeredBy, retryCount = 0) {
  return async (dispatch, getState) => {
    const isMaster = getState().app.settings.isMaster
    const { payFirst, enablePrintStaff: printStaff } = getState().merchant.data?.setting ?? {}
    const printConfig = { printStaff }

    if (isAutoConfirm && !isMaster) {
      // 僅有主機能進行訂單自動確認
      return
    }

    try {
      if (isAutoConfirm) {
        logger.log(`[orderHistory/confirmBatch] Start auto confirm ${order.serial}, triggeredBy: ${triggeredBy}`, { order: convertToSimpleOrder(order), batch, triggeredBy })
      }

      const hasPriceUndeterminedItem = batch.items.some(item => item.priceUndetermined)
      if (hasPriceUndeterminedItem) {
        dispatch(actions.orderHistory.addUnconfirmedOrder(order.serial))
        return
      } // 若有要先設定時價項目，則不能 confirm batch

      const { sdeliveryAutoPrintKitchenReceipt: enableSdeliveryAutoPrintKitchenReceipt } = getState().merchant.data?.setting
      const isStoreDeliveryAutoPrintKitchenReceipt = enableSdeliveryAutoPrintKitchenReceipt && order.deliveryType === 'storeDelivery'

      if (batch.isWaiter) {
        await dispatch(actions.printer.printKitchenBatch(order, batch.items, printConfig, isAutoConfirm, batch))
      } else if (isStoreDeliveryAutoPrintKitchenReceipt || order.deliveryType !== 'storeDelivery') {
        if (payFirst && order.deliveryType === 'table' && order.status !== 'paid') {
          // 內用先付款且尚未付款的不列印廚房單
        } else {
          await dispatch(actions.printer.printKitchenBatch(order, batch.items, printConfig, isAutoConfirm, batch))
        }
      }

      await dispatch({
        type: ActionTypes.CONFIRM_BATCH,
        payload: { orderId: order.id, batch },
      })

      const orderUpdated = getHistoryOrder(order.id)
      const calculatedOrder = await dispatch(updateOrder(orderUpdated, { selectOrder: false, syncOrder: true }))
      if (isAutoConfirm) {
        logger.log(`[orderHistory/confirmBatch] confirm ${order.serial} batch success, calculatedOrder:`, { order: convertToSimpleOrder(calculatedOrder) })
      }

      // 即時打印收據
      const invoiceSettings = getState().printer.printerSetting.invoiceSettings
      const defaultSettings = getState().printer.printerSetting.defaultSettings

      const enableSyncReceipt = invoiceSettings.length > 0
        ? invoiceSettings.some(o => o.syncReceipt)
        : defaultSettings.syncReceipt

      const enableSyncTakeawayReceipt = invoiceSettings.length > 0
        ? invoiceSettings.some(o => o.syncTakeawayReceipt)
        : defaultSettings.syncTakeawayReceipt

      const isSyncReceipt = (enableSyncReceipt && !order.takeaway) || (enableSyncTakeawayReceipt && order.takeaway)

      if (isSyncReceipt) {
        dispatch(actions.printer.printOrderReceipt({
          order: calculatedOrder,
          sync: true,
          printReason: PrintReason.ORDER_RECEIPT.CONFIRM_BATCH,
          printConfig: { reprint: false },
        }))
      }

      dispatch(actions.orderHistory.removeUnconfirmedOrder(calculatedOrder.serial))
    } catch (error) {
      logger.error(`[orderHistory/confirmBatch] retry:${retryCount}times error: ${error?.message || error?.toString()}`, { error, order: convertToSimpleOrder(order) })
      dispatch(updateOrder(order, { selectOrder: false, syncOrder: false }))
      if (retryCount < 5) {
        dispatch(confirmBatch(order, batch, isAutoConfirm, 'error catch', ++retryCount))
      } else {
        dispatch(actions.app.showAlert({
          title: i18n.t('app.alert.confirmBatchAlert.title'),
          message: i18n.t('app.alert.confirmBatchAlert.message', { error: error }),
        }))
      }
    } finally {
      await dispatch(actions.app.closeLoading(loadingKey.PRINT, 'batchList-confirm-batch'))
    }
  }
}
/**
 * 取消訂單
 * @returns {ThunkFunction}
 */
export function cancelBatch (order, batch) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.CANCEL_BATCH,
      payload: { orderId: order.id, batchId: batch.id },
    })
    const updatedOrder = getHistoryOrder()
    dispatch(updateOrder(updatedOrder, { selectOrder: true, syncOrder: true }))
  }
}

/**
 * 選擇舊訂單
 */
export function setSearchOrder (order) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SEARCH_ORDER,
      payload: { order },
    })
  }
}

/**
 * @param {string} orderId
 * @param {string} itemsId
 * @param {boolean} value
 * @returns {ThunkFunction}
 */
export function updateSurcharge (orderId, value, quantity) {
  return async (dispatch, getState) => {
    await dispatch(actions.app.openLoading(loadingKey.ITEM))
    try {
      const selectedItems = getState().orderHistory.selectedOrderItems
      const items = _.filter(selectedItems, item => quantity[item.id])

      const promises = _.flatMap(items, item => {
        const returnValue = []
        if (item.isSet) {
          returnValue.push(dispatch({
            type: ActionTypes.UPDATE_SET_ITEM_SURCHARGE,
            payload: { selectedOrderId: orderId, setId: item.id, value },
          }))
        }
        returnValue.push(dispatch({
          type: ActionTypes.UPDATE_ITEM_SURCHARGE,
          payload: { selectedOrderId: orderId, itemId: item.id, value, quantity: quantity[item.id] },
        }))
        return returnValue
      })
      await Promise.all(promises)
      const updatedOrder = getHistoryOrder()
      dispatch(updateOrder(updatedOrder, { selectOrder: false, syncOrder: true }))
      orderEvent.emitter.emit(orderEvent.eventType.ORDER_UPDATE_ITEM_SURCHARGE, updatedOrder, selectedItems, quantity, value)
    } catch (error) {
      console.log('updateSurcharge error', error)
    } finally {
      dispatch(actions.orderHistory.resetSelectedOrderItems())
      dispatch(actions.app.closeLoading(loadingKey.ITEM))
    }
  }
}

/**
 * 更新未確認的 order
 * @param {string} serial
 * @returns {ThunkFunction}
 */
export function addUnconfirmedOrder (serial) {
  return (dispatch, getState) => {
    const { unconfirmedOrders } = getState().orderHistory
    if (unconfirmedOrders.includes(serial)) return
    dispatch({
      type: ActionTypes.ADD_UNCONFIRMED_ORDER,
      payload: { serial },
    })
  }
}

/**
 * 檢查是否需要從未確認訂單中移除 serial
 * @param {string} serial
 * @returns {ThunkFunction}
 */
export function removeUnconfirmedOrder (serial) {
  return (dispatch, getState) => {
    const unconfirmedOrders = getState().orderHistory.unconfirmedOrders
    const historyOrders = getState().orderHistory.orders

    const order = historyOrders.find(order => order.serial === serial)
    const isUnconfirmedOrder = unconfirmedOrders.includes(serial)

    // 原本就不是未確認訂單，不需處理
    if (!isUnconfirmedOrder) return

    if (
      !order || // 在 orderHistory 找不到訂單，不可能被確認，直接移除
      order.status === 'cancelled' || // 訂單已取消，直接移除
      (order.deliveryType !== 'table' && order.takeawayStatus !== 'pending') || // 非堂食訂單且非待接單狀態，直接移除
      !_.find(order.batches, { status: 'submitted' }) // 所有 batch 都不是 submitted 狀態，移除未確認
    ) {
      dispatch({
        type: ActionTypes.REMOVE_UNCONFIRMED_ORDER,
        payload: { serial },
      })
    }
  }
}

/**
 * 更新要求付款的 order
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function updateRequestPayingOrder (order) {
  return (dispatch, getState) => {
    const prevOrder = getState().orderHistory.orders.find(prevOrder => prevOrder.id === order.id)
    if (!prevOrder) return

    const isPaying = order.displayStatusKey === 'waiting_pay' && order.paying

    if (isPaying !== prevOrder.paying) {
      // 當 paying 有更新時，需要更新 order.paying
      dispatch({
        type: ActionTypes.UPDATE_ORDER_PAYING,
        payload: { id: order.id, isPaying },
      })
    }
  }
}

/**
 * 更新叫侍應的 order
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function updateRequestWaiterOrder (order) {
  return (dispatch, getState) => {
    const isExpired = moment().diff(order.createdAt, 'day') >= 1
    const isRequesting = order.serviced && order.status !== 'paid' && order.status !== 'cancelled' && !isExpired

    dispatch(updateOrderRequestWaiter(order.id, isRequesting))
  }
}

/**
 * 更新 order 叫侍應 flag
 * @param {string} orderId
 * @param {boolean} isRequesting
 * @returns {ThunkFunction}
 */
export function updateOrderRequestWaiter (orderId, isRequesting) {
  return (dispatch, getState) => {
    const prevOrder = getState().orderHistory.orders.find(prevOrder => prevOrder.id === orderId)
    if (!prevOrder) return

    // 需要更改訂單的 serviced 才改
    if (isRequesting !== prevOrder.serviced) {
      dispatch({
        type: ActionTypes.UPDATE_ORDER_SERVICED,
        payload: { orderId, isRequesting },
      })

      const orderUpdated = getState().orderHistory.orders.find(prevOrder => prevOrder.id === orderId)
      dispatch(updateOrder(orderUpdated, { selectOrder: false, syncOrder: true }))
    }
  }
}

/**
 * 用在結帳後將所有找零轉為小費
 * @param {string} paymentId
 * @returns {ThunkFunction}
 */
export function allChangeToTips (paymentId) {
  return (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const orders = getState().orderHistory.orders
    const selectedOrder = orders.find(o => o.id === selectedOrderId)
    const selectedPayment = selectedOrder.payments.find(payment => payment.id === paymentId)

    const updatePayment = {
      change: 0,
      paymentTips: selectedPayment.change,
    }

    dispatch(updateOrderPayment(selectedOrder.id, paymentId, updatePayment))
  }
}

/**
 * 根據 id 更改已送出的 payment tips
 * @param {string} paymentId
 * @param {number} tips
 * @returns {ThunkFunction}
 */
export function updatePaymentTips (paymentId, tips) {
  return (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const historyOrders = getState().orderHistory.orders
    const selectedOrder = historyOrders.find(o => o.id === selectedOrderId)
    const selectedPayment = selectedOrder.payments.find(payment => payment.id === paymentId)

    // 要增加的小費（可能會是負的）
    const addTips = Number(tips) - Number(selectedPayment.paymentTips)
    const updatePayment = {
      paidAmount: selectedPayment.paidAmount + addTips,
      paymentTips: selectedPayment.paymentTips + addTips,
    }

    dispatch(updateOrderPayment(selectedOrder.id, paymentId, updatePayment))
  }
}

/**
 * 作廢 payment
 * @param {string} orderId
 * @param {IAppPayment} payment
 * @param {string} reason
 * @returns {ThunkFunction}
 */
export function voidOrderPayment (orderId, payment, reason = '') {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.VOID_PAYMENT,
      payload: {
        orderId,
        paymentId: payment.id,
        reason,
      },
    })
    const localOrder = getHistoryOrder()
    dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
    orderEvent.emitter.emit(orderEvent.eventType.ORDER_DELETE_PAYMENT, localOrder, payment, reason)
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateOrderPayment (orderId, paymentId, data) {
  return async (dispatch, getState) => {
    // 更改 payment
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT,
      payload: { orderId, paymentId, data },
    })
    const localOrder = getHistoryOrder()
    dispatch(updateOrder(localOrder, { selectOrder: true, syncOrder: true }))
    orderEvent.emitter.emit(orderEvent.eventType.ORDER_UPDATE_PAYMENT, localOrder, paymentId, data)
  }
}

/**
 * @param {string} orderId
 * @param {IAppPayment} payment
 * @returns {ThunkFunction}
 */
export function addOrderPayment (orderId, payment) {
  return (dispatch, getState) => {
    logger.log('[addOrderPayment] start', { orderId, payment })
    dispatch({
      type: ActionTypes.ADD_PAYMENT,
      payload: { orderId, payment },
    })

    // 找出被修改的訂單
    const updatedOrder = getState().orderHistory.orders.find(order => order.id === orderId)

    if (!updatedOrder) {
      // 找不到訂單
      return
    }

    logger.log('[addOrderPayment] result', { updatedOrder })

    // 更新到後端
    dispatch(updateOrder(updatedOrder, { selectOrder: false, syncOrder: true }))

    if (updatedOrder.status !== 'paid') {
      // 加入 payment 後訂單還沒變成已付款，因此後面不用處理，直接 return
      return
    }

    // 處理訂單變成已付款後要做的事
    orderEvent.emitter.emit(orderEvent.eventType.ORDER_PAID, updatedOrder)
    if (updatedOrder.takeawayStatus === 'completed' && updatedOrder.deliveryType === 'storeDelivery') {
      // 外送完成的訂單付款後自動改為送達
      dispatch(actions.orderHistory.deliverOrder(updatedOrder.id))
    }

    const quickMode = getState().merchant.data?.setting?.quickMode
    if (quickMode && updatedOrder.deliveryType === 'takeaway') {
      // 自動完成快餐模式的自取單
      dispatch(actions.orderHistory.completeOrder(updatedOrder, updatedOrder.code))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateCustomerCount (orderId, adults, children) {
  return async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.UPDATE_CUSTOMER_COUNT,
      payload: { selectedOrderId: orderId, adults, children },
    })
    const localOrder = getHistoryOrder()
    dispatch(updateOrder(localOrder, { selectOrder: false, syncOrder: true }))
    orderEvent.emitter.emit(orderEvent.eventType.ORDER_UPDATE_CUSTOMER_COUNT, localOrder, { adults, children })
  }
}

/**
 * 併單，將 mergeOrder 併入 mainOrder，mergeOrder 會被取消
 * @param {string} mainOrderId
 * @param {string} mergeOrderId
 * @returns {ThunkFunction}
 */
export function mergeOrder (mainOrderId, mergeOrderId) {
  return async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.MERGE_ORDER,
      payload: { mainOrderId, mergeOrderId },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updatePrinted (orderId, batchId, itemId, printError, printed) {
  return async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.UPDATE_PRINTED,
      payload: { orderId, batchId, itemId, printError, printed },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function completeAllTakeaway () {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.ORDER))
      const historyOrders = getState().orderHistory.orders
      const waitingOrders = _.filter(historyOrders, order => {
        return order.deliveryType === 'takeaway' && order.from === 'MERCHANT' && order.displayStatusKey === 'waiting_pick_up'
      })
      const updatedOrders = []
      await Promise.all(_.map(waitingOrders, async order => {
        await dispatch({
          type: ActionTypes.COMPLETE_ORDER,
          payload: { orderId: order.id },
        })
        const historyOrders = getState().orderHistory.orders
        const localOrder = historyOrders.find(o => o.id === order.id)
        updatedOrders.push(localOrder)
      }))
      if (!_.isEmpty(updatedOrders)) {
        dispatch(updateOrders(updatedOrders, { syncOrder: true, overwrite: false }))
      }
    } catch (error) {
      console.log('completeAllTakeaway error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.ORDER))
    }
  }
}

/**
 * 設定 order.version += 1
 * @param {string[]} orderIds
 * @returns {ThunkFunction}
 */
export function updateOrderSync (orderIds) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ORDERS_SYNC,
      payload: { orderIds },
    })
  }
}

/**
 * 使用 MR 結帳時需要先刪除 CAPP 使用的 promocode 和 coupon
 * @returns {ThunkFunction}
 */
export function deleteCustomerPromoCodes (orderId) {
  return async (dispatch, getState) => {
    const order = getState().orderHistory.orders.find(order => order.id === orderId)

    const deleteTypes = ['PROMOCODE', 'COUPON']
    const deletedAt = moment().utc().toISOString()
    const updatedOrder = produce(order, draft => {
      draft.modifiers.forEach(modifier => {
        if (deleteTypes.includes(modifier.type)) {
          modifier.deletedAt = deletedAt
        }
      })
    })

    // 更新並計算訂單
    dispatch(updateOrder(updatedOrder, {
      syncOrder: true,
    }))
  }
}

/**
 * @param {string} orderId
 * @param {IAppPayment} selectedPayment
 * @param {string} reason
 * @returns {ThunkFunction}
 */
export function voidCardPayment (orderId, selectedPayment, reason, manualRefundRef, cancelRefundRef, onClose = null) {
  return async (dispatch, getState) => {
    const emitterName = voidCardPayment.name
    const isCardPayment = Boolean(paymentConfigs.cardProviderMaps[selectedPayment.paymentMethod])
    const onSuccess = () => {
      dispatch(actions.orderHistory.voidOrderPayment(orderId, selectedPayment, reason))
      dispatch(actions.app.showAlert({
        message: i18n.t('app.page.checkout.paymentResult.TransactionSuccess'),
      }))
    }

    const onError = (ErrorType = 'fail', rspCode = '') => {
      logger.log('[Payment] Error', { ErrorType: ErrorType, rspCode: rspCode })
      if (i18n.exists(`app.page.checkout.paymentResult.${ErrorType}`)) {
        dispatch(actions.app.showAlert({
          title: i18n.t('app.page.checkout.paymentResult.fail'),
          message: i18n.t(`app.page.checkout.paymentResult.${ErrorType}`, { error_code: rspCode }),
        }))
      } else {
        dispatch(actions.app.showAlert({
          title: i18n.t('app.page.checkout.paymentResult.fail'),
          message: `${i18n.t('app.page.checkout.paymentResult.fail')} ( ${ErrorType} )`,
        }))
      }
    }

    dispatch(actions.app.showDialog(['ePayment']))
    let paymentResult = null
    switch (selectedPayment.gateway) {
      case ecrGateway.GLOBAL_PAYMENT: {
        const globalPayment = new GlobalPayment()
        globalPayment.checkLineStatus(emitterName)
        let intervalID = null
        const result = await new Promise((resolve, reject) => {
          globalPayment.emitter.on(emitterName, async (success) => {
            if (success) {
              const amt = selectedPayment.amount
              const msgId = uuid().replace(/-/gi, '')
              const multipliedAmt = (amt * 100).toFixed(0)
              const payload = JSON.parse(selectedPayment.payload)
              if (selectedPayment.paymentMethod === 'payme') {
                globalPayment.paymeRefund(payload.orderNum, msgId, multipliedAmt.padStart(12, '0'), emitterName)
                  .then((response) => { resolve(response) })
              } else {
                globalPayment.void(payload.traceNo, emitterName)
                  .then((response) => { resolve(response) })
              }
            } else {
              dispatch(onError('failToConnectionCardCenter'))
            }
          })
          // Exit promise if click timeout dialog
          intervalID = setInterval(() => {
            // leave Promise
            if (manualRefundRef.current || cancelRefundRef.current) resolve('')
          }, 1000)
        })
        // Payment request finished
        clearInterval(intervalID)
        paymentResult = result
        break
      }
      case ecrGateway.EFT_PAY: {
        const eftpay = new EftPay()
        const payload = JSON.parse(selectedPayment.payload)

        let intervalID = null
        const result = await new Promise((resolve, reject) => {
          if (selectedPayment.paymentMethod === paymentMethods.PAY_ME) {
            eftpay.refund(payload)
              .then((response) => { resolve(response) })
          } else {
            eftpay.void(payload.INVOICE)
              .then((response) => { resolve(response) })
          }
          // Exit promise if click timeout dialog
          intervalID = setInterval(() => {
            // leave Promise
            if (manualRefundRef.current || cancelRefundRef.current) resolve('')
          }, 1000)
        })
        // Payment request finished
        clearInterval(intervalID)
        paymentResult = result
        break
      }
      case ecrGateway.BBMSL: {
        const bbMSL = new BBMSL()
        const payload = JSON.parse(selectedPayment.payload)
        const transactionId = String(payload.receiptData.txnid)
        let intervalID = null
        const result = await new Promise((resolve, reject) => {
          bbMSL.void(transactionId)
            .then((response) => { resolve(response) })

          // Exit promise if click timeout dialog
          intervalID = setInterval(() => {
            // leave Promise
            if (manualRefundRef.current || cancelRefundRef.current) resolve('')
          }, 1000)
        })
        // Payment request finished
        clearInterval(intervalID)
        paymentResult = result
        break
      }
    }
    if (onClose) { onClose() }
    dispatch(actions.app.closeDialog(['ePayment']))
    // 卡機成功退款 / 餐廳選擇手動確認卡機退款
    if (paymentResult?.success || manualRefundRef.current) {
      onSuccess()
    } else {
      if (!(manualRefundRef.current || cancelRefundRef.current)) {
        dispatch(actions.orderCheckout.creditCardPaymentErrorHandler(paymentResult?.error))
      }
    }
  }
}

// *** [ Local Database ]
const localDBLazyQueueInterval = 100 // ms

const localDBWriteLazyQueue = queue(async (task, callback) => {
  const { orderId } = task
  // * [ Task 開始運行 ]
  // console.warn('~~~~~~ TEST QUEUE Start Process ', `ts(s): ${(performance.now() / 1000).toFixed(3)}`)

  // lazy delay
  await delay(localDBLazyQueueInterval)

  // 在 redux 找尋該訂單塞進 localDB
  const order = getState().orderHistory.orders.find(order => order.id === orderId)
  // console.warn('~~~~~~ TEST QUEUE getState: ', 'Found order: ', order?.id, ' serial: ', order?.serial)
  if (order) OrderLocalDatabase.upsertOrder(order)
  else {
    // console.warn(`[localDBWriteLazyQueue] order not found in redux, orderId:${orderId}, skip write into localDatabase`)
  }
  callback(null, task)
}, 1)

// 用作儲存等候上載到 Local Database 的訂單
const localDBLazyQueueOrderIds = []
/**
 * @returns {ThunkFunction}
 */
export function addLocalDBLazyQueue (orderId) {
  return async (dispatch, getState) => {
    // 檢查防止 Repeat
    if (localDBLazyQueueOrderIds.includes(orderId)) {
      return
    }

    // 把要存到DB的訂單加到 Global variable 暫時作記錄
    localDBLazyQueueOrderIds.push(orderId)

    dispatch(actions.app.throttledLazyQueuingNumber(localDBLazyQueueOrderIds.length))
    // 加入到 queue 執行
    /* eslint-disable handle-callback-err */
    localDBWriteLazyQueue.push({ orderId }, (err, task) => {
      // Callback 運行
      // console.warn('~~~~~~ [localDB] Lazy QUEUE Complete CallBack: ', task)
      const removeIndex = localDBLazyQueueOrderIds.indexOf(orderId)
      if (removeIndex !== -1) {
        localDBLazyQueueOrderIds.splice(removeIndex, 1)
      }
      // console.warn(`~~~~~~ [localDB] Lazy QUEUE Complete CallBack: removeLocalDBLazyQueueRecord, localDBLazyQueueOrderIds:(${localDBLazyQueueOrderIds.length}) `)
      dispatch(actions.app.throttledLazyQueuingNumber(localDBLazyQueueOrderIds.length))
    })
  }
}

/**
 * 當訂單更新時，檢查是否需要跳出 alert 提示訂單被更新
 * @param {IAppOrder} order
 * @param {string} prevStatus
 * @param {number} prevRoundedTotal
 * @returns {ThunkFunction}
 */
export function alertOrderChange (order, prevStatus, prevRoundedTotal) {
  return async (dispatch, getState) => {
    const checkoutOrderId = getState().orderCheckout.orderId
    if (checkoutOrderId === order.id) {
      if (prevStatus !== order.status && (order.status === 'paid' || order.status === 'cancelled')) {
      // 訂單變成已付款：提示此訂單已結帳/取消，顯示返回按鈕
        const messages = {
          paid: i18n.t('app.page.checkout.orderWasPaid'),
          cancelled: i18n.t('app.page.checkout.orderCancelled'),
        }
        dispatch(actions.app.showAlert({
          message: messages[order.status],
          buttons: [
            {
              children: i18n.t('app.common.back'),
              onPress: () => {
                dispatch(actions.orderCheckout.cleanCheckOutOrder())
                window.applicatiionHistory.replace('/orderHistory')
              },
            },
          ],
        }, 'checkout-order-complete'))
        return
      }
      if (order.roundedTotal !== prevRoundedTotal) {
      // 還沒付款，且訂單價格更新了：提示訂單狀態已更改，顯示更新帳單按鈕
      // 重設 orderCheckout
        dispatch(actions.orderCheckout.checkoutOrder(order.id))
        dispatch(actions.app.showAlert({
          modalProps: { enablePressOutsideClose: false, noCloseButton: true },
          message: i18n.t('app.page.checkout.orderWasChanged'),
        }, 'checkout-order-updated'))
      }
    }
  }
}

/**
 * 餐廳完成訂單，通知客人取餐
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function sendPickupNotify (orderId) {
  return async (dispatch, getState) => {
    try {
      const updatedOrder = await dimorderApi.order.sendPickupNotify(orderId)
      dispatch(updateOrder(updatedOrder, { selectOrder: false, syncOrder: false }))
    } catch (error) {
      console.log('sendPickupNotify error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.PICKUP_NOTIFY))
    }
  }
}

export function updateOrderOwner (orderId, { phone = '', name = '' }) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ORDER_OWNER,
      payload: { orderId, phone, name },
    })

    const updatedOrder = getState().orderHistory.orders.find(order => order.id === orderId)

    dispatch(updateOrder(updatedOrder))
  }
}
