import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import Blink from '@/components/Blink'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import OrderList from '../../OrderList'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { ITable } from '@/redux/table/Table.d'
/* eslint-enable no-unused-vars */

/**
 * @typedef TableProps
 * @property {ITable} table
 * @property {(table: ITable) => void} [onSelectTable]
 * @property {(order: IAppOrder) => void} onSelectOrder
 * @property {boolean} [disableOrders=false]
 * @property {boolean} [requestWaiter=false]
 * @property {ViewProps['style']} [style]
 * @property {ViewProps['style']} [infoContainerStyle]
 * @property {ViewProps['style']} [orderCountStyle]
 */

/**
 *
 * @param {TableProps} props
 * @returns
 */
export default function Table (props) {
  const {
    table,
    onSelectTable = () => {},
    onSelectOrder,
    disableOrders = false, // order頁面不顯示訂單
    requestWaiter = false,
    style,
    infoContainerStyle,
    orderCountStyle,
  } = props

  const { t } = useTranslation()
  const fromText = t('app.page.table.tableArea.table.from')
  const toText = t('app.page.table.tableArea.table.to')
  const orderText = t('app.page.table.tableArea.table.order')
  const tableText = t('app.page.table.tableArea.table.tableNo')
  const moveText = t('app.page.table.tableArea.table.move')
  const confirmText = t('app.common.confirm')
  const cancelText = t('app.common.cancel')

  const dispatch = useDispatch()
  const { isSelecting, selectedOrders, isMoving, selectedTableId, isSelectingTable } = useSelector(state => state.table)
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const [isExpanded, setIsExpanded] = React.useState(false)

  const orderIds = requestWaiter ? table.requestWaiterOrderIds : table.orderIds
  const orders = React.useMemo(() => {
    return _.filter(historyOrders, order => _.includes(orderIds, order.id))
  }, [historyOrders, orderIds])

  const customerCount = React.useMemo(() => {
    return _.reduce(orders, (acc, order) => acc + order.adults + order.children, 0)
  }, [orders])

  const pendingOrdersCount = React.useMemo(() => {
    return _.filter(orders, order => order.status === 'pending').length
  }, [orders])

  const isDisable = disableOrders && customerCount >= table.maxCustomer
  const isDisabled = selectedTableId && table.id !== selectedTableId

  const onSelectTableThrottle = React.useCallback(
    _.throttle(onSelectTable, 2000, { trailing: false }),
    [onSelectTable],
  )

  let blinkDuration = 500
  const displayOverdueWarning = _.some(orders, order => {
    if (order.status === 'paid') return false
    if (order.orderCutOffMins) {
      const overdueTime = moment(order.createdAt).add(order.orderCutOffMins, 'minute')
      const isAlmostOverdue = moment().isAfter(moment(overdueTime).subtract(5, 'minute'))
      const isOverdue = moment().isAfter(overdueTime)
      if (isOverdue) {
        blinkDuration = 200
      }
      return (isAlmostOverdue || isOverdue) && order.status === 'pending' && order.deliveryType === 'table'
    }
  })

  React.useEffect(() => {
    // Close the list when it is not selecting
    if (!isSelecting && !onSelectOrder) {
      setIsExpanded(false)
      return
    }

    // Close the list when the selected is not this table
    if (isSelecting && table.id !== selectedTableId) {
      setIsExpanded(false)
    }

    // If there is only one order in the table, set isExpanded alaways to be true
    if (pendingOrdersCount === 1) {
      setIsExpanded(true)
    }
  }, [isSelecting, orders, selectedTableId])

  const handleTablePressed = () => {
    if (!disableOrders) {
      // Move the selected orders to this table
      if (isMoving) {
        dispatch(actions.app.showAlert({
          title: moveText,
          message: `${fromText}${orderText}${selectedOrders.map(s => s.serial.slice(-3)).join('、')} ${toText}${tableText} ${table.key}?`,
          buttons: [
            {
              children: confirmText,
              onPress: () => {
                dispatch(actions.table.moveOrder(table.key))
              },
            },
            {
              backgroundColor: colors.light,
              textColor: colors.textTertiary,
              children: cancelText,
              onPress: () => { },
            },
          ],
        }))
        return
      }

      if (isSelectingTable) {
        onSelectTableThrottle(table)
      }

      // Expand the list when it is selecting
      if (isSelecting || onSelectOrder) {
        dispatch(actions.table.selectTable(table.id))
        if (pendingOrdersCount === 1) {
          setIsExpanded(true)
          return
        }
        setIsExpanded(!isExpanded)
        return
      }
    }

    // Else, open the table dialog
    onSelectTableThrottle(table)
  }

  const handleSelectOrder = (order) => {
    if (isExpanded) {
      if (onSelectOrder) {
        onSelectOrder(order)
      } else {
        dispatch(actions.table.selectTable(null))
        dispatch(actions.table.selectOrder(order))
      }
    } else {
      handleTablePressed()
    }
  }

  const containerStyle = {
    zIndex: table.id === selectedTableId ? 1 : 0,
    backgroundColor: pendingOrdersCount === 0 ? colors.lightGray3 : colors.light,
    ...shadows.default,
  }
  const infoStyle = {
    backgroundColor: pendingOrdersCount === 0 ? colors.lightGray3 : customerCount < table.maxCustomer ? colors.green : colors.secondary,
  }
  const overlayStyle = {
    backgroundColor: colors.gray,
    borderRadius: 5,
    opacity: isDisable ? 0.6 : 0,
  }

  return (
    <TouchableOpacity
      style={[styles.container, containerStyle, style]}
      onPress={handleTablePressed}
      disabled={isDisable}
    >
      {/* info */}
      <View style={[infoStyle, infoContainerStyle]}>
        <Text style={styles.tableText}>{table.name}</Text>
        {
          displayOverdueWarning && (
            <Blink duration={blinkDuration}>
              <Image style={styles.iconStyle} source={require('@icons/table/overdue.png')} />
            </Blink>
          )
        }
      </View>

      {/* overlay */}
      <View style={[StyleSheet.absoluteFill, overlayStyle]} />

      {/* order list */}
      <View style={styles.orders}>
        {/* order count */}
        {pendingOrdersCount > 0 &&
          <Text style={[styles.orderCountText, orderCountStyle]}>
            {pendingOrdersCount}
          </Text>}

        <OrderList
          orders={orders}
          onSelectOrder={handleSelectOrder}
          expanded={isExpanded}
          disabled={isDisabled}
        />
      </View>

    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 8,
  },
  tableText: {
    flex: 1,
    fontSize: 22,
    fontWeight: 'bold',
    color: colors.white,
  },
  statusText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.white,
  },
  orders: {
    flex: 1,
  },
  orderCountText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.gray,
  },
  iconStyle: {
    width: 25,
    height: 25,
  },
})
