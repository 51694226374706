import { useEffect, useReducer, useRef } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { usePrevious } from '@/hooks/previous'
import logger from '@/libs/logger'

/**
 * 同步訂單
 */
export default function useSynchronizeOrders () {
  const dispatch = useDispatch()

  const { tables, tableGroups } = useSelector(state => state.merchant.data) ?? {}
  const prevTables = usePrevious(tables)
  const prevTableGroups = usePrevious(tableGroups)
  const { disableOrderSync, disableOrderHistoryStorage } = useSelector(state => state.app.settings.debugSettings)
  const isDataInit = useSelector(state => state.app.isDataInit)
  const isSyncingData = useSelector(state => state.unsyncOrder.isSyncingData)
  const unsyncOrderIds = useSelector(state => state.unsyncOrder.pendingOrderIds)
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const [syncIntervalUpdate, forceSyncIntervalUpdate] = useReducer(x => x + 1, 0)
  const syncIntervalIdRef = useRef()

  // initialize syncDataInterval
  useEffect(() => {
    // force data sync for every 55s
    syncIntervalIdRef.current = setInterval(() => {
      forceSyncIntervalUpdate()
    }, 55000)
    return () => { clearInterval(syncIntervalIdRef.current) }
  }, [])

  useEffect(() => {
    if (!isDataInit) return
    if (disableOrderSync) return
    dispatch(actions.unsyncOrder.storeUnsyncOrderIds())
  }, [isDataInit, disableOrderSync, unsyncOrderIds, isSyncingData])

  useEffect(() => {
    if (!isDataInit) return
    if (tables !== prevTables || tableGroups !== prevTableGroups) {
      // merchant 桌位設定有變時，先更新至 table store
      dispatch(actions.table.init())
    }

    // 更新 table 內的 orderIds 和 requestWaiterOrderIds
    dispatch(actions.table.mapOrdersIntoTables())
  }, [isDataInit, tables, tableGroups, historyOrders])

  useEffect(() => {
    if (!isDataInit) return
    if (isSyncingData) return // 正在上傳時不要 dispatch syncData，確保一次只有一個 syncData 執行
    if (disableOrderSync) return // Debug flag，可以暫停上傳
    dispatch(actions.unsyncOrder.syncData())
  }, [isDataInit, isSyncingData, disableOrderSync, unsyncOrderIds, syncIntervalUpdate])
}
