import { FlatList, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import React, { useRef } from 'react'

import { actions, useDispatch } from '@/redux'
import Column from '@/components/Column'
import IconButton from '@/components/buttons/IconButton'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import BookingItem from '../BookingList/BookingItem'
import OrderResultList from './OrderResultList'

const searchResultBookings = [{
  id: 'B01',
  createdAt: new Date('2020/07/13 18:00'),
  bookingAt: new Date('2020/07/14 18:00'),
  table: 'A3',
  customer: {
    name: 'Wong',
    customerNameSuffix: 'Mr.',
    phone: '******240',
  },
  adults: 3,
  children: 0,
  remark: '',
  tags: ['重要客人'],
}, {
  id: 'B02',
  createdAt: new Date('2020/07/13 18:00'),
  bookingAt: new Date('2020/07/14 18:20'),
  table: 'B6',
  customer: {
    name: 'Yan',
    customerNameSuffix: 'Mr.',
    phone: '******007',
  },
  adults: 2,
  children: 4,
  remark: '小孩餐具',
  tags: ['小孩多'],
}]

const searchResultOrders = [{
  id: '055',
  serial: '055',
  table: 'B2',
  area: '一樓',
  deliveryType: 'table',
  status: 'pending',
  creatorId: 'user12',
  createdAt: new Date('2020/11/30 17:00'),
  adults: 2,
  children: 0,
  batches: [],
  customer: {
    name: '烏龍',
    customerNameSuffix: '先生',
    memberId: '10588',
    phone: '94245240',
  },
  total: 1020,
}, {
  id: '054',
  serial: '054',
  table: 'B1',
  area: '一樓',
  deliveryType: 'table',
  status: 'paid',
  creatorId: 'user12',
  createdAt: new Date('2020/11/30 10:00'),
  adults: 2,
  children: 0,
  batches: [],
  customer: {
    name: '綠茶',
    customerNameSuffix: '',
    phone: '91234567',
  },
  total: 1020,
}]

/**
 *
 * @param {*} props
 * @returns
 */
export default function SearchResult (props) {
  const dispatch = useDispatch()
  const scrollViewRef = useRef()

  const onPressBookingResult = (id) => {
    dispatch(actions.table.showDialog(['booking']))
  }

  const renderItem = ({ item }) => {
    return (
      <BookingItem
        key={`booking-${item.id}`}
        booking={item}
        onPress={() => onPressBookingResult(item.id)}
      />
    )
  }
  const scrollToEnd = () => {
    scrollViewRef.current.scrollToEnd()
  }
  const onClose = () => {
    dispatch(actions.table.updateSearching(false))
  }

  const onPressOrderResult = (order) => {
    dispatch(actions.table.showDialog(['table']))
  }

  return (
    <View style={[StyleSheet.absoluteFill, styles.container]}>

      {/* press outside overlay */}
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={[StyleSheet.absoluteFill, styles.overlay]} />
      </TouchableWithoutFeedback>

      {/* main */}
      <Column style={styles.main}>
        {/* search result - booking */}
        <Row style={styles.booking}>
          <Row style={styles.boundary}>
            <FlatList
              ref={scrollViewRef}
              data={searchResultBookings}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              horizontal
              contentContainerStyle={{ alignItems: 'center' }}
              showsHorizontalScrollIndicator={false}
            />
          </Row>

          {/* Scroll to right button */}
          <IconButton
            iconSource={require('@icons/table/tip-right.png')}
            style={styles.moveRight}
            iconStyle={styles.moveRightIcon}
            onPress={scrollToEnd}
          />
        </Row>

        {/* divider */}
        <Row style={styles.divider}>
          <Text style={styles.text}>入座中</Text>
          <View style={styles.line} />
        </Row>

        {/* search result - order */}
        <OrderResultList
          orders={searchResultOrders}
          onPress={onPressOrderResult}
        />
      </Column>

    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    zIndex: 3,
    elevation: 3,
  },
  overlay: {
    backgroundColor: colors.black,
    opacity: 0.4,
  },
  main: {
    minHeight: 285 + 75, // cover the backgorund under Header
    width: '100%',
    backgroundColor: colors.light,
    borderBottomRightRadius: 20,
    paddingTop: 75, // cover the backgorund under Header
    paddingLeft: 30,
    paddingRight: 15,
    position: 'absolute',
    top: 0,
    ...shadows.default,
  },
  booking: {
    height: 107,
  },
  boundary: {
    flex: 1,
    overflow: 'hidden',
  },
  moveRight: {
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  moveRightIcon: {
    height: 20,
    width: 20,
  },
  divider: {
    alignItems: 'center',
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 20,
    color: colors.textPrimary,
    marginHorizontal: 6,
  },
  line: {
    flex: 1,
    height: 2,
    backgroundColor: colors.textPrimary,
  },
})
