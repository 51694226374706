import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

const complete = require('@icons/order-header/complete.png')
const chevronPrimary = require('@icons/breadcrumb-chevron-primary.png')
const chevronWhite = require('@icons/breadcrumb-chevron-white.png')

const variantConfigs = {
  category: {
    containerStyle: {
      paddingHorizontal: 16,
    },
    selectedTextStyle: {
      color: colors.darkSecondary,
    },
  },
  breadcrumb: {
    containerStyle: {
      paddingLeft: 16,
      paddingRight: 8,
    },
    selectedTextStyle: {
      color: colors.primary,
    },
  },
}

/**
 * @param {CategoryBar.CategoryButtonProps} props
 */
export default function CategoryButton (props) {
  const { variant = 'category', text, selected, isComplete, onPress, onLayout } = props

  const variantConfig = variantConfigs[variant]

  return (
    <View
      onLayout={onLayout}
    >
      <TouchableOpacity onPress={onPress}>
        <View style={[styles.container, variantConfig.containerStyle, selected && styles.selected]}>
          <Text style={[styles.text, selected && variantConfig.selectedTextStyle]}>{text}</Text>

          {variant === 'breadcrumb' && (
            <Image style={styles.chevronIcon} source={selected ? chevronPrimary : chevronWhite} />
          )}
        </View>
      </TouchableOpacity>

      {variant === 'category' && isComplete && (
        <View style={styles.completeIconView}>
          <Image style={styles.completeIcon} source={complete} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 65,
    height: 35,
  },
  selected: {
    borderRadius: 17.5,
    backgroundColor: colors.white,
  },
  text: {
    color: colors.white,
    fontSize: 15,
    fontWeight: 'bold',
  },
  chevronIcon: {
    width: 9,
    height: 14,
    marginLeft: 8,
  },
  completeIconView: {
    position: 'relative',
    top: 0,
    left: -14,
    zIndex: 1000,
  },
  completeIcon: {
    width: 16,
    height: 16,
  },
})
