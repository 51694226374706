import { FlatList, StyleSheet, View } from 'react-native'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 * @typedef WebTimePickerProps
 * @property {*} value
 * @property {*} fromDate
 * @property {*} toDate
 * @property {() => void} onChange
 */

/**
 *
 * @param {WebTimePickerProps} props
 * @returns
 */
export default function WebTimePicker (props) {
  const { value, fromDate, toDate, onChange = () => { } } = props
  const [hour, setHour] = React.useState(moment(value).hour())
  const [minute, setMinute] = React.useState(moment(value).minute())
  const [minteOptions, setMinteOptions] = React.useState([])
  const min = {
    hour: fromDate ? moment(fromDate).hour() : 0,
    minute: fromDate ? moment(fromDate).minute() : 0,
  }
  const max = {
    hour: toDate ? moment(toDate).hour() : 23,
    minute: toDate ? moment(toDate).minute() : 59,
  }
  const hourOptions = new Array(max.hour - min.hour + 1).fill(min.hour).map((h, i) => h + i)

  React.useEffect(() => {
    const index = _.findIndex(hourOptions, h => h === hour)
    const minMinute = index === 0 ? min.minute : 0
    const maxMinute = index === _.findLastIndex(options) ? max.minute : 59
    const options = new Array(maxMinute - minMinute + 1).fill(minMinute).map((m, index) => m + index)
    setMinteOptions(options)
  }, [hour])

  React.useEffect(() => {
    onChange(moment(value).hour(hour).minute(minute).startOf('minute'))
  }, [hour, minute])

  const renderMinute = ({ item, index }) => {
    const selected = minute === item
    return (
      <SettingListItem
        selected={selected}
        onPress={() => setMinute(item)}
        divider={index !== _.findLastIndex(minteOptions)}
      >
        <SettingListItemText text={item} />
      </SettingListItem>
    )
  }

  const renderHour = ({ item, index }) => {
    const selected = hour === item
    return (
      <SettingListItem
        selected={selected}
        onPress={() => setHour(item)}
        divider={index !== _.findLastIndex(hourOptions)}
      >
        <SettingListItemText text={item} />
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>
      <SettingList style={{ flex: 1 }}>
        <FlatList
          style={{ width: 150 }}
          data={hourOptions}
          renderItem={renderHour}
          keyExtractor={(item, index) => String(index)}
        />
      </SettingList>
      <SettingList style={{ flex: 1 }}>
        <FlatList
          style={{ width: 150 }}
          data={minteOptions}
          renderItem={renderMinute}
          keyExtractor={(item, index) => String(index)}
        />
      </SettingList>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 310,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
})
