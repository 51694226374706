import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import React from 'react'

// eslint-disable-next-line no-unused-vars
import { IMenu } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 *
 * @param {{
 * editingMenu: IMenu,
 * onChangeValue: (value: any) => void,
 * }} props
 * @returns
 */
export default function MenuPrinter (props) {
  const { defaultValue, onChangeValue } = props
  const { t } = useTranslation()
  const kitchenSettings = useSelector(state => state.printer.printerSetting.kitchenReceiptSettings) || []

  const printerProfileOptions = kitchenSettings.map(setting => {
    return {
      value: setting.id,
      display: setting.name,
    }
  })

  return (
    <OptionsPanel
      style={{ width: '100%', minWidth: '25%' }}
      options={printerProfileOptions}
      title={t('app.page.setting.menuPanel.printer.title')}
      defaultValue={defaultValue}
      multiple
      onChangeValue={onChangeValue}
    />
  )
}
