import _ from 'lodash'

import { dimorderApi } from '@/libs/api/dimorder'
import eachDeep from 'deepdash-es/eachDeep'
import filterDeep from 'deepdash-es/filterDeep'
import i18n from '@/i18n'
import store from '@/redux/store'

/* eslint-disable no-unused-vars */
import { IAppCategory, IAppMenuItem, IAppSet } from 'dimorder-orderapp-lib/dist/types/AppMenu'
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { ICategory } from 'dimorder-orderapp-lib/dist/types/Category'
/* eslint-enable no-unused-vars */

/** @type {() => IRootState} */
const getState = store.getState

export async function parseCategories2 (allMenu) {
  /** @type {IAppCategory[]} */

  // map translations to localeDesc and localeNames
  allMenu.optionGroupPresets = _.map(allMenu.optionGroupPresets, optionGroupPreset => {
    const localeOptionNames = {}
    _.forEach(optionGroupPreset?.translations, translation => {
      localeOptionNames[translation.locale] = translation?.name ?? optionGroupPreset.name
      if (!_.isEmpty(translation?.Options)) {
        optionGroupPreset.options = getOptionsName(optionGroupPreset.options, translation.Options, translation.locale)
      }
    })
    return {
      ...optionGroupPreset,
      localeNames: localeOptionNames,
    }
  })
  allMenu.menus = allMenu.menus.map(menu => {
    const localeMenuNames = {}
    const localeMenuDesc = {}

    _.forEach(menu?.translations, translation => {
      // api return same optionGroupPresets with options
      menu.options = _.differenceBy(menu.options, menu.optionGroupPresets, 'id')
      menu.optionGroupPresets = _.differenceBy(menu.optionGroupPresets, menu.options, 'id')
      localeMenuNames[translation.locale] = translation?.name ?? menu.name
      localeMenuDesc[translation.locale] = translation?.desc ?? menu.desc
      _.forEach(menu.optionGroupPresets, optionGroupPreset => {
        const allOptionGroupIndex = _.findIndex(allMenu.optionGroupPresets, all => all.id === optionGroupPreset.id)
        if (allOptionGroupIndex > -1) {
          optionGroupPreset.localeNames = allMenu.optionGroupPresets[allOptionGroupIndex].localeNames
          const allOptions = allMenu.optionGroupPresets[allOptionGroupIndex].options
          _.forEach(optionGroupPreset.options, option => {
            const allOptionIndex = _.findIndex(allOptions, a => a.id === option.id)
            if (allOptionIndex > -1) {
              option.localeNames = allOptions[allOptionIndex].localeNames
            }
          })
        }
      })
      if (!_.isEmpty(translation?.options)) {
        _.forEach(translation.options, translationOptionGroup => {
          const optionGroupIndex = menu.options.findIndex(o => o.id === translationOptionGroup.id)
          const optionGroup = menu.options[optionGroupIndex]
          if (optionGroupIndex >= 0) {
            optionGroup.localeNames = {
              ...optionGroup?.localeNames ?? {},
              [translation.locale]: translationOptionGroup.name,
            }
            menu.options[optionGroupIndex].options = getOptionsName(
              menu.options[optionGroupIndex].options,
              translationOptionGroup.options,
              translation.locale,
            )
          }
        })
      }
    })
    return {
      ...menu,
      localeNames: localeMenuNames,
      localeDesc: localeMenuDesc,
    }
  })
  allMenu.sets = allMenu.sets.map(set => {
    delete Object.assign(set, { menus: set.setMenus }).setMenus
    const localeSetNames = {}
    const localeSetDesc = {}
    set.menus = set.menus.map(menu => {
      const menuWithLocale = allMenu.menus.find(m => m.id === menu.menuId)
      return {
        ...menu,
        localeNames: menuWithLocale?.localeNames ?? {},
        localeDesc: menuWithLocale?.localeDesc ?? {},
      }
    })
    _.forEach(set.translations, translation => {
      localeSetNames[translation.locale] = translation?.name ?? set.name
      localeSetDesc[translation.locale] = translation?.desc ?? set.desc
      _.forEach(set.steps, step => {
        step.localeDesc = {}
        step.localeNames = {}
        _.forEach(step.translations, translation => {
          step.localeDesc[translation.locale] = translation?.desc ?? step.desc
          step.localeNames[translation.locale] = translation?.name ?? step.name
        })
      })
    })
    return {
      ...set,
      localeNames: localeSetNames,
      localeDesc: localeSetDesc,
    }
  })

  // get menus
  const categories = _.map(allMenu.categories, category => getCategoryMenus(category, allMenu.menus, allMenu.sets))
  // 過濾空的和不該在單點目錄的分類
  const filteredCategories = filterDeep(
    _.cloneDeep(categories),
    (category, key, parentCategory, context) => {
      if (category.isSet && parentCategory?.id) {
        // 舊版套餐，且不在第一層，一定不留
        return false
      }

      if (category.type === 'SET') {
        // 是個套餐目錄，一定不留
        return false
      }

      if (category.type === 'SINGLE') {
        // 單點目錄
        return true
      }

      if (category.menus.length > 0) {
        // 有 menu，一定要留
        return true
      }

      // 舊版套餐但在第一層 (沒 parentCategory.id)，要留
      if (category.isSet && !parentCategory?.id) {
        return true
      }

      // 其他情況為 undefined，會繼續檢查 children
    },
    { childrenPath: 'categories' },
  ) || []
  // 過濾空的和不該在套餐目錄的分類
  const filteredSetCategories = filterDeep(
    _.cloneDeep(categories),
    (category, key, parentCategory, context) => {
      if (category.isSet && parentCategory?.id) {
        // 舊版套餐，且不在第一層，一定不留
        return false
      }

      if (category.type === 'SINGLE') {
        // 是個單點目錄，一定不留
        return false
      }

      if (category.type === 'SET') {
        // 套餐目錄
        return true
      }

      if (category.sets.length > 0) {
        // 有 set，一定要留
        return true
      }

      // 舊版套餐但在第一層 (沒 parentCategory.id)，要留
      if (category.isSet && !parentCategory?.id) {
        return true
      }

      // 其他情況為 undefined，會繼續檢查 children
    },
    { childrenPath: 'categories' },
  ) || []
  const rootCategory = {
    id: 'ROOT',
    name: i18n.t('app.constant.menu.root'),
    parentId: null,
    categories: filteredCategories,
    searchCategories: filteredCategories,
    menus: [],
    sets: [],
    path: 'ROOT',
  }
  const rootSetCategory = {
    id: 'SET_ROOT',
    name: i18n.t('app.constant.menu.set'),
    parentId: null,
    categories: filteredSetCategories,
    searchCategories: filteredSetCategories,
    menus: [],
    sets: [],
    isInSetCategories: true,
    path: 'SET_ROOT',
  }
  const promoMenu = allMenu.menus.filter(menu => menu.promoted) || []
  const soldOutMenu = allMenu.menus.filter(menu => menu.sold) || []
  const promoSets = allMenu.sets.filter(set => set.promoted) || []
  const soldOutSets = allMenu.sets.filter(set => set.sold) || []
  const promote = {
    id: 'promote',
    name: i18n.t('app.constant.menu.promoted'),
    parentId: null,
    categories: [],
    menus: promoMenu.map(m => {
      return { ...m, menuId: m.id }
    }),
    sets: promoSets,
    path: 'promote',
    steps: [],
    timerange: [],
    weekdays: [],
  }
  const soldOut = {
    id: 'soldOut',
    name: i18n.t('app.constant.menu.sold'),
    parentId: null,
    categories: [],
    menus: soldOutMenu.map(m => {
      return { ...m, menuId: m.id }
    }),
    sets: soldOutSets,
    path: 'soldOut',
    steps: [],
    timerange: [],
    weekdays: [],
  }

  /** @type {{[id: string]: IAppCategory}} */
  const categoriesMap = {
    ROOT: rootCategory,
    SET_ROOT: rootSetCategory,
  }

  const menus = {}
  const sets = {}

  // create categoriesMap, set parentId
  eachDeep(
    rootCategory.categories,
    (category, key, parentCategory, context) => {
      // 記錄 path，用於搜尋
      const path = 'ROOT.categories' + context.path
      category.path = path
      if (parentCategory && parentCategory.id) {
        category.parentId = parentCategory.id
      } else {
        category.parentId = 'ROOT'
      }
      category.isInSetCategories = false
      categoriesMap[category.id] = category
      category.menus.forEach(menu => {
        menu.menuId = menu.id
        menu.path = path
        menus[menu.id] = menu
      })
    },
    { childrenPath: 'categories', pathFormat: 'string' },
  )
  eachDeep(
    rootSetCategory.categories,
    (category, key, parentCategory, context) => {
      // 記錄 path，用於搜尋
      const path = 'SET_ROOT.categories' + context.path

      category.path = path
      if (parentCategory && parentCategory.id) {
        category.parentId = parentCategory.id
      } else {
        category.parentId = 'SET_ROOT'
      }
      category.isInSetCategories = true
      categoriesMap[category.id] = category
      category.sets.forEach(set => {
        sets[set.id] = set
        set.path = path
        set.isSet = true
        set.categoryId = category.id
        set.steps.forEach(step => {
          step.id = step.key
          // step.parentId = parentCategory.id
          step.parentId = category.parentId
          step.setId = set.id
          step.isSetStep = true
        })
        set.menus.forEach(menu => {
          menu.stepIndex = set.steps.findIndex(step => step.key === menu.step) // 從套餐步驟中找出正確的 stepIndex
          menu.path = path
          menus[menu.id] = menu
        })
      })
    },
    { childrenPath: 'categories', pathFormat: 'string' },
  )
  return [categoriesMap, rootCategory, rootSetCategory, menus, sets, promote, soldOut, allMenu.optionGroupPresets]
}
/**
 * @param {string} merchantId
 * @param {ICategory[]} categories API 給的第一層 categories
 * @returns {Promise<[{[id: string]: IAppCategory}, IAppCategory, IAppCategory, {[id: string]:  IAppMenuItem}, {[id: string]: IAppSet}>}
 */
export async function parseCategories (merchantId, categories, batchLocaleOptions) {
  /** @type {IAppCategory[]} */
  const flattenCategories = []
  const lang = String(getState().app.settings?.lang ?? 'zh').split('-')[0]

  // 建立 flattenCategories 方便 get menus
  eachDeep(
    categories,
    (category, key, parentCategory, context) => {
      // 重新判斷 isSet（用來過濾舊版在 category 的套餐）
      category.isSet = category.isSet && category.steps.length > 0 && category.categories.length === 0
      if (!category.isSet) {
        category.steps = []
      }

      // 放到 flattenCategories
      flattenCategories.push(category)
    },
    { childrenPath: 'categories' },
  )
  const localeOptions = batchLocaleOptions?.length ? batchLocaleOptions : [lang]

  // get menus
  await Promise.all(flattenCategories.map(async category => {
    let menus
    let sets = []
    sets = await dimorderApi.menu.getCategorySets(merchantId, category.id) || []
    const locale = (getState().app.settings.lang || 'zh').slice(0, 2)
    for await (const set of sets) {
      const translation = _.find(set.translations, t => t.locale === locale)
      set.menus = await dimorderApi.menu.getSetMenus(merchantId, set.id) || []
      set.name = _.get(translation, 'name', set.name)
      set.desc = _.get(translation, 'desc', set.desc)
      set.steps = _.map(set.steps, step => {
        const stepTranslation = _.find(step.translations, t => t.locale === locale)
        return {
          ...step,
          name: _.get(stepTranslation, 'name', step.name),
          desc: _.get(stepTranslation, 'desc', step.desc),
        }
      })
    }
    await Promise.all(localeOptions.map(async (locale, index) => {
      const localeMenus = await dimorderApi.menu.getCategoryMenus(merchantId, category.id, locale) || []
      if (!menus) {
        menus = localeMenus
      }

      menus = menus.map(menu => {
        const localeMenu = localeMenus.find(m => m.id === menu.id)
        if (!localeMenu) {
          return { ...menu, locale: lang, localeNames: {}, localeDesc: {} }
        }
        if (!menu.options?.length && !menu.optionGroupPresets?.length) {
          return {
            ...menu,
            locale: lang,
            options: [],
            optionGroupPresets: [],
            name: locale === lang ? localeMenu.name : menu.name,
            localeNames: { ...menu.localeNames, [locale]: localeMenu.name },
            desc: locale === lang ? localeMenu.desc : menu.desc,
            localeDesc: { ...menu.localeDesc, [locale]: localeMenu.desc },
          }
        }
        // 選項群
        const localeMenuOptionGroups = (_.differenceBy(menu.options, menu.optionGroupPresets, 'id') || []).map(optionGroup => {
          const localeMenuOptionGroup = localeMenu.options?.find(o => o.id === optionGroup.id)
          if (!localeMenuOptionGroup) {
            return { ...optionGroup, localeNames: {} }
          }
          if (!optionGroup.options?.length) {
            return {
              ...optionGroup,
              name: locale === lang ? localeMenuOptionGroup.name : optionGroup.name,
              localeNames: { ...optionGroup.localeNames, [locale]: localeMenuOptionGroup.name },
            }
          }
          // 選項
          const localeMenuOptions = optionGroup.options.map(option => {
            const localeMenuOption = localeMenuOptionGroup.options?.find(o => o.id === option.id)
            if (localeMenuOption) {
              return {
                ...option,
                name: locale === lang ? localeMenuOption.name : option.name,
                localeNames: { ...option.localeNames, [locale]: localeMenuOption.name },
              }
            }
            return { ...option, localeNames: {} }
          })
          return {
            ...optionGroup,
            options: localeMenuOptions,
            name: locale === lang ? localeMenuOptionGroup.name : optionGroup.name,
            localeNames: { ...optionGroup.localeNames, [locale]: localeMenuOptionGroup.name },
          }
        })

        // 預設選項群
        const localeMenuOptionGroupPresets = (menu.optionGroupPresets || []).map(optionGroup => {
          const localeMenuOptionGroup = localeMenu.options?.find(o => o.id === optionGroup.id)
          if (!localeMenuOptionGroup) {
            return { ...optionGroup, localeNames: {} }
          }
          if (!optionGroup.options?.length) {
            return {
              ...optionGroup,
              name: locale === lang ? localeMenuOptionGroup.name : optionGroup.name,
              localeNames: { ...optionGroup.localeNames, [locale]: localeMenuOptionGroup.name },
            }
          }
          // 預設選項
          const localeMenuOptions = optionGroup.options.map(option => {
            const localeMenuOption = localeMenuOptionGroup.options?.find(o => o.id === option.id)
            if (localeMenuOption) {
              return {
                ...option,
                name: locale === lang ? localeMenuOption.name : option.name,
                localeNames: { ...option.localeNames, [locale]: localeMenuOption.name },
              }
            }
            return { ...option, localeNames: {} }
          })
          return {
            ...optionGroup,
            options: localeMenuOptions,
            name: locale === lang ? localeMenuOptionGroup.name : optionGroup.name,
            localeNames: { ...optionGroup.localeNames, [locale]: localeMenuOptionGroup.name },
          }
        })
        return {
          ...menu,
          locale: lang,
          options: localeMenuOptionGroups ?? [],
          optionGroupPresets: localeMenuOptionGroupPresets ?? [],
          name: locale === lang ? localeMenu.name : menu.name,
          localeNames: { ...menu.localeNames, [locale]: localeMenu.name },
          desc: locale === lang ? localeMenu.desc : menu.desc,
          localeDesc: { ...menu.localeDesc, [locale]: localeMenu.desc },
        }
      })

      const localeSets = await Promise.all(sets.map(async set => await dimorderApi.menu.getSet(set.id, locale)))
      sets = sets.map(set => {
        const localeSet = localeSets.find(m => m.id === set.id)
        if (localeSet) {
          return {
            ...set,
            name: locale === lang ? localeSet.name : set.name,
            localeNames: { ...set.localeNames, [locale]: localeSet.name },
            desc: locale === lang ? localeSet.desc : set.desc,
            localeDesc: { ...set.localeDesc, [locale]: localeSet.desc },
          }
        }
        return { ...set, localeNames: {}, localeDesc: {} }
      })
    }))
    category.menus = menus
    category.sets = sets
  }))

  // 過濾空的和不該在單點目錄的分類
  const filteredCategories = filterDeep(
    _.cloneDeep(categories),
    (category, key, parentCategory, context) => {
      if (category.isSet && parentCategory?.id) {
        // 舊版套餐，且不在第一層，一定不留
        return false
      }

      if (category.type === 'SET') {
        // 是個套餐目錄，一定不留
        return false
      }

      if (category.type === 'SINGLE') {
        // 單點目錄
        return true
      }

      if (category.menus.length > 0) {
        // 有 menu，一定要留
        return true
      }

      // 舊版套餐但在第一層 (沒 parentCategory.id)，要留
      if (category.isSet && !parentCategory?.id) {
        return true
      }

      // 其他情況為 undefined，會繼續檢查 children
    },
    { childrenPath: 'categories' },
  ) || []

  // 過濾空的和不該在套餐目錄的分類
  const filteredSetCategories = filterDeep(
    _.cloneDeep(categories),
    (category, key, parentCategory, context) => {
      if (category.isSet && parentCategory?.id) {
        // 舊版套餐，且不在第一層，一定不留
        return false
      }

      if (category.type === 'SINGLE') {
        // 是個單點目錄，一定不留
        return false
      }

      if (category.type === 'SET') {
        // 套餐目錄
        return true
      }

      if (category.sets.length > 0) {
        // 有 set，一定要留
        return true
      }

      // 舊版套餐但在第一層 (沒 parentCategory.id)，要留
      if (category.isSet && !parentCategory?.id) {
        return true
      }

      // 其他情況為 undefined，會繼續檢查 children
    },
    { childrenPath: 'categories' },
  ) || []

  const rootCategory = {
    id: 'ROOT',
    name: i18n.t('app.constant.menu.root'),
    parentId: null,
    categories: filteredCategories,
    searchCategories: filteredCategories,
    menus: [],
    sets: [],
    path: 'ROOT',
  }
  const rootSetCategory = {
    id: 'SET_ROOT',
    name: i18n.t('app.constant.menu.set'),
    parentId: null,
    categories: filteredSetCategories,
    searchCategories: filteredSetCategories,
    menus: [],
    sets: [],
    isInSetCategories: true,
    path: 'SET_ROOT',
  }
  const promoMenu = await dimorderApi.menu.getPromotedMenus() || []
  const soldOutMenu = await dimorderApi.menu.getSoldOutMenus() || []
  const promoSets = await dimorderApi.menu.getPromotedSets() || []
  const promote = {
    id: 'promote',
    name: i18n.t('app.constant.menu.promoted'),
    parentId: null,
    categories: [],
    menus: promoMenu.filter(m => !m.deleted).map(m => {
      return { ...m, menuId: m.id }
    }),
    sets: promoSets,
    path: 'promote',
    steps: [],
    timerange: [],
    weekdays: [],
  }
  const soldOut = {
    id: 'soldOut',
    name: i18n.t('app.constant.menu.sold'),
    parentId: null,
    categories: [],
    menus: soldOutMenu.filter(m => !m.deleted).map(m => {
      return { ...m, menuId: m.id }
    }),
    sets: [],
    path: 'soldOut',
    steps: [],
    timerange: [],
    weekdays: [],
  }

  /** @type {{[id: string]: IAppCategory}} */
  const categoriesMap = {
    ROOT: rootCategory,
    SET_ROOT: rootSetCategory,
  }

  const menus = {}
  const sets = {}

  // create categoriesMap, set parentId
  eachDeep(
    rootCategory.categories,
    (category, key, parentCategory, context) => {
      // 記錄 path，用於搜尋
      const path = 'ROOT.categories' + context.path
      category.path = path
      if (parentCategory && parentCategory.id) {
        category.parentId = parentCategory.id
      } else {
        category.parentId = 'ROOT'
      }
      category.isInSetCategories = false
      categoriesMap[category.id] = category
      category.menus.forEach(menu => {
        menu.menuId = menu.id
        menu.path = path
        menus[menu.id] = menu
      })
    },
    { childrenPath: 'categories', pathFormat: 'string' },
  )
  eachDeep(
    rootSetCategory.categories,
    (category, key, parentCategory, context) => {
      // 記錄 path，用於搜尋
      const path = 'SET_ROOT.categories' + context.path

      category.path = path
      if (parentCategory && parentCategory.id) {
        category.parentId = parentCategory.id
      } else {
        category.parentId = 'SET_ROOT'
      }
      category.isInSetCategories = true
      categoriesMap[category.id] = category
      category.sets.forEach(set => {
        sets[set.id] = set
        set.path = path
        set.isSet = true
        set.categoryId = category.id
        set.steps.forEach(step => {
          step.id = step.key
          // step.parentId = parentCategory.id
          step.parentId = category.parentId
          step.setId = set.id
          step.isSetStep = true
        })
        set.menus.forEach(menu => {
          menu.stepIndex = set.steps.findIndex(step => step.key === menu.step) // 從套餐步驟中找出正確的 stepIndex
          menu.path = path
          menus[menu.id] = menu
        })
      })
    },
    { childrenPath: 'categories', pathFormat: 'string' },
  )

  return [categoriesMap, rootCategory, rootSetCategory, menus, sets, promote, soldOut]
}

/**
 * @returns {IAppOrder}
 */
export function getCategoryName (categoryId) {
  const categories = getState().menu.categories

  return _.find(categories, c => c.id === categoryId)?.name
}

function getCategoryMenus (category, menus, sets) {
  const isSet = category.isSet && category.steps?.length > 0 && category.categories?.length === 0
  const steps = !category.isSet ? !category.steps : []
  const categoryMenus = menus.filter(menu => menu.categoryId === category.id)
  const categorySets = sets.filter(set => set.categoryId === category.id)
  const localeNames = {}
  const localeDesc = {}
  _.each(category.translations, translation => {
    localeDesc[translation.locale] = translation.desc
    localeNames[translation.locale] = translation.name
  })
  const categories = _.map(category.categories, c => getCategoryMenus(c, menus, sets))
  return {
    ...category,
    categories,
    isSet,
    steps,
    menus: categoryMenus,
    sets: categorySets,
    localeNames,
    localeDesc,
  }
}

function getOptionsName (options, translationOptions, locale) {
  const updatedOptions = [...options]
  _.forEach(translationOptions, translation => {
    const optionGroupIndex = updatedOptions.findIndex(o => o.id === translation.id)
    const optionGroup = updatedOptions[optionGroupIndex]
    if (optionGroupIndex >= 0) {
      optionGroup.localeNames = {
        ...optionGroup?.localeNames ?? {},
        [locale]: translation?.name ?? optionGroup.name,
      }
    }
  })
  return updatedOptions
}
