import { KeyboardAvoidingView, ScrollView, StyleSheet } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'

export default function MenuPrice (props) {
  const { editingMenu, onUpdate } = props
  const { t } = useTranslation()
  const [keyboardAvoidingViewEnabled, setKeyboardAvoidingViewEnabled] = React.useState(false)
  const takeawayIndex = (editingMenu?.prices ?? []).findIndex(p => p.type === 'takeaway')
  const deliveryIndex = (editingMenu?.prices ?? []).findIndex(p => p.type === 'storeDelivery')

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
    >
      <KeyboardAvoidingView
        style={styles.container}
        contentContainerStyle={styles.container}
        behavior='position'
        enabled={keyboardAvoidingViewEnabled}
        keyboardVerticalOffset={100}
      >
        <Header>
          <HeaderText text={t('app.page.setting.menuPanel.menuInfo.price')} />
        </Header>

        <SettingList style={{ flex: 1 }}>
          <SettingListSwitchRow
            divider
            label={t('app.constant.deliveryType.table')}
            value={!editingMenu.nonTakeawayDisabled}
            onChangeValue={() => onUpdate('nonTakeawayDisabled', !editingMenu.nonTakeawayDisabled)}
          />
          {!editingMenu.nonTakeawayDisabled && (
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.defaultPrice')} />
              <SettingListItemRightAction>
                <SettingListInput
                  keyboardType='number-pad'
                  value={String(editingMenu.price)}
                  disabled={editingMenu.priceUndetermined}
                  onChangeText={(text) => {
                    if (!Number.isNaN(Number(text))) {
                      onUpdate('price', text)
                    }
                  }}
                  onBlur={(event) => {
                    onUpdate('price', Number(event.nativeEvent.text), true)
                  }}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          )}
          {!editingMenu.nonTakeawayDisabled && (
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.discount')} />
              <SettingListItemRightAction>
                <SettingListInput
                  keyboardType='number-pad'
                  value={String(editingMenu.discount)}
                  disabled={editingMenu.priceUndetermined}
                  onChangeText={(text) => {
                    if (!Number.isNaN(Number(text)) && Number(text) <= editingMenu.price) {
                      onUpdate('discount', text)
                    }
                  }}
                  onBlur={(event) => {
                    onUpdate('discount', Number(event.nativeEvent.text), true)
                  }}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          )}
          {!editingMenu.nonTakeawayDisabled && (
            <SettingListSwitchRow
              divider
              label={t('app.constant.order.priceUndetermined')}
              value={editingMenu.priceUndetermined}
              onChangeValue={() => {
                onUpdate('priceUndetermined', !editingMenu.priceUndetermined)
                if (!editingMenu.priceUndetermined) {
                  onUpdate('price', 0)
                  onUpdate('discount', 0)
                }
              }}
            />
          )}

          <SettingListSwitchRow
            divider
            label={t('app.page.setting.menuPanel.menuInfo.takeaway') + '／' + t('app.page.setting.menuPanel.menuInfo.delivery')}
            value={!editingMenu.takeawayDisabled}
            onChangeValue={() => onUpdate('takeawayDisabled', !editingMenu.takeawayDisabled)}
          />
          {!editingMenu.takeawayDisabled && (
            <>
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.takeaway') + t('app.page.setting.menuPanel.menuInfo.price')} />
                <SettingListItemRightAction>
                  <SettingListInput
                    keyboardType='number-pad'
                    value={String(_.get(editingMenu, `prices[${takeawayIndex}].price`, ''))}
                    placeholder={String(editingMenu.price)}
                    disabled={editingMenu.priceUndetermined}
                    onChangeText={(text) => {
                      if (!Number.isNaN(Number(text))) {
                        if (takeawayIndex === -1) {
                          const prices = editingMenu.prices ? [...editingMenu.prices] : []
                          prices.push({
                            type: 'takeaway',
                            price: text,
                            discount: editingMenu.discount,
                          })
                          onUpdate('prices', prices)
                        } else {
                          onUpdate(`prices[${takeawayIndex}].price`, text)
                        }
                      }
                    }}
                    onBlur={(event) => {
                      if (takeawayIndex > -1) {
                        onUpdate(`prices[${takeawayIndex}].price`, Number(event.nativeEvent.text.trim() === '' ? editingMenu.price : event.nativeEvent.text), true)
                      }
                    }}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.takeaway') + t('app.page.setting.menuPanel.menuInfo.discount')} />
                <SettingListItemRightAction>
                  <SettingListInput
                    keyboardType='number-pad'
                    value={String(_.get(editingMenu, `prices[${takeawayIndex}].discount`, ''))}
                    placeholder={String(editingMenu.discount)}
                    disabled={editingMenu.priceUndetermined}
                    onChangeText={(text) => {
                      if (!Number.isNaN(Number(text))) {
                        if (takeawayIndex === -1) {
                          const prices = editingMenu.prices ? [...editingMenu.prices] : []
                          prices.push({
                            type: 'takeaway',
                            price: editingMenu.price,
                            discount: text,
                          })
                          onUpdate('prices', prices)
                        } else {
                          onUpdate(`prices[${takeawayIndex}].discount`, text)
                        }
                      }
                    }}
                    onFocus={() => {
                      if (!editingMenu.nonTakeawayDisabled) {
                        setKeyboardAvoidingViewEnabled(true)
                      }
                    }}
                    onBlur={(event) => {
                      if (takeawayIndex > -1) {
                        onUpdate(`prices[${takeawayIndex}].discount`, Number(event.nativeEvent.text), true)
                      }
                      setKeyboardAvoidingViewEnabled(false)
                    }}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.delivery') + t('app.page.setting.menuPanel.menuInfo.price')} />
                <SettingListItemRightAction>
                  <SettingListInput
                    keyboardType='number-pad'
                    value={String(_.get(editingMenu, `prices[${deliveryIndex}].price`, ''))}
                    placeholder={String(_.get(editingMenu, `prices[${takeawayIndex}].price`, editingMenu.price))}
                    disabled={editingMenu.priceUndetermined}
                    onChangeText={(text) => {
                      if (!Number.isNaN(Number(text))) {
                        if (deliveryIndex === -1) {
                          const prices = editingMenu.prices ? [...editingMenu.prices] : []
                          prices.push({
                            type: 'storeDelivery',
                            price: text,
                            discount: _.get(editingMenu, `prices[${takeawayIndex}].discount`, editingMenu.discount),
                          })
                          onUpdate('prices', prices)
                        } else {
                          onUpdate(`prices[${deliveryIndex}].price`, text)
                        }
                      }
                    }}
                    onFocus={() => {
                      if (!editingMenu.nonTakeawayDisabled) {
                        setKeyboardAvoidingViewEnabled(true)
                      }
                    }}
                    onBlur={(event) => {
                      if (deliveryIndex > -1) {
                        onUpdate(`prices[${deliveryIndex}].price`, Number(event.nativeEvent.text.trim() === '' ? _.get(editingMenu, `prices[${takeawayIndex}].price`, editingMenu.price) : event.nativeEvent.text), true)
                      }
                      setKeyboardAvoidingViewEnabled(false)
                    }}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.delivery') + t('app.page.setting.menuPanel.menuInfo.discount')} />
                <SettingListItemRightAction>
                  <SettingListInput
                    keyboardType='number-pad'
                    value={String(_.get(editingMenu, `prices[${deliveryIndex}].discount`, ''))}
                    placeholder={String(_.get(editingMenu, `prices[${takeawayIndex}].discount`, editingMenu.discount))}
                    disabled={editingMenu.priceUndetermined}
                    onChangeText={(text) => {
                      if (!Number.isNaN(Number(text))) {
                        if (deliveryIndex === -1) {
                          const prices = editingMenu.prices ? [...editingMenu.prices] : []
                          prices.push({
                            type: 'storeDelivery',
                            price: _.get(editingMenu, `prices[${takeawayIndex}].price`, editingMenu.price),
                            discount: text,
                          })
                          onUpdate('prices', prices)
                        } else {
                          onUpdate(`prices[${deliveryIndex}].discount`, text)
                        }
                      }
                    }}
                    onFocus={() => {
                      if (!editingMenu.nonTakeawayDisabled) {
                        setKeyboardAvoidingViewEnabled(true)
                      }
                    }}
                    onBlur={(event) => {
                      if (deliveryIndex > -1) {
                        onUpdate(`prices[${deliveryIndex}].discount`, Number(event.nativeEvent.text), true)
                      }
                      setKeyboardAvoidingViewEnabled(false)
                    }}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
            </>
          )}

          <SettingListSwitchRow
            divider
            label={t('app.page.setting.menuPanel.menuInfo.noDiscount')}
            value={editingMenu.excludedDiscount}
            onChangeValue={() => onUpdate('excludedDiscount', !editingMenu.excludedDiscount)}
          />
          <SettingListSwitchRow
            label={t('app.page.setting.menuPanel.menuInfo.noSurcharge')}
            value={editingMenu.excludedSurcharge}
            onChangeValue={() => onUpdate('excludedSurcharge', !editingMenu.excludedSurcharge)}
          />
        </SettingList>
      </KeyboardAvoidingView>
    </ScrollView>
  )
}

const styles = StyleSheet.create({

})
