import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import orderAppLib from 'dimorder-orderapp-lib'

import { PrintReason } from '@/constants/printing'
import { actions, useDispatch, useSelector } from '@/redux'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { ecrGateway, loadingKey } from '@/constants'
import { getStatusConfig } from '@/libs/orderStatus'
import { useIsOnline } from '@/hooks/app'
import Blink from '@/components/Blink'
import Button from '@/components/buttons/Button'
import CancelPaymentsAndUnlockOrderBanner from '@/components/CancelPaymentsAndUnlockOrderBanner'
import CancelPaymentsAndUnlockOrderDialog from '@root/src/components/dialogs/CancelPaymentsAndUnlockOrderDialog'
import Column from '@/components/Column'
import CustomerInfoCard from '@/components/CustomerInfoCard'
import OrderCancelDialog from '@/components/dialogs/OrderCancelDialog'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import Row from '@/components/Row'
import colors from '@/theme/colors'

import BatchList from './BatchList'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef TableOrderProps
 * @property {string} title
 * @property {IAppOrder} currentOrder
 */

/**
 *
 * @param {TableOrderProps} props
 * @returns
 */
export default function TableOrder (props) {
  const { title, currentOrder } = props
  const dispatch = useDispatch()
  const { id, serial, status, createdAt, customers, roundedTotal, batches, displayStatusKey } = currentOrder
  const orderStatus = getStatusConfig(currentOrder)
  const [openCancelReasonDialog, setOpenCancelReasonDialog] = React.useState(false)
  const cancelId = useSelector(state => state.table.cancelId)
  const isPending = status === 'pending' && _.isEmpty(batches)
  const isOrdered = status === 'pending' && !_.isEmpty(batches)
  const unConfirm = batches?.some(batch => batch.status === 'submitted')
  const isPaid = status === 'paid'
  const isCancelled = status === 'cancelled'
  const rounding = useSelector(state => state.merchant.data.rounding)
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)
  const [cancelReason, setCancelReason] = React.useState('')
  const { selectPrinterBeforePrint } = useSelector(state => state.app.settings)
  const { setting } = useSelector(state => state.merchant.data)
  const { isOnline } = useIsOnline()
  const statusStyle = {
    backgroundColor: orderStatus.color || colors.gray,
  }
  const { t } = useTranslation()
  const customerText = t('app.page.table.dialog.customer')
  const timeText = t('app.page.table.dialog.time')
  const ordedrSerial = t('app.page.table.dialog.ordedrSerial')
  const customerNo = t('app.page.table.dialog.customerNo')
  const statusText = t(`app.page.table.dialog.${currentOrder.displayStatusKey}`)
  const printText = t('app.page.table.dialog.print')
  const orderText = t('app.page.table.dialog.order')
  const payText = t('app.page.table.dialog.pay')
  const cancelText = t('app.page.table.dialog.cancel')
  const reasonText = t('app.page.table.dialog.reason')
  const reasonMessage = t('app.page.table.dialog.reasonMessage')
  const dateText = t('app.page.table.dialog.date')
  const index = useMemo(() => historyOrders.findIndex((order) => order.id === currentOrder.id), [historyOrders, currentOrder.id])
  const order = historyOrders.find(order => order.id === currentOrder.id)
  const enableCheckoutReceipt = setting?.enableCheckoutReceipt
  const buttonWidth = enableCheckoutReceipt ? 85 : 95
  let duration = 500
  let showOverdueIcon = false
  const [isCancelPaymentDialogOpen, setIsCancelPaymentDialogOpen] = useState(false)

  if (order.orderCutOffMins) {
    const overdueTime = moment(order.createdAt).add(order.orderCutOffMins, 'minute')
    const isAlmostOverdue = moment().isAfter(moment(overdueTime).subtract(5, 'minute'))
    const isOverdue = moment().isAfter(overdueTime)
    if (isOverdue) {
      duration = 200
    }
    showOverdueIcon = (isAlmostOverdue || isOverdue) && order.status === 'pending' && order.deliveryType === 'table'
  }

  React.useEffect(() => {
    setOpenCancelReasonDialog(Boolean(cancelId))
  }, [cancelId])

  if (status === 'paid') return null

  const showCancelPaymentsAndUnlockOrderBanner =
    currentOrder?.status === 'pending' &&
    currentOrder?.payments.some(p => p.customerId && p.status !== 'cancel')

  return (
    <Column style={styles.container}>
      {
        showOverdueIcon && (
          <Blink duration={duration} style={styles.pending}>
            <Image
              source={require('@icons/table/overdue.png')}
              style={styles.pendingImg}
            />
          </Blink>
        )
      }
      {showCancelPaymentsAndUnlockOrderBanner && (
        <View style={{ marginBottom: 5 }}>
          <CancelPaymentsAndUnlockOrderBanner setIsCancelPaymentDialogOpen={setIsCancelPaymentDialogOpen} />
        </View>
      )}
      <TouchableOpacity
        onPress={() => {
          dispatch(actions.orderHistory.resetFilter())
          dispatch(actions.orderHistory.updateQuery(''))
          dispatch(actions.orderHistory.selectOrder(currentOrder?.id, index))
          window.applicatiionHistory.push('/orderHistory')
        }}
      >
        {/* header */}
        <Row style={styles.header}>
          <Column style={styles.headerLeft}>
            <Text style={styles.headerTitle}>{title}</Text>
            <View style={[styles.status, statusStyle]}>
              <Text style={styles.statusText}>{statusText}</Text>
            </View>

          </Column>
          <Column style={styles.headerMain}>
            <Text style={styles.headerTitle}>{currencyWithCommas(orderAppLib.libs.getDisplayRoundNumber(roundedTotal, rounding))}</Text>
            <Text style={styles.headerText}>{customerText}：{customers}{customerNo}  {timeText}：{moment(createdAt).format('HH:mm')}</Text>
            <Text style={styles.headerText}>{ordedrSerial}：{serial}  {dateText}：{moment(createdAt).format('DD/MM/YYYY')}</Text>
          </Column>

          <CustomerInfoCard customer={currentOrder} />
        </Row>
        {/* order actions */}
        <Row style={styles.orderActions}>
          <Button
            title={printText}
            style={[!(setting.qrcode && setting.enableDynamicQRCode) && displayStatusKey === 'waiting_order' ? { ...styles.button, opacity: 0.4 } : styles.button, { width: buttonWidth }]}
            textColor={colors.textTertiary}
            textBold
            type='TouchableOpacity'
            disabled={!isOnline && displayStatusKey === 'waiting_order'}
            onPress={async () => {
              if (!(setting.qrcode && setting.enableDynamicQRCode) && displayStatusKey === 'waiting_order') { return }
              if (selectPrinterBeforePrint) {
                setPrinterDialogOpen(true)
              } else {
                await dispatch(actions.app.openLoading(loadingKey.PRINT, 'tableDialog-print-receipt'))
                if (displayStatusKey === 'waiting_order') {
                  dispatch(actions.printer.printQRCode(currentOrder, setting, '', PrintReason.QR_CODE.CLICK_TABLE_DIALOG_PRINT_RECEIPT))
                } else {
                  dispatch(actions.printer.printOrderReceipt({
                    order: currentOrder,
                    printReason: PrintReason.ORDER_RECEIPT.CLICK_TABLE_DIALOG_PRINT_RECEIPT,
                  }))
                }
              }
            }}
          />
          <Button
            title={orderText}
            style={[styles.button, isPending && styles.nextButton, { width: buttonWidth }]}
            textColor={isPending ? colors.white : colors.textTertiary}
            textBold
            type='TouchableOpacity'
            onPress={() => {
              dispatch(actions.table.closeDialog(['table']))
              setTimeout(() => {
                dispatch(actions.auth.permissionCheck('merchant-staff-order', () => {
                  dispatch(actions.order.startOrder(id))
                }, () => {
                  dispatch(actions.table.showDialog(['table']))
                }))
              }, 200)
            }}
            disabled={isPaid || isCancelled || showCancelPaymentsAndUnlockOrderBanner}
          />
          {
            setting?.enableCheckoutReceipt && (
              <Button
                title={t('app.page.table.dialog.printCheckoutReceipt')}
                style={[styles.button, { width: buttonWidth }, (isOrdered && !unConfirm) && styles.checkoutReceiptButton]}
                textColor={(isOrdered && !unConfirm) ? colors.white : colors.textTertiary}
                textBold
                type='TouchableOpacity'
                disabled={isPaid || isCancelled || isPending || unConfirm}
                onPress={async () => {
                  if (selectPrinterBeforePrint) {
                    setPrinterDialogOpen(true)
                  } else {
                    await dispatch(actions.app.openLoading(loadingKey.PRINT, 'tableDialog-print-receipt'))
                    dispatch(actions.order.printedCheckoutReceipt(currentOrder.id))
                    dispatch(actions.printer.printOrderReceipt({
                      order: currentOrder,
                      printReason: PrintReason.ORDER_RECEIPT.CLICK_PRINT_CHECKOUT_RECEIPT,
                    }))
                  }
                }}
              />
            )
          }
          <Button
            title={payText}
            style={[styles.button, (isOrdered && !unConfirm) && styles.nextButton, { width: buttonWidth }]}
            textColor={(isOrdered && !unConfirm) ? colors.white : colors.textTertiary}
            textBold
            type='TouchableOpacity'
            onPress={() => {
              dispatch(actions.table.closeDialog(['table']))
              setTimeout(() => {
                dispatch(actions.auth.permissionCheck('checkout', () => {
                  dispatch(actions.app.setScopeLogin(true))
                  dispatch(actions.orderCheckout.checkoutOrder(id))
                }, () => {
                  dispatch(actions.table.showDialog(['table']))
                }))
              }, 200)
            }}
            disabled={isPaid || isCancelled || isPending || unConfirm || showCancelPaymentsAndUnlockOrderBanner}
          />
          <Button
            title={cancelText}
            style={[styles.button, { width: buttonWidth }]}
            textColor={colors.textTertiary}
            textBold
            type='TouchableOpacity'
            onPress={() => {
              dispatch(actions.table.closeDialog(['table']))
              setTimeout(() => {
                dispatch(actions.auth.permissionCheck('order-cancel', () => {
                  dispatch(actions.table.setCancelId(id))
                  dispatch(actions.table.showDialog(['table']))
                }, () => {
                  dispatch(actions.table.showDialog(['table']))
                }))
              }, 200)
            }}
            disabled={isPaid || isCancelled}
          />
        </Row>
        {/* order items */}
        <BatchList batches={batches} />

        <OrderCancelDialog
          open={openCancelReasonDialog && id === cancelId}
          onClose={() => {
            setOpenCancelReasonDialog(false)
            dispatch(actions.table.setCancelId(null))
          }}
          title={reasonText}
          placeholder={reasonMessage}
          onSubmit={async (text) => {
            if (selectPrinterBeforePrint) {
              setPrinterDialogOpen(true)
              setCancelReason(text)
            } else {
              await dispatch(actions.app.openLoading(loadingKey.PRINT, 'tableDialog-cancel-receipt'))
              dispatch(actions.table.cancelOrder(currentOrder, text))
              dispatch(actions.printer.printOrderReceipt({
                order,
                cancelledType: 'cancel',
                printReason: PrintReason.ORDER_RECEIPT.CLICK_CANCEL_ORDER,
              }))
              dispatch(actions.table.closeDialog(['table']))
              dispatch(actions.table.setCancelId(null))
            }
          }}
          warningText={`${t('app.printing.cancel')}: [${t('app.printing.table')}: ${order.table}${order.subTable > 0 ? `(${String.fromCharCode(order.subTable + 64)})` : ''}] ${order.serial}`}
        />
        <PrinterDialog
          open={printerDialogOpen}
          onClose={() => setPrinterDialogOpen(false)}
          onSelect={async (selectedPrinterId) => {
            await dispatch(actions.app.openLoading(loadingKey.PRINT, 'tableDialog-selectedPrinter-receipt'))
            if (id === cancelId) {
              dispatch(actions.table.cancelOrder(currentOrder, cancelReason))
              dispatch(actions.printer.printOrderReceipt({
                order: currentOrder,
                cancelledType: 'cancel',
                printReason: PrintReason.ORDER_RECEIPT.CLICK_CANCEL_ORDER,
              }))
              dispatch(actions.table.closeDialog(['table']))
              dispatch(actions.table.setCancelId(null))
            } else if (displayStatusKey === 'waiting_order') {
              dispatch(actions.printer.printQRCode(currentOrder, setting, selectedPrinterId, PrintReason.QR_CODE.CLICK_TABLE_DIALOG_PRINT_RECEIPT))
            } else {
              dispatch(actions.printer.printOrderReceipt({
                order: currentOrder,
                selectedPrinterId,
                printReason: PrintReason.ORDER_RECEIPT.CLICK_TABLE_DIALOG_PRINT_RECEIPT,
              }))
            }
          }}
        />
      </TouchableOpacity>
      <CancelPaymentsAndUnlockOrderDialog
        open={isCancelPaymentDialogOpen}
        onClose={() => setIsCancelPaymentDialogOpen(false)}
        onSubmit={() => {
          currentOrder.payments.forEach((payment) => {
            if (payment.status !== 'cancel') {
              if (Object.values(ecrGateway).includes(payment.gateway)) {
                dispatch(actions.orderHistory.voidCardPayment(currentOrder.id, payment, 'unlock order', false, false))
              } else {
                dispatch(actions.orderHistory.voidOrderPayment(currentOrder.id, payment, 'unlock order'))
              }
            }
          })
        }}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: colors.light,
    borderRadius: 15,
    borderTopLeftRadius: 25,
    padding: 14,
    marginBottom: 10,
  },
  header: {
    marginBottom: 10,
  },
  headerLeft: {
    width: '15%',
    alignItems: 'center',
  },
  headerMain: {
    flex: 1,
    marginLeft: 16,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.primary,
  },
  status: {
    height: 28,
    width: 67,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
  },
  statusText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.white,
  },
  headerText: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 18,
    color: colors.primary,
  },
  orderActions: {
    width: '100%',
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  button: {
    height: 38,
    width: 95,
    borderColor: colors.textTertiary,
    borderWidth: 2,
    backgroundColor: colors.transparent,
    margin: 0,
  },
  nextButton: {
    borderWidth: 0,
    backgroundColor: colors.secondary,
  },
  checkoutReceiptButton: {
    borderWidth: 0,
    backgroundColor: colors.tertiary,
  },
  pending: {
    width: 0,
    height: 0,
    alignItems: 'flex-end',
    borderColor: 'transparent',
    borderLeftWidth: 42,
    borderTopColor: colors.primary,
    borderTopWidth: 42,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  pendingImg: {
    height: 20,
    width: 20,
    position: 'absolute',
    top: -40,
    right: 3,
  },
})
