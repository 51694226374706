import React from 'react'
import _ from 'lodash'

import { printing } from '@/constants'
import { useTranslation } from 'react-i18next'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * onSelect: () => void,
 * }} props
 */
export default function PrintingSettingList (props) {
  const { setting, title, path, divider, selecting, onChange, onSelect, children, atLast = false } = props
  const { t } = useTranslation()
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)

  const textStyleOptions = [
    { value: 7, display: '7' },
    { value: 8, display: '8' },
    { value: 9, display: '9' },
    { value: 10, display: '10' },
    { value: 11, display: '11' },
    { value: 12, display: '12' },
    { value: 13, display: '13' },
    { value: 14, display: '14' },
    { value: 15, display: '15' },
    { value: 16, display: '16' },
    { value: 17, display: '17' },
    { value: 18, display: '18' },
    { value: 19, display: '19' },
    { value: 20, display: '20' },
  ]
  const textAlignOptions = [
    { value: 'left', display: t('app.page.setting.printer.left') },
    { value: 'center', display: t('app.page.setting.printer.center') },
    { value: 'right', display: t('app.page.setting.printer.right') },
  ]

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig.title}
        onClose={() => {
          setOptionsPanelConfig(null)
          onSelect(false)
        }}
        {...optionsPanelConfig}
      />
    )
  }

  if (selecting) {
    return <></>
  }

  return (
    <>
      <SettingListItem divider={!_.get(setting, `${path}.enable`) && divider}>
        <SettingListItemText text={title} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={_.get(setting, `${path}.enable`)}
            onChangeValue={() => onChange(`${path}.enable`, !_.get(setting, `${path}.enable`))}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        _.get(setting, `${path}.enable`) && (
          <>
            {!atLast && children}
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={_.get(setting, `${path}.bold`)}
                  onChangeValue={() => onChange(`${path}.bold`, !_.get(setting, `${path}.bold`))}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === _.get(setting, `${path}.fontSize`))?.display}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: _.get(setting, `${path}.fontSize`) || 0,
                  onChangeValue: (value) => onChange(`${path}.fontSize`, value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
                onSelect(true)
              }}
            />
            <SettingListEnterRow
              divider={divider && !atLast}
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === _.get(setting, `${path}.align`))?.display}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: _.get(setting, `${path}.align`) || 0,
                  onChangeValue: (value) => onChange(`${path}.align`, value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
                onSelect(true)
              }}
            />
            {atLast && children}
          </>
        )
      }
    </>
  )
}
