import { useEffect, useRef } from 'react'

/**
 * @description Tracking 元件已載入 / 已卸載
 * @example import useMountedRef from '@/hooks/useMountedRef'
 * // *** 在useState / useRef / useHook 之後section加入
 * const mounted = useMountedRef()
 * // *** 防止update UI for umounted元件而導致memory leak, 在有機會不同步/delay的工作中加入 if(mounted) 作檢查元件是否消失了
 * if(mounted.current) setState(xxxx)
 */
export default function useMountedRef () {
  const mounted = useRef(false)
  useEffect(() => {
    // 元件載入好
    mounted.current = true
    return () => { // 元件umount
      mounted.current = false
    }
  })
  return mounted
}

// [ 使用說明 ]
// import useMountedRef from '@/hooks/useMountedRef'

// *** 在useState / useRef / useHook 之後section加入
// const mounted = useMountedRef()

// *** 防止update UI for umounted元件而導致memory leak, 在不同步/delay的工作中加入 if(mounted) 作檢查元件是否消失了
// if(mounted.current) setState(xxxx)
