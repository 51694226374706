import { StyleSheet, View } from 'react-native'
import React, { useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'

import EmployeeBoard from './EmployeeBoard'
import PasswordBoard from './PasswordBoard'

/**
 * @typedef EmployeeLoginProps
 * @property {() => void} setIsLogoHidden
 * @property {'permission' | 'screenLock'} type
 */

/**
 *
 * @param {EmployeeLoginProps} props
 * @returns
 */
export default function EmployeeLogin (props) {
  const { setIsLogoHidden, type } = props
  const dispatch = useDispatch()
  const [employeeSelected, setEmployeeSelected] = useState(null)
  const neededPermission = useSelector(state => state.app.neededPermission)
  // const isUserLogin = useSelector(state => state.auth.isUserLogin)

  const handleEmployeeLogin = (account, password, neededPermission) => {
    dispatch(actions.auth.scopeLogin(account, password, neededPermission, goToEmployeeLogin, type))
  }

  const handleSelectEmployee = (employee) => {
    setIsLogoHidden(true)
    setEmployeeSelected(employee)
  }

  const goToEmployeeLogin = () => {
    dispatch(actions.auth.updateError(null))
    setIsLogoHidden(false)
    setEmployeeSelected(null)
  }

  return (
    <View style={styles.container}>
      {/* Show employees if not selected */}
      {!employeeSelected
        ? (
          <View style={styles.container}>
            <View style={styles.main}>
              <EmployeeBoard onSelect={handleSelectEmployee} scope={neededPermission} type={type} />
            </View>
          </View>
        )
        : (
          <PasswordBoard
            employee={employeeSelected}
            onBack={goToEmployeeLogin}
            onSubmit={handleEmployeeLogin}
          />
        )}

    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  main: {
    flex: 1,
    alignItems: 'center',
    paddingRight: 52.5,
  },

})
