import * as iconv from 'iconv-lite'

import {
  PrintMethod,
  PrinterBrand,
  PrinterCommand,
  PrinterEncoding,
  PrinterType,
  PrinterUsage,
} from '@/constants/printer'
import Printer from '@/libs/printing/printer/Printer'
import store from '@/redux/store'

const ALIGN = {
  default: 0,
  left: 0,
  center: 1,
  right: 2,
}

const DEFAULT_ORIGIN = {
  x: 10,
  y: 10,
}

/** @type {() => IRootState} */
const getState = store.getState

/**
 * TSC 熱感式條碼印表機
 * encoding - 中文: big5
 * command: TSPL/TSPL2
 * 用途：標籤
 * Printer Programming Manual: https://drive.google.com/drive/folders/1rQKQpbzN181W7zv6GAQfjhV1-ZRicKE9
 *
 * 餐廳常用的標籤紙有兩種尺寸
 * A. 2.00 x 1.58 inches
 * B. 1.58 x 1.18 inches
 */
export default class TDP225 extends Printer {
  TYPE = PrinterType.TDP225
  PRINTER_BRAND = PrinterBrand.TSC
  PRINTER_COMMAND = PrinterCommand.TSPL_TSPL2
  PRINT_METHOD = PrintMethod.THERMAL
  USAGES = [PrinterUsage.LABEL]
  ENCODINGS = { BIG5: PrinterEncoding.BIG5 }
  DEFAULT_ENCODING = this.ENCODINGS.BIG5

  encoding = this.DEFAULT_ENCODING
  x = DEFAULT_ORIGIN.x
  y = DEFAULT_ORIGIN.y
  widthInches = 1.58 // 1.58 in = 40.132 mm = 303 dots
  heightInches = 1.18 // 1.18 in = 29.972 mm = 240 dots
  widthDots = this._convertInchesToDots(this.widthInches)
  heightDots = this._convertInchesToDots(this.heightInches)
  // ! Text 1 => 3 dots
  // ! TLabelFontSize = 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20

  /**
   * [Name] SIZE
   * [Desc] This command defines the label width and length.
   * @param {number} [width] Inches. Default 1.58 inches (about 40 mm).
   * @param {number} [height] Inches. Default 1.18 inches (about 30 mm).
   * @returns
   */
  addSetSize (width = 1.58, height = 1.18) {
    this.widthInches = width
    this.heightInches = height
    this.widthDots = this._convertInchesToDots(width)
    const command = this._toASCII(`SIZE ${width},${height}`)
    console.log(`SIZE ${width},${height}`)
    this.writeNumbers(command)
    return this
  }

  /**
   * // ? 只有 addTextConnect 會用
   * [Name] FEED
   * [Desc] This command feeds label with the specified length. The length is specified by dot.
   * @param {number} [n] 1 ≤ n ≤ 9999 dots. Default 1.
   * @returns
   */
  addFeed (n = 1) {
    const command = this._toASCII(`FEED ${n}`)
    console.log(`FEED ${n}`)
    this.writeNumbers(command)
    return this
  }

  /**
   * ????????????????????????????????
   */
  addTextConnect () {
    this.addFeed()
  }

  /**
   * ???? 這是 init? 為什麼 Label 那邊的 init 寫自己的沒有用這個？
   */
  addSetUp () {
    this.addCodePage()
    this.addSetSize()
    this.addGap()
    this.addDirection(1)
    this.addHome()
    this.addClear()
  }

  /**
   * 加入空行（透過把 Y 軸往下移）
   * @param {LabelFontSize} [fontSize] Default 8.
   */
  addRowFeed (fontSize = 8) {
    const n = 10
    this.y += fontSize * 3 + n // ? MAGIC NUMBERS
  }

  /**
   * 設定 x 起點
   * @param {'left' | 'center' | 'right'} align
   */
  addAlign (align) {
    const PADDING_LEFT = 10
    const PADDING_RIGHT = 20 // ? 為什麼左右兩邊的 padding 不一樣？ 又邊為什麼寬了 10 dots?
    let x = 0
    if (align === 'left') x = PADDING_LEFT
    if (align === 'center') x = Math.round(this.widthDots / 2)
    if (align === 'right') x = this.widthDots - PADDING_RIGHT
    this.x = x
  }

  /**
   * [Name] TEXT
   * [Desc] This command prints text on label.
   * [Syntax] TEXT x,y, " font ",rotation,x-multiplication,y-multiplication,[alignment,] " content "
   * @param {string} content Content of text string.
   * @param {number} [fontSize] Default 8.
   * @param {'default' | 'left' | 'center' | 'right'} [align] Optional. Specify the alignment of text. (V6.73 EZ). Default 'default'.
   * @param {boolean} [bold] Default false.
   * @param {0 | 90 | 180 | 270} [rotation] The rotation angle of text. in clockwise direction. Default 0.
   * @param {string} [font] Default 'DIMORDER.TTF'.
   * @returns
   */
  addText (content, fontSize = 8, align = 'default', bold = false, rotation = 0, font = 'DIMORDER.TTF') {
    // 1 => 1/72 inch // ????
    // 中文字 => 1 x 1
    // 英文字 => 1 x 0.6 // ???? 0.6 從來沒有出現在這份檔案裡面

    let translateX = 0
    let translateY = 0
    for (let i = 0, count = bold ? 2 : 0; i <= count; i++) {
      switch (i) {
        case 0: {
          translateX = 0
          translateY = 0
          break
        }
        case 1: {
          translateX = 0
          translateY = 1
          break
        }
        case 2: {
          translateX = 1
          translateY = 0
          break
        }
      }
      const x = this.x + translateX // The x-coordinate of the text
      const y = this.y + translateY // The y-coordinate of the text
      const xMultiplication = fontSize
      const yMultiplication = fontSize
      const alignment = ALIGN[align]

      const command = this._toASCII(`TEXT ${x},${y},"${font}",${rotation},${xMultiplication},${yMultiplication},${alignment},"`, false)
        .concat(Array.from(iconv.encode(content, this.encoding)))
        .concat(this._toASCII('"'))
      console.log(`TEXT ${x},${y},"${font}",${rotation},${xMultiplication},${yMultiplication},${alignment},"${content}"`)
      this.writeNumbers(command)
    }
    return this
  }

  /**
   * [Name] BLOCK
   * [Desc] This command prints paragraph on label.
   * [Syntax] BLOCK x,y,width,height, "font",rotation,x-multiplication,y-multiplication,[space,]align,]fit,] "content"
   * @param {string} content Data in block. The maximum data length is 4092 bytes.
   * @param {number} [fontSize] Default 8.
   * @param {'default' | 'left' | 'center' | 'right'} [align] Optional. Specify the alignment of text. (V6.73 EZ). Default 'default'.
   * @param {boolean} [bold] Default false.
   * @param {0 | 90 | 180 | 270} [rotation] The rotation angle of text. in clockwise direction. Default 0.
   * @param {string} [font] Default 'DIMORDER.TTF'.
   * @returns
   */
  addBlockText (content, fontSize = 8, align = 'default', bold = false, rotation = 0, font = 'DIMORDER.TTF') {
    let translateX = 0
    let translateY = 0
    for (let i = 0, count = bold ? 2 : 0; i <= count; i++) {
      switch (i) {
        case 0: {
          translateX = 0
          translateY = 0
          break
        }
        case 1: {
          translateX = 0
          translateY = 1
          break
        }
        case 2: {
          translateX = 1
          translateY = 0
          break
        }
      }

      const x = this.x + translateX // The x-coordinate of the text
      const y = this.y + translateY // The y-coordinate of the text
      const width = this.widthDots - 40 // The width of block for the paragraph in dots.
      const height = this.heightDots - 70 - this.x // The height of block for the paragraph in dots. // ? 70 哪來的又？ 關 x 什麼事？
      const xMultiplication = fontSize
      const yMultiplication = fontSize
      const alignment = ALIGN[align]

      const command = this._toASCII(`BLOCK ${x},${y},${width},${height},"${font}",${rotation},${xMultiplication},${yMultiplication},0,${alignment},"`, false)
        .concat(Array.from(iconv.encode(content, this.encoding)))
        .concat(this._toASCII('"'))
      console.log(`BLOCK ${x},${y},${width},${height},"${font}",${rotation},${xMultiplication},${yMultiplication},0,${alignment},"${content}"`)
      this.writeNumbers(command)
    }
    return this
  }

  /**
   * 加文字在標籤底部
   * @param {string} content
   * @param {number} [fontSize] Default 8.
   * @param {'default' | 'left' | 'center' | 'right'} [align] Optional. Specify the alignment of text. (V6.73 EZ). Default 'default'.
   * @param {boolean} [bold] Default false.
   * @param {0 | 90 | 180 | 270} [rotation] The rotation angle of text. in clockwise direction. Default 0.
   * @param {string} [font] Default 'DIMORDER.TTF'.
   */
  addTextAtBottom (content, fontSize = 8, align = 'default', bold = false, rotation = 0, font = 'DIMORDER.TTF') {
    const PADDING_BOTTOM = 20
    this.y = this.heightDots - PADDING_BOTTOM - (fontSize * 3) // ???? fontSize * 3 是說最多只能加 3 行嗎？
    this.addText(content, fontSize, align, bold, rotation, font)
  }

  /**
   * [Name] GAP
   * [Desc] Defines the gap distance between two labels.
   * @param {number} [m] The gap distance between two labels. 0 ≤ m ≤1 (inch). 0 ≤ m ≤5 (inch) / since V6.21 EZ and later firmware. Default 0.12 inches.
   * @param {number} [n] The offset distance of the gap. n ≤ label length (inch). Default 0 inch.
   * @returns
   */
  addGap (m = 0.12, n = 0) {
    const command = this._toASCII(`GAP ${m},${n}`)
    console.log(`GAP ${m},${n}`)
    this.writeNumbers(command)
    return this
  }

  /**
   * [Name] DIRECTION and Mirror Image
   * [Desc] This command defines the printout direction and mirror image. This will be stored in the printer memory.
   * [Note] TDP-643 Plus , TTP-243, TTP-342, TTP-244ME, TTP-342M and TTP-248M series are not supported this mirror feature.
   * @param {number} [n] 0 or 1. Please refer to the illustrations below. Default 0.
   * @param {number} [m] 0: Print normal image. 1: Print mirror image. Default 0.
   * @returns
   */
  addDirection (n = 0, m = 0) {
    const command = this._toASCII(`DIRECTION ${n}${m ? `,${m}` : ''}`)
    console.log(`DIRECTION ${n}${m ? `,${m}` : ''}`)
    this.writeNumbers(command)
    return this
  }

  /**
   * ? 用法看起來有問題
   * ? 看起來應該是設定完 Setup Commands 之後用的
   * ? 然後接 Label Formatting Commands
   * [Name] CLS
   * [Desc] This command clears the image buffer.
   * [Note] This command must be placed after SIZE command.
   * @returns
   */
  addClear () {
    const command = this._toASCII('CLS')
    console.log('CLS')
    this.writeNumbers(command)
    return this
  }

  /**
   * [Name] PRINT
   * [Desc] This command prints the label format currently stored in the image buffer.
   * [Syntax] PRINT m[,n]
   * @param {number} [sets] Specifies how many sets of labels will be printed. 1 ≤ m ≤ 999999999. Default 1.
   * @param {number} [copies] Specifies how many copies should be printed for each particular label set. 1 ≤ n ≤ 999999999. Default 0.
   * @returns
   */
  addPrint (sets = 1, copies = 0) {
    // FIXME n 的範圍 1 ≤ n ≤ 999999999 然後初始 0？
    const m = sets
    const n = copies ? `,${copies}` : ''
    const command = this._toASCII(`PRINT ${m}${n}`)
    console.log(`PRINT ${m}${n}`)
    this.writeNumbers(command)
    this.x = DEFAULT_ORIGIN.x
    this.y = DEFAULT_ORIGIN.y
    return this
  }

  /**
   * [Name] CUT
   * [Desc] This command activates the cutter to immediately cut the labels without back feeding the label.
   * @returns
   */
  addCutPaper () {
    const command = this._toASCII('CUT')
    console.log('CUT')
    this.writeNumbers(command)
    return this
  }

  /**
   * [Name] HOME
   * [Desc] This command will feed label until the internal sensor has determined the origin. Size and gap of the label should be defined before using this command.
   * [Note] For TSPL2 programming printer: Feed label to origin position
   * @returns
   */
  addHome () {
    const command = this._toASCII('HOME')
    console.log('HOME')
    this.writeNumbers(command)
    return this
  }

  /**
   * // ? 為什麼沒有用到？
   * [Name] CODEPAGE
   * [Desc] This command defines the code page of international character set.
   * [Note] 950 = Traditional Chinese Big5
   * @param {number} [n] Name or number of code page, which can be divided into 7-bit code page and 8-bit code page. Default 950.
   * @returns
   */
  addCodePage (n = 950) {
    const command = this._toASCII(`CODEPAGE ${n}`)
    console.log(`CODEPAGE ${n}`)
    this.writeNumbers(command)
    return this
  }

  /**
   * [Name] DENSITY
   * [Desc] This command sets the printing darkness.
   * [Note] Default DENSITY setting is 8.
   * @param {number} [n] 0~15. 0: specifies the lightest level. 15: specifies the darkest level. Default 15.
   * @returns
   */
  addDensity (n = 15) {
    const command = this._toASCII(`DENSITY ${n}`)
    console.log(`DENSITY ${n}`)
    this.writeNumbers(command)
    return this
  }

  // overwriteQRcode () {
  //   this.addQRCode('OVERWRITE_ENV=test', 120, 40, 8)
  //   this.x = 60
  //   this.y = 260
  //   this.addText('OVERWRITE_ENV=test', 0, 0, false, 0, 3)
  // }

  addPrintTest () {
    this.addSetUp()
    this.addRowFeed(8)
    this.addText('列印測試')
    this.addRowFeed(8)
    this.addText('Print Test')

    // this.overwriteQRcode()

    // for (let i = 1; i < 40; i++) {
    //   for (let j = 0; j < 32; j++) {
    //     this.x = i * 10
    //     this.y = j * 10
    //     this.addText('.', 0, 5, 5, 0)
    //   }
    // }
    this.addPrint()
  }

  /**
   * 將字串轉換為 ASCII 指令
   * ???? end 是幹嘛用的？
   * @param {string} commandString
   * @param {boolean} [end] Default true.
   * @returns
   */
  _toASCII (commandString, end = true) {
    let array = []
    const length = commandString.length

    for (let i = 0; i < length; i++) {
      array.push(commandString.charCodeAt(i))
    }

    if (end) {
      array = array.concat([13, 10])
    }
    return array
  }

  /**
   * 轉換：英吋 -> 公分
   * @param {number} inches
   * @returns {number} centimeters
   */
  _convertInchesToCentimeter (inches) {
    // 1 inches = 2.54 cm
    return inches * 2.54
  }

  /**
   * 轉換：英吋 -> 毫米
   * @param {number} inches
   * @returns {number} millimeters
   */
  _convertInchesToMillimeters (inches) {
    // 1 cm = 10 mm
    return this._convertInchesToCentimeter(inches) * 10
  }

  /**
   * 轉換：英吋 -> 點
   * [Note] 200 DPI: 1 mm = 8 dots.
   *        300 DPI: 1mm = 12 dots.
   * @param {number} inches
   * @returns {number} dots
   */
  _convertInchesToDots (inches) {
    // ? DPI 可能不會固定為 200 DPI
    return Math.round(this._convertInchesToMillimeters(inches) * 8)
  }

  /**
   * ! NOT-support
   * @deprecated
   * @returns
   */
  addUnderline () {
    return this
  }

  /**
   * ! NOT-support
   * @deprecated
   * @returns
   */
  addSeparator () {
    return this
  }

  /**
   * ! NOT-support
   * @deprecated
   * @returns
   */
  addCommand () {
    return this
  }

  /**
   * ! NOT-support
   * @deprecated
   * @returns
   */
  drawerKick () {
    return this
  }

  /**
   * ! NOT-support
   * @deprecated
   * @returns
   */
  addTab () {
    return this
  }

  /**
   * ! NOT-support
   * @deprecated
   * @returns
   */
  addSetTabPosition () {
    return this
  }

  /**
   * ! NOT-support
   * @deprecated
   * @returns
   */
  enableASB () {
    return this
  }

  /**
   * ? 沒看到 Label 裡面有使用 addQRCode
   * @deprecated
   * @param {string} url
   * @param {number} [x] Default 20.
   * @param {number} [y] Default 10.
   * @param {number} [width] Default 4.
   * @param {string} [ecc] Default 'L'.
   * @param {string} [mode] Default 'A'.
   * @param {0 | 90 | 180 | 270} [rotation] Default 0.
   * @returns
   */
  addQRCode (url, x = 20, y = 10, width = 4, ecc = 'L', mode = 'A', rotation = 0) {
    const command = this._toASCII(`QRCODE ${x},${y},${ecc},${width},${mode},${rotation},M2,"${url}"`)
    console.log(`QRCODE ${x},${y},${ecc},${width},${mode},${rotation},M2,"${url}"`)
    this.writeNumbers(command)
    return this
  }

  /**
   * ? 沒看到 Label 裡面有使用 addImage
   * @deprecated
   * @param {*} file
   * @param {*} x
   * @param {*} y
   * @returns
   */
  addImage (file, x = 20, y = 10) {
    const command = this._toASCII(`PUTBMP ${x},${y},"${file}",8,80`)
    console.log(`PUTBMP ${x},${y},"${file}"`)
    this.writeNumbers(command)
    return this
  }

  /**
   * ? 沒看到 Label 裡面有使用 addLogo
   * @deprecated
   * @param {number} [x] Default 0.
   * @param {number} [y] Default 0.
   * @returns
   */
  addLogo (x = 0, y = 0) {
    const image = getState().app.settings?.logo?.data
    const data = image.data // ! 很明顯就錯了
    const height = image.height // ! 很明顯就錯了
    const width = image.width / 8 // ! 很明顯就錯了
    const command = this._toASCII(`BITMAP ${x},${y},${width},${height},0,`, false)
    console.log(`BITMAP ${x},${y},${width},${height},0`)
    this.writeNumbers(command)
    this.writeNumbers(data)
    this.writeNumbers([13, 10])
    return this
  }

  /**
   * ? 沒看到 Label 裡面有使用 addDownload
   * @deprecated
   * @param {string} file
   * @returns
   */
  addDownload (file) {
    const command = this._toASCII(`DOWNLOAD "${file}"`)
    console.log(`DOWNLOAD "${file}"`)
    this.writeNumbers(command)
    return this
  }

  /**
   * ? 沒看到 Label 裡面有使用 addFiles
   * @deprecated
   * @returns
   */
  addFiles () {
    const command = this._toASCII('FILES')
    console.log('FILES')
    this.writeNumbers(command)
    return this
  }

  /**
   * ? 沒看到 Label 裡面有使用 addBeep
   * @deprecated
   * @param {number} [level] Default 3.
   * @param {number} [interval] Default 200.
   * @returns
   */
  addBeep (level = 3, interval = 200) {
    const command = this._toASCII(`SOUND ${level},${interval}`)
    console.log(`SOUND ${level},${interval}`)
    this.writeNumbers(command)
    return this
  }
}
