import { BlurView } from 'expo-blur'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'

export default function MenuItemBackButton (props) {
  const { t } = useTranslation()
  const { handleBack } = props

  return (
    <View style={styles.container}>
      <View style={styles.blurBackground} />
      <BlurView tint='dark' intensity={20} style={styles.blurView}>
        <TouchableOpacity style={styles.contentContainer} onPress={handleBack}>
          <View style={styles.contentContainer}>
            <Image style={styles.buttonIcon} source={require('@icons/folder-back.png')} />
            <Text style={styles.buttonText} numberOfLines={1} ellipsizeMode='tail'>{t('app.common.back')}</Text>
          </View>
        </TouchableOpacity>
      </BlurView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: 105,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: 6,
    overflow: 'hidden',
    marginVertical: 4,
    marginHorizontal: 10,
  },
  blurBackground: {
    backgroundColor: colors.textPrimary + 'AB',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  blurView: {
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 4,
  },
  buttonIcon: {
    width: 44,
    height: 44,
    margin: 8,
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    fontWeight: '500',
    lineHeight: 17,
    textAlign: 'center',
    margin: 4,
  },
})
