export const PrinterBrand = {
  GPRINTER: 'GPrinter',
  HPRT: 'HPRT',
  EPSON: 'EPSON',
  TSC: 'TSC',
  STAR: 'STAR',
}

export const PrinterCommand = {
  ESC_POS: 'ESC/POS',
  TSPL_TSPL2: 'TSPL/TSPL2',
  STAR_GRAPHIC: 'STAR Graphic',
  STAR_PRNT: 'STAR/PRNT',
}

export const PrinterType = {
  GP_D801: 'GP_D801',
  GPRINTER: 'GPRINTER',
  EPSON: 'EPSON',
  SP700: 'SP700',
  TDP225: 'TDP225',
  TM_T88VI: 'TM_T88VI',
  TM_U220B: 'TM_U220B',
  TP808: 'TP808',
  TSP043: 'TSP043',
  TSP100III: 'TSP100III',
  TSP100IV: 'TSP100IV',
}

export const PrintMethod = {
  THERMAL: 'thermal', // 熱敏
  DOT: 'dot-matrix', // 針機
}

export const PrinterUsage = {
  KITCHEN_BATCH: 'kitchen_batch', // 廚房單
  ORDER_RECEIPT: 'order_receipt', // 收據單
  REPORT: 'report', // 報表，僅支援熱敏打印機
  LABEL: 'label', // 標籤
}

export const PrinterEncoding = {
  BIG5: 'big5',
  GBK: 'gbk',
  UTF8: 'utf8',
  TIS620: 'tis620', // 泰文
}

export const PrinterFontSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRALARGE: 'extra_large',
  MEDIUWIDTH: 'medium_width',
  LARGEWIDTH: 'large_width',
}

// 用來將 printerSetting customizedStyleSettings, customizedKitchenSettings, customizedLabelSettings 中的 fontSize 數字 0-4 轉換成 PrinterFontSize
export const StyleFontSizeToPrinterFontSizeMap = {
  0: PrinterFontSize.SMALL,
  1: PrinterFontSize.MEDIUM,
  2: PrinterFontSize.LARGE,
  5: PrinterFontSize.EXTRALARGE,
  3: PrinterFontSize.MEDIUWIDTH,
  4: PrinterFontSize.LARGEWIDTH,
}
