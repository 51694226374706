import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'

/**
 * @typedef CancelViaGatewayDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {(cancelViaGateway: boolean) => void} onSubmit
 */

/**
 *
 * @param {CancelViaGatewayDialogProps} props
 * @returns
 */
export default function CancelViaGatewayDialog (props) {
  const { t } = useTranslation()
  const {
    open,
    onClose,
    onSubmit,
  } = props

  return (
    <CenterModal
      enablePressOutsideClose
      title={t('app.component.cancelViaGatewayDialog.title')}
      visible={open}
      onClose={onClose}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.buttons}>
        <Button
          title={t('app.component.cancelViaGatewayDialog.withGateway')}
          onPress={() => {
            onSubmit(true)
          }}
        />
        <Button
          title={t('app.component.cancelViaGatewayDialog.withoutGateway')}
          onPress={() => {
            onSubmit(false)
          }}
        />
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 400,
    padding: 16,
    minHeight: 100,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})
