import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { actions, useDispatch } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import ToggleSwitch from '@/components/ToggleSwitch'
import colors from '@/theme/colors'

export default function ImageHeader (props) {
  const { style, menu, onPress, disableButton } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [deleted, setDeleted] = React.useState(menu?.deleted)
  const [sold, setSold] = React.useState(menu?.sold)

  React.useEffect(() => {
    if (menu) {
      setSold(menu?.sold)
      setDeleted(menu?.deleted)
    }
  }, [menu?.id])

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        style={[styles.imageContainer, onPress && { backgroundColor: colors.lightGray3 }]}
        disabled={!onPress}
        onPress={onPress}
      >
        {menu.image
          ? <Image source={Platform.OS !== 'web' ? { url: menu.image } : menu.image} style={StyleSheet.absoluteFill} />
          : onPress ? (
            <>
              <Image style={styles.uploadIcon} source={require('@icons/setting/upload.png')} />
              <Text style={styles.imgText}>{t('app.page.setting.image.upload')}</Text>
            </>)
            : <Text style={styles.imgText}>{t('app.page.setting.image.empty')}</Text>}
      </TouchableOpacity>
      {/* <View style={styles.textContainer}>
        <Text style={styles.text}>產品編號：001</Text>
        <Text style={styles.text}>系統編號：{menu.id}</Text>
      </View> */}
      {
        disableButton && (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={styles.toggleContainer}
              onPress={() => {
                dispatch(actions.menu.soldMenu(menu, !sold))
                setSold(!sold)
              }}
            >
              <Text style={styles.text}>{t('app.page.setting.menuPanel.menuInfo.sold')}</Text>
              <ToggleSwitch
                value={sold}
                size={20}
                onChangeValue={() => {
                  dispatch(actions.menu.soldMenu(menu, !sold))
                  setSold(!sold)
                  dispatch(actions.menu.soldMenu(menu, !sold))
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.toggleContainer}
              onPress={() => {
                dispatch(actions.menu.hideMenu(menu, !deleted))
                setDeleted(!deleted)
              }}
            >
              <Text style={styles.text}>{t('app.page.setting.menuPanel.menuInfo.hide')}</Text>
              <ToggleSwitch
                value={deleted}
                size={20}
                onChangeValue={() => {
                  dispatch(actions.menu.hideMenu(menu, !deleted))
                  setDeleted(!deleted)
                  dispatch(actions.menu.hideMenu(menu, !deleted))
                }}
              />
            </TouchableOpacity>
          </View>
        )
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: 24,
    width: '100%',
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageContainer: {
    height: 90,
    width: 90,
    backgroundColor: colors.light,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  textContainer: {
    flex: 1,
    flexWrap: 'wrap',
  },
  toggleContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 22,
  },
  uploadIcon: {
    height: 30,
    width: 30,
    marginBottom: 4,
  },
  imgText: {
    color: colors.gray,
    fontSize: 14,
    fontWeight: '500',
  },
})
