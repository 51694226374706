const ecrPayMethods = {
  CREDIT_CARD: {
    BBMSL: 'CREDIT',
    EFT_PAY: 'EDC',
  },
  ALI_PAY: {
    BBMSL: 'ALIPAY',
  },
  WECHAT_PAY: {
    BBMSL: 'wechat',
  },
  QR_PAY: {
    BBMSL: 'QRPAY',
    EFT_PAY: 'EWALLET',
    GLOBAL_PAYMENT: 'qrPay',
  },
  OCTOPUS: {
    BBMSL: 'OCTOPUS',
    EFT_PAY: 'OCTOPUS',
    GLOBAL_PAYMENT: 'octopus',
  },
  PAYME: {
    EFT_PAY: 'SHOWQR',
    GLOBAL_PAYMENT: 'payme',
  },
}

export default ecrPayMethods
