import { StyleSheet } from 'react-native'
import Column from '@root/src/components/Column'
import Header from './Header.jsx'
import MemberInfo from './MemberInfo/index.jsx'
import MemberList from './MemberList/index.jsx'
import React, { useState } from 'react'
import RightNavBar from '@root/src/components/RightNavBar'
import Row from '@root/src/components/Row'
import colors from '@root/src/theme/colors'

function Member () {
  const [member, setMember] = useState(null)
  const [filter, setFilter] = useState('')

  return (
    <Row style={styles.container}>
      <Column style={[styles.content, { zIndex: 99, elevation: 7 }]}>
        <Header member={member} setFilter={setFilter} />
        <Row style={{ flex: 1 }}>
          {!member
            ? <MemberList setMember={setMember} filter={filter} />
            : <MemberInfo member={member} setMember={setMember} />}
        </Row>
      </Column>
      <RightNavBar />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray2,
  },
  content: {
    flex: 1,
  },
})

export default Member
