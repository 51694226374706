import { StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { numberWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import Column from '@/components/Column'
import Row from '@/components/Row'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef PriceSummaryProps
 * @property {IAppOrder} order
 */

/**
 *
 * @param {PriceSummaryProps} props
 * @returns
 */
export default function PriceSummary (props) {
  const { order } = props
  const { t } = useTranslation()

  const rounding = useSelector(state => state.merchant.data?.rounding)

  const roundText = {
    off: t('app.component.priceSummary.off'),
    up: t('app.component.priceSummary.up'),
    down: t('app.component.priceSummary.down'),
  }

  if (!order) {
    return (
      <>
      </>
    )
  }

  const items = _.flatMap(order?.batches, batch => batch.items)
  const itemsCount = _.sumBy(items, 'quantity')
  const minOrderAmount = _.get(order?.shipping, 'discounts.0.minOrderAmount', 0)
  const shippingFee = order?.shipping?.customerAmount || 0
  const shippingDiscount = Math.min(order?.subtotal >= minOrderAmount ? _.get(order?.shipping, 'discounts.0.amount', 0) : 0, shippingFee)
  const discountedShippingFee = addNumberWithRounding(shippingFee, shippingDiscount * -1)
  const isMerchantOrder = (order.from === 'MERCHANT' || order.deliveryType === 'table')
  const roundedAmount = addNumberWithRounding(order.roundedTotal, order.total * -1)
  const discountTotal = _.get(order, 'calculatedDiscountTotal') || 0
  const calculatedDiscountTotal = -(Math.abs(discountTotal) - Math.abs(shippingDiscount))
  let restaurantDiscount = 0
  let dimorderDiscount = 0
  let surcharge = 0
  const actualAmount = 0
  let riceCoin = 0
  let subtotal = 0

  if (order.apiVersion === '1.0.0') {
    subtotal = order.originalTotal

    if (order.discount?.offeredBy === 'DimOrder') {
      dimorderDiscount = order.discountTotal * -1
    } else {
      restaurantDiscount = order.discountTotal * -1
    }

    surcharge = order.surchargeTotal
  } else {
    subtotal = order.subtotal

    order.modifiers.forEach(modifier => {
      if (!modifier.deletedAt) {
        switch (modifier.type) {
          case 'PROMOCODE': {
            if (modifier.offeredBy === 'DimOrder') {
              dimorderDiscount = addNumberWithRounding(dimorderDiscount, modifier.calculatedAmount)
            } else {
              restaurantDiscount = addNumberWithRounding(restaurantDiscount, modifier.calculatedAmount)
            }
            break
          }
          case 'DISCOUNT': {
            if (modifier.offeredBy === 'DimOrder') {
              dimorderDiscount = addNumberWithRounding(dimorderDiscount, modifier.calculatedAmount)
            } else {
              restaurantDiscount = addNumberWithRounding(restaurantDiscount, modifier.calculatedAmount)
            }
            break
          }
          case 'SURCHARGE': {
            surcharge = modifier.calculatedAmount
            break
          }
          case 'RICECOIN': {
            riceCoin = modifier.calculatedAmount
            break
          }
        }
      }
    })
  }

  // actualAmount = addNumberWithRounding(subtotal, restaurantDiscount)

  function addNumberWithRounding (num1, num2 = 0) {
    const number1 = num1 * 100
    const number2 = num2 * 100
    const total = Math.round(number1 + number2) / 100
    return total
  }

  function getPaidAmount (payments) {
    // 將已付的 payment amount 加總
    // 只看 paid 否則會加到 pending 或 cancelled
    // 不使用 paidAmount 因為會加到小費
    return _.sumBy(payments, (payment) => {
      return payment.status === 'paid' ? payment.amount : 0
    })
  }

  return (
    <Column style={styles.container}>
      {/* price summary */}
      <Column style={styles.summary}>
        <Row>
          <Text style={styles.summaryText}>
            {`${t('app.component.priceSummary.count')}：${itemsCount}，${t('app.component.priceSummary.subtotal')}：`}
          </Text>
          <Text style={[styles.summaryText, styles.summaryValue]}>
            {/* { orderAppLib.libs.getDisplayRoundNumber(order?.originalTotal, rounding) } */}
            {numberWithCommas(addNumberWithRounding(subtotal).toFixed(1))}
          </Text>
        </Row>
        {calculatedDiscountTotal !== 0 && (
          <Row>
            <Text style={styles.summaryText}>
              {t('app.component.priceSummary.discount')}：
            </Text>
            <Text style={[styles.summaryText, styles.summaryValue]}>
              {numberWithCommas(addNumberWithRounding(calculatedDiscountTotal).toFixed(2))}
            </Text>
          </Row>
        )}
        {order?.surchargeTotal > 0 && (
          <Row>
            <Text style={styles.summaryText}>
              {t('app.component.priceSummary.surcharge')}：
            </Text>
            <Text style={[styles.summaryText, styles.summaryValue]}>
              {numberWithCommas(addNumberWithRounding(order?.surchargeTotal).toFixed(2))}
            </Text>
          </Row>
        )}
        {/* {restaurantDiscount !== 0 && (
          <Row>
            <Text style={styles.summaryText}>
              {`${t('app.component.priceSummary.restaurantDiscount')}：`}
            </Text>
            <Text style={[styles.summaryText, styles.summaryValue]}>
              {String(addNumberWithRounding(restaurantDiscount).toFixed(1))}
            </Text>
          </Row>
        )}
        {dimorderDiscount !== 0 && (
          <Row>
            <Text style={styles.summaryText}>
              {`${t('app.component.priceSummary.dimorderDiscount')}：`}
            </Text>
            <Text style={[styles.summaryText, styles.summaryValue]}>
              {String(addNumberWithRounding(dimorderDiscount).toFixed(1))}
            </Text>
          </Row>
        )}
        {riceCoin !== 0 && (
          <Row>
            <Text style={styles.summaryText}>
              RICECOIN：
            </Text>
            <Text style={[styles.summaryText, styles.summaryValue]}>
              {String(addNumberWithRounding(riceCoin).toFixed(1))}
            </Text>
          </Row>
        )} */}
        {(isMerchantOrder && Boolean(rounding?.type) && roundedAmount !== 0) && (
          <Row>
            <Text style={styles.summaryText}>
              {`${roundText[rounding?.type]}：`}
            </Text>
            <Text style={[styles.summaryText, styles.summaryValue]}>
              {roundedAmount}
            </Text>
          </Row>
        )}
        {(discountedShippingFee > 0 && order?.status !== 'cancelled') && (
          <Row>
            <Text style={styles.summaryText}>
              {t('app.component.priceSummary.shipping')}：
            </Text>
            <Text style={[styles.summaryText, styles.summaryValue]}>
              {numberWithCommas(addNumberWithRounding(discountedShippingFee).toFixed(1))}
            </Text>
          </Row>
        )}
        <Row>
          <Text style={styles.summaryText}>
            {t('app.component.priceSummary.total')}：
          </Text>
          <Text style={[styles.summaryText, styles.summaryValue]}>
            {numberWithCommas(order.roundedTotal)}
          </Text>
        </Row>
        {order?.payments.length > 0 && (
          <Row>
            <Text style={styles.summaryText}>
              {t('app.component.priceSummary.paidAmount')}：
            </Text>
            <Text style={[styles.summaryText, styles.summaryValue]}>
              {numberWithCommas(getPaidAmount(order?.payments))}
            </Text>
          </Row>
        )}
        {/* {showTotal && (
          isMerchantOrder
            ? (
              <Row>
                <Text style={styles.summaryText}>
                  {t('app.component.priceSummary.total')}：
                </Text>
                <Text style={[styles.summaryText, styles.summaryValue]}>
                  {order.roundedTotal}
                </Text>
              </Row>)
            : (
              <Row>
                <Text style={styles.summaryText}>
                  {t('app.component.priceSummary.actualAmount')}：
                </Text>
                <Text style={[styles.summaryText, styles.summaryValue]}>
                  {actualAmount}
                </Text>
              </Row>
            )
        )} */}
      </Column>
    </Column>

  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  summary: {
    minHeight: 80,
    alignItems: 'flex-end',
    backgroundColor: colors.white,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  summaryText: {
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.textPrimary,
    textAlign: 'right',
  },
  summaryValue: {
    width: 90,
  },
  total: {
    height: 60,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    ...shadows.default,
  },
  totalText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.white,
  },
})
