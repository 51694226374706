import { Platform, StyleSheet, Text, TouchableWithoutFeedback } from 'react-native'
import React from 'react'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'
import sentry from '@/libs/sentry'
import usePressedTimes from '@/hooks/usePressedTimes'

import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'

const PACKAGE_JSON = require('@root/package.json')
const CRASH_OPTIONS = [{ value: 'error', display: 'throw new Error()' }]

if (Platform.OS !== 'web') {
  CRASH_OPTIONS.push({ value: 'native', display: 'Sentry.nativeCrash()' })
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function VersionLabel (props) {
  const configEnv = useSelector(state => state.config.env)
  const [crashSelectDialogOpen, setCrashSelectDialogOpen] = React.useState(false)
  const { handlePress } = usePressedTimes(() => setCrashSelectDialogOpen(true)) // 於 5 秒內連點 10 次打開 Dialog

  const version = React.useMemo(() => {
    let version = PACKAGE_JSON.version
    if (configEnv && configEnv !== 'production') {
      version += `\n${configEnv}`
    }
    return version
  }, [configEnv])

  return (
    <>
      <TouchableWithoutFeedback onPress={handlePress}>
        <Text style={styles.label}>
          {version}
        </Text>
      </TouchableWithoutFeedback>
      <OptionsSelectDialog
        title='Crash Test'
        open={crashSelectDialogOpen}
        onClose={() => setCrashSelectDialogOpen(false)}
        closeOnSelect
        options={CRASH_OPTIONS}
        onSubmit={(crashMethod) => {
          if (crashMethod === 'error') {
            console.log('throw crash test error')
            throw new Error('Crash test.')
          }
          if (crashMethod === 'native') {
            console.log('call sentry.nativeCrash()')
            sentry.nativeCrash()
          }
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  label: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 80,
    textAlign: 'center',
    paddingHorizontal: 4,
    color: colors.white,
    elevation: 8,
    shadowOpacity: 0.16,
    textShadowColor: colors.black,
    textShadowOffset: {
      width: 0,
      height: 3,
    },
    textShadowRadius: 6,
  },
})
