import { StyleSheet } from 'react-native'
import Column from '@/components/Column'
import FilterPanel from './FilterPanel'
import Header from './Header'
import OrderDetail from './OrderDetail'
import OrderList from './OrderList'
import React, { useState } from 'react'
import RightNavBar from '@/components/RightNavBar'
import Row from '@/components/Row'

export default function OrderHistory (props) {
  const [openFilter, setOpenFilter] = useState(false)

  return (
    <Row style={styles.container}>
      <Column style={[styles.content, { zIndex: 99, elevation: 7 }]}>
        <Header
          onOpenFilter={() => setOpenFilter(true)}
        />
        <Row style={{ flex: 1 }}>
          <OrderList />
          <OrderDetail />
        </Row>
      </Column>
      <RightNavBar />
      {openFilter && <FilterPanel onClose={() => setOpenFilter(false)} />}
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
})
