import { StyleSheet, View } from 'react-native'
import { useSelector } from '@/redux'
import BackButton from '../CategoryBar/BackButton'
import Breadcrumb from './Breadcrumb'
import CategoryBar from '../CategoryBar'
import FilterMenuItemByTimeButton from '../FilterMenuItemByTimeButton'
import PictureModeSwitchButton from '../PictureModeSwitchButton'
import React from 'react'
import SearchInput from '../SearchInput'
import _ from 'lodash'
import i18n from '@/i18n'

export default function TextModeHeader (props) {
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const selectedSet = useSelector(state => state.menu.selectedSet)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  const isSearchExpanded = useSelector(state => state.menu.isSearchExpanded)
  const isSet = Boolean(selectedSet)
  const lang = String(i18n.language ?? 'zh').split('-')[0]

  if (!selectedCategory) return null

  return (
    <View style={styles.container}>
      {isSet ? <BackButton /> : <Breadcrumb lastOnly={isSearchExpanded} />}

      <View style={[styles.leftView, !isSearchExpanded && styles.fullWideContainer]}>
        {selectedSetStep && (
          <CategoryBar
            isSet
            setName={_.get(selectedSet?.localeNames, lang, selectedSet.name)}
            categoryItems={selectedSet.steps}
            selectedId={selectedSetStep?.id}
          />
        )}
      </View>

      {!isSet && <SearchInput />}
      {!isSet && <PictureModeSwitchButton />}
      {!isSet && <FilterMenuItemByTimeButton />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 32,
    marginVertical: 8,
  },
  leftView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullWideContainer: {
    flex: 1,
  },
})
