import { FlatList, StyleSheet, Text, View } from 'react-native'
import { produce } from 'immer'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { PrinterType } from '@/constants/printer'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import Button from '@/components/buttons/Button'
import CheckBox from '@/components/CheckBox'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 *
 * @param {*} props
 * @returns
 */
export default function PrinterPriorityPanel (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const printerSetting = useSelector(state => state.printer.printerSetting)
  const { printers, priority: prioritySettings } = printerSetting

  const [priorities, setPriorities] = React.useState(() => {
    const priorities = []
    _.forEach(prioritySettings, (printerPriority, ip) => {
      if (!Number.isNaN(printerPriority) && printerPriority > -1) {
        priorities.push(ip)
      }
    })
    return priorities
  })

  const filteredPrinters = printers?.filter(p => p.printerType !== PrinterType.TDP225)

  React.useEffect(() => {
    resetPriorities()
  }, [prioritySettings])

  const resetPriorities = () => {
    const priorities = []
    _.forEach(prioritySettings, (printerPriority, ip) => {
      if (!Number.isNaN(printerPriority) && printerPriority > -1) {
        priorities.push(ip)
      }
    })
    setPriorities(priorities)
  }

  const handleSave = async () => {
    const newPrinterSetting = produce(printerSetting, (draft) => {
      draft.priority = {}
      priorities.forEach((ip, index) => {
        filteredPrinters.forEach((printer) => {
          if (printer.id === ip) {
            draft.priority[ip] = index + 1
          }
        })
      })
    })

    dispatch(actions.printer.updatePrinterSetting({ printerSetting: newPrinterSetting, showSuccessMessage: true }))
  }

  const renderItem = ({ item: ip, index, drag, isActive }) => {
    const printer = filteredPrinters.find(printer => printer.id === ip)
    return (
      <SettingListItem
        style={[isActive && shadows.slight]}
        draggable
        dragging={isActive}
        onDrag={drag}
        divider={!isActive && index !== priorities.length - 1}
      >
        <SettingListItemText text={_.get(printer, 'name') || _.get(printer, 'id')} />
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.printer.select') + t('app.page.setting.printer.backup')} />
        </Header>
        <SettingList>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={filteredPrinters}
            ListEmptyComponent={(
              <View style={styles.emptyView}>
                <Text>{t('app.page.setting.printer.noDevice')}</Text>
              </View>
            )}
            keyExtractor={(item) => item.id}
            renderItem={({ item: printer, index }) => {
              const priotiryIndex = priorities.findIndex(ip => ip === printer.id)

              return (
                <SettingListItem
                  key={printer.id}
                  onPress={() => {

                  }}
                  divider={index !== filteredPrinters.length - 1}
                >
                  <SettingListItemText text={printer.name || printer.id} />
                  <SettingListItemRightAction style={styles.labels}>
                    <CheckBox
                      value={priotiryIndex > -1}
                      onChangeValue={() => {
                        if (priotiryIndex > -1) {
                          const newPriorities = [...priorities]
                          newPriorities.splice(priotiryIndex, 1)
                          setPriorities(newPriorities)
                        } else {
                          setPriorities([...priorities, printer.id])
                        }
                      }}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              )
            }}
          />
        </SettingList>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.printer.backup') + t('app.page.setting.printer.seq')} />
        </Header>
        <View style={styles.content}>
          {priorities?.length === 0 && (
            <SettingList>
              <View style={styles.emptyView}>
                <Text>{t('app.page.setting.printer.noSelected') + t('app.page.setting.printer.backup')}</Text>
              </View>
            </SettingList>
          )}
          <SettingList
            style={[styles.settingList, { height: priorities.length * 41 }]}
          >
            <DraggableFlatList
              containerStyle={{ flex: 1 }}
              data={priorities}
              keyExtractor={ip => ip}
              renderItem={renderItem}
              onDragEnd={({ data }) => {
                setPriorities(data)
              }}
              removeClippedSubviews={false}
            />

          </SettingList>
        </View>

        <View style={styles.buttons}>
          <Button
            textBold
            title={t('app.common.cancel')}
            style={styles.button}
            backgroundColor={colors.primary}
            onPress={resetPriorities}
          />
          <Button
            textBold
            title={t('app.common.save')}
            style={styles.button}
            backgroundColor={colors.darkSecondary}
            onPress={handleSave}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  settingList: {
    maxHeight: '100%',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labels: {
    padding: 0,
  },
  iconButton: {
    marginRight: -6,
    padding: 6,
  },
  iconButtonIcon: {
    width: 25,
    height: 25,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
