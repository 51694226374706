import React from 'react'
import _ from 'lodash'

import { PrintStyle } from '@/constants/printing'
import { useTranslation } from 'react-i18next'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * onSelect: () => void,
 * }} props
 */
export default function PrintingSettingList (props) {
  const { setting, title, path, divider, selecting, onChange, onSelect, children, atLast = false } = props
  const { t } = useTranslation()
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const type = _.get(setting, `${path}.type`)

  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 5, display: t('app.page.setting.printer.size5') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]
  const textAlignOptions = [
    { value: 'left', display: t('app.page.setting.printer.left') },
    { value: 'center', display: t('app.page.setting.printer.center') },
    { value: 'right', display: t('app.page.setting.printer.right') },
  ]
  const timeFormatOptions = [
    { value: 'YYYY/MM/DD HH:mm:ss', display: 'YYYY/MM/DD HH:mm:ss' },
    { value: 'MM/DD HH:mm', display: 'MM/DD HH:mm' },
  ]
  const separateFormatOptions = [
    { value: '  ', display: t('app.page.setting.printer.customizedSetting.space') },
    { value: ', ', display: t('app.page.setting.printer.customizedSetting.comma') },
    { value: '\n', display: t('app.page.setting.printer.customizedSetting.newLine') },
  ]
  const colorOptions = [
    { value: PrintStyle.COLOR.RED, display: t('app.page.setting.printer.customizedSetting.red') },
    { value: PrintStyle.COLOR.BLACK, display: t('app.page.setting.printer.customizedSetting.black') },
  ]

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig.title}
        onClose={() => {
          setOptionsPanelConfig(null)
          onSelect(false)
        }}
        {...optionsPanelConfig}
      />
    )
  }

  if (selecting) {
    return <></>
  }

  return (
    <>
      <SettingListItem divider={!_.get(setting, `${path}.enable`) && divider}>
        <SettingListItemText text={title} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={_.get(setting, `${path}.enable`)}
            onChangeValue={() => onChange(`${path}.enable`, !_.get(setting, `${path}.enable`))}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        _.get(setting, `${path}.enable`) && (
          <>
            {!atLast && children}
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={_.get(setting, `${path}.bold`)}
                  onChangeValue={() => onChange(`${path}.bold`, !_.get(setting, `${path}.bold`))}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.underline')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={_.get(setting, `${path}.underline`)}
                  onChangeValue={() => onChange(`${path}.underline`, !_.get(setting, `${path}.underline`))}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
              value={textStyleOptions.find(t => t.value === _.get(setting, `${path}.fontSize`))?.display}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.fontSize'),
                  defaultValue: _.get(setting, `${path}.fontSize`) || 0,
                  onChangeValue: (value) => onChange(`${path}.fontSize`, value),
                  options: textStyleOptions,
                  closeOnSelect: true,
                })
                onSelect(true)
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.align')}
              value={textAlignOptions.find(t => t.value === _.get(setting, `${path}.align`))?.display}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.align'),
                  defaultValue: _.get(setting, `${path}.align`) || 0,
                  onChangeValue: (value) => onChange(`${path}.align`, value),
                  options: textAlignOptions,
                  closeOnSelect: true,
                })
                onSelect(true)
              }}
            />
            <SettingListEnterRow
              label={'-' + t('app.page.setting.printer.customizedSetting.color')}
              value={colorOptions.find(t => t.value === _.get(setting, `${path}.color`))?.display}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.color'),
                  defaultValue: _.get(setting, `${path}.color`) || 0,
                  onChangeValue: (value) => onChange(`${path}.color`, value),
                  options: colorOptions,
                  closeOnSelect: true,
                })
                onSelect(true)
              }}
            />
            {
              type === 'time' && (
                <SettingListEnterRow
                  label={'-' + t('app.page.setting.printer.customizedSetting.format')}
                  value={timeFormatOptions.find(t => t.value === _.get(setting, `${path}.format`))?.display}
                  onPress={() => {
                    setOptionsPanelConfig({
                      title: t('app.page.setting.printer.customizedSetting.format'),
                      defaultValue: _.get(setting, `${path}.format`) || 0,
                      onChangeValue: (value) => onChange(`${path}.format`, value),
                      options: timeFormatOptions,
                      closeOnSelect: true,
                    })
                    onSelect(true)
                  }}
                />
              )
            }
            <SettingListEnterRow
              divider={divider && !atLast}
              label={'-' + t('app.page.setting.printer.customizedSetting.separateFormat')}
              value={separateFormatOptions.find(t => t.value === _.get(setting, `${path}.separateFormat`))?.display}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.page.setting.printer.customizedSetting.separateFormat'),
                  defaultValue: _.get(setting, `${path}.separateFormat`) || 0,
                  onChangeValue: (value) => onChange(`${path}.separateFormat`, value),
                  options: separateFormatOptions,
                  closeOnSelect: true,
                })
                onSelect(true)
              }}
            />
            {atLast && children}
          </>
        )
      }
    </>
  )
}
