import { FlatList, KeyboardAvoidingView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Icon } from 'react-native-elements'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import getNameWithNumber from '@/libs/getNameWithNumber'

import BackNavigator from '@/components/Setting/BackNavigator'
import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 * @typedef EditPageProps
 * @property {*} rule
 * @property {() => void} onClose
 * @property {() => void} onDelete
 */

/**
 *
 * @param {EditPageProps} props
 * @returns
 */
export default function EditPage (props) {
  const { rule, onClose, onDelete } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [editingRule, setEditingRule] = useImmer(rule)
  const rules = useSelector(state => state.merchant.data.comboRule.rules) || []
  const groups = useSelector(state => state.merchant.data.comboRule.groups) || []
  const [expanded, setExpanded] = React.useState(null)
  const mandatoryItems = getDisplayText(editingRule.mandatoryItems)
  const discountedItems = getDisplayText(editingRule.discountedItems)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const [offset, setOffset] = React.useState(-200)
  const [keyboardAvoidingViewEnabled, setKeyboardAvoidingViewEnabled] = React.useState(false)
  const groupOptions = _.map(groups, g => {
    return {
      value: g.id,
      display: g.name,
    }
  })
  const weekdayOptions = [
    { value: 0, display: t('app.constant.weekday.sun') },
    { value: 1, display: t('app.constant.weekday.mon') },
    { value: 2, display: t('app.constant.weekday.tue') },
    { value: 3, display: t('app.constant.weekday.wed') },
    { value: 4, display: t('app.constant.weekday.thur') },
    { value: 5, display: t('app.constant.weekday.fri') },
    { value: 6, display: t('app.constant.weekday.sat') },
  ]

  function getDiscountText (modifier) {
    if (!modifier || (modifier.percent === 0 && modifier.amount === 0)) {
      return ''
    }
    if (modifier.type === 'DISCOUNT') {
      return modifier.percent > 0
        ? `(-${modifier.percent}%)`
        : `(-$${modifier.amount})`
    } else if (modifier.amount > 0) {
      return `(+$${modifier.amount})`
    }
    return ''
  }

  function getDisplayText (items) {
    return _.map(items, m => {
      const group = _.find(groups, g => g.id === m.groupId)
      const discountText = getDiscountText(m.modifier)
      return `${m.match > 1 ? `${m.match}x` : ''}${group?.name}${discountText}`
    }).join('+')
  }

  const onSave = () => {
    const updatedRules = [...rules]
    if (editingRule.id) {
      const index = _.findIndex(updatedRules, r => r.id === editingRule.id)
      updatedRules[index] = editingRule
    } else {
      let name = editingRule.name
      if (!name.trim()) {
        const names = _.map(updatedRules, r => r.name)
        const text = t('app.routes.setting.order.comboRule')
        name = getNameWithNumber(names, text)
      }
      updatedRules.push({ ...editingRule, id: uuid(), name })
    }
    dispatch(actions.merchant.updateComboRule({ groups, rules: updatedRules }))
    onClose()
  }

  const renderItem = ({ item, index }) => {
    const group = _.find(groups, g => g.id === item.groupId)
    const discountText = getDiscountText(item.modifier)
    const selected = selectedItem?.id === item.id
    return (
      <>
        <SettingListItem
          divider={expanded !== 'discountedItems' || _.findLastIndex(editingRule[expanded]) !== index || selected}
          onPress={() => setSelectedItem((selected || expanded !== 'discountedItems') ? null : item)}
        >
          <TouchableOpacity
            onPress={() => {
              setEditingRule(draft => {
                if (draft[expanded][index].match > 1) {
                  draft[expanded][index].match -= 1
                } else {
                  _.remove(draft[expanded], r => r.id === item.id)
                }
              })
              setOptionsPanelConfig(null)
            }}
          >
            <Icon
              size={35}
              name='remove'
              color={colors.primary}
            />
          </TouchableOpacity>
          <SettingListItemText
            style={styles.content}
            text={`${group.name} (${t('app.routes.setting.order.comboRulesSetting.num')}${item.match})${discountText}`}
          />
          <TouchableOpacity
            onPress={() => {
              setEditingRule(draft => {
                draft[expanded][index].match += 1
              })
              setOptionsPanelConfig(null)
            }}
          >
            <Icon
              size={35}
              name='add'
              color={colors.primary}
            />
          </TouchableOpacity>
        </SettingListItem>
        {
          selected && (
            <SettingListItem
              divider={expanded !== 'discountedItems' || _.findLastIndex(editingRule[expanded]) !== index}
            >
              <SettingListItemText
                text={t('app.routes.setting.order.comboRulesSetting.price')}
              />
              <SettingListItemRightAction>
                <SettingListInput
                  value={String(item.modifier.amount)}
                  onChangeText={(value) => {
                    if (!_.isNaN(Number(value))) {
                      setEditingRule(draft => {
                        draft[expanded][index].modifier.amount = value
                      })
                    }
                  }}
                  onFocus={() => {
                    setKeyboardAvoidingViewEnabled(true)
                    setOffset(-200 + index * 40)
                  }}
                  onBlur={(event) => {
                    setEditingRule(draft => {
                      draft[expanded][index].modifier.amount = Number(event.nativeEvent.text)
                    })
                  }}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          )
        }
      </>
    )
  }

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView
        behavior='position'
        style={styles.contentContainer}
        enabled={keyboardAvoidingViewEnabled}
        keyboardVerticalOffset={offset}
      >
        <View>
          <BackNavigator
            text={t('app.routes.setting.order.comboRule')}
            onPress={onClose}
          />
          <SettingList>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.name')} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={editingRule.name}
                  onChangeText={(text) => {
                    setEditingRule(draft => {
                      draft.name = text
                    })
                  }}
                  onFocus={() => setKeyboardAvoidingViewEnabled(false)}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              divider
              label={t('app.page.setting.menuPanel.menuInfo.date')}
              value={!_.isEmpty(editingRule.weekdays)
                ? editingRule.weekdays.map(d =>
                  weekdayOptions.find(o => o.value === d)?.display).join()
                : t('app.common.null')}
              onPress={() => {
                setOptionsPanelConfig({
                  title: t('app.constant.weekday.week'),
                  multiple: true,
                  defaultValue: editingRule.weekdays,
                  onChangeValue: (value) => {
                    setEditingRule(draft => {
                      draft.weekdays = value.sort()
                    })
                  },
                  options: weekdayOptions,
                })
              }}
            />
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.start')} />
              <SettingListItemRightAction>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                  <TouchableOpacity
                    onPress={() => {
                      dispatch(actions.app.showDateTimePicker(
                        'time',
                        moment({
                          hour: editingRule.startTime.hour,
                          minute: editingRule.startTime.minute,
                        }).toDate(),
                        (value) => {
                          setEditingRule(draft => {
                            draft.startTime.hour = Number(moment(value).format('HH'))
                            draft.startTime.minute = Number(moment(value).format('mm'))
                          })
                        },
                        moment({
                          hour: 0,
                          minute: 0,
                        }).toDate(),
                      ))
                    }}
                  >
                    <Text>{`${_.padStart(editingRule.startTime.hour, 2, '0')}:${_.padStart(editingRule.startTime.minute, 2, '0')}`}</Text>
                  </TouchableOpacity>
                </View>
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.end')} />
              <SettingListItemRightAction>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                  <TouchableOpacity
                    onPress={() => {
                      dispatch(actions.app.showDateTimePicker(
                        'time',
                        moment({
                          hour: editingRule.endTime.hour,
                          minute: editingRule.endTime.minute,
                        }).toDate(),
                        (value) => {
                          setEditingRule(draft => {
                            draft.endTime.hour = Number(moment(value).format('HH'))
                            draft.endTime.minute = Number(moment(value).format('mm'))
                          })
                        },
                        moment({
                          hour: 0,
                          minute: 0,
                        }).toDate(),
                      ))
                    }}
                  >
                    <Text>{`${_.padStart(editingRule.endTime.hour, 2, '0')}:${_.padStart(editingRule.endTime.minute, 2, '0')}`}</Text>
                  </TouchableOpacity>
                </View>
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListEnterRow
              divider
              label={t('app.routes.setting.order.comboRulesSetting.mandatoryItems')}
              value={mandatoryItems}
              onPress={() => {
                setExpanded('mandatoryItems')
                setOptionsPanelConfig({
                  title: `${t('app.routes.setting.order.comboRulesSetting.group') + t('app.page.setting.printer.setting')}`,
                  multiple: true,
                  defaultValue: _.flatMap(editingRule.mandatoryItems, 'groupId'),
                  onChangeValue: (value) => {
                    setEditingRule(draft => {
                      const addGroups = _.difference(value, _.flatMap(editingRule.mandatoryItems, 'groupId')).map(groupId => {
                        return {
                          type: 'group',
                          itemIds: _.find(groups, g => g.id === groupId)?.itemIds,
                          groupId,
                          match: 1,
                          id: uuid(),
                        }
                      })
                      draft.mandatoryItems = _.filter(editingRule.mandatoryItems, group => value.includes(group.groupId)).concat(addGroups)
                    })
                  },
                  options: groupOptions,
                })
              }}
            />
            {
              expanded === 'mandatoryItems' && (
                <FlatList
                  data={editingRule.mandatoryItems}
                  renderItem={renderItem}
                  keyExtractor={(item, index) => String(item.id) + index}
                  showsVerticalScrollIndicator={false}
                />
              )
            }
            <SettingListEnterRow
              label={t('app.routes.setting.order.comboRulesSetting.discountedItems')}
              value={discountedItems}
              onPress={() => {
                setExpanded('discountedItems')
                setSelectedItem(_.head(editingRule.discountedItems))
                setOptionsPanelConfig({
                  title: `${t('app.routes.setting.order.comboRulesSetting.group') + t('app.page.setting.printer.setting')}`,
                  multiple: true,
                  defaultValue: _.flatMap(editingRule.discountedItems, 'groupId'),
                  onChangeValue: (value) => {
                    setEditingRule(draft => {
                      const addGroups = _.difference(value, _.flatMap(editingRule.discountedItems, 'groupId')).map(groupId => {
                        return {
                          type: 'group',
                          itemIds: _.find(groups, g => g.id === groupId)?.itemIds,
                          groupId,
                          match: 1,
                          id: uuid(),
                          modifier: {
                            type: 'COMBORULE',
                            amount: 0,
                            percent: 0,
                          },
                        }
                      })
                      setSelectedItem(_.last(addGroups))
                      draft.discountedItems = _.filter(editingRule.discountedItems, group => value.includes(group.groupId)).concat(addGroups)
                    })
                  },
                  options: groupOptions,
                })
              }}
              divider={expanded === 'discountedItems'}
            />
            {
              expanded === 'discountedItems' && (
                <FlatList
                  data={editingRule.discountedItems}
                  renderItem={renderItem}
                  keyExtractor={(item, index) => String(item.id) + index}
                  showsVerticalScrollIndicator={false}
                />
              )
            }
          </SettingList>
        </View>
      </KeyboardAvoidingView>
      <View style={styles.contentContainer}>
        <View style={{ flex: 1 }}>
          {
            Boolean(optionsPanelConfig) && (
              _.isEmpty(optionsPanelConfig.options)
                ? (
                  <>
                    <Header />
                    <SettingList>
                      <View style={styles.emptyView}>
                        <Text>{t('app.page.setting.printer.noSetting')}</Text>
                      </View>
                    </SettingList>
                  </>
                )
                : (
                  <OptionsPanel
                    key={optionsPanelConfig?.title}
                    {...optionsPanelConfig}
                  />
                )
            )
          }
        </View>
        <View style={styles.buttons}>
          {
            editingRule?.id && (
              <Button
                textBold
                title={t('app.common.delete')}
                backgroundColor={colors.primary}
                onPress={onDelete}
              />
            )
          }
          <Button
            textBold
            title={t('app.common.save')}
            backgroundColor={colors.darkSecondary}
            onPress={onSave}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
    justifyContent: 'space-between',
  },
  rightText: {
    justifyContent: 'flex-end',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  content: {
    padding: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boldText: {
    fontWeight: 'bold',
    color: colors.primary,
  },
})
