import ActionTypes from './ActionTypes'
import _ from 'lodash'
import _set from 'lodash/set'
import produce from 'immer'

/** @type {IAppState} */
export const initialState = {
  isDataInit: false,
  isLocalDbCleanCompleted: false,
  loadingActions: [],
  alerts: {},
  webSocketStatus: null,
  showWebSockectWarningAt: '',
  dateTimeDialog: { visible: false, mode: 'date', value: null, setValue: null },
  showWebSockectWarningAlert: false,
  isSavedSetting: true,
  webSocketReady: false,
  snackbars: {},
  dialog: {
    order: {
      batchStashes: false,
      inputPrice: false,
    },
    checkout: {
      history: false,
      paymentSuccess: false,
      paymentFail: false,
      selectPrinter: false,
    },
    setting: {
      step: false,
      setMenu: false,
    },
    notification: {
      requestWaiter: false,
    },
    permission: false,
    screenLock: false,
    ePayment: false,
    storageMigration: false,
    clearLocalDatabase: false,
    storeLocalDatabase: false,
    memberRegistrationDialog: false,
    confirmMemberInfoDialog: false,
  },
  neededPermission: '',
  netInfo: {
    type: null,
    isConnected: false,
    details: {},
    isInternetReachable: null,
  },
  settings: {
    enableQrcodeCheckout: false,
    scannerCameraDirection: 1,
    discountShortcut: '',
    lang: 'zh-HK',
    rapidOrder: '',
    receiptPrefix: '1',
    terminalID: '',
    screenLock: 0,
    serialIndex: 1,
    addRushToTag: false,
    checkoutSound: true,
    isMaster: false,
    numberBarInputMode: true,
    numPadOrder: false,
    redirectToTablePage: false,
    quickCheckoutWithCash: false,
    scopeLogin: false,
    setNextStepButton: false,
    selectPrinterBeforePrint: false,
    skipTakeawayModal: false,
    skipPaymentResult: false,
    enableDebugMode: false, // 可在 __DEV__ 本地執行時或開啟 enableDebugMode 時使用
    debugSettings: {
      hideConsoleErrorsAsExceptions: false, // 隱藏 console.error 時跳出的 Exception LogBox
      hideLogBox: false, // 隱藏所有 LogBox
      hideSnackbars: false, // 隱藏 Snackbar
      disablePrinterInit: false, // 停用打印機
      disableOrderHistoryStorage: false, // 停止寫入 orderHistory 到 AsyncStorage
      disableOrderSync: false, // 停止上傳訂單 (/orderimport)
    },
    logo: {},
    combo: {
      groups: [],
      rules: [],
    },
    paymentsOrder: null,
    ecrPayingRecord: {},
    cardTerminalIpAddress: {
      eftPay: '',
      globalPayment: '',
      bbMSL: '',
      octopusMiniPC: '',
    },
    checkOrderAt: null,
  },
  localDBLazyQueuingNumber: 0,
  localDBInitQueuingMessage: null,
}

export default produce(
  /**
   * @param {IAppState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT_DATA: {
        draft.isDataInit = true
        break
      }
      case ActionTypes.COMPLETE_CLEAR_LOCALDB_NO_LONGER_USE: {
        draft.isLocalDbCleanCompleted = true
        break
      }
      case ActionTypes.SET_WEB_SOCKET_WARNING_ALERT: {
        draft.showWebSockectWarningAlert =
          action.payload.showWebSockectWarningAlert
        break
      }
      case ActionTypes.UPDATE_SHOW_WEB_SOCKET_WARNING_AT: {
        const { now } = action.payload
        draft.showWebSockectWarningAt = now
        break
      }
      case ActionTypes.UPDATE_LOADING: {
        const { key, remark, remove } = action.payload
        if (remove) {
          draft.loadingActions = draft.loadingActions.filter(
            (loading) => loading.key !== key,
          )
        } else {
          draft.loadingActions.push({
            key,
            remark,
          })
        }
        break
      }
      case ActionTypes.SHOW_ALERT: {
        const { id, alertConfig } = action.payload
        draft.alerts[id] = {
          id,
          dismiss: false,
          alertConfig,
        }
        break
      }
      case ActionTypes.CLOSE_ALERT: {
        // FIXME: memory leak
        const { id } = action.payload
        draft.alerts[id].dismiss = true
        break
      }
      case ActionTypes.SHOW_DIALOG: {
        const { path } = action.payload
        _set(draft.dialog, path, true)
        break
      }
      case ActionTypes.ENQUEUE_SNACKBAR: {
        const { config } = action.payload

        if (_.size(draft.snackbars) > 5) {
          // 清除舊的 snackbar，避免存太多
          _.forEach(draft.snackbars, (snackbar, id) => {
            if (!snackbar.show) {
              delete draft.snackbars[id]
            }
          })
        }

        draft.snackbars[config.id] = config
        break
      }
      case ActionTypes.CLOSE_SNACKBAR: {
        const { id } = action.payload
        draft.snackbars[id].show = false
        break
      }
      case ActionTypes.UPDATE_NEEDED_PERMISSION: {
        const { permission } = action.payload
        draft.neededPermission = permission
        break
      }
      case ActionTypes.CLOSE_DIALOG: {
        const { path } = action.payload
        _set(draft.dialog, path, false)
        break
      }
      case ActionTypes.IS_DIALOG_OPENED: {
        const { status } = action.payload
        draft.dialog.isDialogOpened = status
        break
      }
      case ActionTypes.UPDATE_SETTING: {
        const { path, value } = action.payload
        _set(draft.settings, path, value)
        break
      }
      case ActionTypes.RESTORE_SETTINGS: {
        const { settings } = action.payload
        draft.settings = settings
        break
      }
      case ActionTypes.UPDATE_DEBUG_MODE: {
        const { enableDebugMode } = action.payload
        draft.settings.enableDebugMode = enableDebugMode
        break
      }
      case ActionTypes.UPDATE_DEBUG_SETTING: {
        const { key, value } = action.payload
        draft.settings.debugSettings[key] = value
        break
      }
      case ActionTypes.RESET_DEBUG_SETTINGS: {
        draft.settings.debugSettings = initialState.settings.debugSettings
        break
      }
      case ActionTypes.SHOW_DATE_TIME_DIALOG: {
        const { dateTimeDialog } = action.payload
        draft.dateTimeDialog = dateTimeDialog
        break
      }
      case ActionTypes.CLOSE_DATE_TIME_DIALOG: {
        draft.dateTimeDialog = initialState.dateTimeDialog
        break
      }
      case ActionTypes.PLAY_SOUND: {
        draft.sound = true
        break
      }
      case ActionTypes.PAUSE_SOUND: {
        draft.sound = false
        break
      }
      case ActionTypes.SET_SCOPE_LOGIN: {
        draft.scopeLogin = action.payload.scopeLogin
        break
      }
      case ActionTypes.UPDATE_NET_INFO: {
        draft.netInfo = action.payload.netInfo
        break
      }
      case ActionTypes.UPDATE_WEBSOCKET_STATUS: {
        const { status } = action.payload
        draft.webSocketStatus = status
        break
      }
      case ActionTypes.UPDATE_WEBSOCKET_READY: {
        const { isReady } = action.payload
        draft.webSocketReady = isReady
        break
      }
      case ActionTypes.UPDATE_IS_SAVED_SETTING: {
        draft.isSavedSetting = action.payload.isSavedSetting
        break
      }
      case ActionTypes.INCREASE_SERIAL_INDEX: {
        const { serialIndex } = action.payload
        const newSerialIndex = Number(serialIndex) + 1
        if (newSerialIndex >= 1000) {
          draft.settings.serialIndex = initialState.settings.serialIndex
        } else {
          draft.settings.serialIndex = newSerialIndex
        }
        break
      }
      case ActionTypes.UPDATE_INIT_QUEUING_MESSAGE: {
        const { message } = action.payload
        draft.localDBInitQueuingMessage = message
        break
      }
      case ActionTypes.UPDATE_LAZY_QUEUING_NUMBER: {
        const { number } = action.payload
        draft.localDBLazyQueuingNumber = number
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
