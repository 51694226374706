import { PrinterFontSize } from '@/constants/printer'
import { all, create } from 'mathjs'
import { binaryToBits, hexToBinary, textToUnicode } from './convertBinary'
import _ from 'lodash'
import unifontL from './unifont_L.json'
import unifontM from './unifont_M.json'
import unifontXL from './unifont_XL.json'

const config = { matrix: 'Matrix' }
const math = create(all, config)

export function getTextBmp (unicode, fontSize, underline = false, bold = false) {
  const matrix = math.matrix()
  const textHex = getUnifont(unicode, fontSize)
  const height = getHeightBySize(fontSize)
  const length = textHex.length / height
  // 特大全形字體 hexLength = 256, height = 32, length = 8
  // 特大半形字體 hexLength = 128, height = 32, length = 4
  // 大全形字體 hexLength = 144, height = 24, length = 6
  // 大半形字體 hexLength = 72, height = 24, length = 3
  // 中全形字體 hexLength = 64, height = 16, length = 4
  // 中半形字體 hexLength = 32, height = 16, length = 2
  const textBinary = _.chunk(_.map(textHex, h => hexToBinary(h)), length)
  _.each(textBinary, (binary, colIndex) => {
    const bits = binaryToBits(binary)
    _.each(bits, (bit, rowIndex) => {
      matrix.subset(math.index(colIndex, rowIndex), Number(bit), 0)
    })
  })

  const size = matrix.size()
  let outputImage = math.clone(matrix)

  if (bold) {
    const column = math.zeros(size[0], 1)
    const row = math.zeros(1, size[1])
    const topRight = math.resize(math.concat(column, math.clone(matrix)), size, 0)
    const bottomRight = math.resize(math.concat(row, math.clone(topRight), 0), size, 0)
    outputImage = math.or(matrix, topRight)
    outputImage = math.or(outputImage, bottomRight)
    outputImage = math.map(outputImage, o => o ? 1 : 0)
  }

  if (underline) {
    outputImage.subset(math.index(size[0] - 1, math.range(0, size[1])), math.ones(size[1]), 1)
  }
  return outputImage._data
}

/**
 * 特大全形字體 hexLength = 256, height = 32, width = 32
 * 特大半形字體 hexLength = 128, height = 32, width = 16
 * 大全形字體 hexLength = 144, height = 24, width = 24
 * 大半形字體 hexLength = 72, height = 24, width = 12
 * 中全形字體 hexLength = 64, height = 16, width = 16
 * 中半形字體 hexLength = 32, height = 16, width = 8
 */
export function getTextSize (unicode, fontSize) {
  const textHex = getUnifont(unicode, fontSize)
  const height = getHeightBySize(fontSize)
  const width = textHex.length / (height / 4)
  return { width, height }
}

export function separateTextWithLength (str = '', maxWidth, fontSize) {
  const separatedText = []
  const spaceWidth = getTextSize('0020', fontSize).width
  const separatedSpace = str?.normalize('NFKC').split(' ')
  let text = ''
  let size = 0
  _.each(separatedSpace, (string, index) => {
    const totalWidth = _.sum(_.map(_.clone(string), s => getTextSize(textToUnicode(s), fontSize).width))
    const isLast = index === _.findLastIndex(separatedSpace)
    if (size + totalWidth <= maxWidth) {
      text += string
      size += totalWidth
    } else {
      _.each(string, s => {
        const unicode = textToUnicode(s)
        const textWidth = getTextSize(unicode, fontSize).width
        if ((size + textWidth) > maxWidth) {
          separatedText.push(text)
          text = ''
          size = 0
        }
        text += s
        size += textWidth
      })
    }
    if (!isLast) {
      if (size + spaceWidth <= maxWidth) {
        text += ' '
        size += spaceWidth
      }
      const nextWidth = _.sum(_.map(_.clone(separatedSpace[index + 1]), s => getTextSize(textToUnicode(s), fontSize).width))
      if (nextWidth + size > maxWidth) {
        separatedText.push(text)
        text = ''
        size = 0
      }
    }
  })
  separatedText.push(text)
  return separatedText.map(t => textToUnicode(t))
}

/**
 * 找出fontSize的height
 */
export function getHeightBySize (fontSize) {
  switch (fontSize) {
    case PrinterFontSize.EXTRALARGE:
    case 5:
      return 32
    case PrinterFontSize.LARGEWIDTH:
    case PrinterFontSize.LARGE:
    case 4:
    case 2:
      return 24
    case PrinterFontSize.MEDIUWIDTH:
    case PrinterFontSize.MEDIUM:
    case 3:
    case 1:
      return 16
    default:
      return 16
  }
}

/**
 * 找出對應fontSize的unifont
 */
const getUnifont = (unicode, fontSize) => {
  switch (fontSize) {
    case PrinterFontSize.EXTRALARGE:
    case 5:
      return _.get(unifontXL, unicode, unifontXL['003F'])
    case PrinterFontSize.LARGEWIDTH:
    case PrinterFontSize.LARGE:
    case 4:
    case 2:
      return _.get(unifontL, unicode, unifontL['003F'])
    case PrinterFontSize.MEDIUWIDTH:
    case PrinterFontSize.MEDIUM:
    case 3:
    case 1:
      return _.get(unifontM, unicode, unifontM['003F'])
    default:
      return _.get(unifontM, unicode, unifontM['003F'])
  }
}

export default {
  getTextBmp,
  getTextSize,
  separateTextWithLength,
  getHeightBySize,
}
