import { Image, Platform, StyleSheet, Text, TouchableOpacity } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SettingImageProps
 * @property {string?} source
 * @property {string?} notFoundSource
 * @property {string?} notFoundText
 * @property {() => void?} onPress
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {SettingImageProps} props
 * @returns
 */
export default function SettingImage (props) {
  const { source, onPress, notFoundSource, notFoundText = '未上傳', style } = props

  const containerStyle = {
    backgroundColor: onPress ? colors.lightGray3 : colors.lightGray2,
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={!onPress}
      style={[styles.img, containerStyle, style]}
    >
      {source
        ? (
          <Image
            style={StyleSheet.absoluteFill}
            source={Platform.OS !== 'web' ? { url: source } : source}
          />)
        : (
          <>
            {notFoundSource &&
              <Image
                style={styles.uploadIcon}
                source={notFoundSource}
              />}
            <Text style={styles.uploadText}>{notFoundText}</Text>
          </>
        )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  img: {
    minWidth: 366,
    minHeight: 220,
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    overflow: 'hidden',
  },
  uploadIcon: {
    height: 48,
    width: 48,
    marginBottom: 8,
  },
  uploadText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.gray,
  },
})
