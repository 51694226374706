
import AsyncStorage from '@react-native-async-storage/async-storage'
import DataStorage from '@/libs/dataStorage'
import moment from 'moment'
import * as FileSystem from 'expo-file-system'
import * as OrderLocalDatabase from '@/libs/orderLocalDatabase'

// 匯出file
const writeFile = async (data, fileName) => {
  await FileSystem.writeAsStringAsync(fileName, data, { encoding: FileSystem.EncodingType.UTF8 })
}

// 匯出所有AsyncStorage的資料到iPad。檔案位置 - /我的iPad/DimPOS
const exportAsyncStorageData = async () => {
  const keyList = [
    'settings',
    'merchant',
    'printer',
    'menu',
    'PRINTINGHASH',
    'PRINTHASH',
    'merchantLogin',
    'userLogin',
    'SCOPE_GROUPS',
    'SCOPE_GROUP_USERS',
    'merchantLogin',
    'userLogin',
    'OVERWRITE_DEPLOYMENT',
    'OVERWRITE_ENV',
    'batchStashes',
    'PAYING_ORDERS',
    'SPLIT_CHECKOUT_ORDERS',
    'orderHistory',
    'unsyncOrderIds',
  ]
  const folderName = FileSystem.documentDirectory + moment().format('YYYYMMDD_HHmmss')

  try {
    // 開新folder
    await FileSystem.makeDirectoryAsync(folderName)
    // 匯出AsyncStorage
    keyList.forEach(async (key) => {
      let data = ''
      switch (key) {
        case 'orderHistory':
          data = JSON.stringify(await OrderLocalDatabase.getAllOrders())
          break
        default:
          data = await AsyncStorage.getItem(key)
      }
      const fileName = folderName + '/' + key + '.txt'
      await writeFile(data, fileName)
    })
  } catch (err) {
    console.error(err)
  }
}

export default { exportAsyncStorageData }
