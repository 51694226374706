import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef TagItemProps
 * @property {string} text
 * @property {boolean} selected
 * @property {() => void} onSelect
 */

/**
 *
 * @param {TagItemProps} props
 * @returns
 */
export default function TagItem (props) {
  const { text, selected, onSelect } = props

  const containerStyle = {
    backgroundColor: selected ? colors.primary : colors.light,
  }
  const textStyle = {
    color: selected ? colors.white : colors.textTertiary,
  }

  return (
    <TouchableOpacity
      onPress={onSelect}
      style={{ marginRight: 8, marginTop: 8 }}
    >
      <View
        style={[styles.container, containerStyle]}
      >
        <Text
          style={[styles.text, textStyle]}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 45,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
    padding: 15,
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
  },
})
