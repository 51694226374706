import { FlatList, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'

/**
 * @param {{
 * onPress: () => void,
 * onChange: () => void,
 * onDelete: () => void,
 * }} props
 */
export default function ComboRuleRow (props) {
  const {
    row,
    widths,
    isTitle = false,
    style,
  } = props

  const renderItem = ({ item, index }) => {
    const isLast = index === _.findLastIndex(row)
    return (
      <View style={[styles.cell, { width: widths[index] }, !isLast && styles.border, style]}>
        <Text style={isTitle && styles.title}>{item}</Text>
      </View>
    )
  }

  return (
    <FlatList
      data={row}
      renderItem={renderItem}
      keyExtractor={(item, index) => String(index)}
      horizontal
      showsHorizontalScrollIndicator={false}
    />
  )
}

const styles = StyleSheet.create({
  cell: {
    minHeight: 40,
    padding: 5,
    justifyContent: 'center',
  },
  border: {
    borderRightWidth: 1,
    borderRightColor: colors.lightGray2,
  },
  title: {
    fontWeight: 'bold',
    color: colors.white,
  },
  buttonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
