import { Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

export default function TopRightTag ({ text, style, showPhoneIcon }) {
  const containerStyle = {
    backgroundColor: showPhoneIcon ? colors.light : colors.primary,
  }

  const textStyle = {
    color: showPhoneIcon ? colors.gray : colors.white,
  }

  return (
    <View style={[styles.container, containerStyle, style]}>
      {!!showPhoneIcon &&
        <Image
          style={styles.icon}
          source={require('@icons/table/phone.png')}
        />}
      <Text style={[styles.text, textStyle]}>{text.slice(-3)}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 42,
    width: 34,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    ...shadows.slight,
  },
  icon: {
    height: 16,
    width: 16,
    marginBottom: 2,
  },
  text: {
    fontSize: 16,
    fontWeight: '500',
  },
})
