import { BlurView } from 'expo-blur'
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import Avatar from './Avatar'
import React from 'react'
import colors from '@/theme/colors'

const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children

export default function EmployeeOption ({ employee: { position, name, account }, blur, showSmallText, style, onPress }) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[styles.container, style]}>
        <ConditionalWrapper
          condition={blur}
          wrapper={children =>
            <BlurView
              intensity={100}
              style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}
            >{children}
            </BlurView>}
        >
          <Avatar name={name[0]} style={styles.avatar} />
          <View>
            <Text style={styles.text}>{account}</Text>
          </View>
        </ConditionalWrapper>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  nonBlurredContent: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    paddingHorizontal: 10,
  },
  avatar: {
    marginRight: 10,
  },
  text: {
    fontSize: 18,
    color: colors.white,
  },
  smallText: {
    fontSize: 10,
    color: colors.white,
  },
})
