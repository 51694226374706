import { FlatList, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'

import { useTranslation } from 'react-i18next'
import ChipLabel from '@/components/ChipLabel'
import Header from '@/components/Setting/Header'
import HeaderRightAction from '@/components/Setting/HeaderRightAction'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 * @param {IOptionsPanelProps} props
 */
export default function OptionsPanel (props) {
  const {
    style,
    title,
    defaultValue,
    options = [],
    multiple = false,
    closeOnSelect = false,
    disableHeader = false,
    disableSaveValue = false,
    onClose,
    onChangeValue,
  } = props

  const { t } = useTranslation()
  const [selectAll, setSelectAll] = React.useState(multiple && defaultValue?.length === options.length)

  const [value, setValue] = React.useState(multiple ? defaultValue || [] : defaultValue)
  React.useEffect(() => {
    setValue(multiple ? defaultValue || [] : defaultValue)
  }, [defaultValue])

  const renderItem = ({ item: option, index }) => {
    const isSelected = value !== undefined && (value === option.value || (multiple && value.includes(option.value)))
    return (
      <SettingListItem
        onPress={() => {
          if (multiple) {
            const valueIndex = value.findIndex(value => value === option.value)
            const newValue = [...value]
            if (valueIndex > -1) {
              newValue.splice(valueIndex, 1)
            } else {
              newValue.push(option.value)
            }
            onChangeValue(newValue)
            setValue(newValue)
          } else if (disableSaveValue) {
            onChangeValue(option.value)
          } else {
            onChangeValue(option.value)
            setValue(option.value)
          }
          if (closeOnSelect) {
            onClose()
          }
        }}
        divider={index !== options.length - 1}
        disabled={option.disabled}
      >
        <SettingListItemText text={option.display} />
        <SettingListItemRightAction hidden={!isSelected}>
          <Image
            style={styles.checkedIcon}
            source={require('@icons/setting/checked.png')}
          />
        </SettingListItemRightAction>
      </SettingListItem>
    )
  }

  return (
    <View style={[styles.container, style]}>
      {!disableHeader && (
        <Header onBack={onClose}>
          <HeaderText text={title} />
          {multiple && (
            <HeaderRightAction>
              <TouchableOpacity
                onPress={() => {
                  const newSelectAll = !selectAll
                  setSelectAll(newSelectAll)
                  if (newSelectAll) {
                    const allValues = options.map(option => option.value)
                    onChangeValue(allValues)
                    setValue(allValues)
                  } else {
                    onChangeValue([])
                    setValue([])
                  }
                }}
              >
                <ChipLabel
                  active
                  label={selectAll ? t('app.common.clear') : t('app.common.selectAll')}
                />
              </TouchableOpacity>
            </HeaderRightAction>
          )}
        </Header>
      )}
      <View style={styles.content}>
        <SettingList style={styles.settingList}>
          <FlatList
            data={options}
            renderItem={renderItem}
            extraData={value}
            keyExtractor={(item, index) => String(item.value) + index}
          />
        </SettingList>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
  },
  settingList: Platform.OS === 'web'
    ? { flex: 1 }
    : {},
  checkedIcon: {
    height: 20,
    width: 20,
  },
})
