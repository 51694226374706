
import _ from 'lodash'
import hash from 'object-hash'
import moment from 'moment'
import shortid from 'shortid'

import { currencyWithCommas } from '@/libs/numberWithCommas'
import PrintDoc from '@/libs/printing/PrintDoc'
import defaultLabelStyle from '@/pages/Setting/PrintingSetting/CustomizedLabelPanel/defaultLabelStyle'
import i18n from '@/i18n'

import TDP225 from '../printer/TDP225'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IMerchant } from 'dimorder-orderapp-lib/dist/types/Merchant'
/* eslint-enable no-unused-vars */

const DEFAULT_SEPARATOR = ', '

export default class Label extends PrintDoc {
  TYPE = PrintDoc.Types.LABEL

  /**
   *
   * @param {IAppBatchItem[]} items
   * @param {IMerchant} merchant
   * @param {IAppOrder} order
   * @param {ILabelSetting} printerSetting
   * @param {IPrintConfig} printConfig
   * @param {object} quantity // ? object 的內容是什麼？
   * @param {CustomizedLabelSetting} customizedStyle
   *
   * @typedef IPrintConfig
   * @property {boolean?} reprint
   * @property {boolean?} cancel
   * @property {boolean?} serveUp
   * @property {boolean?} printStaff
   * @property {boolean?} change
   * @property {number?} orderSerialDigit
   * @property {ITransferTableInfo?} transfer
   *
   * @typedef ITransferTableInfo // ? 應該不只這些，還有什麼東西？
   * @property {{ table: string }} from
   * @property {{ table: string }} to
   * @returns {ThunkFunction}
   */

  constructor (
    items,
    merchant,
    order,
    printerSetting,
    printConfig,
    quantity,
    customizedStyle,
  ) {
    super()
    this.printer = new TDP225() // label 只會由 TDP225 列印

    this.items = items
    this.merchant = merchant
    this.order = order
    this.printerSetting = printerSetting
    this.printConfig = printConfig
    this.quantity = quantity
    this.customizedStyle = customizedStyle || { style: defaultLabelStyle }

    this.isDefaultStyle = !customizedStyle

    this.printId = shortid.generate()
    this.printHash = this._generatePrintHash()
    this.TEXT = {
      TABLE_NUMBER: i18n.t('app.printing.tableNum'),
      TAKEWAY: i18n.t('app.printing.takeaway'),
    }
  }

  print = () => {
    const { width, height } = this.printerSetting

    this.printer.addClear()
    this.printer.addSetSize(width, height) // FIXME CLS 應該要在 SIZE 之後執行
    // FIXME CODEPAGE 呢？
    // FIXME GAP 呢？
    // FIXME addSetUp 寫假的嗎？

    const items = this._splitItemsByQuantity(this.items, this.quantity)
    const length = _.flatMap(
      items,
      item => item?.isSet
        ? this._splitItemsByQuantity(item.setItems)
        : item,
    ).length

    let count = 1
    _.each(items, (item) => {
      if (item?.isSet) {
        const setItems = this._splitItemsByQuantity(item.setItems)
        _.each(setItems, (setItem) => {
          this._addInit() // FIXME 為什麼每個 item 都要重設 CLS DENSITY 15？
          this._printWithStyle(setItem, `${count}/${length}`)
          this._addLabelFooter()
          count += 1
        })
      } else {
        this._addInit() // FIXME 為什麼每個 item 都要重設 CLS DENSITY 15？
        this._printWithStyle(item, `${count}/${length}`)
        this._addLabelFooter()
        count += 1
      }
    })

    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  /**
   * ! 這到底在寫什麼？找一個先執行 CLS 在執行 DENSITY 的範例
   */
  _addInit = () => {
    this.printer.addClear()
    this.printer.addDensity(15) // FIXME CLS 應該要在 DENSITY 之後執行
  }

  /**
   *
   * @param {IAppBatchItem} item
   * @param {string} count e.g. '1/3' '2/3' '3/3'
   */
  _printWithStyle = (item, count) => {
    const { table, serial, subTable } = this.order
    const { printWithPrice } = this.printerSetting

    let orderSerial = serial
    const styles = _.map(this.customizedStyle.style, style => {
      const serialNoStyle = _.find(style.items, item => item.type === 'serialNo')
      if (_.get(serialNoStyle, 'last3Digit', false)) {
        const orderSerialDigit = _.get(this.printConfig, 'orderSerialDigit', 3) // FIXME 可能不是後三碼
        orderSerial = _.takeRight(serial, orderSerialDigit).join('')
      }

      if (!this.isDefaultStyle) {
        return style
      }

      if (style.key !== 'content') {
        return style
      }

      const updatedItems = _.map(style.items, item => {
        if (item.type === 'price') {
          return { ...item, enable: printWithPrice }
        }
        return item
      })

      return { ...style, items: updatedItems }
    })

    let printTableText = ''
    if (table) {
      const tableText = `${table}${subTable > 0 ? `(${String.fromCharCode(subTable + 64)})` : ''}`
      const tableNumText = i18n.language === 'zh-HK' ? tableText + this.TEXT.TABLE_NUMBER : this.TEXT.TABLE_NUMBER + tableText
      printTableText = tableNumText
    } else {
      printTableText = this.TEXT.TAKEWAY
    }

    let printText = item.name

    if (item.options?.length) {
      const options = item.options.map(option => `${option.name}${option.quantity > 1 ? `x${option.quantity}` : ''}`).join(DEFAULT_SEPARATOR)
      printText += `\n${options}`
    }

    if (item.remarks?.length) {
      const remarks = item.remarks.join(DEFAULT_SEPARATOR)
      printText += `\n*${remarks}`
    }

    const data = {
      merchantName: this.merchant.name,
      serialNo: `#${orderSerial}`,
      count,
      price: currencyWithCommas(item.total),
      table: printTableText,
      item: printText,
    }

    _.each(styles, style => {
      const maxFontSize = _.maxBy(style.items, 'fontSize').fontSize
      _.each(style.items, printItem => {
        if (printItem.enable) {
          this._addAlignText(printItem, _.get(data, printItem.type, ''), style.type)
        }
      })
      if (style.items.some(i => i.enable)) {
        this.printer.addRowFeed(maxFontSize)
      }
    })
  }

  /**
   * 標籤頁尾
   * 左：printAt
   * 右：printId
   */
  _addLabelFooter = () => {
    const { printTimes } = this.printerSetting
    const printAt = moment().format('YYYY-MM-DD HH:mm:ss')
    this.printer.addTextAtBottom(printAt, 7, 'left')
    this.printer.addAlign('right')
    this.printer.addTextAtBottom(this.printId, 7, 'right')
    this.printer.addAlign('left')
    this.printer.addPrint(printTimes + 1)
  }

  /**
   *
   * @param {LabelStyleSetting} style
   * @param {string} text
   * @param {'row' | 'block'} type
   */
  _addAlignText (style = {}, text = '', type) {
    const { fontSize, align, bold } = style

    switch (align) {
      case 'center': {
        this.printer.addAlign('center')
        if (type === 'block') {
          this.printer.addBlockText(text, fontSize, align, bold)
        } else {
          this.printer.addText(text, fontSize, align, bold)
        }
        break
      }
      case 'right': {
        this.printer.addAlign('right')
        if (type === 'block') {
          this.printer.addBlockText(text, fontSize, align, bold)
        } else {
          this.printer.addText(text, fontSize, align, bold)
        }
        break
      }
      case 'left':
      default: {
        this.printer.addAlign('left')
        if (type === 'block') {
          this.printer.addBlockText(text, fontSize, align, bold)
        } else {
          this.printer.addText(text, fontSize, align, bold)
        }
        break
      }
    }
  }

  /**
   * ! 為什麼跟 CustomizedKitchenBatch 的 method 不一樣？ 為什麼沒有處理 setItems?
   * @param {*} items
   * @param {*} quantity
   * @returns
   */
  _splitItemsByQuantity (items, quantity) {
    // for each item and each qty, add
    const toItems = []
    _.each(items, (item) => {
      // from
      const _quantity = quantity ? quantity[item.id] : item.quantity
      for (let i = 0; i < _quantity; i++) {
        // to
        const cloneItem = _.cloneDeep(item)
        cloneItem.quantity = 1
        if (item.ids) {
          cloneItem.id = item.ids[i]
        }
        if (cloneItem.prices) {
          cloneItem.total = cloneItem.prices[i]
        } else {
          cloneItem.total = cloneItem.price
        }
        toItems.push(cloneItem)
      }
    })
    return toItems
  }

  /**
   * @returns {string}
   */
  _generatePrintHash () {
    const shouldReprint = this.printConfig.reprint || this.printConfig.serveUp || this.printConfig.cancel // ? change 呢？
    if (shouldReprint) return this.printId
    // batch item 的 property printed 和 printError 有機會是會變的
    // 為了避免重複印單所以改為使用不會變動的 item.id 確保只會列印一次
    const allId = _.map(this.items, (item) => item.id)
    return hash({ ids: allId, printerSetting: this.printerSetting })
  }
}
