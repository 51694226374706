import { Platform, ScrollView, StyleSheet, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useSelectedBatchItem } from '@/hooks/order'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import NumPadDialog from '@/components/dialogs/NumPadDialog'
import Row from '@/components/Row'

import OptionButton from './OptionButton'
import OptionTitle from './OptionTitle'
import ScrollButton from './ScrollButton'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionGroup } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Options (props) {
  const dispatch = useDispatch()
  const scrollViewRef = React.useRef()
  const scrollHeight = React.useRef(0)
  const scrollTop = React.useRef(0)

  const categories = useSelector(state => state.menu.categories)
  const sets = useSelector(state => state.menu.sets)
  const [selectedItem, selectedSetItem] = useSelectedBatchItem()
  const [numPadDialogOpen, setNumPadDialogOpen] = React.useState(false)
  const [maxQuantity, setMaxQuantity] = React.useState(0)
  const [selectedOption, setSelectedOption] = React.useState({})
  const [selectedOptionAmount, setSelectedOptionAmount] = React.useState('0')
  const [onSelect, setOnSelect] = React.useState(() => () => {})
  const isSetItem = Boolean(selectedSetItem)
  const targetItem = selectedSetItem || selectedItem
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)
  if (!targetItem || targetItem.isSet) {
    // 沒有選擇 item 或是被選擇的是套餐本身（沒有選項）
    return <View style={styles.container} />
  }
  const selectedOptionItemIds = targetItem.options.map(option => option.optionItemId)

  let menu = null
  if (isSetItem) {
    const menus = sets[selectedItem.menuId]?.menus || []
    menu = menus.find(menu => menu.id === selectedSetItem.setMenuId)
    menu = _.get(allMenus, selectedSetItem.menuId, menu)
  } else {
    const menus = categories[selectedItem.categoryId]?.menus || []
    menu = menus.find(menu => menu.id === selectedItem.menuId)
  }

  if (!menu) {
    return <View style={styles.container} />
  }
  const options = _.sortBy(_.concat(_.get(menu, 'options', []), _.get(menu, 'optionGroupPresets', [])), ['weight'])
  /**
   * @param {IMenuOptionGroup} option
   */
  const renderOption = (option) => {
    const itemComponents = []
    // 兩個一組 render
    const optionItems = option.options.filter(option => !option.hidden).map(optionItem => {
      return {
        ...optionItem,
        name: _.get(optionItem, `localeNames.${lang}`, optionItem.name),
      }
    })
    optionItems.forEach((optionItem, index) => {
      if (index % 2 === 0) {
        const nextOptionItem = optionItems[index + 1]
        itemComponents.push((
          <Row key={index}>
            <OptionButton
              variant='option'
              selected={selectedOptionItemIds.includes(optionItem.id)}
              text={optionItem.name + (optionItem.price ? `$${optionItem.price > 0 ? '+' : ''}${optionItem.price}` : '')}
              onPress={() => {
                if (optionItem?.max > 1 && !selectedOptionItemIds.includes(optionItem.id)) {
                  setOnSelect(() => (quantity) => {
                    dispatch(actions.orderBatch.updateOption(option, optionItem, quantity))
                  })
                  setMaxQuantity(optionItem.max)
                  setSelectedOption(optionItem)
                  setNumPadDialogOpen(true)
                } else {
                  dispatch(actions.orderBatch.updateOption(option, optionItem))
                }
              }}
            />
            {nextOptionItem && (
              <OptionButton
                variant='option'
                selected={selectedOptionItemIds.includes(nextOptionItem.id)}
                text={nextOptionItem.name + (nextOptionItem.price ? `$${nextOptionItem.price > 0 ? '+' : ''}${nextOptionItem.price}` : '')}
                onPress={() => {
                  if (nextOptionItem?.max > 1 && !selectedOptionItemIds.includes(nextOptionItem.id)) {
                    setOnSelect(() => (quantity) => {
                      dispatch(actions.orderBatch.updateOption(option, nextOptionItem, quantity))
                    })
                    setMaxQuantity(nextOptionItem.max)
                    setSelectedOption(nextOptionItem)
                    setNumPadDialogOpen(true)
                  } else {
                    dispatch(actions.orderBatch.updateOption(option, nextOptionItem))
                  }
                }}
              />
            )}
          </Row>
        ))
      }
    })

    return (
      <React.Fragment key={option.id}>
        <OptionTitle
          title={_.get(option, `localeNames.${lang}`, option.name)}
          color={colors.white}
          required={option.min > 0}
          multiple={(option.multiple && option.max > 1)}
        />
        {itemComponents}
      </React.Fragment>
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView
        ref={scrollViewRef}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        onLayout={event => {
          // store scroll height
          scrollHeight.current = event.nativeEvent.layout.height
        }}
        onScroll={event => {
          // store scroll position
          scrollTop.current = event.nativeEvent.contentOffset.y
        }}
        style={styles.content}
        contentInset={{ top: 40, bottom: 40 }}
        contentOffset={{ y: -40 }}
      >
        {/* {menu.priceUndetermined && (
          <>
            <OptionTitle
              title='價錢'
              color={colors.white}
            />
            <Row>
              <OptionButton
                variant='option'
                text='時價設定'
                onPress={() => setNumPadDialogOpen(true)}
              />
            </Row>
          </>
        )} */}
        {
          options.map((option) => {
            const filteredOptions = option?.options?.filter(o => !o.hidden)
            if (filteredOptions?.length) {
              return renderOption(option)
            }
            return null
          })
        }
      </ScrollView>
      <ScrollButton
        direction='up'
        onPress={() => {
          // if (scrollViewRef.current) {
          //   scrollViewRef.current.scrollTo({
          //     x: 0,
          //     y: _.max([-40, scrollTop.current - scrollHeight.current * 0.2]),
          //   })
          // }
        }}
      />
      <ScrollButton
        direction='down'
        iconStyle={{ height: 12 }}
        onPress={() => {
          // if (scrollViewRef.current) {
          //   scrollViewRef.current.scrollTo({
          //     x: 0,
          //     y: _.min([scrollHeight.current, scrollTop.current + scrollHeight.current * 0.2]),
          //   })
          // }
        }}
      />
      <NumPadDialog
        key={numPadDialogOpen}
        open={numPadDialogOpen}
        onClose={() => {
          setNumPadDialogOpen(false)
          setSelectedOptionAmount('0')
        }}
        title={`${selectedOption?.name} (${selectedOptionAmount || '0'}/${selectedOption?.max})`}
        enablePressOutsideClose
        defaultValue=''
        disableDecimalPoint
        minAmount={1}
        maxAmount={maxQuantity}
        maxLength={String(maxQuantity).length}
        onSubmit={(value) => {
          onSelect(Number(value))
          setNumPadDialogOpen(false)
        }}
        onPress={(value) => setSelectedOptionAmount(value)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: colors.primary,
    borderTopRightRadius: 20,
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: Platform.OS === 'web' ? 40 : 0,
  },
})
