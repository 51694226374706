import { Platform } from 'react-native'
import { produce } from 'immer'
import _ from 'lodash'

import { dimorderApi } from '@/libs/api/dimorder'
import { getSelectedBatchItem, isBetweenDayTime } from '@/libs/order'
import { loadingKey } from '@/constants'
import { parseCategories, parseCategories2 } from '@/libs/menu'
import { replaceSaveSpace } from '@/libs/strReplace'
import delay from '@/libs/delay'
import i18n from '@/i18n'
import mapDeep from 'deepdash/mapDeep'
import searchCategories from '@/libs/searchCategories'

import ActionTypes from './ActionTypes'
import AsyncStorage from '@react-native-async-storage/async-storage'
import actions from '../actions'

/* eslint-disable no-unused-vars */
import { EUpdateMenuAction, IAppCategoryTag } from './MenuState.d'
import { IAppCategory, IAppMenuItem, IAppSet, IAppSetStep } from 'dimorder-orderapp-lib/dist/types/AppMenu'
import { ICategory } from 'dimorder-orderapp-lib/dist/types/Category'
import { IMenuOptionGroup, ISetMenu } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    await dispatch(fetchCategories2())
    // await dispatch(getOptionGroupPresets())
    // await dispatch(fetchCustomItems())
    await dispatch(fetchKitchenDepartments())
    await dispatch(fetchRewardItems())

    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
    if (Platform.OS === 'web') return
    const menu = getState().menu
    await AsyncStorage.setItem('menu', JSON.stringify(menu))
  }
}

export function restore (menu) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESTORE,
      payload: { menu },
    })
  }
}
/**
 * @param {boolean} isLoading
 * @returns {ThunkFunction}
 */
export function updateCategoryLoading (isLoading) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_CATEGORY_LOADING,
      payload: { isLoading },
    })
  }
}

/**
 * @param {boolean} isLoading
 * @returns {ThunkFunction}
 */
export function updateMenuLoading (isLoading) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_MENU_LOADING,
      payload: { isLoading },
    })
  }
}
export function fetchCategories2 () {
  return async (dispatch, getState) => {
    const allmenu = await dimorderApi.menu.getAllMenus()
    const categoryTags = await dimorderApi.category.getCategoryTag()
    const allCatId = mapDeep(allmenu.categories, category => category.id, { childrenPath: 'categories' })
    allmenu.menus = allmenu.menus.filter(menu => allCatId.includes(menu.categoryId))
    allmenu.sets = allmenu.sets.filter(set => allCatId.includes(set.categoryId))
    const [categoriesMap, rootCategory, rootSetCategory, menus, sets, promote, soldOut, optionGroupPresets] = await parseCategories2(allmenu)

    // add isPictureMode to fix quick mode empty menu bug
    const isPictureMode = getState().merchant.data.setting?.orderPictureMode

    dispatch({
      type: ActionTypes.UPDATE_CATEGORIES,
      payload: {
        rootCategory: rootCategory,
        rootSetCategory: rootSetCategory,
        categories: categoriesMap,
        menus: menus,
        sets: sets,
        categoryTags: categoryTags,
        promote: promote,
        soldOut: soldOut,
        optionGroupPresets: optionGroupPresets,
        selectedCategory: isPictureMode ? rootCategory.categories[0] : rootCategory,
        isPictureMode: isPictureMode,
      },
    })
  }
}
/**
 * @returns {ThunkFunction}
 */
export function fetchCategories () {
  return async (dispatch, getState) => {
    const lang = String(i18n.language ?? 'zh').split('-')[0]
    const merchantId = getState().merchant.data.id
    const batchLocaleOptions = getState().merchant.data?.setting?.batchLocaleOptions
    const [
      chineseCategories,
      englishCategories,
      categoryTags,
    ] = await Promise.all([
      dimorderApi.category.getCategories('zh'),
      dimorderApi.category.getCategories('en'),
      dimorderApi.category.getCategoryTag(),
    ])
    const categories = chineseCategories.map(chineseCategory => {
      const localeCategory = englishCategories.find(englishCategory => englishCategory.id === chineseCategory.id)
      if (!localeCategory) {
        return { ...chineseCategory, localeNames: {} }
      }
      if (!chineseCategory.categories?.length) {
        return {
          ...chineseCategory,
          name: lang === 'zh' ? chineseCategory.name : localeCategory.name,
          localeNames: { zh: chineseCategory.name, en: localeCategory.name },
          desc: lang === 'zh' ? chineseCategory.desc : localeCategory.desc,
          localeDesc: { zh: chineseCategory.desc, en: localeCategory.desc },
        }
      }
      // 子分類
      const subCategories = chineseCategory.categories.map(chineseSubCategory => {
        const localeSubCategory = localeCategory?.categories?.find(englishSubCategory => englishSubCategory.id === chineseSubCategory.id)
        if (localeSubCategory) {
          return {
            ...chineseSubCategory,
            name: lang === 'zh' ? chineseSubCategory.name : localeSubCategory.name,
            localeNames: { zh: chineseSubCategory.name, en: localeSubCategory.name },
            desc: lang === 'zh' ? chineseSubCategory.desc : localeSubCategory.desc,
            localeDesc: { zh: chineseSubCategory.desc, en: localeSubCategory.desc },
          }
        }
        return { ...chineseSubCategory, localeNames: {} }
      })
      return {
        ...chineseCategory,
        categories: subCategories,
        name: lang === 'zh' ? chineseCategory.name : localeCategory.name,
        localeNames: { zh: chineseCategory.name, en: localeCategory.name },
        desc: lang === 'zh' ? chineseCategory.desc : localeCategory.desc,
        localeDesc: { zh: chineseCategory.desc, en: localeCategory.desc },
      }
    })

    // formate categories, create root category, categoriesMap
    const [categoriesMap, rootCategory, rootSetCategory, menus, sets, promote, soldOut] = await parseCategories(merchantId, categories, batchLocaleOptions)
    dispatch({
      type: ActionTypes.UPDATE_CATEGORIES,
      payload: {
        rootCategory: rootCategory,
        rootSetCategory: rootSetCategory,
        categories: categoriesMap,
        menus: menus,
        sets: sets,
        categoryTags: categoryTags,
        promote: promote,
        soldOut: soldOut,
      },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function fetchCustomItems () {
  return async (dispatch, getState) => {
    try {
      const customItems = await dimorderApi.merchant.getCustomItems()
      dispatch({
        type: ActionTypes.UPDATE_CUSTOM_ITEMS,
        payload: { customItems },
      })
    } catch (error) {
      console.log('fetchCustomItems error', error)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function fetchKitchenDepartments () {
  return async (dispatch, getState) => {
    try {
      const departments = await dimorderApi.department.getDepartment()
      dispatch({
        type: ActionTypes.UPDATE_KITCHEN_DEPARTMENTS,
        payload: { departments },
      })
    } catch (error) {
      console.log('fetchKitchenDepartments error', error)
    }
  }
}

export function fetchRewardItems () {
  return async (dispatch, getState) => {
    try {
      let next = ''
      let rewards = []

      // getMerchantReward has limit 10 items, so we need to loop until next is empty
      do {
        const response = await dimorderApi.merchant.getMerchantReward({ limit: 1000, after: next })
        next = response.next
        rewards = rewards.concat(response.data)
      } while (next !== '')

      dispatch({
        type: ActionTypes.UPDATE_REWARD_ITEMS,
        payload: { rewards: rewards },
      })
    } catch (e) {
      console.log('fetchRewardItems error', e)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function deleteKitchenDepartments (kitchenDepartmentId) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.department.deleteDepartment(kitchenDepartmentId)
      const departments = getState().menu.departments
      const result = _.remove([...departments], (department) => {
        return department.id !== kitchenDepartmentId
      })
      dispatch({
        type: ActionTypes.UPDATE_KITCHEN_DEPARTMENTS,
        payload: { departments: result },
      })
    } catch (error) {
      console.log('deleteKitchenDepartments error', error)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function upsertKitchenDepartments (department) {
  return async (dispatch, getState) => {
    try {
      const departments = getState().menu.departments
      let updatedDepartments = [...departments]
      if (department?.id) {
        const result = await dimorderApi.department.updateDepartment(department?.id, department)
        updatedDepartments = updatedDepartments.map(d => {
          if (d.id === result.id) {
            return result
          }
          return d
        })
      } else {
        const result = await dimorderApi.department.createDepartment(department)
        updatedDepartments.push(result)
      }

      dispatch({
        type: ActionTypes.UPDATE_KITCHEN_DEPARTMENTS,
        payload: { departments: updatedDepartments },
      })
    } catch (error) {
      console.log('deleteKitchenDepartments error', error)
    }
  }
}

/**
 * @param {boolean} isPictureMode
 * @returns {ThunkFunction}
 */
export function updatePictureMenuMode (isPictureMode) {
  return (dispatch, getState) => {
    const rootCategory = getState().menu.categories.ROOT
    const isfilterByTime = getState().menu.isfilterByTime
    let selectedCategory = null
    if (!rootCategory) {
      return
    }
    if (isPictureMode) {
      if (isfilterByTime) {
        selectedCategory = rootCategory.categories.find(item => {
          return isBetweenDayTime(item)
        })
      } else {
        selectedCategory = rootCategory.categories[0]
      }
    }
    if (!isPictureMode) {
      selectedCategory = rootCategory
    }

    dispatch(selectCategory(selectedCategory))
    dispatch(actions.orderBatch.deselectItems())
    dispatch({
      type: ActionTypes.UPDATE_PICTURE_MODE,
      payload: { isPictureMode },
    })
  }
}

export function updatefilterByTime (isfilterByTime) {
  return (dispatch, getState) => {
    const isPictureMode = getState().menu.isPictureMode
    const rootCategory = getState().menu.categories.ROOT
    const rootSetCategory = getState().menu.categories.SET_ROOT

    const isInSetCategories = getState().menu.selectedCategory.isInSetCategories

    let selectedCategory = rootCategory.categories[0]

    dispatch({
      type: ActionTypes.UPDATE_FILTER_BY_TIME,
      payload: { isfilterByTime },
    })
    if (isPictureMode) {
      if (isInSetCategories) {
        selectedCategory = rootSetCategory.categories.find(item => {
          return isBetweenDayTime(item)
        })
      } else {
        selectedCategory = rootCategory.categories.find(item => {
          return isBetweenDayTime(item)
        })
      }

      dispatch(selectCategory(selectedCategory))
    }
  }
}

/**
 * @param {boolean} isBatchListExpand
 * @returns {ThunkFunction}
 */
export function updateBatchListExpand (isBatchListExpand) {
  return {
    type: ActionTypes.UPDATE_BATCH_LIST_EXPAND,
    payload: { isBatchListExpand },
  }
}

/**
 * @param {boolean} isSearchExpanded
 * @returns {ThunkFunction}
 */
export function updateSearchExpanded (isSearchExpanded) {
  return {
    type: ActionTypes.UPDATE_SEARCH_EXPAND,
    payload: { isSearchExpanded },
  }
}

/**
 * @param {string} searchText
 * @returns {ThunkFunction}
 */
export function updateSearchText (searchText) {
  return {
    type: ActionTypes.UPDATE_SEARCH_TEXT,
    payload: { searchText },
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetSearchInput () {
  return (dispatch, getState) => {
    dispatch(updateSearchText(''))
    dispatch(updateSearchExpanded(false))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function swithToSetCategories () {
  return (dispatch, getState) => {
    const rootSetCategory = getState().menu.rootSetCategory
    const selectedCategory = rootSetCategory.categories.find(item => isBetweenDayTime(item))
    dispatch(selectCategory(selectedCategory))
    dispatch(actions.orderBatch.deselectItems())
  }
}

/**
 * @returns {ThunkFunction}
 */
export function swithToSingleCategories () {
  return (dispatch, getState) => {
    const rootCategory = getState().menu.rootCategory
    const selectedCategory = rootCategory.categories.find(item => isBetweenDayTime(item))
    dispatch(selectCategory(selectedCategory))
    dispatch(actions.orderBatch.deselectItems())
  }
}

/**
 * @param {IAppCategory} category
 * @returns {ThunkFunction}
 */
export function selectCategory (category) {
  return (dispatch, getState) => {
    const [selectedItem] = getSelectedBatchItem()
    if (!selectedItem) {
      dispatch(selectSet(null))
      dispatch(selectSetStep(null))
    } else if (selectedItem.isSet) {
      dispatch(actions.orderBatch.deselectItems())
    }

    dispatch({
      type: ActionTypes.SELECT_CATEGORY,
      payload: { category },
    })
  }
}

/**
 * @param {IAppSet} set
 * @returns {ThunkFunction}
 */
export function selectSet (set) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_SET,
      payload: { set },
    })
  }
}

/**
 * @param {IAppSetStep} setStep
 * @returns {ThunkFunction}
 */
export function selectRequiredSetItem (setStep) {
  return (dispatch, getState) => {
    const selectedSet = getState().menu.selectedSet
    const selectedBatch = getState().orderBatch.selectedBatch
    const selectedItemKey = getState().orderBatch.selectedItemKey
    let stepCount = 10

    if (selectedSet) {
      const setMenus = selectedSet.menus.filter(m => (m.step === setStep?.key) && m.required)
      const set = selectedBatch.items.find(i => i.key === selectedItemKey)
      let items = []
      let itemsIds = []
      let requiredItemQty = 0
      const isAdded = set.setItems.some(s => s.step === setStep?.key)
      if (!isAdded) {
        setMenus.forEach(item => {
          if (!item.sold && !item.deleted) {
            items = [...items, ...Array(item.max).fill(item)]
            itemsIds = [...itemsIds, item.id]
            requiredItemQty += item.max
          }
          stepCount = setStep.max - requiredItemQty
        })
        if (items?.length) {
          dispatch(actions.orderBatch.addSetItems(items, setStep, selectedSet))
          const index = selectedSet?.steps.findIndex(o => o.key === setStep.key)
          const length = selectedSet?.steps.length
          if (
            stepCount <= 0 &&
            (length - index > 1) &&
            (
              !selectedSet.steps[index + 1]?.dependsOnSetMenuItem ||
              itemsIds.includes(selectedSet.steps[index + 1].dependsOnSetMenuItem)
            ) &&
            (
              !selectedSet.steps[index + 1]?.dependsOnSetMenuItems?.length ||
              itemsIds.some(s => selectedSet.steps[index + 1].dependsOnSetMenuItems.includes(s))
            )
          ) {
            dispatch(selectSetStep(selectedSet.steps[index + 1]))
          }
        }
      }
    }
  }
}

/**
 * @param {IAppSetStep} setStep
 * @returns {ThunkFunction}
 */
export function selectSetStep (setStep) {
  return async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.SELECT_SET_STEP,
      payload: { setStep },
    })
    await dispatch(selectRequiredSetItem(setStep))
  }
}

export function getOptionGroupPresets () {
  return async (dispatch, getState) => {
    const batchLocaleOptions = getState().merchant.data?.setting?.batchLocaleOptions
    let optionGroupPresets = await dimorderApi.menu.getOptionGroupPresets()
    if (batchLocaleOptions) {
      await Promise.all(batchLocaleOptions.map(async locale => {
        const localeOptionGroups = await dimorderApi.menu.getOptionGroupPresets(locale)
        optionGroupPresets = optionGroupPresets.map(optionGroup => {
          const optionGroupPreset = localeOptionGroups.find(option => option.id === optionGroup?.id)
          if (!optionGroupPreset) {
            return optionGroup
          }
          if (!optionGroup.options?.length) {
            return { ...optionGroup, localeNames: { ...optionGroup.localeNames, [locale]: optionGroupPreset.name } }
          }
          const localeOptions = optionGroup.options.map(option => {
            const localeOption = optionGroupPreset.options?.find(o => o.id === option.id)
            if (localeOption) {
              return { ...option, localeNames: { ...option.localeNames, [locale]: localeOption.name } }
            }
            return option
          })
          return { ...optionGroup, options: localeOptions, localeNames: { ...optionGroup.localeNames, [locale]: optionGroupPreset.name } }
        })
      }))
    }

    dispatch({
      type: ActionTypes.UPDATE_OPTION_GROUP_PRESETS,
      payload: { optionGroupPresets },
    })
  }
}

/**
 * 新增/修改選項群組
 * @param {IMenuOptionGroup} optionGroupPreset
 * @returns {ThunkFunction}
 */
export function upsertOptionGroupPreset (optionGroupPreset) {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.OPTION))
      optionGroupPreset = produce(optionGroupPreset, draft => {
        draft.name = replaceSaveSpace(draft.name)
        draft.max = Number(optionGroupPreset.max)
        draft.min = Number(optionGroupPreset.min)
        draft.options.forEach(option => {
          option.name = replaceSaveSpace(option.name)
          option.max = Number(option.max)
          option.price = Number(option.price)
        })
        // 刪除沒有名字的選項
        draft.options = draft.options.filter(option => option.name)
      })

      if (optionGroupPreset.id) {
        // 有 id 是 update
        await dimorderApi.menu.updateOptionGroupPreset(optionGroupPreset)
        // await dispatch({
        //   type: ActionTypes.UPDATE_OPTION_GROUP_PRESET,
        //   payload: { optionGroupPreset },
        // })
        // await dispatch(updateMenuOptions(optionGroupPreset, 'UPDATE'))
      } else {
        // 沒 id 是 create
        const data = await dimorderApi.menu.createOptionGroupPreset(optionGroupPreset)
        // await dispatch({
        //   type: ActionTypes.CREATE_OPTION_GROUP_PRESET,
        //   payload: { optionGroupPreset: { ...optionGroupPreset, id: data.id, createdAt: data.createdAt } },
        // })
      }
      dispatch(fetchCategories2())
    } catch (error) {
      console.log('upsertOptionGroupPreset error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.OPTION))
    }
  }
}

/**
 * 刪除選項群組
 * @param {IMenuOptionGroup} optionGroupPreset
 * @returns {ThunkFunction}
 */
export function deleteOptionGroupPreset (optionGroupPreset) {
  return async (dispatch, getState) => {
    if (optionGroupPreset.id) {
      await dimorderApi.menu.deleteOptionGroupPreset(optionGroupPreset.id)
      dispatch(updateMenuOptions(optionGroupPreset, 'DELETE'))
    }

    dispatch({
      type: ActionTypes.DELETE_OPTION_GROUP_PRESET,
      payload: { optionGroupPreset },
    })
  }
}

/**
 * 新增單點/套餐分類
 * @param {Partial<ICategory>} localCategory
 * @returns {ThunkFunction}
 */
export function createCategory (localCategory, printOrder) {
  return async (dispatch, getState) => {
    dispatch(actions.app.openLoading(loadingKey.CATEGORY))
    try {
      let category = await dimorderApi.category.createCategory({
        ...localCategory,
        parentId: (localCategory.parentId === 'ROOT' || localCategory.parentId === 'SET_ROOT') ? '' : localCategory.parentId,
      })

      const newPrintOrder = [...printOrder]
      newPrintOrder[0] = [...printOrder[0]].concat([category.id])
      await dimorderApi.merchant.updateCategoryPrinterOrder(newPrintOrder)
      await dispatch(actions.merchant.fetchSorting())
      await dimorderApi.category.updateCategoryDepartment(category.id, localCategory.kitchenDepartmentId)

      // 加上parentId, isInSetCategories, path
      category = {
        ...category,
        sets: category.sets || [],
        menus: category.menus || [],
        categories: category.categories || [],
        parentId: localCategory.parentId,
        isInSetCategories: localCategory.type === 'SET',
        path: localCategory.path,
        kitchenDepartmentId: localCategory.kitchenDepartmentId,
      }
      const localeNames = {}
      const localeDesc = {}
      _.each(category.translations, t => {
        localeNames[t.locale] = t.name
        localeDesc[t.locale] = t.desc
      })

      dispatch(fetchCategories2())
    } catch (error) {
      console.log('createCategory error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.CATEGORY))
    }
  }
}

/**
 * 刪除單點/套餐分類
 * @param {IAppCategory} category
 * @returns {ThunkFunction}
 */
export function deleteCategory (category, printOrder) {
  return async (dispatch, getState) => {
    await dispatch(actions.app.openLoading(loadingKey.CATEGORY))
    try {
      dispatch({
        type: ActionTypes.DELETE_CATEGORY,
        payload: { category },
      })
      await dimorderApi.category.deleteCategory(category.id)
      const newPrintOrder = printOrder.map(p => p.filter(id => id !== category.id))
      await dimorderApi.merchant.updateCategoryPrinterOrder(newPrintOrder)
      await dispatch(actions.merchant.fetchSorting())
      dispatch(fetchCategories2())
    } catch (error) {
      console.log('deleteCategory error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.CATEGORY))
    }
  }
}

/**
 * 更改單點/套餐分類-名稱/簡介
 * @param {IAppCategory} category
 */
export function updateCategory (category, printOrder, parentId, lang = 'zh') {
  return async (dispatch, getState) => {
    dispatch(actions.app.openLoading(loadingKey.CATEGORY))
    const categories = getState().menu.categories
    const { printCategories } = getState().merchant.sorting
    try {
      const promises = []
      if (_.get(category, 'localeNames')) {
        promises.push(dimorderApi.category.updateCategoryTranslate(category.id, {
          name: _.get(category, `localeNames.${lang}`, category.name),
          desc: _.get(category, `localeDesc.${lang}`, category.desc),
          locale: lang,
        }))
      }

      if (printOrder && printCategories !== printOrder) {
        promises.push(dimorderApi.merchant.updateCategoryPrinterOrder(printOrder))
        await dispatch(actions.merchant.fetchSorting())
      }

      if (categories[category.id]?.weekdays !== category.weekdays) {
        promises.push(dimorderApi.category.updateCategoryDays(category.id, category.weekdays))
      }

      if (categories[category.id]?.timerange !== category.timerange) {
        const data = category.timerange.length === 0 ? {} : {
          start: {
            ...category.timerange[0],
          },
          end: {
            ...category.timerange[1],
          },
        }
        promises.push(dimorderApi.category.updateCategoryTime(category.id, data))
      }

      if (categories[category.id]?.waiterOnly !== category.waiterOnly) {
        promises.push(dimorderApi.category.updateWaiterOnly(category.id, category.waiterOnly))
      }

      if (categories[category.id]?.takeawayOnly !== category.takeawayOnly) {
        promises.push(dimorderApi.category.updateTakeawayOnly(category.id, category.takeawayOnly))
      }

      if (categories[category.id]?.dineinOnly !== category.dineinOnly) {
        promises.push(dimorderApi.category.updateDineinOnly(category.id, category.dineinOnly))
      }

      if (categories[category.id]?.code !== category.code) {
        promises.push(dimorderApi.category.updateCode(category.id, category.code))
      }

      if (categories[category.id]?.kitchenDepartmentId !== category?.kitchenDepartmentId) {
        promises.push(dimorderApi.category.updateCategoryDepartment(category.id, category.kitchenDepartmentId))
      }

      await Promise.all(promises)

      if (parentId !== category.parentId) {
        const id = parentId.replace('SET_ROOT', '').replace('ROOT', '')
        await dimorderApi.category.updateCategoryParent(category.id, id)
      }

      await dispatch(fetchCategories2())
    } catch (error) {
      console.log('updateCategory error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.CATEGORY))
    }
  }
}

/**
 * 在分類中排序分類
 * @param {IAppCategory} category
 * @param {string[]} setIds
 * @returns {ThunkFunction}
 */
export function updateCategoryOrder (category, categories) {
  return async (dispatch, getState) => {
    try {
      let parentCategoryId = category.id
      if (category.id === 'ROOT' || category.id === 'SET_ROOT') {
        parentCategoryId = ''
      }
      const categoryIds = categories.map(c => c.id)
      dispatch({
        type: ActionTypes.UPDATE_CATEGORIES_ORDER,
        payload: {
          parentCategoryId,
          categories,
          id: category.id,
        },
      })

      await dimorderApi.merchant.updateCategoryOrder(parentCategoryId, categoryIds)
    } catch (error) {
      console.log('updateCategoryOrder error', error)
    }
  }
}

/**
 * 新增分類標籤
 * @param {IAppCategoryTag} localCategoryTag
 * @returns {ThunkFunction}
 */
export function createCategoryTag (localCategoryTag) {
  return async (dispatch, getState) => {
    try {
      const categoryTag = await dimorderApi.category.createCategoryTag({
        name: localCategoryTag.name,
        categoryIds: localCategoryTag.categoryIds,
        orderCutOffMins: localCategoryTag.orderCutOffMins,
      })

      dispatch({
        type: ActionTypes.CREATE_CATEGORY_TAG,
        payload: { categoryTag },
      })
    } catch (error) {
      console.log('createCategoryTag error', error)
    }
  }
}

/**
 * 更新分類標籤
 * @param {IAppCategoryTag} localCategoryTag
 * @returns {ThunkFunction}
 */
export function updateCategoryTag (localCategoryTag) {
  return async (dispatch, getState) => {
    try {
      const categoryTag = await dimorderApi.category.updateCategoryTag({
        id: localCategoryTag.id,
        name: localCategoryTag.name,
        merchantId: localCategoryTag.merchantId,
        categoryIds: localCategoryTag.categoryIds,
        orderCutOffMins: localCategoryTag.orderCutOffMins,
      })

      dispatch({
        type: ActionTypes.UPDATE_CATEGORY_TAG,
        payload: { categoryTag },
      })
      dispatch(actions.app.showAlert({ message: i18n.t('app.alert.saveSuccess') }))
    } catch (error) {
      console.log('updateCategoryTag error', error)
    }
  }
}

/**
 * 刪除分類標籤
 * @param {IAppCategoryTag} categoryTag
 * @returns {ThunkFunction}
 */
export function deleteCategoryTag (categoryTag) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.category.deleteCategoryTag(categoryTag.id)

      dispatch({
        type: ActionTypes.DELETE_CATEGORY_TAG,
        payload: { categoryTag },
      })
    } catch (error) {
      console.log('deleteCategoryTag error', error)
    }
  }
}

/**
 * 更新menu及所屬的category
 * @param {IAppMenuItem} menu
 * @param {EUpdateMenuAction} action
 * @returns {ThunkFunction}
 */
export function updateMenuAndCategory (menu, action) {
  return async (dispatch, getState) => {
    const category = _.find(getState().menu.categories, c => c.id === menu.categoryId)

    if (category) {
      let updatedCategory
      // 加上menuId, category path
      const appMenu = {
        ...menu,
        menuId: menu.id,
        path: category.path,
      }

      switch (action) {
        case 'CREATE':
          dispatch({
            type: ActionTypes.CREATE_MENU,
            payload: { menu: appMenu },
          })
          updatedCategory = _.assign({}, category, { menus: [...(category.menus || []), appMenu] })
          break
        case 'DELETE':
          dispatch({
            type: ActionTypes.DELETE_MENU,
            payload: { menu: appMenu },
          })
          updatedCategory = _.assign({}, category, { menus: category.menus.filter(m => m.id !== appMenu.id) })
          break
        case 'UPDATE':
          dispatch({
            type: ActionTypes.UPDATE_MENU,
            payload: { menu: appMenu },
          })
          updatedCategory = _.assign({}, category, { menus: category.menus.map(m => m.id === appMenu.id ? appMenu : m) })
          break
        default:
      }

      await dispatch({
        type: ActionTypes.UPDATE_CATEGORY,
        payload: { category: updatedCategory },
      })
      const updatedMenu = getState().menu
      await AsyncStorage.setItem('menu', JSON.stringify(updatedMenu))
    }
  }
}

/**
 * 新增單點
 * @param {Partial<IAppMenuItem>} localMenu
 * @returns {ThunkFunction}
 */
export function createMenu (localMenu) {
  return async (dispatch, getState) => {
    await dispatch(actions.app.openLoading(loadingKey.MENU))
    try {
      const menu = await dimorderApi.menu.createMenu({
        name: localMenu.name,
        shortName: localMenu.shortName ?? null,
        code: localMenu.code ?? '',
        desc: localMenu.desc ?? null,
        locale: localMenu.locale ?? 'zh',
        categoryID: localMenu.categoryId,
        price: Number(localMenu.price),
        discount: Number(localMenu.discount),
        priceUndetermined: localMenu.priceUndetermined,
        weight: Number(localMenu.weight) ?? 0,
        start: localMenu.timerange?.[0] ?? null,
        end: localMenu.timerange?.[1] ?? null,
        days: localMenu.weekdays ?? null,
        excludedDiscount: localMenu.excludedDiscount ?? false,
        excludedSurcharge: localMenu.excludedSurcharge ?? false,
        promoted: localMenu.promoted ?? false,
        promotedStart: localMenu.promotedTimerange?.[0] ?? null,
        promotedEnd: localMenu.promotedTimerange?.[1] ?? null,
        promotedDays: localMenu.promotedDays ?? null,
        openingPrompt: localMenu.openingPrompt ?? false,
        takeawayOpeningPrompt: localMenu.takeawayOpeningPrompt ?? false,
        kitchenDepartmentId: localMenu.kitchenDepartmentId ?? '',
      })

      dispatch(updateMenu({ ...localMenu, id: menu.id }))
    } catch (error) {
      console.log('createMenu error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.MENU))
    }
  }
}

/**
 * 刪除單點
 * @param {IAppMenuItem} menu
 * @returns {ThunkFunction}
 */
export function deleteMenu (menu) {
  return async (dispatch, getState) => {
    await dispatch(actions.app.openLoading(loadingKey.MENU))
    try {
      await dimorderApi.menu.deleteMenu(menu.id, true)
      await delay(1000)
      // 更新menu
      await dispatch(fetchCategories2())
    } catch (error) {
      console.log('deleteMenu error', error)
    } finally {
      await dispatch(actions.app.closeLoading(loadingKey.MENU))
    }
  }
}

/**
 * 隱藏單點
 * @param {IAppMenuItem} menu
 * @returns {ThunkFunction}
 */
export function hideMenu (menu, deleted) {
  return async (dispatch, getState) => {
    try {
      if (menu.isSet) {
        if (deleted) {
          await dimorderApi.menu.deleteSet(menu.id)
        } else {
          await dimorderApi.menu.revealSet(menu.id)
        }
      } else {
        if (deleted) {
          await dimorderApi.menu.deleteMenu(menu.id)
        } else {
          await dimorderApi.menu.revealMenu(menu.id)
        }
      }
      dispatch(fetchCategories2())
    } catch (error) {
      console.log('hideMenu error', error)
    }
  }
}

/**
 * 單點售罄
 * @param {IAppMenuItem} menu
 */
export function soldMenu (menu, isSold, setMenu = false) {
  return async (dispatch, getState) => {
    try {
      if (menu.isSet) {
        if (isSold) {
          await dimorderApi.menu.updateSetSold(menu.id)
        } else {
          await dimorderApi.menu.updateSetStock(menu.id)
        }
      } else {
        const id = setMenu ? menu.menuId : menu.id
        if (isSold) {
          await dimorderApi.menu.setMenuSold(id)
        } else {
          await dimorderApi.menu.setMenuStock(id)
        }
      }
      dispatch(fetchCategories2())
    } catch (error) {
      console.log('soldMenu error', error)
    }
  }
}

export function soldMenus (menus) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateMenusSold(menus)
    } catch (error) {
      console.log('soldMenus error', error)
    }
  }
}

export function stockMenus (menus) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateMenusStock(menus)
    } catch (error) {
      console.log('stockMenus error', error)
    }
  }
}

export function soldSets (sets) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateSetsSold(sets)
    } catch (error) {
      console.log('soldSets error', error)
    }
  }
}

export function stockSets (sets) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateSetsStock(sets)
    } catch (error) {
      console.log('stockSets error', error)
    }
  }
}

/**
 * 隱藏分類
 * @param {IAppMenuItem} setMenu
 * @param {boolean} enable
 * @returns {ThunkFunction}
 */
export function enableSetMenu (setMenu, enable) {
  return async (dispatch, getState) => {
    try {
      const newSetMenu = { ...setMenu, disabled: !enable }
      await dimorderApi.menu.updateSetMenuEnable(setMenu.id, enable)
      dispatch(updateMenuAndCategory(newSetMenu, 'UPDATE'))
    } catch (error) {
      console.log('enableSetMenu error', error)
    }
  }
}

/**
 * 更新單點
 * @param {IAppMenuItem} menu
 * @returns {ThunkFunction}
 */
export function updateMenu (menu) {
  return async (dispatch, getState) => {
    await dispatch(actions.app.openLoading(loadingKey.MENU))
    try {
      const batchLocaleOptions = getState().merchant.data?.setting?.batchLocaleOptions
      const defaultLocale = ['zh', 'en']
      const locale = _.filter(_.union(defaultLocale, batchLocaleOptions), l => {
        return _.get(menu, `localeNames.[${l}]`, null)
      })
      const promises = _.map(locale, l => {
        const localeName = _.get(menu, `localeNames.[${l}]`, menu.name)
        const localeDesc = _.get(menu, `localeDesc.[${l}]`, '')
        return dimorderApi.menu.updateMenu(menu.id, {
          name: localeName,
          shortName: menu.shortName ?? null,
          code: menu.code ?? '',
          desc: localeDesc,
          locale: l,
          categoryID: menu.categoryId,
          price: Number(menu.price),
          discount: Number(menu.discount),
          priceUndetermined: menu.priceUndetermined,
          weight: Number(menu.weight) ?? 0,
          start: menu.timerange?.[0] ?? null,
          end: menu.timerange?.[1] ?? null,
          days: menu.weekdays ?? [],
          excludedDiscount: menu.excludedDiscount ?? false,
          excludedSurcharge: menu.excludedSurcharge ?? false,
          promoted: menu.promoted ?? false,
          promotedStart: menu.promotedTimerange?.[0] ?? null,
          promotedEnd: menu.promotedTimerange?.[1] ?? null,
          promotedDays: menu.promotedDays ?? null,
          inventory: menu.inventory ?? 0,
          stockAlertLevel: menu.stockAlertLevel ?? 0,
          tableAutoCount: menu.tableAutoCount || false,
          openingPrompt: menu.openingPrompt || false,
          takeawayOpeningPrompt: menu.takeawayOpeningPrompt || false,
          kitchenDepartmentId: menu.kitchenDepartmentId || '',
        })
      })

      const updatedMenus = await Promise.all(promises)
      const updatedMenu = updatedMenus[0]

      await Promise.all([
        dimorderApi.menu.updateMenuTakeawayEnable(menu.id, !menu.takeawayDisabled),
        dimorderApi.menu.updateMenuNonTakeawayEnable(menu.id, !menu.nonTakeawayDisabled),
        dimorderApi.menu.updateMenuDepartment(menu.id, menu.kitchenDepartmentId),
      ])

      if (!_.isEqual(updatedMenu.prices, menu.prices)) {
        const promises = _.map(menu.prices, p => {
          return dimorderApi.menu.updateMenuTakeawayPrice(menu.id, {
            ...p,
            price: Number(p.price),
          })
        })
        await Promise.all(promises)
      }

      const menuOptions = _.map(menu.options, optionGorup => {
        const updatedOptionGroup = { ...optionGorup }
        delete updatedOptionGroup.localeNames
        if (optionGorup.options?.length) {
          const options = _.map(optionGorup.options, option => {
            const updatedOption = { ...option }
            delete updatedOption.localeNames
            return updatedOption
          })
          return { ...updatedOptionGroup, options }
        }
        return updatedOptionGroup
      })

      if (!_.isEqual(updatedMenu.tags, menu.tags)) {
        const addTags = _.differenceBy(menu.tags, updatedMenu.tags, 'name')
        const removeTags = _.differenceBy(updatedMenu.tags, menu.tags, 'name').map(t => {
          return { ...t, remove: true }
        })
        const promises = _.map(_.concat(addTags, removeTags), t => {
          return dimorderApi.menu.updateMenuTag(menu.id, t)
        })
        await Promise.all(promises)
      }

      if (!_.isEqual(updatedMenu.options, menuOptions)) {
        // 刪除不在 localMenu.options 裡的 optionGroup
        await Promise.all(_.map(updatedMenu.options, async optionGroup => {
          const index = _.findIndex(menuOptions, og => og.id === optionGroup.id)

          if (index < 0) {
            await dimorderApi.menu.deleteMenuOptionGroup(updatedMenu.id, optionGroup.id)
          }
        }))

        // 新增/修改 optionGroup
        await Promise.all(_.map(menuOptions, async (optionGroup, localIndex) => {
          const index = _.findIndex(updatedMenu.options, og => og.id === optionGroup.id)

          if (index >= 0) {
            // 更新選項群
            if (!_.isEqual(updatedMenu.options[index], optionGroup)) {
              const updatedOptions = _.filter(optionGroup.options, o => o.id)
              await dimorderApi.menu.updateMenuOptionGroup(updatedMenu.id, optionGroup.id, { ...optionGroup, options: updatedOptions })

              // 刪除 options
              for await (const option of updatedMenu.options[index].options) {
                if (_.findIndex(optionGroup.options, o => o.id === option.id) < 0 && option.id) {
                  await dimorderApi.menu.deleteMenuOption(updatedMenu.id, optionGroup.id, option.id)
                }
              }

              // 新增/修改 options
              for await (const localOption of optionGroup.options) {
                const updateOption = { ...localOption, hide: localOption?.hidden }
                if (localOption?.id) {
                  await dimorderApi.menu.updateMenuOption(updatedMenu.id, optionGroup.id, localOption.id, updateOption)
                } else {
                  await dimorderApi.menu.createMenuOption(updatedMenu.id, optionGroup.id, updateOption)
                }
              }
            }
          } else {
            // 新增選項群
            await dimorderApi.menu.createMenuOptionGroup(updatedMenu.id, optionGroup)
          }
        }))
      }

      if (menu.optionGroupPresets) {
        const menuOptionGroupPresets = _.map(menu.optionGroupPresets, optionGorup => {
          const updatedOptionGroup = { ...optionGorup }
          delete updatedOptionGroup.localeNames
          if (optionGorup.options?.length) {
            const options = _.map(optionGorup.options, option => {
              const updatedOption = { ...option }
              delete updatedOption.localeNames
              return updatedOption
            })
            return { ...updatedOptionGroup, options }
          }
          return updatedOptionGroup
        })

        if (!_.isEqual(updatedMenu.optionGroupPresets, menuOptionGroupPresets)) {
          await dimorderApi.menu.updateMenuOptionGroupPresets(menu.id, menuOptionGroupPresets, menu.locale)
        }
      }

      // 更新 menu
      // dispatch(updateMenuAndSetMenu(menu, 'UPDATE'))
      dispatch(fetchCategories2())
    } catch (error) {
      console.log('updateMenu error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.MENU))
    }
  }
}

/**
 * 更新單點選項
 * @returns {ThunkFunction}
 */
export function updateMenuOptionTranslation (menuId, groupId, optionId, option) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateMenuOption(menuId, groupId, optionId, option)
    } catch (error) {
      console.log('updateMenuOptionTranslation error', error)
    }
  }
}

/**
 * 更新單點選項群
 * @returns {ThunkFunction}
 */
export function updateMenuOptionGroupTranslation (menuId, groupId, optionGroup) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateMenuOptionGroup(menuId, groupId, optionGroup)
    } catch (error) {
      console.log('updateMenuOptionTranslation error', error)
    }
  }
}

/**
 * 在分類中排序餐點
 * @param {IAppCategory} category
 * @param {string[]} menuIds
 * @returns {ThunkFunction}
 */
export function updateMenuOrder (categoryId, menuIds, menus) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_MENUS_ORDER,
        payload: {
          categoryId,
          menus,
        },
      })
      if (categoryId === 'promote') {
        await dimorderApi.merchant.updatePromoteOrder(menuIds)
      } else {
        await dimorderApi.merchant.updateMenuOrder(categoryId, menuIds)
      }
    } catch (error) {
      console.log('updateMenuOrder error', error)
    }
  }
}

/**
 * 套餐步驟項目排序
 * @param {IAppCategory} category
 * @param {string[]} menuIds
 * @returns {ThunkFunction}
 */
export function updateSetMenuOrder (categoryId, menuIds, set) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.merchant.updateSetmenuOrder(categoryId, menuIds)
      dispatch(updateSetAndCategory(set, 'UPDATE'))
    } catch (error) {
      console.log('updateSetMenuOrder error', error)
    }
  }
}

/**
 * 更新set及所屬的category
 * @param {IAppMenuItem} set
 * @param {string} categoryId
 * @param {EUpdateMenuAction} action
 * @returns {ThunkFunction}
 */
export function updateSetAndCategory (set, action) {
  return async (dispatch, getState) => {
    const category = _.find(getState().menu.categories, c => c.id === set.categoryId)
    dispatch(updatePromoteAndSoldOut(set, action === 'DELETE', 'sets'))

    if (category) {
      let updatedCategory
      // 加上isSet, categoryId, category path, steps, menus
      const appSet = {
        ...set,
        isSet: true,
        categoryId: set.categoryId,
        path: category.path,
        steps: _.map(set.steps, (step, index) => {
          return {
            ...step,
            id: step.key,
            setId: set.id,
            parentId: category.parentId,
            isSetStep: true,
          }
        }),
        menus: _.map(set.menus, menu => {
          return {
            ...menu,
            path: category.path,
          }
        }) || [],
      }

      switch (action) {
        case 'CREATE':
          dispatch({
            type: ActionTypes.CREATE_SET,
            payload: { set: appSet },
          })
          updatedCategory = _.assign({}, category, { sets: [...(category.sets || []), appSet] })
          break
        case 'DELETE':
          dispatch({
            type: ActionTypes.DELETE_SET,
            payload: { set: appSet },
          })
          updatedCategory = _.assign({}, category, { sets: category.sets.filter(s => s.id !== appSet.id) })
          break
        case 'UPDATE':
          dispatch({
            type: ActionTypes.UPDATE_SET,
            payload: { set: appSet },
          })
          updatedCategory = _.assign({}, category, { sets: category.sets.map(s => s.id === appSet.id ? appSet : s) })
          break
        case 'SOLD':
          dispatch({
            type: ActionTypes.UPDATE_SET,
            payload: { set: appSet },
          })
          updatedCategory = _.assign({}, category, { menus: category.menus.map(m => m.id === appSet.id ? appSet : m) })
          break
        default:
      }

      dispatch({
        type: ActionTypes.UPDATE_CATEGORY,
        payload: { category: updatedCategory },
      })
    }
  }
}

/**
 * 新增套餐
 * @param {Partial<IAppSet>} localSet
 * @returns {ThunkFunction}
 */
export function createSet (localSet) {
  return async (dispatch, getState) => {
    try {
      console.log(localSet)
      const set = await dimorderApi.menu.createSet({
        name: localSet.name,
        shortName: localSet.shortName ?? null,
        code: localSet.code ?? '',
        desc: localSet.desc ?? null,
        locale: 'zh',
        categoryID: localSet.categoryId,
        price: Number(localSet.price),
        discount: Number(localSet.discount),
        priceUndetermined: localSet.priceUndetermined,
        weight: Number(localSet.weight) ?? 0,
        start: localSet.timerange?.[0] ?? null,
        end: localSet.timerange?.[1] ?? null,
        days: localSet.days ?? null,
        excludedDiscount: localSet.excludedDiscount ?? false,
        excludedSurcharge: localSet.excludedSurcharge ?? false,
        promoted: localSet.promoted ?? false,
        promotedStart: localSet.promotedTimeRange?.[0] ?? null,
        promotedEnd: localSet.promotedTimeRange?.[1] ?? null,
        promotedDays: localSet.promotedDays ?? null,
        inventory: localSet.inventory ?? 0,
        stockAlertLevel: localSet.stockAlertLevel ?? 0,
      })
      const promises = localSet.prices.map(p => {
        return dimorderApi.menu.updateSetTakeawayPrice(set.id, {
          ...p,
          price: Number(p.price),
        })
      })
      await Promise.all(promises)

      const updatedSet = await dimorderApi.menu.getSet(set.id, 'zh')

      // 更新set
      dispatch(updateSetAndCategory(updatedSet, 'CREATE'))
    } catch (error) {
      console.log('createSet error', error)
    }
  }
}

/**
 * 刪除套餐
 * @param {IAppSet} set
 * @returns {ThunkFunction}
 */
export function deleteSet (set) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.deleteSet(set.id, true)

      // 更新set
      dispatch(updateSetAndCategory(set, 'DELETE'))
    } catch (error) {
      console.log('deleteSet error', error)
    }
  }
}

/**
 * 更改套餐
 * @param {IAppSet} set
 * @returns {ThunkFunction}
 */
export function updateSet (set) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateSet(
        set.id
        , {
          name: set.name,
          shortName: set.shortName ?? null,
          code: set.code ?? '',
          desc: set.desc ?? null,
          locale: set.locale,
          categoryID: set.categoryId,
          price: Number(set.price),
          discount: Number(set.discount),
          priceUndetermined: set.priceUndetermined,
          weight: Number(set.weight) ?? 0,
          start: set.timerange?.[0] ?? null,
          end: set.timerange?.[1] ?? null,
          days: set.days ?? null,
          excludedDiscount: set.excludedDiscount ?? false,
          excludedSurcharge: set.excludedSurcharge ?? false,
          promoted: set.promoted ?? false,
          promotedStart: set.promotedTimeRange?.[0] ?? null,
          promotedEnd: set.promotedTimeRange?.[1] ?? null,
          promotedDays: set.promotedDays ?? null,
          inventory: set.inventory ?? 0,
          stockAlertLevel: set.stockAlertLevel ?? 0,
        })
      if (_.get(set, 'prices')) {
        const promises = set.prices.map(p => {
          return dimorderApi.menu.updateSetTakeawayPrice(set.id, {
            ...p,
            price: Number(p.price),
          })
        })
        await Promise.all(promises)
      }
      dispatch(updateSetAndCategory(set, 'UPDATE'))
      const updatedSet = await dimorderApi.menu.getSet(set.id, 'zh')

      // 排序
      await dispatch(updateSetStepOrder(updatedSet, set.steps.map(step => step.key)))

      await dispatch(fetchCategories2())
      const updatedMenu = getState().menu
      await AsyncStorage.setItem('menu', JSON.stringify(updatedMenu))
    } catch (error) {
      console.log('updateSet error', error)
    }
  }
}

/**
 * 在分類中排序套餐
 * @param {IAppCategory} category
 * @param {string[]} setIds
 * @returns {ThunkFunction}
 */
export function updateSetOrder (categoryId, setIds, sets) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.merchant.updateSetOrder(categoryId, setIds)
      await dispatch({
        type: ActionTypes.UPDATE_SETS_ORDER,
        payload: {
          categoryId,
          sets,
        },
      })
    } catch (error) {
      console.log('updateSetOrder error', error)
    }
  }
}

/**
 * 新增套餐(set)下的步驟
 * @param {Partial<IAppSetStep>} localStep
 * @returns {ThunkFunction}
 */
export function createSetStep (localStep) {
  return async (dispatch, getState) => {
    try {
      const set = await dimorderApi.menu.addSetStep(localStep.setId, {
        name: localStep.name,
        desc: localStep.desc ?? null,
        min: localStep.min,
        max: localStep.max,
        optional: localStep.optional ?? false,
        dependsOnSetMenuItems: localStep.dependsOnSetMenuItems ?? null,
        autoNextStep: localStep.autoNextStep ?? false,
      })

      dispatch(updateSetAndCategory(set, 'UPDATE'))
    } catch (error) {
      console.log('createSetStep error', error)
    }
  }
}

/**
 * 刪除套餐(set)下的步驟
 * @param {IAppSetStep} step
 * @returns {ThunkFunction}
 */
export function deleteSetStep (step) {
  return async (dispatch, getState) => {
    try {
      const set = await dimorderApi.menu.deleteSetStep(step.setId, step.key)
      // TODO: fetch的時候要filter掉刪除的
      dispatch(updateSetAndCategory(set, 'UPDATE'))
    } catch (error) {
      console.log('deleteSetStep error', error)
    }
  }
}

/**
 * 更新套餐(set)下的步驟
 * @param {IAppSetStep} step
 * @returns {ThunkFunction}
 */
export function updateSetStep (step, menus, locale = 'zh') {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateSetStep(step.setId, {
        key: step.key,
        name: step.name,
        desc: step.desc ?? null,
        min: step.min,
        max: step.max,
        optional: step.optional ?? false,
        dependsOnSetMenuItems: step.dependsOnSetMenuItems ?? null,
        autoNextStep: step.autoNextStep ?? false,
        locale,
      })
      await dispatch(fetchCategories2())
    } catch (error) {
      console.log('updateSetStep error', error)
    }
  }
}

/**
 * 排序套餐步驟
 * @param {IAppSet} set
 * @param {string[]} stepKeys
 * @returns {ThunkFunction}
 */
export function updateSetStepOrder (set, stepKeys) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.updateSetStepOrder(set.id, stepKeys)
    } catch (error) {
      console.log('updateSetStepOrder error', error)
    }
  }
}

/**
 * 新增套餐(set)下的步驟(step)下的餐點
 * @param {string} setId
 * @param {string} stepId
 * @param {Partial<ISetMenu>} localSetMenu
 */
export function addSetMenu (setId, stepId, localSetMenu) {
  return async (dispatch, getState) => {
    try {
      const merchantId = getState().merchant.data.id
      const setMenu = await dimorderApi.menu.addSetMenu(setId, stepId, localSetMenu.menuId)
      // 更新欄位
      const setPrice = { ...setMenu, price: localSetMenu.price ?? 0 }
      await dimorderApi.menu.updateSetMenu(setId, setMenu.id, setPrice)
      if (_.get(localSetMenu, 'prices')) {
        const promises = localSetMenu.prices.map(p => {
          return dimorderApi.menu.updateSetMenuPrice(setMenu.id, {
            ...p,
            price: Number(p.price),
          })
        })
        await Promise.all(promises)
      }
      // 重新抓所有setMenus
      const updatedSetMenu = await dimorderApi.menu.getSetMenus(merchantId, setId) || []

      const set = await _.find(getState().menu.sets, set => set.id === setId)

      if (set) {
        const updatedSet = _.assign({}, set, { menus: updatedSetMenu })
        dispatch(updateSetAndCategory(updatedSet, 'UPDATE'))
      }
    } catch (error) {
      console.log('createSetMenu error', error)
    }
  }
}

/**
 * 刪除套餐(set)下的步驟(step)下的餐點
 * @param {string} stepId
 * @param {string} setMenuId
 * @returns {ThunkFunction}
 */
export function removeSetMenu (setId, setMenuId) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.deleteSetMenu(setMenuId)
      const set = await _.find(getState().menu.sets, set => set.id === setId)

      if (set) {
        const updatedSet = _.assign({}, set, { menus: set.menus.filter(m => m.id !== setMenuId) })
        dispatch(updateSetAndCategory(updatedSet, 'UPDATE'))
      }
    } catch (error) {
      console.log('deleteSetMenu error', error)
    }
  }
}

/**
 * 更新套餐(set)下的步驟(step)下的餐點
 * @param {string} setId
 * @param {IAppMenuItem} setMenu
 * @returns {ThunkFunction}
 */
export function updateSetMenu (setId, setMenu) {
  return async (dispatch, getState) => {
    try {
      const merchantId = getState().merchant.data.id
      await dimorderApi.menu.updateSetMenu(setId, setMenu.id, setMenu)
      if (_.get(setMenu, 'prices')) {
        const promises = setMenu.prices.map(p => {
          return dimorderApi.menu.updateSetMenuPrice(setMenu.id, {
            ...p,
            price: Number(p.price),
          })
        })
        await Promise.all(promises)
      }
      // 重新抓所有setMenus
      const updatedSetMenu = await dimorderApi.menu.getSetMenus(merchantId, setId) || []

      const set = await _.find(getState().menu.sets, set => set.id === setId)

      if (set) {
        const updatedSet = _.assign({}, set, { menus: updatedSetMenu })
        dispatch(updateSetAndCategory(updatedSet, 'UPDATE'))
      }
    } catch (error) {
      console.log('updateSetMenu error', error)
    }
  }
}

/**
 * 上傳圖片
 * @param {string} id
 * @param {Blob} image
 */
export function uploadImage (id, image) {
  return async (dispatch) => {
    try {
      await dimorderApi.menu.updateMenuImage(id, image)
      await dispatch(actions.menu.fetchCategories())
    } catch (error) {
      console.log('uploadImage error', error)
    }
  }
}

/**
 * 上傳套餐圖片
 * @param {string} id
 * @param {Blob} image
 * @returns {ThunkFunction}
 */
export function uploadSetImage (id, image) {
  return async (dispatch) => {
    try {
      await dimorderApi.menu.updateSetImage(id, image)
      await dispatch(actions.menu.fetchCategories())
    } catch (error) {
      console.log('uploadSetImage error', error)
    }
  }
}

/**
 * 刪除圖片
 * @param {string} id
 * @param {Blob} image
 * @returns {ThunkFunction}
 */
export function deleteImage (id) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.deleteMenuImage(id)
      const menus = getState().menu.menus
      if (menus[id]) {
        const menu = { ...menus[id], image: '' }
        dispatch(updateMenuAndSetMenu(menu, 'UPDATE', 'image'))
      }
    } catch (error) {
      console.log('deleteImage error', error)
    }
  }
}

/**
 * 刪除套餐圖片
 * @param {string} id
 * @param {Blob} image
 * @returns {ThunkFunction}
 */
export function deleteSetImage (id) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.menu.deleteSetImage(id)
      const sets = getState().menu.sets
      if (sets[id]) {
        const set = { ...sets[id], image: '' }
        dispatch(updateMenuAndSetMenu(set, 'UPDATE', 'image'))
      }
    } catch (error) {
      console.log('deleteSetImage error', error)
    }
  }
}

/**
 * 隱藏分類
 * @param {string} categoryId
 * @param {boolean} enable
 * @returns {ThunkFunction}
 */
export function disableCategory (categoryId, enable) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.category.updateCategoryEnable(categoryId, enable)
      const category = _.find(getState().menu.categories, c => c.id === categoryId)
      dispatch({
        type: ActionTypes.UPDATE_CATEGORY,
        payload: { category: { ...category, disabled: !enable } },
      })
    } catch (error) {
      console.log('deleteSetImage error', error)
    }
  }
}

export function hideCategory (categoryId, hidden) {
  return async (dispatch, getState) => {
    try {
      await dimorderApi.category.updateCategoryHidden(categoryId, hidden)
      const category = _.find(getState().menu.categories, c => c.id === categoryId)
      dispatch({
        type: ActionTypes.UPDATE_CATEGORY,
        payload: { category: { ...category, hidden: !hidden } },
      })
    } catch (error) {
      console.log('deleteSetImage error', error)
    }
  }
}

/**
 * reset
 * @returns {ThunkFunction}
 */
export function resetSelections () {
  return async (dispatch, getState) => {
    let defaultCategory = null
    const menu = getState().menu

    if (menu.rootCategory.categories.length > 0) {
      // 有單點分類就先選單點分類的第一個
      defaultCategory = menu.rootCategory.categories[0]
    } else if (menu.rootSetCategory.categories.length > 0) {
      // 沒有單點分類，有套餐分類就先選套餐分類的第一個
      defaultCategory = menu.rootSetCategory.categories[0]
    }

    dispatch({
      type: ActionTypes.RESET_SELECTIONS,
      payload: { category: defaultCategory },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function setOptionGroupPreset (optionGroupPreset, menuIds, original) {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.MENU))
      const addMenus = _.difference(menuIds, original)
      const deleteMenus = _.difference(original, menuIds)
      if (addMenus.length || deleteMenus.length) {
        if (addMenus.length) {
          await dimorderApi.menu.setOptionGroupPreset(optionGroupPreset, addMenus)
          dispatch(updateMenuOptions(optionGroupPreset, 'CREATE', true, addMenus))
        }
        if (deleteMenus.length) {
          await dimorderApi.menu.deleteMenuOptionGroupPreset(optionGroupPreset.id, deleteMenus)
          dispatch(updateMenuOptions(optionGroupPreset, 'DELETE', true, deleteMenus))
        }
      }
    } catch (error) {
      console.log('setOptionGroupPreset error', error)
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.MENU))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateMenuAndSetMenu (menu, action, path) {
  return (dispatch, getState) => {
    try {
      dispatch(updateMenuAndCategory(menu, action))
      dispatch(updatePromoteAndSoldOut(menu, action === 'DELETE'))
      const sets = getState().menu.sets
      for (const [key, value] of Object.entries(sets)) {
        let isUpdate = false
        let setMenus = value.menus ? [...value.menus] : []
        switch (action) {
          case 'DELETE':
            setMenus = _.filter(setMenus, setMenu => {
              if (setMenu.menuId === menu.id) {
                isUpdate = true
              }
              return setMenu.menuId !== menu.id
            })
            break
          case 'UPDATE':
            setMenus = _.map(setMenus, setMenu => {
              if (setMenu.menuId === menu.id) {
                let updatedMenu = setMenu
                if (path) {
                  updatedMenu = { ...setMenu, [path]: menu[path] }
                } else {
                  updatedMenu = {
                    ...setMenu,
                    name: menu.name,
                    desc: menu.desc,
                    options: menu.options,
                    optionGroupPresets: menu.optionGroupPresets,
                    localeNames: menu.localeNames,
                  }
                }
                isUpdate = !_.isEqual(updatedMenu, setMenu)
                return updatedMenu
              }
              return setMenu
            })
            break
        }
        if (isUpdate) {
          const updatedSet = { ...value, menus: setMenus }
          dispatch(updateSetAndCategory(updatedSet, 'UPDATE'))
        }
      }
    } catch (error) {
      console.log('updateMenuAndSetMenu error', error)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateMenuOptions (option, action, isPresets = false, menuIds = []) {
  return (dispatch, getState) => {
    try {
      const categories = _.filter(getState().menu.categories, category => {
        return !(category.id === 'ROOT' || category.id === 'SET_ROOT' || category.type === 'SET' || category.categories.length > 0)
      })
      const menus = _.uniq(_.flatMap(categories, 'menus') || [])
      _.each(menus, menu => {
        let isUpdate = false
        let options = menu.options ? [...menu.options] : []
        let optionGroupPresets = menu.optionGroupPresets ? [...menu.optionGroupPresets] : []
        switch (action) {
          case 'DELETE':
            if (!menuIds?.length || menuIds.includes(menu.id)) {
              options = _.filter(options, menuOption => {
                if (menuOption.id === option.id) {
                  isUpdate = true
                }
                return menuOption.id !== option.id
              })
              optionGroupPresets = _.filter(optionGroupPresets, menuOption => {
                if (menuOption.id === option.id) {
                  isUpdate = true
                }
                return menuOption.id !== option.id
              })
            }
            break
          case 'UPDATE':
            options = _.map(options, menuOption => {
              if (menuOption.id === option.id) {
                isUpdate = true
                return option
              }
              return menuOption
            })
            optionGroupPresets = _.map(optionGroupPresets, menuOption => {
              if (menuOption.id === option.id) {
                isUpdate = true
                return option
              }
              return menuOption
            })
            break
          case 'CREATE':
            if (menuIds.includes(menu.id)) {
              isUpdate = true
              if (isPresets) {
                optionGroupPresets.push(option)
              } else {
                options.push(option)
              }
            }
            break
        }
        if (isUpdate) {
          const updatedMenu = { ...menu, options, optionGroupPresets }
          dispatch(updateMenuAndSetMenu(updatedMenu, 'UPDATE', 'options'))
          dispatch(updateMenuAndSetMenu(updatedMenu, 'UPDATE', 'optionGroupPresets'))
        }
      })
    } catch (error) {
      console.log('updateMenuOptions', error)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updatePromoteAndSoldOut (menu, isDelete = false, type = 'menus') {
  return (dispatch, getState) => {
    try {
      const promoMenu = getState().menu.promote[type]
      const soldMenu = getState().menu.soldOut[type]
      const promote = _.clone(promoMenu ?? [])
      const soldOut = _.clone(soldMenu ?? [])
      const promoIndex = promote.findIndex(m => m.id === menu.id)
      const soldIndex = soldOut.findIndex(m => m.id === menu.id)

      if (isDelete) {
        if (promoIndex > -1) {
          promote.splice(promoIndex, 1)
        }
        if (soldIndex > -1) {
          soldOut.splice(soldIndex, 1)
        }
      } else {
        // 推薦
        if (promoIndex > -1) {
          if (menu.promoted) {
            promote[promoIndex] = menu
          } else {
            promote.splice(promoIndex, 1)
          }
        } else if (menu.promoted) {
          promote.push(menu)
        }
        // 售罄
        if (soldIndex > -1) {
          if (menu.sold) {
            soldOut[soldIndex] = menu
          } else {
            soldOut.splice(soldIndex, 1)
          }
        } else if (menu.sold) {
          soldOut.push(menu)
        }
      }
      if (!_.isEqual(promote, promoMenu)) {
        dispatch({
          type: ActionTypes.UPDATE_PROMOTE,
          payload: {
            promote: promote,
            type,
          },
        })
      }
      if (!_.isEqual(soldOut, soldMenu)) {
        dispatch({
          type: ActionTypes.UPDATE_SOLDOUT,
          payload: {
            soldOut: soldOut,
            type,
          },
        })
      }
    } catch (error) {
      console.log('updatePromoteAndSoldOut error', error)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function filterCategoryWithSearchText () {
  return (dispatch, getState) => {
    const searchText = getState().setting.searchText
    const { rootCategory, rootSetCategory } = searchCategories(searchText)

    dispatch({
      type: ActionTypes.UPDATE_FILTERED_CATEGORY,
      payload: {
        rootCategory: rootCategory,
        rootSetCategory: rootSetCategory,
      },
    })
  }
}
