import { StyleSheet, Text, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { calculateStepQuantityTotal, getStepQuantityMax } from '@/libs/order'
import { useSelectedBatchItem, useSelectedSetMenuIndex } from '@/hooks/order'
import { useTranslation } from 'react-i18next'
import ActionButton from './ActionButton'
import React from 'react'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

// 按多少改多少模式
export default function NumberQuickAction (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  const [selectedItem, selectedSetItem] = useSelectedBatchItem()
  const selectedSetMenuIndex = useSelectedSetMenuIndex()
  const stepQuantityLimit = getStepQuantityMax(selectedItem, selectedSetStep, selectedSetMenuIndex)
  const stepQuantityTotal = calculateStepQuantityTotal(selectedItem, selectedSetStep, selectedSetMenuIndex)

  const isDisabled = !selectedItem || selectedItem?.isSet

  const handleUpdateQuantity = (number) => {
    // 套餐不能改數量
    if (isDisabled) return

    // 所選的是 SetItem 且步驟與 CategoryBar 選擇的步驟相同
    if (selectedSetItem && selectedSetItem.step === selectedSetStep.id) {
      const increaseAmount = number - selectedSetItem.quantity
      if (stepQuantityTotal >= stepQuantityLimit && increaseAmount >= 0) return // 如果已經 max 且數量是增加或不變的話不做事情
      const isOverflow = increaseAmount + stepQuantityTotal > stepQuantityLimit
      const difference = stepQuantityLimit - stepQuantityTotal
      const quantity = isOverflow ? difference : increaseAmount
      dispatch(actions.orderBatch.addQuantity(quantity))
      return
    }

    // 所選的是 Item 而不是 SetItem 且 Item 的數量不等於輸入的數量
    if (!selectedSetItem && selectedItem && selectedItem.quantity !== number) {
      dispatch(actions.orderBatch.updateQuantity(number))
    }
  }

  const handleAddQuantity = (number) => {
    // 套餐不能改數量
    if (isDisabled) return

    // 所選的是 SetItem 且步驟與 CategoryBar 選擇的步驟相同
    if (selectedSetItem && selectedSetItem.step === selectedSetStep.id) {
      if (stepQuantityTotal >= stepQuantityLimit && number >= 0) return // 如果已經 max 且數量是增加或不變的話不做事情
      const isOverflow = number + stepQuantityTotal > stepQuantityLimit
      const difference = stepQuantityLimit - stepQuantityTotal
      const quantity = isOverflow ? difference : number
      dispatch(actions.orderBatch.addQuantity(quantity))
      return
    }

    // 所選的是 Item 而不是 SetItem 且 Item 的數量不等於輸入的數量
    if (!selectedSetItem && selectedItem && selectedItem.quantity !== number) {
      dispatch(actions.orderBatch.addQuantity(number))
    }
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t('app.page.order.numberInputBar.quickMode')}</Text>
      <ActionButton
        onPress={() => handleAddQuantity(1)}
        iconSource={require('@icons/add.png')}
        disabled={isDisabled}
      />
      {[...Array(9).keys()].map((index) => {
        const number = index + 1
        return (
          <ActionButton
            key={number}
            onPress={() => handleUpdateQuantity(number)}
            text={number}
            disabled={isDisabled}
          />
        )
      })}
      <ActionButton
        onPress={() => handleAddQuantity(10)}
        text='+10'
        disabled={isDisabled}
      />
      <ActionButton
        onPress={() => dispatch(actions.orderBatch.addQuantity(-1))}
        iconSource={require('@icons/minus.png')}
        disabled={isDisabled}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 70,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.primary,
    paddingTop: 24,
    paddingBottom: 24,
    ...shadows.default,
    zIndex: 100,
  },
  title: {
    color: colors.white,
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: 8,
    marginBottom: 8,
  },
})
