export default {
  INIT: 'AUTH/INIT',
  MERCHANT_LOGIN: 'AUTH/MERCHANT_LOGIN',
  MERCHANT_LOGOUT: 'AUTH/MERCHANT_LOGOUT',
  USER_LOGIN: 'AUTH/USER_LOGIN',
  USER_LOGOUT: 'AUTH/USER_LOGOUT',
  UPDATE_LOADING: 'AUTH/UPDATE_LOADING',
  UPDATE_ERROR: 'AUTH/UPDATE_ERROR',
  UPDATE_USERS: 'AUTH/UPDATE_USERS',
  UPDATE_CURRENT_USER_PERMISSION: 'AUTH/UPDATE_CURRENT_USER_PERMISSION',
  UPDATE_SCOPE_GROUPS: 'AUTH/UPDATE_SCOPE_GROUPS',
  ADD_SCOPE_GROUP: 'AUTH/ADD_SCOPE_GROUP',
  UPDATE_SCOPE_GROUP: 'AUTH/UPDATE_SCOPE_GROUP',
  DELETE_SCOPE_GROUP: 'AUTH/DELETE_SCOPE_GROUP',
  UPDATE_SCOPE_GROUP_USERS: 'AUTH/UPDATE_SCOPE_GROUP_USERS',
  ADD_SCOPE_GROUP_USER: 'AUTH/ADD_SCOPE_GROUP_USER',
  DELETE_SCOPE_GROUP_USER: 'AUTH/DELETE_SCOPE_GROUP_USER',
  SET_APPROVER: 'AUTH/SET_APPROVER',
}
