import { FontAwesome5 } from '@expo/vector-icons'
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { actions, useDispatch } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef CancelPaymentsAndUnlockOrderBannerProps
 * @property {() => void} setIsCancelPaymentDialogOpen
 */

/**
 *
 * @param {CancelPaymentsAndUnlockOrderBannerProps} props
 * @returns
 */

export default function CancelPaymentsAndUnlockOrderBanner (props) {
  const { t } = useTranslation()
  const { setIsCancelPaymentDialogOpen } = props
  const dispatch = useDispatch()

  return (
    <View style={styles.customerIsPayingBanner}>
      <Text style={styles.whiteText}>
        <FontAwesome5 name='lock' size={18} color={colors.yellow} /> {' '}
        {t('app.page.orderHistory.orderDetail.paying')}
      </Text>

      <TouchableWithoutFeedback onPress={() => {
        dispatch(actions.auth.permissionCheck('change-after-paid', () => {
          setIsCancelPaymentDialogOpen(true)
        }))
      }}
      >
        <Text style={styles.whiteText}>
          <FontAwesome5 name='lock-open' size={18} color={colors.white} /> {' '}
          {t('app.page.orderHistory.orderDetail.cancelPayment')}
        </Text>
      </TouchableWithoutFeedback>
    </View>
  )
}

const styles = StyleSheet.create({
  whiteText: {
    color: colors.white,
  },
  customerIsPayingBanner: {
    padding: 8,
    backgroundColor: colors.secondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
