import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {ISettingState} */
export const initialState = {
  rounding: null, // 金額小數位處理
  surcharge: null, // 預設服務費
  optionConfig: { // 子設定選擇頁面
    visible: false,
    text: null,
    valuePath: null,
    onChangeAction: null,
    options: null,
    multipleSelection: false,
  },
  openings: {}, // 營業時間設定
  selectedCategory: null, // 餐單/套餐設定
  searchText: '',
  customReason: [],
}

export default produce(
  /**
   * @param {ISettingState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT_DATA: {
        draft.isDataInit = true
        break
      }
      case ActionTypes.UPDATE_OPENING_SETTING: {
        const { openings } = action.payload
        draft.openings = openings
        break
      }
      case ActionTypes.SHOW_SETTING_OPTIONS: {
        draft.optionConfig = { visible: true, ...action.payload.config }
        break
      }
      case ActionTypes.CLOSE_SETTING_OPTIONS: {
        draft.optionConfig = initialState.optionConfig
        break
      }
      case ActionTypes.SELECT_CATEGORY: {
        const { category } = action.payload
        draft.selectedCategory = category
        break
      }
      case ActionTypes.RESET_ALL_SUB_VIEW: {
        draft.optionConfig = initialState.optionConfig
        draft.selectedCategory = null
        break
      }
      case ActionTypes.UPDATE_SEARCH_TEXT: {
        draft.searchText = action.payload.searchText
        break
      }
      case ActionTypes.UPDATE_CUSTOM_REASON: {
        const { customReason } = action.payload
        draft.customReason = customReason
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
