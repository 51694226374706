import { Image, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

export default function DeleteActionRow ({ onPress }) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.container}>
        <Image style={styles.deleteIcon} source={require('@icons/delete.png')} />
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0.5,
    bottom: 1,
    left: 16,
    right: 0,
    paddingRight: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.secondary,
  },
  deleteIcon: {
    width: 28,
    height: 28,
  },
})
