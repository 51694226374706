
import { FlatList, StyleSheet, TouchableOpacity } from 'react-native'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import StashDialog from './StashDialog'
import TableHeader from '@/components/OrderTable/TableHeader'
import TableRow from '@/components/OrderTable/TableRow'
import _ from 'lodash'
import colors from '@/theme/colors'
import moment from 'moment'

// item 使用的欄位名稱
const fields = [
  'serial',
  'items',
  'quantity',
  'total',
  'createAt',
]
// 欄寬
const widths = [45, 235, 50, 50, 100] // 480
// 對齊
const justifyContents = [
  'center',
  'flex-start',
  'center',
  'flex-end',
  'flex-start',
]

export default function StashList (props) {
  const { t } = useTranslation()
  const batchStashes = useSelector(state => state.orderBatch.batchStashes)
  const [selectedStash, setSelectedStash] = React.useState(null)
  // 表頭
  const headers = [t('app.common.list.serial'), t('app.page.order.stash.list.content'),
    t('app.page.order.stash.list.quantity'), t('app.page.order.stash.list.amount'), t('app.page.order.stash.list.time')]
  const data = batchStashes.map((stash, index) => {
    const items = stash.batch.items
    return {
      stash,
      serial: index + 1,
      items: items.length > 2
        ? `${_.chain(items).take(2).map(i => i.name).value().join(', ')}...`
        : _.chain(items).take(2).map(i => i.name).value().join(', '),
      quantity: _.reduce(items, (sum, item) => sum + item.quantity, 0),
      total: _.reduce(items, (sum, item) => sum + item.price, 0),
      createAt: moment(stash.createAt).format('MM/DD HH:mm'),
    }
  })

  const renderStash = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => setSelectedStash(item.stash)}>
        <TableRow
          item={item}
          fields={fields}
          widths={widths}
          justifyContents={justifyContents}
          style={styles.row}
        />
      </TouchableOpacity>
    )
  }

  return (
    <>
      <TableHeader
        headers={headers}
        widths={widths}
        justifyContents={justifyContents}
        style={styles.header}
      />
      <FlatList
        contentContainerStyle={styles.content}
        data={data}
        renderItem={renderStash}
        keyExtractor={(item, index) => String(index)}
      />

      <StashDialog
        selectedStash={selectedStash}
        onClose={() => setSelectedStash(null)}
      />
    </>
  )
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.lightPrimary,
  },
  content: {
    width: 480,
  },
  row: {
    minHeight: 30,
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.blackOpacity16,
  },
})
