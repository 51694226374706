import { combineReducers } from 'redux'

import appReducer from '@/redux/app/reducer'
import authReducer from '@/redux/auth/reducer'
import codePushReducer from '@/redux/codePush/reducer'
import configReducer from '@/redux/config/reducer'
import menuReducer from '@/redux/menu/reducer'
import merchantReducer from '@/redux/merchant/reducer'
import orderBatchReducer from '@/redux/orderBatch/reducer'
import orderCheckoutReducer from '@/redux/orderCheckout/reducer'
import orderHistoryReducer from '@/redux/orderHistory/reducer'
import orderReducer from '@/redux/order/reducer'
import printerReducer from '@/redux/printer/reducer'
import settingReducer from '@/redux/setting/reducer'
import statisticsReducer from '@/redux/statistics/reducer'
import tableReducer from '@/redux/table/reducer'
import unsyncOrderReducer from '@/redux/unsyncOrder/reducer'

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  codePush: codePushReducer,
  unsyncOrder: unsyncOrderReducer,
  config: configReducer,
  menu: menuReducer,
  merchant: merchantReducer,
  orderBatch: orderBatchReducer,
  orderCheckout: orderCheckoutReducer,
  orderHistory: orderHistoryReducer,
  order: orderReducer,
  printer: printerReducer,
  setting: settingReducer,
  statistics: statisticsReducer,
  table: tableReducer,
})
