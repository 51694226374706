import { StyleSheet, Text, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { calculateStepQuantityTotal, getStepQuantityMax } from '@/libs/order'
import { useSelectedBatchItem, useSelectedSetMenuIndex } from '@/hooks/order'
import { useTranslation } from 'react-i18next'
import ActionButton from './ActionButton'
import React, { useEffect, useState } from 'react'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

// 輸入模式
export default function NumberInputAction (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isInitialInput, setIsInitialInput] = useState(false)
  const [selectedItem, selectedSetItem] = useSelectedBatchItem()
  const selectedSetMenuIndex = useSelectedSetMenuIndex()
  const targetItem = selectedSetItem || selectedItem
  const key = targetItem?.key
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  const stepQuantityLimit = getStepQuantityMax(selectedItem, selectedSetStep, selectedSetMenuIndex)
  const stepQuantityLimitAll = getStepQuantityMax(selectedItem, selectedSetStep)
  const stepQuantityTotal = calculateStepQuantityTotal(selectedItem, selectedSetStep, selectedSetMenuIndex)
  const stepQuantityTotalAll = calculateStepQuantityTotal(selectedItem, selectedSetStep)

  const isDisabled = !selectedItem || selectedItem?.isSet

  useEffect(() => {
    setIsInitialInput(true)
  }, [key])

  const resetQuantity = () => {
    dispatch(actions.orderBatch.updateQuantity(1))
    setIsInitialInput(true)
  }

  const handleInputChange = (number) => {
    // 套餐不能改數量
    if (isDisabled) return

    // 所選的是 SetItem 且步驟與 CategoryBar 選擇的步驟相同
    if (selectedSetItem && selectedSetItem.step === selectedSetStep.id) {
      if (isInitialInput) {
        if (number === 0) return

        // 不可超過全部套餐上限 - 全部已點數量
        const setLimitAll = stepQuantityLimitAll - stepQuantityTotalAll + stepQuantityTotal
        // 不可超過套餐上限 - 已點數量且考慮 setLimitAll
        const setLimit = Math.min(setLimitAll, stepQuantityLimit - stepQuantityTotal) + stepQuantityTotal
        // 如果有選擇套餐，上限使用 setLimit 否則使用 setLimitAll
        const allowLimit = selectedSetMenuIndex != null ? setLimit : setLimitAll

        if (allowLimit <= 0) return

        if (number > allowLimit) {
          dispatch(actions.orderBatch.updateQuantity(allowLimit))
          setIsInitialInput(false)
          return
        }
        dispatch(actions.orderBatch.updateQuantity(number))
        setIsInitialInput(false)
        return
      } else {
        const newQuantity = Number(String(selectedSetItem.quantity) + String(number))
        if (newQuantity > 9999) return
        const increaseAmount = newQuantity - selectedSetItem.quantity
        const isOverflow = increaseAmount + stepQuantityTotal > stepQuantityLimit
        const difference = stepQuantityLimit - stepQuantityTotal
        const quantity = isOverflow ? difference : increaseAmount
        if (isOverflow) {
          dispatch(actions.orderBatch.addQuantity(quantity))
          setIsInitialInput(true)
          return
        } else {
          dispatch(actions.orderBatch.addQuantity(quantity))
          setIsInitialInput(false)
          return
        }
      }
    }

    // 所選的是 Item 而不是 SetItem
    if (!selectedSetItem && selectedItem) {
      if (isInitialInput) {
        if (number === 0) return
        dispatch(actions.orderBatch.updateQuantity(number))
        setIsInitialInput(false)
      } else {
        const newQuantity = Number(String(selectedItem.quantity) + String(number))
        if (newQuantity > 9999) return
        dispatch(actions.orderBatch.updateQuantity(newQuantity))
      }
    }
  }

  const handleAddQuantity = (number) => {
    // 所選的是 SetItem 且步驟與 CategoryBar 選擇的步驟相同
    if (selectedSetItem && selectedSetItem.step === selectedSetStep.id) {
      if (stepQuantityTotal >= stepQuantityLimit && number >= 0) return // 如果已經 max 且數量是增加或不變的話不做事情
      const isOverflow = number + stepQuantityTotal > stepQuantityLimit
      const difference = stepQuantityLimit - stepQuantityTotal
      const quantity = isOverflow ? difference : number
      dispatch(actions.orderBatch.addQuantity(quantity))
      setIsInitialInput(true)
      return
    }

    // 所選的是 Item 而不是 SetItem
    if (!selectedSetItem && selectedItem) {
      dispatch(actions.orderBatch.addQuantity(number))
      setIsInitialInput(true)
    }
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t('app.page.order.numberInputBar.inputMode')}</Text>
      <ActionButton
        onPress={() => handleAddQuantity(1)}
        iconSource={require('@icons/add.png')}
        disabled={isDisabled}
      />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((index) => {
        const number = index
        return (
          <ActionButton
            key={number}
            onPress={() => handleInputChange(number)}
            text={String(number)}
            disabled={isDisabled}
          />
        )
      })}
      <ActionButton
        onPress={() => handleAddQuantity(-1)}
        iconSource={require('@icons/minus.png')}
        disabled={isDisabled}
      />
      <ActionButton
        onPress={resetQuantity}
        iconSource={require('@icons/clear.png')}
        disabled={isDisabled}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 70,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.primary,
    paddingTop: 24,
    paddingBottom: 24,
    ...shadows.default,
    zIndex: 100,
  },
  title: {
    color: colors.white,
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: 8,
    marginBottom: 8,
  },
})
