import { StyleSheet, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { loadingKey } from '@/constants'
import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import ItemList from '@/components/ItemList'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import React from 'react'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'
import _ from 'lodash'

export default function ShortcutPanel (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { orderPictureMode, code, takeawayOpeningPrompt, quickMode } = useSelector(state => state.merchant.data?.setting) ?? {}
  const { numPadOrder, numberBarInputMode, skipTakeawayModal, skipAwaitTakeawayModal, skipPaymentResult, checkoutSound, setNextStepButton, addRushToTag, redirectToTablePage, quickCheckoutWithCash } = useSelector(state => state.app.settings)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const [listPanelConfig, setListPanelConfig] = React.useState(null)
  const soldOutMenu = useSelector(state => state.menu.soldOut)
  const discountModifier = useSelector(state => state.merchant.modifier)
  const discountOptions = discountModifier.filter(m => m?.applyTo === 'PRODUCT').map(m => {
    return { value: m.id, display: m.name }
  })
  discountOptions.unshift({ value: '', display: t('app.common.null') })
  const discountShortcut = useSelector(state => state.app.settings.discountShortcut)

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  if (listPanelConfig) {
    const menuIds = _.flatMap(soldOutMenu[listPanelConfig], 'id')
    return (
      <ItemList
        onClose={(value) => {
          setListPanelConfig(null)
        }}
        onSave={async (value) => {
          await dispatch(actions.app.openLoading(loadingKey.MENU))
          const stock = _.difference(menuIds, value)
          const sold = _.difference(value, menuIds)
          if (listPanelConfig === 'menus') {
            await dispatch(actions.menu.soldMenus(sold))
            await dispatch(actions.menu.stockMenus(stock))
          }
          if (listPanelConfig === 'sets') {
            await dispatch(actions.menu.soldSets(sold))
            await dispatch(actions.menu.stockSets(stock))
          }
          await dispatch(actions.menu.fetchCategories2())
          setListPanelConfig(null)
          dispatch(actions.app.closeLoading(loadingKey.MENU))
        }}
        menu={menuIds ?? []}
        type={listPanelConfig}
      />
    )
  }

  return (
    <View style={styles.container}>
      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.menuPanel.shortCut.title')} />
        </Header>
        <SettingList>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.shortCut.order')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={numPadOrder}
                onChangeValue={() => dispatch(actions.app.updateSetting(['numPadOrder'], !numPadOrder))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.shortCut.input')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={numberBarInputMode}
                onChangeValue={() => dispatch(actions.app.updateSetting(['numberBarInputMode'], !numberBarInputMode))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListEnterRow
            label={t('app.page.setting.menuPanel.shortCut.orderDiscount')}
            value={_.find(discountOptions, d => d.value === discountShortcut)?.display}
            onPress={() => {
              setOptionsPanelConfig({
                title: t('app.page.setting.menuPanel.shortCut.orderDiscount'),
                defaultValue: discountShortcut,
                onChangeValue: (value) => dispatch(actions.app.updateSetting(['discountShortcut'], value)),
                options: discountOptions,
                closeOnSelect: true,
              })
            }}
          />

        </SettingList>

        <Header>
          <HeaderText text={t('app.page.setting.menuPanel.orderSetting.other.title')} />
        </Header>
        <SettingList>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.shortCut.skipTakeawayModal')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={skipTakeawayModal}
                onChangeValue={() => dispatch(actions.app.updateSetting(['skipTakeawayModal'], !skipTakeawayModal))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.shortCut.skipAwaitTakeawayModal')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={skipAwaitTakeawayModal}
                onChangeValue={() => dispatch(actions.app.updateSetting(['skipAwaitTakeawayModal'], !skipAwaitTakeawayModal))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.pictureMode')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={orderPictureMode}
                onChangeValue={() => dispatch(actions.merchant.updateOrderPictureMode(!orderPictureMode))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.code')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={code}
                onChangeValue={() => dispatch(actions.merchant.updateEnableCode(!code))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.takeawayOpeningPrompt')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={takeawayOpeningPrompt}
                onChangeValue={() => dispatch(actions.merchant.updateTakeawayOpeningPrompt(!takeawayOpeningPrompt))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.quickCheckoutWithCash')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={quickCheckoutWithCash}
                onChangeValue={() => dispatch(actions.app.updateSetting(['quickCheckoutWithCash'], !quickCheckoutWithCash))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.skipPaymentResult')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={skipPaymentResult}
                onChangeValue={() => dispatch(actions.app.updateSetting(['skipPaymentResult'], !skipPaymentResult))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.checkoutSound')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={checkoutSound}
                onChangeValue={() => dispatch(actions.app.updateSetting(['checkoutSound'], !checkoutSound))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.setNextStepButton')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={setNextStepButton}
                onChangeValue={() => dispatch(actions.app.updateSetting(['setNextStepButton'], !setNextStepButton))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          {
            !quickMode && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.localPanel.setting.other.redirectToTablePage')} />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={redirectToTablePage}
                    onChangeValue={() => dispatch(actions.app.updateSetting(['redirectToTablePage'], !redirectToTablePage))}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
            )
          }

          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.addRushToTag')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={addRushToTag}
                onChangeValue={() => dispatch(actions.app.updateSetting(['addRushToTag'], !addRushToTag))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

        </SettingList>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.menuPanel.menuInfo.sold')} />
        </Header>

        <SettingList>
          <SettingListEnterRow
            divider
            label={t('app.page.setting.printer.item') + t('app.page.setting.printer.setting')}
            value={_.size(soldOutMenu.menus)}
            onPress={() => {
              dispatch(actions.auth.permissionCheck('set-menu-soldOut', () => {
                setListPanelConfig('menus')
              }))
            }}
          />

          <SettingListEnterRow
            label={t('app.constant.menu.set') + t('app.page.setting.printer.setting')}
            value={_.size(soldOutMenu.sets)}
            onPress={() => {
              dispatch(actions.auth.permissionCheck('set-menu-soldOut', () => {
                setListPanelConfig('sets')
              }))
            }}
          />
        </SettingList>
      </View>
    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    flexWrap: 'wrap',
    alignContent: 'space-between',
  },
})
