/* eslint-disable camelcase */
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

// Tools Function
export const str2Byte = (str) => new Blob([str]).size

const orderHistoryExport = {
  // ******  [ clear ]  ******
  clear: async function () {},
  // ******  [ set ]  ******
  set: async function (orderHistoryJsonStr) {},
  // ******  [ get ]  ******
  get: async function () {},
  // ******  [ AsyncStorage Migrate To Realm ]  ******
  asyncStorageMigrateToRealm: async function (AsyncStorage) {},
}

const DataStorage = { orderHistory: orderHistoryExport }
export default DataStorage

// *** [ 使用說明 ] ***
// import DataStorage from '@/libs/dataStorage'
// await DataStorage.orderHistory.set(orderHistoryJsonStr)
// await DataStorage.orderHistory.get()
// await DataStorage.orderHistory.clear()

// Tools Function
export const ms = (t1, t0) => (t1 - t0).toFixed(1)
export const ms_now = (t_start) => ms(performance.now(), t_start)
export const str2Byte_mb = (str) => (str2Byte(str) / 1024 / 1024).toFixed(4)
export const str2Byte_mb_lng = (str) => (str2Byte(str) / 1024 / 1024)
export const split_wthLen = (str, n) => str.split(new RegExp('(.{' + n.toString() + '})')).filter(O => O) // .filter(O => O) = remove empty string

// ***  Testing Tool  ***
export const copyOrders = (ords, times) => {
  return new Array(times).fill(0)
    .map((v, i) => _.cloneDeep(ords[i % ords.length]))
}
// Random id (UUID) avoid repeat
export const copyOrders_randId = (ords, times) => {
  return new Array(times).fill(0)
    .map((v, i) => {
      const ord = _.cloneDeep(ords[i % ords.length])
      ord.id = uuid()
      return ord
    })
}
