import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { PrintReason } from '@/constants/printing'
import { actions, useDispatch, useSelector } from '@/redux'
import { ecrGateway, loadingKey } from '@/constants'
import { useSelectedOrder } from '@/hooks/orderHistory'
import BBMSL from '@/libs/EcrGateway/BBMSL'
import Column from '@/components/Column'
import EftPay from '@/libs/EcrGateway/EftPay'
import ModifierDialog from '@/components/dialogs/ModifierDialog'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import paymentConfigs from '@/configs/paymentConfigs'

import ActionButton from './ActionButton'

const ICONS = {
  split: require('@icons/checkout/split-order.png'),
  discount: require('@icons/checkout/discount.png'),
  print: require('@icons/checkout/print.png'),
  status: require('@icons/checkout/status.png'),
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function ActionPanel (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedOrder = useSelectedOrder()
  const [openModifierDialog, setOpenModifierDialog] = React.useState(false)
  const [modifierType, setModifierType] = React.useState(null)
  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)
  const [disableRetry, setDisableRetry] = React.useState(false)
  const { selectPrinterBeforePrint } = useSelector(state => state.app.settings)
  const filteredPayments = selectedOrder.payments.filter(payment => payment.status !== 'cancel')
  // const configEnv = useSelector(state => state.config.env)
  // const failedBBMSL = useSelector(state => state.orderCheckout.failedBBMSL)
  let updateSubbmitingTimer = ''
  const ecrPayingRecord = useSelector(state => state.app.settings.ecrPayingRecord)
  const retryRecord = _.get(ecrPayingRecord, selectedOrder.id)
  const paidAmount = selectedOrder.payments.filter(p => p?.status === 'paid')
    .map(p => Number(p.amount))
    .reduce((a, b) => a + b, 0)
  // 找續先用計算的，之後才會正式存起來
  const remainTotal = Number(selectedOrder.roundedTotal) - Number(paidAmount)

  // 當 alertOrderChange 要顯示訂單已結帳、訂單已取消或訂單更新 alert 時，關閉目前打開的 dialog，避免提示顯示不出來
  // 因為 React Native 無法同時打開兩個 modal
  const shouldCloseAllDialogs = useSelector(state => (
    state.app.alerts['checkout-order-complete']?.dismiss === false ||
    state.app.alerts['checkout-order-updated']?.dismiss === false ||
    false
  ))
  React.useEffect(() => {
    if (openModifierDialog) {
      setOpenModifierDialog(false)
    }
    if (printerDialogOpen) {
      setPrinterDialogOpen(false)
    }
  }, [shouldCloseAllDialogs])

  // ! flexDirection: 'column-reverse'
  return (
    <Column style={[styles.container]}>
      {
        retryRecord && (
          <ActionButton
            title={t('app.page.checkout.payment.retry')}
            icon={ICONS.status}
            disabled={disableRetry}
            onPress={async () => {
              let result
              setDisableRetry(true)
              await dispatch(actions.app.openLoading(loadingKey.CARDPAYMENT, 'retry'))
              switch (retryRecord.gateway) {
                case ecrGateway.BBMSL: {
                  const bbMSL = new BBMSL()
                  result = await bbMSL.getPaymentStatus(retryRecord.id)

                  setDisableRetry(false)
                  if (result) {
                    if (result.success) {
                      const amount = result.data.payload.approvedAmount
                      const isMeet = amount >= remainTotal
                      dispatch(actions.orderCheckout.updatePaymentField('paidAmount', amount))
                      const responsePaymentMethod = paymentConfigs.ecrProviders.find(ecrProvider => ecrProvider.ecrKey?.bbmsl === result?.data?.card)
                      dispatch(actions.orderCheckout.creditCardPaymentSuccessHandler(isMeet, result.data, retryRecord.selectPrinterBeforePrint, selectedOrder.id, responsePaymentMethod))
                    } else {
                      dispatch(actions.orderCheckout.creditCardPaymentErrorHandler(result?.error))
                    }
                  }
                  dispatch(actions.app.closeLoading(loadingKey.CARDPAYMENT, 'retry'))
                  break
                }

                case ecrGateway.EFT_PAY: {
                  const eftpay = new EftPay()
                  result = await eftpay.getPaymentStatus(retryRecord.id, retryRecord.payMethod)

                  if (result) {
                    if (result.success) {
                      const amount = Number(result.data.payload.AMT)
                      const isMeet = amount >= remainTotal
                      dispatch(actions.orderCheckout.updatePaymentField('paidAmount', amount))
                      const responsePaymentMethod = paymentConfigs.ecrProviders.find(ecrProvider => ecrProvider.ecrKey?.eftPay === result?.data?.card)
                      dispatch(actions.orderCheckout.creditCardPaymentSuccessHandler(isMeet, result.data, selectPrinterBeforePrint, selectedOrder.id, responsePaymentMethod))
                    } else {
                      dispatch(actions.orderCheckout.creditCardPaymentErrorHandler(result?.error))
                    }
                  }
                  dispatch(actions.app.closeLoading(loadingKey.CARDPAYMENT, 'retry'))
                  break
                }
              }
            }}
          />
        )
      }
      <ActionButton
        title={t('app.page.checkout.payment.print')}
        icon={ICONS.print}
        onPress={async () => {
          if (selectPrinterBeforePrint) {
            setPrinterDialogOpen(true)
          } else {
            await dispatch(actions.app.openLoading(loadingKey.PRINT, 'checkout-print-receipt'))
            dispatch(actions.printer.printOrderReceipt({
              order: selectedOrder,
              printReason: PrintReason.ORDER_RECEIPT.CHECKOUT,
            }))
          }
        }}
      />
      <ActionButton
        title={t('app.constant.order.discount')}
        icon={ICONS.discount}
        disabled={filteredPayments.length > 0}
        onPress={() => {
          dispatch(actions.auth.permissionCheck('order-discount', () => {
            setOpenModifierDialog(true)
            setModifierType('DISCOUNT')
          }))
        }}
      />
      <ActionButton
        title={t('app.page.checkout.payment.surcharge')}
        icon={ICONS.discount}
        disabled={selectedOrder?.deliveryType === 'takeaway' || filteredPayments.length > 0}
        onPress={() => {
          dispatch(actions.auth.permissionCheck('order-edit', () => {
            setOpenModifierDialog(true)
            setModifierType('SURCHARGE')
          }))
        }}
      />

      <ModifierDialog
        key={String(openModifierDialog)}
        open={openModifierDialog}
        amount={selectedOrder.total}
        modifierType={modifierType}
        onSubmit={(modifier) => {
          clearTimeout(updateSubbmitingTimer)
          dispatch(actions.orderCheckout.updateSubmitting(true))
          dispatch(actions.orderHistory.updateModifier(modifier))
          updateSubbmitingTimer = setTimeout(() => {
            dispatch(actions.orderCheckout.updateSubmitting(false))
          }, 1500)
        }}
        onClose={() => {
          setOpenModifierDialog(false)
        }}
        showDefault={modifierType === 'DISCOUNT'}
      />
      <PrinterDialog
        open={printerDialogOpen}
        onClose={() => setPrinterDialogOpen(false)}
        onSelect={async (selectedPrinterId) => {
          await dispatch(actions.app.openLoading(loadingKey.PRINT, 'checkout-selectedPrinter-receipt'))
          dispatch(actions.printer.printOrderReceipt({
            order: selectedOrder,
            selectedPrinterId,
            printReason: PrintReason.ORDER_RECEIPT.CHECKOUT,
          }))
        }}
      />
    </Column>
  )
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column-reverse',
    position: 'absolute',
    left: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    overflow: 'hidden',
  },
})
