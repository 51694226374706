const paymentMethods = {
  ALI_PAY: 'alipay',
  PAY_ME: 'payme',
  WECHAT_PAY: 'wechatpay',
  FPS: 'fps',
  OCTOPUS: 'octopus',
  WECHAT: 'wechat',
  UNION_PAY_QR: 'unionpayQR',
  BYPASS: 'bypass',
}
export default paymentMethods
