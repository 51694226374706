import React, { useEffect, useState } from 'react'

import { FlatList, StyleSheet, Text, View } from 'react-native'
import { dimorderApi } from '@root/src/libs/api/dimorder'
import { useTranslation } from 'react-i18next'
import CenterModal from '@root/src/components/CenterModal'
import Column from '@root/src/components/Column'
import Row from '@root/src/components/Row'
import colors from '@root/src/theme/colors'
import moment from 'moment'

/**
 * @typedef PointsRecordsDialogProps
 * @property {IMember} member
 * @property {boolean} open
 * @property {() => void} onClose
 */

/**
 *
 * @param {PointsRecordsDialogProps} props
 * @returns
 */
function PointsRecordsDialog (props) {
  const { member, open, onClose } = props
  const { t } = useTranslation()

  const [pointsRecords, setPointsRecords] = useState([])

  const query = {
    limit: 4,
    merchantId: member.fromMerchantId,
  }
  useEffect(() => {
    async function getPointsRecord () {
      const response = await dimorderApi.axiosGo.get(`/m/group/user/${member?.id}/point?limit=4`, { params: query })
      setPointsRecords(response.data.data)
    }

    getPointsRecord()
  }, [])

  const renderItem = ({ item, index }) => {
    const backgroundColor = index % 2 === 0 ? colors.lightGray1 : colors.lightGray2
    const formattedCreatedAt = moment(item.createdAt).format('YYYY-MM-DD HH:mm')
    return (
      <View
        style={[styles.row, { backgroundColor: backgroundColor }]}
      >
        <Text style={styles.cell}>{item.orderSerial}</Text>
        <Text style={styles.cell}>{item.points}</Text>
        <Text style={styles.cell}>{formattedCreatedAt}</Text>
      </View>
    )
  }

  return (
    <CenterModal
      visible={open}
      noTitle
      enablePressOutsideClose
      onClose={onClose}
      containerStyle={{ backgroundColor: colors.lightGray1 }}
    >
      <Column style={styles.container}>
        <Row style={styles.dialogHeader}>
          <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t('app.page.Member.MemberInfo.pointsRecord')}</Text>
          <Text style={{ fontSize: 15, paddingTop: 5 }}>{t('app.page.Member.MemberInfo.lastFourPointsRecord')}</Text>
        </Row>
        <FlatList
          style={{ paddingHorizontal: 7 }}
          data={pointsRecords}
          renderItem={renderItem}
          keyExtractor={item => item?.id}
          scrollEnabled={false}
          ListHeaderComponent={() => {
            return (
              <View style={styles.header}>
                <Text style={styles.headerCell}>{t('app.page.Member.MemberInfo.order')}</Text>
                <Text style={styles.headerCell}>{t('app.page.Member.MemberInfo.points')}</Text>
                <Text style={styles.headerCell}>{t('app.page.Member.MemberInfo.earnTime')}</Text>
              </View>
            )
          }}
          ListEmptyComponent={() => {
            return (
              <Column style={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: 20,
              }}
              >
                <Text style={{
                  fontSize: 22,
                  fontWeight: 'bold',
                  color: colors.primary,
                }}
                >
                  {t('app.page.Member.MemberInfo.noPointsRecord')}
                </Text>
              </Column>
            )
          }}
        />
      </Column>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 360,
    width: 800,
    display: 'flex',
    flexDirection: 'column',
  },
  dialogHeader: {
    paddingBottom: 10,
    paddingHorizontal: 10,
    justifyContent: 'space-between',
  },
  header: {
    flexDirection: 'row',
    backgroundColor: colors.primary,
    paddingVertical: 23,
    paddingHorizontal: 5,
  },
  headerCell: {
    flex: 1,
    fontWeight: 'bold',
    color: colors.white,
  },
  cell: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#ccc',
    paddingVertical: 23,
    paddingHorizontal: 5,
  },
})

export default PointsRecordsDialog
