import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'

import LoginBox from '../LoginBox'
import LoginForm from '../LoginForm'

/**
 * @typedef MerchantLoginProps
 * @property {() => void} onNext
 */

/**
 *
 * @param {MerchantLoginProps} props
 * @returns
 */
export default function MerchantLogin (props) {
  const { onNext } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMerchantLogin = useSelector(state => state.auth.isMerchantLogin)

  React.useEffect(() => {
    if (isMerchantLogin) {
      return onNext()
    }
  }, [isMerchantLogin])

  const handleMerchantLogin = async (account, password) => {
    dispatch(actions.auth.merchantLogin(account, password))
  }

  return (
    <View style={styles.container}>
      <View style={styles.main} />
      <LoginBox>
        <LoginForm
          accountPlaceHolder={t('app.page.login.merchant.id')}
          passwordPlaceHolder={t('app.page.login.merchant.password')}
          onSubmit={handleMerchantLogin}
        />
      </LoginBox>
    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  main: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
