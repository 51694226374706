import { StyleSheet, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef StatusDotProps
 * @property {ViewProps['style']?} style
 * @property {string?} color
 */

/**
 *
 * @param {StatusDotProps} props
 * @returns
 */
export default function StatusDot (props) {
  const { style, color = colors.primary } = props

  return (
    <View
      style={[
        styles.dot,
        style,
        { backgroundColor: color },
      ]}
    />
  )
}

const styles = StyleSheet.create({
  dot: {
    margin: 10,
    width: 10,
    height: 10,
    borderRadius: 5,
  },
})
