import { Pressable, StyleSheet, Text, TouchableHighlight, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'

const TouchableComponents = {
  TouchableWithoutFeedback,
  TouchableOpacity,
  TouchableHighlight,
}

/**
 * @typedef ButtonProps
 * @property {'TouchableWithoutFeedback' | 'TouchableOpacity' | 'TouchableHighlight' | 'Pressable'} [type='TouchableOpacity'] Default: 'TouchableOpacity'
 * @property {string} title
 * @property {() => void} onPress
 * @property {boolean} [disabled=false] Default: false
 * @property {boolean} [textBold=false] Default: false
 * @property {string} [textColor='#ffffff'] Default: '#ffffff' (colors.white)
 * @property {string} [backgroundColor= '#c80f5a'] Default: '#c80f5a' (colors.secondary)
 * @property {ViewProps['style']?} style
 * @property {TextProps['style']?} textStyle
 * @property {ViewProps['style']?} onPressStyle
 * @property {PressableProps?} touchableProps
 * @property {*} children
 */

/**
 *
 * @param {ButtonProps} props
 * @returns
 */
export default function Button (props) {
  const {
    type = 'TouchableOpacity',
    title,
    onPress,

    disabled = false,
    textBold = false,
    textColor = colors.white,
    backgroundColor = colors.secondary,

    style,
    textStyle,
    onPressStyle,

    touchableProps,
    children,
  } = props

  const TouchableComponent = TouchableComponents[type]
  const diabledStyle = disabled && (props.disabledStyle || styles.disabled)
  const action = _.throttle(onPress, 1500, { trailing: false })

  if (type === 'Pressable') {
    return (
      <Pressable
        {...touchableProps}
        onPress={action}
        disabled={disabled}
        style={({ pressed }) => [styles.container, style, diabledStyle, { backgroundColor: pressed ? onPressStyle.backgroundColor : backgroundColor }]}
      >
        {({ pressed }) => <Text style={[styles.text, textStyle, { color: pressed ? onPressStyle.textColor : textColor }]}>{title || children}</Text>}
      </Pressable>
    )
  }

  return (
    <TouchableComponent
      {...touchableProps}
      onPress={action}
      disabled={disabled}
    >
      <View style={[styles.container, { backgroundColor }, style, diabledStyle]}>
        <Text style={[
          styles.text,
          { color: textColor, fontWeight: textBold ? 'bold' : '400' },
          textStyle,
        ]}
        >{title || children}
        </Text>
      </View>
    </TouchableComponent>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 106,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    margin: 8,
  },
  text: {
    fontSize: 14,
  },
  disabled: {
    opacity: 0.4,
  },
})
