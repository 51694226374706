import { StyleSheet, Text, View } from 'react-native'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { loadingKey } from '@/constants'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import Column from '@/components/Column'
import NumPadDialog from '@/components/dialogs/NumPadDialog'
import Row from '@/components/Row'

import HeaderButton from './HeaderButton'

moment.calendarFormat = function (myMoment, now) {
  var diff = myMoment.diff(now, 'days', true)

  var retVal = diff < 1 ? 'sameDay'
    : diff < 2 ? 'nextDay'
      : diff < 3 ? 'dayAfterNextDay'
        : 'else'
  return retVal
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function Header (props) {
  const dispatch = useDispatch()
  const [dateTime, setDateTime] = useState()
  const history = useHistory()
  const [openTakeawayDialog, setTakeawayDialog] = React.useState(false)
  const { t } = useTranslation()
  const codeText = t('app.page.table.header.code')
  const takeawayText = t('app.page.table.header.takeaway')
  const clearText = t('app.page.table.header.clear')
  const lang = useSelector(state => state.app.settings.lang)

  React.useEffect(() => {
    let timeoutId
    const timeoutMs = (60 - Number(moment(dateTime).format('ss'))) * 1000
    if (!moment().isAfter(dateTime, 'second') || !dateTime) {
      timeoutId = setTimeout(() => {
        setDateTime(new Date())
      }, timeoutMs)
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [dateTime])

  if (lang === 'zh-HK') {
    moment.locale('zh-tw', {
      weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      calendar: {
        sameDay: '[今天]',
        nextDay: '[明天]',
        dayAfterNextDay: '[後天]',
        else: function callback (now) {
          const diff = this.diff(now, 'days')
          return `[${diff}天後]`
        },
      },
    })
  } else if (lang === 'en-US') {
    moment.locale('en', {
      calendar: {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        dayAfterNextDay: '[2 Days After]',
        else: function callback (now) {
          const diff = this.diff(now, 'days')
          return `[${diff} Days After]`
        },
      },
    })
  }

  return (
    <Row style={styles.container}>
      {/* booking date */}
      <Column style={{ flexDirection: 'row' }}>
        <Text style={styles.dateText}>
          {moment(dateTime).format('YYYY/MM/DD HH:mm')}
        </Text>

        <Text style={[styles.dateText, { fontSize: 16 }]}>
          {moment(dateTime).format('dddd')}
        </Text>

        <View style={styles.tag}>
          <Text style={styles.tagText}>
            {moment(dateTime).calendar()}
          </Text>
        </View>
        <HeaderButton
          style={{ marginLeft: 10 }}
          title={clearText}
          onPress={() => {
            dispatch(actions.auth.permissionCheck('order-cancel', () => {
              dispatch(actions.app.showAlert({
                title: clearText,
                message: `${t('app.common.confirm')}${clearText}?`,
                buttons: [
                  {
                    children: t('app.common.confirm'),
                    onPress: () => {
                      dispatch(actions.table.cancelAllEmptyOrder())
                    },
                  },
                  {
                    backgroundColor: colors.light,
                    textColor: colors.textTertiary,
                    children: t('app.common.cancel'),
                    onPress: () => {},
                  },
                ],
              }))
            }))
          }}
        />
      </Column>
      <Row style={styles.rightContainer}>
        <HeaderButton
          title={codeText}
          onPress={() => setTakeawayDialog(true)}
        />
        <HeaderButton
          title={takeawayText}
          onPress={() => {
            dispatch(actions.auth.permissionCheck('order-takeaway', () => {
              dispatch(actions.order.createLocalOrder('takeaway'))
              history.push('/order')
            }))
          }}
        />
      </Row>
      <NumPadDialog
        open={openTakeawayDialog}
        onClose={() => setTakeawayDialog(false)}
        title={t('app.component.takeawayDialog.title')}
        defaultValue=''
        clearValue=''
        keepZero
        useCode
        maxLength={4}
        disableDecimalPoint
        onSubmit={async (value) => {
          setTakeawayDialog(false)
          await dispatch(actions.app.openLoading(loadingKey.TAKEAWAY, 'Table-getTakeawayOrderByCode'))
          await dispatch(actions.orderHistory.getTakeawayOrderByCode(value))
          dispatch(actions.app.closeLoading(loadingKey.TAKEAWAY))
        }}
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 75,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.lightPrimary,
    borderBottomRightRadius: 32,
    paddingLeft: 30,
    paddingRight: 20,
    zIndex: 4,
    elevation: 4,
    ...shadows.default,
  },
  dateText: {
    fontSize: 20,
    lineHeight: 30,
    fontWeight: 'bold',
    color: colors.white,
    marginRight: 12,
  },
  summaryText: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.white,
  },
  tag: {
    height: 26,
    minWidth: 51,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tertiary,
    borderRadius: 18,
    paddingHorizontal: 4,
    marginTop: 5,
  },
  tagText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.white,
  },
  rightContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    marginLeft: 16,
  },
})
