import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'

export default function CategoryFilterPanel (props) {
  const { value, onPress = () => {}, text } = props
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      style={[styles.button, { backgroundColor: value ? colors.primary : colors.transparent }]}
      onPress={onPress}
    >
      <Text style={[styles.buttonText, { color: value ? colors.white : colors.primary }]}>{text}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    height: 45,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: colors.primary,
    borderRadius: 8,
    marginHorizontal: 5,
    marginVertical: 8,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
})
