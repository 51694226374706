import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { getPaymentMethod } from '@/libs/merchant'
import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import SummaryRow from './SummaryRow'
import Title from '../Title'

const titles = [
  'sales',
  'customPriceModifier',
  'customItemPriceModifier',
  'paymentMethod',
  'dimOrderDineIn',
  'paymentMethodTips',
  'audit',
  'cash',
  'table',
  'tableGroupSales',
  'merchantTakeaway',
]

export default function SummaryInfo (props) {
  const { t, i18n } = useTranslation()
  const { quickMode: enableQuickMode } = useSelector(state => state.merchant.data?.setting) ?? {}
  // const datetime = useSelector(state => state.statistics.datetime)

  const summarySectionsData = useSelector(state => state.statistics.data.summary?.sections) ?? []
  const tableGroupSalesData = useSelector(state => state.statistics.data.tableGroupSales?.data) ?? []

  const Summary = React.useMemo(() => {
    const salesData = _.get(summarySectionsData.find(data => data.type === 'sales'), 'data', {})
    const tableData = _.get(summarySectionsData.find(data => data.type === 'table'), 'data', {})

    return (
      <Title
        amount={salesData.totalSales}
        count={salesData.sales?.count}
        cancel={salesData.cancelled?.count}
        customer={tableData.guest}
      />
    )
  }, [summarySectionsData])

  const orderedData = React.useMemo(() => {
    const result = titles
      .map(title => {
        if (title === 'tableGroupSales') {
          // 將資料整理成跟其他的格式一致
          const formatedTableGroupSalesData = _.reduce(tableGroupSalesData, (acc, data) => {
            acc[data.tableName] = { count: data.count, amount: data.totalSales }
            return acc
          }, {})
          return {
            type: 'tableGroupSales',
            data: formatedTableGroupSalesData,
          }
        }
        return summarySectionsData.find(data => data.type === title)
      })
      .filter(data => {
        if (!data) return false
        if (data.type === 'table' && enableQuickMode) return false
        if (data.type === 'cash') return _.get(data, 'data.cash')
        if (['customPriceModifier', 'customItemPriceModifier'].includes(data.type)) {
          return _.get(data, 'data.total', 0)
        }
        return data
      })
    return result
  }, [summarySectionsData, tableGroupSalesData, enableQuickMode])

  const renderPayments = ({ item }) => {
    return (
      <SummaryRow
        title={getPaymentMethod(item.type)?.name}
        count={item.count}
        amount={item.amount}
      />
    )
  }

  const renderModifier = ({ item }) => {
    if (item.deletedAt) return null
    return (
      <SummaryRow
        title={item.name}
        count={item.count}
        amount={item.amount}
      />
    )
  }

  const renderCash = ({ item }) => {
    return (
      <SummaryRow
        title={'app.page.stats.newSummary.' + item.type}
        count=''
        amount={item.amount}
      />
    )
  }

  const renderItem = ({ item, index: i }) => {
    const title = i18n.exists('app.page.stats.newSummary.' + item.type)
      ? 'app.page.stats.newSummary.' + item.type
      : item.type

    const data = item.data
    return (
      <>
        <View style={{ height: 25, marginTop: 30 }}>
          <Text style={styles.title}>{t(title)}</Text>
          <View style={styles.divider} />
        </View>
        <FlatList
          data={Object.keys(item.data)}
          renderItem={({ item: type }) => {
            const listItemTitle = i18n.exists('app.page.stats.newSummary.' + type)
              ? 'app.page.stats.newSummary.' + type
              : type
            const isTextPin = type === 'totalSales' || type.includes('evenue') || type === 'total'
            const textStyle = { color: '#EE7B06' }
            if (type === 'merchantPromocode' && i === 0) {
              return
            }
            if (type === 'payments') {
              return (
                <FlatList
                  data={data[type]}
                  renderItem={renderPayments}
                  keyExtractor={(item, index) => String('payments' + index)}
                  showsVerticalScrollIndicator={false}
                />
              )
            }
            if (type === 'modifiers') {
              return (
                <FlatList
                  data={data[type]}
                  renderItem={renderModifier}
                  keyExtractor={(item, index) => String('modifiers' + index)}
                  showsVerticalScrollIndicator={false}
                />
              )
            }
            if (type === 'cash') {
              return (
                <FlatList
                  data={data[type]}
                  renderItem={renderCash}
                  keyExtractor={(item, index) => String('cash' + index)}
                  showsVerticalScrollIndicator={false}
                />
              )
            }
            return (
              <SummaryRow
                title={listItemTitle}
                count={typeof data[type] === 'object' ? data[type].count : ''}
                amount={typeof data[type] === 'object' ? data[type].amount : data[type]}
                textStyle={isTextPin ? textStyle : null}
                isString={type === 'guest'}
              />
            )
          }}
          keyExtractor={(item, index) => String(item + 'list' + index)}
          showsVerticalScrollIndicator={false}
        />
      </>
    )
  }

  if (_.isEmpty(summarySectionsData)) {
    return null
  }

  return (
    <View style={styles.container}>
      {/* <Text style={styles.title}>{`${moment(datetime.from).format('DD/MM/YYYY HH:mm')} - ${moment(datetime.to).format('DD/MM/YYYY HH:mm')}`}</Text> */}
      <FlatList
        data={orderedData}
        renderItem={renderItem}
        keyExtractor={(item, index) => String('title' + index)}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <>
            {Summary}
          </>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
    flex: 1,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})
