import { Image, StyleSheet, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'

export default function SearchInput (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isNumPadOrder = useSelector(state => state.app.settings?.numPadOrder)
  const isSearchExpanded = useSelector(state => state.menu.isSearchExpanded)
  const searchText = useSelector(state => state.menu.searchText)
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)

  const inputRef = React.useRef()

  const isInSet = Boolean(selectedSetStep)
  const isMedium = !isInSet && (selectedCategory.id === 'ROOT' || selectedCategory.id === 'SET_ROOT')

  const showInput = isSearchExpanded || isNumPadOrder

  React.useEffect(() => {
    if (isSearchExpanded) {
      // focus input when search expand
      inputRef?.current?.focus()
    }
  }, [isSearchExpanded])

  return (
    <TouchableOpacity
      style={[
        // 預設，寬度和 search icon 一樣（顯示圓形）
        styles.container,
        // 非套餐，選擇分類為 ROOT 或 SET_ROOT 時，部分展開
        isMedium && styles.mediumContainer,
        // isExpanded or isNumPadOrder 時全展開
        showInput && styles.fullWideContainer,
      ]}
      onPress={() => {
        dispatch(actions.menu.updateSearchExpanded(true))

        // deselect selectedItem and selectedSetItem when isSearchExpanded
        if (isInSet) {
          dispatch(actions.orderBatch.deselectItems())
        }
      }}
    >
      <View style={styles.searchBar}>
        <Image style={styles.searchIcon} source={require('@icons/order-header/search.png')} />
        {showInput && (
          <TouchableWithoutFeedback>
            <TextInput
              ref={inputRef}
              style={styles.input}
              returnKeyType='search'
              placeholder={t('app.page.order.search')}
              placeholderTextColor={colors.textPlaceholder}
              value={searchText}
              onChangeText={(text) => {
                dispatch(actions.menu.updateSearchText(text))
              }}
            />
          </TouchableWithoutFeedback>
        )}
        {showInput && (
          <TouchableOpacity
            onPress={() => {
              dispatch(actions.menu.resetSearchInput())
            }}
          >
            <Image
              style={styles.closeIcon}
              source={require('@icons/order-header/close.png')}
            />
          </TouchableOpacity>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 35,
    height: 35,
    borderRadius: 17.5,
    display: 'flex',
    marginHorizontal: 8,
  },
  mediumContainer: {
    width: 230,
  },
  fullWideContainer: {
    width: undefined,
    flex: 1,
  },
  searchBar: {
    width: '100%',
    height: '100%',
    borderRadius: 17.5,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  searchIcon: {
    width: 35,
    height: 35,
  },
  input: {
    flex: 1,
    fontSize: 17,
    marginHorizontal: 8,
  },
  closeIcon: {
    width: 35,
    height: 35,
  },
})
