import { Badge } from 'react-native-elements'
import { StyleSheet, View } from 'react-native'
import { useRequestPayingOrders } from '@/hooks/orderHistory'
import { useSelector } from '@/redux'
import React, { useMemo } from 'react'
import _ from 'lodash'

import { searchAndFilterOrders } from '@root/src/libs/orderFilters'
import Button from '@/components/buttons/Button'
import colors from '@/theme/colors'

/**
 * @typedef QuickLinkProps
 * @property {string} text
 * @property {() => void} onPress
 * @property {boolean?} selected
 * @property {boolean?} showBadge
 * @property {string} query
 * @property {object} filter
 * @property {*} formattedOrders historyOrders 經過 formatOrdersForQuickLinkCount 整理過後的格式，用以加速 searchAndFilterOrders
 */

/**
 *
 * @param {QuickLinkProps} props
 * @returns
 */
export default function QuickLink (props) {
  const { text, onPress, selected, showBadge, query, filter, formattedOrders } = props
  const unconfirmedOrders = useSelector(state => state.orderHistory.unconfirmedOrders)
  const [requestPayingOrders] = useRequestPayingOrders()

  const count = useMemo(() => {
    if (!showBadge) return 0 // only calculate count when badge show
    if (query === 'paying') return requestPayingOrders.length

    // format and chunk orders to shorten object size which faster the searchAndFilterOrders
    const chunkedOrders = _.chunk(formattedOrders, 100)
    const filteredOrders = chunkedOrders.flatMap(orders => searchAndFilterOrders(orders, query, filter))
    if (query === 'priceUndetermined') return filteredOrders.length
    return filteredOrders.filter(order => unconfirmedOrders.includes(order.serial)).length
  }, [formattedOrders, unconfirmedOrders, requestPayingOrders])

  return (
    <View style={styles.container}>
      <Button
        title={text}
        textColor={selected ? colors.tertiary : colors.white}
        style={styles.button(selected)}
        backgroundColor={colors.transparent}
        textBold
        type='TouchableOpacity'
        onPress={onPress}
      />
      {
        count > 0 && (
          <Badge
            value={count}
            status='error'
            containerStyle={styles.badge}
          />
        )
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: (selected) => ({
    width: 'auto',
    minWidth: 70,
    borderWidth: 2,
    borderColor: selected ? colors.tertiary : colors.white,
    borderRadius: 8,
    paddingHorizontal: 8,
  }),
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})
