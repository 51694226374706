import { StyleSheet, Text, View } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import Button from '../buttons/Button'
import CenterModal from '../CenterModal'
import Row from '../Row'
import colors from '@root/src/theme/colors'

function MemberRegistrationDialog (props) {
  const { setPhone } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isMemberRegistrationDialog = useSelector(state => state.app.dialog.memberRegistrationDialog)
  const [customerPhone, setCustomerPhone] = useState('')
  const [countryCode, setCountryCode] = useState('852')
  const [formatError, setFormatError] = useState(false)

  useEffect(() => {
    if (isMemberRegistrationDialog) {
      setCustomerPhone('')
      setFormatError(false)
    }
  }, [isMemberRegistrationDialog])

  // validate phone number check if it is 8 digits and add prefix '+',
  // !! modify this function if CRM promoted to other countries
  const validatePhoneNumber = (phone) => {
    const regex = /^[0-9]{8}$/
    const isValid = regex.test(phone)
    if (isValid) {
      const phoneWithCountryCode = '+'
      return phoneWithCountryCode.concat(countryCode, phone)
    }
    setFormatError(true)
    return false
  }

  const onSubmit = () => {
    const validatedphone = validatePhoneNumber(customerPhone)

    if (!validatedphone) {
      return
    }

    setPhone(validatedphone)
    dispatch(actions.app.closeDialog(['memberRegistrationDialog']))
    dispatch(actions.app.showDialog(['confirmMemberInfoDialog']))
  }

  return (
    <CenterModal
      enablePressOutsideClose
      visible={isMemberRegistrationDialog}
      contentContainerStyle={styles.container}
      title={t('app.component.memberRegistrationDialog.title')}
      onClose={() => dispatch(actions.app.closeDialog(['memberRegistrationDialog']))}
    >
      <Row style={styles.content}>
        <Text>{t('app.component.memberRegistrationDialog.message')}: </Text>
        <View style={styles.codeInputContainer}>
          <TextInput
            keyboardType='numeric'
            style={styles.input}
            placeholder={t('app.component.memberRegistrationDialog.codeMessage')}
            placeholderTextColor='#B0B0B0'
            value={countryCode}
            maxLength={4}
            editable={false}
            onChangeText={(text) => {
              // Prevent non-numeric characters from being entered
              const regex = /^[0-9]*$|^[0-9]+$/
              const isValid = regex.test(text)
              if (isValid) {
                setCountryCode(text)
              }
            }}
          />
        </View>
        <View style={styles.inputContainer}>
          <TextInput
            keyboardType='numeric'
            autoFocus
            style={styles.input}
            placeholder={t('app.component.memberRegistrationDialog.message')}
            placeholderTextColor='#B0B0B0'
            value={customerPhone}
            maxLength={8}
            onChangeText={(text) => {
              // Prevent non-numeric characters from being entered
              const regex = /^[0-9]*$|^[0-9]+$/
              const isValid = regex.test(text)
              if (isValid) {
                setCustomerPhone(text)
              }
            }}
          />
        </View>
      </Row>
      <Text style={{ color: colors.red }}>{formatError ? t('app.component.memberRegistrationDialog.phoneFormatError') : ''}</Text>
      <Button
        style={styles.button}
        title={t('app.common.apply')}
        onPress={onSubmit}
      />

    </CenterModal>
  )
}

export default MemberRegistrationDialog

const styles = StyleSheet.create({
  container: {
    width: 420,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    alignItems: 'center',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    flex: 0.8,
    backgroundColor: colors.lightGray1,
    borderRadius: 5,
    marginVertical: 16,
  },
  codeInputContainer: {
    flex: 0.2,
    backgroundColor: colors.lightGray1,
    borderRadius: 5,
    marginVertical: 16,
    marginHorizontal: 8,
  },
  input: {
    textAlign: 'center',
    fontSize: 18,
    width: '100%',
    marginVertical: 8,
  },
})
