import moment from 'moment'
import shortid from 'shortid'

import { PrinterFontSize } from '@/constants/printer'
import PrintDoc from '@/libs/printing/PrintDoc'
import i18n from '@/i18n'

export default class QRCode extends PrintDoc {
  TYPE = PrintDoc.Types.QRCODE

  constructor (shortUrl, merchant, order, categoryTagName, printReason) {
    super()
    this.shortUrl = shortUrl
    this.merchant = merchant
    this.order = order
    this.categoryTagName = categoryTagName
    this.printId = shortid.generate()
    this.printReason = printReason
  }

  print () {
    this.addOrderQrcodeHeader()
    return {
      commandBuffer: this.printer.getCommandBuffer(),
      bufferStrings: this.printer.getBufferStrings(),
      uuid: this.printId,
    }
  }

  addOrderQrcodeHeader () {
    const printer = this.printer
    const merchant = this.merchant
    const shortUrl = this.shortUrl
    const order = this.order
    const categoryTagName = this.categoryTagName
    const printId = this.printId

    function init () {
      printer.addInit()
      printer.addCodePage()
      printer.addRowFeed(3)
    }

    /**
     * 若餐廳設定有開啟 enableQRCodeLogo 則列印餐廳 Logo
     */
    function addMerchantLogo () {
      const { enableQRCodeLogo } = merchant.setting
      if (enableQRCodeLogo) {
        printer.addLogo()
        printer.addRowFeed(1)
      }
    }

    /**
     * 商店名稱
     */
    function addMerchantName () {
      const { name } = merchant
      printer.addAlign('center')
      printer.addBoldMode(true)
      printer.addText(name, PrinterFontSize.MEDIUM)
      printer.addBoldMode(false)
      printer.addRowFeed(2)
    }

    /**
     * 入座檯號
     */
    function addTableNumber () {
      const { table, subTable } = order
      printer.addBoldMode(true)
      printer.addText(`${i18n.t('app.printing.qrcode.table')}: ${table}${subTable > 0 ? `(${String.fromCharCode(subTable + 64)})` : ''}`, PrinterFontSize.MEDIUM)
      printer.addBoldMode(false)
      printer.addRowFeed(2)
    }

    /**
     * 人數
     */
    function addCustomerCount () {
      const { adults, children } = order
      printer.addText(`${i18n.t('app.printing.qrcode.customer')}`, PrinterFontSize.MEDIUM)
      if (adults !== 0) {
        printer.addText(`${i18n.t('app.printing.qrcode.adults')} ${adults}${i18n.t('app.printing.qrcode.cudtomerNo')} `, PrinterFontSize.MEDIUM)
      }
      if (children !== 0) {
        printer.addText(`${i18n.t('app.printing.qrcode.child')} ${children}${i18n.t('app.printing.qrcode.cudtomerNo')}`, PrinterFontSize.MEDIUM)
      }
      printer.addRowFeed()
      printer.addSeparator()
    }

    /**
     * 單號
     */
    function addOrderSerial () {
      const { serial } = order
      printer.addAlign('left')
      printer.addText(`${i18n.t('app.printing.qrcode.order')}: ${serial}`, PrinterFontSize.MEDIUM)
      printer.addRowFeed()
    }

    /**
     * 打印時間
     */
    function addPrintAt () {
      printer.addText(`${i18n.t('app.printing.qrcode.time')}: ${moment().format('YYYY/MM/DD HH:mm:ss')}`, PrinterFontSize.SMALL)
      printer.addSeparator()
    }

    /**
     * 標語
     */
    function addSlogan () {
      printer.addAlign('center')
      printer.addText(`${i18n.t('app.printing.qrcode.dimorder')}`, PrinterFontSize.SMALL)
      printer.addRowFeed()
    }

    /**
     * shortUrl 的 QRCode
     */
    function addQRCode () {
      printer.addQRCode(shortUrl)
      printer.addRowFeed()
    }

    /**
     * 若有 categoryTagName 則列印分類標籤
     */
    function addCategoryTag () {
      if (categoryTagName) {
        printer.addText(categoryTagName, PrinterFontSize.MEDIUM)
        printer.addRowFeed(2)
      }
    }

    /**
     * 自訂文字
     */
    function addCustomizedMessage () {
      const { qrCustomText } = merchant.setting
      printer.addText(qrCustomText, PrinterFontSize.MEDIUM)
      printer.addRowFeed(2)
    }

    /**
     * printId
     */
    function addPrintId () {
      printer.addAlign('left').addText(`${printId}       -Powered by DimPOS-`, 0)
      printer.addRowFeed(5)
    }

    init()
    addMerchantLogo()
    addMerchantName()
    addTableNumber()
    addCustomerCount()
    addOrderSerial()
    addPrintAt()
    addSlogan()
    addQRCode()
    addCategoryTag()
    addCustomizedMessage()
    addPrintId()
    printer.addCutPaper()
  }
}
