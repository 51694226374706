import { StyleSheet, Text, View } from 'react-native'
import { currencyWithCommas, numberWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'
import orderAppLib from 'dimorder-orderapp-lib'
import shadows from '@/theme/shadows'

export default function PriceTotal (props) {
  const { roundedTotal } = props.order
  const { t } = useTranslation()
  const rounding = useSelector(state => state.merchant.data.rounding)

  return (
    <View style={styles.total}>
      <Text style={styles.totalText}>
        {t('app.page.checkout.orderSummary.payTotal')}：＄{numberWithCommas(roundedTotal)}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  total: {
    height: 60,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    ...shadows.default,
  },
  totalText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.white,
  },
})
