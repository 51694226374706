import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IMenuState} */
export const initialState = {
  isInit: false,
  isCategoryLoading: false,
  isMenuLoading: false,
  isLoading: false,
  isPictureMode: false,
  isNumberPadMode: false,
  isBatchListExpand: false,
  rootCategory: {},
  rootSetCategory: {},
  categories: {},
  menus: {},
  sets: {},
  categoryTags: [],
  isfilterByTime: false,
  selectedCategory: null,
  selectedSet: null,
  selectedSetStep: null,
  isSearchExpanded: false, // only use in text mode
  searchText: '',
  optionGroupPresets: [],
  customItems: [],
  departments: [],
  promote: {
    id: 'promote',
    name: 'promote',
    parentId: null,
    categories: [],
    menus: [],
    sets: [],
    path: 'promote',
    steps: [],
    timerange: [],
    weekdays: [],
  },
  soldOut: {
    id: 'soldOut',
    name: 'soldOut',
    parentId: null,
    categories: [],
    menus: [],
    sets: [],
    path: 'soldOut',
    steps: [],
    timerange: [],
    weekdays: [],
  },
  rewards: [],
}

export default produce(
  /**
   * @param {IMenuState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_CATEGORY_LOADING: {
        draft.isCategoryLoading = action.payload.isLoading
        break
      }
      case ActionTypes.UPDATE_MENU_LOADING: {
        draft.isMenuLoading = action.payload.isLoading
        break
      }
      case ActionTypes.UPDATE_PICTURE_MODE: {
        draft.isPictureMode = action.payload.isPictureMode
        break
      }
      case ActionTypes.UPDATE_BATCH_LIST_EXPAND: {
        draft.isBatchListExpand = action.payload.isBatchListExpand
        break
      }
      case ActionTypes.UPDATE_SEARCH_EXPAND: {
        draft.isSearchExpanded = action.payload.isSearchExpanded
        break
      }
      case ActionTypes.UPDATE_CATEGORIES: {
        const { isPictureMode, selectedCategory, rootCategory, rootSetCategory, categories, menus, sets, categoryTags, promote, soldOut, optionGroupPresets } = action.payload
        draft.rootCategory = rootCategory
        draft.rootSetCategory = rootSetCategory
        draft.categories = categories
        draft.menus = menus
        draft.sets = sets
        draft.categoryTags = categoryTags
        draft.promote = promote
        draft.soldOut = soldOut
        draft.optionGroupPresets = optionGroupPresets
        draft.selectedCategory = selectedCategory
        draft.isPictureMode = isPictureMode
        break
      }
      case ActionTypes.SELECT_CATEGORY: {
        draft.selectedCategory = action.payload.category
        break
      }
      case ActionTypes.SELECT_SET: {
        draft.selectedSet = action.payload.set
        break
      }
      case ActionTypes.SELECT_SET_STEP: {
        draft.selectedSetStep = action.payload.setStep
        break
      }
      case ActionTypes.UPDATE_SEARCH_TEXT: {
        draft.searchText = action.payload.searchText
        break
      }
      case ActionTypes.UPDATE_OPTION_GROUP_PRESETS: {
        const { optionGroupPresets } = action.payload
        draft.optionGroupPresets = optionGroupPresets
        break
      }
      case ActionTypes.CREATE_OPTION_GROUP_PRESET: {
        const { optionGroupPreset } = action.payload
        draft.optionGroupPresets.push(optionGroupPreset)
        break
      }
      case ActionTypes.UPDATE_OPTION_GROUP_PRESET: {
        const { optionGroupPreset } = action.payload
        const index = draft.optionGroupPresets.findIndex(group => group.id === optionGroupPreset.id)
        draft.optionGroupPresets[index] = optionGroupPreset
        break
      }
      case ActionTypes.DELETE_OPTION_GROUP_PRESET: {
        const { optionGroupPreset } = action.payload
        const index = draft.optionGroupPresets.findIndex(group => group.id === optionGroupPreset.id)
        draft.optionGroupPresets.splice(index, 1)
        break
      }
      case ActionTypes.CREATE_CATEGORY: {
        const { category } = action.payload
        //  加進categoriesMap
        _.set(draft.categories, category.id, category)

        // 加進parent category
        _.set(draft.categories, category.path, category)

        //  加進rootCategory/rootSetCategory
        let treePath
        let searchTreePath
        if (category.isInSetCategories) {
          treePath = category.path.replace('SET_ROOT', 'rootSetCategory')
          searchTreePath = category.path.replace('SET_ROOT.categories', 'rootSetCategory.searchCategories')
        } else {
          treePath = category.path.replace('ROOT', 'rootCategory')
          searchTreePath = category.path.replace('ROOT.categories', 'rootCategory.searchCategories')
        }
        _.set(draft, treePath, category)
        _.set(draft, searchTreePath, category)

        break
      }
      case ActionTypes.DELETE_CATEGORY: {
        const { category } = action.payload
        //  從categoriesMap移除
        draft.categories = _.omit(draft.categories, category.id)

        // 從parent category移除
        const parentPath = _.dropRight(category.path.split('.'), 1).join('.')
        const parentCategories = _.get(draft.categories, parentPath).categories.filter(c => c.id !== category.id)
        _.set(draft.categories, parentPath + '.categories', parentCategories)

        //  從rootCategory/rootSetCategory移除
        let treePath
        if (category.isInSetCategories) {
          treePath = parentPath.replace('SET_ROOT', 'rootSetCategory')
          _.set(draft, treePath + '.categories', parentCategories)
          draft.rootSetCategory.searchCategories = draft.rootSetCategory.categories
          draft.categories.ROOT.searchCategories = draft.categories.ROOT.categories
        } else {
          treePath = parentPath.replace('ROOT', 'rootCategory')
          _.set(draft, treePath + '.categories', parentCategories)
          draft.rootCategory.searchCategories = draft.rootCategory.categories
          draft.categories.SET_ROOT.searchCategories = draft.categories.SET_ROOT.categories
        }

        break
      }
      case ActionTypes.UPDATE_CATEGORY: {
        const { category } = action.payload
        //  從 categoriesMap 更新
        _.set(draft.categories, category.id, category)

        // 從 parent category 更新
        const parentPath = _.dropRight(category.path.split('.'), 1).join('.')
        const parentCategories = _.get(draft.categories, parentPath).categories.map(c => c.id === category.id ? category : c)
        _.set(draft.categories, parentPath + '.categories', parentCategories)

        //  從 rootCategory / rootSetCategory 更新
        let treePath
        if (category.isInSetCategories) {
          treePath = parentPath.replace('SET_ROOT', 'rootSetCategory')
        } else {
          treePath = parentPath.replace('ROOT', 'rootCategory')
        }
        _.set(draft, treePath + '.categories', parentCategories)
        _.set(draft, treePath + '.searchCategories', parentCategories)
        break
      }
      case ActionTypes.CREATE_MENU: {
        const { menu } = action.payload
        _.set(draft.menus, menu.id, menu)
        break
      }
      case ActionTypes.DELETE_MENU: {
        const { menu } = action.payload
        draft.menus = _.omit(draft.menus, menu.id)
        break
      }
      case ActionTypes.UPDATE_MENU: {
        const { menu } = action.payload
        _.set(draft.menus, menu.id, menu)
        break
      }
      case ActionTypes.CREATE_SET: {
        const { set } = action.payload
        _.set(draft.sets, set.id, set)
        break
      }
      case ActionTypes.DELETE_SET: {
        const { set } = action.payload
        draft.sets = _.omit(draft.sets, set.id)
        break
      }
      case ActionTypes.UPDATE_SET: {
        const { set } = action.payload
        _.set(draft.sets, set.id, set)
        break
      }
      case ActionTypes.CREATE_CATEGORY_TAG: {
        const { categoryTag } = action.payload
        draft.categoryTags.push(categoryTag)
        break
      }
      case ActionTypes.UPDATE_CATEGORY_TAG: {
        const { categoryTag } = action.payload
        const index = draft.categoryTags.findIndex(o => o.id === categoryTag.id)
        if (index > -1) {
          draft.categoryTags[index] = categoryTag
        }
        break
      }
      case ActionTypes.DELETE_CATEGORY_TAG: {
        const { categoryTag } = action.payload
        const index = draft.categoryTags.findIndex(o => o.id === categoryTag.id)
        if (index > -1) {
          draft.categoryTags.splice(index, 1)
        }
        break
      }
      case ActionTypes.UPDATE_PROMOTE: {
        const { promote, type } = action.payload
        draft.promote[type] = promote
        break
      }
      case ActionTypes.UPDATE_SOLDOUT: {
        const { soldOut, type } = action.payload
        draft.soldOut[type] = soldOut
        break
      }
      case ActionTypes.RESET_SELECTIONS: {
        draft.selectedCategory = action.payload.category
        draft.selectedSet = initialState.selectedSet
        draft.selectedSetStep = initialState.selectedSetStep
        break
      }
      case ActionTypes.UPDATE_CUSTOM_ITEMS: {
        const { customItems } = action.payload
        draft.customItems = customItems
        break
      }
      case ActionTypes.UPDATE_KITCHEN_DEPARTMENTS: {
        const { departments } = action.payload
        draft.departments = departments
        break
      }
      case ActionTypes.RESTORE: {
        const { menu } = action.payload

        return menu
      }
      case ActionTypes.UPDATE_FILTERED_CATEGORY: {
        const { rootCategory, rootSetCategory } = action.payload
        draft.rootCategory.searchCategories = rootCategory
        draft.rootSetCategory.searchCategories = rootSetCategory
        break
      }
      case ActionTypes.UPDATE_CATEGORIES_ORDER: {
        const { parentCategoryId, categories, id } = action.payload
        let updatedCategories = draft.rootCategory.categories
        if (id === 'SET_ROOT') {
          updatedCategories = draft.rootSetCategory.categories
        }
        if (parentCategoryId) {
          updatedCategories = updatedCategories.map(category => {
            if (category.id === parentCategoryId) {
              const updatedCategory = { ...category, categories }
              _.set(draft.categories, category.id, updatedCategory)
              return updatedCategory
            }
            return category
          })
        } else {
          updatedCategories = categories
          _.set(draft.categories, id + '.categories', categories)
          _.set(draft.categories, id + '.searchCategories', categories)
        }

        if (id === 'SET_ROOT') {
          draft.rootSetCategory.categories = updatedCategories
          draft.rootSetCategory.searchCategories = updatedCategories
        } else {
          draft.rootCategory.categories = updatedCategories
          draft.rootCategory.searchCategories = updatedCategories
        }
        break
      }
      case ActionTypes.UPDATE_MENUS_ORDER: {
        const { categoryId, menus } = action.payload
        if (categoryId === 'promote') {
          draft.promote = { ...draft.promote, menus }
          break
        }

        if (categoryId === 'soldOut') {
          draft.soldOut = { ...draft.soldOut, menus }
          break
        }

        let updatedCategories = draft.rootCategory.categories
        updatedCategories = updatedCategories.map(category => {
          if (category.id === categoryId) {
            const updatedCategory = { ...category, menus }
            _.set(draft.categories, category.id, updatedCategory)
            return updatedCategory
          }
          let subCategories = category.categories || []
          const ids = _.flatMap(subCategories, 'id')
          if (ids.includes(categoryId)) {
            subCategories = subCategories.map(sub => {
              if (sub.id === categoryId) {
                return { ...sub, menus }
              }
              return sub
            })
            const updatedCategory = { ...category, categories: subCategories }
            _.set(draft.categories, category.id, updatedCategory)
            return updatedCategory
          }
          return category
        })

        draft.rootCategory.categories = updatedCategories
        draft.rootCategory.searchCategories = updatedCategories
        _.set(draft.categories, 'ROOT.categories', updatedCategories)
        _.set(draft.categories, 'ROOT.searchCategories', updatedCategories)
        break
      }
      case ActionTypes.UPDATE_SETS_ORDER: {
        const { categoryId, sets } = action.payload
        if (categoryId === 'promote') {
          draft.promote.sets = sets
          break
        }

        let updatedCategories = draft.rootSetCategory.categories
        updatedCategories = updatedCategories.map(category => {
          if (category.id === categoryId) {
            const updatedCategory = { ...category, sets }
            _.set(draft.categories, category.id, updatedCategory)
            return updatedCategory
          }
          let subCategories = category.categories || []
          const ids = _.flatMap(subCategories, 'id')
          if (ids.includes(categoryId)) {
            subCategories = subCategories.map(sub => {
              if (sub.id === categoryId) {
                return { ...sub, sets }
              }
              return sub
            })
            const updatedCategory = { ...category, categories: subCategories }
            _.set(draft.categories, category.id, updatedCategory)
            return updatedCategory
          }
          return category
        })

        draft.rootSetCategory.categories = updatedCategories
        draft.rootSetCategory.searchCategories = updatedCategories
        _.set(draft.categories, 'SET_ROOT.categories', updatedCategories)
        _.set(draft.categories, 'SET_ROOT.searchCategories', updatedCategories)
        break
      }
      case ActionTypes.UPDATE_FILTER_BY_TIME: {
        const { isfilterByTime } = action.payload
        draft.isfilterByTime = isfilterByTime
        break
      }
      case ActionTypes.UPDATE_REWARD_ITEMS: {
        const { rewards } = action.payload
        draft.rewards = rewards
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
