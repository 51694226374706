import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import { actions, useDispatch } from '@/redux'
import { useSelectedBatchItem } from '@/hooks/order'
import BatchHeader from './BatchHeader'
import BatchList from './BatchList'
import React from 'react'

export default function Batch (props) {
  const dispatch = useDispatch()
  const [selectedItem, selectedSetItem] = useSelectedBatchItem()
  return (
    <TouchableWithoutFeedback
      style={styles.outsideTouchable}
      onPress={() => {
        if (!selectedItem?.isSet) {
          dispatch(actions.orderBatch.selectItem(null))
        }
      }}
    >
      <View style={styles.container}>
        <BatchHeader />
        <BatchList />
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  outsideTouchable: {
    flex: 1,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 16,
    marginHorizontal: 32,
  },
})
