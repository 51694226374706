import Layout from '../../../components/Setting/Layout'
import React from 'react'
import RenderRoutes from '@/components/RenderRoutes'

export default function GeneralSetting ({ routes }) {
  return (
    <Layout>
      <RenderRoutes routes={routes} />
    </Layout>
  )
}
