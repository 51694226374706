import { ScrollView, StyleSheet } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'

const defaultTags = {
  disclaimer_alcoholic: false,
}
export default function MenuTags (props) {
  const { editingMenu, onUpdate } = props
  const { t } = useTranslation()
  const [menuTags, setMenuTags] = React.useState(defaultTags)

  React.useEffect(() => {
    const tags = { ...defaultTags }
    _.each(editingMenu.tags, t => {
      tags[t.name] = !t.remove
    })
    setMenuTags(tags)
    onUpdate('menuTags', tags)
  }, [editingMenu.tags])

  const updateMenuTags = (name, value) => {
    const tags = [...editingMenu.tags]
    if (value) {
      tags.push({ name })
    } else {
      _.remove(tags, t => t.name === name)
    }
    onUpdate('tags', tags)
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
    >
      <Header>
        <HeaderText text={t('app.page.setting.menuPanel.menuInfo.tags')} />
      </Header>

      <SettingList style={{ flex: 1 }}>
        <SettingListSwitchRow
          label={t('app.page.setting.menuPanel.menuInfo.disclaimerAlcoholic')}
          value={menuTags.disclaimer_alcoholic}
          onChangeValue={() => updateMenuTags('disclaimer_alcoholic', !menuTags.disclaimer_alcoholic)}
        />
      </SettingList>
    </ScrollView>
  )
}
