import { FlatList, Keyboard, PixelRatio, ScrollView, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import React, { useEffect } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useSelectedBatchItem } from '@/hooks/order'
import cloudinary from '@/libs/cloudinary'
import colors from '@/theme/colors'
import shortCode from '@/libs/shortCode'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import Column from '@/components/Column'
import Row from '@/components/Row'

import OptionButton from './OptionButton'
import SelectedOptionButton from './SelectedOptionButton'

const IMAGE_TRANSFORMATION = 'w_' + 100 * PixelRatio.get()

/**
 * @typedef AllTagsDialogProps
 * @property {boolean} open
 * @property {() => void} close
 */

/**
 *
 * @param {AllTagsDialogProps} props
 * @returns
 */
export default function AllTagsDialog (props) {
  const { open, onClose } = props
  const dispatch = useDispatch()
  const [prefix, setPrefix] = React.useState('')
  const [suffix, setSuffix] = React.useState('')
  const [inputCustomizedTag, setInputCustomizedTag] = React.useState('')
  const customTag = useSelector(state => state.merchant.data.customTag)
  const textInput = React.useRef()
  const scrollViewRef = React.useRef()
  const [selectedItem, selectedSetItem] = useSelectedBatchItem()
  const targetItem = selectedSetItem || selectedItem
  const [combinationTagsList, setCombinationTagsList] = useImmer(targetItem.tags)
  const hasImage = Boolean(targetItem.image)
  const isSetItem = Boolean(targetItem?.step)
  const chunkedPrefix = _.chunk(customTag.prefix.filter(customizedTag => customizedTag.enabled), 24)
  const chunkedSuffix = _.chunk(customTag.suffix.filter(customizedTag => customizedTag.enabled), 32)
  const [prefixIndex, setPrefixIndex] = React.useState(0)
  const [suffixIndex, setSuffixIndex] = React.useState(0)

  useEffect(() => {
    setCombinationTagsList(targetItem.tags)
  }, [targetItem])
  const { t } = useTranslation()
  const imageSource = hasImage ? { uri: cloudinary.transformation(targetItem.image, IMAGE_TRANSFORMATION) } : require('@icons/placeholder-1.png')
  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', () => {
      setCombinationTagsList(() => [...combinationTagsList, { id: shortCode.generate(6), name: '' }])
    })

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      if (inputCustomizedTag === '') {
        const filterEmpty = combinationTagsList.filter((tag) => tag.name !== '')
        setCombinationTagsList(filterEmpty)
      } else {
        setCombinationTagsList(draft => {
          draft[draft.length - 1].name = inputCustomizedTag
        })
      }
      setInputCustomizedTag('')
      Keyboard.dismiss()
    })
    return () => {
      keyboardWillShowListener.remove()
      keyboardDidHideListener.remove()
    }
  })
  const renderPrefixPage = ({ item: pageItems }) => {
    const length = 24 - pageItems.length
    const disableItem = new Array(length).fill({ id: '', name: '', enabled: false, type: 'prefix' })
    return (
      <FlatList
        scrollEnabled={false}
        data={pageItems.concat(disableItem)}
        renderItem={renderPrefixItem}
        numColumns={8}
        showsVerticalScrollIndicator={false}
        keyExtractor={(tag, index) => index}
      />
    )
  }
  const renderSuffixPage = ({ item: pageItems }) => {
    const length = 32 - pageItems.length
    const disableItem = new Array(length).fill({ id: '', name: '', enabled: false, type: 'suffix' })
    return (
      <FlatList
        scrollEnabled={false}
        data={pageItems.concat(disableItem)}
        renderItem={renderSuffixItem}
        numColumns={8}
        showsVerticalScrollIndicator={false}
        keyExtractor={(tag, index) => index}
      />
    )
  }
  const renderPrefixItem = ({ item: tag, index }) => {
    return (
      <OptionButton
        variant='tag'
        text={tag.name}
        containerStyle={tag.enabled ? styles.tagContainer : styles.tagDisableContainer}
        textStyle={styles.tagText}
        onPress={() => {
          if (!tag.enabled) {
            return
          }
          if (tag.name === prefix) {
            setPrefix('')
          } else {
            setPrefix(tag.name)
          }
          if (customTag.suffix.length <= 0) {
            setCombinationTagsList(() => [...combinationTagsList, { id: shortCode.generate(6), name: tag.name }])
          }
        }}
        selected={tag.enabled && prefix === tag.name}
      />
    )
  }
  const renderSuffixItem = ({ item: tag, index }) => {
    return (
      <OptionButton
        variant='tag'
        text={tag.name}
        containerStyle={tag.enabled ? styles.tagContainer : styles.tagDisableContainer}
        textStyle={styles.tagText}
        onPress={() => {
          if (!tag.enabled) {
            return
          }
          setSuffix(tag.name)
          setCombinationTagsList(() => [...combinationTagsList, { id: shortCode.generate(6), name: prefix + tag.name }])
          setSuffix('')
        }}
      />
    )
  }

  return (
    <CenterModal
      title={targetItem.name}
      visible={open}
      onClose={() => {
        setPrefix('')
        setCombinationTagsList(targetItem.tags)
        onClose()
      }}
      contentContainerStyle={styles.contentContainer}
      onPressOutside={() => textInput.current.blur()}
    >
      {/* <Row>
        <Column style={styles.content}>
          <Row>
            <Image source={imageSource} style={styles.imageContainer} />
            <View>
              <Text style={styles.itemDetailTitle}>{targetItem.name}</Text>
              <Text style={styles.itemDetailPrice}>$ {targetItem.price}</Text>
            </View>
          </Row>
        </Column>
      </Row> */}
      <Row>
        <ScrollView
          contentContainerStyle={styles.container}
          style={{ height: 100, width: '100%' }}
          showsVerticalScrollIndicator={false}
          ref={scrollViewRef}
        >
          <TouchableOpacity
            onPress={() => {
              textInput.current.focus()
              scrollViewRef.current.scrollToEnd()
            }}
            style={{
              borderWidth: 1,
              width: '100%',
              minHeight: 80,
              borderRadius: 10,
              flexDirection: 'row',
              flexWrap: 'wrap',
              padding: 10,
              backgroundColor: colors.light,
            }}
          >
            {_.map(combinationTagsList.filter(combinationTag => combinationTag.name !== ''), (combinationTag) => {
              return (
                <SelectedOptionButton
                  key={combinationTag.id}
                  variant='tag'
                  borderRadius={20}
                  text={combinationTag.name}
                  onPress={() => {
                    setCombinationTagsList(() => combinationTagsList.filter(combinationTags => combinationTags.id !== combinationTag.id))
                  }}
                  removeable
                />
              )
            })}
            {
              inputCustomizedTag !== '' && (
                <SelectedOptionButton
                  variant='tag'
                  borderRadius={20}
                  text={inputCustomizedTag}
                  onPress={() => {
                    setInputCustomizedTag('')
                    textInput.current.blur()
                  }}
                />
              )
            }

          </TouchableOpacity>
          <TextInput
            ref={textInput}
            style={{ display: 'none' }}
            onChangeText={(value) => {
              setInputCustomizedTag(value)
            }}
            value={inputCustomizedTag}
          />
        </ScrollView>
        <View style={styles.divider} />
      </Row>
      <View style={[styles.container, { height: '33%' }]}>
        <Carousel
          scrollEnabled={chunkedPrefix.length > 1}
          data={chunkedPrefix}
          renderItem={renderPrefixPage}
          itemWidth={1020}
          sliderWidth={1020}
          onScrollIndexChanged={(index) => setPrefixIndex(index)}
        />
        <Pagination
          vertical
          containerStyle={styles.paginationContainer}
          dotsLength={chunkedPrefix.length}
          activeDotIndex={prefixIndex}
          dotStyle={styles.paginationDot}
          inactiveDotStyle={styles.paginationInActiveDot}
          inactiveDotScale={1}
        />
        <View style={styles.divider} />
      </View>
      <View style={[styles.container, { height: '47%' }]}>
        <Carousel
          scrollEnabled={chunkedSuffix.length > 1}
          data={chunkedSuffix}
          renderItem={renderSuffixPage}
          itemWidth={1020}
          sliderWidth={1020}
          onScrollIndexChanged={(index) => setSuffixIndex(index)}
        />
        <Pagination
          vertical
          containerStyle={styles.paginationContainer}
          dotsLength={chunkedSuffix.length}
          activeDotIndex={suffixIndex}
          dotStyle={styles.paginationDot}
          inactiveDotStyle={styles.paginationInActiveDot}
          inactiveDotScale={1}
        />
      </View>
      <View style={[styles.container, { height: '10%' }]}>
        <Row>
          <Column style={{ flex: 1 }}>
            <Button
              title={t('app.common.cancel')}
              backgroundColor={colors.light}
              textColor={colors.textTertiary}
              onPress={() => {
                setPrefix('')
                setCombinationTagsList(targetItem.tags)
                onClose()
              }}
            />
          </Column>
          <Column>
            <Button
              title={t('app.common.confirm')}
              onPress={() => {
                const filterEmpty = combinationTagsList.filter(combinationTag => combinationTag.name !== '')
                if (isSetItem) {
                  dispatch(actions.orderBatch.updateSetCustomTags(filterEmpty))
                } else {
                  dispatch(actions.orderBatch.updateCustomTags(filterEmpty))
                }
                setPrefix('')
                setCombinationTagsList(combinationTagsList)
                onClose()
              }}
            />
          </Column>
          <Column>
            <Button
              title={t('app.common.applyAll')}
              onPress={() => {
                const filterEmpty = combinationTagsList.filter(combinationTag => combinationTag.name !== '')
                dispatch(actions.orderBatch.updateBatchItemsCustomTags(filterEmpty))
                setPrefix('')
                setCombinationTagsList(combinationTagsList)
                onClose()
              }}
            />
          </Column>
        </Row>
      </View>

    </CenterModal>
  )
}

const styles = StyleSheet.create({
  imageContainer: {
    width: 100,
    height: 105,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: 6,
    overflow: 'hidden',
    marginVertical: 4,
    marginHorizontal: 10,
  },
  itemDetailTitle: {
    fontSize: 30,
  },
  itemDetailPrice: {
    fontSize: 25,
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    paddingTop: 10,
    paddingBottom: 10,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    width: '100%',
    height: '90%',
    justifyContent: 'space-between',
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 8,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  tagContainer: {
    width: 119,
    height: 50,
  },
  tagDisableContainer: {
    width: 119,
    height: 50,
    borderColor: 'transparent',
  },
  tagText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingHorizontal: 14,
    paddingVertical: 4,
  },
  paginationDot: {
    backgroundColor: colors.primary,
    width: 6,
    height: 6,
    margin: 2,
  },
  paginationInActiveDot: {
    backgroundColor: colors.dotGray,
    width: 6,
    height: 6,
    margin: 2,
  },
})
