import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '@/theme/colors'

DisabledLayer.propTypes = {
  enabled: PropTypes.bool,
  text: PropTypes.string,
  fontSize: PropTypes.number,
}

export default function DisabledLayer (props) {
  const { enabled, text = '', fontSize = 12 } = props
  const fontSizeStyle = { fontSize }

  return (
    enabled
      ? (
        <View style={[StyleSheet.absoluteFill, styles.container]}>
          <Text style={[styles.text, fontSizeStyle]}>{text}</Text>
        </View>
      )
      : null
  )
}

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    opacity: 0.8,
  },
  text: {
    color: colors.primary,
    fontWeight: 'bold',
  },
})
