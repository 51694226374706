import { useSelector } from '@/redux'

// eslint-disable-next-line no-unused-vars
import { ITable } from '@/redux/table/Table.d'

/**
 * @returns {object}
 */
export function useSelectedBooking () {
  const selectedBookingId = useSelector(state => state.table.selectedBookingId)
  const bookings = useSelector(state => state.table.bookings)
  if (selectedBookingId === null) return null
  return bookings.find(booking => booking.id === selectedBookingId)
}

/**
 * @returns {ITable}
 */
export function useSelectedTable () {
  const selectedTableId = useSelector(state => state.table.selectedTableId)
  const tables = useSelector(state => state.table.tables)
  if (selectedTableId === null) return null
  return tables.find(table => table.id === selectedTableId)
}
