import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import IconLabel from './IconLabel'
import React from 'react'
import Row from '@/components/Row'

export default function TableLegends () {
  const { t } = useTranslation()
  return (
    <Row>
      <IconLabel
        text={t('app.page.table.tableArea.tableLegends.empty')}
        style={styles.label}
        iconSource={require('@icons/table/table-gray.png')}
      />
      <IconLabel
        text={t('app.page.table.tableArea.tableLegends.settled')}
        style={styles.label}
        iconSource={require('@icons/table/table-green.png')}
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  label: {
    marginRight: 18,
  },
})
