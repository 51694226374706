import betaConfig from './betaConfig'
import defaultConfig from './defaultConfig'
import productionConfig from './productionConfig'
import testConfig from './testConfig'

const configs = {
  default: defaultConfig,
  development: testConfig,
  test: testConfig,
  beta: betaConfig,
  production: productionConfig,
}

export default configs
