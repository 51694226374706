import { Platform, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useIsOnline } from '@/hooks/app'
import CustomDialog from '@/components/CustomDialog'
import logger, { logId } from '@/libs/logger'

const isApp = Platform.OS !== 'web'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OfflineDialog (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isDataInit = useSelector(state => state.app.isDataInit)
  const isOnlineState = useIsOnline()
  const { isOnline } = isOnlineState
  const isMaster = useSelector(state => state.app.settings.isMaster)
  const isUserLogin = useSelector(state => state.auth.isUserLogin)
  const alerts = useSelector(state => state.app.alerts)
  const disableDialog = _.some(_.values(alerts), alert => !alert.dismiss)
  const [visible, setVisible] = React.useState(false)

  React.useEffect(() => {
    let setVisibleTimer = ''
    logger.log('[OfflineDialog] useEffect trigger.', {
      isOnline,
      isOnlineState,
      notMaster: !isMaster,
      isUserLogin,
      notDisableDialog: !disableDialog,
      isApp,
    })
    if (!isOnline && !isMaster && isUserLogin && !disableDialog && isApp) {
      setVisibleTimer = setTimeout(() => {
        setVisible(true)
      }, 5000)
    } else {
      setVisible(false)
    }
    return () => {
      clearTimeout(setVisibleTimer)
      setVisible(false)
    }
  }
  , [isOnline, isMaster, isUserLogin, disableDialog])

  const isOpen = isDataInit && visible

  useEffect(() => {
    dispatch(actions.app.isDialogOpened(isOpen))
  }, [isOpen])

  if (!isOpen) return null
  return (
    <CustomDialog title={t('app.common.offlineDialog.title')} width={640} height={180}>
      <View style={styles.content}>
        <Text style={styles.text}>{t('app.common.offlineDialog.message')}</Text>
        <View style={{ paddingTop: 10 }}>
          <View style={styles.statusList}>
            <Text style={[styles.status, { flexBasis: 160 }]}>logId:</Text>
            <Text style={styles.status}>{logId}</Text>
          </View>
          <View style={styles.statusList}>
            <Text style={[styles.status, { flexBasis: 160 }]}>{t('app.common.offlineDialog.isInternetOK')}:</Text>
            <Text style={styles.status}>{String(isOnlineState.conditions.isInternetOK)}(reachable: {String(isOnlineState.status.isInternetReachable)})</Text>
          </View>
          <View style={styles.statusList}>
            <Text style={[styles.status, { flexBasis: 160 }]}>{t('app.common.offlineDialog.isNetworkConnectedOK')}:</Text>
            <Text style={styles.status}>{String(isOnlineState.conditions.isNetworkConnectedOK)} (type: {isOnlineState.status.networkType})</Text>
          </View>
          <View style={styles.statusList}>
            <Text style={[styles.status, { flexBasis: 160 }]}>{t('app.common.offlineDialog.isWebSocketOK')}:</Text>
            <Text style={styles.status}>{String(isOnlineState.conditions.isWebSocketOK)}(code: {String(isOnlineState.status.webSocketStatusCode)}, {isOnlineState.status.webSocketStatus})</Text>
          </View>
        </View>
      </View>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  status: {
    fontSize: 14,
  },
  statusList: {
    flexDirection: 'row',
    padding: 2,
  },
})
