import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Row from '@/components/Row'
import TagItem from '@/components/InfoDialog/TagList/TagItem'
import colors from '@/theme/colors'

export default function CategoryTagList ({
  selectedCategoryTag = '',
  onSelectCategoryTag,
  categoryTags,
}) {
  const { t } = useTranslation()
  const renderItem = ({ item: tag }) => (
    <TagItem
      text={tag.display}
      selected={selectedCategoryTag === tag.value}
      onSelect={() => { onSelectCategoryTag(tag.value) }}
    />
  )
  return (
    <Row style={styles.container}>
      <Text style={styles.title}>{t('app.page.table.dialog.categoryTag')}</Text>
      <FlatList
        data={categoryTags}
        keyExtractor={(item) => item.value}
        renderItem={renderItem}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        legacyImplementation
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
  },
  title: {
    fontSize: 16,
    paddingLeft: 10,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
})
