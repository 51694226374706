import { KeyboardAvoidingView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderRightAction from '@/components/Setting/HeaderRightAction'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'

import OptionItem from './OptionItem'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionGroup } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 * @typedef OptionInfoProps
 * @property {IMenuOptionGroup} optionGroup
 * @property {string} locale
 * @property {() => void} onClose
 * @property {() => void} onSelectItem
 */

/**
 *
 * @param {OptionInfoProps} props
 * @returns
 */
export default function OptionInfo (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { optionGroup, locale, onClose, onSelectItem } = props
  const [keyboardAvoidingViewEnabled, setKeyboardAvoidingViewEnabled] = React.useState(false)
  const [offset, setOffset] = React.useState(0)
  const [expand, setExpand] = React.useState(false)
  const categories = useSelector(state => state.menu.categories)
  const lang = String(i18n.language ?? 'zh').split('-')[0]

  const [editingOptionGroup, updateEditingOptionGroup] = useImmer(optionGroup)
  const categoryOptions = _.map(categories, category => {
    if (category.id === 'ROOT' || category.id === 'SET_ROOT' || category.type === 'SET' || category.categories.length > 0) {
      return null
    }
    return {
      value: category.id,
      display: _.get(category?.localeNames, lang) ?? category.name,
    }
  }).filter(o => o)

  if (!optionGroup) return null

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.menuPanel.options.alert.delete.title'),
      message: `${t('app.page.setting.menuPanel.options.alert.delete.msg')}${optionGroup.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            dispatch(actions.menu.deleteOptionGroupPreset(optionGroup))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    onClose()
    dispatch(actions.menu.upsertOptionGroupPreset(editingOptionGroup))
  }

  const handleDragEnd = (options) => {
    // save options order
    updateEditingOptionGroup(draft => {
      draft.options = options
    })
  }

  const renderItem = ({ item: option, index, drag, isActive }) => {
    return (
      <OptionItem
        locale={locale}
        option={option}
        isExpand={expand}
        dragging={isActive}
        onChangeOption={(field, value) => {
          updateEditingOptionGroup(draft => {
            draft.options[index][field] = value
            if (field === 'name' && draft.options[index].localeNames) {
              draft.options[index].localeNames[locale] = value
            }
          })
          if (field === 'hidden') {
            const isAllOptionHidden = editingOptionGroup.options.map((o, i) => {
              if (i === index) {
                return { ...o, hidden: value }
              }
              return o
            }).every(o => o.hidden)
            if (isAllOptionHidden && editingOptionGroup.options.length) {
              updateEditingOptionGroup(draft => {
                draft.min = 0
              })
            }
          }
        }}
        onFocusOption={(field) => {
          let offset = -285
          const height = 280
          const maxOffset = 1
          switch (field) {
            case 'name': {
              offset += 0 + (maxOffset * height)
              break
            }
            case 'price': {
              offset += 35 + (maxOffset * height)
              break
            }
            case 'max': {
              offset += 70 + (maxOffset * height)
              break
            }
            default: {
              offset = -285
              break
            }
          }
          setOffset(offset)
          setKeyboardAvoidingViewEnabled(true)
        }}
        onDrag={drag}
        onDelete={() => {
          updateEditingOptionGroup(draft => {
            draft.options.splice(index, 1)
          })
        }}
      />
    )
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      contentContainerStyle={styles.container}
      behavior='position'
      enabled={keyboardAvoidingViewEnabled}
      keyboardVerticalOffset={offset}
    >
      {
        optionGroup.id && (
          <>
            <Header>
              <HeaderText text={t('app.page.setting.printer.setting') + t('app.page.setting.printer.item')} />
            </Header>

            <SettingList>
              <SettingListEnterRow
                label={t('app.page.setting.printer.item') + t('app.page.setting.printer.setting')}
                value=''// {settingState?.menu?.length ?? ''}
                onPress={() => {
                  onSelectItem({
                    title: `${t('app.page.setting.printer.item') + t('app.page.setting.printer.setting')}`,
                    multiple: true,
                    defaultValue: [],
                    options: categoryOptions,
                  })
                }}
              />
            </SettingList>
          </>
        )
      }

      <Header>
        <HeaderText text={optionGroup.id ? t('app.page.setting.menuPanel.options.optionGroup.setting') : t('app.page.setting.menuPanel.options.optionGroup.add')} />
      </Header>
      <SettingList>
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.name')} />
          <SettingListItemRightAction>
            <SettingListInput
              value={(_.get(editingOptionGroup?.localeNames, locale) ?? editingOptionGroup.name) || ''}
              onChangeText={(text) => {
                updateEditingOptionGroup(draft => {
                  // iOS 字串最後一個空格不會顯示，將空格取代為 \u00a0 讓他顯示出空格
                  draft.name = replaceSpace(text)
                  if (draft.localeNames) {
                    draft.localeNames[locale] = replaceSpace(text)
                  }
                })
              }}
              onBlur={() => {
                updateEditingOptionGroup(draft => {
                  // 結束編輯時，將 \u00a0 換回普通的 \u0020 空格
                  draft.name = replaceSaveSpace(editingOptionGroup?.name, true)
                  if (draft.localeNames) {
                    draft.localeNames[locale] = replaceSaveSpace(_.get(editingOptionGroup?.localeNames, locale) ?? editingOptionGroup.name, true)
                  }
                })
              }}
              onFocus={() => setKeyboardAvoidingViewEnabled(false)}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListSwitchRow
          divider
          label={t('app.page.setting.menuPanel.options.optionGroup.must')}
          value={editingOptionGroup.min > 0}
          onChangeValue={(required) => {
            if (required) {
              updateEditingOptionGroup(draft => {
                draft.min = _.max([editingOptionGroup.min, 1])
              })
              if (editingOptionGroup.options.length) {
                updateEditingOptionGroup(draft => {
                  draft.options[0].hidden = false
                })
              }
            } else {
              updateEditingOptionGroup(draft => {
                draft.min = 0
              })
            }
          }}
        />
        {editingOptionGroup.min > 0 && (
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.mustMin')} />
            <SettingListItemRightAction>
              <SettingListInput
                keyboardType='number-pad'
                value={String(editingOptionGroup.min) || ''}
                onChangeText={(text) => {
                  if (/^\d+$/.test(text) || text === '') {
                    updateEditingOptionGroup(draft => {
                      draft.min = text.replace(/\D/g, '')
                    })
                  }
                }}
                onFocus={() => setKeyboardAvoidingViewEnabled(false)}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        )}
        <SettingListSwitchRow
          divider={editingOptionGroup.multiple}
          label={t('app.page.setting.menuPanel.options.optionGroup.multiple')}
          value={editingOptionGroup.multiple}
          onChangeValue={(multiple) => {
            updateEditingOptionGroup(draft => {
              draft.multiple = multiple
            })
          }}
        />
        {editingOptionGroup.multiple && (
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.multipleLimit')} />
            <SettingListItemRightAction>
              <SettingListInput
                keyboardType='number-pad'
                value={String(editingOptionGroup.max) || ''}
                onChangeText={(text) => {
                  if (/^\d+$/.test(text) || text === '') {
                    updateEditingOptionGroup(draft => {
                      draft.max = text
                    })
                  }
                }}
                onFocus={() => setKeyboardAvoidingViewEnabled(false)}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        )}

      </SettingList>

      <Header>
        <HeaderText text={t('app.page.setting.menuPanel.options.option.item')} />
        <HeaderRightAction>
          <TouchableOpacity
            style={{ marginRight: 30 }}
            onPress={() => setExpand(!expand)}
          >
            {
              expand
                ? <HeaderText style={{ color: colors.darkSecondary }} text={t('app.page.setting.menuPanel.options.option.collapseAll')} />
                : <HeaderText style={{ color: colors.darkSecondary }} text={t('app.page.setting.menuPanel.options.option.expandAll')} />
            }
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              updateEditingOptionGroup(draft => {
                draft.options.push({
                  id: uuid(),
                  name: '',
                  price: 0,
                  max: 1,
                  hidden: false,
                  localeNames: {},
                })
              })
            }}
          >
            <HeaderText style={{ color: colors.darkSecondary }} text={t('app.page.setting.menuPanel.options.option.add')} />
          </TouchableOpacity>
        </HeaderRightAction>
      </Header>

      <DraggableFlatList
        style={styles.flatList}
        containerStyle={{ flex: 1 }}
        data={editingOptionGroup.options}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        onDragEnd={({ data }) => handleDragEnd(data)}
      />

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          disabled={!optionGroup.id}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>

    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    // margin: 8,
  },
  addIcon: {
    width: 25,
    height: 25,
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
  },
  flatList: {
    flex: 1,
    height: '100%',
    marginBottom: 10,
  },
})
