import { MaterialCommunityIcons } from '@expo/vector-icons'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SettingListHeaderButtonProps
 * @property {string} text
 * @property {() => void} onPress
 * @property {string?} icon
 * @property {boolean?} disabled
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {SettingListHeaderButtonProps} props
 * @returns
 */
export default function SettingListHeaderButton (props) {
  const { onPress, disabled, text, style, icon } = props
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled || !onPress}>
      <View style={[styles.container, style]}>
        {icon && <View style={styles.icon} />}
        <Text style={styles.text}>{text}</Text>
        {icon && (
          <View style={styles.icon}>
            <MaterialCommunityIcons name={icon} size={18} color='white' />
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    flexDirection: 'row',
    backgroundColor: colors.primary,
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginLeft: -25,
    borderTopStartRadius: 5,
    borderTopEndRadius: 5,
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.white,
  },
  icon: {
    width: 24,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
})
