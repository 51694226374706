import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

MenuItemFolder.propTypes = {
  category: PropTypes.object,
}

/**
 * @param {{category: IAppCategory}} props
 */
export default function MenuItemFolder (props) {
  const { category } = props
  const dispatch = useDispatch()
  const isNumPadOrder = useSelector(state => state.app.settings?.numPadOrder)
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const { t } = useTranslation()
  const categoryName = category?.id === 'SET_ROOT'
    ? t('app.constant.menu.set')
    : _.get(category?.localeNames, lang) ?? category.name

  return (
    <TouchableOpacity
      onPress={() => {
        dispatch(actions.menu.selectCategory(category))
        if (category.isInSetCategories && category.sets?.length === 1) {
          dispatch(actions.orderBatch.addItem(category.sets[0]))
        }
      }}
    >
      <View style={[styles.container, isNumPadOrder && styles.numPadModeContainer]}>
        <Text style={styles.text} numberOfLines={2} ellipsizeMode='tail' wrap>{categoryName}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 180,
    height: 50,
    borderRadius: 8,
    backgroundColor: colors.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginBottom: 4,
    marginHorizontal: 10,
  },
  numPadModeContainer: {
    marginTop: 2,
    marginBottom: 8,
    marginLeft: 2,
    marginRight: 8,
  },
  text: {
    color: colors.white,
    fontSize: 15,
    fontWeight: '500',
    lineHeight: 17,
    textAlign: 'center',
  },
})
