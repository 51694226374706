import { Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import colors from '@/theme/colors'

/**
 * 用以顯示設定的狀態。
 * 可根據傳入的 enabled 顯示『開啟』或『關閉』，
 * 或可用 status 或 color 自訂文字與顏色。
 * @typedef SettingStatusProps
 * @property {boolean} [enabled = false]
 * @property {string} [status = '']
 * @property {string} [color = '']
 * @param {SettingStatusProps} props
 * @returns
 * @example <SettingStatus enabled={true} />
 * @example <SettingStatus status='Error' color='red' />
 */
export default function SettingStatus (props) {
  const { enabled = false, status = '', color = '' } = props
  const { t } = useTranslation()
  const statusText = status || (enabled ? t('app.common.enabled') : t('app.common.disabled'))
  const statusColor = color || (enabled ? colors.green : colors.gray)
  const colorStyle = { color: statusColor }
  return <Text style={[colorStyle]}>{statusText}</Text>
}
