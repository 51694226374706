import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import { useHistory } from '@/libs/reactRouter'
import colors from '@/theme/colors'

import BackNavigator from '@/components/Setting/BackNavigator'
import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import QRCodeDialog from '@/components/dialogs/QRcodeDialog'
import SettingImage from '@/components/Setting/SettingImage'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

const DEFAULT_STATE = {
  name: '',
  desc: '',
  contact: '',
  address: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function EditAbout (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const clientUrls = useSelector(state => state.config.clientUrls)
  const data = useSelector(state => state.merchant.data)
  const token = useSelector(state => state.auth.userLogin.token)

  const [aboutState, setAboutState] = useState(DEFAULT_STATE)
  const [qrCodeDialogOpen, setQRCodeDialogOpen] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [imageType, setImageType] = React.useState('')

  useEffect(() => {
    if (data) {
      setAboutState({
        name: data.name,
        desc: data.desc,
        contact: data.contact,
        address: data.address,
      })
    }
  }, [data])

  const onChangeState = (key, val) => {
    setAboutState(prevState =>
      _.set(_.assign({}, prevState), key, replaceSaveSpace(val)),
    )
  }

  const onSubmit = () => {
    dispatch(actions.merchant.updateInfo(aboutState))
    history.goBack()
  }

  const onUpload = async () => {
    const url = `${clientUrls.merchantFrontend}/image?token=${token}`
    setUrl(await dimorderApi.shortUrl(url))
    setQRCodeDialogOpen(true)
  }

  return (
    <>
      <BackNavigator
        text={t('app.page.setting.aboutPanel.editAbout.info')}
        onPress={() => history.goBack()}
      />
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <Header><HeaderText text={t('app.page.setting.aboutPanel.editAbout.introBackground')} /></Header>
          <SettingImage
            source={data.images.info}
            notFoundText={t('app.page.setting.image.upload')}
            notFoundSource={require('@icons/setting/upload.png')}
            onPress={() => {
              setImageType(t('app.page.setting.aboutPanel.editAbout.introBackground'))
              onUpload()
            }}
          />
          <Header><HeaderText text={t('app.page.setting.aboutPanel.editAbout.frontPageImage')} /></Header>
          <SettingImage
            source={data.images.cover}
            notFoundText={t('app.page.setting.image.upload')}
            notFoundSource={require('@icons/setting/upload.png')}
            onPress={() => {
              setImageType(t('app.page.setting.aboutPanel.editAbout.frontPageImage'))
              onUpload()
            }}
          />
          {/* <Header><HeaderText text='分類標籤' /></Header>
          <Row>
            {setting?.tags.map((tag, index) => <Tag key={index} text={tag} />)}
          </Row> */}
        </View>
        <View style={styles.contentContainer}>
          <Header><HeaderText text={t('app.page.setting.aboutPanel.editAbout.info')} /></Header>
          <View style={{ height: 220 }}>
            <SettingList>
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.aboutPanel.editAbout.name')} />
                <SettingListItemRightAction>
                  <SettingListInput
                    value={replaceSpace(aboutState.name)}
                    onChangeText={(text) => onChangeState('name', text)}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.aboutPanel.editAbout.intro')} />
                <SettingListItemRightAction>
                  <SettingListInput
                    value={replaceSpace(aboutState.desc)}
                    onChangeText={(text) => onChangeState('desc', text)}
                    multiline
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.aboutPanel.editAbout.phone')} />
                <SettingListItemRightAction>
                  <SettingListInput
                    keyboardType='phone-pad'
                    value={aboutState.contact}
                    onChangeText={(text) => onChangeState('contact', text)}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem>
                <SettingListItemText text={t('app.page.setting.aboutPanel.editAbout.address')} />
                <SettingListItemRightAction>
                  <SettingListInput
                    value={replaceSpace(aboutState.address)}
                    onChangeText={(text) => onChangeState('address', text)}
                    multiline
                  />
                </SettingListItemRightAction>
              </SettingListItem>

            </SettingList>
          </View>
          <Header><HeaderText text={t('app.page.setting.aboutPanel.editAbout.menuBanner')} /></Header>
          <SettingImage
            source={data.images.banner}
            notFoundText={t('app.page.setting.image.upload')}
            notFoundSource={require('@icons/setting/upload.png')}
            onPress={() => {
              setImageType(t('app.page.setting.aboutPanel.editAbout.menuBanner'))
              onUpload()
            }}
          />
          <Button
            title={t('app.common.save')}
            style={styles.submitButton}
            backgroundColor={colors.darkSecondary}
            textBold
            onPress={onSubmit}
          />
        </View>
      </View>
      <QRCodeDialog
        open={qrCodeDialogOpen}
        onClose={() => setQRCodeDialogOpen(false)}
        url={url}
        title={imageType}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  submitButton: {
    margin: 0,
    marginTop: 16,
    alignSelf: 'flex-end',
  },
})
