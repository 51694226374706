import { FlatList, StyleSheet } from 'react-native'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useDispatch, useSelector } from '@/redux'
import React from 'react'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'

export default function SetMenuList (props) {
  const dispatch = useDispatch()
  const { title, setMenus, selectedSetMenu, onPress } = props
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)

  const renderMenuItem = ({ item, index }) => {
    const isSelected = item.id === selectedSetMenu?.id
    return (
      <SettingListItem
        key={item.id}
        divider={index !== setMenus?.length - 1}
        onPress={() => onPress(item)}
        style={[styles.listItem, isSelected && { backgroundColor: colors.tertiary }]}
      >
        <SettingListItemText text={`${_.get(allMenus[item.menuId]?.localeNames, lang) ?? item.name} ${item.min || '-'}/${item.max || '-'}`} wrap />
        <SettingListItemRightAction>
          <SettingListItemText text={`${currencyWithCommas(item.price) || '-'}`} />
        </SettingListItemRightAction>

      </SettingListItem>
    )
  }

  return (
    <SettingList shadow style={styles.container} title={title}>
      <FlatList
        data={setMenus}
        key={item => item.id}
        renderItem={renderMenuItem}
        showsVerticalScrollIndicator={false}
        extraData={selectedSetMenu}
      />
    </SettingList>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 0,
    width: 240,
  },
  listItem: {
    paddingLeft: 10,
  },
  addButton: {
    width: 'auto',
    marginLeft: 0,
  },
})
