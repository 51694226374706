import { StyleSheet, View } from 'react-native'
import { useHistory } from 'react-router-dom'
import { useImmer } from 'use-immer'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { countPlaces } from '@/libs/countPlaces'
import { useCheckoutPayment } from '@/hooks/orderCheckout'
import { usePaymentMethod } from '@/hooks/merchant'
import { usePrevious } from '@/hooks/previous'
import { useSelectedOrder } from '@/hooks/orderHistory'
import Column from '@/components/Column'
import PaymentHistoryDialog from '@/components/dialogs/PaymentHistoryDialog'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import ActionPanel from './ActionPanel'
import AmountPanel from './AmountPanel'
import Header from './Header'
import MethodPanel from './MethodPanel'
import NotesPanel from './NotesPanel'
import NumberPad from './NumberPad'
import PopUpStatus from '../PopUpStauts'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Payment (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const selectedOrder = useSelectedOrder()
  const checkoutPayment = useCheckoutPayment()
  const [selectedField, setSelectedField] = React.useState('paidAmount') // 目前輸入的欄位
  const [positionTop, setPositionTop] = React.useState(0) // 幫助ActionPanel定位
  const visible = useSelector(state => state.app.dialog.checkout.history)
  const paymentMethod = usePaymentMethod(checkoutPayment.paymentMethod)
  const isEcrPayment = Boolean(checkoutPayment.gateway)
  const [block, setBlock] = useImmer({ amount: false, paymentTips: false })
  const paidAmount = selectedOrder.payments.filter((p, index) => p?.status === 'paid')
    .map(p => Number(p.amount)).reduce((a, b) => a + b, 0)
  const remainTotal = Number(selectedOrder.roundedTotal) - Number(paidAmount)
  const prevRoundedTotal = usePrevious(selectedOrder.roundedTotal)

  // 在結帳畫面時，訂單價格更新
  React.useEffect(() => {
    if (prevRoundedTotal !== selectedOrder.roundedTotal) {
      // 重設 orderCheckout
      dispatch(actions.orderCheckout.checkoutOrder(selectedOrder.id))
    }
  }, [selectedOrder?.roundedTotal])

  React.useEffect(() => {
    if (checkoutPayment?.autoInput) {
      setBlock(draft => {
        draft.amount = false
      })
    }
  }, [checkoutPayment])

  React.useEffect(() => {
    const types = ['PROMOCODE', 'COUPON']
    const customerModifiers = selectedOrder.modifiers.filter(modifier => {
      return types.includes(modifier.type) && !modifier.deletedAt
    })
    if (customerModifiers.length) {
      dispatch(actions.app.showAlert({
        title: i18n.t('app.page.order.stash.alert.removePromocode.title'),
        message: i18n.t('app.page.order.stash.alert.removePromocode.msg'),
        buttons: [
          {
            children: i18n.t('app.page.order.stash.alert.removePromocode.button.continue'),
            onPress: () => {
              dispatch(actions.orderHistory.deleteCustomerPromoCodes(selectedOrder.id))
            },
          },
          {
            children: i18n.t('app.common.back'),
            onPress: () => {
              dispatch(actions.orderHistory.selectOrder(selectedOrder.id))
              history.push('/orderHistory')
            },
            backgroundColor: colors.light,
            textColor: colors.textTertiary,
          },
        ],
      }))
    }
  }, [selectedOrder])

  const handleKeyboardInput = (value) => {
    // 原 amount
    let amount = String(checkoutPayment[selectedField])
    if (value === 'C') {
      // 重設為 '0'
      dispatch(actions.orderCheckout.updatePayment({
        paymentTips: 0,
        paidAmount: 0,
        change: 0,
      }))
      setBlock(draft => {
        draft[selectedField] = false
      })
      return
    }
    if (block[selectedField]) return
    if (value === '.' && amount.includes('.')) {
      // 已有小數點，不再處理小數點
      return
    }

    if (checkoutPayment?.autoInput) {
      dispatch(actions.orderCheckout.updatePaymentField('autoInput', false))
      if (value !== '.') {
        dispatch(actions.orderCheckout.updatePaymentField('paidAmount', String(value)))

        setBlock(draft => {
          draft[selectedField] = false
        })
        if (Number(value) > remainTotal) {
          const changeOrTips = Number(value) - remainTotal
          handleChangeOrTips(changeOrTips)
        }
        return
      }
    }
    // 根據原本是不是 0 來判斷要蓋掉還是接上去
    if (amount === '0' && value !== '.') {
      amount = String(value)
    } else {
      amount += String(value)
    }
    // 送出修改
    dispatch(actions.orderCheckout.updatePaymentField('paidAmount', String(amount)))
    if (Number(amount) > remainTotal) {
      const changeOrTips = Number(amount) - remainTotal
      handleChangeOrTips(changeOrTips)
    }
  }

  const handleAmountInput = (amount) => {
    // 現金以外或自定義開了小費功能 將多出的放到小費
    if (checkoutPayment?.autoInput) {
      dispatch(actions.orderCheckout.updatePayment({
        autoInput: false,
        paidAmount: String(amount),
      }))

      if (Number(amount) > remainTotal) {
        const changeOrTips = Number(amount) - remainTotal
        handleChangeOrTips(changeOrTips)
      }
      return
    }
    const newAmount = String(Number(checkoutPayment[selectedField]) + amount)
    dispatch(actions.orderCheckout.updatePaymentField('paidAmount', Number(newAmount)))

    setBlock(draft => {
      draft[selectedField] = true
    })
    if (Number(newAmount) > remainTotal) {
      const changeOrTips = Number(newAmount) - remainTotal
      handleChangeOrTips(changeOrTips)
    }
  }

  const handleChangeOrTips = (changeOrTips) => {
    if ((checkoutPayment.paymentMethod !== 'cash' && !paymentMethod.custom) || (paymentMethod.custom && paymentMethod?.tips === true)) {
      // 現金以外或自定義開了小費功能 將多出的放到小費
      dispatch(actions.orderCheckout.updatePayment({
        paymentTips: changeOrTips,
        change: 0,
      }))
    } else {
      // 其他的放到找績
      dispatch(actions.orderCheckout.updatePayment({
        paymentTips: 0,
        change: changeOrTips,
      }))
    }
  }

  return (
    <Column style={styles.container}>
      <Header />
      <Column style={styles.content}>
        <Column style={styles.contentColumn}>
          <AmountPanel
            isCardPayment={isEcrPayment}
            selectedField={selectedField}
            setSelectedField={(field) => {
              setSelectedField(field)
              // 更改 selectedField 表示原本的輸入結束了，先轉回 Number
              dispatch(actions.orderCheckout.convertPaymentFieldToNumber())
            }}
          />
          <MethodPanel />
        </Column>
        <Row style={styles.contentRow}>
          <View
            style={styles.numbers} onLayout={event => {
              setPositionTop(event.nativeEvent.layout.top)
            }}
          >
            <NumberPad
              handleKeyboardInput={handleKeyboardInput}
              handleAmountInput={handleAmountInput}
              disabled={countPlaces(checkoutPayment?.paidAmount) >= 1 || block[selectedField]}
            />
            {/* <ReceiptPanel /> */}
          </View>
          <NotesPanel />
        </Row>
      </Column>
      <ActionPanel style={[styles.actions, { top: positionTop }]} />
      {/* {positionTop !== 0 && <ActionPanel style={[styles.actions, { top: positionTop }]} />} */}
      <PaymentHistoryDialog
        orderStatus={selectedOrder.status}
        payments={selectedOrder.payments}
        open={visible}
        onClose={() => dispatch(actions.app.closeDialog(['checkout', 'history']))}
        showTipsButton={false}
      />
      <PopUpStatus success />
      <PopUpStatus success={false} />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: 518,
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 16,
  },
  contentColumn: {
    width: '100%',
    margin: 16,
  },
  contentRow: {
    height: 406,
    width: '100%',
    justifyContent: 'flex-end',
    margin: 16,
  },
  numbers: {
    height: '100%',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    position: 'absolute',
    left: 0,
  },

})
