import { FlatList, ScrollView, StyleSheet, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightText from '@/components/Setting/SettingListItemRightText'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import colors from '@/theme/colors'

import PresetOptionItem from './PresetOptionItem'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionGroup } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 * @typedef OptionGroupPresetConfigProps
 * @property {IMenuOptionGroup} optionGroup
 * @property {string} locale
 * @property {() => void} onClose
 * @property {(submittedOptionGroup: IMenuOptionGroup) => void} onSubmit
 * @property {() => void} onRemove
 */

/**
 *
 * @param {OptionGroupPresetConfigProps} props
 * @returns
 */
export default function OptionGroupPresetConfig (props) {
  const { optionGroup, locale, onClose, onSubmit, onRemove } = props
  const { t } = useTranslation()
  const OptionListRef = React.useRef()

  const [editingOptionGroup, updateEditingOptionGroup] = useImmer(optionGroup)

  if (!optionGroup) return null

  const handleSave = () => {
    onSubmit(editingOptionGroup)
    onClose()
  }

  const renderItem = ({ item: option, index }) => {
    return (
      <PresetOptionItem
        option={option}
        locale={locale}
        divider={index !== optionGroup.options.length - 1}
        onChangeHidden={(hidden) => {
          updateEditingOptionGroup(draft => {
            draft.options[index].hidden = hidden
          })
        }}
      />
    )
  }

  return (
    <View style={styles.container}>
      <Header>
        <HeaderText text={t('app.page.setting.menuPanel.options.optionGroup.setting')} />
      </Header>

      <SettingList>
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.name')} />
          <SettingListItemRightText text={_.get(editingOptionGroup, `localeNames.${locale}`, editingOptionGroup.name)} />
        </SettingListItem>

        {editingOptionGroup.min > 0 && (
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.mustMin')} />
            <SettingListItemRightText text={String(editingOptionGroup.min)} />
          </SettingListItem>
        )}
        <SettingListItem>
          <SettingListItemText text={t('app.page.setting.menuPanel.options.optionGroup.multipleLimit')} />
          <SettingListItemRightText text={editingOptionGroup.multiple ? String(editingOptionGroup.max) : t('app.page.setting.menuPanel.options.optionGroup.single')} />
        </SettingListItem>
      </SettingList>

      <Header>
        <HeaderText text={t('app.page.setting.menuPanel.options.option.item')} />
      </Header>

      <ScrollView style={{ flex: 1 }}>
        <SettingList>
          <FlatList
            ref={OptionListRef}
            style={styles.flatList}
            containerStyle={{ flex: 1 }}
            data={editingOptionGroup.options}
            keyExtractor={(item, index) => index.toString()}
            renderItem={renderItem}
          />
        </SettingList>
      </ScrollView>

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.remove')}
          style={styles.button}
          backgroundColor={colors.primary}
          onPress={onRemove}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
  },
  flatList: {
    flex: 1,
    height: '100%',
  },
})
