import { BarCodeScanner } from 'expo-barcode-scanner'
import { Dimensions, StyleSheet, View } from 'react-native'
import { URLSearchParams } from 'react-native-url-polyfill'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import { getHistoryOrder } from '@/libs/orderHistory'
import Column from '@/components/Column'
import OpacityButton from '@/components/buttons/OpacityButton'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import logger from '@/libs/logger'

function ScanQRCode () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const [hasPermission, setHasPermission] = useState(null)
  const scannerCameraDirection = useSelector(state => state.app.settings?.scannerCameraDirection)
  const [scanned, setScanned] = useState(false)
  const payFirst = useSelector(state => state.merchant.data?.setting?.payFirst)
  const merchantId = useSelector(state => state.merchant.data.id)

  const { width, height } = Dimensions.get('screen')

  // avoid too many switch camera dispatch
  const throttleCameraDirection = _.throttle(
    (direction) => dispatch(actions.app.updateSetting(['scannerCameraDirection'], direction)),
    1500,
    { trailing: false },
  )

  const askBarCodeScannerPermissions = async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync()
    setHasPermission(status === 'granted')
  }

  useEffect(() => {
    // get permission of the camera first
    askBarCodeScannerPermissions()
  }, [])

  const handleBarCodeScanned = async ({ data }) => {
    setScanned(true)
    let url = data

    // check if the url is valid
    if (!url.includes('dimorder.com') && !url.includes('dimorder.page.link') && !url.includes('dimorder0.page.link')) {
      dispatch(actions.app.showAlert({
        title: t('app.page.QRCode.invalidQRCode.title'),
        message: t('app.page.QRCode.invalidQRCode.message'),
        button: {
          children: t('app.common.confirm'),
          onPress: () => {
            setScanned(false)
          },
        },
      }))

      return
    }

    // parse short url
    if (url.includes('s.dimorder')) {
      try {
        url = await dimorderApi.parseShortUrl(data)
      } catch (e) {
        logger.error('parse short url error', { e })
      }
    }

    const queryString = url.split('?')[1]
    const searchParams = new URLSearchParams(queryString)
    const urlMerchantId = searchParams.get('m')
    const urlOrderId = searchParams.get('o')

    if (merchantId !== urlMerchantId) {
      dispatch(actions.app.showAlert({
        title: t('app.page.QRCode.invalidQRCode.title'),
        message: t('app.page.QRCode.invalidQRCode.message'),
        button: {
          children: t('app.common.confirm'),
          onPress: () => {
            setScanned(false)
          },
        },
      }))

      return
    }

    const checkoutOrder = getHistoryOrder(urlOrderId)

    if (!checkoutOrder) {
      dispatch(actions.app.showAlert({
        title: t('app.page.QRCode.orderNotFound.title'),
        message: t('app.page.QRCode.orderNotFound.message'),
        button: {
          children: t('app.common.confirm'),
          onPress: () => {
            setScanned(false)
          },
        },
      }))
      return
    }

    // ready to checkout
    if (checkoutOrder?.displayStatusKey === 'waiting_pay' && checkoutOrder?.deliveryType === 'table' && !payFirst) {
      dispatch(actions.orderCheckout.checkoutOrder(urlOrderId))
    } else {
      dispatch(actions.orderHistory.selectOrder(urlOrderId))
      history.push('/orderHistory')
    }
  }

  useEffect(() => {
    if (hasPermission === false) {
      dispatch(actions.app.showAlert({
        title: t('app.page.QRCode.permissionDenied.title'),
        message: t('app.page.QRCode.permissionDenied.message'),
        button: {
          children: t('app.common.back'),
          onPress: () => {
            history.replace('/orderHistory')
          },
        },
      }))
    }
  }, [hasPermission])

  // 讀取QR Scanner外框與半透明layout 以及返回和轉換前後鏡的button
  const CameraLayout = () => {
    return (
      <View style={styles.overlay}>
        {/* background opactiy layout with different button */}
        {/* 半透明layout */}
        <View style={[styles.greyBox, { top: 0, left: 0, width: width - width / 2 - height * 0.35, height: height, bottom: 0 }]}>
          {/* 返回button */}
          <OpacityButton
            onPress={() => history.goBack()}
            customStyles={{
              container: { left: 25 },
              image: {
                left: -1,
                top: -1,
              },
            }}
            imageSource={require('@icons/setting/back.png')}
          />
        </View>
        <View style={[styles.greyBox, { top: 0, left: width - width / 2 - height * 0.35, height: height - height / 2 - height * 0.35, width: width - (width - width / 2 - height * 0.35) * 2 }]} />
        <View style={[styles.greyBox, { bottom: 0, right: width - width / 2 - height * 0.35, height: height - height / 2 - height * 0.35, width: width - (width - width / 2 - height * 0.35) * 2 }]} />
        <View style={[styles.greyBox, { top: 0, right: 0, width: width - width / 2 - height * 0.35, height: height, bottom: 0 }]}>
          {/* 轉換前後鏡的button */}
          <OpacityButton
            onPress={() => {
              if (scannerCameraDirection === BarCodeScanner.Constants.Type.back) {
                throttleCameraDirection(BarCodeScanner.Constants.Type.front)
              } else {
                throttleCameraDirection(BarCodeScanner.Constants.Type.back)
              }
            }}
            customStyles={{
              container: {
                marginLeft: 'auto',
                right: 25,
              },
            }}
            imageSource={require('@icons/revert.png')}
          />
        </View>

        {/* Scanner border layout */}
        {/* 讀取QR Scanner外框 */}
        <View style={{ width: height * 0.7, height: height * 0.7 }}>
          <View style={{ position: 'absolute', height: height * 0.7 * 0.15, width: height * 0.7 * 0.15, top: -5, left: -5, borderColor: colors.white, borderTopWidth: 10, borderLeftWidth: 10, borderTopLeftRadius: 10 }} />
          <View style={{ position: 'absolute', height: height * 0.7 * 0.15, width: height * 0.7 * 0.15, top: -5, right: -5, borderColor: colors.white, borderTopWidth: 10, borderRightWidth: 10, borderTopRightRadius: 10 }} />
          <View style={{ position: 'absolute', height: height * 0.7 * 0.15, width: height * 0.7 * 0.15, bottom: -5, left: -5, borderColor: colors.white, borderBottomWidth: 10, borderLeftWidth: 10, borderBottomLeftRadius: 10 }} />
          <View style={{ position: 'absolute', height: height * 0.7 * 0.15, width: height * 0.7 * 0.15, bottom: -5, right: -5, borderColor: colors.white, borderBottomWidth: 10, borderRightWidth: 10, borderBottomRightRadius: 10 }} />
        </View>
      </View>
    )
  }

  return (
    <Row style={styles.container}>
      <Column style={styles.contentContainer}>
        {hasPermission ? (
          <BarCodeScanner
            barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
            onBarCodeScanned={scanned ? null : handleBarCodeScanned}
            type={scannerCameraDirection}
            style={StyleSheet.absoluteFillObject}
          />)
          : (<View style={[StyleSheet.absoluteFillObject, { backgroundColor: colors.black }]} />)}
        <CameraLayout />
      </Column>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
  },
  overlay: {
    flex: 1,
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  greyBox: {
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    position: 'absolute',
  },
})

export default ScanQRCode
