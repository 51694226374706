import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '@/theme/colors'

ActionButton.propTypes = {
  style: PropTypes.any,
  onlyText: PropTypes.string,
  iconSource: PropTypes.any,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
}

export default function ActionButton (props) {
  const {
    style,
    onlyText,
    iconSource,
    color = colors.textSecondary,
    backgroundColor = colors.white,
    disabled,
    onPress,
  } = props
  return (
    <TouchableOpacity disabled={disabled} onPress={onPress}>
      <View style={[styles.container, disabled && styles.disabled, style, { backgroundColor }]}>
        {iconSource && (
          <Image style={styles.icon} source={iconSource} />
        )}
        {onlyText && (
          <Text style={[styles.text, { color }]}>
            {onlyText}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 96,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginHorizontal: 5,
    marginVertical: 5,
  },
  disabled: {
    opacity: 0.4,
  },
  icon: {
    width: 38,
    height: 38,
  },
  text: {
    fontSize: 15,
    fontWeight: 'bold',
  },
})
