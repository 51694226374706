/* global __DEV__ */
import { Platform } from 'react-native'

const PACKAGE_JSON = require('@root/package.json')
const IS_APP = Platform.OS !== 'web'

export function getCommit (codePushPackageMeta) {
  let commit = process.env.REACT_APP_SHORT_SHA
  if (!commit && codePushPackageMeta?.description) {
    const commitAndTag = codePushPackageMeta.description.split(' | ')
    commit = commitAndTag[0]
  }
  return commit
}

export function getCodePushVersion (codePushPackageMeta) {
  const commit = getCommit(codePushPackageMeta)

  // 非 Prod 時版本號沒有作用，在開發時可能一直 build 都是同一個版本號，因此改顯示 commit
  const isProd = codePushPackageMeta?.deployment?.startsWith('Prod')
  const packageVersion = isProd ? PACKAGE_JSON.version : commit

  // App 剛裝好還沒 CodePush 會顯示 Native
  // 本機跑 debug 會顯示 __DEV__
  // 被 Codepush 更新後會顯示 'Prod-Stable v17'
  const codePushVersion = codePushPackageMeta
    ? `${codePushPackageMeta.deployment} ${codePushPackageMeta.label}`
    : (__DEV__ ? '__DEV__' : 'Native')

  const displayPackage = (IS_APP ? codePushVersion : 'Web') + `\n(${packageVersion})`
  return displayPackage
}
