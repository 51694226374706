import { Image, StyleSheet, Text } from 'react-native'
import { useSelector } from '@/redux'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'

/**
 * @typedef IconLabelProps
 * @property {string} text
 * @property {string} iconSource
 * @property {ViewProps['style']?} style
 * @param {IconLabelProps} props
 * @returns
 */
export default function IconLabel (props) {
  const { text, iconSource, style } = props
  const { enableLargerTable, unstackSubTables, enableFloorPlan } = useSelector(state => state.merchant.data?.setting) ?? {}
  const size = enableLargerTable || (unstackSubTables && !enableFloorPlan) ? 'lg' : 'md'

  return (
    <Row style={style}>
      <Image style={size === 'lg' ? styles.iconLarge : styles.icon} source={iconSource} />
      <Text style={size === 'lg' ? styles.textLarge : styles.text}>{!text || text === '' ? '-' : text}</Text>
    </Row>
  )
}

const styles = StyleSheet.create({
  icon: {
    height: 15,
    width: 15,
    marginRight: 3,
  },
  text: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.textPrimary,
  },
  iconLarge: {
    height: 18,
    width: 18,
    marginRight: 3,
  },
  textLarge: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.textPrimary,
  },
})
