import { StyleSheet, Text, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import { useHistory, useParams } from '@/libs/reactRouter'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import BackNavigator from '@/components/Setting/BackNavigator'
import BatchLocaleSelectButton from '@/components/buttons/BatchLocaleSelectButton'
import Button from '@/components/buttons/Button'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'

import EditMenuOptions from './EditMenuOptions'
import MenuImage from './MenuImage'
import MenuInfo from './MenuInfo'
import MenuOptions from './MenuOptions'
import MenuPrice from './MenuPrice'
import MenuPrinter from './MenuPrinter'
import MenuTags from './MenuTags'

// eslint-disable-next-line no-unused-vars
import { IMenu } from 'dimorder-orderapp-lib/dist/types/Menu'

const CONTENTS = {
  Info: 'INFO',
  Price: 'PRICE',
  Options: 'OPTIONS',
  Printer: 'PRINTER',
  Translations: 'TRANSLATIONS',
  TAGS: 'TAGS',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function EditMenu (props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { menuId, categoryId } = useParams() // menuId為已經存在之餐點，categoryId 為新增餐點

  const lang = String(i18n.language ?? 'zh').split('-')[0]

  /** @type {IMenu} */
  const defaultMenu = {
    name: '',
    shortName: '',
    code: '',
    desc: '',
    categoryId: '',
    price: 0,
    discount: 0,
    options: [],
    optionGroupPresets: [],
    timerange: [],
    promotedTimerange: [],
    priceUndetermined: false,
    weekdays: [],
    promotedDays: [],
    locale: lang,
    prices: [],
    promoted: false,
    localeNames: {},
    localeDesc: {},
    tags: [],
  }

  const menus = useSelector(state => state.menu.menus)
  const menu = _.find(menus, (menu) => menu.id === menuId)

  const categories = useSelector(state => state.menu.categories)
  const category = _.find(categories, category => category.id === menu?.categoryId || category.id === categoryId)
  const printerSetting = useSelector(state => state.printer.printerSetting)
  const [isEditingOptions, setEditingOptions] = React.useState(false)
  const [activeContent, setActiveContent] = React.useState(CONTENTS.Info)
  const [editingMenu, updateEditingMenu] = useImmer(menu)
  const kitchenSettings = _.get(printerSetting, 'kitchenReceiptSettings', [])
  const [kitchenSettingIds, setKitchenSettingIds] = React.useState([])

  // * useEffect
  // 有 categoryId 時為建立餐單，使用 defaultMenu 和 categoryId 組合成 editingMenu
  React.useEffect(() => {
    if (categoryId) {
      updateEditingMenu({ ...defaultMenu, categoryId })
    }
  }, [categoryId])

  React.useEffect(() => {
    if (menuId) {
      const defaultKitchenSettings = kitchenSettings.filter(setting => setting?.menu?.includes(menuId)).map(setting => setting.id)
      setKitchenSettingIds(defaultKitchenSettings)
    }
  }, [menuId])

  // 檢查找不到 menu 的情況
  React.useEffect(() => {
    let timeout
    if (menuId && !menu) {
      // 在 menus 裡找不到 menuId 2 秒後返回
      timeout = setTimeout(() => history.goBack(), 2000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [menuId, menu])

  // 當餐單語言改變時，取得其他語言的內容來修改

  // * callback

  const handleMenuUpdate = (path, value, trim = false) => {
    if (typeof value === 'string') {
      if (trim) {
        value = replaceSaveSpace(value.trim())
      } else {
        value = replaceSpace(value)
      }
    }
    updateEditingMenu(draft => {
      _.set(draft, path, value)
    })
  }

  const handleOptionsDragEnd = (event) => {
    const { data } = event
    // data 為排序後的 optiongGroup / optionGroupPerset array
    updateEditingMenu(draft => {
      data.forEach((optionGroup, index) => {
        // 將每個 optiongGroup / optionGroupPerset 找出來 update weight
        const optionGroupDraft =
          draft.optionGroupPresets
            ?.find(preset => preset.id === optionGroup.id) ||
          draft.options
            ?.find(preset => preset.id === optionGroup.id)
        if (optionGroupDraft) {
          optionGroupDraft.weight = index
        }
      })
    })
  }

  const updatePrinterSetting = (printerSettingIds) => {
    const updatedPrinterSetting = { ...printerSetting }
    updatedPrinterSetting.kitchenReceiptSettings = updatedPrinterSetting.kitchenReceiptSettings.map((setting) => {
      const menus = _.get(setting, 'menu') ? Object.keys(setting.menu).map((key) => setting.menu[key]) : []
      if (printerSettingIds?.includes(setting.id)) {
        menus.push(menuId)
        return {
          ...setting,
          menu: _.uniq(menus),
        }
      } else {
        _.remove(menus, (menu) => menu === menuId)
        return {
          ...setting,
          menu: menus,
        }
      }
    })
    dispatch(actions.printer.updatePrinterSetting({ printerSetting: updatedPrinterSetting }))
  }

  const handleSave = () => {
    const localeNames = _.get(editingMenu, 'localeNames', {})
    // guards
    if (editingMenu.name.trim().length === 0 || (!_.isEmpty(localeNames) && _.every(_.values(localeNames), name => !name?.trim()))) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.name')))
      return
    }
    if (isNaN(editingMenu.price) || isNaN(editingMenu.discount)) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.price')))
      return
    }

    if (editingMenu.timerange?.length > 0) {
      if (!_.inRange(Number(editingMenu.timerange[0].hour), 24) || !_.inRange(Number(editingMenu.timerange[1].hour), 24)) {
        dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.hour')))
        return
      }
      if (!_.inRange(Number(editingMenu.timerange[0].minute), 60) || !_.inRange(Number(editingMenu.timerange[1].minute), 60)) {
        dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.min')))
        return
      }
    }
    if (editingMenu?.promotedTimerange?.length > 0) {
      if (!_.inRange(Number(editingMenu?.promotedTimerange[0].hour), 24) || !_.inRange(Number(editingMenu?.promotedTimerange[1].hour), 24)) {
        dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.hour')))
        return
      }
      if (!_.inRange(Number(editingMenu?.promotedTimerange[0].minute), 60) || !_.inRange(Number(editingMenu?.promotedTimerange[1].minute), 60)) {
        dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.min')))
        return
      }
    }

    history.goBack()

    if (editingMenu.id) {
      const updatedLocaleNames = _.mapValues(localeNames, (name) => {
        return name?.trim() || menu.name.trim()
      })
      dispatch(actions.menu.updateMenu({
        ...editingMenu,
        name: updatedLocaleNames[lang],
        localeNames: updatedLocaleNames,
      }))
      updatePrinterSetting(kitchenSettingIds)
    } else {
      dispatch(actions.menu.createMenu({
        ...editingMenu,
        localeNames: {
          [editingMenu.locale]: editingMenu.name,
        },
      }))
    }
  }

  // * render

  // 在 menus 裡找不到 menuId
  if (menuId && !menu) {
    return (
      <View style={styles.centerContainer}>
        <Text style={styles.centerMessage}>{t('app.page.setting.menuPanel.menuInfo.notFound')}</Text>
      </View>
    )
  }

  if (!editingMenu) {
    // 還沒抓到 localeMenu
    return (
      <View style={styles.centerContainer}>
        <Text style={styles.centerMessage}>{t('app.page.setting.menuPanel.menuInfo.loading')}</Text>
      </View>
    )
  }

  if (isEditingOptions) {
    return (
      <EditMenuOptions
        editingMenu={editingMenu}
        updateEditingMenu={updateEditingMenu}
        onDragEnd={handleOptionsDragEnd}
        onClose={() => setEditingOptions(false)}
      />
    )
  }

  const renderContent = () => {
    switch (activeContent) {
      case CONTENTS.Info:
        return (
          <MenuInfo
            category={category}
            editingMenu={editingMenu}
            onUpdate={handleMenuUpdate}
          />
        )
      case CONTENTS.Price:
        return (
          <MenuPrice
            editingMenu={editingMenu}
            onUpdate={handleMenuUpdate}
          />
        )
      case CONTENTS.Options:
        return (
          <MenuOptions
            editingMenu={editingMenu}
            onEditOptions={() => setEditingOptions(true)}
            onDragEnd={handleOptionsDragEnd}
          />
        )
      case CONTENTS.TAGS:
        return (
          <MenuTags
            editingMenu={editingMenu}
            onUpdate={handleMenuUpdate}
          />
        )
      case CONTENTS.Printer:
        return (
          <MenuPrinter
            defaultValue={kitchenSettingIds}
            onChangeValue={(value) => setKitchenSettingIds(value)}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <View style={styles.container}>
        <BackNavigator
          text={editingMenu.id ? t('app.page.setting.menuPanel.menuInfo.edit') : t('app.page.setting.menuPanel.menuInfo.add')}
          onPress={() => { history.goBack() }}
        />
        <View style={styles.contentContainer}>

          {/* Left */}
          <View style={styles.leftContent}>
            <MenuImage editingMenu={editingMenu}>
              <BatchLocaleSelectButton
                selectedLocale={editingMenu?.locale}
                onSelect={locale => handleMenuUpdate('locale', locale)}
              />
            </MenuImage>

            <SettingList>
              <SettingListEnterRow
                divider
                label={t('app.page.setting.menuPanel.menuInfo.info')}
                selected={activeContent === CONTENTS.Info}
                onPress={() => setActiveContent(CONTENTS.Info)}
              />
              <SettingListEnterRow
                divider
                label={t('app.page.setting.menuPanel.menuInfo.price')}
                selected={activeContent === CONTENTS.Price}
                onPress={() => setActiveContent(CONTENTS.Price)}
              />
              <SettingListEnterRow
                divider={Boolean(editingMenu?.id)}
                label={t('app.page.setting.menuPanel.menuInfo.option')}
                selected={activeContent === CONTENTS.Options}
                onPress={() => setActiveContent(CONTENTS.Options)}
              />
              <SettingListEnterRow
                divider
                label={t('app.page.setting.menuPanel.menuInfo.tags')}
                selected={activeContent === CONTENTS.TAGS}
                onPress={() => setActiveContent(CONTENTS.TAGS)}
              />
              <SettingListEnterRow
                label={t('app.page.setting.menuPanel.menuInfo.print')}
                selected={activeContent === CONTENTS.Printer}
                onPress={() => setActiveContent(CONTENTS.Printer)}
              />
            </SettingList>
          </View>

          {/* Right */}
          <View style={styles.rightContent}>
            {renderContent()}
          </View>
        </View>

        <View style={styles.buttonContainer}>
          <Button
            textBold
            title={t('app.common.delete')}
            backgroundColor={colors.primary}
            disabled={!editingMenu.id}
            onPress={() => {
              dispatch(actions.app.showAlert({
                title: t('app.page.setting.menuPanel.menuInfo.alert.delete.title'),
                message: `${t('app.page.setting.menuPanel.menuInfo.alert.delete.msg')}${editingMenu.name}?`,
                buttons: [
                  {
                    children: t('app.common.delete'),
                    onPress: () => {
                      history.goBack()
                      dispatch(actions.menu.deleteMenu(editingMenu))
                    },
                  },
                  {
                    backgroundColor: colors.light,
                    textColor: colors.textTertiary,
                    children: t('app.common.cancel'),
                    onPress: () => { },
                  },
                ],
              }))
            }}
          />
          <Button
            textBold
            title={t('app.common.save')}
            backgroundColor={colors.darkSecondary}
            onPress={handleSave}
          />
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  leftContent: {
    flex: 2,
    marginHorizontal: 10,
  },
  rightContent: {
    flex: 3,
    marginHorizontal: 10,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
