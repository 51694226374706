import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import i18n from '@/i18n'

import BatchLocaleSelectButton from '@/components/buttons/BatchLocaleSelectButton'
import ChipLabel from '@/components/ChipLabel'
import Header from '@/components/Setting/Header'
import HeaderRightAction from '@/components/Setting/HeaderRightAction'
import HeaderText from '@/components/Setting/HeaderText'
import ItemList from '@/components/ItemList'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'

import OptionInfo from './OptionInfo'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OptionPanel (props) {
  const optionGroupPresets = useSelector(state => state.menu.optionGroupPresets)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [localeOptionGroupPresets, setLocaleOptionGroupPresets] = React.useState(null)
  const [selectedOptionGroupPreset, setSelectedOptionGroupPreset] = React.useState(null)
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const [locale, setLocale] = React.useState(lang)
  const [listPanelConfig, setListPanelConfig] = React.useState(null)
  const menus = useSelector(state => state.menu.menus)

  const getLocaleOptionGroupPresets = async () => {
    const localeOptionGroupPresets = optionGroupPresets.map(optionGroupPreset => {
      const options = optionGroupPreset.options.map(option => {
        return { ...option, name: _.get(option?.localeNames, locale) ?? option?.name }
      })
      return { ...optionGroupPreset, options, name: _.get(optionGroupPreset?.localeNames, locale) ?? optionGroupPreset?.name }
    })
    localeOptionGroupPresets.forEach(preset => {
      preset.locale = locale
    })
    setLocaleOptionGroupPresets(optionGroupPresets)

    if (selectedOptionGroupPreset) {
      if (selectedOptionGroupPreset?.id) {
        // 編輯中的 preset 有 id，會拿回另一個語言的 preset

        // 將選擇中的 preset 特定語言版本找出來
        const localeOptionGroupPreset = localeOptionGroupPresets.find(preset => preset.id === selectedOptionGroupPreset?.id)
        // 將抓回來的 preset 更新到 state
        setSelectedOptionGroupPreset(localeOptionGroupPreset)
      } else {
        // 新建中，不會有 id，也不會拿回 preset，直接 update selectedOptionGroupPreset.locale
        setSelectedOptionGroupPreset({
          ...selectedOptionGroupPreset,
          locale,
        })
      }
    }
  }

  React.useEffect(() => {
    getLocaleOptionGroupPresets()
  }, [locale, optionGroupPresets])

  if (!localeOptionGroupPresets) {
    return null
  }

  if (listPanelConfig) {
    const selectedMenus = (Object.values(menus) ?? []).filter(menu => !menu.path?.includes('SET_ROOT'))
      .filter(menu => menu?.optionGroupPresets?.find(option => option.id === selectedOptionGroupPreset?.id))
    const menuIds = _.flatMap(selectedMenus, 'id')
    return (
      <ItemList
        onClose={(value) => {
          setListPanelConfig(null)
          dispatch(actions.menu.setOptionGroupPreset(selectedOptionGroupPreset, value, menuIds))
        }}
        menu={menuIds ?? []}
      />
    )
  }

  const renderItem = ({ item, index }) => {
    return (
      <SettingListItem
        key={item.id}
        onPress={() => {
          setSelectedOptionGroupPreset(item)
        }}
        divider={index !== localeOptionGroupPresets.length - 1}
      >
        <SettingListItemText text={_.get(item?.localeNames, locale) ?? item.name} />
        <ChipLabel label={t('app.page.setting.menuPanel.options.optionGroup.must')} active={item.min > 0} />
        <ChipLabel label={t('app.page.setting.menuPanel.options.optionGroup.multiple')} active={item.multiple} />
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.menuPanel.options.setting')} />
          <HeaderRightAction>
            <BatchLocaleSelectButton
              selectedLocale={locale}
              onSelect={locale => setLocale(locale)}
            />
          </HeaderRightAction>
        </Header>
        <View style={styles.content}>
          <SettingList>
            <FlatList
              data={localeOptionGroupPresets}
              renderItem={renderItem}
              keyExtractor={(item, index) => String(item.id + index)}
              showsVerticalScrollIndicator={false}
              ListEmptyComponent={(
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.menuPanel.options.option.empty')}</Text>
                </View>
              )}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.menuPanel.options.optionGroup.add')}
              onPress={() => {
                setSelectedOptionGroupPreset({
                  id: null,
                  locale,
                  name: '',
                  options: [],
                  multiple: false,
                  min: 0,
                  max: 0,
                  localeNames: {},
                })
              }}
            />
          </SettingList>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <OptionInfo
          locale={locale}
          key={selectedOptionGroupPreset?.id + selectedOptionGroupPreset?.locale}
          optionGroup={selectedOptionGroupPreset}
          onClose={() => setSelectedOptionGroupPreset(null)}
          onSelectItem={(value) => setListPanelConfig(value)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
