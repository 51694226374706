import { BatchFlatListContext } from '@/libs/BatchFlatList/BatchFlatListContext'
import { FlatList, StyleSheet, Text } from 'react-native'
import { actions, useDispatch } from '@/redux'
import { usePrevious } from '@/hooks/previous'
import { useSelectedBatch, useSelectedBatchItem, useSelectedOrder } from '@/hooks/order'
import BatchItem from './BatchItem'
import Column from '@/components/Column'
import React from 'react'
import Row from '@/components/Row'
import _ from 'lodash'
import colors from '@/theme/colors'
import moment from 'moment'

// eslint-disable-next-line no-unused-vars
import { IAppBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'

export default function BatchList (props) {
  const dispatch = useDispatch()
  const [flatListRef, setFlatListRef] = React.useState(null)
  const selectedOrder = useSelectedOrder()
  const selectedBatch = useSelectedBatch()
  const batches = _.uniqBy([...selectedOrder.batches, selectedBatch], 'batchId')
  const previousBatch = usePrevious(selectedBatch)
  const [selectedItem, selectedSetItem] = useSelectedBatchItem()

  const items = _.flatMap(batches, batch => batch.items)
  let usedSerial = 0
  const itemSerials = items.map((item, index) => {
    const itemSerial = usedSerial + 1
    usedSerial++
    return { id: item.id ?? item.key, itemSerial }
  })

  // BatchItem 的 isSetItemsExpaned 預設為 true，所以在這邊不設定也會打開 setItems
  const [expandedSetItems, setExpandedSetItems] = React.useState({})

  /**
   * @param {{item: IAppBatchItem, index: number}} info
   * @returns {React.ReactElement}
   */
  const renderItem = ({ item, index }) => {
    const isSelected = !selectedSetItem && item.key === selectedItem?.key
    const isSetItemsExpaned = expandedSetItems[item.key]
    return (
      <BatchItem
        serial={itemSerials.find(itemSerial => itemSerial.id === item.id || itemSerial.id === item.key).itemSerial}
        item={item}
        selected={!selectedSetItem && item.key === selectedItem?.key}
        onPress={() => {
          if (item.isSet && isSelected) {
            // 選擇中的套餐，按下會 toggle 開關
            setExpandedSetItems({ ...expandedSetItems, [item.key]: !isSetItemsExpaned })
          }
          if (item.isSet && !isSelected) {
            // 沒選擇中的開關，按下直接打開
            setExpandedSetItems({ ...expandedSetItems, [item.key]: true })
          }
          dispatch(actions.orderBatch.selectItem(item.key))
        }}
        onDelete={() => {
          dispatch(actions.orderBatch.deleteItem(item.key))
        }}
        isSetItemsExpaned={isSetItemsExpaned}
        disabled={item.batchStatus === 'submitted'}
      />
    )
  }

  const scrollToLatestBatchItem = () => {
    // scrollToEnd() is not working because it is triggered before the change is made to the FlatList.
    // Since it inherits from ScrollView the best way here is to call scrollToEnd() in onContentSizeChange.
    // 只有在 items 增加的情況捲動置底
    if (selectedBatch.items.length >= previousBatch.items.length) {
      flatListRef.scrollToEnd()
      setTimeout(() => flatListRef.scrollToEnd(), 200)
    }
  }

  const renderBatch = ({ item: batch, index }) => {
    const items = batch.items.map(Item => { return { ...Item, batchStatus: batch.status } })
    return (
      <Column>
        <Row style={styles.batchHeader}>
          <Text style={[styles.batchText, { width: 40 }]}>
            ＃{batch.index + 1}
          </Text>
          <Text style={styles.batchText}>
            {moment(batch.createdAt).format('HH:mm')}
          </Text>
        </Row>
        <FlatList
          data={items}
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => String(item.key + index)}
          onContentSizeChange={scrollToLatestBatchItem}
          style={{ width: '100%' }}
        />
      </Column>)
  }

  return (
    <BatchFlatListContext.Provider value={flatListRef}>
      <FlatList
        ref={setFlatListRef}
        data={batches}
        renderItem={renderBatch}
        keyExtractor={item => item.batchId}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={scrollToLatestBatchItem}
        style={{ width: '100%' }}
      />
    </BatchFlatListContext.Provider>
  )
}

const styles = StyleSheet.create({
  batchHeader: {
    height: 30,
    alignItems: 'center',
    backgroundColor: colors.lightGray3,
    paddingLeft: 15,
    marginBottom: 1,
  },
  batchText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
})
