import { Audio } from 'expo-av'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Constants from 'expo-constants'
import _ from 'lodash'

import { useRequestPayingOrders, useRequestWaiterOrders } from '@/hooks/orderHistory'
import { useSelector } from '@/redux'
import logger from '@/libs/logger'

/**
 * 發出訂單提示音效
 * 1. 未確認訂單
 * 2. 請求付款訂單
 * 3. 請求侍應訂單
 */
export default function useSoundNotifications () {
  const [sound, setSound] = useState()
  const isMerchantLogin = useSelector(state => state.auth.isMerchantLogin)
  const [requestPayingOrders, requestPayOrderSerials] = useRequestPayingOrders()
  const [requestWaiterOrders, requestWaiterOrderSerials] = useRequestWaiterOrders()
  const isMaster = useSelector(state => state.app.settings.isMaster)
  const isDataInit = useSelector(state => state.app.isDataInit)
  const merchant = useSelector(state => state.merchant)
  const { orders: historyOrders, unconfirmedOrders } = useSelector(state => state.orderHistory)
  const merchantId = merchant.data.id
  const deviceId = Constants.installationId

  // ? 播放次數的設定除了 -1 有處理以外其他的沒有處理？
  const replayTimesMap = {
    table: merchant.data?.setting?.notifyPlayCount,
    takeaway: merchant.data?.setting?.takeawayNotifyPlayCount,
    storeDelivery: merchant.data?.setting?.storeDeliveryNotifyPlayCount,
  }

  const notifyingOrders = useMemo(() => {
    // 應該響鈴的訂單(serials)
    return _
      .chain([unconfirmedOrders, requestPayOrderSerials, requestWaiterOrderSerials])
      .flatten()
      .uniq()
      .value()
  }, [unconfirmedOrders, requestPayOrderSerials, requestWaiterOrderSerials])

  const isLooping = useMemo(() => {
    if (historyOrders.length === 0) return false
    if (notifyingOrders.length === 0) return false

    // 檢查有沒有 deliveryType 設定為無限響鈴 (-1)
    const loopingSettings = _.pickBy(replayTimesMap, replayTimes => replayTimes === -1)
    const hasLoopingSettings = !_.isEmpty(loopingSettings)
    if (!hasLoopingSettings) return false

    // 檢查有沒有訂單符合無限響鈴的設定
    const hasLoopingOrder = _.some(historyOrders, order => {
      return _.some(notifyingOrders, serial => (
        order.serial === serial && // 有找到該筆訂單
        replayTimesMap[order.deliveryType] === -1 // 且該 deliveryType 有設定為無限響鈴
      ))
    })
    return hasLoopingOrder
  }, [historyOrders, notifyingOrders, replayTimesMap])

  const soundLogger = useCallback(
    /**
     * @param {string} message
     */
    (message) => {
      logger.log(
        message, {
          merchantId,
          deviceId,
          isMaster,
          isDataInit,
          isMerchantLogin,
          unconfirmedOrders,
          requestPayingOrders,
          requestWaiterOrders,
        },
      )
    },
    [
      merchantId,
      deviceId,
      isMaster,
      isDataInit,
      isMerchantLogin,
      unconfirmedOrders,
      requestPayingOrders,
      requestWaiterOrders,
    ],
  )

  const loadSound = useCallback(async () => {
    if (sound) return sound.unloadAsync()
    const { sound } = await Audio.Sound.createAsync(require('assets/sounds/notify.mp3'))
    setSound(sound)
  }, [sound])

  const playSound = useCallback(async () => {
    if (!sound) return
    sound.setIsLoopingAsync(isLooping)
    await sound.playAsync()
    soundLogger('playSound')
  }, [sound, soundLogger])

  const stopSound = useCallback(async () => {
    if (!sound) return
    await sound.stopAsync()
    soundLogger('stopSound')
  }, [sound, soundLogger])

  // * LOAD and UNLOAD sound
  useEffect(() => {
    if (isDataInit) {
      loadSound()
    }
    return () => sound?.unloadAsync()
  }, [isDataInit])

  // * PLAY and STOP sound
  useEffect(() => {
    if (
      isMaster &&
      isDataInit &&
      notifyingOrders.length > 0
    ) {
      playSound()
    } else {
      stopSound()
    }
  },
  [isMaster, isDataInit, notifyingOrders])
}
