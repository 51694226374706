import React, { useState } from 'react'

import MemberDetails from './MemberDetails'
import MemberOrderDetails from './MemberOrderDetails'
import MembrOrderList from './MembrOrderList'
import PointsRecordsDialog from './PointsRecordsDialog'

/**
 * @typedef MemberInfoProps
 * @property {IMember} member
 * @property {React.Dispatch<React.SetStateAction<null>>} setMember
 */

/**
 * @param {MemberInfoProps} props
 * @returns
 */
function MemberInfo (props) {
  const { member, setMember } = props
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [pointsDetails, setPointsDetails] = useState(false)

  return (
    <>
      <MemberDetails member={member} setMember={setMember} showPointsDetail={() => setPointsDetails(!pointsDetails)} />
      <MembrOrderList member={member} setSelectedOrder={setSelectedOrder} selectedOrder={selectedOrder} />
      <MemberOrderDetails selectedOrder={selectedOrder} />
      <PointsRecordsDialog member={member} open={pointsDetails} onClose={() => setPointsDetails(false)} />
    </>
  )
}

export default MemberInfo
