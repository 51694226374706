import { Platform } from 'react-native'
import Constants from 'expo-constants'
import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'

import codePush from '@/libs/codePush'
import firebaseConfig from '@/configs/firebase'
import logger from './logger'

const IS_APP = Platform.OS !== 'web'
const DEVICE_ID = Constants.installationId
const DEVICE_PATH = `devices/${DEVICE_ID}`
const DEVICE_CONNECTED_PATH = '.info/connected'
const DEVICE_NETWORK_STATUS_PATH = `${DEVICE_PATH}/networkStatus`
const DEVICE_RELOAD_PATH = `${DEVICE_PATH}/reload`
const DEVICE_CHECK_UPDATE_PATH = `${DEVICE_PATH}/checkUpdate`
const DEVICE_DEPLOYMENT_CHANNEL_PATH = `${DEVICE_PATH}/deploymentChannel`
const DEVICE_TEST_PRINTER_PATH = `${DEVICE_PATH}/testPrinter`
const DEVICE_MASTER_PATH = `${DEVICE_PATH}/master`
const DEVICE_TERMINAL_ID_PATH = `${DEVICE_PATH}/terminalID`
const DEVICE_PRINTERS_PATH = `${DEVICE_PATH}/printers`

class Firebase {
  isInit = false
  masterOnValueCallback = () => {}
  terminalIDOnValueCallback = () => {}
  checkUpdateOnValueCallback = () => {}
  testPrinterOnValueCallback = () => {}

  // avoid network error deadlock
  timeoutLimitCallback = (promise, type) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        return reject(new Error(`Firebase ${type} timeout`))
      }, 5000)
      promise.then(result => {
        logger.log(`Firebase ${type} resolve`, result)
        clearTimeout(timeoutId)
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * @param {'test' | 'beta' | 'prod'} env
   */
  init (env) {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig[env])
    }
    this.isInit = true
  }

  startListener () {
    const deviceRef = firebase.database().ref(DEVICE_PATH)

    firebase.database().ref(DEVICE_CONNECTED_PATH).on('value', () => {
      // 上線時更新什麼上firebase
      deviceRef.update({
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      })
      // 斷線時更新什麼上firebase
      deviceRef.onDisconnect().update({
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      })
    })

    firebase.database().ref(DEVICE_RELOAD_PATH).on('value', async (snapshot) => {
      const isNeedReload = snapshot.val()
      if (!isNeedReload) return
      // 當 reload 為 true 時重新啟動 App
      await deviceRef.update({ reload: false })
      codePush.restartApp()
    })

    firebase.database().ref(DEVICE_CHECK_UPDATE_PATH).on('value', async (snapshot) => {
      const deployment = snapshot.val()
      if (!deployment) return
      this.checkUpdateOnValueCallback(deployment)
    })

    firebase.database().ref(DEVICE_TEST_PRINTER_PATH).on('value', async (snapshot) => {
      const id = snapshot.val()
      if (!id) return
      this.testPrinterOnValueCallback(id)
    })

    firebase.database().ref(DEVICE_MASTER_PATH).on('value', async (snapshot) => {
      const isMaster = snapshot.val()
      if (typeof isMaster !== 'boolean') return
      this.masterOnValueCallback(isMaster)
    })

    firebase.database().ref(DEVICE_TERMINAL_ID_PATH).on('value', async (snapshot) => {
      const terminalID = snapshot.val()
      this.terminalIDOnValueCallback(terminalID)
    })
  }

  stopListener () {
    firebase.database().ref(DEVICE_CONNECTED_PATH).off('value')
    firebase.database().ref(DEVICE_RELOAD_PATH).off('value')
    firebase.database().ref(DEVICE_CHECK_UPDATE_PATH).off('value')
    firebase.database().ref(DEVICE_TEST_PRINTER_PATH).off('value')
    firebase.database().ref(DEVICE_MASTER_PATH).off('value')
    firebase.database().ref(DEVICE_TERMINAL_ID_PATH).off('value')
  }

  setCheckUpdateOnValueCallback (callback) {
    this.checkUpdateOnValueCallback = callback
  }

  setTestPrinterOnValueCallback (callback) {
    this.testPrinterOnValueCallback = callback
  }

  setMasterOnValueCallback (callback) {
    this.masterOnValueCallback = callback
  }

  setTerminalIDOnValueCallback (callback) {
    this.terminalIDOnValueCallback = callback
  }

  async updateDevice (device) {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_PATH)
    await this.timeoutLimitCallback(ref.update(device), 'updateDevice')
  }

  async updateMaster (isMaster) {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_PATH)
    await ref.update({ master: isMaster })
  }

  async updateTerminalID (terminalID) {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_PATH)
    await this.timeoutLimitCallback(ref.update({ terminalID: terminalID }), 'updateTerminalID')
  }

  async updateNetInfo (netInfo) {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_NETWORK_STATUS_PATH)
    await ref.update(netInfo)
  }

  async updatePrinter (printerId, printer) {
    if (!IS_APP) return
    if (!this.isInit) return
    const connected = printer.status === 'CONNECTED'
    const data = {
      id: printer.id,
      name: printer.name,
      printerType: printer.printerType,
      queueLength: printer.queueLength || 0,
      selected: true,
      connected,
      bluetooth: false,
      address: printerId,
      wifi: true,
      errorMessage: printer.error ?? '',
    }
    const ref = firebase.database().ref(`${DEVICE_PRINTERS_PATH}/${printerId.replace(/\./g, '_')}/`)
    await ref.update(_.mapValues(data, d => d || ''))
  }

  async deletePrinter (printerId) {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(`${DEVICE_PRINTERS_PATH}/${printerId.replace(/\./g, '_')}/`)
    await ref.remove()
  }

  async getDeploymentChannel () {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_DEPLOYMENT_CHANNEL_PATH)
    const snapshot = await this.timeoutLimitCallback(ref.once('value'), 'getDeploymentChannel')

    return snapshot?.val()
  }

  async setDeploymentChannel (deployment) {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_PATH)
    await this.timeoutLimitCallback(ref.update({ deploymentChannel: deployment }), 'setDeploymentChannel')
  }

  async getCheckUpdate () {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_CHECK_UPDATE_PATH)
    const snapshot = await this.timeoutLimitCallback(ref.once('value'), 'getCheckUpdate')

    return snapshot?.val()
  }

  async resetCheckUpdate () {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_PATH)
    await this.timeoutLimitCallback(ref.update({ checkUpdate: '' }), 'resetCheckUpdate')
  }

  async setCheckUpdateError (message) {
    if (!IS_APP) return
    if (!this.isInit) return
    const ref = firebase.database().ref(DEVICE_PATH)
    await this.timeoutLimitCallback(ref.update({ checkUpdateError: message }), 'setCheckUpdateError')
  }
}

export default new Firebase()
