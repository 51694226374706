import { PrintStyle } from '@/constants/printing'
import { v4 as uuid } from 'uuid'

export const defaultReceiptStyle = [
  {
    key: 'header', // 餐廳資料
    enable: true,
    id: uuid(),
    content: {
      orderType: {
        key: 'orderType',
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 2,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
      },
      serialNo: {
        key: 'serialNo',
        enable: false,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 2,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
      },
      scheduleAt: {
        key: 'scheduleAt',
        enable: true,
        type: 'time',
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        format: PrintStyle.TIME.SHORT24,
      },
      pickupAt: {
        key: 'pickupAt',
        enable: true,
        type: 'time',
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        format: PrintStyle.TIME.SHORT24,
      },
      merchantLogo: {
        key: 'merchantLogo', // Logo
        enable: false,
        type: PrintStyle.TYPE.IMAGE,
      },
      merchantName: {
        key: 'merchantName', // 餐廳名稱
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 1,
        bold: true,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
      },
      merchantAddress: {
        key: 'merchantAddress', // 餐廳地址
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
      },
      merchantContact: {
        key: 'merchantContact', // 餐廳電話
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.CENTER,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
      },
    },
    drag: true,
  },
  {
    key: 'separator', // 分隔線
    style: PrintStyle.SEPARATOR.DOTTED,
    enable: true,
    id: uuid(),
    drag: true,
  },
  {
    key: 'info', // 收據資料
    enable: true,
    id: uuid(),
    content: {
      tableNo: {
        key: 'tableNo', // 枱號
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.SPACE,
        index: 0,
      },
      customerNo: {
        key: 'customerNo', // 人數
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        index: 1,
      },
      serialNo: {
        key: 'serialNo', // 單號
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        last3Digit: {
          fontSize: 0,
          bold: false,
          enable: false,
        },
        index: 2,
      },
      createAt: {
        key: 'createAt', // 落單時間
        enable: true,
        type: 'time',
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        format: PrintStyle.TIME.LONG24,
        index: 3,
      },
      printAt: {
        key: 'printAt', // 打印時間
        enable: true,
        type: 'time',
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        format: PrintStyle.TIME.LONG24,
        index: 4,
      },
      paidAt: {
        key: 'paidAt',
        enable: true,
        type: 'time',
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        format: PrintStyle.TIME.LONG24,
        index: 5,
      },
      customerContact: {
        key: 'customerContact',
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        index: 6,
      },
      customerName: {
        key: 'customerName',
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        index: 7,
      },
      customerAddress: {
        key: 'customerAddress',
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        index: 8,
      },
      pickupAt: {
        key: 'pickupAt',
        enable: true,
        type: 'time',
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        format: PrintStyle.TIME.LONG24,
        index: 9,
      },
      remark: {
        key: 'remark',
        enable: true,
        type: PrintStyle.TYPE.TEXT,
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 0,
        bold: false,
        separateFormat: PrintStyle.TEXT_SEPARATOR.NEW_LINE,
        index: 10,
      },
    },
    drag: true,
  },
  {
    key: 'content', // 收據資料
    enable: true,
    format: ['item', 'quantity', 'price'],
    fontSize: 0,
    title: {
      enable: true,
      separator: true,
      separatorStyle: PrintStyle.SEPARATOR.DOTTED,
    },
    settings: {
      setTotalIncludeItems: true,
      showSetItemsPrice: false,
      showSetName: true,
      showOptionPrice: false,
      showFreeSetItem: true,
      hideFreeItem: false,
      optionFontSize: 0,
      remarkFontSize: 0,
      leading: false,
    },
    id: uuid(),
    drag: true,
  },
  {
    key: 'separator', // 分隔線
    style: PrintStyle.SEPARATOR.DOTTED,
    enable: true,
    id: uuid(),
    drag: true,
  },
  {
    key: 'subtotal', // 小計
    enable: true,
    detail: {
      showDiscountDetail: true,
      fontSize: 0,
    },
    paidAmount: {
      fontSize: 1,
      bold: true,
      separator: false,
    },
    id: uuid(),
    drag: true,
  },
  {
    key: 'payment', // 付款方式
    enable: true,
    id: uuid(),
    settings: {
      showCardPaymentDetail: false,
      fontSize: 0,
      leading: false,
    },
    drag: true,
  },
  {
    key: 'footer', // 頁尾
    enable: true,
    id: uuid(),
    drag: true,
    tableware: true,
    paymentStatus: true,
  },
  {
    key: 'joinMemberQRCode',
    enable: true, // 根據『打印加入會員 QRCode』開關
    id: uuid(),
    drag: true,
    customizedText: {
      enable: true, // 根據『打印自訂文字』開關
      text: '歡迎加入餐廳會員', // 文字
      position: 'bottom', // 位置 'top' | 'bottom'
      bold: false, // 粗體
      fontSize: 0, // 字型
      align: PrintStyle.ALIGN.CENTER, // 對齊
    },
  },
]
