import colors from '@/theme/colors'
import i18n from '@/i18n'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const displayStatusNames = {
  unknown: i18n.t('app.constant.displayStatusKey.unknown'),
  paying: i18n.t('app.constant.displayStatusKey.paying'),
  waiting_order: i18n.t('app.constant.displayStatusKey.waiting_order'),
  waiting_pay: i18n.t('app.constant.displayStatusKey.waiting_pay'),
  waiting_accept: i18n.t('app.constant.displayStatusKey.waiting_accept'),
  waiting_pick_up: i18n.t('app.constant.displayStatusKey.waiting_pick_up'),
  paid: i18n.t('app.constant.displayStatusKey.paid'),
  preparing_meal: i18n.t('app.constant.displayStatusKey.preparing_meal'),
  picked_up: i18n.t('app.constant.displayStatusKey.picked_up'),
  in_delivery: i18n.t('app.constant.displayStatusKey.in_delivery'),
  arrived: i18n.t('app.constant.displayStatusKey.arrived'),
  complete: i18n.t('app.constant.displayStatusKey.complete'),
  cancel_table: i18n.t('app.constant.displayStatusKey.cancel_table'),
  cancel_order: i18n.t('app.constant.displayStatusKey.cancel_order'),
  delete_order: i18n.t('app.constant.displayStatusKey.delete_order'),
}

/**
 * @typedef ActionButtonConfig
 * @type {object}
 * @property {string} action
 * @property {string} text
 * @property {string} backgroundColor
 * @property {any} iconSource
 */

/** @type {{[action: string]: ActionButtonConfig}} */
const orderActions = {
  // common
  qrcode: {
    action: 'qrcode',
    text: 'app.constant.orderAction.qrcode',
    iconSource: require('@icons/orders/order-action/qr.png'),
    loading: true,
  },
  order: {
    action: 'order',
    text: 'app.constant.orderAction.order',
    iconSource: require('@icons/orders/order-action/order.png'),
  },
  checkout: {
    action: 'checkout',
    text: 'app.constant.orderAction.checkout',
    iconSource: require('@icons/orders/order-action/check-out.png'),
    backgroundColor: colors.darkSecondary,
  },
  cancelOrder: {
    action: 'cancel',
    text: 'app.constant.orderAction.cancel',
    iconSource: require('@icons/orders/order-action/cancel-order.png'),
  },
  cancelTable: {
    action: 'cancel',
    text: 'app.constant.orderAction.quit',
    iconSource: require('@icons/orders/order-action/cancel-table.png'),
  },
  deleteOrder: {
    action: 'cancel',
    text: 'app.constant.orderAction.waste',
    iconSource: require('@icons/orders/order-action/delete.png'),
  },
  copyOrder: {
    action: 'copy',
    text: 'app.constant.orderAction.copy',
    iconSource: require('@icons/orders/order-action/copy.png'),
  },
  printReceipt: {
    action: 'printReceipt',
    text: 'app.constant.orderAction.print',
    iconSource: require('@icons/orders/order-action/print-receipt.png'),
    loading: true,
  },
  paymentHistory: {
    action: 'paymentHistory',
    text: 'app.constant.orderAction.paymentHistory',
    iconSource: require('@icons/orders/order-action/payment-history.png'),
  },
  customerCount: {
    action: 'customerCount',
    text: 'app.constant.orderAction.customerCount',
    iconSource: require('@icons/orders/order-action/customer.png'),
  },
  // delivery
  confirm: {
    action: 'confirm',
    text: 'app.constant.orderAction.confirm',
    iconSource: require('@icons/orders/order-action/accept.png'),
    loading: true,
  },
  reject: {
    action: 'cancel',
    text: 'app.constant.orderAction.reject',
    iconSource: require('@icons/orders/order-action/reject.png'),
  },
  ready: {
    action: 'ready',
    text: 'app.constant.orderAction.ready',
    iconSource: require('@icons/orders/order-action/meal.png'),
  },
  complete: {
    action: 'complete',
    text: 'app.constant.orderAction.complete',
    iconSource: require('@icons/orders/order-action/meal.png'),
  },
  confirmAllBatch: {
    action: 'confirmAllBatch',
    text: 'app.constant.orderAction.confirmAll',
    iconSource: require('@icons/orders/order-action/accept.png'),
    backgroundColor: colors.darkSecondary,
  },
  selectAllItems: {
    action: 'selectAllItems',
    text: 'app.constant.orderAction.selectAllItems',
    iconSource: require('@icons/orders/order-action/selectAll.png'),
  },
  takeawayInfo: {
    action: 'takeawayInfo',
    text: 'app.constant.orderAction.takeawayInfo',
    iconSource: require('@icons/orders/order-list/take-away.png'),
  },
  pickUpNotify: {
    action: 'pickUpNotify',
    text: 'app.constant.orderAction.pickUpNotify',
    iconSource: require('@icons/orders/order-action/meal.png'),
  },
}

/** @type {{[action: string]: ActionButtonConfig}} */
const itemActions = {
  discount: {
    action: 'discount',
    text: 'app.constant.orderAction.discount',
    iconSource: require('@icons/orders/order-action/discount.png'),
  },
  surcharge: {
    action: 'surcharge',
    text: 'app.constant.orderAction.surcharge',
    iconSource: require('@icons/orders/order-action/discount.png'),
  },
  itemChangePrice: {
    action: 'itemChangePrice',
    text: 'app.constant.orderAction.itemChangePrice',
    iconSource: require('@icons/orders/order-action/discount.png'),
  },
  itemModifier: {
    action: 'itemModifier',
    text: 'app.constant.orderAction.itemModifier',
    iconSource: require('@icons/orders/order-action/discount.png'),
  },
  setPrice: {
    action: 'setPrice',
    text: 'app.constant.orderAction.setPrice',
    iconSource: require('@icons/orders/order-action/order.png'),
  },
  cancelItem: {
    action: 'cancelItem',
    text: 'app.constant.orderAction.cancelItem',
    iconSource: require('@icons/orders/order-action/delete.png'),
  },
  revealItem: {
    action: 'revealItem',
    text: 'app.constant.orderAction.revealItem',
    iconSource: require('@icons/orders/order-action/undo.png'),
  },
  transfer: {
    action: 'transfer',
    text: 'app.constant.orderAction.transfer',
    iconSource: require('@icons/orders/order-action/transfer.png'),
  },
  serveUp: {
    action: 'serveUp',
    text: 'app.constant.orderAction.serveUp',
    iconSource: require('@icons/orders/order-action/call.png'),
    loading: true,
  },
  printKitchen: {
    action: 'printKitchen',
    text: 'app.constant.orderAction.printKitchen',
    iconSource: require('@icons/orders/order-action/trace.png'),
    loading: true,
  },
  printChangeKitchen: {
    action: 'printChangeKitchen',
    text: 'app.constant.orderAction.printChangeKitchen',
    iconSource: require('@icons/orders/order-action/print-receipt.png'),
    loading: true,
  },
  resetSelect: {
    action: 'resetSelect',
    text: 'app.constant.orderAction.resetSelect',
    iconSource: require('@icons/orders/order-action/reject.png'),
    backgroundColor: colors.darkSecondary,
  },
  confirmBatch: {
    action: 'confirmBatch',
    text: 'app.constant.orderAction.confirmBatch',
    iconSource: require('@icons/orders/order-action/qr.png'),
    backgroundColor: colors.darkSecondary,
  },
}

/**
 * @param {TTableDisplayStatusKey} displayStatusKey
 * @returns {{color: string, text: string, orderActions: ActionButtonConfig[], itemActions: ActionButtonConfig[]}}
 */
function getTableStatusConfig (displayStatusKey) {
  switch (displayStatusKey) {
    case 'waiting_order': {
      return {
        text: i18n.t('app.constant.displayStatusKey.waiting_order'),
        color: colors.status1,
        orderActions: [
          orderActions.qrcode, // TODO: this is for test
          orderActions.order,
          orderActions.customerCount,
          orderActions.cancelOrder,
        ],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'preparing_meal': {
      return {
        text: i18n.t('app.constant.displayStatusKey.preparing_meal'),
        color: colors.status2,
        orderActions: [
          orderActions.qrcode, // TODO: this is for test
          orderActions.order,
          orderActions.customerCount,
          orderActions.cancelOrder,
          // orderActions.copyOrder, // TODO: 完成後開放
          orderActions.printReceipt,
          orderActions.checkout,
          orderActions.confirmAllBatch,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.setPrice,
          itemActions.discount,
          itemActions.cancelItem,
          // itemActions.revealItem,
          itemActions.transfer,
          itemActions.serveUp,
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
          itemActions.confirmBatch,
        ],
      }
    }
    case 'waiting_pay': {
      return {
        text: i18n.t('app.constant.displayStatusKey.waiting_pay'),
        color: colors.status2,
        orderActions: [
          orderActions.qrcode, // TODO: this is for test
          orderActions.order,
          orderActions.customerCount,
          orderActions.cancelOrder,
          // orderActions.copyOrder, // TODO: 完成後開放
          orderActions.printReceipt,
          orderActions.selectAllItems,
          orderActions.paymentHistory,
          orderActions.checkout,
          orderActions.confirmAllBatch,
        ],
        itemActions: [
          itemActions.setPrice,
          // itemActions.discount,
          // itemActions.surcharge,
          // itemActions.itemChangePrice,
          itemActions.itemModifier,
          itemActions.cancelItem,
          // itemActions.revealItem,
          itemActions.transfer,
          itemActions.serveUp,
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
          itemActions.confirmBatch,
        ],
      }
    }
    case 'paid': {
      return {
        text: i18n.t('app.constant.displayStatusKey.paid'),
        color: colors.status6,
        orderActions: [
          // orderActions.qrcode, // TODO: this is for test
          orderActions.deleteOrder,
          // orderActions.copyOrder, // TODO: 完成後開放
          orderActions.printReceipt,
          orderActions.paymentHistory,
          orderActions.selectAllItems,
          orderActions.pickUpNotify,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'cancel_table': {
      return {
        text: i18n.t('app.constant.displayStatusKey.cancel_table'),
        color: colors.status7,
        orderActions: [
          orderActions.printReceipt,
        ],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'delete_order': {
      return {
        text: i18n.t('app.constant.displayStatusKey.delete_order'),
        color: colors.status7,
        orderActions: [
          orderActions.printReceipt,
        ],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'paying': {
      return {
        text: i18n.t('app.constant.displayStatusKey.paying'),
        color: colors.status2,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'waiting_pick_up': {
      return {
        text: i18n.t('app.constant.displayStatusKey.ready'),
        color: colors.status6,
        orderActions: [
          orderActions.deleteOrder,
          orderActions.printReceipt,
          orderActions.paymentHistory,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'unknown':
    default: {
      return {
        text: i18n.t('app.constant.displayStatusKey.unknown'),
        color: colors.primary,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
  }
}

/**
 * @param {TTakeawayDisplayStatusKey} displayStatusKey
 * @returns {{color: string, text: string, orderActions: ActionButtonConfig[], itemActions: ActionButtonConfig[]}}
 */
function getTakeawayStatusConfig (displayStatusKey) {
  switch (displayStatusKey) {
    case 'waiting_order': {
      return {
        text: i18n.t('app.constant.displayStatusKey.waiting_order'),
        color: colors.status1,
        orderActions: [
          orderActions.order,
        ],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'waiting_pay': {
      return {
        text: i18n.t('app.constant.displayStatusKey.waiting_pay'),
        color: colors.status4,
        orderActions: [
          orderActions.order,
          orderActions.cancelOrder,
          orderActions.printReceipt,
          orderActions.takeawayInfo,
          orderActions.selectAllItems,
          orderActions.checkout,
        ],
        itemActions: [
          // itemActions.discount,
          // itemActions.surcharge,
          // itemActions.itemChangePrice,
          itemActions.itemModifier,
          itemActions.cancelItem,
          // itemActions.revealItem,
          itemActions.serveUp,
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'paid': {
      return {
        text: i18n.t('app.constant.displayStatusKey.paid'),
        color: colors.status6,
        orderActions: [
          orderActions.deleteOrder,
          // orderActions.copyOrder, // TODO: 完成後開放
          orderActions.printReceipt,
          orderActions.paymentHistory,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'waiting_accept': {
      return {
        text: i18n.t('app.constant.displayStatusKey.waiting_accept'),
        color: colors.status1,
        orderActions: [
          orderActions.confirm,
          orderActions.cancelOrder,
        ],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'preparing_meal': {
      return {
        text: i18n.t('app.constant.displayStatusKey.preparing_meal'),
        color: colors.status2,
        orderActions: [
          // orderActions.order,
          orderActions.ready,
          orderActions.complete,
          orderActions.cancelOrder,
          // orderActions.copyOrder, // TODO: 完成後開放
          orderActions.printReceipt,
          orderActions.selectAllItems,
          orderActions.checkout,
        ],
        itemActions: [
          // itemActions.discount,
          // itemActions.surcharge,
          // itemActions.itemChangePrice,
          itemActions.itemModifier,
          itemActions.cancelItem,
          // itemActions.revealItem,
          // itemActions.transfer, // TODO: 完成後開放
          itemActions.serveUp,
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'waiting_pick_up': {
      return {
        text: i18n.t('app.constant.displayStatusKey.waiting_pick_up'),
        color: colors.status8,
        orderActions: [
          orderActions.complete,
          orderActions.cancelOrder,
          orderActions.printReceipt,
          orderActions.checkout,
          orderActions.paymentHistory,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.serveUp,
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'picked_up': {
      return {
        text: i18n.t('app.constant.displayStatusKey.picked_up'),
        color: colors.status6,
        orderActions: [
          orderActions.deleteOrder,
          orderActions.printReceipt,
          orderActions.paymentHistory,
          orderActions.checkout,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }

    case 'cancel_order': {
      return {
        text: i18n.t('app.constant.displayStatusKey.cancel_order'),
        color: colors.status7,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'delete_order': {
      return {
        text: i18n.t('app.constant.displayStatusKey.delete_order'),
        color: colors.status7,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'paying': {
      return {
        text: i18n.t('app.constant.displayStatusKey.paying'),
        color: colors.status2,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'unknown':
    default: {
      return {
        text: i18n.t('app.constant.displayStatusKey.unknown'),
        color: colors.primary,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
  }
}

/**
 * @param {TDeliveryDisplayStatusKey} displayStatusKey
 * @returns {{color: string, text: string, orderActions: ActionButtonConfig[], itemActions: ActionButtonConfig[]}}
 */
function getDeliveryStatusConfig (displayStatusKey) {
  switch (displayStatusKey) {
    case 'waiting_accept': {
      return {
        text: i18n.t('app.constant.displayStatusKey.waiting_accept'),
        color: colors.status1,
        orderActions: [
          orderActions.confirm,
          orderActions.cancelOrder,
        ],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'preparing_meal': {
      return {
        text: i18n.t('app.constant.displayStatusKey.preparing_meal'),
        color: colors.status2,
        orderActions: [
          orderActions.ready,
          orderActions.printReceipt,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'waiting_pick_up': {
      return {
        text: i18n.t('app.constant.displayStatusKey.preparing_meal'),
        color: colors.status2,
        orderActions: [
          orderActions.printReceipt,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'in_delivery': {
      return {
        text: i18n.t('app.constant.displayStatusKey.in_delivery'),
        color: colors.status5,
        orderActions: [
          // orderActions.cancelOrder,
          orderActions.printReceipt,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'complete': {
      return {
        text: i18n.t('app.constant.displayStatusKey.complete'),
        color: colors.status6,
        orderActions: [
          // orderActions.deleteOrder,
          orderActions.printReceipt,
          orderActions.selectAllItems,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'waiting_pay': {
      return {
        text: i18n.t('app.constant.displayStatusKey.waiting_pay'),
        color: colors.status3,
        orderActions: [
          orderActions.cancelOrder,
          orderActions.printReceipt,
          orderActions.selectAllItems,
          orderActions.checkout,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'arrived': {
      return {
        text: i18n.t('app.constant.displayStatusKey.arrived'),
        color: colors.status6,
        orderActions: [
          // orderActions.deleteOrder,
          orderActions.printReceipt,
          // orderActions.paymentHistory,
        ],
        itemActions: [
          itemActions.printKitchen,
          itemActions.printChangeKitchen,
          itemActions.resetSelect,
        ],
      }
    }
    case 'cancel_order': {
      return {
        text: i18n.t('app.constant.displayStatusKey.cancel_order'),
        color: colors.status7,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'delete_order': {
      return {
        text: i18n.t('app.constant.displayStatusKey.delete_order'),
        color: colors.status7,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'paying': {
      return {
        text: i18n.t('app.constant.displayStatusKey.paying'),
        color: colors.status2,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
    case 'unknown':
    default: {
      return {
        text: i18n.t('app.constant.displayStatusKey.unknown'),
        color: colors.primary,
        orderActions: [],
        itemActions: [
          itemActions.resetSelect,
        ],
      }
    }
  }
}

/**
 * @typedef StatusConfig
 * @type {object}
 * @property {string} text
 * @property {string} color
 * @property {ActionButtonConfig[]} orderActions
 * @property {ActionButtonConfig[]} itemActions
 */

export const getStatusConfigs = {
  table: getTableStatusConfig,
  takeaway: getTakeawayStatusConfig,
  delivery: getDeliveryStatusConfig,
  storeDelivery: getDeliveryStatusConfig,
  shop: getDeliveryStatusConfig,
}

/**
 * @param {TDisplayStatusKey} displayStatusKey
 * @returns {string}
 */
export function getDisplayStatusName (displayStatusKey) {
  return displayStatusNames[displayStatusKey]
}

/**
 *
 * @param {IAppOrder} order
 * @returns {StatusConfig}
 */
export function getStatusConfig (order) {
  const statusConfig = getStatusConfigs[order.deliveryType](order.displayStatusKey)
  return statusConfig
}
