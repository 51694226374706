import _ from 'lodash'

async function resizeImage(settings: {
  file: File
  maxSize?: number
}): Promise<Blob> {
  const file = settings.file
  const maxSize = settings.maxSize ?? 1000
  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')

  const dataURItoBlob = (dataURI: string): Blob => {
    const bytes = dataURI.split(',')[0].includes('base64')
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1])
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const max = bytes.length
    const ia = new Uint8Array(max)
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
    return new Blob([ia], { type: mime })
  }

  const resize = (): Blob => {
    let width = image.width
    let height = image.height
    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width
        width = maxSize
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height
        height = maxSize
      }
    }
    canvas.width = width
    canvas.height = height
    canvas.getContext('2d')?.drawImage(image, 0, 0, width, height)
    const dataUrl = canvas.toDataURL()
    return dataURItoBlob(dataUrl)
  }

  return new Promise((resolve, reject) => {
    const fileType = _.get(file, 'type', '')
    if (fileType.match(/image.*/) == null) {
      reject(new Error('Not an image'))
      return
    }
    reader.onload = (readerEvent: any) => {
      image.onload = () => resolve(resize())
      image.src = readerEvent.target.result
    }
    reader.readAsDataURL(file)
  })
}

export {
  resizeImage,
}
