import { StyleSheet, View } from 'react-native'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { useSelectedOrder } from '@/hooks/orderHistory'
import ConfirmMemberInfoDialog from '@root/src/components/dialogs/ConfirmMemberInfoDialog'
import CustomerInfoCard from '@/components/CustomerInfoCard'
import IconButton from '@/components/buttons/IconButton'
import InfoPanel from './InfoPanel'
import MemberRegistrationDialog from '@root/src/components/dialogs/MemberRegistrationDialog'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OrderHeader (props) {
  const selectedOrder = useSelectedOrder()
  const [phone, setPhone] = React.useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onBack = () => {
    if (selectedOrder?.status === 'paid') {
      dispatch(actions.orderCheckout.cleanCheckOutOrder())
      history.replace('/orderHistory')
      return
    }
    dispatch(actions.app.showAlert({
      title: t('app.page.order.stash.alert.leave.title'),
      message: t('app.page.order.stash.alert.leave.msg1'),
      buttons: [
        {
          children: t('app.page.order.stash.alert.leave.button.continue'),
          onPress: () => {
            dispatch(actions.orderCheckout.cleanCheckOutOrder())
            history.replace('/orderHistory')
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.page.order.stash.alert.leave.button.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  return (
    <Row style={styles.container}>
      <View>
        <IconButton
          iconSource={require('@icons/back.png')}
          onPress={onBack}
        />
      </View>
      <InfoPanel />
      <CustomerInfoCard customer={selectedOrder} style={styles.customer} register />
      <MemberRegistrationDialog setPhone={setPhone} />
      <ConfirmMemberInfoDialog orderId={selectedOrder.id} phone={phone} />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 136,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.primary,
    borderTopRightRadius: 20,
    overflow: 'hidden',
    paddingTop: 15,
    paddingBottom: 10,
    ...shadows.default,
  },
  customer: {
    position: 'absolute',
    top: 24,
    right: 24,
  },
})
