import { StyleSheet, TextInput, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'

/**
 * @typedef TextInputDialogProps
 * @property {boolean} open
 * @property {() => void} onSubmit
 * @property {() => void} onClose
 * @property {string} title
 * @property {string} placeholder
 * @property {string?} submitButtonText
 * @property {string?} defaultText
 * @property {TextInputProps['keyboardType']?} keyboardType
 * @property {boolean?} secureTextEntry
 * @property {boolean?} enablePressOutsideClose
 */

/**
 *
 * @param {TextInputDialogProps} props
 * @returns
 */
export default function TextInputDialog (props) {
  const { t } = useTranslation()
  const {
    open,
    onClose,
    onSubmit,
    title,
    placeholder,
    submitButtonText = t('app.common.send'),
    defaultText = '',
    keyboardType = 'default',
    secureTextEntry = false,
    enablePressOutsideClose = true,
  } = props
  const [newText, setNewText] = React.useState(defaultText)

  return (
    <CenterModal
      enablePressOutsideClose={enablePressOutsideClose}
      title={title}
      visible={open}
      onClose={onClose}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.inputContainer}>
        <TextInput
          keyboardType={keyboardType}
          autoFocus
          secureTextEntry={secureTextEntry}
          style={styles.input}
          placeholder={placeholder}
          placeholderTextColor='#B0B0B0'
          value={newText}
          onChangeText={(text) => setNewText(text)}
        />
      </View>
      <View style={styles.buttons}>
        <Button
          title={t('app.common.clear')}
          onPress={() => setNewText('')}
        />
        <Button
          title={submitButtonText}
          disabled={!newText}
          onPress={() => {
            onSubmit(newText)
            setNewText('')
            onClose()
          }}
        />
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 450,
    padding: 16,
    height: 150,
  },
  inputContainer: {
    width: '100%',
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.primary,
    margin: 16,
  },
  input: {
    fontSize: 16,
    width: '100%',
    margin: 8,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})
