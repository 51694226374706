import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'

/* eslint-disable no-unused-vars */
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'
import { IMenu } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

/**
 * @typedef MenuInfoProps
 * @property {IAppCategory} category
 * @property {IMenu} editingMenu
 * @property {(path: string, value: any) => void} onUpdate
 */

/**
 *
 * @param {MenuInfoProps} props
 * @returns
 */
export default function MenuInfo (props) {
  const { category, editingMenu, onUpdate } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const weekdayOptions = [
    { value: 0, display: t('app.constant.weekday.sun') },
    { value: 1, display: t('app.constant.weekday.mon') },
    { value: 2, display: t('app.constant.weekday.tue') },
    { value: 3, display: t('app.constant.weekday.wed') },
    { value: 4, display: t('app.constant.weekday.thur') },
    { value: 5, display: t('app.constant.weekday.fri') },
    { value: 6, display: t('app.constant.weekday.sat') },
  ]

  const isInventoryEnabled = useSelector(state => state.merchant.data?.setting?.inventory)

  const [weekdaysSelectDialogOpen, setWeekdaysSelectDialogOpen] = React.useState(false)
  const [promotedDaysSelectDialogOpen, setPromotedDaysSelectDialogOpen] = React.useState(false)
  const [categoriesSelectDialogOpen, setCategoriesSelectDialogOpen] = React.useState(false)
  const [departmentSelectDialogOpen, setDepartmentSelectDialogOpen] = React.useState(false)
  const categories = useSelector(state => state.menu.categories)
  const departments = useSelector(state => state.menu.departments)
  const kitchenDepartment = departments.find(department => department.id === editingMenu.kitchenDepartmentId)
  const lang = String(i18n.language ?? 'zh').split('-')[0]

  const categoryOptions = _.flatMap(categories)
    .filter(c => c.id !== 'ROOT' && c.id !== 'SET_ROOT' && !c.path?.includes('SET_ROOT') && _.isEmpty(c.categories)).map(c => {
      return { value: c.id, display: _.get(c?.localeNames, lang) ?? c.name }
    })

  const departmentOptions = _.concat({ value: '', display: t('app.common.unselected') }, departments.map(department => {
    return { value: department.id, display: department.name }
  }))

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
    >
      <Header>
        <HeaderText text={t('app.page.setting.menuPanel.menuInfo.info')} />
      </Header>
      <SettingList style={{ flex: 1 }}>
        <SettingListEnterRow
          divider
          label={t('app.page.setting.menuPanel.menuInfo.category')}
          value={_.get(categories[editingMenu.categoryId]?.localeNames, lang) ?? category.name}
          onPress={() => setCategoriesSelectDialogOpen(true)}
        />
        <SettingListEnterRow
          divider
          label={t('app.page.setting.menuPanel.menuInfo.department')}
          value={kitchenDepartment?.name ?? t('app.common.unselected')}
          onPress={() => setDepartmentSelectDialogOpen(true)}
        />
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.name')} />
          <SettingListItemRightAction>
            <SettingListInput
              value={_.get(editingMenu, `localeNames.${editingMenu.locale}`, editingMenu.name)}
              onChangeText={(text) => {
                onUpdate('name', text)
                onUpdate(`localeNames.${editingMenu.locale}`, text)
              }}
              onBlur={(event) => {
                onUpdate('name', event.nativeEvent.text, true)
                onUpdate(`localeNames.${editingMenu.locale}`, event.nativeEvent.text, true)
              }}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.shortName')} />
          <SettingListItemRightAction>
            <SettingListInput
              value={editingMenu.shortName}
              onChangeText={(text) => {
                onUpdate('shortName', text)
              }}
              onBlur={(event) => {
                onUpdate('shortName', event.nativeEvent.text, true)
              }}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.code')} />
          <SettingListItemRightAction>
            <SettingListInput
              value={editingMenu.code}
              onChangeText={(text) => {
                onUpdate('code', text)
              }}
              onBlur={(event) => {
                onUpdate('code', event.nativeEvent.text, true)
              }}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.desc')} />
          <SettingListItemRightAction>
            <SettingListInput
              value={_.get(editingMenu, `localeDesc.${editingMenu.locale}`, editingMenu.desc)}
              onChangeText={(text) => {
                onUpdate('desc', text)
                onUpdate(`localeDesc.${editingMenu.locale}`, text)
              }}
              onBlur={(event) => {
                onUpdate('desc', event.nativeEvent.text, true)
                onUpdate(`localeDesc.${editingMenu.locale}`, event.nativeEvent.text, true)
              }}
              multiline
              style={{ height: 60 }}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListEnterRow
          divider
          label={t('app.page.setting.menuPanel.menuInfo.date')}
          value={
            editingMenu.weekdays?.length > 0
              ? editingMenu.weekdays
                .map(d => weekdayOptions.find(o => o.value === d)?.display)
                .join()
              : t('app.common.null')
          }
          onPress={() => setWeekdaysSelectDialogOpen(true)}
        />
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.start')} />
          <SettingListItemRightAction>
            <View style={{ flex: 1, alignItems: 'flex-end' }}>
              <TouchableOpacity
                onPress={() => dispatch(actions.app.showDateTimePicker('time',
                  moment({
                    hour: _.get(editingMenu, 'timerange[0].hour'),
                    minute: _.get(editingMenu, 'timerange[0].minute'),
                  }).toDate(),
                  (value) => {
                    const selectedStart = moment(value)
                    const timerange = editingMenu.timerange
                      ? [{ ...editingMenu.timerange[0] }, { ...editingMenu.timerange[1] }]
                      : [{ hour: 0, minute: 0, second: 0 }, { hour: 0, minute: 0, second: 0 }]
                    timerange[0].minute = Number(selectedStart.format('mm'))
                    timerange[0].hour = Number(selectedStart.format('HH'))
                    onUpdate('timerange', timerange)
                  },
                  moment({
                    hour: 0,
                    minute: 0,
                  }).toDate(),
                ))}
              >
                <Text>
                  {
                    _.get(editingMenu, 'timerange[0]')
                      ? moment({ hour: _.get(editingMenu, 'timerange[0].hour'), minute: _.get(editingMenu, 'timerange[0].minute') }).format('HH : mm')
                      : '- - : - -'
                  }
                </Text>
              </TouchableOpacity>
            </View>
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.end')} />
          <SettingListItemRightAction>
            <View style={{ flex: 1, alignItems: 'flex-end' }}>
              <TouchableOpacity
                onPress={() => dispatch(actions.app.showDateTimePicker('time',
                  moment({
                    hour: _.get(editingMenu, 'timerange[1].hour'),
                    minute: _.get(editingMenu, 'timerange[1].minute'),
                  }).toDate(),
                  (value) => {
                    const selectedStart = moment(value)
                    const timerange = editingMenu.timerange
                      ? [{ ...editingMenu.timerange[0] }, { ...editingMenu.timerange[1] }]
                      : [{ hour: 0, minute: 0, second: 0 }, { hour: 0, minute: 0, second: 0 }]
                    timerange[1].minute = Number(selectedStart.format('mm'))
                    timerange[1].hour = Number(selectedStart.format('HH'))
                    onUpdate('timerange', timerange)
                  },
                  moment({
                    hour: _.get(editingMenu, 'timerange[0].hour'),
                    minute: _.get(editingMenu, 'timerange[0].minute'),
                  }).toDate(),
                ))}
              >
                <Text>
                  {
                    _.get(editingMenu, 'timerange[1]')
                      ? moment({ hour: _.get(editingMenu, 'timerange[1].hour'), minute: _.get(editingMenu, 'timerange[1].minute') }).format('HH : mm')
                      : '- - : - -'
                  }
                </Text>
              </TouchableOpacity>
            </View>
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListItem divider>
          <SettingListItemRightAction>
            <Button
              title={t('app.page.setting.menuPanel.menuInfo.clearTime')}
              style={styles.button}
              backgroundColor={colors.primary}
              textBold
              onPress={() => onUpdate('timerange', [])}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListSwitchRow
          divider
          label={t('app.page.setting.menuPanel.menuInfo.promoted')}
          value={editingMenu.promoted}
          onChangeValue={() => onUpdate('promoted', !editingMenu.promoted)}
        />
        {
          editingMenu.promoted && (
            <SettingListEnterRow
              divider
              label={t('app.page.setting.menuPanel.menuInfo.promoDate')}
              value={editingMenu.promotedDays?.length > 0
                ? editingMenu.promotedDays.map(d =>
                  weekdayOptions.find(o =>
                    o.value === d)?.display).join() : t('app.common.null')}
              onPress={() => setPromotedDaysSelectDialogOpen(true)}
            />
          )
        }
        {
          editingMenu.promoted && (
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.startPromoTime')} />
              <SettingListItemRightAction>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                  <TouchableOpacity
                    onPress={() => dispatch(actions.app.showDateTimePicker('time',
                      moment({
                        hour: _.get(editingMenu, 'promotedTimerange[0].hour'),
                        minute: _.get(editingMenu, 'promotedTimerange[0].minute'),
                      }).toDate(),
                      (value) => {
                        const selectedStart = moment(value)
                        const promotedTimerange = editingMenu.promotedTimerange
                          ? [{ ...editingMenu.promotedTimerange[0] }, { ...editingMenu.promotedTimerange[1] }]
                          : [{ hour: 0, minute: 0, second: 0 }, { hour: 0, minute: 0, second: 0 }]
                        promotedTimerange[0].minute = Number(selectedStart.format('mm'))
                        promotedTimerange[0].hour = Number(selectedStart.format('HH'))
                        onUpdate('promotedTimerange', promotedTimerange)
                      },
                      moment({
                        hour: 0,
                        minute: 0,
                      }).toDate(),
                    ))}
                  >
                    <Text>
                      {
                        _.get(editingMenu, 'promotedTimerange[0]')
                          ? moment({ hour: _.get(editingMenu, 'promotedTimerange[0].hour'), minute: _.get(editingMenu, 'promotedTimerange[0].minute') }).format('HH : mm')
                          : '- - : - -'
                      }
                    </Text>
                  </TouchableOpacity>
                </View>
              </SettingListItemRightAction>
            </SettingListItem>
          )
        }
        {
          editingMenu.promoted && (
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.endPromoTime')} />
              <SettingListItemRightAction>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                  <TouchableOpacity
                    onPress={() => dispatch(actions.app.showDateTimePicker('time',
                      moment({
                        hour: _.get(editingMenu, 'promotedTimerange[1].hour'),
                        minute: _.get(editingMenu, 'promotedTimerange[1].minute'),
                      }).toDate(),
                      (value) => {
                        const selectedStart = moment(value)
                        const promotedTimerange = editingMenu.promotedTimerange
                          ? [{ ...editingMenu.promotedTimerange[0] }, { ...editingMenu.promotedTimerange[1] }]
                          : [{ hour: 0, minute: 0, second: 0 }, { hour: 0, minute: 0, second: 0 }]
                        promotedTimerange[1].minute = Number(selectedStart.format('mm'))
                        promotedTimerange[1].hour = Number(selectedStart.format('HH'))
                        onUpdate('promotedTimerange', promotedTimerange)
                      },
                      moment({
                        hour: _.get(editingMenu, 'promotedTimerange[0].hour'),
                        minute: _.get(editingMenu, 'promotedTimerange[0].minute'),
                      }).toDate(),
                    ))}
                  >
                    <Text>
                      {
                        _.get(editingMenu, 'promotedTimerange[1]')
                          ? moment({ hour: _.get(editingMenu, 'promotedTimerange[1].hour'), minute: _.get(editingMenu, 'promotedTimerange[1].minute') }).format('HH : mm')
                          : '- - : - -'
                      }
                    </Text>
                  </TouchableOpacity>
                </View>
              </SettingListItemRightAction>
            </SettingListItem>
          )
        }
        {
          editingMenu.promoted && (
            <SettingListItem divider>
              <SettingListItemRightAction>
                <Button
                  title={t('app.page.setting.menuPanel.menuInfo.clearTime')}
                  style={styles.button}
                  backgroundColor={colors.primary}
                  textBold
                  onPress={() => onUpdate('promotedTimerange', [])}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          )
        }
        {
          isInventoryEnabled && (
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.inventory')} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={(editingMenu.inventory || 0).toString()}
                  onChangeText={(text) => {
                    const amount = parseInt(text) || 0
                    onUpdate('inventory', amount)
                  }}
                  keyboardType='decimal-pad'
                />
              </SettingListItemRightAction>
            </SettingListItem>
          )
        }
        {isInventoryEnabled && (
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.low-inventory')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={(editingMenu.stockAlertLevel || 0).toString()}
                onChangeText={(text) => {
                  const amount = parseInt(text) || 0
                  onUpdate('stockAlertLevel', amount)
                }}
                keyboardType='decimal-pad'
              />
            </SettingListItemRightAction>
          </SettingListItem>
        )}
        <SettingListSwitchRow
          divider
          label={t('app.page.setting.menuPanel.menuInfo.openingPrompt')}
          value={editingMenu.openingPrompt}
          onChangeValue={() => onUpdate('openingPrompt', !editingMenu.openingPrompt)}
        />
        <SettingListSwitchRow
          divider
          label={t('app.page.setting.menuPanel.menuInfo.takeawayOpeningPrompt')}
          value={editingMenu.takeawayOpeningPrompt}
          onChangeValue={() => onUpdate('takeawayOpeningPrompt', !editingMenu.takeawayOpeningPrompt)}
        />
        <SettingListSwitchRow
          label={t('app.page.setting.menuPanel.menuInfo.tableAutoCount')}
          value={editingMenu.tableAutoCount}
          onChangeValue={() => onUpdate('tableAutoCount', !editingMenu.tableAutoCount)}
        />
      </SettingList>

      <OptionsSelectDialog
        title={t('app.constant.weekday.week')}
        multiple
        open={weekdaysSelectDialogOpen}
        onClose={() => setWeekdaysSelectDialogOpen(false)}
        options={weekdayOptions}
        defaultValue={editingMenu.weekdays}
        onSubmit={(weekdays) => {
          onUpdate('weekdays', _.orderBy(weekdays))
        }}
        modalProps={{ noTitle: true }}
      />

      <OptionsSelectDialog
        title={t('app.constant.weekday.week')}
        multiple
        open={promotedDaysSelectDialogOpen}
        onClose={() => setPromotedDaysSelectDialogOpen(false)}
        options={weekdayOptions}
        defaultValue={editingMenu.promotedDays}
        onSubmit={(promotedDays) => {
          onUpdate('promotedDays', _.orderBy(promotedDays))
        }}
        modalProps={{ noTitle: true }}
      />

      <OptionsSelectDialog
        title={t('app.page.setting.menuPanel.menuInfo.category')}
        open={categoriesSelectDialogOpen}
        onClose={() => setCategoriesSelectDialogOpen(false)}
        options={categoryOptions}
        defaultValue={categories[editingMenu.categoryId]?.id ?? category.id}
        onSubmit={(id) => {
          onUpdate('categoryId', id)
        }}
        modalProps={{ noTitle: true }}
      />

      <OptionsSelectDialog
        title={t('app.page.setting.menuPanel.menuInfo.department')}
        open={departmentSelectDialogOpen}
        onClose={() => setDepartmentSelectDialogOpen(false)}
        options={departmentOptions}
        closeOnSelect
        defaultValue={kitchenDepartment?.id ?? ''}
        onSubmit={(id) => {
          onUpdate('kitchenDepartmentId', id)
        }}
        modalProps={{ noTitle: true }}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({

})
