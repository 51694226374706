import { FlatList, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import defaultLabelStyle from './defaultLabelStyle'

export default function CustomizedLabelPanel (props) {
  const { onSelect } = props
  const { t } = useTranslation()

  const settings = useSelector(state => state.printer.printerSetting.customizedLabelSettings) || []

  const renderItem = ({ item, index }) => {
    return (
      <SettingListItem
        key={index}
        onPress={() => {
          onSelect(item)
        }}
        divider={index !== item.length - 1}
      >
        <SettingListItemText text={item.name} />
      </SettingListItem>
    )
  }

  return (
    <View style={styles.contentContainer}>
      <Header>
        <HeaderText text={t('app.routes.setting.printing.customized')} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={settings}
            ListEmptyComponent={(
              <View style={styles.emptyView}>
                <Text>{t('app.page.setting.printer.noSetting')}</Text>
              </View>
            )}
            keyExtractor={(item, index) => String(index + item?.id)}
            renderItem={renderItem}
          />
          <SettingListFooterAddButton
            text={t('app.page.setting.printer.add') + t('app.page.setting.printer.setting')}
            onPress={() => {
              onSelect({
                name: '',
                style: defaultLabelStyle,
                lineSpacing: 1,
              })
            }}
          />
        </SettingList>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labels: {
    padding: 0,
  },
})
