import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '@/theme/colors'

OptionTitle.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
}

export default function OptionTitle (props) {
  const { title, color, required, multiple } = props
  return (
    <View style={styles.container}>
      <Text style={[styles.title, { color }]}>
        {title}
      </Text>
      {required && (
        <Text style={[styles.title, styles.requiredStar, { color }]}>＊</Text>
      )}
      {multiple && (
        <View style={styles.conditionView}>
          <Text style={styles.conditionText}>多選</Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    margin: 8,
  },
  conditionView: {
    height: 25,
    backgroundColor: colors.tertiary,
    borderRadius: 19,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 6,
  },
  requiredStar: {
    fontWeight: '700',
    position: 'absolute',
    left: -16,
  },
  conditionText: {
    color: colors.primary,
    fontSize: 10,
  },
})
