import AsyncStorage from '@react-native-async-storage/async-storage'

import { dimorderApi, dimorderLib } from '@/libs/api/dimorder'
import delay from '@/libs/delay'
import logger, { convertToSimpleOrder } from '@/libs/logger'

import ActionTypes from './ActionTypes'
import _ from 'lodash'
import actions from '../actions'
import moment from 'moment'
import * as OrderLocalDatabase from '@/libs/orderLocalDatabase'

/**
 * 將 unsyncOrderIds 存到 AsyncStorage
 * @returns {ThunkFunction}
 */
export function storeUnsyncOrderIds () {
  return async (dispatch, getState) => {
    try {
      const pendingSyncOrderIds = getState().unsyncOrder.pendingOrderIds
      const syncingOrderId = getState().unsyncOrder.syncingOrderId
      const unsyncOrderIdsJson = JSON.stringify(pendingSyncOrderIds.concat([syncingOrderId].filter(orderId => orderId !== null)))
      await AsyncStorage.setItem('unsyncOrderIds', unsyncOrderIdsJson)
    } catch (error) {
      logger.error(`[unsyncOrder/storeUnsyncOrderIds] error: ${error?.message ?? error.toString()}`, { error })
    }
  }
}

/**
 * 從 AsyncStorage 還原 unsyncOrderIds
 * @returns {ThunkFunction}
 */
export function restoreUnsyncOrderIds () {
  return async (dispatch, getState) => {
    try {
      const disableOrderSync = getState().app.settings.debugSettings.disableOrderSync
      if (disableOrderSync) return

      const unsyncOrderIdsJson = await AsyncStorage.getItem('unsyncOrderIds')
      if (!unsyncOrderIdsJson) return
      const restoreUnsyncOrderIds = JSON.parse(unsyncOrderIdsJson)
      dispatch({
        type: ActionTypes.RESTORE_UNSYNC_ORDER_IDS,
        payload: { orderIds: restoreUnsyncOrderIds },
      })
      logger.log('[unsyncOrder/restoreUnsyncOrderIds] Done, restore unsyncOrderIds', { restoreUnsyncOrderIds })
    } catch (error) {
      logger.error(`[unsyncOrder/restoreUnsyncOrderIds] error: ${error?.message || error.toString()}`, { error })
    }
  }
}

/**
 * 新增不重複的 orderIds 到 unsyncOrderIds
 * @param {string[]} orderIds
 * @returns {ThunkFunction}
 */
export function addUnsyncOrderIds (orderIds = []) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_UNSYNC_ORDER_IDS,
      payload: { orderIds },
    })
  }
}

/**
 * 從 unsyncOrderIds 移除 orderIds
 * @param {string[]} orderIds
 * @returns {ThunkFunction}
 */
export function removeUnsyncOrderIds (orderIds = []) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.REMOVE_UNSYNC_ORDER_IDS,
      payload: { orderIds },
    })
  }
}

/**
 * 將前端的 order 同步到後端
 * @returns {ThunkFunction}
 */
export function syncData () {
  return async (dispatch, getState) => {
    const { disableOrderSync } = getState().app.settings.debugSettings
    const pendingSyncOrderIds = getState().unsyncOrder.pendingOrderIds

    logger.log('[syncData] syncData() called', { disableOrderSync, pendingSyncOrderIds })

    if (disableOrderSync) return
    if (pendingSyncOrderIds.length === 0) return

    dispatch(updateIsSyncingData(true))

    // 如沒網絡，dimorderApi.order.syncOrder 內部出現 undefined error
    // 記錄 syncOrder 有沒有出現 error
    let isSyncError = false
    for await (const orderId of pendingSyncOrderIds) {
      // 從歷史訂單中找出 unsyncOrder
      const unsyncOrder = getState().orderHistory.orders.find(order => order.id === orderId)

      // 解決一張訂單不見了，後續訂單也不能sync的問題
      // *** Remove undefined order's orderId in unsyncOrderIds
      if (_.isNil(unsyncOrder)) {
        logger.warn(`[unsyncOrder/syncData] unsyncOrderId not found in redux.orderHistory.orders, unsyncOrderId:${orderId}, Count unsyncOrderIds: ${pendingSyncOrderIds?.length}, Count redux.orderHistory.orders:${getState().orderHistory.orders.length}`,
          { orderId, pendingSyncOrderIds })
        await dispatch(removeUnsyncOrderIds([orderId]))
        continue
      }

      logger.log(`[unsyncOrder/syncData] start: ${unsyncOrder.serial}`, {
        order: convertToSimpleOrder(unsyncOrder),
      })

      // 將 order 格式從 app 用轉為後端 api 格式
      const apiOrder = dimorderLib.appOrderToApiOrder(unsyncOrder)

      try {
        // request /orderimport，若和後端資料有衝突，後端會把 batches, betch[].items, modifiers, payments merge

        // 從unsyncOrderIds移除正在同步的 order id
        dispatch(removeUnsyncOrderIds([orderId]))
        // 新增正在同步的 orderId 到 syncingOrderId
        dispatch(updateSyncingOrderId(orderId))

        // 將結果 response 回來以更新前端的 orderHistory,    * 如沒網絡會有內部undefined error
        const response = await dimorderApi.order.syncOrder([apiOrder])
        // 一次只會上傳一張訂單，所以直接拿 [0]
        const responseOrder = response?.orders?.[0]
        if (responseOrder) {
          // 將 api 格式的 Order 轉成 App 用的格式
          const appOrder = dimorderLib.apiOrderToAppOrder(responseOrder)
          appOrder.syncedAt = moment().utc().toISOString()

          logger.log(`[unsyncOrder/syncData] success: ${unsyncOrder.serial}`, {
            order: convertToSimpleOrder(appOrder),
          })

          // 移除已同步的 order id，注意這邊要先移除才能用 updateOrdersFromServer，否則 updateOrdersFromServer 無法更新本地的訂單
          dispatch(updateSyncingOrderId(null))
          // 將 syncOrder 後端 merge 的 order 更新回本地
          dispatch(actions.orderHistory.updateOrdersFromServer([appOrder], '/orderimport response', false))
        } else {
          logger.log('[unsyncOrder/syncData] success, but no response order')
          // 使用之前的方式處理 version
          dispatch(actions.orderHistory.updateOrderSync([unsyncOrder.id]))
          // 移除已同步的 order id
          dispatch(updateSyncingOrderId(null))

          // 更新到Local Database
          const updatedOrder = getState().orderHistory.orders.find(order => order.id === orderId)
          await OrderLocalDatabase.upsertOrder(updatedOrder)
        }
      } catch (error) {
        logger.error(`[unsyncOrder/syncData] error: ${error?.message || error.toString()}`, { error })
        dispatch(addUnsyncOrderIds([orderId]))
        dispatch(updateSyncingOrderId(null))
        isSyncError = true // 記錄有 Error
      }
    }

    if (isSyncError) {
      // 當發生錯誤時，5 秒後才解放重試，避免 Infinite loop
      await delay(5000)
    }
    dispatch(updateIsSyncingData(false))
  }
}

/**
 * @param {string} syncingOrderId
 * @returns {ThunkFunction}
 */
export function updateSyncingOrderId (syncingOrderId) {
  return {
    type: ActionTypes.UPDATE_SYNCING_ORDER_ID,
    payload: { syncingOrderId },
  }
}

/**
 * @param {boolean} open
 * @returns {ThunkFunction}
 */
export function updateDialogOpen (open) {
  return {
    type: ActionTypes.UPDATE_DIALOG_OPEN,
    payload: { open },
  }
}

/**
 * @param {boolean} open
 * @returns {ThunkFunction}
 */
export function updateIsSyncingData (isSyncingData) {
  return {
    type: ActionTypes.UPDATE_IS_SYNCING_DATA,
    payload: { isSyncingData },
  }
}
