import { StyleSheet, Text, View } from 'react-native'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Row from '@/components/Row'

export default function SummaryRow ({ title, count, amount, textStyle, isString = false }) {
  const { t } = useTranslation()
  return (
    <Row style={styles.tableRow}>
      <View style={styles.item}>
        <Text style={textStyle}>{t(title)}</Text>
      </View>
      <View style={styles.number}>
        <Text style={textStyle}>{count}</Text>
      </View>
      <View style={styles.amount}>
        <Text style={textStyle}>{isString ? amount : currencyWithCommas(amount)}</Text>
      </View>
    </Row>
  )
}

const styles = StyleSheet.create({
  tableRow: {
    marginTop: 15,
  },
  item: {
    width: 280,
  },
  number: {
    width: 160,
    display: 'flex',
    alignItems: 'flex-end',
  },
  amount: {
    width: 160,
    display: 'flex',
    alignItems: 'flex-end',
  },
})
