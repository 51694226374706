import { actions, useDispatch } from '@/redux'
import { useEffect } from 'react'
import { useHistory } from '@/libs/reactRouter'

/**
 * 初始化 App 環境設定
 */
export default function useInitializeApp () {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    // 將 applicatiionHistory 放進 window，以便 actions 使用
    if (!window.applicatiionHistory) {
      Object.defineProperty(window, 'applicatiionHistory', {
        value: history,
      })
    }

    dispatch(actions.app.initApp())

    return () => {
      // 卸載時將還沒有送出的 log 送出
      dispatch(actions.app.destoryApp())
    }
  }, [])
}
