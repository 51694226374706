import { Badge, Icon } from 'react-native-elements'
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { useIsOnline } from '@/hooks/app'
import { useLocation } from '@/libs/reactRouter'
import { useRequestWaiterOrders } from '@/hooks/orderHistory'
import colors from '@/theme/colors'
import i18n from '@/i18n'

const isApp = Platform.OS !== 'web'

/**
 * @typedef NavButtonProps
 * @param {object} navItem
 * @param {number} unConfirmCount
 */

/**
 *
 * @param {NavButtonProps} props
 * @returns
 */
export default function NavButton (props) {
  const { navItem, unConfirmCount } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const isSavedSetting = useSelector(state => state.app.isSavedSetting)
  const { isMaster } = useSelector(state => state.app.settings)
  const { t } = useTranslation()
  // active example: '/pathname', '/pathname/', '/pathname/ooxx'
  const regexp = new RegExp(`^\\/${navItem.key}((\\/\\w+)+|\\/?)$`)
  const isActive = regexp.test(location.pathname)
  const merchant = useSelector(state => state.merchant.data)

  const quickMode = merchant?.setting?.quickMode
  const [requestWaiterOrders] = useRequestWaiterOrders()
  const { isOnline } = useIsOnline()
  const handleLogout = async () => {
    dispatch(actions.auth.getUserInfo())
    dispatch(actions.orderHistory.stopWebSocketOrdersUpdater())
    dispatch(actions.auth.userLogout())
    window.applicatiionHistory.replace('/login')
  }
  const disabled = isApp && !isOnline && (navItem.key === 'stats' || navItem.key === 'setting')

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={() => {
        if (navItem.key === 'logout') {
          dispatch(actions.app.showAlert({
            title: (isMaster ? t('app.page.setting.alert.logoutMasterMessage.title') : t('app.page.setting.alert.logoutMessage.title')),
            message: (isMaster ? t('app.page.setting.alert.logoutMasterMessage.message') : t('app.page.setting.alert.logoutMessage.message')),
            buttons: [
              {
                backgroundColor: colors.light,
                textColor: colors.textTertiary,
                children: t('app.page.order.stash.alert.leave.button.cancel'),
                onPress: () => { },
              },
              {
                children: t('app.page.setting.localPanel.logout'),
                onPress: () => {
                  handleLogout()
                },
              },
            ],
          }))
        }
        if (location.pathname !== '/setting/menu/menu' && location.pathname !== '/setting/menu/set') {
          dispatch(actions.setting.updateSearchText(''))
        }
        if (location.pathname === '/orderHistory/checkout/result') {
          dispatch(actions.orderCheckout.cleanCheckOutOrder())
        }
        if (location.pathname === '/order') {
          if (!quickMode && navItem.key !== 'notification') {
            dispatch(actions.app.showAlert({
              title: i18n.t('app.page.order.stash.alert.leave.title'),
              message: i18n.t('app.page.order.stash.alert.leave.msg'),
              buttons: [
                {
                  children: i18n.t('app.page.order.stash.alert.leave.button.continue'),
                  onPress: () => {
                    // 清除 Batch
                    dispatch(actions.orderBatch.resetBatch())
                    if (navItem.path) {
                      if (navItem.path === '/stats') {
                        dispatch(actions.auth.permissionCheck('stats', () => {
                          history.push(navItem.path)
                        }))
                      } else {
                        history.push(navItem.path)
                      }
                    }
                  },
                },
                {
                  backgroundColor: colors.tertiary,
                  textColor: colors.white,
                  children: i18n.t('app.page.order.stash.title'),
                  onPress: () => {
                    dispatch(actions.orderBatch.stashBatch(navItem.path))
                  },
                },
                {
                  backgroundColor: colors.light,
                  textColor: colors.textTertiary,
                  children: i18n.t('app.page.order.stash.alert.leave.button.cancel'),
                  onPress: () => { },
                },
              ],
            }))
          } else {
            dispatch(actions.orderBatch.resetBatch())
            if (navItem.path) {
              if (navItem.path === '/stats') {
                dispatch(actions.auth.permissionCheck('stats', () => {
                  history.push(navItem.path)
                }))
              } else {
                history.push(navItem.path)
              }
            }
          }
        } else if (!isSavedSetting) {
          dispatch(actions.app.showAlert({
            title: i18n.t('app.page.order.stash.alert.leave.title'),
            message: i18n.t('app.page.order.stash.alert.leave.msg2'),
            buttons: [
              {
                children: i18n.t('app.page.order.stash.alert.leave.button.continue'),
                onPress: () => {
                  dispatch(actions.app.updateIsSavedSetting(true))
                  history.push(navItem.path)
                },
              },
              {
                backgroundColor: colors.light,
                textColor: colors.textTertiary,
                children: i18n.t('app.page.order.stash.alert.leave.button.cancel'),
                onPress: () => { },
              },
            ],
          }))
        } else if (navItem.path) {
          if (navItem.path === '/stats') {
            dispatch(actions.auth.permissionCheck('stats', () => {
              history.push(navItem.path)
            }))
          } else if (navItem.path === '/order' && quickMode) {
            dispatch(actions.auth.permissionCheck('order-takeaway', () => {
              history.push(navItem.path)
            }))
          } if (navItem.path === '/member') {
            dispatch(actions.auth.permissionCheck('merchant-portal-crm', () => {
              history.push(navItem.path)
            }))
          } else {
            history.push(navItem.path)
          }
        }
        if (navItem.key === 'notification') {
          dispatch(actions.app.showDialog(['notification', 'requestWaiter']))
        }
      }}
    >
      <View style={[styles.container, isActive ? styles.active : styles.inactive]}>
        {disabled &&
          <View style={styles.unavailableIcon}>
            <Icon
              name='slash'
              type='font-awesome-5'
              color={colors.red}
            />
          </View>}
        {navItem.type ? (
          <Icon
            size={30}
            type={navItem.type}
            name={navItem.iconSource}
          />)
          : (<Image style={styles.icon} source={navItem.iconSource} />)}

        <Text style={styles.title}>
          {navItem.title}
        </Text>
      </View>
      {(navItem.key === 'orderHistory' && unConfirmCount > 0) &&
        <Badge value={unConfirmCount} status='error' containerStyle={{ position: 'absolute', top: 4, right: 14 }} />}
      {(navItem.key === 'notification' && requestWaiterOrders.length > 0) &&
        <Badge value={requestWaiterOrders.length} status='error' containerStyle={{ position: 'absolute', top: 4, right: 14 }} />}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 80,
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    paddingTop: 24,
    paddingBottom: 24,
  },
  active: {

  },
  inactive: {
    opacity: 0.4,
  },
  unavailableIcon: {
    position: 'absolute',
    width: 38,
    height: 38,
  },
  icon: {
    width: 38,
    height: 38,
  },
  title: {
    color: colors.primary,
  },

})
