import { FlatList, StyleSheet, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'

import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CancelReasonPanel (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const customReason = useSelector(state => state.setting.customReason)
  const [selectedReason, setSelectedReason] = React.useState(null)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const typeOptions = [
    { value: '', display: t('app.page.setting.menuPanel.customReason.all') },
    { value: 'dineIn', display: t('app.page.setting.menuPanel.customReason.dineIn') },
    { value: 'takeaway', display: t('app.page.setting.menuPanel.customReason.takeaway') },
    { value: 'item', display: t('app.page.setting.menuPanel.customReason.item') },
  ]

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.common.delete'),
      message: `${t('app.common.is') + t('app.common.delete')}${selectedReason?.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            dispatch(actions.setting.deleteCustomReason(selectedReason?.id))
            setSelectedReason(null)
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSubmit = () => {
    dispatch(actions.setting.upsertCustomReason(selectedReason))
    setSelectedReason(null)
  }

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  const renderItem = ({ item, index }) => {
    return (
      <SettingListItem
        divider
        key={item.id}
        onPress={() => {
          setSelectedReason(item)
        }}
      >
        <SettingListItemText text={item?.name} />
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.menuPanel.customReason.title')} />
        </Header>
        <View style={styles.content}>
          <SettingList style={{ flex: 1 }}>
            <FlatList
              containerStyle={{ flex: 1 }}
              showsVerticalScrollIndicator={false}
              data={customReason}
              keyExtractor={item => item.id}
              renderItem={renderItem}
              onDragEnd={({ data }) => {
              }}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.menuPanel.customReason.add')}
              onPress={() => {
                setSelectedReason({
                  name: '',
                  type: '',
                  enable: true,
                })
              }}
            />
          </SettingList>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <View style={styles.content}>
          {
            selectedReason && (
              <>
                <Header>
                  <HeaderText text={t('app.page.setting.menuPanel.customReason.setting')} />
                </Header>

                <SettingList>
                  {
                    selectedReason.id
                      ? (
                        <SettingListItem divider>
                          <SettingListItemText text={t('app.page.setting.menuPanel.customReason.type')} />
                          <SettingListItemRightAction>
                            <SettingListItemText text={typeOptions.find(t => t.value === selectedReason.type)?.display || selectedReason.type} />
                          </SettingListItemRightAction>
                        </SettingListItem>
                      )
                      : (
                        <SettingListEnterRow
                          divider
                          label={t('app.page.setting.menuPanel.customReason.type')}
                          value={typeOptions.find(t => t.value === selectedReason.type)?.display || selectedReason.type}
                          onPress={() => {
                            setOptionsPanelConfig({
                              title: t('app.page.setting.menuPanel.customReason.type'),
                              defaultValue: selectedReason.type || 0,
                              onChangeValue: (value) => setSelectedReason({ ...selectedReason, type: value }),
                              options: typeOptions,
                              closeOnSelect: true,
                            })
                          }}
                        />
                      )
                  }
                  <SettingListItem divider>
                    <SettingListItemText text={t('app.page.setting.menuPanel.customReason.name')} />
                    <SettingListItemRightAction>
                      <SettingListInput
                        value={selectedReason.name}
                        onChangeText={(text) => {
                          setSelectedReason({ ...selectedReason, name: text })
                        }}
                      />
                    </SettingListItemRightAction>
                  </SettingListItem>
                  <SettingListSwitchRow
                    label={t('app.page.setting.menuPanel.customReason.enable')}
                    value={selectedReason.enable}
                    onChangeValue={() => {
                      setSelectedReason({ ...selectedReason, enable: !selectedReason.enable })
                    }}
                  />
                </SettingList>
              </>
            )
          }
        </View>
        {
          selectedReason && (
            <View style={styles.buttonContainer}>
              <Button
                textBold
                title={t('app.common.delete')}
                style={styles.button}
                onPress={handleDelete}
                backgroundColor={colors.primary}
              />
              <Button
                textBold
                title={t('app.common.save')}
                style={styles.button}
                onPress={handleSubmit}
                backgroundColor={colors.darkSecondary}
              />
            </View>
          )
        }
      </View>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
