import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import TextInputDialog from '@/components/dialogs/TextInputDialog'
import ToggleSwitch from '@/components/ToggleSwitch'

// eslint-disable-next-line no-unused-vars
import { IShipping } from 'dimorder-orderapp-lib/dist/types/Merchant'

const DISCOUNT = { minOrderAmount: 0, amount: 0 }

/**
 * @typedef ShippingInfoProps
 * @property {IShipping} shippingMethod
 * @property {() => void} onClose
 */

/**
 *
 * @param {ShippingInfoProps} props
 * @returns
 */
export default function ShippingInfo (props) {
  const { shippingMethod, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [adminDialogOpen, setAdminDialogOpen] = React.useState(false)
  const [shippingMethodState, setshippingMethodState] = React.useState(shippingMethod)
  const [discountSetting, setDiscountSetting] = React.useState(false)
  const [shippingDiscountState, setShippingDiscount] = React.useState(shippingMethod?.discounts?.length ? shippingMethod.discounts[0] : DISCOUNT)

  if (!shippingMethod) return null

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: `${t('app.common.delete') + t('app.page.setting.checkout.shipping')}`,
      message: `${t('app.common.is') + t('app.common.delete') + shippingMethod.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            dispatch(actions.merchant.deleteShippingMethod(shippingMethodState))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    onClose()
    dispatch(actions.merchant.upsertShippingMethod({ ...shippingMethodState, discounts: [shippingDiscountState] }))
  }

  return (
    <>
      <Header>
        <HeaderText text={shippingMethod.key ? t('app.page.setting.checkout.shipping') : `${t('app.page.setting.checkout.add') + t('app.page.setting.checkout.shipping')}`} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.shippingName')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={shippingMethodState.name}
                onChangeText={(text) => {
                  setshippingMethodState({
                    ...shippingMethodState,
                    name: text,
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.cost')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={String(shippingMethodState.cost)}
                onChangeText={(text) => {
                  if (!Number.isNaN(Number(text))) {
                    setshippingMethodState({
                      ...shippingMethodState,
                      cost: text,
                    })
                  }
                }}
                onBlur={(event) => {
                  setshippingMethodState({
                    ...shippingMethodState,
                    cost: Number(event.nativeEvent.text),
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.minAmount')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={String(shippingMethodState.minAmount)}
                onChangeText={(text) => {
                  if (!Number.isNaN(Number(text))) {
                    setshippingMethodState({
                      ...shippingMethodState,
                      minAmount: text,
                    })
                  }
                }}
                onBlur={(event) => {
                  setshippingMethodState({
                    ...shippingMethodState,
                    minAmount: Number(event.nativeEvent.text),
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.minDeliveryMinute')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={String(shippingMethodState.minDeliveryMinute)}
                onChangeText={(text) => {
                  if (/^\d+$/.test(text)) {
                    setshippingMethodState({
                      ...shippingMethodState,
                      minDeliveryMinute: Number(text),
                    })
                  } else if (text === '') {
                    setshippingMethodState({
                      ...shippingMethodState,
                      minDeliveryMinute: 0,
                    })
                  }
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.to')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={String(shippingMethodState.maxDeliveryMinute)}
                onChangeText={(text) => {
                  if (/^\d+$/.test(text)) {
                    setshippingMethodState({
                      ...shippingMethodState,
                      maxDeliveryMinute: Number(text),
                    })
                  } else if (text === '') {
                    setshippingMethodState({
                      ...shippingMethodState,
                      maxDeliveryMinute: 0,
                    })
                  }
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.start')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={shippingMethodState.enabled}
                onChangeValue={() => {
                  setshippingMethodState({
                    ...shippingMethodState,
                    enabled: !shippingMethodState.enabled,
                  })
                  console.log(shippingMethodState)
                }}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.shippingDiscount')} />
            <SettingListItemRightAction>
              <Button
                textBold
                title='Internal Use'
                backgroundColor={colors.gray}
                onPress={() => {
                  setAdminDialogOpen(true)
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          {
            discountSetting && (
              <>
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.checkout.minOrderAmount')} />
                  <SettingListItemRightAction>
                    <SettingListInput
                      value={String(shippingDiscountState?.minOrderAmount)}
                      onChangeText={(text) => {
                        if (!Number.isNaN(Number(text))) {
                          setShippingDiscount({
                            ...shippingDiscountState,
                            minOrderAmount: text,
                          })
                        }
                      }}
                      onBlur={(event) => {
                        setShippingDiscount({
                          ...shippingDiscountState,
                          minOrderAmount: Number(event.nativeEvent.text),
                        })
                      }}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.checkout.shippingDiscountAmount')} />
                  <SettingListItemRightAction>
                    <SettingListInput
                      value={String(shippingDiscountState?.amount)}
                      onChangeText={(text) => {
                        if (!Number.isNaN(Number(text))) {
                          setShippingDiscount({
                            ...shippingDiscountState,
                            amount: text,
                          })
                        }
                      }}
                      onBlur={(event) => {
                        setShippingDiscount({
                          ...shippingDiscountState,
                          amount: Number(event.nativeEvent.text),
                        })
                      }}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              </>
            )
          }

        </SettingList>
      </View>

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          disabled={!shippingMethod.key}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>
      <TextInputDialog
        open={adminDialogOpen}
        onClose={() => setAdminDialogOpen(false)}
        secureTextEntry
        title={t('app.page.setting.menuPanel.menuInfo.dialog.admin.title')}
        placeholder={t('app.page.setting.menuPanel.menuInfo.dialog.admin.placeholder')}
        onSubmit={async (text) => {
          try {
            await dimorderApi.auth.scopeLogin('admin', text)
            setDiscountSetting(true)
          } catch (error) {
            dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.login')))
          }
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
