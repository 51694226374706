import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import { useTranslation } from 'react-i18next'
import BackButton from '../../Checkout/OrderSummary/OrderHeader/BackButton'
import Button from '@root/src/components/buttons/Button'
import Column from '@root/src/components/Column'
import React from 'react'
import Row from '@root/src/components/Row'
import colors from '@root/src/theme/colors'

/**
 * @typedef MemberDetailsProps
 * @property {IMember} member
 * @property {() => void} setMember
 * @property {() => void} showPointsDetail
 */

/**
 * @param {MemberDetailsProps} props
 * @returns
 */
export default function MemberDetails (props) {
  const { t } = useTranslation()
  const { member, setMember, showPointsDetail } = props

  return (
    <>
      <Row style={styles.container}>
        <View style={{ paddingTop: 10 }}>
          <BackButton onPress={() => setMember(null)} />
        </View>
        <Column style={styles.content}>
          <View style={{ marginBottom: 20 }}>
            <Text style={styles.titleText}>{member.name}</Text>
            <Text style={styles.titleText}>{member.mobile}</Text>
          </View>
          <Text style={styles.text}>{t('app.page.Member.MemberInfo.spendingTotal')}：{member.total}</Text>
          <Text style={styles.text}>{t('app.page.Member.MemberInfo.spendingTimes')}：{member.bought}</Text>
          <Text style={styles.text}>{t('app.page.Member.MemberInfo.availablePoints')}：{member.points}</Text>

          <Button
            style={{ marginTop: 40, width: 150, marginLeft: -10 }}
            title={t('app.page.Member.MemberInfo.checkPointsRecords')}
            onPress={showPointsDetail}
          />
        </Column>
      </Row>

    </>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '23%',
    backgroundColor: colors.primary,
  },
  content: {
    marginTop: 10,
  },
  titleText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 'bold',
    color: colors.white,
  },
  text: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '500',
    color: colors.white,
  },
})
