import { StyleSheet } from 'react-native'
import React from 'react'

import { useSelectedOrder } from '@/hooks/orderHistory'
import Column from '@/components/Column'
import PriceSummary from '@/components/PriceSummary'
import shadows from '@/theme/shadows'

import OrderDetail from './OrderDetail'
import OrderHeader from './OrderHeader'
import PriceTotal from './PriceTotal'

export default function OrderSummary () {
  const selectedOrder = useSelectedOrder()
  return (
    <Column style={styles.container}>
      <OrderHeader />
      <OrderDetail />
      <PriceSummary order={selectedOrder} />
      <PriceTotal order={selectedOrder} />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 408,
    height: '100%',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 1, // Compare to Payment
    elevation: 1, // Compare to Payment
    ...shadows.default,
  },
})
