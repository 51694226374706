import moment from 'moment'

import store from '@/redux/store'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/** @type {() => IRootState} */
const getState = store.getState

/**
 * 篩選出叫侍應的 orders
 * @param {IAppOrder} orders
 * @returns {IAppOrder}
 */
export function filterRequestWaiterOrders (orders = []) {
  return orders.filter(order => {
    // 檢查訂單是否叫侍應
    return (
      order.serviced && // 訂單的叫侍應 flag 為 true
      !['paid', 'cancelled'].includes(order.status) && // 訂單尚未結束
      moment().diff(order.createdAt, 'day') < 1 // 一天內的訂單才處理
    )
  })
}

/**
 * 篩選出請求付款的 orders
 * @param {IAppOrder} orders
 * @returns {IAppOrder}
 */
export function filterRequestPayingOrders (orders = []) {
  return orders.filter(order => {
    // 檢查訂單是否請求付款
    return (
      order.paying && // 訂單的請求付款 flag 為 true
      order.displayStatusKey === 'waiting_pay' // 正在付款中的訂單才處理
    )
  })
}

export function getHistoryOrder (orderId) {
  const orders = getState().orderHistory.orders
  const selectedOrderId = orderId || getState().orderHistory.selectedOrderId
  return orders.find(order => order.id === selectedOrderId)
}

/**
 * 防止訂單flag被刪除
 * @param {IAppOrder} nextOrder
 * @returns
 */
export function preserveValidOrderFlags (nextOrder) {
  const unsyncOrderIds = getState().unsyncOrder.pendingOrderIds
  const syncingOrderId = getState().unsyncOrder.syncingOrderId

  syncingOrderId && unsyncOrderIds.push(syncingOrderId)

  // 如果不是 unsyncOrderIds 裡的訂單，就不處理
  if (!unsyncOrderIds.includes(nextOrder.id)) return nextOrder

  const order = getHistoryOrder(nextOrder.id)

  // prevent pointsClaimPassword from being deleted
  if (order?.pointsClaimPassword) {
    nextOrder.pointsClaimPassword = order.pointsClaimPassword
  }

  return nextOrder
}
