import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

export default function ActionButton ({ text, iconSource, style, textStyle, disabled, onPress }) {
  const buttonStyle = {
    backgroundColor: disabled ? colors.superlightPrimary : colors.lightPrimary,
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
    >
      <View style={[styles.container, buttonStyle, style]}>
        <Image style={styles.icon} source={iconSource} />
        <Text style={[styles.text, textStyle]}>{text}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 70,
    width: 70,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.lightPrimary,
    borderRadius: 5,
  },
  icon: {
    height: 40,
    width: 40,
    resizeMode: 'contain',
  },
  text: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.white,
  },
})
