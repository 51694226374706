import { FlatList, StyleSheet } from 'react-native'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import Column from '@/components/Column'

import OrderItem from './OrderItem'

/* eslint-disable no-unused-vars */
import { IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IOrderSummary } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

/**
 * @param {{batch: IAppOrderBatch[], itemSerials: object, expandedSetItems: object, summary: IOrderSummary[] }} props
 * @returns
 */
export default function OrderItemList ({
  batch,
  itemSerials,
  expandedSetItems,
  setExpandedSetItems,
  summary,
}) {
  const dispatch = useDispatch()
  const selectedOrderItems = useSelector(
    (state) => state.orderHistory.selectedOrderItems,
  )
  const [setItemsExpaned, setSetItemsExpaned] = React.useState(true)

  const renderItem = ({ item, index }) => {
    const isSelected = Boolean(selectedOrderItems.find((i) => i.id === item.id))

    return (
      <OrderItem
        serial={itemSerials.find((i) => i.id === item.id).itemSerial}
        item={item}
        batch={batch}
        summary={summary}
        isSelected={isSelected}
        isSetItemsExpaned={setItemsExpaned}
        onPress={(pressedItem) => {
          if (pressedItem.isSet && setItemsExpaned === isSelected) {
            // 當按下套餐時，展開狀態和選取狀態一致，要控制套餐是否展開或收起
            // 例如：
            //   已展開且已選擇時被點選，收起套餐
            //   未展開且未選擇時被點選，展開套餐
            setExpandedSetItems({ ...expandedSetItems, [pressedItem.id]: !isSelected })
            setSetItemsExpaned(!setItemsExpaned)
          }

          // toggle 選擇狀態
          dispatch(actions.orderHistory.toggleSelectItem(pressedItem))
        }}
      />
    )
  }

  return (
    <Column style={styles.container}>
      <FlatList
        data={batch.items}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        showsVerticalScrollIndicator={false}
        extraData={selectedOrderItems}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
})
