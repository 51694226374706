import { Animated, KeyboardAvoidingView, StyleSheet, View } from 'react-native'
import React, { useRef, useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import AnimatedBackground from '@/components/AnimatedBackground'

import AnimatedLogo from './AnimatedLogo'
import EmployeeLogin from './EmployeeLogin'
import MerchantLogin from './MerchantLogin'
import StatusBar from './StatusBar'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Login (props) {
  const dispatch = useDispatch()
  const isMerchantLogin = useSelector(state => state.auth.isMerchantLogin)
  const [islogoHidden, setIsLogoHidden] = useState(false)
  const [showLoginBox, setShowLoginBox] = useState(false)
  const animationValue = useRef(new Animated.Value(0)).current

  React.useEffect(() => {
    if (isMerchantLogin) {
      goToEmployeeLogin()
    }
  }, [isMerchantLogin])

  const goToEmployeeLogin = () => {
    animateGo()
  }

  const goToMerchantLogin = () => {
    dispatch(actions.auth.merchantLogout())
    animateBack()
  }

  const animateGo = () => {
    Animated.timing(
      animationValue,
      {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      },
    ).start()
  }

  const animateBack = () => {
    Animated.timing(
      animationValue,
      {
        toValue: 0,
        duration: 500,
        useNativeDriver: false,
      },
    ).start()
  }

  const onLongPress = () => {
    setShowLoginBox(true)
  }

  return (
    <KeyboardAvoidingView style={styles.container} behavior='height'>
      <AnimatedBackground animationValue={animationValue} />
      <View style={styles.overlay} />
      <View style={styles.content}>
        {!isMerchantLogin
          ? <MerchantLogin onNext={goToEmployeeLogin} />
          : <EmployeeLogin onBack={goToMerchantLogin} setIsLogoHidden={setIsLogoHidden} showLoginBox={showLoginBox} />}
      </View>
      <StatusBar />
      <AnimatedLogo animationValue={animationValue} hide={islogoHidden} onLongPress={onLongPress} />
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.light,
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.lightPrimary,
    opacity: 0.5,
  },
  content: {
    flex: 1,
    width: '100%',
    paddingLeft: 114.5,
  },
})
