import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import PrintingSettingList from '../PrintingSettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * }} props
 */
export default function InfoSetting (props) {
  const { setting, onChange } = props
  const { t } = useTranslation()
  const [selecting, setSelecting] = React.useState(false)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const { orderSerialDigit = 3 } = useSelector(state => state.printer.printerSetting.defaultSettings)

  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 5, display: t('app.page.setting.printer.size5') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  return (
    <>
      <PrintingSettingList
        divider
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.preOrderAlert')}
        path='content.preOrderAlert'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      >
        <SettingListEnterRow
          label={'-' + t('app.page.setting.printer.customizedSetting.specifiedDay')}
          value={String(setting.content.preOrderAlert.specifiedDayTime.day)}
          onPress={() => {
            setOptionsPanelConfig({
              title: t('app.page.setting.printer.customizedSetting.specifiedDay'),
              defaultValue: setting.content.preOrderAlert.specifiedDayTime.day,
              onChangeValue: (value) => onChange('content.preOrderAlert.specifiedDayTime.day', value),
              options: _.flatMap(new Array(11), (x, index) => { return { value: index, display: index } }),
              closeOnSelect: true,
            })
          }}
        />
        <SettingListEnterRow
          label={'-' + t('app.page.setting.printer.customizedSetting.specifiedTime')}
          value={String(setting.content.preOrderAlert.specifiedDayTime.hour)}
          onPress={() => {
            setOptionsPanelConfig({
              title: t('app.page.setting.printer.customizedSetting.specifiedTime'),
              defaultValue: setting.content.preOrderAlert.specifiedDayTime.hour,
              onChangeValue: (value) => onChange('content.preOrderAlert.specifiedDayTime.hour', value),
              options: _.flatMap(new Array(24), (x, index) => { return { value: index, display: index } }),
              closeOnSelect: true,
            })
          }}
        />
      </PrintingSettingList>
      <PrintingSettingList
        divider
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.status')}
        path='content.status'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      />
      <PrintingSettingList
        divider
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.tableNo')}
        path='content.tableNo'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      >
        <SettingListItem>
          <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.printTable')} />
          <SettingListItemRightAction>
            <ToggleSwitch
              value={_.get(setting, 'content.tableNo.printTable', true)}
              onChangeValue={() => onChange('content.tableNo.printTable', !_.get(setting, 'content.tableNo.printTable', true))}
              size={23}
            />
          </SettingListItemRightAction>
        </SettingListItem>
      </PrintingSettingList>
      <PrintingSettingList
        divider
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.customerNo')}
        path='content.customerNo'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      />
      <PrintingSettingList
        atLast
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.serialNo')}
        path='content.serialNo'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      >
        <SettingListItem>
          <SettingListItemText text={t('app.page.setting.printer.customizedSetting.last3Digit', { digit: orderSerialDigit })} />
        </SettingListItem>
        <SettingListItem>
          <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
          <SettingListItemRightAction>
            <ToggleSwitch
              value={_.get(setting, 'content.serialNo.last3Digit.bold', false)}
              onChangeValue={() => onChange('content.serialNo.last3Digit.bold', !_.get(setting, 'content.serialNo.last3Digit.bold', false))}
              size={23}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListEnterRow
          label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
          value={textStyleOptions.find(t => t.value === _.get(setting, 'content.serialNo.last3Digit.fontSize', 1))?.display || ''}
          onPress={() => {
            setOptionsPanelConfig({
              title: t('app.page.setting.printer.customizedSetting.fontSize'),
              defaultValue: setting.content?.serialNo?.last3Digit?.fontSize || 1,
              onChangeValue: (value) => onChange('content.serialNo.last3Digit.fontSize', value),
              options: textStyleOptions,
              closeOnSelect: true,
            })
          }}
        />
        <SettingListItem divider>
          <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.onlyLast3Digit', { digit: orderSerialDigit })} />
          <SettingListItemRightAction>
            <ToggleSwitch
              value={_.get(setting, 'content.serialNo.last3Digit.enable', false)}
              onChangeValue={() => onChange('content.serialNo.last3Digit.enable', !_.get(setting, 'content.serialNo.last3Digit.enable', false))}
              size={23}
            />
          </SettingListItemRightAction>
        </SettingListItem>
      </PrintingSettingList>
      <PrintingSettingList
        divider
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.pickupAt')}
        path='content.pickupAt'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      />
      <PrintingSettingList
        divider
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.batchIndex')}
        path='content.batchIndex'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      />
      <PrintingSettingList
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.createAt')}
        path='content.createAt'
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      />
    </>
  )
}
