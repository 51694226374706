export default {
  INIT_DATA: 'APP/INIT_DATA',
  COMPLETE_CLEAR_LOCALDB_NO_LONGER_USE: 'APP/COMPLETE_CLEAR_LOCALDB_NO_LONGER_USE',
  SHOW_ALERT: 'APP/SHOW_ALERT',
  CLOSE_ALERT: 'APP/CLOSE_ALERT',
  ENQUEUE_SNACKBAR: 'APP/ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR: 'APP/CLOSE_SNACKBAR',
  SHOW_DIALOG: 'APP/SHOW_DIALOG',
  CLOSE_DIALOG: 'APP/CLOSE_DIALOG',
  IS_DIALOG_OPENED: 'APP/IS_DIALOG_OPENED',
  UPDATE_SETTING: 'MERCHANT/UPDATE_SETTING',
  RESTORE_SETTINGS: 'MERCHANT/RESTORE_SETTINGS',
  SHOW_DATE_TIME_DIALOG: 'APP/SHOW_DATE_TIME_DIALOG',
  CLOSE_DATE_TIME_DIALOG: 'APP/CLOSE_DATE_TIME_DIALOG',
  UPDATE_LOADING: 'APP/UPDATE_LOADING',
  UPDATE_NEEDED_PERMISSION: 'APP/UPDATE_NEEDED_PERMISSION',
  UPDATE_CODEPUSH_DEPLOYMENT: 'APP/UPDATE_CODEPUSH_DEPLOYMENT',
  UPDATE_CODEPUSH_OVERWROTE_DEPLOYMENT: 'APP/UPDATE_CODEPUSH_OVERWROTE_DEPLOYMENT',
  UPDATE_CODEPUSH_PACKAGE_META: 'APP/UPDATE_CODEPUSH_PACKAGE_META',
  UPDATE_CODEPUSH_DIALOG_OPEN: 'APP/UPDATE_CODEPUSH_DIALOG_OPEN',
  UPDATE_CODEPUSH_STATUS: 'APP/UPDATE_CODEPUSH_STATUS',
  UPDATE_CODEPUSH_PROGRESS: 'APP/UPDATE_CODEPUSH_PROGRESS',
  UPDATE_CODEPUSH_ERROR: 'APP/UPDATE_CODEPUSH_ERROR',
  INCREASE_WEBSOCKET_RETRY_COUNT: 'APP/INCREASE_WEBSOCKET_RETRY_COUNT',
  RESET_WEBSOCKET_RETRY_COUNT: 'RESET_WEBSOCKET_RETRY_COUNT',
  UPDATE_SHOW_WEB_SOCKET_WARNING_AT: 'UPDATE_SHOW_WEB_SOCKET_WARNING_AT',
  SET_SCOPE_LOGIN: 'SET_SCOPE_LOGIN',
  UPDATE_NET_INFO: 'APP/UPDATE_NET_INFO',
  UPDATE_WEBSOCKET_STATUS: 'APP/UPDATE_WEBSOCKET_STATUS',
  UPDATE_IS_SAVED_SETTING: 'APP/UPDATE_IS_SAVED_SETTING',
  INCREASE_SERIAL_INDEX: 'APP/INCREASE_SERIAL_INDEX',
  UPDATE_WEBSOCKET_READY: 'APP/UPDATE_WEBSOCKET_READY',
  UPDATE_DEBUG_MODE: 'APP/UPDATE_DEBUG_MODE',
  UPDATE_DEBUG_SETTING: 'APP/UPDATE_DEBUG_SETTING',
  RESET_DEBUG_SETTINGS: 'APP/RESET_DEBUG_SETTINGS',
  UPDATE_INIT_QUEUING_MESSAGE: 'APP/UPDATE_INIT_QUEUING_MESSAGE',
  UPDATE_LAZY_QUEUING_NUMBER: 'APP/UPDATE_LAZY_QUEUING_NUMBER',
}
