export default {
  primary: '#383866',
  lightPrimary: '#45457B',
  backgroundPrimary: '#ECECF2',
  secondary: '#C80F5A',
  darkSecondary: '#A40E61',
  tertiary: '#FFB425',
  textPrimary: '#153243',
  textSecondary: '#5C5C5C',
  textTertiary: '#707070',
  textPlaceholder: '#A0ABB4',
  black: '#000000',
  blackOpacity16: '#00000029', // list item 的底線,
  white: '#FFFFFF',
  light: '#F0F0F0', // 餐單畫面底色
  gray: '#7C7C7D',
  dotGray: '#88857C',
  numPadGray: '#F0F0F0',
  numPadDarkGray: '#DDDDDD',
  lightGray1: '#EFEFEF',
  lightGray2: '#DADADA', // 分單畫面底色
  lightGray3: '#CECECE', // 結帳畫面底色
  lightGray4: '#BFBFBF', // 點餐頁面底下操作區塊底色
  lightGray5: '#C6C6C6', // 點餐頁 BasketRowGroup 邊框
  lightBlue1: '#D1D8DD', // 數字鍵盤 input background
  lightBlue2: '#DBDBE1', // 分單數量、分擔金額的 Add Button background
  divider: '#D6D6D6',
  transparent: 'transparent',
  superlightPrimary: 'rgba(69, 69, 123, 0.12)', // 座位 order action
  success: '#4caf50',
  error: '#f44336',
  warning: '#ff9800',
  green: '#28951D', // 座位 open status
  red: '#FF0000',
  yellow: '#FEFB00',
  status1: '#FFB425', // 訂單 待接單status
  status2: '#F46700', // 訂單 備餐中status
  status3: '#28951D', // 訂單 已完成status
  status4: '#C80F5A', // 訂單 待結帳status
  status5: '#4392D6', // 訂單 配送中status
  status6: '#383866', // 訂單 已付款status
  status7: '#5C5C5C', // 訂單 已作廢status
  status8: '#0052CC', // 訂單 待取餐status
}
