import { FlatList, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Icon, Tooltip } from 'react-native-elements'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useIsOnline } from '@/hooks/app'
import { webSocketStatusCode } from '@/constants'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import LocalDatabaseIcon from './LocalDatabaseIcon'
import NavButton from './NavButton'
import Row from '@/components/Row'
import TableAreaDialog from '@/components/dialogs/TableAreaDialog'

/**
 *
 * @param {*} props
 * @returns
 */
export default function RightNavBar (props) {
  const [unConfirmCount, setunConfirmCount] = useState()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const webSocketStatus = useSelector(state => state.app.webSocketStatus)
  const merchant = useSelector(state => state.merchant.data)
  const printerStatus = useSelector(state => state.printer.printerStatus)
  const netInfo = useSelector(state => state.app.netInfo)

  const quickMode = merchant?.setting?.quickMode
  const enableTnDmode = merchant?.setting?.enableTnDmode
  const enableCRM = merchant?.setting?.enableCRM
  const callWaiter = merchant?.setting?.waiter
  const printers = Object.values(printerStatus)
  const printerStatusHieght = 50 + ((printers.length - 1) * 35)
  const [allConnected, setAllConnected] = React.useState(true)
  const printersInfo = useSelector(state => state.printer.printerSetting.printers)
  const drawerKick = useCallback(_.throttle(() => { dispatch(actions.printer.drawerKick()) }, 3000, { trailing: false }), [])
  const tableAreaDialogOpen = useSelector(state => state.app.dialog.notification.requestWaiter)
  const enableQrcodeCheckout = useSelector(state => state.app.settings.enableQrcodeCheckout)

  const { isOnlineWithWebSocket } = useIsOnline()

  React.useEffect(() => {
    const connected = printers.every(p => p.status === 'CONNECTED')
    setAllConnected(connected)
  }, [printers])

  const navItems = [
    {
      key: 'scanQRCode',
      type: 'material-community',
      title: t('app.routes.rightNav.scan'),
      iconSource: 'qrcode-scan',
      path: '/scanQRCode',
    },
    {
      key: 'order',
      title: t('app.routes.rightNav.order'),
      iconSource: require('@icons/nav/order.png'),
      path: '/order',
    },
    {
      key: 'orderHistory',
      title: t('app.routes.rightNav.orderHistory'),
      iconSource: require('@icons/nav/orders.png'),
      path: '/orderHistory',
    },
    // {
    //   key: 'waiting',
    //   title: '等位',
    //   iconSource: require('@icons/nav/waiting.png'),
    // },
    {
      key: 'table',
      title: t('app.routes.rightNav.table'),
      iconSource: require('@icons/nav/table.png'),
      path: '/table',
    },
    // {
    //   key: 'member',
    //   title: '會員',
    //   iconSource: require('@icons/nav/member.png'),
    // },
    // {
    //   key: 'app',
    //   title: 'APP',
    //   iconSource: require('@icons/nav/app.png'),
    // },
    {
      key: 'stats',
      title: t('app.routes.rightNav.stats'),
      iconSource: require('@icons/nav/report.png'),
      path: '/stats',
    },
    {
      key: 'notification',
      title: t('app.routes.rightNav.notification'),
      iconSource: require('@icons/nav/notification.png'),
    },
    {
      key: 'setting',
      title: t('app.routes.rightNav.setting'),
      iconSource: require('@icons/nav/setting.png'),
      path: '/setting',
    },
    // {
    //   key: 'logout',
    //   title: t('app.page.setting.localPanel.logout'),
    //   iconSource: 'logout',
    //   type: 'meterial',
    // },
    {
      key: 'member',
      title: t('app.routes.rightNav.member'),
      iconSource: 'card-account-details-outline',
      type: 'material-community',
      path: '/member',
    },
  ]

  let newItems = navItems

  if (enableTnDmode) {
    newItems = navItems.filter(o => o.key !== 'table' && o.key !== 'order' && o.key !== 'notification')
  } else if (quickMode) {
    newItems = navItems.filter(o => o.key !== 'table' && o.key !== 'notification')
  } else if (!callWaiter) {
    newItems = navItems.filter(o => o.key !== 'order' && o.key !== 'notification')
  } else {
    newItems = navItems.filter(o => o.key !== 'order')
  }

  if (!enableQrcodeCheckout) {
    newItems = newItems.filter(o => o.key !== 'scanQRCode')
  }

  if (!enableCRM) {
    newItems = newItems.filter(o => o.key !== 'member')
  }

  const renderItem = ({ item, index }) => {
    return (
      <NavButton key={index} navItem={item} unConfirmCount={unConfirmCount} />
    )
  }

  const renderPrinter = ({ item }) => {
    const color = item.status === 'CONNECTED' ? colors.success : colors.error
    const printerInfo = printersInfo.find(p => p.id === item.id)
    const name = printerInfo?.name || item.id
    return (
      <Row style={styles.row}>
        <View style={[styles.textContainer, { backgroundColor: color }]}>
          <Text style={styles.statusText}>{name}</Text>
        </View>
        <Text style={{ color: color }}>
          {
            item.status === 'CONNECTED'
              ? t('app.routes.rightNav.printerConnected')
              : t('app.routes.rightNav.printerDisconnect')
          }
        </Text>
      </Row>
    )
  }

  return (
    <>
      <View style={styles.container}>
        <FlatList
          data={newItems}
          renderItem={renderItem}
          keyExtractor={(item, index) => String(index)}
          showsVerticalScrollIndicator={false}
        />
        {/* {
          webSocketStatus === webSocketStatusCode.CLOSED && (
            <TouchableOpacity
              onPress={() => {
                dispatch(actions.app.enqueueSnackbar({
                  text: i18n.t('app.component.webSocket.alert.webSocketDisconnect.message'),
                  duration: Snackbar?.LENGTH_LONG,
                }))
              }}
            >
              <Icon
                size={50}
                name='warning'
                color={colors.red}
              />
            </TouchableOpacity>
          )
        } */}
        <Tooltip
          backgroundColor={colors.white}
          overlayColor='rgba(0, 0, 0, 0.25)'
          width={150}
          popover={
            <Text>Local Database</Text>
          }
        >
          <LocalDatabaseIcon />
        </Tooltip>
        {
          !allConnected && (
            <Tooltip
              backgroundColor={colors.white}
              overlayColor='rgba(0, 0, 0, 0.25)'
              height={printerStatusHieght}
              width={300}
              popover={
                <View style={[styles.printerStatus, { height: printerStatusHieght }]}>
                  <FlatList
                    data={printers}
                    renderItem={renderPrinter}
                    keyExtractor={(item, index) => String(index)}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              }
            >
              <Icon
                size={35}
                name='print-disabled'
                color={colors.red}
              />
            </Tooltip>
          )
        }
        {
          Platform.OS !== 'web' &&
            <Tooltip
              backgroundColor={colors.white}
              overlayColor='rgba(0, 0, 0, 0.25)'
              height={180}
              width={180}
              popover={
                <View style={styles.netInfo}>
                  <Text style={styles.networkText}>{t('app.component.network.status.connectionType')}:{netInfo?.type?.toUpperCase()}</Text>
                  <Text style={styles.networkText}>{t('app.component.network.status.receivedNetworkSpeed')}:{(netInfo?.isInternetReachable ? netInfo?.receivedNetworkSpeed : 'N/A')}</Text>
                  <Text style={styles.networkText}>{t('app.component.network.status.sendNetworkSpeed')}:{(netInfo?.isInternetReachable ? netInfo?.sendNetworkSpeed : 'N/A')}</Text>
                  <Text style={styles.networkText}>{t('app.component.network.status.websocket')}:{(webSocketStatus !== webSocketStatusCode.CLOSED && netInfo?.isInternetReachable ? t('app.component.network.status.connected') : t('app.component.network.status.error'))}</Text>
                </View>
              }
            >
              <Icon
                style={{ marginBottom: 10 }}
                size={35}
                name='wifi'
                type='material'
                color={(isOnlineWithWebSocket ? colors.green : colors.gray)}
              />
            </Tooltip>
        }
        <TouchableOpacity onPress={() =>
          dispatch(actions.auth.permissionCheck('drawer-kick',
            () => drawerKick(),
          ))}
        >
          <View style={styles.button}>
            <Image style={styles.icon} source={require('@icons/order-action/drawer.png')} />
            <Text style={styles.text}>{t('app.page.setting.AuthorizationPanel.scope.drawer-kick')}</Text>
          </View>
        </TouchableOpacity>

      </View>

      <TableAreaDialog
        open={tableAreaDialogOpen}
        onClose={() => dispatch(actions.app.closeDialog(['notification', 'requestWaiter']))}
        requestWaiter
        onSelectOrder={(order) => {
          dispatch(actions.order.serveTable(order.id))
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 80,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.tertiary,
    paddingTop: 46,
    paddingBottom: 46,
    ...shadows.default,
    zIndex: 100,
  },
  button: {
    width: 65,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginHorizontal: 8,
    marginVertical: 5,
    backgroundColor: colors.primary,
    borderWidth: 2,
    borderColor: colors.white,
  },
  icon: {
    width: 38,
    height: 38,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.white,
  },
  printerStatus: {
    width: 300,
    marginTop: 10,
    padding: 10,
  },
  netInfo: {
    width: 200,
    padding: 25,
  },
  textContainer: {
    width: 170,
    borderRadius: 5,
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 5,
  },
  statusText: {
    color: colors.white,
  },
  networkText: {
    marginBottom: 5,
  },
})
