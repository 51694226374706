import { Icon } from 'react-native-elements'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from '@/redux'
import Blink from '@root/src/components/Blink'
import OrderImage from './OrderImage'
import OrderInfo from './OrderInfo'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
import moment from 'moment'
import shadows from '@/theme/shadows'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const iconSources = {
  table: require('@icons/orders/order-list/table.png'),
  takeaway: require('@icons/orders/order-list/take-away.png'),
  storeDelivery: require('@icons/orders/order-list/store-delivery.png'),
  uberEats: require('@icons/orders/order-list/uber-eats.png'),
  dimOrder: require('@icons/orders/order-list/dim-order.png'),
  lalamove: require('@icons/orders/order-list/lalamove.png'),
}

/**
 * @typedef OrderItemProps
 * @property {IAppOrder} order
 * @property {number} index
 * @property {() => void} onSelect
 *
 * @param {OrderItemProps}
 */
export default function OrderItem ({ order, index, onSelect }) {
  const isSelected = useSelector(state => state.orderHistory.selectedOrderId === order.id)
  const pendingSyncOrderIds = useSelector(state => state.unsyncOrder.pendingOrderIds)
  const syncingOrderId = useSelector(state => state.unsyncOrder.syncingOrderId)
  const isUnsyncOrder = _.includes(pendingSyncOrderIds.concat([syncingOrderId].filter(orderId => orderId !== null)), order.id)

  const iconConfig = React.useMemo(() => {
    const deliveryTypeIconSource = iconSources[order.deliveryType]
    const shippingProviderIconSource = iconSources[order.shippingProvider]

    const iconSource = (order.deliveryType === 'storeDelivery' && shippingProviderIconSource)
      ? shippingProviderIconSource
      : deliveryTypeIconSource

    const iconSize = (order.deliveryType === 'storeDelivery' && shippingProviderIconSource)
      ? 64
      : 45

    const iconBackgroundColor = order.deliveryType === 'storeDelivery'
      ? colors.status5
      : order.deliveryType === 'takeaway'
        ? colors.darkSecondary
        : colors.primary

    return {
      iconSource,
      iconSize,
      iconBackgroundColor,
    }
  }, [order.deliveryType, order.shippingProvider])

  let duration = 500
  let showOverdueIcon = false
  if (order.orderCutOffMins) {
    const overdueTime = moment(order.createdAt).add(order.orderCutOffMins, 'minute')
    const isAlmostOverdue = moment().isAfter(moment(overdueTime).subtract(5, 'minute'))
    const isOverdue = moment().isAfter(overdueTime)
    if (isOverdue) {
      duration = 200
    }
    showOverdueIcon = (isAlmostOverdue || isOverdue) && order.status === 'pending' && order.deliveryType === 'table'
  }

  return (
    <TouchableOpacity
      style={[styles.container]}
      onPress={() => onSelect(order)}
    >
      {isUnsyncOrder && (
        <View
          style={styles.cloud}
        >
          <Icon
            name='cloud-off'
            type='material'
            color={colors.red}
          />
        </View>
      )}
      <OrderImage
        iconSource={iconConfig.iconSource}
        backgroundColor={iconConfig.iconBackgroundColor}
        style={{ height: iconConfig.iconSize, width: iconConfig.iconSize }}
      />
      <OrderInfo order={order} />

      {showOverdueIcon && (
        <Blink duration={duration} style={styles.pending}>
          <Image
            source={require('@icons/table/overdue.png')}
            style={styles.pendingImg}
          />
        </Blink>
      )}

      {isSelected && <View style={styles.overlay} />}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 110,
    width: 400,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.light,
    paddingHorizontal: 18,
    ...shadows.heavy,
  },
  pending: {
    width: 0,
    height: 0,
    alignItems: 'flex-end',
    borderColor: 'transparent',
    borderLeftWidth: 42,
    borderTopColor: colors.primary,
    borderTopWidth: 42,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  cloud: {
    position: 'absolute',
    top: 1,
    left: 3,
  },
  pendingImg: {
    height: 20,
    width: 20,
    position: 'absolute',
    top: -40,
    right: 3,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    borderWidth: 4,
    borderColor: colors.primary,
  },
})
