import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '@/theme/colors'

OptionButton.propTypes = {
  variant: PropTypes.string,
  selected: PropTypes.bool,
  text: PropTypes.string,
  onPress: PropTypes.func,
}

export default function OptionButton (props) {
  const { variant = 'option', selected, text, onPress, containerStyle, textStyle } = props
  const styleName = variant + (selected ? 'Selected' : '')
  const textStyleName = variant + 'Text' + (selected ? 'Selected' : '')
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.container, styles[styleName], variant !== 'option' && styles.buttonPadding, containerStyle]}>
        {Boolean(text) && (
          <Text
            numberOfLines={2}
            ellipsizeMode='tail'
            style={[styles.text, styles[textStyleName], textStyle]}
          >
            {text}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 78,
    height: 38,
    // flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 4,
    borderWidth: 1.3,
    borderRadius: 5,
  },
  buttonPadding: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  option: {
    borderColor: colors.white,
    backgroundColor: colors.primary,
    width: 85,
    height: 38,
  },
  optionText: {
    color: colors.white,
  },
  optionSelected: {
    borderColor: colors.white,
    backgroundColor: colors.white,
    width: 85,
  },
  optionTextSelected: {
    color: colors.primary,
  },
  tag: {
    borderColor: colors.primary,
  },
  tagText: {
    color: colors.primary,
  },
  tagSelected: {
    borderColor: colors.primary,
    backgroundColor: colors.primary,
  },
  tagTextSelected: {
    color: colors.white,
  },
  text: {
    flexShrink: 1,
    flexWrap: 'wrap',
    fontSize: 14,
    textAlign: 'center',
  },
})
