import { StyleSheet } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import CenterModal from '@/components/CenterModal'
import DialogActions from './DialogActions'
import React, { useEffect } from 'react'
import StashList from './StashList'
import _ from 'lodash'

export default function BatchStashesDialog (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(state => state.app.dialog.order.batchStashes)
  const handleClose = () => dispatch(actions.app.closeDialog(['order', 'batchStashes']))
  const batchStashes = useSelector(state => state.orderBatch.batchStashes)

  useEffect(() => {
    if (_.isEmpty(batchStashes)) {
      handleClose()
    }
  }, [batchStashes])

  return (
    <CenterModal
      enablePressOutsideClose
      title={t('app.page.order.stash.history')}
      visible={visible}
      onClose={handleClose}
      contentContainerStyle={styles.content}
    >
      <StashList />
      <DialogActions />
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  content: {
    padding: 0,
    // minWidth: 480,
    width: 480,
    maxHeight: 300,
  },
})
