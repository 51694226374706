import { Image, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import { SwipeRow } from 'react-native-swipe-list-view'
import React from 'react'

import { useTranslation } from 'react-i18next'
import CheckBox from '@/components/CheckBox'
import ChipLabel from '@/components/ChipLabel'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import _ from 'lodash'
import colors from '@/theme/colors'

const deleteIcon = require('@icons/delete.png')

export default function OptionGroupItem (props) {
  const {
    scrollContainerRef,
    optionGroup,
    divider,
    selected,
    draggable,
    dragging,
    onDrag,
    onPress,
    onDelete,
    showPresetLabel,
    checked,
    onCheckedValueChange,
    locale = 'zh',
  } = props

  const swipeRowRef = React.useRef()
  const [rowOpened, setRowOpened] = React.useState(false)
  const { t } = useTranslation()

  React.useEffect(() => {
    // row 打開後 3 秒自動關閉
    if (!rowOpened) return
    const timer = setTimeout(() => {
      swipeRowRef?.current?.closeRow()
    }, 3000)

    return () => clearTimeout(timer)
  }, [rowOpened])

  const handleDelete = () => {
    swipeRowRef?.current?.closeRow()
    onDelete()
  }

  return (
    <SwipeRow
      ref={swipeRowRef}
      disableLeftSwipe={!onDelete}
      disableRightSwipe
      rightOpenValue={-50}
      onRowDidOpen={() => setRowOpened(true)}
      onRowDidClose={() => setRowOpened(false)}
      onSwipeValueChange={({ value }) => {
        // 避免 Swipe 中垂直捲動 FlatList 造成 Swipe 中斷
        scrollContainerRef?.setNativeProps?.({ scrollEnabled: value > -30 })
        if (value < -200) {
          handleDelete()
        }
      }}
    >
      <TouchableWithoutFeedback onPress={handleDelete}>
        <View style={[styles.deleteContainer, dragging && { backgroundColor: colors.transparent }]}>
          <Image style={styles.deleteIcon} source={deleteIcon} />
        </View>
      </TouchableWithoutFeedback>

      <SettingListItem
        divider={divider}
        draggable={draggable}
        selected={selected}
        dragging={dragging}
        onDrag={onDrag}
        onPress={onPress}
      >
        <SettingListItemText text={_.get(optionGroup, `localeNames.${locale}`, optionGroup.name)} />
        <SettingListItemRightAction>
          {showPresetLabel && optionGroup.isPreset && <ChipLabel label={t('app.page.setting.menuPanel.options.option.default')} active />}
          <ChipLabel label={t('app.page.setting.menuPanel.options.optionGroup.must')} active={optionGroup.min > 0} />
          <ChipLabel label={t('app.page.setting.menuPanel.options.optionGroup.multiple')} active={optionGroup.multiple} />
          {onCheckedValueChange && (
            <CheckBox
              value={checked}
              onChangeValue={() => onCheckedValueChange(!checked)}
            />
          )}
        </SettingListItemRightAction>
      </SettingListItem>
    </SwipeRow>
  )
}

const styles = StyleSheet.create({
  deleteContainer: {
    position: 'absolute',
    top: 0.5,
    bottom: 0,
    left: 0,
    right: 0,
    paddingRight: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.secondary,
  },
  deleteIcon: {
    width: 28,
    height: 28,
  },
})
