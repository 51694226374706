import { Image, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '@/theme/colors'

const iconSources = {
  up: require('@icons/scroll-up.png'),
  down: require('@icons/scroll-down.png'),
}

const linearGradientColors = {
  up: [colors.primary, colors.primary + 'E0', colors.primary + '00'],
  down: [colors.primary + '00', colors.primary + 'E0', colors.primary],
}

ScrollButton.propTypes = {
  onPress: PropTypes.func,
  direction: PropTypes.string,
}

export default function ScrollButton (props) {
  const { direction, onPress, iconStyle } = props

  return (
    <TouchableWithoutFeedback
      style={[
        styles.container,
        {
          [direction === 'up' ? 'top' : 'bottom']: 0,
          [direction === 'up' ? 'paddingBottom' : 'paddingTop']: 16,
        },
      ]}
      onPress={onPress}
    >
      <View
        style={[
          styles.container,
          {
            [direction === 'up' ? 'top' : 'bottom']: 0,
            [direction === 'up' ? 'paddingBottom' : 'paddingTop']: 16,
          },
        ]}
      >
        <LinearGradient style={styles.linearGradient} colors={linearGradientColors[direction]}>
          <Image style={[styles.icon, iconStyle]} source={iconSources[direction]} />
        </LinearGradient>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    position: 'absolute',
    width: '100%',
  },
  linearGradient: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
  icon: {
    width: 34,
    height: 34,
  },
})
