import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import colors from '@/theme/colors'

import SummaryRow from './SummaryRow'

const titles = [
  'dimOrderTakeaway',
  'dimOrderStoreDelivery',
]

export default function DimOrderInfo ({ data = [] }) {
  const { t } = useTranslation()

  const orderedData = titles.map(t => {
    return data.find(section => section.type === t)
  })

  const renderItem = ({ item, index: i }) => {
    const title = 'app.page.stats.newSummary.' + item.type
    const data = item.data
    return (
      <>
        <View style={{ height: 25, marginTop: 30 }}>
          <Text style={styles.title}>{t(title)}</Text>
          <View style={styles.divider} />
        </View>
        <FlatList
          data={Object.keys(item.data)}
          renderItem={({ item: type }) => {
            const listItemTitle = 'app.page.stats.newSummary.' + type
            const isTextPin = type === 'totalSales' || type.includes('evenue') || type === 'total'
            const textStyle = { color: '#EE7B06' }
            return (
              <SummaryRow
                title={listItemTitle}
                count={typeof data[type] === 'object' ? data[type].count : ''}
                amount={typeof data[type] === 'object' ? data[type].amount : data[type]}
                textStyle={isTextPin ? textStyle : null}
                isString={type === 'guest'}
              />
            )
          }}
          keyExtractor={(item, index) => String(item + 'list' + index)}
          showsVerticalScrollIndicator={false}
        />
      </>
    )
  }

  if (_.isEmpty(data)) {
    return null
  }

  return (
    <View style={styles.container}>
      <FlatList
        data={orderedData}
        renderItem={renderItem}
        keyExtractor={(item, index) => String('title' + index)}
        showsVerticalScrollIndicator={false}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40,
    flex: 1,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})
