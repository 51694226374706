import { StyleSheet, Text } from 'react-native'
import React from 'react'
import _ from 'lodash'

import CheckBox from '@/components/CheckBox'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 *
 * @param {{
 * option: IMenuOptionItem,
 * onChangeHidden: (hidden: boolean) => {},
 * }} props
 */
export default function PresetOptionItem (props) {
  const {
    option,
    divider,
    locale,
    onChangeHidden,
  } = props

  return (
    <SettingListItem divider={divider}>
      <SettingListItemText text={_.get(option, `localeNames.${locale}`, option.name)} />
      <SettingListItemRightAction>
        <Text>{`$${option.price}`}</Text>
        <CheckBox
          style={styles.checkbox}
          value={!option.hidden}
          onChangeValue={() => onChangeHidden(!option.hidden)}
        />
      </SettingListItemRightAction>
    </SettingListItem>
  )
}

const styles = StyleSheet.create({
  checkbox: {
    marginLeft: 8,
  },
})
