import { Image, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import React from 'react'

export default function BackButton ({ onPress }) {
  return (
    <TouchableWithoutFeedback style={styles.container} onPress={onPress}>
      <Image style={styles.icon} source={require('@icons/back.png')} />
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  icon: {
    width: 48,
    height: 48,
  },
})
