import { useSelector } from '@/redux'
import { webSocketStatusCode } from '@/constants'
import _ from 'lodash'

const webSocketStatusMap = { null: '尚未初始' }
_.forEach(webSocketStatusCode, (statusCode, statusName) => {
  webSocketStatusMap[statusCode] = statusName
})

export function useIsOnline () {
  const netInfo = useSelector(state => state.app.netInfo)
  const webSocketStatus = useSelector(state => state.app.webSocketStatus)

  // 網路連線可用 或 尚未檢查過 (isInternetReachable: null)
  const isInternetOK = netInfo?.isInternetReachable === true || netInfo?.isInternetReachable === null

  // 在已知連線類型的情況下已有連線 或 連線類型尚未確認 (type: null)
  const isNetworkConnectedOK = (netInfo?.isConnected && netInfo?.type !== null) || netInfo?.type === null

  // socket OPEN 或 尚未進行第一次連線 (webSocketStatus: null)
  const isWebSocketOK = webSocketStatus === webSocketStatusCode.OPEN || webSocketStatus === null

  // 不包含 web socket 有網路
  const isOnline = isInternetOK && isNetworkConnectedOK

  // 有網路且有 web socket 連線
  const isOnlineWithWebSocket = isInternetOK && isNetworkConnectedOK && isWebSocketOK

  const status = {
    isOnline,
    isOnlineWithWebSocket,
    conditions: { isInternetOK, isNetworkConnectedOK, isWebSocketOK },
    status: {
      isInternetReachable: netInfo?.isInternetReachable,
      networkType: netInfo?.type,
      webSocketStatusCode: webSocketStatus,
      webSocketStatus: webSocketStatusMap[webSocketStatus],
    },
  }
  return status
}
