import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useCheckoutPayment } from '@/hooks/orderCheckout'
import { usePaymentMethod } from '@/hooks/merchant'
import { useSelectedOrder } from '@/hooks/orderHistory'
import Row from '@/components/Row'

import AmountButton from './AmountButton'

export default function AmountPanel (props) {
  const { selectedField, setSelectedField } = props
  const { t } = useTranslation()
  const selectedOrder = useSelectedOrder()
  const checkoutPayment = useCheckoutPayment()
  const paidAmount = selectedOrder.payments.filter(p => p?.status === 'paid')
    .map(p => Number(p.amount)).reduce((a, b) => a + b, 0)
  // 找續先用計算的，之後才會正式存起來
  const paymentMethod = usePaymentMethod(checkoutPayment.paymentMethod)
  const remainTotal = Number(selectedOrder.roundedTotal) - Number(paidAmount)
  const changeOrTips = (checkoutPayment?.paymentMethod === 'cash' || paymentMethod?.tips === false) ? checkoutPayment.change : checkoutPayment.paymentTips
  const lastTitle = (checkoutPayment?.paymentMethod === 'cash' || paymentMethod?.tips === false) ? t('app.page.checkout.payment.change') : t('app.page.checkout.payment.tips')
  return (
    <Row style={styles.container}>
      <AmountButton
        title={t('app.page.checkout.payment.remainTotal')}
        amount={remainTotal > 0 ? remainTotal : 0}
        disabled
      />
      <AmountButton
        title={t('app.page.checkout.payment.paidTotal')}
        amount={checkoutPayment.paidAmount}
        checked={selectedField === 'paidAmount'}
      />
      <AmountButton
        title={lastTitle}
        amount={changeOrTips}
        disabled
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'space-between',
  },
})
