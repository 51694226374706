import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Column from '@/components/Column'
import React from 'react'
import colors from '@/theme/colors'

export default function CustomerTag ({ style, label, children, active, onPress }) {
  return (
    <Column style={style}>

      <TouchableOpacity onPress={onPress}>
        <View style={active ? styles.tagActive : styles.tagInactive}>
          <Text style={active ? styles.labelTextActive : styles.labelTextInactive}>{label}</Text>
        </View>
      </TouchableOpacity>

      {children}
    </Column>
  )
}

const styles = StyleSheet.create({
  labelTextActive: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
    color: colors.white,
  },
  labelTextInactive: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
    color: colors.gray,
  },
  tagActive: {
    width: 80,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginHorizontal: 8,
    marginVertical: 5,
    backgroundColor: colors.secondary,
    borderWidth: 2,
    borderColor: colors.secondary,
  },
  tagInactive: {
    width: 80,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginHorizontal: 8,
    marginVertical: 5,
    borderWidth: 2,
    borderColor: colors.gray,
  },
})
