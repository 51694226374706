import { Image, StyleSheet, Text, TouchableHighlight } from 'react-native'
import React from 'react'

import colors from '@/theme/colors'

/**
 * @typedef NumberButtonProps
 * @property {() => void} onPress
 * @property {string?} text
 * @property {boolean?} deleteIcon
 * @property {boolean?} highlight
 * @property {boolean?} side
 * @property {boolean?} disabled
 * @property {number?} index
 * @property {number?} row
 * @property {number?} column
 * @property {ViewProps['style']?} style
 * @property {TextProps['style']?} textStyle
 */

/**
 * For the calculator and the password board
 * @param {NumberButtonProps} props
 * @returns
 */
export default function NumberButton (props) {
  const {
    onPress,
    text,
    deleteIcon,
    highlight,
    side,
    disabled,
    index,
    row = 0,
    column = 0,
    style,
    textStyle,
  } = props

  const defaultButtonStyle = {
    backgroundColor: highlight || side ? colors.lightPrimary : colors.light,
    borderTopLeftRadius: column === 0 ? 0 : index === 0 ? 20 : 0,
    borderTopRightRadius: column === 0 ? 0 : index + 1 === column ? 20 : 0,
    borderBottomLeftRadius: column === 0 ? 0 : index === column * (row - 1) ? 20 : 0,
    borderBottomRightRadius: column === 0 ? 0 : index + 1 === column * row ? 20 : 0,
  }

  const defaultTextStyle = {
    color: highlight || side ? colors.white : colors.textSecondary,
    fontSize: side ? 23 : 30,
    fontWeight: 'bold',
    opacity: disabled ? 0.4 : 1,
  }

  return (
    <TouchableHighlight
      underlayColor={colors.lightGray2}
      onPress={onPress}
      disabled={disabled}
      style={[styles.container, defaultButtonStyle, style]}
    >
      {deleteIcon
        ? <Image style={styles.image} source={(require('@icons/backspace.png'))} />
        : <Text style={[defaultTextStyle, textStyle]}>{text}</Text>}

    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 90,
    width: 90,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOpacity: 0.16,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowRadius: 2,
  },
  image: {
    height: 32,
    width: 43,
    marginLeft: -4,
  },
  disabledStyle: {
    opacity: 0.4,
  },
})
