import { Animated, StyleSheet, View } from 'react-native'
import React, { useEffect, useRef } from 'react'

import colors from '@/theme/colors'

/**
 * @typedef InputControlProps
 * @property {number?} inputLength
 * @property {string?} error
 */

/**
 *
 * @param {InputControlProps} props
 * @returns
 */
export default function InputControl (props) {
  const { inputLength = 0, error } = props
  const animation = useRef(new Animated.Value(0)).current

  const fulfilStyle = {
    backgroundColor: colors.white,
  }

  const animationStyles = {
    transform: [
      {
        translateX: animation.interpolate({
          inputRange: [0, 0.25, 0.5, 0.75, 1],
          outputRange: [0, 39, 0, -37, 0],
        }),
      },
    ],
  }

  useEffect(() => {
    animation.setValue(0)

    if (error) {
      Animated.timing(
        animation,
        {
          toValue: 1,
          duration: 280,
          useNativeDriver: true,
        },
      ).start()
    }
  }, [error])

  return (
    <Animated.View style={[styles.container, animationStyles]}>
      {[...Array(6)].map((c, index) =>
        <View
          key={index}
          style={[styles.circle, index < inputLength && fulfilStyle]}
        />,
      )}
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 208,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  circle: {
    height: 18,
    width: 18,
    borderWidth: 2.5,
    borderRadius: 9,
    borderColor: colors.white,
  },
})
