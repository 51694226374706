import { EventEmitter } from 'eventemitter3'
import logger, { convertToSimpleOrder } from '@/libs/logger'

// TODO: 這個 EventEmitter 沒必要存在，可以考慮拿掉，要 log 的地方直接 log 即可
// TODO: 如果不想重複寫這些 logger 的呼叫，也可以考慮包一個 function，為什麼要用 event?

class OrderEvent {
    emitter = new EventEmitter()
    eventType = {
      ORDER_CREATED: 'orderCreated',
      ORDER_SUBMITTED: 'orderSubmitted',
      ORDER_CANCELLED: 'orderCancelled',
      ORDER_PAID: 'orderPaid',
      ORDER_MOVE: 'orderMove',
      ORDER_MERGE: 'orderMerge',
      ORDER_COMPLETE: 'orderComplete',
      ORDER_ITEM_TRANSFER: 'orderItemTransfer',
      ORDER_ITEM_CANCELLED: 'orderItemCancelled',
      ORDER_UPDATE_PAYMENT: 'orderUpdatePayment',
      ORDER_DELETE_PAYMENT: 'orderDeletePayment',
      ORDER_UPDATE_CUSTOMER_COUNT: 'orderUpdateCustomerCount',
      ORDER_UPDATE_ITEM_MODIFIER: 'orderUpdateItemModifer',
      ORDER_UPDATE_ITEM_SURCHARGE: 'orderUpdateItemSurcharge',
    }

    init = () => {
      const {
        ORDER_CREATED,
        ORDER_SUBMITTED,
        ORDER_CANCELLED,
        ORDER_PAID,
        ORDER_MOVE,
        ORDER_MERGE,
        ORDER_COMPLETE,
        ORDER_ITEM_TRANSFER,
        ORDER_ITEM_CANCELLED,
        ORDER_UPDATE_PAYMENT,
        ORDER_DELETE_PAYMENT,
        ORDER_UPDATE_CUSTOMER_COUNT,
        ORDER_UPDATE_ITEM_MODIFIER,
        ORDER_UPDATE_ITEM_SURCHARGE,
      } = this.eventType

      this.emitter.on(ORDER_CANCELLED, (order) => {
        logger.log(`[orderEventEmitter][${ORDER_CANCELLED}]`, { order: convertToSimpleOrder(order) })
      })
      this.emitter.on(ORDER_PAID, (order) => {
        logger.log(`[orderEventEmitter][${ORDER_PAID}]`, { order: convertToSimpleOrder(order) })
      })
      this.emitter.on(ORDER_SUBMITTED, (order) => {
        logger.log(`[orderEventEmitter][${ORDER_SUBMITTED}]`, { order: convertToSimpleOrder(order) })
      })
      this.emitter.on(ORDER_CREATED, (order) => {
        logger.log(`[orderEventEmitter][${ORDER_CREATED}]`, { order: convertToSimpleOrder(order) })
      })
      this.emitter.on(ORDER_COMPLETE, (order) => {
        logger.log(`[orderEventEmitter][${ORDER_COMPLETE}]`, { order: convertToSimpleOrder(order) })
      })
      this.emitter.on(ORDER_ITEM_CANCELLED, (order, selectedItems) => {
        logger.log(`[orderEventEmitter][${ORDER_ITEM_CANCELLED}]`, { order: convertToSimpleOrder(order), selectedItems })
      })
      this.emitter.on(ORDER_DELETE_PAYMENT, (order, payment, reason) => {
        logger.log(`[orderEventEmitter][${ORDER_DELETE_PAYMENT}]`, { order: convertToSimpleOrder(order), payment, reason })
      })
      this.emitter.on(ORDER_MOVE, (order, fromTable, toTable) => {
        logger.log(`[orderEventEmitter][${ORDER_MOVE}]`, { fromTable, toTable, order: convertToSimpleOrder(order) })
      })
      this.emitter.on(ORDER_MERGE, (fromOrder, toOrder) => {
        logger.log(`[orderEventEmitter][${ORDER_MERGE}]`, { fromOrder: convertToSimpleOrder(fromOrder), toOrder: convertToSimpleOrder(toOrder) })
      })
      this.emitter.on(ORDER_ITEM_TRANSFER, (fromOrder, toOrder, selectedItems) => {
        logger.log(`[orderEventEmitter][${ORDER_ITEM_TRANSFER}]`, { fromOrder: convertToSimpleOrder(fromOrder), toOrder: convertToSimpleOrder(toOrder), selectedItems })
      })
      this.emitter.on(ORDER_UPDATE_PAYMENT, (order, paymentId, data) => {
        logger.log(`[orderEventEmitter][${ORDER_UPDATE_PAYMENT}]`, { order: convertToSimpleOrder(order), paymentId, data })
      })
      this.emitter.on(ORDER_UPDATE_CUSTOMER_COUNT, (order, count) => {
        logger.log(`[orderEventEmitter][${ORDER_UPDATE_CUSTOMER_COUNT}]`, { order: convertToSimpleOrder(order), count })
      })
      this.emitter.on(ORDER_UPDATE_ITEM_MODIFIER, (order, selectedItems, updatedQuantity, modifier) => {
        logger.log(`[orderEventEmitter][${ORDER_UPDATE_ITEM_MODIFIER}]`, { order: convertToSimpleOrder(order), selectedItems, updatedQuantity, modifier })
      })
      this.emitter.on(ORDER_UPDATE_ITEM_SURCHARGE, (order, selectedItems, updatedQuantity, value) => {
        logger.log(`[orderEventEmitter][${ORDER_UPDATE_ITEM_SURCHARGE}]`, { order: convertToSimpleOrder(order), selectedItems, updatedQuantity, value })
      })
    }
}

const orderEvent = new OrderEvent()

export default orderEvent
