import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import moment from 'moment'
import produce from 'immer'
import shortid from 'shortid'

import { PrintReason } from '@/constants/printing'
import { actions } from '@/redux'
import { ecrGateway, loadingKey, paymentMethods } from '@/constants'
import { getHistoryOrder } from '@/libs/orderHistory'
import BBMSL from '@/libs/EcrGateway/BBMSL'
import EftPay from '@/libs/EcrGateway/EftPay'
import GlobalPayment from '@/libs/EcrGateway/GlobalPayment'
import ecrPayMethods from '@/constants/ecrPayMethods'
import i18n from '@/i18n'
import logger from '@/libs/logger'
import paymentConfigs from '@/configs/paymentConfigs'

import { initPayment } from './reducer'
import ActionTypes from './ActionTypes'

// eslint-disable-next-line no-unused-vars
import { IAppOrder, IAppPayment } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * 結帳指定的訂單（或已選的訂單）
 * @param {string} orderId
 * @param {boolean} cashQuickCheckout 是否為現金快速結帳
 * @returns {ThunkFunction}
 */
export function checkoutOrder (orderId, cashQuickCheckout = false) {
  return (dispatch, getState) => {
    // 選擇訂單
    dispatch(actions.orderHistory.selectOrder(orderId))

    const selectedOrder = getHistoryOrder(orderId)

    if (!selectedOrder) {
      dispatch(actions.app.showAlert({
        modalProps: { enablePressOutsideClose: true },
        title: '結帳失敗',
        messages: '找不到訂單',
      }))
      return
    }

    const items = _.flatMap(selectedOrder.batches, batch => batch.items)

    const errorMessages = []
    items.forEach(item => {
      // 檢查時價是否都設定完成
      if (item.priceUndetermined && !item.cancelled) {
        errorMessages.push(item.name + '時價未設定')
      }
      item.setItems.forEach(setItem => {
        if (setItem.priceUndetermined && !setItem.cancelled) {
          errorMessages.push(setItem.name + '時價未設定')
        }
      })
    })

    if (errorMessages.length > 0) {
      dispatch(actions.app.showAlert({
        modalProps: { enablePressOutsideClose: true },
        title: '結帳失敗',
        messages: errorMessages,
      }))
      return
    }

    // select order to checkout
    dispatch({
      type: ActionTypes.CHECKOUT_ORDER,
      payload: { orderId: orderId },
    })

    // 建立新的 payment
    dispatch(createPayment(cashQuickCheckout ? selectedOrder : null))

    // 前往結帳畫面
    // 現金結帳不需要前往結帳畫面，直接結帳
    !cashQuickCheckout && window.applicatiionHistory.push('/orderHistory/checkout')
  }
}

/**
 * 建立新的 payment，並設定 selectedPaymentId 選擇這筆 payment
 * @param {IAppOrder?} cashChecoutOrder 現金快速結帳Order
 * @returns {ThunkFunction}
 */
export function createPayment (cashChecoutOrder = null) {
  return async (dispatch, getState) => {
    const payments = getState().merchant.data.payments
    const cashPayment = payments.find(o => o.key === 'cash')
    const paymentId = uuid()
    const payment = {
      ...initPayment,
      paymentMethod: cashPayment?.key ?? payments[0]?.key,
      id: paymentId,
      amount: cashChecoutOrder ? cashChecoutOrder.roundedTotal : 0, // 應付金額
      paidAmount: cashChecoutOrder ? String(cashChecoutOrder.roundedTotal) : '0',
    }
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT,
      payload: { payment },
    })

    if (cashChecoutOrder) {
      const now = moment()

      dispatch(actions.orderCheckout.updatePaymentField('createdAt', now.utc().toISOString()))

      dispatch(actions.app.openLoading(loadingKey.CHECKOUT, 'checkout'))

      // submit order payment and upload to server
      dispatch(submitOrderPayment({ skipCashPaymentResult: Boolean(cashChecoutOrder) }))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function completeCheckout () {
  return (dispatch, getState) => {
    // 回到訂單頁
    const merchant = getState().merchant.data
    if (merchant?.setting?.quickMode) {
      window.applicatiionHistory.push('/order')
    } else {
      window.applicatiionHistory.push('/table')
    }
    // 清除結帳
    dispatch({ type: ActionTypes.CLEAN_CHECKOUT_ORDER })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function cleanCheckOutOrder () {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.CLEAN_CHECKOUT_ORDER })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateSubmitting (submitting) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SUBMITTING,
      payload: { submitting },
    })
  }
}

// * Payment
/**
 * 更改 selectedPayment (merge)
 * @param {IAppPayment} updates 要更新的欄位
 * @returns {ThunkFunction}
 */
export function updatePayment (updates) {
  return (dispatch, getState) => {
    const prevPayment = getState().orderCheckout.payment
    const updatedPayment = produce(prevPayment, draft => {
      _.merge(draft, updates)
    })
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT,
      payload: { payment: updatedPayment },
    })
  }
}

/**
 * 更改 selectedPayment 的特定欄位
 * @param {string} field
 * @param {*} value
 * @returns {ThunkFunction}
 */
export function updatePaymentField (field, value) {
  return async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.UPDATE_PAYMENT_FIELD,
      payload: { field, value },
    })
  }
}

/**
 * 將 payment 的輸入數值欄位改成 Number
 * @returns {ThunkFunction}
 */
export function convertPaymentFieldToNumber () {
  return (dispatch, getState) => {
    const enableMultiPayment = getState().merchant.data?.setting?.enableMultiPayment
    const checkoutOrder = getHistoryOrder()
    const checkoutPayment = getState().orderCheckout.payment
    const orderPaidAmount = _.sumBy(checkoutOrder.payments, payment => payment.status === 'paid' ? payment.amount : 0)
    const remainTotal = Number(checkoutOrder.roundedTotal) - orderPaidAmount

    // amount 為應付金額，在多次付款的情況允許一筆 payment 的 amount 為部分的 roundedTotal
    // 因此實付金額 paidAmount < remainTotal 時，應付金額 amount 即為 paidAmount
    // 當實付金額 paidAmount > remainTotal 時，應付金額 amount 為 remainTotal
    const amount = enableMultiPayment && Number(checkoutPayment.paidAmount) < remainTotal
      ? Number(checkoutPayment.paidAmount)
      : remainTotal

    dispatch(updatePayment({
      paidAmount: Number(checkoutPayment.paidAmount),
      paymentTips: Number(checkoutPayment.paymentTips),
      amount: amount,
    }))
  }
}

/**
 * @typedef {Object} SubmitOrderPaymentParams
 * @property {string} [selectedPrinterId='']
 * @property {boolean} [skipCashPaymentResult=false]
 * @param {SubmitOrderPaymentParams} options
 * @returns {ThunkFunction}
 */
export function submitOrderPayment (options) {
  return (dispatch, getState) => {
    const { selectedPrinterId = '', skipCashPaymentResult = false } = options
    const checkoutOrder = getHistoryOrder()
    const checkoutPayment = getState().orderCheckout.payment

    logger.log('[Pay] Start pay checkoutOrder', { checkoutOrder, checkoutPayment })

    // 使用現金結帳，開啟錢箱
    if (checkoutPayment.paymentMethod === 'cash') {
      dispatch(actions.printer.drawerKick())
    }

    // 進入 submitting 狀態
    dispatch({
      type: ActionTypes.UPDATE_SUBMITTING,
      payload: { submitting: true },
    })

    // 更改 payment 狀態
    dispatch(updatePayment({
      status: 'paid',
      paidAt: moment().utc().toISOString(),
    }))

    // 將結完帳的 payment 更新至 orderHistory order 中才會正式生效
    const updatedPayment = getState().orderCheckout.payment

    dispatch(actions.orderHistory.addOrderPayment(checkoutOrder.id, updatedPayment))
    const updatedCheckoutOrder = getState().orderHistory.orders.find(order => order.id === checkoutOrder.id)
    logger.log('[Pay] Result updatedCheckoutOrder', updatedCheckoutOrder)

    const isPaid = updatedCheckoutOrder.status === 'paid'

    // 處理打印，打印結果不應該影響付款結果，所以打印部分單獨 catch error 來處理
    try {
      const { printStaff } = getState().app.settings
      const payFirst = getState().merchant.data?.setting?.payFirst
      if (isPaid && payFirst && updatedCheckoutOrder.deliveryType === 'table') {
        // 堂食先付款變成已結帳，打印廚房單
        _.each(updatedCheckoutOrder.batches, (batch) => {
          dispatch(actions.printer.printKitchenBatch(updatedCheckoutOrder, batch.items, { printStaff }, false, batch))
        })
      }
    } catch (error) {
      // TODO: handle print error?
      console.log('printKitchenBatch error in submitOrderPayment', error)
    }

    try {
      const autoPrintReceipt = getState().merchant.data?.setting?.autoPrintReceipt
      const invoiceSettings = getState().printer.printerSetting?.invoiceSettings

      if (isPaid && (autoPrintReceipt || invoiceSettings)) {
        const enableCRM = getState().merchant.data.setting.enableCRM
        const usePhoneInCRM = updatedCheckoutOrder.usePhoneInCRM

        const claimPointsShortId = shortid.generate()
        if (enableCRM && !usePhoneInCRM) {
          dispatch(actions.orderHistory.updateOrder({ ...updatedCheckoutOrder, pointsClaimPassword: claimPointsShortId }, { selectOrder: false, syncOrder: true }))
        }

        // 有開 autoPrintReceipt 或有 invoiceSettings 就可以 dispatch printOrderReceipt
        // printOrderReceipt 裡面會判斷 invoiceSettings 的 autoPrintDineIn 和 autoPrintTakeaway 再決定要不要印
        dispatch(actions.printer.printOrderReceipt({
          order: updatedCheckoutOrder,
          selectedPrinterId,
          printReason: PrintReason.ORDER_RECEIPT.PAYMENT_SUBMITTED,
          printConfig: { reprint: false },
          pointsClaimPassword: claimPointsShortId,
        }))
      }
    } catch (error) {
      // TODO: handle print error?
      console.log('printOrderReceipt error in submitOrderPayment', error)
    }

    if (isPaid) {
      // 已完成付款的訂單，根據設定前往該去的頁面
      dispatch(actions.orderHistory.selectOrder(updatedCheckoutOrder.id))
      const skipPaymentResult = getState().app.settings?.skipPaymentResult
      const quickMode = getState().merchant.data?.setting?.quickMode

      if (skipPaymentResult || skipCashPaymentResult) {
        // 設定了跳過完成結帳版面，不需前往 /checkout/result
        // 快餐模式回到點餐頁面，否則回到桌位頁面
        window.applicatiionHistory.push(quickMode ? '/order' : '/table')
      } else {
        // 顯示付款結果
        window.applicatiionHistory.push('/orderHistory/checkout/result')
      }
    } else {
      dispatch(createPayment())
    }

    dispatch(actions.app.closeLoading(loadingKey.SUBMIT_ORDER_PAYMENT, 'submitOrderPayment'))
    dispatch(actions.app.closeLoading(loadingKey.CHECKOUT, 'checkout'))
  }
}

export function adjustCardPayment (selectedPayment, tips) {
  return async (dispatch, getState) => {
    const emitterName = adjustCardPayment.name
    const onSuccess = () => {
      dispatch(actions.orderHistory.updatePaymentTips(selectedPayment.id, tips))
      dispatch(actions.app.showAlert({
        message: i18n.t('app.page.checkout.paymentResult.TransactionSuccess'),
      }))
      dispatch(actions.app.closeLoading(loadingKey.CARDPAYMENT, 'cardPayment'))
    }
    const adjAmt = Number(selectedPayment.amount) + Number(tips)
    dispatch(actions.app.openLoading(loadingKey.CARDPAYMENT, 'cardPayment'))
    switch (selectedPayment.gateway) {
      case ecrGateway.GLOBAL_PAYMENT: {
        const globalPayment = new GlobalPayment()
        globalPayment.checkLineStatus(emitterName)
        globalPayment.emitter.on(emitterName, async (success) => {
          if (success) {
            const payload = JSON.parse(selectedPayment.payload)
            const result = await globalPayment.adjustment(adjAmt * 100, payload.traceNo, emitterName)
            if (result.success) {
              onSuccess()
            } else {
              dispatch(creditCardPaymentErrorHandler(result?.error))
            }
          } else {
            dispatch(creditCardPaymentErrorHandler('failToConnectionCardCenter'))
          }
        })
        break
      }
      case ecrGateway.EFT_PAY: {
        const eftpay = new EftPay()

        const payload = JSON.parse(selectedPayment.payload)
        const result = await eftpay.adjustment(payload.TRACE, Number(tips), adjAmt)
        if (result.success) {
          onSuccess()
        } else {
          dispatch(creditCardPaymentErrorHandler(result?.error))
        }

        break
      }
      case ecrGateway.BBMSL: {
        const bbMSL = new BBMSL()
        const payload = JSON.parse(selectedPayment.payload)
        const transactionId = String(payload.receiptData.txnid).padStart(7, '0')

        const result = await bbMSL.adjustment(transactionId, Number(tips))
        if (result.success) {
          onSuccess()
        } else {
          dispatch(creditCardPaymentErrorHandler(result?.error))
        }
        break
      }
    }
  }
}

/**
 * @param {string} orderId
 * @param {IAppPayment} selectedPayment
 * @param {string} reason
 * @param {{current?: {}}} manualRefundRef
 * @param {{current?: {}}} cancelRefundRef
 * @returns {ThunkFunction}
 * 沒有使用，已移到 src/redux/orderHistory
 */
export function voidCardPayment (orderId, selectedPayment, reason, manualRefundRef, cancelRefundRef) {
  return async (dispatch, getState) => {
    const emitterName = voidCardPayment.name
    const isCardPayment = Boolean(paymentConfigs.cardProviderMaps[selectedPayment.paymentMethod])
    const onSuccess = () => {
      dispatch(actions.orderHistory.voidOrderPayment(orderId, selectedPayment, reason))
      dispatch(actions.app.showAlert({
        message: i18n.t('app.page.checkout.paymentResult.TransactionSuccess'),
      }))
    }
    dispatch(actions.app.showDialog(['ePayment']))
    let paymentResult = null

    switch (selectedPayment.gateway) {
      case ecrGateway.GLOBAL_PAYMENT: {
        const globalPayment = new GlobalPayment()
        globalPayment.checkLineStatus(emitterName)
        globalPayment.emitter.on(emitterName, async (success) => {
          if (success) {
            const amt = selectedPayment.amount
            const msgId = uuid().replace(/-/gi, '')
            const multipliedAmt = (amt * 100).toFixed(0)
            const payload = JSON.parse(selectedPayment.payload)
            let intervalID = null
            const result = await new Promise((resolve, reject) => {
              if (selectedPayment.paymentMethod === 'payme') {
                globalPayment.paymeRefund(payload.orderNum, msgId, multipliedAmt.padStart(12, '0'), emitterName)
                  .then((response) => { resolve(response) })
              } else {
                globalPayment.void(payload.traceNo, emitterName)
                  .then((response) => { resolve(response) })
              }
              // Exit promise if click timeout dialog
              intervalID = setInterval(() => {
                // leave Promise
                if (manualRefundRef.current || cancelRefundRef.current) resolve('')
              }, 1000)
            })
            // Payment request finished
            clearInterval(intervalID)
            paymentResult = result
          } else {
            dispatch(creditCardPaymentErrorHandler('failToConnectionCardCenter'))
          }
        })
        break
      }
      case ecrGateway.EFT_PAY: {
        const eftpay = new EftPay()
        const payload = JSON.parse(selectedPayment.payload)

        let intervalID = null
        const result = await new Promise((resolve, reject) => {
          if (selectedPayment.paymentMethod === paymentMethods.PAY_ME) {
            eftpay.refund(payload)
              .then((response) => { resolve(response) })
          } else {
            eftpay.void(payload.INVOICE)
              .then((response) => { resolve(response) })
          }
          // Exit promise if click timeout dialog
          intervalID = setInterval(() => {
            // leave Promise
            if (manualRefundRef.current || cancelRefundRef.current) resolve('')
          }, 1000)
        })
        // Payment request finished
        clearInterval(intervalID)
        paymentResult = result
        break
      }
      case ecrGateway.BBMSL: {
        const bbMSL = new BBMSL()
        const payload = JSON.parse(selectedPayment.payload)
        const transactionId = String(payload.receiptData.txnid).padStart(isCardPayment ? 7 : 6, '0')
        let intervalID = null
        const result = await new Promise((resolve, reject) => {
          bbMSL.void(transactionId)
            .then((response) => { resolve(response) })

          // Exit promise if click timeout dialog
          intervalID = setInterval(() => {
            // leave Promise
            if (manualRefundRef.current || cancelRefundRef.current) resolve('')
          }, 1000)
        })
        // Payment request finished
        clearInterval(intervalID)
        paymentResult = result
        break
      }
    }
    dispatch(actions.app.closeDialog(['ePayment']))
    if (paymentResult.success) {
      onSuccess()
    } else {
      if (!(manualRefundRef.current || cancelRefundRef.current)) {
        dispatch(creditCardPaymentErrorHandler(paymentResult?.error))
      }
    }
  }
}

export function startEcrPayment (isMeet, selectPrinterBeforePrint, payMethod = undefined, manualPaidRef, cancelPaymentRef) {
  return async (dispatch, getState) => {
    const emitterName = startEcrPayment.name
    const checkoutOrder = getHistoryOrder()
    const checkoutPayment = getState().orderCheckout.payment
    const checkoutPaymentIndex = checkoutOrder?.payments.findIndex(payment => payment.id === checkoutPayment.id)
    const amt = Number(checkoutPayment.amount)
    const multipliedAmt = (amt * 100).toFixed(0)
    const ecrRef = `INV${checkoutOrder.serial}_${shortid.generate()}`
    const msgId = uuid()
    const isQrPay = payMethod === ecrPayMethods.QR_PAY.GLOBAL_PAYMENT
    dispatch(actions.app.showDialog(['ePayment']))
    let paymentResult = null

    switch (checkoutPayment.gateway) {
      case ecrGateway.GLOBAL_PAYMENT: {
        const globalPayment = new GlobalPayment()
        globalPayment.checkLineStatus(emitterName)
        let intervalID = null
        const result = await new Promise((resolve, reject) => {
          globalPayment.emitter.on(emitterName, async (success) => {
            if (success) {
              if (payMethod === ecrPayMethods.PAYME.GLOBAL_PAYMENT) {
                globalPayment.paymeSales(String(multipliedAmt).padStart(12, '0'), msgId.replace(/-/gi, ''), ecrRef, emitterName)
                  .then((response) => { resolve(response) })
              } else if (payMethod === ecrPayMethods.OCTOPUS.GLOBAL_PAYMENT) {
                globalPayment.octopusSales(multipliedAmt, ecrRef, emitterName)
                  .then((response) => { resolve(response) })
              } else {
                globalPayment.sales(multipliedAmt, ecrRef, `${checkoutOrder.serial}-${checkoutPaymentIndex + 1}`, isQrPay, emitterName)
                  .then((response) => { resolve(response) })
              }
            } else {
              dispatch(creditCardPaymentErrorHandler('failToConnectionCardCenter'))
            }
          })
          // Exit promise if click timeout dialog
          intervalID = setInterval(() => {
            // leave Promise
            if (manualPaidRef.current || cancelPaymentRef.current) resolve('')
          }, 1000)
        })
        // Payment request finished
        clearInterval(intervalID)
        paymentResult = result

        break
      }
      case ecrGateway.EFT_PAY: {
        const eftpay = new EftPay()

        dispatch(updateEcrPayingRecord(ecrRef, ecrGateway.EFT_PAY, checkoutOrder.id, false, selectPrinterBeforePrint, payMethod === 'SHOWQR' ? 'EWALLET' : payMethod))

        let intervalID = null
        const result = await new Promise((resolve, reject) => {
          eftpay.sales(amt, ecrRef, payMethod)
            .then((response) => { resolve(response) })

          // Exit promise if click timeout dialog
          intervalID = setInterval(() => {
            // leave Promise
            if (manualPaidRef.current || cancelPaymentRef.current) resolve('')
          }, 1000)
        })
        // Payment request finished
        clearInterval(intervalID)
        paymentResult = result
        break
      }
      case ecrGateway.BBMSL: {
        const bbMSL = new BBMSL()
        const invoiceNumber = `${ecrRef}-${checkoutPaymentIndex}`
        dispatch(updateEcrPayingRecord(invoiceNumber, 'bbMSL', checkoutOrder.id, false, selectPrinterBeforePrint))

        let intervalID = null
        const result = await new Promise((resolve, reject) => {
          bbMSL.sales(amt, invoiceNumber, payMethod)
            .then((response) => { resolve(response) })

          // Exit promise if click timeout dialog
          intervalID = setInterval(() => {
            // leave Promise
            if (manualPaidRef.current || cancelPaymentRef.current) resolve('')
          }, 1000)
        })
        // Payment request finished
        clearInterval(intervalID)
        paymentResult = result
        break
      }
    }
    dispatch(actions.app.closeDialog(['ePayment']))
    if (paymentResult?.success) {
      let responsePaymentMethod = null
      if (checkoutPayment.gateway === ecrGateway.GLOBAL_PAYMENT) {
        responsePaymentMethod = paymentConfigs.ecrProviders.find(ecrProvider => ecrProvider.ecrKey?.globalPayment === paymentResult?.data?.card)
      } else if (checkoutPayment.gateway === ecrGateway.EFT_PAY) {
        responsePaymentMethod = paymentConfigs.ecrProviders.find(ecrProvider => ecrProvider.ecrKey?.eftPay === paymentResult?.data?.card)
      } else if (checkoutPayment.gateway === ecrGateway.BBMSL) {
        responsePaymentMethod = paymentConfigs.ecrProviders.find(ecrProvider => ecrProvider.ecrKey?.bbmsl === paymentResult?.data?.card)
      }
      dispatch(creditCardPaymentSuccessHandler(isMeet, paymentResult.data, selectPrinterBeforePrint, checkoutOrder.id, responsePaymentMethod))
    } else {
      // result unsuccess
      if (!(manualPaidRef.current || cancelPaymentRef.current)) {
        dispatch(creditCardPaymentErrorHandler(paymentResult?.error))
      }
    }
  }
}

export function creditCardPaymentSuccessHandler (isMeet, response, selectPrinterBeforePrint, orderId, responsePaymentMethod) {
  return async (dispatch, getState) => {
    console.log(responsePaymentMethod)
    const now = moment()
    dispatch({
      type: ActionTypes.EDIT_FAILED_BBMSL,
      payload: {
        invoiceNumber: null,
      },
    })
    const paymentMethod = responsePaymentMethod || paymentConfigs.ecrProviders.find(ecrProvider => ecrProvider.key === response.card)
    console.log({ response })
    const paymentUpdates = {
      gateway: response.gateway,
      createdAt: now.utc().toISOString(),
      payload: JSON.stringify(response.payload),
      ref: response.ecrRef,
    }
    if (paymentMethod) {
      paymentUpdates.paymentMethod = paymentMethod?.key
    }
    dispatch(actions.orderCheckout.updatePayment(paymentUpdates))
    dispatch(actions.orderCheckout.updateEcrPayingRecord(response.ecrRef, response.gateway, orderId, true))

    if (isMeet && selectPrinterBeforePrint) {
      dispatch(actions.app.showDialog(['checkout', 'selectPrinter']))
      return
    }

    dispatch(submitOrderPayment())
  }
}

export function creditCardPaymentErrorHandler (ErrorType = 'fail', rspCode = '') {
  return async (dispatch, getState) => {
    logger.log('[Payment] Error', { ErrorType: ErrorType, rspCode: rspCode })
    if (i18n.exists(`app.page.checkout.paymentResult.${ErrorType}`)) {
      dispatch(actions.app.showAlert({
        title: i18n.t('app.page.checkout.paymentResult.fail'),
        message: i18n.t(`app.page.checkout.paymentResult.${ErrorType}`, { error_code: rspCode }),
      }))
    } else {
      dispatch(actions.app.showAlert({
        title: i18n.t('app.page.checkout.paymentResult.fail'),
        message: `${i18n.t('app.page.checkout.paymentResult.fail')} ( ${ErrorType} )`,
      }))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateEcrPayingRecord (invoiceNumber, gateway, orderID, success, selectPrinterBeforePrint, payMethod) {
  return async (dispatch, getState) => {
    const ecrPayingRecord = getState().orderCheckout.ecrPayingRecord || {}
    if (success) {
      const updatedRecord = _.omit(ecrPayingRecord, orderID)
      dispatch(actions.app.updateSetting(['ecrPayingRecord'], updatedRecord))
    } else {
      const updatedRecord = {
        ...ecrPayingRecord,
        [orderID]: { id: invoiceNumber, gateway, selectPrinterBeforePrint, payMethod },
      }
      dispatch(actions.app.updateSetting(['ecrPayingRecord'], updatedRecord))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateConnectGateway (connect) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_CONNECT_GATEWAY,
      payload: { connect },
    })
  }
}
