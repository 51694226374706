import { StyleSheet, View } from 'react-native'
import { produce } from 'immer'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { PrintStyle } from '@/constants/printing'
import { actions, useDispatch, useSelector } from '@/redux'
import { defaultReceiptStyle } from '@/constants/defaultReceiptStyle'
import BackNavigator from '@/components/Setting/BackNavigator'
import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import colors from '@/theme/colors'
// import ChipLabel from '@/components/ChipLabel'
// import HeaderRightAction from '@/components/Setting/HeaderRightAction'
// import HeaderText from '@/components/Setting/HeaderText'

import Preview from './Preview'
import SettingInfo from './SettingInfo'

/**
 * @typedef CustomizedStyleSettingProps
 * @property {string} title
 * @property {() => void} onClose
 * @property {*} setting
 */

/**
 *
 * @param {CustomizedStyleSettingProps} props
 * @returns
 */
export default function CustomizedStyleSetting (props) {
  const { title, setting, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const printerSetting = useSelector(state => state.printer.printerSetting)
  const [selectedType, setSelectedType] = React.useState('dineIn')

  const enableCRM = useSelector(state => state.merchant.data.setting.enableCRM)

  const joinMemberQRCode = defaultReceiptStyle.find(style => style.key === 'joinMemberQRCode')
  const clonedSettingStyle = _.cloneDeep(setting.style)

  const hasJoinMemberQRCode = clonedSettingStyle.some(obj => obj.key === 'joinMemberQRCode')

  // 有開啟 CRM ，但現有setting.style 沒有CRM QRCode style，就加入
  if (enableCRM && !hasJoinMemberQRCode) {
    clonedSettingStyle.push(joinMemberQRCode)
  }

  const [settingState, setSettingState] = useImmer(clonedSettingStyle)
  const [selectedBlock, setSelectedBlock] = useImmer(null)
  const [preview, setPreview] = React.useState(false)
  const [optionSelectDialogOpen, setOptionSelectDialogOpen] = React.useState(false)
  const [name, setName] = React.useState(setting.name)
  const [lineSpacing, setLineSpacing] = React.useState(setting.lineSpacing)
  const typeOptions = [
    { value: 'dineIn', display: t('app.page.setting.printer.customizedSetting.dineIn') },
    { value: 'takeaway', display: t('app.page.setting.printer.customizedSetting.takeaway') },
    { value: 'delivery', display: t('app.page.setting.printer.customizedSetting.delivery') },
  ]

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.common.delete'),
      message: `${t('app.page.setting.printer.customizedSetting.delete.msg')}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            const newPrinterSetting = produce(printerSetting, (draft) => {
              const draftSettings = draft.customizedStyleSettings
              // * Delete
              const index = draftSettings.findIndex(draftSetting => {
                return setting.id === draftSetting.id
              })
              draftSettings.splice(index, 1)
            })
            dispatch(actions.printer.updatePrinterSetting({ printerSetting: newPrinterSetting, showSuccessMessage: true }))
            onClose()
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = async () => {
    const newPrinterSetting = produce(printerSetting, (draft) => {
      const draftSettings = draft.customizedStyleSettings
      if (setting.id) {
        // * Update
        const index = draftSettings.findIndex(draftSetting => {
          return setting.id === draftSetting.id
        })
        draftSettings[index] = {
          ...setting,
          style: settingState,
          name,
          lineSpacing,
        }
      } else {
        // * Create
        draftSettings.push({
          id: uuid(),
          ...setting,
          style: settingState,
          name,
          lineSpacing,
        })
      }
      draft.customizedStyleSettings = draftSettings
    })

    dispatch(actions.printer.updatePrinterSetting({ printerSetting: newPrinterSetting, showSuccessMessage: true }))
    onClose()
  }

  const onChange = (path, value) => {
    const index = settingState.findIndex(s => s.id === selectedBlock.id)
    setSelectedBlock(draft => {
      _.set(draft, path, value)
    })
    setSettingState(draft => {
      _.set(draft[index], path, value)
    })
  }

  const onAdd = (value) => {
    setSettingState(draft => {
      draft.push(value)
    })
    setSelectedBlock(null)
  }

  const onDelete = () => {
    const index = settingState.findIndex(s => s.id === selectedBlock.id)
    setSettingState(draft => {
      _.remove(draft, (d, i) => index === i)
    })
    setSelectedBlock(null)
  }

  const renderItem = ({ item, index, drag, isActive }) => {
    const selected = selectedBlock && item.id === selectedBlock?.id
    return (
      <SettingListItem
        key={item.id}
        divider
        draggable
        dragging={isActive}
        dragDisabled={!item.drag}
        onDrag={drag}
        selected={selected}
        onPress={() => {
          if (selected) {
            setSelectedBlock(null)
          } else if (item.drag) {
            setSelectedBlock(item)
          }
        }}
      >
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.' + (item.key === 'custom' ? item.type : item.key))} />
      </SettingListItem>
    )
  }

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <BackNavigator
          text={title}
          onPress={onClose}
        />
        <View style={styles.content}>
          {
            preview
              ? (
                <Preview
                  blocks={settingState}
                  selectedBlock={selectedBlock}
                  onSelect={(block) => setSelectedBlock(block)}
                  onChangeOrder={(data) => setSettingState(data)}
                />
              )
              : (
                <>
                  <DraggableFlatList
                    style={{ backgroundColor: colors.white }}
                    showsVerticalScrollIndicator={false}
                    data={settingState}
                    keyExtractor={(item, index) => String(item.key + index)}
                    renderItem={renderItem}
                    onDragEnd={({ data }) => {
                      setSettingState(data)
                    }}
                  />
                  <SettingListFooterAddButton
                    text={t('app.page.setting.printer.customizedSetting.add')}
                    onPress={() => setSelectedBlock({
                      key: 'custom',
                      type: null,
                      enable: true,
                      id: uuid(),
                      drag: true,
                      added: false,
                      style: PrintStyle.SEPARATOR.DOTTED,
                      align: PrintStyle.ALIGN.LEFT,
                      fontSize: 0,
                      bold: false,
                      text: '',
                    })}
                  />
                </>
              )
          }
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainerRight}>
        <Header>
          {/* <HeaderText text={t('app.page.setting.printer.customizedSetting.' + selectedType)} />
          <HeaderRightAction>
            <TouchableOpacity
              onPress={() => setOptionSelectDialogOpen(true)}
            >
              <ChipLabel
                active
                label={t('app.page.setting.printer.customizedSetting.change')}
              />
            </TouchableOpacity>
          </HeaderRightAction> */}
        </Header>
        <View style={styles.content}>
          <SettingList>
            <SettingListItem divider>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.name')} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={name}
                  onChangeText={(text) => setName(text)}
                />
              </SettingListItemRightAction>
            </SettingListItem>
            <SettingListItem>
              <SettingListItemText text={t('app.page.setting.menuPanel.menuInfo.lineSpacing')} />
              <SettingListItemRightAction>
                <SettingListInput
                  value={String(lineSpacing)}
                  keyboardType='number-pad'
                  onChangeText={(value) => {
                    if (/^\d+$/.test(value) || value === '') {
                      setLineSpacing(value)
                    }
                  }}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          </SettingList>
          {
            Boolean(selectedBlock) && (
              <SettingInfo
                setting={selectedBlock}
                onChange={onChange}
                onAdd={onAdd}
                onDelete={onDelete}
              />
            )
          }
        </View>
        <View style={styles.buttons}>
          {
            setting.id && (
              <Button
                textBold
                title={t('app.common.delete')}
                style={styles.button}
                backgroundColor={colors.primary}
                onPress={handleDelete}
              />
            )
          }
          <Button
            textBold
            title={t('app.common.save')}
            style={styles.button}
            backgroundColor={colors.darkSecondary}
            onPress={handleSave}
          />
        </View>
      </View>
      <OptionsSelectDialog
        title={t('app.page.setting.printer.customizedSetting.type')}
        open={optionSelectDialogOpen}
        onClose={() => setOptionSelectDialogOpen(false)}
        options={typeOptions}
        defaultValue={selectedType}
        onSubmit={(id) => {
          setOptionSelectDialogOpen(false)
          setSelectedType(id)
        }}
        modalProps={{ noTitle: true }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
  },
  contentContainerRight: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
    marginLeft: 20,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  preview: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
    paddingRight: 15,
    paddingLeft: 15,
    borderWidth: 1,
    borderColor: colors.white,
  },
  settingList: {
    marginBottom: 10,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
