import React from 'react'
import _ from 'lodash'

import { filterRequestPayingOrders, filterRequestWaiterOrders } from '@/libs/orderHistory'
import { useSelector } from '@/redux'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @returns {IAppOrder?}
 */
export function useSelectedOrder () {
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const selectedOrderId = useSelector(state => state.orderHistory.selectedOrderId)
  const searchOrder = useSelector(state => state.orderHistory.searchOrder)
  let order = historyOrders.find(order => order.id === selectedOrderId)
  if (Object.keys(searchOrder).length) {
    order = searchOrder
  }
  return order
}

/**
 * 取得叫侍應的訂單和請求付款的訂單
 * @returns {{
 * requestWaiterOrders: IAppOrder[],
 * requestWaiterOrderSerials: string[],
 * requestPayingOrders: IAppOrder[],
 * requestPayingOrderSerials: string[],
 * }}
 */
export function useNoticeOrders () {
  const historyOrders = useSelector(state => state.orderHistory.orders)

  return React.useMemo(() => {
    // 要叫侍應的訂單
    const requestWaiterOrders = filterRequestWaiterOrders(historyOrders)
    const requestWaiterOrderSerials = _.map(requestWaiterOrders, 'serial')
    // 要請求付款的訂單
    const requestPayingOrders = filterRequestPayingOrders(historyOrders)
    const requestPayingOrderSerials = _.map(requestPayingOrders, 'serial')

    return {
      requestWaiterOrders,
      requestWaiterOrderSerials,
      requestPayingOrders,
      requestPayingOrderSerials,
    }
  }, [historyOrders])
}

/**
 * 取得叫侍應的訂單
 * @returns {[IAppOrder[], string[]]}
 */
export function useRequestWaiterOrders () {
  const { requestWaiterOrders, requestWaiterOrderSerials } = useNoticeOrders()
  return [requestWaiterOrders, requestWaiterOrderSerials]
}

/**
 * 取得請求付款的訂單
 * @returns {[IAppOrder[], string[]]}
 */
export function useRequestPayingOrders () {
  const { requestPayingOrders, requestPayingOrderSerials } = useNoticeOrders()
  return [requestPayingOrders, requestPayingOrderSerials]
}
