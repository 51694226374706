import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import NumberInputDialog from '@/components/dialogs/NumberInputDialog'

/**
 * @typedef ItemNumberInputDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {() => void} onSubmit
 * @property {*[]} items
 * @property {boolean?} useDefault
 * @property {boolean?} selectAll
 */

/**
 * @param {ItemNumberInputDialogProps} props
 */
export default function ItemNumberInputDialog (props) {
  const {
    open,
    onClose,
    onSubmit,
    items,
    useDefault = true,
    selectAll = true,
  } = props
  const { t } = useTranslation()
  const [quantity, setQuantity] = React.useState({})
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [isDefault, setIsDefault] = React.useState({})

  const title = t('app.page.order.openingPrompt.title')

  React.useEffect(() => {
    if (open && items) {
      setSelectedItem(items[0])
      const value = {}
      const value2 = {}
      _.each(items, i => {
        value[i.id] = useDefault ? 1 : 0
        value2[i.id] = useDefault
      })
      setIsDefault(value2)
      setQuantity(value)
    }
  }, [open, items])

  const reset = () => {
    setQuantity({})
    setSelectedItem(null)
  }

  const submit = async (selectedItems) => {
    onSubmit(selectedItems)
  }

  const handleClose = () => {
    onClose()
    reset()
  }

  const renderItem = ({ item, index }) => {
    const selected = selectedItem === item
    const defaultInput = isDefault[item?.id]
    return (
      <TouchableOpacity
        style={[styles.listItem, selected && styles.selectedStyle]}
        onPress={() => setSelectedItem(item)}
      >
        <View style={styles.itemName}>
          <Text style={{ fontSize: 15 }}>
            {item.name}
          </Text>
        </View>
        <View style={styles.number}>
          <Text style={[styles.text, defaultInput ? styles.defaultText : null]}>
            {quantity[item.id] ?? 0}
          </Text>
          {
            selectAll && (
              <Text style={styles.text}>
                {` / ${item.quantity}`}
              </Text>
            )
          }
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <NumberInputDialog
      title={title}
      open={open}
      onClose={handleClose}
      disableDecimalPoint
      defaultValue={quantity[selectedItem?.id] ?? 0}
      maxAmount={selectedItem?.quantity}
      replace
      clearValue='C'
      onPress={(value) => {
        let input = ''
        const selectedId = { ...selectedItem }.id
        if (String(value).includes('C')) {
          setQuantity({
            ...quantity,
            [selectedItem?.id]: 0,
          })
          return
        }
        if (isDefault[selectedItem?.id] || String(quantity[selectedItem?.id]) === '0') {
          input = String(value)
        } else {
          input = String(quantity[selectedItem.id]) + String(value)
        }
        setIsDefault({
          ...isDefault,
          [selectedId]: false,
        })
        if (Number(input) > Number(selectedItem.quantity)) return
        if (input.length <= String(selectedItem.quantity).length) {
          setQuantity({
            ...quantity,
            [selectedItem?.id]: Number(input),
          })

          if (Number(input) * 10 > Number(selectedItem?.quantity)) {
            const currentItemIndex = items.findIndex(item => item.id === selectedItem?.id)
            const itemsLength = items.length
            if (currentItemIndex + 1 < itemsLength) {
              setSelectedItem(items[currentItemIndex + 1])
            }
          }
        } else {
          setQuantity({
            ...quantity,
            [selectedItem?.id]: Number(value),
          })
        }
      }}
    >
      <View style={styles.container}>
        <View style={styles.inputGroup}>
          <FlatList
            data={items}
            renderItem={renderItem}
            keyExtractor={(item, index) => String(item.id) + index}
            showsVerticalScrollIndicator={false}
          />
        </View>
        <View style={styles.buttons}>
          {
            selectAll && (
              <Button
                backgroundColor={colors.tertiary}
                title={t('app.common.all')}
                onPress={async () => {
                  const all = {}
                  items.map(i => { all[i.id] = i.quantity })
                  await submit(all)
                  handleClose()
                }}
              />
            )
          }
          <Button
            title={t('app.common.send')}
            onPress={async () => {
              if (_.some(_.values(quantity), q => q)) {
                await submit(quantity)
              }
              handleClose()
            }}
          />
        </View>
      </View>
    </NumberInputDialog>
  )
}

const styles = StyleSheet.create({
  inputGroup: {
    width: '100%',
    height: 250,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  defaultText: {
    color: colors.gray,
  },
  container: {
    width: 350,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 20,
  },
  listItem: {
    borderWidth: 1,
    borderColor: colors.white,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: 330,
    justifyContent: 'space-between',
    padding: 10,
  },
  number: {
    width: 70,
    alignItems: 'flex-end',
    paddingRight: 5,
    paddingLeft: 5,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    fontSize: 20,
    color: colors.primary,
    fontWeight: 'bold',
  },
  selectedStyle: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.primary,
    backgroundColor: colors.backgroundPrimary,
  },
  itemName: {
    width: 240,
    justifyContent: 'center',
  },
})
