import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { printing } from '@/constants'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import Header from '@/components/Setting/Header'
import Payment from './ReceiptStyle/Payment'
import ReceiptContent from './ReceiptStyle/Content'
import ReceiptHeader from './ReceiptStyle/Header'
import ReceiptInfo from './ReceiptStyle/Info'
import Subtotal from './ReceiptStyle/Subtotal'
import colors from '@/theme/colors'
import printingStyle from './styles'
import shadows from '@/theme/shadows'

const separatorStyle = {
  dotted: '---------------------------------------------------------',
  doubleDotted: '==========================================',
  straight: '_________________________________________________',
}

/**
 * @param {{
 * blocks: object[],
 * selectedBlock: object,
 * onSelect: () => void,
 * onChangeOrder: () => void,
 * }} props
 */
export default function Preview (props) {
  const { blocks, selectedBlock, onSelect, onChangeOrder } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const merchant = useSelector(state => state.merchant.data)

  const renderPreviewItem = ({ item, index, drag, isActive }) => {
    const selected = selectedBlock && item.id === selectedBlock?.id
    return (
      <TouchableWithoutFeedback
        onLongPress={item.drag && drag}
        onPress={() => {
          if (selected) {
            onSelect(null)
          } else if (item.drag) {
            onSelect(item)
          }
        }}
      >
        <View style={[
          styles.preview,
          isActive && shadows.slight,
          selected && styles.selected,
        ]}
        >
          {block(item)}
        </View>
      </TouchableWithoutFeedback>
    )
  }

  const textSize = (size) => {
    switch (size) {
      case 0:
        return printingStyle.small
      case 1:
        return printingStyle.medium
      case 2:
        return printingStyle.large
      default:
        return printingStyle.small
    }
  }

  const block = (item) => {
    switch (item.key) {
      case 'separator' : {
        return (
          <Text style={printingStyle.textCenter}>{separatorStyle[item.style]}</Text>
        )
      }
      case 'header' : {
        return (
          <ReceiptHeader
            items={item.content}
          />
        )
      }
      case 'info' : {
        return (
          <ReceiptInfo
            items={item.content}
          />
        )
      }
      case 'content' : {
        return (
          <ReceiptContent
            item={item}
          />
        )
      }
      case 'subtotal' : {
        return (
          <Subtotal />
        )
      }
      case 'payment' : {
        return (
          <Payment />
        )
      }
      case 'footer' : {
        return (
          <Text style={[printingStyle.center, textSize(1)]}>{merchant.setting.footerCustomText}</Text>
        )
      }
      case 'dimorder' : {
        return (
          <View style={{ padding: 10 }}>
            <Text style={printingStyle.center}>- Powered By DimPOS -</Text>
          </View>
        )
      }
      default: {
        return (
          <Header />
        )
      }
    }
  }

  return (
    <DraggableFlatList
      style={{ width: 400 }}
      showsVerticalScrollIndicator={false}
      data={blocks}
      keyExtractor={(item, index) => String('preview' + index)}
      renderItem={renderPreviewItem}
      onDragEnd={({ data }) => onChangeOrder(data)}
    />
  )
}

const styles = StyleSheet.create({
  preview: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
    paddingRight: 15,
    paddingLeft: 15,
    borderWidth: 1,
    borderColor: colors.white,
  },
  selected: {
    borderColor: colors.lightBlue1,
  },
})
