import { StyleSheet } from 'react-native'
import React from 'react'

import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import NumberButton from '@/components/buttons/NumberButton'
import Row from '@/components/Row'

const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'C', 0, '.']
const amounts = [20, 50, 100, 500]

/**
 * @typedef NumberPadProps
 * @property {boolean?} disabled
 * @property {string?} color
 */

/**
 *
 * @param {NumberPadProps} props
 * @returns
 */
export default function NumberPad (props) {
  const { disabled, color = colors.light } = props
  return (
    <Row style={[styles.container, { ...shadows.default }]}>
      <Row style={styles.main}>
        {
          values.map((option) =>
            <NumberButton
              key={`calculator-${option}`}
              text={option}
              style={[styles.button, { backgroundColor: color }]}
              clear={option === 'C'}
              disabled={disabled && option !== 'C'}
              onPress={() => props.handleKeyboardInput(option)}
            />)
        }
      </Row>
      <Row style={styles.side}>
        {
          amounts.map((option) =>
            <NumberButton
              key={`calculator-${option}`}
              text={option}
              style={[styles.button, { backgroundColor: colors.lightPrimary }]}
              side
              onPress={() => props.handleAmountInput(option)}
            />)
        }
      </Row>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 323,
    width: 323,
    justifyContent: 'space-between',
    borderRadius: 20,
    overflow: 'hidden',
  },
  main: {
    height: 323,
    width: 241,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  side: {
    width: 77,
    flexWrap: 'wrap',
    alignContent: 'space-between',
  },
  button: {
    height: 77,
    width: 77,
    backgroundColor: '#FFFFFF',
  },
})
