import { ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useLocation } from '@/libs/reactRouter'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import CustomizedKitchenPanel from '../CustomizedKitchenPanel'
import CustomizedKitchenSetting from '../CustomizedKitchenPanel/CustomizedKitchenSetting'
import CustomizedLabelPanel from '../CustomizedLabelPanel'
import CustomizedLabelSetting from '../CustomizedLabelPanel/CustomizedLabelSetting'
import CustomizedStylePanel from '../CustomizedStylePanel'
import CustomizedStyleSetting from '../CustomizedStylePanel/CustomizedStyleSetting'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'

import PrinterSetting from './PrinterSetting'

export default function PrintSettingPanel (props) {
  const location = useLocation()
  const { t } = useTranslation()
  const settingConfigs = {
    customer: {
      title: `${t('app.page.setting.printer.confirmBatch') + t('app.page.setting.printer.setting')}`,
      field: 'confirmReceiptSettings',
      initialSetting: {
        name: '', // 名稱
        printer: [], // 打印機
        table: [], // 抬號
        category: [], // 分類
        printTimes: 0, // 重複打印次數
        batchMenuStyleText: 1, // 字型
        takeaway: false,
      },
    },
    kitchen: {
      title: `${t('app.page.setting.printer.kitchenBatch') + t('app.page.setting.printer.setting')}`,
      field: 'kitchenReceiptSettings',
      initialSetting: {
        name: '', // 名稱
        printer: [], // 打印機
        table: [], // 抬號
        category: [], // 分類
        printTimes: 0, // 重複打印次數
        printCancelledItem: false, // 打印取消項目
        cutPaperMode: 'none',
        printSetmenuBundled: false, // 套餐組合打印
        splitItem: false, // 逐項打印
        printWithPrice: false, // 顯示價錢
        printHoldItem: true,
        batchMenuStyleText: 1, // 字型
        menu: null, // ?
        takeaway: false,
        noFooter: false,
      },
    },
    receipt: {
      title: `${t('app.page.setting.printer.receipt') + t('app.page.setting.printer.setting')}`,
      field: 'invoiceSettings',
      initialSetting: {
        name: '', // 名稱
        printer: [], // 打印機
        table: [], // 抬號
        printTimes: 0, // 重複打印次數
        receiptGroupBy: 'all', // 組合方式
        syncReceipt: true, // 確認時打印顧客收據(堂食)
        syncTakeawayReceipt: true, // 確認時打印顧客收據(外賣)
        customerReceiptStyleText: 0, // 字型
        takeaway: false,
        syncReceiptPrioritizedPrinter: false, // 下單時打印收據 (優先打印機)
        lineSpacing: false,
      },
    },
    label: {
      title: `${t('app.page.setting.printer.label') + t('app.page.setting.printer.setting')}`,
      field: 'labelSettings',
      initialSetting: {
        name: '', // 名稱
        printer: [], // 打印機
        table: [], // 抬號
        category: [], // 分類
        printTimes: 0, // 重複打印次數
        printWithPrice: false, // 顯示價錢
        batchMenuStyleText: 0, // 字型
        menu: null, // ?
        printCancelledItem: false, // 打印取消項目
        isLabel: true,
        takeaway: false,
        width: 1.58,
        height: 1.18,
      },
    },
  }
  const page = location.pathname.split('/').pop()

  const [selectedSetting, setSelectedSetting] = React.useState(null)
  const [selectedStyle, setSelectedStyle] = React.useState(null)
  const printerSetting = useSelector(state => state.printer.printerSetting)
  const settingConfig = settingConfigs[page]
  const settings = _.get(printerSetting, settingConfig?.field, [])

  if (selectedSetting) {
    return (
      <PrinterSetting
        type={settingConfig.field}
        title={settingConfig.title}
        setting={selectedSetting}
        onClose={() => {
          setSelectedSetting(null)
        }}
      />
    )
  }

  if (selectedStyle) {
    switch (settingConfig.field) {
      case 'invoiceSettings': {
        return (
          <CustomizedStyleSetting
            title={t('app.routes.setting.printing.customized')}
            setting={selectedStyle}
            onClose={() => setSelectedStyle(null)}
          />
        )
      }
      case 'kitchenReceiptSettings': {
        return (
          <CustomizedKitchenSetting
            title={t('app.routes.setting.printing.customized')}
            setting={selectedStyle}
            onClose={() => setSelectedStyle(null)}
          />
        )
      }
      case 'labelSettings': {
        return (
          <CustomizedLabelSetting
            title={t('app.routes.setting.printing.customized')}
            setting={selectedStyle}
            onClose={() => setSelectedStyle(null)}
          />
        )
      }
    }
  }

  return (
    <View style={styles.container}>

      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={settingConfig.title} />
        </Header>
        <View style={styles.content}>
          <SettingList>
            <ScrollView showsVerticalScrollIndicator={false}>
              {settings?.length === 0 && (
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.printer.noSetting')}</Text>
                </View>
              )}
              {settings?.map((setting, index) => {
                return (
                  <SettingListItem
                    key={index}
                    onPress={() => {
                      setSelectedSetting(setting)
                    }}
                    divider={index !== setting.length - 1}
                  >
                    <SettingListItemText text={setting.name} />
                  </SettingListItem>
                )
              })}
            </ScrollView>
            <SettingListFooterAddButton
              text={t('app.page.setting.printer.add') + t('app.page.setting.printer.setting')}
              onPress={() => {
                setSelectedSetting(settingConfig.initialSetting)
              }}
            />
          </SettingList>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        {
          settingConfig.field === 'invoiceSettings' && (
            <CustomizedStylePanel
              onSelect={setSelectedStyle}
            />
          )
        }
        {
          settingConfig.field === 'kitchenReceiptSettings' && (
            <CustomizedKitchenPanel
              onSelect={setSelectedStyle}
            />
          )
        }
        {
          settingConfig.field === 'labelSettings' && (
            <CustomizedLabelPanel
              onSelect={setSelectedStyle}
            />
          )
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labels: {
    padding: 0,
  },
})
