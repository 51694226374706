import React from 'react'

import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SettingListInputRow (props) {
  const { divider, label, value, onChangeText, inputProps } = props

  return (
    <SettingListItem divider={divider}>
      <SettingListItemText text={label} />
      <SettingListItemRightAction>
        <SettingListInput
          value={value}
          onChangeText={onChangeText}
          {...inputProps}
        />
      </SettingListItemRightAction>
    </SettingListItem>
  )
}
