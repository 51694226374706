import { StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

const variantConfigs = {
  dark: {
    number: {
      buttonStyle: {
        backgroundColor: colors.numPadDarkGray,
      },
      textStyle: {
        color: colors.textSecondary,
      },
    },
    action: {
      buttonStyle: {
        backgroundColor: colors.primary,
      },
      textStyle: {
        color: colors.white,
      },
    },
  },
  light: {
    number: {
      buttonStyle: {
        backgroundColor: colors.numPadGray,
      },
      textStyle: {
        color: colors.textSecondary,
      },
    },
    action: {
      buttonStyle: {
        backgroundColor: colors.lightPrimary,
      },
      textStyle: {
        color: colors.white,
      },
    },
  },
}

/**
 * @param {NumberPadButtonProps} props
 */
export default function NumberPad (props) {
  const { variant: numberPadVariant = 'light', button, size, gap, onButtonPress, disabled } = props
  const { variant: buttonVariant = 'number', fontSize, fontWeight = 'bold', text } = button

  const variantConfig = variantConfigs[numberPadVariant][buttonVariant]
  return (
    <TouchableHighlight
      underlayColor={colors.lightGray2}
      disabled={disabled}
      onPress={() => onButtonPress(text)}
    >
      <View
        style={[
          styles.container,
          { width: size, height: size, margin: gap / 2 },
          disabled && styles.disabled,
          variantConfig.buttonStyle,
        ]}
      >
        <Text
          style={[
            variantConfig.textStyle,
            { fontSize, fontWeight },
          ]}
        >
          {text}
        </Text>
      </View>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  container: {
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.4,
  },
})
