import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'

const checkbox = require('@icons/checkbox.png')
const checked = require('@icons/checkbox-checked.png')

/**
 * @typedef CheckBoxProps
 * @property {boolean} value
 * @property {() => void} onChangeValue
 * @property {boolean?} disabled
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {CheckBoxProps} props
 * @returns
 */
export default function CheckBox (props) {
  const { style, value, onChangeValue, disabled } = props
  const iconSource = value ? checked : checkbox

  return (
    <TouchableOpacity onPress={() => onChangeValue(!value)} disabled={disabled}>
      <View style={[styles.container, style]}>
        <Image style={[styles.icon, disabled && styles.disabled]} source={iconSource} />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    padding: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 17,
    height: 17,
  },
  disabled: {
    opacity: 0.6,
  },
})
