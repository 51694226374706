import { PanResponder } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'

/**
 * 螢幕鎖定
 * @returns
 */
export default function useScreenLock () {
  const dispatch = useDispatch()
  const isUserLogin = useSelector(state => state.auth.isUserLogin)
  const delay = useSelector(state => state.app.settings.screenLock, _.isEqual) // milliseconds
  const timoutId = useRef('')

  const resetInactivityTimeout = useCallback(() => {
    clearTimeout(timoutId.current)
    if (!isUserLogin) return
    if (delay === 0) return
    timoutId.current = setTimeout(() => {
      dispatch(actions.app.showDialog(['screenLock']))
    }, delay)
  }, [delay])

  const panResponder = useMemo(() =>
    PanResponder.create({
      onStartShouldSetPanResponderCapture: () => {
        resetInactivityTimeout()
      },
    }), [delay],
  )

  useEffect(() => {
    resetInactivityTimeout()
  }, [isUserLogin, delay])

  return panResponder
}
