import { StyleSheet, View } from 'react-native'
import React from 'react'

/**
 * @typedef ColumnProps
 * @property {JSX.Element} children
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {ColumnProps} props
 * @returns
 */
export default function Column (props) {
  const { style, children } = props
  return (
    <View style={[styles.container, style]}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
})
