import { FlatList, ScrollView, StyleSheet, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import PrintingSettingList from './PrintingSettingList'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * onAdd: () => void,
 * onDelete: () => void,
 * }} props
 */
export default function SettingInfo (props) {
  const { setting, onChange } = props
  const { t } = useTranslation()
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const [selecting, setSelecting] = React.useState(false)
  const { orderSerialDigit = 3 } = useSelector(state => state.printer.printerSetting.defaultSettings)
  const textStyleOptions = [
    { value: 7, display: '7' },
    { value: 8, display: '8' },
    { value: 9, display: '9' },
    { value: 10, display: '10' },
    { value: 11, display: '11' },
    { value: 12, display: '12' },
    { value: 13, display: '13' },
    { value: 14, display: '14' },
    { value: 15, display: '15' },
    { value: 16, display: '16' },
    { value: 17, display: '17' },
    { value: 18, display: '18' },
    { value: 19, display: '19' },
    { value: 20, display: '20' },
  ]

  React.useEffect(() => {
    setOptionsPanelConfig(null)
    setSelecting(null)
  }, [setting?.id])

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  const renderItem = ({ item, index, drag, isActive }) => {
    return (
      <PrintingSettingList
        divider={_.size(setting.items) !== index + 1}
        setting={setting}
        title={t('app.page.setting.printer.customizedSetting.' + item.type)}
        path={`items.${index}`}
        selecting={selecting}
        onChange={onChange}
        onSelect={(value) => setSelecting(value)}
      >
        {
          item.type === 'serialNo' && (
            <SettingListItem>
              <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.onlyLast3Digit', { digit: orderSerialDigit })} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={_.get(setting, `items.${index}.last3Digit`, false)}
                  onChangeValue={() => onChange(`items.${index}.last3Digit`, !_.get(setting, `items.${index}.last3Digit`, false))}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          )
        }
      </PrintingSettingList>
    )
  }

  return (
    <>
      <Header>
        <HeaderText text={t('app.page.setting.printer.customizedSetting.' + setting.key)} />
      </Header>
      <ScrollView style={styles.content} showsVerticalScrollIndicator={false}>
        <SettingList>
          <FlatList
            data={setting.items}
            renderItem={renderItem}
            keyExtractor={(item, index) => item.id + String(index)}
            showsVerticalScrollIndicator={false}
            ListEmptyComponent={(
              <View style={styles.emptyView} />
            )}
          />
        </SettingList>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  content: {
    flex: 1,
  },
})
