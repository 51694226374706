export const PrintStyle = {
  TIME: {
    LONG12: 'YYYY/MM/DD hh:mm:ss a',
    LONG24: 'YYYY/MM/DD HH:mm:ss',
    SHORT12: 'MM/DD hh:mm a',
    SHORT24: 'MM/DD HH:mm',
  },
  SEPARATOR: {
    DOTTED: 'dotted',
    DOUBLE_DOTTED: 'doubleDotted',
    STRAIGHT: 'straight',
  },
  TEXT_SEPARATOR: {
    SPACE: '  ',
    NEW_LINE: '\n',
    COMMA: ', ',
  },
  TYPE: {
    IMAGE: 'image',
    TEXT: 'text',
    TIME: 'time',
    QRCODE: 'qrcode',
    BARCODE: 'barcode',
  },
  ALIGN: {
    LEFT: 'left',
    CENTER: 'center',
    RIGTH: 'right',
  },
  COLOR: {
    RED: 'red',
    BLACK: 'black',
  },
}

export const PrintReason = {
  ORDER_RECEIPT: {
    CHECKOUT: 'checkout',
    CLICK_CANCEL_ORDER: 'click cancel order',
    CLICK_VOID_ORDER: 'click void order',
    CLICK_PRINT_RECEIPT: 'click print receipt',
    CLICK_PRINT_MAIN_ORDER_RECEIPT: 'click print main order receipt',
    CLICK_PRINT_SUB_ORDER_RECEIPT: 'click print sub order receipt',
    CLICK_PRINT_CHECKOUT_RECEIPT: 'click print checkout receipt',
    CLICK_TABLE_DIALOG_PRINT_RECEIPT: 'click table dialog print receipt',
    ORDER_SUBMITTED: 'order submitted',
    CONFIRM_BATCH: 'confirm batch',
    PAYMENT_SUBMITTED: 'payment submitted',
    WS_PAYMENT_PAID: 'ws event ORDER_PAYMENT_PAID',
  },
  QR_CODE: {
    NEW_ORDER: 'new order auto print',
    CLICK_PRINT: 'click print qr code',
    CLICK_TABLE_DIALOG_PRINT_RECEIPT: 'click table dialog print receipt',
  },
}
