import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'

import Alert from '@/components/Alert'
import Button from '@/components/buttons/Button'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DialogActions (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleClose = () => dispatch(actions.app.closeDialog(['order', 'batchStashes']))
  const [showDeleteAllStashAlert, setShowDeleteAllStashAlert] = React.useState(false)

  return (
    <>
      <View style={styles.container}>
        <Button
          title={t('app.common.deleteAll')}
          type='TouchableOpacity'
          backgroundColor={colors.light}
          textColor={colors.textTertiary}
          textStyle={{ fontWeight: 'bold' }}
          onPress={() => setShowDeleteAllStashAlert(true)}
        />
      </View>

      <Alert
        open={showDeleteAllStashAlert}
        handleClose={() => setShowDeleteAllStashAlert(false)}
        alertConfig={{
          modalProps: { enablePressOutsideClose: true },
          title: t('app.page.order.stash.alert.deleteAll.title'),
          message: t('app.page.order.stash.alert.deleteAll.msg'),
          buttons: [
            {
              title: t('app.common.yes'),
              type: 'TouchableOpacity',
              textStyle: { fontWeight: 'bold' },
              onPress: () => {
                dispatch(actions.orderBatch.dropAllStash())
                handleClose()
                setShowDeleteAllStashAlert(false)
              },
            },
            {
              title: t('app.common.no'),
              type: 'TouchableOpacity',
              backgroundColor: colors.light,
              textColor: colors.textTertiary,
              textStyle: { fontWeight: 'bold' },
              onPress: () => {
                setShowDeleteAllStashAlert(false)
              },
            },
          ],
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
})
