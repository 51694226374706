
export function currencyWithCommas (num, minimumFractionDigits = 0) {
  return Number(num).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits: 2,
  })
}

export function numberWithCommas (num) {
  return Number(num).toLocaleString()
}

export default {
  numberWithCommas,
  currencyWithCommas,
}
