import { FlatList, StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useMemo, useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { formatOrdersForQuickLinkCount } from '@/libs/orderFilters'
import { initialFilter } from '@/redux/orderHistory/reducer'
import IconButton from '@/components/buttons/IconButton'
import Row from '@/components/Row'
import SearchBar from '@/pages/Table/Header/SearchBar'
import colors from '@/theme/colors'

import QuickLink from './QuickLink'

/**
 * @typedef HeaderProps
 * @param {() => void} onOpenFilter
 */

/**
 *
 * @param {HeaderProps} props
 * @returns
 */
export default function Header (props) {
  const { onOpenFilter } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const { quickMode, enableTnDmode } = useSelector(state => state.merchant?.data?.setting) ?? {}
  const query = useSelector(state => state.orderHistory.query)
  const filter = useSelector(state => state.orderHistory.filter)
  const [isSearching, setIsSearching] = useState(false)
  const formattedOrders = useMemo(() => formatOrdersForQuickLinkCount(historyOrders), [historyOrders]) // 記住整理後的格式，之後傳入給每個 QuickLink 做 searchAndFilterOrders 時使用

  // TODO: 待設定UI實作
  const quickLinkConfigs = [
    {
      type: 'table',
      name: t('app.constant.deliveryType.table'),
      query: '',
      filter: {
        ...initialFilter,
        deliveryType: ['table'],
      },
      badge: false,
    },
    {
      type: 'takeaway',
      name: t('app.constant.deliveryType.takeawayShort'),
      query: '',
      filter: {
        ...initialFilter,
        deliveryType: ['takeaway'],
      },
      badge: false,
    },
    {
      type: 'storeDelivery',
      name: t('app.constant.deliveryType.storeDelivery'),
      query: '',
      filter: {
        ...initialFilter,
        deliveryType: ['storeDelivery'],
      },
      badge: false,
    },
    {
      type: 'takeaway-notConfirm',
      name: t('app.page.orderHistory.filterPanel.takeaway-notConfirm'),
      query: '',
      filter: {
        ...initialFilter,
        deliveryType: ['storeDelivery', 'takeaway'],
        displayStatusKey: ['waiting_accept'],
      },
      badge: true,
    },
    {
      type: 'dine-in-notConfirm',
      name: t('app.page.orderHistory.filterPanel.dine-in-notConfirm'),
      query: 'table_waiting_confirm',
      filter: {
        ...initialFilter,
        deliveryType: ['table'],
      },
      badge: true,
    },
    {
      type: 'priceUndetermined',
      name: t('app.page.orderHistory.filterPanel.priceUndetermined'),
      query: 'priceUndetermined',
      filter: {
        ...initialFilter,
        deliveryType: ['table'],
      },
      badge: true,
    },
    {
      type: 'paying',
      name: t('app.page.orderHistory.filterPanel.paying'),
      query: 'paying',
      filter: {
        ...initialFilter,
        deliveryType: ['table'],
      },
      badge: true,
    },
  ]

  const quickLinks = quickLinkConfigs.map(config => {
    let isSelected = false
    switch (config.name) {
      case t('app.constant.deliveryType.table'): {
        isSelected = !query &&
        filter.deliveryType.includes('table') &&
        filter.deliveryType.length === 1 &&
        filter.displayStatusKey.length === 0 &&
        filter.shippingProvider.length === 0 &&
        filter.status.length === 0 &&
        filter.takeawayStatus.length === 0
        break
      }
      case t('app.constant.deliveryType.takeawayShort'): {
        isSelected = !query &&
        filter.deliveryType.includes('takeaway') &&
        filter.deliveryType.length === 1 &&
        filter.displayStatusKey.length === 0 &&
        filter.shippingProvider.length === 0 &&
        filter.status.length === 0 &&
        filter.takeawayStatus.length === 0
        break
      }
      case t('app.constant.deliveryType.storeDelivery'): {
        isSelected = !query &&
        filter.deliveryType.includes('storeDelivery') &&
        filter.deliveryType.length === 1 &&
        filter.displayStatusKey.length === 0 &&
        filter.shippingProvider.length === 0 &&
        filter.status.length === 0 &&
        filter.takeawayStatus.length === 0
        break
      }
      case t('app.page.orderHistory.filterPanel.waitingPay'): {
        isSelected = !query &&
        filter.deliveryType.includes('takeaway') &&
        filter.deliveryType.length === 1 &&
        filter.displayStatusKey.length === 1 &&
        filter.displayStatusKey.includes('waiting_pay') &&
        filter.shippingProvider.length === 0 &&
        filter.status.length === 0 &&
        filter.takeawayStatus.length === 0
        break
      }
      case t('app.page.orderHistory.filterPanel.takeaway-notConfirm'): {
        isSelected = !query &&
        filter.deliveryType.includes('storeDelivery', 'takeaway') &&
        filter.deliveryType.length === 2 &&
        filter.displayStatusKey.length === 1 &&
        filter.displayStatusKey.includes('waiting_accept') &&
        filter.shippingProvider.length === 0 &&
        filter.status.length === 0 &&
        filter.takeawayStatus.length === 0
        break
      }
      case t('app.page.orderHistory.filterPanel.dine-in-notConfirm'): {
        isSelected = query === 'table_waiting_confirm' &&
        filter.deliveryType.includes('table') &&
        filter.deliveryType.length === 1 &&
        filter.displayStatusKey.length === 0 &&
        filter.shippingProvider.length === 0 &&
        filter.status.length === 0 &&
        filter.takeawayStatus.length === 0
        break
      }
      case t('app.page.orderHistory.filterPanel.priceUndetermined'): {
        isSelected = query === 'priceUndetermined' &&
        filter.deliveryType.includes('table') &&
        filter.deliveryType.length === 1 &&
        filter.displayStatusKey.length === 0 &&
        filter.shippingProvider.length === 0 &&
        filter.status.length === 0 &&
        filter.takeawayStatus.length === 0
        break
      }
      case t('app.page.orderHistory.filterPanel.paying'): {
        isSelected = query === 'paying' &&
        filter.deliveryType.includes('table') &&
        filter.deliveryType.length === 1 &&
        filter.displayStatusKey.length === 0 &&
        filter.shippingProvider.length === 0 &&
        filter.status.length === 0 &&
        filter.takeawayStatus.length === 0
        break
      }
    }
    return {
      ...config,
      selected: isSelected,
    }
  })

  const quickLinksInQuickMode = quickLinks.filter(link => link.type !== 'table' && link.type !== 'dine-in-notConfirm')

  const renderItem = ({ item, index }) => {
    return (
      <QuickLink
        key={index}
        text={item.name}
        selected={item.selected}
        showBadge={item.badge}
        query={item.query}
        filter={item.filter}
        formattedOrders={formattedOrders}
        onPress={() => {
          if (item.selected) {
            dispatch(actions.orderHistory.resetFilter())
            dispatch(actions.orderHistory.updateQuery(''))
          } else {
            dispatch(actions.orderHistory.updateQuery(item.query))
            dispatch(actions.orderHistory.applyFilter(item.filter))
          }
        }}
      />
    )
  }

  return (
    <Row style={styles.container}>
      <Row style={styles.main}>
        <Text style={styles.text}>{t('app.page.orderHistory.header')}</Text>
        <SearchBar
          active={isSearching}
          onOpen={() => setIsSearching(true)}
          onClose={() => {
            setIsSearching(false)
            dispatch(actions.orderHistory.updateQuery(''))
          }}
          searchText={query}
          setSearchText={(searchText) => {
            dispatch(actions.orderHistory.updateQuery(searchText))
          }}
          style={{ marginLeft: 18 }}
        />
        {!isSearching &&
          <IconButton
            iconSource={require('@icons/orders/filter.png')}
            style={{ marginHorizontal: 18 }}
            iconStyle={styles.icon}
            onPress={onOpenFilter}
          />}
      </Row>
      {
        !isSearching && (
          <FlatList
            inverted
            style={styles.linkLists}
            data={(!(quickMode || enableTnDmode) ? quickLinks : quickLinksInQuickMode).reverse()}
            renderItem={renderItem}
            keyExtractor={(item, index) => String(index)}
            horizontal
            showsHorizontalScrollIndicator={false}
          />
        )
      }
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 65,
    alignItems: 'center',
    backgroundColor: colors.primary,
    paddingLeft: 30,
    paddingRight: 22,
  },
  main: {
    flex: 1,
    alignItems: 'center',
    minWidth: 180,
  },
  text: {
    fontSize: 23,
    fontWeight: 'bold',
    color: colors.white,
  },
  icon: {
    height: 30,
    width: 30,
  },
  button: {
    width: 'auto',
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
})
