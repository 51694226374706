import { FlatList } from 'react-native-gesture-handler'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import Column from '@/components/Column'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

export default function StatTypeList () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedStatType = useSelector(state => state.statistics.selectedStatType)

  const statTypeOptions = [
    { type: 'summary', text: t('app.page.stats.list.summary') },
    { type: 'category', text: t('app.page.stats.list.category') },
    { type: 'option', text: t('app.page.stats.list.option') },
    { type: 'cancel', text: t('app.page.stats.list.cancel') },
    { type: 'dimorder', text: t('app.page.stats.list.dimorder') },
    { type: 'cash', text: t('app.page.stats.list.cashRequest') },
  ]

  const search = (statType) => {
    dispatch(actions.statistics.selectStatType(statType))
  }

  const renderItem = ({ item }) => {
    const textStyle = { opacity: item.type === selectedStatType ? 1 : 0.4 }

    return (
      <TouchableOpacity onPress={() => search(item.type)}>
        <View style={styles.optionContainer}>
          <Text style={[styles.text, textStyle]}>{item.text}</Text>
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <Column style={styles.container}>
      <FlatList
        data={statTypeOptions}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.listContainer}
        keyExtractor={(item, index) => item.type}
        style={styles.list}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 100,
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    paddingVertical: 30,
    ...shadows.default,
    zIndex: 2,
    elevation: 2,
  },
  list: {
    width: '100%',
  },
  listContainer: {
    alignItems: 'center',
  },
  optionContainer: {
    width: '100%',
    justifyContent: 'center',
    paddingVertical: 30,
    paddingHorizontal: 12,
    textAlign: 'center',
  },
  text: {
    fontSize: 20,
    fontWeight: '500',
    color: colors.white,
  },
})
