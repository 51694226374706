import { LinearProgress } from 'react-native-elements'
import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { dimorderApi } from '@root/src/libs/api/dimorder'
import { useIsOnline } from '@root/src/hooks/app'
import Button from '../buttons/Button'
import CenterModal from '../CenterModal'
import Row from '../Row'
import colors from '@root/src/theme/colors'

function ConfirmMemberInfoDialog (props) {
  const { orderId, phone } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isConfirmMemberInfoDialogOpen = useSelector(state => state.app.dialog.confirmMemberInfoDialog)
  const { isOnline } = useIsOnline()
  const [loading, setLoading] = React.useState(true)
  const [customer, setCustomer] = useState(null)

  const onSubmit = () => {
    dispatch(actions.orderHistory.updateOrderOwner(orderId, { phone: customer.phone, name: customer?.name ?? 'Guest' }))
    dispatch(actions.app.closeDialog(['confirmMemberInfoDialog']))
  }

  async function checkMemberInfo () {
    const response = await dimorderApi.merchant.getGroupUserList({
      phone: phone,
    })

    //  if no resposne of member info, store phone number
    setCustomer(response?.data[0] ?? { phone: phone })
    setLoading(false)
  }

  useEffect(() => {
    if (isConfirmMemberInfoDialogOpen) {
      if (isOnline) {
        checkMemberInfo()
      } else {
        setCustomer({ phone: phone })
        setLoading(false)
      }
    } else {
      // restore initial state when dialog is closed
      setCustomer(null)
      setLoading(true)
    }
  }, [isConfirmMemberInfoDialogOpen])

  const title = !loading
    ? t('app.component.confirmMemberInfoDialog.confirmed.title')
    : t('app.component.confirmMemberInfoDialog.loading.title')

  return (
    <CenterModal
      enablePressOutsideClose
      visible={isConfirmMemberInfoDialogOpen}
      contentContainerStyle={styles.container}
      title={title}
      onClose={() => dispatch(actions.app.closeDialog(['confirmMemberInfoDialog']))}
    >
      <View style={styles.content}>
        {!loading ? (
          <>
            <Text style={styles.text}>{t('app.component.confirmMemberInfoDialog.confirmed.message', { customer: customer?.name === 'Guest' ? customer?.phone : customer?.name || customer?.phone })}</Text>
            <Row>
              <Button
                textColor={colors.textTertiary}
                backgroundColor={colors.light}
                style={styles.button}
                title={t('app.common.back')}
                onPress={() => { dispatch(actions.app.closeDialog(['confirmMemberInfoDialog'])) }}
              />
              <Button
                title={t('app.common.apply')}
                onPress={onSubmit}
              />
            </Row>
          </>
        )
          : (
            <>
              <Text style={styles.text}>{t('app.component.confirmMemberInfoDialog.loading.message')}</Text>
              <LinearProgress
                style={styles.progress}
                color={colors.primary}
                variant='indeterminate'
              />
            </>
          )}
      </View>
    </CenterModal>
  )
}

export default ConfirmMemberInfoDialog

const styles = StyleSheet.create({
  container: {
    width: 450,
    height: 140,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  text: {
    margin: 20,
    fontSize: 16,
  },
  progress: {
    width: '90%',
    margin: 4,
  },
})
