import { KeyboardAvoidingView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useImmer } from 'use-immer'
import { v4 as uuid } from 'uuid'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import SettingListSwitchRow from '@/components/Setting/SettingListRow/SwitchRow'
import colors from '@/theme/colors'

import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionGroup } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 * @param {{optionGroup: IMenuOptionGroup, onClose: () => void}} props
 */
export default function OptionInfo (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { optionGroup, onClose, type, onDelete, onSave, isEditing, handleUpdate } = props
  const [keyboardAvoidingViewEnabled, setKeyboardAvoidingViewEnabled] = React.useState(false)
  const [offset, setOffset] = React.useState(0)

  const [editingSpecialOption, updateEditingSpecialOption] = useImmer(optionGroup)
  if (!optionGroup) return null
  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.menuPanel.options.alert.delete.title'),
      message: `${t('app.page.setting.menuPanel.options.alert.delete.msg')}${editingSpecialOption?.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            onDelete(editingSpecialOption.id)
            dispatch(actions.app.updateIsSavedSetting(false))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }
  const handleSave = () => {
    onSave(editingSpecialOption)
    dispatch(actions.app.updateIsSavedSetting(false))
    onClose()
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      contentContainerStyle={styles.container}
      behavior='position'
      enabled={keyboardAvoidingViewEnabled}
      keyboardVerticalOffset={offset}
    >
      <Header>
        <HeaderText text={isEditing ? t(`app.page.setting.menuPanel.specialOptions.${type}.setting`) : t(`app.page.setting.menuPanel.specialOptions.${type}.add`)} />
      </Header>

      <SettingList>
        <SettingListItem divider>
          <SettingListItemText text={t(`app.page.setting.menuPanel.specialOptions.${type}.name`)} />
          <SettingListItemRightAction>
            <SettingListInput
              value={editingSpecialOption?.name || ''}
              onChangeText={(text) => {
                if (isEditing) {
                  dispatch(actions.app.updateIsSavedSetting(false))

                  handleUpdate(editingSpecialOption.id, 'name', replaceSpace(text))
                }
                updateEditingSpecialOption(draft => {
                  // iOS 字串最後一個空格不會顯示，將空格取代為 \u00a0 讓他顯示出空格
                  draft.name = replaceSpace(text)
                })
              }}
              onBlur={() => {
                if (isEditing) {
                  dispatch(actions.app.updateIsSavedSetting(false))

                  handleUpdate(editingSpecialOption.id, 'name', replaceSaveSpace(editingSpecialOption?.name, true))
                }
                updateEditingSpecialOption(draft => {
                  // 結束編輯時，將 \u00a0 換回普通的 \u0020 空格
                  draft.name = replaceSaveSpace(editingSpecialOption?.name, true)
                })
              }}
              onFocus={() => setKeyboardAvoidingViewEnabled(false)}
            />
          </SettingListItemRightAction>
        </SettingListItem>
        <SettingListSwitchRow
          divider
          label={t(`app.page.setting.menuPanel.specialOptions.${type}.enabled`)}
          value={editingSpecialOption?.enabled}
          onChangeValue={(enabled) => {
            if (isEditing) {
              dispatch(actions.app.updateIsSavedSetting(false))
              handleUpdate(editingSpecialOption?.id, 'enabled', enabled)
            }
            updateEditingSpecialOption(draft => {
              draft.enabled = enabled
            })
          }}
        />

        <SettingListItem>
          <SettingListItemRightAction>
            {isEditing && (
              <TouchableOpacity onPress={handleDelete}>
                <HeaderText style={{ color: colors.red }} text={t(`app.page.setting.menuPanel.specialOptions.${type}.deleteButton`)} />
              </TouchableOpacity>
            )}
            {!isEditing && (
              <TouchableOpacity onPress={handleSave} disabled={!editingSpecialOption.name.trim()}>
                <HeaderText style={!editingSpecialOption.name.trim() && { color: colors.gray }} text={t(`app.page.setting.menuPanel.specialOptions.${type}.addButton`)} />
              </TouchableOpacity>
            )}
          </SettingListItemRightAction>
        </SettingListItem>

      </SettingList>

    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    // margin: 8,
  },
  addIcon: {
    width: 25,
    height: 25,
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
  },
  flatList: {
    flex: 1,
    height: '100%',
    marginBottom: 10,
  },
})
