import { FlatList, Image, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useSelector } from '@/redux'
import paymentConfigs from '@/configs/paymentConfigs'

import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

import ExtraPaymentInfo from './ExtraPaymentInfo'

const arrowRightIcon = require('@icons/arrow-right.png')

/**
 *
 * @param {*} props
 * @returns
 */
export default function ExtraPaymentPanel (props) {
  const { t } = useTranslation()
  const [selectedPayment, setSelectedPayment] = React.useState(null)
  const merchantMethods = useSelector(state => state.merchant.data.payments)
  const extraPayments = useSelector(state => state.merchant.data.extraPayments)
  const paymentMethods = paymentConfigs.flatPaymentMethods.map(buildInMethod => {
    return {
      ...buildInMethod,
      active: false,
      custom: false,
      extra: false,
    }
  })

  merchantMethods.forEach(merchantMethod => {
    const paymentMethod = paymentMethods.find(paymentMethod => paymentMethod.key === merchantMethod.key)
    if (paymentMethod) {
      paymentMethod.active = true
      paymentMethod.serviceCharge = merchantMethod.serviceCharge
    } else {
      paymentMethods.push({
        ...paymentConfigs.otherMethod,
        key: merchantMethod.key,
        name: merchantMethod.name,
        active: true,
        custom: true,
        serviceCharge: merchantMethod.serviceCharge,
      })
    }
  })
  return (
    <View style={styles.container}>
      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.checkout.extra')} />
        </Header>
        <View style={styles.content}>
          <SettingList>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={extraPayments}
              ListEmptyComponent={(
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.checkout.empty')}</Text>
                </View>
              )}
              keyExtractor={(item) => item.key}
              renderItem={({ item: extraPayment, index }) => {
                return (
                  <SettingListItem
                    key={extraPayment.key}
                    divider={index !== extraPayments.length - 1}
                    onPress={() => {
                      setSelectedPayment({ ...extraPayment, extra: true })
                    }}
                  >
                    <SettingListItemText text={extraPayment.name} />
                    <SettingListItemRightAction>

                      <Image
                        source={arrowRightIcon}
                        style={styles.icon}
                      />
                    </SettingListItemRightAction>
                  </SettingListItem>
                )
              }}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.checkout.add') + t('app.page.setting.checkout.extra')}
              onPress={() => {
                setSelectedPayment({ name: '', extra: true, amount: 0 })
              }}
            />
          </SettingList>
        </View>
      </View>
      {/** Right */}
      <View style={styles.contentContainer}>
        <ExtraPaymentInfo
          key={selectedPayment?.key + selectedPayment?.name}
          paymentMethod={selectedPayment}
          onClose={() => setSelectedPayment(null)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  icon: {
    width: 16,
    height: 16,
  },

})
