
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import orderAppLib from 'dimorder-orderapp-lib'

import { useSelectedOrder } from '@/hooks/orderHistory'
import { useSelector } from '@/redux'
import Column from '@/components/Column'
import Row from '@/components/Row'
import TableHeader from '@/components/OrderTable/TableHeader'
import TableRow from '@/components/OrderTable/TableRow'
import colors from '@/theme/colors'
import getFormatBatchItemStatusWithSet from '@/libs/getFormatBatchItemStatusWithSet'
import i18n from '@/i18n'
import shadows from '@/theme/shadows'

// item 使用的欄位名稱
// const fields = ['serial', 'name', 'price', 'quantity', 'totalDiscount', 'surcharge', 'total']
const fields = ['serial', 'name', 'price', 'quantity', 'total']

// 欄寬
const widths = [40, 124, 50 + 40 + 45, 55, 54]
// 對齊
const justifyContents = [
  'center',
  'flex-start',
  'flex-end',
  'center',
  'flex-end',
  'flex-end',
  'flex-end',
]

export default function OrderDetail () {
  const { t } = useTranslation()
  // 表頭
  // const headers = ['序號', '產品名稱', '價錢', '數量', '折扣', '服務費', '小計']
  const headers = [t('app.common.list.serial'), t('app.constant.order.name'), t('app.constant.order.price'), t('app.constant.order.quantity'), t('app.constant.order.subtotal')]

  const selectedOrder = useSelectedOrder()
  const rounding = useSelector(state => state.merchant.data?.rounding)
  let usedSerial = 0
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)
  const allSets = useSelector(state => state.menu.sets)
  const formatedBatches = selectedOrder?.batches.map((batch) => {
    return {
      ...batch,
      items: batch.items.map((item, index) => {
        let quantity = item.quantity
        if (item.divisor) {
          // 除完 divisor 為整數時顯示整數
          quantity = item.quantity % item.divisor === 0 ? item.quantity / item.divisor : `${item.quantity}/${item.divisor}`
        }
        const formatedItem = {
          ...item,
          name: _.get((
            item.isSet
              ? allSets[item.menuId]
              : allMenus[item.menuId]
          )?.localeNames, lang) ?? item.name,
          serial: usedSerial + 1,
          quantity: quantity,
          status: getFormatBatchItemStatusWithSet(item),
        }
        usedSerial++
        return formatedItem
      },
      ),
    }
  })

  const renderItem = ({ item }) => {
    return (
      <TableRow
        serial={item.serial}
        item={item}
        fields={fields}
        widths={widths}
        justifyContents={justifyContents}
        commentText={item.status}
      />
    )
  }

  const renderBatch = ({ item: batch }) => (
    <Column>
      <Row style={styles.batchHeader}>
        <Text style={styles.batchText}>
          ＃{batch.index + 1}  {moment(batch.createdAt).format('HH:mm')}
        </Text>
      </Row>
      <FlatList
        data={batch.items}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        showsVerticalScrollIndicator={false}
      />
    </Column>
  )

  const renderModifer = ({ item: modifier }) => (
    <Row style={styles.batchHeader}>
      <Text style={[styles.modifierText, { flex: 1, paddingLeft: 35 }]}>
        {modifier.type === 'DISCOUNT' ? t('app.page.checkout.orderSummary.discount') : t('app.constant.order.surcharge')}
        {modifier.name ?? modifier.name}
        {modifier.percent ? ` ${modifier.percent}%` : ` $${modifier.amount}`}
      </Text>
      <Text style={[styles.modifierText, { paddingHorizontal: 6 }]}>
        {/* {orderAppLib.libs.getDisplayRoundNumber(modifier?.calculatedAmount, rounding)} */}
        {String(orderAppLib.libs.adjustNumber(modifier?.calculatedAmount, rounding?.digits))}
      </Text>
    </Row>
  )
  return (
    <View style={styles.container}>
      <TableHeader
        headers={headers}
        widths={widths}
        justifyContents={justifyContents}
        style={styles.header}
      />
      <FlatList
        data={formatedBatches}
        renderItem={renderBatch}
        keyExtractor={item => item.id}
        showsVerticalScrollIndicator={false}
        style={{ flexGrow: 0 }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: colors.light,
    ...shadows.default,
  },
  header: {
    backgroundColor: colors.lightPrimary,
  },
  batchHeader: {
    height: 30,
    alignItems: 'center',
    backgroundColor: colors.lightGray3,
    paddingLeft: 15,
  },
  batchText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
  modifierText: {
    fontSize: 13,
    fontWeight: '500',
    color: colors.textSecondary,
  },
})
