import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import { useTranslation } from 'react-i18next'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListInputAdornment from '@/components/Setting/SettingListInputAdornment'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'
import _ from 'lodash'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'

const dragIcon = require('@icons/drag.png')
const deleteIcon = require('@icons/setting/close.png')

/**
 *
 * @param {{
 * option: IMenuOptionItem,
 * dragging: boolean,
 * onChangeOption: () => {},
 * onFocusOption: () => {},
 * onDrag: () => {},
 * onDelete: () => {},
 * }} props
 */
export default function OptionItem (props) {
  const {
    locale,
    option,
    isExpand,
    dragging,
    onChangeOption,
    onFocusOption = () => {},
    onDrag,
    onDelete,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [expand, setExpand] = React.useState(false)

  React.useEffect(() => {
    setExpand(isExpand)
  }, [isExpand])

  const handleDelete = () => {
    if (!option.name) {
      // 沒有名稱直接刪除
      onDelete()
      return
    }

    // 有名稱確認後刪除
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.menuPanel.options.option.alert.delete.title'),
      message: `${t('app.page.setting.menuPanel.options.option.alert.delete.msg')} ${option.name}？`,
      buttons: [
        {
          children: t('app.common.delete'),
          onPress: onDelete,
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  return (
    <SettingList style={[styles.optionItem, dragging && shadows.slight]}>
      <SettingListItem divider>
        <TouchableOpacity onLongPress={onDrag}>
          <Image
            source={dragIcon}
            style={styles.dragIcon}
          />
        </TouchableOpacity>
        <SettingListItemText text={t('app.page.setting.menuPanel.options.option.name')} />
        <SettingListItemRightAction>
          <SettingListInput
            autoFocus={option.name === ''}
            value={(_.get(option?.localeNames, locale) ?? option.name) || ''}
            onChangeText={(text) => {
              onChangeOption('name', replaceSpace(text))
            }}
            onBlur={(event) => {
              onChangeOption('name', replaceSaveSpace(event?.nativeEvent?.text))
            }}
            onFocus={() => onFocusOption('name')}
          />
        </SettingListItemRightAction>
      </SettingListItem>

      {
        expand && (
          <>
            <SettingListItem divider>
              <View style={styles.dragIconPlaceholder} />
              <SettingListItemText text={t('app.page.setting.menuPanel.options.option.price')} />
              <SettingListItemRightAction>
                <SettingListInputAdornment text='$' />
                <SettingListInput
                  keyboardType='decimal-pad'
                  style={styles.priceInput}
                  value={String(option.price) ?? '0'}
                  onChangeText={(text) => {
                    if (!Number.isNaN(Number(text)) || text === '-') {
                      onChangeOption('price', text)
                    }
                  }}
                  onBlur={(event) => {
                    onChangeOption('price', Number(event.nativeEvent.text))
                  }}
                  onFocus={() => onFocusOption('price')}
                />
              </SettingListItemRightAction>
            </SettingListItem>

            <SettingListItem divider>
              <View style={styles.dragIconPlaceholder} />
              <SettingListItemText text={t('app.page.setting.menuPanel.options.option.max')} />
              <SettingListItemRightAction>
                <SettingListInput
                  keyboardType='decimal-pad'
                  style={styles.priceInput}
                  value={String(option.max) ?? '0'}
                  onChangeText={(text) => {
                    if (/^\d+$/.test(text) || text === '') {
                      onChangeOption('max', text)
                    }
                  }}
                  onFocus={() => onFocusOption('max')}
                />
              </SettingListItemRightAction>
            </SettingListItem>

            <SettingListItem divider>
              <View style={styles.dragIconPlaceholder} />
              <SettingListItemText text={t('app.page.setting.menuPanel.options.option.hide')} />
              <SettingListItemRightAction>
                <ToggleSwitch
                  value={option?.hidden}
                  onChangeValue={() => {
                    onChangeOption('hidden', !option?.hidden)
                  }}
                  size={23}
                />
              </SettingListItemRightAction>
            </SettingListItem>
          </>
        )
      }

      <SettingListItem>
        <View style={styles.dragIconPlaceholder} />
        <TouchableOpacity onPress={handleDelete}>
          <HeaderText text={t('app.page.setting.menuPanel.options.option.alert.delete.title')} />
        </TouchableOpacity>
        <SettingListItemRightAction>
          <TouchableOpacity onPress={() => setExpand(!expand)}>
            {
              expand
                ? <HeaderText text={t('app.page.setting.menuPanel.options.option.collapse')} />
                : <HeaderText text={t('app.page.setting.menuPanel.options.option.expand')} />
            }
          </TouchableOpacity>
        </SettingListItemRightAction>
      </SettingListItem>
    </SettingList>
  )
}

const styles = StyleSheet.create({
  optionItem: {
    marginBottom: 10,
  },
  deleteContainer: {
    position: 'absolute',
    top: 0.5,
    bottom: 1,
    left: 0,
    right: 0,
    paddingRight: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.secondary,
    marginBottom: 10,
    borderRadius: 5,
  },
  priceInput: {
    width: 50,
  },
  dragIcon: {
    width: 16,
    height: 16,
    marginLeft: 8,
    marginRight: 16,
    marginVertical: 'auto',
  },
  dragIconPlaceholder: {
    width: 40,
    height: 16,
  },
  deleteIcon: {
    width: 24,
    height: 24,
    marginLeft: 4,
    marginRight: 12,
    marginVertical: 'auto',
  },
})
