import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { getStatusConfig } from '@/libs/orderStatus'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import moment from 'moment'
import shadows from '@/theme/shadows'
// import { TouchableOpacity } from 'react-native-gesture-handler'
import { currencyWithCommas } from '@root/src/libs/numberWithCommas'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import IconLabel from '../BookingList/BookingItem/IconLabel'
import orderAppLib from 'dimorder-orderapp-lib'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef OrderItemProps
 * @property {number} index
 * @property {IAppOrder} order
 * @property {(order: IAppOrder) => void} onSelectOrder
 * @property {boolean} [expanded]
 * @property {boolean} [selected]
 * @property {boolean} [disabled]
 * @property {boolean} [relativePosition] for BookingList & SearchResult component
 */

/**
 *
 * @param {OrderItemProps} props
 * @returns
 */
export default function OrderItem (props) {
  const {
    index,
    order,
    onSelectOrder,
    expanded = false,
    selected = false,
    disabled = false,
    relativePosition = false,
  } = props

  const { enableLargerTable, unstackSubTables, enableCheckoutReceipt, enableFloorPlan } = useSelector(state => state.merchant.data?.setting) ?? {}
  const { serial, customers, createdAt, status, roundedTotal, printedCheckoutReceipt } = order

  if (status === 'paid') return null

  const containerStyle = {
    zIndex: 1,
    position: 'absolute',
    top: expanded ? enableLargerTable ? index * 105 + 5 : index * 55 + 5 : index * 5 + 5, // Stack the orders
    right: expanded ? 0 + 5 : index * 4 + 5, // Stack the orders
  }

  const idStyle = {
    backgroundColor: getStatusConfig(order)?.color || colors.gray,
  }

  const overlayStyle = {
    backgroundColor: colors.gray,
    borderRadius: 5,
    opacity: selected ? 0.6 : 0,
  }

  const orderListStyle = {
    container: styles.container,
    id: styles.id,
    idText: styles.idText,
  }

  if (enableLargerTable) {
    orderListStyle.container = styles.containerLarge
    orderListStyle.id = styles.idLarge
    orderListStyle.idText = styles.idTextLarge
  }

  if (unstackSubTables && !enableFloorPlan) {
    containerStyle.top = 0
    containerStyle.right = 0
    orderListStyle.container = enableLargerTable ? styles.unstackContainerLarge : styles.unstackContainer
    orderListStyle.id = styles.unstackIdLarge
    orderListStyle.idText = styles.idTextLarge
  }

  if (enableCheckoutReceipt && printedCheckoutReceipt) {
    orderListStyle.container = { ...orderListStyle.container, backgroundColor: colors.yellow }
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={() => onSelectOrder(order)}
      style={[orderListStyle.container, !relativePosition && containerStyle]}
    >

      {/* info */}
      <Row style={styles.row}>
        <IconLabel
          text={customers}
          style={styles.count}
          iconSource={require('@icons/table/adult.png')}
        />
        <View style={[orderListStyle.id, idStyle]}>
          <Text style={orderListStyle.idText}>{serial.slice(-3)}</Text>
        </View>
      </Row>

      <Subtotal roundedTotal={roundedTotal} />
      <CreatedAt createdAt={createdAt} />
      <Minutes createdAt={createdAt} />

      {/* overlay */}
      <View style={[StyleSheet.absoluteFill, overlayStyle]} />

    </TouchableOpacity>
  )
}

/**
 *
 * @param {{ roundedTotal: number }} props
 * @returns
 */
function Subtotal ({ roundedTotal }) {
  const { enableLargerTable } = useSelector(state => state.merchant.data?.setting) ?? {}
  const rounding = useSelector(state => state.merchant.data.rounding)
  const size = enableLargerTable ? 'lg' : 'md'
  return size === 'lg'
    ? (
      <View style={styles.bottomLarge}>
        <IconLabel
          text={currencyWithCommas(orderAppLib.libs.getDisplayRoundNumber(roundedTotal, rounding))}
          style={styles.count}
          iconSource={require('@icons/table/money-bag-blue.png')}
        />
      </View>
    )
    : null
}

/**
 *
 * @param {{ createdAt: string }} props
 * @returns
 */
function CreatedAt ({ createdAt }) {
  const { enableLargerTable, unstackSubTables, enableFloorPlan } = useSelector(state => state.merchant.data?.setting) ?? {}
  const size = enableLargerTable || (unstackSubTables && !enableFloorPlan) ? 'lg' : 'md'
  return size === 'lg'
    ? (
      <View style={styles.bottomLarge}>
        <IconLabel
          text={moment(createdAt).format('HH:mm')}
          style={styles.count}
          iconSource={require('@icons/table/chair-blue.png')}
        />
      </View>
    )
    : (
      <View style={styles.bottom}>
        <Text style={styles.timeText}>
          {moment(createdAt).format('HH:mm')}
        </Text>
      </View>
    )
}

/**
 *
 * @param {{ createdAt: string }} props
 * @returns
 */
function Minutes ({ createdAt }) {
  const { t } = useTranslation()
  const { enableLargerTable } = useSelector(state => state.merchant.data?.setting) ?? {}
  if (!enableLargerTable) return null
  const size = enableLargerTable ? 'lg' : 'md'
  return size === 'lg'
    ? (
      <View style={styles.bottomLarge}>
        <IconLabel
          text={`${moment().diff(createdAt, 'minute')} ${t('app.page.table.tableArea.orderLegends.min')}`}
          style={styles.count}
          iconSource={require('@icons/table/clock-blue.png')}
        />
      </View>
    )
    : null
}

const styles = StyleSheet.create({
  container: {
    height: 50,
    width: 60,
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 5,
    ...shadows.top,
  },
  containerLarge: {
    height: 100,
    width: 100,
    backgroundColor: colors.white,
    borderRadius: 5,
    ...shadows.top,
  },
  unstackContainer: {
    height: 64,
    width: 100,
    backgroundColor: colors.white,
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingLeft: 5,
    ...shadows.top,
  },
  unstackContainerLarge: {
    height: 114,
    width: 150,
    backgroundColor: colors.white,
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingLeft: 10,
    ...shadows.top,
  },
  row: {
    width: '100%',
    alignItems: 'center',
  },
  count: {
    flex: 1,
    paddingLeft: 3,
  },
  bottom: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomLarge: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  id: {
    height: 26,
    width: 26,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  idLarge: {
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  unstackIdLarge: {
    height: 35,
    width: 35,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 5,
  },
  idText: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.white,
  },
  timeText: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.textPrimary,
  },
  idTextLarge: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.white,
  },
})
