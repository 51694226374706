import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native'
import { produce } from 'immer'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'

import BackNavigator from '@/components/Setting/BackNavigator'
import BatchLocaleSelectButton from '@/components/buttons/BatchLocaleSelectButton'
import Header from '@/components/Setting/Header'
import HeaderRightAction from '@/components/Setting/HeaderRightAction'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'

import OptionGroupEditor from './OptionGroupEditor'
import OptionGroupItem from './OptionGroupItem'
import OptionGroupPresetConfig from './OptionGroupPresetConfig'

/* eslint-disable no-unused-vars */
import { IAppMenuItem } from 'dimorder-orderapp-lib/dist/types/AppMenu'
import { IMenuOptionGroup } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

/**
 * @typedef EditMenuOptionsProps
 * @property {IAppMenuItem} editingMenu
 * @property {() => void} updateEditingMenu
 * @property {() => void} onDragEnd
 * @property {() => void} onClose
 */

/**
 *
 * @param {EditMenuOptionsProps} props
 * @returns
 */
export default function EditMenuOptions (props) {
  const { editingMenu, updateEditingMenu, onDragEnd, onClose } = props
  const { t } = useTranslation()
  const [selectedOptionGroup, setSelectedOptionGroup] = React.useState()
  const [locale, setLocale] = React.useState(editingMenu.locale)

  /** @type {IMenuOptionGroup} */
  const defaultOptionGroup = {
    id: null,
    locale,
    name: '',
    options: [],
    multiple: false,
    min: 0,
    max: 0,
    localeNames: {
      [locale]: '',
    },
  }

  const scrollViewRef = React.useRef()
  const optionGroupPresets = useSelector(state => state.menu.optionGroupPresets)

  // menu 使用中的 option (包含 options 和 optionGroupPresets)
  const optionGroups = React.useMemo(() => {
    const optionGroups = []
    editingMenu.options?.forEach((option, index) => {
      optionGroups.push(produce(option, draft => {
        draft.weight = draft.weight ?? 0
        draft.isPreset = false
      }))
    })
    editingMenu.optionGroupPresets?.forEach(option => {
      optionGroups.push(produce(option, draft => {
        draft.weight = draft.weight ?? 0
        draft.isPreset = true
      }))
    })
    return _.orderBy(optionGroups, 'weight')
  }, [editingMenu.options, editingMenu.optionGroupPresets])

  const renderOption = ({ item, index, drag, isActive }) => {
    const selected = item.id === selectedOptionGroup?.id

    return (
      <OptionGroupItem
        optionGroup={item}
        locale={locale}
        divider={index !== optionGroups.length - 1}
        selected={selected}
        showPresetLabel
        draggable
        scrollContainerRef={scrollViewRef}
        dragging={isActive}
        onDrag={drag}
        onPress={() => setSelectedOptionGroup(item)}
        onDelete={() => {
          // remove from optionGroupPresets or options
          updateEditingMenu(draft => {
            if (item.isPreset) {
              const index = draft.optionGroupPresets.findIndex(preset => preset.id === item.id)
              if (index < 0) return

              draft.optionGroupPresets.splice(index, 1)
            } else {
              const index = draft.options.findIndex(preset => preset.id === item.id)
              if (index < 0) return

              draft.options.splice(index, 1)
            }

            // 如果正在編輯這個 option group，取消選取
            if (selected) {
              setSelectedOptionGroup(null)
            }
          })
        }}
      />
    )
  }

  const renderOptionGroupPreset = ({ item, index }) => {
    const isUsed = editingMenu.optionGroupPresets?.find(preset => preset.id === item.id)

    return (
      <OptionGroupItem
        optionGroup={item}
        locale={locale}
        divider={index !== optionGroupPresets.length - 1}
        checked={isUsed}
        onCheckedValueChange={() => {
          if (isUsed) {
            // 如果正在編輯這個 option group，取消選取
            if (item.id === selectedOptionGroup?.id) {
              setSelectedOptionGroup(null)
            }

            // remove from menu.optionGroupPresets
            updateEditingMenu(draft => {
              const index = draft.optionGroupPresets.findIndex(preset => preset.id === item.id)
              if (index < 0) return

              draft.optionGroupPresets.splice(index, 1)
            })
          } else {
            // add to menu.optionGroupPresets
            updateEditingMenu(draft => {
              if (!draft.optionGroupPresets) {
                draft.optionGroupPresets = []
              }
              draft.optionGroupPresets.push(item)
            })

            // 將加入的 option group preset 選為編輯中
            setSelectedOptionGroup({
              ...item,
              isPreset: true,
            })
          }
        }}
      />
    )
  }

  const renderEditor = () => {
    if (!selectedOptionGroup) return null

    if (selectedOptionGroup.isPreset) {
      return (
        <OptionGroupPresetConfig
          key={selectedOptionGroup?.id}
          locale={locale}
          optionGroup={selectedOptionGroup}
          onClose={() => setSelectedOptionGroup(null)}
          onSubmit={(submittedOptionGroup) => {
            updateEditingMenu(draft => {
              const index = draft.optionGroupPresets?.findIndex(optionGroup => optionGroup.id === submittedOptionGroup.id)
              if (index < 0) return
              draft.optionGroupPresets[index] = submittedOptionGroup
            })
          }}
          onRemove={() => {
            // 取消選取
            setSelectedOptionGroup(null)

            // remove from menu.optionGroupPresets
            updateEditingMenu(draft => {
              const index = draft.optionGroupPresets.findIndex(preset => preset.id === selectedOptionGroup.id)
              if (index < 0) return

              draft.optionGroupPresets.splice(index, 1)
            })
          }}
        />
      )
    } else {
      return (
        <OptionGroupEditor
          key={selectedOptionGroup?.id}
          menuId={editingMenu?.id}
          locale={locale}
          optionGroup={selectedOptionGroup}
          onClose={() => setSelectedOptionGroup(null)}
          onSubmit={(submittedOptionGroup) => {
            updateEditingMenu(draft => {
              if (!submittedOptionGroup.id) {
                // 新增選項
                draft.options.push({
                  ...submittedOptionGroup,
                  id: uuid(),
                  // 加上最大的 weight + 1
                  weight: (_.last(optionGroups)?.weight ?? 0) + 1,
                })
              } else {
                const index = draft.options.findIndex(optionGroup => optionGroup.id === submittedOptionGroup.id)
                if (index < 0) return

                draft.options[index] = submittedOptionGroup
              }
            })
          }}
          onDelete={() => {
            // 取消選取
            setSelectedOptionGroup(null)

            // remove from menu.options
            updateEditingMenu(draft => {
              const index = draft.options.findIndex(optionGroup => optionGroup.id === selectedOptionGroup.id)
              if (index < 0) return

              draft.options.splice(index, 1)
            })
          }}
        />
      )
    }
  }

  return (
    <View style={styles.container}>
      <BackNavigator
        text={t('app.page.setting.menuPanel.options.setting')}
        onPress={onClose}
      />

      <View style={styles.contentContainer}>
        <View style={styles.contentContainer}>

          {/** Left */}
          <View style={styles.content}>
            <ScrollView
              ref={scrollViewRef}
              showsVerticalScrollIndicator={false}
            >

              <Header>
                <HeaderText text={t('app.page.setting.menuPanel.options.optionGroup.group')} />
                <HeaderRightAction>
                  <BatchLocaleSelectButton
                    selectedLocale={locale}
                    onSelect={locale => setLocale(locale)}
                  />
                </HeaderRightAction>
              </Header>
              <SettingList>
                {optionGroups.length === 0 && (
                  <View style={styles.emptyView}>
                    <Text>{t('app.page.setting.menuPanel.options.optionGroup.empty')}</Text>
                  </View>
                )}
                <DraggableFlatList
                  showsVerticalScrollIndicator={false}
                  data={optionGroups}
                  keyExtractor={(item, index) => String(index)}
                  renderItem={renderOption}
                  onDragEnd={onDragEnd}
                />
                <SettingListFooterAddButton
                  text={t('app.page.setting.menuPanel.options.optionGroup.add')}
                  onPress={() => {
                    // 將新的 optionGroup 選為編輯中
                    setSelectedOptionGroup({
                      ...defaultOptionGroup,
                    })
                  }}
                />
              </SettingList>

              <Header>
                <HeaderText text={t('app.page.setting.menuPanel.options.optionGroup.default')} />
              </Header>
              <SettingList>
                {optionGroupPresets.length === 0 && (
                  <View style={styles.emptyView}>
                    <Text>{t('app.page.setting.menuPanel.options.optionGroup.defaultEmpty')}</Text>
                  </View>
                )}
                <FlatList
                  showsVerticalScrollIndicator={false}
                  data={optionGroupPresets}
                  keyExtractor={item => item.id}
                  renderItem={renderOptionGroupPreset}
                  extraData={editingMenu.optionGroupPresets}
                />
              </SettingList>

            </ScrollView>
          </View>
        </View>

        {/** Right */}
        <View style={styles.content}>
          {renderEditor()}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    display: 'flex',
    margin: 10,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  presetAddButton: {
    marginLeft: 8,
  },
})
