import { FlatList, Image, ScrollView, StyleSheet, Text, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import React from 'react'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ShippingInfo from './ShippingInfo'

const arrowRightIcon = require('@icons/arrow-right.png')

export default function ShippingPanel () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedShipping, setSelectedShipping] = React.useState(null)
  const merchantMethods = useSelector(state => state.merchant.data.shippings)

  return (
    <View style={styles.container}>
      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.checkout.shipping')} />
        </Header>
        <View style={styles.content}>
          <SettingList>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={merchantMethods}
              ListEmptyComponent={(
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.checkout.empty')}</Text>
                </View>
              )}
              keyExtractor={(item) => item.key}
              renderItem={({ item: merchantMethod, index }) => {
                return (
                  <SettingListItem
                    key={merchantMethod.key}
                    divider={index !== merchantMethods.length - 1}
                    onPress={() => {
                      setSelectedShipping(merchantMethod)
                    }}
                  >
                    <SettingListItemText text={merchantMethod.name} />
                    <SettingListItemRightAction>
                      <Image
                        source={arrowRightIcon}
                        style={styles.icon}
                      />
                    </SettingListItemRightAction>
                  </SettingListItem>
                )
              }}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.checkout.add') + t('app.page.setting.checkout.shipping')}
              onPress={() => {
                setSelectedShipping({
                  name: '',
                  type: 'storeDelivery',
                  cost: 0,
                  minAmount: 0,
                  minDeliveryMinute: 40,
                  maxDeliveryMinute: 8640,
                  discounts: [{
                    minOrderAmount: 0,
                    amount: 0,
                  }],
                })
              }}
            />
          </SettingList>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <ShippingInfo
          key={selectedShipping?.key + selectedShipping?.name}
          shippingMethod={selectedShipping}
          onClose={() => setSelectedShipping(null)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  icon: {
    width: 16,
    height: 16,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
