import { Platform } from 'react-native'
import TcpSocket from 'react-native-tcp-socket'
import _ from 'lodash'
import queue from 'async/queue'

import { actions } from '@/redux'
import GPrinter from '@/libs/printing/printer/GPrinter'
import delay from '@/libs/delay'
import i18n from '@/i18n'
import logger from '@/libs/logger'
import printerService from '@/libs/printing/PrinterService'
import store from '@/redux/store'

const { dispatch } = store

class QueueTask {
  id = ''
  name = ''
  task = async () => {}

  constructor ({ id, name, task }) {
    this.id = id
    this.name = name
    this.task = task
  }
}

export default class PrinterClient {
  static STATUS = {
    CONNECTED: 'CONNECTED', // after success write and on close handler
    ERROR: 'ERROR', // whenever error occurs
    CLOSED: 'CLOSED', // only in init state
  }

  static ERROR = {
    COVER: 'cover_open',
    OTHERS: 'others',
    PAPER: 'paper_end',
  }

  static MAGIC_PRINTER = '0.0.0.0:9100'

  printHash = []
  MAX_LENGTH = 500
  id = null // ip:port
  status = PrinterClient.STATUS.CLOSED
  backupId = null
  printing = []
  uuid = ''
  q // print queue
  // taskStatus
  taskStatus={
    error: 0,
    success: 0,
  }

  client // connection client

  /**
   * @param {IPrinter} printer
   */
  constructor (printer) {
    this.id = printer.id
    this.name = printer.name

    this.finishPrinting = false
    this.printerReady = false

    this.q = queue((task, completed) => {
      const remaining = this.q.length()
      task.task()
        .then(async result => {
          this.taskStatus.success++
          await delay(1000)
          completed(null, { task, remaining, result })
        })
        .catch(async error => {
          this.taskStatus.error++
          await delay(1000)
          completed(error, { task, remaining })
        })
    }, 1)
    this.q.drain(() => { })
    this.q.empty(() => { })
    if (Platform.OS === 'web') {
      this._updateStatus({
        status: PrinterClient.STATUS.ERROR,
        error: 'Platform Web Not Support.',
      })
    }
    this._updateConfig(printerService.getPrinterSetting())
  }

  _updateStatus = (payload) => {
    const { status, error } = payload
    this.status = status
    this.error = error
    dispatch(actions.printer.updatePrinterStatus(this.id, { status, error }))
  }

  findRelatedErrors = (id, data, printer) => {
    const errorlog = []
    const mechanicalError = []
    // printer 有機會出現的所有 error
    for (const [keys, values] of Object.entries(printer.errorStatus)) {
      // 逐個看每一個 status bit
      for (const [key] of Object.entries(values)) {
        if (key & data[keys]) {
          // 找到對應的 error
          errorlog.push(values[key])
          // 找到的 error 不屬於 status bit 2 和 5 的話就是 mechanical error
          if (keys !== '2' && keys !== '5') mechanicalError.push(values[key])
        }
      }
    }
    // Data meaning please refer to TSP100IV / SP700 programming manual
    logger.log(`[startPrinter] ${id}: ${printer.TYPE} ASB Status`, { errorlog, data })
    // error 中有 mechanical error
    if (mechanicalError.length) return PrinterClient.ERROR.OTHERS
    // cover is open
    if (errorlog.includes('cover_open')) return PrinterClient.ERROR.COVER
    // paper end
    if (errorlog.includes('paper_end')) return PrinterClient.ERROR.PAPER
    return false
  }

  // 檢查printer return 的status，有error的話show alert
  // 暫時只有TSP00IV，SP700有用
  _checkError = (printer, id, data, reject) => {
    // 防止其他 printer 突然有 data return
    if (printer.TYPE !== 'TSP100IV' && printer.TYPE !== 'SP700') return

    const { printers } = printerService.getPrinterSetting()
    const { name } = _.find(printers, (printer) => {
      return printer.id === id
    })
    const error = this.findRelatedErrors(id, data, printer)
    if (!error) return
    // show error alert
    dispatch(actions.app.showSimpleAlert(i18n.t('app.component.printer.title'), `${i18n.t('app.component.printer.printer')}: ${name}\n${i18n.t(`app.component.printer.error.${error}`)}`))
    // 如果是 mechanical error 需要重新開機的話，傳到 Backup printer 列印
    if (error === PrinterClient.ERROR.OTHERS) reject(data)
  }

  _startPrinter = (printDoc) => {
    return new Promise((resolve, reject) => {
      const [host, port] = this.id.split(':')
      try {
        const { commandBuffer, bufferStrings, uuid } = printDoc.print()
        if (Platform.OS === 'web') {
          return resolve()
        }
        if (this.id === PrinterClient.MAGIC_PRINTER) {
          this._updateStatus({
            status: PrinterClient.STATUS.CONNECTED,
          })
          return resolve()
        }
        const logging = {
          text: bufferStrings,
          printType: printDoc.TYPE,
          uuid,
          orderId: _.get(printDoc, 'order.id', ''),
          orderSerial: _.get(printDoc, 'order.serial', ''),
        }
        const printerStatusLog = {
          uuid,
          printerSetting: this.printerSetting, // FIXME: useless?
        }
        let timeouted = false
        const timerId = setTimeout(() => {
          timeouted = true
          const error = new Error('Connection Timeout')
          this.destroyClient()
          this._updateStatus({
            ...printerStatusLog,
            status: PrinterClient.STATUS.ERROR,
            error: error.message,
          })
          reject(error)
        }, printerService.MAX_PRINT_WAITING_TIME)
        this.client = TcpSocket.createConnection({
          host,
          port,
          timeout: printerService.MAX_PRINT_WAITING_TIME,
        }, async () => {
          // skip action if timeouted
          if (timeouted) { return }
          clearTimeout(timerId)
          // this.client.setNoDelay(true)
          logger.log(`[startPrinter] ${host} TcpSocket connected to print ${printDoc.TYPE}`)
          this.client.write(commandBuffer, 'UTF8', async (error) => {
            if (error) {
              this.destroyClient()
              this._updateStatus({
                ...printerStatusLog,
                status: PrinterClient.STATUS.ERROR,
                error,
              })
              throw error // ? throw 給誰？
            }
            logger.log(`[startPrinter] ${host} write success to print ${printDoc.TYPE}`, { logging })
            this._updateStatus({
              ...printerStatusLog,
              status: PrinterClient.STATUS.CONNECTED,
            })
            // 有機會需要等待 ASB status return，才確定 resolve / reject
            await delay(printerService.MAX_ASB_WAITING_TIME)
            this.destroyClient()
            resolve()
          })
        })
        // ASB return回來的status
        this.client.on('data', (data) => {
          // skip action if whole print process timeouted
          if (timeouted) { return }
          this.destroyClient()
          this._checkError(printDoc.printer, this.id, data, reject)
        })
        this.client.on('close', () => {
          // skip action if whole print process timeouted
          if (timeouted) { return }
          this.destroyClient()
          this._updateStatus({
            ...printerStatusLog,
            status: PrinterClient.STATUS.CONNECTED,
          })
        })
        this.client.on('timeout', async (error) => {
          // skip action if whole print process timeouted
          if (timeouted) { return }
          this.destroyClient()
          this._updateStatus({
            ...printerStatusLog,
            status: PrinterClient.STATUS.ERROR,
            error: 'Timeout',
          })
          throw error // ? throw 給誰？
        })
        this.client.on('error', async (error) => {
          // skip action if whole print process timeouted
          if (timeouted) { return }
          // FIXME: why wait after error?
          await delay(printerService.MAX_CONNECTION_WAITING_TIME)
          this.destroyClient()
          this._updateStatus({
            ...printerStatusLog,
            status: PrinterClient.STATUS.ERROR,
            error,
          })
          throw error // ? throw 給誰？
        })
      } catch (error) {
        this.destroyClient()
        reject(error)
      }
    })
  }

  addToQueue = async (printDoc) => {
    return new Promise((resolve, reject) => {
      this.q.push(
        new QueueTask({
          id: printDoc.printId,
          name: printDoc.TYPE,
          task: async () => {
            return this._startPrinter(printDoc)
          },
        }),
        (error, { task, remaining }) => {
          if (error) {
            reject(error)
          } else {
            console.log('queueTask callback')
            resolve()
          }
        },
      )
    })
  }

  /**
   * @param {IPrinterSetting} printerSetting
   */
  _updateConfig = (printerSetting) => {
    const printer = printerSetting.printers.find(
      (printer) => printer.id === this.id,
    )
    this.name = printer.name ?? printer.id
    this.priority = printerSetting?.priority[this.id]
    this.defaultSettings = printerSetting.defaultSettings
  }

  disconnect = () => {
    this._updateStatus({
      status: PrinterClient.STATUS.ERROR,
      error: '',
    })
    return this.destroyClient()
  }

  destroyClient = () => { // close connection
    if (Platform.OS === 'web') return
    if (this.client) {
      this.client.destroy()
    }
  }

  // TODO: looks useless
  statusTransmission = async () => {
    const printer = new GPrinter()

    printer.statusTransmission()
    const data = printer.getCommandBuffer()
    this.client.write(data)
  }
}
