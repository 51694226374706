import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import Column from '@/components/Column'
import React from 'react'
import RowTextInput from '@/components/InfoDialog/RowTextInput'

export default function DetailInput ({ order, onChangeState }) {
  const { name, customerNameSuffix, phone, remark, address, deliveryType } = order
  const { t } = useTranslation()
  return (
    <Column style={styles.container}>
      <RowTextInput
        placeholder={t('app.constant.order.customer-name')}
        value={name || ''}
        // required={deliveryType === 'takeaway'}
        onChangeText={(value) => onChangeState('name', value)}
        suffixValue={customerNameSuffix}
        setSuffix={(value) => onChangeState('customerNameSuffix', value)}
      />
      <RowTextInput
        placeholder={t('app.constant.order.customer-phone')}
        keyboardType='number-pad'
        value={phone || ''}
        // required={deliveryType === 'takeaway'}
        onChangeText={(value) => {
          if (/^\d+$/.test(value) && value.length <= 8) {
            onChangeState('phone', value)
          } else if (value === '') {
            onChangeState('phone', value)
          }
        }}
      />
      {deliveryType === 'storeDelivery' &&
        <RowTextInput
          placeholder={t('app.constant.order.customer.address')}
          value={address || ''}
          onChangeText={(value) => onChangeState('address', value)}
        />}
      <RowTextInput
        placeholder={t('app.constant.order.remark')}
        value={remark ?? ''}
        onChangeText={(value) => onChangeState('remark', value)}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 206,
  },

})
