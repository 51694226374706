import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { loadingKey } from '@/constants'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Row from '@/components/Row'
import _ from 'lodash'
import colors from '@/theme/colors'
import moment from 'moment'

export default function Header ({ order, onChangeState, setVisible = () => {} }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { createdAt, deliveryType, serial, pickuppOrderNumber, pickupAt } = order
  const merchant = useSelector(state => state.merchant.data)
  const takeawayMaxPickupMins = _.get(merchant, 'setting.takeawayMaxPickupMins') || 8640

  const Details = {
    table: () => (
      <View>
        <Text style={styles.titleText}>
          {moment(createdAt).format('YYYY/MM/DD dddd')}
        </Text>
        <Text style={styles.text}>
          {t('app.constant.order.serial')}：{serial}
        </Text>
      </View>
    ),
    takeaway: () => (
      <View>
        <TouchableOpacity
          onPress={async () => {
            setVisible(false)
            await dispatch(actions.app.openLoading(loadingKey.TIME_PICKER))
            setTimeout(() => {
              dispatch(actions.app.closeLoading(loadingKey.TIME_PICKER))
              dispatch(actions.app.showDateTimePicker('date',
                pickupAt || new Date(),
                (value) => onChangeState('pickupAt', value),
                null,
                moment().add(takeawayMaxPickupMins, 'm').toDate(),
                15,
                async () => {
                  await dispatch(actions.app.openLoading(loadingKey.TIME_PICKER))
                  setTimeout(() => {
                    dispatch(actions.app.closeLoading(loadingKey.TIME_PICKER))
                    setVisible(true)
                  }, 500)
                },
              ))
            }, 500)
          }}
        >
          <Text style={styles.titleText}>
            {moment(pickupAt).format('YYYY/MM/DD dddd')}
          </Text>
        </TouchableOpacity>
        <Text style={styles.text}>
          {t('app.constant.order.serial')}：{serial}
        </Text>
      </View>

    ),
    storeDelivery: () => (
      <View>
        <TouchableOpacity
          onPress={() => {
            setVisible(false)
            setTimeout(() => {
              dispatch(actions.app.showDateTimePicker('date', pickupAt || new Date(), (value) => onChangeState('pickupAt', value), new Date(), moment().add(takeawayMaxPickupMins, 'm').toDate()))
            }, 200)
          }}
        >
          <Text style={styles.titleText}>
            {moment(pickupAt).format('YYYY/MM/DD dddd')}
          </Text>
        </TouchableOpacity>
        <Text style={styles.text}>
          {t('app.constant.order.serial')}：{serial}
        </Text>
        <Text style={styles.text}>
          {t('app.constant.order.pickUp.id')}：{pickuppOrderNumber}
        </Text>
      </View>
    ),
  }
  const Detail = Details[deliveryType]

  return (
    <Row style={styles.container}>

      <Detail />

      {/* ? Days after */}
      <View style={styles.daysAfter}>
        <Text style={styles.daysAfterText}>
          {moment(deliveryType === 'table' ? createdAt : pickupAt).calendar()}
        </Text>
      </View>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 12,
  },
  titleText: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 30,
    color: colors.textSecondary,
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 22,
    color: colors.primary,
    opacity: 0.3,
  },
  daysAfter: {
    height: 26,
    minWidth: 51,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tertiary,
    borderRadius: 18,
    paddingHorizontal: 4,
    marginLeft: 12,
  },
  daysAfterText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.white,
  },
})
