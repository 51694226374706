import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Column from '../Column'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @param {ItemProps} props
 */
export default function Header (props) {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      <Column header index={0} value={t('app.common.list.serial')} />
      <Column header index={1} value={t('app.constant.order.name')} />
      <Column header index={2} value={t('app.constant.order.status')} />
      <Column header index={3} value={t('app.constant.order.price')} />
      <Column header index={4} value={t('app.constant.order.quantity')} />
      {/* <Column header index={5} value='折扣' />
      <Column header index={6} value='服務費' /> */}
      <Column header index={7} value={t('app.constant.order.subtotal')} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // width: 580,
    width: '100%',
    minHeight: 26,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primary,
  },
})
