import { MaterialCommunityIcons } from '@expo/vector-icons'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'

/**
 * @typedef BatchLocaleSelectButtonProps
 * @property {string?} selectedLocale
 * @property {() => void} onSelect
 * @property {boolean?} disabled
 */

/**
 *
 * @param {BatchLocaleSelectButtonProps} props
 * @returns
 */
export default function BatchLocaleSelectButton (props) {
  const lang = useSelector(state => state.app.settings.lang)
  const apiLanguage = lang.slice(0, 2)
  const {
    selectedLocale = apiLanguage,
    onSelect,
    disabled,
  } = props
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const { t } = useTranslation()

  // 系統中可用的餐單語言選項
  const localeOptions = [
    { value: 'zh', display: '繁體中文', keep: true }, // 就算 batchLocalOptions 沒有設定也要顯示
    { value: 'en', display: 'English', keep: true }, // 就算 batchLocalOptions 沒有設定也要顯示
    { value: 'thai', display: 'ไทย' },
    { value: 'korean', display: '한국어 ' },
    { value: 'kitchen', display: t('app.component.languageDialog.kitchen') },
  ]

  // 現在選擇的 locale option
  const selectedLocaleOption = localeOptions.find(localeOption => localeOption.value === selectedLocale)

  // admin 設定餐廳允許使用的餐單語言
  const batchLocaleOptions = useSelector(state => state.merchant.data?.setting?.batchLocaleOptions)

  // 允許選擇的餐單語言選項
  const allowedOptions = localeOptions.filter(localeOption => {
    return localeOption.keep || batchLocaleOptions?.includes(localeOption.value)
  })

  return (
    <>
      <TouchableOpacity
        disabled={disabled}
        onPress={() => setDialogOpen(true)}
      >
        <View style={[styles.languageSelect, { opacity: disabled ? 0.4 : 1 }]}>
          <MaterialCommunityIcons name='translate' size={18} color={colors.textPrimary} />
          <View style={styles.languageWrap}>
            <Text style={styles.language}>{selectedLocaleOption.display}</Text>
          </View>
        </View>
      </TouchableOpacity>

      <OptionsSelectDialog
        title={t('app.component.languageDialog.title')}
        closeOnSelect
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        options={allowedOptions}
        defaultValue={selectedLocale}
        onSubmit={(locale) => onSelect(locale)}
        modalProps={{ noTitle: true }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  languageSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
  },
  languageWrap: {
    borderColor: colors.textPrimary,
    borderStyle: 'solid',
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginLeft: 8,
    paddingVertical: 2,
  },
  language: {
    color: colors.textPrimary,
    fontWeight: 'bold',
  },
})
