import { Animated, StyleSheet, View } from 'react-native'
import React, { useEffect, useRef } from 'react'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

const SIZE = 50

/**
 *
 * @param {*} props
 * @returns
 */
export default function Loading (props) {
  const animation = useRef(new Animated.Value(0)).current
  const loadingActions = useSelector(state => state.app.loadingActions)
  const isAuthInit = useSelector(state => state.auth.isInit)
  const visible = loadingActions?.length > 0

  useEffect(() => {
    if (visible) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(animation, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(animation, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
          }),
        ]),
        {
          iterations: 200,
        },
      ).start()
    }
  }, [visible])

  if (!visible) return null

  const leftCircleStyles = {
    height: SIZE,
    width: SIZE,
    backgroundColor: colors.tertiary,
    borderRadius: SIZE / 2,
    zIndex: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [2, 0],
    }),
    transform: [
      {
        translateX: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [0, SIZE], // 動畫水平滑動
        }),
      },
    ],
  }
  const rightCircleStyles = {
    height: SIZE,
    width: SIZE,
    backgroundColor: colors.primary,
    borderRadius: SIZE / 2,
    zIndex: 1,
    transform: [
      {
        translateX: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [0, SIZE * -1], // 動畫水平滑動
        }),
      },
    ],
  }

  return (
    <View style={[styles.container, !isAuthInit && { backgroundColor: '#FFFFFF' }]}>
      <View style={styles.overlay} />
      <Animated.View style={leftCircleStyles} />
      <Animated.View style={rightCircleStyles} />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.black,
    opacity: 0.5,
  },
})
