import { StyleSheet, Text, TextInput, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'

const DEFAULT_TABLE = { from: '', to: '', customer: '' }

/**
 * @typedef TableInputDialogProps
 * @property {string} title
 * @property {string?} submitButtonText
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {() => void} onSubmit
 * @property {string} props
 */

/**
 *
 * @param {TableInputDialogProps} props
 * @returns
 */
export default function TableInputDialog (props) {
  const { t } = useTranslation()
  const {
    title,
    submitButtonText = t('app.common.send'),
    open,
    onClose,
    onSubmit,
    type,
  } = props
  const [table, setTable] = React.useState(DEFAULT_TABLE)
  const [showText, setShowText] = React.useState(false)
  const placeholderFrom = type === 'NumSeq' ? t('app.page.setting.table.from') : `${t('app.page.setting.table.from')} (e.g. A1)`
  const placeholderTo = type === 'NumSeq' ? t('app.page.setting.table.to') : `${t('app.page.setting.table.to')} (e.g. C3)`

  return (
    <CenterModal
      enablePressOutsideClose
      title={title}
      visible={open}
      onClose={onClose}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.inputGroup}>
        <View style={styles.inputContainer}>
          <TextInput
            keyboardType={type === 'NumSeq' ? 'number-pad' : 'default'}
            style={styles.input}
            placeholder={placeholderFrom}
            placeholderTextColor='#B0B0B0'
            value={table.from || ''}
            onChangeText={(text) => setTable({
              ...table,
              from: text,
            })}
          />
        </View>
        <View style={styles.inputContainer}>
          <TextInput
            keyboardType={type === 'NumSeq' ? 'number-pad' : 'default'}
            style={styles.input}
            placeholder={placeholderTo}
            placeholderTextColor='#B0B0B0'
            value={table.to || ''}
            onChangeText={(text) => setTable({
              ...table,
              to: text,
            })}
          />
        </View>
        {/* <View style={styles.inputContainer}>
          <TextInput
            keyboardType='number-pad'
            style={styles.input}
            placeholder='人數'
            placeholderTextColor='#B0B0B0'
            value={table.customer || ''}
            onChangeText={(text) => setTable({
              ...table,
              customer: text,
            })}
          />
        </View> */}
      </View>
      {
        showText && (
          <Text style={styles.errorText}>{t('app.page.setting.table.errorMsg')}</Text>
        )
      }
      <View style={styles.buttons}>
        <Button
          title={t('app.common.clear')}
          onPress={() => {
            setTable(DEFAULT_TABLE)
            setShowText(false)
          }}
        />
        <Button
          title={submitButtonText}
          onPress={() => {
            if (table.from === '' || table.to === '') {
              setShowText(true)
            } else {
              onSubmit(table)
              setShowText(false)
              setTable(DEFAULT_TABLE)
              onClose()
            }
          }}
        />
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 300,
    padding: 16,
    height: 230,
    justifyContent: 'space-between',
  },
  inputGroup: {
    width: '100%',
    height: 100,
  },
  inputContainer: {
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.primary,
    margin: 8,
  },
  input: {
    fontSize: 16,
    width: '100%',
    margin: 8,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  errorText: {
    flex: 1,
    fontWeight: '500',
    color: colors.secondary,
    marginTop: 8,
  },
})
