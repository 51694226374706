import { FlatList, Image, StyleSheet } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import BackNavigator from '@/components/Setting/BackNavigator'

import SettingList from './SettingList'
import SettingListItem from './SettingListItem'
import SettingListItemRightAction from './SettingListItemRightAction'
import SettingListItemText from './SettingListItemText'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OptionsPanel (props) {
  const dispatch = useDispatch()
  const {
    text,
    valuePath,
    onChangeAction,
    options,
    closeOnChange = false,
    multipleSelection = false,
  } = useSelector(state => state.setting.optionConfig)
  const value = useSelector(state => _.get(state, valuePath))

  const onChangeValue = (input) => {
    // 多選的，toggle選項
    if (multipleSelection) {
      if (value.includes(input)) {
        dispatch(onChangeAction(value.filter(v => v !== input)))
      } else {
        dispatch(onChangeAction([...value, input]))
      }
      return
    }

    // 單選的
    dispatch(onChangeAction(input))
    if (closeOnChange) {
      setTimeout(() => { dispatch(actions.setting.closeSettingOptions()) }, 100)
    }
  }

  const renderItem = ({ item: option, index }) => (
    <SettingListItem
      onPress={() => onChangeValue(option.key)}
      divider={index !== options.length - 1}
    >
      <SettingListItemText text={option.text} />
      <SettingListItemRightAction>
        {(value === option.key || (multipleSelection && value.includes(option.key))) && (
          <Image
            style={styles.checkedIcon}
            source={require('@icons/setting/checked.png')}
          />)}
      </SettingListItemRightAction>
    </SettingListItem>)

  return (
    <>
      <BackNavigator
        text={text}
        onPress={() => dispatch(actions.setting.closeSettingOptions())}
      />
      <SettingList>
        <FlatList
          data={options}
          renderItem={renderItem}
          extraData={value}
          keyExtractor={(item) => String(item.key)}
        />

      </SettingList>
    </>
  )
}

const styles = StyleSheet.create({
  checkedIcon: {
    height: 20,
    width: 20,
  },

})
