import { Animated, Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { getStatusConfig } from '@/libs/orderStatus'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import OrderList from '../../OrderList'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { ITable } from '@/redux/table/Table.d'
/* eslint-enable no-unused-vars */

/**
 * @typedef TableProps
 * @property {ITable} table
 * @property {(table: ITable) => void} [onSelectTable]
 * @property {(order: IAppOrder) => void} onSelectOrder
 * @property {boolean} [disableOrders=false]
 * @property {boolean} [requestWaiter=false]
 * @property {boolean} [requestWaiter=false]
 * @property {Animated.AnimatedProps<TextProps['style']> | null} animatedStyle
 * @property {ViewProps['style']} [style]
 */

/**
 *
 * @param {TableProps} props
 * @returns
 */
export default function Table (props) {
  const {
    table,
    onSelectTable = () => {},
    onSelectOrder,
    disableOrders = false, // order頁面不顯示訂單
    requestWaiter = false,
    forceSrcoll = false,
    style,
    animatedStyle,
  } = props

  const { t } = useTranslation()
  const fromText = t('app.page.table.tableArea.table.from')
  const toText = t('app.page.table.tableArea.table.to')
  const orderText = t('app.page.table.tableArea.table.order')
  const tableText = t('app.page.table.tableArea.table.tableNo')
  const moveText = t('app.page.table.tableArea.table.move')
  const confirmText = t('app.common.confirm')
  const cancelText = t('app.common.cancel')

  const dispatch = useDispatch()
  const { isSelecting, selectedOrders, isMoving, selectedTableId, isSelectingTable } = useSelector(state => state.table)
  const { enableCheckoutReceipt } = useSelector(state => state.merchant.data?.setting) ?? {}
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const [isExpanded, setIsExpanded] = React.useState(false)

  const orderIds = requestWaiter ? table.requestWaiterOrderIds : table.orderIds
  const orders = React.useMemo(() => {
    return _.filter(historyOrders, order => _.includes(orderIds, order.id))
  }, [historyOrders, orderIds])

  const customerCount = React.useMemo(() => {
    return _.reduce(orders, (acc, order) => acc + order.adults + order.children, 0)
  }, [orders])

  const pendingOrdersCount = React.useMemo(() => {
    return _.filter(orders, order => order.status === 'pending').length
  }, [orders])

  const isDisable = disableOrders && customerCount >= table.maxCustomer
  const isDisabled = selectedTableId && table.id !== selectedTableId

  const onSelectTableThrottle = React.useCallback(
    _.throttle(onSelectTable, 2000, { trailing: false }),
    [onSelectTable],
  )

  const displayOverdueWarning = _.some(orders, order => {
    if (order.status === 'paid') return false
    if (order.orderCutOffMins) {
      const overdueTime = moment(order.createdAt).add(order.orderCutOffMins, 'minute')
      const isAlmostOverdue = moment().isAfter(moment(overdueTime).subtract(5, 'minute'))
      const isOverdue = moment().isAfter(overdueTime)
      return (isAlmostOverdue || isOverdue) && order.status === 'pending' && order.deliveryType === 'table'
    }
  })

  React.useEffect(() => {
    // Close the list when it is not selecting
    if (!isSelecting && !onSelectOrder) {
      setIsExpanded(false)
      return
    }

    // Close the list when the selected is not this table
    if (isSelecting && table.id !== selectedTableId) {
      setIsExpanded(false)
    }

    // If there is only one order in the table, set isExpanded alaways to be true
    if (pendingOrdersCount === 1) {
      setIsExpanded(true)
    }
  }, [isSelecting, orders, selectedTableId])

  const handleTablePressed = () => {
    if (!disableOrders) {
      // Move the selected orders to this table
      if (isMoving) {
        dispatch(actions.app.showAlert({
          title: moveText,
          message: `${fromText}${orderText}${selectedOrders.map(s => s.serial.slice(-3)).join('、')} ${toText}${tableText} ${table.key}?`,
          buttons: [
            {
              children: confirmText,
              onPress: () => {
                dispatch(actions.table.moveOrder(table.key))
              },
            },
            {
              backgroundColor: colors.light,
              textColor: colors.textTertiary,
              children: cancelText,
              onPress: () => { },
            },
          ],
        }))
        return
      }

      if (isSelectingTable) {
        onSelectTableThrottle(table)
      }

      // Expand the list when it is selecting
      if (isSelecting || onSelectOrder) {
        dispatch(actions.table.selectTable(table.id))
        if (pendingOrdersCount === 1) {
          setIsExpanded(true)
          return
        }
        setIsExpanded(!isExpanded)
        return
      }
    }

    // Else, open the table dialog
    onSelectTableThrottle(table)
  }

  const handleSelectOrder = (order) => {
    if (isExpanded) {
      if (onSelectOrder) {
        onSelectOrder(order)
      } else {
        dispatch(actions.table.selectTable(null))
        dispatch(actions.table.selectOrder(order))
      }
    } else {
      handleTablePressed()
    }
  }

  // 找該座位的用餐時間（最早訂單）
  const earliestOrder = orders.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
  const now = moment()
  const duration = now.diff(moment(earliestOrder?.createdAt))

  // 找該座位的用餐人數
  const totalCustomers = orders.reduce((sum, order) => sum + order.customers, 0)

  const containerStyle = {
    zIndex: table.id === selectedTableId ? 1 : 0,
    backgroundColor: pendingOrdersCount === 0 ? colors.lightGray3 : getStatusConfig(earliestOrder)?.color,
  }

  const tableNameStyle = {
    color: enableCheckoutReceipt && earliestOrder?.printedCheckoutReceipt ? colors.yellow : 'white',
    fontWeight: enableCheckoutReceipt && earliestOrder?.printedCheckoutReceipt ? 'bold' : '400',
  }

  const timerStyle = {
    fontSize: 17,
    fontWeight: '600',
  }

  return (
    <TouchableOpacity
      style={[containerStyle, style]}
      onPress={handleTablePressed}
      disabled={isDisable}
      onStartShouldSetResponderCapture={() => false}
    >
      {/* info */}
      <View
        style={styles.orders}
      >
        <Text style={[tableNameStyle, styles.tableText]}>{table.name}</Text>

        {orders.length > 0 &&
          <View style={styles.customer}>
            <Image
              style={styles.icon}
              source={require('@icons/table/adult.png')}
            />
            <Text style={{ color: 'white', fontSize: 16, fontWeight: '500' }}>{totalCustomers}</Text>
          </View>}
        {orders.length > 1 &&
          <ImageBackground
            source={require('@icons/table/tables.png')}
            resizeMode='contain'
            style={styles.tables}
          />}
        {orders.length > 0 &&
          <View style={styles.duration}>
            {displayOverdueWarning
              ? (
                <Animated.Text style={[timerStyle, animatedStyle]}>
                  {moment.utc(duration).format('HH:mm')}
                </Animated.Text>)
              : (
                <Text style={[timerStyle, { color: 'white' }]}>
                  {moment.utc(duration).format('HH:mm')}
                </Text>
              )}
          </View>}
      </View>
      {/* order list */}
      {(isSelecting || forceSrcoll) &&
        <OrderList
          orders={orders}
          onSelectOrder={handleSelectOrder}
          expanded={isExpanded}
          disabled={isDisabled}
        />}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  orders: {
    flex: 1,
  },
  tables: {
    position: 'absolute',
    alignItems: 'center',
    left: 6,
    width: 36,
    height: '100%',
  },
  duration: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customer: {
    flexDirection: 'row',
    position: 'absolute',
    width: '100%',
    bottom: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    tintColor: 'white',
    width: 16,
    height: 16,
  },
  tableText: {
    position: 'absolute',
    width: '100%',
    fontSize: 22,
    fontWeight: '500',
    textAlign: 'center',
  },
})
