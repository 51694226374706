import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { dimorderApi } from '@root/src/libs/api/dimorder'
import { getHistoryOrder } from '@root/src/libs/orderHistory'
import { numberWithCommas } from '@root/src/libs/numberWithCommas'
import Column from '@root/src/components/Column'
import Row from '@root/src/components/Row'
import colors from '@root/src/theme/colors'
import shadows from '@root/src/theme/shadows'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef MembrOrderListProps
 * @property {IMember} member
 * @property {IAppOrder} selectedOrder
 * @property {() => void} setSelectedOrder
 */

/**
 *
 * @param {MembrOrderListProps} props
 * @returns
 */
export default function MembrOrderList (props) {
  const { member, selectedOrder, setSelectedOrder } = props
  const { t } = useTranslation()
  const [orderList, setOrderList] = useState([])

  const isEmpty = orderList.length === 0

  const getOrdersFunction = async (order) => {
    const localOrder = getHistoryOrder(order.id)

    return localOrder ?? await dimorderApi.order.getOrderByInfo({ serial: order.serial })
  }

  const getOrders = async () => await Promise.all(member.orders.map(getOrdersFunction))

  useEffect(() => {
    const getOrder = async () => {
      const orderList = await getOrders()
      setOrderList(orderList)
    }

    getOrder()
  }, [member])

  const renderItem = ({ item: order }) => {
    return (
      <TouchableOpacity
        style={[styles.listContainer]}
        onPress={() => setSelectedOrder(order)}
      >
        <MemberOrderListCard order={order} type={order.deliveryType} />
        {selectedOrder?.id === order.id && <View style={styles.overlay} />}
      </TouchableOpacity>

    )
  }

  return (
    <Column style={styles.container}>
      <FlatList
        maxToRenderPerBatch={7}
        initialNumToRender={7}
        data={orderList}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        showsVerticalScrollIndicator={false}
        extraData={orderList}
        contentContainerStyle={(isEmpty) && {
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        ListEmptyComponent={<Text style={styles.text}>{t('app.page.orderHistory.orderList.empty')}</Text>}
        getItemLayout={(data, index) => ({ length: 110, offset: index * 110, index })}
      />
    </Column>
  )
}

const MemberOrderListCard = ({ order, type }) => {
  if (!order) return null
  const { t } = useTranslation()
  const isTable = type === 'table'
  const isStoreDelivery = type === 'storeDelivery'

  const rowOneLabel = !isTable ? t('app.constant.order.pickupDate') : t('app.page.table.dialog.date')
  const rowOneValue = !isTable ? moment(order.pickupAt).format('DD/MM/YYYY') : moment(order.createdAt).format('DD/MM/YYYY')

  const serialLable = t('app.constant.order.serial')
  const serialValue = order.serial

  const rowThreeLabel = !isTable ? t('app.constant.order.pickupAt') : t('app.constant.order.tableLong')
  const rowThreeValue = !isTable ? moment(order.pickupAt).format('HH:mm') : `${order.table}${order.subTable > 0 ? `(${String.fromCharCode(order.subTable + 64)})` : ''}`

  return (
    <Column style={styles.details}>
      <Text style={styles.text}>{rowOneLabel}：{rowOneValue}</Text>
      <Text style={styles.text}>{serialLable}：{serialValue}</Text>
      <Row style={styles.more}>
        {
          isStoreDelivery ? (
            <>
              {order.shipping.lalamove && (
                <Text style={styles.text}>{rowThreeLabel}：{moment(order.shipping.lalamove.scheduleAt).add(10, 'm').format('HH:mm')}</Text>
              )}
              <Text style={styles.text}>{t('app.constant.order.completeAt')}：{moment(order.pickupAt).format('HH:mm')}</Text>
            </>
          ) : <Text style={styles.text}>{rowThreeLabel}：{rowThreeValue}</Text>
        }

      </Row>
      <Text style={styles.price}>
        ${numberWithCommas(order.roundedTotal)}
      </Text>
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.light,
  },
  content: {
    flex: 1,
  },
  listContainer: {
    height: 110,
    // width: 400,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.light,
    paddingHorizontal: 18,
    ...shadows.heavy,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    borderWidth: 4,
    borderColor: colors.lightPrimary,
  },
  details: {
    flex: 1,
    borderColor: colors.primary,
    borderBottomWidth: 1,
  },
  tableDetails: {
    flex: 1,
    borderColor: colors.primary,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 50,
  },
  price: {
    fontSize: 23,
    fontWeight: 'bold',
    color: colors.primary,
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  more: {
    justifyContent: 'space-between',
    borderColor: colors.primary,
    borderTopWidth: 1,
  },
  text: {
    fontSize: 12,
    lineHeight: 22,
    fontWeight: 'bold',
    color: colors.primary,
  },
  noText: {
    fontSize: 45,
    fontWeight: 'bold',
    color: colors.primary,
  },
})
