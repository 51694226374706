import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '@/theme/colors'

ActionButton.propTypes = {
  onPress: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconSource: PropTypes.any,
}

export default function ActionButton (props) {
  const { iconSource, text, onPress, disabled } = props
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <View style={[styles.container, disabled && styles.disabled]}>
        {iconSource && (
          <Image style={styles.icon} source={iconSource} />
        )}
        {text && (
          <Text style={styles.text}>
            {text}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 36,
    height: 36,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    paddingTop: 8,
    paddingBottom: 8,
  },
  disabled: {
    opacity: 0.6,
  },
  icon: {
    width: 36,
    height: 36,
  },
  text: {
    color: colors.white,
    fontSize: 21,
  },
})
