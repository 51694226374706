import { FlatList } from 'react-native-gesture-handler'
import { StyleSheet } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import AreaButton from './AreaButton'
import Column from '@/components/Column'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
import moment from 'moment'
import shadows from '@/theme/shadows'

/**
 * @typedef AreaBarProps
 * @property {boolean} [requestWaiter=false]
 * @param {AreaBarProps} props
 * @returns
 */
export default function AreaBar (props) {
  const { requestWaiter = false } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { tables: allTables, tableAreas, floorPlanAreas, selectedTableAreaId } = useSelector(state => state.table)
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const enableFloorPlan = useSelector(state => state.merchant.data?.setting?.enableFloorPlan)

  // 判斷顯示 FloorPlan區域/檯號群
  const areas = enableFloorPlan ? floorPlanAreas : tableAreas

  // 加上文字及順序設定
  const mappedTableAreas = _
    .chain(areas)
    .map((area, index) => {
      const tables = allTables.filter(table => enableFloorPlan ? table.floorPlanAreaId === area?.id : table.areaId === area?.id)
      const orderIds = _.chain(tables).map(table => table.orderIds).flatten().uniq().value()
      const orders = _.filter(historyOrders, order => _.includes(orderIds, order.id))

      let duration = 500
      const showOverdueIcon = _.some(orders, order => {
        if (order.status === 'paid') return false
        if (order.orderCutOffMins) {
          const overdueTime = moment(order.createdAt).add(order.orderCutOffMins, 'minute')
          const isAlmostOverdue = moment().isAfter(moment(overdueTime).subtract(5, 'minute'))
          const isOverdue = moment().isAfter(overdueTime)
          if (isOverdue) {
            duration = 200
          }
          return (isAlmostOverdue || isOverdue) && order.status === 'pending' && order.deliveryType === 'table'
        }
      })

      return {
        ...area,
        text: t(area.text),
        seq: index,
        showOverdueIcon,
        duration,
      }
    })
    .sortBy(area => area.seq)
    .value()

  const renderItem = ({ item: area }) =>
    <AreaButton
      key={area.text}
      text={area.text}
      active={area.id === selectedTableAreaId}
      showOverdueIcon={area.showOverdueIcon && !requestWaiter}
      duration={area.duration}
      onPress={() =>
        dispatch(actions.table.selectTableArea(area.id))}
    />

  return (
    <Column style={styles.container}>
      <FlatList
        data={mappedTableAreas}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.listContainer}
        style={styles.list}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 100,
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    paddingVertical: 30,
    ...shadows.default,
    zIndex: 2,
    elevation: 2,
  },
  list: {
    width: '100%',
  },
  listContainer: {
    alignItems: 'center',
  },
})
