import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import React from 'react'
import colors from '@/theme/colors'
const chevronPrimary = require('@icons/breadcrumb-chevron-primary.png')

/**
 * @param {{lastOnly: boolean}} props 只顯示路徑中最後一個分類
 */
export default function BackButton (props) {
  const dispatch = useDispatch()
  const selectedCategory = useSelector(state => state.menu.selectedCategory)
  const selectedBatch = useSelector(state => state.orderBatch.selectedBatch)
  const selectedItemKey = useSelector(state => state.orderBatch.selectedItemKey)
  const rootSetCategory = useSelector(state => state.menu.rootSetCategory)
  const isPictureMode = useSelector(state => state.menu.isPictureMode)

  return (
    <TouchableOpacity
      onPress={() => {
        const selectedItem = selectedBatch?.items?.find(item => item.key === selectedItemKey)
        dispatch(actions.menu.resetSearchInput())
        if (selectedItem?.isSet && !selectedItem?.setItems?.length) {
          dispatch(actions.orderBatch.deleteItem(selectedItemKey))
        }
        if (selectedCategory.isInSetCategories && selectedCategory.sets?.length === 1 && !isPictureMode) {
          dispatch(actions.menu.selectCategory(rootSetCategory))
        } else {
          dispatch(actions.menu.selectCategory(selectedCategory))
        }
      }}
    >
      <View style={styles.container}>
        <Image style={styles.chevronIcon} source={chevronPrimary} />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
    borderRadius: 17.5,
    margin: 8,
  },
  chevronIcon: {
    width: 9,
    height: 14,
    transform: [{ rotate: '180deg' }],
  },
})
