export default {
  INIT_DATA: 'SETTING/INIT_DATA',
  UPDATE_OPENING_SETTING: 'SETTING/UPDATE_OPENING_SETTING',
  SHOW_SETTING_OPTIONS: 'SETTING/SHOW_SETTING_OPTIONS',
  CLOSE_SETTING_OPTIONS: 'SETTING/CLOSE_SETTING_OPTIONS',
  RESET_ALL_SUB_VIEW: 'SETTING/RESET_ALL_SUB_VIEW',
  SELECT_CATEGORY: 'SETTING/SELECT_CATEGORY',
  UPDATE_SEARCH_TEXT: 'SETTING/UPDATE_SEARCH_TEXT',
  UPDATE_CUSTOM_REASON: 'SETTING/UPDATE_CUSTOM_REASON',
}
