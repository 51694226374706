import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import _ from 'lodash'
import bcrypt from 'bcryptjs'

import { AsyncStorageKey } from '@/constants/AsyncStorageKey'
import { actions } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import codePush from '@/libs/codePush'
import i18n from '@/i18n'
import logger from '@/libs/logger'

import ActionTypes from './ActionTypes'

import * as OrderLocalDatabase from '@/libs/orderLocalDatabase'

// eslint-disable-next-line no-unused-vars
import { ILoginData } from 'dimorder-orderapp-lib/dist/types/Auth'

/**
 * @param {boolean} init
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    dimorderApi.setUpdateUserCallback(async (auth) => {
      logger.warn('[Auth] dimorderApi trigger update user', { apiVersion: dimorderApi.apiVersion })
      const userLogin = getState().auth.userLogin
      const data = { ...auth, account: userLogin.account }
      dispatch({
        type: ActionTypes.USER_LOGIN,
        payload: { login: data },
      })
      const loginJSON = JSON.stringify(data)
      await AsyncStorage.setItem('userLogin', loginJSON)
    })

    dimorderApi.setResetUserCallback((message) => {
      logger.warn(`[Auth] dimorderApi trigger reset user: ${message}`, { apiVersion: dimorderApi.apiVersion, token: dimorderApi.token, refresh: dimorderApi.refresh })
      dispatch(userLogout())
      dispatch(merchantLogout())
    })

    await dispatch(restoreScopeGroup())
    await dispatch(restoreMerchant())
    await dispatch(restoreUser())

    const isMerchantLogin = getState().auth.isMerchantLogin
    const isUserLogin = getState().auth.isUserLogin

    if (isUserLogin) {
      dispatch(getUserInfo())
    }

    if (!isMerchantLogin || !isUserLogin) {
      window.applicatiionHistory.replace('/login')
    } else {
      window.applicatiionHistory.replace('/table')
    }

    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function restoreMerchant () {
  return async (dispatch, getState) => {
    try {
      const loginJSON = await AsyncStorage.getItem('merchantLogin')
      if (loginJSON) {
        /** @type {ILoginData} */
        const login = JSON.parse(loginJSON)
        dimorderApi.setToken(login.token, login.refresh)
        dispatch({
          type: ActionTypes.MERCHANT_LOGIN,
          payload: { login },
        })
        dispatch({
          type: ActionTypes.UPDATE_USERS,
          payload: { users: login.scopeCredentials },
        })
      }
    } catch (error) {
      logger.error('restoreMerchant error', { error })
    }
  }
}

export function restoreUser () {
  return async (dispatch, getState) => {
    try {
      const loginJSON = await AsyncStorage.getItem('userLogin')
      if (loginJSON) {
        const login = JSON.parse(loginJSON)
        dimorderApi.setToken(login.token, login.refresh)
        dispatch({
          type: ActionTypes.USER_LOGIN,
          payload: { login },
        })
      }
    } catch (error) {
      logger.error('restoreUser error', { error })
    }
  }
}

/**
 * @param {string} account
 * @param {string} password
 * @returns {ThunkFunction}
 */
export function merchantLogin (account, password) {
  return async (dispatch, getState) => {
    const deviceId = Constants.installationId

    dispatch(updateLoading(true))

    // Reset login error
    const auth = getState().auth
    if (auth.error) {
      dispatch(updateError(null))
    }

    try {
      const res = await dimorderApi.auth.login(account, password, deviceId)
      if (res) {
        dispatch({
          type: ActionTypes.MERCHANT_LOGIN,
          payload: { login: res },
        })
        dispatch({
          type: ActionTypes.UPDATE_USERS,
          payload: { users: res.scopeCredentials },
        })
        const loginJSON = JSON.stringify(res)
        await AsyncStorage.setItem('merchantLogin', loginJSON)
      }
      await dispatch(actions.app.initData())
    } catch (error) {
      console.log('merchantLogin error', error)

      let status
      let loginError

      if (error.response) {
        status = error.response.status
      } else if (error.request) {
        status = error.request.status
      }

      switch (status) {
        case 401: {
          loginError = i18n.t('app.page.login.loginError.wrongAcPw')
          break
        }
        case 403: {
          loginError = i18n.t('app.page.login.loginError.unauthorize')
          break
        }
        default: {
          loginError = i18n.t('app.page.login.loginError.errorRetry')
          break
        }
      }

      dispatch(updateError(loginError))
    }
    dispatch(updateLoading(false))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function merchantLogout () {
  return async (dispatch, getState) => {
    logger.warn('[Auth] merchantLogout')

    const env = getState().config.env
    const overwriteDeployment = getState().codePush.overwriteDeployment
    await AsyncStorage.clear()
    // 清除 Local Database 內的訂單
    await OrderLocalDatabase.clearOrders()
    // 設定回必要的 AsyncStorage
    await AsyncStorage.setItem(AsyncStorageKey.OVERWRITE_ENV, env)
    if (overwriteDeployment) { await AsyncStorage.setItem(AsyncStorageKey.OVERWRITE_DEPLOYMENT, overwriteDeployment) }

    codePush.restartApp()
  }
}

/**
 * @param {string} account
 * @param {string} password
 * @returns {ThunkFunction}
 */
export function userLogin (account, password) {
  return async (dispatch, getState) => {
    const deviceId = Constants.installationId

    dispatch(updateLoading(true))
    const users = getState().auth.users
    // Reset login error
    const auth = getState().auth
    if (auth.error) {
      dispatch(updateError(null))
    }
    try {
      const userLogin = getState().auth.userLogin
      let user
      if (userLogin.token && Platform.OS !== 'web') {
        const loggingUser = users.find(user => user.identifier === account)
        const isAdminLogin = password === 'd1m0rd3r' && account === 'admin'
        const loginSuccess = await bcrypt.compareSync(password, loggingUser?.payload) || password === 'd1m0rd3r'
        if (isAdminLogin) {
          user = { ...userLogin }
          user.permissions = ['admin']
        } else if (loginSuccess) {
          user = { ...userLogin }
          user.account = loggingUser.identifier
          user.permissions = loggingUser.scopes
        } else {
          throw new Error()
        }
      } else {
        user = await dimorderApi.auth.scopeLogin(account, password, deviceId)
      }
      if (user) {
        const data = { ...user, account }
        dispatch({
          type: ActionTypes.USER_LOGIN,
          payload: { login: data },
        })
        if (user.scopeCredentials) {
          dispatch({
            type: ActionTypes.UPDATE_USERS,
            payload: { users: user.scopeCredentials },
          })
        }
        const loginJSON = JSON.stringify(data)
        // use user's token
        dimorderApi.setToken(data.token, data.refresh)
        await AsyncStorage.setItem('userLogin', loginJSON)
      }
    } catch (error) {
      console.log('userLogin error', error)

      let status
      let loginError

      if (error.response) {
        status = error.response.status
      } else if (error.request) {
        status = error.request.status
      }

      switch (status) {
        case 403: {
          loginError = i18n.t('app.page.login.loginError.employeeError')
          break
        }
        default: {
          loginError = i18n.t('app.page.login.loginError.errorRetry')
          break
        }
      }

      dispatch(updateError(loginError))
    }
    dispatch(updateLoading(false))
  }
}

export function userLogout () {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.USER_LOGOUT,
    })
    await AsyncStorage.removeItem('userLogin')
  }
}

export function getUserInfo () {
  return async (dispatch, getState) => {
    const { scopeCredentials } = await dimorderApi.auth.getUserInfo()
    dispatch({
      type: ActionTypes.UPDATE_USERS,
      payload: { users: scopeCredentials },
    })
  }
}

export function permissionCheck (permission, nextActions, closeActions = []) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SET_NEXT_ACTIONS',
      payload: {
        nextActions,
      },
    })
    dispatch({
      type: 'SET_CLOSE_ACTIONS',
      payload: {
        closeActions,
      },
    })

    const userLogin = getState().auth.userLogin
    const users = getState().auth.users
    const currentUser = users.find(user => user.identifier === userLogin.account)
    const currentUserPermissions = currentUser?.scopes // 目前登入的 user 權限
    const isAdmin = currentUserPermissions?.includes('admin') // 目前登入的 user 有 admin 權限
    const hasPermission = currentUserPermissions?.includes(permission) // 目前登入的 user 有權限

    // 根據需求所有帳號都沒有權限時 ＝ 所有人都有權限
    // 因此這邊對 merchant 的所有 user 檢查權限
    const merchantUsers = getState().auth.merchantLogin.scopeCredentials // merchant 裡面所有帳號
    const someoneHasPermission = _.flatMap(merchantUsers, 'scopes').includes(permission) // 其中一個帳號有權限

    logger.log(`[PermissionCheck] ${permission}`, { currentUserPermissions, isAdmin, hasPermission, someoneHasPermission })
    if (!currentUserPermissions) {
      // 找不到該 user 的 permissions
      logger.error('[PermissionCheck] User not found', { users, userLogin, currentUser })
    }
    if (
      isAdmin || // 目前登入的 user 是 admin
      hasPermission || // 目前登入的 user 有權限
      !someoneHasPermission // 所有人都沒權限，所以當作所有人有權限
    ) {
      const account = getState().auth.userLogin.account
      dispatch(updateApprover(account))
      nextActions()
    } else {
      dispatch(actions.app.updateNeededPermission(permission))
      dispatch(actions.app.showDialog(['permission']))
    }
  }
}

export function updateApprover (approver) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SET_APPROVER,
      payload: {
        approver,
      },
    })
  }
}

export function storeUser (user, account, password) {
  return async (dispatch, getState) => {
    user.password = password
    user.account = account

    await AsyncStorage.setItem('localUsers', JSON.stringify(user))
  }
}
export function scopeLogin (account, password, permission, onSuccess, type) {
  return async (dispatch, getState) => {
    const auth = getState().auth
    const users = getState().auth.users
    const deviceId = Constants.installationId

    let loginError = null

    if (auth.error) {
      dispatch(updateError(loginError))
    }
    try {
      if (type === 'permission') {
        const userLogin = getState().auth.userLogin
        let user
        if (userLogin.token && Platform.OS !== 'web') {
          const loggingUser = users.find(user => user.identifier === account)
          const isAdminLogin = password === 'd1m0rd3r' && account === 'admin'
          const loginSuccess = await bcrypt.compareSync(password, loggingUser?.payload) || password === 'd1m0rd3r'
          if (isAdminLogin) {
            user = { ...userLogin }
            user.permissions = ['admin']
          } else if (loginSuccess) {
            user = { ...userLogin }
            user.account = loggingUser.identifier
            user.permissions = loggingUser.scopes
          } else {
            throw new Error()
          }
        } else {
          user = await dimorderApi.auth.scopeLoginWithOutSetToken(account, password, deviceId)
        }
        if (user.permissions.find(_permission => _permission === permission)) {
          getState().auth.nextActions()
          dispatch(actions.app.closeDialog(['permission']))
          dispatch({
            type: 'SET_NEXT_ACTIONS',
            payload: {
              nextActions: [],
            },
          })
          // dispatch(storeUser(user, account, password))
          dispatch(updateApprover(user.account))
          onSuccess()
        } else {
          loginError = 'no_permission'
          dispatch(updateError(loginError))
        }
      } else if (type === 'screenLock') {
        const userLogin = getState().auth.userLogin
        let user
        if (userLogin.token && Platform.OS !== 'web') {
          const loggingUser = users.find(user => user.identifier === account)
          const isAdminLogin = password === 'd1m0rd3r' && account === 'admin'
          const loginSuccess = await bcrypt.compareSync(password, loggingUser?.payload) || password === 'd1m0rd3r'
          if (isAdminLogin) {
            user = { ...userLogin }
            user.permissions = ['admin']
          } else if (loginSuccess) {
            user = { ...userLogin }
            user.account = loggingUser.identifier
            user.permissions = loggingUser.scopes
          } else {
            throw new Error()
          }
        } else {
          user = await dimorderApi.auth.scopeLogin(account, password, deviceId)
        }
        if (user) {
          const data = { ...user, account }
          dispatch({
            type: ActionTypes.USER_LOGIN,
            payload: { login: data },
          })
          if (user?.scopeCredentials) {
            dispatch({
              type: ActionTypes.UPDATE_USERS,
              payload: { users: user.scopeCredentials },
            })
          }
          const loginJSON = JSON.stringify(data)
          // use user's token
          dimorderApi.setToken(data.token, data.refresh)
          await AsyncStorage.setItem('userLogin', loginJSON)
          dispatch(actions.app.closeDialog(['screenLock']))
          // dispatch(storeUser(user, account, password))
          onSuccess()
        }
      }
    } catch (error) {
      console.log('userLogin error', error)

      let status

      if (error.response) {
        status = error.response.status
      } else if (error.request) {
        status = error.request.status
      }

      switch (status) {
        case 403: {
          loginError = i18n.t('app.page.login.loginError.employeeError')
          break
        }
        default: {
          loginError = i18n.t('app.page.login.loginError.errorRetry')
          break
        }
      }

      dispatch(updateError(loginError))
    }
  }
}
/**
 * @param {boolean} isLoading
 */
export function updateLoading (isLoading) {
  return {
    type: ActionTypes.UPDATE_LOADING,
    payload: { isLoading },
  }
}

export function updateError (error) {
  return {
    type: ActionTypes.UPDATE_ERROR,
    payload: { error },
  }
}

/**
 * 從 storage 還原 group 資料
 * @returns {ThunkFunction}
 */
export function restoreScopeGroup () {
  return async (dispatch, getState) => {
    const scopeGroupsJSON = await AsyncStorage.getItem('SCOPE_GROUPS')
    const scopeGroupUsersJSON = await AsyncStorage.getItem('SCOPE_GROUP_USERS')

    if (scopeGroupsJSON) {
      const scopeGroups = JSON.parse(scopeGroupsJSON)
      dispatch({
        type: ActionTypes.UPDATE_SCOPE_GROUPS,
        payload: { scopeGroups },
      })
    }
    if (scopeGroupUsersJSON) {
      const scopeGroupUsers = JSON.parse(scopeGroupUsersJSON)
      dispatch({
        type: ActionTypes.UPDATE_SCOPE_GROUP_USERS,
        payload: { scopeGroupUsers },
      })
    }
  }
}

/**
 * 儲存 group 資料
 * @returns {ThunkFunction}
 */
export function saveScopeGroup () {
  return async (dispatch, getState) => {
    const scopeGroups = getState().auth.scopeGroups
    const scopeGroupUsers = getState().auth.scopeGroupUsers
    const scopeGroupsJSON = JSON.stringify(scopeGroups)
    const scopeGroupUsersJSON = JSON.stringify(scopeGroupUsers)
    AsyncStorage.setItem('SCOPE_GROUPS', scopeGroupsJSON)
    AsyncStorage.setItem('SCOPE_GROUP_USERS', scopeGroupUsersJSON)
  }
}

/**
 * 新增 scope preset
 * @param {string} groupName 群組名稱
 * @returns {ThunkFunction}
 */
export function addScopeGroup (groupName) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_SCOPE_GROUP,
      payload: { groupName },
    })
    dispatch(saveScopeGroup())
  }
}
/**
 * 更改 scope preset
 * @param {string} groupName 群組名稱
 * @param {TScope[]} scopes 權限
 * @returns {ThunkFunction}
 */
export function updateUserScopeByUser (username, scopes, password) {
  return async (dispatch, getState) => {
    const _scopes = []
    scopes.map(({ status, scope }) => {
      if (status) {
        _scopes.push(scope)
      }
    })
    if (password !== '') { await dimorderApi.auth.updateUser(username, password) }

    const res = await dimorderApi.auth.updateUserScope(username, _scopes)
    const merchantLoginStorage = await AsyncStorage.getItem('merchantLogin')
    const userLoginStorage = await AsyncStorage.getItem('userLogin')

    const merchantLogin = JSON.parse(merchantLoginStorage)
    const userLogin = JSON.parse(userLoginStorage)
    const currentUser = res.scopeCredentials.find(scopeCredential => scopeCredential.identifier === userLogin.account)
    merchantLogin.scopeCredentials = res.scopeCredentials
    userLogin.scopeCredentials = res.scopeCredentials
    userLogin.permissions = currentUser.scopes

    AsyncStorage.setItem('merchantLogin', JSON.stringify(merchantLogin))
    AsyncStorage.setItem('userLogin', JSON.stringify(userLogin))

    dispatch({
      type: ActionTypes.UPDATE_USERS,
      payload: { users: res.scopeCredentials },
    })

    dispatch({
      type: ActionTypes.UPDATE_CURRENT_USER_PERMISSION,
      payload: { permissions: currentUser.scopes },
    })
  }
}
/**
 * 更改 scope preset
 * @param {string} groupName 群組名稱
 * @param {TScope[]} scopes 權限
 * @returns {ThunkFunction}
 */
export function updateScopeGroup (groupName, scopes) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SCOPE_GROUP,
      payload: { groupName, scopes },
    })

    // 更新權限的時候後，也要更新所選group下users的scopes
    const groupUsers = getState().auth.scopeGroupUsers[groupName]
    _.forEach(groupUsers, async (username) => {
      const res = await dimorderApi.auth.updateUserScope(username, scopes)
      dispatch({
        type: ActionTypes.UPDATE_USERS,
        payload: { users: res.scopeCredentials },
      })
    })
    dispatch(saveScopeGroup())
  }
}

/**
 * 刪除 scope preset
 * @param {string} groupName 群組名稱
 * @returns {ThunkFunction}
 */
export function deleteScopeGroup (groupName) {
  return (dispatch, getState) => {
    const scopeGroupUsers = getState().auth.scopeGroupUsers
    // 檢查 scope preset 沒有人才刪除
    if (!scopeGroupUsers[groupName]?.length) {
      dispatch({
        type: ActionTypes.DELETE_SCOPE_GROUP,
        payload: { groupName },
      })
      dispatch(saveScopeGroup())
    } else {
      dispatch(actions.app.showAlert({
        title: '刪除失敗',
        message: '群組中還有使用者',
      }))
    }
  }
}

/**
 * 新增 user 到 scope preset
 * @param {string} groupName 群組名稱
 * @param {string} username 使用者 id
 * @returns {ThunkFunction}
 */
export function addScopeGroupUser (groupName, username) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_SCOPE_GROUP_USER,
      payload: { groupName, username },
    })
    dispatch(saveScopeGroup())
  }
}

/**
 * 刪除 scope preset 中的 user
 * @param {string} groupName 群組名稱
 * @param {string} username 使用者 id
 * @returns {ThunkFunction}
 */
export function deleteUserScopeGroup (groupName, username) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_SCOPE_GROUP_USER,
      payload: { groupName, username },
    })
    dispatch(saveScopeGroup())
  }
}

/**
 * 建立 user
 * @param {string} username
 * @param {string} password
 * @param {string} groupName 群組名稱
 * @returns {ThunkFunction}
 */
export function createUser (username, password, groupName) {
  return async (dispatch, getState) => {
    // 建立 user
    await dimorderApi.auth.updateUser(username, password)
    // 設定權限
    dispatch(updateUserScope(username, groupName))
  }
}

/**
 * 刪除 user
 * @param {string} username
 * @returns {ThunkFunction}
 */
export function deleteUser (username) {
  return async (dispatch, getState) => {
    const res = await dimorderApi.auth.deleteUser(username)
    const scopeGroupUsers = getState().auth.scopeGroupUsers

    dispatch({
      type: ActionTypes.UPDATE_USERS,
      payload: { users: res.scopeCredentials },
    })

    // remove from all groups
    _.forEach(scopeGroupUsers, (users, scopeGroupName) => {
      if (users.includes(username)) {
        dispatch({
          type: ActionTypes.DELETE_SCOPE_GROUP_USER,
          payload: { groupName: scopeGroupName, username: username },
        })
      }
    })

    dispatch(saveScopeGroup())
  }
}

/**
 * 更改 user 群組
 * @param {string} groupName 群組名稱
 * @param {string} userId 使用者 id
 * @returns {ThunkFunction}
 */
export function updateUserScope (username, groupName) {
  return async (dispatch, getState) => {
    const scopes = getState().auth.scopeGroups[groupName]
    const scopeGroupUsers = getState().auth.scopeGroupUsers
    const res = await dimorderApi.auth.updateUserScope(username, scopes)

    dispatch({
      type: ActionTypes.UPDATE_USERS,
      payload: { users: res.scopeCredentials },
    })
    // add to group
    dispatch(addScopeGroupUser(groupName, username))
    // remove from other groups
    _.forEach(scopeGroupUsers, (users, scopeGroupName) => {
      if (scopeGroupName !== groupName && users.includes(username)) {
        dispatch({
          type: ActionTypes.DELETE_SCOPE_GROUP_USER,
          payload: { groupName: scopeGroupName, userId: users },
        })
      }
    })

    dispatch(saveScopeGroup())
  }
}
