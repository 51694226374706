import { StyleSheet, Text, View } from 'react-native'
import { getFilterSecion } from '@/libs/orderFilters'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import Column from '@/components/Column'
import FilterButton from './FilterButton'
import React from 'react'
import Row from '@/components/Row'
import _ from 'lodash'
import colors from '@/theme/colors'

export default function FilterSection ({
  field,
  filter,
  onSelect,
  divider = false,
  columns = 3,
}) {
  const filterSecion = getFilterSecion(field)
  const { t } = useTranslation()
  const quickMode = useSelector(state => state.merchant.data?.setting?.quickMode)

  console.log('filterSecion', filterSecion)
  return (
    <Column style={styles.container}>
      <Text style={styles.text}>{t(filterSecion.name)}</Text>
      <Row style={styles.row}>
        {filterSecion.fieldValues.map((fieldValue, index) => {
          const selected = filter.some(value => _.isEqual(fieldValue.value, value))
          if (fieldValue.quickMode.includes(quickMode)) {
            return (
              <FilterButton
                key={fieldValue.value}
                width={columns === 3 ? 100 : 155}
                text={fieldValue.name}
                selected={selected}
                onPress={() => onSelect(field, fieldValue.value)}
              />
            )
          }
        })}
      </Row>
      {divider && <View style={styles.divider} />}
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 25,
    paddingTop: 20,
  },
  row: {
    flexWrap: 'wrap',
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 'bold',
    color: colors.primary,
    marginHorizontal: 8,
    marginVertical: 5,
  },
  divider: {
    height: 2,
    backgroundColor: colors.lightGray3,
    marginTop: 12,
  },
})
