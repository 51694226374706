import { Image, StyleSheet } from 'react-native'
import React from 'react'
import _ from 'lodash'

import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'

import QuickModifierButton from './QuickModifierButton'

/* eslint-disable no-unused-vars */
import { IModifier } from 'dimorder-orderapp-lib/dist/types/Modifier'
import { IOrderModifier } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

const ICONS = {
  percent: require('@icons/discount-dialog/percentage-symbol.png'),
  amount: require('@icons/discount-dialog/dollar-symbol.png'),
}

/** @type {IModifier | IOrderModifier} */
const DEFAULT_MODIFIER = {
  percent: 0,
  amount: 0,
  type: 'DISCOUNT',
  applyTo: 'PRODUCT',
  id: 'DISCOUNT',
}

/**
 * @typedef QuickModifiersProps
 * @property {'percent' | 'amount'} variant
 * @property {number} inputValue
 * @property {number[]} values
 * @property {number} amount
 * @property {() => void} onPress
 * @property {'DISCOUNT' | 'SURCHARGE' | 'MERCHANT'} modifierType
 */

/**
 *
 * @param {QuickModifiersProps} props
 * @returns
 */
export default function QuickModifiers (props) {
  const { variant, inputValue, values, amount, onPress, modifierType } = props
  const iconSource = ICONS[variant]
  const submitButtonDisabled =
    (variant === 'percent' && Math.abs(inputValue) > 100) ||
    (variant === 'amount' &&
      Math.abs(inputValue) > +Number(amount).toFixed(1) &&
      modifierType !== 'MERCHANT')

  return (
    <Column style={styles.container}>
      {/* Submit Button */}
      <Button
        type='Pressable'
        style={styles.symbolButton}
        backgroundColor={colors.darkSecondary}
        textColor={colors.white}
        disabled={submitButtonDisabled}
        onPressStyle={{
          backgroundColor: colors.lightPrimary,
          textColor: colors.white,
        }}
        onPress={() =>
          onPress({
            ...DEFAULT_MODIFIER,
            [variant]: +Number(inputValue).toFixed(1),
            type: modifierType,
            id: modifierType,
          })}
      >
        <Image style={styles.symbol} source={iconSource} />
      </Button>

      {/* Quick Modifiers */}
      {_.map(values, (value, index) => {
        const disable =
          Math.abs(value) > amount &&
          variant === 'amount' &&
          modifierType !== 'MERCHANT'
        return (
          <QuickModifierButton
            key={index}
            variant={variant}
            value={value}
            disabled={disable}
            onPress={() =>
              onPress({
                ...DEFAULT_MODIFIER,
                [variant]: value,
                type: modifierType,
                id: modifierType,
              })}
          />
        )
      })}
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    height: 350,
  },
  symbolButton: {
    width: 90,
    height: 50,
    margin: 10,
  },
  symbol: {
    width: 40,
    height: 40,
  },
})
