import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { useSelectedOrder as useSelectedHistoryOrder } from '@/hooks/orderHistory'
import { useSelectedOrder } from '@/hooks/order'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import Column from '@/components/Column'
import ColumnLabel from '@/components/InfoDialog/ColumnLabel'
import ExpandableDivider from '@/components/ExpandableDivider'
import NumberInput from '@/components/InfoDialog/NumberInput'
import Row from '@/components/Row'
import ToggleSwitch from '@/components/ToggleSwitch'

import DetailInput from './DetailInput'
import Header from './Header'
import SubHeader from './SubHeader'

/**
 * @typedef OrderInfoDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {() => void} onSubmit
 * @property {boolean?} isOrderHistory
 */

/**
 *
 * @param {OrderInfoDialogProps} props
 * @returns
 */
export default function OrderInfoDialog (props) {
  const { open, onSubmit = () => {}, onClose = () => {}, isOrderHistory = false } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedOrder = useSelectedOrder()
  const historyOrder = useSelectedHistoryOrder()
  const order = isOrderHistory ? historyOrder : selectedOrder
  const [localOrder, setLocalOrder] = useState(null)
  const [error, setError] = useState('')
  const [isVisible, setVisible] = React.useState(open)
  const merchant = useSelector(state => state.merchant.data)

  React.useEffect(() => {
    setVisible(open)
  }, [open])

  const onChangeState = (key, val) => {
    setLocalOrder(prevState =>
      _.set(_.assign({}, prevState), key, val),
    )
  }

  useEffect(() => {
    setLocalOrder(order)
  }, [order])

  if (localOrder === null || localOrder === undefined) return null

  const titles = {
    table: t('app.page.order.orderInfoDialog.title.table'),
    takeaway: t('app.page.order.orderInfoDialog.title.takeaway'),
    storeDelivery: t('app.page.order.orderInfoDialog.title.storeDelivery'),
  }

  const title = titles[localOrder.deliveryType]

  return (
    <CenterModal
      enablePressOutsideClose
      title={title}
      visible={isVisible}
      onClose={onClose}
    >
      <View style={styles.container}>
        <Header
          order={localOrder}
          onChangeState={onChangeState}
          setVisible={(value) => setVisible(value)}
        />
        <SubHeader
          order={localOrder}
          onChangeState={onChangeState}
          setVisible={(value) => setVisible(value)}
        />
        <ExpandableDivider
          disabled
          lineStyle={{ margin: 0 }}
        />

        <Row style={styles.details}>
          {
            localOrder.deliveryType !== 'table' && (
              <DetailInput
                order={localOrder}
                onChangeState={onChangeState}
              />
            )
          }
          {localOrder.deliveryType === 'table'
            ? (
              <Row style={[styles.rightContainer, { paddingHorizontal: 25 }]}>
                <ColumnLabel label={t('app.constant.order.adult')} required style={styles.label}>
                  <NumberInput
                    min={0}
                    max={20}
                    value={localOrder.adults}
                    setValue={(value) => onChangeState('adults', value)}
                    style={{ width: 50 }}
                  />
                </ColumnLabel>
                <ColumnLabel label={t('app.constant.order.children')} style={styles.label}>
                  <NumberInput
                    min={0}
                    max={20}
                    value={localOrder.children}
                    setValue={(value) => onChangeState('children', value)}
                    style={{ width: 50 }}
                  />
                </ColumnLabel>
              </Row>
            )
            : localOrder.deliveryType === 'takeaway'
              ? (
                <Column style={styles.rightContainer}>
                  <ColumnLabel label={t('app.page.order.orderInfoDialog.content.needTableware')} style={styles.label}>
                    <ToggleSwitch
                      value={localOrder.needTableware}
                      onChangeValue={() => {
                        onChangeState('needTableware', !localOrder.needTableware)
                      }}
                      size={29}
                    />
                  </ColumnLabel>
                </Column>
              )
              : (
                <Column style={styles.rightContainer}>
                  <ColumnLabel label={t('app.page.order.orderInfoDialog.content.dropOffAt')} style={styles.label}>
                    <TouchableOpacity
                      onPress={() => dispatch(actions.app.showDateTimePicker('time', localOrder.dropoffAt || moment().add(_.get(merchant, 'shippings[0].minDeliveryMinute') || 0, 'm').toDate(), (value) => onChangeState('dropoffAt', value)))}
                    >
                      <Text style={styles.inputText}>
                        {
                          localOrder.dropoffAt
                            ? moment(localOrder.dropoffAt).format('HH:mm')
                            : moment().add((_.get(merchant, 'shippings[0].minDeliveryMinute') || 0), 'm').format('HH:mm')
                        }
                      </Text>
                    </TouchableOpacity>
                  </ColumnLabel>
                  <ColumnLabel label={t('app.page.order.orderInfoDialog.content.needTableware')} style={styles.label}>
                    <ToggleSwitch
                      value={localOrder.needTableware}
                      onChangeValue={() => {
                        onChangeState('needTableware', !localOrder.needTableware)
                      }}
                      size={29}
                    />
                  </ColumnLabel>
                </Column>
              )}
        </Row>
        <Row style={styles.bottomRow}>
          <Text style={styles.errorText}>{error || ' '}</Text>
          <Button
            title={t('app.common.confirm')}
            textBold
            style={styles.button}
            onPress={async () => {
              if (isOrderHistory) {
                dispatch(actions.orderHistory.updateOrder(localOrder, { selectOrder: true, syncOrder: true }))
              } else {
                dispatch(actions.orderBatch.deselectItems())
                await dispatch(actions.order.updateOrder(localOrder))
              }
              setError('')
              onSubmit()
            }}
          />
        </Row>
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 395,
    marginHorizontal: 27,
    marginBottom: 8,
    height: 435,
  },
  button: {
    alignSelf: 'flex-end',
    margin: 0,
  },
  details: {
    width: '100%',
    marginBottom: 12,
    justifyContent: 'center',
  },
  leftContainer: {
    width: 206,
  },
  rightContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    borderColor: colors.superlightPrimary,
    borderWidth: 2,
    marginLeft: 30,
    paddingVertical: 15,
    minHeight: 130,
    maxWidth: 200,
  },
  label: {
    alignItems: 'center',
  },
  inputText: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.primary,
    borderBottomColor: colors.primary,
    borderBottomWidth: 2.5,
  },
  errorText: {
    flex: 1,
    fontWeight: '500',
    color: colors.secondary,
  },
})
