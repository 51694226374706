import { StyleSheet, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CreditCardPanel (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let cardTerminalIpAddress = useSelector(state => state.app.settings.cardTerminalIpAddress)
  if (typeof cardTerminalIpAddress === 'string') {
    cardTerminalIpAddress = {
      eftPay: '',
      globalPayment: '',
      bbMSL: '',
      octopusMiniPC: '',
    }
  }
  const [terminalIpAddress, setTerminalIpAddress] = useImmer(cardTerminalIpAddress)
  const handleSave = () => {
    let isVaild = true
    _.each(terminalIpAddress, (ipAddress) => {
      if (!/(localhost|[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}:[0-9]{1,5})/g.test(ipAddress) && ipAddress !== '') {
        dispatch(actions.app.showAlert({ title: t('app.page.setting.printer.saveError'), message: t('app.page.setting.printer.errorMsg2') }))
        isVaild = false
        return false
      }
    })
    if (!isVaild) return

    dispatch(actions.app.updateSetting(['cardTerminalIpAddress'], terminalIpAddress))
    dispatch(actions.app.showAlert({ message: t('app.alert.saveSuccess') }))
  }
  return (
    <View style={styles.container}>
      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.checkout.creditCardSetting')} />
        </Header>
        <View style={styles.content}>
          <SettingListItem divider>
            <SettingListItemText text={'Global Payment ' + t('app.page.setting.localPanel.setting.creditCardSetting.ipAddreess')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                value={terminalIpAddress.globalPayment}
                onChangeText={(value) => {
                  setTerminalIpAddress(draft => {
                    draft.globalPayment = value
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={'eftPay ' + t('app.page.setting.localPanel.setting.creditCardSetting.ipAddreess')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                value={terminalIpAddress.eftPay}
                onChangeText={(value) => {
                  setTerminalIpAddress(draft => {
                    draft.eftPay = value
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={'bbMSL ' + t('app.page.setting.localPanel.setting.creditCardSetting.ipAddreess')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                value={terminalIpAddress.bbMSL}
                onChangeText={(value) => {
                  setTerminalIpAddress(draft => {
                    draft.bbMSL = value
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={'Octopus ' + t('app.page.setting.localPanel.setting.creditCardSetting.ipAddreess')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                value={terminalIpAddress.octopusMiniPC}
                onChangeText={(value) => {
                  setTerminalIpAddress(draft => {
                    draft.octopusMiniPC = value
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </View>
      </View>

      {/** Right */}
      <View style={styles.contentContainer}>
        <View style={styles.content} />
        <View style={styles.buttons}>
          <Button
            textBold
            title={t('app.common.save')}
            style={styles.button}
            backgroundColor={colors.darkSecondary}
            onPress={handleSave}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  icon: {
    width: 16,
    height: 16,
  },
  errorText: {
    margin: 8,
    paddingLeft: 16,
    color: colors.error,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
