import { Platform } from 'react-native'
import Constants from 'expo-constants'
import Logger from 'dimorder-orderapp-lib/dist/libs/Logger'
import _ from 'lodash'
import axios from 'axios'
import * as Device from 'expo-device'

import packageJson from '@root/package.json'
import sentry from '@/libs/sentry'
import shortCode from '@/libs/shortCode'

// eslint-disable-next-line no-unused-vars
import { IAppOrder, IAppOrderBatch, IOrderBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'

export const logId = shortCode.generate(4)
sentry.setTag('logId', logId)

const errorToPlainObject = (error) => {
  // 保留 AxsioError 的內容
  if (axios.isAxiosError(error)) {
    return _.omit(error, ['config.data', 'stack'])
  }

  if (error instanceof Error) {
    return _.pick(error, ['message', 'stack'])
  }

  return error
}

const gcpLogger = new Logger('https://orderapp-logger-ssj7hdeukq-de.a.run.app/log', {
  buildNumber: Platform.OS !== 'web' ? `${Constants.nativeAppVersion} (${Constants.nativeBuildVersion})` : packageJson.version,
  appVersion: packageJson.version,
  platform: `${Device.osName} ${Device.osVersion}`,
  model: `${Device.manufacturer} ${Device.modelName}`,
  client: 'MERCHANT_APP',
  deviceId: Constants.installationId,
  environment: process.env.NODE_ENV,
  logId,
})

const nativeSupportLogLevel = [
  'debug',
  'error',
  'info',
  'log',
  'trace',
  'warn',
]

const logger = {}

nativeSupportLogLevel.forEach(level => {
  logger[level] = (...args) => {
    const [message, data] = args
    if (typeof console[level] === 'function') {
      console[level](...args)
    }
    // translate error object to plain object for better gcp log
    if (data?.error) {
      data.error = errorToPlainObject(data.error)
    }
    gcpLogger.log(
      level,
      { message, ...data },
      new Date().toISOString(),
    )
  }
})

// 用來強制 flush log，可用在 reload 之前避免還沒送出的 log 在 reload 後消失
export const flush = async () => {
  try {
    logger.log('[logger] manual flush logs')
    await gcpLogger.asyncFlush()
  } catch (error) {
    console.log('[logger] manual flush error', error)
  }
}

export function setEnv (env) {
  gcpLogger.labels.environment = env
}

export function setMerchantId (merchantId) {
  gcpLogger.labels.merchantId = merchantId
}

/**
 * @param {string} deployment - 目前安裝的 codepush update 的 label
 */
export function setCodePushLabel (codePushLabel) {
  gcpLogger.labels.codePushLabel = codePushLabel
}

/**
 * @param {string} deployment - 目前安裝的 codepush update 的 deployment
 */
export function setCodePushDeployment (deployment) {
  gcpLogger.labels.deployment = deployment
}

/**
 * @param {string} deployment - 已設定的 overwrite deployment
 */
export function setCodePushOverwriteDeployment (deployment) {
  gcpLogger.labels.overwriteDeployment = deployment
}

/**
 * @param {IAppOrder} order
 */
export function convertToShortOrderLogString (order) {
  return [order?.id, order?.serial, order?.roundedTotal, order?.status].join()
}

/**
 * @param {IAppOrder} orders
 * @description 將整batch orders 轉為 convertToShortOrderLogString
 */
export function convertToShortOrdersLog (orders) {
  const ordersLog = orders.map(order => convertToShortOrderLogString(order))
  return { ordersLog: ordersLog }
}

/**
 * @param {IAppOrder} order
 * @param {boolean} ignoreArray //Convert 成一個不包含array 的logOrder
 */
export function convertToSimpleOrder (order, ignoreArray = false) {
  const logOrder = _.pick(order, [
    'id',
    'serial',
    'table',
    'deliveryType',
    'from',
    'status',
    'takeawayStatus',
    'sync',
    'createdAt',
    'paidAt',
    'pickupAt',
    'cancelledAt',
    'rounding',
    'roundedTotal',
    'modifiers',
    'payments',
  ])

  if (ignoreArray) {
    return _.pickBy(logOrder, key => !_.isArray(key))
  } else {
    logOrder.batches = order.batches?.map(convertToSimpleBatch) ?? []
  }

  return logOrder
}

/**
 * @param {IAppOrderBatch} batch
 */
export function convertToSimpleBatch (batch) {
  const logBatch = _.pick(batch, [
    'id',
    'batchId',
    'orderId',
    'status',
    'takeawayStatus',
    'createdAt',
    'updatedAt',
  ])

  logBatch.items = batch.items?.map(convertToSimpleBatchItem) ?? []

  return logBatch
}

/**
 * @param {IOrderBatchItem} batch
 */
export function convertToSimpleBatchItem (batchItem) {
  const logBatchItem = _.pick(batchItem, [
    'id',
    'batchId',
    'categoryId',
    'menuId',
    'setMenuId',
    'setId',
    'name',
    'setName',
    'isSet',
    'options',
    'modifiers',
    'quantity',
    'total',
    'createdAt',
    'cancelled',
  ])

  logBatchItem.setItems = batchItem.setItems?.map(convertToSimpleBatchItem) ?? []

  return logBatchItem
}

export function convertToSimpleMenu (menu) {
  const logMenu = _.pickBy(menu, key => !_.isArray(key) && !_.isObject(key))

  logMenu.menus = _.map(menu.menus, menu => {
    const item = convertToSimpleMenuItem(menu)
    return convertToSimpleMenuLogString(item)
  })
  logMenu.sets = _.map(menu.sets, set => {
    const needKeys = ['id', 'name', 'price']
    const setLog = _.pick(set, needKeys)
    // 只取出 value ，節省 key 的size
    setLog.d = Object.values(setLog).join()
    // 移除已節省的 data
    for (const key of needKeys) { delete setLog[key] }
    // Items
    setLog.menus = _.map(set.menus, menu => {
      const item = convertToSimpleMenuItem(menu)
      return convertToSimpleMenuLogString(item)
    })

    return setLog
  })

  return logMenu
}

export function convertToSimpleMenuItem (item) {
  const logMenuItem = _.pick(item, [
    'id',
    'name',
    'price',
  ])

  return logMenuItem
}

export function convertToSimpleMenuLogString (item) {
  // 因為gcp查菜式通常是使用菜式名稱，id先省略中間部份 節省log用量
  if (item.id) item.id = item.id.substring(0, 5) + '...' + item.id.substring(item.id.length - 2)
  return Object.values(item).join() // 使用簡單string節省log用量
}

export default logger
