import _ from 'lodash'
import moment from 'moment'
import store from '@/redux/store'
/** @type {() => IRootState} */
const getState = store.getState

export default function genSerial () {
  const { serialIndex, terminalID = '0' } = getState().app.settings
  const { createdAt } = getState().merchant.data
  const now = moment()
  const days = String(now.diff(moment(createdAt, 'YYYY-MM-DD'), 'days')).substring(0, 3).padStart(3, '0')
  const serialwithZero = _.padStart(serialIndex, 3, 0)
  const serial = `${days}-${serialwithZero.substring(0, 1)}${terminalID}${serialwithZero.substring(1, 3)}`

  return serial
}
