import { StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import CustomerTag from './CustomerTag'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function CustomerCount (
  {
    state: {
      adults,
      children,
    },
    setAdultActive,
    setChildrenActive,
    adultActive,
    childrenActive,
    setClearValue,
  },
) {
  const { t } = useTranslation()
  const adult = t('app.page.table.dialog.adult')
  const child = t('app.page.table.dialog.child')

  return (
    <Row style={styles.container}>
      <Row style={styles.rightContainer}>
        <CustomerTag
          label={adult}
          style={styles.columnLabel}
          onPress={() => {
            if (!adultActive) {
              setAdultActive(true)
              setChildrenActive(false)
              setClearValue(true)
            }
          }}
          active={adultActive}
        >
          <Text style={styles.itemText}>{adults}</Text>
        </CustomerTag>

        <CustomerTag
          label={child}
          style={styles.columnLabel}
          onPress={() => {
            if (!childrenActive) {
              setChildrenActive(true)
              setAdultActive(false)
              setClearValue(true)
            }
          }}
          active={childrenActive}
        >
          <Text style={styles.itemText}>{children}</Text>
        </CustomerTag>
      </Row>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  leftContainer: {
    flex: 2,
    paddingRight: '10%',
  },
  rightContainer: {
    flex: 1,
    paddingTop: 30,
    height: 200,
    justifyContent: 'space-between',
  },
  columnLabel: {
    alignItems: 'center',
    width: 90,
  },
  itemText: {
    fontSize: 75,
    fontWeight: '500',
    color: colors.primary,
  },
})
