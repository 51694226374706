import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IMerchantState} */
export const initialState = {
  isInit: false,
  isLoading: false,
  data: {},
  sorting: {
    categories: [],
    categoryTags: [],
    printCategories: [],
  },
  modifier: [],
  group: null,
}

export default produce(
  /**
   * @param {IMerchantState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_LOADING: {
        const { isLoading } = action.payload
        draft.loading = isLoading
        break
      }
      case ActionTypes.UPDATE_MERCHANT: {
        const { data } = action.payload
        draft.data = data
        break
      }
      case ActionTypes.UPDATE_SORTING: {
        const { sorting } = action.payload
        draft.sorting = sorting
        break
      }
      case ActionTypes.UPDATE_MODIFIER: {
        const { modifier } = action.payload
        draft.modifier = modifier
        break
      }
      case ActionTypes.UPDATE_GROUP: {
        const { group } = action.payload
        draft.group = group
        break
      }
      case ActionTypes.RESTORE: {
        const { merchant } = action.payload
        return merchant
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
