import { StyleSheet, Text, TextInput, View } from 'react-native'
import { actions, useDispatch } from '@/redux'
import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import RadioBox from '@/components/RadioBox'
import React, { useMemo, useRef, useState } from 'react'
import Row from '../Row'
import ToggleSwitch from '../ToggleSwitch'
import colors from '@/theme/colors'

/**
 * @typedef DummyOrderDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 */

/**
 *這dialog是一個獨立的功能，用作debug，以複製大量的訂單，給AsyncStorage測試處理大量的訂單
 *Button位置在設定頁面內的debug欄裏
 * @param {DummyOrderDialogProps} props
 * @returns
 */
export default function DummyOrderDialog (props) {
  const { open, onClose } = props
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(quantity)
  const [isGenfromMerchant, setIsGenfromMerchant] = useState(false)
  const [openOrderTypeDialog, setOpenOrderTypeDialog] = useState(false)
  const order = useRef()
  const onSubmit = (quan, orderType, isGenfromMerchant, isSaveAtServer) => {
    dispatch(actions.orderHistory.generateOrder(quan, orderType, isGenfromMerchant, isSaveAtServer))
    onClose()
  }
  return (
    <>
      <CenterModal
        enablePressOutsideClose
        title='加入Dummy Orders'
        visible={open}
        onClose={onClose}
        contentContainerStyle={[styles.contentContainer]}
      >
        <Text>！！！加入過多訂單會嚴重減慢 iPad 的performance</Text>
        <Row style={{ paddingTop: 10 }}>
          <Text style={styles.text}>Generate from sample</Text>
          <ToggleSwitch
            value={isGenfromMerchant}
            onChangeValue={() => {
              setIsGenfromMerchant(!isGenfromMerchant)
              if (!isGenfromMerchant) {
                // wait for the toggle animation finish
                setTimeout(() => setOpenOrderTypeDialog(true), 400)
              }
            }}
            size={23}
          />
          <Text
            style={styles.text}
            onPress={() => {
              setOpenOrderTypeDialog(true)
            }}
          >
            Generate from merchant
          </Text>
        </Row>

        <View style={styles.inputContainer}>
          <TextInput
            autoFocus
            style={styles.input}
            placeholder='Dummy Orders 數量'
            placeholderTextColor='#B0B0B0'
            value={quantity}
            onChangeText={(text) => setQuantity(text)}
          />
        </View>

        <OrderTypeDialog
          open={openOrderTypeDialog}
          onClose={() => setOpenOrderTypeDialog(false)}
          onChange={(type) => {
            order.current = type
          }}
        />
        <View style={styles.buttons}>
          <Button
            title='Save to redux'
            onPress={() => onSubmit(quantity, order.current, isGenfromMerchant)}
          />
          <Button
            disabled={!isGenfromMerchant}
            title='Save to server'
            onPress={() => onSubmit(quantity, order.current, isGenfromMerchant, true)}
          />
        </View>
      </CenterModal>
    </>
  )
}

const OrderTypeDialog = (props) => {
  const { open, onClose, onChange } = props
  const status = [
    { key: 0, type: 'paid', text: '已結帳', checked: true },
    { key: 1, type: 'unpaid', text: '未結帳', checked: false },
  ]

  const deliveryType = [
    { key: 0, type: 'table', text: '堂食單', checked: true },
    { key: 1, type: 'takeaway', text: '自取單', checked: false },
    { key: 2, type: 'storeDelivery', text: '外賣單', checked: false },
  ]
  const [orderStatus, setOrderStatus] = useState(status)
  const [orderType, setOrderType] = useState(deliveryType)

  const order = useMemo(() => {
    return {
      status: orderStatus.find(status => status.checked),
      deliveryType: orderType.find(type => type.checked),
    }
  }, [orderStatus, orderType])

  onChange(order)

  return (
    <CenterModal
      enablePressOutsideClose
      title='選擇訂單類型'
      visible={open}
      onClose={onClose}
      contentContainerStyle={[styles.orderTypeContainer]}
    >
      <View style={styles.typeContainer}>
        <View style={styles.viewContainer}>
          <Text style={[styles.text, { paddingBottom: 10 }]}>訂單狀態</Text>
          {orderStatus.map((item, index) => {
            return (
              <View key={item.key} style={styles.typeContainer}>
                <RadioBox
                  value={item.checked}
                  onChangeValue={() => {
                    setOrderStatus(
                      orderStatus.map((type, i) =>
                        i === index ? { ...type, checked: true } : { ...type, checked: false },
                      ))
                  }}
                />
                <Text style={{ padding: 8 }}>{item.text}</Text>
              </View>
            )
          })}
        </View>
        <View style={styles.viewContainer}>
          <Text style={[styles.text, { paddingBottom: 10 }]}>訂單類型</Text>
          {orderType.map((item, index) => {
            return (
              <View key={item.key} style={styles.typeContainer}>
                <RadioBox
                  value={item.checked}
                  onChangeValue={() => {
                    setOrderType(
                      orderType.map((type, i) =>
                        i === index ? { ...type, checked: true } : { ...type, checked: false },
                      ))
                  }}
                />
                <Text style={{ padding: 8 }}>{item.text}</Text>
              </View>
            )
          })}
        </View>
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  orderTypeContainer: {
    padding: 14,
    height: 160,
    width: 280,
  },
  text: {
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.textPrimary,
    paddingHorizontal: 5,
  },
  contentContainer: {
    width: 480,
    padding: 14,
    height: 200,
  },
  inputContainer: {
    width: '100%',
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.primary,
    margin: 16,
  },
  input: {
    fontSize: 16,
    width: '100%',
    margin: 8,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  viewContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    alignItems: 'center',
  },
})
