import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import _ from 'lodash'

import { useLocation } from '@/libs/reactRouter'
import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import ExpandableDivider from '@/components/ExpandableDivider'
import NumberPad from '@/components/NumberPad'

import QuickModifiers from './QuickModifiers'

/* eslint-disable no-unused-vars */
import { IModifier } from 'dimorder-orderapp-lib/dist/types/Modifier'
import { IOrderModifier } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

const BUTTONS = [
  { text: '1', fontSize: 27 },
  { text: '2', fontSize: 27 },
  { text: '3', fontSize: 27 },
  { text: '4', fontSize: 27 },
  { text: '5', fontSize: 27 },
  { text: '6', fontSize: 27 },
  { text: '7', fontSize: 27 },
  { text: '8', fontSize: 27 },
  { text: '9', fontSize: 27 },
  { text: 'C', fontSize: 26, variant: 'action' },
  { text: '0', fontSize: 27 },
  { text: '.', fontSize: 27 },
]

const QUICK_MODIFIER = {
  DISCOUNT: {
    percent: [-5, -10, -20, -30],
    amount: [-5, -10, -20, -30],
  },
  SURCHARGE: {
    percent: [5, 10, 20, 30],
  },
  MERCHANT: {
    amount: [5, 10, 20, 30],
  },
}

/**
 * @typedef ModifierDialogProps
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {(modifier: IModifier | IOrderModifier) => void} onSubmit
 * @property {'DISCOUNT' | 'SURCHARGE' | 'MERCHANT' | null} [modifierType='DISCOUNT'] Default: 'DISCOUNT'
 * @property {number} amount
 * @property {boolean} [showDefault=false] Dafault: false
 * @property {boolean} [isSet=false] Dafault: false
 */

/**
 *
 * @param {ModifierDialogProps} props
 * @returns
 */
export default function ModifierDialog (props) {
  const { open, onClose, onSubmit, modifierType = 'DISCOUNT', amount, showDefault = false, isSet = false } = props
  const { t } = useTranslation()
  const location = useLocation()
  const [inputValue, setInputValue] = useState('0')
  const [isExpanded, setIsExpanded] = useState(false)
  const defaultModifiers = useSelector(state => state.merchant.modifier)

  const isCheckoutPage = location.pathname === '/orderHistory/checkout'

  const title = React.useMemo(() => {
    if (modifierType === 'DISCOUNT') return t('app.component.modifierDialog.discount')
    if (modifierType === 'SURCHARGE') return t('app.component.modifierDialog.surcharge')
    if (modifierType === 'MERCHANT') return t('app.component.modifierDialog.itemChangePrice')
    return ''
  }, [t, modifierType])

  const modifiers = React.useMemo(() => {
    return defaultModifiers.filter(m => {
      if (!m.enable) return false
      return ((isCheckoutPage && m.applyTo === '') || (!isCheckoutPage && m.applyTo === 'PRODUCT')) && !(Math.abs(m.percent) > 0 && isSet)
    })
  }, [defaultModifiers, isCheckoutPage, isSet])

  const data = React.useMemo(() => {
    const MODIFIER_COUNTS = 4
    if (modifierType === 'DISCOUNT') {
      return modifiers.length <= MODIFIER_COUNTS
        ? _.assign(new Array(MODIFIER_COUNTS).fill({}), modifiers)
        : _.take(modifiers, MODIFIER_COUNTS)
    }
    return []
  }, [modifiers, modifierType])

  const amountPrefix = (modifierType === 'DISCOUNT' ? '-' : '')
  const showDefaultOption = modifiers.length > 0 && showDefault

  /**
   * @param {IModifier | IOrderModifier} modifier
   */
  const handleSubmit = (modifier) => {
    onSubmit(modifier)
    onClose()
  }

  /**
   *
   * @param {RenderItemProps} params
   * @typedef RenderItemProps
   * @property {IModifier | IOrderModifier} item
   * @property {number} index
   * @returns
   */
  const renderItem = ({ item, index }) => {
    const modifier = {
      percent: item.percent,
      amount: item.amount,
      id: 'DISCOUNT',
      type: 'DISCOUNT',
      applyTo: 'PRODUCT',
      custom: true,
      customName: item.name,
      customId: item.id,
    }
    return (
      <Button
        key={index}
        type='Pressable'
        style={styles.button}
        backgroundColor={item.name ? colors.light : colors.white}
        textColor={colors.textTertiary}
        textStyle={styles.buttonText}
        onPress={() => handleSubmit(modifier)}
        onPressStyle={{ backgroundColor: colors.lightPrimary, textColor: colors.white }}
      >
        {item.name}
      </Button>
    )
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title={title}
      visible={open}
      onClose={onClose}
      contentContainerStyle={styles.modal}
    >
      {(!_.isEmpty(modifiers) && showDefaultOption) && (
        <>
          <FlatList
            style={{ flexGrow: 0 }}
            contentContainerStyle={styles.content}
            data={data} // render 前 8 個常用的 discounts，少於 8 個用空白按鈕填滿
            renderItem={renderItem}
            keyExtractor={(item, index) => String(index)}
            numColumns={4}
          />
          <ExpandableDivider
            expanded={isExpanded}
            disabled={modifiers.length < 5}
            onPress={() => {
              if (modifiers.length >= 5) {
                setIsExpanded(!isExpanded)
              }
            }}
          />
        </>
      )}

      {(isExpanded && showDefaultOption)
        ? (
          <FlatList
            style={{ height: 358 }}
            contentContainerStyle={styles.content}
            data={modifiers.length % 4 === 0
              ? _.slice(modifiers, 4)
              : _.assign(new Array(modifiers.length - modifiers.length % 4).fill({}), _.slice(modifiers, 4))} // render 剩下的 discounts
            renderItem={renderItem}
            keyExtractor={(item, index) => String(index)}
            numColumns={4}
          />
        )
        : (
          <View style={[styles.buttomInputs]}>
            <View>
              <View style={[styles.inputValue]}>
                <Text style={[styles.inputValueText, { marginRight: 4 }]}>{amountPrefix}</Text>
                <Text style={styles.inputValueText} numberOfLines={1}>
                  {inputValue}
                </Text>
              </View>
              <NumberPad
                style={{ margin: 10 }}
                buttonSize={70}
                buttons={BUTTONS}
                onButtonPress={(text) => {
                  if (text === 'C') {
                    setInputValue('0')
                    return
                  }
                  if (inputValue === '0' && text !== '.') {
                    setInputValue(text)
                    return
                  }
                  setInputValue(inputValue + text)
                }}
                disabledTexts={{ '.': inputValue?.includes('.') }}
              />
            </View>
            {(QUICK_MODIFIER[modifierType]?.percent && !isSet) &&
              // 套餐不支援百分比折扣，因此不顯示百分比折扣按鈕
              <QuickModifiers
                variant='percent'
                amount={amount}
                inputValue={Number(`${amountPrefix}${inputValue}`)}
                values={QUICK_MODIFIER[modifierType]?.percent}
                modifierType={modifierType}
                onPress={handleSubmit}
              />}
            {QUICK_MODIFIER[modifierType]?.amount &&
              <QuickModifiers
                variant='amount'
                amount={amount}
                inputValue={Number(`${amountPrefix}${inputValue}`)}
                values={QUICK_MODIFIER[modifierType]?.amount}
                modifierType={modifierType}
                onPress={handleSubmit}
              />}
          </View>
        )}

      {modifierType === 'DISCOUNT' && (
        <View style={styles.buttonContainer}>
          <Button
            type='Pressable'
            style={styles.symbolButton}
            backgroundColor={colors.darkSecondary}
            textColor={colors.white}
            textStyle={styles.buttonText}
            onPressStyle={{ backgroundColor: colors.lightPrimary, textColor: colors.white }}
            onPress={() => {
              const baseModifier = { percent: 0, amount: 0, type: modifierType, applyTo: 'PRODUCT', id: modifierType }
              handleSubmit(baseModifier)
            }}
          >
            {t('app.component.modifierDialog.noDiscount')}
          </Button>
        </View>
      )}
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  modal: {
    padding: 20,
    maxHeight: 600,
  },
  content: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  buttomInputs: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-around',
    height: 360,
  },
  inputValue: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.numPadGray,
    borderRadius: 8,
    paddingHorizontal: 24,
    marginHorizontal: 12,
    marginTop: 10,
    // marginBottom: 4,
  },
  inputValueText: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'right',
    color: colors.textTertiary,
  },
  button: {
    width: 110,
    height: 60,
    margin: 10,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  symbolButton: {
    width: 150,
    height: 50,
    margin: 10,
  },
  buttonContainer: {
    alignSelf: 'flex-end',
    paddingRight: 15,
  },
})
