import { KeyboardAvoidingView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import Row from '@/components/Row'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListInputAdornment from '@/components/Setting/SettingListInputAdornment'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

const defaultState = {
  day: '',
  start: '',
  startMin: '',
  end: '',
  endMin: '',
  orderBeforeMin: '',
  takeaway: false,
  storeDelivery: false,
  surcharge: '',
  discount: '',
  twentyFourHours: false,
}

/**
 * @typedef OpeningInfoProps
 * @param {*} selectedOpening
 * @param {() => void} setSelectedOpening
 */

/**
 *
 * @param {OpeningInfoProps} props
 * @returns
 */
export default function OpeningInfo (props) {
  const { selectedOpening, setSelectedOpening } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const days = {
    1: t('app.constant.weekday.mon'),
    2: t('app.constant.weekday.tue'),
    3: t('app.constant.weekday.wed'),
    4: t('app.constant.weekday.thur'),
    5: t('app.constant.weekday.fri'),
    6: t('app.constant.weekday.sat'),
    0: t('app.constant.weekday.sun'),
  }

  const [openingState, setOpeningState] = React.useState(defaultState)
  const [keyboardAvoidingViewEnabled, setKeyboardAvoidingViewEnabled] = React.useState(false)
  const defaultSurcharge = useSelector(state => state.merchant.data.surcharge.percent)
  const merchantSetting = useSelector(state => state.merchant.data?.setting) ?? {}
  const { quickMode } = merchantSetting

  React.useEffect(() => {
    if (selectedOpening) {
      setOpeningState({
        ...selectedOpening,
        surcharge: selectedOpening.surcharge?.percent || 0,
        discount: selectedOpening.discount?.percent || 0,
        twentyFourHours: selectedOpening.start === 0 && selectedOpening.startMin === 0 && selectedOpening.end === 0 && selectedOpening.endMin === 0,
      })
    }
  }, [selectedOpening])

  if (!selectedOpening) return null

  const onChangeState = (key, val) => {
    setOpeningState(prevState =>
      _.set(_.assign({}, prevState), key, val),
    )
  }

  const onSubmit = () => {
    if (isNaN(openingState.start) ||
    isNaN(openingState.startMin) ||
    isNaN(openingState.end) ||
    isNaN(openingState.endMin) ||
    isNaN(openingState.orderBeforeMin) ||
    isNaN(openingState.surcharge)) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.aboutPanel.opening.error.number')))
      return
    }

    if (!_.inRange(Number(openingState.start), 24) || !_.inRange(Number(openingState.end), 24)) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.aboutPanel.opening.error.hour')))
      return
    }
    if (!_.inRange(Number(openingState.startMin), 60) || !_.inRange(Number(openingState.endMin), 60)) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.aboutPanel.opening.error.min')))
      return
    }
    const start = Number(_.padStart(openingState.start, 2, '0') + _.padStart(openingState.startMin, 2, '0'))
    const end = Number(_.padStart(openingState.end, 2, '0') + _.padStart(openingState.endMin, 2, '0'))
    if (start >= end && !openingState.twentyFourHours) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.aboutPanel.opening.error.range')))
      return
    }

    let opening = openingState
    if (openingState.surcharge === '') {
      opening = { ...openingState, surcharge: defaultSurcharge }
    }

    if (openingState.id) {
      dispatch(actions.setting.updateOpening(opening))
    } else {
      dispatch(actions.setting.createOpening(opening))
    }
    setSelectedOpening(null)
  }

  const onDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.aboutPanel.opening.alert.delete.title'),
      message: t('app.page.setting.aboutPanel.opening.alert.delete.msg'),
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            dispatch(actions.setting.deleteOpening(openingState))
            setSelectedOpening(null)
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  return (
    <Column style={styles.container}>
      <Column style={styles.content}>
        <Header><HeaderText text={t('app.page.setting.aboutPanel.opening.workingHour')} /></Header>
        <SettingList>
          <ScrollView showsVerticalScrollIndicator={false}>
            <KeyboardAvoidingView behavior='position' enabled={keyboardAvoidingViewEnabled} keyboardVerticalOffset={160}>
              <SettingListItem divider>
                <SettingListItemText text={t('app.constant.weekday.week')} style={styles.text} />
                <SettingListItemRightAction>
                  <SettingListItemText text={days[selectedOpening.day]} />
                </SettingListItemRightAction>
              </SettingListItem>
              {
                !openingState.twentyFourHours &&
                  <>
                    <SettingListItem divider>
                      <SettingListItemText text={t('app.page.setting.aboutPanel.opening.start')} style={styles.text} />
                      <SettingListItemRightAction>
                        <View style={{ flex: 1 }}>
                          <TouchableOpacity
                            onPress={() => dispatch(actions.app.showDateTimePicker(
                              'time',
                              moment({
                                hour: openingState.start,
                                minute: openingState.startMin,
                              }).toDate(),
                              (value) => {
                                const selectedStart = moment(value)
                                onChangeState('start', selectedStart.format('HH'))
                                onChangeState('startMin', selectedStart.format('mm'))
                              },
                              moment({
                                hour: 0,
                                minute: 0,
                              }).toDate(),
                              null,
                              1,
                            ))}
                          >
                            <Text style={styles.timeText}>
                              {
                                moment({ hour: openingState.start, minute: openingState.startMin }).format('HH:mm')
                              }
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </SettingListItemRightAction>
                    </SettingListItem>
                    <SettingListItem divider>
                      <SettingListItemText text={t('app.page.setting.aboutPanel.opening.end')} style={styles.text} />
                      <SettingListItemRightAction>
                        <View style={{ flex: 1 }}>
                          <TouchableOpacity
                            onPress={() => dispatch(actions.app.showDateTimePicker('time',
                              moment({
                                hour: openingState.end,
                                minute: openingState.endMin,
                              }).toDate(),
                              (value) => {
                                const selectedStart = moment(value)
                                onChangeState('end', selectedStart.format('HH'))
                                onChangeState('endMin', selectedStart.format('mm'))
                              },
                              moment({
                                hour: openingState.start,
                                minute: openingState.startMin,
                              }).toDate(),
                              null,
                              1,
                            ))}
                          >
                            <Text style={styles.timeText}>
                              {
                                moment({ hour: openingState.end, minute: openingState.endMin }).format('HH:mm')
                              }
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </SettingListItemRightAction>
                    </SettingListItem>
                  </>
              }
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.aboutPanel.opening.24hour')} style={styles.text} />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={openingState.twentyFourHours}
                    onChangeValue={() => {
                      onChangeState('twentyFourHours', !openingState.twentyFourHours)
                      if (!openingState.twentyFourHours) {
                        onChangeState('start', '0')
                        onChangeState('startMin', '0')
                        onChangeState('end', '0')
                        onChangeState('endMin', '0')
                      } else {
                        onChangeState('start', '0')
                        onChangeState('startMin', '0')
                        onChangeState('end', '23')
                        onChangeState('endMin', '59')
                      }
                    }}
                    size={23}
                  />

                </SettingListItemRightAction>
              </SettingListItem>

              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.aboutPanel.opening.cutoff')} style={styles.text} />
                <SettingListItemRightAction>
                  <SettingListInput
                    value={openingState.orderBeforeMin.toString()}
                    onFocus={() => setKeyboardAvoidingViewEnabled(false)}
                    onChangeText={(text) => {
                      if (/^\d+$/.test(text) || text === '') {
                        onChangeState('orderBeforeMin', text)
                      }
                    }}
                    keyboardType='number-pad'
                    maxLength={3}
                  />
                  <SettingListInputAdornment text={t('app.page.setting.aboutPanel.opening.min')} />
                </SettingListItemRightAction>
              </SettingListItem>

              <SettingListItem divider>
                <SettingListItemText text={t('app.constant.deliveryType.table')} style={styles.text} />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={openingState.dinein}
                    onChangeValue={() => onChangeState('dinein', !openingState.dinein)}
                    size={23}
                  />

                </SettingListItemRightAction>
              </SettingListItem>

              <SettingListItem divider>
                <SettingListItemText text={t('app.constant.deliveryType.dimorderTakeaway')} style={styles.text} />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={openingState.takeaway}
                    onChangeValue={() => onChangeState('takeaway', !openingState.takeaway)}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>

              <SettingListItem divider>
                <SettingListItemText text={t('app.constant.deliveryType.dimorderDelivery')} style={styles.text} />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={openingState.storeDelivery}
                    onChangeValue={() => onChangeState('storeDelivery', !openingState.storeDelivery)}
                    size={23}
                  />

                </SettingListItemRightAction>
              </SettingListItem>

              {
                !quickMode && (
                  <SettingListItem divider>
                    <SettingListItemText text={t('app.constant.order.DineInSurcharge')} style={styles.text} />
                    <SettingListItemRightAction>
                      <SettingListInput
                        value={openingState.surcharge.toString()}
                        onFocus={() => setKeyboardAvoidingViewEnabled(true)}
                        onChangeText={(text) => {
                          if (/^\d+$/.test(text) || text === '') {
                            onChangeState('surcharge', text)
                          }
                        }}
                        keyboardType='number-pad'
                        maxLength={3}
                      />
                      <SettingListInputAdornment text='％' />
                    </SettingListItemRightAction>
                  </SettingListItem>
                )
              }
              <SettingListItem>
                <SettingListItemText text={t('app.constant.order.merchantDiscount')} style={styles.text} />
                <SettingListItemRightAction>
                  <SettingListInput
                    value={(openingState.discount ?? 0).toString()}
                    onFocus={() => setKeyboardAvoidingViewEnabled(true)}
                    onChangeText={(text) => {
                      if (/^\d+$/.test(text) || text === '') {
                        onChangeState('discount', text)
                      }
                    }}
                    keyboardType='number-pad'
                    maxLength={3}
                  />
                  <SettingListInputAdornment text='％' />
                </SettingListItemRightAction>
              </SettingListItem>
            </KeyboardAvoidingView>
          </ScrollView>
        </SettingList>
      </Column>
      <Row style={styles.buttons}>
        <Button
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          textBold
          onPress={onDelete}
          disabled={!openingState.id}
        />
        <Button
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          textBold
          onPress={onSubmit}
        />
      </Row>
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  buttons: {
    justifyContent: 'flex-end',
  },
  button: {
    marginBottom: 0,
  },
  text: {
    flex: 1,
  },
  timeText: {
    textAlign: 'right',
  },
})
