/* global __DEV__ */
import { LogBox, Platform } from 'react-native'
import { v4 as uuid } from 'uuid'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import moment from 'moment'

import { actions } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import { getCodePushVersion, getCommit } from '@/libs/versionInfo'
import { loadingKey, webSocketStatusCode } from '@/constants'
import DataStorage from '@/libs/dataStorage'
import _ from 'lodash'
import codePush from '@/libs/codePush'
import delay from '@/libs/delay'
import firebase from '@/libs/firebase'
import i18n from '@/i18n'
import logger, { convertToSimpleMenu, flush as flushLogs } from '@/libs/logger'
import orderEvent from '@/libs/orderEvent'
import printerService from '@/libs/printing/PrinterService'
import * as OrderLocalDatabase from '@/libs/orderLocalDatabase'

import { initialState } from './reducer'
import ActionTypes from './ActionTypes'

const PACKAGE_JSON = require('@root/package.json')
const IS_APP = Platform.OS !== 'web'

/**
 * App 啟動流程
 * @returns {ThunkFunction}
 */
export function initApp () {
  return async (dispatch, getState) => {
    logger.log('[initApp] Start')

    // 決定執行環境，根據環境使用 config
    await dispatch(actions.config.init())
    logger.log('[initApp][1/3] config.init() Done')
    // 若有登入，還原登入狀態
    await dispatch(actions.auth.init())
    logger.log('[initApp][2/3] auth.init() Done')

    const isMerchantLogin = getState().auth.isMerchantLogin
    if (isMerchantLogin) {
      // 若有登入 merchant，處理初始 merchant level 的流程
      await dispatch(actions.app.initData())
      logger.log('[initApp][3/3] logged-in flow Done')
    } else {
      // 還沒登入不需要 migration，直接結束
      dispatch({ type: ActionTypes.COMPLETE_CLEAR_LOCALDB_NO_LONGER_USE })
      // 未登入，檢查 APP 更新 (Default Deployment)
      await dispatch(actions.codePush.checkUpdate(true))
      logger.log('[initApp][3/3] not no-logged-in flow Done')
    }

    logger.log('[initApp] Done')
  }
}

/**
 * 登入後處理初始資料的流程
 * @returns {ThunkFunction}
 */
export function initData () {
  return async (dispatch, getState) => {
    logger.log('[initData] Start')
    dispatch(openLoading(loadingKey.INIT_DATA))

    // *** 先完成 migration 確保後面 initData 能用新的格式儲存/還原資料
    await dispatch(storageMigration())
    logger.log('[initData][1/15] storageMigration() Done')

    await dispatch(clearLocalDatabaseOrdersNoLongerUse())
    logger.log('[initData][2/15] clearLocalDatabaseOrdersNoLongerUse() Done')

    await dispatch(actions.merchant.init())
    logger.log('[initData][3/15] merchant.init() Done')

    dispatch(actions.table.init())
    logger.log('[initData][4/15] table.init() Done')

    await dispatch(actions.printer.init())
    logger.log('[initData][5/15] printer.init() Done')

    // *** 嘗試還原本地資料到 redux
    await dispatch(restoreLocalData())
    logger.log('[initData][6/15] restoreLocalData() Done')

    await dispatch(actions.setting.init())
    logger.log('[initData][7/15] setting.init() Done')

    // ? 這東西很奇怪，使用了 merchant 和 printerSetting，計算完東西後存到 app.settings
    // ? 但 app.settings 應該是存 ipad level 的設定
    // ? 只用了 merchant 和 printerSetting 應該是 merchant level 的設定
    // ? 而且他是 printer action 卻存到 app.settings
    await dispatch(actions.printer.getMerchantLogo())

    // 因為 menu 可能會抓很久
    // menu 如果在 restoreLocalData 有還原成功 isMenuInit 會是 true
    // 這樣這邊就不 await 抓資料，讓 UI 可以先用，menu 之後才會被更新
    const isMenuInit = getState().menu.isInit
    if (isMenuInit) {
      dispatch(actions.menu.init()) // ! menu 會抓比較久，所以如果已有資料就不 await
    } else {
      await dispatch(actions.menu.init())
    }
    logger.log('[initData][8/15] menu.init() Done')

    // * 因為 orderHistory.init 不 await，先 pop up storeLocalDatabase dialog
    // * 防止 user 在所有訂單準備好前更改訂單
    // * orderHistory.init 中會待第一次 getOrders 完成 upsertingOrderIntoLocalDatabase 後關閉 storeLocalDatabase dialog
    dispatch(actions.app.showDialog(['storeLocalDatabase']))
    dispatch(actions.orderHistory.init()) // ! orderHistory 雖然是 async 但不急用所以不 await

    dispatch(changeLang()) // 根據 app settings，設定顯示語言
    dispatch(updateIsSavedSetting(true)) // TODO: 待釐清，這不是 initital state 就是 true，為什麼需要在這裡設成 true?
    orderEvent.init() // TODO: 這沒必要存在，可以考慮拿掉
    logger.log('[initData][9/15] changeLang(), updateIsSavedSetting(), orderEvent.init() Done')

    // *** 設定 firebase
    firebase.init(getState().config.env)
    logger.log('[initData][10/15] firebase.init() Done')

    // *** 從 firebase device checkUpdate 或 deploymentChannel 還原到 overwriteDeployment
    await dispatch(actions.codePush.restoreDeploymentFromFirebase())
    logger.log('[initData][11/15] codePush.restoreDeploymentFromFirebase() Done')

    // *** 不管 checkDeploymentChannelFromFirebase 有沒有更改 overwriteDeployment 都要檢查更新
    await dispatch(actions.codePush.checkUpdate(true))
    logger.log('[initData][12/15] codePush.checkUpdate() Done')

    // *** 更新 device info to firebase
    await dispatch(actions.app.updateFirebaseDevice())
    logger.log('[initData][13/15] updateFirebaseDevice() Done')

    // *** 建立各種 firebase device listener
    dispatch(actions.app.startFirebaseListener())
    logger.log('[initData][14/15] startFirebaseListener() Done')

    // *** 定時檢查 App 更新和 Resume APP 檢查更新
    dispatch(actions.codePush.initUpdateTrigger())
    logger.log('[initData][15/15] codePush.initUpdateTrigger() Done')

    dispatch({ type: ActionTypes.INIT_DATA })
    dispatch(closeLoading(loadingKey.INIT_DATA))
    logger.log('[initData] Done')
  }
}

/**
 * App 關閉時需要處理的事情
 * @returns {ThunkFunction}
 */
export function destoryApp () {
  return async (dispatch, getState) => {
    // 將暫存在本地未送出的 log 都送出
    await flushLogs()

    dispatch(actions.app.stopFirebaseListener())
    dispatch(actions.codePush.removeUpdateTrigger())
  }
}

/**
 * firebase 某些欄位改變時要，App 要做出相應的動作
 * @returns {ThunkFunction}
 */
export function startFirebaseListener () {
  return async (dispatch, getState) => {
    firebase.setCheckUpdateOnValueCallback(async (deployment) => {
      const checkUpdate = await dispatch(actions.codePush.overwriteDeploymentFromFirebase(deployment))
      if (checkUpdate) {
        // overwriteDeploymentFromFirebase 會 return boolean 新的 deployment 是否有效
        // 若 deployment 有效就需要檢查更新
        dispatch(actions.codePush.checkUpdate(true))
      }
    })
    firebase.setTestPrinterOnValueCallback((id) => {
      dispatch(actions.printer.printTest(id))
    })
    firebase.setMasterOnValueCallback((isMaster) => {
      dispatch(actions.app.updateSetting(['isMaster'], isMaster))
    })
    firebase.setTerminalIDOnValueCallback((terminalID) => {
      if (!terminalID) return
      dispatch(actions.app.updateSetting(['terminalID'], terminalID))
    })
    firebase.startListener()
  }
}

/**
 * 結束 startFirebaseListener 的 listener
 * @returns {ThunkFunction}
 */
export function stopFirebaseListener () {
  return async (dispatch, getState) => {
    firebase.stopListener()
  }
}

/**
 * 更新 Device 狀態
 * @returns {ThunkFunction}
 */
export function updateFirebaseDevice () {
  return async (dispatch, getState) => {
    if (!IS_APP) return
    try {
      const codePushPackageMeta = getState().codePush.packageMeta
      await firebase.updateDevice({
        isApp: true,
        nativeAppVersion: Constants.nativeAppVersion,
        nativeBuildVersion: Constants.nativeBuildVersion,
        packageVersion: PACKAGE_JSON.version,
        commit: getCommit(codePushPackageMeta),
        version: getCodePushVersion(codePushPackageMeta),
        client: 'merchant-app',
        platforms: [Platform.OS],
        master: getState().app.settings.isMaster,
        merchantId: getState().merchant.data.id,
        terminalID: getState().app.settings.terminalID,
        deploymentChannel: getState().codePush.overwriteDeployment || getState().codePush.deployment || (__DEV__ ? '__DEV__' : 'Default'),
      })
    } catch (error) {
      console.error('updateFirebaseDevice error', error)
    }
  }
}

/**
 * 會在 codepush 檢查更新後、initData 前執行，確保後面 initData 能用新的格式儲存/還原資料
 * @returns {ThunkFunction}
 */
export function storageMigration () {
  return async (dispatch, getState) => {
    const logTitle = '[Storage Migration] [LocalDatabase/orderHistory/migrate] '
    try {
      logger.info(logTitle + 'Checking - AsyncStorage/DataStorage Migrate To Local Database')
      // await DataStorage.orderHistory.get()
      // *** 開啟 StorageMigrationDialog
      dispatch(actions.app.showDialog(['storageMigration']))

      // *** 顯示 StorageMigrationDialog 至少 2 秒
      await delay(1000)

      const oldOrders = []
      let isMigratedDataStorage = false
      let isMigratedAsyncStorage = false

      // [Step A] 中期版本 DataStorage Migrate to local database版本
      const getOrdersDataStorage = async () => {
        const orders = []
        const dataStorageOrdersJson = await DataStorage.orderHistory.get()
        if (!_.isEmpty(dataStorageOrdersJson)) {
          const dataStorageOrders = JSON.parse(dataStorageOrdersJson)?.orders
          if (dataStorageOrders) orders.push(...dataStorageOrders)
          isMigratedDataStorage = true
        }
        return orders
      }
      // [Step B] 最早版本 AsyncStorage Migrate to local database版本
      const getOrdersAsyncStorage = async () => {
        const orders = []
        const asyncStorageOrdersJson = await AsyncStorage.getItem('orderHistory')
        if (!_.isEmpty(asyncStorageOrdersJson)) {
          const asyncStorageOrders = JSON.parse(asyncStorageOrdersJson)?.orders
          if (asyncStorageOrders) orders.push(...asyncStorageOrders)
          isMigratedAsyncStorage = true
        }
        return orders
      }

      // 從中期版本 DataStorage 拿出訂單
      oldOrders.push(...await getOrdersDataStorage())
      // 如沒有訂單，就從最早版本 AsyncStorage 拿出訂單
      if (!oldOrders.length) {
        oldOrders.push(...await getOrdersAsyncStorage())
      }

      // * 舊版本訂單塞進 localDatabase
      if (oldOrders.length > 0) {
        for (const order of oldOrders) await OrderLocalDatabase.upsertOrder(order)
      }

      // * 清理舊版本 storage
      if (isMigratedDataStorage) await DataStorage.orderHistory.clear()
      if (isMigratedAsyncStorage) await AsyncStorage.removeItem('orderHistory')

      // * 處理 Migration 成完
      logger.info(logTitle + 'Migrate To Local Database ---  Finished Successfully!!', { isMigratedDataStorage, isMigratedAsyncStorage })

      // * 完成 Migration 並關閉 StorageMigrationDialog
      dispatch(actions.app.closeDialog(['storageMigration']))
    } catch (error) {
      logger.error(logTitle + `Checking - error: ${error?.message || error.toString()}`, { error })
    }
  }
}

/**
 * 從 Local Database清理不會再用的orders
 * @returns {ThunkFunction}
 */
export function clearLocalDatabaseOrdersNoLongerUse () {
  return async (dispatch, getState) => {
    const logTitle = '[LocalDatabase] [LocalDatabase/orderHistory/ClearOrdersNoLongerUse]'
    try {
      logger.info(logTitle + ' - Clear All Expired Orders from Local Database')

      // *** 開啟 StorageDialog
      dispatch(actions.app.showDialog(['clearLocalDatabase']))
      // *** 顯示 StorageDialog 至少 2 秒
      await delay(1000)

      // *** 處理 清理Database 不會再用的orders
      const unsyncOrderIdsJson = await AsyncStorage.getItem('unsyncOrderIds')
      let restoreUnsyncOrderIds = []
      if (unsyncOrderIdsJson) { restoreUnsyncOrderIds = JSON.parse(unsyncOrderIdsJson) }
      await OrderLocalDatabase.clearOrdersNoLongerUse(restoreUnsyncOrderIds)
      logger.info(logTitle + 'Clear Orders that No Longer Use --- Successfully!!')

      // *** 完成 清理Database 並關閉 StorageDialog
      dispatch(actions.app.closeDialog(['clearLocalDatabase']))
      dispatch({ type: ActionTypes.COMPLETE_CLEAR_LOCALDB_NO_LONGER_USE })
    } catch (error) {
      logger.error(logTitle + ` - error: ${error?.message || error.toString()}`, { error })
    }
  }
}

/**
 * @param {IAlertConfig} alertConfig
 * @param {string} [id]
 * @returns {ThunkFunction}
 */
export const showAlert = (alertConfig, id) => {
  return {
    type: ActionTypes.SHOW_ALERT,
    payload: {
      id: id || uuid(),
      alertConfig,
    },
  }
}

/**
 * @param {ISnackbarConfig} config
 * @returns {ThunkFunction}
 */
export function enqueueSnackbar (config) {
  return (dispatch, getState) => {
    const id = config.id ?? uuid()
    dispatch({
      type: ActionTypes.ENQUEUE_SNACKBAR,
      payload: {
        config: {
          id,
          ...config,
          show: true,
        },
      },
    })

    return id
  }
}

/**
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function closeSnackbar (id) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.CLOSE_SNACKBAR,
      payload: { id },
    })
  }
}

/**
 * @param {string} title
 * @param {string} message
 * @returns {ThunkFunction}
 */
export const showSimpleAlert = (title, message) => {
  return {
    type: ActionTypes.SHOW_ALERT,
    payload: {
      id: uuid(),
      alertConfig: {
        title,
        message,
      },
    },
  }
}

/**
 * @param {string} id
 * @returns {ThunkFunction}
 */
export const closeAlert = (id) => {
  return {
    type: ActionTypes.CLOSE_ALERT,
    payload: { id },
  }
}

/**
 * @param {string | string[]} path
 * @returns {ThunkFunction}
 */
export const showDialog = (path) => {
  return {
    type: ActionTypes.SHOW_DIALOG,
    payload: { path },
  }
}

/**
 * @param {string | string[]} path
 * @returns {ThunkFunction}
 */
export const closeDialog = (path) => {
  return {
    type: ActionTypes.CLOSE_DIALOG,
    payload: { path },
  }
}

export const isDialogOpened = (status) => {
  return {
    type: ActionTypes.IS_DIALOG_OPENED,
    payload: { status },
  }
}

/**
 * @param {Array} nextActions
 * @returns {ThunkFunction}
 */
export const updateNeededPermission = (permission) => {
  return {
    type: ActionTypes.UPDATE_NEEDED_PERMISSION,
    payload: { permission },
  }
}

/**
 * 日期時間彈窗
 * @param {string} mode
 * @param {object} value
 * @param {function} setValue
 */
export const showDateTimePicker = (mode, value, setValue, fromDate = null, toDate = null, minuteInterval = 15, onClose = () => { }) => {
  console.log('minuteInterval', minuteInterval)
  const dateTimeDialog = { visible: true, mode, value, setValue, fromDate, toDate, minuteInterval, onClose }
  return {
    type: ActionTypes.SHOW_DATE_TIME_DIALOG,
    payload: { dateTimeDialog },
  }
}

export const closeDateTimePicker = () => {
  return { type: ActionTypes.CLOSE_DATE_TIME_DIALOG }
}

/**
 *
 * @param {boolean} enableDebugMode
 * @returns {ThunkFunction}
 */
export function updateDebugMode (enableDebugMode) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_DEBUG_MODE,
      payload: { enableDebugMode },
    })

    if (!enableDebugMode) {
      // 關閉 debug mode 時將全部 debug settings 還原呈初始狀態
      dispatch({ type: ActionTypes.RESET_DEBUG_SETTINGS })

      // 關閉 debug mode 清除本地的測試資料並重啟，避免測試資料被上傳
      await AsyncStorage.removeItem('unsyncOrderIds')
      codePush.restartApp()
    }
  }
}

/**
 * @param {keyof IAppDebugSettings} key
 * @param {boolean} value
 * @returns {ThunkFunction}
 */
export function updateDebugSetting (key, value) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_DEBUG_SETTING,
      payload: { key, value },
    })

    if (key === 'disableOrderSync' && value === false) {
      // 關閉了停用 syncData，需要清除 storage 的測試資料並重新啟動，避免測試資料被上傳
      await AsyncStorage.removeItem('unsyncOrderIds')
      codePush.restartApp()
    }

    if (key === 'disablePrinterInit') {
      // 更改了 init 打印機的 flag，需要重啟才會套用
      codePush.restartApp()
    }
  }
}

/**
 * @param {Array} path
 * @returns {ThunkFunction}
 */
export function updateSetting (path, value) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_SETTING,
        payload: { path, value },
      })
      const settings = getState().app.settings
      const settingsJSON = JSON.stringify(settings)
      await AsyncStorage.setItem('settings', settingsJSON)
      if (path[0] === 'lang') {
        dispatch(changeLang())
      }
      if (path[0] === 'isMaster') {
        dispatch(actions.app.updateDevice())
      }
      if (path[0] === 'terminalID') {
        firebase.updateTerminalID(value)
        dispatch(actions.app.updateDevice())
      }
    } catch (error) {
      logger.error(`updateSetting error: ${error?.message || error.toString()}`, { error, params: { path, value } })
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function restoreSettings () {
  return async (dispatch, getState) => {
    try {
      const settingJSON = await AsyncStorage.getItem('settings')
      if (settingJSON) {
        const storageSettings = JSON.parse(settingJSON)

        // 避免有新的設定 storageSettings 還沒有，將兩邊 merge 後重新存回 AsyncStorage
        const settings = { ...initialState.settings, ...storageSettings }
        await AsyncStorage.setItem('settings', JSON.stringify(settings))

        // 本地執行 Debug 時可以根據 settings 把 LogBox 等功能關閉
        if (__DEV__ && LogBox) {
          // reportErrorsAsExceptions 會把 console.error 變成 exception 顯示紅色 LogBox
          console.reportErrorsAsExceptions = !settings.debugSettings.hideConsoleErrorsAsExceptions
          // LogBox.ignoreAllLog 傳入 true 的話所有 LogBox 都不會顯示
          LogBox.ignoreAllLogs(settings.debugSettings.hideLogBox)
        }

        await dispatch({
          type: ActionTypes.RESTORE_SETTINGS,
          payload: { settings },
        })
      }
      const terminalID = getState().app.settings.terminalID
      if (terminalID === '') {
        const random = Math.floor(Math.random() * (9 - 1 + 1) + 1).toString()
        dispatch(actions.app.updateSetting(['terminalID'], random))
      }
    } catch (error) {
      logger.error(`[restoreSettings] error: ${error?.message || error.toString()}`, { error })
    }
  }
}

export const restoreLocalData = () => {
  return async (dispatch, getState) => {
    if (Platform.OS === 'web') return

    await dispatch(restoreSettings())

    // initData 前面會先跟後端抓資料，如果抓成功就不用從本地還原了
    const isMerchantInit = getState().merchant.isInit
    if (!isMerchantInit) {
      try {
        const merchatJson = await AsyncStorage.getItem('merchant')
        if (merchatJson) {
          const merchantFromStorage = JSON.parse(merchatJson)
          dispatch(actions.merchant.restore(merchantFromStorage))
          // 將 merchant 中的桌位資料設定到 table
          dispatch(actions.table.init())
        }
      } catch (error) {
        logger.error(`[restoreLocalData] "merchant" error: ${error?.message || error.toString()}`, { error })
      }
    }

    // initData 前面會先跟後端抓資料，如果抓成功就不用從本地還原了
    const isPrinterInit = getState().printer.isInit
    if (!isPrinterInit) {
      try {
        const printerSettingJson = await AsyncStorage.getItem('printerSetting')
        if (printerSettingJson) {
          const printerSettingFromStorage = JSON.parse(printerSettingJson)
          dispatch(actions.printer.restorePrinterSetting(printerSettingFromStorage))
        }
      } catch (error) {
        logger.error(`[restoreLocalData] "printerSetting" error: ${error?.message || error.toString()}`, { error })
      }
    }

    // menu 一律先用本地資料，之後在 initData 慢慢從後端抓新的
    try {
      const menuJson = await AsyncStorage.getItem('menu')
      if (menuJson) {
        const menuFromStorage = JSON.parse(menuJson)
        dispatch(actions.menu.restore(menuFromStorage))
      }
    } catch (error) {
      logger.error(`[restoreLocalData] "menu" error: ${error?.message || error.toString()}`, { error })
    }

    await dispatch(actions.orderHistory.restoreOrders())
    await dispatch(actions.unsyncOrder.restoreUnsyncOrderIds())
    await dispatch(actions.orderBatch.restoreStashes())

    try {
      const printinghashLocalStorageJSON = await AsyncStorage.getItem('PRINTINGHASH')
      if (printinghashLocalStorageJSON) {
        const printinghashLocalStorage = JSON.parse(printinghashLocalStorageJSON)
        printerService.setPrintingCache(printinghashLocalStorage)
      }
    } catch (error) {
      logger.error(`[restoreLocalData] "PRINTINGHASH" error: ${error?.message || error.toString()}`, { error })
    }

    try {
      const printhashLocalStorageJSON = await AsyncStorage.getItem('PRINTHASH')
      if (printhashLocalStorageJSON) {
        const printhashLocalStorage = JSON.parse(printhashLocalStorageJSON)
        printerService.setPrintCache(printhashLocalStorage)
      }
    } catch (error) {
      logger.error(`[restoreLocalData] "PRINTHASH" error: ${error?.message || error.toString()}`, { error })
    }
  }
}

export const changeLang = () => {
  return (dispatch, getState) => {
    const language = getState().app.settings.lang
    if (!language) dispatch(actions.app.updateSetting(['lang'], 'zh-HK'))
    const lang = language ?? 'zh-HK'
    i18n.changeLanguage(lang)
    const apiLanguage = lang.slice(0, 2)
    dimorderApi.setLanguage(apiLanguage)
  }
}

/**
 * @returns {ThunkFunction}
 */
export const setScopeLogin = (scopeLogin) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SET_SCOPE_LOGIN,
      payload: { scopeLogin: scopeLogin },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export const openLoading = (key, remark = '') => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_LOADING,
      payload: { key, remark, remove: false },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export const closeLoading = (key) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_LOADING,
      payload: { key, remark: '', remove: true },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export const increaseWebsocketRetryCount = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.INCREASE_WEBSOCKET_RETRY_COUNT,
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export const resetWebsocketRetryCount = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_WEBSOCKET_RETRY_COUNT,
    })
  }
}

/**
 * @param {NetInfo} newNetInfo
 * @returns {ThunkFunction}
 */
export const updateNetInfo = (newNetInfo) => {
  return async (dispatch, getState) => {
    const prevNetInfo = getState().app.netInfo
    const netInfo = { ...prevNetInfo, ...newNetInfo }
    dispatch({
      type: ActionTypes.UPDATE_NET_INFO,
      payload: { netInfo },
    })
    firebase.updateNetInfo(netInfo)
    if (prevNetInfo?.isInternetReachable === false && newNetInfo?.isInternetReachable === true) {
      // 原本連不到 api 現在有了，再拿一次訂單補資料
      dispatch(actions.orderHistory.getOrders('updateNetInfo'))
      // 更新這台機器最後活著的時間
      dispatch(actions.app.updateTTL())
    } else if (prevNetInfo?.isInternetReachable === true && newNetInfo?.isInternetReachable === false) {
      // 原本對 api 有連線，後來斷線了，處理 websocket 斷線改狀態、重連等
      dispatch(actions.app.updateWebSocketStatus(webSocketStatusCode.CLOSED))
      dispatch(actions.orderHistory.handleWebSocketError())
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export const updateShowWebSockectWarningAt = () => {
  return async (dispatch, getState) => {
    const now = moment()
    dispatch({
      type: ActionTypes.UPDATE_SHOW_WEB_SOCKET_WARNING_AT,
      payload: { now },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export const updateWebSocketStatus = (status) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_WEBSOCKET_STATUS,
      payload: { status },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export const updateWebSocketReady = (isReady) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_WEBSOCKET_READY,
      payload: { isReady },
    })
  }
}

export const updateIsSavedSetting = (isSavedSetting) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_IS_SAVED_SETTING,
      payload: { isSavedSetting },
    })
  }
}

export const increaseSerial = () => {
  return async (dispatch, getState) => {
    const { serialIndex } = getState().app.settings
    await dispatch({
      type: ActionTypes.INCREASE_SERIAL_INDEX,
      payload: { serialIndex },
    })
    const settings = getState().app.settings
    const settingsJSON = JSON.stringify(settings)
    await AsyncStorage.setItem('settings', settingsJSON)
  }
}
export const errorHandle = (error) => {
  return async (dispatch, getState) => {
    logger.error('[API Failed]', { error })
    // const notShowErrorUrl = ['/m/orderimport']
    // if (error.message === 'Network Error' && !notShowErrorUrl.includes(error.config.url)) {
    //   dispatch(actions.app.showAlert({ title: i18n.t('app.common.error'), message: i18n.t('app.common.networkError') }))
    // }
  }
}

export const updateTTL = () => {
  return async (dispatch, getState) => {
    const deviceId = Constants.installationId

    await dimorderApi.device.updateTTL(deviceId)
  }
}

export const updateDevice = () => {
  return async (dispatch, getState) => {
    const deviceId = Constants.installationId
    const isMaster = getState().app.settings.isMaster
    const terminalId = getState().app.settings.terminalID
    const codePushPackageMeta = getState().codePush.packageMeta
    const device = {
      isApp: IS_APP,
      version: getCodePushVersion(codePushPackageMeta),
      client: 'merchant-app',
      platforms: [Platform.OS],
      isMaster,
      terminalId: terminalId,
      deploymentChannel: getState().codePush.overwriteDeployment || getState().codePush.deployment || (__DEV__ ? '__DEV__' : 'Default'),
      status: 'online',
    }
    await dimorderApi.device.updateDevice(deviceId, device)
  }
}

/**
 * 有大量訂單加入DB，且用lazy queue 的方式時使用
 * @param {number} number
 * @return
 */
export function throttledLazyQueuingNumber (number) {
  return (dispatch) => {
    throttledLazyQueuingNumberThunk(dispatch, number)
  }
}

// 每0.5秒更新一次lazy queue number
export const throttledLazyQueuingNumberThunk = _.throttle((dispatch, number) => updateLazyQueuingNumber(dispatch, number), 500)

/**
 * 更新正在排隊寫入DB的lazy queue number，以作UI顯示的
 * @param {*} dispatch
 * @param {number} number
 */
export function updateLazyQueuingNumber (dispatch, number) {
  dispatch({
    type: ActionTypes.UPDATE_LAZY_QUEUING_NUMBER,
    payload: { number: number },
  },
  )
}

/**
 *
 * @param {string} message
 * @returns
 */
export function throttledInitQueuingMessage (message) {
  return (dispatch) => {
    throttledInitQueuingMessageThunk(dispatch, message)
  }
}

// 每0.5秒更新一次queue message
export const throttledInitQueuingMessageThunk = _.throttle((dispatch, message) => updateInitQueuingMessage(dispatch, message), 500)

/**
 * 更新正在排隊寫入DB的queueing message 以作UI顯示的
 * @param {*} dispatch
 * @param {string} message
 */
export const updateInitQueuingMessage = (dispatch, message) => {
  dispatch({
    type: ActionTypes.UPDATE_INIT_QUEUING_MESSAGE,
    payload: { message: message },
  })
}
