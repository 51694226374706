import { ScrollView, StyleSheet, View } from 'react-native'
import { actions, useDispatch } from '@/redux'
import { useCategoriesPaths } from '@/hooks/category'
import { useTranslation } from 'react-i18next'
import CategoryButton from '../CategoryBar/CategoryButton'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'

/**
 * @param {{lastOnly: boolean}} props 只顯示路徑中最後一個分類
 */
export default function Breadcrumb (props) {
  const { lastOnly } = props
  const dispatch = useDispatch()
  const paths = useCategoriesPaths()
  const { t } = useTranslation()
  const lastCategory = _.last(paths)
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const lastCategoryName = lastCategory?.id === 'ROOT'
    ? t('app.constant.menu.root')
    : (
      lastCategory?.id === 'SET_ROOT'
        ? t('app.constant.menu.set')
        : _.get(lastCategory?.localeNames, lang) ?? lastCategory.name
    )

  return (
    <View style={styles.container}>
      {lastOnly && (
        <CategoryButton
          variant='breadcrumb'
          selected
          color={colors.primary}
          text={lastCategoryName}
          onPress={() => {
            dispatch(actions.menu.selectCategory(lastCategory))
            dispatch(actions.menu.resetSearchInput())
          }}
        />
      )}

      {!lastOnly && (
        <ScrollView horizontal>
          {paths.map((category, index) => {
            const isLast = index === paths.length - 1
            const categoryName = category?.id === 'ROOT'
              ? t('app.constant.menu.root')
              : (
                category?.id === 'SET_ROOT'
                  ? t('app.constant.menu.set')
                  : _.get(category?.localeNames, lang) ?? category.name
              )
            return (
              <CategoryButton
                variant='breadcrumb'
                key={category.id}
                text={categoryName}
                selected={category.id !== 'ROOT' && isLast}
                onPress={() => {
                  if (!isLast) {
                    dispatch(actions.menu.selectCategory(category))
                    dispatch(actions.menu.resetSearchInput())
                  }
                }}
              />
            )
          })}
        </ScrollView>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.primary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 35,
    borderRadius: 17.5,
    margin: 8,
  },
})
