import _set from 'lodash/set'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {ITableState} */
export const initialState = {
  tables: [],
  tableAreas: [],
  floorPlanAreas: [],
  groupTables: [],
  bookings: [],
  selectedTableId: null,
  selectedTableAreaId: null,
  selectedBookingId: null,
  selectedOrders: [],
  isSelecting: false,
  isMoving: false,
  isSearching: false,
  searchText: '',
  isOverviewExpanded: false,
  cancelId: '',
  isSelectingTable: false,
  dialog: {
    dateTimePicker: false,
    dateTimeConfig: { mode: 'date', value: null, setValue: null },
    newBooking: false,
    booking: false,
    table: false,
    newOrder: false,
    cancel: false,
    openingPrompt: false,
  },
}

export default produce(
  /**
   * @param {ITableState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.UPDATE_TABLE_AREAS: {
        draft.tableAreas = action.payload.tableAreas
        break
      }
      case ActionTypes.UPDATE_FLOORPLAN_AREAS: {
        draft.floorPlanAreas = action.payload.floorPlanAreas
        break
      }
      case ActionTypes.UPDATE_TABLES: {
        draft.tables = action.payload.tables
        break
      }
      case ActionTypes.UPDATE_GROUP_TABLES: {
        draft.groupTables = action.payload.tables
        break
      }
      case ActionTypes.UPDATE_TABLE: {
        const { table } = action.payload
        const index = draft.tables.findIndex(t => t.id === table.id)
        if (index >= 0) {
          draft.tables[index] = table
        } else {
          draft.tables.push(table)
        }
        break
      }
      case ActionTypes.UPDATE_BOOKING: {
        const { booking } = action.payload
        const index = draft.bookings.findIndex(b => b.id === booking.id)
        if (index >= 0) {
          draft.bookings[index] = booking
        } else {
          draft.bookings.push(booking)
        }
        break
      }
      case ActionTypes.DELETE_BOOKING: {
        const { bookingId } = action.payload
        draft.bookings = draft.bookings.filter(b => b.id !== bookingId)
        break
      }
      case ActionTypes.SELECT_TABLE_AREA: {
        draft.selectedTableAreaId = action.payload.tableAreaId
        break
      }
      case ActionTypes.SELECT_TABLE: {
        draft.selectedTableId = action.payload.tableId
        break
      }
      case ActionTypes.SELECT_BOOKING: {
        draft.selectedBookingId = action.payload.bookingId
        break
      }
      case ActionTypes.SELECT_ORDER: {
        const { order } = action.payload
        draft.selectedOrders = [...draft.selectedOrders, order]
        break
      }
      case ActionTypes.DESELECT_ORDER: {
        const { order } = action.payload
        draft.selectedOrders = draft.selectedOrders.filter(selectedOrder => selectedOrder.id !== order.id)
        break
      }
      case ActionTypes.UPDATE_SELECTING: {
        draft.isSelecting = action.payload.isSelecting
        draft.selectedOrders = []
        break
      }
      case ActionTypes.UPDATE_SELECTING_TABLE: {
        draft.isSelectingTable = action.payload.isSelectingTable
        break
      }
      case ActionTypes.UPDATE_MOVING: {
        draft.isMoving = action.payload.isMoving
        break
      }
      case ActionTypes.UPDATE_SEARCHING: {
        draft.isSearching = action.payload.isSearching
        draft.isOverviewExpanded = false
        break
      }
      case ActionTypes.UPDATE_SEARCH_TEXT: {
        draft.searchText = action.payload.searchText
        break
      }
      case ActionTypes.UPDATE_OVERVIEW_EXPAND: {
        draft.isOverviewExpanded = action.payload.isOverviewExpanded
        draft.isSearching = false
        break
      }
      case ActionTypes.SHOW_DIALOG: {
        const { path } = action.payload
        _set(draft.dialog, path, true)
        break
      }
      case ActionTypes.CLOSE_DIALOG: {
        const { path } = action.payload
        _set(draft.dialog, path, false)
        break
      }
      case ActionTypes.RESET_ALL_ACTIONS: {
        return {
          ...initialState,
          tables: draft.tables,
          groupTables: draft.groupTables,
          tableAreas: draft.tableAreas,
          floorPlanAreas: draft.floorPlanAreas,
          selectedTableAreaId: draft.selectedTableAreaId || draft.tableAreas[0]?.id,
        }
      }
      case ActionTypes.SET_CANCEL_ID: {
        const { id } = action.payload
        draft.cancelId = id
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
