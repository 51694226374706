import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import CancelledItemSummaryRow from './CancelledItemSummaryRow'
import SubTypeList from './SubTypeList'

/**
 * @typedef CancelInfoProps
 * @property {CancelInfoData} data
 * @typedef CancelInfoData
 * @property {IStatCancelledOrder[]} order
 * @property {IStatCategory} category
 */

/**
 *
 * @param {CancelInfoProps} props
 * @returns
 */
export default function CancelInfo ({ data = {} }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const serialText = t('app.page.stats.cancel.serial')
  const userText = `${t('app.page.stats.cancel.identifier')}/${t('app.page.stats.cancel.approver')}`
  const reasonText = t('app.page.stats.cancel.reason')
  const itemText = t('app.page.stats.cancel.cancelledItem')
  const reportText = t('app.page.stats.cancel.report')
  const cancelItemText = t('app.page.stats.cancel.item')
  const cancelCountText = t('app.page.stats.cancel.count')
  const cancelOrderText = t('app.page.stats.cancel.order')
  const statusText = t('app.page.stats.cancel.status')

  const selectedSubType = useSelector(state => state.statistics.selectedSubType)

  const options = [
    { key: 'items', text: cancelItemText },
    { key: 'orders', text: cancelOrderText },
  ]

  const categoryTotals = Object.values(data.category.categoryTotals).map(total => {
    const menuTotals = Object.values(total.menuTotals)
    return { ...total, menuTotals }
  })

  const mapCancelKey = (key) => {
    switch (key) {
      case 'invalid': {
        return t('app.component.cancelReason.invalid')
      }
      case 'trial': {
        return t('app.component.cancelReason.trial')
      }
      case 'rejected': {
        return t('app.component.cancelReason.rejected')
      }
      case 'unavailable': {
        return t('app.component.cancelReason.unavailable')
      }
      case 'busy': {
        return t('app.component.cancelReason.busy')
      }
      case 'closed': {
        return t('app.component.cancelReason.closed')
      }
      default: {
        return key
      }
    }
  }

  const paymentStatusKey = (key) => {
    switch (key) {
      case 'paid': {
        return t('app.component.paymentStatus.paid')
      }
      case 'unpaid': {
        return t('app.component.paymentStatus.unpaid')
      }
      default: {
        return key
      }
    }
  }

  const renderItem = ({ item, index }) => {
    return (
      <>
        <CancelledItemSummaryRow
          title={item.name}
          count=''
          serial=''
          staff=''
        />
        <FlatList
          data={item.menuTotals}
          renderItem={renderMenuItem}
          keyExtractor={(item, index) => String(item.name + index)}
          showsVerticalScrollIndicator={false}
        />
      </>
    )
  }

  const renderMenuItem = ({ item }) => {
    const orders = item.cancelIdentifierOrders
      ? item.cancelIdentifierOrders.map(order => {
        return { ...order, name: item?.name }
      })
      : Object.values(item.orders)?.map(order => {
        return { ...order, name: item?.name }
      })
    return (
      <FlatList
        data={orders}
        renderItem={renderCancelledItem}
        keyExtractor={(item, index) => String(item.name + index)}
        showsVerticalScrollIndicator={false}
      />
    )
  }

  const renderCancelledItem = ({ item }) => {
    const user = `${item.staff || '-'}/${item.approver || '-'}`
    return (
      <CancelledItemSummaryRow
        title={`    ${item.name}`}
        serial={item.serial}
        count={item.quantity}
        staff={user}
      />
    )
  }

  const renderCancel = ({ item }) => {
    const user = `${item.cancelledBy || '-'}/${item.cancelApprover || '-'}`
    return (
      <CancelledItemSummaryRow
        title={mapCancelKey(item.cancelReason)}
        count={paymentStatusKey(item.paymentStatus)}
        serial={item.orderNumber}
        staff={user}
      />
    )
  }

  if (_.isEmpty(data)) {
    return null
  }

  return (
    <View style={styles.container}>
      <SubTypeList
        options={options}
        selectedItem={selectedSubType}
        onSelect={(key) => dispatch(actions.statistics.selectSubType(key))}
      />
      <View style={styles.contentContainer}>
        <View style={styles.content}>
          <View style={{ flex: 1 }}>
            <View style={{ height: 25, marginTop: 30 }}>
              <Text style={styles.title}>{selectedSubType === 'items' ? itemText : reportText}</Text>
              <View style={styles.divider} />
            </View>
            {
              selectedSubType === 'items' && (
                <>
                  <CancelledItemSummaryRow
                    title={cancelItemText}
                    count={cancelCountText}
                    serial={serialText}
                    staff={userText}
                  />
                  <FlatList
                    data={categoryTotals}
                    renderItem={renderItem}
                    keyExtractor={(item, index) => String(item.name + index)}
                    showsVerticalScrollIndicator={false}
                  />
                </>
              )
            }
            {
              selectedSubType === 'orders' && (
                <>
                  <CancelledItemSummaryRow
                    title={reasonText}
                    count={statusText}
                    serial={serialText}
                    staff={userText}
                  />
                  <FlatList
                    data={_.get(data, 'order', [])}
                    renderItem={renderCancel}
                    keyExtractor={(item, index) => String(item + index)}
                    showsVerticalScrollIndicator={false}
                  />
                </>
              )
            }
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
    minWidth: 600,
    maxWidth: 600,
    flex: 1,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})
