import { StyleSheet } from 'react-native'
import React from 'react'
import Row from '@/components/Row'
import StatTypeList from './StatTypeList'
import Summary from './Summary'
import colors from '@/theme/colors'

export default function NewSummary (props) {
  return (
    <Row style={styles.container}>
      <StatTypeList />
      <Summary />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.light,
    width: '100%',
    flex: 1,
  },
})
