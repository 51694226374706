import { useSelector } from '@/redux'

// eslint-disable-next-line no-unused-vars
import { IAppBatchItem, IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @returns {IAppOrder?}
 */
export function useSelectedOrder () {
  const selectedOrder = useSelector(state => state.order.selectedOrder)
  return selectedOrder
}

/**
 * @returns {IAppOrderBatch}
 */
export function useSelectedBatch () {
  return useSelector(state => state.orderBatch.selectedBatch)
}

/**
 * @returns {[IAppBatchItem, IAppBatchItem]}
 */
export function useSelectedBatchItem () {
  const selectedBatch = useSelectedBatch()
  const selectedItemKey = useSelector(state => state.orderBatch.selectedItemKey)
  const selectedSetItemKey = useSelector(state => state.orderBatch.selectedSetItemKey)

  const selectedItem = selectedBatch?.items.find(item => item.key === selectedItemKey)
  const selectedSetItem = selectedItem?.setItems?.find(setItem => setItem.key === selectedSetItemKey)

  return [selectedItem, selectedSetItem]
}

/**
 * @returns {(number | null)}
 */
export function useSelectedSetMenuIndex () {
  const selectedBatch = useSelectedBatch()
  const selectedItemKey = useSelector(state => state.orderBatch.selectedItemKey)
  const selectedSetItemKey = useSelector(state => state.orderBatch.selectedSetItemKey)

  const selectedItem = selectedBatch?.items.find(item => item.key === selectedItemKey)
  const selectedSetItem = selectedItem?.setItems?.find(setItem => setItem.key === selectedSetItemKey)

  return selectedSetItem?.setMenuIndex ?? selectedSetItemKey?.setMenuIndex ?? null
}
