import { Icon } from 'react-native-elements'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import Button from '@/components/buttons/Button'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import Row from '@/components/Row'

/**
 * @typedef HeaderProps
 * @property {() => void} setOpenFilter
 */

/**
 *
 * @param {HeaderProps} props
 * @returns
 */
export default function Header (props) {
  const { setOpenFilter } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const datetime = useSelector(state => state.statistics.datetime)
  const selectedStatType = useSelector(state => state.statistics.selectedStatType)
  const isInit = useSelector(state => state.statistics.isInit)
  const fromRange = {
    min: moment().add(-1, 'year').toDate(),
    max: moment().startOf('hour').toDate(),
  }
  const toDateRange = {
    min: moment().startOf('day').toDate(),
    max: moment().add(1, 'day').startOf('day').toDate(),
  }
  const [fromDateTime, setFromDateTime] = useState(datetime?.from ?? moment().startOf('day').toDate())
  const [toDateTime, setToDateTime] = useState(datetime?.to ?? moment().startOf('hour').toDate())
  const [toRange, setToRange] = useState(toDateRange)
  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)

  const { selectPrinterBeforePrint } = useSelector(state => state.app.settings)
  React.useEffect(() => {
    const from = moment(fromDateTime).add(1, 'h')
    const to = moment(fromDateTime).add(32, 'day').isBefore(moment()) ? moment(fromDateTime).add(32, 'day') : moment().add(1, 'day').startOf('day')
    setToDateTime(to.startOf('hour').toDate())
    setToRange({
      min: from.toDate(),
      max: to.toDate(),
    })
  }, [fromDateTime])

  const reset = () => {
    setFromDateTime(moment().startOf('day').toDate())
  }

  const submit = async () => {
    if (moment(fromDateTime).isSameOrAfter(moment(toDateTime))) return
    dispatch(actions.statistics.getData(fromDateTime, toDateTime))
  }

  const print = (selectedPrinterId) => {
    dispatch(actions.printer.printReport(selectedPrinterId))
  }

  return (
    <Row style={styles.container}>
      <View style={styles.leftContainer}>
        <Text style={styles.titleText}>{t('app.page.stats.header.title')}</Text>
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => {
            dispatch(actions.app.showDateTimePicker('datetime', fromDateTime, setFromDateTime, fromRange.min, fromRange.max))
          }}
        >
          <Text style={styles.dateText}>
            {moment(fromDateTime).format('YYYY/MM/DD HH:mm')}
          </Text>
        </TouchableOpacity>
        <Text style={styles.dateText}>-</Text>
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => {
            dispatch(actions.app.showDateTimePicker('datetime', toDateTime, setToDateTime, toRange.min, toRange.max))
          }}
        >
          <Text style={styles.dateText}>
            {moment(toDateTime).format('YYYY/MM/DD HH:mm')}
          </Text>
        </TouchableOpacity>
        {
          (selectedStatType === 'category' && isInit) && (
            <TouchableOpacity
              style={styles.icon}
              onPress={setOpenFilter}
            >
              <Icon
                size={30}
                name='filter-alt'
                type='material'
                color={colors.primary}
              />
            </TouchableOpacity>
          )
        }
      </View>
      <View style={styles.rightContainer}>
        <Button
          title={t('app.page.stats.header.reset')}
          style={styles.button}
          backgroundColor={colors.primary}
          textBold
          onPress={reset}
        />
        <Button
          title={t('app.page.stats.header.submit')}
          style={styles.button}
          backgroundColor={colors.primary}
          textBold
          onPress={submit}
        />
        <Button
          title={t('app.page.stats.header.print')}
          style={styles.button}
          backgroundColor={colors.primary}
          textBold
          onPress={() => {
            if (selectPrinterBeforePrint) {
              setPrinterDialogOpen(true)
            } else {
              print()
            }
          }}
        />
      </View>
      <PrinterDialog
        open={printerDialogOpen}
        onClose={() => setPrinterDialogOpen(false)}
        onSelect={(selectedPrinterId) => {
          print(selectedPrinterId)
        }}
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 75,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.lightPrimary,
    borderBottomRightRadius: 32,
    paddingLeft: 30,
    paddingRight: 20,
    zIndex: 4,
    elevation: 4,
    ...shadows.default,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateText: {
    fontSize: 16,
    lineHeight: 30,
    fontWeight: 'bold',
    color: colors.white,
    marginRight: 12,
  },
  titleText: {
    fontSize: 20,
    lineHeight: 30,
    fontWeight: 'bold',
    color: colors.white,
    marginRight: 12,
  },
  button: {
    width: 100,
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 8,
    paddingHorizontal: 8,
    marginRight: 12,
  },
  icon: {
    height: 35,
    width: 35,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 35 / 2,
  },
})
