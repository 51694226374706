import { Animated, StyleSheet } from 'react-native'
import React from 'react'

/**
 * @typedef AnimatedBackgroundProps
 * @property {Animated.Value} animationValue
 */

/**
 *
 * @param {AnimatedBackgroundProps} props
 * @returns
 */
export default function AnimatedBackground (props) {
  const { animationValue, style } = props

  const bgScale = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 1.3],
  })

  const bgTranslatX = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -77],
  })
  return (
    <Animated.Image
      source={require('@images/login/pexels-fauxels-3184183.png')}
      style={[style, styles.container, { transform: [{ translateX: bgTranslatX }, { scale: bgScale }] }]}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    resizeMode: 'cover',
  },
})
