import { Icon } from 'react-native-elements'
import { StyleSheet, Text, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Row from '@/components/Row'
import SearchBar from '@/components/SearchBar'
import TabButton from './TabButton'
import colors from '@/theme/colors'

export default function TabBar ({ items, location }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const search = useSelector(state => state.setting.searchText)
  const user = useSelector(state => state.auth.userLogin)
  const [searchText, setSearchText] = React.useState(search)
  React.useEffect(() => {
    setSearchText(search)
  }, [search])

  items.map((item) => {
    switch (item.key) {
      case 'local' : {
        item.text = t('app.routes.setting.general.local')
        break
      }
      case 'about' : {
        item.text = t('app.routes.setting.general.about')
        break
      }
      case 'authorization' : {
        item.text = t('app.routes.setting.general.authorization')
        break
      }
      case 'order-option' : {
        item.text = t('app.routes.setting.order.option')
        break
      }
      case 'menu' : {
        item.text = t('app.routes.setting.order.menu')
        break
      }
      case 'order-special-option' : {
        item.text = t('app.routes.setting.order.specialOption')
        break
      }
      case 'order-cancel-option': {
        item.text = t('app.routes.setting.order.cancelReason')
        break
      }
      case 'set' : {
        item.text = t('app.routes.setting.order.set')
        break
      }
      case 'category-tag' : {
        item.text = t('app.routes.setting.order.categoryTag')
        break
      }
      case 'orderPage' : {
        item.text = t('app.routes.setting.order.orderPage')
        break
      }
      case 'printing-general' : {
        item.text = t('app.routes.setting.printing.general')
        break
      }
      case 'printer' : {
        item.text = t('app.routes.setting.printing.printer')
        break
      }
      case 'priority' : {
        item.text = t('app.routes.setting.printing.priority')
        break
      }
      case 'customer' : {
        item.text = t('app.routes.setting.printing.customer')
        break
      }
      case 'kitchen' : {
        item.text = t('app.routes.setting.printing.kitchen')
        break
      }
      case 'label' : {
        item.text = t('app.routes.setting.printing.label')
        break
      }
      case 'receipt' : {
        item.text = t('app.routes.setting.printing.receipt')
        break
      }
      case 'customized' : {
        item.text = t('app.routes.setting.printing.customized')
        break
      }
      case 'payment' : {
        item.text = t('app.routes.setting.checkout.payment')
        break
      }
      case 'extraPayment' : {
        item.text = t('app.routes.setting.checkout.extra')
        break
      }
      case 'creditCardSetting' : {
        item.text = t('app.routes.setting.checkout.creditCardSetting')
        break
      }
      case 'shipping' : {
        item.text = t('app.routes.setting.checkout.shipping')
        break
      }
      case 'modifier' : {
        item.text = t('app.routes.setting.checkout.modifier')
        break
      }
      case 'department' : {
        item.text = t('app.routes.setting.menu.department')
        break
      }
      case 'tableGroup' : {
        item.text = t('app.routes.setting.checkout.tableGroup')
        break
      }
      case 'combo-rule': {
        item.text = t('app.routes.setting.order.comboRule')
        break
      }
      case 'payInOut': {
        item.text = t('app.routes.setting.checkout.payInOut')
        break
      }
    }
  })
  return (
    <Row style={styles.container}>
      <Row>
        {items
          .filter(route => route.text) // 跳過沒有名稱的sub routes
          .map((item) => <TabButton key={item.key} item={item} />,
          )}
      </Row>
      {
        (location[2] === 'menu' && (location[3] === 'menu' || location[3] === 'set'))
          ? (
            <SearchBar
              value={searchText}
              onChangeText={(text) => setSearchText(text)}
              onBlur={() => dispatch(actions.setting.updateSearchText(searchText))}
              onCancel={() => {
                dispatch(actions.setting.updateSearchText(''))
                setSearchText('')
              }}
            />
          )
          : (
            <View style={styles.userInfo}>
              <Icon
                size={20}
                name='perm-identity'
                type='material'
                color={colors.primary}
              />
              <View>
                <Text style={styles.text}>{user?.account}</Text>
              </View>
            </View>
          )
      }
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingTop: 25,
    paddingBottom: 15,
    marginHorizontal: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfo: {
    backgroundColor: colors.white,
    borderRadius: 10,
    alignItems: 'flex-end',
    flexDirection: 'row',
    padding: 5,
    maxWidth: 450,
    overflow: 'scroll',
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlignVertical: 'center',
  },
})
