import { Animated, View } from 'react-native'
import React from 'react'

/**
 * @typedef BlinkProps
 * @property {number} duration
 * @property {JSX.Element} children
 * @property {ViewProps['style']?} style
 */

/**
 *
 * @param {BlinkProps} props
 * @returns
 */
export default function Blink (props) {
  const { duration, style, children } = props
  const fadeAnimation = new Animated.Value(0)

  React.useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnimation, {
          toValue: 0,
          duration: duration,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnimation, {
          toValue: 1,
          duration: duration,
          useNativeDriver: true,
        }),
      ]),
      {
        iterations: Infinity,
      },
    ).start()
  })

  return (
    <View style={{ ...style }}>
      <Animated.View style={{ opacity: fadeAnimation }}>
        {children}
      </Animated.View>
    </View>
  )
}
