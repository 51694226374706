import { StyleSheet } from 'react-native'
import { useSelector } from '@/redux'
import React from 'react'
import Table from './Table'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { ITable } from '@/redux/table/Table.d'
/* eslint-enable no-unused-vars */

/**
 * @typedef TableFactoryProps
 * @property {ITable} table
 * @property {(table: ITable) => void} onSelectTable
 * @property {(order: IAppOrder) => void} onSelectOrder
 * @property {boolean} [disableOrders=false]
 * @property {boolean} [requestWaiter=false]
 */

/**
 *
 * @param {TableFactoryProps} props
 * @returns
 */
export default function TableFactory (props) {
  const { table, ...other } = props
  const enableLargerTable = useSelector(state => state.merchant.data?.setting?.enableLargerTable)
  return (
    <Table
      table={table}
      style={enableLargerTable ? styles.squareTableLarge : styles.squareTable}
      infoContainerStyle={styles.squareTableInfo}
      orderCountStyle={styles.squareOrderCount}
      {...other}
    />
  )
}

const styles = StyleSheet.create({
  squareTable: {
    height: 100,
    minHeight: 100,
    width: 100,
    flexDirection: 'column',
    borderRadius: 5,
  },
  squareTableLarge: {
    height: 150,
    minHeight: 150,
    width: 150,
    flexDirection: 'column',
    borderRadius: 5,
  },
  squareTableInfo: {
    height: 36,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: 5,
  },
  squareOrderCount: {
    position: 'absolute',
    left: 5,
    bottom: 5,
  },
})
