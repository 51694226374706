import { StyleSheet, Text } from 'react-native'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef SettingListInputAdornmentProps
 * @property {string} text
 * @property {TextProps['style']?} style
 */

/**
 *
 * @param {SettingListInputAdornmentProps} props
 * @returns
 */
export default function SettingListInputAdornment (props) {
  const { style, text } = props
  return (
    <Text style={[styles.text, style]}>{text}</Text>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    lineHeight: 20,
    color: colors.gray,
    marginHorizontal: 4,
    fontWeight: '500',
  },
})
