import { FlatList } from 'react-native-gesture-handler'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Column from '@/components/Column'
import React from 'react'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

export default function SubTypeList (props) {
  const { options, selectedItem, onSelect = () => {} } = props

  const renderItem = ({ item }) => {
    const textStyle = {
      opacity: item.key === selectedItem ? 1 : 0.4,
    }
    return (
      <TouchableOpacity onPress={() => onSelect(item.key)}>
        <View style={styles.optionContainer}>
          <Text style={[styles.text, textStyle]}>{item.text}</Text>
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <Column style={styles.container}>
      <FlatList
        data={options}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.listContainer}
        style={styles.list}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 120,
    alignItems: 'center',
    backgroundColor: colors.lightGray3,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    paddingVertical: 30,
    ...shadows.default,
    zIndex: 2,
    elevation: 2,
    marginLeft: -18,
    paddingLeft: 18,
  },
  list: {
    width: '100%',
  },
  listContainer: {
    alignItems: 'center',
  },
  optionContainer: {
    width: '100%',
    justifyContent: 'center',
    paddingVertical: 30,
    paddingHorizontal: 12,
    textAlign: 'center',
  },
  text: {
    fontSize: 20,
    fontWeight: '500',
    color: colors.primary,
  },
})
