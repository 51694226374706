import { StyleSheet, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { parseInt } from 'lodash'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import { useTranslation } from 'react-i18next'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import React from 'react'
import Row from '@/components/Row'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListInputAdornment from '@/components/Setting/SettingListInputAdornment'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemEnter from '@/components/Setting/SettingListItemEnter'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'
import firebase from '@/libs/firebase'

const defaultCustomText = {
  qrCustomText: '',
  recommendCustomText: '',
  footerCustomText: '',
}

export default function QuickModeSetting () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const merchant = useSelector(state => state.merchant.data)
  const enableTnDmode = merchant?.setting?.enableTnDmode

  const deviceLocalesOptions = [
    { key: 'zh-HK', text: t('app.page.setting.localPanel.option.language.zh') },
    { key: 'en-US', text: t('app.page.setting.localPanel.option.language.en') },
  ]

  const screenLockOptions = [
    { key: 0, text: t('app.page.setting.localPanel.option.screenLock.never') },
    { key: 10000, text: t('app.page.setting.localPanel.option.screenLock.10s') },
    { key: 15000, text: t('app.page.setting.localPanel.option.screenLock.15s') },
    { key: 30000, text: t('app.page.setting.localPanel.option.screenLock.30s') },
    { key: 60000, text: t('app.page.setting.localPanel.option.screenLock.1m') },
    { key: 180000, text: t('app.page.setting.localPanel.option.screenLock.3m') },
    { key: 300000, text: t('app.page.setting.localPanel.option.screenLock.5m') },
    { key: 600000, text: t('app.page.setting.localPanel.option.screenLock.10m') },
    { key: 900000, text: t('app.page.setting.localPanel.option.screenLock.15m') },
    { key: 3600000, text: t('app.page.setting.localPanel.option.screenLock.1h') },
  ]
  const terminalIDOptions = [
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
    { key: '4', text: '4' },
    { key: '5', text: '5' },
    { key: '6', text: '6' },
    { key: '7', text: '7' },
    { key: '8', text: '8' },
    { key: '9', text: '9' },
  ]
  const clientLocalesOptions = [
    { key: 'zh', text: t('app.page.setting.localPanel.option.language.zh') },
    { key: 'zh-TW', text: t('app.page.setting.localPanel.option.language.zh-TW') },
    { key: 'en', text: t('app.page.setting.localPanel.option.language.en') },
    { key: 'ja', text: t('app.page.setting.localPanel.option.language.ja') },
    { key: '', text: t('app.page.setting.localPanel.option.language.default') },
  ]

  const batchLocaleOptions = [
    { key: 'zh', text: t('app.page.setting.localPanel.option.language.zh') },
    { key: 'en', text: t('app.page.setting.localPanel.option.language.en') },
    { key: 'thai', text: t('app.page.setting.localPanel.option.language.thai') },
    { key: 'korean', text: t('app.page.setting.localPanel.option.language.korean') },
    { key: 'kitchen', text: t('app.page.setting.localPanel.option.language.kitchen') },
  ]
  const roundingTypeOptions = [
    { key: '', text: t('app.page.setting.localPanel.option.rounding.keep') },
    { key: 'off', text: t('app.page.setting.localPanel.option.rounding.off') },
    { key: 'up', text: t('app.page.setting.localPanel.option.rounding.up') },
    { key: 'down', text: t('app.page.setting.localPanel.option.rounding.down') },
  ]
  const rapidOptions = [
    { key: '', text: t('app.page.setting.localPanel.option.rapid.none') },
    { key: 'create', text: t('app.page.setting.localPanel.option.rapid.create') },
    { key: 'place', text: t('app.page.setting.localPanel.option.rapid.place') },
  ]

  const { isMaster } = useSelector(state => state.app.settings)
  const { clientLocales, batchLocale, rounding, setting, takeawayDisabled } = useSelector(state => state.merchant.data)
  const { defaultClientLanguage, takeawayPickupAfterMins, orderPictureMode, code, enableBusyMode } = setting

  const settingBatchLocaleOptions = batchLocaleOptions.filter(x => setting.batchLocaleOptions?.includes(x.key))
  const [takeawayPickup, setTakeawayPickup] = React.useState(takeawayPickupAfterMins)
  const [customText, setCustomText] = React.useState(defaultCustomText)
  const { lang, screenLock, terminalID, rapidOrder } = useSelector(state => state.app.settings)

  React.useEffect(() => {
    if (setting) {
      setCustomText(setting)
    }
  }, [setting])

  return (
    <Row>
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.localPanel.setting.language.title')} />
        </Header>
        <SettingList>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.language.device'),
                valuePath: ['app', 'settings', 'lang'],
                onChangeAction: (value) => actions.app.updateSetting(['lang'], value),
                options: deviceLocalesOptions,
                closeOnChange: true,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.language.device')} />
            <SettingListItemEnter
              text={deviceLocalesOptions.find(o => o.key === lang)?.text || lang}
            />
          </SettingListItem>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.language.user'),
                valuePath: ['merchant', 'data', 'clientLocales'],
                onChangeAction: actions.merchant.updateClientLocales,
                options: clientLocalesOptions.filter(o => o.key),
                multipleSelection: true,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.language.user')} />
            <SettingListItemEnter
              text={clientLocales?.map(l => clientLocalesOptions.find(o => o.key === l)?.text || l).join(', ')}
            />
          </SettingListItem>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.language.user-default'),
                valuePath: ['merchant', 'data', 'setting', 'defaultClientLanguage'],
                onChangeAction: actions.merchant.updateClientDefaultLanguage,
                options: clientLocalesOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.language.user-default')} />
            <SettingListItemEnter
              text={clientLocalesOptions.find(o => o.key === defaultClientLanguage)?.text || defaultClientLanguage}
            />
          </SettingListItem>
          <SettingListItem onPress={() =>
            dispatch(actions.setting.showSettingOptions({
              text: t('app.page.setting.localPanel.setting.language.kitchen'),
              valuePath: ['merchant', 'data', 'batchLocale'],
              onChangeAction: actions.merchant.updateBatchPrintLanguage,
              options: settingBatchLocaleOptions,
            }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.language.kitchen')} />
            <SettingListItemEnter
              text={batchLocaleOptions.find(o => o.key === batchLocale)?.text || batchLocale}
            />
          </SettingListItem>
        </SettingList>
        <Header>
          <HeaderText text={t('app.page.setting.localPanel.setting.system.title')} />
        </Header>
        <SettingList>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.isMaster')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={isMaster}
                onChangeValue={() => {
                  firebase.updateMaster(!isMaster)
                  dispatch(actions.app.updateSetting(['isMaster'], !isMaster))
                }}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.disable-takeaway')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={takeawayDisabled}
                onChangeValue={() => dispatch(actions.auth.permissionCheck('takeaway-disable', () => {
                  dispatch(actions.merchant.updateIsTakeawayDisabled(!takeawayDisabled))
                }))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.busyMode')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={enableBusyMode}
                onChangeValue={() => dispatch(actions.merchant.updateBusyMode(!enableBusyMode))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.system.terminalID'),
                valuePath: ['app', 'settings', 'terminalID'],
                onChangeAction: (value) => actions.app.updateSetting(['terminalID'], value),
                options: terminalIDOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.terminalID')} />
            <SettingListItemEnter
              text={terminalIDOptions.find(o => o.key === terminalID)?.text}
            />
          </SettingListItem>
          <SettingListItem
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.system.screenLock'),
                valuePath: ['app', 'settings', 'screenLock'],
                onChangeAction: (value) => actions.app.updateSetting(['screenLock'], value),
                options: screenLockOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.screenLock')} />
            <SettingListItemEnter
              text={screenLockOptions.find(o => o.key === screenLock)?.text}
            />
          </SettingListItem>
        </SettingList>
      </View>
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.localPanel.setting.price.title')} />
        </Header>
        <SettingList>
          <SettingListItem
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.price.rounding'),
                valuePath: ['merchant', 'data', 'rounding', 'type'],
                onChangeAction: actions.setting.updateRounding,
                options: roundingTypeOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.price.rounding')} />
            <SettingListItemEnter
              text={roundingTypeOptions.find(o => o.key === rounding.type)?.text || rounding.type}
            />
          </SettingListItem>
        </SettingList>
        <Header>
          <HeaderText text={t('app.page.setting.localPanel.setting.other.title')} />
        </Header>
        <SettingList>
          {
            !enableTnDmode && (
              <SettingListItem divider>
                <SettingListItemText text={t('app.page.setting.localPanel.setting.other.pictureMode')} />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={orderPictureMode}
                    onChangeValue={() => dispatch(actions.merchant.updateOrderPictureMode(!orderPictureMode))}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
            )
          }
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.code')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={code}
                onChangeValue={() => dispatch(actions.merchant.updateEnableCode(!code))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.takeaway-minTime')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                keyboardType='number-pad'
                value={takeawayPickup?.toString() || ''}
                onChangeText={(text) => {
                  const amount = parseInt(text) || 0
                  setTakeawayPickup(amount)
                }}
                onBlur={() => dispatch(actions.merchant.updateTakeawayPickupAfterMinutes(takeawayPickup))}
              />
              <SettingListInputAdornment text={t('app.page.setting.localPanel.setting.other.min')} />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.customRecomText')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                value={replaceSpace(customText.recommendCustomText)}
                onChangeText={(text) => {
                  setCustomText({
                    ...customText,
                    recommendCustomText: text,
                  })
                }}
                onBlur={() => dispatch(actions.merchant.updateRecommendCustomText(replaceSaveSpace(customText.recommendCustomText)))}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
      </View>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: {
    margin: 10,
  },
  row: {
    justifyContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingBottom: 100,
    marginHorizontal: 10,
  },

  moreIcon: {
    height: 12,
    width: 12,
    marginLeft: 10,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
