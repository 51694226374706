import { Redirect, Route, Switch } from '@/libs/reactRouter'
import React from 'react'

export default function RenderRoutes ({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />
      })}
      <Redirect to={routes[0].path} />
    </Switch>
  )
}

// https://reactrouter.com/web/example/route-config
export function RouteWithSubRoutes (route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props =>
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />}
    />
  )
}
