import { Image, StyleSheet, Text, TextInput, TouchableOpacity } from 'react-native'
import ColumnLabel from '@/components/InfoDialog/ColumnLabel'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'
import moment from 'moment'

export default function SubHeader ({
  state,
  showTimePicker,
  onChangeState,
}) {
  const { table, bookingAt, adults, children } = state
  return (
    <Row style={styles.container}>
      {/* Time input */}
      <ColumnLabel
        label='預約時間'
        style={styles.label}
        required
      >
        <TouchableOpacity onPress={showTimePicker}>
          <Text style={[styles.input, styles.text]}>
            {moment(bookingAt).format('HH:mm')}
          </Text>
        </TouchableOpacity>
      </ColumnLabel>

      {/* Table input */}
      <ColumnLabel
        label='預約座位'
        style={styles.label}
      >
        <Row style={styles.tableInputRow}>
          <Image
            style={styles.tableIcon}
            source={require('@icons/table/table-blue.png')}
          />
          <TextInput
            style={[styles.tableInput, styles.text]}
            placeholder='- -'
            maxLength={3}
            value={table}
            onChangeText={(value) => { onChangeState('table', value) }}
          />
        </Row>
      </ColumnLabel>

      {/* Cutomer count input */}
      <ColumnLabel
        label='預約人數'
        style={styles.label}
      >
        <Row style={styles.customerCount}>
          <Text style={styles.text}>{adults}</Text>
          <Text style={styles.smallText}>大人</Text>
          <Text style={styles.text}>{children}</Text>
          <Text style={styles.smallText}>小孩</Text>
        </Row>
      </ColumnLabel>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 12,
  },
  label: {
    flex: 1,
  },
  input: {
    width: 90,
    alignItems: 'center',
    borderBottomColor: colors.primary,
    borderBottomWidth: 2.5,
  },
  text: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.primary,
  },
  tableInputRow: {
    height: 35,
    alignItems: 'center',
  },
  tableIcon: {
    height: 28,
    width: 28,
    marginRight: 3,
  },
  tableInput: {
    width: 65,
    textAlign: 'center',
    borderBottomColor: colors.primary,
    borderBottomWidth: 2.5,
  },
  customerCount: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  smallText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.textTertiary,
  },
})
