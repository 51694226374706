import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'

/**
 * @typedef RowListEnterProps
 * @property {string?} label
 * @property {() => void} onPress
 */

/**
 *
 * @param {RowListEnterProps} props
 * @returns
 */
export default function RowListEnter (props) {
  const { label, onPress } = props

  return (
    <TouchableOpacity onPress={onPress}>
      <Row style={styles.container}>
        <View style={styles.input}>
          {label && <Text style={[styles.inputText, { flex: 1 }]}>{label}</Text>}
          <Text style={styles.inputText}>{'>'}</Text>
        </View>
      </Row>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 43,
    width: '100%',
    alignItems: 'flex-end',
    borderBottomColor: colors.primary,
    borderBottomWidth: 2,
    paddingHorizontal: 10,
    paddingBottom: 5,
  },
  input: {
    flex: 1,
    flexDirection: 'row',
  },
  inputText: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.primary,
  },
})
