import { StyleSheet } from 'react-native'
import React from 'react'

import Button from '@/components/buttons/Button'
import colors from '@/theme/colors'

/**
 * @typedef QuickModifierButtonProps
 * @property {'percent' | 'amount'} variant
 * @property {number} value
 * @property {() => void} onPress
 * @property {boolean?} disabled
 */

/**
 *
 * @param {QuickModifierButtonProps} props
 * @returns
 */
export default function QuickModifierButton (props) {
  const { variant, value, onPress, disabled } = props

  return (
    <Button
      type='Pressable'
      style={styles.button}
      backgroundColor={colors.light}
      textColor={colors.textTertiary}
      textStyle={styles.buttonText}
      onPressStyle={{ backgroundColor: colors.lightPrimary, textColor: colors.white }}
      onPress={onPress}
      disabled={!value || disabled}
    >
      {value + (variant === 'percent' ? '%' : '$')}
    </Button>
  )
}

const styles = StyleSheet.create({
  button: {
    width: 90,
    height: 40,
    margin: 10,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
  },
})
