import { Image, KeyboardAvoidingView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { useSelector } from '@/redux'
import Modal from '@/components/EnhancedModal'
import React from 'react'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

/**
 * @param {IModalProps} props
 */
export default function CenterModal (props) {
  const {
    visible,
    onClose = () => { },
    onPressOutside = () => { },
    title,
    noTitle = false,
    noBackdrop = false,
    noCloseButton = false,
    enablePressOutsideClose = false,
    contentContainerStyle,
    containerStyle,
    children,
  } = props
  const isDialogOpened = useSelector(state => state.app.dialog.isDialogOpened)

  return (
    <Modal
      // animationType='fade'
      transparent
      propagateSwipe
      visible={visible && !isDialogOpened}
      style={styles.centeredView}
      onRequestClose={onClose}
      supportedOrientations={['landscape']}
    >
      <TouchableWithoutFeedback
        onPress={!enablePressOutsideClose ? onPressOutside : onClose}
      >
        <View style={[styles.container, !noBackdrop && styles.backdrop]}>
          <KeyboardAvoidingView behavior='padding' style={styles.container}>
            <TouchableWithoutFeedback style={styles.container}>
              <View style={[styles.modalView, containerStyle]}>
                {!noTitle &&
                  <View style={styles.header}>
                    {!noCloseButton && (
                      <TouchableWithoutFeedback onPress={onClose}>
                        <View style={styles.closeButtonView}>
                          <Image style={styles.closeIcon} source={require('@icons/close.png')} />
                        </View>
                      </TouchableWithoutFeedback>
                    )}
                    {Boolean(title) && (
                      <Text style={[styles.title, noCloseButton && { marginLeft: 14 }]}>{title}</Text>
                    )}
                  </View>}
                <View style={[styles.content, contentContainerStyle]}>
                  {children}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    ...StyleSheet.absoluteFill,
    margin: 0,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    backgroundColor: colors.black + '80',
  },
  modalView: {
    maxHeight: '100%',
    margin: 15,
    borderRadius: 8,
    backgroundColor: colors.white,
    overflow: 'hidden',
    ...shadows.default,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: colors.primary,
    minHeight: 42,
  },
  closeButtonView: {
    padding: 14,
  },
  closeIcon: {
    height: 14,
    width: 14,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.white,
  },
  content: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
})
