import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

import Row from '@/components/Row'
import ToggleSwitch from '@/components/ToggleSwitch'
import colors from '@/theme/colors'

/**
 * @typedef RowToggleInputProps
 * @param {boolean} value
 * @param {() => void} onChange
 * @param {string?} label
 */

/**
 *
 * @param {RowToggleInputProps} props
 * @returns
 */
export default function RowToggleInput (props) {
  const { label, value, onChange } = props
  return (
    <Row style={styles.container}>
      <View style={styles.input}>
        {label && <Text style={styles.inputText}>{label}</Text>}
        <View style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ToggleSwitch
            value={value}
            onChangeValue={() => onChange(!value)}
            size={23}
          />
        </View>
      </View>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 43,
    width: '100%',
    alignItems: 'flex-end',
    borderBottomColor: colors.primary,
    borderBottomWidth: 2,
    paddingHorizontal: 10,
    paddingBottom: 5,
  },
  input: {
    flex: 1,
    flexDirection: 'row',
  },
  inputText: {
    flex: 1,
    fontSize: 14,
    fontWeight: '500',
    color: colors.primary,
  },
})
