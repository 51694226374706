import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import { loadingKey } from '@/constants'
import ColumnLabel from '@/components/InfoDialog/ColumnLabel'
import Row from '@/components/Row'
import TableAreaDialog from '@/components/dialogs/TableAreaDialog'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const SHIPPING_PROVIDERS = ['Uber Eat', 'Foodpanda', 'deliveroo', '店家安排']

/**
 * @typedef SubHeaderProps
 * @property {IAppOrder} order
 * @property {() => void} onChangeState
 * @property {() => void} setVisible
 */

/**
 *
 * @param {SubHeaderProps} props
 * @returns
 */
export default function SubHeader (props) {
  const { order, onChangeState, setVisible } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { createdAt, pickupAt, deliveryType, table, shippingProvider } = order
  const [activeIndex, setActiveIndex] = useState(0)
  const [tableAreaDialogOpen, setTableAreaDialogOpen] = React.useState(false)
  const merchant = useSelector(state => state.merchant.data)

  useEffect(() => {
    const index = SHIPPING_PROVIDERS.findIndex(provider => provider === shippingProvider)
    if (index >= 0) {
      setActiveIndex(index)
    }
  }, [shippingProvider])

  const Details = {
    table: () => (
      <Row style={styles.row}>
        <ColumnLabel label={t('app.constant.order.dine-in-time')} style={{ flex: 1 }}>
          <Text style={styles.text}>
            {moment(createdAt).format('HH:mm')}
          </Text>
        </ColumnLabel>

        <ColumnLabel label={t('app.constant.order.table')} style={{ flex: 1 }}>
          <Row style={{ alignItems: 'center' }}>
            <Image
              style={styles.tableIcon}
              source={require('@icons/table/table-blue.png')}
            />
            <TouchableOpacity onPress={() => setTableAreaDialogOpen(true)}>
              <Text style={styles.tableText}>{table || '- -'}</Text>
            </TouchableOpacity>
          </Row>
        </ColumnLabel>

        {/* <ColumnLabel label='入座人數' style={{ flex: 1 }}>
          <Row style={[styles.row, { alignItems: 'center' }]}>
            <Text style={styles.text}>{adults || 0}</Text>
            <Text style={styles.smallText}>大人</Text>
            <Text style={styles.text}>{children || 0}</Text>
            <Text style={styles.smallText}>小孩</Text>
          </Row>
        </ColumnLabel> */}

      </Row>
    ),
    takeaway: () => (
      <ColumnLabel label={t('app.constant.order.pickupAt')} style={{ width: 90 }}>
        <TouchableOpacity
          onPress={async () => {
            setVisible(false)
            await dispatch(actions.app.openLoading(loadingKey.TIME_PICKER))
            setTimeout(async () => {
              dispatch(actions.app.closeLoading(loadingKey.TIME_PICKER))
              dispatch(actions.app.showDateTimePicker('time',
                pickupAt || moment().add(_.get(merchant, 'setting.takeawayPickupAfterMins') || 0, 'm').toDate(),
                (value) => {
                  onChangeState('pickupAt', moment(value).toISOString())
                  dispatch(actions.order.updateSelectedOrderFields({ pickupAt: moment(value).toISOString() }))
                },
                null,
                null,
                15,
                async () => {
                  await dispatch(actions.app.openLoading(loadingKey.TIME_PICKER))
                  setTimeout(() => {
                    dispatch(actions.app.closeLoading(loadingKey.TIME_PICKER))
                    setVisible(true)
                  }, 500)
                },
              ))
            }, 500)
          }}
        >
          <Text style={styles.inputText}>
            {
              pickupAt
                ? moment(pickupAt).format('HH:mm')
                : moment().add((_.get(merchant, 'setting.takeawayPickupAfterMins') || 0), 'm').format('HH:mm')
            }
          </Text>
        </TouchableOpacity>
      </ColumnLabel>
    ),
    storeDelivery: () => (
      // <ColumnLabel label='外送方式'>
      //   <Row style={styles.row}>
      //     {shippingProviders.map((provider, index) =>
      //       <TouchableOpacity
      //         key={index}
      //         style={[styles.tag, index === activeIndex && styles.selectedTag]}
      //         onPress={() => onChangeState('shippingProvider', provider)}
      //       >
      //         <Text style={[styles.tagText, index === activeIndex && styles.selectedTag]}>{provider}</Text>
      //       </TouchableOpacity>,
      //     )}
      //   </Row>
      // </ColumnLabel>
      <>
      </>
    ),
  }
  const Detail = Details[deliveryType]

  return (
    <>
      <Detail />
      <TableAreaDialog
        open={tableAreaDialogOpen}
        onClose={() => setTableAreaDialogOpen(false)}
        onSelectTable={(table) => {
          onChangeState('table', table.key)
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  row: {
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.primary,
  },
  inputText: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.primary,
    borderBottomColor: colors.primary,
    borderBottomWidth: 2.5,
  },
  tableIcon: {
    height: 28,
    width: 28,
    marginRight: 3,
  },
  tableText: {
    width: 66,
    fontSize: 30,
    textAlign: 'center',
    fontWeight: 'bold',
    color: colors.primary,
    borderBottomColor: colors.primary,
    borderBottomWidth: 2.5,
  },
  labelText: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
    color: colors.textSecondary,
  },
  tag: {
    height: 35,
    width: 90,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
    borderColor: colors.primary,
    borderWidth: 2,
  },
  selectedTag: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  tagText: {
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 24,
    color: colors.primary,
  },
  smallText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.textTertiary,
  },

})
