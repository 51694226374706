import { FlatList, Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'

import TextInputDialog from '@/components/dialogs/TextInputDialog'

import GroupNavButton from './GroupNavButton'

/**
 * @typedef GroupNavBarProps
 * @property {*[]} items
 * @property {() => void} resetLocalTable
 */

/**
 *
 * @param {GroupNavBarProps} props
 * @returns
 */
export default function GroupNavBar (props) {
  const { items, resetLocalTable } = props
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const renderItem = ({ item }) => <GroupNavButton item={item} resetLocalTable={resetLocalTable} />

  return (
    <View style={{ zIndex: 1 }}>
      <View style={styles.container}>
        <FlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={item => item.key}
          horizontal
          showsHorizontalScrollIndicator={false}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
          contentContainerStyle={styles.list}
        />
        <TouchableOpacity
          onPress={() => setIsDialogOpened(true)}
        >
          <View style={styles.iconWrap}>
            <Image
              style={styles.icon}
              source={require('@icons/add.png')}
            />
          </View>
        </TouchableOpacity>
      </View>
      <TextInputDialog
        open={isDialogOpened}
        onClose={() => setIsDialogOpened(false)}
        title={t('app.page.setting.table.add') + t('app.page.setting.table.tableGroup')}
        placeholder={t('app.page.setting.table.placeholder')}
        onSubmit={async (text) => {
          if (text !== t('app.page.setting.table.other')) {
            await dispatch(actions.merchant.createTableGroup(text))
            setIsDialogOpened(false)
            history.push(`/setting/table?groupId=${encodeURIComponent(text)}`)
          } else {
            dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.table.nameError')))
          }
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 77,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderBottomRightRadius: 30,
    paddingHorizontal: 50,
  },
  list: {
    height: '100%',
  },
  separator: {
    width: 12,
  },
  iconWrap: {
    paddingHorizontal: 12,
  },
  icon: {
    height: 34,
    width: 34,

  },
})
