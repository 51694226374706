const loadingKey = {
  PRINT: 'print',
  TAKEAWAY: 'getTakeawayOrderByCode',
  SET_MENU: 'setMenu',
  CATEGORY: 'category',
  TABLE: 'table',
  STATS: 'stats',
  INIT_DATA: 'initData',
  SUBMIT_ORDER: 'submitOrder',
  CONFIRM_ORDER: 'confirmOrder',
  ITEM: 'item',
  CHECKOUT: 'checkout',
  CARDPAYMENT: 'cardPayment',
  TIME_PICKER: 'timePicker',
  SUBMIT_ORDER_PAYMENT: 'submitOrderPayment',
  VOID_ORDER_PAYMENT: 'voidOrderPayment',
  MENU: 'menu',
  COMBO: 'combo',
  COMBO_GROUP: 'comboGroup',
  OPTION: 'option',
  ORDER: 'order',
  SETTING: 'setting',
  PICKUP_NOTIFY: 'pickupNotify',
}

export default loadingKey
