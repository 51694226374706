import { actions, useDispatch, useSelector } from '@/redux'
import { useSearchedMenu } from '@/hooks/search'
import { useTranslation } from 'react-i18next'
import NumberPad from '@/components/NumberPad'
import React from 'react'

export default function CodeSearchNumPad (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchText = useSelector(state => state.menu.searchText)

  const searchResults = useSearchedMenu()

  const buttons = [
    { text: 1, fontSize: 25 },
    { text: 2, fontSize: 25 },
    { text: 3, fontSize: 25 },

    { text: 4, fontSize: 25 },
    { text: 5, fontSize: 25 },
    { text: 6, fontSize: 25 },

    { text: 7, fontSize: 25 },
    { text: 8, fontSize: 25 },
    { text: 9, fontSize: 25 },

    { text: 'C', variant: 'action', fontSize: 24 },
    { text: 0, fontSize: 25 },
    { text: t('app.common.numPadConfirm'), variant: 'action', fontSize: 19 },
  ]

  let itemToAdd = null
  // 只有一個結果，且 code match search text
  if (searchResults.length === 1 && searchResults[0].code === searchText) {
    itemToAdd = searchResults[0]
  }

  return (
    <NumberPad
      variant='dark'
      buttonSize={56}
      buttons={buttons}
      onButtonPress={(text) => {
        if (text === 'C') {
          dispatch(actions.menu.updateSearchText(''))
          return
        }
        if (text === t('app.common.numPadConfirm') && itemToAdd) {
          dispatch(actions.orderBatch.addItem(itemToAdd))
          return
        }

        dispatch(actions.menu.updateSearchText(searchText + text))
      }}
      disabledTexts={{
        確認: !itemToAdd,
        OK: !itemToAdd,
      }}
    />
  )
}
