import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import OptionsPanel from '@/components/Setting/OptionsPanel'

/**
 * @typedef OptionsSelectDialogProps
 * @property {string} title
 * @property {string?} submitButtonText
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {(value: TOption | TOption[]) => void} onSubmit
 * @property {TOption[]} options
 * @property {TOption | TOption[]} defaultValue
 * @property {IModalProps?} modalProps
 * @property {IOptionsPanelProps?} optionsPanelProps
 * @property {boolean?} multiple
 * @property {boolean?} closeOnSelect
 * @property {boolean?} enableButtonDisable
 * @property {boolean?} disableHeader
 * @property {boolean?} disableSaveValue
 */

/**
 * @param {OptionsSelectDialogProps} props
 * @returns
 */
export default function OptionsSelectDialog (props) {
  const { t } = useTranslation()
  const {
    title,
    submitButtonText = t('app.common.confirm'),
    open,
    onClose,
    onSubmit,
    options = [],
    defaultValue,
    modalProps = {},
    optionsPanelProps = {},
    multiple = false,
    closeOnSelect = false,
    enableButtonDisable = false,
    disableHeader = false,
    disableSaveValue = false,
  } = props
  const [value, setValue] = useState(defaultValue)

  return (
    <CenterModal
      enablePressOutsideClose
      visible={open}
      onClose={onClose}
      contentContainerStyle={styles.contentContainer}
      {...modalProps}
      title={title}
    >
      <View style={styles.content}>
        <OptionsPanel
          key={title}
          title={title}
          multiple={multiple}
          closeOnSelect={closeOnSelect}
          disableHeader={disableHeader}
          defaultValue={defaultValue}
          onClose={onClose}
          disableSaveValue={disableSaveValue}
          onChangeValue={(value) => {
            if (closeOnSelect) {
              onSubmit(value)
            } else {
              setValue(value)
            }
          }}
          options={options}
          {...optionsPanelProps}
        />
      </View>
      {!closeOnSelect && (
        <View style={styles.buttons}>
          <Button
            title={submitButtonText}
            disabled={enableButtonDisable ? !value : false}
            backgroundColor={colors.darkSecondary}
            onPress={() => {
              onSubmit(value)
              onClose()
            }}
          />
        </View>
      )}
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: 380,
    minHeight: 400,
    maxHeight: 400,
    backgroundColor: colors.lightGray1,
    padding: 8,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    margin: 8,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})
