import { FlatList, Image, ScrollView, StyleSheet, Text, View } from 'react-native'
import { useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import React from 'react'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import TableGroupInfo from './TableGroupInfo'
import _ from 'lodash'

const arrowRightIcon = require('@icons/arrow-right.png')

export default function TableGroupPanel () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedTableGroup, setSelectedTableGroup] = React.useState(null)
  const { tableGroups: groups } = useSelector(state => state.merchant.data)
  const tableGroups = _.keys(groups).map(key => {
    return {
      key,
      text: key,
      id: uuid(),
    }
  })

  return (
    <View style={styles.container}>
      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.table.tableGroup')} />
        </Header>
        <View style={styles.content}>
          <SettingList>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={tableGroups}
              ListEmptyComponent={(
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.checkout.empty')}</Text>
                </View>
              )}
              keyExtractor={(item, index) => String(item.id)}
              renderItem={({ item, index }) => {
                return (
                  <SettingListItem
                    key={item.id}
                    divider={index !== tableGroups.length - 1}
                    onPress={() => {
                      setSelectedTableGroup({ ...item })
                    }}
                  >
                    <SettingListItemText text={item.text} />
                    <SettingListItemRightAction>
                      <Image
                        source={arrowRightIcon}
                        style={styles.icon}
                      />
                    </SettingListItemRightAction>
                  </SettingListItem>
                )
              }}
            />
          </SettingList>
        </View>
      </View>
      {/** Right */}
      <View style={styles.contentContainer}>
        <TableGroupInfo
          key={selectedTableGroup?.id + selectedTableGroup?.text}
          tableGroup={selectedTableGroup}
          onClose={() => setSelectedTableGroup(null)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  icon: {
    width: 16,
    height: 16,
  },

})
