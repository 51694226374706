
export default {
  // item 使用的欄位名稱
  // fields: ['serial', 'name', 'status', 'price', 'quantity', 'totalDiscount', 'surcharge', 'total'],
  fields: ['serial', 'name', 'status', 'price', 'quantity', 'total'],

  // 欄寬
  widths: [60, 129, 120 + 45 + 45, 45, 50, 50],
  // 對齊
  justifyContents: [
    'center',
    'flex-start',
    'flex-start',
    'flex-start',
    'center',
    'flex-start',
    'flex-start',
    'flex-start',
  ],
}
