
import { dimorderApi } from '@/libs/api/dimorder'
import { loadingKey } from '@/constants'
import ActionTypes from './ActionTypes'
import _ from 'lodash'
import actions from '../actions'
import moment from 'moment'

/**
 * 報表
 * @returns {ThunkFunction}
 */
export function getData (fromDateTime, toDateTime) {
  return async (dispatch, getState) => {
    try {
      await dispatch(actions.app.openLoading(loadingKey.STATS))
      dimorderApi.setApiTimeout(0)
      const from = moment(fromDateTime).unix()
      const to = moment(toDateTime).unix()
      await dispatch(getSummary(from, to))
      // await dispatch(getCategoryStat(from, to))
      await dispatch(getCancelStat(from, to))
      await dispatch(getSalesCategoryStat(moment(fromDateTime).utc().toISOString(), moment(toDateTime).utc().toISOString()))
      await dispatch(getCancelledOrders(moment(fromDateTime).utc().toISOString(), moment(toDateTime).utc().toISOString()))
      await dispatch(listCashRequest(from, to))
      // await dispatch(getDepartmentStat(from, to))
      await dispatch(getTableGroupSalesStat(from, to))
      await dispatch(getOptionStat(from, to))
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATETIME,
        payload: { datetime: { from: fromDateTime, to: toDateTime } },
      })
      dispatch({
        type: ActionTypes.INIT,
        payload: {},
      })
    } finally {
      dimorderApi.setApiTimeout(15000)
      dispatch(actions.app.closeLoading(loadingKey.STATS))
    }
  }
}

/**
 * 統計報表
 * @returns {ThunkFunction}
 */
export function getSummary (from, to) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.order.getOrderStatV2(from, to)
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: { path: 'summary', data },
      })
    } catch (error) {
      console.log('getSummary error', error)
    }
  }
}

/**
 * 分類報表
 * @returns {ThunkFunction}
 */
export function getCategoryStat (from, to) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.order.getOrderStatCategory(from, to, false)
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: { path: 'category', data },
      })
    } catch (error) {
      console.log('getCategoryStat', error)
    }
  }
}

/**
 * 分類報表
 * @returns {ThunkFunction}
 */
export function getSalesCategoryStat (from, to) {
  return async (dispatch, getState) => {
    try {
      const result = await dimorderApi.order.getSalesReportCategory(from, to)
      const data = {
        categorySection: _.map(result.data?.categorySection, (cat, index) => {
          return { ...cat, key: `categorySection-${index}` }
        }),
        setSection: _.map(result.data?.setSection, (set, index) => {
          return { ...set, key: `setSection-${index}` }
        }),
      }
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: { path: 'category', data: data },
      })
    } catch (error) {
      console.log('getCategoryStat', error)
    }
  }
}

/**
 * 取消報表
 * @returns {ThunkFunction}
 */
export function getCancelStat (from, to) {
  return async (dispatch, getState) => {
    try {
      const order = await dimorderApi.order.getOrderStatCancel(from, to, undefined)
      const category = await dimorderApi.order.getOrderStatCategory(from, to, true)
      const data = { order, category }
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: { path: 'cancel', data },
      })
    } catch (error) {
      console.log('getCancelStat', error)
    }
  }
}

export function getCancelledOrders (from, to) {
  return async (dispatch, getState) => {
    try {
      const response = await dimorderApi.order.getCancelledOrders(from, to, 'orders')
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: { path: 'cancel.order', data: response.data },
      })
    } catch (error) {
      console.log('getCancelledOrders', error)
    }
  }
}

/**
 * 部門報表
 * @returns {ThunkFunction}
 */
export function getDepartmentStat (from, to) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.order.getOrderStatKitchenDepartment(from, to)
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: { path: 'department', data },
      })
    } catch (error) {
      console.log('getCategoryStat', error)
    }
  }
}

/**
 * 桌位報表
 * @returns {ThunkFunction}
 */
export function getTableGroupSalesStat (from, to) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.order.getTableStatistic(from, to)
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: { path: 'tableGroupSales', data },
      })
    } catch (error) {
      console.log('getTableGroupStat', error)
    }
  }
}

/**
 * 選項報表
 * @returns
 */
export function getOptionStat (from, to) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.order.getOptionStatistic(from, to)
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: { path: 'option', data },
      })
    } catch (error) {
      console.log('getOptionStat', error)
    }
  }
}
/**
 * 收入支出報表
 * @returns {ThunkFunction}
 */
export function listCashRequest (from, to) {
  return async (dispatch, getState) => {
    try {
      const data = await dimorderApi.merchant.listCashRequest(from, to)
      dispatch({
        type: ActionTypes.UPDATE_STAT_DATA,
        payload: {
          path: 'cash',
          data,
        },
      })
    } catch (error) {
      console.log('getCategoryStat', error)
    }
  }
}

/**
 * selectedStatType
 * @param {string} selectedStatType
 */
export function selectStatType (type) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_STAT_TYPE,
      payload: { type },
    })
  }
}

/**
 * selectedSubType
 * @param {string} selectedSubType
 */
export function selectSubType (type) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_SUB_TYPE,
      payload: { type },
    })
  }
}

/**
 * selectedFilter
 * @param {string} selectedFilter
 */
export function selectFilter (key, text, filters = {}) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_FILTER,
      payload: { filter: { key, text, filters } },
    })
  }
}
