import { Animated, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import envOptions from '@/configs/envOptions'
import usePressedTimes from '@/hooks/usePressedTimes'

import OptionsSelectDialog from '@/components/dialogs/OptionsSelectDialog'
import TextInputDialog from '@/components/dialogs/TextInputDialog'

/**
 * @typedef AnimatedLogoProps
 * @property {boolean} hide
 * @property {() => void} onLongPress
 */

/**
 *
 * @param {AnimatedLogoProps} props
 * @returns
 */
export default function AnimatedLogo (props) {
  const { hide, onLongPress } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const configEnv = useSelector((state) => state.config.env)
  const [envSelectDialogOpen, setEnvSelectDialogOpen] = React.useState(false)
  const [adminDialogOpen, setAdminDialogOpen] = React.useState(false)
  const { handlePress } = usePressedTimes(() => setAdminDialogOpen(true))

  if (hide) {
    return null
  }

  /* const logoWidth = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [100, 59],
  })

  const logoHeight = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [130, 76],
  })

  const logoX = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['40%', '95%'],
  })
  const logoY = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['50%', '30%'],
  }) */

  return (
    <>
      <View style={[styles.container, { left: '50%', top: '85%' }]}>
        <TouchableWithoutFeedback
          onPress={handlePress}
          onLongPress={onLongPress}
          delayLongPress={3000}
        >
          <Animated.Image
            style={{ height: 83, width: 300 }}
            source={require('@images/login/cropped-banner_bottomSpace.png')}
          />
        </TouchableWithoutFeedback>
      </View>
      <OptionsSelectDialog
        key={envSelectDialogOpen}
        title={t('app.page.login.environment.select')}
        modalProps={{ enablePressOutsideClose: false }}
        open={envSelectDialogOpen}
        onClose={() => setEnvSelectDialogOpen(false)}
        closeOnSelect
        options={envOptions}
        defaultValue={configEnv}
        onSubmit={(env) => dispatch(actions.config.overwriteEnv(env))}
      />
      <TextInputDialog
        enablePressOutsideClose={false}
        open={adminDialogOpen}
        onClose={() => setAdminDialogOpen(false)}
        secureTextEntry
        title={t('app.page.setting.menuPanel.menuInfo.dialog.admin.title')}
        placeholder={t(
          'app.page.setting.menuPanel.menuInfo.dialog.admin.placeholder',
        )}
        onSubmit={async (text) => {
          try {
            await dimorderApi.auth.adminLogin(text)
            setEnvSelectDialogOpen(true)
          } catch (error) {
            dispatch(
              actions.app.showSimpleAlert(
                t('app.common.error'),
                t('app.page.setting.menuPanel.menuInfo.error.login'),
              ),
            )
          }
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 2,
    transform: [{ translateX: -150 }, { translateY: -41.5 }],
  },
})
