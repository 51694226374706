import { FlatList, Image, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useSelector } from '@/redux'

import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

import DiscountInfo from './DiscountInfo'

const arrowRightIcon = require('@icons/arrow-right.png')

/**
 *
 * @param {*} props
 * @returns
 */
export default function DiscountPanel (props) {
  const { t } = useTranslation()
  const [selectedModifier, setSelectedModifier] = React.useState(null)
  const discountModifier = useSelector(state => state.merchant.modifier)

  return (
    <View style={styles.container}>
      {/** Left */}
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.checkout.modifier')} />
        </Header>
        <View style={styles.content}>
          <SettingList>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={discountModifier}
              ListEmptyComponent={(
                <View style={styles.emptyView}>
                  <Text>{t('app.page.setting.checkout.empty')}</Text>
                </View>
              )}
              keyExtractor={(item) => item.id}
              renderItem={({ item: modifier, index }) => {
                return (
                  <SettingListItem
                    key={modifier.id}
                    divider={index !== discountModifier.length - 1}
                    onPress={() => {
                      setSelectedModifier({ ...modifier, extra: true })
                    }}
                  >
                    <SettingListItemText text={modifier.name} />
                    <SettingListItemRightAction>
                      <Image
                        source={arrowRightIcon}
                        style={styles.icon}
                      />
                    </SettingListItemRightAction>
                  </SettingListItem>
                )
              }}
            />
            <SettingListFooterAddButton
              text={t('app.page.setting.checkout.add') + t('app.page.setting.checkout.modifier')}
              onPress={() => {
                setSelectedModifier({ name: '', enable: true, amount: 0, percent: 0 })
              }}
            />
          </SettingList>
        </View>
      </View>
      {/** Right */}
      <View style={styles.contentContainer}>
        <DiscountInfo
          key={selectedModifier?.id + selectedModifier?.name}
          modifier={selectedModifier}
          onClose={() => setSelectedModifier(null)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    marginHorizontal: 10,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  icon: {
    width: 16,
    height: 16,
  },

})
