import _, { each, sum } from 'lodash'

import { PrinterFontSize } from '@/constants/printer'
import Printer from '@/libs/printing/printer/Printer'

export default class PrintUtiliy extends Printer {
  static leftPad (str, len = 0, ch = ' ') {
    const type = typeof str
    str = str || ''
    if (type !== 'string') {
      str = str.toString()
    }
    str = str?.normalize('NFKC')
    const pad = len - this.strLen(str)
    if (pad) {
      if (pad < 0) {
        console.error(`invalid left pad: str = ${str}, len = ${len}`)
      } else {
        str = ch.repeat(pad) + str
      }
    }
    return str
  }

  static rightPad (str, len = 0, ch = ' ') {
    const type = typeof str
    str = str || ''
    if (type !== 'string') {
      str = str.toString()
    }
    str = str?.normalize('NFKC')
    const pad = len - this.strLen(str)
    if (pad) {
      if (pad < 0) {
        console.error(`invalid right pad: str = ${str}, len = ${len}`)
      } else {
        str += ch.repeat(pad)
      }
    }
    return str
  }

  static strLen (str, chineseWidth = 2) {
    const normalizedString = str?.normalize('NFKC')
    var count = 0
    for (let i = 0, len = normalizedString?.length; i < len; i++) {
      count += normalizedString.charCodeAt(i) < 256 ? 1 : chineseWidth
    }
    return Math.ceil(count)
  }

  static checkSpace (str) {
    if (!str) {
      return false
    }
    for (let i = 0, len = str.length; i < len; i++) {
      if (str[i] === ' ') {
        return true
      }
    }
    return false
  }

  static cutStringByMaxWidth (str, maxWidth, chineseWidth = 2) {
    const printItemName = []
    let i = 0

    for (i = 0; this.strLen(str, chineseWidth) > maxWidth; i++) {
      let strCutLen = 0

      strCutLen = this.getWidthbyTextType(str, maxWidth, chineseWidth)

      printItemName[i] = str.substr(0, strCutLen)

      str = str.slice(strCutLen)
    }
    if (str.length > 0) {
      printItemName[i] = str
    }
    return printItemName
  }

  static getItemNameByMaxWidth (itemName, nameMaxWidth, chineseWidth = 2) {
    const spaced = this.checkSpace(itemName)

    if (spaced) {
      const splitedItemName = itemName.split(' ')
      let count = 0
      for (let i = 0; i < splitedItemName.length; i++) {
        if (this.strLen(splitedItemName[i], chineseWidth) > nameMaxWidth) {
          const cutStr = this.cutStringByMaxWidth(splitedItemName[i], nameMaxWidth, chineseWidth)
          splitedItemName.splice(i, 1, ...cutStr)
          if (i > 0) {
            i--
          } else {
            i = 0
          }
        } else if (i > 0) {
          if (this.strLen(splitedItemName[count] + splitedItemName[i], chineseWidth) + 1 < nameMaxWidth) {
            splitedItemName[count] += ' ' + splitedItemName[i]
            splitedItemName.splice(i, 1)
            i--
          } else {
            count = i
          }
        }
      }
      return splitedItemName
    } else {
      return this.cutStringByMaxWidth(itemName, nameMaxWidth, chineseWidth)
    }
  }

  static getItemNameByMaxWidthWithSpace (itemName, nameMaxWidth, space, chineseWidth = 2) {
    const printItemName = []
    const spaceLength = this.strLen(space)
    const width = nameMaxWidth - spaceLength
    let i = 0

    for (i = 0; this.strLen(itemName, chineseWidth) > width; i++) {
      let strCutLen = 0

      strCutLen = this.getWidthbyTextType(itemName, width, chineseWidth)

      printItemName[i] = space + itemName.substr(0, strCutLen)

      itemName = itemName.slice(strCutLen)
    }
    if (itemName.length > 0) {
      printItemName[i] = space + itemName
    }
    return printItemName
  }

  static getItemPriceByMaxWidth (itemPrice, priceMaxWidth) {
    const printItemPrice = []
    let i = 0
    for (i = 0; this.strLen(itemPrice) > priceMaxWidth; i++) {
      const strCutLen = priceMaxWidth

      printItemPrice[i] = itemPrice.substr(0, strCutLen).padStart(priceMaxWidth, ' ')

      itemPrice = itemPrice.slice(strCutLen)
    }

    if (itemPrice.length > 0) {
      printItemPrice[i] = itemPrice.padStart(priceMaxWidth, ' ')
    }
    return printItemPrice
  }

  static printFixedRow (
    texts,
    widths,
    data,
    styleText,
  ) {
    if (!texts) { texts = [] }
    const PRINT_MAX_WIDTH = 48
    if (sum(widths) > PRINT_MAX_WIDTH) {
      console.error('widths too long:', widths)
    }
    const row = texts.reduce((row, text, i) => {
      const width = widths[i]
      let buffer = ''
      each(text, char => {
        const length = this.strLen(buffer) + this.strLen(char)
        if (length > width) {
        } else {
          buffer += char
        }
      })
      if (this.strLen(buffer) < width) {
        buffer += [...new Array(width - this.strLen(buffer))]
          .map(a => ' ')
          .join('')
      }
      return row + buffer
    }, '')
    data.addText(row, styleText ? styleText.text : PrinterFontSize.SMALL)
    data.addRowFeed()
  }

  static printRow (texts, widths, data) {
    for (const index in texts) { // for of
      const text = texts[index]

      const width = widths ? widths[index] : null

      if (parseInt(index) !== 0) { // better than "+index"
        data.addTab()
      }

      if (text) {
        if (width) {
          data.addText(this.leftPad(text, width))
        } else {
          data.addText(text)
        }
      }
    }

    data.addRowFeed()
  }

  static getWidthbyTextType (text, width, chineseWidth) {
    try {
      const REGEX_CHINESE = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/
      const hasChinese = text.match(REGEX_CHINESE)
      let textWidth = 0
      let length = 0
      _.some(text, t => {
        textWidth += t.charCodeAt(0) < 256 ? 1 : chineseWidth
        if (textWidth > width) {
          return true
        }
        length += 1
      })
      return length
    } catch (error) {
      return width
    }
  }

  static getLabelTextByMaxDots (string, fontsize, dots) {
    const printText = []
    let width = 0
    let content = ''
    for (const text in string) {
      let n = 0.6
      let textDots = 0
      const REGEX_CHINESE = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/
      if (text.match(REGEX_CHINESE)) {
        n = 1
      }
      switch (fontsize) {
        case PrinterFontSize.LARGE:
        case 2:
          textDots += 20 * 3 + n
          break
        case PrinterFontSize.MEDIUM:
        case 1:
          textDots += 15 * 3 + n
          break
        default:
          textDots += 10 * 3 + n
      }
      if ((width + textDots) < dots) {
        width += textDots
        content += text
      } else {
        printText.push(content)
        width = 0
        content = ''
      }
    }
    printText.push(content)
    return printText
  }
}
