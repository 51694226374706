import { MaterialCommunityIcons } from '@expo/vector-icons'
import { StyleSheet } from 'react-native'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'
import i18n from '@/i18n'

import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import SettingList from '@/components/Setting/SettingList'
import SettingListFooterAddButton from '@/components/Setting/SettingListFooterAddButton'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import colors from '@/theme/colors'

export default function CategoryList (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    style,
    title,
    parentCategory,
    categories,
    selectedCategory,
    onPressBottomButton,
    onPress,
    draggable,
    children,
  } = props
  const isLoading = useSelector(state => state.menu.isCategoryLoading)
  const [loadingDepth, setLoadingDepth] = React.useState(0)
  const search = useSelector(state => state.setting.searchText)
  const lang = String(i18n.language ?? 'zh').split('-')[0]

  const renderCategoryItem = ({ item: category, index, drag, isActive }, depth = 0) => {
    const nextDepth = depth + 1
    const hasSubCategories = category.categories?.length > 0
    const isSelected = selectedCategory?.id
      ? category.id === selectedCategory?.id // 非新增時，選到哪個就顯示哪個 isSelected
      : category.id === selectedCategory?.parentId // 新增時，將 parent category 顯示 isSelected
    const isExpanded = selectedCategory?.path.includes(category.path)
    const itemLoading = isLoading && depth === loadingDepth
    const isSubCategory = category.parentId !== 'ROOT' && category.parentId !== 'SET_ROOT'

    return (
      <>
        {/* this category */}
        <SettingListItem
          divider
          draggable={draggable}
          dragging={isActive}
          onDrag={drag}
          dragDisabled={search.trim()}
          selected={isSelected}
          disabled={!onPress}
          backgroundColor={isSubCategory ? colors.lightGray1 : colors.white}
          onPress={() => {
            onPress(category)
          }}
          style={[
            { paddingLeft: depth * 10 },
          ]}
        >
          {hasSubCategories && (
            isExpanded
              ? <MaterialCommunityIcons name='menu-up' style={{ marginLeft: -8 }} size={24} color={colors.textPrimary} />
              : <MaterialCommunityIcons name='menu-down' style={{ marginLeft: -8 }} size={24} color={colors.textPrimary} />
          )}

          <SettingListItemText
            text={itemLoading ? '...' : _.get(category?.localeNames, lang) ?? category.name}
            wrap
          />

        </SettingListItem>

        {/* sub categories */}
        {hasSubCategories && isExpanded && (
          <DraggableFlatList
            containerStyle={{ flex: 1, maxHeight: 300 }}
            showsVerticalScrollIndicator={false}
            data={category.categories}
            extraData={selectedCategory}
            keyExtractor={item => item.id}
            renderItem={(item) => renderCategoryItem(item, nextDepth)}
            onDragEnd={({ data }) => {
              if (!draggable || search.trim()) return
              setLoadingDepth(nextDepth)
              dispatch(actions.menu.updateCategoryOrder(category, data))
            }}
          />
        )}
      </>
    )
  }

  return (
    <SettingList
      shadow
      style={[styles.container, style]}
      title={title}
    >
      {children}
      <DraggableFlatList
        containerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        data={categories}
        extraData={selectedCategory}
        keyExtractor={item => item.id}
        renderItem={renderCategoryItem}
        onDragEnd={({ data }) => {
          if (!draggable) return
          setLoadingDepth(0)
          dispatch(actions.menu.updateCategoryOrder(parentCategory, data))
        }}
      />
      {onPressBottomButton &&
        <SettingListFooterAddButton
          text={`${t('app.common.add')}${title}`}
          style={styles.button}
          onPress={onPressBottomButton}
        />}
    </SettingList>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 200,
  },
  listItem: {
    paddingLeft: 10,
  },
  button: {
    width: 'auto',
    marginLeft: 0,
  },
  icon: {
    width: 15,
    height: 7,
  },
})
