import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import TableRow from '@/components/OrderTable/TableRow'
import colors from '@/theme/colors'
import i18n from '@/i18n'

import tableConfig from './tableConfig'

// eslint-disable-next-line no-unused-vars
import { IAppBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'

export default function SetItems (props) {
  const { item } = props
  const dispatch = useDispatch()
  const selectedOrderItems = useSelector(state => state.orderHistory.selectedOrderItems)
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)

  if (!item.isSet) return null

  /**
   *
   * @param {{item: IAppBatchItem, index: number}} info
   * @returns {React.ReactElement}
   */
  const renderItem = ({ item: setItem, index }) => {
    const isSelected = Boolean(selectedOrderItems.find(i => i.id === setItem.id))
    const displaySetItem = {
      ...setItem,
      batchId: item.batchId,
      name: _.get(allMenus[setItem.menuId]?.localeNames, lang) ?? setItem.name,
    }
    return (
      <TouchableOpacity
        onPress={() => {
          dispatch(actions.orderHistory.toggleSelectItem(displaySetItem))
          dispatch(actions.orderHistory.deSelectItem(item))
        }}
      >
        <TableRow
          item={displaySetItem}
          fields={tableConfig.fields}
          widths={tableConfig.widths}
          justifyContents={tableConfig.justifyContents}
          style={[styles.setItemRow, isSelected && styles.selectedRow]}
          textStyle={isSelected && styles.selectedText}
        />
      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.container}>
      <FlatList
        scrollEnabled={false}
        data={item.setItems}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        extraData={selectedOrderItems}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: colors.lightGray5,
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'solid',
  },
  setHeaderRow: {
    backgroundColor: colors.light,
    minHeight: 30,
  },
  setItemRow: {
    minHeight: 30,
  },
  selectedRow: {
    backgroundColor: colors.tertiary,
  },
  selectedText: {
    color: colors.textPrimary,
  },
})
