import { Dimensions, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import IconButton from '@/components/buttons/IconButton'
import React from 'react'
import colors from '@/theme/colors'

/**
 * @typedef CustomDialog
 * @property {string} title
 * @property {string | number} width
 * @property {string | number} height
 * @property {() => void} onClose
 * @property {boolean} enablePressOutsideClose
 * @property {boolean} closeButton
 * @property {any} children
 */

/**
 *
 * @param {CustomDialog} props
 * @returns
 */

const { width, height } = Dimensions.get('window')

const CustomDialog = (props) => {
  const {
    title,
    width,
    height,
    onClose = () => { },
    closeButton = false,
    enablePressOutsideClose = false,
    children,
  } = props

  const onPressOutside = () => {}

  return (
    <TouchableWithoutFeedback onPress={!enablePressOutsideClose ? onPressOutside : onClose}>
      <View style={styles.container}>
        <View style={styles.backgroundLayer} />
        <TouchableWithoutFeedback>
          <View style={{ width: width }}>
            <View style={styles.header}>
              {closeButton &&
                <IconButton
                  iconSource={require('@icons/close.png')}
                  iconStyle={styles.backIcon}
                  onPress={onClose}
                />}
              <Text style={styles.title}>{title}</Text>
            </View>
            <View style={[styles.content, { height: height }]}>
              {children}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: 'white',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  backgroundLayer: {
    width: width,
    height: height,
    position: 'absolute',
    backgroundColor: colors.black + '80',
  },
  container: {
    width: width,
    height: height,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 42,
    backgroundColor: colors.primary,
    alignItems: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.white,
    marginLeft: 14,
  },
  backIcon: {
    width: 20,
    height: 20,
    marginLeft: 10,
  },
})

export default CustomDialog
