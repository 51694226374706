import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'

InventoryTag.propTypes = {
  inventory: PropTypes.number,
  anchorOrigin: PropTypes.object,
  borderRadius: PropTypes.number || PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default function InventoryTag (props) {
  const {
    inventory = 0,
    anchorOrigin = { vertical: 'top', horizontal: 'right' },
    borderRadius = 8,
    width = 25,
    height = 25,
  } = props

  const INVENTORY_TAG_LIMITATION = 99
  const reachLimitation = inventory > INVENTORY_TAG_LIMITATION

  const anchorOriginStyle = {
    top: anchorOrigin.vertical === 'top' && 0,
    bottom: anchorOrigin.vertical === 'bottom' && 0,
    left: anchorOrigin.horizontal === 'left' && 0,
    right: anchorOrigin.horizontal === 'right' && 0,
    borderTopLeftRadius: anchorOrigin.vertical === 'bottom' && anchorOrigin.horizontal === 'right' && borderRadius,
    borderTopRightRadius: anchorOrigin.vertical === 'bottom' && anchorOrigin.horizontal === 'left' && borderRadius,
    borderBottomLeftRadius: anchorOrigin.vertical === 'top' && anchorOrigin.horizontal === 'right' && borderRadius,
    borderBottomRightRadius: anchorOrigin.vertical === 'top' && anchorOrigin.horizontal === 'left' && borderRadius,
  }

  _.forEach(anchorOriginStyle, (value, key) => {
    if (typeof value !== 'number') delete anchorOriginStyle[key]
  })

  const dimensionStyle = {
    width: width,
    height: height,
  }

  return (
    <View style={[styles.inventoryTag, anchorOriginStyle, dimensionStyle]}>
      <Text style={styles.inventoryText}>
        {reachLimitation ? INVENTORY_TAG_LIMITATION : inventory}
        {reachLimitation && <Text style={styles.plusSymbol}>⁺</Text>}
      </Text>

    </View>
  )
}

const styles = StyleSheet.create({
  inventoryTag: {
    zIndex: 2,
    position: 'absolute',
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inventoryText: {
    color: colors.primary,
    fontSize: 13,
    fontWeight: 'bold',
    alignItems: 'flex-start',
  },
  plusSymbol: {
    fontSize: 12,
  },
})
