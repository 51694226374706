export function replaceSpace (str, globalSearch) {
  const regex = (globalSearch ? /\u0020/ : /\u0020/g)
  return typeof str === 'string' ? (str || '').replace(regex, '\u00a0') : str
}

export function replaceSaveSpace (str, globalSearch) {
  const regex = (globalSearch ? /\u00a0/ : /\u00a0/g)
  return typeof str === 'string' ? (str || '').replace(regex, '\u0020') : str
}

export default {
  replaceSpace,
  replaceSaveSpace,
}
