import { FlatList, StyleSheet, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'
import i18n from '@/i18n'
import searchCategories from '@/libs/searchCategories'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SearchBar from '@/components/SearchBar'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'

/**
 * @typedef ItemListProps
 * @property {string[]} menu
 * @property {() => void} onClose
 * @property {string} resetId
 * @property {() => void} onChange
 * @property {boolean} noBackButton
 * @property {string} type
 * @property {Promise<any>} onSave
 * @property {boolean} search
 * @property {string[]} includedCategories
 * @property {string[]} includedItems
 */

/**
 *
 * @param {ItemListProps} props
 * @returns
 */
export default function ItemList (props) {
  const {
    menu = [],
    onClose,
    resetId,
    onChange = () => {},
    noBackButton = false,
    type = 'menus',
    onSave,
    search = false,
    includedCategories = [],
    includedItems = [],
  } = props

  const { t } = useTranslation()
  const [selectedCategory,
    setSelectedCategory] = React.useState(null)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const [searchText, setSearchText] = React.useState('')
  const { rootCategory, rootSetCategory } = searchCategories(searchText)
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const [items, setItems] = useImmer({})
  const [hideSearch, setHideSearch] = React.useState(true)
  const [filterNotSetCategory, setFilterNotSetCategory] = React.useState(false)
  const allCategories = useSelector(state => state.menu.categories)

  const categories = _.flatMap([...rootCategory, ...rootSetCategory], root => {
    if (_.size(root.categories) > 0) {
      return root.categories
    }
    return root
  }).map(cat => {
    if (!filterNotSetCategory) {
      return cat
    }
    const filteredMenu = _.filter(cat.menus, m => !_.includes(includedItems, m.id))
    return { ...cat, menus: filteredMenu }
  }).filter(cat => {
    if (!filterNotSetCategory) {
      return cat
    }
    return _.size(cat.menus) > 0 && !_.includes(includedCategories, cat.id)
  })

  const filteredCategories = _.filter(categories, category => {
    if (
      category.id === 'ROOT' ||
      category.id === 'SET_ROOT' ||
      category.categories.length > 0 ||
      category[type]?.length === 0) {
      return false
    }
    return true
  })

  React.useEffect(() => {
    const cat = _.find(filteredCategories, f => f.id === selectedCategory)
    setSelectedCategory(null)
    setOptionsPanelConfig(null)
    if (cat) {
      setSelectedCategory(cat.id)
      const menuOptions = _.map(cat.menus, m => {
        return {
          value: m.id,
          display: _.get(m?.localeNames, lang, m.name),
        }
      })
      const selectedMenus = items[cat.id]
      setOptionsPanelConfig({
        key: `category-auto:${cat.id}`,
        multiple: true,
        defaultValue: selectedMenus,
        onChangeValue: (value) => {
          setItems(draft => {
            draft[cat.id] = value
          })
          onChange({ ...items, [cat.id]: value })
        },
        options: menuOptions,
      })
    }
  }, [searchText, filterNotSetCategory])

  React.useEffect(() => {
    const defaultSelectedMenu = {}
    _.each(filteredCategories, category => {
      const menus = category[type] ?? []
      const defaultMenus = menus.filter(m => menu.includes(m.id)).map(m => m.id)
      defaultSelectedMenu[category.id] = defaultMenus
    })
    setItems(defaultSelectedMenu)
    setOptionsPanelConfig(null)
  }, [resetId])

  const onSubmit = () => {
    const selectedMenus = _.uniq(_.reduce(Object.values(items), (result, item) => result.concat(item), []))
    onClose(selectedMenus)
  }

  const renderItem = ({ item, index }) => {
    const menus = item[type] ?? []
    const menuOptions = menus.map(m => {
      return {
        value: m.id,
        display: _.get(m?.localeNames, lang, m.name),
      }
    })
    const selectedMenus = items[item.id]
    const name = _.get(item?.localeNames, lang, item.name)
    const subCategory = item.parentId !== 'ROOT' && item.parentId !== 'SET_ROOT'
    const parentName = _.get(allCategories, `${item.parentId}.name`, '')
    return (
      <SettingListEnterRow
        divider={index < filteredCategories?.length - 1}
        label={`${subCategory ? `${parentName} - ` : ''}${name}`}
        value={selectedMenus?.length}
        selected={item.id === selectedCategory}
        onPress={() => {
          setOptionsPanelConfig({
            key: `category${index}:${item.id}`,
            multiple: true,
            defaultValue: selectedMenus,
            onChangeValue: (value) => {
              setItems(draft => {
                draft[item.id] = value
              })
              onChange({ ...items, [item.id]: value })
            },
            options: menuOptions,
          })
          setSelectedCategory(item.id)
        }}
      />
    )
  }

  return (
    <View style={styles.container}>
      {
        search && (
          <View style={styles.titleContainer}>
            <SearchBar
              style={styles.searchBar}
              value={searchText}
              onChangeText={(text) => setSearchText(text)}
              onCancel={() => {
                setSearchText('')
                setHideSearch(true)
              }}
              onOpen={() => setHideSearch(false)}
              hidden={hideSearch}
            />
            {
              hideSearch && (
                <Button
                  title={t(filterNotSetCategory ? 'app.page.order.stash.alert.leave.button.cancel' : 'app.page.setting.printer.notSelectedItem')}
                  textColor={colors.white}
                  style={styles.button}
                  backgroundColor={filterNotSetCategory ? colors.textTertiary : colors.lightPrimary}
                  textBold
                  type='TouchableOpacity'
                  onPress={() => setFilterNotSetCategory(!filterNotSetCategory)}
                />
              )
            }
          </View>
        )
      }
      <View style={styles.bottomContainer}>
        <View style={styles.contentContainer}>
          <Header onBack={!noBackButton && onSubmit}>
            <HeaderText text={t('app.page.setting.printer.category')} />
          </Header>
          <SettingList style={styles.settingList}>
            <FlatList
              style={styles.paymentList}
              data={filteredCategories}
              renderItem={renderItem}
              keyExtractor={(item, index) => String(index)}
              showsVerticalScrollIndicator={false}
              ListEmptyComponent={(
                <View style={styles.emptyView} />
              )}
            />
          </SettingList>
        </View>
        <View style={styles.contentContainerRight}>
          {
            optionsPanelConfig && (
              <OptionsPanel
                title={`${t('app.page.setting.printer.item') + t('app.page.setting.printer.setting')}`}
                {...optionsPanelConfig}
              />
            )
          }
        </View>
      </View>
      {
        Boolean(onSave) && (
          <View style={styles.buttonContainer}>
            <Button
              textBold
              title={t('app.common.save')}
              backgroundColor={colors.darkSecondary}
              onPress={() => onSave(_.uniq(_.reduce(Object.values(items), (result, item) => result.concat(item), [])))}
            />
          </View>
        )
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 50,
  },
  bottomContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
  },
  contentContainerRight: {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
    marginLeft: 10,
  },
  settingList: {
    marginBottom: 10,
    flex: 1,
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  searchBar: {
    height: 35,
    width: '100%',
  },
  titleContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 30,
  },
  button: {
    width: 'auto',
    minWidth: 100,
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
})
