import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { PrintReason } from '@/constants/printing'
import { actions, useDispatch, useSelector } from '@/redux'
import { loadingKey } from '@/constants'
import Column from '@/components/Column'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

import ActionButton from './ActionButton'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OrderActions (props) {
  const dispatch = useDispatch()
  const { isSelecting, selectedOrders, isMoving } = useSelector(state => state.table)
  const { selectPrinterBeforePrint } = useSelector(state => state.app.settings)
  const { t } = useTranslation()
  const moveErrorMessage = t('app.page.table.orderActions.moveErrorMessage')
  const mergeErrorMessage = t('app.page.table.orderActions.mergeErrorMessage')
  const merge = t('app.page.table.orderActions.merge')
  const isMerge = t('app.page.table.orderActions.isMerge')
  const to = t('app.page.table.orderActions.to')
  const unselect = t('app.page.table.orderActions.unselect')
  const select = t('app.page.table.orderActions.select')
  const move = t('app.page.table.orderActions.move')
  const mergeOrder = t('app.page.table.orderActions.mergeOrder')
  const mergeCheckout = t('app.page.table.orderActions.mergeCheckout')
  const error = t('app.common.error')
  const confirm = t('app.common.confirm')
  const cancel = t('app.common.cancel')
  const combine = t('app.page.table.orderActions.combine')
  const printReceipt = t('app.constant.orderAction.print')
  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)

  const handleMoveTable = () => {
    if (selectedOrders.length < 1) {
      dispatch(actions.app.showSimpleAlert(error, moveErrorMessage))
      return
    }
    // 開始選取座位
    dispatch(actions.table.updateMoving(true))
  }

  const handleMergeOrder = (actionText, action) => {
    if (selectedOrders.length < 2) {
      dispatch(actions.app.showSimpleAlert(error, `${mergeErrorMessage}${actionText}`))
      return
    }
    const orderId = _.last(selectedOrders).serial.slice(-3)
    const fromOrderIds = _.map(_.take(selectedOrders, selectedOrders.length - 1), fromOrder => fromOrder.serial.slice(-3))

    // 確認視窗
    const alertConfig = {
      title: merge,
      message: `${isMerge}${fromOrderIds.join('、')}${actionText}${to}${orderId}？`,
      buttons: [
        {
          title: confirm,
          type: 'TouchableOpacity',
          textStyle: { fontWeight: 'bold' },
          onPress: () => {
            dispatch(action())
          },
        },
        {
          title: cancel,
          type: 'TouchableOpacity',
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          textStyle: { fontWeight: 'bold' },
        },
      ],
    }
    dispatch(actions.app.showAlert(alertConfig))
  }

  const handlePrintReceipt = async (selectedPrinterId) => {
    dispatch(actions.table.updateSelectingTable(false))
    await dispatch(actions.app.openLoading(loadingKey.PRINT, 'ActionPanel-selectedPrinter-receipt'))
    _.each(selectedOrders, order => {
      dispatch(actions.printer.printOrderReceipt({
        order,
        selectedPrinterId,
        printReason: PrintReason.ORDER_RECEIPT.CLICK_PRINT_RECEIPT,
      }))
    })
    dispatch(actions.table.resetSelectingOrder())
  }

  // const handleAddTable = () => {
  //   // TODO: Add table?
  // }

  return (
    <Column style={styles.container}>
      <Column style={styles.mainContainer}>
        <ActionButton
          text={isSelecting ? unselect : select}
          iconSource={require('@icons/table/select-order.png')}
          style={[styles.button, isSelecting && { backgroundColor: colors.secondary }]}
          onPress={() => isSelecting ? dispatch(actions.table.resetSelectingOrder())
            : dispatch(actions.table.updateSelecting(true))}
        />
        <ActionButton
          text={move}
          iconSource={require('@icons/table/move-table.png')}
          style={[styles.button, isMoving && { backgroundColor: colors.secondary }]}
          disabled={!isSelecting}
          onPress={() => {
            dispatch(actions.auth.permissionCheck('item-transfer',
              () => {
                handleMoveTable()
              },
            ))
          }}
        />
        <ActionButton
          text={mergeOrder}
          iconSource={require('@icons/table/merge-order.png')}
          style={styles.button}
          disabled={!isSelecting || isMoving}
          onPress={() => handleMergeOrder(combine, actions.table.mergeOrder)}
        />
        <ActionButton
          text={mergeCheckout}
          iconSource={require('@icons/table/merge-checkout.png')}
          style={styles.button}
          disabled={!isSelecting || isMoving}
          onPress={() => handleMergeOrder(mergeCheckout, actions.table.mergeCheckout)}
        />
        <ActionButton
          text={printReceipt}
          iconSource={require('@icons/orders/order-action/print-receipt.png')}
          style={styles.button}
          disabled={!isSelecting || isMoving}
          onPress={async () => {
            if (selectedOrders.length < 1) {
              dispatch(actions.app.showSimpleAlert(error, moveErrorMessage))
              return
            }
            if (selectPrinterBeforePrint) {
              setPrinterDialogOpen(true)
            } else {
              await handlePrintReceipt()
            }
          }}
        />
      </Column>
      <PrinterDialog
        open={printerDialogOpen}
        onClose={() => setPrinterDialogOpen(false)}
        onSelect={async (selectedPrinterId) => {
          await handlePrintReceipt(selectedPrinterId)
        }}
      />
      {/* <ActionButton
        text='臨時加桌'
        iconSource={require('@icons/table/add-table.png')}
        style={styles.extraButton}
        textStyle={styles.extraButtonText}
        onPress={handleAddTable}
      /> */}
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 130,
    alignItems: 'center',
    backgroundColor: colors.lightGray3,
    borderBottomRightRadius: 20,
    marginLeft: -30, // cover the backgorund under AreaBar
    paddingLeft: 30, // cover the backgorund under AreaBar
    paddingTop: 8,
    paddingBottom: 70,
    ...shadows.default,
    zIndex: 1,
    elevation: 1,
  },
  mainContainer: {
    flex: 1,
  },
  button: {
    marginVertical: 6,
  },
  extraButton: {
    backgroundColor: colors.superlightPrimary,
  },
  extraButtonText: {
    color: colors.primary,
  },
})
