import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { PrinterType } from '@/constants/printer'
import { useSelector } from '@/redux'
import PrinterClient from '@/libs/printing/PrinterClient'
import colors from '@/theme/colors'
// import PrintDoc from '@/libs/printing/PrintDoc'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import ChipLabel from '@/components/ChipLabel'
import SettingList from '@/components/Setting/SettingList'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import StatusDot from '@/components/StatusDot'

const STATUS_COLORS = {
  [PrinterClient.STATUS.CONNECTING]: colors.tertiary,
  [PrinterClient.STATUS.CONNECTED]: colors.success,
  [PrinterClient.STATUS.ERROR]: colors.error,
  [PrinterClient.STATUS.CLOSED]: colors.gray,
  undefined: colors.gray,
}

/**
 * @typedef PrinterDialogProps
 * @param {boolean} open
 * @param {() => void} onClose
 * @param {() => void} onSelect
 * @param {boolean?} mutiSelect
 * @param {boolean?} enablePressOutsideClose
 * @param {boolean?} noCloseButton
 */

/**
 *
 * @param {PrinterDialogProps} props
 * @returns
 */
export default function PrinterDialog (props) {
  const {
    open,
    onClose,
    onSelect,
    mutiSelect = false,
    enablePressOutsideClose = true,
    noCloseButton = false,
  } = props
  const { t } = useTranslation()
  const printerStatus = useSelector(state => state.printer.printerStatus)
  const allPrinters = useSelector(state => state.printer.printerSetting.printers)
  const printers = allPrinters?.filter(printer => printer.printerType !== PrinterType.TDP225)
  const defaultPrinter = useSelector(state => state.printer.printerSetting.defaultPrinter)
  const qrcodePrinter = useSelector(state => state.printer.printerSetting.qrcodePrinter)
  const reportPrinter = useSelector(state => state.printer.printerSetting.reportPrinter)
  const [selectedPrinters, setSelectedPrinters] = useImmer([])

  let title = t('app.component.printerDialog.choosePrinter')
  if (mutiSelect) {
    title = t('app.component.printerDialog.choosePrinterForSetting')
  }
  const confirmButtonText = t('app.component.printerDialog.confirm')
  const cancelButtonText = t('app.component.printerDialog.cancel')

  const renderItem = ({ item: printer, index }) => {
    const printerState = printerStatus[printer.id]
    const statusColor = STATUS_COLORS[_.get(printerState, 'status') ?? 'ERROR']
    const isDefaultPrinter = printer.id === defaultPrinter
    const isQRCodePrinter = printer.id === qrcodePrinter
    const isReportPrinter = printer.id === reportPrinter
    const selected = Boolean(selectedPrinters.find((selectedPrinter) => selectedPrinter === printer.id))
    return (
      <SettingListItem
        selected={selected}
        key={printer.id}
        onPress={async () => {
          if (typeof onSelect === 'function' && !mutiSelect) {
            onSelect(printer.id)
            onClose()
          } else if (mutiSelect) {
            if (selected) {
              setSelectedPrinters(_.remove(selectedPrinters, (selectedPrinter) => selectedPrinter !== printer.id))
            } else {
              setSelectedPrinters([...selectedPrinters, printer.id])
            }
          }
        }}
        divider={index !== printers.length - 1}
      >
        <StatusDot color={statusColor} />
        <SettingListItemText text={printer.name || printer.id} />
        <SettingListItemRightAction style={styles.labels}>
          {isDefaultPrinter && <ChipLabel label={t('app.page.setting.printer.default')} active />}
          {isQRCodePrinter && <ChipLabel label={t('app.page.setting.printer.qrcode')} active />}
          {isReportPrinter && <ChipLabel label={t('app.page.setting.printer.report')} active />}
        </SettingListItemRightAction>
      </SettingListItem>
    )
  }

  return (
    <CenterModal
      enablePressOutsideClose={!enablePressOutsideClose}
      noCloseButton={noCloseButton}
      onClose={onClose}
      visible={open}
      contentContainerStyle={styles.printerModal}
      title={title}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.content}>
            <SettingList>
              <FlatList
                showsVerticalScrollIndicator={false}
                data={printers}
                ListEmptyComponent={(
                  <View style={styles.emptyView}>
                    <Text>{t('app.page.setting.printer.noDevice')}</Text>
                  </View>
                )}
                keyExtractor={(item) => item.id}
                renderItem={renderItem}
              />
            </SettingList>
          </View>
          {
            mutiSelect && (
              <View style={styles.buttons}>
                <Button
                  title={cancelButtonText}
                  backgroundColor={colors.primary}
                  onPress={() => {
                    setSelectedPrinters([])
                    onClose()
                  }}
                />
                <Button
                  title={confirmButtonText}
                  onPress={() => {
                    onSelect(selectedPrinters)
                  }}
                />
              </View>
            )
          }
        </View>
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  emptyView: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labels: {
    padding: 0,
  },
  printerModal: {
    height: 230,
    width: 450,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
