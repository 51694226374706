import { StyleSheet, View } from 'react-native'
import React from 'react'

import colors from '@/theme/colors'
// import useDateTime from '@/hooks/dateTime'

/**
 *
 * @param {*} props
 * @returns
 */
export default function StatusBar (props) {
  // const [current] = useDateTime()

  return (
    <View style={styles.container}>
      {/* <Text style={styles.text}>版本：V201.0.3</Text>
      <Text style={styles.text}>目前IP：user12</Text>
      <Text style={styles.text}>連線狀態：online</Text>
      <Text style={styles.text}>上次登入時間：21:12 14-07-2020 </Text>
      <Text style={styles.text}>目前時間：
        {current.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })} {current.toLocaleDateString([], { year: '2-digit', month: '2-digit', day: '2-digit' })}
      </Text> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 819,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 30,
  },
  text: {
    fontSize: 13,
    color: colors.white,

  },
})
