import { v4 as uuid } from 'uuid'
import Constants from 'expo-constants'
import _ from 'lodash'
import moment from 'moment'

import { PrintReason } from '@/constants/printing'
import { actions } from '@/redux'
import { calculateStepItems, getDiscount, getSurcharge, isOptionsComplete, isSetItemComplete } from '@/libs/order'
import { dimorderApi, dimorderLib } from '@/libs/api/dimorder'
import { getSelectedTableArea } from '@/libs/table'
import { loadingKey, webSocketStatusCode } from '@/constants'
import colors from '@/theme/colors'
import delay from '@/libs/delay'
import genSerial from '@/libs/genSerial'
import i18n from '@/i18n'
import orderEvent from '@/libs/orderEvent'

import { initBatch, initOrder } from './reducer'
import ActionTypes from './ActionTypes'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IUpdateOrderRequestData } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function selectOrderById (orderId) {
  return async (dispatch, getState) => {
    const historyOrders = getState().orderHistory.orders
    const order = historyOrders.find((order) => order.id === orderId)
    dispatch(selectOrder(order))
  }
}

/**
 * @param {IAppOrder} targetOrder
 * @returns {ThunkFunction}
 */
export function selectOrder (targetOrder) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_ORDER,
      payload: { order: targetOrder },
    })
  }
}

/**
 * @param {TDeliveryType} deliveryType
 * @returns {ThunkFunction}
 */
export function createLocalOrder (deliveryType = 'takeaway') {
  return async (dispatch, getState) => {
    const surcharge = getState().merchant.data.surcharge
    const merchnatId = getState().merchant.data.id
    const takeawayPickupAfterMins = getState().merchant.data?.setting?.takeawayPickupAfterMins
    const now = moment()
    const orderId = uuid()
    const order = {
      ...initOrder,
      serial: '', // 暫時用 orderId 開頭來當 serial
      id: orderId,
      deliveryType,
      surcharge,
      creatorId: merchnatId,
      createdAt: now.utc().toISOString(),
      pickupAt: (deliveryType === 'table' ? '' : moment().add(takeawayPickupAfterMins || 0, 'm').toISOString()),
    }
    dispatch(selectOrder(order))
    dispatch(actions.orderHistory.selectOrder(order.id))
  }
}

/**
 * @param {Partial<IAppOrder>} updateFields
 * @returns {ThunkFunction}
 */
export function updateSelectedOrderFields (updateFields) {
  return async (dispatch, getState) => {
    const surcharge = getState().merchant.data.surcharge
    const selectedOrder = getState().order.selectedOrder
    if (selectedOrder.serial) {
      console.log('已送出的訂單無法直接修改')
      return
    }
    console.log('updateFields', updateFields)
    console.log('selectedOrder', selectedOrder)

    const updatedOrder = {
      ...selectedOrder,
      ...updateFields,
    }

    if (updatedOrder.deliveryType === 'table') {
      updatedOrder.surcharge = surcharge
    } else {
      updatedOrder.surcharge = { percent: 0, amount: 0 }
    }

    dispatch(selectOrder(updatedOrder))
  }
}

/**
 * @param {IUpdateOrderRequestData} updateFields
 * @returns {ThunkFunction}
 */
export function updateOrder (updateFields) {
  return async (dispatch, getState) => {
    try {
      const selectedOrder = getState().order.selectedOrder
      if (!selectedOrder.serial) {
        // 訂單未送出，不用改到 server
        dispatch(selectOrder(updateFields))
        return
      }

      // 更新欄位
      const data = _.pick(updateFields, [
        'name',
        'phone',
        'address',
        'adults',
        'children',
        'remark',
        'needTableware',
      ])

      // FIXME: 為什麼這裡會做 api request，這樣離線時怎麼辦？
      let order = await dimorderApi.order.updateOrder(selectedOrder.id, data)

      // 更新 order tags
      if (!_.isEmpty(updateFields.tags)) {
        // FIXME: 為什麼這裡會做 api request，這樣離線時怎麼辦？
        order = await dimorderApi.order.updateTags(
          selectedOrder.id,
          updateFields.tags,
        )
      }

      dispatch(selectOrder(order))
    } catch (error) {
      // TODO: 沒有 handle error
      console.log('updateOrder error', error)
    }
  }
}

/**
 * @param {string?} orderId 有給 id 會從 orderHistory 中選擇
 * @param {string?} batchId 如果要指定 batch 可以給 batchId
 * @returns {ThunkFunction}
 */
export function startOrder (orderId, batchId) {
  return (dispatch, getState) => {
    const merchantId = getState().merchant.data.id
    if (orderId) {
      // 如果有給 orderId 從 orderHistory.orders 找出 order 來用
      const historyOrders = getState().orderHistory.orders
      const order = historyOrders.find((order) => order.id === orderId)
      dispatch(selectOrder(order))
    }
    const selectedOrder = getState().order.selectedOrder
    const selectedBatch = getState().orderBatch.selectedBatch
    const deviceId = Constants.installationId

    if (selectedBatch?.orderId === selectedOrder.id) {
      // 選擇的訂單沒有變
      const sendedOrderBatch = selectedOrder.batches.find(
        (batch) => batch.id === selectedBatch.batchId,
      )
      if (!sendedOrderBatch && !selectedBatch.updatedAt) {
        // 選擇的 batch 還沒送出，可以直接繼續點餐
        window.applicatiionHistory.push('/order')
        return
      }
    }

    if (batchId) {
      const batch = selectedOrder.batches.find((batch) => batch.id === batchId)
      if (!batch.updatedAt) {
        // batch 未送出，可以選擇 batch 繼續點餐
        dispatch(actions.orderBatch.selectBatch(batch))
        window.applicatiionHistory.push('/order')
        return
      }
    }

    // 選擇的 order 變了或是沒選 batch 或是選擇的 batch 已送出時
    // 新開一個 batch 來點餐
    const newBatchId = uuid()
    const now = moment()
    dispatch(
      actions.orderBatch.selectBatch({
        ...initBatch,
        deviceId: deviceId,
        id: newBatchId,
        orderId: selectedOrder.id,
        orderSerial: selectedOrder.serial,
        batchId: newBatchId,
        index: selectedOrder.batches.length,
        table: selectedOrder.table,
        status: 'confirmed',
        createdAt: now.utc().toISOString(),
        updatedAt: now.utc().toISOString(),
        creatorId: merchantId,
      }),
    )

    window.applicatiionHistory.push('/order')
  }
}
/**
 * @param {Partial<IAppOrder>} localOrder
 * @returns {ThunkFunction}
 */
export function createOrder (localOrder, categoryTagId = null, selectedPrinterId = '', isTransferItem = false, syncOrder = true) {
  return async (dispatch, getState) => {
    const tags = getState().menu.categoryTags
    const { rounding } = getState().merchant.data
    const menus = getState().menu.menus
    const { rapidOrder } = getState().app.settings
    const merchantOrderCutOffMins = getState().merchant.data?.setting?.orderCutOffMins
    const categoryTag = tags?.find(tag => tag.id === categoryTagId)
    let orderCutOffMins = 0
    if (categoryTagId && categoryTag) {
      orderCutOffMins = categoryTag.orderCutOffMins
    } else if (merchantOrderCutOffMins) {
      orderCutOffMins = merchantOrderCutOffMins
    }
    const selectedTableArea = getSelectedTableArea()
    const now = moment()
    const serial = genSerial()
    const modifiers = []
    const filteredMenu = []

    let surcharge = {
      percent: 0,
      amount: 0,
      overrideItem: false,
      useDefault: false,
    }
    let discount = {
      percent: 0,
      amount: 0,
      overrideItem: false,
      useDefault: false,
    }

    if (localOrder.deliveryType === 'table') {
      surcharge = getSurcharge(selectedTableArea)
      modifiers.push({
        ...surcharge,
        id: 'SURCHARGE',
        type: 'SURCHARGE',
        applyTo: 'PRODUCT',
      })
    }
    discount = getDiscount(selectedTableArea)

    modifiers.push({
      ...discount,
      percent: -Math.abs(discount.percent),
      id: 'DISCOUNT',
      type: 'DISCOUNT',
      applyTo: 'PRODUCT',
    })
    const updateValue = {
      serial: serial,
      orderSerial: serial,
      surcharge: surcharge,
      createdAt: now.utc().toISOString(),
      updatedAt: now.utc().toISOString(),
      customers: localOrder.children + localOrder.adults,
      modifiers: modifiers,
      rounding: rounding,
      categoryTag: categoryTagId,
      valid: true,
      ...orderCutOffMins && { orderCutOffMins },
    }
    const order = { ...localOrder, ...updateValue }
    orderEvent.emitter.emit(orderEvent.eventType.ORDER_CREATED, order)
    dispatch(actions.app.increaseSerial())

    const setting = getState().merchant.data?.setting
    const netInfo = getState().app.netInfo
    const isOnline = (netInfo?.isInternetReachable === true || netInfo?.isInternetReachable === null) &&
    ((netInfo?.isConnected && netInfo?.type !== null) || netInfo?.type === null)

    if (order.deliveryType === 'table') {
      dispatch(actions.orderHistory.updateOrder(order, { selectOrder: true, syncOrder }))
      await delay(500)
      if (!isTransferItem) {
        if (setting.qrcode && setting.enableDynamicQRCode) {
          if (!setting.enableManualPrintQR && isOnline) {
            dispatch(actions.printer.printQRCode(order, setting, selectedPrinterId, PrintReason.QR_CODE.NEW_ORDER))
          }
          for (const [key, value] of Object.entries(menus)) {
            if (value?.openingPrompt) {
              filteredMenu.push(value)
            }
          }
          if (filteredMenu.length > 0) {
            dispatch(actions.table.showDialog(['openingPrompt']))
          } else if (!rapidOrder) {
            dispatch(actions.table.showDialog(['table']))
          } else if (rapidOrder === 'place') {
            dispatch(actions.order.startOrder())
          }
        } else if (!rapidOrder) {
          dispatch(actions.table.showDialog(['table']))
        }
      }
    }

    // 加上order tags
    // if (localOrder.tags?.length > 0) {
    //   order = await dimorderApi.order.updateTags(order.id, localOrder.tags)
    // }

    await dispatch(selectOrder(order))
    if (!setting.qrDisableCustomerCount && order.deliveryType === 'table') {
      await dispatch(addAutoItemToOrder())
    }
    if (rapidOrder === 'place' && !(setting.qrcode && setting.enableDynamicQRCode)) {
      await dispatch(selectOrder(order))
      dispatch(actions.order.startOrder())
    }
    return order
  }
}

/**
 * @returns {ThunkFunction}
 */
export function addAutoItemToOrder () {
  return async (dispatch, getState) => {
    try {
      const merchantId = getState().merchant.data.id
      const menus = getState().menu.menus
      const items = _.values(menus).filter(m => m.tableAutoCount)
      const selectedOrder = getState().order.selectedOrder
      const customers = _.get(selectedOrder, 'customers', 0)
      if (!_.isEmpty(items) && customers > 0) {
        const newBatchId = uuid()
        await dispatch(
          actions.orderBatch.selectBatch({
            ...initBatch,
            id: newBatchId,
            orderId: selectedOrder.id,
            orderSerial: selectedOrder.serial,
            batchId: newBatchId,
            index: selectedOrder.batches.length,
            table: selectedOrder.table,
            status: 'confirmed',
            createdAt: new Date(),
            creatorId: merchantId,
          }),
        )
        const promises = _.map(items, item => {
          return dispatch(actions.orderBatch.addItem(item, customers))
        })
        await Promise.all(promises)
        await dispatch(submitOrderBatch({ ignoreUnavailable: false, unpaid: false }))
        await dispatch(selectOrder(selectedOrder))
      }
    } catch (error) {
      console.log('addAutoItemToOrder error', error)
    }
  }
}

/**
 * @typedef {Object} SubmitOrderBatchOptions
 * @property {boolean} [cashQuickCheckout=false]
 * @property {boolean} [ignoreUnavailable=false]
 * @property {boolean} [unpaid=false]
 * @param {SubmitOrderBatchParams} options
 * @returns {ThunkFunction}
 */
export function submitOrderBatch (options = {}) {
  return async (dispatch, getState) => {
    const { cashQuickCheckout = false, ignoreUnavailable = false, unpaid = false } = options
    const state = getState()
    const { menus, sets } = state.menu
    const { payFirst, inventory: isInventoryEnabled } = state.merchant.data?.setting
    let selectedOrder = state.order.selectedOrder
    const { selectedBatch, menuItemQuantities } = state.orderBatch
    const { printStaff, redirectToTablePage } = getState().app.settings
    const printConfig = { printStaff }
    const deviceId = Constants.installationId
    const now = moment()
    const userLogin = getState().auth.userLogin

    // 內用設定先結帳或外賣自取非預約自取
    const directCheckout =
      (selectedOrder.deliveryType === 'table' && payFirst) ||
      (selectedOrder.deliveryType === 'takeaway' && !unpaid)

    const errorMessages = []
    let selectItemOrStep = true
    selectedBatch.items.forEach((item) => {
      if (directCheckout || selectedOrder.deliveryType === 'storeDelivery') {
        // 直接結帳要先檢查時價是否都設定完成
        if (item.priceUndetermined && !item.cancelled) {
          errorMessages.push(item.name + i18n.t('app.component.orderError.priceUndetermined'))
        }
        item.setItems.forEach((setItem) => {
          if (setItem.priceUndetermined && !setItem.cancelled) {
            errorMessages.push(setItem.name + i18n.t('app.component.orderError.priceUndetermined'))
          }
        })
      }
      if (!isOptionsComplete(item, sets[item.menuId] || menus[item.menuId])) {
        if (selectItemOrStep) {
          dispatch(actions.orderBatch.selectItem(item.key))
          selectItemOrStep = false
        }
        errorMessages.push(item.name + i18n.t('app.component.orderError.options'))
      }
      item.setItems.forEach((setItem) => {
        if (!isOptionsComplete(setItem, menus[setItem.menuId])) {
          if (selectItemOrStep) {
            dispatch(actions.orderBatch.selectItem(item.key, setItem.key))
            selectItemOrStep = false
          }
          errorMessages.push(`${item.name + i18n.t('app.component.orderError.of') + setItem.name + i18n.t('app.component.orderError.options')}`)
        }
      })

      // 找出未完成的套餐
      if (!isSetItemComplete(item, sets[item.menuId], null, true)) {
        errorMessages.push(item.name + i18n.t('app.component.orderError.set'))
        _.get(sets[item.menuId], 'steps', []).forEach(step => {
          const count = calculateStepItems(item, step)
          if (count > 0) {
            if (selectItemOrStep) {
              dispatch(actions.orderBatch.selectItem(item.key, step.key))
              dispatch(actions.menu.selectSet(sets[item.menuId]))
              dispatch(actions.menu.selectSetStep(step))
              selectItemOrStep = false
            }
            errorMessages.push(i18n.t('app.component.orderError.setStep', { name: step.name, count }))
          }
        })
      }
    })
    if (isInventoryEnabled) {
      // 找出庫存不足的項目
      _.forEach(menuItemQuantities, (orderedQuantity, menuId) => {
        const menu = menus[menuId]

        if (menu?.inventory && orderedQuantity > menu.inventory) {
          if (ignoreUnavailable) {
            dispatch(actions.orderBatch.deleteItemByMenuId(menuId))
          } else {
            errorMessages.push(menu.name + i18n.t('app.component.orderError.inventory'))
          }
        }
      })
    }

    if (errorMessages.length > 0) {
      dispatch(
        actions.app.showAlert({
          modalProps: { enablePressOutsideClose: true },
          title: i18n.t('app.component.orderError.submit'),
          messages: errorMessages,
        }),
      )
      // dispatch(actions.orderBatch.updateShowNotCompleteSets(true))
      return
    }

    await dispatch(actions.app.openLoading(loadingKey.SUBMIT_ORDER))
    if (!selectedOrder.serial) {
      // 先 create order
      selectedOrder = await dispatch(createOrder(selectedOrder))
    }

    const takeawayInfo = {}
    if (selectedOrder.deliveryType !== 'table') {
      takeawayInfo.name = selectedOrder.name
      takeawayInfo.phone = selectedOrder.phone
      takeawayInfo.address = selectedOrder.address
      console.log('submit selectedOrder', selectedOrder)

      if (selectedOrder.pickupAt != null) {
        // 預約單
        takeawayInfo.pickupAt = selectedOrder.pickupAt
      }
    }
    try {
      // submit batch
      const groupBatchItem = dimorderLib.groupBatchItem(selectedBatch.items)
      const batches = [...selectedOrder.batches]

      const calculatedBatchItem = _.map(groupBatchItem, (item) => {
        // 套餐項目需要按套餐的順序排
        if (item.isSet) {
          const sortedSetItems = _.orderBy(item.setItems, ['stepIndex', 'menuId'], 'asc')
          return {
            ...dimorderLib.formatBatchItemStatus(dimorderLib.calculateLocalBatchItem({ ...item, setItems: sortedSetItems }, selectedOrder)),
            createdAt: now.utc().toISOString(),
          }
        }
        return {
          ...dimorderLib.formatBatchItemStatus(dimorderLib.calculateLocalBatchItem(item, selectedOrder)),
          createdAt: now.utc().toISOString(),
        }
      })

      const submitBatch = {
        ...selectedBatch,
        items: calculatedBatchItem,
        deviceId: deviceId,
        submittedat: now.utc().toISOString(),
        confirmedat: now.utc().toISOString(),
        createdAt: now.utc().toISOString(),
        updatedAt: now.utc().toISOString(),
        confirmByDeviceId: deviceId,
        identifier: userLogin.account,
      }

      batches.push(submitBatch)

      const updateValue = {
        deliveryType: selectedOrder.deliveryType,
        batches: batches,
        shipping: selectedOrder.shipping,
        takeawayInfo: takeawayInfo,
        needTableware: selectedOrder.needTableware,
        sync: false,
        takeaway: selectedOrder.deliveryType !== 'table',
        updatedAt: now.utc().toISOString(),
      }

      if (selectedOrder.deliveryType !== 'table') {
        updateValue.status = 'pending'
        updateValue.takeawayStatus = 'ready'
        updateValue.createdAt = now.utc().toISOString()
        updateValue.updatedAt = now.utc().toISOString()
        updateValue.unpaid = unpaid
      }

      const newOrder = { ...selectedOrder, ...updateValue }
      console.log('newOrder', newOrder)
      if (!(payFirst && selectedOrder.deliveryType === 'table')) {
        dispatch(actions.printer.printKitchenBatch(newOrder, submitBatch.items, printConfig, true, submitBatch))
      }
      // 更新 order 到 orderHistory 並準備同步到後端
      const calculatedOrder = dispatch(actions.orderHistory.updateOrder(newOrder, { selectOrder: true, syncOrder: true }))

      // 下單時打印收據
      const invoiceSettings = getState().printer.printerSetting.invoiceSettings
      const defaultSettings = getState().printer.printerSetting.defaultSettings

      const enableSyncReceiptPrioritizedPrinter = invoiceSettings.length > 0
        ? invoiceSettings.some(o => o.syncReceiptPrioritizedPrinter)
        : defaultSettings.syncReceiptPrioritizedPrinter

      const enableSyncReceipt = invoiceSettings.length > 0
        ? invoiceSettings.some(o => o.syncReceipt)
        : defaultSettings.syncReceipt

      const enableSyncTakeawayReceipt = invoiceSettings.length > 0
        ? invoiceSettings.some(o => o.syncTakeawayReceipt)
        : defaultSettings.syncTakeawayReceipt

      const isSyncReceipt = (enableSyncReceipt && !calculatedOrder.takeaway) || (enableSyncTakeawayReceipt && calculatedOrder.takeaway)

      if (enableSyncReceiptPrioritizedPrinter) {
        dispatch(actions.printer.printOrderReceipt({
          order: calculatedOrder,
          printReason: PrintReason.ORDER_RECEIPT.ORDER_SUBMITTED,
          printConfig: { reprint: false },
        }))
      } else if (isSyncReceipt) {
        dispatch(actions.printer.printOrderReceipt({
          order: calculatedOrder,
          sync: true,
          printReason: PrintReason.ORDER_RECEIPT.CONFIRM_BATCH,
          printConfig: { reprint: false },
        }))
      }

      // 列印 batch
      orderEvent.emitter.emit(orderEvent.eventType.ORDER_SUBMITTED, submitBatch)

      if (cashQuickCheckout) {
        await delay(500)
        dispatch(actions.orderCheckout.checkoutOrder(calculatedOrder.id, cashQuickCheckout))
      } else if (directCheckout) {
        // 直接到結帳頁面
        await delay(500)
        dispatch(actions.orderCheckout.checkoutOrder(calculatedOrder.id))
      } else {
        // 沒有要結帳，回到 /orderHistory
        if (redirectToTablePage) {
          window.applicatiionHistory.push('/table')
        } else {
          window.applicatiionHistory.push('/orderHistory')
        }
        // 在 order history 選擇本訂單
        dispatch(actions.orderHistory.selectOrder(calculatedOrder.id))
      }
      // 重置 order，讓再進入落單頁時，會開啟新 order
      dispatch(actions.order.selectOrder(null))
      // 重置 orderBatch
      dispatch(actions.orderBatch.resetBatch())
    } catch (error) {
      console.log('error', error)
      if (error.response) {
        if (error.response.data?.error === 'some items are not available') {
          // 重新抓菜單以更新庫存狀態
          // await dispatch(actions.menu.fetchCategories())
          // 庫存不足
          const { items: soldOutItems, canContinue, inventory } = error.response.data
          const menus = getState().menu.menus
          _.forOwn(inventory, (number, itemId) => {
            const updatedMenus = menus[itemId]
            dispatch(actions.menu.updateMenuAndSetMenu({
              ...updatedMenus,
              inventory: number,
            }, 'UPDATE', 'inventory'))
          })

          // 整理出有在 batch 中的 sets
          const batchSets = selectedBatch.items
            .filter(batchItem => batchItem.isSet)

          const itemNames = _.uniq(soldOutItems.map(item => {
            if (item.setMenuID) {
              // 如果是套餐餐點，找出套餐來組合成 "套餐名稱 餐點名稱"
              const batchSet = batchSets.find(batchSet => {
                return batchSet.setItems.find(setItem => setItem.setMenuId === item.setMenuID)
              })
              if (batchSet) {
                return `${batchSet.name} ${item.name}`
              }
            }
            return item.name
          }))

          if (canContinue) {
            dispatch(actions.app.showAlert({
              title: i18n.t('app.component.orderError.inventory'),
              messages: [i18n.t('app.component.orderError.soldout_message'), i18n.t('app.component.orderError.continue_message'), ...itemNames],
              buttons: [
                {
                  children: i18n.t('app.component.orderError.continue'),
                  onPress: () => {
                    // ignoreUnavailable 重新 submit
                    dispatch(submitOrderBatch({ ignoreUnavailable: false, unpaid: unpaid }))
                  },
                },
                {
                  backgroundColor: colors.light,
                  textColor: colors.textTertiary,
                  children: i18n.t('app.component.orderError.back'),
                  onPress: () => {},
                },
              ],
            }),
            )
          } else {
            dispatch(actions.app.showAlert({
              title: i18n.t('app.component.orderError.inventory'),
              message: i18n.t('app.component.orderError.soldout_message2'),
              button: {
                backgroundColor: colors.light,
                textColor: colors.textTertiary,
                children: i18n.t('app.component.orderError.back'),
                onPress: () => {
                  dispatch(actions.orderBatch.resetBatch())
                },
              },
            }))
          }
        }
      }
    } finally {
      dispatch(actions.app.closeLoading(loadingKey.SUBMIT_ORDER))
    }
  }
}

/**
 * @param {string} itemId
 * @param {number} price
 * @returns {ThunkFunction}
 */
export function updateItemPrice (itemId, price) {
  return async (dispatch, getState) => {
    const selectedOrder = getState().order.selectedOrder

    const responseOrder = await dimorderApi.order.updateItemPrice(
      selectedOrder.id,
      itemId,
      price,
    )
    dispatch(selectOrder(responseOrder))
  }
}

/**
 * 選擇座位(Table)
 * @param {string} tableId
 */
export function selectTable (tableId) {
  return async (dispatch, getState) => {
    const tableKey = getState().table.tables.find((t) => t.id === tableId).key
    dispatch({
      type: ActionTypes.SELECT_TABLE,
      payload: { tableKey },
    })
  }
}

export function updateComboModifier (submittedBatchItems) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_COMBO_MODIFIER,
      payload: { submittedBatchItems },
    })
  }
}

export function groupBatchItem () {
  return async (dispatch, getState) => {
    const batches = getState().order.selectedOrder.batches
    const updatedBatch = batches.map((batch) => {
      return {
        ...batch,
        items: _.orderBy(dimorderLib.groupBatchItem(batch.items.map((item, index) => {
          return dimorderLib.formatBatchItemStatus(dimorderLib.calculateLocalBatchItem(item))
        },
        )), ['menuId'], ['asc']),
      }
    })
    dispatch({
      type: ActionTypes.UPDATE_BATCH_ITEM,
      payload: { updatedBatch },
    })
  }
}
export function serveTable (orderId) {
  return async (dispatch) => {
    try {
      // 關閉叫侍應
      dispatch(actions.orderHistory.updateOrderRequestWaiter(orderId, false))
      // 顯示已回應叫侍應檯號
      dispatch(actions.app.showAlert({
        title: i18n.t('app.component.serve.title'),
        message: i18n.t('app.component.serve.served'),
      }))
    } catch {
      dispatch(actions.app.showAlert({
        title: i18n.t('app.common.error'),
        message: i18n.t('app.component.serve.error'),
      }))
    }
  }
}

export function printedCheckoutReceipt (orderId) {
  return async (dispatch) => {
    try {
      await dimorderApi.order.printedCheckoutReceipt(orderId)
    } catch (error) {
      console.log('printedCheckoutReceipt error', error)
    }
  }
}
