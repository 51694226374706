import { StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { currencyWithCommas, numberWithCommas } from '@/libs/numberWithCommas'
import { useSelectedBatch, useSelectedBatchItem } from '@/hooks/order'
import { useTranslation } from 'react-i18next'
import DisabledLayer from '../DisabledLayer'
import InventoryTag from '../InventoryTag'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import colors from '@/theme/colors'
import i18n from '@/i18n'
import orderAppLib from 'dimorder-orderapp-lib'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from 'dimorder-orderapp-lib/dist/types/AppMenu'

MenuItem.propTypes = {
  item: PropTypes.object,
  isMax: PropTypes.bool,
}

/**
 * @param {{item: IAppMenuItem | IAppSet}} props
 */
export default function MenuItem (props) {
  const { t } = useTranslation()
  const { item: originalItem, isMax: stepIsMax, stepCount, setItems = [] } = props
  const dispatch = useDispatch()
  const selectedBatch = useSelectedBatch()
  const [selectedItem] = useSelectedBatchItem()
  const menus = useSelector(state => state.menu.menus)
  const selectedSet = useSelector(state => state.menu.selectedSet)
  const selectedSetStep = useSelector(state => state.menu.selectedSetStep)
  // fixme: add order.from to orderapplib?
  // wing suggestion!!!
  const item = orderAppLib.libs.setDeliveryTypeMenuPrice(originalItem, 'table')
  const isNumPadOrder = useSelector(state => state.app.settings?.numPadOrder)
  let isInventoryEnabled = useSelector(state => state.merchant.data?.setting?.inventory)
  const orderedQuantity = useSelector(state => state.orderBatch.menuItemQuantities[item.menuId] || 0)
  if (item.inventory === undefined) {
    // 沒設定數量時，不管庫存
    isInventoryEnabled = false
  }
  const stepItemQuantity = setItems.filter(o => o.menuId === originalItem.menuId).length
  // 可點數量 = 庫存 - 已點數量
  const inventory = item.inventory - orderedQuantity
  const isSoldout = inventory <= 0
  const isSuspended = item.sold
  const isMax = stepIsMax || (originalItem.max > 0 && stepItemQuantity >= originalItem.max)
  const isDisabled = (isInventoryEnabled && isSoldout) || isSuspended || isMax
  const disabledText = isInventoryEnabled && isSoldout
    ? t('app.page.order.orderMenu.sold')
    : isSuspended
      ? t('app.page.order.orderMenu.suspend')
      : t('app.page.order.orderMenu.stepMax')
  const price = item.priceUndetermined ? t('app.constant.order.priceUndetermined') : item.price ? (item.price - (item.discount ?? 0)) : 0
  const disabledFontSize = isMax ? 14 : 30
  const lang = String(i18n.language ?? 'zh').split('-')[0]
  const allMenus = useSelector(state => state.menu.menus)
  const allSets = useSelector(state => state.menu.sets)
  const localeName = item.isSet ? _.get(allSets[item.id]?.localeNames, lang) : _.get(allMenus[item.menuId]?.localeNames, lang)
  const originalMenu = _.get(menus, item.menuId)
  let shortName = item.shortName
  if (item.step && originalMenu) {
    shortName = originalMenu.shortName
  }

  return (
    <TouchableHighlight
      style={[styles.container, isNumPadOrder && styles.numPadModeContainer]}
      underlayColor={colors.darkSecondary}
      disabled={isDisabled}
      onPress={async () => {
        // discount 不可設定超過預設價格 price
        if (price < 0) {
          dispatch(actions.app.showAlert({
            modalProps: { enablePressOutsideClose: true },
            title: t('app.component.orderError.submit'),
            message: t('app.component.orderError.priceNegative'),
          }))
          return
        }

        if (item.step) {
          // * 套餐的 item
          dispatch(actions.orderBatch.addSetItem(item, selectedSet))
          const index = selectedSet?.steps.findIndex(o => o === selectedSetStep)
          const length = selectedSet?.steps.length
          const selectedSetItems = _.map(selectedItem?.setItems, s => s.setMenuId)
          selectedSetItems.push(item.id)
          if (
            stepCount === 1 &&
            (length - index > 1) &&
            (
              !selectedSet.steps[index + 1]?.dependsOnSetMenuItem ||
              selectedSetItems.includes(selectedSet.steps[index + 1].dependsOnSetMenuItem)
            ) &&
            (
              !selectedSet.steps[index + 1]?.dependsOnSetMenuItems?.length ||
              selectedSetItems.some(s => selectedSet.steps[index + 1].dependsOnSetMenuItems.includes(s))
            )
          ) {
            dispatch(actions.menu.selectSetStep(selectedSet.steps[index + 1]))
          }
        } else {
          // * 單點的 item
          await dispatch(actions.orderBatch.addItem(item))
        }

        // * 時價的 item
        if (item.priceUndetermined) {
          const existedItem = selectedBatch.items.find(o => o.menuId === item.menuId)
          if (existedItem) {
            if (!existedItem.priceUndetermined) {
              dispatch(actions.orderBatch.updatePrice(Number(existedItem.price)))
            }
          } else {
            dispatch(actions.app.showDialog(['order', 'inputPrice']))
          }
        }
      }}
    >
      <>
        <View style={styles.nameView}>
          <Text style={styles.text}>{item.code}</Text>
          <Text style={[styles.text, { flex: 1 }]} numberOfLines={3} ellipsizeMode='tail' wrap>{localeName ?? item.name}</Text>
        </View>
        <View style={[styles.priceView, item.isSet && styles.setPriceView]}>
          <Text style={[styles.text, { flex: 1, color: item.isSet ? colors.primary : colors.white }]}>{shortName}</Text>
          <Text style={[styles.text, { flex: 1, color: item.isSet ? colors.primary : colors.white }]}>＄{/^\d+$/.test(price) ? numberWithCommas(price) : price}</Text>
        </View>
        {isDisabled && (
          <DisabledLayer fontSize={disabledFontSize} text={disabledText} enabled={isDisabled} />
        )}

        {isDisabled && isInventoryEnabled && isSoldout && (
          <InventoryTag anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} inventory={inventory} />
        )}

      </>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 180,
    height: 70,
    borderRadius: 8,
    backgroundColor: colors.primary,
    overflow: 'hidden',
    marginBottom: 4,
    marginHorizontal: 10,
  },
  numPadModeContainer: {
    marginTop: 2,
    marginBottom: 8,
    marginLeft: 2,
    marginRight: 8,
  },
  nameView: {
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
  },
  priceView: {
    flex: 1.5,
    backgroundColor: colors.secondary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 2,
  },
  setPriceView: {
    backgroundColor: colors.tertiary,
  },
  text: {
    color: colors.white,
    fontSize: 15,
    fontWeight: '500',
    lineHeight: 17,
    textAlign: 'center',
    margin: 2,
  },
})
