/* global __DEV__ */
import { LogBox, StyleSheet, View } from 'react-native'
import { parseInt } from 'lodash'
import { useTranslation } from 'react-i18next'
import DummyOrderDialog from 'src/components/dialogs/DummyOrderDialog.jsx'
import React from 'react'

import { LinearProgress } from 'react-native-elements'
import { actions, useDispatch, useSelector } from '@/redux'
import { dimorderApi } from '@/libs/api/dimorder'
import { replaceSaveSpace, replaceSpace } from '@/libs/strReplace'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import Row from '@/components/Row'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListInputAdornment from '@/components/Setting/SettingListInputAdornment'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemEnter from '@/components/Setting/SettingListItemEnter'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import TextInputDialog from '@/components/dialogs/TextInputDialog'
import ToggleSwitch from '@/components/ToggleSwitch'
import colors from '@/theme/colors'
import fileIO from '@/libs/fileIO'
import firebase from '@/libs/firebase'

const defaultCustomText = {
  qrCustomText: '',
  recommendCustomText: '',
  footerCustomText: '',
}
export default function Setting () {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const deviceLocalesOptions = [
    { key: 'zh-HK', text: t('app.page.setting.localPanel.option.language.zh') },
    { key: 'en-US', text: t('app.page.setting.localPanel.option.language.en') },
  ]

  const screenLockOptions = [
    { key: 0, text: t('app.page.setting.localPanel.option.screenLock.never') },
    { key: 10000, text: t('app.page.setting.localPanel.option.screenLock.10s') },
    { key: 15000, text: t('app.page.setting.localPanel.option.screenLock.15s') },
    { key: 30000, text: t('app.page.setting.localPanel.option.screenLock.30s') },
    { key: 60000, text: t('app.page.setting.localPanel.option.screenLock.1m') },
    { key: 180000, text: t('app.page.setting.localPanel.option.screenLock.3m') },
    { key: 300000, text: t('app.page.setting.localPanel.option.screenLock.5m') },
    { key: 600000, text: t('app.page.setting.localPanel.option.screenLock.10m') },
    { key: 900000, text: t('app.page.setting.localPanel.option.screenLock.15m') },
    { key: 3600000, text: t('app.page.setting.localPanel.option.screenLock.1h') },
  ]
  const clientLocalesOptions = [
    { key: 'zh', text: t('app.page.setting.localPanel.option.language.zh') },
    { key: 'zh-TW', text: t('app.page.setting.localPanel.option.language.zh-TW') },
    { key: 'en', text: t('app.page.setting.localPanel.option.language.en') },
    { key: 'ja', text: t('app.page.setting.localPanel.option.language.ja') },
    { key: '', text: t('app.page.setting.localPanel.option.language.default') },
  ]
  const batchLocaleOptions = [
    { key: 'zh', text: t('app.page.setting.localPanel.option.language.zh') },
    { key: 'en', text: t('app.page.setting.localPanel.option.language.en') },
    { key: 'thai', text: t('app.page.setting.localPanel.option.language.thai') },
    { key: 'korean', text: t('app.page.setting.localPanel.option.language.korean') },
    { key: 'kitchen', text: t('app.page.setting.localPanel.option.language.kitchen') },
  ]
  const roundingTypeOptions = [
    { key: '', text: t('app.page.setting.localPanel.option.rounding.keep') },
    { key: 'off', text: t('app.page.setting.localPanel.option.rounding.off') },
    { key: 'up', text: t('app.page.setting.localPanel.option.rounding.up') },
    { key: 'down', text: t('app.page.setting.localPanel.option.rounding.down') },
  ]
  const surchargeOptions = [
    { key: 0, text: t('app.page.setting.localPanel.option.surcharge.null') },
    { key: 5, text: '5%' },
    { key: 10, text: '10%' },
    { key: 20, text: '20%' },
    { key: 30, text: '30%' },
  ]
  const rapidOptions = [
    { key: '', text: t('app.page.setting.localPanel.option.rapid.none') },
    { key: 'create', text: t('app.page.setting.localPanel.option.rapid.create') },
    { key: 'place', text: t('app.page.setting.localPanel.option.rapid.place') },
  ]
  const terminalIDOptions = [
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
    { key: '4', text: '4' },
    { key: '5', text: '5' },
    { key: '6', text: '6' },
    { key: '7', text: '7' },
    { key: '8', text: '8' },
    { key: '9', text: '9' },
  ]
  const {
    isMaster,
    enableDebugMode,
    enableQrcodeCheckout,
  } = useSelector(state => state.app.settings)
  const {
    hideConsoleErrorsAsExceptions, // 隱藏 console.error 時跳出的 Exception LogBox
    hideLogBox, // 隱藏所有 LogBox
    hideSnackbars, // 隱藏 Snackbar
    disablePrinterInit, // 停用打印機
    disableOrderSync, // 停止上傳訂單 (/orderimport)
    disableOrderHistoryStorage, // 停止寫入 orderHistory 到 AsyncStorage
  } = useSelector(state => state.app.settings.debugSettings)
  const { clientLocales, batchLocale, rounding, surcharge, setting, takeawayDisabled } = useSelector(state => state.merchant.data)
  const {
    defaultClientLanguage,
    waiter,
    qrcode,
    qrDisableCustomerCount,
    takeawayPickupAfterMins,
    payFirst,
    enableDineInPickupNotification,
    enableBusyMode,
    enableDynamicQRCode,
    enableLargerTable,
    enableTablePaginationButton,
    enableManualPrintQR,
    enableQRCodeLogo,
    unstackSubTables,
    enableFloorPlan,
  } = setting ?? {}

  const settingBatchLocaleOptions = batchLocaleOptions.filter(x => setting.batchLocaleOptions?.includes(x.key))
  const [takeawayPickup, setTakeawayPickup] = React.useState(takeawayPickupAfterMins)
  const [customText, setCustomText] = React.useState(defaultCustomText)
  const [customSurcharge, setCustomSurcharge] = React.useState(String(surcharge.percent))
  const [stopOrderUpdater, setStopOrderUpdater] = React.useState(false)
  const [exportDataDialogOpen, setExportDataDialogOpen] = React.useState(false)
  const [isExportingData, setIsExportingdata] = React.useState(false)
  const { lang, screenLock, rapidOrder, terminalID } = useSelector(state => state.app.settings)
  const [dummyOrderDialogOpen, setDummyOrderDialogOpen] = React.useState(false)

  React.useEffect(() => {
    if (setting) {
      setCustomText(setting)
    }
  }, [setting])

  const exportData = async (text) => {
    setIsExportingdata(true)
    try {
      const loginSuccess = await dimorderApi.auth.adminLogin(text)
      if (loginSuccess) {
        await fileIO.exportAsyncStorageData()
        dispatch(actions.app.showSimpleAlert('匯出數據', '匯出數據成功，請檢查iPad的檔案'))
      }
    } catch (e) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.menuInfo.error.login')))
    }
    setIsExportingdata(false)
  }

  return (
    <Row>
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.localPanel.setting.language.title')} />
        </Header>
        <SettingList>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.language.device'),
                valuePath: ['app', 'settings', 'lang'],
                onChangeAction: (value) => actions.app.updateSetting(['lang'], value),
                options: deviceLocalesOptions,
                closeOnChange: true,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.language.device')} />
            <SettingListItemEnter
              text={deviceLocalesOptions.find(o => o.key === lang)?.text || lang}
            />
          </SettingListItem>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.language.user'),
                valuePath: ['merchant', 'data', 'clientLocales'],
                onChangeAction: actions.merchant.updateClientLocales,
                options: clientLocalesOptions.filter(o => o.key),
                multipleSelection: true,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.language.user')} />
            <SettingListItemEnter
              text={clientLocales?.map(l => clientLocalesOptions.find(o => o.key === l)?.text || l).join(', ')}
            />
          </SettingListItem>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.language.user-default'),
                valuePath: ['merchant', 'data', 'setting', 'defaultClientLanguage'],
                onChangeAction: actions.merchant.updateClientDefaultLanguage,
                options: clientLocalesOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.language.user-default')} />
            <SettingListItemEnter
              text={clientLocalesOptions.find(o => o.key === defaultClientLanguage)?.text || defaultClientLanguage}
            />
          </SettingListItem>
          <SettingListItem onPress={() =>
            dispatch(actions.setting.showSettingOptions({
              text: t('app.page.setting.localPanel.setting.language.kitchen'),
              valuePath: ['merchant', 'data', 'batchLocale'],
              onChangeAction: actions.merchant.updateBatchPrintLanguage,
              options: settingBatchLocaleOptions,
            }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.language.kitchen')} />
            <SettingListItemEnter
              text={batchLocaleOptions.find(o => o.key === batchLocale)?.text || batchLocale}
            />
          </SettingListItem>
        </SettingList>
        <Header>
          <HeaderText text={t('app.page.setting.localPanel.setting.price.title')} />
        </Header>
        <SettingList>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.price.rounding'),
                valuePath: ['merchant', 'data', 'rounding', 'type'],
                onChangeAction: actions.setting.updateRounding,
                options: roundingTypeOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.price.rounding')} />
            <SettingListItemEnter
              text={roundingTypeOptions.find(o => o.key === rounding.type)?.text || rounding.type}
            />
          </SettingListItem>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.price.defaultSurcharge'),
                valuePath: ['merchant', 'data', 'surcharge', 'percent'],
                onChangeAction: actions.setting.updateSurcharge,
                options: surchargeOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.price.defaultSurcharge')} />
            <SettingListItemEnter
              text={surchargeOptions.find(o => o.key === surcharge.percent)?.text || `${surcharge.percent}%`}
            />
          </SettingListItem>
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.price.surcharge')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                value={customSurcharge}
                onChangeText={(text) => {
                  if (/^\d+$/.test(text)) {
                    setCustomSurcharge(text)
                  } else if (text === '') {
                    setCustomSurcharge('')
                  }
                }}
                onBlur={() => dispatch(actions.setting.updateSurcharge(customSurcharge))}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>

        {
          enableDynamicQRCode && (
            <>
              <Header>
                <HeaderText text={t('app.page.setting.localPanel.setting.qrCode.title')} />
              </Header>
              <SettingList>
                <SettingListItem divider>
                  <SettingListItemText text={t('app.page.setting.localPanel.setting.qrCode.dynamic')} />
                  <SettingListItemRightAction>
                    <ToggleSwitch
                      value={qrcode}
                      onChangeValue={() => dispatch(actions.merchant.updateDynamicQRCodeEnable(!qrcode))}
                      size={23}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem divider>
                  <SettingListItemText text={t('app.page.setting.localPanel.setting.qrCode.manualPrintQR')} />
                  <SettingListItemRightAction>
                    <ToggleSwitch
                      value={enableManualPrintQR}
                      onChangeValue={() => dispatch(actions.merchant.updateManualPrintQR(!enableManualPrintQR))}
                      size={23}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem divider>
                  <SettingListItemText text={t('app.page.setting.printer.customizedSetting.merchantLogo')} />
                  <SettingListItemRightAction>
                    <ToggleSwitch
                      value={enableQRCodeLogo}
                      onChangeValue={() => dispatch(actions.merchant.updateQRCodeLogoEnable(!enableQRCodeLogo))}
                      size={23}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.localPanel.setting.qrCode.customText')} style={{ width: '50%' }} />
                  <SettingListItemRightAction>
                    <SettingListInput
                      value={replaceSpace(customText.qrCustomText)}
                      onChangeText={(text) => {
                        setCustomText({
                          ...customText,
                          qrCustomText: text,
                        })
                      }}
                      onBlur={() => dispatch(actions.merchant.updateDynamicQRCodeCustomText(replaceSaveSpace(customText.qrCustomText)))}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              </SettingList>
            </>
          )
        }

        {(__DEV__ || enableDebugMode) && (
          <>
            <Header>
              <HeaderText text='DEBUG 設定' />
            </Header>
            <SettingList>
              <SettingListItem divider>
                <SettingListItemText text='隱藏 console.error Exception' />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={hideConsoleErrorsAsExceptions}
                    onChangeValue={() => {
                      console.reportErrorsAsExceptions = !hideConsoleErrorsAsExceptions
                      dispatch(actions.app.updateDebugSetting('hideConsoleErrorsAsExceptions', !hideConsoleErrorsAsExceptions))
                    }}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text='隱藏 LogBox' />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={hideLogBox}
                    onChangeValue={() => {
                      LogBox.ignoreAllLogs(!hideLogBox)
                      dispatch(actions.app.updateDebugSetting('hideLogBox', !hideLogBox))
                    }}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text='隱藏 Snackbar' />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={hideSnackbars}
                    onChangeValue={() => {
                      dispatch(actions.app.updateDebugSetting('hideSnackbars', !hideSnackbars))
                    }}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text='停用打印機' />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={disablePrinterInit}
                    onChangeValue={() => {
                      dispatch(actions.app.updateDebugSetting('disablePrinterInit', !disablePrinterInit))
                    }}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text='停用訂單 Polling' />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={stopOrderUpdater}
                    onChangeValue={() => {
                      const nextState = !stopOrderUpdater
                      setStopOrderUpdater(nextState)
                      if (nextState) {
                        dispatch(actions.orderHistory.startPollingOrdersUpdater())
                      } else {
                        dispatch(actions.orderHistory.stopPollingOrdersUpdater())
                      }
                    }}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text='停止上傳訂單' />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={disableOrderSync}
                    onChangeValue={() => {
                      dispatch(actions.app.updateDebugSetting('disableOrderSync', !disableOrderSync))
                    }}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem divider>
                <SettingListItemText text='停止寫入訂單 Storage' />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={disableOrderHistoryStorage}
                    onChangeValue={() => {
                      dispatch(actions.app.updateDebugSetting('disableOrderHistoryStorage', !disableOrderHistoryStorage))
                    }}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
              <SettingListItem onPress={() => {
                setDummyOrderDialogOpen(true)
              }}
              >
                <SettingListItemText text='寫入Dummy orders' />
              </SettingListItem>
            </SettingList>
            <DummyOrderDialog
              open={dummyOrderDialogOpen}
              onClose={() => { setDummyOrderDialogOpen(false) }}
            />
          </>
        )}

      </View>
      <View style={styles.contentContainer}>
        <Header>
          <HeaderText text={t('app.page.setting.localPanel.setting.system.title')} />
        </Header>
        <SettingList>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.isMaster')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={isMaster}
                onChangeValue={() => {
                  firebase.updateMaster(!isMaster)
                  dispatch(actions.app.updateSetting(['isMaster'], !isMaster))
                }}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.disable-takeaway')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={takeawayDisabled}
                onChangeValue={() => dispatch(actions.auth.permissionCheck('takeaway-disable', () => {
                  dispatch(actions.merchant.updateIsTakeawayDisabled(!takeawayDisabled))
                }))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.busyMode')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={enableBusyMode}
                onChangeValue={() => dispatch(actions.merchant.updateBusyMode(!enableBusyMode))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.system.terminalID'),
                valuePath: ['app', 'settings', 'terminalID'],
                onChangeAction: (value) => actions.app.updateSetting(['terminalID'], value),
                options: terminalIDOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.terminalID')} />
            <SettingListItemEnter
              text={terminalIDOptions.find(o => o.key === terminalID)?.text}
            />
          </SettingListItem>
          <SettingListItem
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.setting.system.screenLock'),
                valuePath: ['app', 'settings', 'screenLock'],
                onChangeAction: (value) => actions.app.updateSetting(['screenLock'], value),
                options: screenLockOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.setting.system.screenLock')} />
            <SettingListItemEnter
              text={screenLockOptions.find(o => o.key === screenLock)?.text}
            />
          </SettingListItem>
        </SettingList>
        <Header>
          <HeaderText text={t('app.page.setting.localPanel.setting.other.title')} />
        </Header>
        <SettingList>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.payFirst')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={payFirst}
                onChangeValue={() => dispatch(actions.merchant.updatePayFirst(!payFirst))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.dineInPickup')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={enableDineInPickupNotification}
                onChangeValue={() => dispatch(actions.merchant.updateEnableDineInPickupNotification(!enableDineInPickupNotification))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.call-waiter')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={waiter}
                onChangeValue={() => dispatch(actions.merchant.updateWaiterEnable(!waiter))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.largerTable')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={enableLargerTable}
                onChangeValue={() => dispatch(actions.merchant.updateEnableLargerTable(!enableLargerTable))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider hidden={enableFloorPlan}>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.unstackSubTables')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={unstackSubTables}
                onChangeValue={() => dispatch(actions.merchant.updateUnstackSubTables(!unstackSubTables))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.TablePaginationButton')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={enableTablePaginationButton}
                onChangeValue={() => dispatch(actions.merchant.updateEnableTablePaginationButton(!enableTablePaginationButton))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.qrCode.skipCustomer')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={qrDisableCustomerCount}
                onChangeValue={() => dispatch(actions.merchant.updateDynamicQRCodeDisableCustomerCount(!qrDisableCustomerCount))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.scanQRCode')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={enableQrcodeCheckout}
                onChangeValue={() => dispatch(actions.app.updateSetting(['enableQrcodeCheckout'], !enableQrcodeCheckout))}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.takeaway-minTime')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                keyboardType='number-pad'
                value={takeawayPickup?.toString() || ''}
                onChangeText={(text) => {
                  const amount = parseInt(text) || 0
                  setTakeawayPickup(amount)
                }}
                onBlur={() => dispatch(actions.merchant.updateTakeawayPickupAfterMinutes(takeawayPickup))}
              />
              <SettingListInputAdornment text={t('app.page.setting.localPanel.setting.other.min')} />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.localPanel.setting.other.customRecomText')} style={{ width: '50%' }} />
            <SettingListItemRightAction>
              <SettingListInput
                value={replaceSpace(customText.recommendCustomText)}
                onChangeText={(text) => {
                  setCustomText({
                    ...customText,
                    recommendCustomText: text,
                  })
                }}
                onBlur={() => dispatch(actions.merchant.updateRecommendCustomText(replaceSaveSpace(customText.recommendCustomText)))}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          <SettingListItem
            divider
            onPress={() =>
              dispatch(actions.setting.showSettingOptions({
                text: t('app.page.setting.localPanel.option.rapid.order'),
                valuePath: ['app', 'settings', 'rapidOrder'],
                onChangeAction: (value) => actions.app.updateSetting(['rapidOrder'], value),
                options: rapidOptions,
              }))}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.option.rapid.order')} />
            <SettingListItemEnter
              text={rapidOptions.find(o => o.key === rapidOrder)?.text || ''}
            />
          </SettingListItem>
          {/* 匯出數據 */}
          <SettingListItem
            divider
            onPress={() => {}}
            onLongPress={() => setExportDataDialogOpen(true)}
            touchableOpacityProps={{ activeOpacity: 1, delayLongPress: 3000 }}
          >
            <SettingListItemText text={t('app.page.setting.localPanel.exportData')} />
            {isExportingData && (
              <LinearProgress
                style={styles.progress}
                color={colors.lightPrimary}
                variant='indeterminate'
              />
            )}
          </SettingListItem>
        </SettingList>

        <TextInputDialog
          enablePressOutsideClose={false}
          open={exportDataDialogOpen}
          onClose={() => setExportDataDialogOpen(false)}
          secureTextEntry
          title={t('app.page.setting.menuPanel.menuInfo.dialog.admin.title')}
          placeholder={t('app.page.setting.menuPanel.menuInfo.dialog.admin.placeholder')}
          onSubmit={exportData}
        />
      </View>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: {
    margin: 10,
  },
  row: {
    justifyContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingBottom: 100,
    marginHorizontal: 10,
  },
  moreIcon: {
    height: 12,
    width: 12,
    marginLeft: 10,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  progress: {
    width: '50%',
    margin: 4,
  },
})
