import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'

import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import SettingList from '@/components/Setting/SettingList'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @typedef DiscountInfoProps
 * @param {*} modifier
 * @param {() => void} onClose
 */

/**
 *
 * @param {DiscountInfoProps} props
 * @returns
 */
export default function DiscountInfo (props) {
  const { modifier, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [modifierState, setModifierState] = React.useState(modifier)
  const [percent, setPercent] = React.useState(false)
  const [applyToItem, setApplyToItem] = React.useState(false)

  useEffect(() => {
    setModifierState(modifier)
    setPercent(modifier?.percent !== 0)
    setApplyToItem(modifier?.applyTo === 'PRODUCT')
  }, [modifier])

  if (!modifier) return null
  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: `${t('app.common.delete') + t('app.page.setting.checkout.modifier')}`,
      message: `${t('app.common.is') + t('app.common.delete') + modifier.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            dispatch(actions.merchant.deleteModifier(modifier?.id))
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    onClose()
    dispatch(actions.merchant.upsertModifier({
      ...modifierState,
      amount: percent ? 0 : Math.abs(modifierState.amount) * -1,
      percent: percent ? Math.abs(modifierState.percent) * -1 : 0,
      applyTo: applyToItem ? 'PRODUCT' : '',
    }))
  }

  const customDiscountText = t('app.page.setting.checkout.modifier') + t('app.page.setting.checkout.setting')

  return (
    <>
      <Header>
        <HeaderText text={modifier.id ? customDiscountText : `${t('app.page.setting.checkout.add') + customDiscountText}`} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          {
            modifier?.id && (
              <SettingListItem>
                <SettingListItemText text={t('app.page.setting.checkout.modifierType')} />
                <SettingListItemRightAction>
                  {
                    applyToItem
                      ? <SettingListItemText text={t('app.page.setting.checkout.itemDiscount')} />
                      : <SettingListItemText text={t('app.page.setting.checkout.orderDiscount')} />
                  }
                </SettingListItemRightAction>
              </SettingListItem>
            )
          }
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.modifierName')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={modifierState.name}
                onChangeText={(text) => {
                  setModifierState({
                    ...modifierState,
                    name: text,
                  })
                }}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          {
            !modifier?.id && (
              <SettingListItem>
                <SettingListItemText text={t('app.page.setting.checkout.applyToItem')} />
                <SettingListItemRightAction>
                  <ToggleSwitch
                    value={applyToItem}
                    onChangeValue={() => setApplyToItem(!applyToItem)}
                    size={23}
                  />
                </SettingListItemRightAction>
              </SettingListItem>
            )
          }
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.amountPercent')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={percent}
                onChangeValue={() => setPercent(!percent)}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
          {
            percent
              ? (
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.checkout.modifierPercent')} />
                  <SettingListItemRightAction>
                    <SettingListInput
                      keyboardType='number-pad'
                      value={String(Math.abs(modifierState.percent))}
                      onChangeText={(percent) => {
                        if (!Number.isNaN(Number(percent)) && Number(percent) <= 100) {
                          setModifierState({
                            ...modifierState,
                            percent,
                          })
                        }
                      }}
                      onBlur={(event) => {
                        setModifierState({
                          ...modifierState,
                          percent: Number(event.nativeEvent.text),
                        })
                      }}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              )
              : (
                <SettingListItem>
                  <SettingListItemText text={t('app.page.setting.checkout.modifierAmount')} />
                  <SettingListItemRightAction>
                    <SettingListInput
                      keyboardType='number-pad'
                      value={String(Math.abs(modifierState.amount))}
                      onChangeText={(amount) => {
                        if (!Number.isNaN(Number(amount))) {
                          setModifierState({
                            ...modifierState,
                            amount,
                          })
                        }
                      }}
                      onBlur={(event) => {
                        setModifierState({
                          ...modifierState,
                          amount: Number(event.nativeEvent.text),
                        })
                      }}
                    />
                  </SettingListItemRightAction>
                </SettingListItem>
              )
          }
          <SettingListItem>
            <SettingListItemText text={t('app.page.setting.checkout.enable')} />
            <SettingListItemRightAction>
              <ToggleSwitch
                value={modifierState.enable}
                onChangeValue={() => setModifierState({
                  ...modifierState,
                  enable: !modifierState.enable,
                })}
                size={23}
              />
            </SettingListItemRightAction>
          </SettingListItem>
        </SettingList>
      </View>

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          disabled={!modifier.id}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
