import { StyleSheet, View } from 'react-native'
import React from 'react'

/**
 * @typedef SettingListItemRightActionProps
 * @property {JSX.Element} children
 * @property {ViewProps['style']} [style]
 * @property {boolean} [hidden]
 */

/**
 *
 * @param {SettingListItemRightActionProps} props
 * @returns
 */
export default function SettingListItemRightAction (props) {
  const { style, hidden = false, children } = props

  if (hidden) {
    return null
  }

  return (
    <View style={[styles.container, style]}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 6,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
