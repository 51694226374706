import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import Column from '../Column'
import React from 'react'
import colors from '@/theme/colors'

// eslint-disable-next-line no-unused-vars
import { ItemProps } from '@/pages/Order/OrderBatch/BatchList/index.d'

/**
 * @param {ItemProps} props
 */
export default function SetItemHeader (props) {
  const {
    serial,
    name,
    onPress,
    selected,
  } = props

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[styles.container, selected && styles.selected]}>
        <Column setItem index={0} value={serial} />
        <Column setItem index={1} value={name} />
        <Column setItem index={2} />
        <Column setItem index={3} />
        <Column setItem index={4} />
        <Column setItem index={5} />
        <Column setItem index={6} />
        <Column setItem index={7} />
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 28,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.light,
  },
  selected: {
    backgroundColor: colors.tertiary,
  },
})
