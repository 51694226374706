import { Platform, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import DateTimePicker from '@/components/DateTimePicker'
import WebTimePicker from '@/components/WebTimePicker'

/**
 * DateTimePicker works only on iOS, Android and Windows
 * @param {*} props
 * @returns
 */
export default function DateTimeDialog (props) {
  const { t } = useTranslation()
  const dateTimeDialog = useSelector(state => state.app.dateTimeDialog)

  const DateTimeModal = ({ visible, mode, value, setValue, fromDate, toDate, minuteInterval, onClose = () => { } }) => {
    const dispatch = useDispatch()
    const [dateTime, setDateTime] = React.useState(new Date())
    const dateTimeMin = Platform.OS === 'web' ? 60 : 30
    const lang = useSelector(state => state.app.settings.lang)

    React.useEffect(() => {
      if (value) {
        setDateTime(new Date(value))
      }
    }, [])

    const onChange = (event, selectedDate) => {
      const currentDate = selectedDate || dateTime
      // if (mode === 'datetime' && moment(selectedDate).format('m') !== '0') {
      //   const resetTime = moment(currentDate).add(-30, 'm').toDate()
      //   setDateTime(resetTime)
      //   return
      // }
      setDateTime(currentDate)
    }

    const onSubmit = () => {
      setValue(dateTime)
      dispatch(actions.app.closeDateTimePicker())
      onClose()
    }

    return (
      <CenterModal
        title={`${t('app.component.dateTimeDialog.select')}${mode === 'date' ? t('app.component.dateTimeDialog.date') : t('app.component.dateTimeDialog.time')}`}
        visible={visible}
        onClose={() => {
          dispatch(actions.app.closeDateTimePicker())
          onClose()
        }}
      >
        <View style={styles.container}>
          {
            Platform.OS === 'web' && mode === 'time'
              ? (
                <WebTimePicker
                  value={dateTime}
                  fromDate={fromDate}
                  toDate={toDate}
                  onChange={(currentDate) => setDateTime(currentDate)}
                />
              )
              : (
                <DateTimePicker
                  value={dateTime}
                  mode={mode}
                  locale={lang === 'zh-HK' ? 'zh-TW' : 'en'}
                  display={mode === 'date'
                    ? 'inline' // ios 14
                    : 'spinner'}
                  minimumDate={fromDate ?? new Date()}
                  maximumDate={toDate}
                  minuteInterval={mode === 'datetime' ? dateTimeMin : minuteInterval}
                  onChange={onChange}
                  style={{ width: 320, height: 300, backgroundColor: colors.white }}
                />
              )
          }
          <Button
            title={t('app.common.confirm')}
            style={styles.button}
            textBold
            type='TouchableOpacity'
            onPress={onSubmit}
          />
        </View>
      </CenterModal>
    )
  }

  return <DateTimeModal {...dateTimeDialog} />
}

const styles = StyleSheet.create({
  container: {
    width: 350,
    height: 385,
    alignItems: 'center',
  },
  button: {
    alignSelf: 'flex-end',
  },
})
