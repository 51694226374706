import { Audio } from 'expo-av'
import { FlatList, StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { ecrGateway, loadingKey, paymentMethods } from '@/constants'
import { useCheckoutPayment } from '@/hooks/orderCheckout'
import { usePaymentMethods } from '@/hooks/merchant'
import { useSelectedOrder } from '@/hooks/orderHistory'
import BigIconButton from '@/components/buttons/BigIconButton'
import Button from '@/components/buttons/Button'
import Column from '@/components/Column'
import EPaymentDialog from '@/components/dialogs/EPaymentDialog'
import PrinterDialog from '@/components/dialogs/PrinterDialog'
import Row from '@/components/Row'
import ToggleSwitch from '@/components/ToggleSwitch'
import colors from '@/theme/colors'
import ecrPayMethods from '@/constants/ecrPayMethods'
import paymentConfigs from '@/configs/paymentConfigs'
import shadows from '@/theme/shadows'

import MethodCard from '../MethodPanel/MethodCard'

/**
 *
 * @param {*} props
 * @returns
 */
export default function NotesPanel (props) {
  const { t } = useTranslation()
  const [soundEffect, setSoundEffect] = useState()
  const manualPaidRef = useRef(false) // EPaymentDialog中‘已成功付款’button
  const cancelPaymentRef = useRef(false) // EPaymentDialog中‘返回重試’button
  const selectPrinterVisible = useSelector(state => state.app.dialog.checkout.selectPrinter, _.isEqual)
  const selectedOrder = useSelectedOrder()
  const checkoutPayment = useCheckoutPayment()

  const dispatch = useDispatch()
  const now = moment()
  const [paymentProviders, cardProviders, qrProviders] = usePaymentMethods()
  const cardProvider = cardProviders.find(cardProvider => cardProvider.key === checkoutPayment.paymentMethod)
  const qrProvider = qrProviders.find(qrProvider => qrProvider.key === checkoutPayment.paymentMethod)
  const OctopusProvider = paymentProviders.find(paymentProvider => paymentProvider.key === checkoutPayment.paymentMethod)
  const enableMultiPayment = useSelector(state => state.merchant.data?.setting?.enableMultiPayment)
  const isCardPayment = Boolean(cardProvider)
  const isQrPayment = Boolean(qrProvider)
  const isOctopusPayment = Boolean(OctopusProvider)
  const totalPaidAmount10x = _.reduce(selectedOrder.payments, (previousValue, currentValue) => {
    return (currentValue.status !== 'cancel' ? previousValue + (Number(currentValue.paidAmount) * 10) : previousValue)
  }, 0)
  // payment gateway 是使用卡機付款
  const isEcrPayment = Boolean(checkoutPayment.gateway) && Object.values(ecrGateway).includes(checkoutPayment.gateway)

  // 這次付款後是否能完成
  const isPaidAfterThisPayment = Math.round(totalPaidAmount10x * 100) / 100 >= selectedOrder.roundedTotal * 10

  const [disablePayBtnState, setDisablePayBtnState] = useState(false)
  const disablePayBtn = enableMultiPayment
    ? Math.round(Number(checkoutPayment.paidAmount) * 100) / 100 <= 0 && selectedOrder.roundedTotal > 0
    : checkoutPayment.paidAmount * 10 < selectedOrder.roundedTotal * 10 && selectedOrder.roundedTotal > 0
  const scopeLogin = useSelector(state => state.app.scopeLogin)
  const [printerDialogOpen, setPrinterDialogOpen] = React.useState(false)
  const { selectPrinterBeforePrint, checkoutSound } = useSelector(state => state.app.settings)

  const isGateway = checkoutPayment.gateway !== '' && checkoutPayment.gateway !== undefined
  const { connectGateway } = useSelector(state => state.orderCheckout)

  const getEcrPaymethod = (gateway) => {
    switch (checkoutPayment.paymentMethod) {
      case paymentMethods.ALI_PAY : {
        switch (gateway) {
          case ecrGateway.BBMSL : {
            return ecrPayMethods.QR_PAY.BBMSL
          }

          case ecrGateway.EFT_PAY : {
            return ecrPayMethods.QR_PAY.EFT_PAY
          }

          case ecrGateway.GLOBAL_PAYMENT : {
            return ecrPayMethods.QR_PAY.GLOBAL_PAYMENT
          }
        }
        break
      }

      case paymentMethods.WECHAT : {
        switch (gateway) {
          case ecrGateway.BBMSL : {
            return ecrPayMethods.QR_PAY.BBMSL
          }

          case ecrGateway.EFT_PAY : {
            return ecrPayMethods.QR_PAY.EFT_PAY
          }

          case ecrGateway.GLOBAL_PAYMENT : {
            return ecrPayMethods.QR_PAY.GLOBAL_PAYMENT
          }
        }
        break
      }

      case paymentMethods.PAY_ME : {
        switch (gateway) {
          case ecrGateway.EFT_PAY : {
            return ecrPayMethods.PAYME.EFT_PAY
          }

          case ecrGateway.GLOBAL_PAYMENT : {
            return ecrPayMethods.PAYME.GLOBAL_PAYMENT
          }
        }
        break
      }

      case paymentMethods.OCTOPUS : {
        switch (gateway) {
          case ecrGateway.BBMSL : {
            return ecrPayMethods.OCTOPUS.BBMSL
          }

          case ecrGateway.EFT_PAY : {
            return ecrPayMethods.OCTOPUS.EFT_PAY
          }

          case ecrGateway.GLOBAL_PAYMENT : {
            return ecrPayMethods.OCTOPUS.GLOBAL_PAYMENT
          }
        }
        break
      }

      case paymentMethods.UNION_PAY_QR : {
        switch (gateway) {
          case ecrGateway.EFT_PAY : {
            return ecrPayMethods.QR_PAY.EFT_PAY
          }
        }
      }
    }
  }
  let paymentsOrder = useSelector(state => state.app.settings.paymentsOrder) || paymentConfigs.defaultPaymentsOrder
  if (_.isEmpty(paymentsOrder)) {
    paymentsOrder = paymentConfigs.defaultPaymentsOrder
  }

  const cardPayment = _.sortBy(_.map(cardProviders, p => {
    const payment = paymentsOrder.find(o => o.key === p.key)
    return { ...p, index: _.get(payment, 'index') }
  }), 'index')

  const qrPayment = _.sortBy(_.map(qrProviders, p => {
    const payment = paymentsOrder.find(o => o.key === p.key)
    return { ...p, index: _.get(payment, 'index') }
  }), 'index')

  const renderItem = ({ item }) => (
    <MethodCard
      style={styles.card}
      item={item}
      active={checkoutPayment.paymentMethod === item.key}
      onPress={() => {
        dispatch(actions.orderCheckout.updatePayment({
          paymentMethod: item.key,
          gateway: item.gateway,
        }))
      }}
    />
  )

  const submitOrderPayment = (selectedPrinterId) => {
    console.log('[NotesPanel] submitOrderPayment()')
    dispatch(actions.app.openLoading(loadingKey.CHECKOUT, 'checkout'))
    dispatch(actions.orderCheckout.submitOrderPayment({ selectedPrinterId: selectedPrinterId }))
  }

  const throttleHandlePayment = _.throttle(() => handlePayment(), 2000, { trailing: false })
  const handlePayment = async (selectedPrinterId = '') => {
    //  因為輸入中時 amount 會被轉為字串以處理小數點輸入，輸入完後要轉回數字
    dispatch(actions.orderCheckout.convertPaymentFieldToNumber())
    if (selectPrinterBeforePrint && isPaidAfterThisPayment && !isEcrPayment) {
      setPrinterDialogOpen(true)
    } else {
      if (isEcrPayment && connectGateway) {
        const gateway = checkoutPayment.gateway
        const ecrPayMethod = getEcrPaymethod(gateway)
        await dispatch(actions.orderCheckout.startEcrPayment(isPaidAfterThisPayment, selectPrinterBeforePrint, ecrPayMethod, manualPaidRef, cancelPaymentRef))
        // 餐廳選擇手動確認卡機付款
        if (manualPaidRef.current) {
          handleMutiPay(selectedPrinterId)
        }
      } else {
        handleMutiPay(selectedPrinterId)
      }
    }
    // 付款流程完成後把connectGateway改回true
    dispatch(actions.orderCheckout.updateConnectGateway(true))
    setDisablePayBtnState(false)
    // 付款流程完成後把manualPaidRef, cancelPaymentRef改回false
    manualPaidRef.current = false
    cancelPaymentRef.current = false
  }

  const handleMutiPay = (selectedPrinterId) => {
    // 如果payment最後不經卡機付款，payment中的gateway設定為empty string，作廢的時候不會有經卡機退款的option
    if (!connectGateway || manualPaidRef.current) dispatch(actions.orderCheckout.updatePaymentField('gateway', ''))
    dispatch(actions.orderCheckout.updatePaymentField('createdAt', now.utc().toISOString()))
    if (checkoutSound) {
      playSound()
    }
    submitOrderPayment({ selectedPrinterId: selectedPrinterId })
  }

  async function playSound () {
    const { sound } = await Audio.Sound.createAsync(
      require('assets/sounds/cashier.mp3'),
    )
    setSoundEffect(sound)
    await sound.playAsync()
  }

  useEffect(() => {
    return soundEffect
      ? () => {
        soundEffect.unloadAsync()
      }
      : undefined
  }, [soundEffect])

  return (
    <Column style={styles.container}>
      <Column style={styles.notes}>
        {/* <Text style={styles.text}>信用卡後四碼</Text>
            <TextInput
              value={selectedPayment.remark}
              style={[styles.textInput, styles.lastFourDigitInput, inputStyle]}
              placeholder='- - - -'
              placeholderTextColor={inputFocus ? colors.white : colors.textPrimary}
              keyboardType='number-pad'
              maxLength={4}
              onChangeText={(text) => dispatch(actions.orderCheckout.updatePaymentField('remark', text))}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
            /> */}
        {
          isCardPayment && (
            <>
              {isGateway && // 檢查payment method是否連接gateway
                <Row style={[styles.toggleSwitchContainer]}>
                  <ToggleSwitch
                    value={connectGateway}
                    onChangeValue={() => dispatch(actions.orderCheckout.updateConnectGateway(!connectGateway))}
                    size={23}
                  />
                  <Text style={[styles.toggleText]}>{t('app.page.checkout.payment.gateway')}</Text>
                </Row>}
              <FlatList
                data={cardPayment}
                renderItem={renderItem}
                keyExtractor={item => item.key}
                extraDate={checkoutPayment.paymentMethod} // To re-render when selected changes
                showsVerticalScrollIndicator={false}
              />
            </>
          )
        }
        {
          isQrPayment && (
            <>
              {isGateway && // 檢查payment method是否連接gateway
                <Row style={[styles.toggleSwitchContainer]}>
                  <ToggleSwitch
                    value={connectGateway}
                    onChangeValue={() => dispatch(actions.orderCheckout.updateConnectGateway(!connectGateway))}
                    size={23}
                  />
                  <Text style={[styles.toggleText]}>{t('app.page.checkout.payment.gateway')}</Text>
                </Row>}
              <FlatList
                data={qrPayment}
                renderItem={renderItem}
                keyExtractor={item => item.key}
                extraDate={checkoutPayment.paymentMethod} // To re-render when selected changes
                showsVerticalScrollIndicator={false}
              />
            </>
          )
        }
        {
          isOctopusPayment &&
            <>
              {isGateway && // 檢查payment method是否連接gateway
                <Row style={[styles.toggleSwitchContainer]}>
                  <ToggleSwitch
                    value={connectGateway}
                    onChangeValue={() => dispatch(actions.orderCheckout.updateConnectGateway(!connectGateway))}
                    size={23}
                  />
                  <Text style={[styles.toggleText]}>{t('app.page.checkout.payment.gateway')}</Text>
                </Row>}
            </>
        }
      </Column>
      <Button
        title={t('app.page.checkout.payment.history')}
        textBold
        backgroundColor={colors.lightPrimary}
        style={styles.button}
        onPress={() => dispatch(actions.auth.permissionCheck('change-after-paid', () => {
          dispatch(actions.app.showDialog(['checkout', 'history']))
        }))}
      />
      <BigIconButton
        iconSource={require('@icons/checkout.png')}
        backgroundColor={colors.darkSecondary}
        onPress={() => {
          setDisablePayBtnState(true)
          // const order = orders.find(o => o.id === selectedOrder.id)
          if (scopeLogin) {
            handlePayment()
          } else {
            if (scopeLogin) {
              throttleHandlePayment()
            } else {
              dispatch(actions.auth.permissionCheck('checkout', () => throttleHandlePayment()))
            }
            dispatch(actions.app.setScopeLogin(false))
          }
          dispatch(actions.app.setScopeLogin(false))
        }}
        disabled={disablePayBtn || disablePayBtnState}
      />
      <PrinterDialog
        open={printerDialogOpen || selectPrinterVisible}
        enablePressOutsideClose={isEcrPayment}
        noCloseButton={isEcrPayment}
        onClose={() => {
          setPrinterDialogOpen(false)
          dispatch(actions.app.closeDialog(['checkout', 'selectPrinter']))
          handleMutiPay()
        }}
        onSelect={(selectedPrinterId) => {
          handleMutiPay(selectedPrinterId)
        }}
      />
      <EPaymentDialog type='pay' manualRef={manualPaidRef} cancelRef={cancelPaymentRef} />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: 130 + 1.5,
    alignItems: 'flex-end',
  },
  toggleSwitchContainer: {
    height: 40,
    width: 110,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 8,
    marginBottom: 8,
  },
  notes: {
    flex: 1,
    width: '100%',
    borderLeftColor: colors.gray,
    borderLeftWidth: 1.5,
    paddingLeft: 20,
    marginBottom: 20,
  },
  card: {
    marginBottom: 8,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.gray,
    marginLeft: 8,
    marginBottom: 8,
  },
  textInput: {
    height: 38,
    width: 110,
    backgroundColor: colors.white,
    borderRadius: 5,
    paddingHorizontal: 8,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
  toggleText: {
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.textPrimary,
    marginLeft: 6,
  },
  lastFourDigitInput: {
    textAlign: 'center',
    paddingVertical: 9,
  },
  button: {
    margin: 0,
    marginBottom: 15,
    ...shadows.default,
  },
})
