import { StyleSheet, View } from 'react-native'
import React from 'react'

import { useSelector } from '@/redux'
import colors from '@/theme/colors'

import CancelInfo from './CancelInfo'
import CashRequest from './CashRequest'
import CategoryInfo from './CategoryInfo'
import DepartmentInfo from './DepartmentInfo'
import DimOrderInfo from './DimOrderInfo'
import OptionInfo from './OptionInfo'
import SummaryInfo from './SummaryInfo'

export default function Summary (props) {
  const isInit = useSelector(state => state.statistics.isInit)
  const data = useSelector(state => state.statistics.data)
  const selectedStatType = useSelector(state => state.statistics.selectedStatType)

  const renderSummary = () => {
    switch (selectedStatType) {
      case 'dimorder': {
        return <DimOrderInfo data={data?.summary?.sections} />
      }
      case 'summary': {
        return <SummaryInfo />
      }
      case 'category': {
        return <CategoryInfo data={data?.category} />
      }
      case 'option': {
        return <OptionInfo />
      }
      case 'cancel': {
        return <CancelInfo data={data?.cancel} />
      }
      case 'department': {
        return <DepartmentInfo data={data?.department?.data} />
      }
      case 'cash': {
        return <CashRequest data={data?.cash} />
      }
    }
  }

  if (!isInit) return null

  return (
    <View style={styles.container}>
      {renderSummary()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.light,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 16,
  },
  tableRow: {
    marginTop: 10,
  },
  item: {
    width: 160,
  },
  number: {
    width: 120,
    display: 'flex',
    alignItems: 'flex-end',
  },
  amount: {
    width: 120,
    display: 'flex',
    alignItems: 'flex-end',
  },
})
