import { useTranslation } from 'react-i18next'
import React from 'react'

import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@root/src/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'

/**
 * @param {{
* setting: object,
* onChange: () => void,
* }} props
*/
function QRCode (props) {
  const { setting, onChange } = props
  const { t } = useTranslation()
  const [customText, setCustomText] = React.useState(setting.customizedText.text)
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)

  const positionStyleOptions = [
    { value: 'top', display: t('app.page.setting.printer.customizedSetting.qrCodePositionTop') },
    { value: 'bottom', display: t('app.page.setting.printer.customizedSetting.qrCodePositionBottom') },
  ]

  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]
  const textAlignOptions = [
    { value: 'left', display: t('app.page.setting.printer.left') },
    { value: 'center', display: t('app.page.setting.printer.center') },
    { value: 'right', display: t('app.page.setting.printer.right') },
  ]

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  return (
    <>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.printJoinMemberQRCode')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.enable}
            onChangeValue={() => onChange('enable', !setting.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.printCustomizedText')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.customizedText.enable}
            onChangeValue={() => onChange('customizedText.enable', !setting.customizedText.enable)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>

      <SettingListItem>
        <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.text')} style={{ width: '50%' }} />
        <SettingListItemRightAction>
          <SettingListInput
            value={customText}
            onChangeText={(text) => {
              setCustomText(text)
              onChange('customizedText.text', text)
            }}
          />
        </SettingListItemRightAction>
      </SettingListItem>

      <SettingListEnterRow
        label={'-' + t('app.page.setting.printer.customizedSetting.position')}
        value={positionStyleOptions.find(t => t.value === setting.customizedText.position)?.display ||
          setting.customizedText.position}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.position'),
            defaultValue: setting.customizedText.position || 'bottom',
            onChangeValue: (value) => onChange('customizedText.position', value),
            options: positionStyleOptions,
            closeOnSelect: true,
          })
        }}
      />

      <SettingListItem>
        <SettingListItemText text={'-' + t('app.page.setting.printer.customizedSetting.bold')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.customizedText?.bold}
            onChangeValue={() => onChange('customizedText.bold', !setting.customizedText?.bold)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>

      <SettingListEnterRow
        label={'-' + t('app.page.setting.printer.customizedSetting.fontSize')}
        value={textStyleOptions.find(t => t.value === setting.customizedText?.fontSize)?.display ||
                      setting.customizedText?.fontSize}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.fontSize'),
            defaultValue: setting.customizedText?.fontSize || 0,
            onChangeValue: (value) => onChange('customizedText.fontSize', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />

      <SettingListEnterRow
        label={'-' + t('app.page.setting.printer.customizedSetting.align')}
        value={textAlignOptions.find(t => t.value === setting.customizedText?.align)?.display ||
                      setting.customizedText?.align}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.align'),
            defaultValue: setting.customizedText?.align || 'center',
            onChangeValue: (value) => onChange('customizedText.align', value),
            options: textAlignOptions,
            closeOnSelect: true,
          })
        }}
      />
    </>
  )
}

export default QRCode
