import { Platform, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import { PrinterType } from '@/constants/printer'
import Button from '@/components/buttons/Button'
import Header from '@/components/Setting/Header'
import HeaderText from '@/components/Setting/HeaderText'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import RadioBox from '@/components/RadioBox'
import SettingList from '@/components/Setting/SettingList'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListInput from '@/components/Setting/SettingListInput'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'

/**
 * @typedef PrinterInfoProps
 * @property {IPrinter} printer
 * @property {() => void} onClose
 */

/**
 *
 * @param {PrinterInfoProps} props
 * @returns
 */
export default function PrinterInfo (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { printer, onClose } = props
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const printers = useSelector(state => state.printer.printerSetting.printers)
  const printerStatus = useSelector(state => state.printer.printerStatus[printer?.id])
  const [printerState, setPrinterState] = React.useState(printer)

  // printerTypeOptions
  const printerTypeOptions = [
    { disabled: true, display: t('app.page.setting.printer.thermal') },
    { value: PrinterType.GPRINTER, display: 'GPrinter' },
    { value: PrinterType.GP_D801, display: 'GPrinter - D801' },
    { value: PrinterType.TP808, display: 'HPRT - TP808' },
    { value: PrinterType.TM_T88VI, display: 'Epson - TM_T88VI' },
    { value: PrinterType.TSP043, display: 'Star - TSP043' },
    { value: PrinterType.TSP100IV, display: 'Star - TSP100IV' },
    // { value: PrinterType.TSP100III, display: 'TSP100III' },
    { disabled: true, display: t('app.page.setting.printer.dotMatrix') },
    { value: PrinterType.TM_U220B, display: 'Epson - TM_U220B' },
    { value: PrinterType.SP700, display: 'Star -SP700' },
    { disabled: true, display: t('app.page.setting.printer.labelPrinter') },
    { value: PrinterType.TDP225, display: 'TSC - TDP225' },
  ]

  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig?.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }

  if (!printer) return null

  const handleDelete = () => {
    dispatch(actions.app.showAlert({
      title: t('app.page.setting.printer.deleteDevice'),
      message: `${t('app.common.is') + t('app.common.delete')}${printer.name}?`,
      buttons: [
        {
          children: t('app.common.confirm'),
          onPress: () => {
            onClose()
            if (printer.index >= 0) {
              dispatch(actions.printer.deletePrinter(printer.index))
            }
          },
        },
        {
          backgroundColor: colors.light,
          textColor: colors.textTertiary,
          children: t('app.common.cancel'),
          onPress: () => { },
        },
      ],
    }))
  }

  const handleSave = () => {
    if (printers.find((o, index) => o.id === printerState.id && index !== printerState?.index)) {
      dispatch(actions.app.showAlert({ title: t('app.page.setting.printer.saveError'), message: t('app.page.setting.printer.errorMsg1') }))
      return
    }

    if (!/(localhost|[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}:[0-9]{1,5})/g.test(printerState.id)) {
      dispatch(actions.app.showAlert({ title: t('app.page.setting.printer.saveError'), message: t('app.page.setting.printer.errorMsg2') }))
      return
    }
    onClose()
    dispatch(actions.printer.upsertPrinter(printerState))
  }

  return (
    <>
      <Header>
        <HeaderText text={printer.index == null ? t('app.page.setting.printer.newDevice') : t('app.page.setting.printer.editDevice')} />
      </Header>
      <View style={styles.content}>
        <SettingList>
          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.printer.ip')} />
            <SettingListItemRightAction>
              <SettingListInput
                keyboardType='decimal-pad'
                value={printerState.id}
                placeholder='192.168.0.100:9001'
                onChangeText={(text) => setPrinterState({ ...printerState, id: text })}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListItem divider>
            <SettingListItemText text={t('app.page.setting.printer.name')} />
            <SettingListItemRightAction>
              <SettingListInput
                value={printerState.name}
                onChangeText={(text) => setPrinterState({ ...printerState, name: text })}
              />
            </SettingListItemRightAction>
          </SettingListItem>

          <SettingListEnterRow
            divider
            label={t('app.page.setting.printer.type')}
            value={
              printerTypeOptions.find(option => {
                return _.get(printerState, 'printerType') && option.value === printerState.printerType.toUpperCase()
              })?.display ?? t('app.page.setting.printer.null')
            }
            onPress={() => {
              setOptionsPanelConfig({
                title: t('app.page.setting.printer.type'),
                closeOnSelect: true,
                defaultValue: printerState.printerType,
                onChangeValue: (value) => {
                  if (value === PrinterType.TDP225) {
                    setPrinterState({
                      ...printerState,
                      isDefaultPrinter: false,
                      isQRCodePrinter: false,
                      isReportPrinter: false,
                      printerType: value,
                    })
                  } else {
                    setPrinterState({ ...printerState, printerType: value })
                  }
                },
                options: printerTypeOptions,
              })
            }}
          />

          <SettingListItem>
            {
              printerState?.printerType !== PrinterType.TDP225 && (
                <>
                  <RadioBox
                    value={printerState.isDefaultPrinter}
                    disabled={printer.isDefaultPrinter}
                    onChangeValue={(isDefaultPrinter) => setPrinterState({ ...printerState, isDefaultPrinter })}
                    style={styles.RadioBox}
                  />
                  <Text style={styles.RadioBoxLabel}>{t('app.page.setting.printer.default')}</Text>
                  <RadioBox
                    value={printerState.isQRCodePrinter}
                    disabled={printer.isQRCodePrinter}
                    onChangeValue={(isQRCodePrinter) => setPrinterState({ ...printerState, isQRCodePrinter })}
                    style={styles.RadioBox}
                  />
                  <Text style={styles.RadioBoxLabel}>{t('app.page.setting.printer.qrcode')}</Text>
                  <RadioBox
                    value={printerState.isReportPrinter}
                    disabled={printer.isReportPrinter}
                    onChangeValue={(isReportPrinter) => setPrinterState({ ...printerState, isReportPrinter })}
                    style={styles.RadioBox}
                  />
                  <Text style={styles.RadioBoxLabel}>{t('app.page.setting.printer.report')}</Text>
                </>
              )
            }
          </SettingListItem>
        </SettingList>

        {printer.index != null && !!printerStatus?.error && (
          <Text style={styles.errorText}>{printerStatus.error}</Text>
        )}

        {printer.index != null && (
          <View style={styles.buttons}>
            {/* <Button
              textBold
              title={t('app.page.setting.printer.connect')}
              style={styles.button}
              backgroundColor={colors.darkSecondary}
              onPress={() => dispatch(actions.printer.connectPrinter(printerState))}
              disabled={Platform.OS === 'web' || [PrinterClient.STATUS.CONNECTED].includes(printerStatus?.status)}
            />
            <Button
              textBold
              title={t('app.page.setting.printer.close')}
              style={styles.button}
              backgroundColor={colors.darkSecondary}
              onPress={() => dispatch(actions.printer.disconnectPrinter(printerState.id))}
              disabled={Platform.OS === 'web' || ![PrinterClient.STATUS.CONNECTED].includes(printerStatus?.status)}
            /> */}
            {/* {
              (
                printerState?.printerType === PrinterType.GPRINTER ||
                printerState?.printerType === PrinterType.TP808 ||
                printerState?.printerType === PrinterType.TM_T88VI
              ) && (
                <Button
                  textBold
                  title={t('app.page.setting.printer.setLogo')}
                  style={styles.button}
                  backgroundColor={colors.tertiary}
                  onPress={() => dispatch(actions.printer.setLogo(printerState.id))}
                  disabled={Platform.OS === 'web'}
                />
              )
            } */}
            <Button
              textBold
              title={t('app.page.setting.printer.test')}
              style={styles.button}
              backgroundColor={colors.darkSecondary}
              onPress={() => dispatch(actions.printer.printTest(printerState.id))}
              disabled={Platform.OS === 'web'}
            />
          </View>
        )}
      </View>

      <View style={styles.buttons}>
        <Button
          textBold
          title={t('app.common.delete')}
          style={styles.button}
          backgroundColor={colors.primary}
          disabled={printerState.index == null}
          onPress={handleDelete}
        />
        <Button
          textBold
          title={t('app.common.save')}
          style={styles.button}
          backgroundColor={colors.darkSecondary}
          onPress={handleSave}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    display: 'flex',
  },
  RadioBoxLabel: {
    fontSize: 14,
    color: colors.textSecondary,
  },
  errorText: {
    margin: 8,
    paddingLeft: 16,
    color: colors.error,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
