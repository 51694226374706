import { StyleSheet, Text, View } from 'react-native'
import { v4 as uuid } from 'uuid'
import React, { useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'

import Button from '@/components/buttons/Button'
import CenterModal from '@/components/CenterModal'
import ExpandableDivider from '@/components/ExpandableDivider'
import Row from '@/components/Row'
import TagList from '@/components/InfoDialog/TagList'

import DetailInput from '../DetailInput'
import Header from '../Header'
import SubHeader from '../SubHeader'

const defaultState = {
  id: uuid(),
  createdAt: new Date(),
  bookingAt: new Date(),
  table: '',
  name: '',
  customerNameSuffix: '',
  memberId: '',
  phone: '',
  remark: '',
  adults: 1,
  children: 0,
  tags: [],
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function NewBookingDialog (props) {
  const dispatch = useDispatch()
  const visible = useSelector(state => state.table.dialog.newBooking)
  const [state, setState] = useState(defaultState)
  const [error, setError] = useState('')

  const onChangeState = (key, val) => {
    setState(prevState =>
      _.set(_.assign({}, prevState), key, val),
    )
  }

  const onSelectTag = (selectTag) => {
    setState((prevState) => {
      return {
        ...prevState,
        tags: prevState.tags.includes(selectTag)
          ? prevState.tags.filter(tag => tag !== selectTag)
          : [...prevState.tags, selectTag],
      }
    })
  }

  const onClose = () => {
    setState(defaultState)
    setError('')
    dispatch(actions.table.closeDialog(['newBooking']))
  }

  const onSubmit = () => {
    // Checksum
    if (state.name.trim().length === 0) {
      setError('請輸入 * 姓名資料')
      return
    }

    if (
      state.phone.trim().length === 0) {
      setError('請輸入 * 電話資料')
      return
    }

    if (state.adults + state.children <= 0) {
      setError('至少要有一位客人')
      return
    }

    dispatch(actions.table.createBooking(state))
    onClose()
  }

  return (
    <CenterModal
      enablePressOutsideClose
      title='新增預約單'
      visible={visible}
      onClose={onClose}
    >
      <View style={styles.container}>
        <Header
          state={state}
          showDatePicker={() => dispatch(actions.app.showDateTimePicker('date', state.bookingAt, (value) => onChangeState('bookingAt', value)))}
        />
        <SubHeader
          state={state}
          onChangeState={onChangeState}
          showTimePicker={() => dispatch(actions.app.showDateTimePicker('time', state.bookingAt, (value) => onChangeState('bookingAt', value)))}
        />
        <ExpandableDivider
          expanded
        />
        <DetailInput
          state={state}
          onChangeState={onChangeState}
          requireName
          requirePhone
          requireadults
        />
        <TagList
          selectedTags={state.tags}
          onSelectTag={onSelectTag}
        />
        {/* actions */}
        <Row style={styles.bottomRow}>
          <Text style={styles.errorText}>
            {error || ' '}
          </Text>
          <Button
            title='確定'
            textBold
            type='TouchableOpacity'
            onPress={onSubmit}
          />
        </Row>
      </View>
    </CenterModal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 366,
    alignItems: 'center',
    marginHorizontal: 43,
  },
  bottomRow: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    flex: 1,
    fontWeight: '500',
    color: colors.secondary,
  },
})
