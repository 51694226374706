import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import DraggableFlatList from 'react-native-draggable-flatlist'
import React from 'react'
import _ from 'lodash'

import { PrintStyle } from '@/constants/printing'
import OptionsPanel from '@/components/Setting/OptionsPanel'
import SettingListEnterRow from '@/components/Setting/SettingListRow/EnterRow'
import SettingListItem from '@/components/Setting/SettingListItem'
import SettingListItemRightAction from '@/components/Setting/SettingListItemRightAction'
import SettingListItemText from '@/components/Setting/SettingListItemText'
import ToggleSwitch from '@/components/ToggleSwitch'
import colors from '@/theme/colors'
import shadows from '@/theme/shadows'

/**
 * @param {{
 * setting: object,
 * onChange: () => void,
 * }} props
 */
export default function ContentSetting (props) {
  const { setting, onChange } = props
  const { t } = useTranslation()
  const [optionsPanelConfig, setOptionsPanelConfig] = React.useState(null)
  const textStyleOptions = [
    { value: 0, display: t('app.page.setting.printer.size0') },
    { value: 1, display: t('app.page.setting.printer.size1') },
    { value: 2, display: t('app.page.setting.printer.size2') },
    { value: 5, display: t('app.page.setting.printer.size5') },
    { value: 3, display: t('app.page.setting.printer.size3') },
    { value: 4, display: t('app.page.setting.printer.size4') },
  ]
  const lineSpacingOptions = [
    { value: 1, display: 1 },
    { value: 2, display: 2 },
    { value: 3, display: 3 },
    { value: 4, display: 4 },
    { value: 5, display: 5 },
    { value: 6, display: 6 },
    { value: 7, display: 7 },
    { value: 8, display: 8 },
    { value: 9, display: 9 },
    { value: 10, display: 10 },
  ]
  const colorOptions = [
    { value: PrintStyle.COLOR.RED, display: t('app.page.setting.printer.customizedSetting.red') },
    { value: PrintStyle.COLOR.BLACK, display: t('app.page.setting.printer.customizedSetting.black') },
  ]
  if (optionsPanelConfig) {
    return (
      <OptionsPanel
        key={optionsPanelConfig.title}
        onClose={() => setOptionsPanelConfig(null)}
        {...optionsPanelConfig}
      />
    )
  }
  const renderItem = ({ item, index, drag, isActive }) => {
    return (
      <TouchableWithoutFeedback
        onLongPress={drag}
        onPress={() => {
        }}
      >
        <View style={[
          styles.button,
          isActive && shadows.slight,
        ]}
        >
          <Text>{t('app.page.setting.printer.customizedSetting.' + item)}</Text>
          <View style={styles.divider} />
        </View>
      </TouchableWithoutFeedback>
    )
  }

  return (
    <>
      <DraggableFlatList
        showsVerticalScrollIndicator={false}
        data={setting.format ?? ['quantity', 'item', 'price']}
        horizontal
        keyExtractor={(item, index) => String(item + index)}
        renderItem={renderItem}
        onDragEnd={({ data }) => onChange('format', data)}
      />
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.showItemPrice')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings.showItemPrice}
            onChangeValue={() => onChange('settings.showItemPrice', !setting.settings.showItemPrice)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.showOptionPrice')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings.showOptionPrice}
            onChangeValue={() => onChange('settings.showOptionPrice', !setting.settings.showOptionPrice)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.showSetName')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings.showSetName}
            onChangeValue={() => onChange('settings.showSetName', !setting.settings.showSetName)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.hideSetText')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings.hideSetText}
            onChangeValue={() => onChange('settings.hideSetText', !setting.settings.hideSetText)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.hideSetItemText')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings.hideSetItemText}
            onChangeValue={() => onChange('settings.hideSetItemText', !setting.settings.hideSetItemText)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider={!setting.settings.leading}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.leading')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings.leading}
            onChangeValue={() => onChange('settings.leading', !setting.settings.leading)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        setting.settings.leading && (
          <SettingListEnterRow
            divider
            label={'-' + t('app.page.setting.menuPanel.menuInfo.lineSpacing')}
            value={setting.settings.lineSpacing || 1}
            onPress={() => {
              setOptionsPanelConfig({
                title: t('app.page.setting.menuPanel.menuInfo.lineSpacing'),
                defaultValue: setting.settings.lineSpacing || 1,
                onChangeValue: (value) => onChange('settings.lineSpacing', value),
                options: lineSpacingOptions,
                closeOnSelect: true,
              })
            }}
          />
        )
      }
      <SettingListItem divider={!_.get(setting, 'settings.setItemLeading', setting.settings.leading)}>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.setItemLeading')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={_.get(setting, 'settings.setItemLeading', setting.settings.leading)}
            onChangeValue={() => onChange('settings.setItemLeading', !setting.settings.setItemLeading)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      {
        _.get(setting, 'settings.setItemLeading', setting.settings.leading) && (
          <SettingListEnterRow
            divider
            label={'-' + t('app.page.setting.menuPanel.menuInfo.lineSpacing')}
            value={_.get(setting, 'settings.setItemlineSpacing', setting.settings.lineSpacing || 1)}
            onPress={() => {
              setOptionsPanelConfig({
                title: t('app.page.setting.menuPanel.menuInfo.lineSpacing'),
                defaultValue: _.get(setting, 'settings.setItemlineSpacing', setting.settings.lineSpacing || 1),
                onChangeValue: (value) => onChange('settings.setItemlineSpacing', value),
                options: lineSpacingOptions,
                closeOnSelect: true,
              })
            }}
          />
        )
      }
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.itemSeparator')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings.itemSeparator}
            onChangeValue={() => onChange('settings.itemSeparator', !setting.settings.itemSeparator)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListItem divider>
        <SettingListItemText text={t('app.page.setting.printer.customizedSetting.setItemSeparator')} />
        <SettingListItemRightAction>
          <ToggleSwitch
            value={setting.settings.setItemSeparator}
            onChangeValue={() => onChange('settings.setItemSeparator', !setting.settings.setItemSeparator)}
            size={23}
          />
        </SettingListItemRightAction>
      </SettingListItem>
      <SettingListEnterRow
        divider
        label={t('app.page.setting.printer.customizedSetting.itemFontSize')}
        value={textStyleOptions.find(t => t.value === setting.fontSize)?.display ||
                  setting.fontSize}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.itemFontSize'),
            defaultValue: setting.fontSize || 0,
            onChangeValue: (value) => onChange('fontSize', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        divider
        label={t('app.page.setting.printer.customizedSetting.itemQuantityFont')}
        value={textStyleOptions.find(t => t.value === _.get(setting, 'settings.itemQuantityFont', setting.fontSize))?.display ||
          setting.settings.itemQuantityFont}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.itemQuantityFont'),
            defaultValue: _.get(setting, 'settings.itemQuantityFont', setting.fontSize) || 0,
            onChangeValue: (value) => onChange('settings.itemQuantityFont', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        label={t('app.page.setting.printer.customizedSetting.optionFontSize')}
        value={textStyleOptions.find(t => t.value === setting.settings.option.fontSize)?.display ||
                  setting.settings.option.fontSize}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.optionFontSize'),
            defaultValue: setting.settings.option.fontSize || 0,
            onChangeValue: (value) => onChange('settings.option.fontSize', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        divider
        label={'-' + t('app.page.setting.printer.customizedSetting.color')}
        value={colorOptions.find(t => t.value === setting.settings.option.color)?.display ||
                  setting.settings.option.color}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.color'),
            defaultValue: setting.settings.option.color || 0,
            onChangeValue: (value) => onChange('settings.option.color', value),
            options: colorOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        label={t('app.page.setting.printer.customizedSetting.remarkFontSize')}
        value={textStyleOptions.find(t => t.value === setting.settings.remark.fontSize)?.display ||
                  setting.settings.remark.fontSize}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.remarkFontSize'),
            defaultValue: setting.settings.remark.fontSize || 0,
            onChangeValue: (value) => onChange('settings.remark.fontSize', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        divider
        label={'-' + t('app.page.setting.printer.customizedSetting.color')}
        value={colorOptions.find(t => t.value === setting.settings.remark.color)?.display ||
                  setting.settings.remark.color}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.color'),
            defaultValue: setting.settings.remark.color || 0,
            onChangeValue: (value) => onChange('settings.remark.color', value),
            options: colorOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        label='Tag'
        value={textStyleOptions.find(t => t.value === setting.settings.tag.fontSize)?.display ||
                  setting.settings.tag.fontSize}
        onPress={() => {
          setOptionsPanelConfig({
            title: 'Tag',
            defaultValue: setting.settings.tag.fontSize || 0,
            onChangeValue: (value) => onChange('settings.tag.fontSize', value),
            options: textStyleOptions,
            closeOnSelect: true,
          })
        }}
      />
      <SettingListEnterRow
        label={'-' + t('app.page.setting.printer.customizedSetting.color')}
        value={colorOptions.find(t => t.value === setting.settings.tag.color)?.display ||
                  setting.settings.tag.color}
        onPress={() => {
          setOptionsPanelConfig({
            title: t('app.page.setting.printer.customizedSetting.color'),
            defaultValue: setting.settings.tag.color || 0,
            onChangeValue: (value) => onChange('settings.tag.color', value),
            options: colorOptions,
            closeOnSelect: true,
          })
        }}
      />
    </>
  )
}
const styles = StyleSheet.create({
  button: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
    padding: 10,
    width: 135,
    alignItems: 'center',
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.lightGray3,
  },
})
