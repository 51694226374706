import OrderAppLib from 'dimorder-orderapp-lib'
import semver from 'semver'

import { actions } from '@/redux'
import configs from '@/configs'
import store from '@/redux/store'

import logger from '../logger'

const PACKAGE_JSON = require('@root/package.json')

const { dispatch } = store
const { apiUrls } = configs[process.env.NODE_ENV] || configs.default
const appVersion = semver.coerce(PACKAGE_JSON.version).version

const dimorderApi = new OrderAppLib.api.Merchant(apiUrls.go, apiUrls.node, { appVersion })
const dimorderLib = OrderAppLib.libs

const locale = 'zh'
dimorderApi.setLanguage(locale)
dimorderApi.setApiTimeout(15000)

dimorderApi.axiosGo.interceptors.request.use(function (config) {
  config.metadata = { startTime: new Date() }
  return config
})

dimorderApi.axiosGo.interceptors.response.use(response => {
  response.config.metadata.endTime = new Date()
  const duration = (response.config.metadata.endTime.getTime() - response.config.metadata.startTime.getTime()) / 1000
  logger.log('[Api Duration]', {
    url: response.config.url,
    duration,
  })
  return response
},
(error) => {
  dispatch(actions.app.errorHandle(error))
  return Promise.reject(error)
})

dimorderApi.axiosNode.interceptors.request.use(function (config) {
  config.metadata = { startTime: new Date() }
  return config
})

dimorderApi.axiosNode.interceptors.response.use(response => {
  response.config.metadata.endTime = new Date()
  const duration = (response.config.metadata.endTime.getTime() - response.config.metadata.startTime.getTime()) / 1000
  logger.log('[Api Duration]', {
    url: response.config.url,
    duration,
  })

  return response
},
(error) => {
  dispatch(actions.app.errorHandle(error))
  return Promise.reject(error)
})
export { dimorderApi, dimorderLib }
