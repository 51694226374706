import { v4 as uuid } from 'uuid'

import { PrintStyle } from '@/constants/printing'

const defaultLabelStyle = [
  {
    key: 'header',
    enable: true,
    id: uuid(),
    drag: true,
    type: 'row',
    items: [
      {
        id: uuid(),
        type: 'merchantName',
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 8,
        bold: true,
        enable: true,
      },
    ],
  },
  {
    key: 'info',
    enable: true,
    id: uuid(),
    drag: true,
    type: 'row',
    items: [
      {
        id: uuid(),
        type: 'serialNo',
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 8,
        bold: false,
        enable: true,
        last3Digit: false,
      },
      {
        id: uuid(),
        type: 'count',
        align: PrintStyle.ALIGN.RIGTH,
        fontSize: 8,
        bold: false,
        enable: true,
      },
    ],
  },
  {
    key: 'content',
    enable: true,
    id: uuid(),
    drag: true,
    type: 'row',
    items: [
      {
        id: uuid(),
        type: 'price',
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 8,
        bold: false,
        enable: false,
      },
      {
        id: uuid(),
        type: 'table',
        align: PrintStyle.ALIGN.RIGTH,
        fontSize: 8,
        bold: false,
        enable: true,
      },
    ],
  },
  {
    key: 'item',
    enable: true,
    id: uuid(),
    drag: false,
    type: 'block',
    items: [
      {
        id: uuid(),
        type: 'item',
        align: PrintStyle.ALIGN.LEFT,
        fontSize: 8,
        bold: true,
        enable: true,
      },
    ],
  },
]

export default defaultLabelStyle
