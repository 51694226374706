import { StyleSheet, Text, View } from 'react-native'
import { actions, useDispatch, useSelector } from '@/redux'
import { useHistory, useParams } from '@/libs/reactRouter'
import { useImmer } from 'use-immer'
import { useTranslation } from 'react-i18next'
import BackNavigator from '@/components/Setting/BackNavigator'
import EditSetDetail from './EditSetDetail'
import MenuList from '../../MenuList'
import React from 'react'
import StepDialog from './StepList/StepDialog'
import StepList from './StepList'
import _ from 'lodash'

export default function EditSet (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { setId, categoryId } = useParams() // setId為已經存在之餐點，categoryId為新增餐點
  const history = useHistory()
  const set = _.find(useSelector(state => state.menu.sets), s => s.id === setId)

  const lang = useSelector(state => state.app.settings.lang)
  const apiLanguage = lang.slice(0, 2)

  const defaultState = {
    name: '',
    shortName: '',
    code: '',
    desc: '',
    categoryId: '',
    price: 0,
    discount: 0,
    prices: [],
    priceUndetermined: false,
    menus: [],
    steps: [],
    waiterOnly: false,
    disabled: false,
    locale: apiLanguage,
  }

  const [setState, setSetState] = useImmer(defaultState)
  const [selectedStep, setSelectedStep] = React.useState(null)
  const [stepMenus, setStepMenus] = React.useState([])
  const [selectedMenu, setSelectedMenu] = React.useState(null)

  React.useEffect(() => {
    if (categoryId) {
      setSetState({ ...defaultState, categoryId })
    }
  }, [categoryId])

  React.useEffect(() => {
    if (set) {
      setSetState(set)
    }
  }, [set])

  React.useEffect(() => {
    if (selectedStep) {
      const stepMenu = set.menus.filter(m => m.step === selectedStep.id)
      setStepMenus(stepMenu)
    } else {
      setStepMenus([])
    }
  }, [selectedStep])

  // 在sets裡找不到setId
  if (setId && !set) {
    setTimeout(() => history.goBack(), 2000)
    return <Text style={styles.errorText}>{t('app.page.setting.menuPanel.set.empty')}</Text>
  }

  const onChangeState = (path, value, locale) => {
    setSetState(draft => {
      _.set(draft, path, value)
      if (path === 'name' && draft.localeNames && locale) {
        _.set(draft, 'localeNames.' + locale, value)
      }
      if (path === 'desc' && draft.localeDesc && locale) {
        _.set(draft, 'localeDesc.' + locale, value)
      }
    })
  }

  const onChangeStepOrder = (steps) => {
    setSetState({
      ...setState,
      steps,
    })
  }

  const onChangeMenuOrder = (menus, categoryId, menuIds) => {
    setStepMenus(menus)
    const sortedMenus = _.differenceBy(set.menus, menus, 'id').concat(menus)
    dispatch(actions.menu.updateSetMenuOrder(categoryId, menuIds, { ...set, menus: sortedMenus }))
  }

  const addSetStep = () => {
    if (!setState?.id) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.set.error.saveSet')))
      return
    }

    setSelectedStep({
      name: '',
      min: '',
      max: '',
      parentId: set.categoryId,
      setId: set.id,
      isSetStep: true,
      path: set.path,
    })
    dispatch(actions.app.showDialog(['setting', 'step']))
  }

  const onEditSetMenu = () => {
    if (!selectedStep || !selectedStep?.id) {
      dispatch(actions.app.showSimpleAlert(t('app.common.error'), t('app.page.setting.menuPanel.set.error.step')))
      return
    }
    history.push(`/setting/menu/set-menu/${setId}/${selectedStep.id}`)
  }

  const onEditStep = (step) => {
    setSelectedStep(step)
    dispatch(actions.app.showDialog(['setting', 'step']))
  }

  return (
    <View style={styles.container}>
      <BackNavigator
        text={setState.id ? t('app.page.setting.menuPanel.set.editSet') : t('app.page.setting.menuPanel.set.addSet')}
        onPress={() => { history.replace('/setting/menu/set') }}
      />
      <View style={styles.contentContainer}>
        {/* 套餐資料 */}
        <View style={styles.content}>
          <EditSetDetail
            set={setState}
            onChangeState={onChangeState}
            setSetState={(set) => setSetState(set)}
            disableButton={selectedStep !== null}
          />
        </View>

        {/* 步驟 */}
        <View style={[styles.content, styles.setMenus]}>
          <StepList
            draggable
            steps={setState?.steps}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
            addSetStep={addSetStep}
            onEditStep={onEditStep}
            onChangeStepOrder={onChangeStepOrder}
            locale={setState.locale || apiLanguage}
          />

          {/* 步驟 */}
          <MenuList
            draggable
            stepKey={selectedStep?.key}
            menus={stepMenus}
            selectedMenu={selectedMenu}
            onPressHeaderButton={onEditSetMenu}
            onChangeMenuOrder={onChangeMenuOrder}
            headerButtonText={t('app.page.setting.menuPanel.set.edit')}
            isSetMenu
          />
        </View>
      </View>
      <StepDialog
        step={selectedStep}
        setSelectedStep={setSelectedStep}
        menus={set?.menus}
        steps={set?.steps}
        locale={setState.locale || apiLanguage}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    marginHorizontal: 10,
  },
  setMenus: {
    flexDirection: 'row',
  },
})
