import _ from 'lodash'

import codePush from '@/libs/codePush'

export const Deployments = {
  Test: 'Test',
  Beta: 'Beta',
  'Production-Smoke': 'Production-Smoke',
  'Production-SE': 'Production-SE',
  'Production-SE2': 'Production-SE2',
  'Production-SE3': 'Production-SE3',
  'Production-SE4': 'Production-SE4',
  'Production-SE5': 'Production-SE5',
  'Production-Edge': 'Production-Edge',
  'Production-Stable': 'Production-Stable',
  'Prod-Smoke': 'Prod-Smoke',
  'Prod-Edge': 'Prod-Edge',
  'Prod-Edge|1': 'Prod-Edge|1',
  'Prod-Edge|2': 'Prod-Edge|2',
  'Prod-Stable': 'Prod-Stable',
  'Prod-Stable|1': 'Prod-Stable|1',
  'Prod-Stable|2': 'Prod-Stable|2',
  'Prod-Stable|3': 'Prod-Stable|3',
  'Prod-Legacy': 'Prod-Legacy',
}

// 選擇更新頻道時出現的選項
export const deploymentDialogOptions = [
  { value: undefined, display: '預設' },
  { value: 'Prod-Legacy', display: 'Prod-Legacy' },
  { value: 'Prod-Stable|1', display: 'Prod-Stable|1' },
  { value: 'Prod-Stable|2', display: 'Prod-Stable|2' },
  { value: 'Prod-Stable|3', display: 'Prod-Stable|3' },
  { value: 'Prod-Edge|1', display: 'Prod-Edge|1' },
  { value: 'Prod-Edge|2', display: 'Prod-Edge|2' },
  { value: 'Prod-Smoke', display: 'Prod-Smoke' },
  { value: Deployments.Beta, display: 'Beta' },
  { value: Deployments.Test, display: 'Test' },
  { value: 'Prod-Stable', display: 'Prod-Stable' },
  { value: 'Prod-Edge', display: 'Prod-Edge' },
  { value: Deployments['Production-SE'], display: 'SE' },
  { value: Deployments['Production-SE2'], display: 'SE2' },
  { value: Deployments['Production-SE3'], display: 'SE3' },
  { value: Deployments['Production-SE4'], display: 'SE4' },
  { value: Deployments['Production-SE5'], display: 'SE5' },
  { value: Deployments['Production-Edge'], display: 'Production-Edge' },
  { value: Deployments['Production-Smoke'], display: 'Production-Smoke' },
  { value: Deployments['Production-Stable'], display: 'Production-Stable' },
]

// 因為 merchant dashboard 把 deployment 的設定搞成簡短的小寫
// 因此需要有這個 map 來對應到原本的名稱
export const merchantSettingDeploymentMap = {
  test: Deployments.Test,
  beta: Deployments.Beta,
  smoke: Deployments['Production-Smoke'],
  se: Deployments['Production-SE'],
  se2: Deployments['Production-SE2'],
  se3: Deployments['Production-SE3'],
  se4: Deployments['Production-SE4'],
  se5: Deployments['Production-SE5'],
  edge: Deployments['Production-Edge'],
  stable: Deployments['Production-Stable'],
  production: Deployments['Production-Stable'],
  'Prod-Smoke': Deployments['Prod-Smoke'],
  'Prod-Edge': Deployments['Prod-Edge'],
  'Prod-Edge|1': Deployments['Prod-Edge|1'],
  'Prod-Edge|2': Deployments['Prod-Edge|2'],
  'Prod-Stable': Deployments['Prod-Stable'],
  'Prod-Stable|1': Deployments['Prod-Stable|1'],
  'Prod-Stable|2': Deployments['Prod-Stable|2'],
  'Prod-Stable|3': Deployments['Prod-Stable|3'],
  'Prod-Legacy': Deployments['Prod-Legacy'],
}

export const deploymentKeys = {
  [Deployments.Test]: 'WozAJSqv5TzjKajDMctlxHiQ6GHh0J33juLrz',
  [Deployments.Beta]: 'kgNKPJPdOA4PUbKlwwvc-XgymvS54HOAJh5rH',
  [Deployments['Production-Smoke']]: 'Q7MqBEMjLUiMt172PaF_07gHb8PZGlJQyRPqN',
  [Deployments['Production-SE']]: 'Qco_khDqOW3totWzIsbFonlRtPbkz0iJ138RV',
  [Deployments['Production-SE2']]: 'JPMTy3lxs4wFkVCCHXy1JjQVt6_u9hD5DcKVP',
  [Deployments['Production-SE3']]: 'Yzt96n8GhnS295T7Y_Bid95K8zuV_wfda8MDz',
  [Deployments['Production-SE4']]: '2n12XhH3TNMtuGgBztIO9z8zGkj3TbEwSqyrj',
  [Deployments['Production-SE5']]: 'Hi5YuWXOkIUFEKNZoENT79mP9kgrrPbwfsPP-',
  [Deployments['Production-Edge']]: 'rJhFmGv7lbb0JGAiyOHl423P_pOpn-47ly-no',
  [Deployments['Production-Stable']]: 'GMtiO2EpBsCoGZSD_N4sOLJT1wUrYFt3kQE_y',
  [Deployments['Prod-Smoke']]: 'sQXWwoR19rBydDL_xidgH1UH8NGyw2ssrnBDW',
  [Deployments['Prod-Edge']]: 'gDcgF4cWkoe7FuwRy1bBjp2v4tTrFF_WHnix-',
  [Deployments['Prod-Edge|1']]: 'gI6weBp3DrOwLAPYnjKF01JoDsB6nVZGzPdtf',
  [Deployments['Prod-Edge|2']]: '1UBSoN_Xu-itXRkhBy3tFVHTsv7yhPJTuvD6q',
  [Deployments['Prod-Stable']]: 'amUus4FEj6S46XnhFTCD2Enq8Yi_QdZxBQY4e',
  [Deployments['Prod-Stable|1']]: 'VUaOuLv2mvsOSZFVLkGSmQE-Yf0Bd3soG5Xmw',
  [Deployments['Prod-Stable|2']]: 'CY9l4eJHX9yEbu5D93IW94PCuemM8aqnPLUBN',
  [Deployments['Prod-Stable|3']]: 'jZNqGmwC229-ofm9HyCY52EtMxY_ZpsGhof4U',
  [Deployments['Prod-Legacy']]: '3BEHez0GSgWqFZuKvmS2bbDRGrEYrCBPFKI04',
}

export const deploymentKeyMap = _.invert(deploymentKeys)

export const statusDisplayTextI18nKeys = {
  [codePush?.SyncStatus?.UP_TO_DATE]: 'app.page.setting.localPanel.settingCard.upToDate',
  [codePush?.SyncStatus?.UPDATE_INSTALLED]: 'app.page.setting.localPanel.settingCard.updateInstalled',
  [codePush?.SyncStatus?.UPDATE_IGNORED]: 'app.page.setting.localPanel.settingCard.updateIgnored',
  [codePush?.SyncStatus?.UNKNOWN_ERROR]: 'app.page.setting.localPanel.settingCard.unknownError',
  [codePush?.SyncStatus?.SYNC_IN_PROGRESS]: 'app.page.setting.localPanel.settingCard.syncInProgress',
  [codePush?.SyncStatus?.CHECKING_FOR_UPDATE]: 'app.page.setting.localPanel.settingCard.checkingForUpdate',
  [codePush?.SyncStatus?.AWAITING_USER_ACTION]: 'app.page.setting.localPanel.settingCard.awating',
  [codePush?.SyncStatus?.DOWNLOADING_PACKAGE]: 'app.page.setting.localPanel.settingCard.downloading',
  [codePush?.SyncStatus?.INSTALLING_UPDATE]: 'app.page.setting.localPanel.settingCard.installing',
}

export default {
  Deployments,
  merchantSettingDeploymentMap,
  deploymentKeys,
  deploymentKeyMap,
  statusDisplayTextI18nKeys,
}
