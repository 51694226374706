import { Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Row from '@/components/Row'
import colors from '@/theme/colors'

export default function IconLabel (
  {
    style,
    textStyle,
    text,
    iconSource,
    colorIcon = colors.light,
  }) {
  const colorIconStyle = {
    backgroundColor: colorIcon,
  }

  return (
    <Row style={[styles.container, style]}>
      {/* icon / color dot */}
      {iconSource
        ? (
          <Image
            style={styles.icon}
            source={iconSource}
          />
        )
        : (
          <View
            style={[styles.colorIcon, colorIconStyle]}
          />
        )}

      {/* text */}
      <Text
        style={[styles.text, textStyle]}
      >
        {text}
      </Text>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 3,
  },
  colorIcon: {
    height: 10,
    width: 10,
    marginRight: 5,
    borderRadius: 5,
  },
  text: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.gray,
  },
})
