import { FlatList, Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Row from '@/components/Row'
import _ from 'lodash'

import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import styles from '../styles'

const rowWidth = [270, 100]

export default function Subtotal (props) {
  const { items } = props
  const { t } = useTranslation()
  const merchant = useSelector(state => state.merchant.data)

  return (
    <View>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>{t('app.page.setting.printer.customizedSetting.subtotal')}</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.small]}>$60</Text>
      </Row>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>{t('app.page.setting.printer.customizedSetting.promo')}</Text>
      </Row>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>  {t('app.page.setting.printer.customizedSetting.discount')}: {t('app.page.setting.printer.customizedSetting.menuItem')}</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.small]}>$5</Text>
      </Row>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>  {t('app.page.setting.printer.customizedSetting.orderDiscount')}</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.small]}>$5.5</Text>
      </Row>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>{t('app.page.setting.printer.customizedSetting.totalDiscount')}</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.small]}>$10.5</Text>
      </Row>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>{t('app.page.setting.printer.customizedSetting.surcharge')}(20%)</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.small]}>$11</Text>
      </Row>
      <Row>
        <Text style={[{ width: rowWidth[0] }, styles.small]}>{t('app.page.setting.printer.customizedSetting.roundind')}</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.small]}>$0.5</Text>
      </Row>
      <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Text style={[{ width: rowWidth[0] }, styles.large]}>{t('app.page.setting.printer.customizedSetting.roundedTotal')}</Text>
        <Text style={[{ width: rowWidth[1], textAlign: 'right' }, styles.large]}>$60</Text>
      </Row>
    </View>
  )
}
