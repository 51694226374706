import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import colors from '@/theme/colors'

import SummaryRow from './SummaryRow'

/**
 * @typedef DepartmentInfoProps
 * @property {IStatDepartmentData[]} data
 */

/**
 *
 * @param {DepartmentInfoProps} props
 * @returns
 */
export default function DepartmentInfo ({ data = [] }) {
  const { t } = useTranslation()
  const title = t('app.page.stats.department.title')
  const departmentText = t('app.page.stats.department.department')
  const totalText = t('app.page.stats.department.total')

  const renderItem = ({ item }) => {
    return (
      <SummaryRow
        title={item.name}
        count=''
        amount={item.total}
      />
    )
  }

  if (_.isEmpty(data)) {
    return null
  }

  return (
    <View style={styles.container}>
      <View style={{ height: 25, marginTop: 30 }}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.divider} />
      </View>
      <SummaryRow
        title={departmentText}
        count=''
        amount={totalText}
        isString
      />
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(item + index)}
        showsVerticalScrollIndicator={false}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    flex: 1,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})
