
import { ScrollView, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { ecrGateway } from '@/constants'
import NumPadDialog from '@/components/dialogs/NumPadDialog'
import Row from '@/components/Row'
import colors from '@/theme/colors'

import CompleteAllContent from './CompleteAllContent'

/**
 *
 * @param {*} props
 * @returns
 */
export default function PaymentResult (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [updateTipPayment, setUpdateTipPayment] = React.useState(null)

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.paper}>
          <CompleteAllContent onUpdateTipPress={setUpdateTipPayment} />
        </View>
        <Row style={styles.paperCut}>
          {[...Array(19)].map((t, index) =>
            <View style={styles.triangleDown} key={`saw-${index}`} />,
          )}
        </Row>
      </ScrollView>

      <NumPadDialog
        open={Boolean(updateTipPayment)}
        onClose={() => setUpdateTipPayment(null)}
        title={t('app.page.checkout.paymentResult.update-tips')}
        defaultValue={updateTipPayment?.paymentTips}
        onSubmit={(value) => {
          const isEcrPayment = Boolean(updateTipPayment.gateway)
          if (isEcrPayment && Object.values(ecrGateway).includes(updateTipPayment.gateway)) {
            setUpdateTipPayment(null)
            dispatch(actions.orderCheckout.adjustCardPayment(updateTipPayment, value))
          } else {
            dispatch(actions.orderHistory.updatePaymentTips(updateTipPayment.id, value))
            setUpdateTipPayment(null)
          }
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.lightGray3,
    paddingVertical: 48,
  },
  paper: {
    width: 456,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingHorizontal: 80,
    paddingVertical: 48,
  },
  paperCut: {
    paddingBottom: 24,
  },
  triangleDown: {
    width: 0,
    height: 0,
    borderLeftColor: 'transparent',
    borderLeftWidth: 12,
    borderRightColor: 'transparent',
    borderRightWidth: 12,
    borderTopColor: colors.white,
    borderTopWidth: 24,
  },
})
