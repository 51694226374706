import { StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import Column from '@root/src/components/Column'
import React, { useState } from 'react'
import Row from '@root/src/components/Row'
import SearchBar from '@root/src/components/SearchBar'
import colors from '@root/src/theme/colors'

/**
 * @typedef HeaderProps
 * @property {IMember} member
 * @property {() => void} setFilter
 */

/**
 *
 * @param {HeaderProps} props
 * @returns
 */
function Header (props) {
  const { member, setFilter } = props
  const [hideSearch, setHideSearch] = useState(true)
  const [searchText, setSearchText] = React.useState('')
  const { t } = useTranslation()

  return (
    <Row style={[styles.container]}>
      <Row style={[styles.main, member && { justifyContent: 'space-between' }]}>
        <Text style={styles.text}>{member ? t('app.page.Member.memberInfoHeader') : t('app.page.Member.memberListHeader')}</Text>
        <Column style={{ justifyContent: 'space-between' }}>
          {!member && (
            <SearchBar
              value={searchText}
              onChangeText={(text) => setSearchText(text)}
              onOpen={() => setHideSearch(false)}
              onCancel={() => {
                setSearchText('')
                setFilter('')
                setHideSearch(true)
              }}
              onBlur={() => {
                setFilter(searchText)
              }}
              hidden={hideSearch}
              placeholder={t('app.page.Member.searchMember')}
            />
          )}
        </Column>
      </Row>
    </Row>
  )
}

export default Header

const styles = StyleSheet.create({
  container: {
    height: 65,
    alignItems: 'center',
    backgroundColor: colors.primary,
    paddingLeft: 30,
    paddingRight: 22,
  },
  main: {
    flex: 1,
    alignItems: 'center',
    minWidth: 180,
  },
  text: {
    fontSize: 23,
    fontWeight: 'bold',
    color: colors.white,
    marginRight: 30,
  },
  icon: {
    height: 30,
    width: 30,
  },
  button: {
    width: 'auto',
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
})
