import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import { variants } from '@/libs/setting'
import Column from '@/components/Column'
import React from 'react'
import colors from '@/theme/colors'

// 用於display/enter
export default function SettingCard ({
  setting,
  style,
  titleTextStyle,
  valueTextStyle,
  touchableProps = {},
}) {
  const { displayText, value, variant, onPress, onLongPress } = setting
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      {...touchableProps}
      onPress={onPress}
      onLongPress={onLongPress}
      disabled={variant !== variants.SELECT && !onPress && !onLongPress}
    >
      <Column style={[styles.container, style]}>
        <Text style={[styles.titleText, titleTextStyle]}>{t(displayText)}</Text>
        <Text style={[styles.text, valueTextStyle]} numberOfLines={2}>{value}</Text>
      </Column>
    </TouchableOpacity>

  )
}

const styles = StyleSheet.create({
  container: {
    height: 90,
    width: (366 - 10 * 2) / 2, // list固定寬度的一半(留20 margin)
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 5,
  },
  titleText: {
    maxWidth: '70%',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 12,
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
    color: colors.gray,
    textAlign: 'center',
  },
})
