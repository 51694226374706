import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'
import colors from '@root/src/theme/colors'

const OpacityButton = ({ onPress, imageSource, customStyles = {} }) => {
  return (
    <TouchableOpacity
      style={[styles.container, customStyles.container]}
      onPress={onPress}
    >
      <View
        style={styles.buttonBackground}
      />
      <Image
        style={[styles.image, customStyles.image]}
        source={imageSource}
      />
    </TouchableOpacity>
  )
}

export default OpacityButton

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    width: 60,
    height: 60,
  },
  buttonBackground: {
    position: 'absolute',
    borderRadius: 50,
    width: '100%',
    height: '100%',
    backgroundColor: colors.black,
    opacity: 0.4,
  },
  image: {
    alignSelf: 'center',
    tintColor: colors.white,
    width: 25,
    height: 25,
  },
})
